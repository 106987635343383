/*
 * Author: Andrew Seeley
 * Date: 13/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This component is the social sign up buttons for Facebook and Google
 * @param {string} loginType The login type, must be one from the constants LOGIN_TYPE_XXXX
 * @param {callback} setLoading Calls the parent component to set the loading spinner
 * @param {callback} signUpCallBack Calls the parent component once sign up is completed
 */

import React, {Component} from 'react';
import {Platform, Image, View} from 'react-native';
import {StandardButton, StandardText} from './Atoms';

// Social Sign on libaries
import {firebaseAnalysisLogEvent} from '../Helpers/PlatformSynchronizer';
import {getGoogleSignin} from '../Helpers/PlatformSynchronizer';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import FacebookLogo from '../assets/images/fblogo-blue.png';
import GoogleLogo from '../assets/images/google-favicon.png';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import * as env from '../environments';
import {loginFacebook, loginGoogle} from '../API/AuthenticationAPI';
import {removeItem} from '../API/StorageHelper';
import logger from 'helpers/Logger';

export class SocialSignUpButton extends Component {
  constructor(props: any) {
    super(props);
    this.signUpSocialMedia.bind(this);
  }

  componentDidMount() {
    // This prevents jest from throwing an error when using jest. It returns undefined from the Google sign on library otherwise.
    // Configure the Google Sign in Details
    if (Platform.OS === types2.ANDROID) {
      if (this.props.loginType == types.LOGIN_TYPE_GOOGLE) {
        getGoogleSignin.configure({
          webClientId: env.GOOGLE_WEB_CLIENT_ID,
        });
      }
    }
  }

  render() {
    // Based on the login type set the button's text & logo
    let socialButtonText;
    if (this.props.isSignUp) {
      socialButtonText = (
        <StandardText
          style={[
            CommonStyles.textAlignCentre,
            CommonStyles.customFont,
            CommonStyles.rpfont13,
            BrandStyles.TextColor6,
          ]}
        >
          Create account with
        </StandardText>
      );
    } else {
      socialButtonText = (
        <StandardText
          style={[
            CommonStyles.textAlignCentre,
            CommonStyles.customFont,
            CommonStyles.rpfont13,
            BrandStyles.TextColor6,
          ]}
        >
          Login with
        </StandardText>
      );
    }

    let loginType = this.props.loginType;
    let buttonText = '';
    let socialLogo = '';
    switch (loginType) {
      case types.LOGIN_TYPE_FACEBOOK:
        buttonText = 'Facebook';
        socialLogo = FacebookLogo;
        break;
      case types.LOGIN_TYPE_GOOGLE:
        buttonText = 'Google';
        socialLogo = GoogleLogo;
        break;
      default:
        logger.log('Error - invalid login type');
        return (
          <StandardText style={CommonStyles.customFont}>
            Error - invalid login type
          </StandardText>
        );
    }

    return (
      // style={[styles.layout, BrandStyles.primaryBgColor1]}
      <View
        style={[
          CommonStyles.loginSignupButtonWidth,
          CommonStyles.rploginSignupButtonWidth,
        ]}
      >
        {/* <StandardTouchableOpacity style={[styles.layout]} accessibilityLabel={buttonText}>
          <Image style={styles.socialLogo} source={socialLogo} />
          <StandardButton
            style={[CommonStyles.customFontBold, BrandStyles.TextColor6, styles.text]}
            containerStyle={[styles.socialSignInButton]}
            onPress={() => this.signUpSocialMedia()}
          >
            {buttonText}
          </StandardButton>
        </StandardTouchableOpacity> */}
        <StandardButton
          accessibilityLabel={buttonText || ''}
          style={[
            CommonStyles.rpfont13,
            CommonStyles.textAlignCentre,
            CommonStyles.customFont,
            BrandStyles.brandBlockTxtColor6,
            CommonStyles.LoginSignUpP20,
          ]}
          containerStyle={[
            BrandStyles.primaryBgColor1,
            CommonStyles.elevation5,
            BrandStyles.borderColor8,
            CommonStyles.LoginSignUpBW,
            CommonStyles.borderRadius5,
            CommonStyles.borderWidth1,
          ]}
          onPress={() => this.signUpSocialMedia()}
        >
          <View style={[CommonStyles.logingffdacaip]}>
            <Image style={[CommonStyles.loginsingupwh50]} source={socialLogo} />
            <View style={[CommonStyles.loginsingupfacaip]}>
              {socialButtonText}

              <StandardText
                style={[
                  CommonStyles.rpfont13,
                  CommonStyles.customFont,
                  CommonStyles.fontWeightBold,
                  BrandStyles.brandBlockTxtColor6,
                ]}
              >
                {buttonText}
              </StandardText>
            </View>
          </View>
        </StandardButton>
      </View>
    );
  }

  /*
   * Sign up an account using social media
   * Will call back the parent components with the results of the sign up.
   */
  signUpSocialMedia() {
    // Validate the input
    const validateFields = this.props.validateFields(true);

    if (!validateFields) {
      return;
    }
    // Call the parent component & set the loading state to true to show the spinner
    this.props.setLoading();
    // Check what the sign up type is then take action based on this
    const loginType = this.props.loginType;
    // Check if it's a Sign up or Login.
    // We pass this to the loginFacebook and loginGoogle. If it's a signup we create an account for the user
    const isSignUp = this.props.isSignUp;
    switch (loginType) {
      case types.LOGIN_TYPE_GOOGLE:
        loginGoogle(isSignUp)
          .then(response => {
            if (isSignUp) {
              firebaseAnalysisLogEvent(
                types.FIREBASE_ANALYTICS_EVENTS.SIGNUP_GOOGLE,
                {},
              );
            } else {
              firebaseAnalysisLogEvent(
                types.FIREBASE_ANALYTICS_EVENTS.LOGIN_GOOGLE,
                {},
              );
            }

            this.props.signUpCallback(types.LOGIN_TYPE_GOOGLE, null, response);
          })
          .catch(error => {
            removeItem(types.LOGIN_LAST_TYPE);
            logger.log('Google login error ', error);
            // check if there is a Payload and statusCode
            if (error.Payload == null) {
              // error doesn't have a Payload object
              this.props.signUpCallback(types.LOGIN_TYPE_GOOGLE, error, null);
            } else if (error.Payload.statusCode == '409') {
              const errorMessage = 'An account with this email already exists.';
              this.props.signUpCallback(
                types.LOGIN_TYPE_GOOGLE,
                errorMessage,
                null,
              );
            } else if (error.Payload.statusCode == types.VERSION_ERROR) {
              const error = {
                code: types.VERSION_ERROR,
                message: error.Payload.content.message,
              };
              this.props.signUpCallback(types.LOGIN_TYPE_GOOGLE, error, null);
            } else {
              this.props.signUpCallback(types.LOGIN_TYPE_GOOGLE, error, null);
            }
          });
        break;
      default:
        logger.log('Error - invalid login type');
        break;
    }
  }
}

export default SocialSignUpButton;
