/*
 * Author: Declan Hart
 * Date: 09/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Supports information.
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function SupportItemsReducer(state = {}, action: any) {
  switch (action.type) {
    case types.GET_SUPPORT_ITEMS:
      return {
        supportItems: action.itemsObj.supportItems,
      };
    default:
      return state;
  }
}
