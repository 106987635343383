/*
 * Author: Declan Hart
 * Date: 16/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Display Support details
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import {Cost} from '../Atoms';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class UnitOfMeasureYear extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <View>
        <View
          style={[
            CommonStyles.flexRow,
            CommonStyles.justifyContentSpaceBetween,
            CommonStyles.flex1,
          ]}
        >
          <Cost
            year
            save={this.props.save}
            selectedValues={this.props.values.cost}
            readOnly={this.props.readOnly}
          />
        </View>
      </View>
    );
  }
}

export default UnitOfMeasureYear;
