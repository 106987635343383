/*
 * Author: Joshua Merkas
 * Date: 10/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Documents Screen, show all the documents.
 */

import React, {Component} from 'react';
import {View, FlatList, Image, Alert, Platform, AppState} from 'react-native';
import NetInfo from '@react-native-community/netinfo';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {debounce, groupBy, toPairs} from 'lodash';
import * as mime from 'react-native-mime-types-enhanced';
import logger from 'helpers/Logger';

import {SummaryTile, Container, ActionButton} from '../Components/Molecules';
import {
  jpgFileIcon,
  docFileIcon,
  pdfFileIcon,
  DocumentPurple3x,
} from '../assets/images';
import {MyDocumentsRed4x} from '../assets/images/vector-icons';
import {
  StandardText,
  StandardAlert,
  StandardTouchableOpacity,
  StandardSearch,
} from '../Components/Atoms';
import {DocumentRestrictedPopUp} from '../Components/Organisms';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {ERRORS} from '../Constants/Messages';
import {BrandColors} from '../Styles/Colours';

import * as DocumentListActions from '../Actions/DocumentListActions';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {callApiChecker} from '../API/APIHelper';
import {callAPIs} from '../API/APICaller';
import {getDocumentList, getUploadURL} from '../API/MemberAPI';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {DOCUMENTS_SCREEN as messages} from '../Constants/Messages';
import BasicPopupAlert from '../Components/Organisms/BasicPopupAlert';

import {formatDateDocument} from '../Helpers/Utils';
import {fileUploadRestrictCheck} from '../Helpers/FileHelper';
import {isEmpty} from 'lodash';
import {
  DocumentPickerSingleton,
  RNFetchBlobSingleton,
  FilePickerSingleton,
} from '../Helpers/PlatformSynchronizer';
import fileTypeChecker from "file-type-checker";
import { allowedDocumentTypes } from '../Constants/Constants2';

const TIME_INTERVAL = 100;
class Documents extends Component {
  state = {
    fileUploadVisibleModal: false,
    fileEditVisibleModal: false,
    documentList: [],
    loading: true,
    documentPath: '',
    selectedFile: {},
    filePickerResponse: {},
    categories: types2.DOC_UPLOAD.CATEGORIES,
    documentCategory: 1,
    documentCategoryDisplay: 'Plan - Current',
    documentListOriginal: [],
    docItem: {},
    docIndex: {},
    saveApiError: false,
    isConnected: true,
    hasChanged: false,
    tempSearchValue: '',
    searchInput: '',
    searchDocuments: [],
    permissionAlertMessage: '',
    errorPopUpVisible: false,
    documentRestrictPoupVisible: false,
  };
  subscription: any;

  constructor(props: any) {
    super(props);

    this._getDocumentList.bind(this);
    this._createMainContents.bind(this);
    this._openDocument.bind(this);
    this._fileUploadSaveButton.bind(this);
    this._fileTypeImage.bind(this);
    this.getDocumentTypeValue.bind(this);
    this.getDocumentCategoryType.bind(this);
    this.handleConnectivityChange = this.handleConnectivityChange.bind(this);
    this._editIconCallback.bind(this);
    this._renderdocs.bind(this);
    this._showAlert.bind(this);
    this._closeAlert = this._closeAlert.bind(this);
  }

  documentListOriginal = [];

  componentDidMount() {
    this._getDocumentList(true);
    NetInfo.addEventListener(this.handleConnectivityChange);
    this.subscription=AppState.addEventListener('change', this._handleAppStateChange);
  }

  componentWillUnmount() {
    // AppState.removeEventListener('change', this._handleAppStateChange);
    this.subscription.remove();
  }

  componentDidUpdate(prevProps: any) {
    if (isEmpty(prevProps.documentList) && this.props.documentList.documentList ) {
      this.setState({
        loading: false,
      });
      if (types.isWeb) {
        this.props.setLoading(false);
      }
    }
  }


  handleConnectivityChange = (isConnected: any) => {
    this.setState({isConnected: isConnected});
  };

  _handleAppStateChange = (nextAppState: any) => {
    if (nextAppState) {
      NetInfo.fetch().then(isConnected => {
        this.setState({isConnected: isConnected});
      });
    }
  };

  _closeAlert() {
    this.setState({
      errorPopUpVisible: false,
    });
  }

  _closeDocumentAlert = () => {
    this.setState({
      documentRestrictPoupVisible: false,
    });
  };

  _getDocumentList = (force: any) => {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    let callbackFunction = (data: any) => {
      var documentList = data[0].content;
      this.props.actions.DocumentListActions.actionGetDocumentList(
        documentList.documents,
      );
      var reduxDocumentList = documentList.documents;
      var reduxDocumentListOriginal = reduxDocumentList.slice();
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        documentList: reduxDocumentList,
        searchDocuments: reduxDocumentList,
        documentListOriginal: reduxDocumentListOriginal,
        documentListLength: reduxDocumentList.length,
        loading: false,
      });
    };

    if (callApiChecker(this.props.documentList.apiCalled) || force) {
      callAPIs(
        [
          getDocumentList(
            this.props.loadedMemberId.loadedMemberId,
            this.props.user.demoProfile,
          ),
        ],
        callbackFunction,
        null,
        () => {
          if (types.isWeb) {
            this.props.setLoading(false);
          }
          this.setState({loading: false});
        },
      );
    } else {
      var reduxDocumentList = this.props.documentList.documentList;
      var reduxDocumentListOriginal = reduxDocumentList.slice();
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        documentList: reduxDocumentList,
        searchDocuments: reduxDocumentList,
        documentListOriginal: reduxDocumentListOriginal,
        documentListLength: reduxDocumentList.length,
        loading: false,
      });
    }
  };

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        needsSideBar={false}
        loading={this.state.loading}
        screenType={types.SCREEN_TYPE_MAIN}
        activeScreen={types.SCREEN_DOCUMENTS}
        selectedIcon={types.SCREEN_DOCUMENTS}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.DOCUMENTS}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _determineAccess() {
    if (Object.getOwnPropertyNames(this.props.member).length === 0) {
      return true;
    } else {
      let readOnly = this.props.member;
      if (readOnly.access) {
        if (readOnly.access.documents) {
          if (readOnly.access.documents == types.ACCESS_READ_ONLY) {
            return false;
          }
        }
      }
    }
    if (this.props.user.demoProfile) {
      return false;
    }
    return true;
  }

  _createMainContents = () => (
    <View style={[CommonStyles.flexColumn, CommonStyles.content]}>
      <View style={[CommonStyles.marginLeft10, CommonStyles.marginRight10]}>
        <SummaryTile
          disableMore={true}
          headerDisplayString={types2.MY_DOCUMENTS}
          headerIconImage={MyDocumentsRed4x}
          HeaderTextColor={BrandStyles.headerContainsDataText}
          HeaderBoarderColor={BrandStyles.headerContainsDataBorder}
          modal={(event: any) => {
            this.onPick(event);
          }}
          hideYellowStar={true}
          showButton={this._determineAccess()}
          webFileUpload={this._webFileUploadOnChange}
          isWebFileUpload={types.isWeb ? true : false}
        />
      </View>
      <View style={[CommonStyles.searchContainer]}>
        <StandardSearch
          border={CommonStyles.borderWidth0}
          underlineColorAndroid="transparent"
          value={this.state.tempSearchValue}
          placeholder={types2.SEARCH_DOCUMENTS}
          onChangeText={(tempSearchValue: any) => {
            this.updateSearchResults(tempSearchValue);
          }}
        />
      </View>
      {this.renderDocumentList()}
      {this._renderPermisionPopup()}
      <DocumentRestrictedPopUp
        visible={this.state.documentRestrictPoupVisible}
        close={this._closeDocumentAlert}
      />
      <BasicPopupAlert
        visible={this.state.errorPopUpVisible}
        close={this.state.closeAction}
        cancelLabel={this.state.alertButtonText}
        headerText={this.state.alertHeader}
        messageText={this.state.alertMessage}
      />
    </View>
  );

  _webFileUploadOnChange = async (fileObject: any) => {
    const responseModified = fileObject;
    const arrayBuffer = await fileObject.arrayBuffer();

    const validatedFileType = fileTypeChecker.validateFileType(arrayBuffer, allowedDocumentTypes);
    if (fileUploadRestrictCheck(fileObject.name) || !validatedFileType) {
      this.setState({documentRestrictPoupVisible: true});
    } else {
      responseModified.fileName = fileObject.name;
      this.props.actions.NavigationParamActions.setParam({
        isFromHealthwellbeing: true,
        filePickerResponse: responseModified,
        categories: this.state.categories,
        documentCategory: this.state.documentCategory,
      });

      this.props.navigation.navigate(types.DOCUMENTADDFORM);
    }
  };

  renderDocumentList = () => {
    let documentFlatList;
    if (this.state.documentList.length == 0) {
      documentFlatList = (
        <StandardText style={[CommonStyles.customFont]}>
          {types2.NO_DOCUMENTS}
        </StandardText>
      );
    } else {
      let categorizedList = groupBy(
        this.props.documentList.searchList.sort((a: any, b: any) =>
          a.category.localeCompare(b.category),
        ),
        'category',
      );
      let categorizedArray = toPairs(categorizedList);

      documentFlatList = (
        <View style={[CommonStyles.DocumentListContainer]}>
          <View style={[CommonStyles.flex1]}>
            <FlatList
              data={categorizedArray}
              extradata={this.state}
              renderItem={({item}) => this._renderDocumentListItems(item)}
            />
          </View>
        </View>
      );
    }

    return documentFlatList;
  };

  _saveFileUploadModal = () => this._fileUploadSaveButton();

  getDocumentTypeValue() {
    return this.state.categories.find(category => {
      return category.type === this.state.documentCategory;
    });
  }
  getDocumentCategoryType(docCategory: any) {
    return this.state.categories.find(category => {
      return category.value === docCategory;
    });
  }

  _fileUploadSaveButton() {
    let filePickerResponse = this.state.filePickerResponse;
    let documentList = this.state.documentList;
    let requestObject = {
      fileType: types2.FILE_TYPE.DOCUMENT,
      filename: filePickerResponse.fileName,
      contentType: filePickerResponse.type,
      documentCategory: this.getDocumentTypeValue().value,
    };

    // Create Callback function with URL Returned
    let getUploadURLCallbackFunction = (data: any) => {
      let url = data[0].content.uploadURL;
      var cleanUri = '';
      if (Platform.OS === types2.IOS) {
        cleanUri = decodeURI(filePickerResponse.path.replace('file://', ''));
      } else if (Platform.OS === types2.ANDROID) {
        cleanUri = filePickerResponse.path;
      }

      RNFetchBlobSingleton.fetch(
        'PUT',
        url,
        {'Content-Type': filePickerResponse.type},
        RNFetchBlobSingleton.wrap(cleanUri),
      )
        .uploadProgress((written, total) => {})
        .then(filePickerResponse => {
          const date = new Date();
          const currentTimeDate = date.toISOString();
          const newDocument = {
            url: url,
            filename: requestObject.filename,
            lastModified: currentTimeDate,
            category: requestObject.documentCategory,
          };
          this.setState({
            selectedFile: newDocument,
          });
          const documentList = this.state.documentList;
          documentList.push(newDocument);
          this.setState({
            documentList: documentList,
          });
          this._getDocumentList(true);
          this.setState({loading: false, fileUploadVisibleModal: false});
        })
        .catch(error => {
          logger.log('The error is:', error);
          this.setState({
            saveApiErrorCode: null,
            saveApiError: true,
            loading: false,
          });
        });
    };

    const getUploadURLErrorCallbackFunction = (err: any) => {
      logger.log('Documents upload file error: ', err);
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
        loading: false,
      });
    };

    // Upload URL
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({loading: true, saveApiError: false});
    callAPIs(
      [getUploadURL(this.props.loadedMemberId.loadedMemberId, requestObject)],
      getUploadURLCallbackFunction,
      getUploadURLErrorCallbackFunction,
    );
  }

  _closeFileUploadModal = () =>
    this.setState({
      fileUploadVisibleModal: false,
      loading: false,
    });

  _editIconCallback = (editItem: any) => {
    this.setState({
      filename: editItem.filename,
      documentCategory: this.state.categories.find(category => {
        return category.value === editItem.category;
      }).type,
      documentKey: editItem.key,
      docIndex: this.state.documentList.indexOf(editItem),
    });
    this.props.actions.NavigationParamActions.setParam({
      filename: editItem.filename,
      documentCategory: this.state.categories.find(category => {
        return category.value === editItem.category;
      }).type,
      documentKey: editItem.key,
      docIndex: this.state.documentList.indexOf(editItem),
      lastModified: editItem.lastModified,
    });
    this.props.navigation.navigate('DocumentEditForm');
  };

  timeoutPicture = null;
  onPick(event: any) {
    if (Platform.OS === types2.ANDROID) {
      if (this.timeoutPicture) clearTimeout(this.timeoutPicture);
      this.timeoutPicture = setTimeout(() => this._uploadDocument(event), 500);
    } else {
      this._uploadDocument(event);
    }
  }

  // Function to show the file picker
  _uploadDocument(event: any) {
    if (
      this.props.member &&
      this.props.member.access.document == types.ACCESS_READ_ONLY
    ) {
      return;
    }
    const pageX = event.nativeEvent ? event.nativeEvent.pageX : 0;
    const pageY = event.nativeEvent ? event.nativeEvent.pageY : 0;
    var options = {
      title: messages.SELECT_DOCUMENT,
      customButtons: [{name: types.FROM_DRIVES_KEY, title: types.FROM_DRIVES}],
      mediaType: 'photo',
      storageOptions: {
        skipBackup: true,
        path: 'images',
      },
    };

    DocumentPickerSingleton(pageX, pageY, options).then(response => {
      let assets = response.assets?.[0];
      if(Platform.OS === types2.IOS){
        assets = response
      }
      if (response.documentRestrictPoupVisible) {
        this.setState({documentRestrictPoupVisible: true});
      } else {
        this.props.actions.NavigationParamActions.setParam({
          filePickerResponse: {
            fileName: assets.fileName,
            path: Platform.OS === types2.IOS ? assets.path : assets.uri,
            type: mime.types[
              assets.fileName.split('.').pop().toLocaleLowerCase()
            ],
            uri: assets.uri,
          },
          isFromHealthwellbeing: false,
        });
        this.props.navigation.navigate(types.DOCUMENTADDFORM);
      }
    });
  }

  _closeAlertMessage = () => {
    this.setState({permissionAlertMessage: ''});
  };

  _renderPermisionPopup() {
    return (
      <StandardAlert
        modalTitle={ERRORS.GENERIC_ISSUE_ALERT_TITLE}
        close={this._closeAlertMessage}
        message={this.state.permissionAlertMessage}
      />
    );
  }
  // Renders the items in the flatlist.
  _renderDocumentListItems = (item: any) => {
    item[1].sort(
      (a: any, b: any) =>
        new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime(),
    );

    return (
      <View style={[CommonStyles.budgetsTilesContainer]}>
        {this._categoryDescription(item[0])}
        {this._renderdocs(item[1])}
      </View>
    );
  };

  _renderdocs = (dList: any) => {
    let that = this;
    let outcomeList: any = [];
    dList.forEach(function (item: any, index: any) {
      outcomeList.push(
        <StandardTouchableOpacity
          onPress={() => that._editIconCallback(item)}
          style={[BrandStyles.primaryBgColor1]}
          accessibilityLabel={item.filename}
          activeOpacity={0.8}
          style={[CommonStyles.budgetsTileWrapper, BrandStyles.primaryBgColor1]}
          key={index}
        >
          <View>
            <View style={CommonStyles.documentTitle}>
              <StandardTouchableOpacity
                accessibilityLabel={'Open Document ' + item.filename}
                style={[
                  CommonStyles.documentName,
                  CommonStyles.documentTileMarginTouchableRight,
                ]}
                onPress={() => that._openDocument(item)}
              >
                <View style={CommonStyles.documentImageMargin}>
                  {that._fileTypeImage(item.filename)}
                </View>
                <StandardText
                  style={[
                    CommonStyles.item,
                    CommonStyles.customFontBold,
                    BrandStyles.TextColor5,
                    CommonStyles.documentTileFontColor,
                    types.isWeb ? CommonStyles.font15 : CommonStyles.font20,
                    CommonStyles.alignFlexStart,
                  ]}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {item.filename}
                </StandardText>
              </StandardTouchableOpacity>
            </View>
            <View style={CommonStyles.documentDate}>
              <View>
                <StandardText
                  style={[
                    CommonStyles.ProfileTileHeaderText,
                    BrandStyles.TextColor5,
                    CommonStyles.font15,
                    CommonStyles.customFont,
                  ]}
                >
                  {formatDateDocument(item.lastModified)}
                </StandardText>
              </View>
            </View>
          </View>
        </StandardTouchableOpacity>,
      );
    });

    return outcomeList;
  };

  _fetchSearchDocuments() {
    let searchInput = this.state.tempSearchValue;
    let searchDocuments: any = [];
    this.props.documentList.documentList
      .filter(function (item: any) {
        return (
          item.filename.toLowerCase().includes(searchInput.toLowerCase()) ==
            true ||
          item.category.toLowerCase().includes(searchInput.toLowerCase()) ==
            true
        );
      })
      .map(function (item: any) {
        searchDocuments.push(item);
        return item;
      });
    if (searchInput) {
      this.props.actions.DocumentListActions.actionGetSearchDocumentList(
        searchDocuments,
      );
    } else {
      this.props.actions.DocumentListActions.actionGetDocumentList(
        this.props.documentList.documentList,
      );
    }
    if (types.isWeb) {
      this.props.setLoading(false);
    }
    this.setState({loading: false});
  }

  debouncedSearchFilteredDocumentFunc = debounce(
    () => this._fetchSearchDocuments(),
    TIME_INTERVAL,
  );

  updateSearchResults(tempSearchValue: any) {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({tempSearchValue: tempSearchValue, loading: true});
    this.debouncedSearchFilteredDocumentFunc();
  }

  // Function to display the correct format for the categories
  _categoryDescription(category: any) {
    let categoryDisplayName = this.state.categories.find(item => {
      return item.value === category;
    });

    return (
      <StandardText
        style={[
          CommonStyles.customFontBold,
          CommonStyles.documentTitleMargin,
          CommonStyles.documentTitleColor,
          types.isWeb ? CommonStyles.font15 : CommonStyles.font20,
        ]}
      >
        {categoryDisplayName?.description}
      </StandardText>
    );
  }

  // Force Download paremeter equal to true automatically downloads the file
  _openDocument(document: any, forceDownload: any) {
    let fileExt = '';
    if (this.getFileExtension(document.filename)) {
      fileExt = this.getFileExtension(document.filename)[0];
    }
    let contentTypeExt =
      fileExt == undefined ? '.' + document.contentType.split('/').pop() : '';

    if (types.isWeb) {
      this.props.setLoading(false);
    }
    this.setState({loading: true});

    if (Platform.OS === types2.IOS) {
      const ios = RNFetchBlobSingleton.ios;
      const dirs = RNFetchBlobSingleton.fs.dirs;
      RNFetchBlobSingleton.config({
        path:
          dirs.DocumentDir + '/LeapIn/' + document.filename + contentTypeExt,
        fileCache: true,
      })
        .fetch('GET', document.url, {
          // Headers here
        })
        .then(res => {
          this.setState({loading: false});
          ios
            .openDocument(res.path())
            .catch((err: any) =>
              this._showAlert(
                types2.ERROR,
                types2.DOWNLOAD_ERROR,
                types2.OK,
                this._closeAlert,
                true,
              ),
            );
        })
        .catch(err => {
          this._showAlert(
            types2.ERROR,
            types2.DOWNLOAD_ERROR,
            types2.OK,
            this._closeAlert,
            true,
          );
        });
    } else if (Platform.OS === types2.ANDROID) {
      let fileType = document.contentType;
      if (fileType == types2.PDF) {
        this.props.navigation.navigate('PdfViewer', {
          url: document.url,
          fileName: document.filename,
        });
        this.setState({loading: false});
      } else if (
        types2.IMAGE_FILE_TYPES.includes(fileType) &&
        forceDownload != true
      ) {
        this.props.navigation.navigate('WebViewer', {
          url: document.url,
          fileName: document.filename,
        });
        this.setState({loading: false});
      } else {
        const {config, fs} = RNFetchBlobSingleton;
        const DownloadDir = fs.dirs.DownloadDir;
        const options = {
          fileCache: true,
          addAndroidDownloads: {
            useDownloadManager: true,
            notification: true,
            mime: document.contentType,
            title: document.filename + ' has been downloaded',
            description: 'File downloaded by file manager',
            mediaScannable: true,
            path: DownloadDir + '/LeapIn/' + document.filename,
          },
        };
        config(options)
          .fetch('GET', document.url)
          .then(res => {});
        this.setState({loading: false});
      }
    } else if (types.isWeb) {
      window.open(document.url);
    }
  }

  getFileExtension(filename: any) {
    let fileType: any = [];
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : fileType;
  }
  // Selecteds the file type image to display based on document type
  _fileTypeImage(file: any) {
    let fileExt = this.getFileExtension(file);
    if (fileExt && (fileExt[0] === 'jpg' || fileExt[0] === 'jpeg')) {
      return <Image style={[CommonStyles.documentIcon]} source={jpgFileIcon} />;
    } else if (fileExt && (fileExt[0] === 'doc' || fileExt[0] === 'docx')) {
      return <Image style={[CommonStyles.documentIcon]} source={docFileIcon} />;
    } else if (fileExt && fileExt[0] === 'pdf') {
      return <Image style={[CommonStyles.documentIcon]} source={pdfFileIcon} />;
    } else {
      return (
        <Image style={[CommonStyles.documentIcon]} source={DocumentPurple3x} />
      );
    }
  }

  _showAlert(
    alertHeader: any,
    alertMessage: any,
    alertButtonText: any,
    closeAction: any,
    errorPopUpVisible: any,
  ) {
    this.setState({
      alertHeader: alertHeader,
      alertMessage: alertMessage,
      alertButtonText: alertButtonText,
      closeAction: closeAction,
      errorPopUpVisible: errorPopUpVisible,
    });
  }
}

const mapStateToProps = (state: any) => ({
  loadedMemberId: state.LoadedMemberReducer,
  documentList: state.DocumentListReducer,
  member: state.MemberReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    DocumentListActions: bindActionCreators(DocumentListActions, dispatch),
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);