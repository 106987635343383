/*
 * Author: Declan Hart
 * Date: 28/04/2020
 * Copyright © 2020 Leap in!. All rights reserved.
 *
 * Carousel Component for Popup Modal
 */

import React, {Component} from 'react';
import {View, Image, Linking, Platform, Dimensions} from 'react-native';
//Import extermal components
import ModalMobile from 'react-native-modal';
import ModalWeb from 'modal-enhanced-react-native-web';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// import Orientation from 'react-native-orientation-enhanced';
import {getStyleByOrientation} from '../../Helpers/GetStyleByOrientation'
import {isTablet} from '../../Helpers/PlatformSynchronizer';
//import {WebView as WebViewWeb} from 'react-native-webview';
import {WebViewWeb} from 'helpers/ReactNativeWebview';

//Import redux actions
import * as GuideMeActions from '../../Actions/GuideMeActions';

//Import custom components
import {StandardButton, StandardText} from '../Atoms';
import {BrandActivityIndicator, GuideMeView, SummaryTile} from './';
import {Carousel, SvgMask} from '../Atoms';

//Import constants
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {APP_ID} from '../../environments';

//Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {
  GUIDE_ME_TEXT,
  GUIDE_ME_TEXT_WEB,
  GUIDE_ME_RICH_TEXT,
  GUIDE_ME_RICH_TEXT_WEB,
  GUIDE_ME_IMAGE,
} from '../../Styles/ResponsiveCSS';

import {
  aboutMeIconCharcoal,
  aboutMeIconRed,
} from '../../assets/images/vector-icons';

const typeImage = 'image';
const windowDimensions = Dimensions.get('window');

class PopupModal extends Component {
  state = {
    orientation: '',
  };

  componentDidUpdate(prevProps: any) {
    if (
      this.props.guideMe.welcomeGuideStep !== prevProps.guideMe.welcomeGuideStep
    ) {
      if (
        (this.props.guideMe.showWelcomeGuide &&
          APP_ID === types2.APP_ID.LEAPIN &&
          this.props.guideMe.welcomeGuideStep ===
            types2.GUIDE_ME_STEPS.STEP_1) ||
        this.props.guideMe.welcomeGuideStep === types2.GUIDE_ME_STEPS.STEP_6
      ) {
        this.props.actions.GuideMeActions.setOverlayHighlightOne({
          size: {x: 0, y: 0},
          position: {x: 0, y: 0},
        });
      }
    }
  }

  // UNSAFE_componentWillMount() {
  //   // The getOrientation method is async. It happens sometimes that
  //   // you need the orientation at the moment the JS runtime starts running on device.
  //   // `getInitialOrientation` returns directly because its a constant set at the
  //   // beginning of the JS runtime.
  //   if (!types.isWeb) {
  //     const initial = Orientation.getInitialOrientation();
  //     this.setState({
  //       orientation: initial,
  //     });
  //   }
  // }

  componentDidMount() {
    // if (!types.isWeb) {
    //   Orientation.addOrientationListener(this._orientationDidChange);
    // }
  }
  componentWillUnmount() {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation,
    });
  };

  _renderGuideContent = () => {
    if (this.props.dataArray && this.props.dataArray.length > 0) {
      if (
        !(
          this.props.guideMe.showWelcomeGuide ||
          this.props.guideMe.showWelcomeManagedGuide
        )
      ) {
        return this.props.dataArray.map((item: any, index: any) => (
          <View key={index} style={CommonStyles.carouselContainer}>
            <StandardText
              style={[
                CommonStyles.customFontBold,
                CommonStyles.popUptextAlign,
                CommonStyles.popGuideMeTitleText,
              ]}
            >
              {types2.GUIDE_ME_TITLE}
            </StandardText>
            <SummaryTile
              headerDisplayString={item.TITLE}
              headerIconImage={aboutMeIconRed}
              screenType={types.SCREEN_TYPE_MAIN}
              containsData={true}
              actionOverride={true}
              displayMore={false}
            />
            {this._renderContent(item)}

            {/* Un-Comment below line when we implement Internal Links */}
            {/* {this._renderNavigationButton(item)} */}
          </View>
        ));
      } else {
        return this.props.dataArray.map((item: any, index: any) => (
          <View key={index} style={CommonStyles.carouselContainer}>
            <StandardText
              style={[
                CommonStyles.customFontBold,
                CommonStyles.popUptextAlign,
                CommonStyles.popGuideMeTitleText,
              ]}
            >
              {types2.GUIDE_ME_TITLE}
            </StandardText>
            <SummaryTile
              headerDisplayString={item.TITLE}
              headerIconImage={aboutMeIconRed}
              screenType={types.SCREEN_TYPE_MAIN}
              containsData={true}
              actionOverride={true}
              displayMore={false}
            />
            <View>{this.getContent(item)}</View>
          </View>
        ));
      }
    }
  };

  getTitleInfo = (item: any) => {
    if (item.TITLE_INFO) {
      return (
        <StandardText
          style={[
            CommonStyles.rpfont19,
            BrandStyles.TextColor3,
            CommonStyles.marginBottom20,
            CommonStyles.fontWeightBold,
          ]}
        >
          {item.TITLE_INFO}
        </StandardText>
      );
    }
  };

  getContent = (item: any) => {
    if (APP_ID === types2.APP_ID.LEAPIN) {
      return this.renderGuideMeContent(item);
    } else {
      return this.renderDualBrandContent(item);
    }
  };

  renderDualBrandContent = (item: any) => {
    if (item.IMAGE) {
      return (
        <View
          style={[
            CommonStyles.justifyFlexRowSpace,
            CommonStyles.marginTop20,
            CommonStyles.paddingLeft10,
            CommonStyles.paddingRight10,
          ]}
        >
          <View style={CommonStyles.marginTop20}>
            {this.renderAvatarImage(item)}
          </View>
          <View
            style={[
              CommonStyles.alignCenter,
              CommonStyles.rpMarginTop20p,
              CommonStyles.containerGuideMeText,
            ]}
          >
            {this.renderDualBrandText(item)}
          </View>
        </View>
      );
    } else {
      return (
        <View
          style={[CommonStyles.justifyFlexRowSpace, CommonStyles.marginTop20]}
        >
          {this.renderDualBrandText(item)}
        </View>
      );
    }
  };

  renderDualBrandText = (item: any) => {
    const Content = item.CONTENT;

    if (Content.length === 2) {
      return (
        <View style={CommonStyles.AlignCenter}>
          {this.getTitleInfo(item)}
          <StandardText style={CommonStyles.rpfont15}>
            {Content[0]}
            <StandardText
              style={[CommonStyles.rpfont15, CommonStyles.fontWeightBold]}
            >
              {Content[1]}
            </StandardText>
          </StandardText>
        </View>
      );
    } else if (Content.length === 3) {
      return (
        <View style={CommonStyles.AlignCenter}>
          <StandardText style={CommonStyles.rpfont15}>
            {Content[0]}
            <StandardText
              style={[CommonStyles.rpfont15, CommonStyles.fontWeightBold]}
            >
              {Content[1]}
            </StandardText>
            {Content[2]}
          </StandardText>
        </View>
      );
    } else {
      return (
        <View style={CommonStyles.AlignCenter}>
          <StandardText style={CommonStyles.rpfont15}>
            {Content[0]}
            <StandardText
              style={[CommonStyles.rpfont15, CommonStyles.fontWeightBold]}
            >
              {Content[1]}
            </StandardText>
            {Content[2]}
          </StandardText>
          <StandardText
            style={[CommonStyles.rpfont15, CommonStyles.marginTop15]}
          >
            {Content[3]}
          </StandardText>
        </View>
      );
    }
  };

  renderAvatarImage = (item: any) => {
    return (
      <View style={CommonStyles.alignCenter}>
        <Image style={item.IMAGE_STYLE} source={item.IMAGE} />
      </View>
    );
  };

  renderGuideMeContent = (item: any) => {
    return <View>{this.renderGuideMeText(item)}</View>;
  };

  renderGuideMeText = (item: any) => {
    const Content = item.Content ? item.Content : item.CONTENT;
    const Theme = item.THEME;
    const TextStyle = [CommonStyles.rpfont15, CommonStyles.fontWeightBold];
    let TextColors: any = [];
    if (Theme == types2.WELCOME_GUIDE_THEMES.Red) {
      TextColors = [BrandStyles.TextColor3, BrandStyles.TextColor2];
    } else if (Theme == types2.WELCOME_GUIDE_THEMES.Purple) {
      TextColors = [BrandStyles.TextColor4, BrandStyles.TextColor3];
    } else if (Theme == types2.WELCOME_GUIDE_THEMES.RedPurple) {
      TextColors = [BrandStyles.TextColor3, BrandStyles.TextColor4];
    } else if (
      Theme == types2.WELCOME_GUIDE_THEMES.Yellow ||
      Theme === types2.WELCOME_GUIDE_THEMES.YellowBlack
    ) {
      TextColors = [BrandStyles.TextColor2, BrandStyles.TextColor3];
    }

    if (Content.length == 2) {
      return (
        <View>
          <StandardText style={[TextStyle, TextColors[0]]}>
            {Content[0]}
            <StandardText style={[TextStyle, TextColors[1]]}>
              {Content[1]}
            </StandardText>
          </StandardText>
        </View>
      );
    } else if (Content.length == 3) {
      return (
        <View>
          <StandardText style={[TextStyle, TextColors[0]]}>
            {Content[0]}
            <StandardText style={[TextStyle, TextColors[1]]}>
              {Content[1]}
            </StandardText>
            {Content[2]}
          </StandardText>
        </View>
      );
    } else if (Content.length == 5) {
      return (
        <View>
          <StandardText style={[TextStyle, TextColors[0]]}>
            {Content[0]}
            <StandardText style={[TextStyle, TextColors[1]]}>
              {Content[1]}
            </StandardText>
            {Content[2]}
            <StandardText
              onPress={() => {
                Linking.openURL(item.LINK);
              }}
              style={[
                TextStyle,
                TextColors[0],
                CommonStyles.textDecorationLineUnderline,
              ]}
            >
              {Content[3]}
            </StandardText>
            {Content[4]}
          </StandardText>
        </View>
      );
    } else {
      return this._renderContent(item);
    }
  };

  _renderNavigationButton = (item: any) => {
    const content = JSON.parse(item.Content);
    if (content.Link !== undefined || content.LinkType !== undefined) {
      return (
        <StandardButton
          onPress={() => this._navigationCallback(item)}
          style={[
            BrandStyles.TextColor1,
            CommonStyles.customFontMedium,
            CommonStyles.fontWeight400,
            CommonStyles.buttonText,
          ]}
          containerStyle={[
            CommonStyles.ModalButtonContainer,
            CommonStyles.ModalCancelButton,
            BrandStyles.ContainerPrimaryBgColor,
          ]}
          accessibilityLabel={types2.ACCESSIBILITY_LABELS.TAP_TO_READ}
        >
          {types2.CLICK_HERE}
        </StandardButton>
      );
    }
  };

  _renderDynamicSideMenuContent = () => {
    let dynamicSideMenuContent: any = [];
    let scene = this.props.sideMenu.currentScreen;
    let memberStatus = this.props.member.memberStatus;
    const enableNewPlanOption = this.props.member.enableNewPlanOption;

    if (scene === types2.LINKED_MEMBER) {
      dynamicSideMenuContent = types2.MenuItemsDefault;
    } else if (scene === types2.BUDGETS) {
      if (memberStatus === types2.MEMBER_STATUS_DATA.PLAN_READY) {
        dynamicSideMenuContent = types2.MenuItemsPlanReady;
      } else if (
        memberStatus === types2.MEMBER_STATUS_DATA.ONBOARDING ||
        memberStatus === types2.MEMBER_STATUS_DATA.MANAGED
      ) {
        dynamicSideMenuContent = types2.MenuItemsBudget;
      }
    } else {
      if (memberStatus === types2.MEMBER_STATUS_DATA.PLAN_READY) {
        dynamicSideMenuContent = types2.MenuItemsPlanReady;
      } else if (
        memberStatus === types2.MEMBER_STATUS_DATA.ONBOARDING ||
        memberStatus === types2.MEMBER_STATUS_DATA.MANAGED
      ) {
        if (!enableNewPlanOption) {
          dynamicSideMenuContent = types2.MenuItemsManagedOrOnBoarding;
        } else {
          dynamicSideMenuContent = types2.MenuItemsNewPlanEnabled;
        }
      }
    }

    return dynamicSideMenuContent;
  };

  _navigationCallback(item: any) {
    const content = JSON.parse(item.Content);

    if (content.LinkType == 'External') {
      this.props.close();
      Linking.openURL(content.Link);
    } else {
      let sideMenuContent = this._renderDynamicSideMenuContent();
      let navigateScreen = false;
      //check if screen exists in current sideMenu content
      Object.keys(sideMenuContent).forEach(key => {
        if (sideMenuContent[key].screen == content.Link) {
          this.props.actions.GuideMeActions.getPreviousScreenInfo({
            fromScreen: types2.GUIDEME,
            currentScreen: content.Link,
          });
          navigateScreen = true;
        }
      });

      if (navigateScreen) {
        this.props.close();
        return this.props.navigation.navigate(content.Link);
      }
    }
  }

  _renderContent(item: any) {
    if (item.ContentType === typeImage) {
      let content = JSON.parse(item.Content ? item.Content : item.CONTENT);
      let image =
        this.state.orientation === types2.ORIENTATION.LANDSCAPE
          ? content.landscape
          : content.portrait;
      return (
        <Image
          style={[BrandStyles.primaryBgColor3, CommonStyles.flex1]}
          source={{uri: image}}
        />
      );
    } else {
      if (types.isWeb) {
        return (
          <WebViewWeb
            scalesPageToFit={Platform.OS === types2.ANDROID ? true : false}
            srcDoc={true}
            source={this._applyCSS(item)}
          />
        );
      }
      return (
        <StandardText>
          {item.Content ? item.Content : item.CONTENT}
        </StandardText>
      );
    }
  }

  _applyCSS(item: any) {
    var cssStyles = '';
    switch (item.ContentType) {
      case types2.GUIDE_ME_CONTENT_TYPE.PLAIN_TEXT:
        cssStyles = types.isWeb ? GUIDE_ME_TEXT_WEB : GUIDE_ME_TEXT;
        break;
      case types2.GUIDE_ME_CONTENT_TYPE.HTML:
        cssStyles = types.isWeb ? GUIDE_ME_RICH_TEXT_WEB : GUIDE_ME_RICH_TEXT;
        break;
      case types2.GUIDE_ME_CONTENT_TYPE.IMAGE:
        cssStyles = GUIDE_ME_IMAGE;
        break;
      case types2.GUIDE_ME_CONTENT_TYPE.CUSTOM_HTML:
        cssStyles = '';
        break;
    }

    const htmlContent = `<style>${cssStyles}</style>  ${
      item.Content ? item.Content : item.CONTENT
    }`;
    return htmlContent;
  }

  _renderModalContent = () => {
    return (
      <View style={CommonStyles.popUpContainer}>
        <Carousel
          isMobile={this.props.isMobile}
          windowWidth={this.props.windowWidth}
          action={this.props.close}
          buttonText={types2.OK_GOT_IT}
          guideMe={this.props.guideMe}
          guideMeActions={this.props.actions.GuideMeActions}
        >
          {this._renderGuideContent()}
        </Carousel>
      </View>
    );
  };

  renderMask = () => {
    const layout = {
      height: windowDimensions.height,
      width: windowDimensions.width,
    };

    if (this.props.guideMe.overlayHighlightOne) {
      const overlayHighlightOne = this.props.guideMe.overlayHighlightOne;
      const overlayHighlightTwo = this.props.guideMe.overlayHighlightTwo;

      return (
        <SvgMask
          animated={false}
          layout={layout}
          style={CommonStyles.containerOverlay}
          overlayHighlightOne={overlayHighlightOne}
          overlayHighlightTwo={overlayHighlightTwo}
        />
      );
    } else {
      return null;
    }
  };

  getPopUpModalStyle = () => {
    //Modal styles for GuideMe popup
    const style = [CommonStyles.popUpModal];

    //Return style for popup modal in device and web
    let isTabletView = isTablet;
    if (types.isWeb && this.props.isMobile) {
      isTabletView = false;
    }

    if (this.props.guideMe.showWelcomeManagedGuide) {
      switch (this.props.guideMe.welcomeGuideStep) {
        case types2.GUIDE_ME_STEPS.STEP_2:
          isTabletView
            ? style.push(CommonStyles.popUpModalAlignStepTwo)
            : style.push(CommonStyles.popUpModalAlignStepTwoMobile);
          break;
        case types2.GUIDE_ME_STEPS.STEP_3:
          isTabletView
            ? style.push(CommonStyles.popUpModalAlignStepThree)
            : style.push(CommonStyles.popUpModalAlignStepThreeMobile);
          break;
        case types2.GUIDE_ME_STEPS.STEP_4:
          isTabletView
            ? style.push(CommonStyles.popUpModalAlignStepFour)
            : style.push(CommonStyles.popUpModalAlignStepFourMobile);
          break;
        case types2.GUIDE_ME_STEPS.STEP_5:
          isTabletView
            ? style.push(CommonStyles.popUpModalAlignStepFive)
            : style.push(CommonStyles.popUpModalAlignStepFiveMobile);
          break;
        default:
          isTabletView
            ? style.push(CommonStyles.popUpModalAlign)
            : style.push(CommonStyles.popUpModalAlignMobile);
          break;
      }
    } else {
      if (types.isWeb && this.props.isMobile) {
        style.push(CommonStyles.popUpModalMobAlign);
      } else {
        style.push(CommonStyles.popUpModalAlign);
      }
    }
    return style;
  };

  render() {
    var Modal = ModalMobile;
    if (types.isWeb) {
      Modal = ModalWeb;
    }
    return (
      <Modal
        style={types.isWeb ? CommonStyles.margin0 : null}
        isVisible={this.props.visible}
        backdropOpacity={0.2}
        animationInTiming={1}
        animationOutTiming={1}
      >
        <View
          style={getStyleByOrientation(
            this.getPopUpModalStyle(),
            this.state.orientation,
          )}
        >
          <BrandActivityIndicator
            enableWeb={true}
            loading={this.props.loading}
            overlayStyles={
              types.isWeb ? CommonStyles.screenActivityIndicator : null
            }
          />
          {this._renderModalContent()}
        </View>
        <View style={CommonStyles.containerOverlayWrapper}>
          {(this.props.guideMe.showWelcomeGuide ||
            this.props.guideMe.showWelcomeManagedGuide) &&
            this.renderMask()}
        </View>
      </Modal>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  sideMenu: state.SideMenuReducer,
  member: state.MemberReducer,
  guideMe: state.GuideMeReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    GuideMeActions: bindActionCreators(GuideMeActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupModal);
