/*
 * Author: Nirodha Perera
 * Date: 20/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Summary tile on the Goals for summary screen
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View} from 'react-native';

import {
  GOAL_SCREEN_LABELS,
  FILE_TYPE,
  ADD_GOAL_PICTURE_TITLE,
  ADD_GOAL_PICTURE_HEADER,
  READ_SIMPLE,
  WEB_STORE,
} from '../../Constants/Constants2';

import {ACTION_BUTTON, isWeb} from '../../Constants/Constants';

import {setImageRedux} from '../../Helpers/ImageHelper';
import {StandardText, StandardTouchableOpacity} from '../Atoms';
import {SummaryTile, PhotoTile} from '../Molecules';

import {bindActionCreators} from 'redux';
import {
  goalsIconCharcoal,
  goalsIconRed,
  viewIconCharcoal60,
} from '../../assets/images/vector-icons';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import {connect} from 'react-redux';

import * as AvatarListActions from '../../Actions/AvatarListActions';
import * as GoalActions from '../../Actions/GoalActions';
import * as NavigationParamActions from '../../Actions/NavigationParamActions';

import {getAvatarImages} from '../../API/MemberAPI';
import {callAPIs} from '../../API/APICaller';
import {setValue, getValue, removeItem} from '../../API/WebPersistenceStore';

class GoalDetails extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <StandardTouchableOpacity
        activeOpacity={0.8}
        onPress={this.props.onPress}
        accessibilityLabel={GOAL_SCREEN_LABELS.ADD_GOAL}
        style={[
          CommonStyles.containerGoalDetails,
          BrandStyles.primaryBgColor1,
          CommonStyles.paddingLeft20,
          CommonStyles.paddingRight20,
          CommonStyles.marginBottom20,
        ]}
      >
        <View style={CommonStyles.flexDirectionRow}>
          <View style={CommonStyles.oneContainer}>
            {this._renderGoalName(this.props.goalItem)}
            <View style={CommonStyles.flexDirectionRow}>
              <PhotoTile
                borderRadius={CommonStyles.borderRadius15}
                style={StyleSheet.flatten(
                  CommonStyles.containerPhotoTileGoalImage,
                )}
                imageGap={5}
                bottomMargin={false}
                flexRatio={CommonStyles.flex1}
                apiFileType={FILE_TYPE.GOAL_PHOTO}
                imageObject={this.props.goalItem.goalPhoto}
                showCaption={false}
                actionCallback={this._navigateAddGoalImage}
                actionData={this.props.goalItem}
                clickable={true}
                readOnly={this.props.readOnly}
                rightMargin={false}
                lastUpdate={this._lastUpdateTime(this.props.goalItem)}
              />
              <View style={[CommonStyles.flex1, CommonStyles.marginTop10]}>
                {this._renderGoalSteps(this.props.goalItem)}
              </View>
            </View>
          </View>
        </View>
      </StandardTouchableOpacity>
    );
  }

  _navigateAddGoalImage = () => {
    const navigationParams = this.props.actions.NavigationParamActions.setParam(
      {
        imageObject: this.props.goalItem.goalPhoto,
        headerTitle: ADD_GOAL_PICTURE_TITLE,
        mainHeader: ADD_GOAL_PICTURE_HEADER,
        photoType: FILE_TYPE.GOAL_PHOTO,
        goalItem: this.props.goalItem,
      },
    );
    if (isWeb) {
      removeItem(WEB_STORE.NAVIGATION_PARAMS);
      setValue(WEB_STORE.NAVIGATION_PARAMS, JSON.stringify(navigationParams));
    }
    this.props.navigation.navigate('ProfilePictureForm');
    this._getAvatarImages();
  };

  _getAvatarImages = () => {
    const callbackFunction = (data: any) => {
      const avatarList = data[0].content;
      this.props.actions.AvatarListActions.actionGetAvatarList(avatarList);

      if (isWeb) {
        removeItem(WEB_STORE.AVATAR_LISTOBJ);
        setValue(WEB_STORE.AVATAR_LISTOBJ, JSON.stringify(avatarList));
      }

      this.setState({
        loading: false,
      });
    };

    callAPIs(
      [getAvatarImages(this.props.loadedMemberId.loadedMemberId)],
      callbackFunction,
      null,
      () => {
        this.setState({loading: false});
      },
    );
  };

  _lastUpdateTime = (goalItem: any) => {
    if (
      goalItem.goalPhoto !== undefined &&
      goalItem.imageUploadObject === undefined &&
      goalItem.goalPhoto.lastModified
    ) {
      return new Date(goalItem.goalPhoto.lastModified);
    } else {
      return new Date();
    }
  };

  _renderGoalName = (goalItem: any) => {
    if (goalItem.goalName) {
      return (
        <StandardText
          style={[
            CommonStyles.SummaryTileText,
            BrandStyles.TextColor5,
            CommonStyles.customFontBold,
          ]}
        >
          {goalItem.goalName}
        </StandardText>
      );
    } else {
      return null;
    }
  };

  _renderGoalSteps = (goalItem: any) => {
    let currentGoalSteps = goalItem.currentGoalSteps;
    if (currentGoalSteps === undefined) {
      return null;
    } else {
      currentGoalSteps = currentGoalSteps.slice(0, 4);
      let counter = 1;
      let displayString = [];
      for (let i = 0; i < currentGoalSteps.length; i++) {
        displayString.push({
          index: counter,
          string: currentGoalSteps[i].stepName,
        });
        counter++;
      }

      return displayString.map(item => (
        <View style={CommonStyles.flexDirectionRow}>
          <View style={CommonStyles.flexDirectionColumn}>
            <StandardText
              style={[
                CommonStyles.SummaryTileText,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
              ]}
            >
              {item.index + '. '}
            </StandardText>
          </View>
          <View style={CommonStyles.flexDirectionColumn}>
            <StandardText
              style={[
                CommonStyles.SummaryTileText,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
                CommonStyles.justifyContentFlexStart,
              ]}
            >
              {item.string}
            </StandardText>
          </View>
        </View>
      ));
    }
  };
}

const mapStateToProps = (state: any) => ({
  loadedMemberId: state.LoadedMemberReducer,
  goals: state.GoalReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    AvatarListActions: bindActionCreators(AvatarListActions, dispatch),
    GoalActions: bindActionCreators(GoalActions, dispatch),
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GoalDetails);
