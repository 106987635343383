/*
 * Author: Brad D'Costa
 * Date: 13/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays number spinner used throughout the app.
 * Will return the new value on change
 */

/* Props that can be used
 * Name				Description											Type	Default
 * max				maximum number of the Spinner						number	0
 * min				minimum number of the Spinner						number	99
 * default			default number of the Spinner						number	0
 * color			custom color of the Spinner							string	'#33c9d6'
 * numColor			custom number color									string	'#333333'
 * numBgColor		background color of number button					string	'#ffffff'
 * onNumChange		get the number of the Spinner						func	N/A
 * showBorder		show the border of the Spinner or not				bool	true
 * disabled			disable the Spinner or not							bool	false
 * fontSize			custom fontSize of the text input in the Spinner	number	20
 * btnFontSize		custom fontSize of buttons in the Spinner			number	20
 * buttonTextColor	custom color of the button in the Spinner			string	'#ffffff'
 * width			custom width of the Spinner							number	90
 * height			custom height of the Spinner						number	30
 * underlayColor	custom color of the TouchableHighlight underlay		string  '#ffffff'
 */

import React, {Component} from 'react';
import {View, TouchableHighlight, StyleSheet} from 'react-native';

import {
  SingletonNumberSpinner,
  announceForAccessibility,
} from '../../Helpers/PlatformSynchronizer';

import {StandardText} from '../Atoms';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

// Import Stylesheets
import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';
import {BrandColors} from '../../Styles/Colours';

// This style are passed override default style of NumberSpinner which used for Web App
// This styles put inside this file because 'input.mobile' kind styles not working when import from CommonStyles.js
const customWebNumberSpinnerStyles = {
  input: {
    width: 99,
    height: 33,
  },
  'input.mobile': {
    border: '2px solid rgb(0, 0, 0)',
    borderRadius: 4,
  },
  btn: {
    borderColor: 'none',
    background: 'none',
    top: 0,
    bottom: 0,
    right: 0,
  },
  'btnDown.mobile': {
    top: 0,
    bottom: 0,
    left: 0,
    width: 30,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
  'btnUp.mobile': {
    top: 0,
    bottom: 0,
    width: 30,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },
};

class NumberSpinner extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      min: props.min || 0,
      max: props.max || 99,
      num: props.default || 0,
      color: props.color || '#33c9d6',
      numColor: props.numColor || '#333333',
      numBgColor: props.numBgColor || '#ffffff',
      showBorder: props.showBorder || true,
      fontSize: props.fontSize || 20,
      btnFontSize: props.btnFontSize || 20,
      buttonTextColor: props.buttonTextColor || '#ffffff',
      disabled: props.disabled || false,
      width: props.width || 90,
      height: props.height || 30,
      highlightUnderlayColor: props.underlayColor || '#ffffff',
    };
  }

  componentDidMount() {
    this.setState({props: this.props});
  }

  render() {
    let noAccess = false;
    if (this.props.readOnly == 'read') {
      noAccess = true;
    }
    if (noAccess == true) {
      return (
        <View
          style={[
            CommonStyles.NSContainer,
            {
              borderColor: this.state.showBorder
                ? this.state.color
                : 'transparent',
            },
            {width: this.state.width},
          ]}
        >
          <View
            style={[
              CommonStyles.NSNum,
              BrandStyles.readOnlyTextfieldGrey,
              {
                borderColor: this.state.showBorder
                  ? this.state.color
                  : 'transparent',
                height: this.state.height,
              },
            ]}
          >
            <StandardText
              accessibilityLabel={`Current value ${this.state.num}`}
              style={[
                CommonStyles.NSNumText,
                CommonStyles.customFontMedium,
                {color: this.state.numColor, fontSize: this.state.fontSize},
              ]}
            >
              {this.state.num}
            </StandardText>
          </View>
        </View>
      );
    }
    if (types.isWeb) {
      return (
        <View style={[CommonStyles.textField, CommonStyles.maxWidth99]}>
          <SingletonNumberSpinner
            value={this.props.default}
            onChange={(value: any) => {
              this.setValue(value);
            }}
            onClick={(value: any) => {
              this.setValue(value);
            }}
            min={0}
            strict
            style={customWebNumberSpinnerStyles}
          />
        </View>
      );
    } else {
      return (
        <View style={CommonStyles.textField}>
          <SingletonNumberSpinner
            initialValue={this.props.default}
            onValueChange={(value: any) => {
              this.setValue(value);
            }}
            displayValue
            borderColor={BrandColors.CHARCOAL_TINT60}
            borderWidth={2}
            tintColor={BrandColors.CHARCOAL_TINT60}
            overrideTintColor
            textColor={BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL}
            height={50}
            width={150}
            fontSize={25}
          />
        </View>
      );
    }
  }

  _onNumChange(num: any) {
    if (this.props.onNumChange) this.props.onNumChange(num);
    const textToRead = `Current Value ${num}`;
    announceForAccessibility(textToRead);
  }

  setValue(value: any) {
    if (this.props.onNumChange) this.props.onNumChange(value);
  }

  _increase() {
    if (this.state.disabled) return;

    if (this.state.max > this.state.num) {
      const num = this.state.num + 1;
      if (typeof this.state.value === 'undefined') {
        this.setState({num});
      }
      this._onNumChange(num);
    }
  }

  _decrease() {
    if (this.state.disabled) return;

    if (this.state.min < this.state.num) {
      const num = this.state.num - 1;
      if (typeof this.state.value === 'undefined') {
        this.setState({num});
      }
      this._onNumChange(num);
    }
  }
}

export default NumberSpinner;
