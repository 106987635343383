import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {View, PanResponder, StyleSheet} from 'react-native';

export default class UserInactivity extends PureComponent {
  static propTypes = {
    timeForInactivity: PropTypes.number,
    checkInterval: PropTypes.number,
    children: PropTypes.node.isRequired,
    onInactivity: PropTypes.func.isRequired,
    recordLastActiveTime: PropTypes.func.isRequired,
  };

  static defaultProps = {
    timeForInactivity: 10000,
    checkInterval: 2000,
    style: {
      flex: 1,
    },
  };

  inactivityTimer: any;

  state = {
    timeForInactivity: 10000,
    checkInterval: 2000,
  };

  lastInteraction = new Date();
  panResponder = {};

  UNSAFE_componentWillReceiveProps() {
    this.panResponder = PanResponder.create({
      onStartShouldSetPanResponder: this.onStartShouldSetPanResponder,
      onMoveShouldSetPanResponder: this.onMoveShouldSetPanResponder,
      onStartShouldSetPanResponderCapture: this.onStartShouldSetPanResponder,
      onMoveShouldSetPanResponderCapture: () => false,
      onPanResponderTerminationRequest: () => true,
      onShouldBlockNativeResponder: () => false,
    });

    this.maybeStartWatchingForInactivity();
  }

  componentWillUnmount() {
    clearInterval(this.inactivityTimer);
    this.panResponder = null;
    this.inactivityTimer = null;
  }

  onStartShouldSetPanResponder = () => {
    this.props.recordLastActiveTime();
    this.setIsActive();
    return false;
  };

  onMoveShouldSetPanResponder = () => {
    this.props.recordLastActiveTime();
    this.setIsActive();
    return false;
  };

  maybeStartWatchingForInactivity = () => {
    if (
      this.inactivityTimer &&
      this.state.timeForInactivity == this.props.timeForInactivity
    ) {
      return;
    }

    this.setState({
      timeForInactivity: this.props.timeForInactivity,
      checkInterval: this.props.checkInterval,
    });

    if (this.inactivityTimer) {
      clearInterval(this.inactivityTimer);
    }

    this.inactivityTimer = setInterval(() => {
      if (new Date() - this.lastInteraction >= this.state.timeForInactivity) {
        this.setIsInactive();
      }
    }, this.state.checkInterval);
  };

  setIsActive = () => {
    this.lastInteraction = new Date();
    if (this.state.timeWentInactive) {
      this.setState(
        {
          timeWentInactive: null,
        },
        () => {
          //this.props.onInactivity(null);
        },
      );
    }
    this.maybeStartWatchingForInactivity();
  };

  setIsInactive = () => {
    const timeWentInactive = new Date();
    this.setState(
      {
        timeWentInactive,
      },
      () => {
        this.props.onInactivity(timeWentInactive);
      },
    );
    clearInterval(this.inactivityTimer);
    this.inactivityTimer = null;
  };

  render() {
    const {style, children} = this.props;
    return (
      <View
        style={style}
        collapsable={false}
        {...this.panResponder.panHandlers}
      >
        {children}
      </View>
    );
  }
}
