/*
 * Author: Declan Hart
 * Date: 16/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Display Support details
 */

import React, {Component} from 'react';
import {View, Image} from 'react-native';

import {StandardText} from '../Atoms';
import {SupportTitle, SupportTrafficLights} from '../Molecules';
import {SupportPurple3x} from '../../assets/images/vector-icons';
import CustomIcon from '../../assets/images/CustomIcon';
// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {ACCESS_READ_ONLY} from '../../Constants/Constants';
import {
  SUPPORTS_ADD_TITLE,
  SUPPORTS_EDIT_TITLE,
  SUPPORS_VIEW_TITLE,
} from '../../Constants/Constants2';

class SupportDetailsSection extends Component {
  constructor(props: any) {
    super(props);
  }

  getSupportsCount = () => {
    if (
      this.props.supportsCount > 1 &&
      this.props.addSupportStep !== undefined
    ) {
      return this.props.addSupportStep + ' of ' + this.props.supportsCount;
    }
  };

  getSupportsTitle = () => {
    let supportsTitle = SUPPORTS_ADD_TITLE;
    if (this.props.edit) {
      supportsTitle = SUPPORTS_EDIT_TITLE;
    }
    if (this.props.readOnly === ACCESS_READ_ONLY) {
      supportsTitle = SUPPORS_VIEW_TITLE;
    }

    if (this.props.crews && this.props.crews.length > 0) {
      let crewDisplayText = '';
      for (let i = 0; i < this.props.crews.length; i++) {
        const crewMember = this.props.crews[i];
        if (crewDisplayText != '') {
          if (i == this.props.crews.length - 1) {
            crewDisplayText += ` and `;
            crewDisplayText += this.getName(crewMember.crew);
          } else {
            crewDisplayText += `, `;
            crewDisplayText += this.getName(crewMember.crew);
          }
        } else {
          crewDisplayText += this.getName(crewMember.crew);
        }
      }
      supportsTitle = 'Support from ' + crewDisplayText;
    }
    return supportsTitle;
  };

  getName = (crew: any) => {
    if (
      !(crew.firstName && crew.lastName) ||
      (crew.firstName == '' && crew.lastName == '')
    ) {
      return `${crew.orgName}`;
    } else {
      if (crew.orgName && crew.orgName != '') {
        return `${crew.firstName} ${crew.lastName} ${crew.orgName}`;
      } else {
        return `${crew.firstName} ${crew.lastName}`;
      }
    }
  };

  render() {
    let access = true;
    if (this.props.readOnly === ACCESS_READ_ONLY) {
      access = false;
    }

    const supportsCount = this.getSupportsCount();

    return (
      <View style={this.props.style}>
        <View style={[CommonStyles.containerSupport_AddDetailTitle]}>
          <View
            style={[
              CommonStyles.justifyFlexRowStart,
              CommonStyles.alignItemsCenter,
              this.props.edit && access ? CommonStyles.flex07 : {},
            ]}
          >
            <CustomIcon
              style={[
                CommonStyles.titleBoxIcon,
                CommonStyles.titleBoxIconMargin,
                BrandStyles.TextColor4,
              ]}
              name={SupportPurple3x}
            />
            <View>
              <StandardText
                style={[
                  CommonStyles.font20,
                  CommonStyles.customFontBold,
                  BrandStyles.TextColor4,
                  CommonStyles.paddingRight20,
                  CommonStyles.marginRight20,
                ]}
              >
                {this.getSupportsTitle()}
              </StandardText>
            </View>
          </View>
          {this.props._topDeleteButton ? this.props._topDeleteButton() : null}
          {supportsCount ? (
            <View style={[CommonStyles.justifyFlexRowEnd, CommonStyles.flex03]}>
              <StandardText
                style={[CommonStyles.customFontBold, CommonStyles.font18]}
              >
                {supportsCount}
              </StandardText>
            </View>
          ) : null}
        </View>
        {this.props.topButtons ? this.props.topButtons() : null}
        <View
          style={[
            CommonStyles.justifyFlexColumnStart,
            CommonStyles.marginTop20,
          ]}
        >
          <SupportTitle
            style={CommonStyles.justifyFlexRowStart}
            displayString={this.props.title}
            save={this.props.saveTitle}
            readOnly={this.props.readOnly}
            valid={this.props.valid}
          />
          <SupportTrafficLights
            style={[CommonStyles.justifyFlexColumnStart]}
            onPress={this.props.saveOpinion}
            selected={this.props.opinion}
            readOnly={this.props.readOnly}
          />
        </View>
      </View>
    );
  }
}

export default SupportDetailsSection;
