/*
 * Author: Declan Hart
 * Date: 16/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * TODO: <Summary of why we have this class>
 */

import React, {Component} from 'react';
import {
  Text,
  View,
  FlatList,
  StyleSheet,
  ScrollView,
  TextInput,
  Image,
  TouchableOpacity,
} from 'react-native';

import {editIcon} from '../../assets/images';

import {StandardText, StandardInput} from '../Atoms';
import {TitleBox, SummaryTileButton} from './';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import { DOMPurify } from 'helpers/dompurify';

class SupportTitle extends Component {
  constructor(props: any) {
    super(props);
    this.onTextChange = this.onTextChange.bind(this);
  }

  onTextChange(title: any) {
    this.props.save(title);
  }

  render() {
    return (
      <View style={[this.props.style]}>
        <StandardInput
          readOnly={this.props.readOnly}
          accessibilityLabel="Support title"
          key="title"
          border={BrandStyles.borderColor4}
          value={this.props.displayString}
          multiline
          onChangeText={(title: any) =>{
            const sanitizedTitle = DOMPurify.sanitize(title);
 
            this.onTextChange(sanitizedTitle)}}
          blurOnSubmit
          style={[
            CommonStyles.inputFieldBasic,
            CommonStyles.fontWeight400,
            CommonStyles.multilineInputField,
            CommonStyles.ModalTextInputWrapper,
            {width: '100%'},
          ]}
          underlineColorAndroid="transparent"
          errorMessage={this.props.valid}
        />
      </View>
    );
  }
}

export default SupportTitle;
