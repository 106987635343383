/*
 * Author: Brad D'Costa
 * Date: 11/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Web Service class to handle the About Me API calls
 */

// Custom functions for helping connect to the secure AWS API Gateway
import {
  initaliseAwsClient,
  configuredAWSLamda,
  buildLambda,
  parseResult,
  apiErrorHandler,
} from './APIHelper';
import AWS from 'aws-sdk';
import {AWS_LAMBDA_SERVICES} from '../environments';
import logger from 'helpers/Logger';

//* ********************************
//*       A B O U T   M E
//* ********************************

/*
 * Returns the Living Arrangements for a given Member ID
 * @async
 * @param {string} memberId The member ID of the user you want to return
 * @returns {Promise<object>} The living arrangements object
 */
export const getLivingArrangement = (
  memberId: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getLivingArrangementById = configuredAWSLamda();
      const requestBody = {id: memberId, isDemoProfile};
      const functionName = 'getAboutMeLivingArrangement';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.ABOUTME;
      const params = buildLambda(requestBody, functionName, serviceName);
      getLivingArrangementById.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/*
 * Updates the Living Arrangements for a given Member ID
 * @async
 * @param {string} memberId The member ID of the user you want to return
 * @param {object} request The object containing the updated details
 * @returns {Promise<object>} The living arrangements object if successful, otherwise an error object
 */
export const updateLivingArrangement = (
  memberId: any,
  planId: any,
  requestObj: any,
  isDemoProfile = false,
  autoHandleError = false,
) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const putAboutMeLivingArrangement = configuredAWSLamda();
      const requestBody = {id: memberId, planId, isDemoProfile};
      for (const k in requestObj) requestBody[k] = requestObj[k];

      const functionName = 'putAboutMeLivingArrangement';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.ABOUTME;
      const params = buildLambda(requestBody, functionName, serviceName);
      putAboutMeLivingArrangement.invoke(params, (err, data) => {
        if (err) {
          logger.log('The updateLivingArrangement error is:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/*
 * Returns the Favourites for a given Member ID
 * @async
 * @param {string} memberId The member ID of the user you want to return
 * @returns {Promise<object>} The favourites object
 */
export const getFavourites = (
  memberId: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getAboutMeFavourites = configuredAWSLamda();
      const requestBody = {id: memberId, isDemoProfile};
      const functionName = 'getAboutMeFavourites';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.ABOUTME;
      const params = buildLambda(requestBody, functionName, serviceName);
      getAboutMeFavourites.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/*
 * Updates the Favourites for a given Member ID
 * @async
 * @param {string} memberId The member ID of the user you want to return
 * @param {object} request The object containing the updated details
 * @returns {Promise<object>} The favourites object if successful, otherwise an error object
 */
export const updateFavourites = (
  memberId: any,
  planId: any,
  requestObj: any,
  isDemoProfile = false,
  autoHandleError = false,
) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const putAboutMeFavourites = configuredAWSLamda();
      const requestBody = {
        id: memberId,
        planId,
        isDemoProfile,
      };
      for (const k in requestObj) requestBody[k] = requestObj[k];
      const functionName = 'putAboutMeFavourites';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.ABOUTME;
      const params = buildLambda(requestBody, functionName, serviceName);
      putAboutMeFavourites.invoke(params, (err, data) => {
        if (err) {
          logger.log('The updateFavourites error is:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/*
 * Returns the Health and Wellbeing for a given Member ID
 * @async
 * @param {string} memberId The member ID of the user you want to return
 * @returns {Promise<object>} The health and wellbeing object
 */
export const getHealth = (
  memberId: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getAboutMeHealth = configuredAWSLamda();
      const requestBody = {id: memberId, isDemoProfile};
      const functionName = 'getAboutMeHealth';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.ABOUTME;
      const params = buildLambda(requestBody, functionName, serviceName);
      const startTime = new Date().getTime();
      getAboutMeHealth.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/*
 * Updates the Health and Wellbeing for a given Member ID
 * @async
 * @param {string} memberId The member ID of the user you want to return
 * @param {object} request The object containing the updated details
 * @returns {Promise<object>} The health and wellbeing object if successful, otherwise an error object
 */
export const updateHealth = (
  memberId: any,
  planId: any,
  requestObj: any,
  isDemoProfile = false,
  autoHandleError = false,
) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const putAboutMeHealth = configuredAWSLamda();
      const requestBody = {
        id: memberId,
        planId,
        isDemoProfile,
      };
      for (const k in requestObj) requestBody[k] = requestObj[k];
      const functionName = 'putAboutMeHealth';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.ABOUTME;
      const params = buildLambda(requestBody, functionName, serviceName);
      putAboutMeHealth.invoke(params, (err, data) => {
        if (err) {
          logger.log('The updateHealth error is:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};
