/*
 * Author: Brad D'Costa
 * Date: 29/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the Summary Tile Header section.
 */

import React, {Component} from 'react';
import {Image, View, StyleSheet, AppState, Platform} from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import logger from 'helpers/Logger';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as OfflineNoticeActions from '../../Actions/OfflineNoticeActions';
import {StandardTouchableOpacity, StandardText} from '../Atoms';
import {ActionButton} from './';
import CustomIcon from '../../assets/images/CustomIcon';
import {
  ScaledSheet,
  scale,
  verticalScale,
  moderateScale,
} from 'react-native-size-matters';

// Import Stylesheets
import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import {MissingInformation3X, InformationIconx3} from '../../assets/images';
import {Logger} from 'aws-amplify';

class SummaryTileHeader extends Component {
  state = {
    isConnected: true,
  };

  constructor(props: any) {
    super(props);
    this._buttonOnPress = this._buttonOnPress.bind(this);
  }

  componentDidMount() {
    NetInfo.addEventListener(this.handleConnectivityChange);
  }

  handleConnectivityChange = (isConnected: any) => {
    if (isConnected) {
      this.setState({isConnected: true});
    } else {
      this.setState({isConnected: false});
    }
  };

  _buttonOnPress = () => {
    this.props.actions.OfflineNoticeActions.shakeOfflineNotice();
  };

  render() {
    if (this.props.showButton) {
      return (
        <StandardTouchableOpacity
          accessibilityLabel={
            this.props.accessibilityLabel + ' ' + this.props.actionType
          }
          activeOpacity={0.6}
          onPress={
            this.state.isConnected ? this.props.onPress : this._buttonOnPress
          }
        >
          {this._mainComponent()}
        </StandardTouchableOpacity>
      );
    } else {
      return this._mainComponent();
    }
  }

  _mainComponent = () => {
    return (
      <View style={this._determineContainerStyle()}>
        {this._renderImage(this.props.imageSource)}
        <View
          style={[
            CommonStyles.flex1,
            CommonStyles.flexDirectionRow,
            types.isWeb ? CommonStyles.marginLeft10 : null,
            Platform.OS === types2.IOS
              ? CommonStyles.marginRight20
              : CommonStyles.marginRight40,
          ]}
        >
          <View style={CommonStyles.flexShrink1}>
            <StandardText style={this._determineTextStyle()}>
              {this.props.displayString}
            </StandardText>
          </View>
          {this._renderYellowStar()}
        </View>
        {this.props.hideActionButton ? null : (
          <View style={CommonStyles.alignFlexStart}>
            {this._renderActionButton()}
          </View>
        )}
      </View>
    );
  };

  _renderYellowStar() {
    if (!this.props.hideYellowStar && !this.props.containsData) {
      return (
        <Image
          style={[CommonStyles.yellowStar]}
          source={
            this.props.hasError ? InformationIconx3 : MissingInformation3X
          }
        />
      );
    }
  }

  _renderActionButton = () => {
    if (this.props.customAddButton) {
      return this.props.customAddButton;
    }
    if (this.props.showButton) {
      return (
        <ActionButton
          actionType={this.props.actionType}
          showButtonTitle={this.props.showButtonTitle}
          showIconString={this.props.showIconString}
          imageActionIcon={this.props.imageActionIcon}
        />
      );
    }
    return null;
  };

  _renderImage = (imageSource: any) => {
    let styles = [];
    if (this.props.iconStyle) {
      styles = this.props.iconStyle;
    } else {
      styles = [CommonStyles.titleBoxIcon, CommonStyles.titleBoxIconMargin];
    }

    styles.push(this._determineTextColor());
    if (this.props.imageIcon) {
      return (
        <Image
          accessible={false}
          source={this.props.imageSource}
          style={styles}
        />
      );
    } else {
      if (this.props.imageSource != undefined) {
        return (
          <CustomIcon accessible={false} name={imageSource} style={styles} />
        );
      } else {
        return null;
      }
    }
  };

  _determineTextColor = () => {
    const styles = [];
    if (this.props.textColor) {
      styles.push(
        this.props.secondLevel ? BrandStyles.TextColor2 : this.props.textColor,
      );
    } else {
      styles.push(BrandStyles.TextColor3);
    }
    return styles;
  };

  _determineTextStyle = () => {
    const styles = [
      CommonStyles.customFontBold,
      CommonStyles.titleBoxIconMargin,
      this._determineTextColor(),
    ];

    if (this.props.fontSize) {
      styles.push(this.props.fontSize);
    } else {
      styles.push(CommonStyles.SummaryTileHeaderText);
    }

    return styles;
  };

  _determineContainerStyle = () => {
    const styles = [
      CommonStyles.alignSelfFlexStart,
      CommonStyles.content,
      CommonStyles.flexRow,
      CommonStyles.marginBottom15,
    ];

    if (this.props.ignorePadding) {
      styles.push(CommonStyles.SummaryTileHeader);
    } else {
      styles.push(CommonStyles.SummaryTileHeaderContainer);
    }
    if (this.props.boarderColor) {
      styles.push(
        this.props.secondLevel
          ? BrandStyles.borderColor2
          : this.props.boarderColor,
      );
    } else {
      styles.push(BrandStyles.borderColor3);
    }
    if (this.props.alignItemsCenter) {
      styles.push(CommonStyles.alignItemsCenter);
    }
    return styles;
  };
}

SummaryTileHeader.defaultProps = {
  hasError: false,
};

const mapStateToProps = (state: any) => ({
  offline: state.OfflineNoticeReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    OfflineNoticeActions: bindActionCreators(OfflineNoticeActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SummaryTileHeader);
