/*
 * Author: Justin Kilimnik
 * Date: 28/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Constants for branding differences.
 */

// Company / Branding Constants
export const LEAP_IN_COMPANY_NAME = 'Leap in!';
export const HTTP = 'http:';
export const HTTPS = 'https:';
export const LEAP_IN_URL = `${HTTPS}//www.leapin.com.au/`;
// App names allow the possibility to use the app brand company thropughout the app (if different from Leap in!)
export const APP_COMPANY_NAME = LEAP_IN_COMPANY_NAME;
export const APP_COMPANY_URL = LEAP_IN_URL;
export const APP_ABOUT = `${LEAP_IN_URL}about/`;
export const APP_SIGNUP_TERMS_AND_CONDITIONS_URL = `${LEAP_IN_URL}terms/`;
export const APP_PRIVACY_POLICY = `${LEAP_IN_URL}privacy-policy/`;
export const APP_TERMS_OF_USE = `${LEAP_IN_URL}terms-of-use/`;
export const APP_PLAN_MANAGER_TERMS_AND_CONDITIONS_URL = `${LEAP_IN_URL}plan-management-terms/`;
export const APP_HELP_PDF_URL = `${LEAP_IN_URL}Leap-In_Help-Guide.pdf`;
export const APP_HELP_URL = `${LEAP_IN_URL}help`;
export const APP_CHECKLIST_URL = `${LEAP_IN_URL}checklist/`;
export const APP_SOCIAL_LOGIN_ENABLED = true;
export const APP_SOCIAL_FACEBOOK_LOGIN_ENABLED = false;
export const APP_BRAND = 'Leap in!';
export const PLAYSTORE_URL = `${HTTPS}//play.google.com/store/apps/details?id=com.planready`;
export const APPSTORE_URL = `${HTTPS}//itunes.apple.com/au/app/leap-in-ndis-planning/id1399430949?mt=8`;
export const COPYRIGHTS = '';
export const BRAND_FRIENDLY_APP_NAME = 'Leap in!';
