/*
 * Author: Shelan Cooray
 * Date: 11/12/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Component for dispay list of goal progress
 * This can be used on 'CompletedGoal' and 'UpdateGoalProgress' screens
 * to list down goal progress
 */

import React, {Component} from 'react';
import {View} from 'react-native';
import {GoalProgressCommentTile} from '../Molecules';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';

class GoalProgressList extends Component {
  constructor(props: any) {
    super(props);
  }

  state = {};

  render() {
    return (
      <View style={[CommonStyles.marginTop20]}>{this._goalProgressList()}</View>
    );
  }

  _goalProgressList = () => {
    const goalProgressList = this.props.goalProgressList;
    return goalProgressList.map((progressItem: any, index: any) => {
      return (
        <GoalProgressCommentTile key={index} progressItem={progressItem} />
      );
    });
  };
}

export default GoalProgressList;
