/*
 * Author: Brad D'Costa
 * Date: 05/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the Subtitle text for modals.
 */

import React, {Component} from 'react';
import {View} from 'react-native';
import {StandardText} from '../Atoms';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class ModalSubtitle extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <View style={[CommonStyles.ModalSubtitleContainer]}>
        {this._renderTextElements(this.props.text)}
      </View>
    );
  }

  _renderTextElements = (textArray: any) => {
    let fontStyle = CommonStyles.customFontMedium;
    if (this.props.fontStyle) {
      fontStyle = this.props.fontStyle;
    }
    return textArray.map((text: any, key: any) => (
      <StandardText
        key={key}
        style={[
          CommonStyles.ModalSubtitleText,
          fontStyle,
          BrandStyles.TextColor6,
        ]}
      >
        {text}
      </StandardText>
    ));
  };
}

export default ModalSubtitle;
