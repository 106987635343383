/*
 * Author: Anuja Ulpathakumbura
 * Date: 25/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Document add screen
 */

import React, {Component} from 'react';
import {View, Platform, AppState} from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import logger from 'helpers/Logger';
//import {NavigationActions, StackActions} from 'react-navigation';
import {CommonActions, StackActions} from '@react-navigation/native';

import {
  Container,
  FormTitle,
  BrandActivityIndicator,
  RequiredIndicator,
  BasicOverlayPopupModal,
} from '../Components/Molecules';
import {StandardText, StandardInput, StandardPicker} from '../Components/Atoms';
import {DOCUMENT_PLAN_POPUP as popupMessages} from '../Constants/Messages';
import * as DocumentListActions from '../Actions/DocumentListActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {BasicForm} from '../Components/Organisms';

import {MyDocumentsPurple4x, aboutMeIconRed} from '../assets/images';
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {callAPIs} from '../API/APICaller';
import {getDocumentList, getUploadURL} from '../API/MemberAPI';
import {callApiChecker} from '../API/APIHelper';
import {isTablet} from '../Helpers/PlatformSynchronizer';

import {RNFetchBlobSingleton} from '../Helpers/PlatformSynchronizer';

const DEFAULT_CATEGORY = 3;

class DocumentAddForm extends Component {
  state = {
    documentList: [],
    loading: true,
    filePickerResponse: {},
    fileName: '',
    categories: types2.DOC_UPLOAD.CATEGORIES,
    documentCategory: 10,
    documentCategoryDisplay: 'Plan - Current',
    saveApiError: false,
    isConnected: true,
    hasChanged: false,
    docPopUpVisible: false,
  };
  subscription: any;
  constructor(props: any) {
    super(props);
    this._createMainContents.bind(this);
    this.getDocumentTypeValue.bind(this);
    this._closeFileUploadForm.bind(this);
    this._saveFileUploadForm.bind(this);
    this.handleConnectivityChange.bind(this);
    this._renderSaveStyles.bind(this);
    this._getDocumentList.bind(this);
    this._showPlanUploadAlert.bind(this);
  }

  handleConnectivityChange = (isConnected: any) => {
    this.setState({isConnected: isConnected});
  };

  _handleAppStateChange = (nextAppState: any) => {
    if (nextAppState) {
      NetInfo.fetch().then(isConnected => {
        this.setState({isConnected: isConnected});
      });
    }
  };

  UNSAFE_componentWillMount() {
    if (this.props.navigationParams.params.isFromHealthwellbeing) {
      this.setState({
        documentCategory: DEFAULT_CATEGORY,
      });
    }
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  componentDidMount() {
    NetInfo.addEventListener(this.handleConnectivityChange);

    this.subscription=AppState.addEventListener('change', this._handleAppStateChange);

    this.setState({
      filePickerResponse: this.props.navigationParams.params.filePickerResponse,
      fileName: this.props.navigationParams.params.filePickerResponse.fileName,
      loading: false,
    });
  }

  _saveFileUploadForm = () => {
    this._fileUploadSaveButton();
  };

  _closeFileUploadForm = () => {
    if (types.isWeb) {
      this.props.navigation.goBack();
    } else {
      const actionToDispatch = CommonActions.reset({
        index: 1,
        routes: [
          {name: 'Profile'},
          {name: 'Documents'},
        ],
      });
      this.props.navigationParams.params.isFromHealthwellbeing
        ? this.props.navigation.goBack()
        : this.props.navigation.dispatch(actionToDispatch);
    }
  };

  _showPlanUploadAlert = () => {
    let messageFontSize = CommonStyles.font18;
    let headerFontSize = CommonStyles.font20;
    if (types.isWeb) {
      messageFontSize = CommonStyles.font15;
      headerFontSize = CommonStyles.font18;
    } else if (isTablet()) {
      messageFontSize = CommonStyles.font18;
      headerFontSize = CommonStyles.font25;
    }

    return (
      <BasicOverlayPopupModal
        visible={this.state.docPopUpVisible}
        style={this._determinePopUpStyle(isTablet)}
        headerText={popupMessages.TITLE}
        disableInfoImg={false}
        headerStyle={[
          headerFontSize,
          CommonStyles.customFontBold,
          BrandStyles.TextColor10,
        ]}
        backdropOpacity={0.2}
        divider={true}
        close={this._closeAlertMessage}
        cancelContainerStyle={CommonStyles.popUpOkButton}
        cancelStyle={[CommonStyles.buttonPopupOk, CommonStyles.customFontBold]}
        cancelLabel={types2.OK_GOT_IT}
      >
        <View>
          <StandardText
            style={[
              messageFontSize,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
              CommonStyles.textAlignCentre,
            ]}
          >
            {popupMessages.MESSAGE}
          </StandardText>
        </View>
      </BasicOverlayPopupModal>
    );
  };

  _closeAlertMessage = () => {
    this.setState({docPopUpVisible: false});
    this._closeFileUploadForm();
  };

  _determinePopUpStyle = (isTablet: any) => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      if (this.props.isMobile) {
        styles.push([CommonStyles.containerInstructionPopUpWebMobileLarge]);
        return styles;
      }
      styles.push([CommonStyles.containerInstructionPopUpWeb]);
    } else if (isTablet()) {
      styles.push(CommonStyles.containerInstructionPopUpTablet);
    } else {
      styles.push(CommonStyles.containerInstructionPopUpMobile);
    }
    return styles;
  };

  getDocumentTypeValue() {
    return this.state.categories.find(category => {
      return category.type === this.state.documentCategory;
    });
  }

  _fileUploadSaveButton() {
    const validateFields = this.validateFields();
    if (!validateFields) {
      return;
    }

    let filePickerResponse = this.state.filePickerResponse;
    let requestObject = {
      fileType: types2.FILE_TYPE.DOCUMENT,
      filename: this.state.fileName,
      contentType: filePickerResponse.type,
      documentCategory: this.getDocumentTypeValue().value,
    };

    let getUploadURLCallbackFunction = (data: any) => {
      let url = data[0].content.uploadURL;
      if (!types.isWeb) {
        var cleanUri = '';
        if (Platform.OS === types2.IOS) {
          cleanUri = decodeURI(filePickerResponse.path.replace('file://', ''));
        } else if (Platform.OS === types2.ANDROID) {
          cleanUri = filePickerResponse.path;
        }
        RNFetchBlobSingleton.fetch(
          'PUT',
          url,
          {'Content-Type': filePickerResponse.type},
          RNFetchBlobSingleton.wrap(cleanUri),
        )
          .uploadProgress((written, total) => {})
          .then(filePickerResponse => {
            this._setDocumentToRedux(url, requestObject);
            this.setState({loading: false});
            if (!this.state.docPopUpVisible) {
              this._closeFileUploadForm();
            }
          })
          .catch(error => {
            logger.log('The error is:', error);
            this.setState({
              saveApiErrorCode: null,
              saveApiError: true,
              loading: false,
            });
          });
      } else {
        fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': filePickerResponse.type,
          },
          body: filePickerResponse,
        })
          .then(response => {
            this._setDocumentToRedux(url, requestObject);
            this.props.setLoading(false);
            if (!this.state.docPopUpVisible) {
              this._closeFileUploadForm();
            }
          })
          .catch(error => {
            logger.log('The error is:', error);
            this.props.setLoading(false);
            this.setState({
              saveApiErrorCode: null,
              saveApiError: true,
            });
          });
      }
      if (
        this.getDocumentTypeValue().value === types2.PLAN_NEW ||
        this.getDocumentTypeValue().value === types2.PLAN_OLD
      ) {
        this.setState({docPopUpVisible: true});
      }
    };

    const getUploadURLErrorCallbackFunction = (err: any) => {
      logger.log('Documents upload file error calling: ', err);
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
        loading: false,
      });
    };

    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({loading: true, saveApiError: false});
    callAPIs(
      [getUploadURL(this.props.loadedMemberId.loadedMemberId, requestObject)],
      getUploadURLCallbackFunction,
      getUploadURLErrorCallbackFunction,
    );
  }

  _setDocumentToRedux = (url: any, requestObject: any) => {
    const date = new Date();
    const currentTimeDate = date.toISOString();
    const newDocument = {
      url,
      filename: requestObject.filename,
      lastModified: currentTimeDate,
      category: requestObject.documentCategory,
    };
    this.setState({
      selectedFile: newDocument,
    });
    const documentList = this.props.documentList.documentList;
    documentList.push(newDocument);
    this.props.actions.DocumentListActions.actionGetDocumentList(documentList);
  };

  _getDocumentList = (force: any) => {
    let callbackFunction = (data: any) => {
      var documentList = data[0].content;
      this.props.actions.DocumentListActions.actionGetDocumentList(
        documentList.documents,
      );
      var reduxDocumentList = this.props.documentList.documentList;
      var reduxDocumentListOriginal = reduxDocumentList.slice();
      this.setState({
        documentList: reduxDocumentList,
        searchDocuments: reduxDocumentList,
        documentListOriginal: reduxDocumentListOriginal,
        documentListLength: reduxDocumentList.length,
        loading: false,
      });
      this._getDocumentList(true);
    };

    if (callApiChecker(this.props.documentList.apiCalled) || force) {
      callAPIs(
        [getDocumentList(this.props.loadedMemberId.loadedMemberId)],
        callbackFunction,
        null,
        () => {
          this.setState({loading: false});
        },
      );
    } else {
      var reduxDocumentList = this.props.documentList.documentList;
      var reduxDocumentListOriginal = reduxDocumentList.slice();
      this.setState({
        documentList: reduxDocumentList,
        searchDocuments: reduxDocumentList,
        documentListOriginal: reduxDocumentListOriginal,
        documentListLength: reduxDocumentList.length,
        loading: false,
      });
    }
  };
  _renderSaveStyles = () => {
    return {
      style: [
        BrandStyles.brandBlockTxtColor5,
        CommonStyles.buttonFormAction,
        CommonStyles.customFontBold,
      ],
      containerStyle: [
        BrandStyles.primaryBgColor4,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerBudget,
      ],
    };
  };

  validateFields = () => {
    var validateErrors = {
      DocumentNameError: '',
    };

    if (this.state.fileName == '' || this.state.fileName == undefined) {
      validateErrors.DocumentNameError = types.DOCUMENT_NAME_NOT_ENTERED;
    }

    // Set the state to show the validation on the components
    this.setState({
      validDocumentName: validateErrors.DocumentNameError,
    });

    // Now return if the there are valid fields
    for (let [key, value] of Object.entries(validateErrors)) {
      if (value != '') {
        return false;
      }
    }
    return true;
  };

  _createMainContents = () => (
    <BasicForm
      headerDisplayString={types2.ADD_DOCUMENT}
      // headerIconImage={aboutMeIconRed}
      save={this._saveFileUploadForm}
      saveStyle={this._renderSaveStyles()}
      close={this._closeFileUploadForm}
      disableMore={true}
      showButton={false}
      readOnly={this.props.member.access.documents}
      buttonsPositionBottom={true}
      contentWidthFull={true}
    >
      <View>
        <FormTitle
          containsData={this.state.fileName}
          text={types2.DOCUMENT_NAME}
          style={[
            CommonStyles.customFontBold,
            CommonStyles.AboutMeModalLabel,
            types.isWeb ? CommonStyles.font15 : null,
          ]}
        />
        <RequiredIndicator />
        <StandardInput
          value={this.state.fileName}
          ref="changefilename"
          placeHolder="Filename"
          onChangeText={(changefilename: any) =>
            this.setState({
              fileName: changefilename,
            })
          }
          blurOnSubmit={true}
          style={[
            CommonStyles.ModalTextInputWrapper,
            BrandStyles.primaryBgColor1,
          ]}
          underlineColorAndroid="transparent"
          errorMessage={this.state.validDocumentName}
        />
        <FormTitle
          containsData={true}
          text={types2.DOCUMENT_CATEGORY}
          style={[
            CommonStyles.customFontBold,
            CommonStyles.AboutMeModalLabel,
            types.isWeb ? CommonStyles.font15 : null,
          ]}
        />
        <View
          style={[
            CommonStyles.ModalTextInputContainer,
            CommonStyles.ModalPickerInputContainer,
          ]}
        >
          <View style={[CommonStyles.PickerBackground]}>
            <StandardPicker
              style={[
                CommonStyles.PickerInputContainer,
                CommonStyles.pickerBackgroundColorGray,
              ]}
              selectedValue={this.state.documentCategory}
              pickerOptions={this.state.categories}
              onValueChange={(itemValue: any, itemIndex: any) =>
                this.setState({documentCategory: parseInt(itemValue)})
              }
            />
          </View>
        </View>
      </View>
      {this._showPlanUploadAlert()}
    </BasicForm>
  );

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        needsSideBar={false}
        screenType={types.SCREEN_TYPE_MAIN}
        activeScreen={types.SCREEN_DOCUMENTS}
        selectedIcon={types.SCREEN_DOCUMENTS}
        nav={this.props.navigation}
        loading={this.state.loading}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        toggleMenu={this.props.screenProps?.toggleMenu}
        headerTitle={types2.NAVIGATION_TEXT.DOCUMENTS}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }
}
const mapStateToProps = (state: any) => ({
  loadedMemberId: state.LoadedMemberReducer,
  documentList: state.DocumentListReducer,
  member: state.MemberReducer,
  navigationParams: state.NavigationParamsReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    DocumentListActions: bindActionCreators(DocumentListActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentAddForm);
