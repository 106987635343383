/*
 * Author: Andrew Seeley
 * Date: 20/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The screen to sign up and put in your profile details.
 */

import React, {Component} from 'react';
import {
  StyleSheet,
  Image,
  View,
  Keyboard,
  Animated,
  Dimensions,
  Alert,
  Platform,
  AppState,
  Linking,
} from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import {KeyboardAwareScrollView} from 'helpers/KeyboardAwareScrollView';
// import CheckBox from 'react-native-checkbox';
// import {NavigationActions} from 'react-navigation-awseeley--enhanced';
import {StackActions} from '@react-navigation/native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import SocialSignUpButton from '../Components/SocialSignUpButton';
import {BrandActivityIndicator} from '../Components/Molecules/BrandActivityIndicator';
import {DataSharingConsentModal, FormSubtitle} from '../Components/Molecules';
import {storeData} from '../API/StorageHelper';
import {setValue} from '../API/WebPersistenceStore';
import {
  isTablet,
  firebaseAnalysisLogEvent,
  announceForAccessibility,
  focusOnView,
} from '../Helpers/PlatformSynchronizer';
import {getValue} from '../API/WebPersistenceStore';

import {
  signUpCognito,
  loginCognito,
  cognitoPasswordChallenge,
} from '../API/AuthenticationAPI';
import {routeTolanding} from '../Components/RouteToLanding/RouteToLanding';
import {requestPersonAccess} from '../API/CrewAPI';
import {putTemporaryPassword} from '../API/UserAPI';
import {removeItem} from '../API/StorageHelper';
import {getParticipants} from '../API/PersonAPI';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {
  ERRORS,
  COMMON_BUTTONS,
  LOGIN_SIGNUP_SCREEN,
  POPUP_TITLES,
  PREAUTHENTICATION_SCREEN,
} from '../Constants/Messages';
import {RNkeychain as Keychain} from '../Helpers/PlatformSynchronizer';
import * as AuthenticationActions from '../Actions/AuthenticationActions';
import * as loadedMemberActions from '../Actions/LoadedMemberActions';
import * as MemberActions from '../Actions/MemberActions';
import * as UserActions from '../Actions/UserActions';
import * as AdminActions from '../Actions/AdminActions';
import * as OfflineNoticeActions from '../Actions/OfflineNoticeActions';

import {
  StandardInput,
  StandardButton,
  StandardText,
  StandardTouchableOpacity,
  BackButton,
  SecureStandardInput,
} from '../Components/Atoms';
import {OfflineNotice, DeepLinkBanner} from '../Components/Organisms';
import BasicPopupAlert from '../Components/Organisms/BasicPopupAlert';

import {focusNextField} from '../Components/FocusNextField/FocusNextField';
import {getStoreUrl} from '../Helpers/Utils';

//import DeviceInfo from "react-native-device-info";

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {BrandIcons} from '../Styles/BrandIcons';
import * as brand from '../Constants/BrandConstants';

//Import Images

import {GooglePlayBadge, AppleBadge, LILogoWhiteText,UncheckedBoxPurple2x, CheckedBoxPurple2x} from '../assets/images';
import {RefreshIcon} from '../assets/images/vector-icons';

// Import messages
import {
  FORGOT_PASSWORD as forgotPwdMessages,
  ACCESSIBILITY_SCREEN_TITLES,
  MESSAGES as Messages,
} from '../Constants/Messages';

import {APP_BRAND} from '../Constants/BrandConstants';
import {APP_ID, GOOGLE_CAPTCHA_CLIENT_KEY} from '../environments';

// import Orientation from 'react-native-orientation-enhanced';

import logger from 'helpers/Logger';

import {logUserOut} from '../API/PreauthenticationHelper';

import CustomIcon from '../assets/images/CustomIcon';

import ReCAPTCHA from 'react-google-recaptcha';

const recaptchaRef = React.createRef();
// Get the device height & width
let ScreenHeight = Dimensions.get('window').height;
let ScreenWidth = Dimensions.get('window').width;

// Setup animations
const animationValue = new Animated.Value(0);

// Maps the animation values to start from the top of the screen out of view and finish at the bottom of the screen in view
const animateTopToBottom = animationValue.interpolate({
  inputRange: [0, 1],
  outputRange: [-ScreenHeight, 0.0],
});
// Maps the animation values to start from the bottom of the screen out of view and finish at the top of the screen in view
const animateBottomToTop = animationValue.interpolate({
  inputRange: [0, 1],
  outputRange: [ScreenHeight, 0.0],
});

const INPUTS = {
  SCROLL_VIEW: 'scrollView',
  FIRST_NAME: 'firstName',
  PREFERRED_NAME: 'knownas',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  PASSWORD: 'password',
};

const LOGINSIGNUP_ROUTE = 'Welcome';

let buttonLoginSignInText: any,
  screenMode: any,
  isSignUp: any,
  headerContent,
  showForgotPassword,
  showTermsAndC,
  signUpType;

export class LoginSignUp extends Component {
  goBackbtn: any;
  inputs: any;
  keyboardWillHideListener: any;
  keyboardWillShowListener: any;
  loggedInRef: any;
  mainTitle: any;
  scroll: any;
  termsAndCAccepted: any;
  subscription: any;
  UNSAFE_componentWillMount() {
    // Check if the mode is signup since both lognin and signup are in the same screen
    const cognitoId = getValue('awsIdentity');
    if (cognitoId) {
      this.props.navigation.navigate('Home');
    } else {
      this.props.navigation.navigate(LOGINSIGNUP_ROUTE);
    }

    if (
      !types.isWeb &&
      this.props.navigation.getParam('screenMode', undefined) ===
        types.LOGINSIGNUP_MODE_SIGNUP
    ) {
      firebaseAnalysisLogEvent(
        types.FIREBASE_ANALYTICS_EVENTS.SIGNUP_LANDING,
        {},
      );
      // Orientation.getOrientation((err: any, orientation: any) => {
      //   this.setState({
      //     orientation,
      //   });
      // });
    } else {
      const cognitoId = getValue('awsIdentity');
      if (cognitoId) {
        this.props.navigation.navigate('Home');
      }
    }
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation,
    });
  };

  componentDidMount() {
    // To clear the redux since PreAuthentication screen is no longer using with the new signup flow.
    this.props.actions.authActions.actionLogout();
    NetInfo.addEventListener(this.handleConnectivityChange);
    NetInfo.fetch().then(isConnected => {
      this.setState({isConnected});
    });
    // if (!types.isWeb) {
    //   Orientation.addOrientationListener(this._orientationDidChange);
    // }
    this.keyboardWillShowListener = Keyboard.addListener(
      'keyboardWillShow',
      this.keyboardWillShow,
    );
    this.keyboardWillHideListener = Keyboard.addListener(
      'keyboardWillHide',
      this.keyboardWillHide,
    );
    this.subscription=AppState.addEventListener('change', this._handleAppStateChange);

    let accessibility_title = '';
    const {screenMode, signUpType} = ''; //this.props.navigation.state.params;
    if (screenMode === types.LOGINSIGNUP_MODE_LOGIN) {
      accessibility_title = ACCESSIBILITY_SCREEN_TITLES.SIGNIN;
    } else if (screenMode === types.LOGINSIGNUP_MODE_SIGNUP) {
      accessibility_title = ACCESSIBILITY_SCREEN_TITLES.CREATE_ACCOUNT;
      if (signUpType === types.SIGNUP_TYPE_CREW) {
        accessibility_title = ACCESSIBILITY_SCREEN_TITLES.SIGNIN_CREW;
      } else if (signUpType === types.SIGNUP_TYPE_PARTICIPANT) {
        accessibility_title = ACCESSIBILITY_SCREEN_TITLES.SIGNIN_PARTICIPANT;
      }
    }
    if (accessibility_title !== '') {
      announceForAccessibility(accessibility_title);
    }

    this.dispatchFocusInOrder();
  }

  componentWillUnmount() {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
    this.keyboardWillShowListener.remove();
    this.keyboardWillHideListener.remove();
    // AppState.removeEventListener('change', this._handleAppStateChange);
    this.subscription.remove();
  }

  dispatchFocusInOrder() {
    setTimeout(
      function (this: any) {
        const latestItem = [this.goBackbtn, this.mainTitle]
          .filter(item => item !== undefined && item != null)
          .pop();
        if (latestItem && !this.props.loading) {
          focusOnView(latestItem);
        }
      }.bind(this),
      10,
    );
  }

  _handleAppStateChange = (nextAppState: any) => {
    if (nextAppState) {
      NetInfo.fetch().then(isConnected => {
        this.setState({isConnected: isConnected});
      });
    }
  };

  handleConnectivityChange = (isConnected: any) => {
    this.setState({isConnected});
  };

  keyboardWillShow() {
    if (Platform.OS === types2.IOS && isTablet()) {
      this.scroll.props.scrollToPosition(0, 280);
    }
  }

  keyboardWillHide() {
    if (Platform.OS === types2.IOS && isTablet()) {
      this.scroll.props.scrollToPosition(0, 0);
    }
  }

  state = {
    firstname: '',
    lastname: '',
    knownas: '',
    email: '',
    password: '',
    termsandconditionsaccepted: false,
    loading: false,
    firstNameError: '',
    knownasError: '',
    lastNameError: '',
    emailError: '',
    passwordError: '',
    termsandconditionsacceptedError: '',
    passwordChallenge: false,
    newPassword: '',
    newPasswordError: '',
    cognitoUserAttributes: {},
    cognitoUser: {},
    displayTermsandCErrorMessage: true,
    isConnected: true,
    isFirstTimeLogin: false,
    orientation: '',
    showDataSharingConsentModal: false,
    dataSharingConsentModalRoutingObject: {},
    errorPopUpVisible: false,
    temporaryPasswordExpiredMessageVisible: false,
    showPlanReadyError: false,
    captchToken: null,
  };

  headerLine1 = '';
  headerLine2 = '';

  constructor(props: any) {
    super(props);
    this.signUp = this.signUp.bind(this);
    this.loginSignUpUser = this.loginSignUpUser.bind(this);
    this.loginUser = this.loginUser.bind(this);
    this.inputs = {};
    this.backNavigation = this.backNavigation.bind(this);
    this._closeAlert = this._closeAlert.bind(this);
    this.keyboardWillShow = this.keyboardWillShow.bind(this);
    this.keyboardWillHide = this.keyboardWillHide.bind(this);
    this._showAlert = this._showAlert.bind(this);
  }

  /*
   * Changes the focus to the given field
   * @param {string} nextField The ref name of the input on the next field.
   */
  focusNextField(nextField: any) {
    if (this.inputs[nextField].focus) {
      this.inputs[nextField].focus();
    }
  }

  //check for internet connectivity before navigating to Webview
  _navigationCallback = (navScreen: any) => {
    if (this.state.isConnected) {
      this.props.navigation.navigate(navScreen);
    } else {
      this._showAlert(
        types2.ALERT_TITLE,
        types2.CONNECTION_ERROR,
        types2.OK_GOT_IT,
        this._closeAlert,
        true,
        false,
      );
    }
  };

  _renderForgotPassword(showForgotPassword: any) {
    // Determine if need to display a button that will give the users an ability to reset password due to being forgotten
    if (showForgotPassword && !this.state.passwordChallenge) {
      return (
        <StandardButton
          accessibilityLabel={
            forgotPwdMessages.BUTTON_TEXT_1 + forgotPwdMessages.BUTTON_TEXT_2
          }
          style={[
            CommonStyles.textAlignCentre,
            CommonStyles.buttonText,
            BrandStyles.TextColor2,
            CommonStyles.LoginSignUpP10,
            CommonStyles.rpfont12,
            CommonStyles.customFont,
          ]}
          containerStyle={[
            CommonStyles.LoginSignUpMT10,
            true ? CommonStyles.LoginSignUpMB10 : null,
          ]}
          onPress={this.forgotPassword.bind(this)}
        >
          <View
            style={[
              CommonStyles.flexDirectionRow,
              CommonStyles.alignFlexStart,
              CommonStyles.content,
              CommonStyles.leftMargin10,
            ]}
          >
            <View>
              <StandardText
                style={[
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                  types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont12,
                ]}
              >
                {forgotPwdMessages.BUTTON_TEXT_1}
              </StandardText>
            </View>
            <View>
              <StandardText
                style={[
                  BrandStyles.TextColor5,
                  styles.termsAndConditions,
                  styles.termsAndConditionsLine3,
                  types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont12,
                  CommonStyles.customFontBold,
                ]}
              >
                {forgotPwdMessages.BUTTON_TEXT_2}
              </StandardText>
            </View>
          </View>
        </StandardButton>
      );
    }
  }

  _renderTemporaryPasswordExpiredMessage() {
    const _getTemporaryPasswordExpiredModalStyles = () => {
      const styles = [
        CommonStyles.containerRoundCorners,
        CommonStyles.alignSelfCenter,
      ];
      if (types.isWeb) {
        styles.push([CommonStyles.containerInstructionPopUpWeb]);

        if (this.props.isMobile) {
          styles.push([CommonStyles.containerPopupWebMobile]);
        }
      } else if (isTablet()) {
        styles.push([
          CommonStyles.containerInstructionPopUpTablet,
          CommonStyles.minHeight300,
        ]);
      } else {
        styles.push([
          CommonStyles.containerInstructionPopUpMobile,
          CommonStyles.minHeight230,
        ]);
      }
      return styles;
    };

    const _temporaryPasswordExpiredModalOkAction = () => {
      this.setState(
        {temporaryPasswordExpiredMessageVisible: false, loading: true},
        () =>
          putTemporaryPassword(this.state.email)
            .then(() => {
              this._showAlert(
                LOGIN_SIGNUP_SCREEN.TEMP_PASSWORD_SENT_MODAL_HEADER,
                LOGIN_SIGNUP_SCREEN.TEMP_PASSWORD_SENT_MODAL_MESSAGE,
                types2.OK_GOT_IT,
                this._closeAlert,
                true,
                false,
              );
              this.setState({loading: false});
            })
            .catch(err => {
              logger.log('putTemporaryPassword error ', err);
              this.setState({loading: false});
            }),
      );
    };

    const _temporaryPasswordExpiredModalCancelAction = () =>
      this.setState({
        temporaryPasswordExpiredMessageVisible: false,
      });

    return (
      <BasicPopupAlert
        visible={this.state.temporaryPasswordExpiredMessageVisible}
        close={_temporaryPasswordExpiredModalCancelAction}
        style={_getTemporaryPasswordExpiredModalStyles()}
        cancelContainerStyle={[
          BrandStyles.primaryBgColor1,
          BrandStyles.brandBorderColor1,
          CommonStyles.buttonContainerFormAction,
        ]}
        cancelStyle={[
          BrandStyles.brandBlockTxtColor6,
          CommonStyles.buttonFormAction,
          CommonStyles.customFontBold,
        ]}
        cancelLabel={LOGIN_SIGNUP_SCREEN.TEMP_PASSWORD_EXPIRED_MODAL_CANCEL}
        headerText={LOGIN_SIGNUP_SCREEN.TEMP_PASSWORD_EXPIRED_MODAL_HEADER}
        messageText={LOGIN_SIGNUP_SCREEN.TEMP_PASSWORD_EXPIRED_MODAL_MESSAGE}
        saveAction={_temporaryPasswordExpiredModalOkAction}
        saveLabel={LOGIN_SIGNUP_SCREEN.TEMP_PASSWORD_EXPIRED_MODAL_OK}
        isMobile={this.props.isMobile}
      />
    );
  }

  _renderDontHaveAccount() {
    if (
      screenMode === types.LOGINSIGNUP_MODE_LOGIN &&
      !this.state.passwordChallenge
    ) {
      return (
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.flexWrap,
            CommonStyles.alignCenter,
            CommonStyles.marginBottom20,
          ]}
          accessible={true}
          accessibilityLabel={
            LOGIN_SIGNUP_SCREEN.DONT_HAVE_ONE +
            ' ' +
            LOGIN_SIGNUP_SCREEN.HERE +
            ' ' +
            types2.COMPONENT_TYPES.BUTTON
          }
        >
          <StandardTouchableOpacity
            onPress={() => {
              this.props.navigation.navigate('LoginSignUp', {
                screenMode: types.LOGINSIGNUP_MODE_SIGNUP,
                enableBack: true,
              });
              this.setState({loading: false});
            }}
          >
            <View style={[CommonStyles.flex1, CommonStyles.flexDirectionRow]}>
              <StandardText
                style={[
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                  types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont12,
                ]}
              >
                {LOGIN_SIGNUP_SCREEN.DONT_HAVE_ONE}
              </StandardText>
              <StandardText
                style={[
                  BrandStyles.TextColor5,
                  CommonStyles.textLink,
                  types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont12,
                  CommonStyles.customFontBold,
                ]}
              >
                {LOGIN_SIGNUP_SCREEN.HERE}
              </StandardText>
            </View>
          </StandardTouchableOpacity>
        </View>
      );
    }
  }

  getMarginRightLeft2p() {
    return isTablet() ? CommonStyles.marginRightLeft2p : null;
  }

  getTermsOfuseText() {
    return !isTablet() ? CommonStyles.termsOfuseText : null;
  }

  renderLoginSignUpButton(showTermsAndC: any) {
    let termsAndC;
    if (showTermsAndC) {
      let termsAndCLabel = '';

      if (this.termsAndCAccepted) {
        termsAndCLabel = ACCESSIBILITY_SCREEN_TITLES.SELECTED;
      } else {
        termsAndCLabel = ACCESSIBILITY_SCREEN_TITLES.NOT_SELECTED;
      }

      termsAndC = (
        <View style={[CommonStyles.flex1, CommonStyles.alignCenter]}>
          <View
            style={[
              CommonStyles.alignCenter,
              CommonStyles.alignSelfCenter,
              CommonStyles.flexDirectionRow,
              CommonStyles.marginBottom5,
              this.getMarginRightLeft2p(),
            ]}
          >
            <View
              style={[CommonStyles.termsOfuseBox]}
              accessible={true}
              accessibilityLabel={termsAndCLabel}
            >
              {/* <CheckBox
                checkboxStyle={
                  this.state.displayTermsandCErrorMessage
                    ? []
                    : [
                        CommonStyles.CheckBoxBorderWidth,
                        BrandStyles.errorBorder,
                      ]
                }
                label=""
                checkedImage={CheckedBoxPurple2x}
                uncheckedImage={UncheckedBoxPurple2x}
                onChange={() => {
                  this.setState({
                    termsandconditionsaccepted:
                      !this.state.termsandconditionsaccepted,
                  });
                  this.termsAndCAccepted =
                    !this.state.termsandconditionsaccepted;
                }}
                value={this.state.termsandconditionsaccepted}
              /> */}
            </View>
            <View
              style={[
                this.getTermsOfuseText(),
                CommonStyles.flexDirectionRow,
                CommonStyles.flexWrap,
              ]}
              accessible={true}
              accessibilityLabel={
                LOGIN_SIGNUP_SCREEN.TERMS_OF_USE_TEXT_1 +
                LOGIN_SIGNUP_SCREEN.TERMS_OF_USE_TEXT_2 +
                types2.COMPONENT_TYPES.BUTTON
              }
            >
              <StandardTouchableOpacity
                onPress={() =>
                  APP_ID === types2.APP_NAME.WESLEY
                    ? Linking.openURL(brand.APP_SIGNUP_TERMS_AND_CONDITIONS_URL)
                    : this._navigationCallback('TermsAndConditions')
                }
                accessibilityLabel={LOGIN_SIGNUP_SCREEN.TERMS_OF_USE_TEXT_2}
              >
                <View
                  style={[
                    CommonStyles.flexDirectionRow,
                    CommonStyles.marginBottom5,
                  ]}
                >
                  <StandardText
                    style={[
                      BrandStyles.TextColor5,
                      types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont12,
                      CommonStyles.customFont,
                      CommonStyles.alignItemsCenter,
                    ]}
                  >
                    {LOGIN_SIGNUP_SCREEN.TERMS_OF_USE_TEXT_1}
                  </StandardText>
                  <StandardText
                    style={[
                      BrandStyles.TextColor5,
                      CommonStyles.termsAndConditionsLine3,
                      types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont12,
                      CommonStyles.customFontBold,
                      CommonStyles.alignItemsCenter,
                    ]}
                  >
                    {LOGIN_SIGNUP_SCREEN.TERMS_OF_USE_TEXT_2}
                  </StandardText>
                </View>
              </StandardTouchableOpacity>
            </View>
          </View>
          <View>
            {this._showTermsandCError(this.state.displayTermsandCErrorMessage)}
          </View>
        </View>
      );
    } else {
      termsAndC = null;
    }

    if (isSignUp) {
      return (
        <View
          style={[
            CommonStyles.rpMarginTop5p,
            CommonStyles.flex1,
            CommonStyles.LoginSignUpMB20,
            CommonStyles.alignItemsCenter,
          ]}
        >
          {termsAndC}
          <View style={CommonStyles.marginTop10}>
            <StandardButton
              style={[
                types.isWeb ? CommonStyles.font18 : CommonStyles.rpfont22MS,
                CommonStyles.customFont,
                CommonStyles.textAlignCentre,
                CommonStyles.fontWeightBold,
                BrandStyles.brandBlockTxtColor6,
              ]}
              containerStyle={[
                BrandStyles.primaryBgColor1,
                CommonStyles.elevation3,
                BrandStyles.imageBorderColor,
                CommonStyles.borderRadius5,
                CommonStyles.borderWidth1,
                CommonStyles.rploginsingupBtnWidth,
                CommonStyles.padding10,
              ]}
              onPress={() => this.loginSignUpUser(true)}
            >
              {buttonLoginSignInText}
            </StandardButton>
          </View>
        </View>
      );
    } else {
      return (
        <View
          style={[
            CommonStyles.rpMarginTop5p,
            CommonStyles.flex1,
            CommonStyles.LoginSignUpMB20,
            CommonStyles.alignItemsCenter,
          ]}
        >
          {termsAndC}
          <StandardButton
            style={[
              types.isWeb ? CommonStyles.font18 : CommonStyles.rpfont22MS,
              CommonStyles.customFont,
              CommonStyles.textAlignCentre,
              CommonStyles.fontWeightBold,
              BrandStyles.brandBlockTxtColor6,
            ]}
            containerStyle={[
              BrandStyles.primaryBgColor1,
              CommonStyles.elevation3,
              BrandStyles.imageBorderColor,
              CommonStyles.borderRadius5,
              CommonStyles.borderWidth1,
              CommonStyles.rploginsingupBtnWidth,
              CommonStyles.padding10,
            ]}
            onPress={() => this.loginSignUpUser(false)}
          >
            {buttonLoginSignInText}
          </StandardButton>
        </View>
      );
    }
  }

  _renderPasswordInputField(screenMode: any, passwordRequirements: any) {
    if (screenMode != types.LOGINSIGNUP_MODE_REQUESTACCESS) {
      return (
        <View key="passwordContainer" style={[styles.textInput]}>
          <FormSubtitle text={['Password']} />
          <SecureStandardInput
            onFocus={() => {
              if (isTablet()) {
                this.scroll.props.scrollToPosition(0, 280);
              }
            }}
            applyWrapper={false}
            key="password"
            accessibilityLabel="Password"
            onRef={(ref: any) => {
              this.inputs[INPUTS.PASSWORD] = ref;
            }}
            value={this.state.password}
            onChangeText={(password: any) => this.setState({password})}
            blurOnSubmit={true}
            secureTextEntry={true}
            style={[
              CommonStyles.ModalTextInputWrapper,
              this.state.passwordError ? CommonStyles.LoginSignUpMB10 : null,
            ]}
            noMargin={true}
            underlineColorAndroid="transparent"
            errorMessage={this.state.passwordError}
            onSubmitEditing={() =>
              types.isWeb ? this.loginSignUpUser(false) : null
            }
          />
          {!this.state.passwordChallenge ? passwordRequirements : null}
        </View>
      );
    }
  }

  _renderNewPasswordInputField(screenMode: any, passwordRequirements: any) {
    // If it isn't request access we need the password for email sign in & sign up
    if (screenMode != types.LOGINSIGNUP_MODE_REQUESTACCESS) {
      // There is a password challenge, so add a new field for the user to enter a new password
      if (this.state.passwordChallenge) {
        return (
          <View
            key="newPasswordContainer"
            style={[styles.textInput, {marginBottom: 2}]}
          >
            <FormSubtitle text={['New Password']} />
            <SecureStandardInput
              onFocus={() => {
                if (isTablet()) {
                  this.scroll.props.scrollToPosition(0, 280);
                }
              }}
              applyWrapper={false}
              key="newPassword"
              accessibilityLabel="New Password"
              ref="newPassword"
              value={this.state.newPassword}
              onChangeText={(newPassword: any) => this.setState({newPassword})}
              blurOnSubmit={true}
              secureTextEntry={true}
              style={[CommonStyles.ModalTextInputWrapper]}
              noMargin={true}
              underlineColorAndroid="transparent"
              errorMessage={this.state.newPasswordError}
            />
            {this.state.passwordChallenge ? passwordRequirements : null}
          </View>
        );
      }
    }
  }

  renderInputFields(screenMode: any) {
    const signUpFields = [];
    let passwordRequirements;
    if (screenMode == types.LOGINSIGNUP_MODE_SIGNUP) {
      signUpFields.push(
        // Wrap the sign up fields in an array to ensure the parent flex spacing still works (If we wrap this all in a view it breaks this)
        [
          <View
            key="fNamelNameContainer"
            style={[
              CommonStyles.flex1,
              this.state.orientation === types2.ORIENTATION.LANDSCAPE &&
              screenMode === types.LOGINSIGNUP_MODE_SIGNUP
                ? CommonStyles.flexDirectionRow
                : null,
            ]}
          >
            <View
              key="firsnameContainer"
              style={[
                styles.textInput,
                this.state.orientation === types2.ORIENTATION.LANDSCAPE &&
                screenMode === types.LOGINSIGNUP_MODE_SIGNUP
                  ? CommonStyles.rpMarginRight5
                  : null,
              ]}
            >
              <FormSubtitle text={['First Name']} />
              <StandardInput
                onFocus={() => {
                  if (isTablet()) {
                    this.scroll.props.scrollToPosition(0, 280);
                  }
                }}
                applyWrapper={false}
                accessibilityLabel="First Name"
                key="firstname"
                value={this.state.firstname}
                onChangeText={(firstname: any) => this.setState({firstname})}
                onSubmitEditing={() => {
                  focusNextField(
                    this.inputs,
                    INPUTS.SCROLL_VIEW,
                    INPUTS.LAST_NAME,
                  );
                }}
                blurOnSubmit={false}
                style={[
                  CommonStyles.LoginSignUpMB10,
                  CommonStyles.ModalTextInputWrapper,
                ]}
                noMargin={true}
                underlineColorAndroid="transparent"
                returnKeyType={'next'}
                errorMessage={this.state.firstNameError}
              />
            </View>
            <View key="lastnameContainer" style={[styles.textInput]}>
              <FormSubtitle text={['Last Name']} />
              <StandardInput
                onFocus={() => {
                  if (isTablet()) {
                    this.scroll.props.scrollToPosition(0, 280);
                  }
                }}
                applyWrapper={false}
                accessibilityLabel="Last Name"
                key="lastname"
                onRef={(ref: any) => {
                  this.inputs[INPUTS.LAST_NAME] = ref;
                }}
                value={this.state.lastname}
                onChangeText={(lastname: any) => this.setState({lastname})}
                onSubmitEditing={() => {
                  focusNextField(this.inputs, INPUTS.SCROLL_VIEW, INPUTS.EMAIL);
                }}
                blurOnSubmit={false}
                style={[
                  CommonStyles.LoginSignUpMB10,
                  CommonStyles.ModalTextInputWrapper,
                ]}
                noMargin={true}
                underlineColorAndroid="transparent"
                returnKeyType={'next'}
                errorMessage={this.state.lastNameError}
              />
            </View>
          </View>,
        ],
      );

      let passwordRequirements = (
        <StandardText
          style={[
            BrandStyles.TextColor5,
            CommonStyles.customFont,
            CommonStyles.rpfont10,
            styles.passwordRequirements,
            CommonStyles.alignSelfCenter,
            CommonStyles.rpMarginTop5p,
          ]}
        >
          {LOGIN_SIGNUP_SCREEN.PASSWORD_REQUIREMENT}
        </StandardText>
      );
    } else {
      passwordRequirements = null;
    }

    // autofocus to email and open keyboard
    let autofocus = false;
    if (screenMode == types.LOGINSIGNUP_MODE_LOGIN) {
      autofocus = true;
    }

    signUpFields.push([
      <View
        key="emailPasswordContainer"
        style={[
          CommonStyles.flex1,
          this.state.orientation === types2.ORIENTATION.LANDSCAPE &&
          screenMode === types.LOGINSIGNUP_MODE_SIGNUP
            ? CommonStyles.flexDirectionRow
            : CommonStyles.alignCenter,
        ]}
      >
        <View
          key="emailContainer"
          style={[
            styles.textInput,
            this.state.orientation === types2.ORIENTATION.LANDSCAPE &&
            screenMode === types.LOGINSIGNUP_MODE_SIGNUP
              ? CommonStyles.rpMarginRight5
              : null,
          ]}
        >
          <FormSubtitle text={['Email']} />
          <StandardInput
            onFocus={() => {
              if (isTablet()) {
                this.scroll.props.scrollToPosition(0, 280);
              }
            }}
            applyWrapper={false}
            key="email"
            accessibilityLabel="Email"
            onRef={(ref: any) => {
              this.inputs[INPUTS.EMAIL] = ref;
            }}
            value={this.state.email}
            onChangeText={(email: any) => {
              var emailTrimmed = email.trim();
              this.setState({email: emailTrimmed});
            }}
            onSubmitEditing={() => {
              focusNextField(this.inputs, INPUTS.SCROLL_VIEW, INPUTS.PASSWORD);
            }}
            blurOnSubmit={false}
            autoCapitalize={'none'}
            keyboardType={'email-address'}
            returnKeyType={'next'}
            style={[
              CommonStyles.LoginSignUpMB10,
              CommonStyles.ModalTextInputWrapper,
            ]}
            noMargin={true}
            underlineColorAndroid="transparent"
            errorMessage={this.state.emailError}
          />
        </View>
        {this._renderPasswordInputField(screenMode, passwordRequirements)}
        {this._renderNewPasswordInputField(screenMode, passwordRequirements)}
        {this._renderWebCaptcha(screenMode)}
      </View>,
    ]);

    return (
      <View
        style={[
          this.state.orientation === types2.ORIENTATION.LANDSCAPE &&
          screenMode === types.LOGINSIGNUP_MODE_SIGNUP
            ? CommonStyles.flex1CenterContent
            : null,
        ]}
      >
        {signUpFields}
      </View>
    );
  }

  _renderWebCaptcha = (screenMode: any) => {
    if (types.isWeb && screenMode == types.LOGINSIGNUP_MODE_SIGNUP) {
      return (
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          render="explicit"
          sitekey={GOOGLE_CAPTCHA_CLIENT_KEY}
          badge="bottomright"
          onChange={this._onCaptachaChangeSignUp}
        />
      );
    }
  };

  _onCaptachaChangeSignUp = (responseToken: any) => {
    this.setState({
      captchToken: responseToken,
    });

    let validateFields = this.validateFields(false, isSignUp);
    if (!validateFields) {
      recaptchaRef.current.reset();
      return;
    }
    this.signUp(responseToken);
    recaptchaRef.current.reset();
    return;
  };

  renderDivider() {
    if (screenMode != types.LOGINSIGNUP_MODE_REQUESTACCESS) {
      return [
        <View key="leftline" style={CommonStyles.LoginSignUpMR20H3BCwW40} />,
        <StandardText
          key="ortext"
          style={[
            BrandStyles.TextColor1,
            CommonStyles.customFontBold,
            styles.OrText,
            CommonStyles.font18,
          ]}
        >
          or
        </StandardText>,
        <View key="rightline" style={CommonStyles.LoginSignUpMR20H3BCwW40} />,
      ];
    } else {
      return null;
    }
  }

  renderSocialSignUpGoogle() {
    if (
      screenMode != types.LOGINSIGNUP_MODE_REQUESTACCESS &&
      !this.state.passwordChallenge
    ) {
      return [
        // <View key='signupGoogle' style={CommonStyles.LoginSignUpF1AIcJCc}>
        <SocialSignUpButton
          key="signupGoogle"
          loginType={types.LOGIN_TYPE_GOOGLE}
          isSignUp={isSignUp}
          setLoading={this.setLoading}
          signUpCallback={this.signUpSocialMediaResult}
          validateFields={this.validateFields}
        />,
        // </View>,
      ];
    } else {
      return null;
    }
  }

  renderSocialSignUpFacebook() {
    if (
      screenMode != types.LOGINSIGNUP_MODE_REQUESTACCESS &&
      !this.state.passwordChallenge &&
      brand.APP_SOCIAL_FACEBOOK_LOGIN_ENABLED
    ) {
      return [
        // <View key='signupFacebook' style={CommonStyles.LoginSignUpF1AIcJCc}>
        <SocialSignUpButton
          key="signupFacebook"
          loginType={types.LOGIN_TYPE_FACEBOOK}
          isSignUp={isSignUp}
          setLoading={this.setLoading}
          signUpCallback={this.signUpSocialMediaResult}
          validateFields={this.validateFields}
        />,
        // </View>
      ];
    } else {
      return null;
    }
  }

  renderCrewMessage() {
    return (
      <View>
        <StandardText
          style={[
            CommonStyles.customFontBold,
            BrandStyles.TextColor5,
            CommonStyles.font20,
          ]}
        >
          {LOGIN_SIGNUP_SCREEN.CREW_SIGNUP1}
        </StandardText>
        <StandardText
          style={[
            CommonStyles.customFont,
            BrandStyles.TextColor5,
            CommonStyles.font20,
          ]}
        >
          {LOGIN_SIGNUP_SCREEN.CREW_SIGNUP2}
        </StandardText>
      </View>
    );
  }

  _renderLogo() {
    if (brand.APP_BRAND != types2.APP_BRAND.LEAPIN) {
      return (
        <View style={[CommonStyles.SignUplogoContainer]}>
          <Image
            style={[CommonStyles.loginsingupLogowh60]}
            source={BrandIcons.LOGO_ICON}
          />
        </View>
      );
    }
  }

  _isObjectEmpty = (obj: any) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  renderAlertContent = () => {
    if (types.isWeb && this.state.showPlanReadyError) {
      let imageStyle = [CommonStyles.playStoreIcon];
      if (this.props.isMobile) {
        imageStyle = [CommonStyles.playStoreIconMobile];
      }
      return (
        <View style={CommonStyles.imageListContainer}>
          <View style={[CommonStyles.imageContainerStoreIcons]}>
            <StandardTouchableOpacity
              activeOpacity={0.6}
              onPress={() => {
                if (types.isWeb) {
                  window.open(types.PLAY_STORE);
                }
              }}
            >
              <Image
                style={[imageStyle, CommonStyles.marginRight10]}
                source={AppleBadge}
              />
            </StandardTouchableOpacity>
            <StandardTouchableOpacity
              activeOpacity={0.6}
              onPress={() => {
                if (types.isWeb) {
                  window.open(types.APP_STORE);
                }
              }}
            >
              <Image
                style={[imageStyle, CommonStyles.marginLeft10]}
                source={GooglePlayBadge}
              />
            </StandardTouchableOpacity>
          </View>
        </View>
      );
    }
  };

  getAlertStyle = () => {
    let styles;
    if (this.state.showPlanReadyError) {
      if (this.props.isMobile) {
        styles = CommonStyles.containerAlertMobile;
      } else {
        styles = CommonStyles.containerAlertWeb;
      }
    }
    return styles;
  };

  render() {
    Animated.timing(animationValue, {
      toValue: 1,
      duration: 3000,
    }).start();

    try {
      //screenMode = this.props.navigation.state.params.screenMode;
      screenMode = this.props.navigation.getParam('screenMode', undefined);
      // screenMode = types.LOGINSIGNUP_MODE_SIGNUP;
      signUpType = types.SIGNUP_TYPE_CREW;
      // screenMode = this.props.navigation.state.params.screenMode;
      if (screenMode == types.LOGINSIGNUP_MODE_LOGIN) {
        this.headerLine1 = LOGIN_SIGNUP_SCREEN.LOGIN_TITLE_1;
        this.headerLine2 = LOGIN_SIGNUP_SCREEN.LOGIN_TITLE_2;
        buttonLoginSignInText = PREAUTHENTICATION_SCREEN.BUTTON_LOGIN;
        showTermsAndC = this.state.passwordChallenge ? true : false;
        showForgotPassword = true;
        isSignUp = false;
        headerContent = null;
      } else if (screenMode == types.LOGINSIGNUP_MODE_SIGNUP) {
        this.headerLine1 = LOGIN_SIGNUP_SCREEN.SIGNUP_TITLE_1;
        this.headerLine2 = LOGIN_SIGNUP_SCREEN.SIGNUP_TITLE_2;
        buttonLoginSignInText = 'Create';
        showTermsAndC = true;
        showForgotPassword = false;
        isSignUp = true;
      } else if (screenMode == types.LOGINSIGNUP_MODE_REQUESTACCESS) {
        //This is not used anymore
        this.headerLine1 =
          LOGIN_SIGNUP_SCREEN.REQUESTACCESS_TITLE_1 +
          brand.LEAP_IN_COMPANY_NAME +
          LOGIN_SIGNUP_SCREEN.REQUESTACCESS_TITLE_2;
        buttonLoginSignInText = 'Next';
        showTermsAndC = false;
        showForgotPassword = false;
        isSignUp = false;
      } else {
        throw 'Invalid screen mode set in login';
      }

      headerContent = (
        <View
          style={[
            CommonStyles.flex1,
            CommonStyles.rpMarginTop25p,
            CommonStyles.rpMarginBottom40p,
          ]}
        >
          <View
            accessibilityLabel={this.headerLine1 + ' ' + this.headerLine2}
            accessible={true}
            ref={ref => {
              this.mainTitle = ref;
            }}
          >
            <View
              style={[
                CommonStyles.flex1,
                CommonStyles.viewWhiteBorder,
                BrandStyles.borderColor4,
                BrandStyles.brandBlockBgColor6,
                CommonStyles.alignSelfFlexStart,
                CommonStyles.alignCenter,
                CommonStyles.zIndex02,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.customFontBold,
                  BrandStyles.TextColor1,
                  CommonStyles.loginSignUpHeadingPadding,
                  CommonStyles.borderColor4,
                  types.isWeb ? CommonStyles.font22 : CommonStyles.rpfont22,
                  CommonStyles.fontWeightBold,
                ]}
              >
                {this.headerLine1}
              </StandardText>
            </View>
            <View
              style={[
                CommonStyles.flex1,
                BrandStyles.primaryBgColor1,
                CommonStyles.alignSelfFlexStart,
                CommonStyles.alignCenter,
                CommonStyles.zIndex01,
                CommonStyles.secondHeaderTop,
                types.isWeb
                  ? CommonStyles.marginLeft50
                  : CommonStyles.rpMarginLeft70,
                CommonStyles.viewWhiteBorder,
                BrandStyles.borderColor4,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.customFontBold,
                  BrandStyles.brandBlockTxtColor6,
                  CommonStyles.loginSignUpHeadingPadding,
                  types.isWeb ? CommonStyles.font22 : CommonStyles.rpfont22,
                  CommonStyles.fontWeightBold,
                ]}
              >
                {this.headerLine2}
              </StandardText>
            </View>
          </View>
          <BasicPopupAlert
            visible={this.state.errorPopUpVisible}
            close={this.state.closeAction}
            cancelLabel={this.state.alertButtonText}
            headerText={this.state.alertHeader}
            messageText={this.state.alertMessage}
            forceUpdate={this.state.forceUpdate}
            latestVersion={this.state.latestVersion}
            showPlanReadyError={this.state.showPlanReadyError}
            subContent={this.renderAlertContent()}
            style={this.getAlertStyle()}
            isMobile={this.props.isMobile}
          />
          {this._renderTemporaryPasswordExpiredMessage()}
        </View>
      );
    } catch (e) {
      throw 'No screen mode set for LoginSignUp. Please set one for this screen';
    }
    return (
      <View style={[CommonStyles.screenWrapperContainer]}>
        <View style={[CommonStyles.flex1]}>
          {this.state.showDataSharingConsentModal && (
            <DataSharingConsentModal
              closeModal={(result: any) =>
                this._closeDataSharingConsentModalAndContinue(result)
              }
              isVisible={this.state.showDataSharingConsentModal}
              hideModal={() => {
                this.setState({showDataSharingConsentModal: false});
              }}
              setLoading={() => {
                this.setState({loading: true});
              }}
              nav={this.props.navigation}
              isMobile={this.props.isMobile}
            />
          )}
          <View
            style={[
              CommonStyles.flex1,
              CommonStyles.flexDirectionRow,
              CommonStyles.headerWrapper,
              BrandStyles.ContainerBackgroundColor,
            ]}
          >
            {this.renderHeaderItems()}
            {this.backNavigation()}
          </View>
          <OfflineNotice />
          {types.isWeb && <DeepLinkBanner />}
          <KeyboardAwareScrollView
            extraScrollHeight={
              Platform.OS === types2.IOS && isTablet() ? 200 : null
            }
            ref={ref => (this.inputs[INPUTS.SCROLL_VIEW] = ref)}
            enableAutomaticScroll={Platform.OS === types2.IOS ? true : false}
            keyboardDismissMode="interactive"
            keyboardShouldPersistTaps="always"
            innerRef={ref => {
              this.scroll = ref;
            }}
            style={[CommonStyles.flex1, BrandStyles.primaryBgColor1]}
          >
            {this._renderLoginButton()}
            <View
              style={[
                CommonStyles.flex1,
                CommonStyles.alignCenter,
                CommonStyles.flexWrap,
              ]}
            >
              {headerContent}
              <View style={[CommonStyles.flex1, CommonStyles.rpWidth270]}>
                {this.renderInputFields(screenMode)}
                {this.renderLoginSignUpButton(showTermsAndC)}
              </View>
              <View
                style={
                  types.isWeb
                    ? [CommonStyles.flex1CenterContent, {marginTop: 50}]
                    : [CommonStyles.flex1]
                } //using inline style as edge won't render correctly using a common style
              >
                {this._renderForgotPassword(showForgotPassword)}
              </View>
              <View
                style={[
                  CommonStyles.flex1,
                  CommonStyles.flexDirectionRow,
                  screenMode === types.LOGINSIGNUP_MODE_LOGIN &&
                  this.state.orientation != types2.ORIENTATION.LANDSCAPE
                    ? CommonStyles.rpMarginTop25p
                    : null,
                ]}
              >
                {!brand.APP_SOCIAL_LOGIN_ENABLED ||
                Platform.OS === types2.IOS ||
                types.isWeb
                  ? null
                  : this.renderSocialSignUpGoogle()}
                {!brand.APP_SOCIAL_LOGIN_ENABLED ||
                Platform.OS === types2.IOS ||
                types.isWeb
                  ? null
                  : this.renderSocialSignUpFacebook()}
              </View>
              <View style={[CommonStyles.flex1]}>
                {this._renderDontHaveAccount()}
              </View>
            </View>
          </KeyboardAwareScrollView>
        </View>
        <BrandActivityIndicator enableWeb={true} loading={this.state.loading} />
      </View>
    );
  }

  _renderLoginButton() {
    if (screenMode === types.LOGINSIGNUP_MODE_SIGNUP) {
      return (
        <View
          style={[
            CommonStyles.flexDirectionRow,
            types.isWeb ? CommonStyles.alignFlexEnd : CommonStyles.justifyEnd,
            CommonStyles.paddingTop20,
            CommonStyles.paddingRight20,
          ]}
        >
          <StandardTouchableOpacity
            activeOpacity={0.8}
            onPress={this.loginUser.bind(this)}
            accessibilityLabel={ACCESSIBILITY_SCREEN_TITLES.LIKETOLOGIN}
            style={[
              types.isWeb
                ? CommonStyles.budgetsTileWrapper
                : CommonStyles.loginTileWrapper,
            ]}
          >
            <View
              style={[CommonStyles.flexDirectionRow, CommonStyles.flexWrap]}
            >
              <Image
                source={BrandIcons.LOGO_ICON}
                style={[CommonStyles.loginButton]}
                ref={ref => {
                  if (ref) {
                    this.loggedInRef = ref;
                  }
                }}
              />
              <View
                style={[
                  CommonStyles.paddingLeft10,
                  CommonStyles.flexDirectionColumn,
                  CommonStyles.alignCenter,
                ]}
              >
                <StandardText
                  style={[CommonStyles.loginToText, CommonStyles.customFont]}
                >
                  {LOGIN_SIGNUP_SCREEN.LOGIN_TO}
                </StandardText>
                <StandardText
                  style={[CommonStyles.myaccountText, CommonStyles.customFont]}
                >
                  {LOGIN_SIGNUP_SCREEN.MY_ACCOUNT}
                </StandardText>
              </View>
            </View>
          </StandardTouchableOpacity>
        </View>
      );
    }
  }

  _renderBackButton() {
    if (
      APP_BRAND == types2.APP_BRAND.LEAPIN ||
      APP_BRAND == types2.APP_BRAND.BLUE_CARE ||
      APP_BRAND == types2.APP_BRAND.UCN ||
      APP_BRAND == types2.APP_BRAND.YOORALLA
    ) {
      return (
        <BackButton
          isBlack={false}
          goBack={() => {
            this.props.navigation.goBack();
          }}
        />
      );
    } else {
      return (
        <BackButton
          isBlack={true}
          goBack={() => {
            this.props.navigation.goBack();
          }}
        />
      );
    }
  }

  renderHeaderItems = () => {
    if (types.isWeb) {
      return (
        <View
          style={[
            CommonStyles.flex1,
            CommonStyles.justifyFlexRowSpace,
            CommonStyles.alignItemsCenter,
          ]}
        >
          <StandardTouchableOpacity
            activeOpacity={0.8}
            onPress={() => window.open(brand.LEAP_IN_URL)}
            accessibilityLabel={LOGIN_SIGNUP_SCREEN.RETURN_TO_LEAPIN}
            style={[CommonStyles.loginTileWrapper]}
          >
            <View
              style={[CommonStyles.flexDirectionRow, CommonStyles.flexWrap]}
            >
              <Image
                source={LILogoWhiteText}
                style={[CommonStyles.imageBrandLogoText]}
              />
            </View>
          </StandardTouchableOpacity>
          <StandardTouchableOpacity
            activeOpacity={0.8}
            onPress={() => window.open(brand.LEAP_IN_URL)}
            accessibilityLabel={LOGIN_SIGNUP_SCREEN.RETURN_TO_LEAPIN}
            style={[CommonStyles.loginTileWrapper]}
          >
            <View
              style={[CommonStyles.flexDirectionRow, CommonStyles.flexWrap]}
            >
              <CustomIcon
                style={[CommonStyles.headerItemIcon]}
                name={RefreshIcon}
              />
              <View
                style={[
                  CommonStyles.paddingLeft10,
                  CommonStyles.flexDirectionColumn,
                  CommonStyles.alignCenter,
                ]}
              >
                <StandardText
                  style={[
                    CommonStyles.font18,
                    CommonStyles.customFont,
                    BrandStyles.TextColor1,
                  ]}
                >
                  {LOGIN_SIGNUP_SCREEN.RETURN_TO_LEAPIN}
                </StandardText>
              </View>
            </View>
          </StandardTouchableOpacity>
        </View>
      );
    }
  };

  backNavigation = () => {
    const enableBack = this.props.navigation.getParam('enableBack', undefined);
    if (enableBack && Platform.OS === types2.IOS) {
      return (
        <View
          style={[
            CommonStyles.flex1,
            CommonStyles.alignFlexStart,
            CommonStyles.justifyCenter,
          ]}
        >
          <View
            style={CommonStyles.alignBackButton}
            ref={ref => {
              this.goBackbtn = ref;
            }}
          >
            {/* {Platform.OS === types2.IOS ? <BackButton isBlack={false} goBack={() => {this.props.navigation.goBack()}}></BackButton> : null} */}
            {this._renderBackButton()}
          </View>
        </View>
      );
    }
  };

  /*
   * This will validate all the fields in the Signup & Login screen.
   * It checks if its a sign up with the isSignUp class level varible that set's this screens state
   * And the isSocailSignUpLogin is passed back from the social sign in buttons to indicate if
   * Facebook or Google is being used to sign in.
   * @param {bool} If the login is a social media sign up
   * @returns {bool} True if the fields are valid, false if there is in invalid field. Addiontaly will display an alert with the invalid field.
   */
  validateFields = (isSocialSignUpLogin: any, isSignUpFlow: any) => {
    let whatFailed = [];
    let count = 0;
    let isSignUp;

    if (isSignUpFlow != undefined) {
      isSignUp = isSignUpFlow;
    }

    // Used to set the state for failed fields - we build it up as to only set the state once
    // If the error string is blank it means it passed validation :)
    // Additionaly it all sets them to passed if the user fixed previous validation issues
    var validateErrors = {
      firstNameError: '',
      knownasError: '',
      lastNameError: '',
      emailError: '',
      passwordError: '',
      termsandconditionsacceptedError: '',
      validateCheckBox: '',
    };

    // Only validate the sign up fields if the user is signing up
    if (isSignUp) {
      if (!isSocialSignUpLogin) {
        validateErrors.firstNameError = this.validateFirstName();
        validateErrors.lastNameError = this.validateLastName();
      }

      // If the user is requesting access to another account they don't need to accept
      // t&c. Screenmode is set in the render of this component so that's how we have scope.
      if (screenMode != types.LOGINSIGNUP_MODE_REQUESTACCESS) {
        validateErrors.validateCheckBox = this.validateCheckBox();
      }
    } else if (
      screenMode == types.LOGINSIGNUP_MODE_LOGIN &&
      this.state.passwordChallenge
    ) {
      validateErrors.validateCheckBox = this.validateCheckBox();
    }

    // We always need to validate the email unless it's a social sign up/login
    if (!isSocialSignUpLogin) {
      validateErrors.emailError = this.validateEmail();
      // The password is only validated when loggging in or signing up
      // It is not needed when requesting acesss to another user's account
      if (screenMode != types.LOGINSIGNUP_MODE_REQUESTACCESS) {
        validateErrors.passwordError = this.validatePassword();
      }
    }

    this.setState({
      firstNameError: validateErrors.firstNameError,
      knownasError: validateErrors.knownasError,
      lastNameError: validateErrors.lastNameError,
      emailError: validateErrors.emailError,
      passwordError: validateErrors.passwordError,
      termsandconditionsacceptedError:
        validateErrors.termsandconditionsacceptedError,
    });

    var readThisText =
      validateErrors.firstNameError +
      '. ' +
      validateErrors.lastNameError +
      '. ' +
      validateErrors.emailError +
      '. ' +
      validateErrors.passwordError +
      '. ' +
      validateErrors.validateCheckBox;

    // Now return if the there are valid fields
    for (let [key, value] of Object.entries(validateErrors)) {
      if (value != '') {
        announceForAccessibility(readThisText);
        return false;
      }
    }
    return true;
  };

  loginUser() {
    this.props.navigation.navigate('LoginSignUp', {
      screenMode: types.LOGINSIGNUP_MODE_LOGIN,
      enableBack: true,
    });

    if (!types.isWeb) {
      firebaseAnalysisLogEvent(
        types.FIREBASE_ANALYTICS_EVENTS.LOGIN_LANDING,
        {},
      );
      this.props.navigation.navigate('LoginSignUp', {
        screenMode: types.LOGINSIGNUP_MODE_LOGIN,
        enableBack: true,
      });
    }
  }

  loginSignUpUser(isSignUpFlow: any) {
    let isSignUp;
    if (isSignUpFlow != undefined) {
      isSignUp = isSignUpFlow;
    }
    const screenMode = this.props.navigation.getParam('screenMode', undefined);

    if (types.isWeb && isSignUp) {
      recaptchaRef.current.execute();
      return;
    }

    if (!this.state.isConnected) {
      this.props.actions.OfflineNoticeActions.shakeOfflineNotice();
      return;
    }

    // If we are simply requesting access to another account do the api call and go next
    // Note - even if the email dosen't exist in LI For access still go next to prevent a user
    // From guessing people's email who might use the service
    else if (screenMode == types.LOGINSIGNUP_MODE_REQUESTACCESS) {
      // Set the sceen to loading & call the API to request access to a person
      this.setState({loading: true});
      let callbackFunction = (data: any) => {
        // Stop loading
        this.setState({loading: false});
        // Navigate to the next screen
        this.navigateToNext();
      };
      // Request access to a person from a user ID to a given email;
      requestPersonAccess(
        this.props.auth.awstoken.IdentityId,
        this.state.email,
        callbackFunction,
      );
    } else {
      // The user is either logging in or sign up
      let validateFields;
      if (isSignUp) {
        validateFields = this.validateFields(false, isSignUp);
        if (!validateFields) {
          return;
        }
        this.signUp();
        return true;
      } else {
        // If there are no valid fields don't do anything.
        // Validate fields will alert the user to an error.

        validateFields = this.validateFields(false, isSignUp);
        if (!validateFields) {
          return;
        }
        this.login();
        return true;
      }
    }
  }

  //Checks if the FirstName field has input
  validateFirstName() {
    if (this.state.firstname === '') {
      return types.FIRSTNAME_NOT_ENTERED;
    } else {
      return '';
    }
  }

  //Checks if the LastName field has input
  validateLastName() {
    if (this.state.lastname === '') {
      return types.LASTNAME_NOT_ENTERED;
    } else {
      return '';
    }
  }
  //Checks if a Valid Email Address has been entered
  validateEmail() {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(this.state.email)) {
      return '';
    } else {
      return types.EMAIL_INVALID_FORMAT;
    }
  }

  //Checks if Password field has input
  validatePassword() {
    var re = /.{8,}/gim;
    if (re.test(this.state.password)) {
      // Ensure there is no whitespace in the password
      if (this.state.password.indexOf(' ') >= 0) {
        return types.PASSWORD_CONTAINS_SPACES;
      }
      return '';
    } else {
      return types.PASSWORD_INVALID_LENGTH;
    }
  }

  /*    validateCheckBox() {
            if(this.state.termsandconditionsaccepted === false){
                return types.TANDC_NOT_ACCEPTED;
            } else {
                return '';
            }
        }
    */
  validateCheckBox() {
    if (this.state.termsandconditionsaccepted === false) {
      this.setState({displayTermsandCErrorMessage: false});
      return types.TANDC_NOT_ACCEPTED;
    } else {
      this.setState({displayTermsandCErrorMessage: true});
      return '';
    }
  }

  _showTermsandCError = (TandCCheck: any) => {
    if (TandCCheck != false) {
      return null;
    } else {
      return (
        <StandardText
          accessibilityLabel={ACCESSIBILITY_SCREEN_TITLES.TERM_CONDITION}
          style={[
            CommonStyles.inputErrorMessageText,
            styles.termsAndConditionsError,
            CommonStyles.LoginSignFS13MR20MT20PB10,
          ]}
        >
          {types.TANDC_NOT_ACCEPTED}
        </StandardText>
      );
    }
  };

  forgotPassword = () => {
    // Navigate to forgot password
    this.props.navigation.navigate('ForgotPassword');
  };

  signUp(responseToken: any) {
    let firstName = this.state.firstname.trim();
    let lastName = this.state.lastname.trim();
    let email = this.state.email;
    let password = this.state.password;
    let knownas = this.state.knownas;
    let captchToken = responseToken;

    this.setState({loading: true});
    const typeConfirmed = this.props.admin.typeConfirmed;
    signUpCognito(
      email,
      password,
      firstName,
      lastName,
      knownas,
      captchToken,
      true,
    )
      .then(response => {
        if (!types.isWeb) {
          firebaseAnalysisLogEvent(
            types.FIREBASE_ANALYTICS_EVENTS.SIGNUP_CLICKED,
            {},
          );
        }

        this.saveEmailCredentials(
          email,
          password,
          response.cognitoIdToken,
          response.awsIdentity,
        );
      })
      .catch(error => {
        logger.log('Signup error ', error);
        this.setState({loading: false});

        if (error.code && error.code == types.VERSION_ERROR) {
          this.setState({
            latestVersion: error.message,
          });
          // The user is using an app version that is no longer supported, so display message to the user
          this._showAlert(
            ERRORS.UPDATE_AVAILABLE,
            ERRORS.FORCE_UPDATE_TEXT,
            COMMON_BUTTONS.UPDATE,
            this._closeAlert,
            true,
            true,
          );
        } else {
          if (error.message) {
            if (error.message != ERRORS.NO_INTERNET) {
              this._showAlert(
                POPUP_TITLES.SIGNUP_FAIL,
                error.message,
                COMMON_BUTTONS.CLOSE,
                this._closeAlert,
                true,
                false,
              );
            }
          } else {
            this._showAlert(
              POPUP_TITLES.SIGNUP_FAIL,
              ERRORS.GENERIC,
              COMMON_BUTTONS.CLOSE,
              this._closeAlert,
              true,
              false,
            );
          }
        }
      });
  }

  _showAlert(
    alertHeader: any,
    alertMessage: any,
    alertButtonText: any,
    closeAction: any,
    errorPopUpVisible: any,
    forceUpdate: any,
    showPlanReadyError: any,
  ) {
    this.setState({
      alertHeader: alertHeader,
      alertMessage: alertMessage,
      alertButtonText: alertButtonText,
      closeAction: closeAction,
      errorPopUpVisible: errorPopUpVisible,
      forceUpdate: forceUpdate,
      showPlanReadyError: showPlanReadyError,
    });
  }

  _closeAlert() {
    if (this.state.forceUpdate) {
      const updateLink = getStoreUrl(Platform.OS);
      Linking.openURL(updateLink);
    }
    this.setState({
      password: '',
      errorPopUpVisible: false,
    });
  }

  _determineShowDataSharingConsentModal(user: any, routingObject: any) {
    let showDataSharingConsentModal = !user.tAndCAcceptedBrands.find(
      (x: any) => x == APP_ID,
    );
    this.setState({
      showDataSharingConsentModal: showDataSharingConsentModal,
      dataSharingConsentModalRoutingObject: routingObject,
    });

    return showDataSharingConsentModal;
  }

  _closeDataSharingConsentModalAndContinue = (continueNavigation: any) => {
    this.setState({
      showDataSharingConsentModal: false,
      loading: false,
    });

    // If the User close the modal the continueNavigation will be false
    // The user will be logged out if the user closes the modal
    // IF user select continue tnc will be updated to accepted and continueNavigation will be true
    // There for continue navigation

    // Breaks the navigation
    if (!continueNavigation) {
      this.setState({
        email: '',
        password: '',
      });
      return;
    }

    if (types.isWeb) {
      if (this.state.cognitoId) {
        setValue('cognitoId', this.state.cognitoId);
        setValue('awsIdentity', this.state.awsIdentity);
      }
      return this.props.navigation.navigate(
        this.state.dataSharingConsentModalRoutingObject.routeName,
      );
    } else {
      const actionToDispatch = CommonActions.reset({
        index: 0,
        routes: [ {
          name:this.state.dataSharingConsentModalRoutingObject.routeName
        }],
      });

      return this.props.navigation.dispatch(actionToDispatch);
    }
  };

  login() {
    let username = this.state.email;
    let password = this.state.password;
    let newPassword = this.state.newPassword;
    let userAttributes = this.state.cognitoUserAttributes;
    let cognitoUser = this.state.cognitoUser;

    Keyboard.dismiss();

    this.setState({loading: true});
    if (!types.isWeb) {
      firebaseAnalysisLogEvent(
        types.FIREBASE_ANALYTICS_EVENTS.LOGIN_CLICKED,
        {},
      );
    }
    // There is a password challenge occurring
    if (this.state.passwordChallenge) {
      cognitoPasswordChallenge(
        username,
        newPassword,
        userAttributes,
        cognitoUser,
      )
        .then(response => {
          // The cognito id will have been written to the backend when the success has returned
          this.setState({isFirstTimeLogin: true});

          this.saveEmailCredentials(
            username,
            newPassword,
            response.cognitoIdToken,
            response.awsIdentity,
          );
        })
        .catch(error => {
          logger.log('Cognito login error:', error);
          this.setState({loading: false, password: ''});
          if (typeof error === 'string') {
            this._showAlert(
              POPUP_TITLES.SIGNIN_FAIL,
              error,
              types2.OK,
              this._closeAlert,
              true,
              false,
              false,
            );
          } else if (error.message) {
            if (error.message != ERRORS.NO_INTERNET) {
              this._showAlert(
                POPUP_TITLES.SIGNIN_FAIL,
                error.message,
                types2.OK,
                this._closeAlert,
                true,
                false,
                false,
              );
            }
          } else {
            this._showAlert(
              POPUP_TITLES.SIGNIN_FAIL,
              ERRORS.GENERIC_LOGIN,
              types2.OK,
              this._closeAlert,
              true,
              false,
              false,
            );
          }
        });
    } else {
      loginCognito(username, password)
        .then(response => {
          if (response.type == types.COGNITO_TYPE_LOGIN) {
            this.saveEmailCredentials(
              username,
              password,
              response.cognitoIdToken,
              response.awsIdentity,
            );
          } else if (response.type == types.COGNITO_TYPE_CHALLENGE) {
            // A challenge for the password has been intialised, so need to display a field to take a new password
            this.setState({
              passwordChallenge: true,
              loading: false,
              cognitoUserAttributes: response.userAttributes,
              cognitoUser: response.cognitoUser,
            });
          }
        })
        .catch(error => {
          logger.log('Cognito login error:', error);
          this.setState({loading: false, password: ''});
          if (error && error.message === types.COGNITO_TEMP_PASSWORD_EXPIRED) {
            return this.setState({
              temporaryPasswordExpiredMessageVisible: true,
            });
          }
          if (typeof error === 'string') {
            this._showAlert(
              POPUP_TITLES.SIGNIN_FAIL,
              error,
              types2.OK,
              this._closeAlert,
              true,
              false,
              false,
            );
          } else if (error.message) {
            if (error.message != ERRORS.NO_INTERNET) {
              this._showAlert(
                POPUP_TITLES.SIGNIN_FAIL,
                ERRORS.TRY_AGAIN_MESSAGE,
                COMMON_BUTTONS.CLOSE,
                this._closeAlert,
                true,
                false,
                false,
              );
            }
          } else {
            this._showAlert(
              POPUP_TITLES.SIGNIN_FAIL,
              ERRORS.GENERIC_LOGIN,
              types2.OK,
              this._closeAlert,
              true,
              false,
              false,
            );
          }
        });
    }
  }

  // A callback from the Social Sign Up Button
  signUpSocialMediaResult = (type: any, error: any, response: any) => {
    if (error) {
      removeItem(types.LOGIN_LAST_TYPE);
      this.setState({loading: false});
      if (error.code && error.code == types.VERSION_ERROR) {
        this.setState({
          latestVersion: error.message,
        });
        // The user is using an app version that is no longer supported, so display message to the user
        this._showAlert(
          ERRORS.UPDATE_AVAILABLE,
          ERRORS.FORCE_UPDATE_TEXT,
          COMMON_BUTTONS.UPDATE,
          this._closeAlert.bind(this),
          true,
          true,
          false,
        );
      } else if (
        error !=
          'Google sign in error GoogleSigninError: Sign in action cancelled' &&
        error != 'Login was cancelled'
      ) {
        Alert.alert(ERRORS.GENERIC_ISSUE_ALERT_TITLE, JSON.stringify(error));
      }
    } else {
      if (type == types.LOGIN_TYPE_FACEBOOK) {
        storeData(types.LOGIN_LAST_TYPE, types.LOGIN_TYPE_FACEBOOK);
        this.props.actions.authActions.actionLoginSuccess(
          types.LOGIN_TYPE_FACEBOOK,
          response.facebookToken,
          response.awsIdentity,
        );
      } else if (type == types.LOGIN_TYPE_GOOGLE) {
        storeData(types.LOGIN_LAST_TYPE, types.LOGIN_TYPE_GOOGLE);
        this.props.actions.authActions.actionLoginSuccess(
          types.LOGIN_TYPE_FACEBOOK,
          response.googleToken,
          response.awsIdentity,
        );
      } else {
        logger.log('Invalid Login Type');
        return;
      }

      this.setState({loading: false});
      this.navigateToNext();
    }
  };

  setLoading = () => {
    this.setState({loading: true});
  };

  /**
   * Function that will call the auth action, store data and save the credientials for email login.
   * @param {string} username - The user name that was entered for logging in.
   * @param {string} password - The password entered for logging in.
   * @param {string} cognitoId - The cognito id that is representing the person who signed in.
   * @param {string} awsIdentity - The AWS identity after the federated id has been fetched from AWS
   */
  saveEmailCredentials = (
    username: any,
    password: any,
    cognitoId: any,
    awsIdentity: any,
  ) => {
    storeData(types.LOGIN_LAST_TYPE, types.LOGIN_TYPE_EMAIL);
    this.props.actions.authActions.actionLoginSuccess(
      types.LOGIN_TYPE_EMAIL,
      cognitoId,
      awsIdentity,
    );
    // Storing a logged in flag into aync storage
    storeData(types.PREVIOUSLY_SIGNIN, 'logged');
    // Save the credentials in the keychain
    if (types.isWeb) {
      this.setState({
        cognitoId,
        awsIdentity: awsIdentity.IdentityId,
      });
      this.navigateToNext();
    } else {
      Keychain.setInternetCredentials(
        types.COGNITO_KEY,
        username,
        password,
      ).then(() => {
        // Navigate to the next screen.
        this.navigateToNext();
      });
    }
  };

  /*
   * Once you sign in this will navigate to different landing screens.
   */

  navigateToNext() {
    //Lets work out where we need to go next
    // 1. Lets set some flags!
    //let planType = this.props.navigation.state.params.planType;
    let screenMode = this.props.navigation.getParam('screenMode', undefined);
    // let screenMode = types.LOGINSIGNUP_MODE_SIGNUP;
    // let signUpType = this.props.navigation.state.params.signUpType;
    // 2. is this a login or a sign up!?
    if (
      screenMode == types.LOGINSIGNUP_MODE_LOGIN &&
      !this.state.isFirstTimeLogin
    ) {
      // 3a. This is a login, nothing special so trigger the regular routing
      this._loginRouting();
    } else if (
      screenMode == types.LOGINSIGNUP_MODE_SIGNUP ||
      (screenMode == types.LOGINSIGNUP_MODE_LOGIN &&
        this.state.isFirstTimeLogin)
    ) {
      let callbackFunction = (
        routingObj: any,
        memberData: any,
        ManagedMemberCount: any,
        flags: any,
      ) => {
        //Update the redux store with the ID we want to load the initial data for
        if (flags && flags.length > 0) {
          this.props.actions.adminActions.actionGetFeatureFlags(flags);
        }
        const userObj = routingObj.params.userObj;
        const isUserOnlyMember =
          userObj.isMember && userObj.numberOfMembers == 1;

        if (userObj.managedMemberId != undefined && !isUserOnlyMember) {
          this.props.actions.loadedMemberActions.actionGetLoadedMemberId(
            userObj.managedMemberId,
          );
        } else {
          this.props.actions.loadedMemberActions.actionGetLoadedMemberId(
            userObj.user.id,
          );
        }

        if (userObj.isMember && !userObj.isCrew && memberData) {
          this.props.actions.MemberActions.actionGetMember(
            memberData.payload,
            memberData.loadedMemberId,
            memberData.isEmployee,
            memberData.isFinancialStaff,
          );
        }
        this.props.actions.userActions.actionGetUser(userObj);
        this.setState({loading: false});

        Keyboard.dismiss();

        const actionToDispatchRoute = CommonActions.reset({
          index: 0,
          routes: [{name:routingObj.routeName}],
        });

        if (types.isWeb) {
          if (this.state.cognitoId) {
            setValue('cognitoId', this.state.cognitoId);
            setValue('awsIdentity', this.state.awsIdentity);
          }

          if (userObj.typeConfirmed) {
            this.props.navigation.navigate(
              routingObj.routeName,
              routingObj.params,
            );
          } else {
            if (this.state.cognitoId) {
              setValue('cognitoId', this.state.cognitoId);
              setValue('awsIdentity', this.state.awsIdentity);
            }
            this.props.navigation.navigate('ChooseParticipantOrCrew');
          }
        } else {
          const actionToDispatchTypeSelection = CommonActions.reset({
            index: 0,
            routes: [
           {name: 'ChooseParticipantOrCrew'}
            ],
          });
          this.props.navigation.dispatch(
            userObj.typeConfirmed
              ? actionToDispatchRoute
              : actionToDispatchTypeSelection,
          );
        }
      };

      routeTolanding(
        this.props.auth.awstoken.IdentityId,
        callbackFunction,
        screenMode === types.LOGINSIGNUP_MODE_LOGIN,
      );
    }
  }

  _loginRouting = () => {
    // Navigate to the pre authentication page & set it to be the root of the stack so the back button will then close the app.
    let callbackFunction = (
      routingObj: any,
      memberData: any,
      ManagedMemberCount: any,
      flags: any,
    ) => {
      if (flags && flags.length > 0) {
        this.props.actions.adminActions.actionGetFeatureFlags(flags);
      }
      // Remove the spinner now that it is about to transistion to somewhere else
      if (routingObj.code == '404') {
        // Remove the last login info. so that user can select any available google logins etc.
        removeItem(types.LOGIN_LAST_TYPE);
        // Will throw an alert to the user when attempting to login to a user account that doesn't exist
        this._showAlert(
          POPUP_TITLES.SIGNIN_FAIL,
          ERRORS.NO_ACCOUNT,
          types2.OK,
          this._closeAlert,
          true,
          false,
          false,
        );
        //Navigate to the pre authentication page & set it to be the root of the stack so the back button will then close the app.
        if (types.isWeb) {
          this.props.navigation.navigate('LoginSignUp', {
            screenMode: types.LOGINSIGNUP_MODE_SIGNUP,
            enableBack: false,
          });
        } else {
          const actionToDispatch = CommonActions.reset({
            index: 0,
            routes: [ {name: 'LoginSignUp', params: {screenMode: types.LOGINSIGNUP_MODE_SIGNUP}}
              // NavigationActions.navigate({
              //   routeName: 'LoginSignUp',
              //   params: {screenMode: types.LOGINSIGNUP_MODE_SIGNUP},
              // }),
            ],
          });
          this.props.navigation.dispatch(actionToDispatch);
        }
        this.setState({loading: false});
      } else if (routingObj.code && routingObj.code == types.VERSION_ERROR) {
        this.setState({
          latestVersion: routingObj.message,
        });
        // The user is using an app version that is no longer supported, so display message to the user
        this._showAlert(
          ERRORS.UPDATE_AVAILABLE,
          ERRORS.FORCE_UPDATE_TEXT,
          COMMON_BUTTONS.UPDATE,
          this._closeAlert,
          true,
          true,
          false,
        );
      } else if (
        routingObj.params &&
        routingObj.params.userObj &&
        routingObj.params.userObj.user.active
      ) {
        const userObj = routingObj.params.userObj;

        //Update the redux store with the ID we want to load the initial data for
        const isUserOnlyMember =
          userObj.isMember && userObj.numberOfMembers == 1;

        if (userObj.managedMemberId != undefined && !isUserOnlyMember) {
          this.props.actions.loadedMemberActions.actionGetLoadedMemberId(
            userObj.managedMemberId,
          );
        } else if (userObj.user.id != undefined) {
          this.props.actions.loadedMemberActions.actionGetLoadedMemberId(
            userObj.user.id,
          );
        } else {
          // Remove the last login info. so that user can select any available google logins etc.
          removeItem(types.LOGIN_LAST_TYPE);
          this._showAlert(
            POPUP_TITLES.SIGNIN_FAIL,
            ERRORS.GENERIC_ISSUE_ALERT_MSG,
            types2.OK,
            this._closeAlert,
            true,
            false,
          );

          if (types.isWeb) {
            this.props.navigation.navigate('LoginSignUp', {
              screenMode: types.LOGINSIGNUP_MODE_SIGNUP,
              enableBack: false,
            });
          } else {
            CommonActions.reset({
              index: 0,
              routes: [{name: routingObj.routeName}],
            });
            const actionToDispatch = CommonActions.reset({
              index: 0,
              routes: [ {name:"LoginSignUp", params: {screenMode: types.LOGINSIGNUP_MODE_SIGNUP} }
                // NavigationActions.navigate({
                //   routeName: 'LoginSignUp',
                //   params: {screenMode: types.LOGINSIGNUP_MODE_SIGNUP},
                // }),
              ],
            });
            this.props.navigation.dispatch(actionToDispatch);
          }

          this.setState({loading: false});
        }

        if (userObj.isMember && !userObj.isCrew && memberData) {
          // Write the data to redux
          this.props.actions.MemberActions.actionGetMember(
            memberData.payload,
            memberData.loadedMemberId,
            memberData.isEmployee,
            memberData.isFinancialStaff,
          );
        }

        //Update the redux store with the User Object of the logged in user
        this.props.actions.userActions.actionGetUser(userObj);
        if (this.state.isFirstTimeLogin) {
          this.props.actions.userActions.setFirstTimeSignUp(
            this.state.isFirstTimeLogin,
            true,
          );
        }

        this.setState({loading: false});
        this.setState({isFirstTimeLogin: false});
        // if there is no need to show the DataSharingConsentModal navigate inside the app
        if (
          !this._determineShowDataSharingConsentModal(userObj.user, routingObj)
        ) {
          if (!types.isWeb) {
            const actionToDispatch = CommonActions.reset({
              index: 0,
              routes: [{name: routingObj.routeName}],
            });
            this.props.navigation.dispatch(actionToDispatch);
          } else {
            if (this.state.cognitoId) {
              setValue('cognitoId', this.state.cognitoId);
              setValue('awsIdentity', this.state.awsIdentity);
            }
            this.props.navigation.navigate(routingObj.routeName);
          }
        }
      } else {
        // Remove the last login info. so that user can select any available google logins etc.
        removeItem(types.LOGIN_LAST_TYPE);
        //User account being logged into is inacivte!!!! OH NOES
        this._showAlert(
          POPUP_TITLES.SIGNIN_FAIL,
          ERRORS.INACTIVE_ACCOUNT,
          types2.OK,
          this._closeAlert,
          true,
          false,
        );

        if (types.isWeb) {
          this.props.navigation.navigate('LoginSignUp', {
            screenMode: types.LOGINSIGNUP_MODE_SIGNUP,
            enableBack: false,
          });
        } else {
          const actionToDispatch = CommonActions.reset({
            index: 0,
            actions: [ {name: 'LoginSignUp',  params: {screenMode: types.LOGINSIGNUP_MODE_SIGNUP}}
            ],
          });
          this.props.navigation.dispatch(actionToDispatch);
        }
        this.setState({loading: false});
      }
    };
    routeTolanding(this.props.auth.awstoken.IdentityId, callbackFunction, true);
  };
}

const styles = StyleSheet.create({
  // Animations for the class
  heroBanner: {
    transform: [{translateY: animateTopToBottom}],
  },
  heroImage: {
    height: ScreenHeight,
    width: ScreenWidth * 0.34,
  },
  /* Overlay View - sits on top of the whole view as RN Android cannot overflow over flexboxes
                This enables the Leap Into text to start on top of the */
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: '25%',
    zIndex: 100,
    transform: [{translateY: animateBottomToTop}],
  },
  // This is the right hand side content background.
  // It ensures the colour is set and animates from the bottom to the topz
  backgroundContent: {
    flex: 66,
    transform: [{translateY: animateBottomToTop}],
  },

  // CSS that is only used in this class
  title1: {
    // marginRight: 150,
  },
  passwordRequirements: {
    // width: '90%',
    // fontSize: 20,
    // marginLeft: 15,
    //width: 350,
    // minHeight: 100,
  },
  termsAndConditionsDescription: {
    fontSize: 17,
  },
  termsAndConditions: {
    width: '100%',
  },

  termsAndConditionsLine2: {
    fontSize: 17,
    paddingRight: 10,
  },
  termsAndConditionsLine3: {
    fontSize: 17,
    paddingRight: 10,
    textDecorationLine: 'underline',
    paddingBottom: 20,
  },
  signUp: {
    height: 60,
  },
  textInput: {
    width: '100%',
    marginTop: 0,
    marginBottom: 10,
  },
  textInputOptional: {
    width: '90%',
    paddingLeft: '10%',
    marginBottom: 10,
  },
  OrText: {
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 3,
    paddingBottom: 3,
  },
  forgotPasswordButton: {
    fontSize: 20,
    justifyContent: 'center',
    textAlign: 'center',
  },
  forgotButtonContainer: {
    minHeight: 45,
    maxHeight: 55,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
});

const mapStateToProps = (state: any) => ({
  auth: state.AuthenticationReducer,
  loadedMemberId: state.LoadedMemberReducer,
  user: state.UserReducer,
  admin: state.AdminReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    authActions: bindActionCreators(AuthenticationActions, dispatch),
    adminActions: bindActionCreators(AdminActions, dispatch),
    loadedMemberActions: bindActionCreators(loadedMemberActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
    OfflineNoticeActions: bindActionCreators(OfflineNoticeActions, dispatch),
    MemberActions: bindActionCreators(MemberActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginSignUp);
