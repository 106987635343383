import React, {Component} from 'react';
import {View, Text, Image} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {formatDateOnCurrentMonth} from '../../Helpers/Utils';

import * as SideMenuActions from '../../Actions/SideMenuActions';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import {StandardTouchableOpacity} from '../Atoms';

import {
  NOTIFICATION_STATUS,
  MESSAGES_ALREADY_APPROVED,
  MESSAGES_SA_APPROVED,
} from '../../Constants/Constants';
import {
  NOTIFICATION_TYPES,
  NOTIFICATION_DATE_FORMAT,
  BUDGETS,
  HTTP_TYPES,
  ZERO_STRING,
} from '../../Constants/Constants2';
import {PanelView} from '../Molecules/PanelView';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {MESSAGES} from '../../Constants/Messages';

import {
  PlanSummaryIconCharcoalSmall,
  crewIconCharcoal,
  BudgetsIconSmall,
  CalendarIconSmall,
  ServiceAgreementsIconSmall,
  LILogoSmall,
  EyeGray4,
  TickCircleGray,
} from '../../assets/images/vector-icons';
import {InformationIconx3} from '../../assets/images';
import CustomIcon from '../../assets/images/CustomIcon';

class NotificationRow extends Component {
  state = {
    loading: false,
    length: 0,
    expanded: this.props.expandedNotificationId == this.props.Id,
    Status: this.props.Status,
  };

  constructor(props: any) {
    super(props);
    this._renderInitialView = this._renderInitialView.bind(this);
    this._renderExpandedView = this._renderExpandedView.bind(this);
    this._onClicked = this._onClicked.bind(this);
  }

  render() {
    return (
      <View
        style={[
          CommonStyles.notificationBorderContainer,
          this._determineRead(),
          this._applyBgColor(),
        ]}
      >
        <PanelView
          renderInitialView={() => this._renderInitialView()}
          expanded={this.state.expanded}
          onPress={(expanded: any) => this._onClicked(expanded)}
        >
          {/* Expanded view items should be in ParentView children   */}
          {this.props.Description ? (
            this._isViewable() ? (
              <StandardTouchableOpacity
                accessible={false}
                onPress={() =>
                  this._linkBtnClicked(
                    this.props.Id,
                    this.props.GroupedNotificationIds,
                    this.props.Link,
                  )
                }
              >
                {this._renderExpandedView()}
              </StandardTouchableOpacity>
            ) : (
              this._renderExpandedView()
            )
          ) : null}
        </PanelView>
      </View>
    );
  }

  _isViewable = () => {
    return this.props.Link != null;
  };
  _renderApprovals = (title: any) => {
    if (this.props.Link && this.props.IsActioned == 0) {
      return (
        <View style={[CommonStyles.flexRow, CommonStyles.endContent]}>
          <View
            style={[CommonStyles.flexRow, CommonStyles.alignItemsCenter]}
            accessible={true}
            accessibilityLabel={'View'}
            accessibilityTraits={'button'}
            accessibilityComponentType={'button'}
          >
            <Text
              style={[
                BrandStyles.messageNotiDateColor,
                types.isWeb
                  ? CommonStyles.font15
                  : CommonStyles.notificationText,
                CommonStyles.customFontMedium,
                CommonStyles.textAlignVerticalTop,
              ]}
            >
              View
            </Text>
            <CustomIcon
              name={EyeGray4}
              style={[
                CommonStyles.EyeIconGray,
                CommonStyles.marginLeft5,
                BrandStyles.messageNotiDateColor,
              ]}
            />
          </View>
        </View>
      );
    } else if (this.props.IsActioned === 1) {
      {
        return (
          <View style={[CommonStyles.flexRow, CommonStyles.endContent]}>
            <CustomIcon
              name={TickCircleGray}
              style={CommonStyles.EyeIconGray}
            />
          </View>
        );
      }
    }
  };

  _renderExpandedView = () => {
    return (
      <View
        style={[
          CommonStyles.paddingTop10,
          CommonStyles.paddingBottom5,
          this._applyBgColor(),
        ]}
      >
        <View style={CommonStyles.messageExpandSeparator} />
        <View style={[CommonStyles.flexRow]}>
          <Text
            style={[
              BrandStyles.TextColor5,
              types.isWeb ? CommonStyles.font15 : CommonStyles.notificationText,
              CommonStyles.customFont,
              CommonStyles.textAlignVerticalTop,
              CommonStyles.marginTop10,
            ]}
          >
            {this.props.Description}
          </Text>
        </View>

        {this._renderApprovals(this.props.Title)}
      </View>
    );
  };

  _isServiceAgreement = (link: any) => {
    return link.includes('ServiceAgreement');
  };
  _renderInitialView = () => {
    return (
      <View style={[CommonStyles.flexColumn, this._applyBgColor()]}>
        <View style={[CommonStyles.flexRow]}>
          <CustomIcon
            accessible={false}
            name={this._determineType(this.props.Type)}
            style={[
              CommonStyles.NotificationRowicon,
              this.props.Type === NOTIFICATION_TYPES.GENERIC
                ? BrandStyles.TextColor3
                : BrandStyles.TextColor5,
            ]}
          />
          <View style={CommonStyles.MessageTileTextWrap}>
            {this.state.expanded ? (
              <Text
                style={[
                  BrandStyles.TextColor5,
                  types.isWeb
                    ? CommonStyles.font15
                    : CommonStyles.notificationRowText,
                  CommonStyles.customFontBold,
                  CommonStyles.textAlignVerticalTop,
                ]}
              >
                {this.props.Title}
              </Text>
            ) : (
              <Text
                numberOfLines={2}
                ellipsizeMode="tail"
                style={[
                  BrandStyles.TextColor5,
                  types.isWeb
                    ? CommonStyles.font15
                    : CommonStyles.notificationRowText,
                  CommonStyles.customFontBold,
                  CommonStyles.textAlignVerticalTop,
                ]}
              >
                {this.props.Title}
              </Text>
            )}
          </View>
          {this._renderUnRead()}
        </View>
        <View style={CommonStyles.paddingTop10}>
          <Text
            style={[
              BrandStyles.messageNotiDateColor,
              CommonStyles.font15,
              CommonStyles.customFontMedium,
              CommonStyles.textAlignVerticalTop,
            ]}
          >
            {formatDateOnCurrentMonth(this.props.NotificationDate)}
          </Text>
        </View>
      </View>
    );
  };
  _determineType(type: any) {
    switch (type) {
      case NOTIFICATION_TYPES.GENERIC:
        return LILogoSmall;
      case NOTIFICATION_TYPES.BUDGETS:
        return BudgetsIconSmall;
      case NOTIFICATION_TYPES.AGREEMENT:
        return ServiceAgreementsIconSmall;
      case NOTIFICATION_TYPES.DATE:
        return CalendarIconSmall;
      case NOTIFICATION_TYPES.CREW:
        return crewIconCharcoal;
      default:
        return PlanSummaryIconCharcoalSmall;
    }
  }

  _onClicked(expanded: any) {
    let link = ZERO_STRING;
    const navigationLink = this.props.Link.split('/');
    if (HTTP_TYPES.includes(navigationLink[0])) {
      link = this.props.Link;
    }
    if (this.state.Status === NOTIFICATION_STATUS.UNREAD) {
      this.props.updateNotification(
        this.props.Id,
        this.props.GroupedNotificationIds,
        link,
        this.props.IsActioned,
      );
    }
    this.setState({
      expanded: expanded,
      Status: NOTIFICATION_STATUS.READ,
    });
  }

  _linkBtnClicked(id: any, groupedNotificationIds: any, link: any) {
    if (link != null) {
      this.props.updateNotification(
        id,
        groupedNotificationIds,
        link,
        this.props.IsActioned,
      );
    }
  }

  _applyBgColor = () => {
    if (this.state.Status == NOTIFICATION_STATUS.UNREAD) {
      return CommonStyles.pickerColorWrapperGray;
    }
  };
  _determineRead = () => {
    if (this.state.Status == NOTIFICATION_STATUS.UNREAD) {
      return CommonStyles.borderColorDarkGrey;
    } else {
      return CommonStyles.borderColorGrey;
    }
  };

  _renderUnRead = () => {
    if (this.state.Status == NOTIFICATION_STATUS.UNREAD) {
      return (
        <View style={[CommonStyles.NotificationRowunreadDotContainer]}>
          <Image
            source={InformationIconx3}
            style={CommonStyles.NotificationRowiconDot}
            accessibilityLabel={MESSAGES.ACCESSIBILITY.IMPORTANT_INFORMATION}
            accessible={true}
          />
        </View>
      );
    } else {
      return null;
    }
  };
}

const mapStateToProps = (state: any) => ({
  notifications: state.NotificationReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SideMenuActions: bindActionCreators(SideMenuActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationRow);
