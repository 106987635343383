/*
 * Author: Gayan
 * Date: 29/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a standard input component which adds support for the following
 * - Error state with message
 * - Set's a default accessibilitylabel if none is passed for automation testing
 */

import React, {Component} from 'react';
import {View, TextInput, Image, Keyboard, TouchableOpacity} from 'react-native';
import {StandardText} from './';
import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';
import {BrandColors} from '../../Styles/Colours';
import {ACCESSIBILITY_LABELS} from '../../Constants/Constants2';
import {ACCESS_READ_ONLY} from '../../Constants/Constants';

import {viewIconCharcoal60, HideCharcoal60} from '../../assets/images';
let borderStyle: any;
class SecureStandardInput extends Component {
  constructor(props: any) {
    super(props);

    this.setRef = this.setRef.bind(this);
    this._renderShowHidePassword = this._renderShowHidePassword.bind(this);
    this.state = {
      secureTextEntry: props.secureTextEntry,
    };
  }

  render() {
    borderStyle = [];
    let applyWrapper, noMargin;

    // If there is no error don't display a border, otherwise show a border if an error message is passed
    if (this.props.errorMessage != '' && this.props.errorMessage != undefined) {
      borderStyle = [{borderWidth: 3}, BrandStyles.errorBorder];
    } else {
      if (!this.props.noBorder) {
        borderStyle = [{borderWidth: 1}];
      }
    }

    if (this.props.applyWrapper == undefined) {
      applyWrapper = true;
    } else {
      applyWrapper = this.props.applyWrapper;
    }

    if (this.props.noMargin == undefined) {
      noMargin = false;
    } else {
      noMargin = this.props.noMargin;
    }

    let readOnly = false;
    if (this.props.readOnly === ACCESS_READ_ONLY) {
      readOnly = true;
    } else {
      readOnly = false;
    }
    // If there is no acessibility label set use the placeholder text (For automation testing)
    // Otherwise use the label
    var accessibilityLabel =
      this.props.accessibilityLabel != undefined
        ? this.props.accessibilityLabel
        : this.props.placeholder;

    const {placeholderStyle, style, onChange, ...props} = this.props;

    if (applyWrapper) {
      return (
        <View style={this._mainStyles(noMargin)}>
          <View style={[CommonStyles.ModalTextInputContainer]}>
            <TextInput
              {...props}
              editable={!readOnly}
              placeholderTextColor={BrandColors.RESPONSIVE_DESIGN_COLORS.GREY}
              ref={input => this.setRef(input)}
              style={this._InputStyles(readOnly)}
              secureTextEntry={this.state.secureTextEntry}
            />
            {this._renderShowHidePassword()}
          </View>
          {this._renderErrorMessage()}
        </View>
      );
    } else {
      return (
        <View style={this._mainStyles(noMargin)}>
          <TextInput
            {...props}
            editable={!readOnly}
            placeholderTextColor={BrandColors.RESPONSIVE_DESIGN_COLORS.GREY}
            ref={input => this.setRef(input)}
            style={this._InputStyles(readOnly)}
            secureTextEntry={this.state.secureTextEntry}
          />
          {this._renderShowHidePassword()}
          {this._renderErrorMessage()}
        </View>
      );
    }
  }

  _renderShowHidePassword = () => {
    if (this.props.secureTextEntry) {
      return (
        <TouchableOpacity
          onPress={() =>
            this.setState({secureTextEntry: !this.state.secureTextEntry})
          }
          style={[CommonStyles.passwordShowHideIcon]}
          accessibilityLabel={ACCESSIBILITY_LABELS.VIEW_HIDE_PW}
          accessible={true}
        >
          <Image
            style={[CommonStyles.searchBoxIcon]}
            source={
              this.state.secureTextEntry ? viewIconCharcoal60 : HideCharcoal60
            }
          />
        </TouchableOpacity>
      );
    }
  };

  _InputStyles = (readOnly: any) => {
    // ToDo : removed inputFieldWrapper style
    var exportedStyles = [
      CommonStyles.customFont,
      CommonStyles.font17,
      CommonStyles.inputFieldWrapper,
      CommonStyles.colorBlack,
      this.props.style,
    ];
    if (readOnly) {
      exportedStyles.push(
        BrandStyles.readOnlyTextfieldGrey,
        BrandStyles.TextColor2,
      );
    }
    exportedStyles = [...exportedStyles, ...borderStyle];
    return exportedStyles;
  };

  setRef = (textInput: any) => {
    if (this.props.onRef) {
      this.props.onRef(textInput);
    }
  };

  _mainStyles = (noMarginFlag: any) => {
    var styles = [CommonStyles.width100];
    if (noMarginFlag) {
    } else {
      styles.push(CommonStyles.marginBottom20);
    }
    return styles;
  };

  _renderErrorMessage = () => {
    if (this.props.errorMessage) {
      return (
        <StandardText
          accessibilityLabel={this.props.errorMessage}
          style={[CommonStyles.inputErrorMessageText]}
        >
          {this.props.errorMessage}
        </StandardText>
      );
    } else {
      return null;
    }
  };
}

SecureStandardInput.defaultProps = {
  value: '',
  //secureTextEntry: false,
};

export default SecureStandardInput;
