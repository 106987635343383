/*
 * Author: Andrew Seeley
 * Date: 04/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Change Password screen for a Cognito user with an email address and password.
 */

import React, {Component} from 'react';
import {StyleSheet, Text, View, Image} from 'react-native';

import {CommonActions} from '@react-navigation/native';
import {RNkeychain as Keychain} from '../Helpers/PlatformSynchronizer';

// Import constants and messages
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import * as messages from '../Constants/Messages';

// Import APIs
import {changeCognitoPassword} from '../API/AuthenticationAPI';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
// Import other components
import {
  StandardText,
  StandardButton,
  SecureStandardInput,
} from '../Components/Atoms';
import {
  Container,
  BasicOverlayPopupModal,
  FormSubtitle,
} from '../Components/Molecules';
import {BasicForm} from '../Components/Organisms';

//import DeviceInfo from "react-native-device-info";

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import logger from 'helpers/Logger';
import {
  announceForAccessibility,
  isTablet,
} from '../Helpers/PlatformSynchronizer';
import {InformationIconx3, ChangePasswordRed4x} from '../assets/images';

class ChangePassword extends Component {
  inputs: any;
  state = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    oldPwdError: '',
    newPwdError: '',
    loading: false,
    instructionModalVisible: false,
    changePasswordSuccess: false,
  };

  constructor(props: any) {
    super(props);

    this.changePassword = this.changePassword.bind(this);
    this.savePasswordAndNavToSettings =
      this.savePasswordAndNavToSettings.bind(this);
    this._renderInstructionPopUp = this._renderInstructionPopUp.bind(this);
    this._closePlanManagedModal = this._closePlanManagedModal.bind(this);
    this.inputs = {};
  }

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        needsSidebar={false}
        loading={this.state.loading}
        screenType={types.SCREEN_TYPE_MAIN}
        activeScreen={types2.CHANGE_MY_PASSWORD_HEADER}
        selectedIcon={types.SCREEN_SETTINGS}
        nav={this.props.navigation}
        toggleMenu={
          this.props.screenProps ? this.props.screenProps?.toggleMenu : null
        }
        getInitialMenuState={
          this.props.screenProps
            ? this.props.screenProps?.getInitialMenuState
            : null
        }
        headerTitle={messages.CHANGE_PASSWORD_SCREEN.HEADER_TITLE}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _renderSaveStyles = () => {
    const style = [
      BrandStyles.brandBlockTxtColor5,
      CommonStyles.buttonFormAction,
      CommonStyles.customFontBold,
    ];
    let containerStyle = [];

    if (
      this.state.currentPassword &&
      this.state.newPassword &&
      this.state.confirmNewPassword &&
      this.state.confirmNewPassword === this.state.newPassword
    ) {
      containerStyle = [
        BrandStyles.primaryBgColor4,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerFormAction,
      ];
    } else {
      containerStyle = [
        BrandStyles.selectedBgColor1,
        BrandStyles.borderColor11,
        CommonStyles.buttonContainerFormAction,
      ];
    }

    return {
      style,
      containerStyle,
    };
  };

  _createMainContents = () => (
    <BasicForm
      headerDisplayString={messages.CHANGE_PASSWORD_SCREEN.HEADER_TITLE}
      headerIconImage={ChangePasswordRed4x}
      HeaderTextColor={BrandStyles.headerContainsDataText}
      HeaderBoarderColor={BrandStyles.headerContainsDataBorder}
      save={this.changePassword.bind(this)}
      saveLabel={messages.CHANGE_PASSWORD_SCREEN.SAVE_BUTTON_TEXT}
      close={this.cancelChange.bind(this)}
      disableMore={true}
      hideYellowStar={true}
      actionOverride={true}
      saveStyle={this._renderSaveStyles()}
      buttonsPositionBottom={true}
      imageIcon={true}
    >
      <View style={[CommonStyles.passwordRestForm]}>
        {this._renderInstructionPopUp()}
        <View style={[CommonStyles.ChangePasswordModalContentContainer]}>
          <View style={[CommonStyles.leftAboutMeModalContentContainer]}>
            <Text
              style={[
                CommonStyles.ChangePasswordModalLabel,
                CommonStyles.customFontMedium,
              ]}
            >
              {messages.CHANGE_PASSWORD_SCREEN.OLD_PASSWORD}
            </Text>
          </View>
          <View style={[CommonStyles.changePasswordTextField]}>
            <FormSubtitle
              text={[messages.MESSAGES.PLACEHOLDER.SHOW_PASSWORD]}
            />
            <SecureStandardInput
              ref="oldpassword"
              value={this.state.currentPassword}
              onChangeText={(currentPassword: any) =>
                this.setState({currentPassword})
              }
              onSubmitEditing={() => {
                const newpassword = this.inputs['newpassword'];
                if (newpassword.focus) {
                  newpassword.focus();
                }
              }}
              accessibilityLabel={messages.MESSAGES.PLACEHOLDER.OLD_PASSWORD}
              blurOnSubmit={false}
              style={[
                CommonStyles.inputFieldFont20,
                CommonStyles.ModalTextInputWrapper,
                CommonStyles.width100Per,
              ]}
              underlineColorAndroid="transparent"
              secureTextEntry={true}
              errorMessage={this.state.oldPwdError}
              hideTabOnFocus={true}
              noMargin={true}
            />
            <StandardButton
              accessibilityLabel={
                messages.FORGOT_PASSWORD.BUTTON_TEXT_1 +
                messages.FORGOT_PASSWORD.BUTTON_TEXT_2
              }
              style={[
                CommonStyles.textAlignCentre,
                CommonStyles.buttonText,
                BrandStyles.TextColor2,
                CommonStyles.rpfont12,
                CommonStyles.customFont,
              ]}
              onPress={this._forgotPassword.bind(this)}
            >
              <View
                style={[
                  CommonStyles.flexDirectionRow,
                  CommonStyles.alignCenter,
                  CommonStyles.marginTop10,
                ]}
              >
                <StandardText
                  style={[
                    CommonStyles.customFont,
                    BrandStyles.TextColor5,
                    types.isWeb ? CommonStyles.rpfont10 : CommonStyles.rpfont12,
                    CommonStyles.customFont,
                    CommonStyles.textAlignCentre,
                  ]}
                >
                  {messages.FORGOT_PASSWORD.BUTTON_TEXT_1}
                </StandardText>

                <StandardText
                  style={[
                    CommonStyles.fontWeightBold,
                    BrandStyles.TextColor5,
                    CommonStyles.termsAndConditionsLine3,
                    types.isWeb ? CommonStyles.rpfont10 : CommonStyles.rpfont12,
                    CommonStyles.customFont,
                    CommonStyles.paddingBottom0,
                    CommonStyles.textAlignCentre,
                  ]}
                >
                  {messages.FORGOT_PASSWORD.BUTTON_TEXT_2}
                </StandardText>
              </View>
            </StandardButton>
          </View>
        </View>
        <View style={[CommonStyles.ChangePasswordModalContentContainer]}>
          <View style={[CommonStyles.leftAboutMeModalContentContainer]}>
            <Text
              style={[
                CommonStyles.ChangePasswordModalLabel,
                CommonStyles.customFontMedium,
              ]}
            >
              {messages.CHANGE_PASSWORD_SCREEN.NEW_PASSWORD}
            </Text>
          </View>
          <View style={[CommonStyles.changePasswordTextField]}>
            <SecureStandardInput
              onRef={(ref: any) => {
                this.inputs['newpassword'] = ref;
              }}
              value={this.state.newPassword}
              onChangeText={(newPassword: any) => this.setState({newPassword})}
              onSubmitEditing={() => {
                const confirmNewPassword = this.inputs['confirmNewPassword'];
                if (confirmNewPassword.focus) {
                  confirmNewPassword.focus();
                }
              }}
              accessibilityLabel={messages.MESSAGES.PLACEHOLDER.NEW_PASSWORD}
              blurOnSubmit={true}
              style={[
                CommonStyles.inputFieldFont20,
                CommonStyles.ModalTextInputWrapper,
                CommonStyles.width100Per,
              ]}
              underlineColorAndroid="transparent"
              secureTextEntry={true}
              errorMessage={this.state.newPwdError}
              hideTabOnFocus={true}
              noMargin={true}
            />
          </View>
          <StandardText
            style={[
              BrandStyles.TextColor5,
              CommonStyles.customFont,
              CommonStyles.rpfont10,
              CommonStyles.marginLeft10,
            ]}
          >
            {messages.LOGIN_SIGNUP_SCREEN.PASSWORD_REQUIREMENT}
          </StandardText>
        </View>
        <View style={[CommonStyles.ChangePasswordModalContentContainer]}>
          <View style={[CommonStyles.leftAboutMeModalContentContainer]}>
            <Text
              style={[
                CommonStyles.ChangePasswordModalLabel,
                CommonStyles.customFontMedium,
              ]}
            >
              {messages.CHANGE_PASSWORD_SCREEN.RE_ENTER_PASSWORD}
            </Text>
          </View>
          <View style={[CommonStyles.changePasswordTextField]}>
            <SecureStandardInput
              onRef={(ref: any) => {
                this.inputs['confirmNewPassword'] = ref;
              }}
              value={this.state.confirmNewPassword}
              onChangeText={(confirmNewPassword: any) =>
                this.setState({confirmNewPassword})
              }
              accessibilityLabel={
                messages.MESSAGES.PLACEHOLDER.RE_ENTER_PASSWORD
              }
              onSubmitEditing={() => this.changePassword()}
              blurOnSubmit={true}
              style={[
                CommonStyles.inputFieldFont20,
                CommonStyles.ModalTextInputWrapper,
                CommonStyles.width100Per,
              ]}
              underlineColorAndroid="transparent"
              secureTextEntry={true}
              errorMessage={this.state.newConfPwdError}
              hideTabOnFocus={true}
            />
          </View>
        </View>
      </View>
    </BasicForm>
  );

  _closePlanManagedModal() {
    this.setState({
      instructionModalVisible: false,
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    });
    if (this.state.changePasswordSuccess) {
      if (!types.isWeb) {
        this.props.navigation.dispatch(CommonActions.goBack());
      } else {
        this.props.navigation.goBack();
      }
    }
  }

  _determinePopUpStyle = (isTablet: any) => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      styles.push([CommonStyles.containerInstructionPopUpWeb]);

      if (this.props.isMobile) {
        styles.push([CommonStyles.containerPopupWebMobile]);
      }
    } else if (isTablet()) {
      styles.push(
        this.state.changePasswordSuccess
          ? CommonStyles.containerInstructionPopUpTablet
          : CommonStyles.containerChangePasswordPopUpTablet,
      );
    } else {
      styles.push(
        CommonStyles.changePasswordPopUpMobile,
        this.state.changePasswordSuccess
          ? CommonStyles.height230
          : CommonStyles.height300,
      );
    }
    return styles;
  };

  _renderInstructionPopUp() {
    return (
      <BasicOverlayPopupModal
        visible={this.state.instructionModalVisible}
        style={this._determinePopUpStyle(isTablet)}
        backdropOpacity={0.2}
        divider={true}
        close={this._closePlanManagedModal}
        cancelContainerStyle={CommonStyles.popUpOkButton}
        cancelStyle={[CommonStyles.buttonPopupOk, CommonStyles.customFontBold]}
        cancelLabel={types2.OK_GOT_IT}
      >
        <View>{this.getPopUpContent(isTablet)}</View>
      </BasicOverlayPopupModal>
    );
  }

  getPopUpContent(isTablet: any) {
    let headerTitle = messages.CHANGE_PASSWORD_SCREEN.PASSWORD_CHANGED;
    let fontSize = CommonStyles.rpfont15;
    let headerFontSize = CommonStyles.font20;
    if (types.isWeb) {
      fontSize = CommonStyles.font15;
      headerFontSize = CommonStyles.font18;
    } else if (isTablet()) {
      fontSize = CommonStyles.font18;
      headerFontSize = CommonStyles.font25;
    }

    let bodyContent = [
      <StandardText
        style={[CommonStyles.customFont, BrandStyles.TextColor5, fontSize]}
      >
        {messages.CHANGE_PASSWORD_SCREEN.PASSWORD_CHANGED_MESSAGE}
      </StandardText>,
    ];

    if (!this.state.changePasswordSuccess) {
      headerTitle = messages.CHANGE_PASSWORD_SCREEN.WRONG_PASSWORD;

      bodyContent = [
        <View>
          <StandardText
            style={[
              CommonStyles.customFont,
              BrandStyles.TextColor5,
              fontSize,
              CommonStyles.paddingBottom5p,
            ]}
          >
            {messages.CHANGE_PASSWORD_SCREEN.WRONG_PASSWORD_MESSAGE}
          </StandardText>
          <StandardButton
            accessibilityLabel={
              messages.FORGOT_PASSWORD.BUTTON_TEXT_1 +
              messages.FORGOT_PASSWORD.BUTTON_TEXT_2
            }
            style={[BrandStyles.TextColor2, fontSize, CommonStyles.customFont]}
            onPress={this._forgotPassword.bind(this)}
          >
            <View
              style={[CommonStyles.flexDirectionRow, CommonStyles.flexWrap]}
            >
              <View>
                <StandardText
                  style={[
                    CommonStyles.customFont,
                    BrandStyles.TextColor5,
                    fontSize,
                  ]}
                >
                  {messages.CHANGE_PASSWORD_SCREEN.REQUEST_NEW_PASSWORD}
                </StandardText>
              </View>
              <View style={CommonStyles.alignItemsCenter}>
                <StandardText
                  style={[
                    CommonStyles.customFont,
                    BrandStyles.TextColor5,
                    fontSize,
                  ]}
                >
                  {'"' + messages.FORGOT_PASSWORD.BUTTON_TEXT_1}
                </StandardText>
              </View>
              <View
                style={[
                  CommonStyles.alignItemsCenter,
                  CommonStyles.paddingBottom10,
                ]}
              >
                <StandardText
                  style={[
                    CommonStyles.customFontBold,
                    BrandStyles.TextColor5,
                    fontSize,
                    CommonStyles.textLink,
                  ]}
                >
                  {messages.FORGOT_PASSWORD.BUTTON_TEXT_2 + '"'}
                </StandardText>
              </View>
            </View>
          </StandardButton>
        </View>,
      ];
    }

    return (
      <View style={[]}>
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.scrollView_subContentCenter,
          ]}
        >
          {!this.state.changePasswordSuccess ? (
            <View>
              <Image
                style={[CommonStyles.changePasswordButtonImage]}
                source={InformationIconx3}
                accessibilityLabel={
                  messages.MESSAGES.ACCESSIBILITY.IMPORTANT_INFORMATION
                }
                accessible={true}
              />
            </View>
          ) : null}
          <View style={[CommonStyles.scrollView_subContentCenter]}>
            <StandardText
              style={[
                headerFontSize,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
              ]}
            >
              {headerTitle}
            </StandardText>
          </View>
        </View>
        <View
          style={[
            types.isWeb
              ? CommonStyles.paddingTop10p
              : CommonStyles.paddingTop5p,
            CommonStyles.scrollView_subContentCenter,
            CommonStyles.paddingBottom10,
          ]}
        >
          {bodyContent}
        </View>
      </View>
    );
  }

  _forgotPassword() {
    this.setState({
      loading: false,
      instructionModalVisible: false,
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    });
    // Navigate to forgot password
    this.props.navigation.navigate('ForgotPassword');
  }

  /*
   * Change the user's password from the current to the new one.
   * Will implement some validation on the password, such as length.
   */
  changePassword() {
    // If new or old password length is not 8 or longer, display an error to the user
    var newPasswordLength = this.state.newPassword.length;
    var currentPasswordLength = this.state.currentPassword.length;
    var confirmNewPasswordLength = this.state.confirmNewPassword.length;

    let errorMessage = {
      newPasswordError: '',
      oldPasswordError: '',
      confirmNewPasswordError: '',
      passwordsDoesNotMatch: '',
    };

    if (
      newPasswordLength < 8 ||
      currentPasswordLength < 8 ||
      confirmNewPasswordLength < 8
    ) {
      // Determine which password length is too short
      if (newPasswordLength < 8) {
        errorMessage.newPasswordError =
          messages.CHANGE_PASSWORD_SCREEN.NEW +
          messages.MESSAGES.PASSWORD.LENGTH;
      }

      if (currentPasswordLength < 8) {
        errorMessage.oldPasswordError =
          messages.CHANGE_PASSWORD_SCREEN.OLD +
          messages.MESSAGES.PASSWORD.LENGTH;
      }

      if (confirmNewPasswordLength < 8) {
        errorMessage.confirmNewPasswordError =
          messages.CHANGE_PASSWORD_SCREEN.RE_ENTERED +
          messages.MESSAGES.PASSWORD.LENGTH;
      }
    } else if (this.state.newPassword !== this.state.confirmNewPassword) {
      errorMessage.confirmNewPasswordError =
        messages.CHANGE_PASSWORD_SCREEN.PASSWORD_DONT_MATCH;
    } else {
      this.setState({loading: true});
      // Otherwise change the password and alert the user if it was successfull or unsucessfull
      changeCognitoPassword(this.state.currentPassword, this.state.newPassword)
        .then(result => {
          this.setState({loading: false});

          if (!types.isWeb) {
            // Fetch the saved credentials so that it can be updated with the new password
            Keychain.getInternetCredentials(types.COGNITO_KEY).then(
              credentials => {
                if (credentials) {
                  this.savePasswordAndNavToSettings(credentials.username);
                }
                // No credentials saved
                else {
                  // TODO: Should never reach here, but will need to figure out what happens.
                  logger.log(
                    'No credentials when getting previously saved credential.',
                  );
                }
              },
            );
          } else {
            this.setState({
              changePasswordSuccess: true,
              instructionModalVisible: true,
            });
          }
        })
        .catch(error => {
          logger.log('error ', error);
          let message = messages.ERRORS.INCORRECT_PASSWORD;
          if (error.name == types.LIMIT_EXCEED) {
            message = messages.ERRORS.LIMIT_EXCEEDED;
          } else if (error.name == types.NETWORK_FAILURE) {
            logger.log('Error Name: ', error.name);
            message = messages.ERRORS.NETWORK_FAILURE;
          }

          if (message == messages.ERRORS.INCORRECT_PASSWORD) {
            this.setState({
              loading: false,
              changePasswordSuccess: false,
              instructionModalVisible: true,
            });
          } else {
            this.setState({
              loading: false,
              oldPwdError: message,
            });
          }

          announceForAccessibility(message);
        });
    }

    // Set the state with the error messages
    this.setState({
      oldPwdError: errorMessage.oldPasswordError,
      newPwdError: errorMessage.newPasswordError,
      newConfPwdError: errorMessage.confirmNewPasswordError,
    });
    var readThisText =
      errorMessage.oldPasswordError +
      ' ' +
      errorMessage.newPasswordError +
      ' ' +
      errorMessage.confirmNewPasswordError;
    announceForAccessibility(readThisText);
  }

  /**
   * Function that will save the new password and then transition back to the settings screen.
   * @param {string} username The username that will need to be saved along with the password.
   */
  savePasswordAndNavToSettings(username: any) {
    // Update the old username and password
    Keychain.setInternetCredentials(
      types.COGNITO_KEY,
      username,
      this.state.newPassword,
    )
      .then(() => {
        // The update has completed successfully
        // Set the password to be changed when going back
        this.setState({
          changePasswordSuccess: true,
          instructionModalVisible: true,
        });
      })
      .catch(error => {
        // TODO: Need to fix the error handling here
        logger.log('An error occurred when saving the password: ', error);
      });
  }

  /**
   * Cancels the change in password, so it will navigate back to the settings screen.
   */
  cancelChange() {
    this.props.navigation.goBack(null);
  }

  /*
   * Changes the focus to the given field
   * @param {string} nextField The ref name of the input on the next field.
   */
  focusNextField(nextField: any) {
    this.refs[nextField].focus();
  }
}

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
