/*
 * Author: Declan Hart
 * Date: 10/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * TODO: <Summary of why we have this class>
 */

import React, {Component} from 'react';
import {StyleSheet, View} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {setImageRedux} from '../../Helpers/ImageHelper';

import {
  StandardText,
  SupportTileDetails,
  StandardTouchableOpacity,
} from '../Atoms';
import {ActionButton, PhotoTile} from './';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import * as SupportsActions from '../../Actions/SupportsActions';
import * as AvatarListActions from '../../Actions/AvatarListActions';
import {getAvatarImages} from '../../API/MemberAPI';
import {callAPIs} from '../../API/APICaller';

import * as NavigationParamActions from '../../Actions/NavigationParamActions';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {setValue, getValue, removeItem} from '../../API/WebPersistenceStore';

const SELECTED = ' selected';

class SupportTile extends Component {
  state = {
    isLoading: false,
    supportImage: null,
    lastUpdate: new Date(),
  };

  constructor(props: any) {
    super(props);
    this.renderFrequency = this.renderFrequency.bind(this);
    this.calculateHourWeek = this.calculateHourWeek.bind(this);
    this.calculateUOMEach = this.calculateUOMEach.bind(this);
  }

  renderFrequency() {
    if (this.props.support.unitOfMeasure) {
      switch (this.props.support.unitOfMeasure) {
        case types.HOUR:
          return this.calculateHourWeek();
        case types.WEEK:
          return this.calculateHourWeek();
        case types.YEAR:
          return types.YEAR;
        case types.EACH:
          return this.calculateUOMEach();
        case types.DAY:
          return this.calculateUOMEach();
        default:
          return null;
      }
    } else {
      return null;
    }
  }

  calculateHourWeek() {
    if (this.props.support.supportFields != null) {
      const frequencyObject = this.props.support.supportFields.find(
        (item: any) => {
          return item.fieldType === types.FREQUENCY;
        },
      );
      return frequencyObject != undefined ? frequencyObject.value : null;
    } else {
      return null;
    }
  }

  calculateUOMEach() {
    if (this.props.support.supportFields != null) {
      const frequencyObject = this.props.support.supportFields.find(
        (item: any) => {
          return item.fieldType === types.TIMESPERYEAR;
        },
      );
      return frequencyObject != undefined && frequencyObject.units != null
        ? frequencyObject.units + ' times per year'
        : null;
    } else {
      return null;
    }
  }

  _determineAccess = () => {
    if (this.props.readOnly == types2.READ_SIMPLE) {
      return types.ACTION_BUTTON.VIEW;
    }
    return types.ACTION_BUTTON.EDIT;
  };

  renderEdit = (viewOrEdit: any) => {
    if (this.props.navigateToEdit) {
      return (
        <StandardTouchableOpacity
          accessibilityLabel={viewOrEdit}
          style={[CommonStyles.textAlignVertical]}
          onPress={() => {
            this.props.navigateToEdit(
              this.props.support.categoryId,
              this.props.support.supportGroupId,
              this.props.support,
            );
          }}
        >
          <ActionButton
            actionType={this._determineAccess()}
            labelStyle={'bottom'}
          />
        </StandardTouchableOpacity>
      );
    } else {
      return null;
    }
  };

  render() {
    let viewOrEdit = types2.EDIT;
    const supportingCrew = this.props.support.supportingCrew
      ? this.props.support.supportingCrew
      : [];
    const supportingOrgCrew = this.props.support.supportingOrgCrew
      ? this.props.support.supportingOrgCrew
      : [];
    const SupportCrew = [...supportingCrew, ...supportingOrgCrew];

    if (this.props.readOnly === types2.READ_SIMPLE) {
      viewOrEdit = types2.VIEW;
    }

    return (
      <View style={CommonStyles.justifyFlexRowStart}>
        {this.renderPhoneTile()}
        <View
          style={[
            CommonStyles.containerSupportSummaryDetail,
            BrandStyles.primaryBgColor1,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.text_SupportContainer,
              CommonStyles.customFontBold,
            ]}
          >
            {this.props.support.title}
          </StandardText>
          <SupportTileDetails
            frequency={this.renderFrequency()}
            opinion={this.props.support.opinion}
            crew={SupportCrew}
          />
        </View>
        <View style={[CommonStyles.containerSupportTileEdit]}>
          {this.renderEdit(viewOrEdit)}
        </View>
      </View>
    );
  }

  renderPhoneTile = () => {
    let readOutSupport = types2.DEFUALT_SUPPORT_CAPTION;
    if (this.state.photoCaption) {
      readOutSupport = this.state.photoCaption + SELECTED;
    } else if (this.props.support.photoLocation) {
      readOutSupport =
        this.props.support.photoLocation.split('/').pop() + SELECTED;
    }

    return (
      <PhotoTile
        borderRadius={CommonStyles.borderRadius15}
        readOnly={this.props.readOnly}
        style={StyleSheet.flatten(CommonStyles.containerPhotoTileImage_Summary)}
        imageGap={5}
        bottomMargin={false}
        flexRatio={CommonStyles.flex1}
        apiFileType={types2.FILE_TYPE.SUPPORT_PHOTO}
        imageObject={this.props.support.supportPhoto}
        showCaption={false}
        actionCallback={this._navigateAddSupportImage}
        clickable={true}
        lastUpdate={this._lastUpdateTime(this.props.support)}
        accessibilityLabel={readOutSupport}
      />
    );
  };

  _navigateAddSupportImage = () => {
    const navigationParams = this.props.actions.NavParamActions.setParam({
      imageObject: this.props.support.supportPhoto,
      headerTitle: types2.ADD_SUPPORT_PICTURE_TITLE,
      mainHeader: types2.ADD_SUPPORT_PICTURE_HEADER,
      photoType: types2.FILE_TYPE.SUPPORT_PHOTO,
      supportItem: this.props.support,
    });
    if (types.isWeb) {
      removeItem(types2.WEB_STORE.NAVIGATION_PARAMS);
      setValue(
        types2.WEB_STORE.NAVIGATION_PARAMS,
        JSON.stringify(navigationParams),
      );
    }
    this.props.navigation.navigate('ProfilePictureForm');
    this._getAvatarImages();
  };

  _lastUpdateTime = (supportItem: any) => {
    if (
      supportItem.supportPhoto !== undefined &&
      supportItem.imageUploadObject === undefined &&
      supportItem.supportPhoto.lastModified
    ) {
      return new Date(supportItem.supportPhoto.lastModified);
    } else {
      return new Date();
    }
  };

  _getAvatarImages = () => {
    const callbackFunction = (data: any) => {
      const avatarList = data[0].content;
      this.props.actions.AvatarListActions.actionGetAvatarList(avatarList);

      if (types.isWeb) {
        removeItem(types2.WEB_STORE.AVATAR_LISTOBJ);
        setValue(types2.WEB_STORE.AVATAR_LISTOBJ, JSON.stringify(avatarList));
      }

      this.setState({
        loading: false,
      });
    };

    callAPIs(
      [getAvatarImages(this.props.loadedMemberId.loadedMemberId)],
      callbackFunction,
      null,
      () => {
        this.setState({loading: false});
      },
    );
  };
}

const mapStateToProps = (state: any) => ({
  supports: state.SupportsReducer,
  loadedMemberId: state.LoadedMemberReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
    AvatarListActions: bindActionCreators(AvatarListActions, dispatch),
    NavParamActions: bindActionCreators(NavigationParamActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportTile);
