
import { createNavigationContainerRef } from '@react-navigation/native';
export const navigationRef = createNavigationContainerRef();
export function navigate(name:any, params: any) {
  if (navigationRef.isReady()) {
    navigationRef.navigate(name, params);
  }
}
export function getCurrentRoute(){
  if (navigationRef.isReady()) {
    const route=navigationRef.getCurrentRoute();
   return route.name;
  }
}

export function goBack() {
  if (navigationRef.isReady()) {
    navigationRef.goBack();
  }
}

export function replace(name : string, params: any = {}) {
  if (navigationRef.isReady()) {
    navigationRef.replace(name, params);
  }
}