/*
 * Author: Nirodha Perera
 * Date: 04/10/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays a title and expand/collapse button for payment history.
 * clicking the expand/collapse button will show/hide the content within the tile
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {MESSAGES, ACCORDIAN} from '../../Constants/Messages';

import {StandardText, StandardTouchableOpacity} from '../Atoms';

import {
  arrowCollapseCharcoal60,
  arrowExpandCharcoal60,
} from '../../assets/images';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import {isTablet} from '../../Helpers/PlatformSynchronizer';

class AccordianPaymentTile extends Component {
  openByDefault: any;
  state = {
    collapsed: true,
  };

  constructor(props: any) {
    super(props);
    this._determineLabelStyle = this._determineLabelStyle.bind(this);
    this.openByDefault = props.openByDefault ? props.openByDefault : false;
  }

  componentDidMount() {
    if (this.openByDefault) {
      this._toggleAccordian();
    }
  }

  render() {
    return (
      <View style={[CommonStyles.containerInvoice_Accordian]}>
        {this._renderTitle()}
        {this._renderChildren()}
      </View>
    );
  }

  _renderTitle = () => {
    if (isTablet) {
      return (
        <View
          style={[
            CommonStyles.containerPayment_AccordianHeader,
            BrandStyles.borderColor6,
          ]}
        >
          <StandardTouchableOpacity
            accessibilityLabel={this._determineLabel()}
            activeOpacity={0.6}
            onPress={() => {
              this._toggleAccordian();
            }}
          >
            <View style={[CommonStyles.containerInvoice_AccordianButton]}>
              <StandardText style={this._determineLabelStyle()}>
                {this.props.title}
              </StandardText>
              <Image
                style={[CommonStyles.accordianButtonIcon]}
                source={this._determineIcon()}
              />
            </View>
          </StandardTouchableOpacity>
        </View>
      );
    } else {
      return (
        <View
          style={[
            CommonStyles.containerInvoice_AccordianHeader,
            BrandStyles.borderColor6,
          ]}
        >
          <StandardTouchableOpacity
            accessibilityLabel={this._determineLabel()}
            activeOpacity={0.6}
            onPress={() => {
              this._toggleAccordian();
            }}
          >
            <View style={[CommonStyles.containerInvoice_AccordianButton]}>
              <Image
                style={[CommonStyles.accordianButtonIcon]}
                source={this._determineIcon()}
              />
              <StandardText style={this._determineLabelStyle()}>
                {this.props.title}
              </StandardText>
            </View>
          </StandardTouchableOpacity>
        </View>
      );
    }
  };

  _renderChildren = () => {
    if (this.state.collapsed) {
      return null;
    } else {
      return (
        <View
          style={[
            CommonStyles.summaryTileText,
            CommonStyles.summaryTileTextContainer,
          ]}
        >
          {this.props.children}
        </View>
      );
    }
  };

  _determineIcon = () => {
    if (this.state.collapsed) {
      return this.props.icons.expand;
    } else {
      return this.props.icons.collapse;
    }
  };

  _determineLabel = () => {
    if (this.state.collapsed) {
      return ACCORDIAN.EXPAND;
    } else {
      return ACCORDIAN.COLLAPSE;
    }
  };

  _determineLabelStyle = () => {
    let styles = [
      CommonStyles.customFontSemiBold,
      CommonStyles.font18,
      CommonStyles.paddingRight10,
    ];
    if (this.props.buttonColor) {
      styles.push(this.props.buttonColor);
    } else {
      styles.push(BrandStyles.TextColor5);
    }
    return styles;
  };

  _toggleAccordian = () => {
    let flag = !this.state.collapsed;
    if (this.props.callback) {
      this.props.callback(flag);
    }
    this.setState({collapsed: flag});
  };
}

export default AccordianPaymentTile;
