/*
 * Author: Sujeban Elankeswaran
 * Date: 20/06/22
 * Copyright © 2022 Leap in!. All rights reserved.
 *
 * Simple Checkbox
 */

import React from 'react';
import {BrandColors} from '../../Styles/Colours';

const style = {
  borderColor: BrandColors.BLACK,
  backgroundColor: BrandColors.WHITE,
  width: 20,
  height: 20,
};
const SimpleCheckbox = (props: any) => {
  return (
    <input
      type="checkbox"
      style={style}
      checked={props.isChecked}
      onChange={props.onValueChange}
    />
  );
};

export default SimpleCheckbox;
