/*
 * Author: Declan Hart
 * Date: 09/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Supports information.
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function SupportCategoriesReducer(state = {}, action: any) {
  switch (action.type) {
    case types.GET_SUPPORT_CATEGORIES:
      // Removes any duplicate category reference objects that may have been returned by the API
      const filteredCategories = action.CategoriesObj.categories.filter(
        (category: any, index: any, arr: any) =>
          arr
            .map((mapObj: any) => mapObj.categoryId)
            .indexOf(category.categoryId) === index,
      );

      return {
        apiCalled: new Date(),
        categories: filteredCategories,
      };

    default:
      return state;
  }
}
