/*
 * Author: Joshua Merkas
 * Date: 01/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The AboutMe Screen, an Aboutme placeholder screen.
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import {SCREEN_TYPE_MAIN, isWeb} from '../Constants/Constants';
import {NAVIGATION_TEXT} from '../Constants/Constants2';

import {
  AboutMeMyDetails,
  AboutMeMoreAboutMe,
  AboutMeLifeStage,
  AboutMeDisability,
} from '../Components/Organisms';
import {Container} from '../Components/Molecules';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {focusNextField} from '../Components/FocusNextField/FocusNextField';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';

import {getMember} from '../API/MemberAPI';
import {callAPIs} from '../API/APICaller';
import * as MemberActions from '../Actions/MemberActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';

class AboutMe extends Component {
  state = {
    selectedLifeStages: [],
    loading: true,
    refreshing: false,
  };

  constructor(props: any) {
    super(props);
    this._navigateToAdditionalInfoForm =
      this._navigateToAdditionalInfoForm.bind(this);
    this._showLifestageModal = this._showLifestageModal.bind(this);
    this._showPrimaryDisabilityModal =
      this._showPrimaryDisabilityModal.bind(this);
    this._navigateLeifeStageForm = this._navigateLeifeStageForm.bind(this);
    this._navigateToAboutMeDisabilityForm =
      this._navigateToAboutMeDisabilityForm.bind(this);
    this._createMainContents.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (this.props.member.lifeStages != nextProps.member.lifeStages) {
      this.setState({
        selectedLifeStages: JSON.parse(
          JSON.stringify(nextProps.member.lifeStages),
        ),
      });
    }
  }

  componentDidMount() {
    this.setState({loading: true});
    this.fetchData();
  }

  fetchData = () => {
    const callbackFunction = (data: any) => {
      const member = data[0].content;
      this.props.actions.MemberActions.actionGetMember(
        member,
        this.props.user.id,
        this.props.user.isEmployee,
        this.props.user.isFinancialStaff,
      );

      this.setState({
        selectedLifeStages: this.props.member.lifeStages,
        loading: false,
        refreshing: false,
      });
    };
    callAPIs(
      [
        getMember(
          this.props.loadedMemberId.loadedMemberId,
          this.props.user.demoProfile,
        ),
      ],
      callbackFunction,
      null,
      () => {
        this.setState({loading: false, refreshing: false});
      },
    );
  };

  render() {
    if (isWeb) {
      return this._createMainContents();
    }

    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={true}
        screenType={SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={NAVIGATION_TEXT.ABOUT_ME}
        onRefresh={() => {
          this.setState({refreshing: true});
          this.fetchData();
        }}
        refreshing={this.state.refreshing}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _navigateToAdditionalInfoForm = () =>
    this.props.navigation.navigate('AboutMeAdditionalInfoForm');

  _showLifestageModal = () => {
    this.props.navigation.navigate('AboutMeLifeStageModal');
  };

  _showPrimaryDisabilityModal = () => {
    this.props.navigation.navigate('AboutMeDisability');
  };

  _navigateLeifeStageForm = () => {
    this.props.navigation.navigate('AboutMeLifeStageForm');
  };

  _createMainContents = () => (
    <View style={[CommonStyles.content]}>
      <View style={[CommonStyles.mainContentContainer]}>
        <View accessible={false} style={[CommonStyles.columnContainer]}>
          <AboutMeMyDetails
            reducer={this.props.member}
            form={this._navigateToAboutMeMyDetailsForm}
          />
          <AboutMeLifeStage
            reducer={this.props.member}
            form={this._navigateLeifeStageForm}
            lifeStages={this.state.selectedLifeStages}
          />
          <AboutMeDisability
            reducer={this.props.member}
            form={this._navigateToAboutMeDisabilityForm}
          />
          <AboutMeMoreAboutMe
            reducer={this.props.member}
            form={this._navigateToAdditionalInfoForm}
          />
        </View>
      </View>
    </View>
  );

  _navigateToAboutMeDisabilityForm = () => {
    this.props.navigation.navigate('AboutMeDisabilityForm');
  };

  _navigateToAboutMeMyDetailsForm = () => {
    this.props.navigation.navigate('AboutMeMyDetailsForm');
  };
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    MemberActions: bindActionCreators(MemberActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutMe);
