import React from 'react';
import {Platform, View} from 'react-native';
import {WebView} from 'react-native-webview';

interface IWebViewWeb {
  title: string;
  CommonStyles?: any;
  source?: any;
  domStorageEnabled?: boolean;
  startInLoadingState?: boolean;
  scalesPageToFit?: any;
  srcDoc?: boolean;
  scrollEnabled?: boolean;
}

const WebViewWeb = ({
  title,
  style,
  source,
  srcDoc = false,
  startInLoadingState,
  domStorageEnabled,
  scalesPageToFit,
  scrollEnabled = true,
}: IWebViewWeb) => {
  return (
    <View style={{flex: 1}}>
      {Platform.OS === 'web' ? (
        srcDoc ? (
          <iframe
            title={title}
            style={{flex: 1, border: 'none'}}
            srcDoc={source} // Call applyCSS directly
          />
        ) : (
          <iframe
            title={title}
            style={{flex: 1,border: 'none'}}
            src={source} // Call applyCSS directly
          />
        )
      ) : srcDoc ? (
        <WebView
          scrollEnabled={scrollEnabled}
          scalesPageToFit={scalesPageToFit}
          originWhitelist={['*']}
          style={{flex: 1}}
          source={{html: source}} // Call applyCSS directly
          domStorageEnabled={domStorageEnabled}
          startInLoadingState={startInLoadingState}
          javaScriptEnabled={false}
        />
      ) : (
        <WebView
          scrollEnabled={scrollEnabled}
          scalesPageToFit={scalesPageToFit}
          originWhitelist={['*']}
          style={{flex: 1}}
          source={{uri: source}} // Call applyCSS directly
          domStorageEnabled={domStorageEnabled}
          startInLoadingState={startInLoadingState}
          javaScriptEnabled={false}
        />
      )}
    </View>
  );
};

export {WebViewWeb};
