/*
 * Author: Declan Hart
 * Date: 16/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Display Support details
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import {Cost, Frequency, Usage} from '../Atoms';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';

class UnitOfMeasureWeek extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <View>
        <Usage
          options={types.WEEKLYUSAGEVALUES}
          save={this.props.save}
          selectedValues={this.props.values.usage}
          readOnly={this.props.readOnly}
        />
        <View style={[CommonStyles.justifyFlexColumnStart]}>
          <Frequency
            save={this.props.save}
            selectedValues={this.props.values.frequency}
            readOnly={this.props.readOnly}
          />
          <Cost
            save={this.props.save}
            selectedValues={this.props.values.cost}
            readOnly={this.props.readOnly}
          />
        </View>
      </View>
    );
  }
}

export default UnitOfMeasureWeek;
