/*
 * Author: Tharindu Bandara
 * Date: 18/02/2019
 * Copyright © 2019 Leap in!. All rights reserved.
 *
 * Navigation params actions for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const setParam = (params: any) => ({
  type: types.SET_NAVIGATION_PARAM,
  params,
});
