/*
 * Author: Brad D'Costa
 * Date: 05/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Plan Manager Selection Actions for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const actionSetManagement = (managementObj: any) => ({
  type: types.PLAN_MANAGEMENT.SET_MANAGEMENT,
  managementObj,
});
