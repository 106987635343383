/*
 * Author: Mayuran
 * Date: 03/10/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 */

import React, {Component} from 'react';
import {View, Dimensions} from 'react-native';
import {StandardText} from '../Atoms';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import CommonStyles from '../../Styles/CommonStyles';
import {BrandColors} from '../../Styles/Colours';
import BrandStyles from '../../Styles/BrandStyles';
import * as MemberActions from '../../Actions/MemberActions';
// import Triangle from 'react-native-triangle';
import DeviceInfo from 'react-native-device-info';
// import Orientation from 'react-native-orientation-enhanced';
const isTablet = DeviceInfo.isTablet();

const triangleSize = {height: 10, width: 15};
const triangleRightOffSet = {tablet: 55, phone: 20};

class MemberPopOver extends Component {
  state = {
    orientation: '',

    title: this.props.member
      ? (this.props.member.firstName ? this.props.member.firstName : '') +
        ' ' +
        (this.props.member.lastName ? this.props.member.lastName : '')
      : '',
  };
  constructor(props: any) {
    super(props);
  }
  // UNSAFE_componentWillMount() {
  //   Orientation.getOrientation((err: any, orientation: any) => {
  //     this.setState({
  //       orientation: orientation,
  //     });
  //   });
  // }

  componentDidMount() {
    // Orientation.addOrientationListener(this._orientationDidChange);
  }

  componentWillUnmount() {
    // Orientation.removeOrientationListener(this._orientationDidChange);
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation: orientation,
    });
  };

  UNSAFE_componentWillReceiveProps(props: any) {
    if (props.member) {
      this.setState({
        title:
          (props.member.firstName ? props.member.firstName : '') +
          ' ' +
          (props.member.lastName ? props.member.lastName : ''),
      });
    }
  }

  render() {
    const {height, width} = Dimensions.get('window');
    if (
      /\S/.test(this.state.title) &&
      (this.props.user.isCrew || this.props.user.isEmployee)
    ) {
      return (
        <View
          style={[
            CommonStyles.title_popOverContainer,
            !isTablet ? CommonStyles.right10 : null,
          ]}
        >
          <View style={[CommonStyles.title_popOverTextContainer]}>
            <StandardText
              style={[
                CommonStyles.customFont,
                BrandStyles.brandBlockTxtColor,
                CommonStyles.rpfont11,
                CommonStyles.textAlignCentre,
                isTablet ? null : CommonStyles.paddingTop5,
              ]}
            >
              {this.state.title}
            </StandardText>
          </View>

          <View
            style={[
              CommonStyles.flexDirectionRow,
              CommonStyles.alignFlexEnd,
              {
                paddingRight: isTablet
                  ? width / 8 - triangleRightOffSet.tablet
                  : width / 8 - triangleRightOffSet.phone,
              },
            ]}
          >
            {/* <Triangle
              width={triangleSize.width}
              height={triangleSize.height}
              color={BrandColors.RESPONSIVE_DESIGN_COLORS.YELLOW}
              direction={'down'}
            /> */}
          </View>
        </View>
      );
    } else {
      return null;
    }
  }
}
const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  user: state.UserReducer,
});

export default connect(mapStateToProps)(MemberPopOver);
