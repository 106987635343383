/*
 * Author: Brad D'Costa
 * Date: 02/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Living Arrangement Reference actions for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const actionGetLivingArrangementRef = (livingRefObj: any) => ({
  type: types.GET_LIVING_ARRANGEMENT_REFERENCE,
  livingRefObj,
});
