/*
 * Author: Brad D'Costa
 * Date: 11/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Web Service class to handle the User API calls
 */

// Custom functions for helping connect to the secure AWS API Gateway
import {
  initaliseAwsClient,
  configuredAWSLamda,
  buildLambda,
  parseResult,
  logPerf,
  apiErrorHandler,
} from './APIHelper';
import {
  AWS_LAMBDA_SERVICES,
  AWS_LAMBDA_CONFIG_ITEM,
  AWS_REGION,
  APP_VERSION,
  API_URL,
} from '../environments';

// Imports for token and device information
import {Platform} from 'react-native';

import AWS from 'aws-sdk';
import {
  getVersion,
  getManufacturer,
  getBrand,
  getModel,
  getFirebaseMessageToken,
} from '../Helpers/PlatformSynchronizer';
import logger from 'helpers/Logger';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import DeviceInfo from 'react-native-device-info';

import {getCurrentUserIdToken} from './AuthenticationAPI';
//* ********************************
//*          U S E R S
//* ********************************

/*
 * Returns a user with a given user ID
 * @async
 * @param {string} cognitoId The cognito ID of the user you want to return
 * @returns {Promise<object>} The user object
 */
export const getUser = (
  cognitoId: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise(async (resolve, reject) => {
      const idToken = await getCurrentUserIdToken();
      // Use the lambda function directly from the AWS sdk
      const getUserByCognitoId = configuredAWSLamda();
      //to to find a solution to FCM for WEB & Mobile

      if (!types.isWeb) {
        getFirebaseMessageToken().then(async token => {
          const deviceManufacturer = await getManufacturer();
          const requestBody = {
            cognitoId,
            isDemoProfile,
            deviceOS: Platform.OS,
            deviceVersion: `${Platform.Version}`,
            deviceToken: token,
            deviceManufacturer,
            deviceBrand: getBrand(),
            deviceModel: getModel(),
            version: getVersion(),
            token: idToken,
          };
          const functionName = 'getUserByCognitoId';
          logger.log('Lambda::', functionName);
          const serviceName = AWS_LAMBDA_SERVICES.USER;
          const params = buildLambda(requestBody, functionName, serviceName);
          const startTime = new Date().getTime();
          getUserByCognitoId.invoke(params, (err, data) => {
            if (err) {
              logPerf(
                'getUserByCognitoId',
                new Date().getTime() - startTime,
                false,
              );
              logger.log('There was an error:', err);
              reject(err);
            } else {
              const result = parseResult(data);
              logPerf('getUserByCognitoId', new Date().getTime() - startTime);
              const errorObject = apiErrorHandler(
                result.Payload,
                autoHandleError,
              );
              if (errorObject) {
                reject(errorObject);
              } else {
                resolve(result.Payload);
              }
            }
          });
        });
      } else {
        const requestBody = {
          cognitoId,
          isDemoProfile,
          deviceOS: Platform.OS,
          deviceVersion: `${Platform.Version}`,
          deviceToken: 'token',
          deviceManufacturer: 'web',
          deviceBrand: 'web',
          deviceModel: 'web',
          version: APP_VERSION,
          token: idToken,
        };
        const functionName = 'getUserByCognitoId';
        logger.log('Lambda::', functionName);
        const serviceName = AWS_LAMBDA_SERVICES.USER;
        const params = buildLambda(requestBody, functionName, serviceName);
        const startTime = new Date().getTime();
        getUserByCognitoId.invoke(params, (err, data) => {
          if (err) {
            logPerf(
              'getUserByCognitoId',
              new Date().getTime() - startTime,
              false,
            );
            logger.log('There was an error:', err);
            reject(err);
          } else {
            const result = parseResult(data);
            logPerf('getUserByCognitoId', new Date().getTime() - startTime);
            const errorObject = apiErrorHandler(
              result.Payload,
              autoHandleError,
            );
            if (errorObject) {
              reject(errorObject);
            } else {
              resolve(result.Payload);
            }
          }
        });
      }
    });

  return sendRequest;
};

/*
 * Create a user
 * @async
 * @param {object} the user object
 * @returns {Promise<object>} The result of the user creation
 */
export const createUser = (userObj: any, callback: any) => {
  AWS.config.credentials.get((err: any) => {
    if (err) {
      logger.log('There was an error getting credentials:', err);
    } else {
      // Use the lambda function directly from the AWS sdk
      const putUserByCognitoId = configuredAWSLamda();
      const requestBody = userObj;

      // Add in the version into the request body
      requestBody.version = APP_VERSION;
      const functionName = 'postUser';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.USER;
      const params = buildLambda(requestBody, functionName, serviceName);
      const startTime = new Date().getTime();
      putUserByCognitoId.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          logPerf('createUser', new Date().getTime() - startTime, false);
          callback(err, null);
        } else {
          const result = parseResult(data, true);
          logPerf('createUser', new Date().getTime() - startTime);
          callback(null, result);
        }
      });
    }
  });
};

/*
 * Create's a user object for the API given the paramaters
 * @param {string} email The users email address
 * @param {string} firstName The users first name
 * @param {string} lastName The users last name
 * @param {string} knownAs What the user is known as
 * @param {string} cognitoId The users cognitio ID
 * @param {bool} tAndCAccepted If the user has accepted the terms and conditions
 * @param {bool} isMember True if the user is a member
 * @param {bool} isCrew True if the user is a crew
 * @returns {object} A user object that can be ingested by the APP
 */
export const createUserObject = (
  email: any,
  firstName: any,
  lastName: any,
  knownAs: any,
  cognitoId: any,
  tAndCAccepted: any,
  isMember: any,
  isCrew: any,
  typeConfirmed: any,
) => {
  const user = {
    email,
    firstName,
    lastName,
    knownAs,
    cognitoId,
    tAndCAccepted,
    isMember,
    isCrew,
    typeConfirmed,
  };
  return user;
};

/*
 * deactivates a user
 * @async
 * @param {string} userId The user ID of the user you want to deactivate
 * @returns {Promise<object>} The result of the user deactivation
 */
export const deactiveteUser = (reqBody: any, autoHandleError = true) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const putUserInactive = configuredAWSLamda();
      const requestBody = reqBody;
      const functionName = 'putUserInactive';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.USER;
      const params = buildLambda(requestBody, functionName, serviceName);
      putUserInactive.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/*
 * Update User Type
 * @async
 * @param {object} the user object
 * @returns {Promise<object>} The result of the user creation
 */
export const putUser = (userObj: any, autoHandleError = true) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const putUserByCognitoId = configuredAWSLamda();
      // FCM.getFCMToken().then(token => {
      const requestBody = userObj;
      const functionName = 'putUser';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.USER;
      const params = buildLambda(requestBody, functionName, serviceName);
      const startTime = new Date().getTime();
      putUserByCognitoId.invoke(params, (err, data) => {
        if (err) {
          logPerf('putUser', new Date().getTime() - startTime, false);
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          logPerf('putUser', new Date().getTime() - startTime);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            const result = parseResult(data);
            logPerf('putUser', new Date().getTime() - startTime);
            const errorObject = apiErrorHandler(
              result.Payload,
              autoHandleError,
            );
            if (errorObject) {
              reject(errorObject);
            } else {
              resolve(result.Payload);
            }
          }
        }
      });
      // });
    });

  return sendRequest;
};

/**
 * Update User's TnC For Current Brand
 * This function will update Current Brand's TnC
 * And Remove previously accepted TnC for other brands if removedBrandTnCs is not Empty
 * @param { number } userId - current user's Id
 * @param { string[] } removedBrandTnCs - tnc removed brand if there are any
 * @returns {Promise<object>} The result of the tnc update
 */
export const putUserTnC = (
  userId: any,
  removedBrandTnCs = [],
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const putUserTnC = configuredAWSLamda();
      const requestBody = {
        userId,
        removedBrandTnCs,
      };
      const functionName = 'putUserTnC';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.USER;
      const params = buildLambda(requestBody, functionName, serviceName);
      const startTime = new Date().getTime();
      putUserTnC.invoke(params, (err, data) => {
        if (err) {
          logPerf(functionName, new Date().getTime() - startTime, false);
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          logPerf(functionName, new Date().getTime() - startTime);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/**
 * Function will re-activate an inactive and expired cognito account given an email
 * And resend the invitation message to a user.
 * @param { String } email - user's email
 * @returns {Promise<object>} The result lambda invocation
 */
export const putTemporaryPassword = (email: any) => {
  const getApiUriPrefix = (configItem: any) => {
    if (configItem === 'qa') {
      return 'api-test';
    } else if (configItem === 'prod') {
      return 'api';
    } else {
      return 'api-' + configItem;
    }
  };
  const URI = `https://${getApiUriPrefix(
    AWS_LAMBDA_CONFIG_ITEM,
  )}.leapin.com.au/int/user/temporarypassword-v2`;
  const requestBody = JSON.stringify({
    body: {
      email,
    },
  });
  const requestHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  return fetch(URI, {
    method: 'PUT',
    headers: requestHeaders,
    mode: 'cors',
    body: requestBody,
  });
};

export const checkSystemUnderMaintenance = async () => {
  try {
    const URI = `${API_URL}/web/user/checkSystemUnderMaintenance`;
    const data = await fetch(URI);
    const dataJson = await data.json();
    const request = {
      Payload: JSON.stringify(dataJson),
      StatusCode: 200,
    };
    const result = parseResult(request, true);
    if (dataJson.statusCode == types2.STATUS_CODES.SYSTEM_UNDER_MAINTAINED) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};
