/*
 * Authour: Nirodha Perera
 * Date: 22/02/2019
 * Copyright © 2019 Leap in!. All rights reserved.
 *
 * A basic component to provide overlay behaviour
 */

import React, {Component} from 'react';
import {View, TouchableOpacity} from 'react-native';
import {StandardTouchableOpacity} from '../Atoms';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {BrandIcons} from '../../Styles/BrandIcons';

class BasicPopOver extends Component {
  constructor(props: any) {
    super(props);
  }

  state = {};

  getOverlayStyles = () => {
    const styles = [CommonStyles.containerOverlayPopover];

    if (this.props.overlayStyles) {
      styles.push(this.props.overlayStyles);
    }

    return styles;
  };

  getPopOverStyles = () => {
    const styles = [
      CommonStyles.containerPopover,
      CommonStyles.elevation10,
      BrandStyles.primaryBgColor1,
    ];

    if (this.props.popOverStyles) {
      styles.push(this.props.popOverStyles);
    }

    return styles;
  };

  render() {
    if (this.props.showOverlay) {
      return (
        <View style={CommonStyles.containerOverlay}>
          <StandardTouchableOpacity
            style={this.getOverlayStyles()}
            onPress={this.props.closePopover}
          />
          <View style={this.getPopOverStyles()}>{this.props.children}</View>
        </View>
      );
    } else {
      return <View style={this.getPopOverStyles()}>{this.props.children}</View>;
    }
  }
}

export default BasicPopOver;
