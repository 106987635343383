/*
 * Author: Andrew Seeley
 * Date: 20/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This will show over a screen when a crew loads a screen that is a member only screen.
 */

import React, {Component} from 'react';
import {View} from 'react-native';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';

import {StandardText} from '../Atoms';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

class CrewNoAccess extends Component {
  render() {
    let displayText = types2.CREW_NO_ACCESS_MESSAGE;

    if (types.isWeb) {
      displayText = types2.CREW_PLEASE_SELECT_MEMBER_MESSAGE;
    }

    if (this.props.customText) {
      displayText = this.props.customText;
    }

    return (
      <View style={[CommonStyles.fullscreenCrewNoAccess]}>
        <StandardText
          style={[CommonStyles.customFont, CommonStyles.textAlignCentre]}
        >
          {displayText}
        </StandardText>
      </View>
    );
  }
}

export default CrewNoAccess;
