/*
 * Author: Brad D'Costa
 * Date: 02/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Living Arrangement reference information.
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

/* Expected DataModel as per v22 of Swagger Definitions (versioning is based on Confluence versioning)
 *  {
 *      "homeType": {
 *          "id": integer,
 *          "description": "string",
 *          "imageURL": "string"
 *      },
 *      "livingWith": [
 *          {
 *              "type": "string
 *              "description": "string"
 *              "imageURL": "string"
 *          }
 *      ]
 */

export default function LivingArrangementRefReducer(state = {}, action: any) {
  switch (action.type) {
    case types.GET_LIVING_ARRANGEMENT_REFERENCE:
      return {
        homeTypes: action.livingRefObj.homeTypes,
        livingWith: action.livingRefObj.livingWith,
        apiCalled: new Date(),
      };
    default:
      return state;
  }
}
