/*
 * Author: Declan Hart
 * Date: 11/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Support Category Selector component, displays the categories for supports to filter the support items.
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import {SingleSelectDriverQuestion, MultiSelectDriverQuestion} from '../Atoms';

class DriverQuestion extends Component {
  state = {
    selectedOption: -1,
  };

  constructor(props: any) {
    super(props);
    this.renderQuestion = this.renderQuestion.bind(this);
  }

  renderQuestion() {
    if (this.props.driver.isMultiselect) {
      return (
        <MultiSelectDriverQuestion
          driver={this.props.driver}
          navigation={this.props.navigation}
          selectedResponse={this.props.selectedMultipleResponses}
          save={this.props.saveMultiple}
          readOnly={this.props.readOnly}
        />
      );
    } else {
      return (
        <SingleSelectDriverQuestion
          driver={this.props.driver}
          selectedResponse={this.props.selectedResponse}
          save={this.props.save}
          readOnly={this.props.readOnly}
        />
      );
    }
  }

  handleIndexChange(type: any, itemValue: any) {
    this.setState({selectedOption: itemValue});
    this.props.save(type, itemValue);
  }

  render() {
    return <View>{this.renderQuestion()}</View>;
  }
}

export default DriverQuestion;
