/*
 * Author: Andrew Lee
 * Date: 03/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The summary tile on the About Me screen for the disability section.
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View, Text} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {DISABILITY as messages} from '../../Constants/Messages';

import {StandardText} from '../Atoms';
import {SummaryTile} from '../Molecules';

import {
  DisabilityPurple3x,
  DisabilityCharcoal,
  DisabilityRed,
} from '../../assets/images/vector-icons';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class AboutMeDisability extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <SummaryTile
        headerDisplayString={messages.SUMMARY_TITLE}
        headerIconImage={DisabilityPurple3x}
        modal={this.props.form}
        containsData={this._determineContainsData()}
        HeaderTextColor={BrandStyles.TextColor4}
        HeaderBoarderColor={BrandStyles.borderColor4}
        actionOverride={this._determineAccess()}
      >
        {this._renderMyDetail()}
      </SummaryTile>
    );
  }

  _determineAccess = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      if (this.props.reducer.access) {
        if (this.props.reducer.access.profile) {
          if (this.props.reducer.access.profile == types.ACCESS_READ_ONLY) {
            return types.ACTION_BUTTON.VIEW;
          }
        }
      }
    }
    return null;
  };

  _determineContainsData = () => {
    if (
      this.props.reducer.disabilities == null &&
      this.props.reducer.customDisabilities
    ) {
      return false;
    }

    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return false;
    } else {
      var checkArray = [
        this.props.reducer.disabilities,
        this.props.reducer.customDisabilities,
      ];
      var dataCheck = false;
      for (var i = 0; i < checkArray.length; i++) {
        if (checkArray[i]) {
          if (Array.isArray(checkArray[i])) {
            if (checkArray[i].length > 0) {
              dataCheck = true;
              break;
            }
          } else {
            dataCheck = true;
            break;
          }
        }
      }
      return dataCheck;
    }
  };

  _determineIcon = () => {
    if (this._determineContainsData()) {
      return DisabilityRed;
    } else {
      return DisabilityCharcoal;
    }
  };

  _renderMyDetail = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      return <View>{this._renderDisabilities()}</View>;
    }
  };

  _renderDisabilities = () => {
    if (this.props.reducer == null) {
      return null;
    }
    if (
      this.props.reducer.disabilities.length == 0 &&
      this.props.reducer.customDisabilities == 0
    ) {
      return null;
    }

    const disabilities = this.props.reducer.disabilities;
    const customDisabilities = this.props.reducer.customDisabilities;

    let summaryString = '';
    // Create a string that will be passed into the standard text to be displayed
    for (
      let disabilityCounter = 0;
      disabilityCounter < disabilities.length;
      disabilityCounter++
    ) {
      // Get the disability
      const disability = disabilities[disabilityCounter];

      summaryString += disability.type;

      // Add a comma and space as long as the counter is not at the end
      if (disabilityCounter != disabilities.length - 1) {
        summaryString += ', ';
      }
    }

    // Show added custom disabilities in about me disability summary
    if (customDisabilities.length != 0 && disabilities.length != 0) {
      summaryString += ', ';
    }
    for (
      let customDisabilityCounter = 0;
      customDisabilityCounter < customDisabilities.length;
      customDisabilityCounter++
    ) {
      // Get the customDisability
      const customDisability = customDisabilities[customDisabilityCounter];

      summaryString += customDisability.type;

      // Add a comma and space as long as the counter is not at the end
      if (customDisabilityCounter != customDisabilities.length - 1) {
        summaryString += ', ';
      }
    }

    // Create the JSX
    return (
      <StandardText
        style={[
          CommonStyles.customFont,
          CommonStyles.SummaryTileText,
          BrandStyles.TextColor5,
        ]}
      >
        {summaryString}
      </StandardText>
    );
  };
}

export default AboutMeDisability;
