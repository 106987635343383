/*
 * Author: Shelan Cooray
 * Date: 14/09/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Display Health Well being Going Well Add/Edit Form.
 */

import React, {Component} from 'react';
import {View} from 'react-native';
import {StandardInput} from '../Components/Atoms';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {MESSAGES} from '../Constants/Messages';

import {BasicForm} from '../Components/Organisms';
import {FormTitle, Container, FormSubtitle} from '../Components/Molecules';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

//Import Stylesheets

import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {ThumbsUpPurple3x} from '../assets/images/vector-icons';

import {callApiChecker} from '../API/APIHelper';
import {callAPIs} from '../API/APICaller';
import {getHealth, updateHealth} from '../API/AboutMeAPI';
import * as HealthWellbeingActions from '../Actions/HealthWellbeingActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import logger from 'helpers/Logger';

import { DOMPurify } from 'helpers/dompurify';

class HealthWellbeingGoingWellForm extends Component {
  state = {
    goingWellMainScreen: '',
    goingWellForm: {description: ''},
    loading: true,
    saveApiError: false,
    ongoingHealth: {description: ''},
    refreshing: false,
  };

  ApiCalls = {
    healthWellbeingAPI: false,
  };

  constructor(props: any) {
    super(props);
    this._saveForm = this._saveForm.bind(this);
    this._cancelForm = this._cancelForm.bind(this);
    this._createMainContents.bind(this);
    this.fetchData.bind(this);
  }

  componentDidMount() {
    this.setState({loading: true});
    this.fetchData();
  }

  fetchData = () => {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    const callbackFunction = (data: any) => {
      const health = data[0].content;
      this.props.actions.HealthWellbeingActions.actionGetHealthWellbeing(
        health,
      );
      this._initialiseHealthDataInState(this.props.healthWellbeing);
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({loading: false, refreshing: false});
    };

    callAPIs(
      [
        getHealth(
          this.props.loadedMemberId.loadedMemberId,
          this.props.user.demoProfile,
        ),
      ],
      callbackFunction,
      null,
      () => {
        if (types.isWeb) {
          this.props.setLoading(false);
        }
        this.setState({loading: false, refreshing: false});
      },
    );
  };

  _initialiseHealthDataInState = (data: any) => {
    this.setState({
      healthConcerns: data.healthConcerns,
      ongoingHealth: data.ongoingHealth,
      goingWellMainScreen: data.ongoingHealth.description,
      healthConcernsMainScreen:
        data.healthConcerns.description +
        '\n' +
        data.healthConcerns.difficulty +
        '\n' +
        data.healthConcerns.support,
      goingWellForm: data.ongoingHealth,
      goingWellFormInitialState: data.ongoingHealth,
      healthConcernsForm: data.healthConcerns,
      refreshing: false,
    });
    this.ApiCalls.healthWellbeingAPI = true;
  };

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.GOING_WELL}
        hasFormContentChanged={this._hasFormContentChanged}
        currentScreenName={HealthWellbeingGoingWellForm.name}
        onRefresh={() => {
          this.setState({refreshing: true});
          this.fetchData();
        }}
        refreshing={this.state.refreshing}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _cancelForm = () => {
    let csData = '';
    let stateUpdate = {};
    csData = this.state.goingWellMainScreen;
    stateUpdate = {
      goingWellMainScreen: csData,
      goingWellForm: this.state.ongoingHealth,
      saveApiError: false,
    };
    this.setState(stateUpdate);
    this.props.navigation.goBack();
  };

  _saveForm = () => {
    let msData = '';
    let saveObj = {};
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({loading: true, saveApiError: false});
    msData = this.state.goingWellForm;
    saveObj = {ongoingHealth: msData};
    this._saveHealth(saveObj, types.HEALTH_WELLBEING.ONGOING_HEALTH);
  };

  _saveHealth = (saveObj: any) => {
    const updateHealthCallback = () => {
      this.props.actions.HealthWellbeingActions.actionUpdateHealthWellbeingGoingWell(
        saveObj,
      );
      if (types.isWeb) {
        this.props.setLoading(true);
      }
      this.setState({
        loading: false,
        goingWellMainScreen: saveObj.ongoingHealth.description,
        ongoingHealth: saveObj.ongoingHealth,
      });
      this.props.navigation.goBack();
    };

    const updateHealthErrorCallback = (err: any) => {
      logger.log('HealthWellbeing update health error: ', err);
      if (types.isWeb) {
        this.props.setLoading(true);
      }
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
        loading: false,
      });
    };

    callAPIs(
      [
        updateHealth(
          this.props.loadedMemberId.loadedMemberId,
          this.props.member.planId,
          saveObj,
          this.props.user.demoProfile,
        ),
      ],
      updateHealthCallback,
      updateHealthErrorCallback,
    );
  };

  _hasFormContentChanged = () => {
    return (
      JSON.stringify(this.state.goingWellFormInitialState) !==
      JSON.stringify(this.state.goingWellForm)
    );
  };

  _createMainContents = () => {
    return (
      <BasicForm
        headerDisplayString={
          MESSAGES.HEALTH_WELLBEING_SCREEN.GOING_WELL_TILE_TITLE
        }
        headerIconImage={ThumbsUpPurple3x}
        close={() => this._cancelForm()}
        save={() => this._saveForm()}
        disableMore={true}
        readOnly={this.props.member.access.profile}
        hideActionButton={true}
        buttonsPositionTop={true}
        contentWidthFull={true}
      >
        <FormTitle
          text={
            MESSAGES.HEALTH_WELLBEING_SCREEN.GOING_WELL_MODAL_DESCRIPTION_TITLE
          }
          containsData={this.state.goingWellForm.description}
        />
        <FormSubtitle
          text={[MESSAGES.HEALTH_WELLBEING_SCREEN.GOING_WELL_SUB_HEADING]}
        />
        <StandardInput
          readOnly={this.props.member.access.profile}
          multiline={true}
          value={this.state.goingWellForm.description}
          accessibilityLabel={
            MESSAGES.HEALTH_WELLBEING_SCREEN
              .GOING_WELL_MODAL_DESCRIPTION_PLACEHOLDER
          }
          onChangeText={(goingWellFormDescription: any) =>{
            const sanitizedGoingWellFormDescription = DOMPurify.sanitize(goingWellFormDescription);

            this.setState(prevState => ({
              goingWellForm: {
                ...prevState.goingWellForm,
                description: sanitizedGoingWellFormDescription,
              },
            }))
          }}
          underlineColorAndroid="transparent"
          style={[
            CommonStyles.ModalTextInputWrapperAutoHeight,
            BrandStyles.primaryBgColor1,
            CommonStyles.textAlignVerticalTop,
            types.isWeb ? CommonStyles.font15 : {},
          ]}
          blurOnSubmit={false}
        />
      </BasicForm>
    );
  };
}

const mapStateToProps = (state: any) => ({
  healthWellbeing: state.HealthWellbeingReducer,
  member: state.MemberReducer,
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    HealthWellbeingActions: bindActionCreators(
      HealthWellbeingActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthWellbeingGoingWellForm);
