/*
 * Author: Mayuran
 * Date: 29/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 */
import React, {Component} from 'react';
import {Text, View, Animated} from 'react-native';
import {StandardTouchableOpacity} from '../Atoms';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {
  CollapseCharcoalx3,
  ExpandCharcoalx3,
} from '../../assets/images/vector-icons';
import CustomIcon from '../../assets/images/CustomIcon';
// import Orientation from 'react-native-orientation-enhanced';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

export class PanelView extends Component {
  springValue: any;
  constructor(props: any) {
    super(props);

    this.state = {
      title: props.title,
      expanded: this.props.expanded ? this.props.expanded : false,
      orientation: '',
    };
  }
  // UNSAFE_componentWillMount = () => {
  //   if (!types.isWeb) {
  //     Orientation.getOrientation((err: any, orientation: any) => {
  //       this.setState({
  //         orientation: orientation,
  //       });
  //     });
  //   }
  // };
  componentWillUnmount = () => {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
  };

  _orientationDidChange = (orientation: any) => {
    if (orientation === this.state.orientation) {
      this.setState({
        orientation,
      });
    } else {
      this.setState({
        orientation,
        expanded: false,
      });
    }
  };
  componentDidMount = () => {
    // if (!types.isWeb) {
    //   Orientation.addOrientationListener(this._orientationDidChange);
    // }
  };
  toggle() {
    if (this.props.onPress) {
      this.props.onPress(!this.state.expanded);
    }
    if (types.isWeb) {
      this.setState({
        expanded: !this.state.expanded,
      });
    }
    setTimeout(
      function (this: any) {
        let initialValue = this.state.expanded
          ? this.state.maxHeight + this.state.minHeight
          : this.state.minHeight;
        if (!types.isWeb) {
          this.setState({
            expanded: !this.state.expanded,
          });
        }
        this.springValue.setValue(initialValue);
        Animated.spring(this.springValue, {}).start();
      }.bind(this),
      100,
    );
  }

  _setMaxHeight(event: any) {
    this.setState({
      maxHeight: event.nativeEvent.layout.height,
    });
    event.nativeEvent.layout.height = 0;
  }

  _setMinHeight(event: any) {
    this.setState({
      minHeight: event.nativeEvent.layout.height,
    });
    this.springValue = new Animated.Value(event.nativeEvent.layout.height);
  }
  render() {
    let icon = ExpandCharcoalx3;

    if (this.state.expanded) {
      icon = CollapseCharcoalx3;
    }

    return (
      <StandardTouchableOpacity
        accessible={false}
        onPress={this.toggle.bind(this)}
      >
        <Animated.View
          style={[
            CommonStyles.messagecontainer,
            {height: this.state.animation},
          ]}
        >
          <View
            style={CommonStyles.messageFirstContainer}
            onLayout={this._setMinHeight.bind(this)}
          >
            {this.props.renderInitialView()}
            {this.props.children != null ? (
              <View
                style={[CommonStyles.flexRow, CommonStyles.pannelViewShowHide]}
                accessible={true}
                accessibilityLabel={this.state.expanded ? 'Hide' : 'Read'}
                accessibilityTraits={'button'}
                accessibilityComponentType={'button'}
              >
                <Text
                  style={[
                    BrandStyles.messageNotiDateColor,
                    types.isWeb
                      ? CommonStyles.font15
                      : CommonStyles.notificationText,
                    CommonStyles.customFontMedium,
                    CommonStyles.textAlignVerticalTop,
                  ]}
                >
                  {this.state.expanded ? 'Hide' : 'Read'}
                </Text>
                <CustomIcon
                  style={[
                    CommonStyles.messageCollaExpaImage,
                    BrandStyles.messageNotiDateColor,
                  ]}
                  name={icon}
                />
              </View>
            ) : null}
          </View>
          {this.props.children != null && this.state.expanded ? (
            <View
              style={[CommonStyles.messageChildrenBody]}
              onLayout={this._setMaxHeight.bind(this)}
            >
              {this.props.children}
            </View>
          ) : null}
        </Animated.View>
      </StandardTouchableOpacity>
    );
  }
}
