/*
 * Author: Mayuran
 * Date: 05/12/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the CaptionImage
 */

import React, {Component} from 'react';
import {Image, View, StyleSheet} from 'react-native';
import {StandardInput, StandardText} from '../Atoms';
import CommonStyles from '../../Styles/CommonStyles';
import {MESSAGES} from '../../Constants/Messages';
import BrandStyles from '../../Styles/BrandStyles';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

class CaptionImage extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <View
        style={[
          this.props.showInLeftSide
            ? CommonStyles.justifyFlexColumnStart
            : CommonStyles.justifyFlexRowStart,
        ]}
      >
        <View>{this.props.children}</View>
        <View
          style={[
            CommonStyles.justifyFlexColumnStart,
            CommonStyles.paddingLeft10,
            !this.props.showInLeftSide ? CommonStyles.paddingTop10 : null,
          ]}
        >
          <StandardText
            style={[
              BrandStyles.TextColor6,
              CommonStyles.font12,
              CommonStyles.customFont,
              CommonStyles.title_SupportQuestions,
            ]}
          >
            {MESSAGES.PHOTO_CAPTION_TITLE}
          </StandardText>
          <View style={[CommonStyles.flex1]}>
            <StandardInput
              blurOnSubmit={true}
              noMargin={true}
              placeholder="Optional"
              returnKeyType={'done'}
              style={[CommonStyles.CaptionTextInputWrapper]}
              value={this.props.value}
              onChangeText={this.props.onChangeText}
              onSubmitEditing={this.props.onSubmitEditing}
              underlineColorAndroid="transparent"
            />
          </View>
        </View>
      </View>
    );
  }
}

export default CaptionImage;
