/*
 * Author: Tharindu Bandara
 * Date: 06/01/2021
 * Copyright © 2021 Leap in!. All rights reserved.
 * File picker component for web
 */
import React from 'react';

const FilePicker = (props: any) => {
  const {onChange, children, onRef} = props;

  return (
    <div>
      <input
        onChange={onChange}
        ref={inputRef => {
          if (onRef) onRef(inputRef);
        }}
        type="file"
        style={{display: 'none'}}
      />
      {children}
    </div>
  );
};

export default FilePicker;
