/*
 * Author: Declan Hart
 * Date: 28/03/2020
 * Copyright © 20201 Leap in!. All rights reserved.
 *
 * Screen allows user to select the guide me profile they would like to view
 */

import React, {Component} from 'react';
import {Platform, StyleSheet, View, ScrollView} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {StandardText, BackButton} from '../Components/Atoms';
import {
  OptionTile,
  LoginSignUpTitle,
  Container,
  Copyrights,
  ScreenTitle,
} from '../Components/Molecules';
import * as UserActions from '../Actions/UserActions';
import * as AuthenticationActions from '../Actions/AuthenticationActions';
import * as loadedMemberActions from '../Actions/LoadedMemberActions';
import * as OfflineNoticeActions from '../Actions/OfflineNoticeActions';
import * as MemberActions from '../Actions/MemberActions';
import * as AdminActions from '../Actions/AdminActions';
import * as GuideMeActions from '../Actions/GuideMeActions';

import {
  focusOnView,
  announceForAccessibility,
  isTablet,
  getVersion,
  Firebase,
} from '../Helpers/PlatformSynchronizer';
import AuthenticationUtil from '../Helpers/AuthenticationUtil';

import {putUser} from '../API/UserAPI';
import {callAPIs} from '../API/APICaller';
import {routeTolanding} from '../Components/RouteToLanding/RouteToLanding';
import {BrandActivityIndicator} from '../Components/Molecules/BrandActivityIndicator';
import {
  OfflineNotice,
  DeepLinkBanner,
  HeaderBanner,
} from '../Components/Organisms';

import {APP_BRAND} from '../Constants/BrandConstants';

import {PR_DEMO_EMAIL, DEMO_PASSWORD} from '../environments';

import {getDataLoggedIn} from '../API/StorageHelper';

export class ChooseGuideMeType extends Component {
  state = {
    loading: false,
    orientation: '',
    isConnected: true,
  };

  constructor(props: any) {
    super(props);
    this.loginToShowMeAround = this.loginToShowMeAround.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  render() {
    return (
      <View
        style={[
          CommonStyles.screenWrapperContainer,
          BrandStyles.primaryBgColor1,
        ]}
      >
        <View style={[CommonStyles.flex1, CommonStyles.flexDirectionColumn]}>
          {types.isWeb ? <HeaderBanner /> : null}
          <OfflineNotice />
          {types.isWeb && <DeepLinkBanner />}
          <View
            style={[
              CommonStyles.alignItemsCenter,
              CommonStyles.marginBottom10,
              CommonStyles.zIndex102,
            ]}
          >
            <LoginSignUpTitle
              title={types2.SIGNUP_LOGIN_FLOW.EXPLORE}
              backButton={true}
              nav={this.props.navigation}
              screen={types2.SIGNUP_LOGIN_FLOW.WELCOME}
            />
          </View>
          <ScrollView
            contentContainerStyle={[
              isTablet() && CommonStyles.margin10P,
              !types.isWeb && CommonStyles.marginTop10P,
              !types.isWeb && CommonStyles.paddingBottom50,
            ]}
            showsVerticalScrollIndicator={false}
          >
            <View
              style={[
                CommonStyles.rpMarginTop20p,
                !types.isWeb && CommonStyles.rpPadding10,
                CommonStyles.alignItemsCenter,
                CommonStyles.flex1,
              ]}
            >
              {!types.isWeb && (
                <View
                  style={[
                    CommonStyles.rpMarginTop25p,
                    CommonStyles.rpMarginBottom10p,
                  ]}
                >
                  <ScreenTitle title={types2.SIGNUP_LOGIN_FLOW.EXPLORE} />
                </View>
              )}
              <View
                style={[
                  this.getDirection(),
                  types.isWeb && CommonStyles.alignCenter,
                  CommonStyles.optionTileWrapper,
                ]}
              >
                {types2.CHOOSE_GUIDE_ME_TYPE.map(option =>
                  this.renderTiles(option),
                )}
              </View>
              <View style={[CommonStyles.rpMarginBottom20p]} />
            </View>
          </ScrollView>
          {!types.isWeb && (
            <View
              style={[
                this.getDirection(),
                CommonStyles.alignCenter,
                BrandStyles.copyrightsBarBgColor,
                CommonStyles.loginButtonWrapper,
              ]}
            >
              <Copyrights />
            </View>
          )}
        </View>
        <BrandActivityIndicator enableWeb={true} loading={this.state.loading} />
      </View>
    );
  }

  renderTiles = (option: any) => {
    return (
      <OptionTile
        option={option}
        orientation={this.state.orientation}
        onPress={(nextScreen: any) =>
          this.loginToShowMeAround(option.content, option.username)
        }
        minWidth={types.isWeb ? '' : CommonStyles.width75}
      />
    );
  };

  loginToShowMeAround(guideMeType: any, email: any) {
    this.props.actions.guideMeActions.setGuideMeDemoType(guideMeType);
    this.setState({loading: true});
    const auth = new AuthenticationUtil('', '', email, DEMO_PASSWORD, null);
    auth.login(
      () => {
        this.saveEmailCredentials(auth);
      },
      this.updateState,
      this.signUpFailure,
      true,
    );
    return;
  }

  updateState(stateObj: any) {
    this.setState(stateObj);
  }

  signUpFailure(error: any) {
    this.setState({
      loading: false,
      alertHeader: error.title,
      alertMessage: error.body,
      alertButtonText: error.buttonText,
      forceUpdate: error.forceUpdate,
      errorPopUpVisible: true,
    });
  }

  saveEmailCredentials = (auth: any) => {
    let actionToDispatch = auth.saveEmailCredentials(
      this.props.actions,
      this.updateState,
      (actionToDispatch: any) => {
        if (!types.isWeb) {
          this.props.navigation.dispatch(actionToDispatch);
        } else {
          this.props.navigation.navigate(actionToDispatch);
        }
      },
      this.signUpFailure,
      true,
      true,
    );
  };

  getDirection() {
    return this.state.orientation === types2.ORIENTATION.LANDSCAPE
      ? CommonStyles.flexDirectionRow
      : CommonStyles.flexDirectionColumn;
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.AuthenticationReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    authActions: bindActionCreators(AuthenticationActions, dispatch),
    adminActions: bindActionCreators(AdminActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
    memberActions: bindActionCreators(MemberActions, dispatch),
    AuthenticationActions: bindActionCreators(AuthenticationActions, dispatch),
    loadedMemberActions: bindActionCreators(loadedMemberActions, dispatch),
    OfflineNoticeActions: bindActionCreators(OfflineNoticeActions, dispatch),
    guideMeActions: bindActionCreators(GuideMeActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChooseGuideMeType);
