/*
 * Author: Declan Hart
 * Date: 23/05/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a standard input component which adds support for the following
 * - Error state with message
 * - Set's a default accessibilitylabel if none is passed for automation testing
 */

import React, {Component, forwardRef} from 'react';
import {
  View,
  Text,
  TextInput,
  Image,
  TouchableWithoutFeedback,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Keyboard,
} from 'react-native';
import {SingletonDatePicker} from '../../Helpers/PlatformSynchronizer';
import {forEach} from 'lodash';
import {StandardText, StandardInput} from '../Atoms';
import {_generateDisplayDate} from '../../Helpers/generateDisplayDate';
import {formatDDMMMYYYYDate} from '../../Helpers/Utils';

//Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {ANDROID, IOS} from '../../Constants/Constants2';
import * as types from '../../Constants/Constants';
import {CalanderCharcol} from '../../assets/images';

import {INPUT_CONTROL_ACCESSIBILITY} from '../../Constants/Messages';
class DatePicker extends Component {
  state = {
    simpleDate: new Date(),
    simpleText: '',
  };

  constructor(props: any) {
    super(props);
    this.setRef = this.setRef.bind(this);
  }

  componentDidMount() {
    {
      this._initialiseDate(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    this._initialiseDate(nextProps);
  }

  setRef = (pickerInput: any) => {
    if (this.props.onRef) {
      this.props.onRef(pickerInput);
    }
  };

  render() {
    let readOutText = '';
    if (this.props.value == '') {
      readOutText = '';
    } else if (this.state.simpleText) {
      var dateParts = this.state.simpleText.split('-');
      var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
      readOutText = formatDDMMMYYYYDate(dateObject);
    } else {
      readOutText = this.state.simpleText;
    }
    let marginRight = true;
    if (this.props.marginRight != undefined) {
      marginRight = this.props.marginRight;
    }
    if (this.props.readOnly == 'read') {
      return (
        <View style={{marginBottom: 20}}>
          <View style={[CommonStyles.ModalTextInputContainer]}>
            <StandardText style={this._determineReadStyle()}>
              {this.state.simpleText}
            </StandardText>
          </View>
        </View>
      );
    } else {
      let options = this.props.restricted
        ? {date: this.state.simpleDate, maxDate: this.state.simpleDate}
        : {date: this.state.simpleDate};
      let containerStyles = [];
      if (marginRight) {
        containerStyles =
          this.props.errorBorder > 0
            ? [BrandStyles.errorBorder, {borderWidth: this.props.errorBorder}]
            : [BrandStyles.borderColor2];
      } else {
        containerStyles =
          this.props.errorBorder > 0
            ? [
                BrandStyles.errorBorder,
                CommonStyles.marginRight0,
                {borderWidth: this.props.errorBorder},
              ]
            : [BrandStyles.borderColor2, CommonStyles.marginRight0];
      }

      return (
        <View
          style={[
            CommonStyles.marginBottom20,
            types.isWeb
              ? [CommonStyles.justifyFlexRowStart, CommonStyles.maxWidth160]
              : null,
          ]}
          ref={input => {
            if (Platform.OS === IOS) {
              this.setRef(input);
            }
          }}
        >
          {types.isWeb ? this._renderDatePickerTextField() : null}
          {!types.isWeb
            ? this._renderMobileDataPicker(readOutText, containerStyles)
            : this._renderWebDataPicker(readOutText, containerStyles)}
          {this.props.errorMessage && !types.isWeb ? (
            <StandardText
              accessibilityLabel={this.props.errorMessage}
              style={[CommonStyles.inputErrorMessageText]}
            >
              {this.props.errorMessage}
            </StandardText>
          ) : null}
        </View>
      );
    }
  }

  _renderDatePickerTextField = () => {
    return (
      <StandardInput
        multiline={false}
        key={'datepicker'}
        accessibilityLabel="Enter the date"
        mandatory={true}
        onSubmitEditing={() => {}}
        value={this.state.simpleText}
        onChangeText={(text: any) => {
          this.setState({simpleText: text});
        }}
        onBlur={() => {
          this._handleDateInput(this.state.simpleText);
        }}
        onRef={(ref: any) => {}}
        readOnly={false}
        returnKeyType={'next'}
        blurOnSubmit={true}
        underlineColorAndroid="transparent"
        style={[
          CommonStyles.ModalTextInputWrapper,
          CommonStyles.textFieldWrapper,
          BrandStyles.brandBorderColor3,
          CommonStyles.font16,
          CommonStyles.customFont,
        ]}
        defaultValue={
          ''
          // this.props.payInvoice.invoice != undefined
          //   ? this.props.payInvoice.invoice.invoiceDate
          //   : ''
        }
        errorMessage={this.props.errorMessage}
      />
    );
  };
  _renderWebDataPicker = (readOutText: any, containerStyles: any) => {
    const CustomComp = forwardRef(({value, onClick}, ref) => (
      <TouchableOpacity
        ref={ref}
        value={value}
        accessibilityLabel={'Select a date'}
        mandatory={false}
        style={[BrandStyles.primaryBgColor1]}
        onPress={onClick}
      >
        <Image
          style={[CommonStyles.DocumentButtonIcon]}
          source={CalanderCharcol}
        />
      </TouchableOpacity>
    ));

    return (
      <View style={[CommonStyles.marginLeft10]}>
        <SingletonDatePicker
          selected={this.props.value ? new Date(this.props.value) : null}
          onChange={this._handleDatePicked}
          dateFormat="dd/MM/yyyy"
          popperPlacement={this.props.popUpPlacement}
          maxDate={this._setMaximumDate()}
          customInput={<CustomComp onClick={this._showDateTimePicker} />}
        />
      </View>
    );
  };
  _renderMobileDataPicker = (readOutText: any, containerStyles: any) => {
    return (
      <View style={[CommonStyles.datePickerBorder, containerStyles]}>
        <TouchableWithoutFeedback
          ref={input => this.setRef(input)}
          accessibilityLabel={
            this.props.accessibilityLabel +
            ' ' +
            INPUT_CONTROL_ACCESSIBILITY.DATE_PICKER +
            readOutText +
            (this.props.mandatory
              ? INPUT_CONTROL_ACCESSIBILITY.MANDATORY
              : '') +
            ' button.'
          }
          onPress={this._showDateTimePicker}
        >
          <View
            style={[
              CommonStyles.ModalTextInputContainer,
              CommonStyles.DatePickerBoxMargin,
            ]}
          >
            <StandardText style={this._determineTextStyle()}>
              {this.state.simpleText}
            </StandardText>
          </View>
        </TouchableWithoutFeedback>
        <SingletonDatePicker
          maximumDate={this._setMaximumDate()}
          isVisible={this.state.isDateTimePickerVisible}
          onConfirm={this._handleDatePicked}
          onCancel={this._hideDateTimePicker}
        />
      </View>
    );
  };

  _setMaximumDate = () => {
    if (this.props.maxDate !== undefined) {
      return this.props.maxDate;
    }
  };

  _determineReadStyle = () => {
    var styles = [
      BrandStyles.readOnlyTextfieldGrey,
      CommonStyles.ReadOnlyDateInputWrapper,
      CommonStyles.DatePickerContainer,
    ];
    if (
      this.props.value == '' ||
      this.state.simpleText == this.props.placeholderText
    ) {
      styles.push(BrandStyles.placeholderTextColor);
    } else {
      styles.push(BrandStyles.TextColor5);
    }
    return styles;
  };

  _determineTextStyle = () => {
    const styles = [
      BrandStyles.primaryBgColor1,
      CommonStyles.DateInputWrapper,
      CommonStyles.DatePickerContainer,
      this.props.fontSize,
    ];

    if (
      this.props.value === '' ||
      this.state.simpleText === this.props.placeholderText
    ) {
      styles.push(BrandStyles.placeholderTextColor);
    } else {
      styles.push(BrandStyles.TextColor5);
    }

    if (Platform.OS === IOS) {
      styles.push(CommonStyles.paddingTop15);
    }
    return styles;
  };

  checkValidDate = (date: any) => {
    if (isNaN(Date.parse(date))) {
      var days = String(date).split('-');
      return new Date(
        parseInt(days[2]),
        parseInt(days[1]) - 1,
        parseInt(days[0]),
      );
    }
    return new Date(date);
  };

  _initialiseDate = (props: any) => {
    let tempParsedDate = this.checkValidDate(props.value);

    if (
      props.value == '' ||
      isNaN(tempParsedDate.valueOf()) == true ||
      !(tempParsedDate instanceof Date)
    ) {
      this.setState({
        simpleDate: new Date(),
        simpleText: props.placeholderText,
        simpleDBDate: '',
      });
    } else {
      //We have a date value returned from the DB
      let parsedDate = this.checkValidDate(props.value);
      let displayDate = _generateDisplayDate(parsedDate);
      let dbDate = this._generateDBDate(parsedDate);
      this.setState({
        simpleDate: new Date(
          parsedDate.getFullYear(),
          parsedDate.getMonth(),
          parsedDate.getDate(),
        ),
        simpleText: displayDate,
        simpleDBDate: dbDate,
      });
    }
  };

  formatDate(date: any) {
    if (date) {
      const day = date.getDate();
      const monthIndex = date.getMonth() + 1;
      const year = date.getFullYear();
      return (
        ('0' + day).slice(-2) + '-' + ('0' + monthIndex).slice(-2) + '-' + year
      );
      return '';
    }
  }

  _generateDBDate = (dateObj: any) => {
    let year = dateObj.getFullYear();
    var month = dateObj.getMonth() + 1;
    if (month < 10) {
      month = '0' + month;
    }
    var day = '';
    if (dateObj.getDate() < 10) {
      day = '0' + dateObj.getDate();
    } else {
      day = dateObj.getDate();
    }
    var returnString = year + '-' + month + '-' + day;
    return returnString;
  };

  _showDateTimePicker = () => {
    if (Platform.OS === IOS) {
      if (
        this.props.inputs !== undefined &&
        this.props.nextFocus !== undefined
      ) {
        Keyboard.dismiss();
        forEach(this.props.inputs, (value, key) => {
          this.props.inputs[key].blur();
        });
        this.props.inputs[this.props.nextFocus].focus();
      }
    }
    this.setState({isDateTimePickerVisible: true});
  };

  _hideDateTimePicker = () => this.setState({isDateTimePickerVisible: false});

  _handleDatePicked = (date: any) => {
    let parsedDate = this.checkValidDate(date);
    if (parsedDate != types.INVALID_DATE) {
      let displayDate = _generateDisplayDate(parsedDate);
      let dbDate = this._generateDBDate(parsedDate);
      this.setState({
        simpleDate: new Date(
          parsedDate.getFullYear(),
          parsedDate.getMonth(),
          parsedDate.getDate(),
        ),
        simpleText: displayDate,
        simpleDBDate: dbDate,
      });
      this.props.save(date);
      if (this.state.isDateTimePickerVisible) {
        this._hideDateTimePicker();
      }
    }
  };

  _handleDateInput = (date: any) => {
    if (date) {
      let splitDate = date.split(/[.,\/ -]/);
      let parsedDate = new Date(
        splitDate[1] + '-' + splitDate[0] + '-' + splitDate[2],
      );
      this._handleDatePicked(parsedDate);
    }
  };
}

export default DatePicker;
