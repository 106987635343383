/*
 * Authour: Shelan Cooray
 * Date: 05/07/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is the action for guideme for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const getGuideMeResults = (guideMeContent: any) => ({
  type: types2.GET_GUIDEME,
  guideMeContent,
});

export const getPreviousScreenInfo = (previousScreenInfo: any) => ({
  type: types2.PREVIOUS_SCREEN_INFO,
  previousScreenInfo,
});

export const setCurrentScreen = (currentScreen: any) => ({
  type: types2.SET_CURRENT_SCREEN,
  currentScreen,
});

export const showWelcomeGuide = (showWelcomeGuide: any) => ({
  type: types2.SHOW_WELCOME_GUIDE,
  showWelcomeGuide,
});

export const setWelcomeGuideStep = (welcomeGuideStep: any) => ({
  type: types2.WELCOME_GUIDE_STEP,
  welcomeGuideStep,
});

export const setOverlayHighlightOne = (overlayHighlightOne: any) => ({
  type: types2.OVERLAY_HIGHLIGHT_ONE,
  overlayHighlightOne,
});

export const setOverlayHighlightTwo = (overlayHighlightTwo: any) => ({
  type: types2.OVERLAY_HIGHLIGHT_TWO,
  overlayHighlightTwo,
});

export const showWelcomeManagedGuide = (showWelcomeManagedGuide: any) => ({
  type: types2.SHOW_WELCOME_MANAGED_GUIDE,
  showWelcomeManagedGuide,
});
export const setGuideMeDemoType = (setGuideMeDemoType: any) => ({
  type: types2.SET_DEMO_GUIDE_ME,
  setGuideMeDemoType,
});
export const setIsDemoProfileFirstTime = (isDemoProfileFirstTime: any) => ({
  type: types2.IS_DEMO_PROFILE_FIRST_TIME,
  isDemoProfileFirstTime,
});
export const setHighlightedMenuItem = (setHighlightedMenuItem: any) => ({
  type: types2.SET_HIGHLIGHTED_MENU_ITEM,
  setHighlightedMenuItem,
});
