/*
 * Author: Shelan Cooray
 * Date: 23/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the Invoice Item list as seperate sections
 */

import React, {Component} from 'react';
import {View, Image} from 'react-native';

import {StandardText} from '../Atoms';
import {CurrencyText} from './';
import {
  formatDurationString,
  formatDDMMMYYDate,
  formatQuantity,
} from '../../Helpers/Utils';
import {isWeb} from '../../Constants/Constants';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import {GreyClockIcon3x} from '../../assets/images';
import logger from 'helpers/Logger';

class InvoiceList extends Component {
  constructor(props: any) {
    super(props);
    this._renderTableContents = this._renderTableContents.bind(this);
  }

  render() {
    return <View>{this._renderLineItem()}</View>;
  }

  _renderLineItem = () => {
    let dataArray = this.props.data;
    if (Array.isArray(dataArray) && dataArray.length > 0) {
      return dataArray.map((item, index) => {
        const itemContent = [
          item.itemCode,
          item.description,
          item.budgetCategory,
          this._renderQuantity(item.quantity, item.duration),
          item.unitPrice,
          item.total,
          this._renderDateRange(item.startDate, item.endDate),
        ];
        if (
          item.funded !== undefined &&
          this.props.itemFundedPrice !== undefined
        ) {
          itemContent.push(item.funded);
        }

        let addSeparator = true;

        if (index == dataArray.length - 1) {
          addSeparator = false;
        }

        return this._renderTableContents(itemContent, index, addSeparator);
      });
    }
  };

  _renderQuantity = (quantity: any, duration: any) => {
    if (quantity !== undefined && quantity !== 0) {
      return formatQuantity(quantity);
    } else {
      return formatDurationString(duration);
    }
  };

  _renderDateRange = (startDate: any, endDate: any) => {
    if (endDate == undefined) {
      return formatDDMMMYYDate(startDate);
    }
    return formatDDMMMYYDate(startDate) + ' - ' + formatDDMMMYYDate(endDate);
  };

  _qtyOrDuration = (itemQty: any) => {
    return (
      <View
        style={[
          CommonStyles.flex1,
          CommonStyles.flexDirectionColumn,
          CommonStyles.alignCenter,
        ]}
      >
        <View
          style={[
            CommonStyles.containerList_ItemFigureTitle,
            CommonStyles.endContent,
          ]}
        >
          <StandardText
            style={[
              isWeb
                ? CommonStyles.title_InvoiceListWebMobile
                : CommonStyles.title_ListContainerSemiBold,
              CommonStyles.customFontSemiBold,
              CommonStyles.marginTop20,
            ]}
          >
            {this.props.itemQty}
          </StandardText>
        </View>
        <View
          style={[
            CommonStyles.containerList_ItemFigureTitle,
            CommonStyles.endContent,
          ]}
        >
          <StandardText
            style={[CommonStyles.title_ListContainer, CommonStyles.customFont]}
          >
            {itemQty}
          </StandardText>
        </View>
      </View>
    );
  };

  _unitPrice = (itemUnitPrice: any) => {
    return (
      <View
        style={[
          CommonStyles.flex1,
          CommonStyles.flexDirectionColumn,
          CommonStyles.alignCenter,
        ]}
      >
        <View style={[CommonStyles.flex1, CommonStyles.endContent]}>
          <StandardText
            style={[
              isWeb
                ? CommonStyles.title_InvoiceListWebMobile
                : CommonStyles.title_ListContainerSemiBold,
              CommonStyles.customFontSemiBold,
              CommonStyles.textAlignCentre,
            ]}
          >
            {this.props.itemUnitPrice}
          </StandardText>
        </View>
        <CurrencyText
          value={itemUnitPrice}
          isPositive={true}
          containerStyle={[
            CommonStyles.flex1,
            CommonStyles.flexDirectionRow,
            CommonStyles.endContent,
          ]}
          integerStyle={[
            isWeb ? CommonStyles.font20 : CommonStyles.font30,
            CommonStyles.customFont,
            BrandStyles.TextColor5,
          ]}
        />
      </View>
    );
  };

  _requestedTotal = (itemTotal: any) => {
    return (
      <View
        style={[
          CommonStyles.flex1,
          CommonStyles.flexDirectionColumn,
          CommonStyles.alignCenter,
        ]}
      >
        <View style={[CommonStyles.flex1, CommonStyles.endContent]}>
          <StandardText
            style={[
              isWeb
                ? CommonStyles.title_InvoiceListWebMobile
                : CommonStyles.title_ListContainerSemiBold,
              CommonStyles.customFontSemiBold,
              CommonStyles.textAlignCentre,
            ]}
          >
            {this.props.itemTotalPrice}
          </StandardText>
        </View>
        <CurrencyText
          value={itemTotal}
          isPositive={true}
          containerStyle={[
            CommonStyles.flex1,
            CommonStyles.flexDirectionRow,
            CommonStyles.endContent,
          ]}
          integerStyle={[
            isWeb ? CommonStyles.font20 : CommonStyles.font30,
            CommonStyles.customFont,
            BrandStyles.TextColor5,
          ]}
        />
      </View>
    );
  };

  _fundedTotal = (itemFunded: any) => {
    if (itemFunded !== undefined) {
      return (
        <View
          style={[
            CommonStyles.flex1,
            CommonStyles.flexDirectionColumn,
            CommonStyles.alignCenter,
          ]}
        >
          <View style={[CommonStyles.flex1, CommonStyles.endContent]}>
            <StandardText
              style={[
                isWeb
                  ? CommonStyles.title_InvoiceListWebMobile
                  : CommonStyles.title_ListContainerSemiBold,
                CommonStyles.customFontSemiBold,
                CommonStyles.textAlignCentre,
              ]}
            >
              {this.props.itemFundedPrice}
            </StandardText>
          </View>
          <CurrencyText
            value={itemFunded}
            isPositive={true}
            containerStyle={[
              CommonStyles.flex1,
              CommonStyles.flexDirectionRow,
              CommonStyles.endContent,
            ]}
            integerStyle={[
              isWeb ? CommonStyles.font20 : CommonStyles.font30,
              CommonStyles.customFont,
              BrandStyles.TextColor5,
            ]}
          />
        </View>
      );
    }
  };

  _oneRowClaimItems = (
    itemQty: any,
    itemUnitPrice: any,
    itemTotal: any,
    itemFunded: any,
  ) => {
    return (
      <View
        style={[
          CommonStyles.justifyFlexRowStart,
          CommonStyles.marginTop10,
          CommonStyles.marginBottom10,
        ]}
      >
        {this._qtyOrDuration(itemQty)}
        {this._unitPrice(itemUnitPrice)}
        {this._requestedTotal(itemTotal)}
        {this._fundedTotal(itemFunded)}
      </View>
    );
  };

  _twoByTwoColumnItems = (
    itemQty: any,
    itemUnitPrice: any,
    itemTotal: any,
    itemFunded: any,
  ) => {
    return (
      <View
        style={[CommonStyles.justifyFlexCoulmnStart, CommonStyles.marginTop10]}
      >
        <View
          style={[
            CommonStyles.justifyFlexRowStart,
            CommonStyles.marginTop10,
            CommonStyles.marginBottom10,
          ]}
        >
          {this._qtyOrDuration(itemQty)}
          {this._unitPrice(itemUnitPrice)}
        </View>
        <View
          style={[
            CommonStyles.justifyFlexRowStart,
            CommonStyles.marginTop10,
            CommonStyles.marginBottom10,
          ]}
        >
          {this._requestedTotal(itemTotal)}
          {this._fundedTotal(itemFunded)}
        </View>
      </View>
    );
  };

  _decideTwoRows = (itemUnitPrice: any, itemTotal: any, itemFunded: any) => {
    if (this.props.itemFundedPrice === undefined) {
      return false;
    }

    if (!isWeb) {
      return true;
    }

    let digitLength =
      itemUnitPrice.toString().length + itemTotal.toString().length;

    if (itemFunded !== undefined) {
      digitLength += itemFunded.toString().length;
    }

    if (digitLength > 9) {
      return true;
    }
    return false;
  };

  _renderTableContents = (contents: any, key: any, addSeparator: any) => {
    const [
      itemCode,
      itemDesc,
      itemCategory,
      itemQty,
      itemUnitPrice,
      itemTotal,
      dateRange,
      itemFunded,
    ] = contents;

    let viewKey = key;
    if (!viewKey) {
      viewKey = 0;
    }

    return (
      <View
        key={viewKey}
        style={
          addSeparator
            ? CommonStyles.containerList_Item
            : CommonStyles.containerList_LastItem
        }
      >
        <View
          style={[CommonStyles.justifyFlexRowStart, CommonStyles.marginBottom5]}
        >
          <View style={[CommonStyles.containerGreyTextWrapper]}>
            <StandardText
              style={[
                CommonStyles.title_ListContainer,
                CommonStyles.customFont,
              ]}
            >
              {itemCode}
            </StandardText>
          </View>
        </View>
        <View
          style={[CommonStyles.justifyFlexRowStart, CommonStyles.marginBottom5]}
        >
          <StandardText
            style={[CommonStyles.title_ListContainer, CommonStyles.customFont]}
          >
            {itemDesc}
          </StandardText>
        </View>
        <View
          style={[
            CommonStyles.justifyFlexRowStart,
            CommonStyles.marginBottom5,
            CommonStyles.alignItemsCenter,
          ]}
        >
          <Image
            style={CommonStyles.containerBudgetApprovalCard_ImageDate}
            source={GreyClockIcon3x}
          />
          <StandardText
            style={[
              CommonStyles.font15,
              CommonStyles.customFont,
              BrandStyles.TextColor10,
            ]}
          >
            {dateRange}
          </StandardText>
        </View>
        {this._decideTwoRows(itemQty, itemUnitPrice, itemTotal, itemFunded)
          ? this._twoByTwoColumnItems(
              itemQty,
              itemUnitPrice,
              itemTotal,
              itemFunded,
            )
          : this._oneRowClaimItems(
              itemQty,
              itemUnitPrice,
              itemTotal,
              itemFunded,
            )}
      </View>
    );
  };
}

export default InvoiceList;
