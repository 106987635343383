/*
 * Author: Declan Hart
 * Date: 16/1/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Display Support details
 */

import React, {Component} from 'react';
import {View} from 'react-native';
import {Picker} from '@react-native-picker/picker';

import {StandardText, StandardInput} from './';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

const EMPTY_STRING = '';
const DEFAULT_VALUE = '1';
class Frequency extends Component {
  state = {
    selectedOption: DEFAULT_VALUE,
  };

  constructor(props: any) {
    super(props);
    this.handleIndexChange = this.handleIndexChange.bind(this);
  }

  componentDidMount() {
    this.props.save(
      types.FREQUENCY,
      this.state.selectedOption,
      null,
      null,
      null,
    );
  }

  UNSAFE_componentWillMount() {
    if (this.props.selectedValues.length > 0) {
      this.setState({
        selectedOption: this.props.selectedValues[0].value,
      });
    } else if (
      this.props.selectedValues.length === 0 &&
      this.state.selectedOption === EMPTY_STRING
    ) {
      this.setState({selectedOption: EMPTY_STRING});
    } else {
      this.setState({selectedOption: DEFAULT_VALUE});
    }
  }

  _getFrequency() {
    if (this.state.selectedOption) {
      return this.state.selectedOption.toString();
    }
    return EMPTY_STRING;
  }

  handleIndexChange(itemValue: any) {
    this.setState({selectedOption: itemValue});
    if (itemValue != types2.PLEASE_SELECT_VALUE) {
      this.props.save(types.FREQUENCY, itemValue, null, null, null);
    }
  }

  render() {
    return (
      <View style={[CommonStyles.spaceAround, CommonStyles.flex1]}>
        <View style={[CommonStyles.marginBottom5]}>
          <StandardText
            style={[
              CommonStyles.title_SupportQuestions,
              CommonStyles.customFontBold,
            ]}
          >
            {types2.HOW_MANY_TIMES_A_YEAR}
          </StandardText>
        </View>
        <View style={CommonStyles.flex1}>
          <StandardInput
            readOnly={this.props.readOnly}
            accessibilityLabel="How many times a year (if relevant)"
            key="frequency"
            value={this._getFrequency()}
            onChangeText={(frequency: any) => this.handleIndexChange(frequency)}
            border={BrandStyles.borderColor4}
            blurOnSubmit
            applyWrapper={false}
            underlineColorAndroid="transparent"
            style={[
              CommonStyles.costInputField,
              CommonStyles.textInputWrapper_Support,
            ]}
          />
        </View>
      </View>
    );
  }
}

export default Frequency;
