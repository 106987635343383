import React, {Component} from 'react';
import {View} from 'react-native';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import {StandardButton, StandardText} from '../Atoms';

import {FORGOT_PASSWORD, LOGIN_SIGNUP_SCREEN} from '../../Constants/Messages';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

class ReturnToWelcome extends Component {
  render() {
    var accessibilityLabel =
      LOGIN_SIGNUP_SCREEN.RETURN_TO_WELCOME.BUTTON_TEXT_1 +
      ' ' +
      LOGIN_SIGNUP_SCREEN.RETURN_TO_WELCOME.BUTTON_TEXT_2;
    return (
      <StandardButton
        accessibilityLabel={accessibilityLabel}
        style={[
          CommonStyles.textAlignCentre,
          CommonStyles.buttonText,
          BrandStyles.TextColor2,
          CommonStyles.LoginSignUpP10,
          CommonStyles.rpfont12,
          CommonStyles.customFont,
        ]}
        containerStyle={[
          CommonStyles.LoginSignUpMT10,
          CommonStyles.paddingTop20,
          CommonStyles.LoginSignUpMB10,
          CommonStyles.paddingBottom10,
        ]}
        onPress={() => this.props.navigate()}
      >
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.alignFlexStart,
            CommonStyles.leftMargin10,
            CommonStyles.flexWrap,
          ]}
        >
          <View>
            <StandardText
              style={[
                CommonStyles.customFont,
                BrandStyles.TextColor5,
                types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont12,
              ]}
            >
              {LOGIN_SIGNUP_SCREEN.RETURN_TO_WELCOME.BUTTON_TEXT_1}
            </StandardText>
          </View>
          <View>
            <StandardText
              style={[
                BrandStyles.TextColor5,
                CommonStyles.width100,
                CommonStyles.termsAndConditionsLine3,
                types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont12,
                CommonStyles.customFontBold,
              ]}
            >
              {LOGIN_SIGNUP_SCREEN.RETURN_TO_WELCOME.BUTTON_TEXT_2}
            </StandardText>
          </View>
        </View>
      </StandardButton>
    );
  }
}

export default ReturnToWelcome;
