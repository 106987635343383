/*
 * Author: Joshua Merkas
 * Date: 12/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The healthandwellbeing screen, a healthandwellbeing placeholder screen.
 */

import React, {Component} from 'react';
import {View, Platform} from 'react-native';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {MESSAGES} from '../Constants/Messages';

import {
  BasicModal,
  HealthWellbeingDocuments,
  HealthWellbeingGoingWell,
  HealthWellbeingToImprove,
} from '../Components/Organisms';
import {
  ModalSubtitle,
  ModalTitle,
  SummaryTile,
  Container,
} from '../Components/Molecules';
import {StandardInput, StandardText, StandardPicker} from '../Components/Atoms';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {callApiChecker} from '../API/APIHelper';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {callAPIs} from '../API/APICaller';
import {getDocumentList} from '../API/MemberAPI';
import {getHealth} from '../API/AboutMeAPI';

import * as HealthWellbeingActions from '../Actions/HealthWellbeingActions';
import * as DocumentListActions from '../Actions/DocumentListActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import * as mime from 'react-native-mime-types-enhanced';
import logger from 'helpers/Logger';

const HEALTH_AND_WELLBEING_GOING_WELL = 'HealthWellbeingGoingWellForm';
const HEALTH_AND_WELLBEING_TO_IMPROVE = 'HealthWellbeingToImproveForm';

class HealthWellbeing extends Component {
  state = {
    filePickerResponse: {},
    refreshing: false,
  };

  constructor(props: any) {
    super(props);
    this._createMainContents.bind(this);
    this.fetchData.bind(this);
  }
  componentDidMount() {
    this._downloadInitialData();
  }

  _downloadInitialData = () => {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    const downloadCallback = (data: any) => {
      // Get the document list
      const documentList = data[0].content;
      this.props.actions.DocumentListActions.actionGetDocumentList(
        documentList.documents,
      );
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({loading: false});
    };

    if (this._determineFullAccess()) {
      callAPIs(
        [
          getDocumentList(
            this.props.loadedMemberId.loadedMemberId,
            this.props.user.demoProfile,
          ),
        ],
        downloadCallback,
        null,
        () => {
          if (types.isWeb) {
            this.props.setLoading(false);
          }
          this.setState({loading: false});
        },
      );
    } else {
      if (types.isWeb) {
        this.props.setLoading(false);
      } else {
        this.setState({loading: false});
      }
    }
  };

  fetchData = () => {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({refreshing: true});
    const apiArray = [];

    const callbackFunction = (data: any) => {
      const healthPayload = data[0].content;
      this.props.actions.HealthWellbeingActions.actionGetHealthWellbeing(
        healthPayload,
      );

      if (apiArray.length > 1) {
        const documentList = data[1].content;
        this.props.actions.DocumentListActions.actionGetDocumentList(
          documentList.documents,
        );
      }

      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({loading: false, refreshing: false});
    };

    //get latest health and well being data
    apiArray.push(
      getHealth(
        this.props.loadedMemberId.loadedMemberId,
        this.props.member.isDemoProfile,
      ),
    );

    if (this._determineFullAccess()) {
      apiArray.push(
        getDocumentList(
          this.props.loadedMemberId.loadedMemberId,
          this.props.member.isDemoProfile,
        ),
      );
    }

    callAPIs(apiArray, callbackFunction, null, () => {
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({loading: false, refreshing: false});
    });
  };

  componentDidMount() {
    this.fetchData();
  }

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }

    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.HEALTH_AND_WELLBEING}
        onRefresh={this.fetchData}
        refreshing={this.state.refreshing}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _determineFullAccess = () => {
    if (Object.getOwnPropertyNames(this.props.member).length === 0) {
      return false;
    } else {
      if (!this.props.member.access) {
        return false;
      } else {
        if (this.props.member.access.documents !== types.ACCESS_NONE) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  _createMainContents = () => (
    <View style={[CommonStyles.content]}>
      <View style={[CommonStyles.mainContentContainer]}>
        <View accessible={false} style={[CommonStyles.columnContainer]}>
          <HealthWellbeingGoingWell
            reducer={this.props.healthWellbeing}
            readOnly={this.props.member}
            form={() => this._navigateToHealthWellbeingGoingWellForm()}
          />
          <HealthWellbeingToImprove
            reducer={this.props.healthWellbeing}
            readOnly={this.props.member}
            form={() => this._navigateToHealthWellbeingToImproveForm()}
          />
          {this._determineFullAccess() && (
            <HealthWellbeingDocuments
              navigation={this.props.navigation}
              reducer={this.props.documentList}
              readOnly={this.props.member}
              isMobile={this.props.isMobile}
            />
          )}
        </View>
      </View>
    </View>
  );

  _navigateToHealthWellbeingGoingWellForm() {
    this.props.navigation.navigate(HEALTH_AND_WELLBEING_GOING_WELL);
  }

  _navigateToHealthWellbeingToImproveForm() {
    this.props.navigation.navigate(HEALTH_AND_WELLBEING_TO_IMPROVE);
  }
}

const mapStateToProps = (state: any) => ({
  healthWellbeing: state.HealthWellbeingReducer,
  member: state.MemberReducer,
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
  documentList: state.DocumentListReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    HealthWellbeingActions: bindActionCreators(
      HealthWellbeingActions,
      dispatch,
    ),
    DocumentListActions: bindActionCreators(DocumentListActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HealthWellbeing);
