/*
 * Author: Declan Hart
 * Date: 16/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Display Support details
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import {
  Cost,
  Frequency,
  Usage,
  DaysOfUse,
  TimeOfDay,
  HoursPerVisit,
} from '../Atoms';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

class UnitOfMeasureHour extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <View>
        <Usage
          options={types.HOURLYUSAGEVALUES}
          selectedValues={this.props.values.usage}
          save={this.props.save}
          readOnly={this.props.readOnly}
        />
        <DaysOfUse
          save={this.props.save}
          selectedValues={this.props.values.daysOfUse}
          readOnly={this.props.readOnly}
        />
        <TimeOfDay
          values={types.TIMEOFUSEVALUE}
          selectedValues={this.props.values.timeOfDay}
          save={this.props.save}
          readOnly={this.props.readOnly}
        />
        <View style={[CommonStyles.justifyFlexColumnStart]}>
          <Frequency
            style={[CommonStyles.flex1]}
            selectedValues={this.props.values.frequency}
            save={this.props.save}
            readOnly={this.props.readOnly}
          />
          <HoursPerVisit
            style={[CommonStyles.flex1]}
            save={this.props.save}
            selectedValues={this.props.values.hours}
            readOnly={this.props.readOnly}
          />
          <Cost
            style={[CommonStyles.flex1]}
            save={this.props.save}
            selectedValues={this.props.values.cost}
            readOnly={this.props.readOnly}
          />
        </View>
      </View>
    );
  }
}

export default UnitOfMeasureHour;
