/*
 * Author: Gayan
 * Date: 23/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * App Landing screen if there is no current user session. Animates in & allows the user to sign up or login.
 */

import React, {Component} from 'react';
import {
  Platform,
  StyleSheet,
  View,
  ScrollView,
  Animated,
  Image,
  Dimensions,
  TouchableOpacity,
  BackHandler,
  AppState,
  Alert,
  Linking,
} from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {isIphoneX} from 'react-native-iphone-x-helper';

// import Orientation from 'react-native-orientation-enhanced';
import logger from 'helpers/Logger';
import DeviceInfo from 'react-native-device-info'

// Import Stylesheets
import {Hub, Logger} from 'aws-amplify';
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {BrandIcons} from '../Styles/BrandIcons';

import {
  participant_image,
  AvatarFamily,
  AvatarArmsOutstretched,
  AvatarArmsUp,
} from '../assets/images';
import CustomIcon from '../assets/images/CustomIcon';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {
  ERRORS,
  ACCESSIBILITY_SCREEN_TITLES,
  CHOOSE_PARTICIPANT_CREW,
} from '../Constants/Messages';

import {StandardText, BackButton} from '../Components/Atoms';
import {
  OptionTile,
  LoginSignUpTitle,
  Container,
  Copyrights,
  ScreenTitle,
} from '../Components/Molecules';
import * as UserActions from '../Actions/UserActions';
import * as AuthenticationActions from '../Actions/AuthenticationActions';
import * as loadedMemberActions from '../Actions/LoadedMemberActions';
import * as OfflineNoticeActions from '../Actions/OfflineNoticeActions';
import * as MemberActions from '../Actions/MemberActions';
import * as AdminActions from '../Actions/AdminActions';
import * as LogoutActions from '../Actions/LogoutActions';
import {getValue, setValue} from '../API/WebPersistenceStore';
import {
  navigateToBooKMarkURL,
  webBookMarkUrl,
  redirectToForgotPassword,
} from '../Helpers/SignupNavigationHelper';
import {
  focusOnView,
  announceForAccessibility,
  isTablet,
  getVersion,
  firebaseAnalysisLogEvent,
} from '../Helpers/PlatformSynchronizer';
import AuthenticationUtil from '../Helpers/AuthenticationUtil';
import {preAuthenticate, logUserOut} from '../API/PreauthenticationHelper';
// import Database from '../API/Database';

import {putUser, checkSystemUnderMaintenance} from '../API/UserAPI';
import {callAPIs} from '../API/APICaller';
import {routeTolanding} from '../Components/RouteToLanding/RouteToLanding';
import {
  BrandActivityIndicator,
  PreAuthenticationCheckMessage,
} from '../Components/Molecules';
import {
  OfflineNotice,
  DeepLinkBanner,
  HeaderBanner,
} from '../Components/Organisms';
import * as env from '../environments';
import {getAwsAuth} from '../API/AuthenticationAPI';

import {APP_BRAND} from '../Constants/BrandConstants';

import {getDataLoggedIn} from '../API/StorageHelper';
import { getDeepLinkParams } from 'helpers/Utils';

const SCHEMA = 'schema';
const LOGIN_ROUTE = 'Login';

export class Welcome extends Component {
  state = {
    loading: false,
    orientation: '',
    isConnected: true,
    preAuthenticating: false,
  };
  subscription: any;
  linking: any;

  constructor(props: any) {
    super(props);
    this.navigateToDeepLink.bind(this);

    const logger = new Logger('My-Logger');
    const listener = (data: any) => {
      switch (data.payload.event) {
        case 'configured':
          logger.info('the Auth module is configured');
          break;
        case 'signIn':
          logger.info('user signed in');
          break;
        case 'signIn_failure':
          logger.error('user sign in failed');
          break;
        case 'signUp':
          logger.info('user signed up');
          break;
        case 'signUp_failure':
          logger.error('user sign up failed');
          break;
        case 'confirmSignUp':
          logger.info('user confirmation successful');
          break;
        case 'completeNewPassword_failure':
          logger.error('user did not complete new password flow');
          break;
        case 'autoSignIn':
          logger.info('auto sign in successful');
          break;
        case 'autoSignIn_failure':
          logger.error('auto sign in failed');
          break;
        case 'forgotPassword':
          logger.info('password recovery initiated');
          break;
        case 'forgotPassword_failure':
          logger.error('password recovery failed');
          break;
        case 'forgotPasswordSubmit':
          logger.info('password confirmation successful');
          break;
        case 'forgotPasswordSubmit_failure':
          logger.error('password confirmation failed');
          break;
        case 'tokenRefresh':
          logger.info('token refresh succeeded');
          break;
        case 'tokenRefresh_failure':
          logger.error('token refresh failed');
          break;
        case 'cognitoHostedUI':
          {
            const auth = new AuthenticationUtil();
            auth.SsoLogin(() => {
              this.props.navigation.navigate('Home');
            });
            logger.info('Cognito Hosted UI sign in successful');
          }
          break;
        case 'cognitoHostedUI_failure':
          logger.error('Cognito Hosted UI sign in failed');
          break;
        case 'customOAuthState':
          logger.info('custom state returned from CognitoHosted UI');
          break;
        case 'customState_failure':
          logger.error('custom state failure');
          break;
        case 'parsingCallbackUrl':
          logger.info('Cognito Hosted UI OAuth url parsing initiated');
          break;
        case 'userDeleted':
          logger.info('user deletion successful');
          break;
        case 'signOut':
          logger.info('user signed out');
          break;
      }
    };

    Hub.listen('auth', listener);
  }

  UNSAFE_componentWillMount() {
    if (!types.isWeb) {
      firebaseAnalysisLogEvent(
        types.FIREBASE_ANALYTICS_EVENTS.SIGNUP_MEMBER_TYPE_SELECTION,
        {},
      );
    }

    if (types.isWeb) {
      const urlParams = new URLSearchParams(window.location.search);
      const schema = urlParams.get(SCHEMA);
      this.setState({
        previouslyLoggedIn: getValue(types.PREVIOUSLY_SIGNIN) === 'logged',
      });
      const cognitoId = getValue('awsIdentity');
      if (cognitoId) {
        if (env.IS_STAFF_APP === types.APP_TYPE_STAFF) {
          getAwsAuth()
            .then(user => {
              this.setState({
                preAuthenticating: true,
              });
            })
            .catch(() => {
              this.setState({
                preAuthenticating: false,
              });
            });
        } else {
          this.setState({
            preAuthenticating: true,
          });
        }

        let auth = new AuthenticationUtil();
        auth.preAuthenticate(
          cognitoId,
          this.props.actions,
          (actionToDispatch: any) => {
            this.setState({
              preAuthenticating: false,
            });
            if (schema) {
              this.navigateToDeepLink(schema);
            } else if (!types.isWeb) {
              this.props.navigation.dispatch(actionToDispatch);
            } else {
              this.props.navigation.navigate(actionToDispatch);
            }
          },
        );
      } else if (schema) {
        this.navigateToDeepLink(schema);
      }
    }

    this.props.actions.logoutActions.hideConfirmLogout({
      logUserOut: false,
    });
    NetInfo.addEventListener(this.handleConnectivityChange);
    NetInfo.fetch().then(isConnected => {
      this.setState({isConnected});
    });

    // if (!types.isWeb) {
    //   Orientation.getOrientation((err: any, orientation: any) => {
    //     this.setState({
    //       orientation,
    //     });
    //   });
    // }
    checkSystemUnderMaintenance().then(res => {
      if (res) {
        if (types.isWeb) {
          this.props.history.replace(types2.routePaths.UnderMaintenance.path);
        }
      }
    });
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation,
    });
  };

  componentWillUnmount() {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
    // Linking.removeEventListener('url', this.handleOpenURL);
    if (!types.isWeb) {
      this.linking.remove();
      // AppState.removeEventListener('change', this._handleAppStateChange);
      this.subscription.remove();
    }
  }

  componentDidMount() {
    if (!types.isWeb) {
      announceForAccessibility(ACCESSIBILITY_SCREEN_TITLES.PARTICIPANT_OR_CREW);
      // Orientation.addOrientationListener(this._orientationDidChange);
      this.dispatchFocusInOrder();
    }
    Linking.getInitialURL().then(url => {
      this._navigateDeepLink(url);
    });
    this.linking = Linking.addEventListener('url', this.handleOpenURL);
    this.subscription = AppState.addEventListener(
      'change',
      this._handleAppStateChange,
    );
  }

  dispatchFocusInOrder() {
    setTimeout(
      function (this: any) {
        if (this.skipButton) {
          focusOnView(this.skipButton);
        }
      }.bind(this),
      1000,
    );
  }

  handleConnectivityChange = (isConnected: any) => {
    if (isConnected) {
      this.setState({isConnected});
    }
  };

  _handleAppStateChange = (nextAppState: any) => {
    if (nextAppState) {
      NetInfo.fetch().then(isConnected => {
        this.setState({isConnected});
      });
    }
  };
  handleOpenURL = (event: any) => {
    this._navigateDeepLink(event.url);
  };

  _navigateDeepLink = (url: any) => {
    if (url) {
      const paramValues = getDeepLinkParams(url);
      const navType = paramValues['type'];
      const email = paramValues['email'];
      switch (navType) {
        case types2.DEEP_LINK_NAV_TYPES.FORGOT_PASSWORD:
          this.props.navigation.navigate({
            routeName: types2.FORGOT_PASSWORD,
            params: {email},
          });
          break;
        default:
          break;
      }
    }
  };

  navigateToDeepLink = (schema: any) => {
    if (schema) {
      // Store if navigated from a deeplink to view banner
      setValue(types2.DEEPLINK_NAVIGATION, true);
      setValue(types2.DEEPLINK_URL, window.location.href);
    } else {
      setValue(types2.DEEPLINK_NAVIGATION, false);
    }
    if (this.state.isAuthenticated) {
      if (getValue(types2.BOOK_MARK_PARAMS) !== null) {
        navigateToBooKMarkURL(
          window,
          JSON.parse(getValue(types2.BOOK_MARK_PARAMS)),
        );
      } else {
        if (schema) {
          window.open(webBookMarkUrl(window, true), '_self');
        } else {
          this.props.navigation.navigate('Home');
        }
      }
    } else {
      if (schema) {
        setValue(types2.BOOK_MARK_PARAMS, webBookMarkUrl(window, false));
        this.props.navigation.navigate(LOGIN_ROUTE);
      }
    }
  };

  render() {
    return (
      <>
        <ScrollView
          style={[
            CommonStyles.screenWrapperContainer,
            BrandStyles.primaryBgColor1,
          ]}>
          <View style={[CommonStyles.flex1, CommonStyles.flexDirectionColumn]}>
            {types.isWeb ? <HeaderBanner /> : null}
            <OfflineNotice title={types2.SIGNUP_LOGIN_FLOW.WELCOME} />
            {types.isWeb && <DeepLinkBanner />}
            <View style={[CommonStyles.flexDirectionRow]}>
              <LoginSignUpTitle
                title={types2.SIGNUP_LOGIN_FLOW.WELCOME_TITLE}
              />
              {types.isWeb && this.state.preAuthenticating && (
                <View style={CommonStyles.alignItemsFlexEnd}>
                  <PreAuthenticationCheckMessage />
                </View>
              )}
            </View>
            {types.isWeb && this.state.previouslyLoggedIn && (
              <View
                style={[
                  CommonStyles.loginButtonWrapper,
                  CommonStyles.rpMarginTop20p,
                  CommonStyles.optionTileWrapper,
                ]}>
                <View
                  style={[
                    this.getDirection(),
                    CommonStyles.alignCenter,
                    !types.isWeb && BrandStyles.primaryBgColor3,
                  ]}>
                  {this.renderTiles(types2.WELCOME_SCREEN_LOGIN_OPTION)}
                </View>
              </View>
            )}
            <ScrollView
              contentContainerStyle={[
                !types.isWeb
                  ? CommonStyles.paddingBottom50
                  : CommonStyles.paddingBottom15,
                !types.isWeb && CommonStyles.paddingHorizontal10,
                CommonStyles.alignItemsCenter,
                CommonStyles.flex1,
              ]}
              showsVerticalScrollIndicator={false}
              style={[!types.isWeb ? CommonStyles.flex1 : null]}>
              {!types.isWeb && (
                <View style={CommonStyles.screenTitleContainer}>
                  <ScreenTitle title={types2.SIGNUP_LOGIN_FLOW.WELCOME_TITLE} />
                </View>
              )}

              {!types.isWeb && (
                <View
                  style={[
                    CommonStyles.flexDirectionColumn,
                    types.isWeb && CommonStyles.alignCenter,
                    CommonStyles.optionTileWrapper,
                  ]}>
                  {this.renderTiles(types2.WELCOME_SCREEN_LOGIN_OPTION)}
                </View>
              )}
              {!types.isWeb ||
                (!this.state.previouslyLoggedIn && (
                  <View
                    style={[
                      CommonStyles.rpMarginTop10p,
                      CommonStyles.optionTileWrapper,
                    ]}>
                    <View
                      style={[
                        CommonStyles.flexDirectionColumn,
                        types.isWeb && CommonStyles.alignCenter,
                      ]}>
                      {this.renderTiles(types2.WELCOME_SCREEN_LOGIN_OPTION)}
                    </View>
                  </View>
                ))}

              <View
                style={[
                  CommonStyles.flexDirectionColumn,
                  types.isWeb && CommonStyles.alignCenter,
                  CommonStyles.optionTileWrapper,
                ]}>
                {types2.WELCOME_SCREEN_SIGN_UP_OPTIONS.map((option, index) =>
                  this.renderTiles(option, index),
                )}
              </View>
              <View
                style={[
                  CommonStyles.flexDirectionColumn,
                  types.isWeb && CommonStyles.alignCenter,
                  CommonStyles.optionTileWrapper,
                ]}>
                {types2.WELCOME_SCREEN_DEMO_OPTIONS.map((option, index) =>
                  this.renderTiles(option, index),
                )}
              </View>
            </ScrollView>
          </View>

          <BrandActivityIndicator
            enableWeb={true}
            loading={this.state.loading}
          />
        </ScrollView>
        {!types.isWeb && (
          <View
            style={[
              CommonStyles.alignCenter,
              BrandStyles.copyrightsBarBgColor,
              CommonStyles.loginButtonWrapper,
            ]}>
            <Copyrights />
          </View>
        )}
      </>
    );
  }

  renderTiles = (option: any, index = undefined, removePadding = false) => {
    const props = {key: index};
    return (
      <OptionTile
        {...props}
        option={option}
        orientation={this.state.orientation}
        onPress={(nextScreen: any) => this.onOptionSelect(nextScreen)}
        minWidth={types.isWeb ? '' : CommonStyles.width75}
        removePadding={removePadding}
        isIphoneX={isIphoneX()}
      />
    );
  };

  onOptionSelect(option: any) {
    if (option.link) {
      this.navigateToLink(option.link);
    } else {
      this.navigateToScreen(option);
    }
  }

  navigateToScreen(option: any) {
    this.props.navigation.navigate(option.screen);
  }

  async navigateToLink(link: any) {
    window.open(link);
  }

  getDirection() {
    return this.state.orientation === types2.ORIENTATION.LANDSCAPE
      ? CommonStyles.flexDirectionRow
      : CommonStyles.flexDirectionColumn;
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.AuthenticationReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    authActions: bindActionCreators(AuthenticationActions, dispatch),
    adminActions: bindActionCreators(AdminActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
    memberActions: bindActionCreators(MemberActions, dispatch),
    AuthenticationActions: bindActionCreators(AuthenticationActions, dispatch),
    loadedMemberActions: bindActionCreators(loadedMemberActions, dispatch),
    OfflineNoticeActions: bindActionCreators(OfflineNoticeActions, dispatch),
    logoutActions: bindActionCreators(LogoutActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
