/*
 * Author: Nirodha Perera
 * Date: 10/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 */

import React, {Component} from 'react';
import {
  View,
  FlatList,
  StyleSheet,
  ScrollView,
  Image,
  TextInput,
} from 'react-native';
import {StandardInput, StandardText} from '../Components/Atoms';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// Import messages
import {MESSAGES} from '../Constants/Messages';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import * as MemberActions from '../Actions/MemberActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {
  ModalTitle,
  ModalSubtitle,
  FormTitle,
  FormSubtitle,
  SummaryTileHeader,
  Container,
} from '../Components/Molecules';

import {BasicForm} from '../Components/Organisms';
import logger from 'helpers/Logger';

// Import images
import {
  impactDisabilityCommunication,
  impactDisabilityConnect,
  impactDisabilityDailyLiving,
  impactDisabilityIndependence,
  impactDisabilityLearning,
  ImpactOfMyDiabilityPurple3x,
} from '../assets/images/vector-icons';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {getMember, updateMember} from '../API/MemberAPI';
import {callAPIs} from '../API/APICaller';
import { DOMPurify } from 'helpers/dompurify';

class AboutMeAdditionalInfoForm extends Component {
  state = {
    dailyLiving: '',
    communication: '',
    connectingWithOthers: '',
    learning: '',
    independence: '',
    aboutMe: {},
    initialFormState: {},
    refreshing: false,
  };

  // Create an array of objects that can be looped to create the inputs that are needed
  additionalInfo = [
    {
      title: MESSAGES.MORE_ABOUT_ME.DAILY_LIVING_TITLE,
      subtitle: MESSAGES.MORE_ABOUT_ME.DAILY_LIVING_SUBTITLE,
      icon: impactDisabilityDailyLiving,
      value: () => this.state.dailyLiving,
      onChangeFunction: (dailyLiving: any) => {{
        this.setState({dailyLiving});}
      },
    },
    {
      title: MESSAGES.MORE_ABOUT_ME.COMMUNICATION_TITLE,
      subtitle: MESSAGES.MORE_ABOUT_ME.COMMUNICATION_SUBTITLE,
      icon: impactDisabilityCommunication,
      value: () => this.state.communication,
      onChangeFunction: (communication: any) => {{
        this.setState({communication});}
      },
    },
    {
      title: MESSAGES.MORE_ABOUT_ME.CONNECTING_TITLE,
      subtitle: MESSAGES.MORE_ABOUT_ME.CONNECTING_SUBTITLE,
      icon: impactDisabilityConnect,
      value: () => this.state.connectingWithOthers,
      onChangeFunction: (connectingWithOthers: any) => {{

        this.setState({connectingWithOthers});}
      },
    },
    {
      title: MESSAGES.MORE_ABOUT_ME.LEARNING_TITLE,
      subtitle: MESSAGES.MORE_ABOUT_ME.LEARNING_SUBTITLE,
      icon: impactDisabilityLearning,
      value: () => this.state.learning,
      onChangeFunction: (learning: any) => {{

        this.setState({learning});}
      },
    },
    {
      title: MESSAGES.MORE_ABOUT_ME.INDEPENDENCE_TITLE,
      subtitle: MESSAGES.MORE_ABOUT_ME.INDEPENDENCE_SUBTITLE,
      icon: impactDisabilityIndependence,
      value: () => this.state.independence,
      onChangeFunction: (independence: any) => {{

        this.setState({independence});}
      },
    },
  ];

  constructor(props: any) {
    super(props);
    this._createInput = this._createInput.bind(this);
    this._createAllInputs = this._createAllInputs.bind(this);
    this._closeForm = this._closeForm.bind(this);
    this._saveForm = this._saveForm.bind(this);
    this._saveMoreAboutMe = this._saveMoreAboutMe.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this._setModalState();
  }

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.IMPACT_OF_MY_DISABILITY}
        hasFormContentChanged={this._hasFormContentChanged}
        currentScreenName={AboutMeAdditionalInfoForm.name}
        onRefresh={this.fetchData}
        refreshing={this.state.refreshing}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  fetchData = () => {
    this.setState({refreshing: true});
    const callbackFunction = (data: any) => {
      const member = data[0].content;
      this.props.actions.MemberActions.actionGetMember(
        member,
        this.props.user.id,
        this.props.user.isEmployee,
        this.props.user.isFinancialStaff,
      );

      this._setModalState();
      this.setState({refreshing: false});
    };
    callAPIs(
      [
        getMember(
          this.props.loadedMemberId.loadedMemberId,
          this.props.user.demoProfile,
        ),
      ],
      callbackFunction,
      null,
      () => {
        this.setState({refreshing: false});
      },
    );
  };

  _createMainContents = () => {
    return (
      <BasicForm
        headerDisplayString={MESSAGES.MORE_ABOUT_ME.TITLE}
        headerIconImage={ImpactOfMyDiabilityPurple3x}
        save={this._saveForm}
        close={this._closeForm}
        disableMore={true}
        readOnly={this.props.member.access.profile}
        buttonsPositionTop={true}
        topDescription={true}
        topDescriptionValue={this._renderTopDescription}
        hideActionButton={true}
        contentWidthFull={true}
      >
        <View style={CommonStyles.marginTop10}>{this._createAllInputs()}</View>
      </BasicForm>
    );
  };

  _renderTopDescription = () => {
    return (
      <View
        style={[
          CommonStyles.formImportantNotification,
          CommonStyles.paddingBottom10,
        ]}
      >
        <View
          style={[
            BrandStyles.secondaryBgColor3,
            CommonStyles.containerRoundCornersBorderless,
            CommonStyles.marginBottom10,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
              types.isWeb
                ? CommonStyles.customFontSemiBold
                : CommonStyles.customFontMedium,
            ]}
          >
            {MESSAGES.MORE_ABOUT_ME.MODAL_SUBTEXT}
          </StandardText>
        </View>
      </View>
    );
  };

  _renderSubSection(subtitle: any, value: any, onChangeFunction: any) {
    if (this.props.member.access.profile === types.ACCESS_READ_ONLY) {
      if (value()) {
        return (
          <View style={[CommonStyles.formImportantNotification]}>
            <StandardText
              style={[
                CommonStyles.SummaryTileText,
                BrandStyles.TextColor5,
                types.isWeb
                  ? CommonStyles.customFontSemiBold
                  : CommonStyles.customFontMedium,
              ]}
            >
              {value()}
            </StandardText>
          </View>
        );
      } else {
        return (
          <StandardText
            style={[CommonStyles.SummaryTileText, BrandStyles.TextColor5]}
          >
            {MESSAGES.MORE_ABOUT_ME.NO_INFORMATION}
          </StandardText>
        );
      }
    } else {
      return (
        <View>
          <FormSubtitle fontStyle={CommonStyles.customFont} text={[subtitle]} />
          <StandardInput
            readOnly={this.props.member.access.profile}
            multiline={true}
            value={value()}
            onChangeText={(text: any) => {
              const sanitizedText = DOMPurify.sanitize(text);

              onChangeFunction(sanitizedText)}}
            underlineColorAndroid="transparent"
            style={[
              CommonStyles.ModalTextInputWrapperAutoHeight,
              BrandStyles.primaryBgColor1,
              CommonStyles.textAlignVerticalTop,
            ]}
            blurOnSubmit={false}
          />
        </View>
      );
    }
  }

  _createAllInputs = () => {
    const modalJSX: any = [];
    let counter = 0;
    // Loop through the additional info array
    this.additionalInfo.map(additionalInfoItem => {
      modalJSX.push(
        this._createInput(
          counter,
          additionalInfoItem.title,
          additionalInfoItem.subtitle,
          additionalInfoItem.icon,
          additionalInfoItem.value,
          additionalInfoItem.onChangeFunction,
        ),
      );

      counter++;
    });

    return modalJSX;
  };

  _createInput(
    counter: any,
    headerTitle: any,
    subtitle: any,
    icon: any,
    value: any,
    onChangeFunction: any,
  ) {
    return (
      <View key={counter}>
        <FormTitle
          text={headerTitle}
          accessibilityLabel={headerTitle}
          imageSource={icon}
          containsData={value()}
        />
        {this._renderSubSection(subtitle, value, onChangeFunction)}
      </View>
    );
  }

  _saveForm = (saveObj: any) => {
    // Get the entire member object
    const tempMemberObject = {};

    // Update the member object
    tempMemberObject.id = this.props.member.id;
    tempMemberObject.dailyLiving = this.state.dailyLiving;
    tempMemberObject.communication = this.state.communication;
    tempMemberObject.connectingWithOthers = this.state.connectingWithOthers;
    tempMemberObject.learning = this.state.learning;
    tempMemberObject.independence = this.state.independence;
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({loading: true, saveApiError: false});
    this._saveMoreAboutMe(tempMemberObject);
  };

  _saveMoreAboutMe = (saveObj: any) => {
    const updateMemberCallback = () => {
      // Successful save -- update redux store and hide modal
      this.props.actions.MemberActions.actionUpdateMember(saveObj);
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        myDetailsVisibleModal: false,
        loading: false,
        aboutMe: saveObj,
      });
      this.props.navigation.goBack();
    };

    const updateMemberErrorCallback = (err: any) => {
      logger.log('The err in the update member is: ', err);
      //error code returned by lambda
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
        loading: false,
      });
    };

    callAPIs(
      [updateMember(saveObj, this.props.user.demoProfile)],
      updateMemberCallback,
      updateMemberErrorCallback,
    );
  };

  _closeForm = () => {
    // Closing the modal - reset the state of the modal to be from the member reducer
    this._setModalState();

    const stateUpdate = {
      saveApiError: false,
    };
    this.setState(stateUpdate);
    this.props.navigation.goBack();
  };

  _setModalState = () => {
    this.setState({
      dailyLiving: this.props.member.dailyLiving,
      communication: this.props.member.communication,
      connectingWithOthers: this.props.member.connectingWithOthers,
      learning: this.props.member.learning,
      independence: this.props.member.independence,
      initialFormState: {
        dailyLiving: JSON.parse(JSON.stringify(this.props.member.dailyLiving)),
        communication: JSON.parse(
          JSON.stringify(this.props.member.communication),
        ),
        connectingWithOthers: JSON.parse(
          JSON.stringify(this.props.member.connectingWithOthers),
        ),
        learning: JSON.parse(JSON.stringify(this.props.member.learning)),
        independence: JSON.parse(
          JSON.stringify(this.props.member.independence),
        ),
      },
    });
  };

  _hasFormContentChanged = () => {
    const initialFormState = this.state.initialFormState;
    const currentFormState = {
      dailyLiving: this.state.dailyLiving,
      communication: this.state.communication,
      connectingWithOthers: this.state.connectingWithOthers,
      learning: this.state.learning,
      independence: this.state.independence,
    };
    return (
      JSON.stringify(initialFormState) !== JSON.stringify(currentFormState)
    );
  };
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  loadedMemberId: state.LoadedMemberReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    MemberActions: bindActionCreators(MemberActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AboutMeAdditionalInfoForm);
