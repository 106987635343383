/*
 * Author: Niroda
 * Date: 28/05/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The add support screen, a add supports screen.
 */

import React, {Component} from 'react';
import {View, Image, StyleSheet} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as messages from '../Constants/Messages';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

import {
  Container,
  ActionButton,
  SummaryTile,
  FormTitle,
  GridView,
  PhotoTileCheckBoxButton,
} from '../Components/Molecules';
import {
  StandardText,
  StandardButton,
  StandardTouchableOpacity,
} from '../Components/Atoms';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {CrewPurple3x, addIcon} from '../assets/images/vector-icons';
import {InformationIconx3} from '../assets/images';

import * as SupportsActions from '../Actions/SupportsActions';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {setValue, removeItem, getValue} from '../API/WebPersistenceStore';

class SelectAddCrewSupport extends Component {
  state = {
    initialFormState: {},
    isLoading: false,
    profileTypePerson: true,
    supportCrew: [],
    crewMembers: this.props.crews.PersonOrgCrew
      ? this.props.crews.PersonOrgCrew
      : [],
  };

  constructor(props: any) {
    super(props);
    this._navigateToAddCrew.bind(this);
    this.renderCrewCategories.bind(this);
    this._renderIndividualCrew.bind(this);
    this._toggleCheckbox = this._toggleCheckbox.bind(this);
  }

  componentDidMount() {
    return this._setInitialState();
  }

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        headerTitle={types2.NAVIGATION_TEXT.ADD_SUPPORTS}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        sidebarProfilePicture={true}
        hasFormContentChanged={this._hasFormContentChanged}
        currentScreenName={SelectAddCrewSupport.name}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _navigateToAddCrew = () => {
    const navigationParams = {
      screenMode: types.FORM_MODE_NEW,
      editEmail: true,
      isFromSupport: true,
      getNewCrew: this._toggleCheckbox,
    };
    if (types.isWeb) {
      removeItem(types2.WEB_STORE.CREW_NAVIGATION_PARAMS);
      // Reset crew state
      removeItem(types2.WEB_STORE.CREW_STATE);
      setValue(
        types2.WEB_STORE.CREW_NAVIGATION_PARAMS,
        JSON.stringify(navigationParams),
      );
    }
    this.props.actions.NavParamActions.setParam(navigationParams);
    this.props.navigation.navigate(types2.NAV_SCREENS.CREW_FORM);
  };

  _renderIndividualCrew = (crewItem: any) => {
    let caption = crewItem.orgName;
    if (crewItem.memberType === 'person') {
      if (crewItem.knownAs != null && crewItem.knownAs !== '') {
        caption = crewItem.knownAs;
      } else {
        caption = `${crewItem.firstName} ${crewItem.lastName}`;
      }
    }

    let crewImage = null;
    if (crewItem.profileImage) {
      if (crewItem.profileImage.url) {
        crewImage = crewItem.profileImage;
      }
    }

    let filteredCrewItem = null;

    if (this.state.supportCrew && this.state.supportCrew.length > 0) {
      filteredCrewItem = this.state.supportCrew.find(
        item => item.crewId === crewItem.crewId,
      );
    }

    if (filteredCrewItem) {
      crewItem.selected = true;
    } else {
      crewItem.selected = false;
    }

    return (
      <View style={CommonStyles.marginLeft20}>
        <PhotoTileCheckBoxButton
          readOnly={this.props.readOnly}
          key={crewItem.crewId}
          photoTileStyle={StyleSheet.flatten(CommonStyles.CrewPhotoTile)}
          radious={CommonStyles.phototileCornerContainer}
          iconStyle={
            types.isWeb
              ? CommonStyles.radioButtonPMSmall
              : CommonStyles.image_40_40
          }
          photoTileApiFileType={types2.FILE_TYPE.CREW_PROFILE}
          photoTileImageObject={crewImage}
          photoTileShowCaption={true}
          photoTileCaption={caption}
          photoTileCaptionStyle={[
            CommonStyles.customFont,
            BrandStyles.TextColor5,
            types.isWeb ? [CommonStyles.font15] : null,
          ]}
          photoTileActionCallback={this._toggleCheckbox}
          photoTileClickable={false}
          data={crewItem}
          onChange={(checkBoxObj: any) => {
            this._toggleCheckbox(checkBoxObj);
          }}
          photoBorderRadius={CommonStyles.borderRadiusRound}
        />
      </View>
    );
  };

  _toggleCheckbox = (crewItem: any) => {
    let selectedCrew = false;
    let selectedCrewObject = null;

    if (this.state.supportCrew && this.state.supportCrew.length > 0) {
      selectedCrewObject = this.state.supportCrew.find(
        item => item === crewItem,
      );
    }

    if (selectedCrewObject) {
      selectedCrew = true;
    }

    let supportCrew = [];

    if (selectedCrew) {
      supportCrew = this.state.supportCrew.filter(
        (item, index) => item !== crewItem,
      );
      this.setState({
        supportCrew,
      });
    } else {
      supportCrew = [...this.state.supportCrew, crewItem];
      this.setState({supportCrew});
    }

    if (types.isWeb) {
      removeItem(types2.WEB_STORE.SELECTE_CREW_SUPPORT);
      removeItem(types2.WEB_STORE.SUPPORT_CREW);
      let crewIds = supportCrew.map(crew => crew.crewId);
      setValue(types2.WEB_STORE.SUPPORT_CREW, crewIds);
    }
  };

  getCrewArray(crewMembers: any) {
    return crewMembers.crews.map((crewMember: any) => {
      return this._renderIndividualCrew(crewMember);
    });
  }

  renderCrewCategories() {
    let crewMembersUpdated = this.props.crews.PersonOrgCrew;

    if (crewMembersUpdated != undefined) {
      return crewMembersUpdated.map((crew: any) => (
        <View key={crew.description}>
          <FormTitle
            text={crew.description}
            style={CommonStyles.font18}
            containsData={true}
          />
          <View
            style={[CommonStyles.contentFlexWrap, CommonStyles.alignFlexStart]}
          >
            <GridView
              isMobile={this.props.isMobile}
              maximumColumns={types.CREW_DISPLAY_MAX_VALUE}
              viewWidth={this.props.viewWidth}
              itemDimension={types2.ITEM_DIMENSION}
              childDimension={true}
            >
              {this.getCrewArray(crew)}
            </GridView>
          </View>
        </View>
      ));
    } else {
      return null;
    }
  }

  _renderAddByCrewButton = () => {
    return (
      <StandardTouchableOpacity
        style={CommonStyles.containerSupport_AddButton}
        accessibilityLabel="Add Crew"
        onPress={this._navigateToAddCrew}
        readOnly={this.props.member.access.profile}
      >
        <ActionButton
          title={types2.CREW}
          actionType={types.ACTION_BUTTON.ADD}
          image={addIcon}
        />
      </StandardTouchableOpacity>
    );
  };

  _renderNextButton = () => {
    return (
      <StandardButton
        onPress={() => this._navigateToSupport()}
        style={this._renderButtonStyle()}
        containerStyle={this._renderButtonContainerStyle()}
        accessibilityLabel={types2.NEXT}
        disabled={
          this.state.supportCrew && this.state.supportCrew.length > 0
            ? false
            : true
        }
      >
        {types2.NEXT}
      </StandardButton>
    );
  };

  _renderButtonStyle = () => {
    if (this.state.supportCrew && this.state.supportCrew.length > 0) {
      return [BrandStyles.TextColor1, CommonStyles.buttonFormAction];
    } else {
      return [BrandStyles.TextColor10, CommonStyles.buttonFormAction];
    }
  };

  _renderButtonContainerStyle = () => {
    if (this.state.supportCrew && this.state.supportCrew.length > 0) {
      return [
        BrandStyles.primaryBgColor4,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerFormAction,
      ];
    } else {
      return [
        BrandStyles.secondaryBgColor3,
        BrandStyles.borderColor8,
        CommonStyles.buttonContainerFormAction,
      ];
    }
  };

  _navigateToSupport = () => {
    this.props.actions.SupportsActions.addTempSupportCrewAction(
      this.state.supportCrew,
    );
    this.props.actions.NavParamActions.setParam({
      crewSelected: this.state.supportCrew,
    });
    this.props.navigation.navigate('AddSupportsByCrew', {
      fromScreen: 'crew',
    });
  };

  _createMainContents = () => (
    <View style={[CommonStyles.flexColumn, CommonStyles.content]}>
      <View>
        <SummaryTile
          headerDisplayString={messages.ADD_SUPPORTS_BY_CREW.TITLE}
          headerIconImage={CrewPurple3x}
          HeaderTextColor={BrandStyles.TextColor4}
          HeaderBoarderColor={BrandStyles.borderColor4}
          hideYellowStar={true}
          showButton={false}
          fontSize={CommonStyles.font20}
          disableMore={true}
          hasFormContentChanged={this._hasFormContentChanged}
          currentScreenName={SelectAddCrewSupport.name}
        />
      </View>

      <View style={[CommonStyles.containerImportantNotification]}>
        <View>
          <Image
            style={[
              CommonStyles.titleBoxIcon,
              CommonStyles.titleBoxIconMargin,
              CommonStyles.alignSelfCenter,
            ]}
            accessible={true}
            accessibilityLabel={
              messages.MESSAGES.ACCESSIBILITY.IMPORTANT_INFORMATION
            }
            source={InformationIconx3}
          />
        </View>
        <View
          style={[
            BrandStyles.secondaryBgColor3,
            CommonStyles.PlanSummaryActionBox,
            CommonStyles.justifyContentSpaceBetween,
            CommonStyles.containerRoundCornersBorderless,
            CommonStyles.marginBottom10,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
            ]}
          >
            {messages.ADD_SUPPORTS_BY_CREW.DESC}
          </StandardText>
        </View>
      </View>

      <View
        style={[
          CommonStyles.containerSupportNavigation,
          CommonStyles.marginTop10,
        ]}
      >
        {this._renderAddByCrewButton()}
        {this._renderNextButton()}
      </View>

      <View style={CommonStyles.marginTop10}>
        {this.renderCrewCategories()}
      </View>
    </View>
  );

  _setInitialState = () => {
    if (types.isWeb) {
      this.props.setLoading(false);
      // Remove local storage values for previously selected supports
      removeItem('addSupportsByCrew');
    }
    this.setState({
      initialFormState: {
        crewMembers: this.props.crews.PersonOrgCrew
          ? this.props.crews.PersonOrgCrew
          : [],
        supportCrew: [],
      },
    });
    if (types.isWeb) {
      let selectedCrewSupport = getValue(types2.WEB_STORE.SELECTE_CREW_SUPPORT);
      if (selectedCrewSupport) {
        selectedCrewSupport = JSON.parse(selectedCrewSupport);
        this.setState({
          supportCrew: [selectedCrewSupport],
        });
      }
    }
  };
  _hasFormContentChanged = () => {
    let initialFormState = this.state.initialFormState;
    let currentFormState = {
      crewMembers: this.state.crewMembers,
      supportCrew: this.state.supportCrew,
    };
    return (
      JSON.stringify(initialFormState) !== JSON.stringify(currentFormState)
    );
  };
}

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
  member: state.MemberReducer,
  supports: state.SupportsReducer,
  categories: state.SupportCategoriesReducer,
  crews: state.CrewReducer,
  navigationParams: state.NavigationParamsReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
    NavParamActions: bindActionCreators(NavigationParamActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SelectAddCrewSupport);
