/*
 * Author: Declan Hart
 * Date: 15/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The add support details screen, for adding details to the support.
 */

import React, {Component} from 'react';
import {View, Image, Dimensions} from 'react-native';
import {Picker} from '@react-native-picker/picker';
//import {NavigationActions} from 'react-navigation-awseeley--enhanced';
import {CommonActions, StackActions} from '@react-navigation/native';
import {uploadImageToS3} from '../Helpers/ImageHelper';

import {
  StandardButton,
  StandardText,
  StandardPicker,
} from '../Components/Atoms';
import {
  SupportDetailsSection,
  UnitOfMeasure,
  SupportDescriptionSection,
  AddCrewToSupport,
} from '../Components/Organisms';
import {
  Container,
  DriverQuestion,
  SupportsDeleteCancelSaveButtons,
  ActionButton,
  BasicOverlayPopupModal,
} from '../Components/Molecules';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {InformationIconx3} from '../assets/images/vector-icons';

import {deleteIconCharcoal60} from '../assets/images/vector-icons';

import {callAPIs} from '../API/APICaller';
import {
  createSupport,
  deleteSupportForPlan,
  getSupports,
} from '../API/PlanDataAPI';
import {
  getSupportItems,
  getBudgetCategories,
  getSupportCategories,
} from '../API/ReferenceAPI';
import * as GeneralActions from '../Actions/GeneralActions';
import * as SupportsActions from '../Actions/SupportsActions';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import * as messages from '../Constants/Messages';
import logger from 'helpers/Logger';
import {
  announceForAccessibility,
  isTablet,
} from '../Helpers/PlatformSynchronizer';
// import Orientation from 'react-native-orientation-enhanced';
import DeviceInfo from 'react-native-device-info';
import {getValue, removeItem, setValue} from '../API/WebPersistenceStore';

const {height, width} = Dimensions.get('window');
const SELECTED_CREW = 'selectedCrew';

class AddSupportDetails extends Component {
  state = {
    isLoading: false,
    editing: false,
    saveDisable: false,
    validTitle: '',
    selectedCategory: '',
    selectedItem: '',
    supportId: '',
    supportDescription: '',
    supportTitle: '',
    supportOpinion: '',
    supportCrew: [],
    timeOfUseResponses: [],
    daysOfUseResponses: [],
    timesPerYearResponses: [],
    frequencyResponses: [],
    costResponses: [],
    driverResponses: [],
    multiSelectDriverResponses: [],
    usageResponses: [],
    hoursPerVistResponses: [],
    budgetCategories: [],
    selectedBudgetCategory: null,
    deleteModalVisible: false,
    viewContainerDimensions: undefined,
    initialFormState: {},
  };

  constructor(props: any) {
    super(props);
    this.mapDriverQuestions = this.mapDriverQuestions.bind(this);
    this.submitSupport = this.submitSupport.bind(this);
    this.saveTitle = this.saveTitle.bind(this);
    this.saveDescription = this.saveDescription.bind(this);
    this.saveSupportOpinion = this.saveSupportOpinion.bind(this);
    this.saveUnitOfMeasureResponses =
      this.saveUnitOfMeasureResponses.bind(this);
    this.saveDriverResonses = this.saveDriverResonses.bind(this);
    this.saveMultiSelectDriverResponses =
      this.saveMultiSelectDriverResponses.bind(this);
    this.saveCrew = this.saveCrew.bind(this);
    this.removeCrewMember = this.removeCrewMember.bind(this);
    this.createUnitOfMeasureQuestionResponse =
      this.createUnitOfMeasureQuestionResponse.bind(this);
    this.buildUnitOfMeasureResponseObject =
      this.buildUnitOfMeasureResponseObject.bind(this);
    this.buildSupportItems = this.buildSupportItems.bind(this);
    this.mapItemDrivers = this.mapItemDrivers.bind(this);
    this.mapIndexesToValues = this.mapIndexesToValues.bind(this);
    this.getUnitOfMeasure = this.getUnitOfMeasure.bind(this);
    this.getSupportItems = this.getSupportItems.bind(this);
    this.createSupportingCrew = this.createSupportingCrew.bind(this);
    this.deleteSupport = this.deleteSupport.bind(this);
    this.navigateToSupports = this.navigateToSupports.bind(this);
    this.validateSupport = this.validateSupport.bind(this);
    this.getBudgetCategories = this.getBudgetCategories.bind(this);
    this.stopLoader = this.stopLoader.bind(this);
    this._topDeleteButton = this._topDeleteButton.bind(this);
    this.selectSaveCrew = this.selectSaveCrew.bind(this);
  }

  UNSAFE_componentWillMount() {
    let prevScreen = '';
    if (types.isWeb) {
      prevScreen = getValue('prevScreen');
    }
    //const edit = this.props.navigation.getParam('edit', undefined);
    //const { route } = this.props;
    //const { edit } = route.params || {};

    let edit;
    if(types.isWeb) {
      edit = this.props.navigation.getParam('edit', undefined) ;
     }else {
      edit = this.props.route.params['edit'] ? this.props.route.params['edit'] : undefined;
     }
    if (
      (!edit && prevScreen === 'Supports') ||
      prevScreen === 'AddSupportDetails'
    ) {
      this.props.navigation.navigate('Supports');
    }
    const support = this.props.navParams.params
      ? this.props.navParams.params.item
      : undefined;

    if (types.isWeb) {
      //restore selected support category and items on refresh for web
      let tempSupportItems = this.props.supports.tempSupportItems;
      let tempSupportCategories = this.props.supports.tempSupportCategories;
      let addSupportStep = this.props.supports.addSupportStep;
      if (!tempSupportItems) {
        tempSupportItems = JSON.parse(getValue('tempSupportItems'));
        this.props.actions.SupportsActions.addTempSupportItemsAction(
          tempSupportItems,
        );
      }
      if (!tempSupportCategories) {
        tempSupportCategories = JSON.parse(getValue('tempSupportCategories'));
        this.props.actions.SupportsActions.addTempSupportCategoriesAction(
          tempSupportCategories,
        );
      }

      if (!addSupportStep) {
        addSupportStep = Number(getValue('addSupportStep'));
        this.props.actions.SupportsActions.addSupportStepAction(addSupportStep);
      }
    }

    if (types.isWeb && edit && !support) {
      this.fetchData();
    } else {
      this.initializeSupportDetails(edit, support);
    }
  }

  componentDidUpdate(prevProps: any) {
    if (
      types.isWeb &&
      prevProps.supports.addSupportStep !== this.props.supports.addSupportStep
    ) {
      //const { route } = this.props;
      //const { edit } = route.params || {};
      //const edit = this.props.navigation.getParam('edit', undefined);
      let edit;
      if(types.isWeb) {
        edit = this.props.navigation.getParam('edit', undefined) ;
       }else {
        edit = this.props.route.params['edit'] ? this.props.route.params['edit'] : undefined;
       }


      const support = this.props.navParams.params
        ? this.props.navParams.params.item
        : undefined;
      if ((edit && support) || !edit) {
        this.initializeSupportDetails(edit, support);
      }
    }
  }

  componentDidMount() {
    // if (!types.isWeb) {
    //   Orientation.addOrientationListener(this._orientationDidChange);
    // }
    let prevScreen = '';
    if (types.isWeb) {
      prevScreen = getValue('prevScreen');
    }
    this._setInitialState();
    if (
      types.isWeb &&
      JSON.parse(getValue(SELECTED_CREW)) &&
      prevScreen === 'CrewForm'
    ) {
      let SupportCrew = JSON.parse(getValue(SELECTED_CREW));
      this.setState({
        supportCrew: SupportCrew,
      });
      removeItem(SELECTED_CREW);
    }
  }

  componentWillUnmount() {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
  }

  fetchData = () => {
    //get support details for web
    const downloadCallback = (data: any) => {
      const categories = data[0].content;
      const supports = data[1].content;

      this.props.actions.SupportsActions.actionGetSupports(supports);
      this.props.actions.SupportsActions.actionGetSupportsCategories(
        categories,
      );
      const currentSupportId = getValue('currentSupportId');
      const selectedSupport = supports.currentSupports.filter(
        (item: any) =>
          item.currentSupportId.toString() === currentSupportId.toString(),
      );
      this.initializeSupportDetails(true, selectedSupport[0]);
      this.props.setLoading(false);
    };

    callAPIs(
      [
        getSupportCategories(),
        getSupports(
          this.props.member.planId,
          this.props.loadedMemberId.loadedMemberId,
        ),
      ],
      downloadCallback,
      null,
      () => {
        this.props.setLoading(false);
      },
    );
  };

  initializeSupportDetails = (edit: any, support: any) => {
    this.setState({
      editing: edit || false,
    });

    if (types.isWeb || this.props.navParams.params) {
      // if (!types.isWeb) {
      //   Orientation.getOrientation((err: any, orientation: any) => {
      //     this.setState({
      //       orientation: orientation,
      //     });
      //   });
      // }

      this.getBudgetCategories();

      if (edit) {
        let supportingCrew = support.supportingCrew
          ? support.supportingCrew
          : [];
        let supportingOrgCrew = support.supportingOrgCrew
          ? support.supportingOrgCrew
          : [];
        let SupportCrew = [...supportingCrew, ...supportingOrgCrew];
        if (types.isWeb && JSON.parse(getValue(SELECTED_CREW))) {
          SupportCrew = JSON.parse(getValue(SELECTED_CREW));
          this.setState({
            supportCrew: SupportCrew,
          });
          removeItem(SELECTED_CREW);
        } else {
          this.setState({
            supportCrew: SupportCrew
              ? this.createSupportingCrew(SupportCrew)
              : [],
          });
        }
        this.setState({
          selectedItem: support,
          selectedCategory: support.categoryId,
          selectedGroupId: support.supportGroupId,
          supportTitle: support.title,
          supportId: support.currentSupportId,
          supportDescription: support.detail,
          supportOpinion: support.opinion,
          driverResponses: support.supportDrivers ? support.supportDrivers : [],
          multiSelectDriverResponses: support.supportDrivers
            ? support.supportDrivers
            : [],
          selectedBudgetCategory: support.budgetCategoryId
            ? support.budgetCategoryId
            : null,
          saveSuccess: false,
          isBack: false,
        });
        this.createUnitOfMeasureQuestionEditResponses(support.supportFields);
        this.getSupportItems(support.supportGroupId);
      } else {
        if (this.props.supports) {
          let addSupportStep = this.props.supports.addSupportStep;
          let item =
            this.props.supports.tempSupportItems &&
            this.props.supports.tempSupportItems.length !== 0
              ? this.props.supports.tempSupportItems[addSupportStep - 1]
              : {groupId: '', description: ''};
          let category =
            this.props.supports.tempSupportCategories &&
            this.props.supports.tempSupportCategories.length > 0
              ? this.props.supports.tempSupportCategories[addSupportStep - 1]
              : undefined;
          let supportCrew = this.props.supports.tempSupportCrew
            ? this.props.supports.tempSupportCrew
            : [];
          let crewIds = '';
          if (types.isWeb) {
            crewIds = getValue('supportCrew');
          }
          if (types.isWeb && supportCrew.length === 0 && crewIds) {
            supportCrew = this.props.crews.crewIndividual.filter(
              (crew: any) => {
                return ~crewIds.indexOf(crew.crewId);
              },
            );
            this.props.actions.SupportsActions.addTempSupportCrewAction(
              supportCrew,
            );
          }
          let budgetCategory =
            item && item.budgetCategoryId ? item.budgetCategoryId : null;

          if (
            addSupportStep &&
            this.props.supports.tempSupportItems.length != addSupportStep
          ) {
            this.setState({
              saveButton: 'Save & Next',
            });
          }

          for (const j in supportCrew) {
            const selectedOption = this.props.crews.crewIndividual.find(
              (crew: any) => {
                return crew.crewId === supportCrew[j].crewId;
              },
            );
            this.saveCrew(selectedOption, null);
          }

          this.setState({
            addSupportStep: addSupportStep,
            selectedItem: item,
            selectedCategory: category,
            selectedGroupId: item.groupId,
            supportTitle: item.description,
            selectedBudgetCategory: budgetCategory,
          });
          this.getSupportItems(item.groupId);
        }
      }
    }
    if (types.isWeb) {
      this.props.setLoading(false);
    }
  };

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation: orientation,
    });
  };

  stopLoader() {
    this.setState({isLoading: false});
  }

  getBudgetCategories() {
    if (
      this.props.member.planStatus &&
      this.props.member.planStatus == 'pm_active'
    ) {
      let callback = (data: any) => {
        const categories = data[0].content;
        const budgetCategories = JSON.parse(JSON.stringify(categories));
        for (const i in categories) {
          budgetCategories[i].type = categories[i].SupportCategoryId;
          budgetCategories[i].description = categories[i].Description;
        }
        this.setState({budgetCategories: budgetCategories});
        this.setState({isLoading: false});
      };

      callAPIs([getBudgetCategories()], callback, null, () => {
        this.setState({isLoading: false});
      });
    } else {
      if (this.state.selectedCategory) {
        this.setState({selectedBudgetCategory: this.state.selectedCategory});
      }
    }
  }

  getSupportItems(groupId: any) {
    let callback = (data: any) => {
      const items = data[0].content;
      this.props.actions.SupportsActions.actionGetSupportItems(items);
      this.setState({isLoading: false});
    };

    let requestBody = {
      groupItemId: groupId,
    };

    // If groupId is undefined we do not need reference data
    if (groupId == undefined) {
      this.setState({isLoading: false});
    } else {
      callAPIs([getSupportItems(requestBody)], callback, null, () => {
        this.setState({isLoading: false});
      });
    }
  }

  createSupportingCrew(crew: any) {
    if (crew) {
      return crew.map((crewMember: any) => {
        return {
          crew: crewMember,
          crewSupport: crewMember.crewSupport,
        };
      });
      return [];
    }
  }

  createUnitOfMeasureQuestionEditResponses(responses: any) {
    if (responses) {
      responses.forEach((element: any) => {
        let result: any = [];
        responses.map((response: any) => {
          if (element.fieldType === response.fieldType) {
            result.push(response);
          }
        });
        this.seperateUnitOfMeasureResponses(element.fieldType, result);
      });
    }
  }

  mapDriverQuestions() {
    if (
      !this.state.editing &&
      Object.getOwnPropertyNames(this.state.selectedItem).length != 0 &&
      this.state.selectedItem.drivers &&
      this.state.selectedItem.drivers.length > 0
    ) {
      return this.state.selectedItem.drivers.map((item: any, index: any) => {
        let selectedResponses = this.state.driverResponses.filter(response => {
          return response.driverCode.localeCompare(item.driverCode) === 0;
        });
        let selectedMultipleResponses =
          this.state.multiSelectDriverResponses.filter(response => {
            return response.driverCode.localeCompare(item.driverCode) === 0;
          });
        return (
          <DriverQuestion
            key={index}
            navigation={this.props.navigation}
            driver={item}
            saveSingle={this.saveDriverResonses}
            saveMultiple={this.saveMultiSelectDriverResponses}
            selectedResponse={selectedResponses}
            selectedMultipleResponses={selectedMultipleResponses}
            save={this.saveDriverResonses}
          />
        );
      });
    } else if (
      this.state.editing &&
      this.props.supportGroups &&
      this.props.supportGroups.length != 0
    ) {
      return this.props.supportGroups.map((group: any) => {
        return group.drivers.map((item: any, index: any) => {
          let selectedResponses = this.state.driverResponses.filter(
            response => {
              return response.driverCode.localeCompare(item.driverCode) === 0;
            },
          );
          let selectedMultipleResponses =
            this.state.multiSelectDriverResponses.filter(response => {
              return response.driverCode.localeCompare(item.driverCode) === 0;
            });

          // TODO - BUILDING OF THE OBJECTS IS HERE
          return (
            <DriverQuestion
              key={index}
              driver={item}
              navigation={this.props.navigation}
              selectedResponse={selectedResponses}
              selectedMultipleResponses={selectedMultipleResponses}
              saveMultiple={this.saveMultiSelectDriverResponses}
              save={this.saveDriverResonses}
              readOnly={this.props.member.access.profile}
            />
          );
        });
      });
    } else {
      return null;
    }
  }

  renderCategories() {
    if (
      this.props.member.planStatus &&
      this.props.member.planStatus == 'pm_active' &&
      this.state.budgetCategories.length > 0
    ) {
      return (
        <View>
          <View style={[CommonStyles.leftAboutMeModalContentContainer]}>
            <StandardText
              style={[
                CommonStyles.customFontMedium,
                CommonStyles.ModalLabel,
                BrandStyles.TextColor5,
              ]}
            >
              {messages.ADD_SUPPORT_SCREEN.SUPPORT_CATEGORY}
            </StandardText>
          </View>
          <View
            style={[
              CommonStyles.rightAboutMeModalContentContainer,
              {maxWidth: 400},
            ]}
          >
            <View
              style={[
                BrandStyles.secondaryBgColor3,
                CommonStyles.borderRadius10,
              ]}
            >
              <StandardPicker
                access={this.props.member.access.profile}
                pickerOptions={this.state.budgetCategories}
                style={[
                  CommonStyles.standardPicker,
                  CommonStyles.standardPickerBackgroundColorGray,
                ]}
                selectedValue={this.state.selectedBudgetCategory}
                onValueChange={(itemValue: any, itemIndex: any) =>
                  this.setState(prevState => ({
                    selectedBudgetCategory: types.isWeb
                      ? parseInt(itemValue)
                      : itemValue,
                  }))
                }
              >
                <Picker.Item key={0} label={'Select category'} value={0} />
                {this.renderOptions()}
              </StandardPicker>
            </View>
          </View>
        </View>
      );
    } else {
      return null;
    }
  }

  renderOptions() {
    return this.state.budgetCategories.map(item => {
      return (
        <Picker.Item
          key={item.type}
          label={item.description}
          value={item.type}
        />
      );
    });
  }

  saveTitle(title: any) {
    this.setState({supportTitle: title});
  }

  saveCrew(crew: any, description: any) {
    if (description == types2.ADD_A_NEW_CREW_MEMBER) {
      const navigationParams = {
        screenMode: types.FORM_MODE_NEW,
        isFromSupportDetails: true,
        setSelectedCrew: this.selectSaveCrew,
        editEmail: true,
      };
      if (types.isWeb) {
        removeItem(types2.WEB_STORE.CREW_NAVIGATION_PARAMS);
        removeItem(types2.WEB_STORE.CREW_STATE);
        setValue(
          types2.WEB_STORE.CREW_NAVIGATION_PARAMS,
          JSON.stringify(navigationParams),
        );
      }
      this.props.actions.NavParamActions.setParam(navigationParams);
      this.props.navigation.navigate('CrewForm');
      return;
    }

    this.selectSaveCrew(crew, description);
  }

  selectSaveCrew(crew: any, description: any) {
    let selectedCrew = this.state.supportCrew;
    let object = {
      crew: crew,
      crewSupport: description,
    };

    let itemIndex = selectedCrew.findIndex(item => {
      return item.crew.crewId === crew.crewId;
    });

    if (itemIndex >= 0 && description != null) {
      selectedCrew.splice(itemIndex, 1, object);
    } else if (itemIndex < 0) {
      selectedCrew.push(object);
    }
    removeItem(SELECTED_CREW);
    setValue(SELECTED_CREW, JSON.stringify(selectedCrew));
    this.setState({supportCrew: selectedCrew});
  }

  removeCrewMember(crew: any) {
    let selectedCrew = this.state.supportCrew.filter(
      crewMember => crewMember.crew.crewId !== crew,
    );
    this.setState({supportCrew: selectedCrew});
  }

  saveSupportOpinion(opinion: any) {
    this.setState({supportOpinion: opinion});
  }

  saveDescription(description: any) {
    this.setState({supportDescription: description});
  }

  seperateUnitOfMeasureResponses(type: any, responses: any) {
    if (type === types.TIMEOFDAY) {
      this.setState({timeOfUseResponses: responses});
    } else if (type === types.DAYSOFUSE) {
      this.setState({daysOfUseResponses: responses});
    } else if (type === types.TIMESPERYEAR) {
      this.setState({timesPerYearResponses: responses});
    } else if (type === types.FREQUENCY) {
      this.setState({frequencyResponses: responses});
    } else if (type === types.COST) {
      this.setState({costResponses: responses});
    } else if (type === types.USAGE) {
      this.setState({usageResponses: responses});
    } else if (type == types.HOURS_PER_VIST) {
      this.setState({hoursPerVistResponses: responses});
    }
  }

  saveUnitOfMeasureResponses(
    type: any,
    values: any,
    cost: any,
    number: any,
    isTrue: any,
  ) {
    values = this.mapIndexesToValues(type, values);
    var responses = [];
    if (Array.isArray(values)) {
      values.forEach(value => {
        responses.push(
          this.createUnitOfMeasureQuestionResponse(
            type,
            value,
            cost,
            number,
            isTrue,
          ),
        );
      });
    } else {
      responses.push(
        this.createUnitOfMeasureQuestionResponse(
          type,
          values,
          cost,
          number,
          isTrue,
        ),
      );
    }
    this.seperateUnitOfMeasureResponses(type, responses);
  }

  saveMultiSelectDriverResponses(type: any, value: any) {
    let currentMultiResponses = this.state.multiSelectDriverResponses;
    let object = {
      driverCode: type,
      value: value.value,
      description: value.description,
    };

    var itemIndex = -1;
    currentMultiResponses.findIndex((item, index) => {
      if (item.value.localeCompare(value.value) === 0) {
        itemIndex = index;
        return;
      }
    });
    if (itemIndex >= 0 && value != null) {
      currentMultiResponses.splice(itemIndex, 1);
    } else if (itemIndex < 0) {
      currentMultiResponses.push(object);
    }

    this.setState({multiSelectDriverResponses: currentMultiResponses});
  }

  saveDriverResonses(type: any, value: any) {
    let currentResponses = this.state.driverResponses;
    let object = {
      driverCode: type,
      value,
    };
    var itemIndex = -1;
    currentResponses.findIndex((item, index) => {
      if (item.driverCode.localeCompare(type) === 0) {
        itemIndex = index;
        return;
      }
    });
    if (itemIndex >= 0 && value != null) {
      currentResponses.splice(itemIndex, 1, object);
    } else if (itemIndex < 0) {
      currentResponses.push(object);
    }
    this.setState({driverResponses: currentResponses});
  }

  getSupportImage() {
    return this.state.selectedItem.supportPhoto != null
      ? this.state.selectedItem.supportPhoto
      : null;
  }

  createUnitOfMeasureQuestionResponse(
    type: any,
    value: any,
    cost: any,
    number: any,
    isTrue: any,
  ) {
    return {
      fieldType: type,
      value: value,
      cost: cost,
      units: number,
      isSelected: isTrue,
    };
  }

  buildPersonCrew() {
    if (this.state.supportCrew) {
      const filterdPersonCrew = this.state.supportCrew.filter(
        member =>
          member.crew.firstName != undefined && member.crew.firstName != '',
      );
      return filterdPersonCrew.map(crewMember => {
        return {
          id: crewMember.crew.crewId,
          crewSupport: crewMember.crewSupport,
        };
      });
    }
    return [];
  }

  buildOrganisationCrew() {
    if (this.state.supportCrew) {
      const filterdOrganisationCrew = this.state.supportCrew.filter(
        member =>
          member.crew.firstName == undefined || member.crew.firstName == '',
      );
      return filterdOrganisationCrew.map(crewMember => {
        return {
          id: crewMember.crew.crewId,
          crewSupport: crewMember.crewSupport,
        };
      });
    }
    return [];
  }

  mapItemDrivers(itemDrivers: any) {
    let result: any = [];
    if (itemDrivers) {
      itemDrivers.map((driver: any) => {
        return this.state.driverResponses.map(response => {
          if (driver.driverCode.localeCompare(response.driverCode) === 0) {
            if (driver.value.localeCompare(response.value) === 0) {
              result.push(true);
              return;
            } else {
              result.push(false);
              return;
            }
          }
        });
      });
      return !result.includes(false);
    }
    return false;
  }

  mapIndexesToValues(type: any, value: any) {
    if (type === types.TIMEOFDAY) {
      return value.map((valueIndex: any) => {
        return types.TIMEOFUSEVALUE[valueIndex];
      });
    } else if (type === types.DAYSOFUSE) {
      return value.map((valueIndex: any) => {
        return types.DAYSOFTHEWEEK[valueIndex];
      });
    } else if (type === types.USAGE) {
      return types.HOURLYUSAGEVALUES_ARRAY[value];
    } else if (type === types.ASSESS) {
      return types.ASSESSDRIVERVALUES[value];
    } else if (type === types.RATIO) {
      return types.RATIOQUESTIONVALUES[value];
    } else {
      return value;
    }
  }

  buildSupportItems() {
    let filteredSupportItems: any = [];
    if (this.props.supportItems && this.props.supportItems.length > 0) {
      this.props.supportItems.map((item: any) => {
        let id = item.supportItemId;
        if (this.mapItemDrivers(item.drivers)) {
          filteredSupportItems.push({id, isSelected: true});
        }
      });
    }
    return filteredSupportItems;
  }

  buildUnitOfMeasureResponseObject() {
    let result = [];
    if (types.isWeb && this.state.costResponses.length > 0) {
      this.state.costResponses[0].cost = parseFloat(
        this.state.costResponses[0].cost,
      );
    }
    result = this.state.timeOfUseResponses.concat(
      this.state.timesPerYearResponses,
      this.state.daysOfUseResponses,
      this.state.frequencyResponses,
      this.state.costResponses,
      this.state.usageResponses,
      this.state.hoursPerVistResponses,
    );
    return result;
  }

  buildDriverResponses() {
    let result = [];

    // Build the list of driver codes that are multiselect for the current support item
    let supportGroups = this.props.supportGroups;
    let driversGroupId = this.state.selectedGroupId;

    let multiSelectDriverCodes: any = [];
    // Only if we have selected a category we have a support group. If the user does not select a suggested
    // support there are no groups.
    if (this.props.supportGroups != undefined) {
      let currentSupportGroup = this.props.supportGroups.find(
        (o: any) => o.groupId === driversGroupId,
      );
      if (
        currentSupportGroup != undefined &&
        currentSupportGroup.drivers.length > 0
      ) {
        for (let i = 0; i < currentSupportGroup.drivers.length; i++) {
          const driverGroup = currentSupportGroup.drivers[i];
          // If it's a multi select item add the drivers
          if (driverGroup.isMultiselect) {
            // Add the driver's to our driver codes
            for (let k = 0; k < driverGroup.values.length; k++) {
              const driver = driverGroup.values[k];
              multiSelectDriverCodes += driver.key;
            }
          }
        }
      }
    }

    let driverResponses = this.state.driverResponses;
    let multiSelectDriverResponses = this.state.multiSelectDriverResponses;

    // Remove unselected multi select modal items
    // Now we have our multi select items, remove any items in driver responses
    // That are in multiSelectDriverCodes
    // And not in this.state.multiSelectDriverResponses
    // (This is so if you uncheck items in the multi select it makes sure they are removed)
    driverResponses = driverResponses.filter(driver => {
      if (multiSelectDriverCodes.includes(driver.value)) {
        if (
          multiSelectDriverResponses.filter(o => o.value === driver.value)
            .length > 0
        ) {
          // Keep the item if it's in the multi select
          return true;
        } else {
          // Otherwise the user has not selected it, or unselected it, remove.
          return false;
        }
      } else {
        // If it's not a multi select item then keep it
        return true;
      }
    });

    // Combine the current state driver respones with what the user has multi-selected
    result = driverResponses.concat(multiSelectDriverResponses);

    // Remove any duplicates
    result = result.filter(
      (item, index, self) =>
        index === self.findIndex(t => t.value === item.value),
    );

    return result;
  }

  validateSupport() {
    if (this.state.supportTitle === '') {
      this.setState({
        validTitle: types2.SUPPORT_TITLE_INVALID_ERROR,
      });
      announceForAccessibility(types2.SUPPORT_TITLE_INVALID_ERROR);
      return false;
    }
    return true;
  }

  submitSupport() {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({isLoading: true});
    let isValid = this.validateSupport();
    let callback = (data: any) => {
      const newSupportId = data[0].content;
      this.props.actions.SupportsActions.actionCreateSupport(newSupportId);
      this.setState({
        saveSuccess: true,
        isBack: true,
        isLoading: false,
      });
      if (types.isWeb) {
        this.props.setLoading(false);
        removeItem('supportCrew');
        this.setState({
          supportCrew: [],
          supportDescription: '',
          supportOpinion: '',
          saveButton: undefined,
          saveSuccess: undefined,
        });
      }
      this.navigateToSupports();
    };

    let budgetCategoryId = null;

    if (
      this.state.selectedBudgetCategory != 0 &&
      this.state.selectedBudgetCategory != null
    ) {
      budgetCategoryId = this.state.selectedBudgetCategory;
    }

    let requestBody = {
      id: this.props.member.id,
      currentSupportId: this.state.supportId,
      planId: this.props.member.planId,
      categoryId: this.state.selectedCategory,
      supportGroupId: this.state.selectedItem.groupId,
      customGroupDescription: '',
      title: this.state.supportTitle,
      detail: this.state.supportDescription,
      opinion: this.state.supportOpinion,
      supportItems: this.buildSupportItems(),
      supportingCrew: this.buildPersonCrew(),
      supportingOrganisation: this.buildOrganisationCrew(),
      supportFields: this.buildUnitOfMeasureResponseObject(),
      supportDrivers: this.buildDriverResponses(),
      isUserPlanManaged:
        this.props.member.planStatus &&
        this.props.member.planStatus == 'pm_active',
      budgetCategoryId: budgetCategoryId,
    };

    if (this.state.saveSuccess) {
      this.navigateToSupports();
    } else if (isValid) {
      callAPIs(
        [createSupport(requestBody, this.props.user.demoProfile, true)],
        callback,
        null,
        () => {
          this.setState({isLoading: false, saveDisable: false});
          if (types.isWeb) {
            this.props.setLoading(false);
          }
        },
      );
    } else {
      this.setState({
        isLoading: false,
        saveDisable: false,
      });
      if (types.isWeb) {
        this.props.setLoading(false);
      }
    }
  }

  // This will navigate to the supports screen and place the home screen under it in the stack
  // It will cause both components to call UNSAFE_componentWillMount, didMount again so any API calls will reoccur
  navigateToSupports() {
    //const { route } = this.props;
    //const { edit, prevScreen = '' } = route.params || {};
    //const edit = this.props.navigation.getParam('edit', undefined);
    //let prevScreen = '';
    // if (types.isWeb) {
    //   const { prevScreen = '' } = route.params || {};
    //   //const prevScreen = this.props.navigation.getParam(
    //     'prevScreen',
    //     undefined,
    //   );
    // }
    
    let edit;
    let prevScreen;
    if(types.isWeb) {
      edit = this.props.navigation.getParam('edit', undefined) ;
      prevScreen = this.props.navigation.getParam('prevScreen', undefined) ;
     }else {
      edit = this.props.route.params['edit'] ? this.props.route.params['edit'] : undefined;
      prevScreen = this.props.route.params['prevScreen'] ? this.props.route.params['prevScreen'] : undefined;
     }

    if (
      this.state.addSupportStep &&
      this.props.supports.tempSupportItems.length !=
        this.props.supports.addSupportStep
    ) {
      this.props.actions.SupportsActions.addSupportStepAction(
        this.state.addSupportStep + 1,
      );
      if (!types.isWeb) {
        this.props.navigation.navigate('AddSupportDetails');
      } else {
        setValue('addSupportStep', this.state.addSupportStep + 1);
      }
    } else if (edit && prevScreen == 'SpendingSupportItemDetail') {
      let budgetCategory = []
        .concat(
          this.props.budget.capacityBuilding,
          this.props.budget.capitalSupports,
          this.props.budget.core,
        )
        .find(
          x =>
            x.categoryId == this.props.navParams.params.item.budgetCategoryId,
        );

      return this.props.navigation.navigate('SpendingSupportItemDetail', {
        item: budgetCategory,
      });
    } else {
      if (types.isWeb) {
        this.props.navigation.navigate('Supports');
      } else {
        const actionToDispatch = CommonActions.reset({
          index: 1,
          routes:[
            {name: 'Profile'},
            {name: 'Supports'}
          ],
        });

        this.props.navigation.dispatch(actionToDispatch);
      }
    }
  }

  deleteSupport() {
    this.setState({isLoading: true, deleteModalVisible: false});
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    let callback = (data: any) => {
      // TODO: Need to fix this later so that the redux store is updated and not just redownloaded.
      let supportsCallbackFunction = (data: any) => {
        const supports = data[0].content;
        this.props.actions.SupportsActions.actionGetSupports(supports);
        this.navigateToSupports();
      };

      let supportsCallbackErrorFunction = (err: any) => {
        // Just log the error and navigate to supports anyway
        logger.log('deleteSupport supportsCallbackErrorFunction error: ', err);
        this.navigateToSupports();
      };

      callAPIs(
        [getSupports(this.props.member.planId, this.props.user.id, false)],
        supportsCallbackFunction,
        supportsCallbackErrorFunction,
      );
    };

    callAPIs(
      [
        deleteSupportForPlan(
          this.props.member.planId,
          this.props.member.id,
          this.state.supportId,
          this.props.user.demoProfile,
        ),
      ],
      callback,
      null,
      () => {
        this.setState({isLoading: false});
      },
    );
  }

  getUnitOfMeasure() {
    if (this.state.selectedItem.unitOfMeasure != null) {
      return this.state.selectedItem.unitOfMeasure.description != null
        ? this.state.selectedItem.unitOfMeasure.description
        : this.state.selectedItem.unitOfMeasure;
    } else {
      return null;
    }
  }

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        needsSidebar={true}
        modals={this._createAllModals}
        screenType={types.SCREEN_TYPE_MAIN}
        headerTitle={this._determinHeaderTitle()}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        loading={this.state.isLoading}
        hasFormContentChanged={this._hasFormContentChanged}
        currentScreenName={AddSupportDetails.name}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createAllModals = () => {
    let modalJSX = [this._renderDeleteConfirmationPopUp()];
    return modalJSX;
  };

  _renderError = () => {
    if (this.state.saveSuccess) {
      return (
        <StandardText
          style={[CommonStyles.italicFont, CommonStyles.customFont]}
        >
          {types2.DEMO_PROFILE_HEADER}
        </StandardText>
      );
    } else {
      return null;
    }
  };

  _renderDelete() {
    if (this.state.editing) {
      return (
        <View style={[CommonStyles.justifyFlexRowEnd, CommonStyles.flex03]}>
          <ActionButton
            title={''}
            actionType={types.ACTION_BUTTON.DELETE}
            isToucable={true}
            onPress={() => this._showDeleteConfimationPopUp()}
            readOnly={this.props.member.access.profile}
            image={deleteIconCharcoal60}
            imageActionIcon={false}
          />
        </View>
      );
    }
    return null;
  }

  _showDeleteConfimationPopUp = () => {
    this.setState({deleteModalVisible: true});
  };

  _closeDeletePopUpModal = () => {
    this.setState({deleteModalVisible: false});
  };

  _determinHeaderTitle = () => {
    let headerTitle = types2.NAVIGATION_TEXT.ADD_SUPPORTS;
    if (this.state.editing) {
      headerTitle = types2.NAVIGATION_TEXT.EDIT_SUPPORTS;
    }
    if (this.props.member.access.profile === types.ACCESS_READ_ONLY) {
      headerTitle = types2.NAVIGATION_TEXT.VIEW_SUPPORTS;
    }
    return headerTitle;
  };

  _renderDeleteConfirmationPopUp() {
    return (
      <BasicOverlayPopupModal
        visible={this.state.deleteModalVisible}
        style={this._determinePopUpStyle(isTablet)}
        backdropOpacity={0.2}
        divider={false}
        hideCloseButton={true}
      >
        <View>
          <View style={[]}>
            <View
              style={[
                CommonStyles.flexDirectionRow,
                CommonStyles.scrollView_subContentCenter,
                CommonStyles.paddingBottom5p,
              ]}
            >
              <View>
                <Image
                  style={[CommonStyles.changePasswordButtonImage]}
                  source={InformationIconx3}
                  accessibilityLabel={
                    messages.MESSAGES.ACCESSIBILITY.IMPORTANT_INFORMATION
                  }
                  accessible={true}
                />
              </View>
              <View style={[CommonStyles.scrollView_subContentCenter]}>
                <StandardText
                  style={[
                    isTablet() ? CommonStyles.font25 : CommonStyles.font20,
                    BrandStyles.TextColor5,
                    CommonStyles.customFont,
                  ]}
                >
                  {types2.ALERT}
                </StandardText>
              </View>
            </View>
            <View
              style={[
                CommonStyles.paddingTop5,
                CommonStyles.scrollView_subContentCenter,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                  isTablet() ? CommonStyles.font18 : CommonStyles.rpfont15,
                  CommonStyles.customFont,
                ]}
              >
                {types2.CONFIRM_SUPPORT_DELETE}
              </StandardText>
            </View>
            <View
              style={[
                CommonStyles.containerInstructionDevider,
                CommonStyles.paddingBottom5p,
              ]}
            />
            <View style={[CommonStyles.ModalActionButtons]}>
              <View style={CommonStyles.ModalFooterLeft}>
                <StandardButton
                  onPress={() => {
                    this._closeDeletePopUpModal();
                  }}
                  style={[
                    BrandStyles.brandBlockTxtColor6,
                    CommonStyles.buttonFormAction,
                    CommonStyles.customFontBold,
                  ]}
                  containerStyle={[
                    BrandStyles.primaryBgColor1,
                    BrandStyles.brandBorderColor1,
                    CommonStyles.buttonContainerFormAction,
                  ]}
                  accessibilityLabel={types2.CANCEL}
                >
                  {types2.CANCEL}
                </StandardButton>
              </View>
              <View style={CommonStyles.ModalFooterRight}>
                <StandardButton
                  onPress={() => {
                    this.deleteSupport();
                  }}
                  style={[
                    BrandStyles.brandBlockTxtColor5,
                    CommonStyles.buttonFormAction,
                    CommonStyles.customFontBold,
                  ]}
                  containerStyle={[
                    BrandStyles.primaryBgColor4,
                    BrandStyles.brandBorderColor1,
                    CommonStyles.buttonContainerFormAction,
                  ]}
                  accessibilityLabel={types2.CONFIRM_DEL}
                >
                  {types2.CONFIRM_DEL}
                </StandardButton>
              </View>
            </View>
          </View>
        </View>
      </BasicOverlayPopupModal>
    );
  }

  _determinePopUpStyle = (isTablet: any) => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (isTablet()) {
      styles.push([
        CommonStyles.containerInstructionPopUpTablet,
        CommonStyles.height300,
      ]);
    } else {
      styles.push([
        CommonStyles.containerInstructionPopUpMobile,
        CommonStyles.height230,
      ]);
    }
    return styles;
  };

  _topDeleteButton = () => {
    return this._renderDelete();
  };

  _renderButton = (viewActionButtons: any) => {
    if (viewActionButtons) {
      if (!(!types.isWeb && this.state.isBack) || this.state.editing) {
        return (
          <SupportsDeleteCancelSaveButtons
            readOnly={this.props.member.access.profile}
            showCancel={false}
            showDelete={this.state.editing}
            cancel={() => {
              if (types.isWeb) {
                this.props.navigation.goBack();
              } else {
                this.props.navigation.dispatch(CommonActions.goBack());
              }
            }}
            save={this.submitSupport}
            delete={this.deleteSupport}
            saveDisabled={this.state.saveDisable}
            saveButton={this.state.saveButton}
          />
        );
      } else {
        return (
          <View style={CommonStyles.screenFooter}>
            <View style={CommonStyles.ModalFooterLeftSkip}>
              {this._renderError()}
            </View>
            <View style={CommonStyles.ModalFooterRightSkip}>
              <StandardButton
                style={[
                  BrandStyles.brandBlockTxtColor6,
                  CommonStyles.buttonFormAction,
                  CommonStyles.customFontBold,
                ]}
                containerStyle={[
                  BrandStyles.primaryBgColor1,
                  BrandStyles.brandBorderColor1,
                  CommonStyles.buttonContainerFormAction,
                ]}
                onPress={this._backToUnsavedSupport}
                accessibilityLabel={this.state.saveButton}
              >
                {types2.SKIP}
              </StandardButton>
            </View>
          </View>
        );
      }
    } else {
      return null;
    }
  };
  _backToUnsavedSupport = () => {
    this.props.navigation.navigate('AddSupportDetails');
  };
  _renderAddCrewToSupport = () => {
    if (
      this.props.supports.tempSupportCrew &&
      this.props.supports.tempSupportCrew.length > 0
    ) {
      return null;
    } else {
      return (
        <AddCrewToSupport
          navigation={this.props.navigation}
          save={this.saveCrew}
          crew={this.props.crews}
          selectedCrew={this.state.supportCrew}
          removeCrewMember={this.removeCrewMember}
          readOnly={this.props.member.access.profile}
        />
      );
    }
  };

  _createMainContents = () => {
    let viewActionButtons = false;
    let viewWidthStyles = [];
    if (
      this.state.viewContainerDimensions &&
      this.state.viewContainerDimensions.viewHeight +
        types2.ADDED_VIEW_HEIGHT >=
        height
    ) {
      viewActionButtons = true;
    }
    if (this.props.viewWidth <= types2.MEDIA_QUERY.SMALL) {
      viewWidthStyles.push({
        width: this.props.viewWidth - types2.PHONE_VIEW_WIDTH_OFFSET,
      });
    }
    return (
      <View
        onLayout={this._onScreenLayout}
        style={[
          CommonStyles.content,
          CommonStyles.marginBottom20,
          viewWidthStyles,
        ]}
      >
        <SupportDetailsSection
          style={[CommonStyles.marginBottom10]}
          title={this.state.supportTitle || ''}
          saveTitle={this.saveTitle}
          saveOpinion={this.saveSupportOpinion}
          opinion={this.state.supportOpinion}
          readOnly={this.props.member.access.profile}
          valid={this.state.validTitle}
          crews={this.state.supportCrew}
          edit={this.state.editing ? true : false}
          addSupportStep={this.state.addSupportStep}
          supportsCount={
            this.props.supports.tempSupportItems
              ? this.props.supports.tempSupportItems.length
              : 1
          }
          topButtons={() => this._renderButton(true)}
          _topDeleteButton={this._topDeleteButton}
        />
        {this._renderAddCrewToSupport()}
        <UnitOfMeasure
          readOnly={this.props.member.access.profile}
          save={this.saveUnitOfMeasureResponses}
          measure={this.getUnitOfMeasure()}
          values={{
            daysOfUse: this.state.daysOfUseResponses,
            timeOfDay: this.state.timeOfUseResponses,
            timesPerYear: this.state.timesPerYearResponses,
            usage: this.state.usageResponses,
            cost: this.state.costResponses,
            frequency: this.state.frequencyResponses,
            hours: this.state.hoursPerVistResponses,
          }}
        />
        {this.mapDriverQuestions()}
        <SupportDescriptionSection
          readOnly={this.props.member.access.profile}
          save={this.saveDescription}
          description={this.state.supportDescription}
          imageObject={this.getSupportImage()}
        />
        {this.renderCategories()}
        {this._renderButton(viewActionButtons)}
        {this._renderDeleteConfirmationPopUp()}
      </View>
    );
  };

  _onScreenLayout = (event: any) => {
    //Get layout width and height
    let viewWidth = event.nativeEvent.layout.width;
    let viewHeight = event.nativeEvent.layout.height;

    this.setState({viewContainerDimensions: {viewWidth, viewHeight}});
  };
  _setInitialState = () => {
    this.setState({
      initialFormState: {
        validTitle: this.state.validTitle,
        selectedCategory: this.state.selectedCategory,
        selectedItem: this.state.selectedItem,
        supportId: this.state.supportId,
        supportDescription: this.state.supportDescription,
        supportTitle: this.state.supportTitle,
        supportOpinion: this.state.supportOpinion,
        supportCrew: this.state.supportCrew,
        timeOfUseResponses: this.state.timeOfUseResponses,
        daysOfUseResponses: this.state.daysOfUseResponses,
        timesPerYearResponses: this.state.timesPerYearResponses,
        costResponses: this.state.costResponses,
        driverResponses: this.buildDriverResponses(),
        multiSelectDriverResponses: this.state.multiSelectDriverResponses,
        usageResponses: this.state.usageResponses,
        hoursPerVistResponses: this.state.hoursPerVistResponses,
        selectedBudgetCategory: this.state.selectedBudgetCategory,
      },
    });
  };
  _hasFormContentChanged = () => {
    let initialFormState = this.state.initialFormState;
    let currentFormState = {
      validTitle: this.state.validTitle,
      selectedCategory: this.state.selectedCategory,
      selectedItem: this.state.selectedItem,
      supportId: this.state.supportId,
      supportDescription: this.state.supportDescription,
      supportTitle: this.state.supportTitle,
      supportOpinion: this.state.supportOpinion,
      supportCrew: this.state.supportCrew,
      timeOfUseResponses: this.state.timeOfUseResponses,
      daysOfUseResponses: this.state.daysOfUseResponses,
      timesPerYearResponses: this.state.timesPerYearResponses,
      costResponses: this.state.costResponses,
      driverResponses: this.buildDriverResponses(),
      multiSelectDriverResponses: this.state.multiSelectDriverResponses,
      usageResponses: this.state.usageResponses,
      hoursPerVistResponses: this.state.hoursPerVistResponses,
      selectedBudgetCategory: this.state.selectedBudgetCategory,
    };
    return (
      JSON.stringify(initialFormState) !== JSON.stringify(currentFormState)
    );
  };
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  supportGroups: state.SupportItemGroupsReducer.supportGroups,
  supportItems: state.SupportItemsReducer.supportItems,
  supports: state.SupportsReducer,
  crews: state.CrewReducer,
  loadedMemberId: state.LoadedMemberReducer,
  user: state.UserReducer,
  budget: state.BudgetReducer,
  navParams: state.NavigationParamsReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
    GeneralActions: bindActionCreators(GeneralActions, dispatch),
    NavParamActions: bindActionCreators(NavigationParamActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSupportDetails);
