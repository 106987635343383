/*
 * Author: Declan Hart
 * Date: 16/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Display Support details
 */

import React, {Component} from 'react';
import {StyleSheet, View, Image, TextInput} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {addPhotoPurple} from '../../assets/images';

import {
  SupportTitle,
  SupportTrafficLights,
  SupportDescription,
  PhotoTile,
} from '../Molecules';
import {StandardText} from '../Atoms';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import * as SupportsActions from '../../Actions/SupportsActions';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class SupportDescriptionSection extends Component {
  state = {
    supportImage: null,
  };

  constructor(props: any) {
    super(props);
    this.setLocalSupportImage = this.setLocalSupportImage.bind(this);
  }

  render() {
    return (
      <View>
        <StandardText
          style={[
            CommonStyles.title_SupportQuestions,
            CommonStyles.customFontBold,
          ]}
        >
          {types2.SUPPORT_TELL_MORE_ABOUT}
        </StandardText>
        <View
          style={[
            CommonStyles.justifyFlexColumnStart,
            CommonStyles.marginTop10,
          ]}
        >
          <SupportDescription
            style={CommonStyles.justifyFlexRowStart}
            description={this.props.description}
            save={this.props.save}
            readOnly={this.props.readOnly}
          />
        </View>
      </View>
    );
  }

  setLocalSupportImage = (requestObject: any) => {
    // Used to display the image
    const imageObjectState = {
      lastModified: new Date(),
      key: requestObject.uri,
      url: requestObject.uri,
      localImage: true,
    };

    // Sometimes Android returns a null for images content type
    // So if we don't know what it is use a jpg file.
    let contentType = 'image/jpeg';
    if (requestObject.type != null) {
      contentType = requestObject.type;
    }

    // Uses to upload the image to the API & get a signed URL
    const fileName = requestObject.fileName
      ? requestObject.fileName
      : requestObject.path.split('/').pop();
    const imageObjectUpload = {
      fileType: types2.FILE_TYPE.SUPPORT_PHOTO,
      filename: fileName,
      contentType,
      path: requestObject.path,
      localUri: requestObject.uri,
    };

    this.setState({supportImage: imageObjectState});
    this.props.actions.SupportsActions.setSupportImage(imageObjectUpload);
  };
}

const mapStateToProps = (state: any) => ({
  supports: state.SupportsReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SupportDescriptionSection);
