/*
 * Author: Brad D'Costa
 * Date: 15/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a standard input component which adds support for the following
 * - Error state with message
 * - Set's a default accessibilitylabel if none is passed for automation testing
 * - Icon on the right side
 */

import React, {Component} from 'react';
import {View, StyleSheet, Image, Platform} from 'react-native';
import DeviceInfo from 'react-native-device-info';
import {StandardInput, StandardText} from './';

import {SearchImageCharcoal} from '../../assets/images/vector-icons';
import CustomIcon from '../../assets/images/CustomIcon';

import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

let borderWidth;
class StandardSearch extends Component {
  render() {
    // If there is no error don't display a border, otherwise show a border if an error message is passed
    if (this.props.errorMessage != '' && this.props.errorMessage != undefined) {
      borderWidth = 3;
      var isError = true;
    } else {
      borderWidth = 0;
      var isError = false;
    }
    // If there is no acessibility label set use the placeholder text (For automation testing)
    // Otherwise use the label
    var accessibilityLabel =
      this.props.accessibilityLabel != undefined
        ? this.props.accessibilityLabel
        : this.props.placeholder;
    return (
      <View style={[CommonStyles.content]}>
        <View style={this._determinContainerStyle()}>
          <View style={[CommonStyles.marginRight5]}>
            <CustomIcon
              style={this._determineIconStyle()}
              name={SearchImageCharcoal}
            />
          </View>
          <View style={[CommonStyles.flex1]}>
            <StandardInput
              {...this.props}
              border={this.props.border}
              noMargin={true}
              applyWrapper={false}
              placeholderStyle={this._determinPlaceHolderStyle()}
              style={[
                CommonStyles.SearchInput,
                BrandStyles.errorBorder,
                CommonStyles.customFontBold,
                this.props.style,
                {borderWidth},
                !types.isWeb
                  ? DeviceInfo.isTablet()
                    ? CommonStyles.paddingBottom5
                    : CommonStyles.paddingBottom0
                  : CommonStyles.paddingBottom5,
              ]}
              hideTabOnFocus={true}
              noBorder={this._determineBoder()}
            />
          </View>
        </View>
        {this.renderErrorMessage(isError)}
      </View>
    );
  }

  _determineBoder = () => {
    let noBorder = true;
    if (!this.props.noBorder) {
      noBorder = this.props.noBorder;
    }
    return noBorder;
  };

  _determinContainerStyle = () => {
    const styles = [
      CommonStyles.flexDirectionRow,
      CommonStyles.alignItemsCenter,
    ];

    if (this.props.webStyles) {
      styles.push(this.props.webStyles);
    } else {
      styles.push(CommonStyles.StandardSearchContainer);
    }
    return styles;
  };

  _determineIconStyle = () => {
    let styles = [CommonStyles.searchBoxIcon];
    if (this.props.iconStyle) {
      styles = this.props.iconStyle;
    }
    return styles;
  };

  _determinPlaceHolderStyle = () => {
    let styles = [
      CommonStyles.font15,
      CommonStyles.paddingLeft10,
      CommonStyles.paddingRight0,
      CommonStyles.paddingTop0,
    ];
    if (this.props.placeHolderStyle) {
      styles = this.props.placeHolderStyle;
    }
    return styles;
  };

  renderErrorMessage = (errorFlag: any) => {
    if (errorFlag) {
      return (
        <StandardText
          style={[CommonStyles.inputErrorMessageText, CommonStyles.customFont]}
        >
          {this.props.errorMessage}
        </StandardText>
      );
    } else {
      return null;
    }
  };
}

export default StandardSearch;
