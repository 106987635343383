/*
 * Author: Brad D'Costa
 * Date: 30/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Summary tile on the MyProfile screen for the Supports Section
 */

import React, {Component} from 'react';
import {View, TouchableHighlight} from 'react-native';

import {
  ITEM_DIMENSION,
  DEFUALT_SUPPORT_CAPTION,
  FILE_TYPE,
} from '../../Constants/Constants2';
import {
  DISPLAY_MAX_SUPPORTS_MYPROFILE_TABLET,
  DISPLAY_MAX_SUPPORTS_MYPROFILE_PHONE,
  SCREEN_TYPE_MAIN,
  ACCESS_READ_ONLY,
  ACTION_BUTTON,
  isWeb,
} from '../../Constants/Constants';

import {StandardText} from '../Atoms';
import {SummaryTile, PhotoTile, GridView} from '../Molecules';

import {
  supportsIconCharcoal,
  supportsIconRed,
} from '../../assets/images/vector-icons';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import {isTablet} from '../../Helpers/PlatformSynchronizer';
//import DeviceInfo from 'react-native-device-info';

import {connect} from 'react-redux';
import * as SideMenuActions from '../../Actions/SideMenuActions';
import * as SupportsActions from '../../Actions/SupportsActions';
import * as NavigationParamActions from '../../Actions/NavigationParamActions';

import {bindActionCreators} from 'redux';

import {callAPIs} from '../../API/APICaller';
import {getSupportGroupItems} from '../../API/ReferenceAPI';

const CURRENT_SCREEN = 'MyProfileSupports';
const SELECTED = ' selected';
class MyProfileSupports extends Component {
  constructor(props: any) {
    super(props);
    this.renderCrew = this.renderCrew.bind(this);
  }

  render() {
    const itemLength = this.props.reducer.supports
      ? this.props.reducer.supports.length
      : 0;
    let maxLenth = DISPLAY_MAX_SUPPORTS_MYPROFILE_TABLET;

    if (!isTablet()) {
      maxLenth = DISPLAY_MAX_SUPPORTS_MYPROFILE_PHONE;
    }

    return (
      <SummaryTile
        headerDisplayString="Supports"
        headerIconImage={supportsIconRed}
        screenType={SCREEN_TYPE_MAIN}
        modal={this.props.modal}
        containsData={this._determineContainsData()}
        actionOverride={this._determineAccess()}
        displayMore={itemLength > maxLenth}
      >
        {this._renderSupports()}
      </SummaryTile>
    );
  }

  _determineAccess = () => {
    if (Object.getOwnPropertyNames(this.props.readOnly).length === 0) {
      return null;
    } else {
      const readOnly = this.props.readOnly;
      if (
        readOnly.access &&
        readOnly.access.profile &&
        this.props.readOnly.access.profile === ACCESS_READ_ONLY
      ) {
        return ACTION_BUTTON.VIEW;
      }
    }
    return null;
  };

  _determineContainsData = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return false;
    } else if (
      !this.props.reducer.supports ||
      this.props.reducer.supports.length === 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  _determinIcon = () => {
    if (this._determineContainsData()) {
      return supportsIconRed;
    } else {
      return supportsIconCharcoal;
    }
  };

  _renderSupports() {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      if (
        this.props.reducer.supports &&
        this.props.reducer.supports.length === 0
      ) {
        return null;
      } else if (this.props.reducer.supports) {
        const supportsToDisplay = this.props.reducer.supports.slice(
          0,
          isTablet
            ? DISPLAY_MAX_SUPPORTS_MYPROFILE_TABLET
            : DISPLAY_MAX_SUPPORTS_MYPROFILE_PHONE,
        );
        return (
          <View style={[CommonStyles.justifyCenter]}>
            <GridView
              viewWidth={this.props.viewWidth}
              itemDimension={ITEM_DIMENSION}
              isMobile={this.props.isMobile}
              childDimension={true}
            >
              {this.createSupportsArray(supportsToDisplay)}
            </GridView>
          </View>
        );
      }
    }
  }

  createSupportsArray(supports: any) {
    return supports.map((supportItem: any) => {
      let readOutSupport = '';
      if (supportItem.supportPhoto) {
        readOutSupport = supportItem.supportPhoto.photoCaption + SELECTED;
      } else if (supportItem.photoLocation) {
        readOutSupport = supportItem.photoLocation.split('/').pop() + SELECTED;
      } else if (supportItem.photoCaption) {
        readOutSupport = supportItem.photoCaption + SELECTED;
      } else {
        readOutSupport = DEFUALT_SUPPORT_CAPTION;
      }

      return (
        <TouchableHighlight
          onPress={() => this._navigateToEdit(supportItem)}
          underlayColor="white"
        >
          <View
            style={[
              CommonStyles.flexDirectionRow,
              isWeb ? CommonStyles.alignItemsCenter : null,
            ]}
          >
            <PhotoTile
              style={{width: 75, height: 75}}
              bottomMargin={false}
              apiFileType={FILE_TYPE.SUPPORT_PHOTO}
              imageObject={this._getSupportPhoto(supportItem)}
              actionCallback={this._navigateToEdit}
              actionData={supportItem}
              showCaption={false}
              clickable={true}
              photoBorderRadius={CommonStyles.borderRadiusRound}
              lastUpdate={this._lastUpdateTime(supportItem)}
              accessibilityLabel={readOutSupport}
            />
            <View
              style={[
                CommonStyles.flex1,
                CommonStyles.myProfileTileTextContainer,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.customFontBold,
                  CommonStyles.SummaryTileText,
                  BrandStyles.TextColor5,
                ]}
              >
                {supportItem.title}
              </StandardText>
              {this.renderCrew(supportItem)}
            </View>
          </View>
        </TouchableHighlight>
      );
    });
  }

  _getSupportPhoto = (supportItem: any) => {
    if (
      supportItem.supportPhoto !== undefined &&
      supportItem.imageUploadObject === undefined
    ) {
      return supportItem.supportPhoto;
    } else if (
      supportItem.imageUploadObject &&
      supportItem.imageUploadObject.localImage
    ) {
      return supportItem.imageUploadObject;
    } else {
      return null;
    }
  };

  _lastUpdateTime = (supportItem: any) => {
    if (
      supportItem.supportPhoto !== undefined &&
      supportItem.imageUploadObject === undefined &&
      supportItem.supportPhoto.lastModified
    ) {
      return new Date(supportItem.supportPhoto.lastModified);
    } else {
      return new Date();
    }
  };

  renderCrew(support: any) {
    const supportingCrew = support.supportingCrew ? support.supportingCrew : [];
    const supportingOrgCrew = support.supportingOrgCrew
      ? support.supportingOrgCrew
      : [];
    const SupportCrew = [...supportingCrew, ...supportingOrgCrew];
    if (SupportCrew.length > 0) {
      let crewDisplayText = '';
      for (let i = 0; i < SupportCrew.length; i++) {
        const crewMember = SupportCrew[i];
        if (crewDisplayText != '') {
          crewDisplayText += ', ';
          crewDisplayText += this.getName(crewMember);
        } else {
          crewDisplayText += this.getName(crewMember);
        }
      }
      return (
        <View>
          <StandardText
            style={[
              CommonStyles.customFont,
              CommonStyles.SummaryTileText,
              CommonStyles.textAlignVerticalCenter,
              BrandStyles.TextColor5,
            ]}
          >
            Crew:{' '}
          </StandardText>
          <StandardText
            style={[
              CommonStyles.customFont,
              CommonStyles.SummaryTileText,
              CommonStyles.textAlignVerticalCenter,
              BrandStyles.TextColor5,
            ]}
          >
            {crewDisplayText}
          </StandardText>
        </View>
      );
    } else {
      return null;
    }
  }

  _navigateToEdit = (support: any) => {
    if (isWeb) {
      this.props.setLoading(true);
    } else {
      this.setState({isLoading: true});
    }
    const callback = (data: any) => {
      const groups = data[0].content;
      this.props.actions.SupportsActions.actionGetSupportGroups(groups);
      if (isWeb) {
        this.props.setLoading(false);
      } else {
        this.setState({isLoading: false});
      }
      this.props.actions.NavParamActions.setParam({
        item: support,
      });
      this.props.navigation.navigate('AddSupportDetails', {
        edit: true,
        prevScreen: CURRENT_SCREEN,
      });
    };
    const requestBody = {
      groupId: support.supportGroupId,
      categoryId: support.categoryId,
      searchTerm: '',
      page: 1,
      itemsPerPage: 50,
    };
    if (support.supportGroupId && support.categoryId) {
      callAPIs([getSupportGroupItems(requestBody)], callback, null, () => {
        this.setState({isLoading: false});
      });
    } else {
      if (isWeb) {
        this.props.setLoading(false);
      } else {
        this.setState({isLoading: false});
      }
      this.props.actions.NavParamActions.setParam({
        item: support,
      });
      this.props.navigation.navigate('AddSupportDetails', {
        edit: true,
        prevScreen: CURRENT_SCREEN,
      });
    }
  };

  getName = (crew: any) => {
    if (
      !(crew.firstName && crew.lastName) ||
      (crew.firstName === '' && crew.lastName === '')
    ) {
      return `${crew.orgName}`;
    } else {
      if (crew.orgName && crew.orgName !== '') {
        return `${crew.firstName} ${crew.lastName} ${crew.orgName}`;
      } else {
        return `${crew.firstName} ${crew.lastName}`;
      }
    }
  };
}

const mapStateToProps = (state: any) => ({
  sideMenu: state.SideMenuReducer,
});
const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SideMenuActions: bindActionCreators(SideMenuActions, dispatch),
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
    NavParamActions: bindActionCreators(NavigationParamActions, dispatch),
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(MyProfileSupports);
