/*
 * Author: Declan Hart
 * Date: 28/04/2020
 * Copyright © 2020 Leap in!. All rights reserved.
 *
 * Component to display demo banner at top of screen
 */

import React, {Component} from 'react';
import {View, Image} from 'react-native';

import {StandardText, StandardTouchableOpacity} from '../Atoms';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {
  EXIT,
  DEMO_PROFILE_HEADER,
  CREATE_OWN_ACCOUNT,
} from '../../Constants/Constants2';

import {Logout2x} from '../../assets/images';

const DemoBanner = (props: any) => {
  return (
    <View
      style={[
        CommonStyles.flexDirectionRow,
        CommonStyles.justifyContentSpaceBetween,
        BrandStyles.secondaryBgColor3,
        CommonStyles.minHeight35,
        CommonStyles.paddingTop5,
      ]}
    >
      <View style={CommonStyles.width350} />
      <View style={CommonStyles.width350}>
        <StandardText style={CommonStyles.webSelectedMembersText}>
          {DEMO_PROFILE_HEADER}
        </StandardText>
      </View>
      <View style={[CommonStyles.flexDirectionRow, CommonStyles.width350]}>
        <StandardTouchableOpacity
          onPress={props.createAccount}
          style={[CommonStyles.flexDirectionRow, CommonStyles.paddingRight20]}
        >
          <StandardText
            style={[CommonStyles.webSelectedMembersText, CommonStyles.padding5]}
          >
            {CREATE_OWN_ACCOUNT}
          </StandardText>
          <Image
            style={[CommonStyles.image_25_25, CommonStyles.marginRight10]}
            source={Logout2x}
          />
        </StandardTouchableOpacity>
        <StandardTouchableOpacity
          onPress={props.logout}
          style={[CommonStyles.flexDirectionRow, CommonStyles.paddingRight20]}
        >
          <StandardText
            style={[CommonStyles.webSelectedMembersText, CommonStyles.padding5]}
          >
            {EXIT}
          </StandardText>
          <Image
            style={[CommonStyles.image_25_25, CommonStyles.marginRight10]}
            source={Logout2x}
          />
        </StandardTouchableOpacity>
      </View>
    </View>
  );
};

export default DemoBanner;
