/*
 * Author: Brad D'Costa
 * Date: 30/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Summary tile on the MyProfile screen for the Health Wellbeing Section
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View, Text} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import {StandardText} from '../Atoms';
import {SummaryTile} from '../Molecules';

import {
  healthWellbeingIconCharcoal,
  healthWellbeingIconRed,
} from '../../assets/images/vector-icons';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class MyProfileHealthWellbeing extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <SummaryTile
        headerDisplayString="Health + wellbeing"
        headerIconImage={healthWellbeingIconRed}
        screenType={types.SCREEN_TYPE_MAIN}
        modal={this.props.modal}
        containsData={this._determineContainsData()}
        actionOverride={this._determineAccess()}
        displayMore={this._checkMoreData()}
      >
        {this._renderHealthWellbeing()}
      </SummaryTile>
    );
  }

  _checkMoreData() {
    if (
      (this.props.reducer.ongoingHealth &&
        this.props.reducer.ongoingHealth.description) ||
      (this.props.reducer.healthConcerns &&
        (this.props.reducer.healthConcerns.description ||
          this.props.reducer.healthConcerns.difficulty ||
          this.props.reducer.healthConcerns.support))
    ) {
      return true;
    } else {
      return false;
    }
  }

  _determineAccess = () => {
    if (Object.getOwnPropertyNames(this.props.readOnly).length === 0) {
      return null;
    } else {
      let readOnly = this.props.readOnly;
      if (readOnly.access) {
        if (readOnly.access.profile) {
          if (this.props.readOnly.access.profile == types.ACCESS_READ_ONLY) {
            return types.ACTION_BUTTON.VIEW;
          }
        }
      }
    }
    return null;
  };

  _determineContainsData = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return false;
    } else {
      if (this.props.reducer.ongoingHealth.description == undefined) {
        return false;
      } else {
        if (
          this.props.reducer.ongoingHealth.description == '' &&
          this.props.reducer.healthConcerns.description == ''
        ) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  _determinIcon = () => {
    if (this._determineContainsData()) {
      return healthWellbeingIconRed;
    } else {
      return healthWellbeingIconCharcoal;
    }
  };

  _renderHealthWellbeing() {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      if (!this._determineContainsData()) {
        return null;
      } else {
        return (
          <View>
            <StandardText
              style={[
                CommonStyles.SummaryTileText,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
              ]}
            >
              {this.props.reducer.ongoingHealth.description}
            </StandardText>
          </View>
        );
      }
    }
  }
}

export default MyProfileHealthWellbeing;
