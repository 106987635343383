/*
 * Author: Brad D'Costa
 * Date: 24/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * customised Redux Store functions, so that the store can be referenced from
 * non-react components (eg. Web Service class)
 */

let store: any;

export default {
  init(configureStore: any) {
    store = configureStore();
  },
  getStore() {
    return store;
  },
};
