/*
 * Author: Andrew Seeley
 * Date: 05/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This screen gives the user the option to show if they already have an ndis plan and one without.
 */

import React, {Component} from 'react';
import {
  Platform,
  StyleSheet,
  View,
  Animated,
  Easing,
  Image,
  Dimensions,
  ScrollView,
} from 'react-native';
import {StandardButton, StandardText} from '../Components/Atoms';
import FitImage from 'react-native-fit-image';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {BrandIcons} from '../Styles/BrandIcons';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {
  SIGNUP_OPTIONS_SCREEN,
  ACCESSIBILITY_SCREEN_TITLES,
  MESSAGES as Messages,
} from '../Constants/Messages';
import * as UserActions from '../Actions/UserActions';
import {OfflineNotice} from '../Components/Organisms';

// Import Images
import {SignUpOptionsImage} from '../assets/images';
import {announceForAccessibility} from '../Helpers/PlatformSynchronizer';

// Get the device height & width
const ScreenHeight = Dimensions.get('window').height;
const ScreenWidth = Dimensions.get('window').width;

// Setup animations
const animationValue = new Animated.Value(0);

// Maps the animation values to start from the top of the screen out of view and finish at the bottom of the screen in view
const animateTopToBottom = animationValue.interpolate({
  inputRange: [0, 1],
  outputRange: [-ScreenHeight, 0.0],
});
// Maps the animation values to start from the bottom of the screen out of view and finish at the top of the screen in view
const animateBottomToTop = animationValue.interpolate({
  inputRange: [0, 1],
  outputRange: [ScreenHeight, 0.0],
});

class SignUpOptions extends Component {
  constructor(props: any) {
    super(props);
    this.navigateToPlanNewExisting = this.navigateToPlanNewExisting.bind(this);
    this.renderBackButton = this.renderBackButton.bind(this);
  }

  componentDidMount() {
    announceForAccessibility(ACCESSIBILITY_SCREEN_TITLES.SIGNUP);
  }

  render() {
    Animated.timing(animationValue, {
      toValue: 1,
      duration: 3000,
    }).start();

    return (
      <View style={[CommonStyles.screenWrapperContainer]}>
        <View
          style={[CommonStyles.content, CommonStyles.alignFlexStart]}
          contentContainerStyle={[
            CommonStyles.alignItemsCenter,
            CommonStyles.justifyContentCentre,
            CommonStyles.scrollViewVerticalCentre,
          ]}
        >
          {/* Overlay View - sits on top of the whole view as RN Android cannot overflow over flexboxes
                    This enables the Leap Into text to start on top of the */}
          <OfflineNotice />
          <Animated.View style={styles.overlay}>
            <ScrollView>
              {/* The flex here & in the children positions the title text and signup/login text appropiately. This ensures it appears consistent across different android devices */}
              <View style={CommonStyles.SignUpOptionsMT30}>
                <View
                  style={[
                    CommonStyles.alignItemsFlexStart,
                    BrandStyles.borderColor1,
                    BrandStyles.brandBlockBgColor6,
                    CommonStyles.alignItemsFlexStart,
                    CommonStyles.alignSelfFlexStart,
                  ]}
                >
                  <StandardText
                    style={[
                      CommonStyles.customFontBold,
                      CommonStyles.textAlignCentre,
                      CommonStyles.font58,
                      BrandStyles.TextColor1,
                      BrandStyles.borderColor1,
                      CommonStyles.SignUpOptionsBW4P10,
                    ]}
                  >
                    {SIGNUP_OPTIONS_SCREEN.HEADER}
                  </StandardText>
                </View>

                <View style={CommonStyles.SignUpOptionsFDJCAIMLP}>
                  <StandardButton
                    ref="buttonParticipant"
                    style={[
                      CommonStyles.font58,
                      CommonStyles.textAlignCentre,
                      CommonStyles.customFontBold,
                      BrandStyles.brandBlockTxtColor6,
                      CommonStyles.SignUpOptionsPLPR5,
                    ]}
                    containerStyle={[
                      BrandStyles.primaryBgColor1,
                      CommonStyles.elevation5,
                      BrandStyles.borderColor1,
                      CommonStyles.SignUpOptionsBW4ML20P,
                    ]}
                    onPress={() =>
                      this.navigateToPlanNewExisting(
                        types.SIGNUP_TYPE_PARTICIPANT,
                      )
                    }
                  >
                    {SIGNUP_OPTIONS_SCREEN.PARTICIPANT}
                  </StandardButton>
                  <View
                    style={[
                      CommonStyles.flexRow,
                      CommonStyles.flex1,
                      CommonStyles.SignUpOptionsPLPR20P,
                    ]}
                  >
                    <View
                      style={[
                        CommonStyles.SignUpOptionsPR10P,
                        CommonStyles.alignSelfCenter,
                      ]}
                    >
                      <View
                        style={[
                          BrandStyles.SignUpOptionsBackgroundColor,
                          CommonStyles.justifyContentCentre,
                        ]}
                      >
                        <StandardText
                          style={[
                            CommonStyles.customFontBold,
                            CommonStyles.font58,
                            CommonStyles.textAlignCentre,
                            BrandStyles.brandBlockTxtColor3,
                          ]}
                        >
                          {SIGNUP_OPTIONS_SCREEN.SEPARATOR}
                        </StandardText>
                      </View>
                    </View>
                    <View style={CommonStyles.SignUpOptionsPL10P}>
                      <View
                        style={[
                          CommonStyles.SignUpOptionsBW4,
                          CommonStyles.justifyContentCentre,
                          CommonStyles.elevation5,
                          BrandStyles.borderColor1,
                          BrandStyles.SignUpOptionsBackgroundColor,
                        ]}
                      >
                        <StandardText
                          style={[
                            CommonStyles.customFont,
                            CommonStyles.font17,
                            BrandStyles.brandBlockTxtColor3,
                          ]}
                        >
                          {SIGNUP_OPTIONS_SCREEN.PARTICIPANT_SUBTEXT}
                        </StandardText>
                      </View>
                    </View>
                  </View>
                  <StandardButton
                    style={[
                      CommonStyles.font58,
                      CommonStyles.textAlignCentre,
                      CommonStyles.customFontBold,
                      BrandStyles.brandBlockTxtColor6,
                      CommonStyles.SignUpOptionsPLPR5,
                    ]}
                    containerStyle={[
                      BrandStyles.primaryBgColor1,
                      CommonStyles.elevation5,
                      BrandStyles.borderColor1,
                      CommonStyles.SignUpOptionsBW4ML3P,
                    ]}
                    onPress={() =>
                      this.navigateToPlanNewExisting(types.SIGNUP_TYPE_CREW)
                    }
                  >
                    {SIGNUP_OPTIONS_SCREEN.CREW}
                  </StandardButton>
                  <View style={[CommonStyles.SignUpOptionsPL10PPR40P]}>
                    <View
                      style={[
                        CommonStyles.SignUpOptionsBW4,
                        CommonStyles.justifyContentCentre,
                        CommonStyles.elevation5,
                        BrandStyles.borderColor1,
                        BrandStyles.SignUpOptionsBackgroundColor,
                      ]}
                    >
                      <StandardText
                        style={[
                          CommonStyles.customFont,
                          CommonStyles.font17,
                          BrandStyles.brandBlockTxtColor3,
                        ]}
                      >
                        {SIGNUP_OPTIONS_SCREEN.CREW_SUBTEXT}
                      </StandardText>
                    </View>
                  </View>
                  <View
                    style={[
                      CommonStyles.flexRow,
                      CommonStyles.flex1,
                      CommonStyles.alignFlexEnd,
                      CommonStyles.SignUpOptionsMT10,
                    ]}
                  >
                    <StandardText
                      style={[
                        CommonStyles.customFontMedium,
                        CommonStyles.Transparent,
                        CommonStyles.font25,
                        BrandStyles.brandBlockTxtColor3,
                      ]}
                    >
                      {SIGNUP_OPTIONS_SCREEN.CHOOSE_OPTION}
                    </StandardText>
                  </View>
                </View>
              </View>
              <View>
                {Platform.OS === types2.IOS ? this.renderBackButton() : null}
              </View>
            </ScrollView>
          </Animated.View>
          {/* Underlying View */}
          <View style={[CommonStyles.flexRow]}>
            {/* The hero image that comes in from the top */}
            <View style={CommonStyles.SignUpOptionsF34}>
              <Animated.View style={styles.heroBanner}>
                <FitImage
                  style={styles.heroImage}
                  source={SignUpOptionsImage}
                />
                <Image
                  style={CommonStyles.liLogoCorner}
                  source={BrandIcons.LOGO_ICON}
                />
              </Animated.View>
            </View>
            {/* The content view - this sets it's background colour and animation to come in from the bottom */}
            <Animated.View
              style={[
                styles.backgroundContent,
                BrandStyles.SignUpOptionsBackgroundColor,
              ]}
            />
          </View>
        </View>
      </View>
    );
  }

  /*
   * Save the plan type you have selected to redux
   * Navigate to the sign up options for new or existing plan after this
   * @param {string} type The sign up type you have selected.
   */
  navigateToPlanNewExisting(type: any) {
    if (type == types.SIGNUP_TYPE_PARTICIPANT) {
      this.props.actions.actionUpdateIsMember(true);
      this.props.actions.actionUpdateIsCrew(false);
    } else if (type == types.SIGNUP_TYPE_CREW) {
      this.props.actions.actionUpdateIsMember(false);
      this.props.actions.actionUpdateIsCrew(true);
    }
    // this.props.actions.actionUpdateSignUpType(type);
    this.props.navigation.navigate('SignUpOptionsNewExisting', {
      signUpType: type,
    });
  }

  renderBackButton() {
    return (
      <StandardButton
        style={[
          CommonStyles.textAlignCentre,
          CommonStyles.font37,
          CommonStyles.customFont,
          CommonStyles.buttonText,
          BrandStyles.TextColor1,
          {padding: 10},
        ]}
        containerStyle={[
          BrandStyles.LoginSignUpBackgroundColor,
          CommonStyles.elevation5,
          BrandStyles.borderColor1,
          {
            borderWidth: 4,
            marginTop: 10,
            width: 100,
            marginLeft: 100,
          },
        ]}
        onPress={() => this.props.navigation.goBack()}
      >
        {Messages.GO_BACK.CANCEL}
      </StandardButton>
    );
  }
}

const styles = StyleSheet.create({
  // Animations for the class
  heroBanner: {
    transform: [{translateY: animateTopToBottom}],
  },
  heroImage: {
    height: ScreenHeight,
    width: ScreenWidth * 0.34,
  },
  /* Overlay View - sits on top of the whole view as RN Android cannot overflow over flexboxes
                This enables the Leap Into text to start on top of the */
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: '28%',
    zIndex: 100,
    transform: [{translateY: animateBottomToTop}],
  },
  // This is the right hand side content background.
  // It ensures the colour is set and animates from the bottom to the topz
  backgroundContent: {
    flex: 66,
    transform: [{translateY: animateBottomToTop}],
  },
});

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(UserActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpOptions);
