/*
 * Authour: Andrew Lee
 * Date: 09/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Crew information that has been obtained.
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function CrewReducer(state = {}, action: any) {
  switch (action.type) {
    case types2.LIVE_WITH_NEW_CREW:
      // new crewid created from who I live with section
      return {
        ...state,
        newCrewId: action.newCrewId,
      };
    case types2.REMOVE_LIVE_WITH_NEW_CREW:
      // remove new crewid created from who I live with section
      return {
        ...state,
        newCrewId: undefined,
      };
    case types.GET_CREWS:
      // Combine the crew categories, crews, and crew access levels.
      const crews = JSON.parse(JSON.stringify(action.crews));
      const personCrewMembers = action.personCrewMembers;
      const organisationCrewMembers = action.organisationCrewMembers;
      const crewAccessLevels =
        action.crewAccessLevels && action.crewAccessLevels.accessRights
          ? action.crewAccessLevels.accessRights
          : null;
      let crewMembers = [];
      // Add the access rights to each crew member
      for (const i in personCrewMembers) {
        // For each category
        for (const j in personCrewMembers[i].crews) {
          // Get all the crew members that belong to that category
          // Get the individual crew relationship ID
          const crewRelationshipId =
            personCrewMembers[i].crews[j].relationshipId;

          const personMember = personCrewMembers[i].crews[j];
          personMember.memberType = 'person';
          personMember.extraInformation = '';
          personMember.ndisRegistrationNumber = '';
          personMember.organisationGuid = '';
          personMember.memberId = '';
          personMember.website = '';

          personCrewMembers[i].crews[j] = personMember;

          // Get the matching relationship ID in crewAccess and add it to that individual crew member
          if (crewAccessLevels) {
            const personCrewAccess = crewAccessLevels.find(
              (access: any) => access.relationshipId == crewRelationshipId,
            );
            personCrewMembers[i].crews[j].access = personCrewAccess.access;
          }
        }
      }

      for (const i in organisationCrewMembers) {
        // For each category
        for (const j in organisationCrewMembers[i].organisationCrews) {
          // Get all the crew members that belong to that category
          // Get the individual crew relationship ID

          const organisationMember =
            organisationCrewMembers[i].organisationCrews[j];
          organisationMember.memberType = 'organisation';
          organisationMember.firstName = '';
          organisationMember.knownAs = '';
          organisationMember.lastName = '';
          organisationMember.notificationType = '';
          organisationMember.relationship = '';
          organisationMember.support = '';

          organisationCrewMembers[i].organisationCrews[j] = organisationMember;
        }
      }

      // add person crew and organisation crew members to list
      crewMembers = [...personCrewMembers, ...organisationCrewMembers];

      // The top crew - this is in the order from the API, as categories will be in the c
      var crewsIndividual = [];
      var personCrews = JSON.parse(JSON.stringify(action.crews));
      var orgCrews = JSON.parse(JSON.stringify(action.crews));

      // Add the crews to their respective categories in crews
      for (const i in crews) {
        // For each crew category
        crews[i].crews = []; // Init the crews array - this holds the crew members for the givne category
        personCrews[i].crews = [];
        orgCrews[i].crews = [];
        // Add the crew to our individual items
        for (const j in crewMembers) {
          // Find the members that match this category & push it
          // We found a match for this member, add it to their correct crew category and break, move onto the next member until done
          if (crews[i].type == crewMembers[j].type) {
            // Add the crew members in this category to the crewsIndividual array
            // Since it's an arary of crews we use apply to push it to the array
            if (crewMembers[j].crews && crewMembers[j].crews.length > 0) {
              crewsIndividual.push(...crewMembers[j].crews);
              crews[i].crews.push(...crewMembers[j].crews);
              personCrews[i].crews = crewMembers[j].crews;
            } else if (
              crewMembers[j].organisationCrews &&
              crewMembers[j].organisationCrews.length > 0
            ) {
              crewsIndividual.push(...crewMembers[j].organisationCrews);
              crews[i].crews.push(...crewMembers[j].organisationCrews);
              orgCrews[i].crews = crewMembers[j].organisationCrews;
            }
          }
        }
      }

      return {
        ...state,
        crewIndividual: crewsIndividual,
        crew: personCrews,
        PersonOrgCrew: crews,
        organisationCrew: orgCrews,
        apiCalled: new Date(),
      };
    case types.CREWS.ADD_EDIT_CREW_LIST:
      const newCategoryIndex = null;
      let previousCategoryIndex = null;
      let previousCrewIndex = null;
      let individualCrewIndex = null;
      let previousCategory = null;

      let newCrew = action.crew;
      let previousCrews = state.crew;
      let previousIndividualCrew = state.crewIndividual;

      // Check to determine if the crew already exists as an individual
      for (const individualCrew in previousIndividualCrew) {
        const crew = previousIndividualCrew[individualCrew];
        if (crew.crewId == newCrew.crewId) {
          individualCrewIndex = individualCrew;
          previousCategory = crew.category;
          break;
        }
      }

      let nextCategoryIndex = undefined;

      // Find the location of the new category and / or previous category
      for (const categoryIndex in previousCrews) {
        const previousCrewCategory = previousCrews[categoryIndex];

        if (previousCrewCategory.type == newCrew.category) {
          nextCategoryIndex = categoryIndex;
        }

        if (previousCrewCategory.type == previousCategory) {
          previousCategoryIndex = categoryIndex;
        }
      }

      // If the next index exists and previous index exists, will need to find the location of the crew
      if (previousCategoryIndex) {
        // Find the location of the crew index inside the category
        for (const crewIndex in previousCrews[previousCategoryIndex].crews) {
          if (
            previousCrews[previousCategoryIndex].crews[crewIndex].crewId ==
            newCrew.crewId
          ) {
            previousCrewIndex = crewIndex;
            break;
          }
        }
      }

      // If there is an index to the next category, it means the previous crew will need to be removed
      if (nextCategoryIndex && previousCrewIndex) {
        previousCrews[previousCategoryIndex].crews.splice(previousCrewIndex, 1);
      }
      // The next category is empty and the previous category is not, which means it needs to update the existing one
      else if (
        !nextCategoryIndex &&
        previousCategoryIndex &&
        previousCrewIndex
      ) {
        previousCrews[previousCategoryIndex].crews[previousCrewIndex] = newCrew;
      }

      // Need to add it to next category index
      if (nextCategoryIndex) {
        previousCrews[nextCategoryIndex].crews.push(newCrew);
      }

      // If the individual crew has been found, just update the crew
      if (individualCrewIndex) {
        previousIndividualCrew[individualCrewIndex] = newCrew;
      } else {
        previousIndividualCrew.push(newCrew);
      }

      return {
        ...state,
        crew: previousCrews,
        crewIndividual: previousIndividualCrew,
      };
    case types.CREWS.DELETE_CREW_FROM_LIST: {
      newCrew = action.crew;
      previousCrews = state.crew;
      previousIndividualCrew = state.crewIndividual;

      // First of all check if the crew already exists & if so just modify it
      for (let i in previousCrews) {
        if (!previousCrews[i].crews) {
          break;
        }
        if (previousCrews[i].type == newCrew.category) {
          // Go through all the crew in the category
          for (const j in previousCrews[i].crews) {
            if (previousCrews[i].crews[j].crewId == newCrew.crewId) {
              // Now delete the crew
              previousCrews[i].crews.splice(j, 1);
              // Since its also an existing crew find it in the crew individual
              for (i in previousIndividualCrew) {
                if (previousIndividualCrew[i].crewId == newCrew.crewId) {
                  previousIndividualCrew.splice(i, 1);
                }
              }
              break;
            }
          }
        }
      }

      return {
        ...state,
        crew: previousCrews,
        crewIndividual: previousIndividualCrew,
      };
    }
    case types.CREWS.SET_CREW_ITEM: // set the selected crew item, either adding or selecing.
      return {
        ...state,
        selectedCrew: action.crew,
      };
    case types.CREWS.CREW_UPDATED:
      return {
        ...state,
        lastUpdated: new Date(),
      };
    case types.GET_CREW_CAT:
      const crewCategories = action.crewCategories;
      return {
        ...state,
        crewCategories,
      };
    default:
      return state;
  }
}
