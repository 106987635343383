/*
 * Author: Brad D'Costa
 * Date: 30/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Summary tile on the MyProfile screen for the Home Section
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import {StandardText} from '../Atoms';
import {SummaryTile} from '../Molecules';

import {homeIconCharcoal, homeIconRed} from '../../assets/images/vector-icons';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class MyProfileHome extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <SummaryTile
        headerDisplayString="Home"
        headerIconImage={homeIconRed}
        screenType={types.SCREEN_TYPE_MAIN}
        modal={this.props.modal}
        containsData={this._determineContainsData()}
        actionOverride={this._determineAccess()}
        displayMore={this._checkMoreData()}
      >
        {this._renderWhereILive()}
      </SummaryTile>
    );
  }

  _checkMoreData() {
    if (
      this.props.reducer &&
      ((this.props.reducer.modification &&
        this.props.reducer.modification.detail) ||
        (this.props.reducer.supportAtHome &&
          this.props.reducer.supportAtHome.length))
    ) {
      return true;
    } else {
      return false;
    }
  }

  _determineAccess = () => {
    if (Object.getOwnPropertyNames(this.props.readOnly).length === 0) {
      return null;
    } else {
      let readOnly = this.props.readOnly;
      if (readOnly.access) {
        if (readOnly.access.profile) {
          if (this.props.readOnly.access.profile == types.ACCESS_READ_ONLY) {
            return types.ACTION_BUTTON.VIEW;
          }
        }
      }
    }
    return null;
  };

  _determineContainsData = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return false;
    } else {
      var checkArray = [
        this.props.reducer.homeType.type,
        this.props.reducer.livingWith,
        this.props.reducer.numberOfPeople,
        this.props.reducer.liveWithDisplayData,
      ];
      var dataCheck = false;
      for (var i = 0; i < checkArray.length; i++) {
        if (checkArray[i]) {
          if (Array.isArray(checkArray[i])) {
            if (checkArray[i].length > 0) {
              dataCheck = true;
              break;
            }
          } else {
            dataCheck = true;
            break;
          }
        }
      }
      return dataCheck;
    }
  };

  _determineIcon = () => {
    if (this._determineContainsData()) {
      return homeIconRed;
    } else {
      return homeIconCharcoal;
    }
  };

  _renderWhereILive = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      return (
        <View>
          {this._renderCombinedTypeLivingWith()}
          {this._renderLivingWithCount()}
          {this.renderHomeModification()}
        </View>
      );
    }
  };

  _renderCombinedTypeLivingWith = () => {
    if (this._renderHomeType() == null && this._renderLivingWith() == null) {
      return null;
    } else {
      return (
        <StandardText
          style={[
            CommonStyles.SummaryTileText,
            BrandStyles.TextColor5,
            CommonStyles.customFont,
          ]}
        >
          I live{' '}
          {(this._renderHomeType()
            ? '' + this._renderHomeType()
            : ''
          ).toLowerCase()}
          {(this._renderLivingWith()
            ? '' + this._renderLivingWith()
            : ''
          ).toLowerCase()}
        </StandardText>
      );
    }
  };

  _renderHomeType = () => {
    var homeType = '';
    if (this.props.reducer.homeType.description) {
      homeType = 'in a ' + this.props.reducer.homeType.description + ' ';
    }

    if (homeType == '') {
      return null;
    } else {
      return homeType;
    }
  };

  _renderLivingWith = () => {
    var livingWith = '';
    var livingWithArray = this.props.reducer.livingWith;
    for (var i = 0; i < livingWithArray.length; i++) {
      if (livingWith != '') {
        livingWith = livingWith + ', ' + livingWithArray[i].description;
      } else {
        livingWith += livingWithArray[i].description;
      }
    }
    if (livingWith == '') {
      return null;
    } else {
      return livingWith;
    }
  };

  _renderLivingWithCount = () => {
    var livingWithCount = '';
    if (this.props.reducer.numberOfPeople != 0) {
      if (this.props.reducer.numberOfPeople == 1) {
        livingWithCount =
          'All up I live with ' + this.props.reducer.numberOfPeople + ' person';
      } else {
        livingWithCount =
          'All up I live with ' + this.props.reducer.numberOfPeople + ' people';
      }
    }

    if (livingWithCount == '') {
      return null;
    } else {
      return (
        <StandardText
          style={[
            CommonStyles.SummaryTileText,
            BrandStyles.TextColor5,
            CommonStyles.customFont,
          ]}
        >
          {livingWithCount}
        </StandardText>
      );
    }
  };

  renderHomeModification = () => {
    if (
      this._determineContainsData() &&
      this.props.reducer.modification.isModified
    ) {
      return (
        <StandardText
          style={[
            CommonStyles.SummaryTileText,
            BrandStyles.TextColor5,
            CommonStyles.customFont,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
              CommonStyles.customFontBold,
            ]}
          >
            Yes
          </StandardText>
          , I currently have modifications to my home.
        </StandardText>
      );
    } else if (
      this._determineContainsData() &&
      !this.props.reducer.modification.isModified
    ) {
      return (
        <StandardText
          style={[
            CommonStyles.SummaryTileText,
            BrandStyles.TextColor5,
            CommonStyles.customFont,
          ]}
        >
          My home has not been modified
        </StandardText>
      );
    } else {
      return null;
    }
  };
}

export default MyProfileHome;
