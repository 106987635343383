/*
 * Author: Roshan Piumal
 * Date: 07/06/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a back button component with standard styling applied.
 */

import React, {Component} from 'react';
import {View} from 'react-native';
import {StandardText} from './';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import {
  LEFT_ROTATE_STRIPE,
  RIGHT_ROTATE_STRIPE,
} from '../../Styles/OnboardingStyles';
import BrandStyles from '../../Styles/BrandStyles';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

class HeaderWarningBannerWeb extends Component {
  render() {
    return (
      <View style={[CommonStyles.headerWarningBannerBackground]}>
        <div style={LEFT_ROTATE_STRIPE} />
        <View>
          <StandardText
            allowFontScaling={true}
            style={[
              CommonStyles.Transparent,
              CommonStyles.customFont,
              BrandStyles.TextColor2,
              CommonStyles.headerWarningBanner,
            ]}
          >
            {this.props.children}
          </StandardText>
        </View>
        <div style={RIGHT_ROTATE_STRIPE} />
      </View>
    );
  }
}

export default HeaderWarningBannerWeb;
