/*
 * Author: Declan Hart
 * Date: 18/11/2019
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Admin reducer for feature flags
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const actionGetFeatureFlags = (flagsObj: any) => ({
  type: types.GET_FEATURE_FLAGS,
  flagsObj,
});
