/*
 * Author: Declan Hart
 * Date: 27/5/2019
 * Copyright © 2019 Leap in!. All rights reserved.
 *
 * This is a component torender the tips and tricks link button and open the link.
 */

import React from 'react';
import {View, Image, Linking} from 'react-native';

import {StandardText, StandardTouchableOpacity} from './';

import {BUDGETS_AND_PAYMENTS_SCREEN as messages} from '../../Constants/Messages';

import {LILogo} from '../../assets/images';

import {WEBSITE_TIPS_TRICKS} from '../../Constants/Constants';

import CommonStyles from '../../Styles/CommonStyles';

import BrandStyles from '../../Styles/BrandStyles';

const TipsAndTricks = function (props: any) {
  if (!props.showTipsAndTricks) {
    return null;
  }
  return (
    <StandardTouchableOpacity
      accessibilityLabel={messages.TRACK_HELP_1 + ' ' + messages.TRACK_HELP_2}
      onPress={() => Linking.openURL(WEBSITE_TIPS_TRICKS)}
    >
      <View
        accessible={true}
        accessibilityLabel={messages.TRACK_HELP_1 + ' ' + messages.TRACK_HELP_2}
      >
        <StandardText
          style={[
            CommonStyles.trackingSubTitle,
            CommonStyles.customFont,
            BrandStyles.TextColor5,
          ]}
        >
          {messages.TRACK_HELP_1 + ' '}
        </StandardText>
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.alignCenter,
            CommonStyles.marginTop5,
          ]}
        >
          <Image
            style={[CommonStyles.image_35_35, CommonStyles.marginRight10]}
            source={LILogo}
          />
          <StandardText
            style={[
              CommonStyles.trackingSubTitle,
              CommonStyles.customFontMedium,
              BrandStyles.TextColor5,
            ]}
          >
            {messages.TRACK_HELP_2}
          </StandardText>
        </View>
      </View>
    </StandardTouchableOpacity>
  );
};

export default TipsAndTricks;
