/*
 * Author: Gayan
 * Date: 16/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * OfflineNotice actions for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const shakeOfflineNotice = () => ({
  type: types2.OFFLINE_NOTICE_SHAKE,
});
