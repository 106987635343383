/*
 * Author: Brad D'Costa
 * Date: 29/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Member Living Arrangement information.
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {nullStringReplacer} from '../API/APIHelper';

/* Expected DataModel as per v22 of Swagger Definitions (versioning is based on Confluence versioning)
 *  {
 *      "homeType": {
 *          "type": integer,
 *          "description": "string",
 *      },
 *      "livingWith": [
 *          {
 *              "type": "string
 *              "description": "string"
 *              "imageURL": "string"
 *          }
 *      ]
 *      "numberOfPeople": integer,
 *      "supportAtHome": [
 *          {
 *              "crewId": "string
 *              "firstName": "string"
 *              "lastName": "string"
 *              "email": "string",
 *              "knownAs": "string",
 *              "phoneNumber": "string",
 *              "relationshipId": integer,
 *              "relationshipType": "string",
 *              "category": "string",
 *              "orgName": "string",
 *              "support": "string",
 *              "profileImage": {
 *                  "key": "string"
 *                  "lastModified:": "string"
 *                  "profileImageURL": "string"
 *              }
 *          }
 *      ]
 *      "modification": {
 *          "detail": integer,
 *      }
 */

export default function LivingArrangementReducer(state = {}, action: any) {
  switch (action.type) {
    case types.GET_LIVING_ARRANGEMENT:
      var parsedhomeTypeObj = {};
      if (action.livingObj.homeType != null) {
        parsedhomeTypeObj = {
          type: nullStringReplacer(action.livingObj.homeType.type),
          description: nullStringReplacer(
            action.livingObj.homeType.description,
          ),
        };
      }

      var parsedLivingWithArr = [];
      if (action.livingObj.livingWith != null) {
        for (var i = 0; i < action.livingObj.livingWith.length; i++) {
          var tempObj = {
            type: nullStringReplacer(action.livingObj.livingWith[i].type),
            description: nullStringReplacer(
              action.livingObj.livingWith[i].description,
            ),
          };
          parsedLivingWithArr.push(tempObj);
        }
      }

      var parsedNumberOfPeople = nullStringReplacer(
        action.livingObj.numberOfPeople,
      );

      var parsedSupportAtHomeArr = [];
      if (action.livingObj.supportAtHome != null) {
        for (var i = 0; i < action.livingObj.supportAtHome.length; i++) {
          var tempObj = {
            id: nullStringReplacer(action.livingObj.supportAtHome[i].id),
          };
          parsedSupportAtHomeArr.push(tempObj);
        }
      }

      var parsedModification = {
        isModified: nullStringReplacer(
          action.livingObj.modification.isModified,
        ),
        detail: nullStringReplacer(action.livingObj.modification.detail),
      };

      return {
        homeType: parsedhomeTypeObj,
        livingWith: parsedLivingWithArr,
        modification: parsedModification,
        supportAtHome: parsedSupportAtHomeArr,
        numberOfPeople: parsedNumberOfPeople,
        apiCalled: new Date(),
      };
    case types.UPDATE_LIVING_ARRANGEMENT_HOME:
      var newValue = {
        homeType: {
          type: nullStringReplacer(action.homeObj.homeType.type),
          description: nullStringReplacer(action.homeObj.homeType.description),
        },
        numberOfPeople: action.homeObj.numberOfPeople,
        livingWith: action.homeObj.livingWith,
      };

      var updatedState = Object.assign({}, state);
      for (var k in newValue) updatedState[k] = newValue[k];
      return updatedState;

    case types.UPDATE_LIVING_ARRANGEMENT_MODIFICATION:
      var newValue = {
        modification: {
          isModified: nullStringReplacer(
            action.modificationObj.modification.isModified,
          ),
          detail: nullStringReplacer(
            action.modificationObj.modification.detail,
          ),
        },
      };
      var updatedState = Object.assign({}, state);
      for (var k in newValue) updatedState[k] = newValue[k];
      return updatedState;

    case types.WHO_I_LIVE_WITH.SET_DATA:
      var newValue = {
        liveWithOriginalData: action.whoILiveWithObj.originalData,
        liveWithModalData: action.whoILiveWithObj.modalData,
        liveWithDisplayData: action.whoILiveWithObj.displayData,
      };
      var updatedState = Object.assign({}, state);
      for (var k in newValue) updatedState[k] = newValue[k];
      return updatedState;

    case types.WHO_I_LIVE_WITH.RESET_DATA:
      var newValue = {
        liveWithModalData: action.whoILiveWithObj,
      };
      var updatedState = Object.assign({}, state);
      for (var k in newValue) updatedState[k] = newValue[k];
      return updatedState;

    case types.WHO_I_LIVE_WITH.UPDATE_ON_SAVE:
      var newValue = {
        liveWithOriginalData: action.whoILiveWithObj.liveWithOriginalData,
        supportAtHome: action.whoILiveWithObj.supportAtHome,
        liveWithDisplayData: action.whoILiveWithObj.liveWithDisplayData,
      };
      var updatedState = Object.assign({}, state);
      for (var k in newValue) updatedState[k] = newValue[k];
      return updatedState;

    default:
      return state;
  }
}
