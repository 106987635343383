/*
 * Author: Nirodha Perera
 * Date: 08/03/2019
 * Copyright © 2019 Leap in!. All rights reserved.
 *
 * Display summary of guide me for web side menu
 */

import React, {Component} from 'react';
import {Platform, View} from 'react-native';

import {
  GUIDEME_GENERAL_CONTENT,
  GUIDE_ME_CONTENT_TYPE,
  BUDGETS,
  WEB_STORE,
} from '../../Constants/Constants2';
import {ACTION_BUTTON, isWeb} from '../../Constants/Constants';

//Import styles
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {
  GUIDE_ME_TEXT,
  GUIDE_ME_RICH_TEXT,
  GUIDE_ME_IMAGE,
  GUIDE_ME_TEXT_WEB,
  GUIDE_ME_RICH_TEXT_WEB,
} from '../../Styles/ResponsiveCSS';

//Import custom components
import {StandardText} from '../Atoms';
import {ActionButton} from './';

//Import extermal components
import {WebViewWeb as WebView} from 'helpers/ReactNativeWebview';
import {getValue} from '../../API/WebPersistenceStore';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

//Import redux actions
import * as GuideMeActions from '../../Actions/GuideMeActions';
import * as TabBarActions from '../../Actions/TabBarActions';

//Import API call helper methods
import {callAPIs} from '../../API/APICaller';
import {getGuideMeContent} from '../../API/GuideMeAPI';

//Import environments data
import {APP_ID} from '../../environments';

const viewMore = 'View \nmore';
const labelPosition = 'bottom';
class GuideMeSummary extends Component {
  state = {
    dataArray: [],
    loading: false,
    summaryView: {
      width: 0,
      height: 0,
      x: 0,
    },
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    if (
      this.props.loadedMember.loadedMemberId &&
      this.props.guideMe.currentScreen
    ) {
      this._retrieveGuideMeContent(this.props.guideMe.currentScreen);
    }
  }

  componentDidUpdate(prevProps: any) {
    if (
      (this.props.loadedMember.loadedMemberId &&
        prevProps.loadedMember.loadedMemberId !==
          this.props.loadedMember.loadedMemberId) ||
      (this.props.guideMe.currentScreen &&
        prevProps.guideMe.currentScreen !== this.props.guideMe.currentScreen)
    ) {
      this._retrieveGuideMeContent(this.props.guideMe.currentScreen);
    }
  }

  _retrieveGuideMeContent = (currentScreen: any) => {
    this.setState({
      loading: true,
      dataArray: [],
    });

    const callbackFunction = (results: any) => {
      let guideMePayload = results[0].content.instructionResult;
      console.log('guideMePayload', guideMePayload);
      if (!Array.isArray(guideMePayload) || !guideMePayload?.length) {
        guideMePayload = GUIDEME_GENERAL_CONTENT;
      }

      // Write the data to redux
      this.props.actions.GuideMeActions.getGuideMeResults(guideMePayload);
      this.setState({
        loading: false,
        dataArray: guideMePayload,
      });
    };

    const guideMeErrorCallback = (err: any) => {
      if (err.message) {
        this.setState({
          loading: false,
        });
      }
    };
    let loadedMemberId = this.props.loadedMember.loadedMemberId;
    const storedMemberId = getValue(WEB_STORE.LOADED_MEMBER_ID);

    if (!loadedMemberId && storedMemberId) {
      loadedMemberId = storedMemberId;
    }

    callAPIs(
      [
        getGuideMeContent(
          loadedMemberId,
          currentScreen,
          this.props.user.demoProfile,
        ),
      ],
      callbackFunction,
      guideMeErrorCallback,
      () => {
        this.setState({
          loading: false,
        });
      },
    );
  };

  _applyCSS(content: any) {
    var cssStyles = '';
    switch (content.ContentType) {
      case GUIDE_ME_CONTENT_TYPE.PLAIN_TEXT:
        cssStyles = isWeb ? GUIDE_ME_TEXT_WEB : GUIDE_ME_TEXT;
        break;
      case GUIDE_ME_CONTENT_TYPE.HTML:
        cssStyles = isWeb ? GUIDE_ME_RICH_TEXT_WEB : GUIDE_ME_RICH_TEXT;
        break;
      case GUIDE_ME_CONTENT_TYPE.IMAGE:
        cssStyles = GUIDE_ME_IMAGE;
        break;
      case GUIDE_ME_CONTENT_TYPE.CUSTOM_HTML:
        break;
    }
    // get the trimmed summary content
    const finalContent = this.getSummaryContent(content.Content || content.CONTENT);

    const htmlContent = `<style>${cssStyles}</style>  ${finalContent}`;
    return htmlContent;
  }

  getSummaryContent = (content: any) => {
    const stringValue = content
      .replace(/<\/?p[^>]*>/g, '')
      .replace(/\s\s+/g, ' ');

    const summaryString = stringValue.substring(0, 124);

    return `<p>${summaryString.trim()}...</p>`;
  };

  render() {
    if (this.state.dataArray?.length > 0) {
      const initialContent = this.state.dataArray[0];
      return (
        <View
          style={[CommonStyles.conatinerMenu, BrandStyles.borderColor6]}
          onLayout={({nativeEvent}) => {
            this.setState({summaryView: nativeEvent.layout});
          }}>
          <View style={CommonStyles.alignCenter}>
            <StandardText
              style={[
                CommonStyles.customFontBold,
                CommonStyles.popUptextAlign,
                CommonStyles.popGuideMeTitleText,
              ]}>
              {initialContent.Title}
            </StandardText>
          </View>

         
            <WebView
            srcDoc={true}
              scrollEnabled={false}
              scalesPageToFit={true}
              style={CommonStyles.WebViewStyle}
              source={ this._applyCSS(initialContent)}
            />
        
          <ActionButton
            isToucable={true}
            customText={viewMore}
            actionType={ACTION_BUTTON.VIEW}
            labelStyle={labelPosition}
            containerStyle={CommonStyles.alignItemsFlexEndRow}
            style={[
              CommonStyles.customFontMedium,
              CommonStyles.SummaryTileHeaderButtonText,
            ]}
            onPress={() => {
              this.props.actions.GuideMeActions.setIsDemoProfileFirstTime(true);
              this.props.actions.TabBarActions.displayGuideMe(BUDGETS);
            }}
          />
        </View>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state: any) => ({
  loadedMember: state.LoadedMemberReducer,
  guideMe: state.GuideMeReducer,
  member: state.MemberReducer,
  user: state.UserReducer,
});
const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    GuideMeActions: bindActionCreators(GuideMeActions, dispatch),
    TabBarActions: bindActionCreators(TabBarActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GuideMeSummary);
