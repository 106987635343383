/*
 * Author: Joshua Merkas
 * Date: 22/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Manage Participants Screen, this is the landing page when you Manage Participants.
 */

import React, {Component} from 'react';
import {View, Alert} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

import {
  ManageParticipantsSidebar,
  ManageParticipantsMembers,
  ManageParticipantsMyProfile,
} from '../Components/Organisms';
import {
  Container,
  BrandActivityIndicator,
  PaginationNavigator,
} from '../Components/Molecules';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {
  COMMON_BUTTONS as buttonText,
  ERRORS as errorText,
  SEARCH_MEMBERS,
} from '../Constants/Messages';

import {
  MEMBER_STATUS_DATA,
  ONBOARDING_GUIDE_STATUS,
} from '../Constants/Constants2';
import * as MemberActions from '../Actions/MemberActions';
import * as LivingArrangementActions from '../Actions/LivingArrangementActions';
import * as FavouriteThingsActions from '../Actions/FavouriteThingsActions';
import * as HealthWellbeingActions from '../Actions/HealthWellbeingActions';
import * as loadedMemberActions from '../Actions/LoadedMemberActions';
import * as CrewActions from '../Actions/CrewActions';
import * as SupportsActions from '../Actions/SupportsActions';
import * as GoalActions from '../Actions/GoalActions';
import * as NotificationActions from '../Actions/NotificationActions';
import * as GuideMeActions from '../Actions/GuideMeActions';
import * as TabBarActions from '../Actions/TabBarActions';
import * as PayInvoiceActions from '../Actions/PayInvoiceActions';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {
  hasBudgetAccess,
  filterBudgetCategories,
} from '../Helpers/determineAnyBudgetAccess';

import {callAPIs} from '../API/APICaller';
import {getParticipants} from '../API/PersonAPI';
import {getMember} from '../API/MemberAPI';
import {
  getLivingArrangement,
  getFavourites,
  getHealth,
} from '../API/AboutMeAPI';
import {getCrewAccessLevels} from '../API/CrewAPI';
import {getCrew} from '../API/PersonAPI';
import {getCrewCategories, getBudgetCategories} from '../API/ReferenceAPI';
import {
  getSupports,
  getGoalsForPlan,
  getBudget,
  getDraftInvoices,
  getPlans,
} from '../API/PlanDataAPI';
import {getNotifications} from '../API/NotificationAPI';

import * as BudgetActions from '../Actions/BudgetActions';
import * as SideMenuActions from '../Actions/SideMenuActions';

import {StandardSearch} from '../Components/Atoms';

import {debounce} from 'lodash';
import logger from 'helpers/Logger';

import {setServiceAgreementFeature} from '../Helpers/Utils';
// import {NavigationActions} from 'react-navigation';

// Constants
const PEOPLE_INDEX = 0;
const MY_PROFILE_INDEX = 1;

// Constants
const RESULT_INDEX = {
  LIVING_ARRANGEMENT: 0,
  FAVOURITES: 1,
  HEALTH: 2,
  CREW_CATEGORIES: 3,
  CREW: 4,
  CREW_ACCESS: 5,
  SUPPORTS: 6,
  GOALS: 7,
};

const PLAN_RESULT_INDEX = {
  BUDGETS: 0,
  BUDGET_CATEGORIES: 1,
  DRAFT_INVOICES: 2,
  PLANS: 3,
};

class ManageParticipants extends Component {
  state = {
    people: [],
    //tempSearchValue: '',
    searchInput: '',
    searchPeople: [],
    loading: true,
    unmounted: true,
    myProfile: {},
    pageSize: 12,
    pageNumber: 1,
    totalPages: 1,
    isPaginationMetaDataAvailable: false,
    refreshing: false,
    totalCount: 0,
  };

  constructor(props: any) {
    super(props);
    this.navigateMember = this.navigateMember.bind(this);
    this._createSidebarContent = this._createSidebarContent.bind(this);
    this._loadInitialData = this._loadInitialData.bind(this);
    this._navigateToAddMember = this._navigateToAddMember.bind(this);
    this._fetchBudgetsPayment = this._fetchBudgetsPayment.bind(this);
  }

  componentDidMount() {
    this.state.unmounted = false;
    this.setState({loading: true});
    // Attempt to load the initial data
    this._loadInitialData(
      this.state.searchInput,
      this.state.pageNumber,
      this.state.pageSize,
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (
      this.props.user.numberOfMembers !== nextProps.user.numberOfMembers ||
      nextProps.tabBar.selectedTab == types2.LINKED_MEMBER
    ) {
      // Attempt to load the initial data
      this.setState({loading: true});
      this._loadInitialData(
        this.state.searchInput,
        this.state.pageNumber,
        this.state.pageSize,
      );
    }
  }

  render() {
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        sidebarContent={this._createSidebarContent}
        sidebarMode={types2.SIDEBAR_MODE.PLAN_READY_MANAGE_PARTICIPANT}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        activeScreen={types.SCREEN_MANAGE_MEMBERS}
        hideBackButton={true}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.LINKED_MEMBERS}
        onRefresh={this.fetchData}
        refreshing={this.state.refreshing}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    const pageResultCount = this.state.pageNumber * this.state.pageSize;
    const lastIndex =
      pageResultCount > this.state.totalCount
        ? this.state.totalCount
        : pageResultCount;
    const startIndex =
      this.state.pageNumber === 1
        ? this.state.pageNumber
        : (this.state.pageNumber - 1) * this.state.pageSize + 1;
    return (
      <View style={[CommonStyles.flexColumn, CommonStyles.content]}>
        <View style={[CommonStyles.searchContainer]}>
          <StandardSearch
            border={CommonStyles.borderWidth0}
            underlineColorAndroid="transparent"
            value={this.state.searchInput}
            placeholder={SEARCH_MEMBERS}
            onChangeText={(searchInput: any) => {
              this.setState({searchInput}, () =>
                this.debouncedUpdateSearchResultsFunc(),
              );
            }}
          />
        </View>
        <ManageParticipantsMembers
          loading={this.state.loading}
          members={this.state.people}
          navigate={this.navigateMember}
          add={this._navigateToAddMember}
          resultSummary={`${types2.RESULT_COUNT.SHOW}${startIndex}-${lastIndex}${types2.RESULT_COUNT.OF}${this.state.totalCount}${types2.RESULT_COUNT.RESULTS}`}
        />
        {this.state.isPaginationMetaDataAvailable && (
          <PaginationNavigator
            pageNumber={this.state.pageNumber}
            totalPages={this.state.totalPages}
            pageSize={this.state.pageSize}
            goToNextPage={() => {
              this.setState({loading: true});
              this._loadInitialData(
                this.state.searchInput,
                ++this.state.pageNumber,
                this.state.pageSize,
              );
            }}
            goToPreviousPage={() => {
              this.setState({loading: true});
              this._loadInitialData(
                this.state.searchInput,
                --this.state.pageNumber,
                this.state.pageSize,
              );
            }}
          />
        )}
      </View>
    );
  };

  _navigateToAddMember() {
    this.props.navigation.navigate('CrewCreateMember', {
      screenMode: 'ADD_MEMBER',
    });
  }

  _createSidebarContent = () => {
    // #TODO - This is a Temporary Hack to show up Add Button on Linked Members Screen(ManagedParticipants)
    return '';
    //<ManageParticipantsSidebar filter={this.updateSearchResults} nav={this.props.navigation}/>
  };

  componentWillUnmount() {
    this.state.unmounted = true;
  }

  debouncedUpdateSearchResultsFunc = debounce(() => {
    this.setState({loading: true});
    this._loadInitialData(this.state.searchInput, 1, this.state.pageSize);
  }, 3000);

  navigateMember = (person: any) => {
    this.setState({loading: true});
    let callbackFunction = (results: any) => {
      let memberPayload = null;
      if (results[0]) {
        memberPayload = results[0].content;
        this.props.actions.MemberActions.actionGetMember(
          memberPayload,
          this.props.user.id,
          this.props.user.isEmployee,
          this.props.user.isFinancialStaff,
        );
      }

      const planID = memberPayload ? memberPayload.planId : null;
      // Now call the get supports and get goals for plan
      if (memberPayload && this._fetchBudgets()) {
        this._fetchBudgetsPayment(person.id, planID);
      } else {
        this._fetchMyProfileDetails(person.id, planID, false);
      }
    };

    callAPIs(
      [getMember(person.id, this.props.user.demoProfile)],
      callbackFunction,
      null,
      () => {
        this.setState({loading: false});
      },
    );
    //Update the redux store with the ID of the person we are viewing data as
    this.props.actions.loadedMemberActions.actionGetLoadedMemberId(person.id);
  };

  _fetchMyProfileDetails = (personId: any, planId: any, hasBudget: any) => {
    let callbackFunction = (results: any) => {
      let memberPayload = null;
      if (results[RESULT_INDEX.MEMBER]) {
        memberPayload = results[RESULT_INDEX.MEMBER].content;
        this.props.actions.MemberActions.actionGetMember(
          memberPayload,
          this.props.user.id,
          this.props.user.isEmployee,
          this.props.user.isFinancialStaff,
        );
      }

      if (results[RESULT_INDEX.LIVING_ARRANGEMENT]) {
        let livingPayload = results[RESULT_INDEX.LIVING_ARRANGEMENT].content;
        this.props.actions.LivingArrangementActions.actionGetLivingArrangement(
          livingPayload,
        );
      }
      if (results[RESULT_INDEX.FAVOURITES]) {
        let favouritesPayload = results[RESULT_INDEX.FAVOURITES].content;
        this.props.actions.FavouriteThingsActions.actionGetFavouriteThings(
          favouritesPayload,
        );
      }
      if (results[RESULT_INDEX.HEALTH]) {
        let healthPayload = results[RESULT_INDEX.HEALTH].content;
        this.props.actions.HealthWellbeingActions.actionGetHealthWellbeing(
          healthPayload,
        );
      }

      let crewCategoryPayload = results[RESULT_INDEX.CREW_CATEGORIES]
        ? results[RESULT_INDEX.CREW_CATEGORIES].content.categories
        : null;
      let personCrewsPayload = results[RESULT_INDEX.CREW]
        ? results[RESULT_INDEX.CREW].personCrews.content
        : null;
      let organisationCrewsPayload = results[RESULT_INDEX.CREW]
        ? results[RESULT_INDEX.CREW].organisationCrews.content
        : null;
      let crewsAccessPayload = results[RESULT_INDEX.CREW_ACCESS]
        ? results[RESULT_INDEX.CREW_ACCESS].content
        : null;
      // Write the data to redux

      if (
        crewCategoryPayload &&
        personCrewsPayload &&
        organisationCrewsPayload &&
        crewsAccessPayload
      ) {
        this.props.actions.CrewActions.actionGetCrews(
          crewCategoryPayload,
          personCrewsPayload,
          organisationCrewsPayload,
          crewsAccessPayload,
        );
      }

      if (results[RESULT_INDEX.SUPPORTS]) {
        const supportsPayload = results[RESULT_INDEX.SUPPORTS].content;
        this.props.actions.SupportsActions.actionGetSupports(supportsPayload);
      }
      if (results[RESULT_INDEX.GOALS]) {
        const goalsPayload = results[RESULT_INDEX.GOALS].content.currentGoals;
        this.props.actions.GoalActions.actionGetGoals(goalsPayload);
      }
      let memberObj = {
        id: this.props.member.id,
        showBanner: true,
      };
      this.props.actions.MemberActions.actionUpdateMember(memberObj);
      if (!hasBudget) {
        this.navigateMemberScreen();
      }
    };

    let APIArray = [
      getLivingArrangement(personId, this.props.user.demoProfile),
      getFavourites(personId, this.props.user.demoProfile),
      getHealth(personId, this.props.user.demoProfile),
      getCrewCategories(personId, this.props.user.demoProfile),
      getCrew(personId, this.props.user.demoProfile),
      getCrewAccessLevels(personId, this.props.user.demoProfile),
    ];

    if (planId) {
      APIArray.push(getSupports(planId, personId, this.props.user.demoProfile));
      APIArray.push(
        getGoalsForPlan(planId, personId, this.props.user.demoProfile),
      );
    }
    callAPIs(APIArray, callbackFunction, null, () => {
      this.setState({loading: false});
    });
  };
  /**
   * This function will fetch the support and goals once the plan id has been obtained.
   * It will also fetch budgets if required.
   * It will then update the redux with the data downloaded. The state will then update to false.
   * @param {number} planId The plan id that will be fetched for the profile.
   */
  _fetchBudgetsPayment = (memberId: any, planId: any) => {
    const callback = (results: any) => {
      if (callBudgets) {
        // check if getBudget returns NO_PLANS_ERROR_CODE
        if (
          results[PLAN_RESULT_INDEX.BUDGETS].statusCode ==
          types2.NO_PLANS_ERROR_CODE
        ) {
          this.props.actions.BudgetActions.setPmExpiredState(true);
          this.navigateMemberScreen();

          return '';
        }

        const budgetsPayload = results[PLAN_RESULT_INDEX.BUDGETS].content;

        // Check if Service Agreement feature is disabled from the LIBE
        if (budgetsPayload.isServiceAgreementsEnabled != null) {
          if (types.isWeb) {
            setServiceAgreementFeature(
              budgetsPayload.isServiceAgreementsEnabled,
            );
          } else {
            this.props.actions.MemberActions.actionSetServiceAgreementEnabled(
              budgetsPayload.isServiceAgreementsEnabled,
            );
          }
        }

        const budgetsCategories =
          results[PLAN_RESULT_INDEX.BUDGET_CATEGORIES].content;
        this.props.actions.BudgetActions.actionGetBudgets(budgetsPayload);
        this.props.actions.BudgetActions.actionGetBudgetCategories(
          filterBudgetCategories(budgetsCategories),
        );
        const draftInvoicesPayload =
          results[PLAN_RESULT_INDEX.DRAFT_INVOICES].content;
        this.props.actions.PayInvoiceActions.setDraftInvoicesCount(
          draftInvoicesPayload.length,
        );
        if (results[PLAN_RESULT_INDEX.PLANS]) {
          const plans = results[PLAN_RESULT_INDEX.PLANS].content;
          this.props.actions.BudgetActions.actionAddPreviousPlans(plans);
        }
        this.props.actions.NavigationParamActions.setParam({
          planKey: '',
          planStatus: '',
          navigateFrom: '',
          planClaimUntilDate: null,
        });
        this.navigateMemberScreen();
        this._fetchMyProfileDetails(memberId, planId, true);
      }

      // Set the state to no longer be loading
    };

    const errorHandler = (error: any) => {
      // TODO: The error handler will need to be more in depth.
      logger.log(
        'The fetch for supports, goals and optional budgets failed with error: ',
        error,
      );
      this.setState({loading: false});
      Alert.alert(types2.ERROR, error);
    };

    let callBudgets = false;
    //Do checks for budgets first and then push to APIArray if needed
    const APIArray = [
      getBudget(memberId, this.props.user.demoProfile),
      getBudgetCategories(),
      getDraftInvoices(
        memberId,
        this.props.user.demoProfile,
        // searchQuery,
        // pageNumber,
        // pageSize,
        false,
      ),
      getPlans(memberId, this.props.user.demoProfile),
    ];

    callBudgets = true;
    callAPIs(APIArray, callback, errorHandler);
  };

  /**
   * This function will check whether the budgetAPI should be called based on the data returned from the memberAPI
   */
  _fetchBudgets = () => {
    if (hasBudgetAccess(this.props.member)) {
      const memberObj = this.props.member;
      const userObj = this.props.user;

      // fetch budget of the selected person if logged in user is a employee
      if (
        memberObj.pmExitDate != '' &&
        (userObj.isEmployee === true || userObj.isFinancialStaff === true)
      ) {
        return true;
      }

      if (
        memberObj.memberStatus === types2.MEMBER_STATUS_DATA.MANAGED &&
        (memberObj.planStatus === types2.PLAN_STATUS_DATA.PM_ACTIVE ||
          memberObj.planStatus === types2.PLAN_STATUS_DATA.COMPLETED)
      ) {
        return true;
      }
    }
    return false;
  };

  /**
   * This function will download the initial data, such as the list of members who are being managed by the crew. It will also detect if the logged in user is a member, and if so, it will also download the member's profile. If an error occurs, it will notify the user and get them to try to fetch the data again.
   */
  _loadInitialData = (searchQuery: any, pageNumber: any, pageSize: any) => {
    searchQuery = searchQuery.trim();

    // Create an array of promises depending on whether the user is a member or a crew
    let promiseArray = [
      getParticipants(
        this.props.user.id,
        searchQuery,
        pageNumber,
        pageSize,
        this.props.user.demoProfile,
      ),
    ];
    if (this.props.user.isMember && pageNumber === 1) {
      promiseArray.push(
        getMember(this.props.user.id, this.props.user.demoProfile),
      );
    }

    const initialDownloadCallback = (values: any) => {
      let peopleArray = [];
      const people =
        values[PEOPLE_INDEX] &&
        values[PEOPLE_INDEX].content &&
        values[PEOPLE_INDEX].content.participants
          ? values[PEOPLE_INDEX].content.participants
          : [];
      const paginationMetadata =
        values[PEOPLE_INDEX] &&
        values[PEOPLE_INDEX].content &&
        values[PEOPLE_INDEX].content.paginationMetadata
          ? values[PEOPLE_INDEX].content.paginationMetadata
          : undefined;

      let myProfile = null;
      if (values.length > 1) {
        myProfile = values[MY_PROFILE_INDEX].content;
      }

      // Add the user profile to the first item in the list if the user is a member as well
      if (this.props.user.isMember && myProfile) {
        if (!searchQuery) {
          peopleArray.push(myProfile);
        } else {
          // If there is a searchQuery check if the current profile firstName matches the query
          // If so push the profile to peopleArray
          if (
            myProfile.firstName
              .toLowerCase()
              .includes(searchQuery.toLowerCase())
          ) {
            peopleArray.push(myProfile);
          }
        }
      }
      people.forEach((person: any, index: any) => {
        if (person.id === this.props.user.id) {
          people.splice(index, 1);
        }
      });

      // Concat the rest of the downloaded people array
      peopleArray = peopleArray.concat(people);

      // Remove the loading state and set the states
      this.setState({
        loading: false,
        refreshing: false,
        people: peopleArray,
        isPaginationMetaDataAvailable: !!paginationMetadata,
        pageNumber: !!paginationMetadata
          ? paginationMetadata.currentPage
          : this.state.pageNumber,
        pageSize: !!paginationMetadata
          ? paginationMetadata.pageSize
          : this.state.pageSize,
        totalPages: !!paginationMetadata
          ? paginationMetadata.totalPages
          : this.state.totalPages,
        totalCount: !!paginationMetadata
          ? paginationMetadata.totalCount
          : this.state.totalCount,
      });
    };

    if (this.props.user && this.props.user.id) {
      callAPIs(promiseArray, initialDownloadCallback, null, () => {
        this.setState({loading: false, refreshing: false});
      });
      // Loads the Notification Initially at this screen to update the store.

      // TODO: Currently we do not need notifications at this screen there for
      // remove load notification API call to improve the performance
    }
  };

  fetchData = () => {
    this.setState({refreshing: true});

    this._loadInitialData(
      this.state.searchInput,
      this.state.pageNumber,
      this.state.pageSize,
    );
  };

  navigateMemberScreen = () => {
    if (this.props.member.memberStatus === MEMBER_STATUS_DATA.MANAGED) {
      this.props.actions.SideMenuActions.setCurrentScreen(types2.BUDGETS);
      if (
        this.props.user.isOnboardingGuideRequired ===
        ONBOARDING_GUIDE_STATUS.DISPLAY
      ) {
        this.props.actions.GuideMeActions.showWelcomeManagedGuide(true);
        this.props.actions.GuideMeActions.setWelcomeGuideStep(1);
        this.props.actions.TabBarActions.displayGuideMe(
          this.props.tabBar.selectedTab,
        );
      }
      this.props.navigation.navigate(types2.BUDGETS, {
        screenCameFrom: {
          type: types2.ROUTING_STATE.LINKED_MEMBERS_SCREEN,
        },
      });
    } else {
      this.props.actions.SideMenuActions.setCurrentScreen(types2.PROFILE);
      this.props.actions.BudgetActions.setPmExpiredState(false);
      this.props.navigation.navigate(types2.PROFILE, {
        screenCameFrom: {
          type: types2.ROUTING_STATE.LINKED_MEMBERS_SCREEN,
        },
      });
    }
  };
}

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  member: state.MemberReducer,
  tabBar: state.TabBarReducer,
  budgets: state.BudgetReducer,
  notifications: state.NotificationReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    BudgetActions: bindActionCreators(BudgetActions, dispatch),
    GoalActions: bindActionCreators(GoalActions, dispatch),
    MemberActions: bindActionCreators(MemberActions, dispatch),
    LivingArrangementActions: bindActionCreators(
      LivingArrangementActions,
      dispatch,
    ),
    FavouriteThingsActions: bindActionCreators(
      FavouriteThingsActions,
      dispatch,
    ),
    HealthWellbeingActions: bindActionCreators(
      HealthWellbeingActions,
      dispatch,
    ),
    PayInvoiceActions: bindActionCreators(PayInvoiceActions, dispatch),
    loadedMemberActions: bindActionCreators(loadedMemberActions, dispatch),
    CrewActions: bindActionCreators(CrewActions, dispatch),
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
    notificationActions: bindActionCreators(NotificationActions, dispatch),
    SideMenuActions: bindActionCreators(SideMenuActions, dispatch),
    GuideMeActions: bindActionCreators(GuideMeActions, dispatch),
    TabBarActions: bindActionCreators(TabBarActions, dispatch),
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageParticipants);
