/*
 * Author: Andrew Lee
 * Date: 04/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a screen that will give the member / users information about the benefit of selecting Leap in! as their plan managers, and then it will take the members to the relevant screens to proceed with either entering their plan details, or selecting Leap in! as their plan managers.
 */

import React, {Component} from 'react';
import {
  View,
  FlatList,
  StyleSheet,
  Text,
  ScrollView,
  Image,
  Alert,
  Dimensions,
} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {StandardText, StandardButton, BulletText} from '../Components/Atoms';

import {Container} from '../Components/Molecules';

import {BENEFIT_OF_LI_SCREEN as messages} from '../Constants/Messages';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

import {BenefitHeroImage, TickCirclePurple} from '../assets/images';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {BrandColors} from '../Styles/Colours';

let ScreenHeight = Dimensions.get('window').height;

class BenefitOfLeapIn extends Component {
  messageLists = [
    messages.POINT_1,
    messages.POINT_2,
    messages.POINT_3,
    messages.POINT_4,
    messages.POINT_5,
  ];

  constructor(props: any) {
    super(props);

    this._createMainContents.bind(this);
    this._renderListItems.bind(this);
    this._goToPlanMeeting.bind(this);
    this._goToPlanDelivery.bind(this);
  }

  render() {
    return (
      <Container
        contents={this._createMainContents}
        ignorePadding={true}
        nav={this.props.navigation}
        needsSidebar={false}
        isScroll={false}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        screenType={types.SCREEN_TYPE_MAIN}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    return (
      <View style={[CommonStyles.content]}>
        {/* This is overlay content so that the title will overlap the image */}
        <ScrollView style={CommonStyles.BenefitsOfLeapinOverlay}>
          <View
            style={[
              CommonStyles.marginTop20,
              CommonStyles.BenefitOfLeapInFlex3,
            ]}
          >
            <View
              style={[
                CommonStyles.titleContainer,
                BrandStyles.primaryBgColor1,
                CommonStyles.topTitleContainer,
                CommonStyles.alignFlexStart,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.customFontBold,
                  CommonStyles.font40,
                  BrandStyles.TextColor4,
                ]}
              >
                {messages.TITLE_1}
              </StandardText>
            </View>
            <View
              style={[
                CommonStyles.titleContainer,
                BrandStyles.primaryBgColor4,
                CommonStyles.marginRight20,
                CommonStyles.alignFlexEnd,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.customFontBold,
                  CommonStyles.font40,
                  BrandStyles.TextColor1,
                ]}
              >
                {messages.TITLE_2}
              </StandardText>
            </View>
          </View>
          <View
            style={[CommonStyles.flexRow, CommonStyles.BenefitOfLeapInFlex5]}
          >
            {/* Blank view to push the content to the side*/}
            <View style={[CommonStyles.BenefitOfLeapInFlex66]} />
            <View style={[CommonStyles.BenefitOfLeapInFlex34]}>
              <View
                style={[
                  CommonStyles.flexColumn,
                  CommonStyles.content,
                  CommonStyles.benefitsListContainer,
                ]}
              >
                <View style={[CommonStyles.BenefitOfLeapInFlex5]}>
                  {this._renderListItems()}
                </View>
                <View style={[CommonStyles.BenefitOfLeapInFlex1]}>
                  <StandardText
                    style={[
                      CommonStyles.customFontBold,
                      BrandStyles.TextColor5,
                      CommonStyles.font20,
                    ]}
                  >
                    {messages.QUESTION}
                  </StandardText>
                </View>
              </View>
            </View>
          </View>
          <View
            style={[
              CommonStyles.justifyContentFlexEnd,
              CommonStyles.BenefitOfLeapInFlex1,
              CommonStyles.flexRow,
            ]}
          >
            <StandardButton
              onPress={() => this._goToPlanMeeting()}
              style={[
                BrandStyles.TextColor1,
                CommonStyles.customFontBold,
                CommonStyles.fontWeight400,
                CommonStyles.buttonText,
              ]}
              containerStyle={[
                CommonStyles.BenefitOfLeapInButtonContainers,
                BrandStyles.primaryBgColor3,
                CommonStyles.justifyContentCentre,
              ]}
            >
              {messages.PLAN_READY_BTN}
            </StandardButton>
            <StandardButton
              onPress={() => this._goToPlanDelivery()}
              style={[
                BrandStyles.TextColor1,
                CommonStyles.customFontBold,
                CommonStyles.fontWeight400,
                CommonStyles.buttonText,
              ]}
              containerStyle={[
                CommonStyles.BenefitOfLeapInButtonContainers,
                BrandStyles.primaryBgColor3,
                CommonStyles.justifyContentCentre,
              ]}
            >
              {messages.NDIS_PLAN_BTN}
            </StandardButton>
          </View>
        </ScrollView>

        {/* This is the image view */}
        <View style={[CommonStyles.flexRow]}>
          <View style={[CommonStyles.BenefitOfLeapInFlex66]}>
            <Image
              source={BenefitHeroImage}
              style={[CommonStyles.BenefitOfLeapInHeroImage]}
            />
          </View>
          <View style={[CommonStyles.BenefitOfLeapInFlex34]} />
        </View>
      </View>
    );
  };

  _renderListItems = () => {
    // Loop through and create a list of standard text with a check icon
    let pointsJSX: any = [];
    let counterKey = 0;

    this.messageLists.map(messageItem => {
      pointsJSX.push(
        <BulletText key={counterKey} bulletImage={TickCirclePurple}>
          {messageItem}
        </BulletText>,
      );
      counterKey++;
    });

    return pointsJSX;
  };

  _goToPlanMeeting = () => {
    this.props.navigation.navigate('PlanMeeting');
  };

  _goToPlanDelivery = () => {
    // TODO: Need to update the plan delivery when it is ready.
    this.props.navigation.navigate('TermsAndConditionsPM', {
      screenCameFrom: {
        type: types2.ROUTING_STATE.HOME_SCREEN,
      },
    });
  };
}

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BenefitOfLeapIn);
