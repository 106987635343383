/*
 * Author: Andrew Lee
 * Date: 04/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a component that will allow the display of messages at the bottom of the screen, above the tab bar, and it will also be able to navigate given a navigation property, it will be tappable.
 */

import React, {Component} from 'react';
import {View, FlatList, StyleSheet, Text} from 'react-native';
import {StandardTouchableOpacity, StandardText} from '../Atoms';
import FitImage from 'react-native-fit-image';

// Import style sheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class BottomMessageBar extends Component {
  constructor(props: any) {
    super(props);
    this._renderInnerContents.bind(this);
  }

  render() {
    const {nav, accessibilityLabel, containerStyle} = this.props;

    if (nav) {
      return (
        <View style={[containerStyle, styles.messageContainer]}>
          <StandardTouchableOpacity
            accessibilityLabel={accessibilityLabel}
            onPress={() => nav()}
          >
            {this._renderInnerContents(true)}
          </StandardTouchableOpacity>
        </View>
      );
    } else {
      return (
        <View style={[containerStyle, styles.messageContainer]}>
          {this._renderInnerContents()}
        </View>
      );
    }
  }

  _renderInnerContents = () => {
    const {iconSource, containerStyle} = this.props;

    let renderIcon = false;
    if (iconSource) {
      renderIcon = true;
    }

    return (
      <View style={[CommonStyles.content, CommonStyles.flexRow]}>
        <View
          style={[
            CommonStyles.content,
            CommonStyles.justifyContentCentre,
            CommonStyles.alignCenter,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFontMedium,
              BrandStyles.TextColor5,
              CommonStyles.font20,
            ]}
          >
            {this.props.message}
          </StandardText>
        </View>
        {renderIcon && (
          <View style={[CommonStyles.BottomMessageBarIconPadding]}>
            <FitImage
              source={iconSource}
              style={[CommonStyles.BottomMessageBarNextIconImage]}
            />
          </View>
        )}
      </View>
    );
  };
}

const styles = StyleSheet.create({
  messageContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    zIndex: 999,
    left: 0,
    paddingTop: 30,
    paddingBottom: 30,
  },
});

export default BottomMessageBar;
