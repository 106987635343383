/*
 * Author: Declan Hart
 * Date: 11/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Display message when no data is avaliable
 */

import React, {Component} from 'react';
import {StyleSheet, View, Image} from 'react-native';

import {editIcon} from '../../assets/images';

import {StandardText} from '../Atoms';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class NoData extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <View>
        <StandardText
          style={[
            CommonStyles.customFontBold,
            CommonStyles.noDataText,
            CommonStyles.alignCenter,
          ]}
        >
          Time to add your {this.props.activity}!
        </StandardText>
        <StandardText
          style={[
            CommonStyles.noDataText,
            CommonStyles.alignCenter,
            CommonStyles.customFont,
          ]}
        >
          Here you can view the current {this.props.activity} or services that
          you currently recieve.
        </StandardText>
        <StandardText
          style={[
            CommonStyles.noDataText,
            CommonStyles.alignCenter,
            CommonStyles.customFont,
          ]}
        >
          For each one, you can select which ones you want to keep, change or
          stop recieving.
        </StandardText>
        <StandardText
          style={[
            CommonStyles.noDataText,
            CommonStyles.alignCenter,
            CommonStyles.customFont,
          ]}
        >
          {this.props.additionalInformation}
        </StandardText>
      </View>
    );
  }
}

export default NoData;
