/*
 * Author: Brad D'Costa
 * Date: 16/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is the stylesheet for the Leap in! brand
 */

import {StyleSheet} from 'react-native';
import {BrandColors} from './Colours';

export default StyleSheet.create({
  //* ********************************
  //*      L O G I N  F L O W
  //* ********************************

  // Pre Authentication example

  // Screen background
  noWifiBannerBackGroundColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
  },
  brandNowifiTextColor: {
    color: BrandColors.WHITE, // WHITE/CHARCOAL
  },
  preAuthenticationBackgroundColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  // Solid 'block' for buttons and text
  // Background color is white
  // Text color is the same as the screen background color
  preAuthenticationBlockTextSolid: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  preAuthenticationBlockContainerSolid: {
    backgroundColor: BrandColors.WHITE,
  },

  // Ghost 'block' for buttons and text
  // Background color is the same as the screen background color
  // Text is white
  preAuthenticationBlockTextGhost: {
    color: BrandColors.WHITE,
  },

  preAuthenticationBlockContainerGhost: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  // Other screen's will need to follow this pattern as follows (Only background colors filled out):

  loginSignUpBackgroundColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },
  secondaryLoginSignUpBackgroundColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },

  forgotPasswordBackgroundColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },

  signUpCrewInstructionsBackgroundColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },

  LoginSignUpBackgroundColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },

  SignUpOptionsBackgroundColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },

  SignUpOptionsTextColor: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },

  SignUpOptionsNewExistingBackgroundColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  SignUpOptionsNewExistingTextColor: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  // top bar
  ContainerBackgroundColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  ContainerPrimaryBgColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE, // PURPLE/BLUE
  },

  ContainerSecondaryBgColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.YELLOW, // ORANGE_YELLOW/BLUE
  },

  WrapperHeaderEditTextColor: {
    color: BrandColors.WHITE, // CHARCOAL/WHITE
  },

  WrapperHeaderEditTextPurpleColor: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE, // CHARCOAL/PURPLE
  },

  WrapperHeaderEditSelectedIconTextColor: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },

  WrapperHeaderEditSelectedTextColor: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL, // CHARCOAL/WHITE
  },

  //* ********************************
  //*        T A B B A R
  //* ********************************
  // Note: The tab unselected & selected color are in Colors.js

  tabSelectedText: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED, // RED/BLACK
  },

  tabUnselectedText: {
    color: BrandColors.WHITE,
  },

  bottomTabIconActiveColor: {
    color: BrandColors.WHITE,
  },

  bottomTabIconInactiveColor: {
    color: BrandColors.WHITE,
  },

  bottomTabTopBorderColor: {
    borderTopColor: BrandColors.WHITE,
    borderTopWidth: 0,
  },

  //* ********************************
  //*        NOTIFICATION
  //* ********************************
  //

  notificationBadgeBgColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.YELLOW,
  },

  notificationSelectedBadgeBgColor: {
    backgroundColor: BrandColors.NOTIFICATION_BADGE_BLUE,
  },

  notificationBadgeTextColor: {
    color: BrandColors.BLACK,
  },

  notificationSelectedBadgeTextColor: {
    color: BrandColors.WHITE,
  },

  brandBlockBgColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },

  lightGreyBgColour: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
  },

  messageNotiDateColor: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
  },
  brandBlockBgColorWhite: {
    backgroundColor: BrandColors.WHITE,
  },

  brandBlockTxtColor: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },

  brandBlockTxtColorWhite: {
    color: BrandColors.WHITE,
  },

  brandBorderColor: {
    borderColor: BrandColors.WHITE,
  },

  brandBorderColor2: {
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },

  brandBorderColor3: {
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },

  brandBorderColor4: {
    borderColor: BrandColors.WHITE,
  },

  brandBlockBgColor1: {
    backgroundColor: BrandColors.WHITE, // WHITE/CHARCOAL
  },

  brandBlockTxtColor1: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED, // RED/WHITE
  },

  brandBlockBgColor2: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED, // RED/WHITE
  },

  brandBlockTxtColor2: {
    color: BrandColors.WHITE, // WHITE/CHARCOAL
  },

  brandBlockTxtColor3: {
    color: BrandColors.WHITE, // WHITE/CHARCOAL
  },

  brandBlockBgColor3: {
    backgroundColor: BrandColors.WHITE, // WHITE/CHARCOAL
  },

  brandBlockBgColor4: {
    backgroundColor: BrandColors.WHITE,
  },

  brandBlockTxtColor4: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED, // RED/CHARCOAL
  },

  brandBlockBgColor5: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED, // RED/CHARCOAL
  },

  brandBlockTxtColor5: {
    color: BrandColors.WHITE, // WHITE/WHITE
  },

  brandBlockBgColor6: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE, // PURPLE/CHARCOAL
  },

  brandBlockTxtColor6: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE, // PURPLE/CHARCOAL
  },

  brandBlockBgColor7: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE, // PURPLE/WHITE
  },

  brandBlockTxtColor7: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE, // PURPLE/WHITE
  },

  brandBlockTxtColor8: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED, // RED/BLUE
  },

  brandBlockTxtColor9: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE, // PURPLE/BLUE
  },

  brandBlockTxtColor10: {
    color: BrandColors.WHITE, // WHITE/WHITE/CHARCOAL
  },

  brandBlockBgColor8: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.YELLOW, // ORANGE_YELLOW/BLUE
  },

  brandBorderColor1: {
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE, // PURPLE/BLUE
  },

  brandBlockBgColor9: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.YELLOW, // ORANGE_YELLOW/WHITE
  },

  brandBlockBgColor10: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED, // RED/BLUE
  },

  brandBlockBgColor11: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE, // PURPLE/BLUE
  },

  //* ********************************
  //*          M I S C
  //* ********************************

  headerContainsDataText: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  headerContainsDataBorder: {
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  //* ********************************
  //*          C O L O U R S
  //* ********************************

  // White
  TextColor1: {
    color: BrandColors.WHITE,
  },
  // Black
  TextColor2: {
    color: BrandColors.BLACK,
  },
  // Red
  TextColor3: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },
  // Purple
  TextColor4: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },
  // CHARCOAL
  TextColor5: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },
  // CHARCOAL w/ 60% tint
  TextColor6: {
    color: BrandColors.CHARCOAL_TINT60,
  },

  TextColor7: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
  },

  TextColor8: {
    color: BrandColors.PROS_GREEN,
  },

  TextColor9: {
    color: BrandColors.BLUE,
  },

  TextColor10: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
  },

  TextColor11: {
    color: BrandColors.GREY,
  },

  TextColor12: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.YELLOW,
  },

  TextColor13: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.MID_GREY,
  },

  TextColorLoggedIn: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },

  // White
  primaryBgColor1: {
    backgroundColor: BrandColors.WHITE,
  },

  // White
  primaryBgColor1_Opacity60: {
    backgroundColor: BrandColors.WHITE_OPACITY60,
  },

  primaryBgColor2: {
    backgroundColor: BrandColors.BLACK,
  },

  // Red
  primaryBgColor3: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  // Purple
  primaryBgColor4: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },

  // Purple
  primaryBgColor6: {
    backgroundColor: BrandColors.GREEN,
  },

  secondaryBgColor1: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },

  secondaryBgColor2: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.YELLOW,
  },

  secondaryBgColor3: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
  },

  secondaryBgColor4: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },

  secondaryBgColor5: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.MID_GREY,
  },

  bodyBgColor1: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },

  modalSectionBgColor1: {
    backgroundColor: BrandColors.LIGHT_GREY,
  },

  // Red
  primaryBtnColor1: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  secondaryBtnColor1: {
    backgroundColor: BrandColors.WHITE,
  },

  borderColor1: {
    borderColor: BrandColors.WHITE,
  },

  borderColor2: {
    borderColor: BrandColors.BLACK,
  },

  borderColor3: {
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  borderColor4: {
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },

  borderColor5: {
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },

  borderColor6: {
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
  },

  borderColor7: {
    borderColor: BrandColors.CHARCOAL_TINT60,
  },

  borderColor8: {
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
  },

  borderColor9: {
    borderColor: BrandColors.BLUE,
  },

  borderColor10: {
    borderColor: BrandColors.GREEN,
  },

  borderColor11: {
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
  },

  borderColor12: {
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.YELLOW,
  },

  borderColor13: {
    borderColor: BrandColors.SUMMER_GREEN,
  },

  borderColor14: {
    borderColor: BrandColors.CORN,
  },

  errorText: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },

  errorBorder: {
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },

  imageBorderBackground: {
    backgroundColor: BrandColors.WHITE,
  },

  imageBorderColor: {
    borderColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
  },
  // Placeholder Grey
  placeholderTextColor: {
    color: BrandColors.CHARCOAL_TINT60,
  },

  trafficLightGreen: {
    backgroundColor: BrandColors.TRAFFICLIGHTGREEN,
  },

  trafficLightYellow: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.YELLOW,
  },

  darkgreyColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
  },

  trafficLightRed: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },
  notificationTitleColor: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  readOnlyTextfieldGrey: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
  },
  readOnlyCheckboxGrey: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
  },

  // Budget tracker tolerence
  textColorBudgetOnTrack: {
    color: BrandColors.TOLERENCE_GREEN,
  },

  textColorBudgetOffTrack: {
    color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },

  selectedBgColor1: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
  },

  // guide me pop up heading
  headerBgColor1: {
    backgroundColor: BrandColors.SUMMER_GREEN,
  },

  headerBgColor2: {
    backgroundColor: BrandColors.CORN,
  },

  // onboarding screens
  copyrightsBarBgColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },
  opTileCreateProfileBgColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },
  opTileSignupBgColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.YELLOW,
  },
  opTileExplAppBgColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },
  opTileShowMeRoundBgColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },
  opTileLoginBgColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },
  opTileParticipantBgColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },
  opTileCrewBgColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },
  opTileNDISp1BgColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },
  opTileNDISp2BgColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },
  opTileSupportCooBgColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.YELLOW,
  },
  opTileNDISPlaningBgColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },
  opTileMgBudgetsCooBgColor: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
  },
  LoginSignupTitleBg: {
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
  },
  optionTileImageBorderColor: {
    borderColor: BrandColors.WHITE,
  },
  optionTileAvatarBorderColor: {
    borderColor: BrandColors.WHITE,
  },
  screenTitleTextColor: {
    color: BrandColors.BLACK,
  },
});
