/*
 * Author: Brad D'Costa
 * Date: 19/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The initial screen for Plan Management Terms and Conditions
 */

import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Animated,
  Image,
  Dimensions,
  ScrollView,
  Platform,
} from 'react-native';

import FitImage from 'react-native-fit-image';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {BrandColors} from '../Styles/Colours';

import {
  StandardButton,
  StandardText,
  BulletText,
  BackButton,
} from '../Components/Atoms';
import {
  Container,
  SummaryTileHeader,
  DatePicker,
} from '../Components/Molecules';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {TERMS_CONDITION_PM_SCREEN, MESSAGES} from '../Constants/Messages';
import * as UserActions from '../Actions/UserActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
// Import Images
import {ApprovalsRed3x, InformationIconx3} from '../assets/images';
import {firebaseAnalysisLogEvent} from '../Helpers/PlatformSynchronizer';

class TermsAndConditionsPM extends Component {
  state = {
    loading: false,
  };
  constructor(props: any) {
    super(props);
  }

  createMainContents = () => (
    <View
      style={[CommonStyles.content, BrandStyles.primaryBgColour4]}
      contentContainerStyle={[
        CommonStyles.alignItemsCenter,
        CommonStyles.justifyContentCentre,
        CommonStyles.scrollViewVerticalCentre,
      ]}
    >
      <View
        style={[
          CommonStyles.mainContentContainer,
          BrandStyles.primaryBgColour1,
        ]}
      >
        <View style={[CommonStyles.singleMainContentContainer]}>
          <View style={[CommonStyles.SummaryTileContainer]}>
            <View
              style={[
                CommonStyles.justifyContentSpaceBetween,
                BrandStyles.borderColor3,
                CommonStyles.content,
                CommonStyles.SummaryTileHeaderContainer,
                CommonStyles.flexRow,
                CommonStyles.rpMarginBottom10p,
              ]}
            >
              <Image
                style={[
                  CommonStyles.leapInReadPlanManagedTnCIcon,
                  CommonStyles.rpMarginBottom5p,
                  CommonStyles.titleBoxIconMargin,
                ]}
                source={ApprovalsRed3x}
              />
              <View
                style={[
                  CommonStyles.flex1,
                  CommonStyles.flexDirectionRow,
                  CommonStyles.alignItemsFlexEnd,
                  CommonStyles.rpMarginBottom5p,
                ]}
              >
                <StandardText
                  style={[
                    CommonStyles.font20,
                    CommonStyles.customFontSemiBold,
                    BrandStyles.TextColor3,
                  ]}
                >
                  {TERMS_CONDITION_PM_SCREEN.HEADER}
                </StandardText>
              </View>
            </View>
          </View>

          <View style={[CommonStyles.flexDirectionRow]}>
            <View
              style={[
                CommonStyles.alignCenter,
                CommonStyles.rpMarginRight5,
                CommonStyles.rpMarginBottom20p,
              ]}
            >
              <Image
                style={[CommonStyles.pmSignUpImportantNotificationIcon]}
                source={InformationIconx3}
                accessibilityLabel={
                  MESSAGES.ACCESSIBILITY.IMPORTANT_INFORMATION
                }
                accessible={true}
              />
            </View>
            <View
              style={[
                CommonStyles.containerImportantNotification,
                CommonStyles.flex1,
                CommonStyles.rpMarginBottom20p,
              ]}
            >
              <View
                style={[
                  BrandStyles.secondaryBgColor3,
                  CommonStyles.PlanSummaryActionBox,
                  CommonStyles.justifyContentSpaceBetween,
                  CommonStyles.containerRoundCornersBorderless,
                  CommonStyles.marginBottom10,
                ]}
              >
                <StandardText
                  style={[
                    types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont20,
                    CommonStyles.spaceAround,
                    BrandStyles.TextColor5,
                    CommonStyles.customFont,
                    CommonStyles.textLineHeight30,
                  ]}
                >
                  {TERMS_CONDITION_PM_SCREEN.BODY_TEXT1}
                </StandardText>
                <BulletText
                  bulletCharacter={'\u2012'}
                  hasFlex={types.isWeb}
                  style={[
                    types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont20,
                    CommonStyles.spaceAround,
                    BrandStyles.TextColor5,
                    CommonStyles.customFont,
                  ]}
                >
                  {TERMS_CONDITION_PM_SCREEN.BODY_TEXT2}
                </BulletText>
                <BulletText
                  bulletCharacter={'\u2012'}
                  hasFlex={types.isWeb}
                  style={[
                    types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont20,
                    CommonStyles.spaceAround,
                    BrandStyles.TextColor5,
                    CommonStyles.customFont,
                  ]}
                >
                  {TERMS_CONDITION_PM_SCREEN.BODY_TEXT3}
                </BulletText>
              </View>
            </View>
          </View>

          <View
            style={
              this.props.isMobile
                ? [CommonStyles.twoColumns]
                : CommonStyles.ModalActionButtons
            }
          >
            <View
              style={
                this.props.isMobile
                  ? [
                      CommonStyles.justifyContentFlexStart,
                      CommonStyles.justifyContentCentre,
                    ]
                  : CommonStyles.ModalFooterLeft
              }
            >
              <StandardButton
                onPress={() =>
                  this.props.navigation.navigate('UploadPersonalDetailsPM')
                }
                style={[
                  BrandStyles.brandBlockTxtColor6,
                  CommonStyles.buttonFormAction,
                  CommonStyles.customFontBold,
                ]}
                containerStyle={[
                  BrandStyles.primaryBgColor1,
                  BrandStyles.brandBorderColor1,
                  CommonStyles.buttonContainerFormAction,
                ]}
                accessibilityLabel={
                  TERMS_CONDITION_PM_SCREEN.CANCEL_BUTTON_TEXT
                }
              >
                {TERMS_CONDITION_PM_SCREEN.CANCEL_BUTTON_TEXT}
              </StandardButton>
            </View>
            <View
              style={
                this.props.isMobile
                  ? [
                      CommonStyles.justifyContentFlexEnd,
                      CommonStyles.marginLeft30,
                    ]
                  : CommonStyles.ModalFooterRight
              }
            >
              <StandardButton
                onPress={() => {
                  if (!types.isWeb) {
                    firebaseAnalysisLogEvent(
                      types.FIREBASE_ANALYTICS_EVENTS
                        .READ_TERMS_AND_CONDITIONS_CLICKED,
                      {},
                    );
                  }

                  this.props.navigation.navigate('TermsAndConditionsFullPM');
                }}
                style={[
                  BrandStyles.brandBlockTxtColor2,
                  CommonStyles.buttonFormAction,
                  CommonStyles.customFontBold,
                ]}
                containerStyle={[
                  BrandStyles.primaryBgColor4,
                  BrandStyles.brandBorderColor1,
                  CommonStyles.buttonContainerFormAction,
                  this.props.isMobile ? [CommonStyles.maxWmaxWidth60P] : null,
                ]}
                accessibilityLabel={TERMS_CONDITION_PM_SCREEN.BUTTON}
              >
                {TERMS_CONDITION_PM_SCREEN.BUTTON}
              </StandardButton>
            </View>
          </View>
        </View>
      </View>
    </View>
  );

  render() {
    if (types.isWeb) {
      return this.createMainContents();
    } else {
      return (
        <Container
          contents={this.createMainContents}
          loading={this.state.loading}
          sidebarMode={types2.SIDEBAR_MODE.EMPTY}
          needsSidebar
          customSidebar
          screenType={types.SCREEN_TYPE_MAIN}
          activeScreen={types.SCREEN_TERMS_CONDITIONS_PM}
          nav={this.props.navigation}
          headerTitle={types2.NAVIGATION_TEXT.TERMS_AND_CONDITIONS}
          getInitialMenuState={this.props.screenProps?.getInitialMenuState}
          toggleMenu={this.props.screenProps?.toggleMenu}
          demoProfile={this.props.user.demoProfile}
          showConfirmLogout={
            this.props.actions.LogoutConfirmationActions.showConfirmLogout
          }
        />
      );
    }
  }
}

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TermsAndConditionsPM);
