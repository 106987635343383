/*
 * Author: Sujeban Elankeswaran
 * Date: 27/12/2019
 * Copyright © 2019 Leap in!. All rights reserved.
 *
 * Provider actions for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const actionSetProviderList = (providerList: any) => ({
  type: types.SET_PROVIDER_LIST,
  providerList,
});

export const actionSetSelectedProvider = (selectedProvider: any) => ({
  type: types.SET_SELECTED_PROVIDER,
  selectedProvider,
});
