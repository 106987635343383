import React, {Component} from 'react';
import {View, ScrollView, Animated, Dimensions} from 'react-native';
import ReCAPTCHA from 'react-google-recaptcha';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {StandardButton} from '../Components/Atoms';
import {BrandActivityIndicator} from '../Components/Molecules/BrandActivityIndicator';
import {
  OfflineNotice,
  DeepLinkBanner,
  HeaderBanner,
  LoginFields,
  SignUpFields,
  SignUpTermsCheckBox,
  BasicPopupAlert,
} from '../Components/Organisms';
import {
  LoginSignUpTitle,
  Container,
  ReturnToWelcome,
  Copyrights,
  ScreenTitle,
} from '../Components/Molecules';

import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {LOGIN_SIGNUP_SCREEN} from '../Constants/Messages';
import logger from 'helpers/Logger';
import AuthenticationUtil from '../Helpers/AuthenticationUtil';

import * as validations from '../Helpers/validationUtils';
import {GOOGLE_CAPTCHA_CLIENT_KEY} from '../environments';

import * as AuthenticationActions from '../Actions/AuthenticationActions';
import * as loadedMemberActions from '../Actions/LoadedMemberActions';
import * as MemberActions from '../Actions/MemberActions';
import * as UserActions from '../Actions/UserActions';
import * as AdminActions from '../Actions/AdminActions';
import * as OfflineNoticeActions from '../Actions/OfflineNoticeActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import * as GuideMeActions from '../Actions/GuideMeActions';

import {announceForAccessibility} from '../Helpers/PlatformSynchronizer';
import {checkSystemUnderMaintenance} from '../API/UserAPI';
class SignUp extends Component {
  recaptchaRef: any;
  state = {
    firstname: '',
    lastname: '',
    knownas: '',
    email: '',
    password: '',
    termsandconditionsaccepted: false,
    loading: false,
    firstNameError: '',
    knownasError: '',
    lastNameError: '',
    emailError: '',
    passwordError: '',
    termsandconditionsacceptedError: '',
    passwordChallenge: false,
    newPassword: '',
    newPasswordError: '',
    cognitoUserAttributes: {},
    cognitoUser: {},
    displayTermsandCErrorMessage: false,
    isConnected: true,
    isFirstTimeLogin: false,
    orientation: '',
    showDataSharingConsentModal: false,
    dataSharingConsentModalRoutingObject: {},
    errorPopUpVisible: false,
    temporaryPasswordExpiredMessageVisible: false,
    showPlanReadyError: false,
    captchToken: null,
  };

  constructor(props: any) {
    super(props);
    this.saveField = this.saveField.bind(this);
    this.saveTnCField = this.saveTnCField.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.saveEmailCredentials = this.saveEmailCredentials.bind(this);
    this.updateState = this.updateState.bind(this);
    this.signUpFailure = this.signUpFailure.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.navigationToWelcome = this.navigationToWelcome.bind(this);
    this.recaptchaRef = React.createRef();
  }

  render() {
    let firstTitleLine = LOGIN_SIGNUP_SCREEN.SIGNUP_TITLE_1;
    let secondTitleLine = LOGIN_SIGNUP_SCREEN.SIGNUP_TITLE_2;
    if (this.props.user.isCrew) {
      firstTitleLine = LOGIN_SIGNUP_SCREEN.CREW_SIGNUP_TITLE_1;
      secondTitleLine = LOGIN_SIGNUP_SCREEN.CREW_SIGNUP_TITLE_2;
    }

    return (
      <View
        style={[
          CommonStyles.screenWrapperContainer,
          BrandStyles.primaryBgColor1,
        ]}
      >
        {types.isWeb && <HeaderBanner />}
        <OfflineNotice />
        {types.isWeb && <DeepLinkBanner />}
        <View style={[CommonStyles.marginBottom20, CommonStyles.zIndex102]}>
          <LoginSignUpTitle
            title={firstTitleLine}
            backButton={true}
            nav={this.props.navigation}
            screen={'ChooseParticipantOrCrew'}
          />
        </View>
        <ScrollView
          contentContainerStyle={[
            CommonStyles.alignItemsCenter,
            !types.isWeb && CommonStyles.padding10P,
            !types.isWeb && CommonStyles.rpPaddingBottom100,
          ]}
          showsVerticalScrollIndicator={false}
        >
          <View
            style={[CommonStyles.alignItemsCenter, CommonStyles.rpMarginTop25p]}
          >
            {!types.isWeb && (
              <View
                style={[
                  CommonStyles.rpMarginTop25p,
                  CommonStyles.rpMarginBottom10p,
                ]}
              >
                <ScreenTitle title={firstTitleLine} />
              </View>
            )}

            <SignUpFields
              firstname={this.state.firstname}
              firstnameError={this.state.firstNameError}
              lastname={this.state.lastname}
              lastnameError={this.state.lastNameError}
              scrollToPosition={() => {}}
              saveField={this.saveField}
            />
            <LoginFields
              email={this.state.email}
              emailError={this.state.emailError}
              password={this.state.password}
              passwordError={this.state.passwordError}
              scrollToPosition={() => {}}
              saveField={this.saveField}
            />
            <SignUpTermsCheckBox
              termsandconditionsaccepted={this.state.termsandconditionsaccepted}
              saveTnCField={this.saveTnCField}
              displayErrorMessage={this.state.displayTermsandCErrorMessage}
              isConnected={this.state.isConnected}
              navigation={this.props.navigation}
            />
            <ReturnToWelcome navigate={this.navigationToWelcome} />
            <StandardButton
              style={[
                types.isWeb ? CommonStyles.font18 : CommonStyles.rpfont22MS,
                CommonStyles.customFont,
                CommonStyles.textAlignCentre,
                CommonStyles.fontWeightBold,
                BrandStyles.brandBlockTxtColor6,
              ]}
              containerStyle={[
                BrandStyles.primaryBgColor1,
                CommonStyles.elevation3,
                BrandStyles.imageBorderColor,
                CommonStyles.borderRadius5,
                CommonStyles.borderWidth1,
                CommonStyles.rploginsingupBtnWidth,
                CommonStyles.padding10,
              ]}
              onPress={() => {
                if (types.isWeb) {
                  this.recaptchaRef.current.execute();
                } else {
                  this.signUp();
                }
              }}
            >
              Create
            </StandardButton>
          </View>
        </ScrollView>
        {!types.isWeb && (
          <View
            style={[
              CommonStyles.alignCenter,
              BrandStyles.copyrightsBarBgColor,
              CommonStyles.loginButtonWrapper,
            ]}
          >
            <Copyrights />
          </View>
        )}
        <BasicPopupAlert
          visible={this.state.errorPopUpVisible}
          close={this.closeAlert}
          cancelLabel={this.state.alertButtonText}
          headerText={this.state.alertHeader}
          messageText={this.state.alertMessage}
          forceUpdate={this.state.forceUpdate}
          latestVersion={this.state.latestVersion}
          showPlanReadyError={this.state.showPlanReadyError}
          isMobile={this.props.isMobile}
        />
        <BrandActivityIndicator enableWeb={true} loading={this.state.loading} />
        {types.isWeb && (
          <ReCAPTCHA
            ref={this.recaptchaRef}
            size="invisible"
            render="explicit"
            sitekey={GOOGLE_CAPTCHA_CLIENT_KEY}
            badge="bottomright"
            onChange={this._onCaptachaChangeSignUp}
          />
        )}
      </View>
    );
  }

  saveField(fieldName: any, value: any) {
    switch (fieldName) {
      case types.FIRST_NAME:
        this.setState({firstname: value});
        break;
      case types.LAST_NAME:
        this.setState({lastname: value});
        break;
      case types.EMAIL:
        this.setState({email: value});
        break;
      case types.PASSWORD:
        this.setState({password: value});
        break;
      default:
        break;
    }
  }

  saveTnCField() {
    this.setState({
      termsandconditionsaccepted: !this.state.termsandconditionsaccepted,
    });
  }

  async signUp() {
    this.setState({loading: true});
    if (!this.validateFields()) {
      this.setState({loading: false});
      return;
    }
    let typeConfirmed = this.props.user.isMember || this.props.user.isCrew;
    const authentication = new AuthenticationUtil(
      this.state.firstname,
      this.state.lastname,
      this.state.email,
      this.state.password,
      this.state.captchToken,
      typeConfirmed,
      this.props.user.isCrew,
    );
    const isSystemUnderMaintenance = await checkSystemUnderMaintenance();
    if (!isSystemUnderMaintenance) {
      const response = await authentication.signUp();
      await new Promise(resolve => setTimeout(resolve, 1000));
      if(response.isSuccess) {
        this.props.actions.userActions.setFirstTimeSignUp(true, false);
        this.saveEmailCredentials(authentication);
      } else {
        this.signUpFailure(response.errorMessage)
      }
    } else {
      if (types.isWeb) {
        this.props.history.push(types2.routePaths.UnderMaintenance.path);
      }

      this.signUpFailure({});
    }
  }

  updateState(stateObj: any) {
    this.setState(stateObj);
  }

  saveEmailCredentials = (auth: any) => {
    auth.saveEmailCredentials(
      this.props.actions,
      this.updateState,
      (actionToDispatch: any) => {
        if (!types.isWeb) {
          this.props.navigation.dispatch(actionToDispatch);
        } else {
          this.props.navigation.navigate(actionToDispatch);
        }
      },
      this.signUpFailure,
    );
  };

  signUpFailure(error: any) {
    this.setState({
      loading: false,
      alertHeader: error.title,
      alertMessage: error.body,
      alertButtonText: error.buttonText,
      forceUpdate: error.forceUpdate,
      errorPopUpVisible: true,
    });
  }

  closeAlert = () => {
    this.setState({
      errorPopUpVisible: false,
    });
  };

  validateFields() {
    let validationErrors = {
      firstName: validations.validateFirstName(this.state.firstname),
      lastName: validations.validateLastName(this.state.lastname),
      email: validations.validateEmail(this.state.email),
      password: validations.validatePassword(this.state.password),
      termsandconditionsaccepted: validations.validateCheckBox(
        this.state.termsandconditionsaccepted,
      ),
    };
    this.setState({
      firstNameError: validationErrors.firstName,
      lastNameError: validationErrors.lastName,
      emailError: validationErrors.email,
      passwordError: validationErrors.password,
      termsandconditionsacceptedError:
        validationErrors.termsandconditionsaccepted,
    });
    if (validationErrors.termsandconditionsaccepted !== '') {
      this.setState({
        displayTermsandCErrorMessage: true,
      });
    }
    var readThisText =
      validationErrors.firstName +
      '. ' +
      validationErrors.lastName +
      '. ' +
      validationErrors.email +
      '. ' +
      validationErrors.password +
      '. ' +
      validationErrors.validateCheckBox;

    for (let [key, value] of Object.entries(validationErrors)) {
      if (value !== '') {
        announceForAccessibility(readThisText);
        return false;
      }
    }
    return true;
  }

  navigationToWelcome() {
    this.props.navigation.navigate('Welcome');
    this.setState({loading: false});
  }

  _onCaptachaChangeSignUp = (responseToken: any) => {
    this.setState({
      captchToken: responseToken,
    },
    () => {
        this.recaptchaRef.current.reset();
        this.signUp();
      },
    );
  };
}

const mapStateToProps = (state: any) => ({
  auth: state.AuthenticationReducer,
  loadedMemberId: state.LoadedMemberReducer,
  user: state.UserReducer,
  admin: state.AdminReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    authActions: bindActionCreators(AuthenticationActions, dispatch),
    adminActions: bindActionCreators(AdminActions, dispatch),
    loadedMemberActions: bindActionCreators(loadedMemberActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
    OfflineNoticeActions: bindActionCreators(OfflineNoticeActions, dispatch),
    memberActions: bindActionCreators(MemberActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
    guideMeActions: bindActionCreators(GuideMeActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
