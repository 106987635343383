/*
 * Author: Shelan Cooray
 * Date: 08/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the Sub Title text for Forms.
 */

import React, {Component} from 'react';
import {View} from 'react-native';
import {StandardText} from '../Atoms';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class FormSubtitle extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <View style={[CommonStyles.formSubTitleContainer]}>
        {this._renderTextElements(this.props.text)}
      </View>
    );
  }

  _renderTextStyles = () => {
    let styles = [
      this.props.style,
      CommonStyles.formSubtitleText,
      CommonStyles.customFontBold,
    ];

    if (this.props.fontStyle) {
      styles.push(this.props.fontStyle);
    } else {
      styles.push(CommonStyles.customFontMedium);
    }

    if (this.props.textColor) {
      styles.push(this.props.textColor);
    } else {
      styles.push(BrandStyles.TextColor6);
    }

    return styles;
  };

  _renderTextElements = (textArray: any) => {
    return textArray.map((text: any, key: any) => (
      <StandardText key={key} style={this._renderTextStyles()}>
        {text}
      </StandardText>
    ));
  };
}

export default FormSubtitle;
