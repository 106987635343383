/*
 * Author: Declan Hart
 * Date: 11/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Support Category component, displays the categories for supports.
 */

import React, {Component} from 'react';
import {
  Text,
  View,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  Image,
} from 'react-native';

import {editIcon} from '../../assets/images/vector-icons';
import {supportsIconRed} from '../../assets/images';

import {SummaryTileHeader, SupportTile} from '../Molecules';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class SupportCategory extends Component {
  state = {
    loading: false,
    length: 0,
  };

  constructor(props: any) {
    super(props);
    this._renderSupports = this._renderSupports.bind(this);
  }

  render() {
    if (this.props.supports.length > 0) {
      return (
        <View style={CommonStyles.supportsHeaderWrapper}>
          <SummaryTileHeader
            key={this.props.headerDisplayString}
            displayString={this.props.Category.description}
            accessibilityLabel={this.props.Category.description}
            imageSource={
              this.props.Category.titleIcon
                ? {uri: this.props.Category.titleIcon.url}
                : supportsIconRed
            }
            imageIcon={true}
            containsData={true}
            iconStyle={[CommonStyles.supportCategoryTitleBoxIcon]}
          />
          {this._renderSupports()}
        </View>
      );
    } else {
      return null;
    }
  }

  _renderSupports() {
    return this.props.supports.map((support: any) => {
      return (
        <SupportTile
          showEditCaption={this.props.showEditCaption}
          key={support.currentSupportId}
          navigation={this.props.navigation}
          navigateToEdit={this.props.navigateToEdit}
          support={support}
          readOnly={this.props.readOnly}
        />
      );
    });
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
});

export default SupportCategory;
