/*
 * Author: Mayuran Kunarajah
 * Date: 02/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Radio Button Component.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheet,
  View,
  Text,
  Image,
  TouchableWithoutFeedback,
} from 'react-native';
import CommonStyles from '../../Styles/CommonStyles';

import {
  MediumRedTickCircleRed3x,
  MediumRedEmptyCircleRed3x,
} from '../../assets/images/vector-icons';
import CustomIcon from '../../assets/images/CustomIcon';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {MESSAGES} from '../../Constants/Messages';

export default class RadioButton extends Component {
  constructor(props: any, context: any) {
    super(props, context);
    this.onSelected = this.onSelected.bind(this);
    this.checkReadOnlyAccess = this.checkReadOnlyAccess.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    this.setState({
      selectedIndex: nextProps.selectedIndex,
    });
  }

  getRadioStyle() {
    return {
      height: this.context.size,
      width: this.context.size,
    };
  }

  checkReadOnlyAccess() {
    if (this.props.disabled) {
      return true;
    } else if (this.props.readOnly == types.ACCESS_READ_ONLY) {
      return true;
    }
    return false;
  }

  renderRadioButton() {
    if (this.props.customImage) {
      return (
        <Image
          style={[CommonStyles.changePasswordButtonImage]}
          source={this.props.customImage}
          accessible={true}
        />
      );
    }
    if (this.props.isSelected) {
      return (
        <CustomIcon
          style={this.props.buttonStyle}
          name={
            this.props.buttonTick
              ? this.props.buttonTick
              : MediumRedTickCircleRed3x
          }
        />
      );
    } else {
      return (
        <CustomIcon
          style={this.props.buttonStyle}
          name={
            this.props.buttonEmpty
              ? this.props.buttonEmpty
              : MediumRedEmptyCircleRed3x
          }
        />
      );
    }
  }

  render() {
    var {children} = this.props;
    return (
      <View
        style={{
          opacity: this.props.disabled ? types2.OPACITY04 : types2.OPACITY1,
        }}
      >
        <TouchableWithoutFeedback
          disabled={this.checkReadOnlyAccess()}
          onPress={this.onSelected}
        >
          <View style={[CommonStyles.flexDirectionRow, this.props.style]}>
            <View>{this.renderRadioButton()}</View>
            {children}
            <Text
              accessibilityLabel={
                this.props.isSelected
                  ? MESSAGES.ACCESSIBILITY.RADIO_BUTTON.SELECTED
                  : MESSAGES.ACCESSIBILITY.RADIO_BUTTON.NOT_SELECTED
              }
            />
          </View>
        </TouchableWithoutFeedback>
      </View>
    );
  }

  onSelected = () => {
    if (this.props.onChange) {
      if (this.props.data) {
        this.props.onChange(this.props.data);
      } else {
        this.props.onChange({
          id: this.props.id,
          description: this.props.value,
        });
      }
    } else {
      this.context.onSelect(this.props.index, this.props.value);
    }
  };
}

RadioButton.contextTypes = {
  onSelect: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
};
