/*
 * Author: Nirodha Perera
 * Date: 18/09/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Very Basic Popup Modal Component with Backdrop Overlay to Use Across Leap in App
 */

import React, {Component} from 'react';
import {View, Image} from 'react-native';

import {StandardButton, StandardText} from '../Atoms';
import {isTablet} from '../../Helpers/PlatformSynchronizer';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {
  LILogo,
  BudgetOnBoarding,
  BudgetsPlaceholderLandscape,
  BudgetsPlaceholderPortrait,
  ZeroBudgetsPlaceholderLandscape,
  ZeroBudgetsPlaceholderPortrait,
  AvatarArmsOutstretched,
  AvatarFamily,
} from '../../assets/images';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {getStyleByOrientation} from '../../Helpers/GetStyleByOrientation'



import {BUDGETS_AND_PAYMENTS_SCREEN as messages} from '../../Constants/Messages';

class BudgetLocked extends Component {
  state = {
    orientation: types2.ORIENTATION.LANDSCAPE,
  };

  renderCloseButton() {
    if (this.props.close && !this.props.hideCloseButton) {
      let cancelText = 'Cancel';

      if (this.props.cancelLabel) {
        cancelText = this.props.cancelLabel;
      }

      let cancelContainerStyle: any = [];
      let style = [];

      if (this.props.cancelContainerStyle) {
        cancelContainerStyle = cancelContainerStyle.concat(
          this.props.cancelContainerStyle,
        );
      } else {
        cancelContainerStyle = [
          CommonStyles.buttonContainerFormAction,
          BrandStyles.primaryBgColor1,
          BrandStyles.borderColor4,
        ];
      }

      if (this.props.cancelStyle !== types2.UNDEFINED) {
        style = this.props.cancelStyle;
      } else {
        style = [
          BrandStyles.brandBlockTxtColor6,
          CommonStyles.buttonFormAction,
          CommonStyles.customFontBold,
        ];
      }

      return (
        <StandardButton
          onPress={this.props.close}
          style={style}
          containerStyle={cancelContainerStyle}
          accessibilityLabel={cancelText}
        >
          {cancelText}
        </StandardButton>
      );
    } else {
      return null;
    }
  }

  renderActionButton() {
    if (this.props.save) {
      let saveText = 'Save';

      if (this.props.saveLabel) {
        saveText = this.props.saveLabel;
      }

      let saveContainerStyle: any = [];
      let style = [];

      if (this.props.saveContainerStyle) {
        saveContainerStyle = saveContainerStyle.concat(
          this.props.saveContainerStyle,
        );
      } else {
        saveContainerStyle = [
          CommonStyles.buttonContainerFormAction,
          BrandStyles.primaryBgColor1,
          BrandStyles.borderColor4,
        ];
      }

      if (this.props.saveStyle !== types2.UNDEFINED) {
        style = this.props.saveStyle;
      } else {
        style = [
          BrandStyles.brandBlockTxtColor6,
          CommonStyles.buttonFormAction,
          CommonStyles.customFontBold,
        ];
      }

      return (
        <StandardButton
          onPress={this.props.save}
          style={style}
          containerStyle={saveContainerStyle}
          accessibilityLabel={saveText}
        >
          <Image
            style={[CommonStyles.budgetLockedImage, CommonStyles.marginRight10]}
            source={LILogo}
          />
          {saveText}
        </StandardButton>
      );
    } else {
      return null;
    }
  }

  getAvatarImageStyles = (isTablet: any) => {
    if (this.props.save) {
      if (isTablet() || types.isWeb) {
        if (this.props.isMobile) {
          return CommonStyles.imageAvatarPlanReadyMobile;
        }
        return CommonStyles.imageAvatarPlanReady;
      }
      return CommonStyles.imageAvatarPlanReadyMobile;
    } else {
      if (isTablet() || types.isWeb) {
        if (this.props.isMobile) {
          return CommonStyles.imageAvatarOnBoardingMobile;
        }
        return CommonStyles.imageAvatarOnBoarding;
      }
      return CommonStyles.imageAvatarOnBoardingMobile;
    }
  };

  _renderContent = () => {
    return (
      <View style={[CommonStyles.flex1, CommonStyles.alignItemsCenter]}>
        <View style={[CommonStyles.columnContainer]}>
          <View
            style={
              isTablet() ? CommonStyles.padding20 : CommonStyles.paddingTop10
            }
          >
            {this.renderTextContent(isTablet)}
          </View>
          <View style={[CommonStyles.alignCenter]}>
            {this.renderCloseButton()}
          </View>
        </View>
        <Image
          style={
            types.isWeb
              ? this.getAvatarImageStyles(isTablet)
              : getStyleByOrientation(
                  this.getAvatarImageStyles(isTablet),
                  this.state.orientation,
                )
          }
          source={BudgetOnBoarding}
          resizeMode="cover"
        />
      </View>
    );
  };

  _renderPlanReadyContent = (isTablet: any) => {
    if (isTablet()) {
      return (
        <View style={[CommonStyles.containerBudgetLockedBanner]}>
          <View style={[CommonStyles.flexRow]}>
            <View
              style={[
                CommonStyles.flexDirectionColumn,
                CommonStyles.justifyContentFlexEnd,
              ]}
            >
              <Image
                style={getStyleByOrientation(
                  this.getAvatarImageStyles(isTablet),
                  this.state.orientation,
                )}
                source={AvatarFamily}
                resizeMode="cover"
              />
            </View>
            <View
              style={[
                CommonStyles.flex1,
                CommonStyles.alignItemsCenter,
                CommonStyles.marginLeft10,
              ]}
            >
              <View style={[CommonStyles.flex1, CommonStyles.columnContainer]}>
                <View
                  style={
                    isTablet()
                      ? CommonStyles.paddingTop20
                      : CommonStyles.paddingTop10
                  }
                >
                  <View>
                    <StandardText
                      style={[
                        isTablet() ? CommonStyles.font20 : CommonStyles.font18,
                        this.props.textColor,
                        CommonStyles.customFontBold,
                        CommonStyles.textAlignCentre,
                      ]}
                    >
                      {messages.UNLOCK_MESSAGE_1}
                    </StandardText>

                    <StandardText
                      style={[
                        isTablet() ? CommonStyles.font20 : CommonStyles.font18,
                        this.props.textColor,
                        CommonStyles.customFontBold,
                        CommonStyles.textAlignCentre,
                        CommonStyles.paddingTop10,
                      ]}
                    >
                      {messages.UNLOCK_MESSAGE_2}
                    </StandardText>
                  </View>
                </View>
                <View style={[CommonStyles.alignCenter]}>
                  <View
                    style={[
                      CommonStyles.marginTop20,
                      CommonStyles.marginBottom15,
                    ]}
                  >
                    {this.renderActionButton()}
                  </View>
                  <View style={[CommonStyles.marginBottom20]}>
                    {this.renderCloseButton()}
                  </View>
                </View>
                <StandardText
                  style={[
                    isTablet() ? CommonStyles.font18 : CommonStyles.rpfont15,
                    this.props.textColor,
                    CommonStyles.customFontBold,
                    CommonStyles.textAlignCentre,
                    CommonStyles.marginBottom20,
                  ]}
                >
                  {messages.UNLOCK_MESSAGE_3}
                </StandardText>
              </View>
            </View>
          </View>
        </View>
      );
    } else if (types.isWeb) {
      return (
        <View style={[CommonStyles.containerBudgetLockedBanner]}>
          <View
            style={[
              CommonStyles.flex1,
              CommonStyles.justifyFlexRowSpace,
              CommonStyles.marginLeft5,
              CommonStyles.marginRight25,
            ]}
          >
            <View
              style={[
                CommonStyles.flexDirectionColumn,
                CommonStyles.justifyContentFlexEnd,
              ]}
            >
              <Image
                style={getStyleByOrientation(
                  this.getAvatarImageStyles(isTablet),
                  this.state.orientation,
                )}
                source={AvatarFamily}
              />
            </View>
            <View styles={[CommonStyles.flexDirectionColumn]}>
              <View
                style={
                  isTablet()
                    ? CommonStyles.paddingTop20
                    : CommonStyles.paddingTop10
                }
              >
                <View>
                  <StandardText
                    style={[
                      isTablet() ? CommonStyles.font20 : CommonStyles.font18,
                      this.props.textColor,
                      CommonStyles.customFontBold,
                      CommonStyles.textAlignCentre,
                    ]}
                  >
                    {messages.UNLOCK_MESSAGE_1}
                  </StandardText>

                  <StandardText
                    style={[
                      isTablet() ? CommonStyles.font20 : CommonStyles.font18,
                      this.props.textColor,
                      CommonStyles.customFontBold,
                      CommonStyles.textAlignCentre,
                      CommonStyles.paddingTop10,
                    ]}
                  >
                    {messages.UNLOCK_MESSAGE_2}
                  </StandardText>
                </View>
              </View>
              <View style={[CommonStyles.flexRow, CommonStyles.justifyCenter]}>
                <View
                  style={[
                    CommonStyles.marginTop20,
                    CommonStyles.marginBottom15,
                  ]}
                >
                  {this.renderActionButton()}
                </View>
              </View>
              <View
                style={[
                  CommonStyles.flexRow,
                  CommonStyles.flexWrap,
                  CommonStyles.flex1,
                  CommonStyles.justifyCenter,
                ]}
              >
                <View>
                  <View
                    style={[
                      CommonStyles.marginBottom20,
                      CommonStyles.alignCenter,
                    ]}
                  >
                    {this.renderCloseButton()}
                  </View>
                  <StandardText
                    style={[
                      isTablet() ? CommonStyles.font18 : CommonStyles.rpfont15,
                      this.props.textColor,
                      CommonStyles.customFont,
                      CommonStyles.textAlignCentre,
                      CommonStyles.paddingBottom20,
                    ]}
                  >
                    {messages.UNLOCK_MESSAGE_3}
                  </StandardText>
                </View>
              </View>
            </View>
          </View>
        </View>
      );
    } else {
      return (
        <View style={[CommonStyles.containerBudgetLockedBanner]}>
          <View
            style={
              isTablet() ? CommonStyles.paddingTop20 : CommonStyles.paddingTop10
            }
          >
            <View>
              <StandardText
                style={[
                  isTablet() ? CommonStyles.font20 : CommonStyles.font18,
                  this.props.textColor,
                  CommonStyles.customFontBold,
                  CommonStyles.textAlignCentre,
                ]}
              >
                {messages.UNLOCK_MESSAGE_1}
              </StandardText>

              <StandardText
                style={[
                  isTablet() ? CommonStyles.font20 : CommonStyles.font18,
                  this.props.textColor,
                  CommonStyles.customFontBold,
                  CommonStyles.textAlignCentre,
                  CommonStyles.paddingTop10,
                ]}
              >
                {messages.UNLOCK_MESSAGE_2}
              </StandardText>
            </View>
          </View>
          <View
            style={[CommonStyles.flexRow, CommonStyles.justifyContentFlexEnd]}
          >
            <View
              style={[CommonStyles.marginTop20, CommonStyles.marginBottom15]}
            >
              {this.renderActionButton()}
            </View>
          </View>
          <View
            style={[
              CommonStyles.flexRow,
              CommonStyles.marginRight25,
              CommonStyles.marginLeft25,
            ]}
          >
            <View
              style={[
                CommonStyles.flexDirectionColumn,
                CommonStyles.justifyContentFlexEnd,
              ]}
            >
              <Image
                style={getStyleByOrientation(
                  this.getAvatarImageStyles(isTablet),
                  this.state.orientation,
                )}
                source={AvatarFamily}
              />
            </View>
            <View
              style={[
                CommonStyles.flexRow,
                CommonStyles.justifyContentFlexEnd,
                CommonStyles.flexWrap,
                CommonStyles.flex1,
              ]}
            >
              <View style={[CommonStyles.alignCenter]}>
                <View style={[CommonStyles.marginBottom20]}>
                  {this.renderCloseButton()}
                </View>
                <StandardText
                  style={[
                    isTablet() ? CommonStyles.font18 : CommonStyles.rpfont15,
                    this.props.textColor,
                    CommonStyles.customFont,
                    CommonStyles.textAlignCentre,
                    CommonStyles.paddingBottom20,
                  ]}
                >
                  {messages.UNLOCK_MESSAGE_3}
                </StandardText>
              </View>
            </View>
          </View>
        </View>
      );
    }
  };

  renderTextContent = (isTablet: any) => {
    if (this.props.isMemberOnBoarding) {
      return (
        <StandardText
          style={[
            isTablet() || types.isWeb
              ? CommonStyles.font18
              : CommonStyles.rpfont15,
            this.props.textColor,
            CommonStyles.customFontBold,
            CommonStyles.textAlignCentre,
          ]}
        >
          {messages.ONBOARD_MESSAGE}
        </StandardText>
      );
    } else if (this.props.isBudgetExpired) {
      return (
        <StandardText
          style={[
            isTablet() || types.isWeb
              ? CommonStyles.font18
              : CommonStyles.rpfont15,
            this.props.textColor,
            CommonStyles.customFontBold,
            CommonStyles.textAlignCentre,
          ]}
        >
          {messages.PLAN_EXPIRED}
        </StandardText>
      );
    }
  };

  renderCustomButtonContainerStyle = () => {
    const styles = [CommonStyles.ModalActionButtons];
    if (this.props.buttonContainerStyle) {
      styles.push(this.props.buttonContainerStyle);
    }
    return styles;
  };

  _containerStyle = (activated: any, isTablet: any) => {
    const style = [this.props.style, CommonStyles.containerPopMessage];
    if (activated) {
      style.push(BrandStyles.primaryBgColor4);
    } else {
      style.push(BrandStyles.secondaryBgColor2);
    }

    if (types.isWeb) {
      style.push(CommonStyles.containerPopMessageWeb);
      if (this.props.isMobile) {
        style.push(CommonStyles.containerPopMessageWebMobile);
      }
    }
    if (isTablet()) {
      style.push(CommonStyles.containerPopMessageTablet);
    } else {
      style.push(CommonStyles.containerPopMessage);
    }
    return style;
  };

  _renderImagePlaceHolder = () => {
    if (types.isWeb || isTablet()) {
      if (
        this.props.isMobile ||
        this.state.orientation !== types2.ORIENTATION.LANDSCAPE
      ) {
        return this.props.isBudgetExpired
          ? ZeroBudgetsPlaceholderPortrait
          : BudgetsPlaceholderPortrait;
      }
      return this.props.isBudgetExpired
        ? ZeroBudgetsPlaceholderLandscape
        : BudgetsPlaceholderLandscape;
    }

    return this.props.isBudgetExpired
      ? ZeroBudgetsPlaceholderPortrait
      : BudgetsPlaceholderPortrait;
  };

  _renderImagePlaceHolderStyle = () => {
    if (types.isWeb) {
      if (this.props.isMobile) {
        return CommonStyles.imageBudgetPlaceholderWebMobile;
      }
      return CommonStyles.imageBudgetPlaceholderWeb;
    } else {
      return CommonStyles.imageBudgetPlaceholder;
    }
  };

  render() {
    const imagePlaceHolder = this._renderImagePlaceHolder();

    if (this.props.isMemberOnBoarding || this.props.isBudgetExpired) {
      return (
        <View
          style={[
            CommonStyles.flex1,
            types.isWeb ? CommonStyles.marginMinus12 : {},
          ]}
        >
          <Image
            source={imagePlaceHolder}
            style={this._renderImagePlaceHolderStyle()}
          />
          <View style={this._containerStyle(this.props.save, isTablet)}>
            <View style={[CommonStyles.paddingBottom10]}>
              <Image
                style={[CommonStyles.budgetImage, CommonStyles.marginBottom10]}
                source={LILogo}
              />
            </View>
            {this._renderContent()}
          </View>
        </View>
      );
    } else {
      return (
        <View style={CommonStyles.flex1}>
          {this._renderPlanReadyContent(isTablet)}
          <Image
            source={imagePlaceHolder}
            style={this._renderImagePlaceHolderStyle()}
          />
        </View>
      );
    }
  }
}

export default BudgetLocked;
