
import {configLoggerType, logger} from 'react-native-logs';


const config: configLoggerType = {
  levels: {
    debug: 0,
    info: 1,
    warn: 2,
    error: 3,
  },
  enabled: true,
};

class Logger {
  private readonly _logger = logger.createLogger<
    'debug' | 'info' | 'warn' | 'error'
  >(config);

  public log(message: string | object, data?: any) {
    
    this._logger.debug(message, data);
    
  }
  public debug(message: string | object, data?: any) {
    
    this._logger.debug(message, data);
    
  }

  public info(message: string | object, data?: any) {

    this._logger.info(message, data);
    
  }
  public warn(message: string | object, data?: any) {
    
    this._logger.warn(message, data);
    
  }
  public error(message: string | object, data?: any) {
    
    this._logger.error(message, data);
    
  }
}

export default new Logger();

