/**
 * Author: Shelan Cooray
 * Date: 29/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Very simple spending bar which is less complicated than SpendingBar. Just display the spent & remaing with given colors
 * Example:
 * <SimpleSpendingBar budget={spend} budgetColors={GREEN_BUDGET}></SpendingBar>
 * Where:
 * let spend = {
 *               'total': '146780',
 *               'spent': '87570',
 *               'unspent': '26760',
 *             }
 */

import React, {Component} from 'react';
import {View} from 'react-native';
import PropTypes from 'prop-types';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class SimpleSpendingBar extends Component {
  constructor(props: any) {
    super(props);
    this._renderBar.bind(this);
    this._renderSection.bind(this);
    this._getColorForKey.bind(this);
  }

  _renderBar = () => {
    const budget = this.props.budget;
    let showTotal = this.props.showTotal;
    // If the user did not pass assume they want to display it
    if (showTotal === undefined) {
      showTotal = false;
    }

    let jsx = [];
    if (budget !== undefined) {
      // Loop over the key value pairs for the budget
      for (let key in budget) {
        if (budget.hasOwnProperty(key)) {
          // Render any section but the total
          if (key !== 'total' && !showTotal) {
            let backgroundColor = this._getColorForKey(key, 'backgroundColor');
            const section = this._renderSection(
              key,
              budget[key],
              budget.total,
              backgroundColor,
            );

            if (section) {
              jsx.push(section);
            }
          }
        }
      }

      const sortOrder = this.props.sortOrder;
      // If sort order was passed then sot the jsx
      if (sortOrder) {
        // We sort based of the key of the object. Pass in an array of key objects
        // And it will be sorted in that order
        jsx = jsx.sort(function (a, b) {
          if (sortOrder.indexOf(a.key) > sortOrder.indexOf(b.key)) {
            return 1;
          } else if (sortOrder.indexOf(a.key) < sortOrder.indexOf(b.key)) {
            return -1;
          } else {
            return 0;
          }
        });
      }
      return jsx;
    } else {
      return null;
    }
  };

  /**
   * Return jsx for a section of the bar
   * @param {String} title The title to display in the section
   * @param {String} value The value of the section to display, also used to size the section
   * @param {String} total The total value of the whole bar (All section values added together)
   * @param {String} backgroundColor The background color for the section
   */
  _renderSection = (
    title: any,
    value: any,
    total: any,
    backgroundColor: any,
  ) => {
    // The minimum flex width for a section to ensure the text will fix
    let flexValue = value / total;

    // If the flex value is not 0, then display a section with colour, otherwise return an empty section.
    if (flexValue !== 0) {
      return (
        <View
          key={title}
          style={[
            CommonStyles.containerSimpleSpendingBar_Section,
            {flex: flexValue, backgroundColor: backgroundColor},
          ]}
        />
      );
    }
    return null;
  };

  /**
   * Get a color for a given key and the colour type. If not found will return white.
   * @param {String} key The key for the budget color you are looking for
   * @param {String} colorType The color type to return. E.g backgroundColor, textColor
   */
  _getColorForKey(key: any, colorType: any) {
    let budgetColors = this.props.budgetColors;
    // In the array of colour objects, find the one that matches the given key
    let keyColor = budgetColors.find((c: any) => c.key === key);
    // So long as we found the colour return ut
    if (keyColor != undefined) {
      // Return the colour for our given colour type
      return keyColor[colorType];
    }

    // If the colour is not found return white
    return 'white';
  }

  render() {
    return (
      <View style={[CommonStyles.flex1]}>
        <View
          style={[
            CommonStyles.containerSimpleSpendingBar,
            BrandStyles.borderColor4,
          ]}
        >
          {this._renderBar()}
        </View>
      </View>
    );
  }
}

// Warn the user if props are not passed
SimpleSpendingBar.propTypes = {
  budget: PropTypes.object.isRequired,
  budgetColors: PropTypes.array.isRequired,
  showTotal: PropTypes.bool,
  sortOrder: PropTypes.array,
};

export default SimpleSpendingBar;
