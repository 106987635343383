/*
 * Author: Shelan Cooray
 * Date: 28/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Service Agreements Summary Screen
 */

import React, {Component} from 'react';
import {View, FlatList, Image} from 'react-native';
import {ServiceAgreement3x} from '../assets/images/vector-icons';
import {GreyClockIcon3x} from '../assets/images';
import {
  StandardText,
  StandardTouchableOpacity,
  ColouredLabel,
} from '../Components/Atoms';
import {forEach, find, orderBy} from 'lodash';

import {
  Container,
  SummaryTile,
  CurrencyText,
  GridView,
} from '../Components/Molecules';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as BudgetActions from '../Actions/BudgetActions';
import {callApiChecker} from '../API/APIHelper';
import {callAPIs} from '../API/APICaller';
import {getServiceAgreementsList} from '../API/PlanDataAPI';
import * as NavigationparamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {SERVICE_AGREEMENTS_LIST_SCREEN as messages} from '../Constants/Messages';
import {
  getScreenTitle,
  formatAsCurrency,
  formatDate,
  formatDDMMMYYDate,
} from '../Helpers/Utils';

class ServiceAgreementsList extends Component {
  state = {
    serviceAgreementsList: [],
  };

  constructor(props: any) {
    super(props);
    this.onRefresh = this.onRefresh.bind(this);
  }

  componentDidMount = () => {
    if (types.isWeb) {
      this.props.setLoading(true);
    } else {
      this.setState({loading: true});
    }
    this.fetchData();
  };

  fetchData = () => {
    const callbackFunction = (data: any) => {
      const serviceAgreements = data[0].content;
      this.props.actions.BudgetActions.actionGetServiceAgreementsList(
        serviceAgreements,
      );

      let reduxServiceAgreements =
        this.props.serviceReducer.serviceAgreementsList.serviceAgreements;
      let counter = 0;
      reduxServiceAgreements =
        this.props.serviceReducer.serviceAgreementsList.serviceAgreements.map(
          (serviceAgreementItem: any) => {
            const returnItem = serviceAgreementItem;

            // Add a key value to the service agreement item
            returnItem.key = counter;
            counter++;

            // Go through and add the key into the invoices
            if (serviceAgreementItem.contracts) {
              for (let i = 0; i < serviceAgreementItem.contracts.length; i++) {
                serviceAgreementItem.contracts[i].key = i;
              }
            }

            return returnItem;
          },
        );
      if (types.isWeb) {
        this.props.setLoading(false);
      } else {
        this.setState({loading: false});
      }

      this.setState({
        serviceAgreementsList: reduxServiceAgreements,
        refreshing: false,
      });
    };

    callAPIs(
      [getServiceAgreementsList(this.props.loadedMemberId)],
      callbackFunction,
      null,
      () => {
        if (types.isWeb) {
          this.props.setLoading(false);
        } else {
          this.setState({loading: false});
        }
        this.setState({refreshing: false});
      },
    );
  };

  onRefresh = () => {
    this.setState({refreshing: true});
    this.fetchData();
  };

  render() {
    if (types.isWeb) {
      return <View>{this._createMainContents()}</View>;
    }

    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        headerTitle={types2.NAVIGATION_TEXT.SERVICE_AGREEMENTS}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        needsSideBar={false}
        onRefresh={this.onRefresh}
        refreshing={this.state.refreshing}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    return (
      <View style={[CommonStyles.flexColumn, CommonStyles.content]}>
        <View style={[CommonStyles.marginLeft10, CommonStyles.marginRight10]}>
          <SummaryTile
            headerDisplayString={messages.MAIN_TITLE}
            headerIconImage={ServiceAgreement3x}
            HeaderTextColor={BrandStyles.TextColor3}
            HeaderBoarderColor={BrandStyles.borderColor3}
            hideYellowStar={true}
            showButton={false}
            fontSize={CommonStyles.rpfont20}
            disableMore={true}
          />
        </View>
        {this._serviceFlatList()}
      </View>
    );
  };

  renderResultSummary = (flatternSAArray: any) => {
    if (flatternSAArray && flatternSAArray.length > 0) {
      const resultCount = flatternSAArray.length;
      return (
        <View style={CommonStyles.alignItemsCenter}>
          <StandardText
            style={[
              CommonStyles.textResultSummary,
              CommonStyles.customFontBold,
              BrandStyles.TextColor13,
            ]}
          >
            {`${types2.RESULT_COUNT.SHOW}${resultCount}${types2.RESULT_COUNT.RESULTS}`}
          </StandardText>
        </View>
      );
    }
  };

  _serviceFlatList = () => {
    const {serviceAgreementsList} = this.state;
    // Check if there is no service agreement list
    if (serviceAgreementsList.length == 0) {
      return null;
    }

    let flatternSAArray: any = [];
    forEach(serviceAgreementsList, item => {
      forEach(item.contracts, contract => {
        flatternSAArray.push(contract);
      });
    });

    flatternSAArray = orderBy(flatternSAArray, ['endDate'], ['desc']);
    // Pushing budget category styles(color/icon) to mapping category
    forEach(flatternSAArray, item => {
      item['budgetCategoryData'] = this._filterBudgetCategoryStyles(
        this.props.budgets.budgetCategories,
        item.category,
      );
    });

    return (
      <View style={[CommonStyles.mainContentContainer]}>
        <View style={[CommonStyles.content]}>
          {this.renderResultSummary(flatternSAArray)}
          {this._renderResponsiveList(flatternSAArray)}
        </View>
      </View>
    );
  };

  _renderResponsiveList = (dataArray: any) => {
    if (types.isWeb) {
      return (
        <GridView
          viewWidth={this.props.viewWidth}
          isMobile={this.props.isMobile}
          itemDimension={
            types2.ITEM_DIMENSION + types2.ITEM_DIMENSION_OFFSET_SA
          }
        >
          {this._renderTiles(dataArray)}
        </GridView>
      );
    } else {
      return (
        <FlatList
          data={dataArray}
          keyExtractor={item => item.key + item.providerName}
          renderItem={({item}) => this._renderServiceAgreements(item)}
        />
      );
    }
  };

  _renderTiles = (dList: any) => {
    let outcomeList: any = [];
    dList.forEach((item: any) => {
      outcomeList.push(this._renderServiceAgreements(item));
    });
    return outcomeList;
  };

  _filterBudgetCategoryStyles = (
    budgetCategories: any,
    budgetCategoryId: any,
  ) => {
    return find(budgetCategories, {categoryId: budgetCategoryId});
  };

  _renderStatusLabel = (item: any) => {
    let message = messages.INACTIVE;
    let backgroundColour =
      CommonStyles.ServiceAgreementStatusContainerGreyTextWrapper;
    if (item.active) {
      message = messages.ACTIVE;
      backgroundColour = CommonStyles.containerPurpleTextWrapper;
    }
    return (
      <ColouredLabel message={message} backgroundStyle={backgroundColour} />
    );
  };

  _renderServiceAgreements = (item: any) => {
    return (
      <View
        style={[
          CommonStyles.budgetsTilesContainer,
          types.isWeb
            ? [CommonStyles.paddingBottom10, CommonStyles.budgetsTileWrapper]
            : null,
        ]}
      >
        <StandardTouchableOpacity
          accessibilityLabel={'Service Agreement Details'}
          onPress={() => this._navigateToDetails(item)}
          activeOpacity={0.8}
          style={[
            BrandStyles.primaryBgColor1,
            types.isWeb ? null : CommonStyles.budgetsTileWrapper,
          ]}
        >
          <View>
            <View
              style={[
                BrandStyles.primaryBgColor1,
                types.isWeb
                  ? CommonStyles.flexDirectionRow
                  : CommonStyles.justifyFlexRowStart,
                CommonStyles.marginBottom5,
                CommonStyles.alignItemsCenter,
              ]}
            >
              <Image
                style={CommonStyles.containerBudgetApprovalCard_Image}
                source={this._renderCategoryIcon(item)}
              />
              <View style={[CommonStyles.justifyFlexColumnStart]}>
                <StandardText style={this._renderCategoryStyle(item)}>
                  {item.providerName}
                </StandardText>
              </View>
            </View>
            <View style={[CommonStyles.justifyFlexRowStart]}>
              <View
                style={[
                  CommonStyles.justifyFlexColumnStart,
                  CommonStyles.flex06,
                ]}
              >
                <View
                  style={[
                    CommonStyles.justifyFlexColumnStart,
                    CommonStyles.cancelMaxWidth,
                  ]}
                >
                  {this._renderStatusLabel(item)}
                </View>
                <View
                  style={[
                    CommonStyles.justifyFlexColumnStart,
                    CommonStyles.cancelMaxWidth,
                  ]}
                >
                  {this._renderStatusLabel(item)}
                </View>
                <View
                  style={[
                    CommonStyles.justifyFlexRowStart,
                    CommonStyles.alignItemsCenter,
                    CommonStyles.marginBottom10,
                    types.isWeb ? CommonStyles.marginTop15 : null,
                  ]}
                >
                  <Image
                    style={CommonStyles.containerBudgetApprovalCard_ImageDate}
                    source={GreyClockIcon3x}
                  />
                  <StandardText
                    style={[
                      CommonStyles.font15,
                      CommonStyles.customFont,
                      BrandStyles.TextColor10,
                    ]}
                  >
                    {this._renderDate(item)}
                  </StandardText>
                </View>
                <View
                  style={[
                    CommonStyles.justifyFlexColumnStart,
                    CommonStyles.flex4,
                  ]}
                >
                  <StandardText
                    style={[
                      CommonStyles.title_ListContainer,
                      CommonStyles.marginBottom10,
                      CommonStyles.customFont,
                    ]}
                  >
                    {item.categoryDescription}
                  </StandardText>
                  <StandardText
                    style={[
                      CommonStyles.title_ListContainer,
                      CommonStyles.customFont,
                    ]}
                  >
                    {item.contractNumber}
                  </StandardText>
                </View>
              </View>
              <View
                style={[
                  CommonStyles.justifyFlexColumnStart,
                  CommonStyles.flex04,
                ]}
              >
                <View
                  style={[
                    CommonStyles.flex05,
                    CommonStyles.flexDirectionColumn,
                    CommonStyles.alignItemsCenter,
                  ]}
                >
                  <ColouredLabel
                    message={messages.USED}
                    backgroundStyle={CommonStyles.containerRedTextWrapper}
                  />
                  <CurrencyText
                    value={item.usedBudget}
                    isPositive={true}
                    integerStyle={[
                      CommonStyles.font25,
                      CommonStyles.customFont,
                      BrandStyles.TextColor5,
                    ]}
                  />
                </View>
                <View
                  style={[
                    CommonStyles.flex05,
                    CommonStyles.flexDirectionColumn,
                    CommonStyles.alignItemsCenter,
                    types.isWeb ? CommonStyles.marginTop20 : {},
                  ]}
                >
                  <ColouredLabel
                    message={messages.REMAINING}
                    backgroundStyle={CommonStyles.containerPurpleTextWrapper}
                  />
                  <CurrencyText
                    value={item.remainingBudget}
                    isPositive={true}
                    integerStyle={[
                      CommonStyles.font25,
                      CommonStyles.customFont,
                      BrandStyles.TextColor5,
                    ]}
                  />
                </View>
              </View>
            </View>
          </View>
        </StandardTouchableOpacity>
      </View>
    );
  };

  _navigateToDetails = (item: any) => {
    this.props.actions.NavigationParamActions.setParam({
      itemDetails: item,
      title: getScreenTitle(this.props.member, messages.MAIN_TITLE),
    });
    this.props.navigation.navigate('ServiceAgreementsDetails');
  };

  _renderCategoryIcon = (item: any) => {
    let icon = ServiceAgreement3x;
    if (item.budgetCategoryData !== undefined) {
      icon = {uri: item.budgetCategoryData.icon.url};
    }
    return icon;
  };

  _renderCategoryStyle = (item: any) => {
    let styles = [
      CommonStyles.font18,
      CommonStyles.customFontSemiBold,
      BrandStyles.TextColor3,
    ];
    if (item.budgetCategoryData !== undefined) {
      styles = [
        CommonStyles.font18,
        CommonStyles.customFontSemiBold,
        {color: item.budgetCategoryData.color},
      ];
    }
    return styles;
  };

  _renderDate = (item: any) => {
    return (
      formatDDMMMYYDate(item.startDate) +
      ' - ' +
      formatDDMMMYYDate(item.endDate)
    );
  };
}

const mapStateToProps = (state: any) => ({
  loadedMemberId: state.LoadedMemberReducer.loadedMemberId,
  member: state.MemberReducer,
  serviceReducer: state.ServiceAgreementsListReducer,
  budgets: state.BudgetReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    BudgetActions: bindActionCreators(BudgetActions, dispatch),
    NavigationParamActions: bindActionCreators(
      NavigationparamActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceAgreementsList);
