/*
 * Author: Declan Hart
 * Date: 08/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Display message when no data is avaliable
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import {SelectOrAddCrew, SelectedCrewMember} from '../Molecules';
import {StandardText} from '../Atoms';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

class AddCrewToSupport extends Component {
  constructor(props: any) {
    super(props);
    this._renderCrew = this._renderCrew.bind(this);
  }

  _renderCrew = () => {
    if (this.props.selectedCrew != null && this.props.selectedCrew.length > 0) {
      return (
        <View style={[CommonStyles.flexColumn]}>
          {this.props.selectedCrew.map((crewMember: any, index: any) => (
            <SelectedCrewMember
              key={crewMember.crew.crewId}
              crew={crewMember.crew}
              indexCounter={index}
              crewSupportInformation={crewMember.crewSupport}
              save={this.props.save}
              remove={this.props.removeCrewMember}
            />
          ))}
        </View>
      );
    }
  };

  render() {
    let access = true;
    if (this.props.readOnly == 'read') {
      access = false;
    }
    if (access == false) {
      return (
        <View style={CommonStyles.spaceAround}>
          <View
            style={[CommonStyles.flexDirectionRow, CommonStyles.marginBottom5]}
          >
            <StandardText
              style={[
                CommonStyles.title_SupportQuestions,
                CommonStyles.customFontBold,
              ]}
            >
              {types2.WHO_IS_HELPING_YOU}
            </StandardText>
          </View>
        </View>
      );
    }

    return (
      <View style={CommonStyles.spaceAround}>
        <View
          style={[CommonStyles.flexDirectionRow, CommonStyles.marginBottom15]}
        >
          <StandardText
            style={[
              CommonStyles.title_SupportQuestions,
              CommonStyles.customFontBold,
            ]}
          >
            {types2.WHO_IS_HELPING_YOU}
          </StandardText>
        </View>
        <SelectOrAddCrew
          navigation={this.props.navigation}
          save={this.props.save}
          crew={this.props.crew}
        />
        {this._renderCrew()}
      </View>
    );
  }
}

export default AddCrewToSupport;
