/*
 * Author: Brad D'Costa
 * Date: 05/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays a Pros and Cons section
 * Note: Cons are optional (empty array or undefined), and Pros will take up the full container if cons aren't being used
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View} from 'react-native';
// import Orientation from 'react-native-orientation-enhanced';
//Detect device is a tablet or mobile
import DeviceInfo from 'react-native-device-info';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import * as learnMore from '../../Constants/PMLearnMoreConstants';
import {MESSAGES} from '../../Constants/Messages';

import {StandardText, StandardTouchableOpacity} from '../Atoms';
import {ProConBullets} from '../Molecules';

import {ThumbsDownCharcoal, ThumbsUpGreen} from '../../assets/images';
import {isTablet} from '../../Helpers/PlatformSynchronizer';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class ProsCons extends Component {
  state = {
    orientation: '',
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    // if (!types.isWeb) {
    //   Orientation.addOrientationListener(this._orientationDidChange);
    // }
  }

  componentWillUnmount() {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation,
    });
  };

  render() {
    var hasCons = false;
    if (this.props.conData) {
      if (this.props.conData.length > 0) {
        hasCons = true;
      }
    }

    return (
      <View style={[CommonStyles.marginLeft, CommonStyles.paddingBottom10]}>
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.alignItemsCenter,
            CommonStyles.paddingBottom5,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFontSemiBold,
              BrandStyles.TextColor4,
              types.isWeb ? CommonStyles.font18 : CommonStyles.rpfont20,
            ]}
          >
            {learnMore.COMPARE_PROS_CONS}
          </StandardText>
        </View>
        {this._renderProsCons(hasCons)}
      </View>
    );
  }

  _determineMainContainerStyles = () => {
    let styles = [CommonStyles.containerRoundCorners];
    if (this.state.orientation === types2.ORIENTATION.LANDSCAPE) {
      styles.push(CommonStyles.flexDirectionRow);
    } else {
      styles.push(CommonStyles.flexDirectionColumn);
    }
    return styles;
  };

  _determineProsConsStyles = (isCons: any, isTablet: any) => {
    let styles = [CommonStyles.containerProsCons];

    // Styles for web app Desktop and Web mobile
    if (types.isWeb) {
      if (isCons) {
        styles.push(CommonStyles.containerCons);
      }

      if (this.props.isMobile) {
        styles.push(CommonStyles.flexDirectionColumn);
      } else {
        styles.push(CommonStyles.flexDirectionRow);
        styles.push(CommonStyles.flex1);
      }
      return styles;
    }

    // Styles for For Native app Tablet and Mobile
    if (isTablet() && this.state.orientation === types2.ORIENTATION.LANDSCAPE) {
      styles.push(CommonStyles.flexDirectionRow);
      styles.push(CommonStyles.flex1);
    } else {
      if (isTablet()) {
        styles.push(CommonStyles.flexDirectionRow);
      } else {
        styles.push(CommonStyles.flexDirectionColumn);
      }

      if (isCons) {
        styles.push(CommonStyles.containerCons);
      }
    }
    return styles;
  };

  _determineThumbsIconStyles = (isTablet: any) => {
    let styles = [
      CommonStyles.flex018,
      CommonStyles.flexDirectionColumn,
      CommonStyles.alignItemsCenter,
      CommonStyles.containerThumbIcon,
    ];
    if (!isTablet()) {
      styles = [
        CommonStyles.justifyCenter,
        CommonStyles.flexDirectionRowReverse,
        CommonStyles.containerThumbIcon,
      ];
    }
    return styles;
  };

  _determineBulletContainerStyles = (isTablet: any) => {
    let styles: any = [];
    if (isTablet()) {
      styles = [CommonStyles.flex082];
    }
    return styles;
  };

  _renderProsCons = (hasCons: any) => {
    if (hasCons) {
      return (
        <View style={this._determineMainContainerStyles()}>
          {this._renderPros(isTablet)}

          <View style={this._determineProsConsStyles(true, isTablet)}>
            <View style={this._determineThumbsIconStyles(isTablet)}>
              <StandardText
                style={[
                  isTablet()
                    ? CommonStyles.paddingBottom0
                    : CommonStyles.paddingBottom10,
                  CommonStyles.customFontSemiBold,
                  BrandStyles.TextColor4,
                  types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont18,
                ]}
              >
                {learnMore.CONS}
              </StandardText>
              <Image
                style={[CommonStyles.prosConsIcon]}
                source={ThumbsDownCharcoal}
              />
            </View>
            <View style={this._determineBulletContainerStyles(isTablet)}>
              {this._renderBulletContent(this.props.conData, false)}
            </View>
          </View>
        </View>
      );
    } else {
      return (
        <View style={this._determineMainContainerStyles()}>
          {this._renderPros()}
        </View>
      );
    }
  };

  _renderPros = (isTablet: any) => {
    return (
      <View style={this._determineProsConsStyles(false, isTablet)}>
        <View style={this._determineThumbsIconStyles(isTablet)}>
          <StandardText
            style={[
              isTablet() ? CommonStyles.paddingTop0 : CommonStyles.paddingTop10,
              CommonStyles.textAlignVerticalTop,
              CommonStyles.customFontSemiBold,
              BrandStyles.TextColor4,
              types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont18,
            ]}
          >
            {learnMore.PROS}
          </StandardText>
          <Image style={[CommonStyles.prosConsIcon]} source={ThumbsUpGreen} />
        </View>
        <View style={this._determineBulletContainerStyles(isTablet)}>
          {this._renderBulletContent(this.props.proData, true)}
        </View>
      </View>
    );
  };

  _renderBulletContent = (data: any, isPro: any) => {
    var dataArray = data;
    return dataArray.map((tempStr: any, index: any) => (
      <ProConBullets key={index} data={tempStr} typePro={isPro} />
    ));
  };
}

export default ProsCons;
