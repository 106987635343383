/*
 * Author: Andrew Seeley
 * Date: 04/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a wrapper around RN Text with a standard styling applied.
 */

import React, { Component } from 'react';
import { Image, View } from 'react-native';
import { StandardText } from './';

import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';

class BulletText extends Component {
  constructor(props: any) {
    super(props);
    this._renderBulletPoint.bind(this);
  }

  render() {
    let hasFlex = true;
    if (this.props.hasFlex != undefined) {
      hasFlex = this.props.hasFlex;
    }
    return (
      <View>
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.alignItemsFlexStart,
            {paddingTop: 5, paddingRight: 5, paddingBottom: 5, paddingLeft: 0},
          ]}
        >
          {this._renderBulletPoint()}
          <View style={this._hasFlex(hasFlex)}>
            <StandardText
              style={[
                CommonStyles.customFont,
                BrandStyles.TextColor5,
                CommonStyles.font17,
                this.props.style,
              ]}
            >
              {this.props.children}
            </StandardText>
          </View>
        </View>
      </View>
    );
  }

  _hasFlex = (hasFlex: any) => {
    if (hasFlex) {
      return [{flex: 1}];
    }
  };

  _renderBulletPoint() {
    if (this.props.bulletImage != undefined) {
      return (
        <Image
          style={[CommonStyles.prosConsIcon, {marginLeft: 0, marginRight: 10}]}
          source={this.props.bulletImage}
        />
      );
    } else {
      return (
        <StandardText
          style={[
            CommonStyles.customFont,
            BrandStyles.TextColor5,
            CommonStyles.font17,
            {marginLeft: 10, marginRight: 10},
            this.props.style,
          ]}
        >
          {this.props.bulletCharacter}
        </StandardText>
      );
    }
  }
}

BulletText.defaultProps = {
  bulletCharacter: '\u2022',
};

export default BulletText;
