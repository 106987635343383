/*
 * Author: Mayuran Kunarajah
 * Date: 02/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Radio Group Component.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {StyleSheet, View, Text} from 'react-native';

import RadioButton from './RadioButton';

const defaultSize = 27;
const defaultThickness = 1;
const defaultColor = '#E0292E';

export default class RadioGroup extends Component {
  prevSelected: any;
  constructor(props: any, context: any) {
    super(props, context);

    this.state = {
      selectedIndex: this.props.selectedIndex,
    };
    this.prevSelected = this.props.selectedIndex;
    this.onSelect = this.onSelect.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.selectedIndex != this.prevSelected) {
      this.prevSelected = nextProps.selectedIndex;
      this.setState({
        selectedIndex: nextProps.selectedIndex,
      });
    }
  }

  getChildContext() {
    return {
      onSelect: this.onSelect,
      size: this.props.size,
    };
  }

  onSelect(index: any, value: any) {
    if (
      (this.props.onSelect && this.state.selectedIndex !== index) ||
      this.props.disableUnSelect
    ) {
      this.setState({
        selectedIndex: index,
      });
      this.props.onSelect(index, true);
    } else {
      this.setState({
        selectedIndex: -1,
      });
      this.props.onSelect(index, false);
    }
  }

  render() {
    const radioButtons = React.Children.map(
      this.props.children,
      (radioButton, index) => {
        const isSelected =
          this.state.selectedIndex != undefined
            ? this.state.selectedIndex == index
            : radioButton.props.isSelected;
        const color =
          isSelected && this.props.activeColor
            ? this.props.activeColor
            : this.props.color;
        return (
          <RadioButton
            color={color}
            activeColor={this.props.activeColor}
            {...radioButton.props}
            index={index}
            isSelected={isSelected}
          >
            {radioButton.props.children}
          </RadioButton>
        );
      },
    );

    return <View style={this.props.style}>{radioButtons}</View>;
  }
}

RadioGroup.childContextTypes = {
  onSelect: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
};

RadioGroup.defaultProps = {
  size: defaultSize,
};
