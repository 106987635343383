/*
 * Author: Andrew Seeley
 * Date: 20/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Login actions for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const actionLoginSuccess = (logintype, token, awstoken) => ({
  type: types.LOGIN_SUCCESS,
  logintype,
  token,
  awstoken,
});

export const actionLoginFailure = (logintype: any) => ({
  type: types.LOGIN_FAILURE,
  logintype,
});

export const actionLogout = () => ({
  type: types.LOGOUT,
});
