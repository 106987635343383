/*
 * Author: Josh Merkas
 * Date: 11/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * DocumentList information.
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function DocumentListReducer(state = {}, action: any) {
  switch (action.type) {
    case types.GET_DOCUMENT_LIST:
      return {
        ...state,
        documentList: action.documentlistObj,
        searchList: action.documentlistObj,
        apiCalled: new Date(),
      };
    case types2.GET_SEARCH_DOCUMENT_LIST:
      return {
        ...state,
        searchList: action.documentlistObj,
      };
    default:
      return state;
  }
}
