/*
 * Author: Anuja Ulpathakumbura
 * Date: 25/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Document edit screen
 */

import React, {Component} from 'react';
import {View, Alert, Platform} from 'react-native';
import NetInfo from '@react-native-community/netinfo';
//import {NavigationActions} from 'react-navigation-awseeley--enhanced';
import {CommonActions, StackActions} from '@react-navigation/native';
import {
  Container,
  FormTitle,
  EditIconComponent,
  RequiredIndicator,
} from '../Components/Molecules';
import {StandardText, StandardInput, StandardPicker} from '../Components/Atoms';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {BasicForm, BasicPopupAlert} from '../Components/Organisms';
import {downloadIconCharcoal60, deleteIconCharcoal60} from '../assets/images';

import {MyDocumentsPurple4x} from '../assets/images';

import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import * as DocumentListActions from '../Actions/DocumentListActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {callApiChecker} from '../API/APIHelper';
import {callAPIs} from '../API/APICaller';
import {formatDateDocument} from '../Helpers/Utils';
import logger from 'helpers/Logger';

import {
  getDocumentList,
  getUploadURL,
  deleteFile,
  putFileData,
} from '../API/MemberAPI';

import {
  RNFetchBlobSingleton,
  FileDownloaderSingleton,
  isTablet,
} from '../Helpers/PlatformSynchronizer';

class DocumentEditForm extends Component {
  state = {
    fileUploadVisibleModal: false,
    fileEditVisibleModal: false,
    documentList: [],
    loading: true,
    documentPath: '',
    selectedFile: {},
    filePickerResponse: {},
    categories: types2.DOC_UPLOAD.CATEGORIES,
    documentCategory: 1,
    documentCategoryDisplay: 'Plan - Current',
    documentListOriginal: [],
    docItem: {},
    docIndex: {},
    saveApiError: false,
    isConnected: true,
    hasChanged: false,
    tempSearchValue: '',
    searchInput: '',
    searchDocuments: [],
    errorPopUpVisible: false,
  };
  constructor(props: any) {
    super(props);
    this._saveFileEditForm.bind(this);
    this._fileEditSaveButton.bind(this);
    this._closeFileEditForm.bind(this);
  }

  componentDidMount() {
    this.setState({
      loading: false,
      documentCategory: this.props.navigationParams.params.documentCategory,
      filename: this.props.navigationParams.params.filename,
      docIndex: this.props.navigationParams.params.docIndex,
      documentKey: this.props.navigationParams.params.documentKey,
    });
  }

  _saveFileEditForm = () => {
    this._fileEditSaveButton();
  };

  _closeFileEditForm = () => {
    if (types.isWeb) {
      this.props.setLoading(true);
      this.props.navigation.goBack();
    } else {
      const actionToDispatch = CommonActions.reset({
        index: 1,
        routes: [
          {name: 'Profile'},
          {name: 'Documents' }
        ],
      });
     
        setTimeout(() => {
          this.setState({loading: false});
          this.props.navigation.dispatch(actionToDispatch);
        }, 2000);
      };
  };

  getDocumentTypeValue() {
    return this.state.categories.find(category => {
      return category.type === this.state.documentCategory;
    });
  }

  _fileEditSaveButton() {
    const validateFields = this.validateFields();
    if (!validateFields) {
      return;
    }
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({loading: true});
    let documentList = this.props.documentList.documentList;
    let requestObj = {
      id: this.props.loadedMemberId.loadedMemberId,
      fileType: 'document',
      key: this.state.documentKey,
      newName: this.state.filename,
      newCategory: this.getDocumentTypeValue().value,
    };

    let putFileDataCallback = (data: any) => {
      documentList[this.state.docIndex].filename = requestObj.newName;
      documentList[this.state.docIndex].category = requestObj.newCategory;
      this.props.actions.DocumentListActions.actionGetDocumentList(
        documentList,
      );
      this._closeFileEditForm();
    };

    const putFileDataErrorCallback = (err: any) => {
      logger.log('err: ', err);
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
        loading: false,
      });
    };

    if (this.state.hasChanged) {
      callAPIs(
        [putFileData(requestObj)],
        putFileDataCallback,
        putFileDataErrorCallback,
      );
    } else {
      this._closeFileEditModal();
    }
  }

  _closeFileEditModal = () => this.props.navigation.goBack();

  // Formats the correct date to display on the screen
  // Needs to be made into a helper function

  _renderSaveStyles = () => {
    let styles = {};
    return (styles = {
      style: [
        BrandStyles.brandBlockTxtColor5,
        CommonStyles.buttonFormAction,
        CommonStyles.customFontBold,
      ],
      containerStyle: [
        BrandStyles.primaryBgColor4,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerBudget,
      ],
    });
  };

  //Force Download paremeter equal to true automatically downloads the file
  _openDocument(document: any, forceDownload: any) {
    let fileExt = '';
    if (this.getFileExtension(document.filename)) {
      fileExt = this.getFileExtension(document.filename)[0];
    }
    let contentTypeExt =
      fileExt == undefined ? '.' + document.contentType.split('/').pop() : '';
    if (Platform.OS === types2.IOS) {
      this.setState({loading: true});
      const ios = RNFetchBlobSingleton.ios;
      const dirs = RNFetchBlobSingleton.fs.dirs;
      //iPad
      RNFetchBlobSingleton.config({
        path:
          dirs.DocumentDir + '/LeapIn/' + document.filename + contentTypeExt,
        fileCache: true,
      })
        .fetch('GET', document.url, {
          // Headers here
        })
        .then(res => {
          this.setState({loading: false});
          ios
            .openDocument(res.path())
            .catch((err: any) =>
              Alert.alert(types2.ERROR, types2.DOWNLOAD_ERROR),
            );
        })
        .catch(err => {
          this.setState({loading: false});
          Alert.alert(types2.ERROR, types2.DOWNLOAD_ERROR);
        });
    } else if (Platform.OS === types2.ANDROID) {
      let fileType = document.contentType;
      // Download the file locally if it is not supported
      const {config, fs} = RNFetchBlobSingleton;
      const DownloadDir = fs.dirs.DownloadDir;
      const options = {
        fileCache: true,
        addAndroidDownloads: {
          useDownloadManager: true,
          notification: true,
          mime: document.contentType,
          title: document.filename + types2.DOCUMENT_DOWNLOAD,
          description: types2.DOCUMENT_DOWNLOAD_DESCRIPTION,
          mediaScannable: true,
          path: DownloadDir + '/LeapIn/' + document.filename,
        },
      };
      config(options)
        .fetch('GET', document.url)
        .then(res => {});
    } else if (types.isWeb) {
      window.open(document.url);
    }
  }

  getFileExtension(filename: any) {
    let fileType: any = [];
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : fileType;
  }

  _downloadIconCallback = (downloadItem: any) => {
    this._openDocument(downloadItem, true);
  };

  // Deletes the Document from the flat list needs to delete from API to
  _deleteDocument(item: any) {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({loading: true});
    let deleteObject = {
      id: this.props.loadedMemberId.loadedMemberId,
      fileType: 'document',
      key: item.key,
    };

    let deleteFileCallback = (data: any) => {
      let payload = data[0];

      // Remove it from the state
      let index = this.props.documentList.documentList.indexOf(item);
      var documentList = this.state.documentList;
      documentList.splice(index, 1);
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        documentList: documentList,
      });

      this.props.actions.DocumentListActions.actionGetDocumentList(
        documentList,
      );
      this._closeFileEditForm();
    };

    const deleteFileErrorCallback = (err: any) => {
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
        loading: false,
      });
    };

    callAPIs(
      [deleteFile(deleteObject)],
      deleteFileCallback,
      deleteFileErrorCallback,
    );
  }

  _deleteIconCallback = (deleteItem: any) => {
    if (!this.state.isConnected) {
      this._showAlert(
        types2.OFFLINE,
        types2.NO_INTERNET,
        types2.CLOSE,
        () => {
          this.setState({
            errorPopUpVisible: false,
          });
        },
        true,
      );
    } else {
      this._showAlert(
        types2.ALERT_TITLE,
        types2.CONFIRM_DELETE,
        types2.CANCEL,
        () => {
          this.setState({
            errorPopUpVisible: false,
          });
        },
        true,
        types2.CONFIRM_DEL,
        () => {
          this.setState({
            errorPopUpVisible: false,
          });
          this._deleteDocument(deleteItem);
        },
      );
    }
  };

  _renderDocumentActionButtons = () => {
    let item = this.props.documentList.documentList.find((item: any) => {
      return item.key === this.state.documentKey;
    });

    if (this._determineAccess()) {
      return (
        <View style={CommonStyles.documentActions}>
          <View
            style={[
              CommonStyles.flex1,
              CommonStyles.flexRow,
              CommonStyles.justifyContentFlexEnd,
            ]}
          >
            <View style={CommonStyles.marginRight20}>
              <EditIconComponent
                editIconImage={downloadIconCharcoal60}
                iconText="Download"
                iconPressCallback={this._downloadIconCallback}
                editDoc={item}
              />
            </View>
            <View>
              <EditIconComponent
                editIconImage={deleteIconCharcoal60}
                iconText="Delete"
                iconPressCallback={this._deleteIconCallback}
                editDoc={item}
              />
            </View>
          </View>
        </View>
      );
    } else {
      return (
        <View style={CommonStyles.documentActions}>
          <EditIconComponent
            notCentered={true}
            editIconImage={downloadIconCharcoal60}
            iconText="Download"
            iconPressCallback={this._downloadIconCallback}
            editDoc={item}
          />
        </View>
      );
    }
  };
  _determineAccess() {
    if (Object.getOwnPropertyNames(this.props.member).length === 0) {
      return true;
    } else {
      let readOnly = this.props.member;
      if (readOnly.access) {
        if (readOnly.access.documents) {
          if (readOnly.access.documents == types.ACCESS_READ_ONLY) {
            return false;
          }
        }
      }
    }
    return true;
  }

  validateFields = () => {
    var validateErrors = {
      DocumentNameError: '',
    };

    if (this.state.filename == '' || this.state.filename == undefined) {
      validateErrors.DocumentNameError = types.DOCUMENT_NAME_NOT_ENTERED;
    }

    // Set the state to show the validation on the components
    this.setState({
      validDocumentName: validateErrors.DocumentNameError,
    });

    // Now return if the there are valid fields
    for (let [key, value] of Object.entries(validateErrors)) {
      if (value != '') {
        return false;
      }
    }
    return true;
  };

  _showAlert = (
    alertHeader: any,
    alertMessage: any,
    alertButtonText: any,
    closeAction: any,
    errorPopUpVisible: any,
    saveButtonText: any,
    saveAction: any,
  ) => {
    this.setState({
      alertHeader,
      alertMessage,
      alertButtonText,
      closeAction,
      errorPopUpVisible,
      saveButtonText,
      saveAction,
    });
  };

  _determinePopUpStyle = () => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      styles.push([CommonStyles.containerInstructionPopUpWeb]);

      if (this.props.isMobile) {
        styles.push([CommonStyles.containerPopupWebMobile]);
      }
    } else if (isTablet()) {
      styles.push([
        CommonStyles.containerInstructionPopUpTablet,
        CommonStyles.minHeight300,
      ]);
    } else {
      styles.push([
        CommonStyles.containerInstructionPopUpMobile,
        CommonStyles.minHeight230,
      ]);
    }
    return styles;
  };

  _createMainContents = () => {
    let cancelContainerStyle, cancelStyle, saveContainerStyle;
    if (this.state.errorPopUpVisible && this.state.saveAction) {
      cancelContainerStyle = [
        BrandStyles.primaryBgColor1,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerFormAction,
        CommonStyles.marginRight10,
      ];

      cancelStyle = [
        CommonStyles.customFontBold,
        CommonStyles.buttonFormAction,
        BrandStyles.TextColor4,
      ];

      saveContainerStyle = [
        CommonStyles.buttonContainerFormAction,
        BrandStyles.primaryBgColor4,
        BrandStyles.borderColor4,
      ];

      if (types.isWeb && this.props.isMobile) {
        cancelContainerStyle.push(CommonStyles.maxWidth100);
        saveContainerStyle.push(CommonStyles.maxWidth100);
      }
    }
    return (
      <BasicForm
        headerDisplayString={'Edit Documents'}
        // headerIconImage={MyDocumentsPurple4x}
        save={this._saveFileEditForm}
        saveStyle={this._renderSaveStyles()}
        close={this._closeFileEditForm}
        disableMore={true}
        showButton={false}
        readOnly={this.props.member.access.documents}
        buttonsPositionBottom={true}
        contentWidthFull={true}
      >
        {this._renderDocumentActionButtons()}
        <View>
          <FormTitle
            containsData={this.state.filename}
            text={types2.DOCUMENT_NAME}
            style={[
              CommonStyles.customFontBold,
              CommonStyles.AboutMeModalLabel,
              types.isWeb ? CommonStyles.font15 : null,
            ]}
          />
          <RequiredIndicator />
          <StandardInput
            value={this.state.filename}
            readOnly={this.props.member.access.documents}
            ref="changefilename"
            placeHolder="Filename"
            onChangeText={(changefilename: any) =>
              this.setState(prevState => ({
                filename: changefilename,
                hasChanged: true,
              }))
            }
            blurOnSubmit={true}
            style={[
              CommonStyles.ModalTextInputWrapper,
              BrandStyles.primaryBgColor1,
              CommonStyles.customFont,
            ]}
            underlineColorAndroid="transparent"
            errorMessage={this.state.validDocumentName}
          />
          <StandardText
            style={[
              CommonStyles.customFontMedium,
              CommonStyles.ModalLabel,
              BrandStyles.TextColor5,
            ]}
          >
            {types2.DOCUMENT_CATEGORY}
          </StandardText>
          <View
            style={[BrandStyles.secondaryBgColor3, CommonStyles.borderRadius10]}
          >
            <View style={[CommonStyles.PickerBackground]}>
              {
                <StandardPicker
                  style={[
                    CommonStyles.standardPicker,
                    CommonStyles.standardPickerBackgroundColorGray,
                  ]}
                  access={this.props.member.access.documents}
                  selectedValue={this.state.documentCategory}
                  pickerOptions={this.state.categories}
                  onValueChange={(itemValue: any, itemIndex: any) => {
                    this.setState({
                      documentCategory: parseInt(itemValue),
                      hasChanged: true,
                    });
                  }}
                />
              }
            </View>
          </View>
          <StandardText
            style={[
              CommonStyles.customFontMedium,
              CommonStyles.ModalLabel,
              BrandStyles.TextColor5,
            ]}
          >
            {types2.DOCUMENT_UPLOADED_DATE}
          </StandardText>
          <StandardText
            style={[
              CommonStyles.documentLastModified,
              CommonStyles.customFont,
              BrandStyles.TextColor5,
            ]}
          >
            {formatDateDocument(
              this.props.navigationParams.params.lastModified,
            )}
          </StandardText>
          <BasicPopupAlert
            visible={this.state.errorPopUpVisible}
            close={this.state.closeAction}
            cancelLabel={this.state.alertButtonText}
            saveAction={this.state.saveAction}
            saveLabel={this.state.saveButtonText}
            headerText={this.state.alertHeader}
            messageText={this.state.alertMessage}
            cancelContainerStyle={cancelContainerStyle}
            saveContainerStyle={saveContainerStyle}
            cancelStyle={cancelStyle}
            style={this._determinePopUpStyle()}
            isMobile={this.props.isMobile}
          />
        </View>
      </BasicForm>
    );
  };

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        headerTitle={types2.EDIT_DOCUMENT}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        toggleMenu={this.props.screenProps?.toggleMenu}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }
}
const mapStateToProps = (state: any) => ({
  loadedMemberId: state.LoadedMemberReducer,
  documentList: state.DocumentListReducer,
  member: state.MemberReducer,
  navigationParams: state.NavigationParamsReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    DocumentListActions: bindActionCreators(DocumentListActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentEditForm);
