/*
 * Author: Akila Gunawardhana(agunawardhana@mitrai.com)
 * Date: 19/08/2021
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The initial screen for Plan Management Terms and Conditions
 */

import React, {Component} from 'react';
import {
  View,
  Animated,
  Image,
  Dimensions,
  ScrollView,
  RefreshControl,
  Text,
} from 'react-native';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {LILogo, HazardStripLeft, HazardStripRight} from '../assets/images';
import {
  LEFT_ROTATE_STRIPE,
  RIGHT_ROTATE_STRIPE,
} from '../Styles/OnboardingStyles';
import {StandardText, StandardButton} from '../Components/Atoms';
import {checkSystemUnderMaintenance} from '../API/UserAPI';
import {callAPIs} from '../API/APICaller';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
//import {NavigationActions} from 'react-navigation-awseeley--enhanced';
import {CommonActions, StackActions} from '@react-navigation/native';

class UnderMaintenance extends Component {
  state = {
    loading: false,
    refreshing: false,
  };
  constructor(props: any) {
    super(props);
  }
  checkStillUnderMaintenance() {
    checkSystemUnderMaintenance().then(res => {
      if (!this.props.general.isSystemUnderMaintained) {
        if (types.isWeb) {
          this.props.navigation.navigate('Welcome');
        } else {
          this.props.navigation.dispatch(
            CommonActions.reset({
              index: 0,
              routes: [
                {name: 'Welcome'}
              ],
            }),
          );
        }
      }
    });
  }
  onRefresh = () => {
    this.setState({refreshing: true});
    this.checkStillUnderMaintenance();
    this.setState({refreshing: false});
  };

  createMainContents = () => (
    <View style={[CommonStyles.underMaintenanceBackground]}>
      <Image
        style={[CommonStyles.budgetLockedImage, CommonStyles.marginTop20]}
        source={LILogo}
      />
      <StandardText
        style={[
          BrandStyles.TextColor5,
          CommonStyles.customFont,
          CommonStyles.rpfont13,
          CommonStyles.rpMarginTop5p,
          CommonStyles.fontWeightBold,
        ]}
      >
        {this.props.general.systemUnderMaintainedMessage?.title}
      </StandardText>
      <View style={[CommonStyles.underMaintenanceMessage]}>
        <StandardText
          style={[
            BrandStyles.TextColor5,
            CommonStyles.customFont,
            CommonStyles.rpfont12,
            CommonStyles.textAlignCentre,
            CommonStyles.rpMarginTop5p,
          ]}
        >
          {this.props.general.systemUnderMaintainedMessage?.message}
        </StandardText>
      </View>
      {this.props.general.systemUnderMaintainedMessage.customMessage && (
        <View style={[CommonStyles.underMaintenanceMessage]}>
          <StandardText
            style={[
              BrandStyles.TextColor5,
              CommonStyles.customFont,
              CommonStyles.rpfont12,
              CommonStyles.textAlignCentre,
              CommonStyles.rpMarginTop5p,
            ]}
          >
            {this.props.general.systemUnderMaintainedMessage.customMessage}
          </StandardText>
        </View>
      )}

      <StandardText
        style={[
          BrandStyles.TextColor5,
          CommonStyles.customFont,
          CommonStyles.rpfont12,
          CommonStyles.textAlignCentre,
          CommonStyles.rpMarginTop5p,
          CommonStyles.marginBottom15,
        ]}
      >
        {this.props.general.systemUnderMaintainedMessage?.footerMessage}{' '}
        <Text
          style={[
            BrandStyles.TextColor5,
            CommonStyles.customFont,
            CommonStyles.rpfont12,
            CommonStyles.fontWeightBold,
          ]}
        >
          {this.props.general.systemUnderMaintainedMessage?.phoneNumber}
        </Text>
      </StandardText>
    </View>
  );

  createContentsWeb = () => (
    <View
      style={[
        CommonStyles.underMaintenanceContainer,
        BrandStyles.primaryBgColour1,
      ]}
    >
      <View style={LEFT_ROTATE_STRIPE} />
      <View style={[CommonStyles.underMaintenanceBackground]}>
        {this.createMainContents()}
      </View>
      <View style={RIGHT_ROTATE_STRIPE} />
      <StandardButton
        onPress={() => this.checkStillUnderMaintenance()}
        style={[
          BrandStyles.brandBlockTxtColor6,
          CommonStyles.buttonFormAction,
          CommonStyles.customFontBold,
        ]}
        containerStyle={[
          BrandStyles.primaryBgColor1,
          BrandStyles.brandBorderColor1,
          CommonStyles.buttonContainerFormAction,
          CommonStyles.rpMarginTop10p,
        ]}
        accessibilityLabel={'Refresh'}
      >
        {'Refresh'}
      </StandardButton>
    </View>
  );

  createContentsMobile = () => (
    <ScrollView
      contentContainerStyle={CommonStyles.underMaintenanceContainer}
      refreshControl={
        <RefreshControl
          refreshing={this.state.refreshing}
          onRefresh={this.onRefresh}
        />
      }
    >
      <View
        style={[
          CommonStyles.underMaintenanceContainer,
          BrandStyles.primaryBgColour1,
        ]}
      >
        <View style={[CommonStyles.underMaintenanceBackground]}>
          <Image
            source={HazardStripLeft}
            style={[CommonStyles.underMaintenanceStripImage]}
          />
          {this.createMainContents()}
          <Image
            source={HazardStripRight}
            style={[CommonStyles.underMaintenanceStripImage]}
          />
        </View>
      </View>
    </ScrollView>
  );

  render() {
    if (types.isWeb) {
      return this.createContentsWeb();
    } else {
      return (
        <View
          style={CommonStyles.screenWrapper}
          accessibilityViewIsModal={true}
        >
          {this.createContentsMobile()}
        </View>
      );
    }
  }
}

const mapStateToProps = (state: any) => ({
  general: state.GeneralReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UnderMaintenance);
