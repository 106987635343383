/*
 * Author: Andrew Seeley
 * Date: 29/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is the goals summary screen. If you have no goals added it will display a message.
 * If you have goals added it will show them in a trello board like fashion.
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

import {OfflineNotice, GoalDetails} from '../Components/Organisms';
import {
  SummaryTile,
  Container,
  CrewNoAccess,
  CompletedGoalDetails,
} from '../Components/Molecules';
import {StandardText, StandardTouchableOpacity} from '../Components/Atoms';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {SPLASH_CONTENT as splashMessage} from '../Constants/Messages';

import {callAPIs} from '../API/APICaller';
import {setImageRedux} from '../Helpers/ImageHelper';
import {getGoalTemplateReference} from '../API/ReferenceAPI';
import {getGoalsForPlan, getGoalProgress} from '../API/PlanDataAPI';
import {setValue, removeItem} from '../API/WebPersistenceStore';

import * as MemberActions from '../Actions/MemberActions';
import * as GoalActions from '../Actions/GoalActions';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {ThumbsUpRed} from '../assets/images';

import logger from 'helpers/Logger';

import {goalsIconRed} from '../assets/images/vector-icons';

import CustomIcon from '../assets/images/CustomIcon';

const addGoals = 'AddGoals';
class GoalSummary extends Component {
  _saveGoalImage: any;
  state = {
    loading: false,
    goalImage: null,
    lastUpdate: new Date(),
    refreshing: false,
  };

  constructor(props: any) {
    super(props);
    this.fetchData.bind(this);
    this._setGoalImageCallBack.bind(this);
    this._selectedGoal.bind(this);
  }

  componentDidMount() {
    this.setState({loading: true});
    this.fetchData();
  }

  fetchData = () => {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    const callbackFunction = (results: any) => {
      const goalsPayload = results[0].content.currentGoals;

      this.props.actions.GoalActions.actionGetGoals(goalsPayload);
      if (types.isWeb) {
        removeItem(types2.WEB_STORE.GOALS);
        setValue(types2.WEB_STORE.GOALS, JSON.stringify(goalsPayload));
      }

      // Set the state to no longer be loading
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({loading: false, refreshing: false});
    };

    callAPIs(
      [
        getGoalsForPlan(
          this.props.member.planId,
          this.props.loadedMemberId.loadedMemberId,
          this.props.user.demoProfile,
        ),
      ],
      callbackFunction,
      null,
      () => {
        if (types.isWeb) {
          this.props.setLoading(false);
        }
        this.setState({loading: false, refreshing: false});
      },
    );
  };

  render() {
    // If we are a crew & there is no loaded member ID lockout the screen
    if (!this.props.member.id && this.props.user.isCrew) {
      return (
        <View style={[CommonStyles.content]}>
          <OfflineNotice />
          <CrewNoAccess />
        </View>
      );
    }
    let isLoading = true;
    // Only when we have a member ID & plan id hide the loading icon
    if (this.props.member.id && this.props.member.planId) {
      isLoading = this.state.loading;
    }

    // Change the title and where the content starts
    const ignorePadding = !this._hasGoals();
    let title = this.props.member.knownAs
      ? this.props.member.knownAs + "'s goals"
      : this.props.member.firstName + "'s goals";
    if (ignorePadding) {
      title = splashMessage.GOALS.TITLE;
    }

    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        needsSidebar={false}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        loading={this.state.loading}
        headerTitle={types2.NAVIGATION_TEXT.MY_GOALS}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        onRefresh={() => {
          this.setState({refreshing: true});
          this.fetchData();
        }}
        refreshing={this.state.refreshing}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    let titleFontSize = CommonStyles.rpfont20;
    if (types.isWeb) {
      titleFontSize = CommonStyles.font20;
    }
    // As one 'dummy goal' is created for the tap here to add a new goal
    return (
      <View style={[CommonStyles.singleMainContentContainer]}>
        <View accessible={false} style={[CommonStyles.columnContainer]}>
          <SummaryTile
            headerDisplayString={types2.GOAL_SCREEN_LABELS.GOALS}
            headerIconImage={goalsIconRed}
            HeaderTextColor={BrandStyles.TextColor3}
            HeaderBoarderColor={BrandStyles.borderColor3}
            hideYellowStar={true}
            showButton={false}
            fontSize={titleFontSize}
            disableMore={true}
          />
          {this._renderGoalSummary(true)}
          <SummaryTile
            headerDisplayString={types2.GOAL_SCREEN_LABELS.COMPLETED_GOALS}
            imageIcon={true}
            headerIconImage={ThumbsUpRed}
            HeaderTextColor={BrandStyles.TextColor3}
            HeaderBoarderColor={BrandStyles.borderColor3}
            hideYellowStar={true}
            showButton={false}
            fontSize={titleFontSize}
            disableMore={true}
            iconStyle={[CommonStyles.titleGoalSection]}
          />
          {this._renderGoalSummary(false)}
        </View>
      </View>
    );
  };

  _hasGoals = () => {
    let hasGoals = false;
    if (this.props.goals.goals) {
      if (this.props.goals.goals.length > 1) {
        hasGoals = true;
      }
    }

    return hasGoals;
  };

  _renderAddGoalButton = (isActive: any) => {
    if (isActive) {
      return (
        <StandardTouchableOpacity
          activeOpacity={0.8}
          onPress={this._addGoal}
          accessibilityLabel={types2.GOAL_SCREEN_LABELS.ADD_GOAL}
          style={[CommonStyles.buttonAddGoal, BrandStyles.primaryBgColor1]}
        >
          <View style={[CommonStyles.flexRow, CommonStyles.alignCenter]}>
            <CustomIcon
              style={[CommonStyles.titleBoxIcon, CommonStyles.margin10]}
              name={goalsIconRed}
            />
            <StandardText
              style={[
                CommonStyles.SummaryTileText,
                CommonStyles.customFontBold,
                BrandStyles.TextColor3,
                types.isWeb ? CommonStyles.font17 : CommonStyles.rpfont18,
              ]}
            >
              {types2.GOAL_SCREEN_LABELS.ADD_GOAL}
            </StandardText>
          </View>
        </StandardTouchableOpacity>
      );
    }
  };

  _renderGoalSummary = (isActive: any) => {
    if (this.props.goals) {
      if (this.props.goals.goals) {
        if (this.props.goals && this.props.goals.goals.length > 0) {
          return (
            <View>
              {this._renderEmptyGoalMessage(isActive)}
              {this._renderGoals(this.props.goals.goals, isActive)}
            </View>
          );
        }
      }
    }
  };

  _renderEmptyGoalMessage = (isActive: any) => {
    let emptyGoalMessage = '';

    if (isActive) {
      const activeGoals = this.props.goals.goals.filter(
        (goal: any) => goal.isActive === true,
      );
      emptyGoalMessage = types2.GOAL_SCREEN_LABELS.NO_ACTIVE_GOALS;
      if (activeGoals.length === 0) {
        return (
          <View style={CommonStyles.containerEmptyGoals}>
            <StandardText
              style={[
                CommonStyles.SummaryTileText,
                CommonStyles.customFontBold,
                BrandStyles.TextColor13,
                types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont18,
              ]}
            >
              {emptyGoalMessage}
            </StandardText>
          </View>
        );
      }
    } else {
      const inActiveGoals = this.props.goals.goals.filter(
        (goal: any) => goal.isActive === false,
      );
      emptyGoalMessage = types2.GOAL_SCREEN_LABELS.NO_COMPLETED_GOALS;
      if (inActiveGoals.length === 0) {
        return (
          <View style={CommonStyles.containerEmptyGoals}>
            <StandardText
              style={[
                CommonStyles.SummaryTileText,
                CommonStyles.customFontBold,
                BrandStyles.TextColor13,
                types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont18,
              ]}
            >
              {emptyGoalMessage}
            </StandardText>
          </View>
        );
      }
    }

    if (!isActive) {
      emptyGoalMessage = types2.GOAL_SCREEN_LABELS.NO_COMPLETED_GOALS;
    }
  };

  _renderGoals = (goalsList: any, isActive: any) => {
    if (isActive) {
      return goalsList.map((goalItem: any, index: any) => {
        if (goalItem.isAdd) {
          if (this.props.member.access.profile === types.ACCESS_FULL) {
            return (
              <View key={index} style={CommonStyles.alignCenter}>
                {this._renderAddGoalButton(isActive)}
              </View>
            );
          }
        } else if (goalItem.isActive) {
          return (
            <GoalDetails
              key={index}
              goalItem={goalItem}
              navigation={this.props.navigation}
              readOnly={this.props.member.access.profile}
              onPress={() => this._selectedGoal(goalItem)}
            />
          );
        }
      });
    } else {
      return goalsList.map((goalItem: any, index: any) => {
        if (goalItem.isActive === false && !goalItem.isAdd) {
          return (
            <CompletedGoalDetails
              key={index}
              goalItem={goalItem}
              onPress={() => this._navigateToCompletedGoal(goalItem)}
            />
          );
        }
      });
    }
  };

  _navigateToCompletedGoal = (goalItem: any) => {
    if (types.isWeb) {
      this.props.setLoading(true);
    } else {
      this.setState({isLoading: false});
    }
    const goalObject = {
      goalId: goalItem.currentGoalId,
      goalName: goalItem.goalName,
      goalCompletedDate: goalItem.completedDate,
      goalListPosition: goalItem.listPosition,
    };

    const getGoalProgressCallbackFunction = (data: any) => {
      const goalProgress = data[0].content.goalProgress;
      this.props.actions.GoalActions.getGoalProgress(goalProgress);
      if (types.isWeb) {
        this.props.setLoading(false);
      } else {
        this.setState({isLoading: false});
      }
      this.props.actions.NavigationParamActions.setParam({
        completedGoalObject: goalObject,
      });
      if (types.isWeb) {
        removeItem('completedGoalObject');
        removeItem('currentGoalProgress');
        setValue('completedGoalObject', JSON.stringify(goalObject));
        setValue('currentGoalProgress', JSON.stringify(goalProgress));
      }
      this.props.navigation.navigate('CompletedGoal');
    };

    callAPIs(
      [
        getGoalProgress(
          this.props.loadedMemberId.loadedMemberId,
          goalItem.currentGoalId,
        ),
      ],
      getGoalProgressCallbackFunction,
      null,
      () => {
        this.setState({loading: true});
      },
    );
  };

  _selectedGoal = (goalItem: any) => {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({isLoading: true});

    // Make sure we have the goal template data
    let getGoalTemplateReferenceCallback = (data: any) => {
      let payload = data[0];
      this.setState({loading: false});
      this.props.actions.GoalActions.getGoalTemplates(
        payload.content.templates,
      );

      // Update redux with the current goal
      this.props.actions.GoalActions.editExistingGoal(goalItem);

      if (types.isWeb) {
        this.props.setLoading(false);
        setValue('currentGoalID', goalItem.currentGoalId);
      }
      this.props.navigation.navigate('GoalPreview');
    };

    if (this.props.goals.goalsTemplates == null) {
      // First time calling the API
      callAPIs(
        [getGoalTemplateReference()],
        getGoalTemplateReferenceCallback,
        null,
        () => {
          if (types.isWeb) {
            this.props.setLoading(true);
          }
          this.setState({isLoading: true});
        },
      );
    } else {
      // Update redux with the current goal
      this.props.actions.GoalActions.editExistingGoal(goalItem);
      if (types.isWeb) {
        this.props.setLoading(false);
        setValue('currentGoalID', goalItem.currentGoalId);
      }
      this.setState({isLoading: false});
      // Navigate to the page
      this.props.navigation.navigate('GoalPreview');
    }
  };

  _setGoalImageCallBack = (requestObject: any, goalItem: any) => {
    var goalImage = setImageRedux(
      requestObject,
      this.props.actions.GoalActions.setGoalImage,
      types2.FILE_TYPE.GOAL_PHOTO,
    );
    this.setState({goalImage});
    const saveGoalImageSuccess = () => {
      this.setState({loading: false});
    };

    this._saveGoalImage(goalItem, saveGoalImageSuccess);
  };

  _addGoal = () => {
    if (types.isWeb) {
      removeItem('currentGoal');
    }
    this.props.actions.GoalActions.addNewGoal(
      this.props.member.id,
      this.props.member.planId,
    );
    this.props.actions.NavigationParamActions.setParam({
      isFilterApplied: false,
    });
    this.props.navigation.navigate(addGoals, {
      screenMode: types.SCREEN_MODE_NEW,
    });
  };
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  goals: state.GoalReducer,
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    GoalActions: bindActionCreators(GoalActions, dispatch),
    MemberActions: bindActionCreators(MemberActions, dispatch),
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GoalSummary);
