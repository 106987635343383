


// Define the interface
export type ImageLibraryOptions= {};
const  launchImageLibrary = () => {
    console.warn('Image picker is not supported on web platform');
};
const launchCamera = () => {
    console.warn('Image picker is not supported on web platform');
};

export {launchImageLibrary, launchCamera };