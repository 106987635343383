/*
 * Author: Nirodha Perera
 * Date: 31/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Listing of supports
 */

import React, {Component} from 'react';
import {StyleSheet, View, Image, TouchableOpacity} from 'react-native';

import {addPhotoPurple} from '../../assets/images';

import {
  StandardText,
  StandardTile,
  SupportTileDetails,
  StandardTouchableOpacity,
} from '../Atoms';
import {ActionButton} from './';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import * as messages from '../../Constants/Messages';

import {getSupportItems} from '../../API/ReferenceAPI';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class SupportTileItem extends Component {
  constructor(props: any) {
    super(props);
    this.renderFrequency = this.renderFrequency.bind(this);
    this.calculateHourWeek = this.calculateHourWeek.bind(this);
    this.calculateUOMEach = this.calculateUOMEach.bind(this);
  }

  renderFrequency() {
    if (this.props.support.unitOfMeasure) {
      switch (this.props.support.unitOfMeasure) {
        case types.HOUR:
          return this.calculateHourWeek();
        case types.WEEK:
          return this.calculateHourWeek();
        case types.YEAR:
          return types.YEAR;
        case types.EACH:
          return this.calculateUOMEach();
        case types.DAY:
          return this.calculateUOMEach();
        default:
          return null;
      }
    } else {
      return null;
    }
  }

  calculateHourWeek() {
    if (this.props.support.supportFields != null) {
      let frequencyObject = this.props.support.supportFields.find(
        (item: any) => {
          return item.fieldType === types.FREQUENCY;
        },
      );
      return frequencyObject != undefined ? frequencyObject.value : null;
    } else {
      return null;
    }
  }

  calculateUOMEach() {
    if (this.props.support.supportFields != null) {
      let frequencyObject = this.props.support.supportFields.find(
        (item: any) => {
          return item.fieldType === types.TIMESPERYEAR;
        },
      );
      return frequencyObject != undefined && frequencyObject.units != null
        ? frequencyObject.units + ' times per year'
        : null;
    } else {
      return null;
    }
  }

  _determineAccess = () => {
    if (this.props.readOnly == 'read') {
      return types.ACTION_BUTTON.VIEW;
    }
    return types.ACTION_BUTTON.EDIT;
  };

  renderEdit = (viewOrEdit: any) => {
    // #TODO - Shelan - Remove <&& !types.isWeb> condition once
    // My Profile support features available
    if (this.props.navigateToEdit && !types.isWeb) {
      return (
        <StandardTouchableOpacity
          accessibilityLabel={viewOrEdit}
          style={[CommonStyles.textAlignVertical]}
          onPress={() => {
            this.props.navigateToEdit(
              this.props.support.categoryId,
              this.props.support.supportGroupId,
              this.props.support,
            );
          }}
        >
          <ActionButton
            actionType={this._determineAccess()}
            labelStyle={'bottom'}
          />
        </StandardTouchableOpacity>
      );
    } else {
      return null;
    }
  };

  render() {
    let image =
      this.props.support.supportPhoto != null &&
      this.props.support.supportPhoto.url != null
        ? {uri: this.props.support.supportPhoto.url}
        : addPhotoPurple;
    let viewOrEdit = 'Edit';
    let supportingCrew = this.props.support.supportingCrew
      ? this.props.support.supportingCrew
      : [];
    let supportingOrgCrew = this.props.support.supportingOrgCrew
      ? this.props.support.supportingOrgCrew
      : [];
    let SupportCrew = [...supportingCrew, ...supportingOrgCrew];

    if (this.props.readOnly == 'read') {
      viewOrEdit = 'View';
    }
    return (
      // #TODO - Shelan - Remove disabled={types.isWeb ? true : false} prop on
      // StandardTouchableOpacity, once My Profile support features available
      <View style={[CommonStyles.budgetsTilesContainer]}>
        <StandardTouchableOpacity
          style={[
            CommonStyles.budgetsSupportTileWrapper,
            BrandStyles.primaryBgColor1,
          ]}
          disabled={types.isWeb ? true : false}
        >
          <View style={[CommonStyles.marginLeft5, CommonStyles.marginRight5]}>
            <View style={[CommonStyles.flex1, CommonStyles.flexDirectionRow]}>
              <Image
                style={[
                  CommonStyles.supportBudgetImage,
                  BrandStyles.borderColor,
                  CommonStyles.marginRight10,
                ]}
                source={image}
              />
              <View style={[CommonStyles.flex1]}>
                <StandardText
                  numberOfLines={2}
                  ellipsizeMode="tail"
                  style={[
                    CommonStyles.flex1,
                    CommonStyles.customFontBold,
                    CommonStyles.font20,
                    BrandStyles.TextColor5,
                  ]}
                >
                  {this.props.support.title}
                </StandardText>
                <SupportTileDetails
                  frequency={this.renderFrequency()}
                  opinion={this.props.support.opinion}
                  crew={SupportCrew}
                />
              </View>
            </View>
            <View>{this.renderEdit(viewOrEdit)}</View>
          </View>
        </StandardTouchableOpacity>
      </View>
    );
  }
}

export default SupportTileItem;
