/*
 * Author: Andrew Lee
 * Date: 16/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This screen is to give users a method to resetting their cognito password if they have forgotten it.
 */

import React, {Component} from 'react';
import {
  Animated,
  Dimensions,
  Image,
  StyleSheet,
  View,
  Text,
  Platform,
} from 'react-native';
// import DeviceInfo from 'react-native-device-info';
import {KeyboardAwareScrollView} from 'helpers/KeyboardAwareScrollView';
// Import components
import {BrandActivityIndicator} from '../Components/Molecules/BrandActivityIndicator';
import {
  focusOnView,
  announceForAccessibility,
  isTablet,
} from '../Helpers/PlatformSynchronizer';
import logger from 'helpers/Logger';

// Import Atoms
import {
  StandardInput,
  StandardButton,
  StandardText,
  BackButton,
  SecureStandardInput,
  StandardTouchableOpacity,
} from '../Components/Atoms';

// Import API calls
import {
  forgotCognitoPassword,
  confirmCognitoPassword,
} from '../API/AuthenticationAPI';
import {putTemporaryPassword} from '../API/UserAPI';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import * as brand from '../Constants/BrandConstants';

//Import Images
import {
  AvatarLyingDown,
  InformationIconx3,
  LILogoWhiteText,
} from '../assets/images';
import {RefreshIcon} from '../assets/images/vector-icons';
// Import messages
import {
  FORGOT_PASSWORD as forgotPwdMessages,
  MESSAGES as Messages,
  LOGIN_SIGNUP_SCREEN,
  isWeb,
} from '../Constants/Messages';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

import {APP_BRAND} from '../Constants/BrandConstants';
import {OfflineNotice, DeepLinkBanner} from '../Components/Organisms';
import {
  BasicOverlayPopupModal,
  Copyrights,
  FormSubtitle,
  LoginSignUpTitle,
} from '../Components/Molecules';

import {focusNextField} from '../Components/FocusNextField/FocusNextField';
import CustomIcon from '../assets/images/CustomIcon';
import {Logger} from 'aws-amplify';
// Get the device height & width
let ScreenHeight = Dimensions.get('window').height;
let ScreenWidth = Dimensions.get('window').width;

// Setup animations
const animationValue = new Animated.Value(0);

// Maps the animation values to start from the top of the screen out of view and finish at the bottom of the screen in view
const animateTopToBottom = animationValue.interpolate({
  inputRange: [0, 1],
  outputRange: [-ScreenHeight, 0.0],
});
// Maps the animation values to start from the bottom of the screen out of view and finish at the top of the screen in view
const animateBottomToTop = animationValue.interpolate({
  inputRange: [0, 1],
  outputRange: [ScreenHeight, 0.0],
});

const INPUTS = {
  SCROLL_VIEW: 'scrollView',
  EMAIL: 'email',
  PASSWORD: 'newPassword',
  CODE: 'tokenCode',
};

class ForgotPassword extends Component {
  goBackbtn: any;
  inputs: any;
  mainTitle: any;
  scroll: any;
  state = {
    loading: false,
    passwordChallenge: false,
    email: '',
    tokenCode: '',
    newPassword: '',
    errors: {},
    instructionModalVisible: false,
  };

  constructor(props: any) {
    super(props);
    this.inputs = {};
  }

  componentDidMount() {
    this.dispatchFocusInOrder();
    let email;
    if(types.isWeb) {
      email = this.props.navigation.getParam("email", undefined) ;
     }else {
      email = this.props?.route?.params ? this.props?.route?.params['email'] : undefined;
     }
     
    if (email) {
      this.setState({email, passwordChallenge: false});
    }

    if (!types.isWeb) {
      const isDeepLink = this.props?.route?.params ? this.props?.route?.params['deepLink'] : undefined;
      if (isDeepLink) this.setState({passwordChallenge: true});
    }

    if (types.isWeb) {
      const urlParams = new URLSearchParams(window.location.search);
      const email = urlParams.get('email');
      if (email) {
        this.setState({email, passwordChallenge: true});
      }
    }
  }

  dispatchFocusInOrder() {
    setTimeout(
      function (this: any) {
        const latestItem = [this.mainTitle, this.goBackbtn]
          .filter(item => item !== undefined && item != null)
          .pop();
        if (latestItem && !this.state.loading) {
          focusOnView(latestItem);
        }
      }.bind(this),
      100,
    );
  }

  _renderInputFields = () => {
    let inputFields = [];
    let emailDisplay = null;
    // There is a password challenge, so display the email that the user has entered, along with the input fields for the token code and new password.
    if (this.state.passwordChallenge) {
      emailDisplay = (
        <View style={[CommonStyles.listContentPasswordChallenge]}>
          <StandardText
            style={[
              CommonStyles.listContent_text,
              BrandStyles.TextColor2,
              CommonStyles.customFont,
            ]}
          >
            {forgotPwdMessages.ACCOUNT_INFO + this.state.email}
          </StandardText>
        </View>
      );
      // Build the input field for the token code
      inputFields.push([
        <View key="codeContainer" style={CommonStyles.textInputWrapper}>
          <FormSubtitle text={[forgotPwdMessages.CODE]} />
          <StandardInput
            applyWrapper={false}
            onRef={(ref: any) => {
              this.inputs[INPUTS.CODE] = ref;
            }}
            key="tokenCode"
            keyboardType="numeric"
            accessibilityLabel={forgotPwdMessages.CODE}
            value={this.state.tokenCode}
            onChangeText={(tokenCode: any) => this.setState({tokenCode})}
            onSubmitEditing={() => {
              focusNextField(this.inputs, INPUTS.SCROLL_VIEW, INPUTS.PASSWORD);
            }}
            returnKeyType={'next'}
            blurOnSubmit={false}
            style={[
              CommonStyles.LoginSignUpMB10,
              CommonStyles.ModalTextInputWrapper,
            ]}
            underlineColorAndroid="transparent"
            errorMessage={this.state.errors.tokenCode}
          />
        </View>,
      ]);

      // Build the input field for the new password
      inputFields.push([
        <View key="newPasswordContainer" style={CommonStyles.textInputWrapper}>
          <FormSubtitle text={[forgotPwdMessages.PASSWORD]} />
          <SecureStandardInput
            applyWrapper={false}
            key="newPassword"
            onRef={(ref: any) => {
              this.inputs['newPassword'] = ref;
            }}
            accessibilityLabel={forgotPwdMessages.PASSWORD}
            value={this.state.newPassword}
            onChangeText={(newPassword: any) => this.setState({newPassword})}
            onSubmitEditing={() =>
              types.isWeb ? this._submitEmailOrPassword() : null
            }
            blurOnSubmit={true}
            secureTextEntry={true}
            style={[
              CommonStyles.LoginSignUpMB10,
              CommonStyles.ModalTextInputWrapper,
            ]}
            autoCompleteType="new-password"
            underlineColorAndroid="transparent"
            errorMessage={this.state.errors.newPassword}
          />
        </View>,
      ]);
    }
    // There is no challenge, so need to just render the email input field
    else {
      emailDisplay = null;
      inputFields.push([
        <View key="emailContainer">
          <FormSubtitle text={[forgotPwdMessages.EMAIL]} />
          <StandardInput
            onFocus={() => {
              if (isTablet()) {
               // this.scroll.props.scrollToPosition(0, 280);
              }
            }}
            applyWrapper={false}
            onRef={(ref: any) => {
              this.inputs[INPUTS.EMAIL] = ref;
            }}
            key="email"
            keyboardType="email-address"
            accessibilityLabel={forgotPwdMessages.EMAIL}
            value={this.state.email}
            onChangeText={(email: any) => {
              const trimmedEmail = email.trim();
              this.setState({email: trimmedEmail});
            }}
            onSubmitEditing={() =>
              types.isWeb ? this._submitEmailOrPassword() : null
            }
            blurOnSubmit={true}
            returnKeyType={'next'}
            style={[
              CommonStyles.LoginSignUpMB10,
              CommonStyles.ModalTextInputWrapper,
            ]}
            underlineColorAndroid="transparent"
            errorMessage={this.state.errors.email}
          />
        </View>,
      ]);
    }

    return (
      <View style={[CommonStyles.textInputContainer]}>
        {emailDisplay}
        {inputFields}
      </View>
    );
  };

  _renderSubmitButton = () => {
    let buttonText = '';
    if (this.state.passwordChallenge) {
      buttonText = forgotPwdMessages.SUBMIT_BTN_PWD;
    }
    // There is no challenge currently
    else {
      buttonText = forgotPwdMessages.SUBMIT_BTN_EMAIL;
    }

    return (
      <StandardButton
        style={[
          BrandStyles.brandBlockTxtColor6,
          CommonStyles.button,
          CommonStyles.customFontBold,
        ]}
        containerStyle={[
          BrandStyles.primaryBgColor1,
          BrandStyles.brandBorderColor1,
          CommonStyles.buttonContainer,
        ]}
        onPress={this._submitEmailOrPassword.bind(this)}
      >
        {buttonText}
      </StandardButton>
    );
  };

  _submitEmailOrPassword = () => {
    if (this._validateFields()) {
      this.setState({loading: true});
      if (this.state.passwordChallenge) {
        let tokenCode = this.state.tokenCode;
        let password = this.state.newPassword;
        let username = this.state.email;

        confirmCognitoPassword(username, tokenCode, password)
          .then(() => {
            this.setState({
              popupTitle: forgotPwdMessages.SUCCESS_TITLE,
              popupMessage: forgotPwdMessages.SUCCESS_MESSAGE,
              instructionModalVisible: true,
              loading: false,
            });
          })
          .catch(err => {
            this.setState({
              popupTitle: forgotPwdMessages.VALIDATION_TITLE,
              popupMessage: forgotPwdMessages.CODE_VALIDATION_ERROR,
              instructionModalVisible: true,
              loading: false,
            });
          });
      }
      // No challenge yet, so the email is being submitted
      else {
        forgotCognitoPassword(this.state.email)
          .then(() => {
            // Successfully sent the reset command
            this.setState({
              popupTitle: forgotPwdMessages.RESET_ALERT_TITLE,
              popupMessage: forgotPwdMessages.RESET_ALERT_MESSAGE,
              loading: false,
              passwordChallenge: true,
              instructionModalVisible: true,
            });
          })
          .catch(err => {
            if (err.message === types2.USER_STATE_CONFIRM) {
              // User account is not activated yet
              // Regardless of whether the temporary password sent is expired or not send a new temp password
              putTemporaryPassword(this.state.email)
                .then(() => {
                  this.setState({
                    popupTitle: forgotPwdMessages.CHECK_INBOX_TITLE,
                    popupMessage:
                      forgotPwdMessages.TEMPORARY_PASSWORD_SENT_MESSAGE,
                    instructionModalVisible: true,
                    loading: false,
                  });
                })
                .catch(() => this.setState({loading: false}));
            } else {
              this.setState({
                popupTitle: forgotPwdMessages.VALIDATION_TITLE,
                popupMessage: forgotPwdMessages.INVALID_EMAIL,
                instructionModalVisible: true,
                loading: false,
              });
            }
          });
      }
    }
  };

  renderHeaderItems = () => {
    if (types.isWeb) {
      return (
        <View
          style={[
            CommonStyles.flex1,
            CommonStyles.justifyFlexRowSpace,
            CommonStyles.alignItemsCenter,
          ]}
        >
          <StandardTouchableOpacity
            activeOpacity={0.8}
            onPress={() => window.open(brand.LEAP_IN_URL)}
            accessibilityLabel={LOGIN_SIGNUP_SCREEN.RETURN_TO_LEAPIN}
            style={[CommonStyles.loginTileWrapper]}
          >
            <View
              style={[CommonStyles.flexDirectionRow, CommonStyles.flexWrap]}
            >
              <Image
                source={LILogoWhiteText}
                style={[CommonStyles.imageBrandLogoText]}
              />
            </View>
          </StandardTouchableOpacity>
          <StandardTouchableOpacity
            activeOpacity={0.8}
            onPress={() => window.open(brand.LEAP_IN_URL)}
            accessibilityLabel={LOGIN_SIGNUP_SCREEN.RETURN_TO_LEAPIN}
            style={[CommonStyles.loginTileWrapper]}
          >
            <View
              style={[CommonStyles.flexDirectionRow, CommonStyles.flexWrap]}
            >
              <CustomIcon
                style={[CommonStyles.headerItemIcon]}
                name={RefreshIcon}
              />
              <View
                style={[
                  CommonStyles.paddingLeft10,
                  CommonStyles.flexDirectionColumn,
                  CommonStyles.alignCenter,
                ]}
              >
                <StandardText
                  style={[
                    CommonStyles.font18,
                    CommonStyles.customFont,
                    BrandStyles.TextColor1,
                  ]}
                >
                  {LOGIN_SIGNUP_SCREEN.RETURN_TO_LEAPIN}
                </StandardText>
              </View>
            </View>
          </StandardTouchableOpacity>
        </View>
      );
    }
  };

  // backNavigation = () => {
  //   const enableBack = this.props.navigation.getParam('enableBack', undefined);
  //   if (enableBack && Platform.OS === types2.IOS) {
  //     return (
  //       <View
  //         style={[
  //           CommonStyles.flex1,
  //           CommonStyles.alignFlexStart,
  //           CommonStyles.justifyCenter,
  //         ]}
  //       >
  //         <View
  //           style={CommonStyles.alignBackButton}
  //           ref={ref => {
  //             this.goBackbtn = ref;
  //           }}
  //         >
  //           {this._renderBackButton()}
  //         </View>
  //       </View>
  //     );
  //   }
  // };

  _renderBackButton() {
    if (
      APP_BRAND == types2.APP_BRAND.LEAPIN ||
      APP_BRAND == types2.APP_BRAND.BLUE_CARE ||
      APP_BRAND == types2.APP_BRAND.UCN ||
      APP_BRAND == types2.APP_BRAND.YOORALLA
    ) {
      return (
        <BackButton
          isBlack={false}
          goBack={() => {
            this.props.navigation.goBack();
          }}
        />
      );
    } else {
      return (
        <BackButton
          isBlack={true}
          goBack={() => {
            this.props.navigation.goBack();
          }}
        />
      );
    }
  }

  render() {
    Animated.timing(animationValue, {
      toValue: 1,
      duration: 3000,
    }).start();

    return (
      <View
        accessibilityViewIsModal={true}
        style={[CommonStyles.screenWrapperContainer]}
      >
        {this._renderInstructionPopUp(isTablet())}
        <View style={[CommonStyles.flex1]}>
          {types.isWeb ? (
            <View
              style={[
                CommonStyles.flex1,
                CommonStyles.flexDirectionRow,
                CommonStyles.headerWrapper,
                types.isWeb
                  ? BrandStyles.ContainerBackgroundColor
                  : BrandStyles.LoginSignupTitleBg,
              ]}
            >
              {this.renderHeaderItems()}
            </View>
          ) : (
            <View
              style={[
                CommonStyles.alignItemsCenter,
                CommonStyles.zIndex102,
                CommonStyles.flexDirectionRow,
              ]}
            >
              <LoginSignUpTitle
                backButton={true}
                nav={this.props.navigation}
                screen={types2.SIGNUP_LOGIN_FLOW.WELCOME}
                goBack={() => {
                  this.props.navigation.goBack();
                }}
              />
            </View>
          )}
          <OfflineNotice />
          {types.isWeb && <DeepLinkBanner />}
          <KeyboardAwareScrollView
            extraScrollHeight={
              Platform.OS === types2.IOS && isTablet() ? 200 : null
            }
            ref={ref => (this.inputs[INPUTS.SCROLL_VIEW] = ref)}
            enableAutomaticScroll={Platform.OS === types2.IOS ? true : false}
            keyboardDismissMode="interactive"
            keyboardShouldPersistTaps="always"
            innerRef={ref => {
              this.scroll = ref;
            }}
            style={BrandStyles.primaryBgColor1}
            contentContainerStyle={CommonStyles.scrollView_container}
          >
            <View
              style={[CommonStyles.title, CommonStyles.alignItemsCenter]}
              accessible={true}
              ref={ref => {
                this.mainTitle = ref;
              }}
              accessibilityLabel={
                forgotPwdMessages.HEADER1 + ' ' + forgotPwdMessages.HEADER2
              }
            >
              <View
                style={[
                  CommonStyles.title_headerContainer,
                  BrandStyles.brandBorderColor,
                  BrandStyles.brandBlockBgColor6,
                ]}
              >
                <StandardText
                  style={[
                    CommonStyles.title_headerText,
                    BrandStyles.TextColor1,
                    CommonStyles.customFont,
                  ]}
                >
                  {forgotPwdMessages.HEADER1}
                </StandardText>
              </View>
              <View
                style={[
                  CommonStyles.title_subHeaderContainer,
                  BrandStyles.primaryBgColor1,
                  CommonStyles.viewWhiteBorder,
                  BrandStyles.borderColor4,
                ]}
              >
                <StandardText
                  style={[
                    CommonStyles.title_subHeaderText,
                    BrandStyles.brandBlockTxtColor6,
                    CommonStyles.customFont,
                  ]}
                >
                  {forgotPwdMessages.HEADER2}
                </StandardText>
              </View>
            </View>
            <View
              style={[
                CommonStyles.scrollView_subContentList,
                CommonStyles.alignItemsCenter,
              ]}
            >
              {!this.state.passwordChallenge ? (
                <>
                  <View
                    style={[CommonStyles.listContent]}
                    accessible={true}
                    accessibilityLabel={
                      forgotPwdMessages.INFO_ONE +
                      ' ' +
                      forgotPwdMessages.INFO_MESSAGE1
                    }
                  >
                    <StandardText
                      style={[
                        BrandStyles.brandBlockTxtColor6,
                        CommonStyles.listContent_textNumber,
                        CommonStyles.customFont,
                      ]}
                    >
                      {forgotPwdMessages.INFO_ONE}
                    </StandardText>
                    <StandardText
                      style={[
                        CommonStyles.listContent_text,
                        CommonStyles.customFont,
                      ]}
                    >
                      {forgotPwdMessages.INFO_MESSAGE1}
                    </StandardText>
                  </View>
                  <View
                    style={[CommonStyles.listContent]}
                    accessible={true}
                    accessibilityLabel={
                      forgotPwdMessages.INFO_TWO +
                      ' ' +
                      forgotPwdMessages.INFO_MESSAGE2
                    }
                  >
                    <StandardText
                      style={[
                        BrandStyles.brandBlockTxtColor6,
                        CommonStyles.listContent_textNumber,
                        CommonStyles.customFont,
                      ]}
                    >
                      {forgotPwdMessages.INFO_TWO}
                    </StandardText>
                    <StandardText
                      style={[
                        CommonStyles.listContent_text,
                        CommonStyles.customFont,
                      ]}
                    >
                      {forgotPwdMessages.INFO_MESSAGE2}
                    </StandardText>
                  </View>
                  <View
                    style={[CommonStyles.listContent]}
                    accessible={true}
                    accessibilityLabel={
                      forgotPwdMessages.INFO_THREE +
                      ' ' +
                      forgotPwdMessages.INFO_MESSAGE3
                    }
                  >
                    <StandardText
                      style={[
                        BrandStyles.brandBlockTxtColor6,
                        CommonStyles.listContent_textNumber,
                        CommonStyles.customFont,
                      ]}
                    >
                      {forgotPwdMessages.INFO_THREE}
                    </StandardText>
                    <StandardText
                      style={[
                        CommonStyles.listContent_text,
                        CommonStyles.customFont,
                      ]}
                    >
                      {forgotPwdMessages.INFO_MESSAGE3}
                    </StandardText>
                  </View>
                </>
              ) : (
                <View
                  style={[CommonStyles.listContent]}
                  accessible={true}
                  accessibilityLabel={
                    forgotPwdMessages.INFO_ONE +
                    ' ' +
                    forgotPwdMessages.INFO_MESSAGE4
                  }
                >
                  <StandardText
                    style={[
                      BrandStyles.brandBlockTxtColor6,
                      CommonStyles.listContent_textNumber,
                      CommonStyles.customFont,
                    ]}
                  >
                    {forgotPwdMessages.INFO_ONE}
                  </StandardText>
                  <StandardText
                    style={[
                      CommonStyles.listContent_text,
                      CommonStyles.customFont,
                    ]}
                  >
                    {forgotPwdMessages.INFO_MESSAGE4}
                  </StandardText>
                </View>
              )}
            </View>
            <View style={[CommonStyles.scrollView_subContent]}>
              {this._renderInputFields()}
            </View>
            <View
              style={[
                CommonStyles.scrollView_subContent,
                CommonStyles.scrollView_subContentCenter,
              ]}
            >
              {this._renderSubmitButton()}
            </View>
            <View style={[CommonStyles.imageContainerPasswordReset]}>
              <Image source={AvatarLyingDown} style={CommonStyles.image} />
            </View>
          </KeyboardAwareScrollView>
        </View>
        {!types.isWeb && (
          <View
            style={[
              CommonStyles.alignCenter,
              BrandStyles.copyrightsBarBgColor,
              CommonStyles.loginButtonWrapper,
            ]}
          >
            <Copyrights />
          </View>
        )}
        <BrandActivityIndicator enableWeb={true} loading={this.state.loading} />
      </View>
    );
  }

  _determinePopUpStyle = (isTablet: any) => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      styles.push([CommonStyles.containerInstructionPopUpWeb]);

      if (this.props.isMobile) {
        styles.push([CommonStyles.containerPopupWebMobile]);
      }
    } else if (isTablet) {
      styles.push(CommonStyles.containerInstructionPopUpTablet);
    } else {
      styles.push(CommonStyles.containerInstructionPopUpMobile);
    }
    return styles;
  };

  _closePlanManagedModal = () => {
    this.setState({instructionModalVisible: false});
    if (
      this.state.popupMessage === forgotPwdMessages.SUCCESS_MESSAGE ||
      this.state.popupMessage ===
        forgotPwdMessages.TEMPORARY_PASSWORD_SENT_MESSAGE
    ) {
      this.props.navigation.goBack(null);
    }
  };

  _renderInstructionPopUp = (isTablet: any) => {
    return (
      <BasicOverlayPopupModal
        visible={this.state.instructionModalVisible}
        style={this._determinePopUpStyle(isTablet)}
        backdropOpacity={0.2}
        divider={false}
        close={this._closePlanManagedModal}
        cancelContainerStyle={CommonStyles.popUpOkButton}
        cancelStyle={[CommonStyles.buttonPopupOk, CommonStyles.customFontBold]}
        cancelLabel={types2.OK_GOT_IT}
      >
        <View
          style={[
            CommonStyles.alignItemsCenter,
            CommonStyles.justifyContentSpaceBetween,
          ]}
        >
          <View
            style={[
              CommonStyles.flexDirectionRow,
              CommonStyles.alignItemsCenter,
              CommonStyles.marginBottom20,
            ]}
          >
            <View>
              <Image
                style={[CommonStyles.changePasswordButtonImage]}
                source={InformationIconx3}
                accessibilityLabel={
                  Messages.ACCESSIBILITY.IMPORTANT_INFORMATION
                }
                accessible={true}
              />
            </View>
            <View>
              <StandardText
                style={[
                  isTablet ? CommonStyles.font23 : CommonStyles.font18,
                  BrandStyles.TextColor5,
                  CommonStyles.customFont,
                ]}
              >
                {this.state.popupTitle}
              </StandardText>
            </View>
          </View>
          <StandardText
            style={[
              isTablet ? CommonStyles.font18 : CommonStyles.rpfont15,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
              CommonStyles.paddingBottom20,
            ]}
          >
            {this.state.popupMessage}
          </StandardText>
        </View>
        <View
          style={[
            CommonStyles.containerInstructionDevider,
            CommonStyles.marginTop20,
          ]}
        />
      </BasicOverlayPopupModal>
    );
  };

  // Checks if the fields are valid
  _validateFields = () => {
    // Need to validate the token code and the password if the password challenge is there
    let validationErrors = {
      tokenCode: '',
      newPassword: '',
      email: '',
    };
    if (this.state.passwordChallenge) {
      let tokenError = this._validateToken();
      if (tokenError) {
        validationErrors.tokenCode = tokenError;
      }

      let passwordError = this._validatePassword();
      if (passwordError) {
        validationErrors.newPassword = passwordError;
      }
    } else {
      let emailError = this._validateEmail();
      if (emailError) {
        validationErrors.email = emailError;
      }
    }

    let fieldsValid = true;
    var readThisText =
      validationErrors.email +
      ' ' +
      validationErrors.tokenCode +
      ' ' +
      validationErrors.newPassword;
    for (let [key, value] of Object.entries(validationErrors)) {
      if (value != '') {
        announceForAccessibility(readThisText);
        fieldsValid = false;
        this.setState({errors: validationErrors});
      }
    }
    return fieldsValid;
  };

  //Checks if a Valid Email Address has been entered
  _validateEmail = () => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(this.state.email)) {
      return '';
    } else {
      return types.EMAIL_INVALID_FORMAT;
    }
  };

  //Checks if Password field has input
  _validatePassword = () => {
    var re = /.{8,}/gim;
    if (re.test(this.state.newPassword)) {
      // Ensure there is no whitespace in the password
      if (this.state.newPassword.indexOf(' ') >= 0) {
        return types.PASSWORD_CONTAINS_SPACES;
      }
      return '';
    } else {
      return types.PASSWORD_INVALID_LENGTH;
    }
  };

  // Checks if the token is valid
  _validateToken = () => {
    let token = this.state.tokenCode;
    let tokenInt = parseInt(token);
    if (token.length != 6) {
      return types.TOKEN_LENGTH;
    }

    if (typeof tokenInt !== 'number') {
      return types.TOKEN_TYPE;
    }

    return '';
  };
}

export default ForgotPassword;
