/*
 * Author: Anuja Ulpathakumbura
 * Date: 16/08/2018
 *
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Logout confirmation modal
 */

import React, {Component} from 'react';
import {View, Text, Image} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// import Orientation from 'react-native-orientation-enhanced';
import {isTablet, focusOnView} from '../../Helpers/PlatformSynchronizer';
import {StandardButton, StandardText} from '../Atoms';
import BasicOverlayPopupModal from './BasicOverlayPopupModal';

import {LOGOUT_CONFIRMATION_MODAL} from '../../Constants/Messages';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import * as LogoutConfirmationActions from '../../Actions/LogoutActions';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {InformationIconx3} from '../../assets/images';
import {MESSAGES} from '../../Constants/Messages';
import {removeItem} from '../../API/WebPersistenceStore';
import {getStyleByOrientation} from '../../Helpers/GetStyleByOrientation';

import {getData} from '../../API/StorageHelper';
import {
  logoutFacebook,
  logoutGoogle,
  logoutCognito,
  userLogOut,
} from '../../API/AuthenticationAPI';

class LogoutConfirmModal extends Component {
  state = {
    instructionModalVisible: false,
    orientation: '',
    showConfirmLogout: false,
  };

  constructor(props: any) {
    super(props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.logout) {
      this.setState({
        showConfirmLogout: nextProps.logout.showConfirmLogout,
      });
    }
  }

  // UNSAFE_componentWillMount() {
  //   Orientation.getOrientation((err, orientation) => {
  //     this.setState({
  //       orientation: orientation
  //     });
  //   });
  // }

  // componentDidMount() {
  //   Orientation.addOrientationListener(this._orientationDidChange);
  // }

  // componentWillUnmount() {
  //   Orientation.removeOrientationListener(this._orientationDidChange);
  // }

  // _orientationDidChange = orientation => {
  //   this.setState({
  //     orientation: orientation
  //   });
  // };

  render() {
    return (
      <BasicOverlayPopupModal
        visible={this.state.showConfirmLogout}
        style={[this._determinePopUpStyle(isTablet)]}
        backdropOpacity={0.2}
        divider={false}
        close={() => this._closeNavigationConfirmModal(false)}
        hideCloseButton={true}
        cancelContainerStyle={CommonStyles.popUpOkButton}
        cancelStyle={[CommonStyles.buttonPopupOk, CommonStyles.customFontBold]}
        cancelLabel={types2.OK_GOT_IT}
      >
        <View>{this.getPopUpContent(isTablet)}</View>
      </BasicOverlayPopupModal>
    );
  }

  _determinePopUpStyle = (isTablet: any) => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      styles.push([CommonStyles.containerInstructionPopUpWeb]);

      if (this.props.isMobile) {
        styles.push([CommonStyles.containerPopupWebMobile]);
      }
    } else if (isTablet()) {
      styles.push([
        CommonStyles.containerInstructionPopUpTablet,
        CommonStyles.minHeight300,
      ]);
    } else {
      styles.push([
        CommonStyles.containerInstructionPopUpMobile,
        CommonStyles.minHeight230,
      ]);
    }
    return styles;
  };

  getPopUpContent(isTablet: any) {
    let fontSize = CommonStyles.rpfont15;
    let headerFontSize = CommonStyles.font20;
    if (types.isWeb) {
      fontSize = CommonStyles.font18;
      headerFontSize = CommonStyles.font18;
    } else if (isTablet()) {
      fontSize = CommonStyles.font18;
      headerFontSize = CommonStyles.font25;
    }

    return (
      <View>
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.scrollView_subContentCenter,
            types.isWeb
              ? CommonStyles.marginBottom48
              : CommonStyles.paddingBottom5p,
            CommonStyles.marginLeft20,
            CommonStyles.marginRight20,
          ]}
        >
          <View>
            <Image
              style={[CommonStyles.changePasswordButtonImage]}
              source={InformationIconx3}
              accessibilityLabel={MESSAGES.ACCESSIBILITY.IMPORTANT_INFORMATION}
              accessible={true}
            />
          </View>
          <View
            accessible={true}
            ref={ref => {
              if (ref && !types.isWeb) {
                focusOnView(ref);
              }
            }}
            style={[CommonStyles.scrollView_subContentCenter]}
          >
            <StandardText
              style={[
                headerFontSize,
                BrandStyles.TextColor10,
                CommonStyles.customFontBold,
              ]}
            >
              {this.props.user.demoProfile
                ? LOGOUT_CONFIRMATION_MODAL.EXIT
                : LOGOUT_CONFIRMATION_MODAL.LOGOUT}
            </StandardText>
          </View>
        </View>
        <View
          style={[
            types.isWeb ? null : CommonStyles.paddingTop5,
            CommonStyles.scrollView_subContentCenter,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFont,
              BrandStyles.TextColor5,
              fontSize,
              CommonStyles.customFont,
            ]}
          >
            {this.props.user.demoProfile
              ? LOGOUT_CONFIRMATION_MODAL.EXIT_CONFIRMATION_MESSAGE
              : LOGOUT_CONFIRMATION_MODAL.LOGOUT_CONFIRMATION_MESSAGE}
          </StandardText>
          {this.props.user.demoProfile && this.renderSubText()}
        </View>
        <View
          style={[
            CommonStyles.containerInstructionDevider,
            CommonStyles.paddingBottom5p,
          ]}
        />
        <View style={[CommonStyles.ModalActionButtons]}>
          <View
            style={[
              types.isWeb
                ? CommonStyles.ModalFooterCenter
                : CommonStyles.ModalFooterLeft,
            ]}
          >
            <StandardButton
              onPress={() => this._closeNavigationConfirmModal(false)}
              style={[
                BrandStyles.brandBlockTxtColor6,
                CommonStyles.buttonFormAction,
                CommonStyles.customFontBold,
              ]}
              containerStyle={[
                BrandStyles.primaryBgColor1,
                BrandStyles.brandBorderColor1,
                CommonStyles.buttonContainerFormAction,
              ]}
              accessibilityLabel={
                LOGOUT_CONFIRMATION_MODAL.LOGOUT_CONFIRMATION_CANCEL
              }
            >
              {LOGOUT_CONFIRMATION_MODAL.LOGOUT_CONFIRMATION_CANCEL}
            </StandardButton>
          </View>
          <View
            style={[
              types.isWeb
                ? CommonStyles.ModalFooterCenter
                : CommonStyles.ModalFooterRight,
            ]}
          >
            <StandardButton
              onPress={() => this._closeNavigationConfirmModal(true)}
              style={[
                BrandStyles.brandBlockTxtColor5,
                CommonStyles.buttonFormAction,
                CommonStyles.customFontBold,
              ]}
              containerStyle={[
                BrandStyles.primaryBgColor4,
                BrandStyles.brandBorderColor1,
                CommonStyles.buttonContainerFormAction,
              ]}
              accessibilityLabel={
                LOGOUT_CONFIRMATION_MODAL.LOGOUT_CONFIRMATION_OK
              }
            >
              {LOGOUT_CONFIRMATION_MODAL.LOGOUT_CONFIRMATION_OK}
            </StandardButton>
          </View>
        </View>
      </View>
    );
  }

  _closeNavigationConfirmModal = (logUserOut: any) => {
    if(logUserOut){
    removeItem('cognitoId');
    removeItem('awsIdentity');
    userLogOut();
    this.props.navigation.navigate('Welcome');
    }
    
    return this.props.actions.LogoutConfirmationActions.hideConfirmLogout({
      logUserOut: logUserOut,
    });
  };

  renderSubText() {
    return (
      <StandardText
        style={[
          CommonStyles.customFont,
          BrandStyles.TextColor5,
          CommonStyles.rpfont15,
          CommonStyles.customFont,
          CommonStyles.paddingTop10,
        ]}
      >
        {LOGOUT_CONFIRMATION_MODAL.EXIT_CONFIRMATION_SUB_MESSAGE}
      </StandardText>
    );
  }
}

const mapStateToProps = (state: any) => ({
  logout: state.LogoutReducer,
  member: state.MemberReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LogoutConfirmModal);
