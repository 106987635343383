/*
 * Authour: Shelan Cooray
 * Date: 13/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The form to select your life stage
 */

import React, {Component} from 'react';
import {View} from 'react-native';
import {StandardText} from '../Components/Atoms';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {MESSAGES} from '../Constants/Messages';

import {BasicForm} from '../Components/Organisms';
import {
  Container,
  FormTitle,
  FormSubtitle,
  RadioButton,
} from '../Components/Molecules';

import {callAPIs} from '../API/APICaller';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {LifeStagePurple3x} from '../assets/images/vector-icons';

import * as MemberActions from '../Actions/MemberActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {updateMember, getMember} from '../API/MemberAPI';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import DeviceInfo from 'react-native-device-info';
// import Orientation from 'react-native-orientation-enhanced';
import logger from 'helpers/Logger';
import {getLifeStages} from '../API/ReferenceAPI';
import {isTablet} from '../Helpers/PlatformSynchronizer';

class AboutMeLifeStageForm extends Component {
  state = {
    title: '',
    data: [],
    callback: '',
    selectedOptions: [],
    initialSelectedOptions: [],
    loading: false,
    orientation: '',
    isOpen: !types.isWeb ? this.props.screenProps?.getInitialMenuState() : false,
    refreshing: false,
  };

  constructor(props: any) {
    super(props);
    this.cancelModal = this.cancelModal.bind(this);
    this.isOptionSelected = this.isOptionSelected.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.determineIndex = this.determineIndex.bind(this);
    this.save = this.save.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      title: types2.LIFE_STAGES.DESCRIPTION,
      type: null,
      loading: true,
    });

    // if (!types.isWeb) {
    //   this.setState({
    //     orientation: Orientation.getInitialOrientation(),
    //   });
    // }

    callAPIs(
      [getLifeStages()],
      (data: any) => {
        this.setState({
          data: data[0].content.lifeStages,
          loading: false,
        });
      },
      null,
      () => {
        this.setState({loading: false});
      },
    );
  }

  fetchData = () => {
    this.setState({refreshing: true});

    const callbackFunction = (data: any) => {
      const member = data[0].content;
      const lifeStageData = data[1].content;
      this.props.actions.MemberActions.actionGetMember(
        member,
        this.props.user.id,
        this.props.user.isEmployee,
        this.props.user.isFinancialStaff,
      );
      this.setState({
        selectedLifeStages: member.lifeStages,
        selectedOptions: JSON.parse(
          JSON.stringify(
            member.lifeStages.map((selection: any) => {
              return {
                id: selection.lifeStageId,
                value: selection.lifeStageDescription,
              };
            }),
          ),
        ),
        data: lifeStageData.lifeStages,
        loading: false,
        refreshing: false,
      });
    };

    callAPIs(
      [
        getMember(
          this.props.loadedMemberId.loadedMemberId,
          this.props.user.demoProfile,
        ),
        getLifeStages(),
      ],
      callbackFunction,
      null,
      () => {
        this.setState({loading: false, refreshing: false});
      },
    );
  };

  componentDidMount() {
    // if (!types.isWeb) {
    //   Orientation.addOrientationListener(this._orientationDidChange);
    // }

    this.setState({
      selectedOptions: JSON.parse(
        JSON.stringify(
          this.props.member.lifeStages.map((selection: any) => {
            return {
              id: selection.lifeStageId,
              value: selection.lifeStageDescription,
            };
          }),
        ),
      ),
      initialSelectedOptions: JSON.parse(
        JSON.stringify(
          this.props.member.lifeStages.map((selection: any) => {
            return {
              id: selection.lifeStageId,
              value: selection.lifeStageDescription,
            };
          }),
        ),
      ),
    });
  }
  componentWillUnmount() {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (!types.isWeb) {
      this.setState({
        isOpen: this.props.screenProps?.getInitialMenuState() || false,
      });
    }

    if (this.props.member.lifeStages !== nextProps.member.lifeStages) {
      this.setState({
        selectedLifeStages: JSON.parse(
          JSON.stringify(nextProps.member.lifeStages),
        ),
      });
    }
  }

  _hasFormContentChanged = () => {
    return (
      JSON.stringify(this.state.selectedOptions) !==
      JSON.stringify(this.state.initialSelectedOptions)
    );
  };

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation,
    });
  };

  cancelModal() {
    this.props.navigation.goBack(null);
  }

  save() {
    this._saveLifestageModal(this.state.type, this.state.selectedOptions);
  }

  _saveLifestageModal(type: any, selectedItems: any) {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({loading: true});
    const parsedLifestages = selectedItems.map((selection: any) => {
      return {
        lifeStageId: selection.id,
        lifeStageDescription: selection.value,
      };
    });
    this.setState({
      selectedLifeStages: parsedLifestages,
    });
    const lifestagesIds = parsedLifestages.map(
      (lifeStage: any) => lifeStage.lifeStageId,
    );
    const memberUpdateObject = {
      id: this.props.member.id,
      lifeStages: lifestagesIds,
    };
    const updateMemberCallback = (data: any) => {
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({loading: false});
      this.props.actions.MemberActions.actionUpdateMember({
        lifeStages: data[0].content.lifeStages,
      });
      this.props.navigation.goBack(null);
    };
    const updateMemberErrorCallback = (err: any) => {
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({loading: false});
      logger.log('updateMemberErrorCallback error: ', err);
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
        loading: false,
      });
    };
    callAPIs(
      [updateMember(memberUpdateObject, this.props.user.demoProfile, false)],
      updateMemberCallback,
      updateMemberErrorCallback,
    );
    // this._lifestageSaveButton()
  }

  determineSelected(option: any) {
    const selectedOptions = this.state.selectedOptions;

    // Adding one will have the option as {key: , description: }
    if (
      selectedOptions.filter(o => o.value === option.description).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Determine the index of an option in the selected options array
   * Returns -1 if not found
   * @param {object} option The option you want to find the index of.
   */
  determineIndex(option: any) {
    const selectedOptions = this.state.selectedOptions;
    let index = -1;
    selectedOptions.filter((o, idx, array) => {
      if (o.value === option.description) {
        index = idx;
        return true;
      } else {
        return false;
      }
    });
    return index;
  }

  _renderItemsOnGrid(items: any) {
    let maxColumns = types.DISPLAY_MAX_SUB_ITEMS_PHONE_PORTRAIT;
    if (isTablet && !types.isWeb) {
      if (this.state.orientation === types2.ORIENTATION.LANDSCAPE) {
        maxColumns = types.DISPLAY_MAX_SUB_ITEMS_TAB_LANDSCAPE;
      } else {
        maxColumns = types.DISPLAY_MAX_SUB_ITEMS_TAB_PORTRAIT;
      }
      maxColumns -= this.state.isOpen == true ? 1 : 0;
    }

    if (types.isWeb && !this.props.isMobile) {
      maxColumns = types.DISPLAY_MAX_SUB_ITEMS_TAB_PORTRAIT;
    }

    var rows = [];
    var i = 0;
    while (i < items.length) {
      rows.push(
        <View style={CommonStyles.flexDirectionRow}>
          {this._renderRow(i, maxColumns, items)}
        </View>,
      );
      i = parseInt(i) + parseInt(maxColumns);
    }
    return rows;
  }

  _renderRow(i: any, maxColumns: any, items: any) {
    var columnstyle = CommonStyles.oneContainer;
    var flexstyle = CommonStyles.flex1;
    if (maxColumns == 3) {
      columnstyle = CommonStyles.oneThirdContainer;
      flexstyle = CommonStyles.flex030;
    } else if (maxColumns == 2) {
      columnstyle = CommonStyles.oneTwoContainer;
      flexstyle = CommonStyles.flex045;
    }
    var columns = [];
    for (var j = 0; j < maxColumns; j++) {
      var option = items[parseInt(i) + parseInt(j)];
      if (option != undefined) {
        columns.push(
          <View
            style={[
              columnstyle,
              CommonStyles.flexDirectionColumn,
              CommonStyles.justifyContentCentre,
            ]}
          >
            <View>
              <RadioButton
                id={option.id}
                value={option.description}
                isSelected={this.determineSelected(option)}
                style={[CommonStyles.radioButtonLS_DividerStyleMultiselect]}
                buttonStyle={
                  types.isWeb
                    ? CommonStyles.radioButtonPMSmall
                    : CommonStyles.image_40_40
                }
                onChange={this.selectOption}
                readOnly={this.props.member.access.profile}
                disabled={
                  this.props.member.access.profile !== types.ACCESS_FULL
                    ? true
                    : false
                }
              >
                <View
                  style={[CommonStyles.radioButtonLSSmall_ContainerMultiselect]}
                >
                  <View>
                    <StandardText
                      style={[
                        types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
                        BrandStyles.TextColor5,
                        CommonStyles.customFont,
                      ]}
                    >
                      {option.description}
                    </StandardText>
                  </View>
                </View>
              </RadioButton>
            </View>
          </View>,
        );
      }
    }

    return columns;
  }

  isOptionSelected(key: any) {
    return this.state.selectedOptions.includes(key);
  }

  selectOption(option: any) {
    let selectedOptions = this.state.selectedOptions;
    // Update the option to include key value name-value to work with AddSupportDetails screen
    option.value = option.description;

    if (!this.determineSelected(option)) {
      selectedOptions.push(option);
    } else if (this.determineSelected(option)) {
      selectedOptions.splice(this.determineIndex(option), 1);
    }
    this.setState({
      selectedOptions: selectedOptions,
    });
  }

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.LIFE_STAGES}
        hasFormContentChanged={this._hasFormContentChanged}
        currentScreenName={AboutMeLifeStageForm.name}
        onRefresh={this.fetchData}
        refreshing={this.state.refreshing}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    return (
      <BasicForm
        headerDisplayString={types2.LIFE_STAGES.HEADER_TITLE}
        readOnly={this.props.member.access.profile}
        headerIconImage={LifeStagePurple3x}
        disableMore={true}
        close={() => this.cancelModal()}
        save={() => this.save()}
        buttonsPositionTop={true}
        hideActionButton={true}
        contentWidthFull={true}
      >
        <View style={[CommonStyles.flex1]}>
          <FormTitle
            containerStyles={[
              types.isWeb ? CommonStyles.maxWidth100Percentage : null,
            ]}
            text={this.state.title}
            containsData={this.state.selectedOptions}
          />
          <FormSubtitle text={['Please select all that apply.']} />
          {this._renderItemsOnGrid(this.state.data)}
        </View>
      </BasicForm>
    );
  };
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  sideMenu: state.SideMenuReducer,
  loadedMemberId: state.LoadedMemberReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    MemberActions: bindActionCreators(MemberActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AboutMeLifeStageForm);
