/*
 * Author: Shelan Cooray
 * Date: 08/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the Title text for Forms.
 */

import React, {Component} from 'react';
import {Image, View} from 'react-native';

import {StandardText} from '../Atoms';
import {ActionButton} from './';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {MESSAGES} from '../../Constants/Messages';

import {MissingInformation3X, InformationIconx3} from '../../assets/images';

import CustomIcon from '../../assets/images/CustomIcon';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

class FormTitle extends Component {
  constructor(props: any) {
    super(props);
  }

  _renderTextStyles = () => {
    let styles = [
      this.props.actionButtonLeft ? null : CommonStyles.formTitleText,
      CommonStyles.customFontSemiBold,
      CommonStyles.paddingRight10,
      this.props.style,
    ];

    if (this.props.textColor) {
      styles.push(this.props.textColor);
    } else {
      styles.push(BrandStyles.TextColor5);
    }
    return styles;
  };

  _renderTextColor = () => {
    let styles = [];

    if (this.props.textColor) {
      styles.push(this.props.textColor);
    } else {
      styles.push(BrandStyles.TextColor5);
    }
    return styles;
  };

  _renderTextContainerStyle = () => {
    if (this.props.containerStyles) {
      return this.props.containerStyles;
    } else {
      if (this.props.renderNotification) {
        return CommonStyles.paddingLeft10;
      } else if (this.props.renderGoalFilters) {
        return CommonStyles.flex1;
      } else {
        return null;
      }
    }
  };

  _renderImage = (imageSource: any) => {
    let styles = [];
    if (this.props.iconStyle) {
      styles = this.props.iconStyle;
    } else {
      styles = [CommonStyles.titleBoxIcon, CommonStyles.titleBoxIconMargin];
    }

    if (this.props.imageSource !== undefined) {
      return (
        <CustomIcon
          accessible={false}
          style={[styles, this._renderTextColor()]}
          name={imageSource}
        />
      );
    } else {
      return null;
    }
  };

  _checkDataAvailability = (data: any) => {
    if (this.props.disableYellowStar) {
      return false;
    }
    if (!data || (Array.isArray(data) && data.length === 0) || data === '') {
      return true;
    }
  };

  _renderYellowStar = (data: any) => {
    if (this._checkDataAvailability(data)) {
      return (
        <Image
          style={[CommonStyles.yellowStar]}
          source={
            this.props.hasError ? InformationIconx3 : MissingInformation3X
          }
        />
      );
    }
  };

  _renderActionButton = () => {
    if (this.props.showButton) {
      return (
        <View style={this._renderCustomActionButtonStyle()}>
          <ActionButton
            imageActionIcon={this.props.imageActionIcon}
            actionType={this.props.actionType}
            showButtonTitle={this.props.showButtonTitle}
            accessibilityLabel={this.props.actionButtonAccessibilityLabel}
            showIconString={this.props.showIconString}
            isToucable={true}
            onPress={this.props.onPress}
          />
        </View>
      );
    } else {
      return null;
    }
  };

  _renderCustomActionButtonStyle = () => {
    let styles = [
      this.props.actionButtonLeft ? null : CommonStyles.RightStrict,
    ];
    if (this.props.customActionButtonStyle !== undefined) {
      styles.push(this.props.customActionButtonStyle);
    }
    return styles;
  };

  _renderStartWithText = () => {
    return (
      <View
        style={[
          CommonStyles.formTitleContainer,
          CommonStyles.formTitlePadding,
          this.props.borderStyle ? this.props.borderStyle : null,
        ]}
      >
        {this.props.renderNotification ? this.props.renderNotification() : null}
        {this._renderImage(this.props.imageSource)}
        <View
          style={[
            this._renderTextContainerStyle(),
            this.props.actionButtonLeft
              ? [CommonStyles.flex1, CommonStyles.flexDirectionRow]
              : null,
          ]}
        >
          <StandardText style={this._renderTextStyles()}>
            {this.props.text}
          </StandardText>
          {this.props.actionButtonLeft ? this._renderActionButton() : null}
        </View>
        {this._renderYellowStar(this.props.containsData)}
        {this.props.actionButtonLeft ? null : this._renderActionButton()}
      </View>
    );
  };

  _actionButtonAligment = () => {
    let styles = [];
    if (this.props.actionButtonLeft) {
      styles.push(CommonStyles.flexDirectionColumn, CommonStyles.flexWrap);
    }
    if (this.props.starLeft) {
      styles.push(CommonStyles.flex07);
    }
    return styles;
  };

  _renderStartWithSpace = () => {
    return (
      <View
        style={[
          CommonStyles.formTitleContainer,
          CommonStyles.formTitlePadding,
          this.props.borderStyle ? this.props.borderStyle : null,
        ]}
      >
        {this.props.renderNotification ? this.props.renderNotification() : null}
        {this._renderImage(this.props.imageSource)}
        <View
          style={[
            this._renderTextContainerStyle(),
            this.props.actionButtonLeft
              ? [CommonStyles.flex1, CommonStyles.flexDirectionRow]
              : null,
          ]}
        >
          <View style={this._actionButtonAligment()}>
            <StandardText style={this._renderTextStyles()}>
              {this.props.text}
            </StandardText>
          </View>
          {this.props.actionButtonLeft ? this._renderActionButton() : null}
          <View
            style={
              this.props.actionButtonLeft
                ? CommonStyles.flexDirectionColumn
                : null
            }
          >
            {this._renderYellowStar(this.props.containsData)}
          </View>
          {this.props.actionButtonLeft ? null : this._renderActionButton()}
        </View>
      </View>
    );
  };

  render() {
    return (
      <View>
        {this.props.actionButtonLeft
          ? this._renderStartWithSpace()
          : this._renderStartWithText()}
      </View>
    );
  }
}

FormTitle.defaultProps = {
  hasError: false,
};

export default FormTitle;
