/*
 * Author: Andrew Lee
 * Date: 04/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a simple overlay component that can be applied to other components.
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import CommonStyles from '../../Styles/CommonStyles';
import {BrandColors} from '../../Styles/Colours';

class Overlay extends Component {
  render() {
    let bgColour = BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL;
    if (this.props.backgroundColor) {
      bgColour = this.props.backgroundColor;
    }

    return (
      <View
        style={[
          CommonStyles.fullscreenOverlay,
          this.props.style,
          {backgroundColor: bgColour},
        ]}
      />
    );
  }
}

export default Overlay;
