/*
 * Author: Andrew Seeley
 * Date: 20/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Reachability reducer
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function ReachabilityReducer(state = [], action: any) {
  switch (action.type) {
    case types.CHANGE_CONNECTION_STATUS:
      return {
        isConnected: action.isconnected,
      };
    default:
      return state;
  }
}
