/*
 * Authour: Andrew Lee
 * Date: 12/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This will be the component to allow the member / crew to select a profile photo to be uploaded to their account. It will also display the profile photo that is passed into it.
 * @param {object} imageObject The object that contains the image URL, key and last modified date.
 * @param {string} apiFileType The API file type to upload. Must match an enum in swagger.
 * @param {object} userType The type of person picture that is being uploaded, i.e. member or crew.
 * @param {number} crewId The crew id who is being displayed on the profile photo.
 * @param {function} uploadCallback Optional - provides a way to callback once the image has been selected. Will send the response for the image selection to the image component
 * @param {function} actionCallback Optional - If you want to call an function in the parent component instead of selecting an image. Will override selecting an image functionality
 * @param {object} actionData Optional - Any data you may want to pass back to the parent component when you use @actionCallback.
 */

// Import the react native components
import React, {Component} from 'react';
import {StyleSheet, View, Image, Platform, Text} from 'react-native';
import {StandardTouchableOpacity, StandardText} from '../Atoms';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {connect} from 'react-redux';
// import * as ImagePicker from 'react-native-image-picker';
import {uploadImageToS3} from '../../Helpers/ImageHelper';
import { launchImageLibrary, ImageLibraryOptions } from 'helpers/ImagePicker'

//Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import * as ImageCache from '../../API/ImageCache';
import {callAPIs} from '../../API/APICaller';
import {BrandActivityIndicator} from './';
import {
  FILE_TYPE,
  PHOTOTILEGAP,
  PHOTOBORDERGAP,
} from '../../Constants/Constants2';

// Import the placeholder image
import {PlaceholderGrey, addPhotoPurple} from '../../assets/images';
// Import the upload URL API
import {getUploadURL} from '../../API/MemberAPI';
import logger from 'helpers/Logger';
import * as mime from 'react-native-mime-types-enhanced';

class PhotoTile extends Component {
  state = {
    imagePath: addPhotoPurple,
    loading: false,
  };

  constructor(props: any) {
    super(props);
    this.uploadImage.bind(this);
  }

  UNSAFE_componentWillMount() {
    // Set the placeholder image based on the user type
    if (
      this.props.apiFileType == FILE_TYPE.SUPPORT_PHOTO ||
      this.props.apiFileType == FILE_TYPE.GOAL_PHOTO ||
      this.props.apiFileType == FILE_TYPE.MEMBER_PROFILE ||
      this.props.apiFileType == FILE_TYPE.CREW_PROFILE
    ) {
      this.setState({imagePath: addPhotoPurple});
    } else {
      this.setState({imagePath: PlaceholderGrey});
    }

    // Fetch the image objects
    this._fetchImageObjects(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (
      this.props.lastUpdate &&
      nextProps.lastUpdate &&
      this.props.lastUpdate.getTime() !== nextProps.lastUpdate.getTime()
    ) {
      this._fetchImageObjects(nextProps);
    }
  }

  _fetchImageObjects = (props: any) => {
    let imageObject = props.imageObject;
    if (imageObject) {
      if (imageObject.localUri) {
        // It's a local image so set keys that are required
        imageObject.url = imageObject.localUri;
      }
    }

    if (imageObject && imageObject.key != '') {
      if (imageObject.localImage) {
        this.setState({
          imagePath: {uri: imageObject.url},
        });
      } else {
        // if (Platform.OS === types2.IOS) {
          this.setState({
            imagePath: {uri: imageObject.url},
          });
        // } else {
        //   ImageCache.getImage(imageObject).then(imagePath => {
        //     if (this.state.imagePath && this.state.imagePath.uri != imagePath) {
        //       this.setState({
        //         imagePath: {uri: imagePath},
        //       });
        //     }
        //   });
        // }
      }
    } else {
      if (
        this.props.apiFileType == FILE_TYPE.SUPPORT_PHOTO ||
        this.props.apiFileType == FILE_TYPE.GOAL_PHOTO ||
        this.props.apiFileType == FILE_TYPE.MEMBER_PROFILE ||
        this.props.apiFileType == FILE_TYPE.CREW_PROFILE
      ) {
        this.setState({imagePath: addPhotoPurple});
      } else {
        this.setState({imagePath: PlaceholderGrey});
      }
    }
  };

  render() {
    var clickable = true;
    if (this.props.clickable != undefined) {
      clickable = this.props.clickable;
    }
    var rightMargin = true;
    if (this.props.rightMargin != undefined) {
      rightMargin = this.props.rightMargin;
    }

    var bottomMargin = true;
    if (this.props.bottomMargin != undefined) {
      bottomMargin = this.props.bottomMargin;
    }

    let imageHeight = this.props.style.height;
    let imageWidth = this.props.style.width;
    if (this.props.imageGap !== undefined) {
      imageHeight = imageHeight - this.props.imageGap;
      imageWidth = imageWidth - this.props.imageGap;
    } else {
      imageHeight = imageHeight - PHOTOTILEGAP;
      imageWidth = imageWidth - PHOTOTILEGAP;
    }

    let access = true;
    if (this.props.readOnly == 'read') {
      access = false;
    }

    if (clickable && access == true) {
      return (
        <View style={this._setMinWidthHeight()}>
          <BrandActivityIndicator loading={this.state.loading} />
          <StandardTouchableOpacity
            disabled={this.props.disabled}
            accessibilityLabel={this.props.accessibilityLabel}
            style={[
              this._determineStyle(rightMargin),
              this._setMinWidthHeight(),
            ]}
            onPress={this.executeAction.bind(this)}
          >
            {this._renderConstantContent(imageHeight, imageWidth)}
          </StandardTouchableOpacity>
        </View>
      );
    } else {
      return (
        <View
          style={[
            this._determineStyle(rightMargin, bottomMargin),
            this._setMinWidthHeight(),
          ]}
        >
          <BrandActivityIndicator loading={this.state.loading} />
          {this._renderConstantContent(imageHeight, imageWidth)}
        </View>
      );
    }
  }

  _setMinWidthHeight = () => {
    let height = this.props.style.height + PHOTOBORDERGAP;
    let width = this.props.style.width + PHOTOBORDERGAP;

    return {minWidth: width, minHeight: height};
  };

  _determineStyle = (rightMargin: any, bottomMargin: any) => {
    var exportedStyles = [CommonStyles.elevation0];
    if (!this.props.showCaption) {
      let width = this.props.style.width;
    }
    if (rightMargin) {
      exportedStyles.push(CommonStyles.marginRight0);
    }
    if (bottomMargin) {
      exportedStyles.push(CommonStyles.marginBottom0);
    }
    return exportedStyles;
  };
  //BrandStyles.imageBorderBackground,
  _renderConstantContent = (imageHeight: any, imageWidth: any) => {
    return (
      <View
        style={[
          CommonStyles.flexDirectionRowPhototile,
          this.props.flexRatio ? this.props.flexRatio : null,
        ]}
      >
        <View
          style={[
            CommonStyles.imageMainContainer,
            CommonStyles.flexDirectionColumn,
            CommonStyles.alignSelfCenter,
            this.props.style,
            {
              width: this.props.style.width + PHOTOTILEGAP,
              height: this.props.style.height,
            },
          ]}
        >
          <View
            style={[
              CommonStyles.imageContainerBorder,
              BrandStyles.primaryBgColor1,
              BrandStyles.borderColor5,
              this.props.style,
              {
                width: this.props.style.width - PHOTOTILEGAP,
                height: this.props.style.height - PHOTOTILEGAP,
              },
              this.props.borderRadius
                ? StyleSheet.flatten(this.props.borderRadius)
                : CommonStyles.photoTileRoundContainer,
            ]}
          >
            <View
              style={[
                this.state.imagePath == addPhotoPurple
                  ? BrandStyles.primaryBgColor3
                  : null,
                this.props.borderRadius
                  ? StyleSheet.flatten(this.props.borderRadius)
                  : CommonStyles.photoTileRoundContainer,
                {
                  width: imageWidth - PHOTOTILEGAP,
                  height: imageHeight - PHOTOTILEGAP,
                },
              ]}
            >
              <Image
                style={[
                  {
                    width: imageWidth - PHOTOTILEGAP,
                    height: imageHeight - PHOTOTILEGAP,
                  },
                  this.props.photoBorderRadius
                    ? StyleSheet.flatten(this.props.photoBorderRadius)
                    : null,
                  this.props.borderRadius
                    ? StyleSheet.flatten(this.props.borderRadius)
                    : CommonStyles.photoTileRoundContainer,
                ]}
                source={this.state.imagePath}
              />
            </View>
          </View>
        </View>
        <View
          style={
            types.isWeb
              ? [
                  CommonStyles.columnContainer,
                  CommonStyles.flexWrap,
                  CommonStyles.justifyContentCentre,
                ]
              : null
          }
        >
          {types.isWeb ? this._renderCaption() : null}
        </View>
        {types.isWeb ? null : this._renderCaption()}
      </View>
    );
  };

  executeAction() {
    if (this.props.actionCallback) {
      if (this.props.actionData) {
        this.props.actionCallback(this.props.actionData);
      } else {
        this.props.actionCallback();
      }
    } else {
      this.uploadImage();
    }
  }

  /**
   * Function that will deal with presenting the user with options to upload an image, or take an image with their camera.
   */
  uploadImage() {
    var options : ImageLibraryOptions = {
      mediaType: 'photo',
      //title: 'Select Photo',
      // storageOptions: {
      //   skipBackup: true,
      //   path: 'images',
      // },
    };

     launchImageLibrary(options, (response: any) => {
      if (response.didCancel) {
        // TODO: Need to update what the cancel should do, most likely nothing
      } else if (response.error) {
        // TODO: If there is an error, most likely should warn the user. Need to update.
      } else {
        this.setState({
          loading: true,
        });
        let source = {uri: response.uri};

        // Creating the callback function when the URL is returned
        let getUploadURLCallbackFunction = (data: any) => {
          let url = data[0].content.uploadURL;
          uploadImageToS3(url, response.type, response.path)
            .then(response => {
              this.setState({
                imagePath: source,
                loading: false,
              });

              requestObject = {
                ...requestObject,
                imagePath: source.uri,
              };
              this.props.uploadCallBack(requestObject);
            })
            .catch(error => {
              logger.log('The error is:', error);
            });
        };

        let fileNameModified = response.fileName
          ? response.fileName
          : response.uri.split('/').pop();

        // Sometimes Android returns a null for images content type
        // So generate from file extension
        response.type = response.type
          ? response.type
          : mime.types[fileNameModified.split('.').pop()];

        // Build the request object that would allow for the upload
        let requestObject = {
          fileType: this.props.apiFileType,
          filename: fileNameModified,
          contentType: response.type,
        };

        // If there is a crew id, add it in
        if (this.props.crewId) {
          requestObject.forCrew = this.props.crewId;
        }

        let forceUpload = false;
        if (this.props.forceMemberImageUpload === true) {
          forceUpload = true;
        }

        // Check if we have an uploadCallback, if so pass the image back to the function
        // And let the parent component handle what it wants to do with the image
        if (this.props.uploadCallBack && !forceUpload) {
          this.setState({
            imagePath: source,
            loading: false,
          });
          // Send back the full response for the Imagepicker and let the parent class deal with it
          var modifiedRespose = response;
          if (Platform.OS === types2.IOS) {
            modifiedRespose.path = response.uri;
          }
          this.props.uploadCallBack(modifiedRespose);
          return;
        }

        // Call the API to get the signed URL
        callAPIs(
          [
            getUploadURL(
              this.props.loadedMemberId.loadedMemberId,
              requestObject,
              getUploadURLCallbackFunction,
            ),
          ],
          getUploadURLCallbackFunction,
          null,
          () => {
            logger.log('Get upload url failed for photo tile');
          },
        );
      }
    });
  }

  // Will render the caption under the image, if we want to show it.
  _renderCaption = () => {
    let captionkey = `${this.props.key}caption`;
    if (this.props.showCaption) {
      return (
        <View style={[CommonStyles.imageCaptionContainer]}>
          <StandardText
            numberOfLines={
              this.props.captionNumberOfLines
                ? this.props.captionNumberOfLines
                : null
            }
            ellipsizeMode={
              this.props.captionEllipsizeMode
                ? this.props.captionEllipsizeMode
                : null
            }
            key={captionkey}
            style={[this._determinCaptionStyle()]}
          >
            {this.props.caption}
          </StandardText>
        </View>
      );
    } else {
      return null;
    }
  };

  _determinCaptionStyle = () => {
    let styles = [
      CommonStyles.textAlignLeft,
      CommonStyles.customFontMedium,
      BrandStyles.TextColor5,
      types.isWeb ? CommonStyles.font15 : null,
    ];
    if (this.props.captionStyles) {
      styles = this.props.captionStyles;
    }
    return styles;
  };
}

PhotoTile.defaultProps = {
  accessibilityLabel: '',
};

const mapStateToProps = (state: any) => ({
  loadedMemberId: state.LoadedMemberReducer,
  member: state.MemberReducer,
});

export default connect(mapStateToProps)(PhotoTile);
