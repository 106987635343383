/*
 * Author: Brad D'Costa
 * Date: 29/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Favourite Things actions for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const actionGetFavouriteThings = (favouriteObj: any) => ({
  type: types.GET_FAVOURITE_THINGS,
  favouriteObj,
});

export const actionUpdateFavouriteLikeToDo = (likeToDoObj: any) => ({
  type: types.UPDATE_FAVOURITE_LIKE_TO_DO,
  likeToDoObj,
});

export const actionUpdateFavouriteWouldLikeTo = (wouldLikeToObj: any) => ({
  type: types.UPDATE_FAVOURITE_WOULD_LIKE_TO,
  wouldLikeToObj,
});
