/*
 * Author: Nirodha Perera
 * Date: 05/09/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The screen for add supports by category
 */

import React, {Component} from 'react';
import {View, Dimensions, Platform} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {debounce} from 'lodash';
import {announceForAccessibility} from '../Helpers/PlatformSynchronizer';
// import Orientation from 'react-native-orientation-enhanced';
import logger from 'helpers/Logger';

import {NoData} from '../Components/Organisms';
import {
  Container,
  ItemTile,
  SelectedItem,
  AutoComplete,
  SummaryTile,
  SupportCategoryTile,
} from '../Components/Molecules';
import {
  StandardText,
  StandardButton,
  StandardTouchableOpacity,
} from '../Components/Atoms';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import * as messages from '../Constants/Messages';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {SupportPurple3x} from '../assets/images/vector-icons';

import {callAPIs} from '../API/APICaller';
import {getSupportGroupItems, getSupportCategories} from '../API/ReferenceAPI';
import {getSupports} from '../API/PlanDataAPI';
import * as SupportsActions from '../Actions/SupportsActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {getParamValue} from '../Helpers/Utils';

import {setValue, getValue} from '../API/WebPersistenceStore';

const BILLER_CODE_STRING = '_bc';

const {height, width} = Dimensions.get('window');
class CategorySupports extends Component {
  state = {
    selectedCategory: null,
    isLoading: true,
    tileContainerDimensions: undefined,
    supportSelected: [],
    support: '',
    searchText: '',
    scrollEnabled: true,
    selectedSuportName: null,
    billerCode: null,
    filteredSuport: {},
    selectedSuportItem: {},
    selectedGroup: [],
    selectedCategories: [],
    itemValue: [],
    selectedColor: null,
    orientation: '',
    selectedGroupIds: [],
  };

  constructor(props: any) {
    super(props);
    this._renderSupportCategory = this._renderSupportCategory.bind(this);
    this._onTilesLayout = this._onTilesLayout.bind(this);
    this.updateSupport = this.updateSupport.bind(this);
    this.navigateToCreateCustomSupport =
      this.navigateToCreateCustomSupport.bind(this);
    this._removeSelectedSupport.bind(this);
    this._removeSelectedGroup.bind(this);
    this._renderSelectedSupports.bind(this);
    this.addSupport = this.addSupport.bind(this);
    this._renderSearch.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (!types.isWeb) {
      // Orientation.getOrientation((err: any, orientation: any) => {
      //   this.setState({
      //     orientation,
      //   });
      // });
    } else {
      this.fetchData();
    }
  }

  componentDidMount() {
    // if (!types.isWeb) {
    //   Orientation.addOrientationListener(this._orientationDidChange);
    // }
    const selectedSuportName = getParamValue(
      types.isWeb ? this.props.navigation : this.props.route,
      'itemselectedSuportItem',
      '',
      'selectedSuportName',
    );
    const category = getParamValue(
      types.isWeb ? this.props.navigation : this.props.route,
      'selectedCategory',
      null,
    );
    const billerCode =  getParamValue(
      types.isWeb ? this.props.navigation : this.props.route,
      'itemselectedSuportItem',
      '',
      'billerCode',
    );
    const selectedColor = getParamValue(
      types.isWeb ? this.props.navigation : this.props.route,
      'selectedColor',
      undefined,
    )
   // this.setState({isLoading: true});
    this.setState(
      {isLoading: true, 
      selectedSuportName: selectedSuportName,
      selectedColor: selectedColor,
      billerCode: billerCode,

      selectedCategory: category,
    }
      );
    const callback = (data: any) => {
      const groups = data[0].content;
      this.props.actions.SupportsActions.actionGetSupportGroups(groups);
      this.setState({
        isLoading: false,
      });
    };
    const requestBody = {
      categoryId: category,
      searchTerm: '',
      page: 1,
      itemsPerPage: 50,
    };
    logger.log('Supports category supports', requestBody)
    callAPIs([getSupportGroupItems(requestBody)], callback, null, () => {
      this.setState({isLoading: false});
    });

    this._setInitialState();
  }

  fetchData = () => {
    //get support details for web
    const downloadCallback = (data: any) => {
      const categories = data[0].content;
      const supports = data[1].content;
      this.props.actions.SupportsActions.actionGetSupportsCategories(
        categories,
      );

      this.props.actions.SupportsActions.actionGetSupports(supports);
      this.props.setLoading(false);
    };

    this.props.setLoading(true);

    callAPIs(
      [
        getSupportCategories(),
        getSupports(
          this.props.member.planId,
          this.props.loadedMemberId.loadedMemberId,
          this.props.user.demoProfile,
        ),
      ],
      downloadCallback,
      null,
      () => {
        this.props.setLoading(false);
      },
    );
  };

  componentWillUnmount() {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation,
    });
  };

  navigateToCreateCustomSupport() {
    let selectedSupports: any = [];

    if (this.state.selectedGroup.length > 0) {
      selectedSupports = [...selectedSupports, ...this.state.selectedGroup];
    }

    if (this.state.selectedCategories.length > 0) {
      this.props.actions.SupportsActions.addTempSupportCategoriesAction(
        this.state.selectedCategories,
      );
      if (types.isWeb) {
        setValue(
          'tempSupportCategories',
          JSON.stringify(this.state.selectedCategories),
        );
      }
    }

    if (this.state.itemValue.length > 0) {
      selectedSupports = [...selectedSupports, ...this.state.itemValue];
    }

    if (selectedSupports.length > 0) {
      this.props.actions.SupportsActions.addTempSupportItemsAction(
        selectedSupports,
      );
      if (types.isWeb) {
        setValue('tempSupportItems', JSON.stringify(selectedSupports));
      }
    }

    this.props.actions.SupportsActions.addSupportStepAction(1);
    if (types.isWeb) {
      setValue('addSupportStep', 1);
    }

    this.props.navigation.navigate('AddSupportDetails', {
      selectedSupports: this.state.itemValue,
    });
    this.setState({isLoading: false});
  }

  updateSupport(group: any) {
    let selectedGroup = this.state.selectedGroup;

    const isSelected = this.state.selectedGroupIds.includes(group.groupId);

    if (isSelected) {
      let start_index = 0;
      selectedGroup.map(selectedSupportGroups => {
        if (selectedSupportGroups.groupId === group.groupId) {
          const selectedGp = selectedGroup;
          selectedGp.splice(start_index, 1);
          selectedGroup = selectedGp;
        }
        start_index = start_index + 1;
      });
      group.isSelected = false;
    } else {
      group.isSelected = true;
      selectedGroup.push(group);
    }

    const selectedCategories = this.state.selectedCategories;
    selectedCategories.push(this.state.selectedCategory);

    const selectedGroupIds = selectedGroup.map(sg => sg.groupId);
    this.setState({
      selectedGroup,
      selectedCategories,
      selectedGroupIds,
    });
  }

  _removeSelectedGroup = (groupId: any) => {
    let selectedGroup = this.state.selectedGroup;

    let start_index = 0;
    selectedGroup.map(selectedSupportGroups => {
      if (selectedSupportGroups.groupId === groupId) {
        const selectedGp = selectedGroup;
        selectedGp.splice(start_index, 1);
        selectedGroup = selectedGp;
      }
      start_index = start_index + 1;
    });

    this.setState({selectedGroup});
    const updatedGroups: any = [];
    this.props.supportGroups.map((group: any) => {
      if (group.groupId === groupId) {
        group.isSelected = false;
      }
      updatedGroups.push(group);
    });

    const groupObj = {
      groupItems: updatedGroups,
    };

    this.props.actions.SupportsActions.actionGetSupportGroups(groupObj);
  };

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        screenType={types.SCREEN_TYPE_MAIN}
        headerTitle={types2.NAVIGATION_TEXT.ADD_SUPPORTS}
        nav={this.props.navigation}
        sidebarProfilePicture={true}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        loading={this.state.isLoading}
        scrollEnabled={this.state.scrollEnabled}
        hasFormContentChanged={this._hasFormContentChanged}
        currentScreenName={CategorySupports.name}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _removeSelectedSupport = (index: any) => {
    const supportSelected = JSON.parse(
      JSON.stringify(this.state.supportSelected),
    );
    const itemValues = JSON.parse(JSON.stringify(this.state.itemValue));

    // Remove the index from the temp selected supports
    supportSelected.splice(index, 1);
    itemValues.splice(index, 1);

    // Update the state
    this.setState({
      supportSelected,
      itemValue: itemValues,
    });
  };

  _renderSelectedSupports = () => {
    // Go through the list of selected supports to render on screen
    const {supportSelected, selectedGroup} = this.state;

    const supportSelectedJSX: any = [];
    const selectedGroupJSX: any = [];

    let indexSupportCounter = 0;
    supportSelected.map(support => {
      supportSelectedJSX.unshift(
        <SelectedItem
          key={indexSupportCounter}
          index={indexSupportCounter}
          organisation={support}
          removeOrg={this._removeSelectedSupport}
        />,
      );
      indexSupportCounter++;
    });

    selectedGroup.map(support => {
      selectedGroupJSX.unshift(
        <SelectedItem
          key={support.groupId}
          index={support.groupId}
          organisation={support.description}
          removeOrg={this._removeSelectedGroup}
        />,
      );
    });

    return (
      <View
        style={[
          CommonStyles.flexColumn,
          CommonStyles.flexWrap,
          CommonStyles.marginTop10,
        ]}
      >
        {supportSelected.length > 0 && supportSelectedJSX}
        {selectedGroup.length > 0 && selectedGroupJSX}
      </View>
    );
  };

  addSupport() {
    const selectedSupport = this.state.supportSelected;
    let duplicateItem = false;
    selectedSupport.map(org => {
      if (org.toLowerCase() === this.state.support.toLowerCase()) {
        duplicateItem = true;
        this.setState({
          support: '',
          selectedSuportName: '',
          filteredSuport: [],
        });
      }
    });

    if (!duplicateItem && this.state.support != '') {
      selectedSupport.push(this.state.support);
      const itemValue = this.state.itemValue;
      itemValue.push({
        description: this.state.support,
      });
      this.setState({
        supportSelected: selectedSupport,
        support: '',
        itemValue,
        selectedSuportName: '',
        filteredSuport: [],
      });
    }
  }

  _setFilterdSupport(data: any) {
    this.setState({
      scrollEnabled: Platform.OS === types2.ANDROID ? false : true,
      isLoadingSearch: false,
    });
    if (data[0].content.groupItems.length > 0) {
      const filteredSuport = data[0].content.groupItems.filter(function (
        item: any,
        index: any,
        array: any,
      ) {
        return item.groupId;
      });
      if (
        this.state.searchText != '' &&
        this.state.searchText != undefined &&
        this.state.searchText.length > 1
      ) {
        this.setState({
          filteredSuport,
          isLoadingSearch: false,
        });
      } else {
        this.setState({
          filteredSuport: [],
          isLoadingSearch: false,
        });
      }
    }
  }

  _filteredSupportFailed(err: any) {
    logger.log('notifications error: ', err);
    this.setState({isLoadingSearch: false, filteredSuport: []});
  }

  _fetchSearchData(requestBody: any) {
    logger.log('Supports by Crew - fetch search data', requestBody)
    callAPIs(
      [getSupportGroupItems(requestBody, false)],
      (data: any) => this._setFilterdSupport(data),
      (err: any) => this._filteredSupportFailed(err),
    );
    this.setState({isLoadingSearch: true});
  }

  debouncedSearchFilteredSupportFunc = debounce(
    requestBody => this._fetchSearchData(requestBody),
    1000,
  );

  debouncedSearchFilteredSupport(query: any) {
    this.setState({
      searchText: query,
      selectedSuportName: query,
      support: query,
      billerCode: '',
    });

    const requestBody = {
      searchTerm: query,
      page: 1,
      itemsPerPage: 50,
    };

    this.debouncedSearchFilteredSupportFunc(requestBody);
  }

  _renderSearchItem = (item: any) => {
    return (
      <StandardTouchableOpacity
        accessibilityLabel={item.type + ' selected'}
        key={item.description}
        style={[CommonStyles.listItem]}
        onPress={() => {
          this._setSuportItem(item);
        }}
      >
        <StandardText
          style={[CommonStyles.StandardFontSize, CommonStyles.customFont]}
        >
          <StandardText style={[CommonStyles.customFont]}>
            {item.description}
          </StandardText>
        </StandardText>
      </StandardTouchableOpacity>
    );
  };

  _setSuportItem = (item: any) => {
    this.setState({
      filteredSuport: [],
      searchText: item.description,
      selectedSuportName: item.description,
      selectedSuportItem: item,
      billerCode: item.Id,
      scrollEnabled: true,
    });

    this.setState(prevState => ({
      supportItems: {
        ...prevState.supportItems,
        billerKey: item.Id,
        billerCode: item.Id + BILLER_CODE_STRING,
        selectedSuportName: item.description,
        selectedSuportItem: item,
      },
    }));

    const selectedSupport = this.state.supportSelected;
    let duplicateItem = false;
    selectedSupport.map(org => {
      if (org.includes(item.description)) {
        duplicateItem = true;
        this.setState({
          support: '',
        });
      }
    });

    if (!duplicateItem) {
      selectedSupport.push(item.description);
      const itemValue = this.state.itemValue;
      itemValue.push(item);

      const selectedCategories = this.state.selectedCategories;
      selectedCategories.push(item.categoryId);

      this.setState({
        supportSelected: selectedSupport,
        support: '',
        itemValue,
        selectedCategories,
      });
    }

    this.setState({
      searchText: '',
      selectedSuportName: '',
      billerCode: '',
      selectedSuportItem: {},
      filteredSuport: {},
    });
  };

  _renderSupportCategory() {
    if (this.props.categories === undefined) {
      return (
        <NoData
          activity={'Categories'}
          additionalInformation={messages.MESSAGES.NODATA.CATEGORIES}
        />
      );
    } else {
      if (Object.getOwnPropertyNames(this.props.categories).length != 0) {
        return this.props.categories.map((category: any) => {
          if (
            this.state.selectedCategory &&
            this.state.selectedCategory.toString() ===
              category.categoryId.toString()
          ) {
            return (
              <SupportCategoryTile
                key={category.categoryId}
                category={category}
                selected={this.state.selectedCategory}
                removeCallback={this.props.navigation.goBack}
                showRemoveButton={true}
                selectedColor={this.state.selectedColor}
              />
            );
          }
        });
      } else {
        return (
          <NoData
            activity={'Categories'}
            additionalInformation={messages.MESSAGES.NODATA.CATEGORIES}
          />
        );
      }
    }
  }

  _renderSupportItems() {
    if (
      this.props.supportGroups &&
      Object.getOwnPropertyNames(this.props.supportGroups).length != 0 &&
      this.props.supportGroups.length > 0 &&
      this.state.selectedCategory != null
    ) {
      return this.props.supportGroups.map((group: any) => {
        const isSelected = this.state.selectedGroupIds.includes(group.groupId);
        return (
          <ItemTile
            key={group.groupId}
            content={group}
            isSelected={isSelected}
            actionCallback={this.updateSupport}
            actionData={group}
            columns={2}
            parentDimensions={this.state.tileContainerDimensions}
          />
        );
      });
    } else {
      return null;
    }
  }

  _setSearchHeight = () => {
    if (this.state.filteredSuport.length > 0) {
      return CommonStyles.height300;
    } else {
      return null;
    }
  };

  _renderSearch() {
    return (
      <View
        style={[
          CommonStyles.autoCompleteZindex,
          CommonStyles.marginTop20,
          this._setSearchHeight(),
        ]}
      >
        <AutoComplete
          readOnly={false}
          data={this.state.filteredSuport}
          listStyle={[CommonStyles.content, CommonStyles.listContainer]}
          onChangeText={(text: any) =>
            this.debouncedSearchFilteredSupport(text)
          }
          placeholder={'Type your current support'}
          renderItem={(item: any) => this._renderSearchItem(item)}
          returnKeyType={'done'}
          onSubmitEditing={() => {
            if (this.state.selectedSuportName.length > 0) {
              this.addSupport();
            }
          }}
          style={[
            CommonStyles.ModalTextInputWrapper,
            CommonStyles.StandardFontSize,
            CommonStyles.paddingLeft20,
          ]}
          value={
            this.state.billerCode
              ? this.state.selectedSuportName +
                ' (' +
                this.state.billerCode +
                ')'
              : this.state.selectedSuportName
          }
          underlineColorAndroid="transparent"
          isLoadingSearch={this.state.isLoadingSearch}
          accessibilityLabelClear={'Clear Supports'}
          canAddCustom={true}
          rowStyle={true}
          border={BrandStyles.borderColor4}
          addCustom={this.addSupport}
          onClear={() => {
            this.setState({
              searchText: '',
              selectedSuportName: '',
              billerCode: '',
              selectedSuportItem: {},
              filteredSuport: {},
              scrollEnabled: true,
            });
          }}
          onFocus={() => {
            // Since the focus is on the auto complete, any drag should dismiss the keyboard
            this.setState({keyboardDismissMode: 'on-drag'});
          }}
          onBlur={() => {
            // No longer focused on the auto complete, remove the dismiss mode
            this.setState({keyboardDismissMode: 'none'});
          }}
        />
        {this._renderSelectedSupports()}
      </View>
    );
  }

  _renderNextButton = () => {
    let buttonDisable = true;
    if (
      this.state.selectedGroup.length > 0 ||
      this.state.itemValue.length > 0
    ) {
      announceForAccessibility(messages.NEXT_BUTTON.NEXT_BUTTON_MESSAGE);
      buttonDisable = false;
    }
    return (
      <StandardButton
        onPress={() => this.navigateToCreateCustomSupport()}
        style={[BrandStyles.brandBlockTxtColor5, CommonStyles.buttonFormAction]}
        containerStyle={this._renderButtonContainerStyle(buttonDisable)}
        accessibilityLabel={types2.NEXT}
        disabled={buttonDisable ? true : false}
      >
        {types2.NEXT}
      </StandardButton>
    );
  };

  _setOrientationHeight = () => {
    if (this.state.orientation === types2.ORIENTATION.LANDSCAPE) {
      return width;
    } else {
      return height;
    }
  };

  _renderBottomNextButtom = () => {
    if (
      this.state.tileContainerDimensions &&
      this.state.tileContainerDimensions.height + types2.ADDED_VIEW_HEIGHT >=
        this._setOrientationHeight()
    ) {
      return (
        <View>
          <View
            style={[
              CommonStyles.justifyFlexRowEnd,
              CommonStyles.marginRight10,
              CommonStyles.marginBottom20,
              CommonStyles.marginTop10,
            ]}
          >
            {this._renderNextButton()}
          </View>
        </View>
      );
    }
  };

  _renderButtonContainerStyle = (buttonDisable: any) => {
    if (buttonDisable) {
      return [
        BrandStyles.secondaryBgColor3,
        BrandStyles.borderColor6,
        CommonStyles.buttonContainerFormAction,
      ];
    } else {
      return [
        BrandStyles.primaryBgColor4,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerFormAction,
      ];
    }
  };

  _onTilesLayout = (event: any) => {
    let {width, height} = event.nativeEvent.layout;
    this.setState({tileContainerDimensions: {width, height}});
  };

  _createMainContents = () => (
    <View style={[CommonStyles.flexColumn, CommonStyles.content]}>
      <View
        onLayout={this._onTilesLayout}
        style={[CommonStyles.flexColumn, CommonStyles.marginBottom20]}
      >
        <View>
          <SummaryTile
            headerDisplayString={messages.ADD_SUPPORTS_BY_CATEGORY.TITLE}
            headerIconImage={SupportPurple3x}
            HeaderTextColor={BrandStyles.TextColor4}
            HeaderBoarderColor={BrandStyles.borderColor4}
            hideYellowStar={true}
            showButton={false}
            fontSize={CommonStyles.font20}
            disableMore={true}
          />
        </View>
        <View style={CommonStyles.wrapTileContainer}>
          <View style={CommonStyles.flex1}>
            {this._renderSupportCategory()}
          </View>
          <View
            style={[
              CommonStyles.AddSupportsFlex1,
              CommonStyles.AddSupportsAIFE,
              CommonStyles.paddingTop10,
            ]}
          >
            {this._renderNextButton()}
          </View>
        </View>
        <View style={[CommonStyles.paddingTop10]}>
          <View
            onLayout={this._onTilesLayout}
            style={[CommonStyles.contentFlexWrap]}
          >
            {this._renderSupportItems()}
          </View>
        </View>
        {this._renderSearch()}
      </View>
      {this._renderBottomNextButtom()}
    </View>
  );

  _setInitialState = () => {
    this.setState({
      initialFormState: {
        supportSelected: [],
        selectedSuportItem: {},
        selectedGroup: [],
        selectedCategories: [],
        itemValue: [],
      },
    });
    if (types.isWeb) {
      const supportItems = JSON.parse(getValue('tempSupportItems'));

      if (supportItems) {
        const selectedGroupIds = supportItems.map((sg: any) => sg.groupId);
        this.setState({selectedGroup: supportItems, selectedGroupIds});
      }
    }
  };
  _hasFormContentChanged = () => {
    const initialFormState = this.state.initialFormState;
    const currentFormState = {
      supportSelected: this.state.supportSelected,
      selectedSuportItem: this.state.selectedSuportItem,
      selectedGroup: this.state.selectedGroup,
      selectedCategories: this.state.selectedCategories,
      itemValue: this.state.itemValue,
    };
    return (
      JSON.stringify(initialFormState) !== JSON.stringify(currentFormState)
    );
  };
}

const mapStateToProps = (state: any) => ({
  categories: state.SupportCategoriesReducer.categories,
  supportGroups: state.SupportItemGroupsReducer.supportGroups,
  member: state.MemberReducer,
  supports: state.SupportsReducer,
  sideMenu: state.SideMenuReducer,
  loadedMemberId: state.LoadedMemberReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CategorySupports);
