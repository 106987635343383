/*
 * Author: Shelan Cooray
 * Date: 26/07/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Very Basic Popup Modal Component with Backdrop Overlay to Use Across Leap in App
 */

import React, {Component} from 'react';
import {View, Image, ScrollView, Platform} from 'react-native';
// import Orientation from 'react-native-orientation-enhanced';
import ModalMobile from 'react-native-modal';
import ModalWeb from 'modal-enhanced-react-native-web';

import {StandardButton, StandardText} from '../Atoms';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {
  CloseButtonCharcoal2x,
  CloseButtonRoundFilled,
  InformationIconx3,
} from '../../assets/images';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import DeviceInfo from 'react-native-device-info';

class BasicOverlayPopupModal extends Component {
  state = {
    orientation: '',
  };

  UNSAFE_componentWillMount() {
    // if (!types.isWeb) {
    //   const initial = Orientation.getInitialOrientation();
    //   this.setState({
    //     orientation: initial,
    //   });
    // }
  }

  componentDidMount() {
    // if (!types.isWeb) {
    //   Orientation.addOrientationListener(this._orientationDidChange);
    // }
  }

  componentWillUnmount() {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation,
    });
  };

  renderCloseButton() {
    if (this.props.close && !this.props.hideCloseButton) {
      let cancelText = 'Cancel';

      if (this.props.cancelLabel) {
        cancelText = this.props.cancelLabel;
      }

      let cancelContainerStyle: any = [];
      let style = [];

      if (this.props.cancelContainerStyle) {
        cancelContainerStyle = cancelContainerStyle.concat(
          this.props.cancelContainerStyle,
        );
      } else {
        cancelContainerStyle = [
          CommonStyles.buttonContainerFormAction,
          BrandStyles.brandBlockBgColor4,
          BrandStyles.borderColor4,
        ];
      }

      if (this.props.cancelStyle != types2.UNDEFINED) {
        style = this.props.cancelStyle;
      } else {
        style = [
          BrandStyles.brandBlockTxtColor6,
          CommonStyles.buttonFormAction,
          CommonStyles.customFontBold,
        ];
      }

      return (
        <StandardButton
          onPress={this.props.close}
          style={style}
          containerStyle={cancelContainerStyle}
          accessibilityLabel={cancelText}
          allowFontScaling={false}
        >
          {cancelText}
        </StandardButton>
      );
    } else {
      return null;
    }
  }

  renderActionButton() {
    if (this.props.saveComponent) {
      return this.props.saveComponent();
    } else if (this.props.save) {
      let saveText = 'Save';

      if (this.props.saveLabel) {
        saveText = this.props.saveLabel;
      }

      let saveContainerStyle: any = [];
      let style = [];

      if (this.props.saveContainerStyle) {
        saveContainerStyle = saveContainerStyle.concat(
          this.props.saveContainerStyle,
        );
      } else {
        saveContainerStyle = [
          CommonStyles.buttonContainerFormAction,
          BrandStyles.primaryBgColor4,
          BrandStyles.borderColor4,
        ];
      }

      if (this.props.saveStyle != types2.UNDEFINED) {
        style = this.props.saveStyle;
      } else {
        style = [
          BrandStyles.TextColor1,
          CommonStyles.buttonFormAction,
          CommonStyles.customFontBold,
        ];
      }

      return (
        <StandardButton
          onPress={this.props.save}
          style={style}
          containerStyle={saveContainerStyle}
          accessibilityLabel={saveText}
        >
          {saveText}
        </StandardButton>
      );
    } else {
      return null;
    }
  }

  _renderButtonDiver = (isDivider: any) => {
    if (isDivider) {
      return (
        <View style={[CommonStyles.flex1]}>
          <View style={CommonStyles.dividerStylePopupModal} />
        </View>
      );
    }
  };

  _renderContent = () => {
    return (
      <ScrollView
        style={[CommonStyles.paddingLeft20, CommonStyles.paddingRight20]}
      >
        {this.props.children}
      </ScrollView>
    );
  };

  renderButtons = () => {
    if (this.props.save || this.props.saveComponent) {
      if (types.isWeb) {
        return this._renderButtonContainer();
      }
      return (
        <View style={[CommonStyles.alignCenter, CommonStyles.flexWrap]}>
          {this._renderButtonContainer()}
        </View>
      );
    } else {
      return (
        <View style={[CommonStyles.alignCenter]}>
          {this.renderCloseButton()}
        </View>
      );
    }
  };

  _renderButtonContainer = () => {
    return (
      <View style={this.renderCustomButtonContainerStyle()}>
        <View
          style={[
            types.isWeb
              ? CommonStyles.ModalFooterCenter
              : CommonStyles.ModalFooterLeft,
          ]}
        >
          {this.renderCloseButton()}
        </View>
        <View
          style={[
            types.isWeb
              ? CommonStyles.ModalFooterCenter
              : CommonStyles.ModalFooterRight,
          ]}
        >
          {this.renderActionButton()}
        </View>
      </View>
    );
  };

  renderCustomButtonContainerStyle = () => {
    const styles = [CommonStyles.ModalActionButtons];
    if (this.props.buttonContainerStyle) {
      styles.push(this.props.buttonContainerStyle);
    }
    return styles;
  };

  _renderInnerViewStyle = () => {
    const styles = [CommonStyles.containerBasicPopUp];
    if (this.props.innerStyles !== undefined) {
      styles.push(this.props.innerStyles);
    }
    return styles;
  };

  renderHeaderText = () => {
    if (this.props.headerText) {
      return (
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.scrollView_subContentCenter,
          ]}
        >
          {!this.props.disableInfoImg && (
            <View>
              <Image
                style={[CommonStyles.changePasswordButtonImage]}
                source={InformationIconx3}
              />
            </View>
          )}
          <View style={[CommonStyles.scrollView_subContentCenter]}>
            <StandardText
              style={[
                !types.isWeb
                  ? DeviceInfo.isTablet()
                    ? CommonStyles.font25
                    : CommonStyles.font20
                  : CommonStyles.font30,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
                this.props.headerStyle && this.props.headerStyle,
              ]}
            >
              {this.props.headerText}
            </StandardText>
          </View>
        </View>
      );
    }
  };

  renderHeaderTextV2 = () => {
    if (this.props.headerTextV2) {
      return (
        <View
          style={[
            CommonStyles.navigationConfirmModalActionButtons,
            CommonStyles.justifyContentSpaceBetween,
            BrandStyles.primaryBgColor2,
            CommonStyles.headerTextV2,
          ]}
        >
          <StandardText
            style={[
              !types.isWeb
                ? DeviceInfo.isTablet()
                  ? CommonStyles.font25
                  : CommonStyles.font20
                : CommonStyles.font20,
              BrandStyles.brandBlockTxtColorWhite,
              CommonStyles.customFont,
              CommonStyles.customFontBold,

              this.props.headerStyle && this.props.headerStyle,
            ]}
          >
            {this.props.headerTextV2}
          </StandardText>
          <StandardButton
            onPress={this.props.close}
            accessibilityLabel={'close'}
            allowFontScaling={false}
          >
            <Image
              style={[
                CommonStyles.alignFlexStart,
                CommonStyles.DisabilityCloseIcon,
              ]}
              source={CloseButtonRoundFilled}
            />
          </StandardButton>
        </View>
      );
    }
  };

  render() {
    if (this.props.inScreenPopup) {
      if (this.props.visible) {
        return (
          <View
            style={[this.props.style, CommonStyles.containerPopMessage]}
            backdropOpacity={this.props.backdropOpacity}
          >
            <View
              style={[
                CommonStyles.flex1,
                CommonStyles.justifyContentSpaceBetween,
                CommonStyles.containerBasicPopUp,
              ]}
            >
              <View
                style={[
                  CommonStyles.spendingItemSpacing,
                  CommonStyles.justifyContentFlexEnd,
                ]}
              >
                <Image
                  style={[
                    CommonStyles.accordianButtonIcon,
                    CommonStyles.alignFlexStart,
                    CommonStyles.content,
                  ]}
                  source={CloseButtonCharcoal2x}
                />
              </View>
              {this._renderContent()}
              {this._renderButtonDiver(this.props.divider)}
              {this.renderButtons()}
            </View>
          </View>
        );
      } else {
        return null;
      }
    } else {
      let Modal = ModalMobile;
      if (types.isWeb) {
        Modal = ModalWeb;
      }
      return (
        <Modal
          isVisible={this.props.visible}
          style={this.props.style}
          backdropOpacity={this.props.backdropOpacity}
          transparent={true}
          animationInTiming={1}
          animationOutTiming={0}
        >
          {this.props.headerText && this.renderHeaderText()}
          {this.props.headerTextV2 && this.renderHeaderTextV2()}
          <View style={this._renderInnerViewStyle()}>
            {this._renderContent()}
            {!this.props.hideActionButton &&
              this._renderButtonDiver(this.props.divider)}

            {!this.props.hideActionButton ? (
              <View
                style={[
                  CommonStyles.alignCenter,
                  CommonStyles.paddingLeft20,
                  CommonStyles.paddingRight20,
                  CommonStyles.marginTop15,
                ]}
              >
                {this.renderButtons()}
              </View>
            ) : null}
          </View>
        </Modal>
      );
    }
  }
}

BasicOverlayPopupModal.defaultProps = {
  disableInfoImg: false,
};

export default BasicOverlayPopupModal;
