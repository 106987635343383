/*
 * Authour: Brad D'Costa
 * Date: 05/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Plan Management Selection Information
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function PlanManagerReducer(state = {}, action: any) {
  switch (action.type) {
    case types.PLAN_MANAGEMENT.SET_MANAGEMENT:
      return {
        management: action.managementObj,
      };
    default:
      return state;
  }
}
