/*
 * Author: Declan Hart
 * Date: 9/1/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The support screen, a supports placeholder screen.
 */

import React, {Component} from 'react';
import {View, StyleSheet, ScrollView} from 'react-native';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

import {StandardTouchableOpacity} from '../Components/Atoms';
import {SupportCategory} from '../Components/Organisms';
import {
  Container,
  BrandActivityIndicator,
  ActionButton,
} from '../Components/Molecules';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {addIcon} from '../assets/images';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {callAPIs} from '../API/APICaller';
import {getSupports} from '../API/PlanDataAPI';
import {getSupportCategories, getSupportGroupItems} from '../API/ReferenceAPI';
import * as SupportsActions from '../Actions/SupportsActions';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {getValue, removeItem, setValue} from '../API/WebPersistenceStore';
const CURRENT_SCREEN = 'Supports';

class Supports extends Component {
  state = {
    isLoading: true,
    refreshing: false,
  };

  constructor(props: any) {
    super(props);
    this._renderSupportCategories = this._renderSupportCategories.bind(this);
    this._groupSupportsByCategory = this._groupSupportsByCategory.bind(this);
    this.navigateToEdit = this.navigateToEdit.bind(this);
    this.getOtherSupports = this.getOtherSupports.bind(this);
    this.renderOtherCategory = this.renderOtherCategory.bind(this);
    this._navigateToAddByCrew = this._navigateToAddByCrew.bind(this);
    this._navigateToAddByCategory = this._navigateToAddByCategory.bind(this);
    this._renderAddByCategoryButton =
      this._renderAddByCategoryButton.bind(this);
    this._renderAddByCrewButton = this._renderAddByCrewButton.bind(this);
    this.fetchData = this.fetchData.bind();
  }

  UNSAFE_componentWillMount() {
    this.setState({loading: true});
    this.fetchData();
    if (types.isWeb) {
      removeItem('supportCrew');
    }
  }

  fetchData = () => {
    const downloadCallback = (data: any) => {
      const categories = data[0].content;
      const supports = data[1].content;

      this.props.actions.SupportsActions.actionGetSupports(supports);
      this.props.actions.SupportsActions.actionGetSupportsCategories(
        categories,
      );
      this.props.actions.SupportsActions.setSupportInitialState();

      if (types.isWeb) {
        removeItem(types2.WEB_STORE.SUPPORTS);
        setValue(types2.WEB_STORE.SUPPORTS, JSON.stringify(supports));
      }

      this.setState({isLoading: false, refreshing: false});
      if (types.isWeb) {
        this.props.setLoading(false);
      }
    };
    if (types.isWeb) {
      this.props.setLoading(true);
    }

    callAPIs(
      [
        getSupportCategories(this.props.user.demoProfile),
        getSupports(
          this.props.member.planId,
          this.props.loadedMemberId.loadedMemberId,
          this.props.user.demoProfile,
        ),
      ],
      downloadCallback,
      null,
      () => {
        this.setState({loading: false, refreshing: false});
      },
    );
  };

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        needsSidebar={false}
        screenType={types.SCREEN_TYPE_MAIN}
        headerTitle={types2.NAVIGATION_TEXT.SUPPORTS}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        onRefresh={() => {
          this.setState({refreshing: true});
          this.fetchData();
        }}
        refreshing={this.state.refreshing}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _navigateToAddByCategory() {
    this.props.actions.SupportsActions.setSupportInitialState();
    this.props.navigation.navigate('AddSupportsByCategory', {
      categories: this.props.categories,
      fromScreen: 'category',
    });
  }

  _navigateToAddByCrew() {
    this.props.actions.SupportsActions.setSupportInitialState();
    this.props.navigation.navigate('SelectAddCrewSupport', {
      categories: this.props.categories,
    });
  }

  navigateToEdit(categoryId: any, groupId: any, support: any) {
    this.setState({isLoading: true});
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    const callback = (data: any) => {
      const groups = data[0].content;
      this.props.actions.SupportsActions.actionGetSupportGroups(groups);
      this.setState({isLoading: false});
      if (types.isWeb) {
        this.props.setLoading(false);
        setValue('currentSupportId', support.currentSupportId);
      }
      this.props.actions.NavParamActions.setParam({
        item: support,
      });

      this.props.navigation.navigate('AddSupportDetails', {
        edit: true,
        prevScreen: CURRENT_SCREEN,
      });
    };
    const requestBody = {
      groupId,
      categoryId,
      searchTerm: '',
      page: 1,
      itemsPerPage: 50,
    };
    if (groupId && categoryId) {
      callAPIs(
        [getSupportGroupItems(requestBody, this.props.user.demoProfile)],
        callback,
        null,
        () => {
          this.setState({loading: false});
          if (types.isWeb) {
            this.props.setLoading(false);
          }
        },
      );
    } else {
      this.setState({isLoading: false});
      if (types.isWeb) {
        this.props.setLoading(false);
        setValue('currentSupportId', support.currentSupportId);
      }

      this.props.actions.NavParamActions.setParam({
        item: support,
      });

      this.props.navigation.navigate('AddSupportDetails', {
        edit: true,
        prevScreen: CURRENT_SCREEN,
      });
    }
  }

  _renderAddByCrewButton() {
    return (
      <StandardTouchableOpacity
        style={CommonStyles.addSupportContainer}
        accessibilityLabel="Add by Crew"
        onPress={this._navigateToAddByCrew}
        readOnly={this.props.member.access.profile}
      >
        <ActionButton
          title={'by Crew'}
          actionType={types.ACTION_BUTTON.ADD}
          image={addIcon}
        />
      </StandardTouchableOpacity>
    );
  }

  _renderAddByCategoryButton() {
    return (
      <StandardTouchableOpacity
        style={CommonStyles.addSupportContainer}
        accessibilityLabel="Add by Category"
        onPress={this._navigateToAddByCategory}
        readOnly={this.props.member.access.profile}
      >
        <ActionButton
          title={'by Category'}
          actionType={types.ACTION_BUTTON.ADD}
          image={addIcon}
        />
      </StandardTouchableOpacity>
    );
  }

  _groupSupportsByCategory(supports: any, categoryId: any) {
    return supports.filter((item: any) => item.categoryId === categoryId);
  }

  _renderSupportCategories() {
    if (
      Object.getOwnPropertyNames(this.props.categories).length != 0 &&
      Object.getOwnPropertyNames(this.props.supports).length != 0 &&
      this.props.supports.supports.length !== 0
    ) {
      return this.props.categories.categories.map((category: any) => (
        <SupportCategory
          readOnly={this.props.member.access.profile}
          key={category.categoryId}
          navigation={this.props.navigation}
          Category={category}
          supports={this._groupSupportsByCategory(
            this.props.supports.supports,
            category.categoryId,
          )}
          navigateToEdit={this.navigateToEdit}
        />
      ));
    } else {
      return null;
    }
  }

  getOtherSupports() {
    const result: any = [];
    if (this.props.supports.supports) {
      this.props.supports.supports.map((support: any) => {
        if (!support.hasOwnProperty('categoryId')) {
          result.push(support);
        }
      });
    }
    return result;
  }

  renderOtherCategory() {
    return (
      <SupportCategory
        readOnly={this.props.member.access.profile}
        navigation={this.props.navigation}
        Category={{categoryId: '', description: 'other'}}
        supports={this.getOtherSupports()}
        navigateToEdit={this.navigateToEdit}
      />
    );
  }

  _createMainContents = () => {
    return (
      <View style={[CommonStyles.content, BrandStyles.primaryBgColor1]}>
        <View style={CommonStyles.containerSupportNavigation}>
          {this._renderAddByCrewButton()}
          {this._renderAddByCategoryButton()}
        </View>
        <ScrollView>
          {this._renderSupportCategories()}
          {this.renderOtherCategory()}
        </ScrollView>
        <BrandActivityIndicator loading={this.state.isLoading} />
      </View>
    );
  };
}

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
  member: state.MemberReducer,
  supports: state.SupportsReducer,
  categories: state.SupportCategoriesReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
    NavParamActions: bindActionCreators(NavigationParamActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Supports);
