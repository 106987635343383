/*
 * Author: Brad D'Costa
 * Date: 01/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Summary tile on the About Me screen for the My Details Section
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {MY_DETAILS as messages} from '../../Constants/Messages';

import {StandardText} from '../Atoms';
import {SummaryTile} from '../Molecules';

import {_generateDisplayDate} from '../../Helpers/generateDisplayDate';

import {AboutMePurple3x} from '../../assets/images/vector-icons';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class AboutMeMyDetails extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <SummaryTile
        headerDisplayString={messages.TITLE}
        headerIconImage={AboutMePurple3x}
        modal={this.props.form}
        containsData={this._determineContainsData()}
        HeaderTextColor={BrandStyles.TextColor4}
        HeaderBoarderColor={BrandStyles.borderColor4}
        actionOverride={this._determineAccess()}
      >
        {this._renderMyDetail()}
      </SummaryTile>
    );
  }

  _determineAccess = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      if (this.props.reducer.access) {
        if (this.props.reducer.access.profile) {
          if (this.props.reducer.access.profile == types.ACCESS_READ_ONLY) {
            return types.ACTION_BUTTON.VIEW;
          }
        }
      }
    }
    return null;
  };

  _determineContainsData = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return false;
    } else {
      var checkArray = [
        this.props.reducer.address,
        this.props.reducer.city,
        this.props.reducer.dateOfBirth,
        this.props.reducer.email,
        this.props.reducer.firstName,
        this.props.reducer.knownAs,
        this.props.reducer.lastName,
        this.props.reducer.ndisMeeting,
        this.props.reducer.postcode,
        this.props.reducer.state,
      ];
      var dataCheck = false;
      for (var i = 0; i < checkArray.length; i++) {
        if (checkArray[i] !== '') {
          dataCheck = true;
          break;
        }
      }
      return dataCheck;
    }
  };

  _renderMyDetail = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      return (
        <View>
          {this._renderNameSummary()}
          {this._renderKnownAsSummary()}
          {this._renderDateOfBirthSummary()}
          {this._renderAddressSummary()}
          {this._renderEmailSummary()}
          {this._renderNdisMeetingSummary()}
          {this._renderPhoneNumberSummary()}
        </View>
      );
    }
  };

  _renderNameSummary = () => {
    if (
      this.props.reducer.firstName == '' &&
      this.props.reducer.lastName == ''
    ) {
      return null;
    } else {
      return (
        <View
          accessible={true}
          style={[
            CommonStyles.flexRow,
            CommonStyles.flexWrap,
            CommonStyles.alignItemsCenter,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFontBoldOriginal,
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
            ]}
          >
            Name:{' '}
          </StandardText>
          <StandardText
            style={[
              CommonStyles.customFont,
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
            ]}
          >
            {this.props.reducer.firstName} {this.props.reducer.lastName}
          </StandardText>
        </View>
      );
    }
  };

  _renderKnownAsSummary = () => {
    if (this.props.reducer.knownAs == '') {
      return null;
    } else {
      return (
        <View
          accessible={true}
          style={[
            CommonStyles.flexRow,
            CommonStyles.flexWrap,
            CommonStyles.alignItemsCenter,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFontBoldOriginal,
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
            ]}
          >
            Call Me:{' '}
          </StandardText>
          <StandardText
            style={[
              CommonStyles.customFont,
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
            ]}
          >
            {this.props.reducer.knownAs}
          </StandardText>
        </View>
      );
    }
  };

  _renderDateOfBirthSummary = () => {
    if (this.props.reducer.dateOfBirth == '') {
      return null;
    } else {
      let parsedDate = new Date(this.props.reducer.dateOfBirth);
      let displayDate = _generateDisplayDate(parsedDate);
      return (
        <View
          accessible={true}
          style={[
            CommonStyles.flexRow,
            CommonStyles.flexWrap,
            CommonStyles.alignItemsCenter,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFontBoldOriginal,
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
            ]}
          >
            Born:{' '}
          </StandardText>
          <StandardText
            style={[
              CommonStyles.customFont,
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
            ]}
          >
            {displayDate}
          </StandardText>
        </View>
      );
    }
  };

  _renderAddressSummary = () => {
    if (
      this.props.reducer.address == '' &&
      this.props.reducer.city == '' &&
      this.props.reducer.postcode == '' &&
      this.props.reducer.state == ''
    ) {
      return null;
    } else {
      if (this.props.reducer.address == '') {
        return (
          <View
            accessible={true}
            style={[
              CommonStyles.flexRow,
              CommonStyles.flexWrap,
              CommonStyles.alignItemsCenter,
            ]}
          >
            <StandardText
              style={[
                CommonStyles.customFontBoldOriginal,
                CommonStyles.SummaryTileText,
                BrandStyles.TextColor5,
              ]}
            >
              Lives At:{' '}
            </StandardText>
            <StandardText
              style={[
                CommonStyles.customFont,
                CommonStyles.SummaryTileText,
                BrandStyles.TextColor5,
              ]}
            >
              {this.props.reducer.city} {this.props.reducer.postcode}{' '}
              {this.props.reducer.state}
            </StandardText>
          </View>
        );
      } else {
        return (
          <View
            accessible={true}
            style={[
              CommonStyles.flexRow,
              CommonStyles.flexWrap,
              CommonStyles.alignFlexStart,
            ]}
          >
            <StandardText
              style={[
                CommonStyles.customFontBoldOriginal,
                CommonStyles.SummaryTileText,
                BrandStyles.TextColor5,
              ]}
            >
              Lives At:{' '}
            </StandardText>
            <StandardText
              style={[
                CommonStyles.customFont,
                CommonStyles.SummaryTileText,
                CommonStyles.marginTop2,
                BrandStyles.TextColor5,
              ]}
            >
              {this.props.reducer.apartment} {this.props.reducer.address},{' '}
              {this.props.reducer.city} {this.props.reducer.postcode}{' '}
              {this.props.reducer.state}
            </StandardText>
          </View>
        );
      }
    }
  };

  _renderEmailSummary = () => {
    if (this.props.reducer.email == '') {
      return null;
    } else {
      return (
        <View
          accessible={true}
          style={[
            CommonStyles.flexRow,
            CommonStyles.flexWrap,
            CommonStyles.alignItemsCenter,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFontBoldOriginal,
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
            ]}
          >
            Email:{' '}
          </StandardText>
          <StandardText
            style={[
              CommonStyles.customFont,
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
            ]}
          >
            {this.props.reducer.email}
          </StandardText>
        </View>
      );
    }
  };

  _renderPhoneNumberSummary = () => {
    if (this.props.reducer.phoneNumber == '') {
      return null;
    } else {
      return (
        <View
          accessible={true}
          style={[
            CommonStyles.flexRow,
            CommonStyles.flexWrap,
            CommonStyles.alignItemsCenter,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFontBoldOriginal,
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
            ]}
          >
            Phone Number:{' '}
          </StandardText>
          <StandardText
            style={[
              CommonStyles.customFont,
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
            ]}
          >
            {this.props.reducer.phoneNumber}
          </StandardText>
        </View>
      );
    }
  };

  _renderNdisMeetingSummary = () => {
    if (this.props.reducer.ndisMeeting == '') {
      return null;
    } else {
      let parsedDate = new Date(this.props.reducer.ndisMeeting);
      let displayDate = _generateDisplayDate(parsedDate);
      return (
        <View
          accessible={true}
          style={[
            CommonStyles.flexRow,
            CommonStyles.flexWrap,
            CommonStyles.alignItemsCenter,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFontBoldOriginal,
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
            ]}
          >
            NDIS Meeting Date:{' '}
          </StandardText>
          <StandardText
            style={[
              CommonStyles.customFont,
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
            ]}
          >
            {displayDate}
          </StandardText>
        </View>
      );
    }
  };
}

export default AboutMeMyDetails;
