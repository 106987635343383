/*
 * Author: Roshan Piumal
 * Date: 02/10/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 */
import React, {Component} from 'react';
import {View} from 'react-native';
import {StandardTouchableOpacity, StandardText} from '../Atoms';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {BrandColors} from '../../Styles/Colours';
import CustomIcon from '../../assets/images/CustomIcon';
import {backArrowblack, skipButton} from '../../assets/images/vector-icons';
import PropTypes from 'prop-types';
import {PAGINATION_NAVIGATOR} from '../../Constants/Messages';

class PaginationNavigator extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    if (this.hasNextPage() || this.hasPreviousPage()) {
      return (
        <View style={CommonStyles.flex1}>
          <View style={[CommonStyles.ModalActionButtons, CommonStyles.flex1]}>
            <View style={CommonStyles.justifyContentFlexStart}>
              {this.renderGoToPrevious()}
            </View>
            <View style={CommonStyles.ModalFooterRight}>
              {this.renderGoToNext()}
            </View>
          </View>
          <View
            style={[
              CommonStyles.flex1,
              CommonStyles.flexRow,
              CommonStyles.justifyContentCentre,
            ]}
          >
            <StandardText
              style={[
                CommonStyles.textResultSummary,
                CommonStyles.customFontBold,
                BrandStyles.TextColor13,
              ]}
            >
              {`${PAGINATION_NAVIGATOR.DISPLAYING_PAGE} ${this.props.pageNumber} ${PAGINATION_NAVIGATOR.OF} ${this.props.totalPages}`}
            </StandardText>
          </View>
        </View>
      );
    } else {
      return null;
    }
  }

  renderGoToNext = () => {
    if (this.hasNextPage()) {
      return (
        <StandardTouchableOpacity
          accessible
          accessibilityLabel={PAGINATION_NAVIGATOR.NEXT}
          activeOpacity={0.6}
          onPress={() => this.props.goToNextPage()}
        >
          <View
            style={[CommonStyles.alignItemsCenter, CommonStyles.paddingRight20]}
          >
            <CustomIcon
              style={[
                CommonStyles.paginationIcon,
                {
                  color: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
                },
              ]}
              name={skipButton}
            />
            <StandardText
              style={[
                CommonStyles.Transparent,
                CommonStyles.customFontBold,
                CommonStyles.paddingTop10,
                {
                  color: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
                },
                CommonStyles.paginationActionText,
              ]}
            >
              {`${PAGINATION_NAVIGATOR.NEXT} ${this.props.pageSize} ${
                this.props.categorizeBy ? this.props.categorizeBy : ''
              }`}
            </StandardText>
          </View>
        </StandardTouchableOpacity>
      );
    }
  };

  renderGoToPrevious = () => {
    if (this.hasPreviousPage()) {
      return (
        <StandardTouchableOpacity
          accessible
          accessibilityLabel={PAGINATION_NAVIGATOR.PREVIOUS}
          activeOpacity={0.6}
          onPress={() => this.props.goToPreviousPage()}
        >
          <View
            style={[CommonStyles.alignItemsCenter, CommonStyles.paddingLeft20]}
          >
            <CustomIcon
              style={[
                CommonStyles.paginationIcon,
                {
                  color: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
                },
              ]}
              name={backArrowblack}
            />
            <StandardText
              style={[
                CommonStyles.Transparent,
                CommonStyles.customFontBold,
                CommonStyles.paddingTop10,
                {
                  color: BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
                },
                CommonStyles.paginationActionText,
              ]}
            >
              {`${PAGINATION_NAVIGATOR.PREVIOUS} ${this.props.pageSize} ${
                this.props.categorizeBy ? this.props.categorizeBy : ''
              }`}
            </StandardText>
          </View>
        </StandardTouchableOpacity>
      );
    }
  };

  hasNextPage = () => this.props.pageNumber < this.props.totalPages;

  hasPreviousPage = () => this.props.pageNumber > 1;
}

PaginationNavigator.prototypes = {
  pageNumber: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  goToNextPage: PropTypes.func.isRequired,
  goToPreviousPage: PropTypes.func.isRequired,
};

export default PaginationNavigator;
