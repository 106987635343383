/*
 * Author: Declan Hart
 * Date: 15/07/2019
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays a the tiles on invoice details page
 */
import React, {Component} from 'react';
import {View, ScrollView} from 'react-native';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {StandardText} from '../Atoms';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

class InvoiceDetailsTile extends Component {
  render() {
    return (
      <View
        style={[
          CommonStyles.justifyFlexColumnStart,
          CommonStyles.invoiceApprovalDetailWrapper,
          CommonStyles.height100,
          types.isWeb ? null : CommonStyles.minHeight300,
        ]}
        accessible={true}
      >
        <StandardText
          style={[
            CommonStyles.customFontSemiBold,
            types.isWeb ? CommonStyles.font15 : CommonStyles.font18,
            BrandStyles.TextColor10,
            CommonStyles.marginLeft10,
            CommonStyles.marginBottom20,
          ]}
        >
          {this.props.title}
        </StandardText>
        <ScrollView style={[CommonStyles.content, CommonStyles.maxHeight400]}>
          {this.props.contents}
        </ScrollView>
      </View>
    );
  }
}
export default InvoiceDetailsTile;
