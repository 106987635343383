/*
 * Author: Shelan Cooray
 * Date: 13/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Componant for indicate form fields which are required.
 */

import React, {Component} from 'react';
import {Image, View} from 'react-native';

import {StandardText} from '../Atoms';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import {InformationIconx3} from '../../assets/images';

class RequiredIndicator extends Component {
  constructor(props: any) {
    super(props);
  }

  _renderTextStyles = () => {
    let styles = [
      this.props.style,
      CommonStyles.formRequiredText,
      CommonStyles.customFontBold,
    ];

    if (this.props.textColor) {
      styles.push(this.props.textColor);
    } else {
      styles.push(BrandStyles.TextColor10);
    }
    return styles;
  };

  _renderText = () => {
    if (this.props.text) {
      return this.props.text;
    } else {
      return types2.REQUIRED;
    }
  };

  _renderImage = (imageSource: any) => {
    let styles = [];
    if (this.props.iconStyle) {
      styles = this.props.iconStyle;
    } else {
      styles = [
        CommonStyles.requiredIndicatorIcon,
        CommonStyles.titleBoxIconMargin,
      ];
    }

    if (imageSource === undefined) {
      return <Image style={styles} source={InformationIconx3} />;
    } else {
      return <Image style={styles} source={imageSource} />;
    }
  };

  render() {
    return (
      <View style={[CommonStyles.formRequiredContainer]}>
        {this._renderImage(this.props.imageSource)}
        <StandardText style={this._renderTextStyles()}>
          {this._renderText()}
        </StandardText>
      </View>
    );
  }
}

export default RequiredIndicator;
