/*
 * Author: Roshan Piumal
 * Date: 16/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Navigation Confirmation consent modal
 */

import React, {Component} from 'react';
import {View, Linking, Text, Image} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// import Orientation from 'react-native-orientation-enhanced';

import {StandardButton, StandardText} from '../Atoms';
import BasicOverlayPopupModal from './BasicOverlayPopupModal';

import {NAVIGATION_CONFIRM_MODAL, MESSAGES} from '../../Constants/Messages';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import * as NavigationConfirmationActions from '../../Actions/NavigationActions';
import * as TabBarActions from '../../Actions/TabBarActions';
import * as SideMenuActions from '../../Actions/SideMenuActions';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {InformationIconx3} from '../../assets/images';

import DeviceInfo from 'react-native-device-info';
import {getStyleByOrientation} from '../../Helpers/GetStyleByOrientation'
import {
  continueNavigation,
  determineShowNavigationConfirmationOrContinue,
} from '../../Helpers/Utils';
import {isEmpty, isFunction} from 'lodash';

class NavigationConfirmModal extends Component {
  state = {
    isVisible: false,
    orientation: '',
  };

  constructor(props: any) {
    super(props);
  }

  // UNSAFE_componentWillMount() {
  //   Orientation.getOrientation((err: any, orientation: any) => {
  //     this.setState({
  //       orientation: orientation,
  //     });
  //   });
  // }

  componentDidMount() {
    // Orientation.addOrientationListener(this._orientationDidChange);
  }

  componentWillUnmount() {
    // Orientation.removeOrientationListener(this._orientationDidChange);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (
      this._determineShowNavigationConfirmationOrContinue(
        nextProps.navReducer,
        this.props.currentScreenName,
        this.props.hasFormContentChanged,
      )
    ) {
      this.props.actions.NavigationConfirmationActions.showConfirmNavigation();
    }
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation: orientation,
    });
  };

  render() {
    let isTablet = DeviceInfo.isTablet();
    return (
      <BasicOverlayPopupModal
        visible={this.props.navReducer.showConfirmNavigation}
        style={this._determinePopUpStyle(isTablet)}
        backdropOpacity={0.2}
        divider={false}
        close={this._closeNavigationConfirmModal}
        hideCloseButton={true}
        cancelContainerStyle={CommonStyles.popUpOkButton}
        cancelStyle={[CommonStyles.buttonPopupOk, CommonStyles.customFontBold]}
        cancelLabel={types2.OK_GOT_IT}
      >
        <View>{this.getPopUpContent(isTablet)}</View>
      </BasicOverlayPopupModal>
    );
  }

  _determinePopUpStyle = (isTablet: any) => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (isTablet) {
      styles.push([
        CommonStyles.containerInstructionPopUpTablet,
        CommonStyles.height370,
      ]);
    } else {
      styles.push(
        CommonStyles.containerInstructionPopUpMobile,
        CommonStyles.height300,
      );
    }
    return styles;
  };

  getPopUpContent(isTablet: any) {
    return (
      <View style={[]}>
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.scrollView_subContentCenter,
            CommonStyles.paddingBottom5p,
            CommonStyles.marginLeft20,
            CommonStyles.marginRight20,
          ]}
        >
          <View>
            <Image
              style={[CommonStyles.changePasswordButtonImage]}
              source={InformationIconx3}
              accessibilityLabel={MESSAGES.ACCESSIBILITY.IMPORTANT_INFORMATION}
              accessible={true}
            />
          </View>
          <View style={[CommonStyles.scrollView_subContentCenter]}>
            <StandardText
              style={[
                isTablet ? CommonStyles.font25 : CommonStyles.font20,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
              ]}
            >
              {NAVIGATION_CONFIRM_MODAL.NAVIGATION_TITLE}
            </StandardText>
          </View>
        </View>
        <View
          style={[
            CommonStyles.paddingTop5,
            CommonStyles.scrollView_subContentCenter,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFont,
              BrandStyles.TextColor5,
              isTablet ? CommonStyles.font18 : CommonStyles.rpfont15,
            ]}
          >
            {NAVIGATION_CONFIRM_MODAL.NAVIGATION_CONFIRMATION_MESSAGE}
          </StandardText>
        </View>
        <View
          style={[
            CommonStyles.containerInstructionDevider,
            CommonStyles.paddingBottom5p,
          ]}
        />
        <View style={[CommonStyles.ModalActionButtons]}>
          <View style={CommonStyles.ModalFooterLeft}>
            <StandardButton
              onPress={this._closeNavigationConfirmModal}
              style={[
                BrandStyles.brandBlockTxtColor6,
                CommonStyles.buttonFormAction,
                CommonStyles.customFontBold,
              ]}
              containerStyle={[
                BrandStyles.primaryBgColor1,
                BrandStyles.brandBorderColor1,
                CommonStyles.buttonContainerFormAction,
              ]}
              accessibilityLabel={
                NAVIGATION_CONFIRM_MODAL.NAVIGATION_CONFIRM_MODAL_CANCEL
              }
            >
              {NAVIGATION_CONFIRM_MODAL.NAVIGATION_CONFIRM_MODAL_CANCEL}
            </StandardButton>
          </View>
          <View style={CommonStyles.ModalFooterRight}>
            <StandardButton
              onPress={() => this._continueNavigation(this.props.navReducer)}
              style={[
                BrandStyles.brandBlockTxtColor5,
                CommonStyles.buttonFormAction,
                CommonStyles.customFontBold,
              ]}
              containerStyle={[
                BrandStyles.primaryBgColor4,
                BrandStyles.brandBorderColor1,
                CommonStyles.buttonContainerFormAction,
              ]}
              accessibilityLabel={
                NAVIGATION_CONFIRM_MODAL.NAVIGATION_CONFIRM_MODAL_OK
              }
            >
              {NAVIGATION_CONFIRM_MODAL.NAVIGATION_CONFIRM_MODAL_OK}
            </StandardButton>
          </View>
        </View>
      </View>
    );
  }

  _continueNavigation = (navReducer: any) => {
    const scene = navReducer.navigateToIndexParams.scene;
    navReducer.navigateToIndexParams.navigation.popToTop();

    this.props.actions.TabBarActions.addSelectedTab(scene.route.routeName);
    this.props.actions.SideMenuActions.setCurrentScreen(scene.route.routeName);

    navReducer.navigateToIndex(
      navReducer.navigateToIndexParams.navigation,
      navReducer.navigateToIndexParams.scene,
      navReducer.navigateToIndexParams.jumpToIndex,
      navReducer.navigateToIndexParams.previousScene,
    );
    return this.props.actions.NavigationConfirmationActions.hideConfirmNavigation();
  };

  _determineShowNavigationConfirmationOrContinue = (
    navReducer: any,
    currentScreenName: any,
    hasFormContentChanged: any,
  ) => {
    // Do the initial validation that is required to execute the function
    if (
      isEmpty(navReducer) ||
      isEmpty(navReducer.navigateToIndexParams) ||
      !isFunction(navReducer.navigateToIndex) ||
      !currentScreenName ||
      !isFunction(hasFormContentChanged)
    ) {
      return false;
    }

    let previousScreen = navReducer.navigateToIndexParams.previousScene;
    let previousScreenRouteName =
      previousScreen.routes[previousScreen.index].routeName;

    if (
      previousScreenRouteName !== currentScreenName ||
      navReducer.showConfirmNavigation ||
      !navReducer.determineShowConfirmNavigation
    ) {
      return false;
    }

    if (hasFormContentChanged()) {
      // If the form content has changed fire the action to show the modal
      return true;
    }
    // if the form content has not been changed continue the navigation
    this._continueNavigation(navReducer);
    return false;
  };

  _closeNavigationConfirmModal = () => {
    return this.props.actions.NavigationConfirmationActions.hideConfirmNavigation();
  };
}

const mapStateToProps = (state: any) => ({
  navReducer: state.NavigationReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    NavigationConfirmationActions: bindActionCreators(
      NavigationConfirmationActions,
      dispatch,
    ),
    TabBarActions: bindActionCreators(TabBarActions, dispatch),
    SideMenuActions: bindActionCreators(SideMenuActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NavigationConfirmModal);
