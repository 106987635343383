/*
 * Author: Andrew Seeley
 * Date: 30/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a standard button component which adds support for the following
 * - Set's the default accessibilitylabel, this is needed for:
 *     - Automation testing with RN - their tools use this label as RN does not have support for tag
 *     - Ensures voiceover follows Google guidelines as per https://support.google.com/accessibility/android/answer/6378990?hl=en
 */

import React, {Component} from 'react';
import {View, StyleSheet} from 'react-native';
import Button from 'react-native-button';
import {ACCESS_READ_ONLY} from '../../Constants/Constants';
import {COMPONENT_TYPES} from '../../Constants/Constants2';
import CommonStyles from '../../Styles/CommonStyles';

class StandardButton extends Component {
  constructor(props: any) {
    super(props);
    this.isString.bind(this);
  }

  render() {
    const noAccessibilityLabelError =
      'Pass an accessibilityLabel to StandardButton as there is no text for it to set from';

    let buttonType = COMPONENT_TYPES.BUTTON;
    if (this.props.buttonType) {
      buttonType = this.props.buttonType;
    }
    let accessibilityLabel = '';

    // If an accessibility label is set use it,
    // Otherwise us the button text for its label
    if (this.props.accessibilityLabel) {
      accessibilityLabel = this.props.accessibilityLabel;
    } else {
      // Any sort of children could be passed to this button, e.g some buttons use images instead.
      // So first of all extract any test that may have been passed, if any we use that for the accessibility label
      // Otherwise if there is no text passed (E.g just a button with an image only) throw an error as we will

      // If multiple items are passed in the props children
      // Look for any text and concatenate it to use it as the accessibility label
      if (Array.isArray(this.props.children)) {
        accessibilityLabel = '';
        for (const i in this.props.children) {
          // If it's a string build the accessibility label
          if (this.isString(this.props.children[i])) {
            accessibilityLabel += this.props.children[i];
          }
        }

        // If there was no text passed in throw an exception as the accessibilityLabel will need to be set manually
        if (accessibilityLabel == '') {
          throw noAccessibilityLabelError;
        }
      } else {
        // No array was passed in, it's just a single item so check if it's just a string
        if (this.isString(this.props.children)) {
          accessibilityLabel = this.props.children;
          // If there was no text passed in throw an exception as the accessibilityLabel will need to be set manually
        } else {
          throw noAccessibilityLabelError;
        }
      }
    }

    let readOnly = false;
    if (this.props.readOnly == ACCESS_READ_ONLY) {
      readOnly = true;
    } else {
      readOnly = false;
    }

    if (readOnly == true) {
      return null;
    }

    if (buttonType === COMPONENT_TYPES.BUTTON) {
      return (
        <Button
          accessibilityLabel={accessibilityLabel}
          style={[CommonStyles.alignButtonText, this.props.style]}
          containerStyle={[this.props.containerStyle]}
          onPress={this.props.onPress}
          disabled={this.props.disabled}
          allowFontScaling={this.props.allowFontScaling}
        >
          {this.props.children}
        </Button>
      );
    } else {
      return (
        <View
          accessible={true}
          accessibilityLabel={accessibilityLabel + ' ' + buttonType}
        >
          <Button
            style={[CommonStyles.alignButtonText, this.props.style]}
            containerStyle={[this.props.containerStyle]}
            onPress={this.props.onPress}
            disabled={this.props.disabled}
            allowFontScaling={this.props.allowFontScaling}
          >
            {this.props.children}
          </Button>
        </View>
      );
    }
  }

  isString(item: any) {
    if (item instanceof String || typeof item === 'string') {
      return true;
    }
    return false;
  }
}

export default StandardButton;
