/*
 * Author: Anuja Ulpathakumbura
 * Date: 15/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Logout reducer
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function LogoutReducer(
  state = {showConfirmLogout: false, logUserOut: false},
  action: any,
) {
  switch (action.type) {
    case types.SHOW_LOGOUT_CONFIRMATION:
      return {
        ...state,
        showConfirmLogout: true,
      };
    case types.HIDE_LOGOUT_CONFIRMATION:
      return {
        ...state,
        showConfirmLogout: false,
        logUserOut: action.payload.logUserOut,
      };
    case types.LOGOUT_USER:
      return {
        ...state,
        logUserOut: action.logUserOut,
        demoProfile: undefined,
        showCase: undefined,
      };
    default:
      return state;
  }
}
