import React, {Component} from 'react';
import {View} from 'react-native';
import {GridView, FormTitle, RequiredIndicator} from './';
import {StandardInput} from '../Atoms';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {ADD_CREW as crewMessages} from '../../Constants/Messages';
import {isWeb} from '../../Constants/Constants';
import { DOMPurify } from 'helpers/dompurify';
const INPUTS = {
  PHONE_NUMBER: 'phoneNumber',
  EMAIL: 'email',
};

class CrewFirstName extends Component {
  inputs: any;
  constructor() {
    super();
    this.inputs = {};
  }

  render() {
    return (
      <View
        style={[
          CommonStyles.marginTop10,
          isWeb ? CommonStyles.marginRight10 : {},
        ]}
      >
        <FormTitle
          containsData={this.props.firstName}
          text={crewMessages.PROFILE.FIRST_NAME}
          style={[CommonStyles.customFontBold, CommonStyles.AboutMeModalLabel]}
        />
        <View style={[CommonStyles.rightAboutMeModalContentContainer]}>
          <RequiredIndicator />
          <StandardInput
            readOnly={this.props.readOnly}
            value={this.props.firstName}
            accessibilityLabel="First Name"
            onRef={(ref: any) => {
              this.props.inputs[this.props.currentInputRef] = ref;
            }}
            onSubmitEditing={() => {
              this.props.focusNextField(
                this.props.inputs,
                this.props.nextInputRef,
              );
            }}
            returnKeyType={'next'}
            onChangeText={(firstName: any) =>{
              const sanitizedFirstName = DOMPurify.sanitize(firstName);

              this.props.editFirstName(sanitizedFirstName)
            }}
            blurOnSubmit={false}
            style={[
              CommonStyles.ModalTextInputWrapper,
              BrandStyles.primaryBgColor1,
              CommonStyles.customFont,
            ]}
            underlineColorAndroid="transparent"
            errorMessage={this.props.validFirstName}
          />
        </View>
      </View>
    );
  }
}

export default CrewFirstName;
