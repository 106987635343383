/*
 * Author: Mayuran Kunarajah
 * Date: 01/03/2019
 * Copyright © 2019 Leap in!. All rights reserved.
 *
 */
import {getUploadURL} from '../API/MemberAPI';
import {callAPIs} from '../API/APICaller';
import {SingletonFileUploader} from './PlatformSynchronizer';
class FileUploader {
  signedUrl = null;
  succesCallBack = null;
  failureCallBack = null;
  file = null;
  memberId = null;

  constructor(memberId: any, file: any, succesBlock: any, failBlock: any) {
    this.file = file;
    this.succesCallBack = succesBlock;
    this.failureCallBack = failBlock;
    this.memberId = memberId;
    this.startUploading = this.startUploading.bind(this);
  }
  startUploading = (requestObject: any) => {
    const that = this;
    const getUploadURLCallbackFunction = (data: any) => {
      that.signedUrl = data[0].content.uploadURL;
      SingletonFileUploader(that.signedUrl, that.file)
        .then(() => {
          that.succesCallBack();
        })
        .catch(error => {
          that.failureCallBack(error);
        });
    };

    callAPIs(
      [
        getUploadURL(
          this.memberId,
          requestObject,
          getUploadURLCallbackFunction,
        ),
      ],
      getUploadURLCallbackFunction,
      null,
      () => null,
    );
  };
}
export default FileUploader;
