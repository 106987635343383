/*
 * Author: Andrew Seeley
 * Date: 20/11/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Splash landing screen for the app.
 */

import React, {Component} from 'react';
import {View, Alert, BackHandler, Linking, Platform} from 'react-native';
import {announceForAccessibility} from '../Helpers/PlatformSynchronizer';
import {CommonActions} from '@react-navigation/native';
//import {NavigationActions, StackActions} from 'react-navigation';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {routeTolanding} from '../Components/RouteToLanding/RouteToLanding';
import {BrandActivityIndicator} from '../Components/Molecules';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {getDataLoggedIn} from '../API/StorageHelper';

import * as AuthenticationActions from '../Actions/AuthenticationActions';
import * as AdminActions from '../Actions/AdminActions';
import * as loadedMemberActions from '../Actions/LoadedMemberActions';
import * as MemberActions from '../Actions/MemberActions';
import * as UserActions from '../Actions/UserActions';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import * as brand from '../Constants/BrandConstants';
import {
  ERRORS,
  COMMON_BUTTONS,
  ACCESSIBILITY_SCREEN_TITLES,
} from '../Constants/Messages';
import * as NotificationActions from '../Actions/NotificationActions';
import BasicPopupAlert from '../Components/Organisms/BasicPopupAlert';
import {preAuthenticate, logUserOut} from '../API/PreauthenticationHelper';

// import Database from '../API/Database';
import {getValue, setValue, removeItem} from '../API/WebPersistenceStore';
import {
  navigateToBooKMarkURL,
  webBookMarkUrl,
  redirectToForgotPassword,
} from '../Helpers/SignupNavigationHelper';
import {OfflineNotice} from '../Components/Organisms';
import {APP_ID} from '../environments';
import logger from 'helpers/Logger';

import DeviceInfo from 'react-native-device-info';
// import Orientation from 'react-native-orientation-enhanced';

import {getStoreUrl} from '../Helpers/Utils';
import {isEmpty} from 'lodash';

const LOGINSIGNUP_ROUTE = 'Welcome';
const SCHEMA = 'schema';

class Splash extends Component {
  state = {
    autoLoginCheckComplete: false,
    loading: false,
    errorPopUpVisible: false,
    // Setting up the initial device orientation to load the corresponding video as splash screen
    orientation: types.isWeb
      ? types2.ORIENTATION.LANDSCAPE
      : types2.ORIENTATION.LANDSCAPE,
  };

  constructor(props: any) {
    super(props);

    this.updateAutoLoginFlag.bind(this);
    this.navigateToLanding.bind(this);
    this._closeAlert.bind(this);
    this._showAlert.bind(this);
  }

  _closeAlert() {
    const updateLink = getStoreUrl(Platform.OS);
    Linking.openURL(updateLink);
    this.setState({
      errorPopUpVisible: false,
    });
    BackHandler.exitApp();
  }

  UNSAFE_componentWillMount() {
    if (types.isWeb) {
      const cognitoId = getValue('awsIdentity');
      const urlParams = new URLSearchParams(window.location.search);
      const schema = urlParams.get(SCHEMA);
      if (schema) {
        // Store if navigated from a deeplink to view banner
        setValue(types2.DEEPLINK_NAVIGATION, true);
        setValue(types2.DEEPLINK_URL, window.location.href);
      } else {
        setValue(types2.DEEPLINK_NAVIGATION, false);
      }

      if (cognitoId) {
        if (getValue(types2.BOOK_MARK_PARAMS) !== null) {
          navigateToBooKMarkURL(
            window,
            JSON.parse(getValue(types2.BOOK_MARK_PARAMS)),
          );
        } else {
          if (schema) {
            window.open(webBookMarkUrl(window, true), '_self');
          } else {
            this.props.navigation.navigate('Home');
          }
        }
      } else {
        if (schema) {
          if (redirectToForgotPassword(window) === '') {
            setValue(types2.BOOK_MARK_PARAMS, webBookMarkUrl(window, false));
          }

          if (types.isWeb) {
            if (redirectToForgotPassword(window)) {
              window.open(redirectToForgotPassword(window), '_self');
            }
          }
        }

        /* Check if user has previously logged in using local storage
        If logged in redirect to login
        If not redirect to sign up screen */
        getDataLoggedIn(types.PREVIOUSLY_SIGNIN).then(alreadyloggedIn => {
          this.props.navigation.navigate(LOGINSIGNUP_ROUTE, {
            screenMode: alreadyloggedIn
              ? types.LOGINSIGNUP_MODE_LOGIN
              : types.LOGINSIGNUP_MODE_SIGNUP,
            enableBack: false,
          });
        });
      }
    }
  }
  componentDidMount() {
    this.setState({loading: true});

    let readoutText1 = ACCESSIBILITY_SCREEN_TITLES.SPLASH_PART1;
    let brand = '';
    let poweredBy = '';
    if (APP_ID === types2.APP_ID.LEAPIN) {
      brand = types2.USER_FRIENDLY_APP_NAME.LEAPIN;
    } else if (APP_ID === types2.APP_ID.BLUE_CARE) {
      brand = types2.USER_FRIENDLY_APP_NAME.BLUE_CARE;
      poweredBy = ACCESSIBILITY_SCREEN_TITLES.SPLASH_PART3;
    } else if (APP_ID === types2.APP_ID.UCQ) {
      brand = types2.USER_FRIENDLY_APP_NAME.UCQ;
      poweredBy = ACCESSIBILITY_SCREEN_TITLES.SPLASH_PART3;
    } else if (APP_ID === types2.APP_ID.WESLEY) {
      brand = types2.USER_FRIENDLY_APP_NAME.WESLEY;
      poweredBy = ACCESSIBILITY_SCREEN_TITLES.SPLASH_PART3;
    } else if (APP_ID === types2.APP_ID.UCN) {
      brand = types2.USER_FRIENDLY_APP_NAME.UCN;
      poweredBy = ACCESSIBILITY_SCREEN_TITLES.SPLASH_PART3;
    }
    let readoutText2 = ACCESSIBILITY_SCREEN_TITLES.SPLASH_PART2;
    //announceForAccessibility(readoutText1 + brand + readoutText2 + poweredBy);

    // Check if previous session exists
    // Open up the local database
    // Database.sharedInstance.openDatabase();
    preAuthenticate(this.updateAutoLoginFlag, this.navigateToLanding, true);
  }

  onEnd() {
    this.setState({loading: true});

    this.navigateToLanding();
  }

  updateAutoLoginFlag = () => {
    this.state.autoLoginCheckComplete = true;
  };

  navigateToLanding = () => {
    if (this.state.autoLoginCheckComplete) {
      if (
        Object.getOwnPropertyNames(this.props.auth).length === 0 ||
        (this.props.auth && !this.props.auth.loggedIn)
      ) {
        // No existing user
        // Navigate to the pre authentication page & set it to be the root of the stack so the back button will then close the
        //app.
        getDataLoggedIn(types.PREVIOUSLY_SIGNIN).then(alreadyloggedIn => {
          const actionToDispatch = CommonActions.reset({
            index: 0,
            routes: [ 
              {name:LOGINSIGNUP_ROUTE,  params: {
              screenMode: alreadyloggedIn
                ? types.LOGINSIGNUP_MODE_LOGIN
                : types.LOGINSIGNUP_MODE_SIGNUP,
            } }
            ],
          });
         this.props.navigation.dispatch(actionToDispatch);
        });
        this.setState({loading: false});
      } else {
        // Existing user
        let callbackFunction = (
          routingObj: any,
          memberData: any,
          ManagedMemberCount: any,
          flags: any,
        ) => {
          if (flags && flags.length > 0) {
            this.props.actions.adminActions.actionGetFeatureFlags(flags);
          }
          if (routingObj.code == '404') {
            // Will throw an alert to the user when attempting to login to a user account that doesn't exist
            Alert.alert(types2.ERROR, ERRORS.NO_ACCOUNT);
            // Navigate to the pre authentication page & set it to be the root of the stack so the back button will then close the app.
            getDataLoggedIn(types.PREVIOUSLY_SIGNIN).then(alreadyloggedIn => {
              const actionToDispatch = CommonActions.reset({
                index: 0,
                routes: [{name: LOGINSIGNUP_ROUTE, params: {
                  screenMode: alreadyloggedIn
                    ? types.LOGINSIGNUP_MODE_LOGIN
                    : types.LOGINSIGNUP_MODE_SIGNUP,
                }, }
                ],
              });
              this.props.navigation.dispatch(actionToDispatch);
            });
            this.setState({loading: false});
          } else if (
            routingObj.statusCode == types.COGNITO_INVALID_SIGNATURE_ERROR
          ) {
            // Will throw an alert to the user when attempting to login with an invalid signature
            Alert.alert(types2.ERROR, ERRORS.GENERIC_ISSUE_ALERT_MSG);
            // Navigate to the pre authentication page & set it to be the root of the stack so the back button will then close the app.
            getDataLoggedIn(types.PREVIOUSLY_SIGNIN).then(alreadyloggedIn => {
              const actionToDispatch = CommonActions.reset({
                index: 0,
                routes: [ {name: LOGINSIGNUP_ROUTE,  params: {
                  screenMode: alreadyloggedIn
                    ? types.LOGINSIGNUP_MODE_LOGIN
                    : types.LOGINSIGNUP_MODE_SIGNUP,
                }, }

                ],
              });
              this.props.navigation.dispatch(actionToDispatch);
            });
            this.setState({loading: false});
          } else if (
            routingObj.code &&
            routingObj.code == types.VERSION_ERROR
          ) {
            this.setState({
              latestVersion: routingObj.message,
            });
            // The user is using an app version that is no longer supported, so display message to the user
            this._showAlert(
              ERRORS.UPDATE_AVAILABLE,
              ERRORS.FORCE_UPDATE_TEXT,
              COMMON_BUTTONS.UPDATE,
              this._closeAlert.bind(this),
              true,
            );
          } else if (routingObj.params.userObj.user.active) {
            // Update the redux store with the ID we want to load the initial data for
            const isUserOnlyMember =
              routingObj.params.userObj.isMember &&
              routingObj.params.userObj.numberOfMembers == 1;
            if (
              routingObj.params.userObj.managedMemberId != undefined &&
              !isUserOnlyMember
            ) {
              this.props.actions.loadedMemberActions.actionGetLoadedMemberId(
                routingObj.params.userObj.managedMemberId,
              );
            } else {
              this.props.actions.loadedMemberActions.actionGetLoadedMemberId(
                routingObj.params.userObj.user.id,
              );
            }
            // Update the redux store with the User Object of the logged in user
            this.props.actions.userActions.actionGetUser(
              routingObj.params.userObj,
            );

            if (memberData) {
              // Write the data to redux
              this.props.actions.MemberActions.actionGetMember(
                memberData.payload,
                memberData.loadedMemberId,
                memberData.isEmployee,
                memberData.isFinancialStaff,
              );
            }

            // Check if the user has accepted the TnC for the current brand if not force logout the user
            this._checkIfTheUserHasAcceptedTheTnCForCurrentBrand(
              routingObj.params.userObj.user,
              (continueNavigation: any) => {
                if (!continueNavigation) {
                  return;
                }

                // Continue with the navigation flow
                let actionToDispatch = CommonActions.reset({
                  index: 0,
                  routes: [{name: routingObj.routeName, params: routingObj.params}],
                });
                if (this.props.notifications == true) {
                  let userObj = routingObj.params.userObj;
                  actionToDispatch = CommonActions.reset({
                    index: 0,
                    routes: [ {name: 'TabRoot',  params: {
                      userObj,
                      tabbarFlag: '1A',
                      goToNotifications: true,
                    }, }
                    ],
                  });

                  this.props.actions.notificationActions.notificationClick(
                    false,
                  );
                }
                this.setState({loading: false});
                this.props.navigation.dispatch(actionToDispatch);
              },
            );
          } else {
            // User account being logged into is inactive.
            Alert.alert(types2.ERROR, ERRORS.INACTIVE_ACCOUNT);
            getDataLoggedIn(types.PREVIOUSLY_SIGNIN).then(alreadyloggedIn => {
              const actionToDispatch = CommonActions.reset({
                index: 0,
                routes: [ {name : LOGINSIGNUP_ROUTE,    params: {
                  screenMode: alreadyloggedIn
                    ? types.LOGINSIGNUP_MODE_LOGIN
                    : types.LOGINSIGNUP_MODE_SIGNUP,
                }, }
                ],
              });
              this.props.navigation.dispatch(actionToDispatch);
            });
            this.setState({loading: false});
          }
        };
        routeTolanding(
          this.props.auth.awstoken.IdentityId,
          callbackFunction,
          true,
        );
      }
    }
  };

  _navigateToLoginSignUp = () => {
    getDataLoggedIn(types.PREVIOUSLY_SIGNIN).then(alreadyloggedIn => {
      const actionToDispatch = CommonActions.reset({
        index: 0,
        routes: [ 
          { name :LOGINSIGNUP_ROUTE, params:{
            screenMode: alreadyloggedIn
              ? types.LOGINSIGNUP_MODE_LOGIN
              : types.LOGINSIGNUP_MODE_SIGNUP,
          } }
        ],
      });
      this.props.navigation.dispatch(actionToDispatch);
    });
    this.setState({loading: false});
  };

  _checkIfTheUserHasAcceptedTheTnCForCurrentBrand = (
    user: any,
    callback: any,
  ) => {
    if (!user.tAndCAcceptedBrands.find((x: any) => x == APP_ID)) {
      logUserOut().then(() => {
        this._navigateToLoginSignUp();
        // If the user has not accepted the tnc for the current brand
        // Log the user out and navigate the user to login signup
        // And return false to callback to break the navigation flow
        callback(false);
      });
    } else {
      // User has accepted the tnc for the current brand
      // And return true to callback to continue with the navigation flow
      callback(true);
    }
  };

  render() {
    if (types.isWeb) {
      return (
        <View
          style={[CommonStyles.splashContainer, BrandStyles.primaryBgColor1]}
        >
          <BrandActivityIndicator
            noBackground={true}
            loading={this.state.loading}
          />
        </View>
      );
    }

    return (
      <View style={[CommonStyles.splashContainer, BrandStyles.primaryBgColor1]}>
        <BrandActivityIndicator
          noBackground={true}
          loading={this.state.loading}
        />
        <BasicPopupAlert
          visible={this.state.errorPopUpVisible}
          close={this.state.closeAction}
          cancelLabel={this.state.alertButtonText}
          headerText={this.state.alertHeader}
          messageText={this.state.alertMessage}
          forceUpdate={this.state.forceUpdate}
          latestVersion={this.state.latestVersion}
        />
      </View>
    );
  }

  _showAlert(
    alertHeader: any,
    alertMessage: any,
    alertButtonText: any,
    closeAction: any,
    errorPopUpVisible: any,
  ) {
    this.setState({
      alertHeader: alertHeader,
      alertMessage: alertMessage,
      alertButtonText: alertButtonText,
      closeAction: closeAction,
      errorPopUpVisible: errorPopUpVisible,
      forceUpdate: true,
    });
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.AuthenticationReducer,
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
  notifications: state.NotificationReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    authActions: bindActionCreators(AuthenticationActions, dispatch),
    adminActions: bindActionCreators(AdminActions, dispatch),
    loadedMemberActions: bindActionCreators(loadedMemberActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
    notificationActions: bindActionCreators(NotificationActions, dispatch),
    MemberActions: bindActionCreators(MemberActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Splash);
