/*
 * Author: Brad D'Costa
 * Date: 17/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Colour constants for use through styleguide
 */

export const BrandColors = {
  WHITE: '#ffffff',
  WHITE_OPACITY60: '#ffffff99',
  BLACK: '#222538',
  CHARCOAL_TINT60: '#222538', // change to CHARCOAL
  TRAFFICLIGHTGREEN: '#009933',
  GREEN: '#009933',
  PROS_GREEN: '#006E62',
  TOLERENCE_GREEN: '#3fb64b',
  NOTIFICATION_BADGE_BLUE: '#0855A2',
  NOTIFICATION_BADGE_BLACK: '#000000',
  NOTIFICATION_ROW_UNREAD: '#ffe1b7',
  TEAL: '#00748C',
  SUMMER_GREEN: '#8BC1A1',
  CORN: '#F8E467',

  SPENDBAR_GREEN: {
    DARK: '#1cb354',
    LIGHT: '#90daa9',
    NONE: '#ffffff',
  },

  SPENDBAR_RED: {
    DARK: '#c50e29',
    LIGHT: '#e59294',
    NONE: '#ffffff',
  },

  TAB_BAR_COLORS: {
    TAB_BOTTOM_ACTIVE: '#533FAB',
    TAB_BOTTOM_INACTIVE: '#E32625',
  },

  // New color codes for the responsive design
  RESPONSIVE_DESIGN_COLORS: {
    RED: '#E32625',
    PURPLE: '#533FAB',
    YELLOW: '#FFB41E',
    CHARCOAL: '#222538',
    LIGHT_GREY: '#E2E2E2',
    MID_GREY: '#A7A5A5',
    GREY: '#6B6868',
    WHITE: '#000000',
  },

  // Color codes for goals inspiration tiles
  GOALS_INSPIRATION_COLORS: {
    FIRE_ENGINE_RED: '#C41D21',
    RED_ORANGE: '#FF4438',
    RAZZMATAZZ: '#D60057',
    EGGPLANT: '#97106C',
    PERSIAN_ROSE: '#E13EAF',
    DARK_ORCHID: '#9E27BF',
    INDIGO: '#5C0F8B',
    DARK_BLUE: '#001689',
    COBALT: '#0047BA',
    NAVY_BLUE: '#0075C9',
    BONDI_BLUE: '#0099A8',
    SHERPA_BLUE: '#00514D',
    TROPICAL_RAIN_FOREST: '#006E63',
    PIGMENT_GREEN: '#00AE42',
    FUN_GREEN: '#006632',
  },

  //Color codes for onboarding screens
  ONBOARDING_COLORS: {
    BLUE: '#0C2E88',
    PINK: '#A20066',
    JUNGLE_MIST: '#B8BCBC',
    CASCADE: '#82A59D',
    SKY_BLUE: '#00A0FA',
    BLUECARE_GRADIENT: ['#082265', '#0C2E88', '#082265'],
    UCN_GRADIENT: ['#5A0039', '#A20066', '#510033'],
    UCQ_GRADIENT: ['#82A59D', '#D7E2DF', '#D3DFDC', '#82A59D'],
  },

  UNDER_MAINTENANCE_YELLOW: '#ffb31a',
};
