/*
 * Author: Brad D'Costa
 * Date: 06/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the checkbox button used throughout the app.
 */

import React, {Component} from 'react';
import {Image, View} from 'react-native';

import {StandardTouchableOpacity, StandardText} from '../Atoms';
import {
  TickWhite,
  MediumRedTickCircleRed3x,
  MediumRedEmptyCircleRed3x,
} from '../../assets/images';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

// Import Stylesheets
import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';

const CHECKBOX = 'checkbox';

class CheckBoxButton extends Component {
  state = {props: {}};

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    this.setState({props: this.props});
  }

  render() {
    let access = true;
    let disabled = false;
    if (
      this.props.readOnly == types.ACCESS_READ_ONLY ||
      this.props.readOnly == types.ACCESS_NONE
    ) {
      access = false;
      disabled = true;
    }
    return (
      <View style={this._determineColumns()}>
        <StandardTouchableOpacity
          accessibilityLabel={
            this.props.data.description +
            ' selected ' +
            this.props.data.selected +
            ' Checkbox'
          }
          disabled={disabled}
          activeOpacity={0.6}
          onPress={() => {
            this._toggleCheckbox();
          }}
        >
          <View
            style={[
              CommonStyles.flexDirectionRow,
              CommonStyles.alignItemsCenter,
              CommonStyles.paddingBottom10,
              this._determineSeperator(),
              CommonStyles.paddingTop5,
            ]}
          >
            <View style={this._determineContainerStyle(CHECKBOX)}>
              <Image
                style={this._determineIconStyle()}
                source={this._determineIcon()}
              />
            </View>
            <View style={[CommonStyles.marginLeft10]} />
            <View style={this._determineContainerStyle()}>
              {this._hasImage()}
              <StandardText
                style={[
                  this._determineLabelColor(),
                  this._determineTextStyle(),
                ]}
              >
                {this.props.data.description}
              </StandardText>
            </View>
          </View>
        </StandardTouchableOpacity>
      </View>
    );
  }

  _determineColumns = () => {
    var styles = [{marginBottom: 10}];
    if (this.props.columns == 2) {
      styles.push(CommonStyles.width50);
      styles.push(CommonStyles.multiLineMarginRight);
      if (
        this.props.columnType &&
        this.props.columnType == types2.CHECKBOX.RIGHT
      ) {
        styles.push(CommonStyles.multiLineMarginLeft);
      }
    } else {
      styles.push(CommonStyles.width100);
    }
    return styles;
  };

  _determineIcon = () => {
    if (this.props.data.selected || this.props.isSelected) {
      if (this.props.circular) {
        return MediumRedTickCircleRed3x;
      } else {
        return TickWhite;
      }
    } else {
      if (this.props.circular) {
        return MediumRedEmptyCircleRed3x;
      }
    }
  };

  _determineIconStyle = () => {
    let checkboxAccess = true;
    if (this.props.readOnly == types.ACCESS_READ_ONLY) {
      checkboxAccess = false;
    } else {
      checkboxAccess = true;
    }

    let styles = [CommonStyles.CheckBoxButtonIcon];
    if (this.props.iconStyle) {
      styles = this.props.iconStyle;
    }

    if (checkboxAccess === false) {
      styles.push(CommonStyles.opacity05);
    }
    return styles;
  };

  _determineTextStyle = () => {
    let styles = [
      CommonStyles.CheckBoxButtonLabelIconContainer,
      CommonStyles.multiLineText,
    ];
    if (this.props.textStyle) {
      styles.push(this.props.textStyle);
    } else {
      styles.push([
        CommonStyles.customFontSemiBold,
        CommonStyles.checkBoxLabelText,
      ]);
    }
    return styles;
  };

  _determineContainerStyle = (isCheckbox: any) => {
    let checkboxAccess = true;
    if (this.props.readOnly == types.ACCESS_READ_ONLY) {
      checkboxAccess = false;
    } else {
      checkboxAccess = true;
    }

    var styles = [
      CommonStyles.flexDirectionRow,
      CommonStyles.alignItemsCenter,
      CommonStyles.CheckBoxButtonLabelContainer,
    ];

    if (this.props.data.selected || this.props.isSelected) {
      if (!this.props.circular) {
        if (checkboxAccess == false) {
          styles.push([BrandStyles.readOnlyCheckboxGrey]);
        } else {
          styles.push([BrandStyles.primaryBgColor3]);
        }
      }
    } else {
      if (!this.props.circular) {
        if (checkboxAccess == false) {
          styles.push([
            BrandStyles.readOnlyTextfieldGrey,
            CommonStyles.elevation5,
          ]);
        } else {
          styles.push([BrandStyles.secondaryBgColor2, CommonStyles.elevation5]);
        }
      }
    }
    if (isCheckbox == 'checkbox' && !this.props.selected) {
      styles.push([CommonStyles.CheckBoxButtonNoIcon]);
    }
    return styles;
  };

  _determineSeperator = () => {
    let styles = {};
    if (this.props.seperator) {
      styles = this.props.seperatorStyle;
    }
    return styles;
  };

  _determineLabelColor = () => {
    var styles = [BrandStyles.TextColor5];
    if (!this.props.circular) {
      if (this.props.data.selected || this.props.isSelected) {
        styles.push([BrandStyles.TextColor1]);
      } else {
        styles.push([BrandStyles.TextColor5]);
      }
    }
    return styles;
  };

  _hasImage = () => {
    //check if it is imageURL or if has selected and deselected icons
    var image = '';
    if (this.props.data.imageURL != null) {
      image = this.props.data.imageURL;
    } else {
      if (this.props.data.selectedIcon && this.props.data.selected) {
        image = this.props.data.selectedIcon.url;
      } else if (this.props.data.deselectedIcon && !this.props.data.selected) {
        image = this.props.data.deselectedIcon.url;
      }
    }
    if (image != '') {
      if (this.props.circular) {
        return null;
      } else {
        return (
          <Image
            style={[
              CommonStyles.CheckBoxButtonIcon,
              CommonStyles.CheckBoxButtonImageIconContainer,
            ]}
            source={{uri: image}}
          />
        );
      }
    }
  };

  _toggleCheckbox = () => {
    let setStateCallbackFunction = () => {
      this.props.onChange(this.state.props.data);
    };
    var stateObj = this.state.props;
    // If we allow this comoment to manage selected then set it
    // Otherwise do nothing with the state and let the parent component manage it
    if (this.props.isSelected == undefined) {
      stateObj.data.selected = !stateObj.data.selected;
    }
    this.setState({props: stateObj}, setStateCallbackFunction);
  };
}

export default CheckBoxButton;
