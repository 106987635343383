/*
 * Author: Andrew Seeley
 * Date: 30/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This gives access to local storage in the app.
 * It operates as a key-value pair store. It is not encrypted.
 * On Android the storage is only accessiable via this app, and the user can only access it directly if they root the device.
 * On iOS the storage is only accessiable via this app, and the user can only access it directly if they plug their iPhone into their computer and use an iOS file exporer.
 */

import React from 'react';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import logger from 'helpers/Logger';
import AsyncStorage from '@react-native-async-storage/async-storage';

/*
 * Stores a given value to a key
 * @param {string} key The key used to store and access the data.
 * @param {object} data A string or json object of the data you wish to store.
 */

export const getDataLoggedIn = (key: any) =>
  new Promise((resolve, reject) => {
    AsyncStorage.getItem(key)
      .then(value => {
        resolve(value);
      })
      .catch(error => {
        logger.log(error);
        resolve(null);
      });
  });

export const storeData = async (key: any, data: any) => {
  await AsyncStorage.setItem(key, data);
};

/*
 * Retrivied a value from a given key
 * @async
 * @param {string} key The key used to store and access the data.
 * @returns {Promise<object>} A string or json object of the data associated with the key
 */
export const getData = (key: any) =>
  new Promise((resolve, reject) => {
    AsyncStorage.getItem(key)
      .then(value => {
        if (value != null) {
          resolve(value);
        } else {
          reject({code: types.LOGIN_NO_TYPE});
        }
      })
      .catch(error => {
        logger.log(error);
        reject(error);
      });
  });

/*
 * Removes a value from a given key
 * @param {string} key The key value pair you wish to remove
 */
export const removeItem = (key: any) => {
  AsyncStorage.removeItem(key);
};
