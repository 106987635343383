/*
 * Author: Brad D'Costa
 * Date: 18/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Logged in user information.
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {nullStringReplacer} from '../API/APIHelper';

/* Expected DataModel as per v22 of Swagger Definitions (versioning is based on Confluence versioning)
 *  {
 *      "user": {
 *          "id": "string"
 *          "firstName": "string"
 *          "lastName": "string"
 *          "email": "string"
 *          "knownAs": "string"
 *          "profilePhoto": "string"
 *          "tAndCAccepted": "string"
 *          "tAndCAcceptedDate": "string",
 *          "active": boolean
 *      }
 *      "isMember": boolean
 *      "isCrew": boolean
 *      "numberOfMembers": "string"
 *  }
 */
export default function UserReducer(state = {}, action: any) {
  switch (action.type) {
    case types.GET_USER:
      let parsedInnerUserObj = {};
      if (action.userObj.user != null) {
        parsedInnerUserObj = {
          id: nullStringReplacer(action.userObj.user.id),
          firstName: nullStringReplacer(action.userObj.user.firstName),
          lastName: nullStringReplacer(action.userObj.user.lastName),
          email: nullStringReplacer(action.userObj.user.email),
          knownAs: nullStringReplacer(action.userObj.user.knownAs),
          profilePhoto: nullStringReplacer(action.userObj.user.profilePhoto),
          tAndCAccepted: nullStringReplacer(action.userObj.user.tAndCAccepted),
          tAndCAcceptedDate: nullStringReplacer(
            action.userObj.user.tAndCAcceptedDate,
          ),
          tAndCAcceptedBrands: action.userObj.user.tAndCAcceptedBrands,
          active: nullStringReplacer(action.userObj.user.active),
          isOnboardingGuideRequired:
            action.userObj.user.isOnboardingGuideRequired,
        };
      }

      const parsedUserObj = {
        isMember: nullStringReplacer(action.userObj.isMember),
        isCrew: nullStringReplacer(action.userObj.isCrew),
        numberOfMembers: nullStringReplacer(action.userObj.numberOfMembers),
        isEmployee: nullStringReplacer(action.userObj.isEmployee),
        isFinancialStaff: nullStringReplacer(action.userObj.isFinancialStaff),
        status: action.userObj.status,
      };

      return {
        ...state,
        id: parsedInnerUserObj.id,
        firstName: parsedInnerUserObj.firstName,
        lastName: parsedInnerUserObj.lastName,
        email: parsedInnerUserObj.email,
        knownAs: parsedInnerUserObj.knownAs,
        profilePhoto: parsedInnerUserObj.profilePhoto,
        tAndCAccepted: parsedInnerUserObj.tAndCAccepted,
        tAndCAcceptedDate: parsedInnerUserObj.tAndCAcceptedDate,
        tAndCAcceptedBrands: parsedInnerUserObj.tAndCAcceptedBrands,
        active: parsedInnerUserObj.active,
        isMember: parsedUserObj.isMember,
        isCrew: parsedUserObj.isCrew,
        numberOfMembers: parsedUserObj.numberOfMembers,
        isEmployee: parsedUserObj.isEmployee,
        isFinancialStaff: parsedUserObj.isFinancialStaff,
        inactiveTimeoutThreshold: action.userObj.inactiveTimeoutThreshold,
        isOnboardingGuideRequired: parsedInnerUserObj.isOnboardingGuideRequired,
        status: parsedUserObj.status,
      };
    case types.UPDATE_USER_PLAN_TYPE:
      return {
        ...state,
        planType: action.planType, // New or existing
      };
    case types.UPDATE_USER_SIGNUP_TYPE:
      return {
        ...state,
        signUpType: action.signUpType, // participant or crew
      };
    case types.UPDATE_USER_ISMEMBER:
      return {
        ...state,
        isMember: action.isMember, // true or false
      };
    case types.UPDATE_USER_ISCREW:
      return {
        ...state,
        isCrew: action.isCrew, // true or false
      };
    case types.UPDATE_USER_NUMBEROFMEMBERS:
      return {
        ...state,
        numberOfMembers: action.numberOfMembers,
      };
    case types.SET_FIRST_TIME_SIGN_UP:
      return {
        ...state,
        isFirstTimeLogin: action.isFirstTime,
        passwordChallenge: action.passwordChallenge,
      };
    case types.UPDATE_USER_GUIDE_STATUS:
      return {
        ...state,
        isOnboardingGuideRequired: action.isOnboardingGuideRequired,
      };
    case types.GET_MOST_RECENT_MEMBERS:
      return {
        ...state,
        recentMembers: action.memberObj,
      };
    case types.UPDATE_USER:
      return {
        ...state,
        demoProfile: action.userObj.demoProfile,
        isShowcase: action.userObj.isShowCase,
      };
    default:
      return state;
  }
}
