/*
 * Author: Brad D'Costa
 * Date: 30/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Summary tile on the MyProfile screen for the Favourite Things Section
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import {StandardText} from '../Atoms';
import {SummaryTile} from '../Molecules';

import {
  favouriteThingsIconCharcoal,
  favouriteThingsIconRed,
} from '../../assets/images/vector-icons';
import {MESSAGES} from '../../Constants/Messages';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {Types} from 'aws-sdk';

class MyProfileFavouriteThings extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <SummaryTile
        headerDisplayString="Favourite things"
        headerIconImage={favouriteThingsIconRed}
        screenType={types.SCREEN_TYPE_MAIN}
        modal={this.props.modal}
        containsData={this._determineContainsData()}
        actionOverride={this._determineAccess()}
        displayMore={false}
      >
        {this._renderFavouriteThings()}
      </SummaryTile>
    );
  }

  _determineAccess = () => {
    if (Object.getOwnPropertyNames(this.props.readOnly).length === 0) {
      return null;
    } else {
      let readOnly = this.props.readOnly;
      if (readOnly.access) {
        if (readOnly.access.profile) {
          if (this.props.readOnly.access.profile == types.ACCESS_READ_ONLY) {
            return types.ACTION_BUTTON.VIEW;
          }
        }
      }
    }
    return null;
  };

  _determineContainsData = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return false;
    } else {
      if (
        this.props.reducer.likeToDo == '' &&
        this.props.reducer.wouldLikeToDo == ''
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  _determinIcon = () => {
    if (this._determineContainsData()) {
      return favouriteThingsIconRed;
    } else {
      return favouriteThingsIconCharcoal;
    }
  };

  _renderFavouriteThings() {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      if (
        this.props.reducer.likeToDo == '' &&
        this.props.reducer.wouldLikeToDo == ''
      ) {
        return null;
      } else {
        return (
          <View>
            {this.props.reducer.likeToDo ? (
              <StandardText
                style={[
                  CommonStyles.SummaryTileText,
                  BrandStyles.TextColor5,
                  CommonStyles.customFont,
                ]}
              >
                {MESSAGES.FAVOURITE_THINGS_SCREEN.TODAY_MODAL_TITLE}{' '}
                {this.props.reducer.likeToDo}
              </StandardText>
            ) : null}
            {this.props.reducer.wouldLikeToDo ? (
              <StandardText
                style={[
                  CommonStyles.SummaryTileText,
                  BrandStyles.TextColor5,
                  CommonStyles.customFont,
                ]}
              >
                {MESSAGES.FAVOURITE_THINGS_SCREEN.FUTURE_MODAL_TITLE}{' '}
                {this.props.reducer.wouldLikeToDo}
              </StandardText>
            ) : null}
          </View>
        );
      }
    }
  }
}

export default MyProfileFavouriteThings;
