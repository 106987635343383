/*
 * Authour: Nirodha Perera
 * Date: 08/03/2019
 * Copyright © 2019 Leap in!. All rights reserved.
 *
 * A wrapper component to identify when url path changes for web
 */

import React, {Component} from 'react';
import {View} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {
  routePaths,
  BUDGETS,
  PROFILE,
  HOME,
  WEB_STORE,
  DEEP_LINK_SCREENS,
} from '../../Constants/Constants2';
import {GoogleAnalyticsInitialiser} from '../../Helpers/PlatformSynchronizer';

import * as GuideMeActions from '../../Actions/GuideMeActions';
import ReactGA from 'react-ga';
import {getValue, removeItem} from '../../API/WebPersistenceStore';

class AppContainer extends Component {
  unlisten: any;
  constructor(props: any) {
    super(props);
    this.getCurrentScreen = this.getCurrentScreen.bind(this);
  }

  UNSAFE_componentWillMount() {
    GoogleAnalyticsInitialiser(window.location.pathname);
    this.unlisten = this.props.history.listen((location: any, action: any) => {
      const currentScreen = this.getCurrentScreen(location.pathname);

      if (currentScreen) {
        this.props.actions.GuideMeActions.setCurrentScreen(currentScreen);
      }
    });
  }
  componentWillUnmount() {
    this.unlisten();
  }
  UNSAFE_componentWillReceiveProps() {
    this.checkSystemUnderMaintenance();
  }

  componentDidMount() {
    this.checkSystemUnderMaintenance();
    const currentScreen = this.getCurrentScreen(this.props.location.pathname);

    if (currentScreen) {
      this.props.actions.GuideMeActions.setCurrentScreen(currentScreen);
    }
  }

  checkSystemUnderMaintenance() {
    if (
      this.props.general.isSystemUnderMaintained &&
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname &&
      this.props.history.location.pathname !== routePaths.UnderMaintenance.path
    ) {
      this.props.history.push(routePaths.UnderMaintenance.path);
    }
  }

  getCurrentScreen = (url: any) => {
    GoogleAnalyticsInitialiser(window.location.pathname);
    const urlPath = url.replace(/\/$/, '');
    let screens = [];
    if (urlPath) {
      screens = urlPath.split('/');
    }

    let currentScreen = screens[1];

    if (currentScreen === HOME) {
      if (this.props.user.status === 'managed') {
        currentScreen = BUDGETS;
      } else {
        currentScreen = PROFILE;
      }

      if (screens.length > 2 && (routePaths[screens[2]] || (screens[2]==DEEP_LINK_SCREENS.MY_PROFILE || screens[2]==DEEP_LINK_SCREENS.BUDGETS))) {
        currentScreen = screens[2];
        if(screens[2]==DEEP_LINK_SCREENS.MY_PROFILE){
          currentScreen = PROFILE;
        }
        else if(screens[2]==DEEP_LINK_SCREENS.BUDGETS){
          currentScreen = BUDGETS;
        }
      } else if (screens.length == 2) {
        if (getValue(WEB_STORE.CURRENT_MENU) !== null) {
          removeItem(WEB_STORE.CURRENT_MENU);
        }
      }
    }

    return currentScreen;
  };

  render() {
    return <View>{this.props.children}</View>;
  }
}

const mapStateToProps = (state: any) => ({
  guideMe: state.GuideMeReducer,
  user: state.UserReducer,
  general: state.GeneralReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    GuideMeActions: bindActionCreators(GuideMeActions, dispatch),
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppContainer),
);
