import React, {Component} from 'react';
import {
  Text,
  View,
  Button,
  Image,
  TouchableOpacity,
  Dimensions,
  Platform,
} from 'react-native';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import SideMenu from 'react-native-side-menu-enhanced';
// import Orientation from 'react-native-orientation-enhanced';
import DeviceInfo from 'react-native-device-info';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {BrandIcons} from '../../Styles/BrandIcons';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {NotificationBadge} from '../../Components/Molecules/NotificationBadge';
import * as SideMenuActions from '../../Actions/SideMenuActions';
import * as GuideMeActions from '../../Actions/GuideMeActions';
import {
  openDockedDrawer,
  closeDockedDrawer,
} from '../../assets/images/vector-icons';
import Menu from '../../Screens/Menu';
import CustomIcon from '../../assets/images/CustomIcon';
import {MESSAGES} from '../../Constants/Messages';
import {
  OFFSET_WIDTH,
  OFFSET_HEIGHT,
  GUIDE_ME_STEPS,
} from '../../Constants/Constants2';

let {height, width} = Dimensions.get('window');
const openMenuOffset = 250;
class MenuContainer extends Component {
  constructor(props: any) {
    super(props);
    this.orientationStyle = this.orientationStyle.bind(this);
  }

  state = {
    moreButtonView: undefined,
  };

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const isTablet = DeviceInfo.isTablet();

    if (
      nextProps.guideMe.showWelcomeGuide &&
      this.props.guideMe.welcomeGuideStep !== nextProps.guideMe.welcomeGuideStep
    ) {
      if (
        isTablet &&
        nextProps.guideMe.welcomeGuideStep == GUIDE_ME_STEPS.STEP_4
      ) {
        let offsetHeight = OFFSET_HEIGHT;

        if (Platform.OS === types2.ANDROID) {
          offsetHeight = OFFSET_HEIGHT - 15;
        }
        this.props.actions.GuideMeActions.setOverlayHighlightTwo({
          size: {
            x: this.state.moreButtonView.width + OFFSET_WIDTH,
            y: this.state.moreButtonView.height - OFFSET_WIDTH / 2,
          },
          position: {
            x: this.state.moreButtonView.x + this.props.openMenuOffset,
            y: this.state.moreButtonView.y + offsetHeight,
          },
        });

        if (isTablet && !this.props.isOpen) {
          this.props.toggleMenu();
        }
      } else {
        this.props.actions.GuideMeActions.setOverlayHighlightTwo({
          size: {x: 0, y: 0},
          position: {x: 0, y: 0},
        });
      }
    }
  }

  // UNSAFE_componentWillMount() {
  //   Orientation.getOrientation((err: any, orientation: any) => {
  //     this.setState({
  //       orientation: orientation,
  //     });
  //   });
  // }

  componentDidMount() {
    // Orientation.addOrientationListener(this._orientationDidChange);
  }

  componentWillUnmount() {
    // Orientation.removeOrientationListener(this._orientationDidChange);
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation,
    });
  };

  orientationStyle = (value: any) => {
    if (height < width) {
      [height, width] = [width, height];
    }

    if (this.state) {
      let widthVal =
        this.state.orientation == types2.ORIENTATION.LANDSCAPE ? height : width;
      let openWidth = this.state.isOpen ? widthVal - openMenuOffset : widthVal;

      return {
        transform: [
          {
            translateX: value,
          },
        ],
        width: openWidth,
      };
    }
  };

  render() {
    const menu = (
      <Menu
        containerStyle={CommonStyles.sideMenuContainer}
        isOpen={this.props.isOpen}
        navigation={this.props.nav}
        toggleMenu={this.props.toggleMenu}
      />
    );
    const scene = this.props.sideMenu.currentScreen;
    let accessibleRead = '';
    if (scene === types2.LINKED_MEMBER) {
      accessibleRead = types2.NAVIGATION_TEXT.MEMBERS_MENU;
    } else if (scene === types2.PROFILE) {
      accessibleRead = types2.NAVIGATION_TEXT.PROFILE_MENU;
    } else if (scene === types2.BUDGETS) {
      accessibleRead = types2.NAVIGATION_TEXT.BUDGETS_MENU;
    }

    return (
      <SideMenu
        menu={menu}
        isOpen={this.props.isOpen}
        openMenuOffset={this.props.openMenuOffset}
        disableGestures={true}
        animationStyle={this.orientationStyle}
      >
        <View
          style={[
            CommonStyles.contentWrapper,
            CommonStyles.contentBackgroundColor,
          ]}
        >
          <View
            onLayout={({nativeEvent}) => {
              this.setState({moreButtonView: nativeEvent.layout});
            }}
            style={[
              CommonStyles.sideBarDockButton,
              CommonStyles.height100,
              CommonStyles.sideBarShadow,
            ]}
          >
            <TouchableOpacity
              onPress={this.props.toggleMenu}
              accessibilityLabel={accessibleRead}
              style={[
                CommonStyles.justifyContentCentre,
                CommonStyles.height100,
              ]}
            >
              {this.renderImage()}
            </TouchableOpacity>
          </View>
          {this.props.children}
        </View>
      </SideMenu>
    );
  }

  renderImage() {
    if (this.props.isOpen) {
      return (
        <CustomIcon
          name={closeDockedDrawer}
          style={[CommonStyles.sideBarDockButtonIcon, CommonStyles.zIndex10]}
        />
      );
    } else {
      return (
        <CustomIcon
          name={openDockedDrawer}
          style={[CommonStyles.sideBarDockButtonIcon, CommonStyles.zIndex10]}
        />
      );
    }
  }
}

const mapStateToProps = (state: any) => ({
  sideMenu: state.SideMenuReducer,
  guideMe: state.GuideMeReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SideMenuActions: bindActionCreators(SideMenuActions, dispatch),
    GuideMeActions: bindActionCreators(GuideMeActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
