/**
 * Author: Sujeban Elankeswaran
 * Date: 19/12/2019
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This shows the Referral Providers details.
 */

import React, {Component} from 'react';
import {View, ScrollView} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {StandardText} from '../Components/Atoms';
import {Container, ReferralProvidersList} from '../Components/Molecules';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {
  BUDGETS_AND_PAYMENTS_SCREEN as messages,
  BUDGET_SPEND_GRAPHS as graphMessages,
} from '../Constants/Messages';
import {callAPIs} from '../API/APICaller';
import {getProvidersByCategoryRegion} from '../API/ProviderAPI';
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {getScreenTitle} from '../Helpers/Utils';

import * as SupportsActions from '../Actions/SupportsActions';
import * as BudgetActions from '../Actions/BudgetActions';
import * as PayInvoiceActions from '../Actions/PayInvoiceActions';
import * as MemberActions from '../Actions/MemberActions';
import * as ProviderActions from '../Actions/ProviderActions';
import * as EnquiryActions from '../Actions/EnquiryActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
// import Orientation from 'react-native-orientation-enhanced';

class ReferralProviders extends Component {
  state = {
    loading: false,
    listedProviderSupports: [],
    unlistedProviderSupports: [],
    adhocInvoicesSpentAmounts: [],
    orientation: '',
    isOpen: this.props.sideMenu ? this.props.sideMenu.sideMenuIsOpen : false,
    refreshing: false,
    categoryName: '',
  };

  constructor(props: any) {
    super(props);
    this._createMainContents = this._createMainContents.bind(this);
    this.onRefresh = this.onRefresh.bind();
  }

  // UNSAFE_componentWillMount() {
  //   if (!types.isWeb) {
  //     Orientation.getOrientation((err: any, orientation: any) => {
  //       this.setState({orientation});
  //     });
  //   }
  // }
  componentWillUnmount() {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({orientation});
  };

  UNSAFE_componentWillReceiveProps(props: any) {
    this.setState({
      isOpen: props.sideMenu ? props.sideMenu.sideMenuIsOpen : false,
    });
  }

  componentDidMount() {
    this.setState({loading: false});
    this.callApi();
  }

  fetchProviders = () => {
    const sa3Region = this.props.member.boundries.SA3
      ? this.props.member.boundries.SA3
      : this._getParams('saregion', undefined);
    let item = this.props.general.category
      ? this.props.general.category
      : this._getParams('category', undefined);
    this.setState({categoryName: item});
    if (sa3Region && item) {
      callAPIs(
        [getProvidersByCategoryRegion(`${sa3Region}`, item)],
        (data: any) => {
          const obj = JSON.parse(data[0].content.body);
          if (types.isWeb) {
            this.props.setLoading(false);
          }
          this.props.actions.ProviderActions.actionSetProviderList(obj.data);
        },
        null,
        (err: any) => {},
      );
    } else {
      this.props.navigation.navigate('BudgetsPayments');
    }
  };

  async callApi() {
    if (!this.props.providers.listReferrals) {
      if (types.isWeb) {
        this.props.setLoading(true);
      }
      await this.fetchProviders();
    }
  }

  onRefresh = () => {
    this.setState({refreshing: true});
  };

  _getParams = (key: any) => {
    if(types.isWeb) {
      return this.props.navigation.getParam(key, undefined) || undefined;
     }
     return this.props.route.params? this.props.route.params[key] : undefined;
  };

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        sidebarMode={types2.SIDEBAR_MODE.PLAN_MANAGEMENT}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.PROVIDER_SITES_NEAR_YOU}
        onRefresh={this.onRefresh}
        refreshing={this.state.refreshing}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    return (
      <View
        style={[
          CommonStyles.content,
          CommonStyles.marginTop10,
          CommonStyles.marginLeft10,
          CommonStyles.marginRight10,
        ]}
      >
        <StandardText
          style={[
            CommonStyles.font22,
            CommonStyles.marginBottom20,
            CommonStyles.marginTop5,
            CommonStyles.customFontBold,
            BrandStyles.TextColor4,
          ]}
        >
          Providers for{' '}
          {this.props.general.category
            ? this.props.general.category
            : this.state.categoryName}{' '}
          in {this.props.member.city}
        </StandardText>
        <ScrollView style={[CommonStyles.content, BrandStyles.primaryBgColour]}>
          {this._renderNoContentMessage()}
          <ReferralProvidersList {...this.props} />
        </ScrollView>
      </View>
    );
  };

  _renderNoContentMessage() {
    let providersList = this.props.providers.providerList;

    if (providersList.length || this.state.loading) {
      return null;
    }

    return (
      <View
        style={[
          CommonStyles.alignItemsCenter,
          CommonStyles.justifyContentCentre,
        ]}
      >
        <StandardText
          style={[
            CommonStyles.flex1,
            CommonStyles.font20,
            BrandStyles.TextColor5,
          ]}
        >
          {messages.NO_CONTENT_MESSAGE}
        </StandardText>
      </View>
    );
  }

  _getTitle() {
    return getScreenTitle(this.props.member, messages.MAIN_TITLE);
  }
}

// Warn the user if props are not passed
ReferralProviders.propTypes = {};

//For the reducer connection
const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  member: state.MemberReducer,
  budget: state.BudgetReducer,
  loadedMemberId: state.LoadedMemberReduce,
  providers: state.ProviderReducer,
  general: state.GeneralReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
    BudgetActions: bindActionCreators(BudgetActions, dispatch),
    PayInvoiceActions: bindActionCreators(PayInvoiceActions, dispatch),
    MemberActions: bindActionCreators(MemberActions, dispatch),
    ProviderActions: bindActionCreators(ProviderActions, dispatch),
    EnquiryActions: bindActionCreators(EnquiryActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ReferralProviders);
