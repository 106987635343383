import React, {Component} from 'react';
import {View, FlatList, StyleSheet, ScrollView, Image} from 'react-native';
import {StandardInput, StandardText} from '../Components/Atoms';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {MESSAGES} from '../Constants/Messages';

import {
  FavouriteThingsFuture,
  FavouriteThingsToday,
  BasicForm,
} from '../Components/Organisms';
import {
  ModalSubtitle,
  ModalTitle,
  SummaryTile,
  Container,
  CheckBoxButton,
  FormTitle,
  FormSubtitle,
  RadioButton,
} from '../Components/Molecules';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {callApiChecker} from '../API/APIHelper';
import {getFavourites, updateFavourites} from '../API/AboutMeAPI';
import * as FavouriteThingsActions from '../Actions/FavouriteThingsActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {FavouriteThingsPurple3x} from '../assets/images';
class MultiSelectModal extends Component {
  state = {
    title: '',
    data: [],
    callback: '',
    selectedOptions: [],
  };

  constructor(props: any) {
    super(props);
    this.cancelModal = this.cancelModal.bind(this);
    this.renderOptions = this.renderOptions.bind(this);
    this.isOptionSelected = this.isOptionSelected.bind(this);
    this.selectOption = this.selectOption.bind(this);
    this.determineIndex = this.determineIndex.bind(this);
    this.save = this.save.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      title: this.props.navigation.state.params.title,
      type: this.props.navigation.state.params.type,
      data: this.props.navigation.state.params.data,
      selectedOptions: JSON.parse(
        JSON.stringify(this.props.navigation.state.params.selected),
      ),
    });
  }

  cancelModal() {
    this.props.navigation.goBack(null);
  }

  save() {
    this.props.navigation.state.params.save(
      this.state.type,
      this.state.selectedOptions,
    );
    this.props.navigation.goBack(null);
  }

  determineSelected(option: any) {
    const selectedOptions = this.state.selectedOptions;

    // Adding one will have the option as {key: , description: }
    if (
      selectedOptions.filter(o => o.value === option.description).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Determine the index of an option in the selected options array
   * Returns -1 if not found
   * @param {object} option The option you want to find the index of.
   */
  determineIndex(option: any) {
    const selectedOptions = this.state.selectedOptions;
    var index = -1;
    selectedOptions.filter((o, idx, array) => {
      if (o.value === option.description) {
        index = idx;
        return true;
      } else {
        return false;
      }
    });
    return index;
  }

  renderOptions() {
    return this.state.data.map((option, index) => {
      return (
        <RadioButton
          id={index}
          value={option.description}
          isSelected={this.determineSelected(option)}
          style={[CommonStyles.radioButtonPM_DividerStyleMultiselect]}
          buttonStyle={CommonStyles.radioButtonPMSmallChanged}
          onChange={this.selectOption}
        >
          <View style={[CommonStyles.radioButtonPMSmall_ContainerMultiselect]}>
            <View
              style={[
                CommonStyles.flexDirectionRow,
                CommonStyles.justifyContentSpaceBetween,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.font17,
                  BrandStyles.TextColor5,
                  CommonStyles.customFont,
                ]}
              >
                {option.description}
              </StandardText>
            </View>
          </View>
        </RadioButton>
      );
    });
  }

  isOptionSelected(key: any) {
    return this.state.selectedOptions.includes(key);
  }

  selectOption(option: any) {
    let selectedOptions = this.state.selectedOptions;
    // Update the option to include key value name-value to work with AddSupportDetails screen
    option.value = option.description;

    if (!this.determineSelected(option)) {
      selectedOptions.push(option);
    } else if (this.determineSelected(option)) {
      selectedOptions.splice(this.determineIndex(option), 1);
    }
    this.setState({
      selectedOptions: selectedOptions,
    });
  }

  render() {
    return (
      <Container
        contents={this._createMainContents}
        loading={false}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={MESSAGES.HEALTH_WELLBEING_SCREEN.MAIN_TITLE}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    return (
      <BasicForm
        headerDisplayString={this.props.navigation.state.params.mainTittle}
        readOnly={this.props.navigation.state.params.access}
        headerIconImage={FavouriteThingsPurple3x}
        disableMore={true}
        close={() => this.cancelModal()}
        save={() => this.save()}
        buttonsPositionBottom={true}
      >
        <View style={[CommonStyles.flex1]}>
          <FormTitle
            text={this.state.title}
            containsData={this.state.selectedOptions}
          />
          <FormSubtitle text={['Please select all that apply.']} />
          {this.renderOptions()}
        </View>
      </BasicForm>
    );
  };
}

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiSelectModal);
