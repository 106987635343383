/*
 * Author: Andrew Lee
 * Date: 16/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Reducer for
 * Hiding and showing the tabbar programatically
 * Display and Hide the GuideMeModal when user clicks the tabbar Guide Me tab
 *
 */
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function TabBarReducer(
  state = {showGuideMe: false},
  action: any,
) {
  switch (action.type) {
    case types2.TAB_BAR_SHOW:
      return {
        showTabBar: true,
      };
    case types2.TAB_BAR_HIDE:
      return {
        showTabBar: false,
      };
    case types2.GUIDEME_SHOW:
      return {
        ...state,
        showGuideMe: true,
        manualGuideMe: action.isManualOpen,
        selectedTab: action.selectedTab,
      };
    case types2.GUIDEME_HIDE:
      return {
        ...state,
        showGuideMe: false,
        selectedTab: action.selectedTab,
      };
    case types2.ADD_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.selectedTab,
      };
    case types2.GET_GUIDEME_MEMBER:
      return {
        guideMeMember: action.guideMeMember,
      };
    default:
      return state;
  }
}
