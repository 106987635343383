/*
 * Author: Andrew Seeley
 * Date: 12/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a goal insparation tile, for the add goal & goal steps screens.
 *
 */

import React, {Component} from 'react';

import {StandardTouchableOpacity, StandardText} from '../Atoms';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

//Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {BrandColors} from '../../Styles/Colours';

class GoalInspirationTile extends Component {
  state = {
    selectedColor: '',
  };

  UNSAFE_componentWillMount() {
    if (this.props.getSelectedColor) {
      let borderColor = this.props.getSelectedColor();
      this.setState({
        selectedColor: borderColor,
      });
    } else {
      this.setState({
        selectedColor: BrandColors.BLACK,
      });
    }
  }

  render() {
    if (this.props.parentDimensions == undefined) {
      return null;
    }

    let parentWidth = this.props.parentDimensions.width;
    let height = this.props.parentDimensions.height;

    let columns = this.props.columns;
    let margin = 4;
    // Determine the tile width bases on the number of columns we want in the parent container
    let tilewidth = parentWidth / columns;
    // Take into consideration the margins when calculating the width
    tilewidth = tilewidth - margin * 2 - 1;
    let nativeAppStyle = {
      width: tilewidth,
      margin: margin,
      borderColor: this.state.selectedColor,
      opacity: this.props.disabled ? types2.OPACITY04 : types2.OPACITY1,
    };
    if (types.isWeb) {
      nativeAppStyle = {
        margin: margin,
        borderColor: this.state.selectedColor,
        opacity: this.props.disabled ? types2.OPACITY04 : types2.OPACITY1,
      };
    }

    return (
      <StandardTouchableOpacity
        style={[
          CommonStyles.tileGoal,
          BrandStyles.primaryBgColor1,
          nativeAppStyle,
        ]}
        accessibilityLabel={this.props.content.description}
        onPress={this._actionCallback}
        disabled={this.props.disabled}
      >
        <StandardText
          style={[
            CommonStyles.customFont,
            CommonStyles.fontWeight400,
            CommonStyles.goalInspText,
            CommonStyles.textAlignCentre,
            BrandStyles.TextColor5,
          ]}
        >
          {this.props.content.description}
        </StandardText>
      </StandardTouchableOpacity>
    );
  }

  _actionCallback = () => {
    if (this.props.actionData) {
      this.props.actionCallback(this.props.actionData);
    } else {
      this.props.actionCallback();
    }
  };
}

export default GoalInspirationTile;
