// index.js - WEB
import React from 'react';
import { createRoot } from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import App from './App';
import {Auth} from 'aws-amplify';
import awsConfig from './aws-exports';
import registerServiceWorker from './registerServiceWorker';
import * as env from './environments';
// Generate required css
import iconFont from './assets/fonts/fontello.ttf';
import {
  APP_TYPE_STAFF,
  IS_APP_LOADED_FROM_PLAY_STORE,
  SETTINGS_URL_PATH_NAME,
} from './Constants/Constants';
import {AppRegistry} from 'react-native';

const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: fontello;
}`;

// Create stylesheet
const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}

const host = window.location.hostname.toLowerCase();
if (env.IS_STAFF_APP === APP_TYPE_STAFF) {
  Auth.configure(awsConfig);
}

// when Redirected from play store -> need to reroute to settings page
// more details can be found in LCP-14
if (window.location.href.includes(SETTINGS_URL_PATH_NAME)) {
  sessionStorage.setItem(IS_APP_LOADED_FROM_PLAY_STORE, 'true');
}

// Inject stylesheet
document.head.appendChild(style);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Router>
  <App />
</Router>);