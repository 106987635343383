/**
 * Author: Shelan Cooray
 * Date: 20/07/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a helper module to provide navigate to newly created member
 * when Crew Singup and Add a new Member along with.
 *
 * NOTE on 20/07/2018
 * Future this can be use across ManageParticipants and MyProfile where currently there is repeatable code similar to this
 */

import {Alert} from 'react-native';
import {CommonActions} from '@react-navigation/native';
import {getMember} from '../API/MemberAPI';
import {callAPIs} from '../API/APICaller';
import {setServiceAgreementFeature} from '../Helpers/Utils';

import {
  getLivingArrangement,
  getFavourites,
  getHealth,
} from '../API/AboutMeAPI';
import {getCrewAccessLevels} from '../API/CrewAPI';
import {getCrew} from '../API/PersonAPI';
import {getCrewCategories} from '../API/ReferenceAPI';
import {getSupports} from '../API/PlanDataAPI';
import {getGoalsForPlan} from '../API/PlanDataAPI';
import {getBudget} from '../API/PlanDataAPI';
import {getValue, setValue, removeItem} from '../API/WebPersistenceStore';

import {getDeepLinkParams} from '../Helpers/Utils';
import {hasBudgetAccess} from './determineAnyBudgetAccess';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import logger from 'helpers/Logger';

// Constants
const RESULT_INDEX = {
  MEMBER: 0,
  LIVING_ARRANGEMENT: 1,
  FAVOURITES: 2,
  HEALTH: 3,
  CREW_CATEGORIES: 4,
  CREW: 5,
  CREW_ACCESS: 6,
};

const PLAN_RESULT_INDEX = {
  SUPPORTS: 0,
  GOALS: 1,
  BUDGETS: 2,
};

const HOME = '/Home';

export const navigateMember = (personId: any, props: any, reset: any) => {
  const callbackFunction = (results: any) => {
    // Get the data
    const memberPayload = results[RESULT_INDEX.MEMBER].content;
    const livingPayload = results[RESULT_INDEX.LIVING_ARRANGEMENT].content;
    const favouritesPayload = results[RESULT_INDEX.FAVOURITES].content;
    const healthPayload = results[RESULT_INDEX.HEALTH].content;
    const crewCategoryPayload =
      results[RESULT_INDEX.CREW_CATEGORIES].content.categories;
    const personCrewsPayload = results[RESULT_INDEX.CREW].personCrews.content;
    const organisationCrewsPayload =
      results[RESULT_INDEX.CREW].organisationCrews.content;
    const crewsAccessPayload = results[RESULT_INDEX.CREW_ACCESS].content;

    // Write the data to redux
    props.actions.MemberActions.actionGetMember(
      memberPayload,
      props.user.id,
      props.user.isEmployee,
      props.user.isFinancialStaff,
    );
    props.actions.LivingArrangementActions.actionGetLivingArrangement(
      livingPayload,
    );
    props.actions.FavouriteThingsActions.actionGetFavouriteThings(
      favouritesPayload,
    );
    props.actions.HealthWellbeingActions.actionGetHealthWellbeing(
      healthPayload,
    );
    props.actions.CrewActions.actionGetCrews(
      crewCategoryPayload,
      personCrewsPayload,
      organisationCrewsPayload,
      crewsAccessPayload,
    );

    // Now call the get supports and get goals for plan
    _fetchSupportsAndGoals(personId, memberPayload, props, reset);
  };

  callAPIs(
    [
      getMember(personId),
      getLivingArrangement(personId),
      getFavourites(personId),
      getHealth(personId),
      getCrewCategories(personId),
      getCrew(personId),
      getCrewAccessLevels(personId),
    ],
    callbackFunction,
    null,
    () => {},
  );

  props.actions.loadedMemberActions.actionGetLoadedMemberId(personId);
};

/**
 * This function will fetch the support and goals once the plan id has been obtained.
 * It will also fetch budgets if required.
 * It will then update the redux with the data downloaded. The state will then update to false.
 * @param {object} memberPayload memberobject to fetch the budget data.
 */
const _fetchSupportsAndGoals = (
  memberId: any,
  memberPayload: any,
  props: any,
  reset: any,
) => {
  const callback = (results: any) => {
    const supportsPayload = results[PLAN_RESULT_INDEX.SUPPORTS].content;
    const goalsPayload = results[PLAN_RESULT_INDEX.GOALS].content.currentGoals;

    // Write the data to redux
    props.actions.SupportsActions.actionGetSupports(supportsPayload);
    props.actions.GoalActions.actionGetGoals(goalsPayload);

    if (callBudgets) {
      if (
        !(
          results[PLAN_RESULT_INDEX.BUDGETS].statusCode ==
          types2.NO_PLANS_ERROR_CODE
        )
      ) {
        const budgetsPayload = results[PLAN_RESULT_INDEX.BUDGETS].content;

        // Check if Service Agreement feature is disabled from the LIBE
        if (budgetsPayload.isServiceAgreementsEnabled != null) {
          if (types.isWeb) {
            setServiceAgreementFeature(
              budgetsPayload.isServiceAgreementsEnabled,
            );
          } else {
            this.props.actions.MemberActions.actionSetServiceAgreementEnabled(
              budgetsPayload.isServiceAgreementsEnabled,
            );
          }
        }

        props.actions.BudgetActions.actionGetBudgets(budgetsPayload);
      }
    }

    // Set the state to no longer be loading
    navigateToProfile(props, reset);
  };

  const errorHandler = (error: any) => {
    logger.log(
      'The fetch for supports, goals and optional budgets failed with error: ',
      error,
    );
    Alert.alert(types2.ERROR, error);
  };

  const APIArray = [
    getSupports(memberPayload.planId, memberId),
    getGoalsForPlan(memberPayload.planId, memberId),
  ];

  let callBudgets = false;
  // Do checks for budgets first and then push to APIArray if needed
  if (_fetchBudgets(memberPayload, props)) {
    APIArray.push(getBudget(memberId));
    callBudgets = true;
  }

  callAPIs(APIArray, callback, errorHandler);
};

/**
 * This function will check whether the budgetAPI should be called based on the data returned from the memberAPI
 */
const _fetchBudgets = (memberPayload: any, props: any) => {
  // determine access first
  let fetchBudgets = false;
  if (hasBudgetAccess(memberPayload)) {
    const memberObj = memberPayload;
    const userObj = props.user;

    // fetch budget of the selected person if logged in user is a employee
    if (
      memberObj.pmExitDate != '' &&
      (userObj.isEmployee === true || userObj.isFinancialStaff === true)
    ) {
      return true;
    }
    if (
      (memberObj.memberStatus === types2.MEMBER_STATUS_DATA.MANAGED &&
        memberObj.planManager === types2.PLAN_MANAGER_DATA.LEAP_IN) ||
      (memberObj.planManager === types2.PLAN_MANAGER_DATA.PLAN_MANAGED &&
        memberObj.planStatus === types2.PLAN_STATUS_DATA.PM_ACTIVE)
    ) {
      fetchBudgets = true;
    }
  }
  return fetchBudgets;
};

const navigateToProfile = (props: any, reset: any) => {
  // const actionToDispatch = NavigationActions.reset({
  //   index: 0,
  //   actions: [
  //     NavigationActions.navigate({
  //       routeName: 'Home'
  //     })
  //   ]
  // });
  // if (reset) {
  //   props.navigation.dispatch(actionToDispatch);
  // }

  props.navigation.navigate('Splash', {
    screenCameFrom: {
      type: types2.ROUTING_STATE.LINKED_MEMBERS_SCREEN,
    },
  });
};

// This private function is to Switch depend on given screen.
// Whenever new screen need to add as bookmark add relevant screen names here.
const _switchBookMarkScreen = (screen: any) => {
  if (types2.DEEP_LINK_NAV_TYPES_ARRAY.includes(screen)) {
    setValue(types2.MENUE_ID, types2.PROFILE);
  } else {
    setValue(types2.MENUE_ID, types2.BUDGET_PAYMENTS);
  }
  switch (screen) {
    case types2.DEEP_LINK_NAV_TYPES.MY_PROFILE_GUIDE_ME:
      const showBookMarkGuideMe = {
        screen: types2.DEEP_LINK_SCREENS.MY_PROFILE,
        showGuideMe: true,
      };
      setValue(
        types2.SHOW_BOOK_MARK_GUIDE_ME,
        JSON.stringify(showBookMarkGuideMe),
      );
      return types2.DEEP_LINK_SCREENS.MY_PROFILE;
    case types2.DEEP_LINK_NAV_TYPES.MY_PROFILE_DETAILS:
      return types2.DEEP_LINK_SCREENS.ABOUT_ME_DETAILS_FORM;
    case types2.DEEP_LINK_NAV_TYPES.PLAN_SUMMARY:
      return types2.DEEP_LINK_SCREENS.PLAN_MEETING;
    case types2.DEEP_LINK_NAV_TYPES.GOAL:
      return types2.DEEP_LINK_SCREENS.GOAL;
    case types2.FORGOT_PASSWORD:
      return types2.DEEP_LINK_SCREENS.FORGOT_PASSWORD;
    case types2.DEEP_LINK_NAV_TYPES.PLAN_MEETING_CHECKLIST:
      return types2.DEEP_LINK_SCREENS.PLAN_MEETING_CHECKLIST;
    case types2.DEEP_LINK_NAV_TYPES.DOCUMENTS:
      return types2.DEEP_LINK_SCREENS.DOCUMENTS;
    case types2.DEEP_LINK_NAV_TYPES.PLAN_MANAGEMENT_OPTIONS:
      return types2.DEEP_LINK_SCREENS.PLAN_MANAGEMENT_OPTIONS;
    case types2.DEEP_LINK_NAV_TYPES.PROFILE_BUDGETS:
      return types2.DEEP_LINK_SCREENS.BUDGETS;
    case types2.DEEP_LINK_NAV_TYPES.DRAFT_INVOICE:
      return types2.DEEP_LINK_SCREENS.DRAFT_INVOICE;
    case types2.DEEP_LINK_NAV_TYPES.INVOICE_PAYMENT_HISTORY:
      return types2.DEEP_LINK_SCREENS.INVOICE_HISTORY;
    case types2.DEEP_LINK_NAV_TYPES.INVOICE || types2.DEEP_LINK_NAV_TYPES.SA:
      return types2.DEEP_LINK_SCREENS.APPROVAL_SUMMARY;
    case types2.DEEP_LINK_NAV_TYPES.SIGNUP_FOR_PLAN_MANAGEMENT:
      setValue(types2.BOOK_MARK_SIGNUP_PM, true);
      return types2.DEEP_LINK_SCREENS.SIGNUP_FOR_PLAN_MANAGEMENT;
    case types2.DEEP_LINK_NAV_TYPES.UPLOAD_NEW_PLAN:
      setValue(types2.BOOK_MARK_SETUP_NEW_PLAN, true);
      return types2.DEEP_LINK_SCREENS.UPLOAD_NEW_PLAN;
    case types2.DEEP_LINK_NAV_TYPES.PREVIOUS_PLANS:
      setValue(types2.BOOK_MARK_PREVIOUS_PLANS, true);
      return types2.DEEP_LINK_SCREENS.BUDGETS;
    case types2.DEEP_LINK_NAV_TYPES.PAYMENT_HISTORY:
      return types2.DEEP_LINK_SCREENS.PAYMENT_HISTORY;
    case types2.DEEP_LINK_NAV_TYPES.LOGIN_PAGE:
      return types2.DEEP_LINK_SCREENS.LOGIN_PAGE;
    default:
      return types2.DEEP_LINK_SCREENS.HOME;
  }
};

export const redirectToForgotPassword = (window: any) => {
  const urlParams = new URLSearchParams(window.location.search);
  let screen = urlParams.get('type');
  const email = urlParams.get('email');
  const origin = window.location.origin;
  let URL = '';
  if (_switchBookMarkScreen(screen) === types2.FORGOT_PASSWORD) {
    URL = origin + '/' + _switchBookMarkScreen(screen);
    URL = URL + '?email=' + email;
    return URL;
  }
  return URL;
};

// This Function will constructed URL to navigate depend on given Query parameters in bookmark URL
// If User is logged in straightway return the URL to navigate with given query params
// If User is not logged in create a bookMarkObject to access once user looged in to Navigate to particular URL
export const webBookMarkUrl = (window: any, loggedIn: any) => {
  const urlParams = new URLSearchParams(window.location.search);
  const schema = urlParams.get('schema');
  const origin = window.location.origin;
  let screen = urlParams.get('type');
  const itemId = urlParams.get('id');
  const memberID = urlParams.get('personId');
  const itemUri = urlParams.get('invoiceUri');
  const email = urlParams.get('email');
  if (schema && loggedIn === true) {
    let URL = origin + HOME + _switchBookMarkScreen(screen);
    if (_switchBookMarkScreen(screen) === types2.FORGOT_PASSWORD) {
      URL = origin + '/' + _switchBookMarkScreen(screen);
    }
    if (itemId) {
      URL = URL + '/' + itemId;
      setValue(types2.BOOK_MARK_EXTRA_ID, itemId);
    }
    if (itemUri) {
      setValue(types2.BOOK_MARK_INVOICE_URI, decodeURI(itemUri));
    }
    if (memberID) {
      setValue(types2.WEB_STORE.LOADED_MEMBER_ID, memberID);
    }

    if (email) {
      URL = URL + '?email=' + email;
    }
    return URL;
  }

  if (schema && loggedIn === false) {
    const bookMarkObject = {
      schema: schema,
      origin: origin,
      screen: _switchBookMarkScreen(screen),
      id: itemId,
    };
    if (itemUri) {
      setValue(types2.BOOK_MARK_INVOICE_URI, decodeURI(itemUri));
    }
    if (memberID) {
      setValue(types2.WEB_STORE.LOADED_MEMBER_ID, memberID);
    }
    return JSON.stringify(bookMarkObject);
  }
};

// This function will construct URL from bookMarkParams and Navigate to relevant screen
export const navigateToBooKMarkURL = (window: any, bookMarkParams: any) => {
  let URL = bookMarkParams.origin + HOME + bookMarkParams.screen;
  if (bookMarkParams.id) {
    URL = URL + '/' + bookMarkParams.id;
    setValue(types2.BOOK_MARK_EXTRA_ID, bookMarkParams.id);
  }
  window.open(URL, '_self');
  removeItem(types2.BOOK_MARK_PARAMS);
};

/*
 * Any deep linking related my profile stack can be facilitate from this helper function
 * @param {string} url
 * @param {props} props
 */
export const myProfileDeepLinkNavigate = (url: any, props: any) => {
  if (url) {
    const route = url.replace(/.*?:\/\//g, '');
    const navType = route.split('/')[0];
    switch (navType) {
      case types2.DEEP_LINK_NAV_TYPES.MY_PROFILE_GUIDE_ME:
        this.tabRootNavigator &&
          this.tabRootNavigator.dispatch(
            CommonActions.navigate({name: HOME}),
          );
        this._renderGuideMeModal(types2.PROFILE);
        break;
      case types2.DEEP_LINK_NAV_TYPES.MY_PROFILE_DETAILS:
        this.tabRootNavigator &&
          this.tabRootNavigator.dispatch(
            CommonActions.navigate({name: types2.ABOUT_ME_MY_DETAILS_FORM}),
          );
        break;
      case types2.DEEP_LINK_NAV_TYPES.PLAN_SUMMARY:
        this.tabRootNavigator &&
          this.tabRootNavigator.dispatch(
            CommonActions.navigate({name: types2.PLAN_MEETING}),
          );
        break;
      case types2.DEEP_LINK_NAV_TYPES.GOAL:
        this.tabRootNavigator &&
          this.tabRootNavigator.dispatch(
            CommonActions.navigate({name: types2.GOAL}),
          );
        break;
      case types2.DEEP_LINK_NAV_TYPES.PLAN_MEETING_CHECKLIST:
        this.tabRootNavigator &&
          this.tabRootNavigator.dispatch(
            CommonActions.navigate({name: types2.MEETING_CHECKLIST}),
          );
        break;
      case types2.DEEP_LINK_NAV_TYPES.FORGOT_PASSWORD:
        this.tabRootNavigator &&
          this.tabRootNavigator.dispatch(
            CommonActions.navigate({name: types2.FORGOT_PASSWORD}),
          );
        break;
      case types2.DEEP_LINK_NAV_TYPES.DOCUMENTS:
        this.tabRootNavigator &&
          this.tabRootNavigator.dispatch(
            CommonActions.navigate({name: types2.DOCUMENTS}),
          );
        break;
      case types2.DEEP_LINK_NAV_TYPES.PLAN_MANAGEMENT_OPTIONS:
        this.tabRootNavigator &&
          this.tabRootNavigator.dispatch(
            CommonActions.navigate({
              name: types2.PLAN_MANAGEMENT_SCREEN,
            }),
          );
        break;
      default:
        break;
    }
  }
};
