/**
 * Author: Andrew Seeley
 * Date: 14/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a title for most of the spending bars, the main title.
 * @param {String} totalSpendPrefix Any prefix to apply to the total spend
 * @param {String} totalSpend The total spend to display
 * @param {String} title The title to display after the total spend
 * @param {Object} textColors The text colours for the total spend & title
 * @param {Any} icon Optional: Icon to show on the left side
 * Example:
 * <SpendingBarTitleMain totalSpend={spend['total']}
 *                         title='Total funding for NDIS plan #123456'
 *                         textColors={BLACK_BUDGET_TITLE}
 *                         />
 * Where:
 *  spend = {
 *           'total': 146780,
 *           'spent': 87570,
 *           'allocated': 45200,
 *           'unspent': 26760,
 *       }
 *  BLACK_BUDGET_TITLE = {
 *    'budget': BrandColors.BLACK,
 *    'funding': BrandColors.CHARCOAL
 *   }
 */

import React, {Component} from 'react';
import {View, Text, Image, StyleSheet} from 'react-native';
import PropTypes from 'prop-types';
import {StandardText} from '../Atoms';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {formatAsCurrency} from '../../Helpers/Utils';
import {SummaryTile, CurrencyText} from './';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

class SpendingBarTitleMain extends Component {
  constructor(props: any) {
    super(props);
    this._renderIcon.bind(this);
    this._determineStyle.bind(this);
  }

  render() {
    const title = this.props.title;
    let totalSpendPrefix = '';
    const totalSpend = this.props.totalSpend;
    const textColors = this.props.textColors;
    const icon = this.props.icon;

    // If there is a prefix to the total spend apply it
    if (this.props.totalSpendPrefix) {
      if (this.props.showSummaryTitle) {
        totalSpendPrefix = `${this.props.totalSpendPrefix}`;
      } else {
        totalSpendPrefix = `${this.props.totalSpendPrefix} - `;
      }
    }

    return (
      <View style={[CommonStyles.spendingTitle]}>
        {this.renderTitle(
          totalSpendPrefix,
          totalSpend,
          title,
          textColors,
          icon,
        )}
      </View>
    );
  }

  renderTitle(
    totalSpendPrefix: any,
    totalSpend: any,
    title: any,
    textColors: any,
    icon: any,
  ) {
    if (this.props.showSummaryTitle) {
      return (
        <View style={[CommonStyles.flex1]}>
          <SummaryTile
            imageIcon={true}
            headerDisplayString={totalSpendPrefix}
            headerIconImage={{uri: icon}}
            screenType={types.SCREEN_TYPE_MAIN}
            HeaderTextColor={{color: textColors.budget}}
            HeaderBoarderColor={{borderColor: textColors.budget}}
            containsData={false}
            showButton={false}
            hideYellowStar
          />
          <View style={[CommonStyles.flexRow, CommonStyles.alignItemsCenter]}>
            <CurrencyText
              value={totalSpend}
              isPositive
              integerStyle={[
                CommonStyles.customFont,
                this._determineStyle(CommonStyles.font30, CommonStyles.font20),
                {color: textColors.budget},
              ]}
              decimalStyle={[
                CommonStyles.customFont,
                this._determineStyle(CommonStyles.font18, CommonStyles.font15),
                {color: textColors.budget},
              ]}
              containerStyle={[CommonStyles.flexDirectionRow]}
            />
            <StandardText
              style={[
                CommonStyles.flex1,
                CommonStyles.spendBarTitleFunding,
                CommonStyles.customFont,
                CommonStyles.font18,
                {color: textColors.funding},
              ]}
            >
              {` ${title}`}
            </StandardText>
          </View>
        </View>
      );
    }
    return (
      <View
        style={[
          CommonStyles.flex1,
          this._determineStyle(
            CommonStyles.flexRow,
            CommonStyles.flexDirectionColumn,
          ),
        ]}
      >
        <View
          style={[
            CommonStyles.justifyContentCentre,
            CommonStyles.alignItemsCenter,
          ]}
        >
          {this._renderIcon(icon)}
        </View>
        <View style={[CommonStyles.flexRow]}>
          <StandardText
            style={[
              CommonStyles.spendBarTitleBudget,
              CommonStyles.customFontBold,
              CommonStyles.fontWeightBG400,
              {color: textColors.budget},
            ]}
          >
            {totalSpendPrefix}
          </StandardText>
          <CurrencyText
            value={totalSpend}
            isPositive
            integerStyle={[
              CommonStyles.customFont,
              this._determineStyle(CommonStyles.font30, CommonStyles.font17),
              {color: textColors.budget},
            ]}
            decimalStyle={[
              CommonStyles.customFont,
              this._determineStyle(CommonStyles.font18, CommonStyles.font15),
              {color: textColors.budget},
            ]}
            containerStyle={[CommonStyles.flexDirectionRow]}
          />
        </View>
        <StandardText
          style={[
            CommonStyles.flex1,
            CommonStyles.customFont,
            CommonStyles.fontWeight400,
            CommonStyles.textAlignVerticalBottom,
            CommonStyles.paddingBottom3,
            this._determineStyle(CommonStyles.font18, CommonStyles.font15),
            {color: textColors.funding},
          ]}
        >
          {` ${title}`}
        </StandardText>
      </View>
    );
  }

  /**
   * Return the icon if the prop passed in and render it in an image
   * @param {Any} icon The icon image to render
   */
  _renderIcon(icon: any) {
    if (icon != undefined) {
      return (
        <Image
          style={[CommonStyles.titleBoxIcon, CommonStyles.titleBoxIconMargin]}
          source={{uri: icon}}
        />
      );
    }
    return null;
  }

  _determineStyle = (tab: any, normal: any) => {
    let orientation = this.props.orientation;

    return this.props.isTablet ? tab : normal;
  };
}
// Warn the user if props are not passed
SpendingBarTitleMain.propTypes = {
  totalSpend: PropTypes.number.isRequired,
  totalSpendPrefix: PropTypes.string,
  title: PropTypes.string.isRequired,
  textColors: PropTypes.object.isRequired,
  icon: PropTypes.any,
};

export default SpendingBarTitleMain;
