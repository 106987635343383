/*
 * Author: Shelan Cooray
 * Date: 21/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Budget Approvals Summary Card Component
 */
import React, {Component} from 'react';

import {View, FlatList, Image} from 'react-native';

import {BudgetsIconRed, GreyClockIcon3x} from '../../assets/images';
import {StandardText, StandardButton} from '../Atoms';
import {CurrencyText, GridView} from './';

//Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {APPROVALS_SUMMARY} from '../../Constants/Messages';

import {formatDDMMMYYDate} from '../../Helpers/Utils';

class SummaryCard extends Component {
  state = {};

  constructor(props: any) {
    super(props);
    this._renderValue.bind(this);
    this._renderDate.bind(this);
    this._onViewPress.bind(this);
    this._onApprovePress.bind(this);
  }

  render() {
    if (this.props.data && this.props.data.length > 0) {
      return this.props.data.map((singleList: any, index: any) => (
        <View
          style={[
            CommonStyles.mainContentContainer,
            CommonStyles.paddingLeft10,
            CommonStyles.paddingRight10,
          ]}
        >
          <View
            style={[
              CommonStyles.content,
              CommonStyles.marginTop20,
              index != this.props.data.length - 1
                ? CommonStyles.ApprovalSummaryGreySeperator
                : null,
            ]}
          >
            <View>
              {singleList[0].groupedInvoices
                ? this._renderApproveAllTile(singleList[0])
                : null}
            </View>
            <GridView
              maximumColumns={types.isWeb ? null : 1}
              isStatic={types.isWeb}
              viewWidth={this.props.viewWidth}
              isMobile={this.props.isMobile}
              itemDimension={this.props.itemDimension}
            >
              {this._renderList(singleList)}
            </GridView>
          </View>
        </View>
      ));
    } else {
      return null;
    }
  }

  _renderList = (data: any) => {
    let itemList: any = [];
    data.map((item: any) => {
      itemList.push(this._renderPaymentListItems(item));
    });

    return itemList;
  };

  _renderPaymentListItems = (item: any) => {
    let bubbleDisplay = '';
    if (item.isMemberSubmitted) {
      bubbleDisplay = types2.APPROVAL_TYPE.CLAIM_INVOICE;
    } else if (
      item.type === types2.APPROVAL_TYPE.INVOICE_DISPLAY.toLowerCase()
    ) {
      bubbleDisplay = types2.APPROVAL_TYPE.INVOICE_DISPLAY;
    } else {
      bubbleDisplay = types2.APPROVAL_TYPE.SERVICE_DISPLAY;
    }

    return (
      <View>
        {/* If the current item belongs to a group, render the approve all tile 
            Only the first item of the group will contain the groupedInvoices property
         */}
        <View
          style={[
            CommonStyles.budgetsTilesContainer,
            CommonStyles.budgetsTileWrapper,
            BrandStyles.primaryBgColor1,
            CommonStyles.justifyContentSpaceBetween,
            types.isWeb ? CommonStyles.paddingBottom22 : {},
          ]}
        >
          <View
            style={[
              CommonStyles.justifyFlexRowEnd,
              CommonStyles.alignItemsCenter,
              types.isWeb ? [CommonStyles.containerSummarySection] : {},
            ]}
          >
            <Image
              style={CommonStyles.containerBudgetApprovalCard_ImageDate}
              source={GreyClockIcon3x}
            />
            <StandardText
              style={[
                CommonStyles.font15,
                CommonStyles.customFont,
                BrandStyles.TextColor10,
              ]}
            >
              {this._renderDate(item)}
            </StandardText>
          </View>
          <View>
            <View
              style={[
                CommonStyles.justifyFlexRowStart,
                CommonStyles.alignItemsCenter,
                types.isWeb ? [CommonStyles.containerSummarySection] : {},
              ]}
            >
              <Image
                style={
                  types.isWeb
                    ? CommonStyles.containerBudgetApprovalCard_Image_Web
                    : CommonStyles.containerBudgetApprovalCard_Image
                }
                source={this._renderBillerIcon(item)}
              />
              <View style={[CommonStyles.justifyFlexColumnStart]}>
                <StandardText style={this._renderBillerStyle(item)}>
                  {item.providerFriendlyName
                    ? item.providerFriendlyName
                    : item.biller.name}
                </StandardText>
              </View>
            </View>
            <View
              style={[
                CommonStyles.marginLeft50,
                CommonStyles.marginBottom5,
                types.isWeb
                  ? [CommonStyles.flexDirectionRow, CommonStyles.flexWrap]
                  : null,
              ]}
            >
              <View style={[CommonStyles.containerGreyTextWrapper]}>
                <StandardText
                  style={[
                    types.isWeb ? CommonStyles.font14 : CommonStyles.font17,
                    CommonStyles.customFont,
                    BrandStyles.TextColor5,
                  ]}
                >
                  {bubbleDisplay}
                </StandardText>
              </View>
            </View>
          </View>
          <View style={[CommonStyles.greySeperator]} />
          <View
            style={[
              types.isWeb
                ? [CommonStyles.marginTop10, CommonStyles.minHeight85]
                : {},
            ]}
          >
            <View
              style={[
                CommonStyles.justifyFlexRowSpace,
                CommonStyles.marginTop5,
                CommonStyles.marginBottom5,
              ]}
            >
              <View
                style={[
                  CommonStyles.flex06,
                  CommonStyles.justifyContentFlexStart,
                  CommonStyles.flexDirectionColumn,
                ]}
              >
                {this._renderSADescription(item)}
                <StandardText
                  style={[
                    types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
                    CommonStyles.customFont,
                    BrandStyles.TextColor5,
                  ]}
                >
                  {item.invoiceNumber}
                </StandardText>
              </View>
              {this._renderValue(item)}
            </View>
          </View>
          <View
            style={[
              CommonStyles.justifyFlexRowSpace,
              CommonStyles.marginTop10,
              CommonStyles.wrapTileContainer,
              types.isWeb ? [CommonStyles.maxHeight15] : {},
            ]}
          >
            <StandardButton
              onPress={() => this._onViewPress(item)}
              style={[
                BrandStyles.brandBlockTxtColor6,
                CommonStyles.buttonBudgetAction,
                CommonStyles.customFontBold,
              ]}
              containerStyle={[
                BrandStyles.primaryBgColor1,
                BrandStyles.brandBorderColor1,
                CommonStyles.buttonContainerBudget,
                types.isWeb
                  ? [CommonStyles.maxHeight28, CommonStyles.minHeight28]
                  : {},
              ]}
              accessibilityLabel={types.ACTION_BUTTON.VIEW}
            >
              {types.ACTION_BUTTON.VIEW}
            </StandardButton>
            <StandardButton
              onPress={() => this._onApprovePress(item)}
              style={[
                BrandStyles.brandBlockTxtColor5,
                CommonStyles.buttonBudgetAction,
                CommonStyles.customFontBold,
              ]}
              containerStyle={[
                BrandStyles.primaryBgColor4,
                BrandStyles.brandBorderColor1,
                CommonStyles.buttonContainerBudget,
                types.isWeb
                  ? [CommonStyles.maxHeight28, CommonStyles.minHeight28]
                  : null,
              ]}
              accessibilityLabel={types.ACTION_BUTTON.APPROVE}
              readOnly={this.props.readOnly}
            >
              {types.ACTION_BUTTON.APPROVE}
            </StandardButton>
          </View>
        </View>
      </View>
    );
  };

  _renderApproveAllTile = (item: any) => (
    <View
      style={[
        CommonStyles.budgetsTilesContainer,
        CommonStyles.flexDirectionRow,
        CommonStyles.paddingTop25,
      ]}
    >
      <View
        style={[
          CommonStyles.flex06,
          CommonStyles.justifyContentFlexStart,
          CommonStyles.flexDirectionColumn,
        ]}
      >
        <StandardText
          style={[
            CommonStyles.font16,
            CommonStyles.customFontBold,
            CommonStyles.paddingRight5,
            CommonStyles.paddingLeft5,
            BrandStyles.TextColor13,
          ]}
        >
          {APPROVALS_SUMMARY.APPROVE_INVOICE_GROUP_MESSAGE(
            item.groupedInvoices.items.length,
            item.biller.name,
          )}
        </StandardText>
      </View>

      <View
        style={[
          CommonStyles.justifyContentFlexStart,
          CommonStyles.flexDirectionColumn,
        ]}
      >
        <StandardButton
          onPress={() => this._onApproveGroupPress(item.groupedInvoices.items)}
          style={[
            BrandStyles.brandBlockTxtColor5,
            CommonStyles.buttonBudgetAction,
            CommonStyles.customFontBold,
          ]}
          containerStyle={[
            BrandStyles.primaryBgColor4,
            BrandStyles.brandBorderColor1,
            CommonStyles.buttonContainerBudget,
            CommonStyles.alignFlexEnd,
            CommonStyles.maxWidth150,
            CommonStyles.paddingLeft10,
            CommonStyles.paddingRight10,
          ]}
          accessibilityLabel={types.ACTION_BUTTON.APPROVE_ALL}
        >
          {types.ACTION_BUTTON.APPROVE_ALL}
        </StandardButton>
      </View>
    </View>
  );

  _renderApproveAllTile = (item: any) => (
    <View
      style={[
        CommonStyles.budgetsTilesContainer,
        CommonStyles.flexDirectionRow,
        CommonStyles.justifyContentSpaceBetween,
        CommonStyles.paddingTop25,
      ]}
    >
      <View
        style={[
          CommonStyles.flex06,
          CommonStyles.justifyContentFlexStart,
          CommonStyles.flexDirectionColumn,
        ]}
      >
        <StandardText
          style={[
            CommonStyles.font16,
            CommonStyles.customFontBold,
            CommonStyles.paddingRight5,
            CommonStyles.paddingLeft5,
            BrandStyles.TextColor13,
          ]}
        >
          {APPROVALS_SUMMARY.APPROVE_INVOICE_GROUP_MESSAGE(
            item.groupedInvoices.items.length,
            item.biller.name,
          )}
        </StandardText>
      </View>
      <View style={[CommonStyles.flex04]}>
        <StandardButton
          onPress={() => this._onApproveGroupPress(item.groupedInvoices.items)}
          style={[
            BrandStyles.brandBlockTxtColor5,
            CommonStyles.buttonBudgetAction,
            CommonStyles.customFontBold,
          ]}
          containerStyle={[
            BrandStyles.primaryBgColor4,
            BrandStyles.brandBorderColor1,
            CommonStyles.buttonContainerBudget,
            CommonStyles.alignFlexEnd,
            CommonStyles.maxWidth150,
            CommonStyles.paddingLeft10,
            CommonStyles.paddingRight10,
          ]}
          accessibilityLabel={types.ACTION_BUTTON.APPROVE_ALL}
        >
          {types.ACTION_BUTTON.APPROVE_ALL}
        </StandardButton>
      </View>
    </View>
  );

  _renderValue = (item: any) => {
    return (
      <View
        style={[
          CommonStyles.flex06,
          CommonStyles.flexDirectionRow,
          CommonStyles.justifyContentFlexEnd,
        ]}
      >
        <CurrencyText
          value={item.total}
          isPositive={true}
          integerStyle={[
            types.isWeb ? CommonStyles.font20 : CommonStyles.font30,
            CommonStyles.customFont,
            BrandStyles.TextColor5,
          ]}
        />
      </View>
    );
  };

  _renderBillerIcon = (item: any) => {
    let icon = BudgetsIconRed;
    if (item.type === types2.APPROVAL_TYPE.SERVICE) {
      if (item.budgetCategoryData !== undefined) {
        icon = {uri: item.budgetCategoryData.icon.url};
      }
    }
    return icon;
  };

  _renderBillerStyle = (item: any) => {
    let styles = [
      CommonStyles.summaryCardText,
      CommonStyles.customFontSemiBold,
      BrandStyles.TextColor3,
    ];
    if (item.type === types2.APPROVAL_TYPE.SERVICE) {
      if (item.budgetCategoryData !== undefined) {
        styles = [
          CommonStyles.summaryCardText,
          CommonStyles.customFontSemiBold,
          {color: item.budgetCategoryData.color},
        ];
      }
    }
    return styles;
  };

  _renderSADescription = (item: any) => {
    if (item.type === types2.APPROVAL_TYPE.SERVICE) {
      return (
        <StandardText
          style={[
            types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
            CommonStyles.customFont,
            BrandStyles.TextColor5,
            CommonStyles.marginBottom5,
          ]}
        >
          {item.budgetCategory}
        </StandardText>
      );
    }
  };

  _renderDate = (item: any) => {
    if (item.type === types2.APPROVAL_TYPE.SERVICE) {
      return (
        formatDDMMMYYDate(item.startDate) +
        ' - ' +
        formatDDMMMYYDate(item.endDate)
      );
    } else if (item.type === types2.APPROVAL_TYPE.INVOICE) {
      return formatDDMMMYYDate(item.invoiceDate);
    }
    return '';
  };

  _onViewPress = (item: any) => {
    if (this.props.itemViewPress) {
      this.props.itemViewPress(item);
    }
  };

  _onApprovePress = (item: any) => {
    if (this.props.itemApprovePress) {
      this.props.itemApprovePress(item);
    }
  };

  _onApproveGroupPress = (invoiceGroup: any) => {
    if (this.props.approveInvoiceGroup) {
      this.props.approveInvoiceGroup(invoiceGroup);
    }
  };
}

export default SummaryCard;
