/*
 * Author: Roshan Piumal
 * Date: 07/06/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a back button component with standard styling applied.
 */

import React, { Component } from 'react';
import { Image, View } from 'react-native';
import StandardText from './StandardText';
import StandardTouchableOpacity from './StandardTouchableOpacity';

// Import Stylesheets
import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';

import {
  backArrowblack,
  backArrowPurple,
  backArrowWhite,
} from '../../assets/images';
import * as types2 from '../../Constants/Constants2';

class BackButton extends Component {
  render() {
    return (
      <View
        style={[CommonStyles.flexDirectionRow, CommonStyles.alignFlexStart]}
      >
        <StandardTouchableOpacity
          accessible
          accessibilityLabel={types2.GO_BACK}
          activeOpacity={0.6}
          onPress={() => this.props.goBack()}
        >
          <View
            style={[
              CommonStyles.alignItemsCenter,
              CommonStyles.screenWrapperHeaderContainer,
            ]}
          >
            <Image
              style={[CommonStyles.screenWrapperHeaderIcon]}
              source={
                this.props.isPurple
                  ? backArrowPurple
                  : this.props.isBlack
                  ? backArrowblack
                  : backArrowWhite
              }
            />
            <StandardText
              allowFontScaling={false}
              style={[
                CommonStyles.Transparent,
                CommonStyles.customFont,
                this.props.isPurple
                  ? BrandStyles.WrapperHeaderEditTextPurpleColor
                  : this.props.isBlack
                  ? BrandStyles.TextColor2
                  : BrandStyles.TextColor1,
              ]}
            >
              {types2.BACK}
            </StandardText>
          </View>
        </StandardTouchableOpacity>
      </View>
    );
  }
}

BackButton.defaultProps = {
  isPurple: false,
};

export default BackButton;
