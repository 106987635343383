/*
 * Author: Mayuran
 * Date: 07/11/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays a title and expand/collapse button.
 * clicking the expand/collapse button will show/hide the content within the tile
 */

import React, {Component} from 'react';
import {Image, View} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {ACCORDIAN} from '../../Constants/Messages';

import {StandardText, StandardTouchableOpacity} from '../Atoms';
import {GridView} from './';
import {formatDDMMMYYDate} from '../../Helpers/Utils';

import {
  arrowCollapseCharcoal60,
  arrowExpandCharcoal60,
  GreyClockIcon3x,
  approvalCharcoal,
  editIconCharcoal60,
  emailIcon,
  addIconGrey,
} from '../../assets/images';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {filter} from 'lodash';

const REIMBURSEMENTACCOUNT = 'reimbursementAccount';
const PROVIDERACCOUNT = 'providerAccount';
const INVOICE_DATES = [
  {
    name: 'Invoice Date',
    image: GreyClockIcon3x,
    param: 'invoiceDate',
    type: 'date',
    source: null,
  },
  {
    name: 'Invoice Received Date',
    image: GreyClockIcon3x,
    param: 'invoiceReceiveDate',
    type: 'date',
    source: null,
  },
  {
    name: 'Approved Date',
    image: approvalCharcoal,
    param: 'approvedDate',
    type: 'date',
    source: null,
  },
  {
    name: 'Created Date',
    image: editIconCharcoal60,
    param: 'createdDate',
    type: 'date',
    source: null,
  },
  {
    name: 'Claimed Date',
    image: emailIcon,
    param: 'claimedDate',
    type: 'date',
    source: null,
  },
  {
    name: 'Created By',
    image: addIconGrey,
    param: 'createdUser',
    type: 'string',
    source: 'createdSource',
  },
  {
    name: 'Updated By',
    image: editIconCharcoal60,
    param: 'modifiedUser',
    type: 'string',
    source: null,
  },
  {
    name: 'Provider Account:',
    image: null,
    param: PROVIDERACCOUNT,
    type: 'provider_object',
    source: PROVIDERACCOUNT,
  },
  {
    name: 'Reimbursement To:',
    image: null,
    param: REIMBURSEMENTACCOUNT,
    type: 'provider_object',
    source: REIMBURSEMENTACCOUNT,
  },
];
const HIDE_DETAILS = 'Hide detail';
const CUT_OFF_PADDING = 75;
const WEB_TABLET_TILES = 3;
const MOBILE_TILES = 2;
const DATE_TYPE = 'date';
const STRING_TYPE = 'string';
const PROVIDER_OBJECT = 'provider_object';
class AccordianInvoiceDate extends Component {
  state = {
    collapsed: types.isWeb ? false : true,
  };

  constructor(props: any) {
    super(props);
  }

  setDimesions(layout: any) {
    const {x, y, width, height} = layout;
    this.setState({
      viewWidth: width,
    });
  }

  render() {
    const _invoiceDate = this.props.invoiceDetails.invoiceDate
      ? formatDDMMMYYDate(this.props.invoiceDetails.invoiceDate)
      : '';
    return (
      <View
        onLayout={event => {
          this.setDimesions(event.nativeEvent.layout);
        }}
      >
        <StandardTouchableOpacity
          accessibilityLabel={this._determineLabel()}
          activeOpacity={0.6}
          onPress={() => {
            this._toggleAccordian();
          }}
        >
          <View
            style={[
              CommonStyles.flex1,
              CommonStyles.flexRow,
              this.props.leftAlignContent ? null : CommonStyles.justifyEnd,
              CommonStyles.alignItemsCenter,
              CommonStyles.paddingTop10,
            ]}
          >
            <Image
              style={CommonStyles.containerClock}
              source={GreyClockIcon3x}
            />
            <StandardText
              style={[
                CommonStyles.customFont,
                BrandStyles.TextColor10,
                types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
                CommonStyles.marginRight10,
              ]}
            >
              {this.state.collapsed ? _invoiceDate : HIDE_DETAILS}
            </StandardText>

            <View
              style={[
                CommonStyles.flexDirectionRow,
                CommonStyles.alignItemsCenter,
              ]}
            >
              <Image
                style={[CommonStyles.accordianButtonIcon]}
                source={this._determineIcon()}
              />
            </View>
          </View>
        </StandardTouchableOpacity>
        <View style={[types.isWeb ? CommonStyles.alignItemsCenter : null]}>
          {this._renderChildren()}
        </View>
      </View>
    );
  }

  _renderChildren = () => {
    if (this.state.collapsed) {
      return null;
    } else {
      let filteredInvoiceDates = [];
      if (
        types.isWeb &&
        (this.props.user.isEmployee || this.props.user.isFinancialStaff)
      ) {
        filteredInvoiceDates = INVOICE_DATES;
      } else {
        filteredInvoiceDates = filter(INVOICE_DATES, i => {
          return i.param !== 'invoiceReceiveDate';
        });
      }

      if (!this.props.invoiceDetails.providerAccount) {
        filteredInvoiceDates = filter(INVOICE_DATES, item => {
          return item.param !== PROVIDERACCOUNT;
        });
      }

      if (!this.props.invoiceDetails.reimbursementAccount) {
        filteredInvoiceDates = filter(INVOICE_DATES, item => {
          return item.param !== REIMBURSEMENTACCOUNT;
        });
      }

      let outerViewWidth = 0;
      return (
        <View
          onLayout={event => {
            outerViewWidth = event.nativeEvent.layout.width;
          }}
          style={this._determineGridContainerStyle()}
        >
          <GridView
            viewWidth={
              types.isWeb
                ? this.props.viewWidth - CUT_OFF_PADDING
                : outerViewWidth
            }
            isMobile={this.props.isMobile}
            maximumColumns={
              this.state.viewWidth > types2.MEDIA_QUERY.CUT_OFF
                ? WEB_TABLET_TILES
                : MOBILE_TILES
            }
            isStatic={true}
          >
            {filteredInvoiceDates.map((date, index) => {
              return (
                <View
                  style={[
                    CommonStyles.flexDirectionRow,
                    CommonStyles.paddingTop10,
                    CommonStyles.paddingLeft20,
                  ]}
                >
                  {date.image !== null && (
                    <Image
                      style={CommonStyles.containerClock}
                      source={date.image}
                    />
                  )}
                  <View
                    accessible={true}
                    style={[
                      CommonStyles.flexDirectionColumn,
                      date.image === null ? CommonStyles.marginLeft40 : {},
                    ]}
                  >
                    <StandardText
                      style={[
                        types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
                        BrandStyles.TextColor5,
                        CommonStyles.customFont,
                      ]}
                    >
                      {date.name}
                    </StandardText>
                    <StandardText style={this._determineMainLabelStyleStyle()}>
                      {this._displayValue(date)}
                    </StandardText>
                    {this._displaySource(date)}
                  </View>
                </View>
              );
            })}
          </GridView>
        </View>
      );
    }
  };

  _displayValue = (item: any) => {
    if (this.props.invoiceDetails[item.param] && item.type === DATE_TYPE) {
      return formatDDMMMYYDate(this.props.invoiceDetails[item.param]);
    }
    if (this.props.invoiceDetails[item.param] && item.type === STRING_TYPE) {
      return this.props.invoiceDetails[item.param];
    }
    if (
      this.props.invoiceDetails[item.param] &&
      item.type === PROVIDER_OBJECT
    ) {
      return this.props.invoiceDetails[item.param].friendlyName;
    }
    return '-';
  };

  _displaySource = (date: any) => {
    if (date.source && this.props.invoiceDetails[date.source] !== null) {
      let source = '';
      if (
        this.props.invoiceDetails[date.source] &&
        date.type === PROVIDER_OBJECT
      ) {
        source =
          this.props.invoiceDetails[date.source].bsb +
          ' - ' +
          this.props.invoiceDetails[date.source].accountNumber;
      } else {
        source = this.props.invoiceDetails[date.source];
      }
      return (
        <StandardText style={this._determineMainLabelStyleStyle()}>
          {source}
        </StandardText>
      );
    }
  };

  _determineGridContainerStyle = () => {
    let styles: any = [];
    if (types.isWeb && this.props.isMobile) {
      styles = [CommonStyles.marginRight25];
    }
    return styles;
  };

  _determineMainLabelStyleStyle = () => {
    let styles = [
      CommonStyles.font17,
      BrandStyles.TextColor5,
      CommonStyles.customFont,
      CommonStyles.maxWidth150,
    ];
    if (types.isWeb) {
      styles.push([
        CommonStyles.font15,
        BrandStyles.TextColor10,
        CommonStyles.maxWidth300,
      ]);
      if (this.props.isMobile) {
        styles.push([CommonStyles.maxWidth125]);
      }
    }
    return styles;
  };

  _determineIcon = () => {
    if (this.state.collapsed) {
      return arrowExpandCharcoal60;
    } else {
      return arrowCollapseCharcoal60;
    }
  };

  _determineLabel = () => {
    if (this.state.collapsed) {
      return ACCORDIAN.EXPAND;
    } else {
      return ACCORDIAN.COLLAPSE;
    }
  };

  _toggleAccordian = () => {
    let flag = !this.state.collapsed;
    if (this.props.callback) {
      this.props.callback(flag);
    }
    this.setState({collapsed: flag});
  };
}

export default AccordianInvoiceDate;
