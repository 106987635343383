/*
 * Authour: Andrew Lee
 * Email: andrew.l.lee@au.ey.com
 * Date: 06/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This will be the header, and the side components so that it can be reused by all other developers instead of each developer having to add their own set of headers and left right containers.
 * @param {object} contents The contents that will be displayed within the container.
 * @param {array} modals The modals that will need to be loaded
 * @param {boolean} needsSidebar Whether the container needs to include the side bar.
 * @param {boolean} sidebarProfilePicture Whether to display the profile picture component on the side bar.
 * @param {function} sidebarContent The content that should be included in the side bar.
 */

import React, {Component} from 'react';
import {
  View,
  Image,
  Dimensions,
  RefreshControl,
  ScrollView,
  Platform,
  Text,
} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {
  WrapperHeader,
  MemberPopOver,
  BrandActivityIndicator,
  NavigationConfirmModal,
  LogoutConfirmationModal,
  DemoBanner,
} from './';
import {Overlay, HeaderWarningBanner} from '../Atoms';
import GuideMeModal from '../../Screens/GuideMeModal';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {Sidebar, OfflineNotice, MenuContainer} from '../Organisms';
import {KeyboardAwareScrollView} from 'helpers/KeyboardAwareScrollView';
import {DrawerActions} from '@react-navigation/native';
// Detect device is a tablet or mobile
import DeviceInfo from 'react-native-device-info';
import { RootTabContext } from '../../context/RootTabContext';
import logger from 'helpers/Logger';

const {height, width} = Dimensions.get('window');

class Container extends Component {
   static contextType:React.ContextType<typeof RootTabContext> = RootTabContext;
  state = {
    profilePictureModalVisible: false,
    isOpen: false,
  };

  constructor(props: any) {
    super(props);
    this._renderContentsView.bind(this);
    this._renderContents.bind(this);
    this._setRef.bind(this);

    this._closeprofilePictureModal.bind(this);
    this._setProfilePicture.bind(this);
    this._determineAccess.bind(this);
    this._getAvatarImages.bind(this);

    this.renderImage = this.renderImage.bind(this);
    this._renderSideMenu = this._renderSideMenu.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.getMainContainerStyles = this.getMainContainerStyles.bind(this);
    this.renderHeaderWarningBanner = this.renderHeaderWarningBanner.bind(this);
  }

  UNSAFE_componentWillMount() {
    const isTablet = DeviceInfo.isTablet();
    const rootContext = this.context;
    if (isTablet) {
      this.setState({isOpen:  rootContext.screenProps?.getInitialMenuState ? rootContext.screenProps.getInitialMenuState() : false});
    }
  }
  _setRef = (scrollView: any) => {
    if (this.props.onRef) {
      this.props.onRef(scrollView);
    }
  };

  renderImage() {
    if (this.props.isOpen) {
      return (
        <Image
          source={closeDockedDrawer}
          style={[CommonStyles.sideBarDockButtonIcon, CommonStyles.zIndex10]}
        />
      );
    } else {
      return (
        <Image
          source={openDockedDrawer}
          style={[CommonStyles.sideBarDockButtonIcon, CommonStyles.zIndex10]}
        />
      );
    }
  }

  renderHeaderWarningBanner = () => {
    return <HeaderWarningBanner />;
  };

  // Build the container and then slot in the props that should be JSX
  render() {
    return (
      <View style={CommonStyles.screenWrapper} accessibilityViewIsModal={true}>
        <NavigationConfirmModal
          nav={this.props.nav}
          hasFormContentChanged={this.props.hasFormContentChanged}
          currentScreenName={this.props.currentScreenName}
        />
        <LogoutConfirmationModal navigation={this.props.nav} />
        {/* {this.props.headerTitle && <WrapperTitle text={this.props.headerTitle} screenType={this.props.screenType} displayIcon={this.props.icon} />} */}

        <WrapperHeader
          style={[this._screenWrapStyle('header'), CommonStyles.zIndex1]}
          onPressPicture={this._showProfilePicture}
          selectedIcon={this.props.selectedIcon}
          activeScreen={this.props.activeScreen}
          screenType={this.props.screenType}
          nav={this.props.nav}
          headerTitle={this.props.headerTitle}
          hideBackButton={this.props.hideBackButton}
          isOpen={this.state.isOpen}
          toggleMenu={this.toggleMenu}
          loading={this.props.loading}
          hideMoreButton={this.props.hideMoreButton || false}
          screen={this.props.previousScreen}
        />
        {this.renderDemoBanner()}
        {this.props.isBudgetUnderMaintenanceStatus &&
        this.props.headerTitle === types2.NAVIGATION_TEXT.MY_BUDGETS
          ? this.renderHeaderWarningBanner()
          : null}
        <OfflineNotice />
        <MemberPopOver />
        {this._renderSideMenu(this.props.byPassOnLayout)}
        <BrandActivityIndicator loading={this.props.loading} />
        {this.props.overlay && (
          <Overlay
            style={this.props.overlayStyle}
            backgroundColor={this.props.overlayColor}
            bgColour={this.props.overlayColour}
          />
        )}
      </View>
    );
  }
  _createSetProfilePicture() {
    return (
      <SetProfilePicture
        close={this._closeprofilePictureModal.bind(this)}
        imageObject={this.props.member.profileImage}
        setProfilePicture={this._setProfilePicture.bind(this)}
        determineAccess={this._determineAccess.bind(this)}
        visible={this.state.profilePictureModalVisible}
        forceMemberImageUpload={true}
      />
    );
  }

  renderDemoBanner = () => {
    if (this.props.demoProfile) {
      return (
        <DemoBanner
          logout={() => {
            this.props.showConfirmLogout();
          }}
          createAccount={() => this.setState({createAccountPopup: true})}
        />
      );
    }
    return null;
  };

  _getAvatarImages = () => {
    let callbackFunction = (data: any) => {
      var avatarList = data[0].content;
      this.props.actions.AvatarListActions.actionGetAvatarList(avatarList);
    };
    callAPIs(
      [getAvatarImages(this.props.loadedMemberId.loadedMemberId)],
      callbackFunction,
      null,
      () => {},
    );
  };

  _showProfilePicture = () => {
    this.setState({profilePictureModalVisible: true});
    this._getAvatarImages();
  };
  _closeprofilePictureModal = () => {
    this.setState({profilePictureModalVisible: false});
  };
  _determineAccess = () => {
    if (
      this.props.member === undefined ||
      Object.getOwnPropertyNames(this.props.member).length === 0
    ) {
      return null;
    } else {
      let readOnly = this.props.member;
      if (readOnly.access) {
        if (readOnly.access.profile) {
          if (readOnly.access.profile == types.ACCESS_READ_ONLY) {
            return types.ACCESS_READ_ONLY;
          }
        }
      }
    }
    return null;
  };

  _setProfilePicture = (requestObject: any) => {
    imageObjectState = {
      lastModified: new Date(),
      key: requestObject.imagePath,
      url: requestObject.imagePath,
      localImage: true,
    };
    saveObj = {
      id: this.props.member.id,
      profileImage: imageObjectState,
    };
    this.props.actions.MemberActions.actionUpdateMember(saveObj);
    this.setState({profileImage: imageObjectState, lastUpdate: new Date()});
  };

  getMainContainerStyles() {
    const isTablet = DeviceInfo.isTablet();
    let mainContainerStyles = [
      CommonStyles.alignItemsCenter,
      CommonStyles.scrollViewVerticalCentre,
      CommonStyles.contentBackgroundColor,
    ];
    if (!this.props.ignorePadding) {
      mainContainerStyles.push(CommonStyles.mainContentScrollView);
    } else {
      if (this.props.customStyle) {
        mainContainerStyles.push(this.props.customStyle);
      }
    }

    let updatedContentOffset = types2.CONTENT_OFFSET;
    let updatedViewOffset = types2.VIEW_WIDTH_OFFSET;
    let updatedPhoneViewOffset = types2.PHONE_VIEW_WIDTH_OFFSET;

    if (this.props.removeExtraOffset) {
      updatedContentOffset = types2.CONTENT_OFFSET - types2.WIDTH_OFFSET_CHANGE;
      updatedViewOffset = types2.VIEW_WIDTH_OFFSET - types2.WIDTH_OFFSET_CHANGE;
      updatedPhoneViewOffset =
        types2.PHONE_VIEW_WIDTH_OFFSET - types2.WIDTH_OFFSET_CHANGE;
    }

    if (this.state.isOpen && !this.getContentLayout()) {
      viewWidth = Dimensions.get('screen').width - updatedContentOffset;
    } else {
      viewWidth = Dimensions.get('screen').width - updatedViewOffset;
    }

    if (!isTablet) {
      viewWidth = Dimensions.get('screen').width - updatedPhoneViewOffset;
    }
    mainContainerStyles.push({width: viewWidth});
    if (isTablet) {
      setTimeout(
        function (this: any) {
          this.setState({
            mainContainerStyles,
          });
        }.bind(this),
        500,
      );
    } else {
      this.setState({
        mainContainerStyles,
      });
    }
  }

  _renderContentsView(byPassOnLayout: any) {
    // If there isScroll dosen't exist set it as true
    if (this.props.isScroll === undefined) {
      isScroll = true;
    } else {
      isScroll = this.props.isScroll;
    }

    // Most of the time the container will be a scroll view to support content going off the screen
    // The only cases where this isn't set to true is if the components on the screen itself don't take up the whole screen
    // Or include scroll views in the component itself (E.g Goal tracker screen)
    const ScrollContainer = KeyboardAwareScrollView; 

    if (isScroll) {
      return (
        <View>
          <ScrollContainer
            refreshControl={
              this.props.onRefresh ? (
                <RefreshControl
                  refreshing={this.props.refreshing}
                  onRefresh={this.props.onRefresh}
                />
              ) : null
            }
            enableOnAndroid={true}
            enableAutomaticScroll={true}
            style={[CommonStyles.content]}
            contentContainerStyle={this.state.mainContainerStyles}
            scrollEnabled={this.props.scrollEnabled}
            ref={input => this._setRef(input)}
            onLayout={() => this.getMainContainerStyles()}
            extraHeight={150}
            keyboardShouldPersistTaps="always"
            key={byPassOnLayout ? this.state.isOpen : undefined}
          >
            {this._renderContents()}
          </ScrollContainer>
        </View>
      );
    } else {
      return (
        <View style={[CommonStyles.content]}>
          <View
            style={this.state.mainContainerStyles}
            onLayout={() => this.getMainContainerStyles()}
          >
            {this._renderContents()}
          </View>
        </View>
      );
    }
  }

  getContentLayout() {
    const isTablet = DeviceInfo.isTablet();
    return (
      !isTablet ||
      width < types2.MEDIA_QUERY.CUT_OFF ||
      height < types2.MEDIA_QUERY.CUT_OFF
    );
  }

  _renderContents = () => {
    let styles = [CommonStyles.mainContentContainer];
    if (!this.props.removeBottomPadding) {
      styles.push(CommonStyles.paddingBottom40);
    }
    return <View style={styles}>{this.props.contents()}</View>;
  };

  _screenWrapStyle = (screen: any) => {
    const isTablet = DeviceInfo.isTablet();

    var colour = '';
    var container = '';

    switch (this.props.screenType) {
      case types.SCREEN_TYPE_MAIN:
        colour = BrandStyles.ContainerBackgroundColor;
        break;
      case types.SCREEN_TYPE_MAIN_ALTERNATIVE:
        colour = BrandStyles.ContainerPrimaryBgColor;
        break;
      case types.SCREEN_TYPE_EDIT:
        colour = BrandStyles.ContainerSecondaryBgColor;
        break;
      default:
        colour = '';
    }

    switch (screen) {
      case 'header':
        container = isTablet
          ? CommonStyles.headerContainer
          : CommonStyles.headerContainerMobile;
        break;
      default:
        container = '';
    }
    return [container, colour];
  };

  toggleMenu() {
    if (this.getContentLayout()) {
      this.props.nav.dispatch(DrawerActions.openDrawer());
    } else {
      this.setState(
        {
          isOpen: this.context?.screenProps.toggleMenu(),
        },
        () => this.getMainContainerStyles(),
      );
    }
  }

  _createSidebar = () => {
    if (this.props.needsSidebar) {
      return (
        <Sidebar
          mode={this.props.sidebarMode}
          content={this.props.sidebarContent}
          profilePicture={this.props.sidebarProfilePicture}
          navigation={this.props.nav}
        />
      );
    } else {
      return null;
    }
  };

  _createGuideMeModal = () => {
    return <GuideMeModal navigation={this.props.nav} />;
  };

  _createModals = () => {
    if (typeof this.props.modals === 'function') {
      return this.props.modals();
    } else {
      return null;
    }
  };

  _renderSideMenu = (byPassOnLayout: any) => {
    if (!this.getContentLayout()) {
      return (
        <View style={CommonStyles.contentWrapper}>
          <MenuContainer
            nav={this.props.nav}
            isOpen={this.state.isOpen}
            toggleMenu={this.toggleMenu}
            openMenuOffset={types2.SIDEBAR_WIDTH}
          >
            <View style={this._screenWrapStyle('left')} />
            {this._renderContentsView(byPassOnLayout)}
            <View style={this._screenWrapStyle('right')} />
            {this._createGuideMeModal()}
            {this._createModals()}
          </MenuContainer>
        </View>
      );
    } else {
      return (
        <View style={CommonStyles.contentWrapper}>
          {this._renderContentsView()}
          {this._createGuideMeModal()} 
           {this._createModals()}
        </View>
      );
    }
  };
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {},
});

Container.defaultProps = {
  scrollEnabled: true,
};

export default Container;
