/*
 * Author: Shelan Cooray
 * Date: 23/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Cunstuct nicely formatted Currency Value with given styles
 * e.g $44.50 or -$44.40
 */

import React, {Component} from 'react';
import {View} from 'react-native';
import {StandardText} from '../Atoms';
import {formatAsCurrency} from '../../Helpers/Utils';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

class CurrencyText extends Component {
  constructor(props: any) {
    super(props);
    this._renderNegativeSign = this._renderNegativeSign.bind(this);
    this._determineContainerStyle = this._determineContainerStyle.bind(this);
    this._determineIntegerStyle = this._determineIntegerStyle.bind(this);
    this._determineDecimalStyle = this._determineDecimalStyle.bind(this);
  }

  render() {
    const formattedCurrency = formatAsCurrency(
      this.props.value,
      types2.EMPTY_STRING,
      2,
    );
    const intValue = formattedCurrency.toString().split(types2.DOT)[0];
    const decimalValue = formattedCurrency.toString().split(types2.DOT)[1];
    if (this.props.noContainer) {
      return (
        <StandardText
          accessibilityLabel={
            types2.DOLLOR +
            this._renderNegativeSign(this.props.value) +
            intValue +
            types2.DOT +
            decimalValue
          }
        >
          <StandardText style={this._determineIntegerStyle()}>
            {this._renderNegativeSign(this.props.value) +
              types2.DOLLOR +
              intValue}
          </StandardText>
          <StandardText style={this._determineDecimalStyle()}>
            {types2.DOT + decimalValue}
          </StandardText>
        </StandardText>
      );
    } else {
      return (
        <View style={this._determineContainerStyle()}>
          <StandardText
            accessibilityLabel={
              types2.DOLLOR +
              this._renderNegativeSign(this.props.value) +
              intValue +
              types2.DOT +
              decimalValue
            }
          >
            <StandardText style={this._determineIntegerStyle()}>
              {this._renderNegativeSign(this.props.value) +
                types2.DOLLOR +
                intValue}
            </StandardText>
            <StandardText style={this._determineDecimalStyle()}>
              {types2.DOT + decimalValue}
            </StandardText>
          </StandardText>
        </View>
      );
    }
  }

  _renderNegativeSign = (item: any) => {
    if (this.props.isPositive && item < 0) {
      return types2.MINUS;
    } else {
      return types2.EMPTY_STRING;
    }
  };

  // Overide Interger value + currency sign if styles send via props
  _determineIntegerStyle = () => {
    let styles = [
      CommonStyles.font25,
      CommonStyles.customFontSemiBold,
      BrandStyles.TextColor5,
    ];
    if (this.props.integerStyle) {
      styles = this.props.integerStyle;
    }
    return styles;
  };

  // Overide Decimal value + dot sign if styles send via props
  _determineDecimalStyle = () => {
    let styles = [
      CommonStyles.font15,
      CommonStyles.customFont,
      BrandStyles.TextColor5,
    ];
    if (this.props.decimalStyle) {
      styles = this.props.decimalStyle;
    }
    return styles;
  };

  _determineContainerStyle = () => {
    let styles = [
      CommonStyles.flex1,
      CommonStyles.flexDirectionRow,
      CommonStyles.justifyContentFlexEnd,
    ];
    if (this.props.containerStyle) {
      styles = [this.props.containerStyle];
    }
    return styles;
  };
}

export default CurrencyText;
