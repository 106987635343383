/*
 * Author: Declan Hart
 * Date: 16/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Display Support details
 */

import React, {Component} from 'react';
import {StyleSheet, View, Image, TextInput} from 'react-native';
import Button from 'react-native-button';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import {
  SupportsChangeUnselected,
  SupportsChangeSelected,
  SupportsKeepUnselected,
  SupportsKeepSelected,
  SupportsStopUnselected,
  SupportsStopSelected,
} from '../../assets/images';

import {StandardText} from '../Atoms';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class TrafficLightOption extends Component {
  state = {
    supportTitle: '',
  };

  constructor(props: any) {
    super(props);
    this._setupIcon = this._setupIcon.bind(this);
    this._renderIcon = this._renderIcon.bind(this);
  }

  _renderIcon(selected: any, unselectedImage: any, selectedImage: any) {
    return (
      <View>
        <Image
          style={[CommonStyles.supportFeelingIcon]}
          source={selected ? selectedImage : unselectedImage}
        />
      </View>
    );
  }

  _setupIcon() {
    switch (this.props.title) {
      case types.KEEP:
        return this._renderIcon(
          this.props.selected,
          SupportsKeepUnselected,
          SupportsKeepSelected,
        );
      case types.CHANGE:
        return this._renderIcon(
          this.props.selected,
          SupportsChangeUnselected,
          SupportsChangeSelected,
        );
      case types.STOP:
        return this._renderIcon(
          this.props.selected,
          SupportsStopUnselected,
          SupportsStopSelected,
        );
      default:
        return null;
    }
  }

  _commonContent = () => {
    let font = this.props.selected
      ? CommonStyles.customFontBold
      : CommonStyles.customFontLight;

    return (
      <View
        style={[CommonStyles.flexColumn, CommonStyles.supportFeelingWrapper]}
      >
        {this._setupIcon()}
        <StandardText style={[font, BrandStyles.TextColor5]}>
          {this.props.title}
        </StandardText>
      </View>
    );
  };
  render() {
    let access = true;
    if (this.props.readOnly == 'read') {
      access = false;
    }

    let isButton = true;
    if (this.props.isButton != undefined) {
      isButton = this.props.isButton;
    }

    if (access == false || !isButton) {
      return this._commonContent();
    } else {
      return (
        <Button onPress={() => this.props.onPress(this.props.title)}>
          {this._commonContent()}
        </Button>
      );
    }
  }
}

export default TrafficLightOption;
