/*
 * Author: Declan Hart
 * Date: 16/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Display Support details
 */

import React, {Component} from 'react';
import {StyleSheet, View, Image, TextInput} from 'react-native';

import {editIcon} from '../../assets/images';

import {
  UnitOfMeasureYear,
  UnitOfMeasureEach,
  UnitOfMeasureWeek,
  UnitOfMeasureDay,
  UnitOfMeasureHour,
} from '../Molecules';

import {StandardText} from '../Atoms';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

class UnitOfMeasure extends Component {
  constructor(props: any) {
    super(props);
    this.renderUnitOfMeasure = this.renderUnitOfMeasure.bind(this);
  }

  renderUnitOfMeasure() {
    switch (this.props.measure) {
      case types.YEAR:
        return (
          <UnitOfMeasureYear
            save={this.props.save}
            values={this.props.values}
            readOnly={this.props.readOnly}
          />
        );
      case types.WEEK:
        return (
          <UnitOfMeasureWeek
            save={this.props.save}
            values={this.props.values}
            readOnly={this.props.readOnly}
          />
        );
      case types.DAY:
        return (
          <UnitOfMeasureDay
            save={this.props.save}
            values={this.props.values}
            readOnly={this.props.readOnly}
          />
        );
      case types.HOUR:
        return (
          <UnitOfMeasureHour
            save={this.props.save}
            values={this.props.values}
            readOnly={this.props.readOnly}
          />
        );
      case types.EACH:
        return (
          <UnitOfMeasureEach
            save={this.props.save}
            values={this.props.values}
            readOnly={this.props.readOnly}
          />
        );
      default:
        return (
          <UnitOfMeasureHour
            save={this.props.save}
            values={this.props.values}
            readOnly={this.props.readOnly}
          />
        );
    }
  }

  render() {
    return <View>{this.renderUnitOfMeasure()}</View>;
  }
}

export default UnitOfMeasure;
