/*
 * Authour: Andrew Lee
 * Date: 12/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is to store images that have been downloaded into an image cache by referencing the URI that is passed in when downloading it. This is to prevent the excess downloading of images especially when navigating around different screens that has the same image being displayed.
 */

// Import of libraries
import {isWeb} from '../Constants/Constants';
import {FILE_HEADER, IOS, ANDROID} from '../Constants/Constants2';

import {Platform} from 'react-native';
import logger from 'helpers/Logger';
import {RNFetchBlobSingleton} from '../Helpers/PlatformSynchronizer';

const SHA1 = require('crypto-js/sha1');

/**
 * Function that will obtain the filesystem path that the image is stored under once it has been downloaded from the url passed in.
 * @param {string} imageObject The image object that contains the url, last modified date and key to the file
 * @returns {Promise} A promise that will hold the path to the cached image when it returns
 */
// TODO: This will need to be updated to include file name, content type and other bits of information to make the caching valid.
export const getImage = (imageObject: any) => {
  // Before returning the promise check that the uri and key are both present
  if (!imageObject.url || !imageObject.key || !imageObject.lastModified) {
    logger.log('Missing uri or key or last modified date for image cache');
  }

  let path = '';
  if (Platform.OS === IOS) {
    const imageName = `${imageObject.key}`.split('/').pop();
    const consolidatedKeyIOS = `${imageName}`;
    path = `${RNFetchBlobSingleton.fs.dirs.CacheDir}_downloaded_images/${consolidatedKeyIOS}`;
  } else if (Platform.OS === ANDROID) {
    const consolidatedKey = `${imageObject.key}_${imageObject.lastModified}`;
    path = `${RNFetchBlobSingleton.fs.dirs.CacheDir}_downloaded_images/${SHA1(
      consolidatedKey,
    )}`;
  }

  return new Promise((resolve, reject) => {
    if (isWeb) {
      resolve(imageObject.url);
    } else {
      RNFetchBlobSingleton.fs
        .exists(path)
        .then(exists => {
          if (exists) {
            resolve(FILE_HEADER + path);
          } else {
            RNFetchBlobSingleton.config({path})
              .fetch('GET', imageObject.url, {})
              .then(() => {
                resolve(FILE_HEADER + path);
              });
          }
        })
        .catch(err => {
          logger.log(err);
        });
    }
  });
};

export const getAvatarImage = (imageObject: any) => {
  let path = `${RNFetchBlobSingleton.fs.dirs.CacheDir}_avatar_images/${
    imageObject.updatedDate
  }_${imageObject.key.replace(/ /g, '_')}`;

  return new Promise((resolve, reject) => {
    RNFetchBlobSingleton.fs
      .exists(path)
      .then(exists => {
        if (exists) {
          resolve(FILE_HEADER + path);
        } else {
          RNFetchBlobSingleton.config({path})
            .fetch('GET', imageObject.avatarImageUrl, {})
            .then(() => {
              resolve(FILE_HEADER + path);
            });
        }
      })
      .catch(err => {
        logger.log(err);
      });
  });
};
