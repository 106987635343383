/*
 * Author: Gayan
 * Date: 23/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * App Landing screen if there is no current user session. Animates in & allows the user to sign up or login.
 */

import React, {Component} from 'react';
import {
  Platform,
  StyleSheet,
  View,
  ScrollView,
  Animated,
  Image,
  Dimensions,
  TouchableOpacity,
  BackHandler,
  AppState,
  Alert,
} from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {CommonActions} from '@react-navigation/native';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {BrandIcons} from '../Styles/BrandIcons';

import CustomIcon from '../assets/images/CustomIcon';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {
  ERRORS,
  ACCESSIBILITY_SCREEN_TITLES,
  CHOOSE_PARTICIPANT_CREW,
} from '../Constants/Messages';

import {StandardText, BackButton} from '../Components/Atoms';
import * as UserActions from '../Actions/UserActions';
import * as AuthenticationActions from '../Actions/AuthenticationActions';
import * as AdminActions from '../Actions/AdminActions';
import * as loadedMemberActions from '../Actions/LoadedMemberActions';
import * as OfflineNoticeActions from '../Actions/OfflineNoticeActions';

import {
  focusOnView,
  announceForAccessibility,
  isTablet,
  getVersion,
  firebaseAnalysisLogEvent,
} from '../Helpers/PlatformSynchronizer';

import {putUser} from '../API/UserAPI';
import {callAPIs} from '../API/APICaller';
import {routeTolanding} from '../Components/RouteToLanding/RouteToLanding';
import {BrandActivityIndicator} from '../Components/Molecules/BrandActivityIndicator';
import {
  OfflineNotice,
  DeepLinkBanner,
  HeaderBanner,
} from '../Components/Organisms';
import {
  OptionTile,
  Container,
  LoginSignUpTitle,
  Copyrights,
  ScreenTitle,
} from '../Components/Molecules';

import {APP_BRAND} from '../Constants/BrandConstants';

// import Orientation from 'react-native-orientation-enhanced';
import logger from 'helpers/Logger';
import DeviceInfo from 'react-native-device-info';
import {getDataLoggedIn} from '../API/StorageHelper';
import {
  DEMO_OPTIONS_COLORS_CREW,
  DEMO_OPTIONS_COLORS_PARTICIPANT,
} from '../Styles/OnboardingStyles';

const PERSON = {
  PARTICIPANT: {
    ...DEMO_OPTIONS_COLORS_PARTICIPANT,
    title: CHOOSE_PARTICIPANT_CREW.PARTICIPANT,
    subtitle: {
      portrait: CHOOSE_PARTICIPANT_CREW.PARTICIPANT_DESC,
      landscape: CHOOSE_PARTICIPANT_CREW.PARTICIPANT_DESC_BR,
    },
    image: BrandIcons.WELCOME_PROFILE, //participant_image
    userType: types2.USER_TYPE.MEMBER.TYPE,
  },
  CREW: {
    ...DEMO_OPTIONS_COLORS_CREW,
    title: CHOOSE_PARTICIPANT_CREW.CREW,
    subtitle: {
      portrait: CHOOSE_PARTICIPANT_CREW.CREW_DESC,
      landscape: CHOOSE_PARTICIPANT_CREW.CREW_DESC_BR,
    },
    image: BrandIcons.SIGNUP_CREW, //crew_image
    userType: types2.USER_TYPE.CREW.TYPE,
  },
};
// Get the device height & width
let ScreenHeight = Dimensions.get('window').height;
let ScreenWidth = Dimensions.get('window').width;
const LOGIN_SIGNUP = 'LoginSignUp';

// Setup animations
const animationValue = new Animated.Value(0);

// Maps the animation values to start from the top of the screen out of view and finish at the bottom of the screen in view
const animateTopToBottom = animationValue.interpolate({
  inputRange: [0, 1],
  outputRange: [-ScreenHeight, 0.0],
});
// Maps the animation values to start from the bottom of the screen out of view and finish at the top of the screen in view
const animateBottomToTop = animationValue.interpolate({
  inputRange: [0, 1],
  outputRange: [ScreenHeight, 0.0],
});

export class ChooseParticipantOrCrew extends Component {
  state = {
    loading: false,
    orientation: '',
    isConnected: true,
  };
  subscription: any;

  constructor(props: any) {
    super(props);
    this._selectedTypeCrew = this._selectedTypeCrew.bind(this);
    this.backButtonNavigation = this.backButtonNavigation.bind(this);
    this._skipButtonClicked = this._skipButtonClicked.bind(this);
    this.navigateToSignUp = this.navigateToSignUp.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (!types.isWeb) {
      firebaseAnalysisLogEvent(
        types.FIREBASE_ANALYTICS_EVENTS.SIGNUP_MEMBER_TYPE_SELECTION,
        {},
      );
    }

    NetInfo.addEventListener(this.handleConnectivityChange);
    NetInfo.fetch().then(isConnected => {
      this.setState({isConnected});
    });

    // if (!types.isWeb) {
    //   Orientation.getOrientation((err: any, orientation: any) => {
    //     this.setState({
    //       orientation,
    //     });
    //   });
    // }
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation,
    });
  };

  componentWillUnmount() {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
    // AppState.removeEventListener('change', this._handleAppStateChange);
    this.subscription.remove();
  }

  componentDidMount() {
    if (!types.isWeb) {
      announceForAccessibility(ACCESSIBILITY_SCREEN_TITLES.PARTICIPANT_OR_CREW);
      // Orientation.addOrientationListener(this._orientationDidChange);
      this.dispatchFocusInOrder();
    }
    this.subscription=AppState.addEventListener('change', this._handleAppStateChange);
  }

  dispatchFocusInOrder() {
    setTimeout(
      function (this: any) {
        if (this.skipButton) {
          focusOnView(this.skipButton);
        }
      }.bind(this),
      1000,
    );
  }

  handleConnectivityChange = (isConnected: any) => {
    if (isConnected) {
      this.setState({isConnected});
    }
  };

  _handleAppStateChange = (nextAppState: any) => {
    if (nextAppState) {
      NetInfo.fetch().then(isConnected => {
        this.setState({isConnected});
      });
    }
  };

  _selectedTypeCrew() {
    if (!this.state.isConnected) {
      this.props.actions.OfflineNoticeActions.shakeOfflineNotice();
      return;
    }

    // Common event to identify type confirmation
    if (!types.isWeb) {
      firebaseAnalysisLogEvent(
        types.FIREBASE_ANALYTICS_EVENTS.TYPE_CONFIRMED,
        {},
      );

      // Event to capture crew type selection
      firebaseAnalysisLogEvent(
        types.FIREBASE_ANALYTICS_EVENTS.TYPE_CREW_SELECTED,
        {},
      );
    }

    let version = '';

    if (types.isWeb) {
      version = getVersion;
    } else {
      version = getVersion();
    }
    this.setState({loading: true});
    const userObj = {
      id: this.props.user.id,
      cognitoId: this.props.auth.awstoken.IdentityId,
      isMember: false,
      isCrew: true,
      typeConfirmed: true,
      version,
    };

    const putUserCallbackFunction = (data: any) => {
      const userObj = data[0].content;
      this._crewSignUpInstructionsRouting(
        types.PLAN_TYPE_NEW,
        types.ADD_MEMBER,
        types.SIGNUP_TYPE_CREW,
      );
    };

    const putUserErrorCallback = (error: any) => {
      logger.log('Received an error', error);
    };

    callAPIs(
      [putUser(userObj, false)],
      putUserCallbackFunction,
      putUserErrorCallback,
    );
  }

  navigateToSignUp(option: any) {
    // default to member
    if (option.title.toLowerCase() === types2.USER_TYPE.CREW.TYPE) {
      this.props.actions.userActions.actionUpdateIsCrew(true);
      this.props.actions.userActions.actionUpdateIsMember(false);
    } else {
      this.props.actions.userActions.actionUpdateIsMember(true);
      this.props.actions.userActions.actionUpdateIsCrew(false);
    }
    this.props.navigation.navigate('SignUp');
    this.setState({loading: false});
  }

  _crewSignUpInstructionsRouting = (
    planType: any,
    screenMode: any,
    signUpType: any,
  ) => {
    let callbackFunction = (routingObj: any) => {
      //Update the redux store with the ID we want to load the initial data for
      if (routingObj.params.userObj.managedMemberId != undefined) {
        this.props.actions.loadedMemberActions.actionGetLoadedMemberId(
          routingObj.params.userObj.managedMemberId,
        );
      } else {
        this.props.actions.loadedMemberActions.actionGetLoadedMemberId(
          routingObj.params.userObj.user.id,
        );
      }

      this.props.actions.userActions.actionGetUser(routingObj.params.userObj);
      this.setState({loading: false});
      this.props.navigation.navigate('CrewCreateMember', {
        screenMode: types.LOGINSIGNUP_CREW_CREATE_MEMBER,
        planType: types.PLAN_TYPE_NEW,
        signUpType: types.SIGNUP_TYPE_CREW,
        shouldntRenderBackButton: true,
      });
    };
    routeTolanding(this.props.auth.awstoken.IdentityId, callbackFunction);
  };

  backButtonNavigation = () => {
    getDataLoggedIn(types.PREVIOUSLY_SIGNIN).then(alreadyloggedIn => {
      if (!types.isWeb) {
        const actionToDispatch = CommonActions.reset({
          index: 0,
          routes: [ { name : LOGIN_SIGNUP, params : { screenMode: alreadyloggedIn
            ? types.LOGINSIGNUP_MODE_LOGIN
            : types.LOGINSIGNUP_MODE_SIGNUP,}}
          ],
        });
        this.props.navigation.dispatch(actionToDispatch);
      } else {
        this.props.navigation.navigate(LOGIN_SIGNUP, {
          screenMode: alreadyloggedIn
            ? types.LOGINSIGNUP_MODE_LOGIN
            : types.LOGINSIGNUP_MODE_SIGNUP,
        });
      }
    });
  };

  _renderLogo() {
    if (APP_BRAND != types2.APP_BRAND.LEAPIN) {
      return (
        <View
          style={[
            CommonStyles.alignFlexEnd,
            CommonStyles.chooseparticipantorcrewt20l10,
          ]}
        >
          <Image
            style={[CommonStyles.loginsingupLogowh60]}
            source={BrandIcons.LOGO_ICON_BUTTON}
          />
        </View>
      );
    }
  }

  _renderSkipButton() {
    return (
      <View style={[CommonStyles.rpCreworpartcipant]}>
        <TouchableOpacity onPress={this._skipButtonClicked}>
          <View style={[CommonStyles.alignItemsCenter]}>
            <View>
              <CustomIcon
                style={[
                  CommonStyles.skipButtonwh30,
                  BrandStyles.bottomTabIconInactiveColor,
                ]}
                name={BrandIcons.SKIP_BUTTON}
              />
            </View>
            <View>
              <StandardText
                allowFontScaling={false}
                style={[
                  CommonStyles.customFont,
                  BrandStyles.bottomTabIconInactiveColor,
                ]}
              >
                Skip
              </StandardText>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }

  _skipButtonClicked() {
    this.navigateToSignUp();
  }

  render() {
    return (
      <View
        style={[
          CommonStyles.screenWrapperContainer,
          BrandStyles.primaryBgColor1,
        ]}
      >
        {types.isWeb && <HeaderBanner />}
        <View style={[CommonStyles.flex1, CommonStyles.flexDirectionColumn]}>
          <OfflineNotice />
          {types.isWeb && <DeepLinkBanner />}
          <View
            style={[
              CommonStyles.alignItemsCenter,
              CommonStyles.marginBottom10,
              CommonStyles.zIndex102,
              CommonStyles.flexDirectionRow,
            ]}
          >
            <LoginSignUpTitle
              title={CHOOSE_PARTICIPANT_CREW.I_AM}
              backButton={true}
              nav={this.props.navigation}
              screen={types2.SIGNUP_LOGIN_FLOW.WELCOME}
            />
          </View>
          <ScrollView
            contentContainerStyle={[
              !types.isWeb && CommonStyles.paddingBottom50,
              !types.isWeb && CommonStyles.rpPadding10,
              CommonStyles.alignItemsCenter,
              CommonStyles.flex1,
            ]}
            showsVerticalScrollIndicator={false}
          >
            {!types.isWeb && (
              <View style={CommonStyles.screenTitleContainer}>
                <ScreenTitle title={CHOOSE_PARTICIPANT_CREW.I_AM} />
              </View>
            )}
            <View
              style={[
                CommonStyles.flexDirectionColumn,
                types.isWeb && CommonStyles.alignCenter,
                CommonStyles.optionTileWrapper,
                types.isWeb && CommonStyles.rpMarginTop20p,
                CommonStyles.rpMarginBottom20p,
              ]}
            >
              {this.renderTiles(PERSON.PARTICIPANT, this.navigateToSignUp)}
              {this.renderTiles(PERSON.CREW, this.navigateToSignUp)}
            </View>
          </ScrollView>
          {!types.isWeb && (
            <View
              style={[
                CommonStyles.alignCenter,
                BrandStyles.copyrightsBarBgColor,
                CommonStyles.loginButtonWrapper,
              ]}
            >
              <Copyrights />
            </View>
          )}
        </View>
        <BrandActivityIndicator enableWeb={true} loading={this.state.loading} />
      </View>
    );
  }

  renderTiles = (option: any, onPress: any) => {
    return (
      <OptionTile
        option={option}
        orientation={this.state.orientation}
        onPress={onPress}
        minWidth={types.isWeb ? '' : CommonStyles.width75}
      />
    );
  };

  _setElevation() {
    return Platform.OS === types2.ANDROID &&
      DeviceInfo.getBrand() === types2.GOOGLE
      ? null
      : CommonStyles.elevation5;
  }
}

const mapStateToProps = (state: any) => ({
  auth: state.AuthenticationReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    authActions: bindActionCreators(AuthenticationActions, dispatch),
    adminActions: bindActionCreators(AdminActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
    AuthenticationActions: bindActionCreators(AuthenticationActions, dispatch),
    loadedMemberActions: bindActionCreators(loadedMemberActions, dispatch),
    OfflineNoticeActions: bindActionCreators(OfflineNoticeActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChooseParticipantOrCrew);
