/*
 * Author: Josh Merkas
 * Date: 11/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * DocumentList actions for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const actionGetDocumentList = (documentlistObj: any) => ({
  type: types.GET_DOCUMENT_LIST,
  documentlistObj,
});

export const actionGetSearchDocumentList = (documentlistObj: any) => ({
  type: types2.GET_SEARCH_DOCUMENT_LIST,
  documentlistObj,
});
