/*
 * Author: Nirodha Perera
 * Date: 05/09/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The screen for add supports by category
 */

import React, {Component} from 'react';
import {View, Image} from 'react-native';
import {shuffle} from 'lodash';
import {NoData} from '../Components/Organisms';
import {
  Container,
  SummaryTile,
  SupportCategoryTile,
  GridView,
} from '../Components/Molecules';
import {StandardText} from '../Components/Atoms';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {isWeb, SCREEN_TYPE_MAIN} from '../Constants/Constants';
import {
  SUPPORT_CATEGORY_COLORS,
  NAVIGATION_TEXT,
  ITEM_DIMENSION,
} from '../Constants/Constants2';
import {MESSAGES, ADD_SUPPORTS_BY_CATEGORY} from '../Constants/Messages';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import * as SupportsActions from '../Actions/SupportsActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {InformationIconx3} from '../assets/images';
import {SupportPurple2x} from '../assets/images/vector-icons';
import DeviceInfo from 'react-native-device-info';

import {callAPIs} from '../API/APICaller';
import {getSupports} from '../API/PlanDataAPI';
import {getSupportCategories} from '../API/ReferenceAPI';

import {removeItem} from '../API/WebPersistenceStore';

const CATEGORIES = 'Categories';
const CATEGORY_SUPPORT = 'CategorySupports';

class AddSupportsByCategory extends Component {
  state = {
    selectedCategory: null,
    isLoading: true,
    tileContainerDimensions: undefined,
    scrollEnabled: true,
    categoryColors: shuffle(SUPPORT_CATEGORY_COLORS),
  };

  constructor(props: any) {
    super(props);
    this._renderSupportCategories = this._renderSupportCategories.bind(this);
    this.goNext = this.goNext.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (isWeb) {
      this.props.setLoading(true);
      // Remove local storage values for previously selected supports
      removeItem('tempSupportItems');
    }

    const downloadCallback = (data: any) => {
      const categories = data[0].content;
      const supports = data[1].content;
      this.props.actions.SupportsActions.actionGetSupports(supports);
      this.props.actions.SupportsActions.actionGetSupportsCategories(
        categories,
      );
      if (isWeb) {
        this.props.setLoading(false);
      }
      this.setState({isLoading: false});
    };

    callAPIs(
      [
        getSupportCategories(),
        getSupports(
          this.props.member.planId,
          this.props.loadedMemberId.loadedMemberId,
          this.props.user.demoProfile,
        ),
      ],
      downloadCallback,
      null,
      () => {
        if (isWeb) {
          this.props.setLoading(false);
        }
        this.setState({isLoading: false});
      },
    );
  }

  goNext(category: any, color: any) {
    this.setState({selectedCategory: category, isLoading: true});

    setTimeout(
      function (this: any) {
        this.props.navigation.navigate(CATEGORY_SUPPORT, {
          selectedCategory: category,
          selectedColor: color.replace('#', ''),
        });
        this.setState({isLoading: false, selectedCategory: null});
      }.bind(this),
      500,
    );
  }

  find_dimesions(layout: any) {
    const {x, y, width, height} = layout;
    this.setState({
      tileContainerDimensions: {
        width,
        height,
      },
      viewWidth: width,
    });
  }

  render() {
    if (isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        needsSidebar={true}
        screenType={SCREEN_TYPE_MAIN}
        headerTitle={NAVIGATION_TEXT.ADD_SUPPORTS}
        nav={this.props.navigation}
        sidebarProfilePicture={true}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        scrollEnabled={this.state.scrollEnabled}
        loading={this.state.isLoading}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _getTileColor = (index: any) => {
    const colors = this.state.categoryColors;
    return colors[index];
  };

  _renderSupportCategories() {
    if (!this.state.isLoading) {
      if (this.props.categories == undefined) {
        return (
          <NoData
            activity={CATEGORIES}
            additionalInformation={MESSAGES.NODATA.CATEGORIES}
          />
        );
      } else {
        if (Object.getOwnPropertyNames(this.props.categories).length != 0) {
          return (
            <GridView
              style={CommonStyles.paddingBottom20}
              viewWidth={this.props.viewWidth}
              itemDimension={ITEM_DIMENSION}
              childDimension={true}
              isMobile={this.props.isMobile}
            >
              {this.props.categories.map((category: any, index: any) => (
                <SupportCategoryTile
                  key={category.categoryId}
                  category={category}
                  selected={this.state.selectedCategory}
                  actionCallback={this.goNext}
                  tileColor={this._getTileColor(index)}
                />
              ))}
            </GridView>
          );
        } else {
          return (
            <NoData
              activity={CATEGORIES}
              additionalInformation={MESSAGES.NODATA.CATEGORIES}
            />
          );
        }
      }
    }
  }

  _createMainContents = () => (
    <View style={[CommonStyles.flexColumn, CommonStyles.content]}>
      <View>
        <SummaryTile
          headerDisplayString={ADD_SUPPORTS_BY_CATEGORY.TITLE}
          headerIconImage={SupportPurple2x}
          HeaderTextColor={BrandStyles.TextColor4}
          HeaderBoarderColor={BrandStyles.borderColor4}
          hideYellowStar={true}
          showButton={false}
          fontSize={CommonStyles.font20}
          disableMore={true}
        />
      </View>
      <View
        style={[
          CommonStyles.formImportantNotification,
          CommonStyles.alignCenter,
        ]}
      >
        <Image
          style={[
            CommonStyles.infoIcon,
            CommonStyles.titleBoxIconMargin,
            CommonStyles.alignSelfCenter,
          ]}
          source={InformationIconx3}
          accessibilityLabel={MESSAGES.ACCESSIBILITY.IMPORTANT_INFORMATION}
          accessible={true}
        />
        <View
          style={[
            BrandStyles.secondaryBgColor3,
            CommonStyles.padding10,
            CommonStyles.justifyContentSpaceBetween,
            CommonStyles.containerRoundCornersBorderless,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
            ]}
          >
            {ADD_SUPPORTS_BY_CATEGORY.DESC}
          </StandardText>
        </View>
      </View>
      <View
        onLayout={event => {
          this.find_dimesions(event.nativeEvent.layout);
        }}
        style={[
          CommonStyles.flex1,
          CommonStyles.wrapTileContainer,
          CommonStyles.marginBottom20,
        ]}
      >
        {this._renderSupportCategories()}
      </View>
    </View>
  );
}

const mapStateToProps = (state: any) => ({
  categories: state.SupportCategoriesReducer.categories,
  supportGroups: state.SupportItemGroupsReducer.supportGroups,
  member: state.MemberReducer,
  supports: state.SupportsReducer,
  sideMenu: state.SideMenuReducer,
  loadedMemberId: state.LoadedMemberReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddSupportsByCategory);
