/*
 * Author: Joshua Merkas
 * Date: 12/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Favourite Things Screen, a Favourite Things placeholder screen.
 */

import React, {Component} from 'react';
import {View} from 'react-native';
import {StandardText} from '../Components/Atoms';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

import {
  FavouriteThingsFuture,
  FavouriteThingsToday,
} from '../Components/Organisms';
import {Container} from '../Components/Molecules';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';

import {callAPIs} from '../API/APICaller';
import {getFavourites} from '../API/AboutMeAPI';
import * as FavouriteThingsActions from '../Actions/FavouriteThingsActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';

const FAVOURITE_THINGS_FUTURE = 'FavouriteThingsFutureForm';
const FAVOURITE_THINGS_TODAY = 'FavouriteThingsTodayForm';
class FavouriteThings extends Component {
  state = {
    likeToDo: '',
    wouldLikeToDo: '',
    likeToDoMainScreen: '',
    wouldLikeToMainScreen: '',
    likeToDoModal: '',
    wouldLikeToDoModal: '',
    loading: true,
    refreshing: false,
  };

  constructor(props: any) {
    super(props);
    this._navigateToFavouriteThingsTodayForm.bind(this);
    this._navigateToFavouriteThingsFutureForm.bind(this);
    this._createMainContents.bind(this);
    this.fetchData.bind(this);
  }

  componentDidMount() {
    this.setState({loading: true});
    this.fetchData();
  }

  fetchData = () => {
    const callbackFunction = (data: any) => {
      const favouriteThings = data[0].content;
      this.props.actions.FavouriteThingsActions.actionGetFavouriteThings(
        favouriteThings,
      );

      this.setState({loading: false, refreshing: false});
    };
    callAPIs(
      [
        getFavourites(
          this.props.loadedMemberId.loadedMemberId,
          this.props.user.demoProfile,
        ),
      ],
      callbackFunction,
      null,
      () => {
        this.setState({loading: false, refreshing: false});
      },
    );
  };

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.FAVORITE_THINGS}
        onRefresh={() => {
          this.setState({refreshing: true});
          this.fetchData();
        }}
        refreshing={this.state.refreshing}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    return (
      <View style={[CommonStyles.content]}>
        <View style={[CommonStyles.mainContentContainer]}>
          <View accessible={false} style={[CommonStyles.columnContainer]}>
            <FavouriteThingsToday
              reducer={this.props.favouriteThings}
              readOnly={this.props.member}
              form={() => this._navigateToFavouriteThingsTodayForm()}
            />
            <FavouriteThingsFuture
              reducer={this.props.favouriteThings}
              readOnly={this.props.member}
              form={() => this._navigateToFavouriteThingsFutureForm()}
            />
          </View>
        </View>
      </View>
    );
  };

  _navigateToFavouriteThingsTodayForm() {
    this.props.navigation.navigate(FAVOURITE_THINGS_TODAY);
  }

  _navigateToFavouriteThingsFutureForm() {
    this.props.navigation.navigate(FAVOURITE_THINGS_FUTURE);
  }
}

const mapStateToProps = (state: any) => ({
  favouriteThings: state.FavouriteThingsReducer,
  member: state.MemberReducer,
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    FavouriteThingsActions: bindActionCreators(
      FavouriteThingsActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FavouriteThings);
