/*
 * Authour: Missaka Herath
 * Date: 03/04/2024
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is the action for payment history for redux
 */

import * as types from '../Constants/Constants';


export const setSearchQuery = (searchQuery: any) => ({
  type: types.SET_SEARCH_QUERY,
  payload: searchQuery
});

export const setPageNumber = (pageNumber: any) => ({
  type: types.SET_PAGE_NUMBER,
  payload: pageNumber
});

export const setPaymentHistoryData = (paymentHistoryData: any) => ({
  type: types.SET_PAYMENT_HISTORY_DATA,
  payload: paymentHistoryData
});
