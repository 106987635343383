/*
 * Author: Gayan
 * Date: 10/07/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Constants for the html.
 */

import {BrandColors} from './Colours';

export const GUIDE_ME_TEXT = `
body,h1,h2,h3,h4,h5,p {font-family: "ArialMT"}
    body {
        line-height: 1.7em;
    }
    div  {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    b {
      color: ${BrandColors.RESPONSIVE_DESIGN_COLORS.RED}
    }
`;

export const GUIDE_ME_TEXT_WEB = `
body,h1,h2,h3,h4,h5,p {font-family: "ArialMT"}
    div  {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    p {
        font-size: 15px;
    }
    b {
      color: ${BrandColors.RESPONSIVE_DESIGN_COLORS.RED}
    }
`;

export const GUIDE_ME_RICH_TEXT = `
    body,h1,h2,h3,h4,h5,p {font-family: "ArialMT"}
    div  {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const GUIDE_ME_RICH_TEXT_WEB = `
    body,h1,h2,h3,h4,h5,p {font-family: "ArialMT"}
    div  {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    p {
        font-size: 15px;
    }
`;

export const GUIDE_ME_IMAGE = `
    img  {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
`;
