/*
 * Author: Shelan Cooray
 * Date: 12/02/2019
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Manage Members Component.
 */

import React, {Component} from 'react';
import {View, Text, FlatList} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {StandardText, StandardTouchableOpacity, StandardSearch} from '../Atoms';
import {RoundPhotoTile} from '../Molecules';
import {callAPIs} from '../../API/APICaller';
import {getParticipants, getRecentMembers} from '../../API/PersonAPI';
import {getMember} from '../../API/MemberAPI';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import CustomIcon from '../../assets/images/CustomIcon';
import {AddMembersRed4x} from '../../assets/images/vector-icons';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {SETTINGS_SCREEN} from '../../Constants/Messages';

import * as MemberActions from '../../Actions/MemberActions';
import * as LoadedMemberActions from '../../Actions/LoadedMemberActions';
import * as UserActions from '../../Actions/UserActions';
import * as BudgetActions from '../../Actions/BudgetActions';
import * as LivingArrangementActions from '../../Actions/LivingArrangementActions';
import * as FavouriteThingsActions from '../../Actions/FavouriteThingsActions';
import * as HealthWellbeingActions from '../../Actions/HealthWellbeingActions';
import * as CrewActions from '../../Actions/CrewActions';
import * as SupportsActions from '../../Actions/SupportsActions';
import * as GoalActions from '../../Actions/GoalActions';
import * as PayInvoiceActions from '../../Actions/PayInvoiceActions';
import * as NavigationParamActions from '../../Actions/NavigationParamActions';
import * as NotificationActions from '../../Actions/NotificationActions';
import * as GuideMeActions from '../../Actions/GuideMeActions';
import * as TabBarActions from '../../Actions/TabBarActions';

import {
  hasBudgetAccess,
  filterBudgetCategories,
} from '../../Helpers/determineAnyBudgetAccess';

import {
  getBudget,
  getDraftInvoices,
  getPlans,
  getSupports,
  getGoalsForPlan,
} from '../../API/PlanDataAPI';
import {getCrewCategories, getBudgetCategories} from '../../API/ReferenceAPI';
import {getNotifications} from '../../API/NotificationAPI';
import {
  getLivingArrangement,
  getFavourites,
  getHealth,
} from '../../API/AboutMeAPI';
import {getCrewAccessLevels} from '../../API/CrewAPI';
import {getCrew} from '../../API/PersonAPI';
import {debounce, remove, isEmpty} from 'lodash';

import {setValue, getValue} from '../../API/WebPersistenceStore';
import {setServiceAgreementFeature} from '../../Helpers/Utils';

const PLAN_RESULT_INDEX = {
  BUDGETS: 0,
  BUDGET_CATEGORIES: 1,
  DRAFT_INVOICES: 2,
  PLANS: 3,
};

const RESULT_INDEX = {
  LIVING_ARRANGEMENT: 0,
  FAVOURITES: 1,
  HEALTH: 2,
  CREW_CATEGORIES: 3,
  CREW: 4,
  CREW_ACCESS: 5,
  SUPPORTS: 6,
  GOALS: 7,
};

const MAX_MEMBERS = 3;
const PREVIOUS_PLANS = 'PreviousPlans';
const BUDGETS_PAYMENTS = 'BudgetsPayments';

class WebMembers extends Component {
  navigateMemberScreen: any;
  state = {
    members: [],
    filteredMembers: [],
    recentMembers: [],
    searchText: '',
    keyboardDismissMode: 'none',
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    this.props.setLoading(true);

    const promiseArray = [
      getParticipants(
        this.props.user.id,
        null,
        null,
        null,
        this.props.user.demoProfile,
      ),
    ];

    if (
      this.props.user.isEmployee ||
      this.props.user.isFinancialStaff ||
      this.props.user.isCrew
    ) {
      promiseArray.push(
        getRecentMembers(this.props.user.id, this.props.user.demoProfile),
      );
    }

    const initialDownloadCallback = (data: any) => {
      const participants = data[0].content.participants;
      let recentMembers: any = [];
      if (this.props.user.isEmployee || this.props.user.isFinancialStaff) {
        this.props.setLoading(false);
      }
      // this is to add members that haven't been recently accessed
      // i.e. if there a crew who is also a member
      if (data.length > 1) {
        let recentMembers = data[1].content.members;
        if (recentMembers.length < 3) {
          participants.forEach((participant: any) => {
            let found = recentMembers.find(
              (member: any) => member.id === participant.id,
            );
            if (!found) {
              recentMembers.push(participant);
            }
          });
        }

        // This is to remove loaded member from recent members list
        // if matching loadedMemberId found in the store
        recentMembers = remove(recentMembers, member => {
          return (
            member.id !== parseInt(getValue(types2.WEB_STORE.LOADED_MEMBER_ID))
          );
        });
        this.props.actions.UserActions.actionGetMostRecentMembers(
          recentMembers.slice(0, 3),
        );
      }
      this.setState({
        members: participants,
        recentMembers,
      });

      if (
        this._isMemberCrew() &&
        this._isMemberOnBoarded() &&
        this._isPlanManageActiveOrComplete()
      ) {
        if (isEmpty(this.props.budgets)) {
          return this._loadMember(this.props.member);
        }
      }

      if (
        Object.getOwnPropertyNames(this.props.budgets).length !== 0 ||
        Object.getOwnPropertyNames(this.props.member).length === 0 ||
        this.props.guideMe.currentScreen !== types2.BUDGETS ||
        this.props.isMobile
      ) {
        return this.props.setLoading(false);
      }
    };

    if (this.props.user && this.props.user.id) {
      callAPIs(
        promiseArray,
        initialDownloadCallback,
        () => {
          return this.props.setLoading(false);
        },
        null,
      );
    }
  }

  _isMemberOnBoarded = () => {
    const planManaged = this.props.member.memberStatus;
    if (planManaged == types2.MEMBER_STATUS_DATA.MANAGED) {
      return true;
    }
    return false;
  };

  _isPlanManageActiveOrComplete = () => {
    const planStatus = this.props.member.planStatus;
    if (
      planStatus === types2.PLAN_STATUS_DATA.PM_ACTIVE ||
      planStatus === types2.PLAN_STATUS_DATA.COMPLETED
    ) {
      return true;
    }
    return false;
  };

  _isMemberCrew = () => {
    if (
      this.props.user.isCrew &&
      this.props.user.isMember &&
      this.props.member
    ) {
      return true;
    }
    return false;
  };

  _setFilterdMembers(data: any) {
    if (data[0].content.participants.length > 0) {
      const filteredMembers = data[0].content.participants.filter(function (
        item: any,
        index: any,
        array: any,
      ) {
        return item.id;
      });

      if (this.state.searchText !== '' && this.state.searchText !== undefined) {
        this.setState({filteredMembers: filteredMembers});
      }
    } else {
      this.setState({filteredMembers: {}});
    }
  }

  _fetchSearchData(query: any) {
    callAPIs(
      [
        getParticipants(
          this.props.user.id,
          query,
          null,
          null,
          this.props.user.demoProfile,
        ),
      ],
      (data: any) => this._setFilterdMembers(data),
      () => {},
    );
  }

  _debouncedSearchFilteredSupportFunc = debounce(
    query => this._fetchSearchData(query),
    1000,
  );

  debouncedSearchFilteredSupport(query: any) {
    this.setState({
      searchText: query,
      showResults: true,
    });

    if (query === '' || query === undefined || query < 1) {
      this.setState({filteredMembers: {}});
      return;
    }

    this._debouncedSearchFilteredSupportFunc(query);
  }

  _loadMember = (person: any) => {
    this.props.setLoading(true);
    const callbackFunction = (results: any) => {
      this._loadNotifications(person.id);
      let memberPayload = null;

      let recentMembers = results[1].content.members;

      recentMembers = remove(recentMembers, member => {
        return member.id !== person.id;
      });
      this.props.actions.UserActions.actionGetMostRecentMembers(
        recentMembers.slice(0, 3),
      );

      if (results[0]) {
        memberPayload = results[0].content;
        this.props.actions.MemberActions.actionGetMember(
          memberPayload,
          this.props.user.id,
          this.props.user.isEmployee,
          this.props.user.isFinancialStaff,
        );
      }

      const planID = memberPayload ? memberPayload.planId : null;
      if (memberPayload && this._fetchBudgets()) {
        this._fetchBudgetsPayment(person.id, planID);
      } else {
        this._fetchMyProfileDetails(person.id, planID, false);
        this.props.setLoading(false);
      }

      // Update the redux store with the ID of the person we are viewing data as
      this.props.actions.LoadedMemberActions.actionGetLoadedMemberId(person.id);
      // Persist the selected person ID in order to retrive on refresh
      setValue(types2.WEB_STORE.LOADED_MEMBER_ID, person.id);

      this.setState({
        filteredMembers: {},
        searchText: '',
        showResults: false,
      });

      // Enable guide me tutorial for plan managed members
      if (
        this.props.member.memberStatus === types2.MEMBER_STATUS_DATA.MANAGED &&
        this.props.user.isOnboardingGuideRequired ===
          types2.ONBOARDING_GUIDE_STATUS.DISPLAY
      ) {
        this.props.actions.GuideMeActions.showWelcomeManagedGuide(true);
        this.props.actions.GuideMeActions.setWelcomeGuideStep(1);
        this.props.actions.TabBarActions.displayGuideMe();
      }
    };

    callAPIs(
      [
        getMember(person.id, this.props.user.demoProfile),
        getRecentMembers(this.props.user.id, this.props.user.demoProfile),
      ],
      callbackFunction,
      null,
      () => {},
    );
  };

  _loadNotifications(userId: any) {
    let notificationUserId = userId;
    let notificationPageNumber = this.state.notificationData
      ? this.state.notificationData.pageNumber
      : undefined;

    if (this.props.member.id !== undefined) {
      notificationUserId = this.props.member.id;
    }

    if (notificationPageNumber === undefined) {
      notificationPageNumber = 1;
    }
    const downloadCallback = (data: any) => {
      this.props.actions.NotificationActions.loadNotifications(data[0].content);
    };

    callAPIs(
      [
        getNotifications(
          notificationUserId,
          notificationPageNumber,
          types.NOTIFICATION_PAGE_SIZE,
          false,
          this.props.user.demoProfile,
        ),
      ],
      downloadCallback,
      (err: any) => {},
    );
  }

  _fetchBudgetsPayment = (memberId: any, planId: any) => {
    let callBudgets = false;
    const callback = (results: any) => {
      this._loadNotifications(this.props.user.id);
      if (callBudgets) {
        // check if getBudget returns NO_PLANS_ERROR_CODE
        if (
          results[PLAN_RESULT_INDEX.BUDGETS].statusCode ==
          types2.NO_PLANS_ERROR_CODE
        ) {
          this.props.actions.BudgetActions.setPmExpiredState(true);
          this.props.nav.navigate(BUDGETS_PAYMENTS);

          return '';
        }

        const budgetsPayload = results[PLAN_RESULT_INDEX.BUDGETS].content;

        // Check if Service Agreement feature is disabled from the LIBE
        if (budgetsPayload.isServiceAgreementsEnabled != null) {
          setServiceAgreementFeature(budgetsPayload.isServiceAgreementsEnabled);
        }

        const budgetsCategories =
          results[PLAN_RESULT_INDEX.BUDGET_CATEGORIES].content;
        this.props.actions.BudgetActions.actionGetBudgets(budgetsPayload);
        this.props.actions.BudgetActions.actionGetBudgetCategories(
          filterBudgetCategories(budgetsCategories),
        );
        const draftInvoicesPayload =
          results[PLAN_RESULT_INDEX.DRAFT_INVOICES].content;
        this.props.actions.PayInvoiceActions.setDraftInvoicesCount(
          draftInvoicesPayload.length,
        );
        if (results[PLAN_RESULT_INDEX.PLANS]) {
          const plans = results[PLAN_RESULT_INDEX.PLANS].content;
          this.props.actions.BudgetActions.actionAddPreviousPlans(plans);
        }

        this.props.actions.NavigationParamActions.setParam({
          planKey: '',
          planStatus: '',
          navigateFrom: '',
          planClaimUntilDate: null,
        });

        if (
          this.props.member.planStatus === types2.PLAN_STATUS_DATA.COMPLETED ||
          this.props.member.planStatus === types2.PLAN_STATUS_DATA.INACTIVE
        ) {
          this.props.setLoading(false);
          this.props.nav.navigate(PREVIOUS_PLANS, {});
        } else {
          this.props.setLoading(false);
          this.props.nav.navigate(BUDGETS_PAYMENTS);
        }
      }
      this._fetchMyProfileDetails(memberId, planId, true);
    };

    const errorHandler = (error: any) => {
      this.props.setLoading(false);
    };

    //Do checks for budgets first and then push to APIArray if needed
    const APIArray = [
      getBudget(memberId, this.props.user.demoProfile),
      getBudgetCategories(),
      getDraftInvoices(memberId, this.props.user.demoProfile, false),
      getPlans(memberId, this.props.user.demoProfile),
    ];

    callBudgets = true;
    callAPIs(APIArray, callback, errorHandler);
  };

  _fetchBudgets = () => {
    if (hasBudgetAccess(this.props.member)) {
      const memberObj = this.props.member;
      const userObj = this.props.user;

      // fetch budget of the selected person if logged in user is a employee
      if (
        memberObj.pmExitDate != '' &&
        (userObj.isEmployee === true || userObj.isFinancialStaff === true)
      ) {
        return true;
      }

      if (
        memberObj.memberStatus === types2.MEMBER_STATUS_DATA.MANAGED &&
        (memberObj.planStatus === types2.PLAN_STATUS_DATA.PM_ACTIVE ||
          memberObj.planStatus === types2.PLAN_STATUS_DATA.COMPLETED)
      ) {
        return true;
      }
    }
    return false;
  };

  _fetchMyProfileDetails = (personId: any, planId: any, hasBudget: any) => {
    let callbackFunction = (results: any) => {
      let memberPayload = null;
      if (results[RESULT_INDEX.MEMBER]) {
        memberPayload = results[RESULT_INDEX.MEMBER].content;
        this.props.actions.MemberActions.actionGetMember(
          memberPayload,
          this.props.user.id,
          this.props.user.isEmployee,
          this.props.user.isFinancialStaff,
        );
      }

      if (results[RESULT_INDEX.LIVING_ARRANGEMENT]) {
        let livingPayload = results[RESULT_INDEX.LIVING_ARRANGEMENT].content;
        this.props.actions.LivingArrangementActions.actionGetLivingArrangement(
          livingPayload,
        );
      }
      if (results[RESULT_INDEX.FAVOURITES]) {
        let favouritesPayload = results[RESULT_INDEX.FAVOURITES].content;
        this.props.actions.FavouriteThingsActions.actionGetFavouriteThings(
          favouritesPayload,
        );
      }
      if (results[RESULT_INDEX.HEALTH]) {
        let healthPayload = results[RESULT_INDEX.HEALTH].content;
        this.props.actions.HealthWellbeingActions.actionGetHealthWellbeing(
          healthPayload,
        );
      }

      let crewCategoryPayload = results[RESULT_INDEX.CREW_CATEGORIES]
        ? results[RESULT_INDEX.CREW_CATEGORIES].content.categories
        : null;
      let personCrewsPayload = results[RESULT_INDEX.CREW]
        ? results[RESULT_INDEX.CREW].personCrews.content
        : null;
      let organisationCrewsPayload = results[RESULT_INDEX.CREW]
        ? results[RESULT_INDEX.CREW].organisationCrews.content
        : null;
      let crewsAccessPayload = results[RESULT_INDEX.CREW_ACCESS]
        ? results[RESULT_INDEX.CREW_ACCESS].content
        : null;
      // Write the data to redux

      if (
        crewCategoryPayload &&
        personCrewsPayload &&
        organisationCrewsPayload &&
        crewsAccessPayload
      ) {
        this.props.actions.CrewActions.actionGetCrews(
          crewCategoryPayload,
          personCrewsPayload,
          organisationCrewsPayload,
          crewsAccessPayload,
        );
      }

      if (results[RESULT_INDEX.SUPPORTS]) {
        const supportsPayload = results[RESULT_INDEX.SUPPORTS].content;
        this.props.actions.SupportsActions.actionGetSupports(supportsPayload);
      }
      if (results[RESULT_INDEX.GOALS]) {
        const goalsPayload = results[RESULT_INDEX.GOALS].content.currentGoals;
        this.props.actions.GoalActions.actionGetGoals(goalsPayload);
      }
      let memberObj = {
        id: this.props.member.id,
        showBanner: true,
      };
      this.props.actions.MemberActions.actionUpdateMember(memberObj);
      if (!hasBudget) {
        this.navigateMemberScreen();
      }
    };

    let APIArray = [
      getLivingArrangement(personId),
      getFavourites(personId),
      getHealth(personId),
      getCrewCategories(personId),
      getCrew(personId),
      getCrewAccessLevels(personId),
    ];

    if (planId) {
      APIArray.push(getSupports(planId, personId));
      APIArray.push(getGoalsForPlan(planId, personId));
    }
    callAPIs(APIArray, callbackFunction, null, () => {
      this.setState({loading: false});
    });
  };

  _navigateToAddMember() {
    this.props.nav.navigate('CrewCreateMember', {
      screenMode: 'ADD_MEMBER',
    });
  }

  _renderNdisNumber = (member: any) => {
    if (member.item.ndisNumber) {
      return (
        <Text
          style={BrandStyles.TextColor10}
        >{` (${member.item.ndisNumber})`}</Text>
      );
    }
  };

  _showMembers() {
    if (this.state.filteredMembers.length > 0) {
      return (
        <View
          style={[
            CommonStyles.autoCompleteInput,
            CommonStyles.elevation10,
            BrandStyles.primaryBgColor1,
            BrandStyles.brandBorderColor2,
            CommonStyles.borderWidth1,
            CommonStyles.autoCompletePopOverContainer,
          ]}
        >
          <FlatList
            data={this.state.filteredMembers}
            renderItem={member => {
              return (
                <StandardTouchableOpacity
                  onPress={() => {
                    this._loadMember(member.item);
                  }}
                  style={[
                    CommonStyles.autoCompleteSearchBorder,
                    CommonStyles.flexRow,
                  ]}
                >
                  <Text>{`${member.item.firstName} ${member.item.lastName}`}</Text>
                  {this._renderNdisNumber(member)}
                </StandardTouchableOpacity>
              );
            }}
          />
        </View>
      );
    } else {
      return null;
    }
  }

  _getPersonTileArray(members: any) {
    if (members) {
      return members.map((member: any) => {
        return (
          <StandardTouchableOpacity
            accessible
            accessibilityLabel={'Member Tile'}
            activeOpacity={0.6}
            onPress={() => this._loadMember(member)}
            style={[
              CommonStyles.profileTileWrapper,
              BrandStyles.primaryBgColor1,
            ]}
          >
            <View style={[CommonStyles.justifyFlexRowStart]}>
              <RoundPhotoTile
                style={[
                  CommonStyles.roundedMemberImage,
                  CommonStyles.marginRight5,
                ]}
                containerStyle={[CommonStyles.containerRoundMemberPhoto]}
                accessible={true}
                imageObject={member.profileImage}
                apiFileType={'profile'}
                accessibilityLabel={'Member Profile Picture'}
                disabled={true}
                lastUpdate={new Date()}
              />
              <StandardText style={[CommonStyles.webRecentMembersText]}>
                {`${member.firstName} ${member.lastName}`}
              </StandardText>
            </View>
          </StandardTouchableOpacity>
        );
      });
    } else {
      return null;
    }
  }

  _loadSearchBox = () => {
    if (
      this.props.user.isEmployee ||
      this.props.user.isFinancialStaff ||
      this.state.members.length > MAX_MEMBERS
    ) {
      return (
        <StandardSearch
          editable={true}
          underlineColorAndroid="transparent"
          value={this.state.searchText}
          placeholder={types2.SEARCH_MEMBERS}
          onChangeText={(text: any) =>
            this.debouncedSearchFilteredSupport(text)
          }
          webStyles={[CommonStyles.StandardSearchContainerWeb]}
          iconStyle={[CommonStyles.searchBoxIconSmall]}
          placeHolderStyle={[
            CommonStyles.font13,
            CommonStyles.fontWeightBold,
            CommonStyles.paddingRight0,
            CommonStyles.paddingTop0,
          ]}
          noBorder={false}
          border={{borderWidth: 0}}
          disabledPlaceHolderStyleChange={true}
        />
      );
    }
  };

  _loadSearchResults = () => {
    if (
      this.state.members.length > MAX_MEMBERS &&
      this.state.showResults &&
      this.state.searchText !== ''
    ) {
      return this._showMembers();
    }
  };

  _loadAddMember = () => {
    if (this.props.user.isCrew) {
      return (
        <View style={[CommonStyles.margin10, CommonStyles.zIndex100]}>
          <View>
            <StandardTouchableOpacity
              accessible
              accessibilityLabel={'Add new member'}
              activeOpacity={0.6}
              onPress={() => {
                this._navigateToAddMember();
              }}
              style={[
                CommonStyles.addNewMemberTileWrapper,
                BrandStyles.primaryBgColor1,
              ]}
            >
              <View
                style={[
                  CommonStyles.flexDirectionRow,
                  CommonStyles.alignFlexStart,
                ]}
              >
                <CustomIcon
                  accessible={false}
                  name={AddMembersRed4x}
                  style={CommonStyles.addNewMemberIcon}
                />
                <StandardText style={[CommonStyles.webAddMembersText]}>
                  {SETTINGS_SCREEN.ADD_NEW_MEMBER}
                </StandardText>
              </View>
            </StandardTouchableOpacity>
          </View>
        </View>
      );
    } else {
      return null;
    }
  };

  renderSearch() {
    return (
      <View style={[CommonStyles.DatePickerBoxMargin, CommonStyles.zIndex100]}>
        <View style={[CommonStyles.flexDirectionRow]}>
          {this._loadSearchBox()}
        </View>
        <View>{this._loadSearchResults()}</View>
      </View>
    );
  }
  render() {
    return (
      <View>
        {this.props.isMobile ? this._loadAddMember() : null}
        {this._getPersonTileArray(this.props.user.recentMembers)}
        {this._loadAddMember()}
        {this.renderSearch()}
      </View>
    );
  }
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  budgets: state.BudgetReducer,
  guideMe: state.GuideMeReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    UserActions: bindActionCreators(UserActions, dispatch),
    MemberActions: bindActionCreators(MemberActions, dispatch),
    LoadedMemberActions: bindActionCreators(LoadedMemberActions, dispatch),
    BudgetActions: bindActionCreators(BudgetActions, dispatch),
    PayInvoiceActions: bindActionCreators(PayInvoiceActions, dispatch),
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    GoalActions: bindActionCreators(GoalActions, dispatch),
    LivingArrangementActions: bindActionCreators(
      LivingArrangementActions,
      dispatch,
    ),
    FavouriteThingsActions: bindActionCreators(
      FavouriteThingsActions,
      dispatch,
    ),
    HealthWellbeingActions: bindActionCreators(
      HealthWellbeingActions,
      dispatch,
    ),
    CrewActions: bindActionCreators(CrewActions, dispatch),
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
    NotificationActions: bindActionCreators(NotificationActions, dispatch),
    TabBarActions: bindActionCreators(TabBarActions, dispatch),
    GuideMeActions: bindActionCreators(GuideMeActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WebMembers);
