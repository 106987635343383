import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function AvatarReducer(state = {}, action: any) {
  switch (action.type) {
    case types.RESET_STORE:
      return {};

    case types.GET_PAY_INVOICE_STEP:
      return {
        ...state,
        payInvoiceStep: action.payInvoiceStep,
      };
    case types.GET_PAY_INVOICE_VIEW:
      return {
        ...state,
        payInvoiceView: action.payInvoiceView,
      };
    case types.GET_MEMBER_BILLERS:
      return {
        ...state,
        memberBillers: action.memberBillers,
      };
    case types.ADD_NEW_PROVIDER:
      return {
        ...state,
        selectedProvider: action.selectedProvider,
      };
    case types.ADD_INVOICE_ACTION:
      return {
        ...state,
        invoice: action.invoiceDetails,
      };
    case types.ADD_SUPPORT_ITEMS_ACTION:
      return {
        ...state,
        supportItems:
          state.supportItems && state.supportItems.length > 0
            ? [...state.supportItems, action.supportItems]
            : [action.supportItems],
      };
    case types.SET_PAYMENT_SUBMIT_RESULT:
      return {
        ...state,
        paymentResult: action.paymentResult,
      };
    case types.REMOVE_SUPPORT_ITEMS_ACTION:
      return {
        ...state,
        supportItems: state.supportItems.filter(
          (item: any, index: any) => index !== action.index,
        ),
      };
    case types.SET_INVOICE_DOC:
      return {
        ...state,
        invoiceDoc: action.invoiceDoc,
      };
    case types.SET_DRAFT_INVOICES_COUNT:
      return {
        ...state,
        draftInvoicesCount: action.draftInvoicesCount,
      };

    default:
      return state;
  }
}
