/*
 * Author: Brad D'Costa
 * Date: 29/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Member Profile and About Me information.
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {nullStringReplacer} from '../API/APIHelper';

/* Expected DataModel as per v36 of Swagger Definitions (versioning is based on Confluence versioning)
 *  {
 *      "id": "string"
 *      "planId": "string"
 *      "firstName": "string"
 *      "lastName": "string"
 *      "knownAs": "string"
 *      "email": "string"
 *      "dateOfBirth": "string"
 *      "moreAboutMe": "string"
 *      "city": "string"
 *      "postcode": "string"
 *      "state": "string"
 *      "ndisMeeting": "string"
 *      "phoneNumber": "string"
 *      "access": {
 *          "profile": "string
 *          "finances": "string"
 *          "documents": "string"
 *          "notifications": "string"
 *      }
 *      "profileImage": {
 *          "key": "string"
 *          "lastModified": "string"
 *          "profileImageURL": "string"
 *      }
 */
export default function MemberReducer(state = {}, action: any) {
  switch (action.type) {
    case types.GET_MEMBER:
      let parsedMemberAccessObj = {};

      if (
        action.loggedInUserId === action.memberObj.id ||
        action.isEmployee ||
        action.isFinancialStaff
      ) {
        parsedMemberAccessObj = {
          profile: types.ACCESS_FULL,
          finances: types.ACCESS_FULL,
          documents: types.ACCESS_FULL,
          notifications: types.ACCESS_FULL,
          decisionMaker: types.ACCESS_DECISION_MAKER_YES,
        };
      } else {
        parsedMemberAccessObj = {
          profile: types.ACCESS_NONE,
          finances: types.ACCESS_NONE,
          documents: types.ACCESS_NONE,
          notifications: types.ACCESS_NONE,
          decisionMaker: types.ACCESS_DECISION_MAKER_NO,
        };
      }

      if (action.memberObj.access != null) {
        parsedMemberAccessObj = {
          profile: nullStringReplacer(
            action.memberObj.access.profile,
            types.DEFAULT_ACCESS_CONTROL,
          ),
          finances: nullStringReplacer(
            action.memberObj.access.finances,
            types.DEFAULT_ACCESS_CONTROL,
          ),
          documents: nullStringReplacer(
            action.memberObj.access.documents,
            types.DEFAULT_ACCESS_CONTROL,
          ),
          notifications: nullStringReplacer(
            action.memberObj.access.notifications,
            types.DEFAULT_ACCESS_CONTROL,
          ),
          decisionMaker: nullStringReplacer(
            action.memberObj.access.decisionMaker,
            types.ACCESS_DECISION_MAKER_NO,
          ),
        };
      }

      let parsedMemberProfileImageObj = {
        key: '',
        lastModified: '',
        profileImageURL: '',
        url: '',
        photoCaption: '',
      };
      if (action.memberObj.profileImage != null) {
        parsedMemberProfileImageObj = {
          key: nullStringReplacer(action.memberObj.profileImage.key),
          lastModified: nullStringReplacer(
            action.memberObj.profileImage.lastModified,
          ),
          photoCaption: nullStringReplacer(
            action.memberObj.profileImage.photoCaption,
          ),
          profileImageURL: nullStringReplacer(
            action.memberObj.profileImage.profileImageURL,
          ),
          url: nullStringReplacer(action.memberObj.profileImage.url),
        };
      }

      const parsedMemberObj = {
        id: nullStringReplacer(action.memberObj.id),
        memberStatus: nullStringReplacer(action.memberObj.memberStatus),
        planId: nullStringReplacer(action.memberObj.planId),
        planStatus: nullStringReplacer(action.memberObj.planStatus),
        firstName: nullStringReplacer(action.memberObj.firstName),
        lastName: nullStringReplacer(action.memberObj.lastName),
        knownAs: nullStringReplacer(action.memberObj.knownAs),
        email: nullStringReplacer(action.memberObj.email),
        dateOfBirth: nullStringReplacer(action.memberObj.dateOfBirth),
        dailyLiving: nullStringReplacer(action.memberObj.dailyLiving),
        communication: nullStringReplacer(action.memberObj.communication),
        connectingWithOthers: nullStringReplacer(
          action.memberObj.connectingWithOthers,
        ),
        learning: nullStringReplacer(action.memberObj.learning),
        independence: nullStringReplacer(action.memberObj.independence),
        apartment: nullStringReplacer(action.memberObj.apartment),
        address: nullStringReplacer(action.memberObj.address),
        city: nullStringReplacer(action.memberObj.city),
        postcode: nullStringReplacer(action.memberObj.postcode).toString(),
        state: nullStringReplacer(action.memberObj.state),
        ndisMeeting: nullStringReplacer(action.memberObj.ndisMeeting),
        ndisPlanNumber: nullStringReplacer(action.memberObj.ndisPlanNumber),
        planStartDate: nullStringReplacer(action.memberObj.planStartDate),
        planManager: nullStringReplacer(action.memberObj.planManager),
        ndisNumber: nullStringReplacer(action.memberObj.ndisNumber),
        lifeStages: nullStringReplacer(action.memberObj.lifeStages),
        phoneNumber: nullStringReplacer(action.memberObj.phoneNumber),
        mobilePhone: nullStringReplacer(action.memberObj.mobilePhone),
        pmtnCAccepted: nullStringReplacer(action.memberObj.PMTnCAccepted),
        disabilities: nullStringReplacer(action.memberObj.disabilities),
        pmExitDate: nullStringReplacer(action.memberObj.pmExitDate),
        latitude: nullStringReplacer(action.memberObj.latitude).toString(),
        longitude: nullStringReplacer(action.memberObj.longitude).toString(),
        boundries: nullStringReplacer(action.memberObj.boundries),
        disabilityMoreInfo: nullStringReplacer(
          action.memberObj.disabilityMoreInfo,
        ),
        customDisabilities: nullStringReplacer(
          action.memberObj.customDisabilities,
        ),
        showBanner: false,
        enableNewPlanOption: action.memberObj.enableNewPlanOption,
        isHedgehog: action.memberObj.isHedgehog,
      };

      return {
        ...state,
        id: parsedMemberObj.id,
        memberStatus: parsedMemberObj.memberStatus,
        // memberStatus: 'onboarding',
        planId: parsedMemberObj.planId,
        planStatus: parsedMemberObj.planStatus,
        // planStatus: 'pm_lp_verified',
        firstName: parsedMemberObj.firstName,
        lastName: parsedMemberObj.lastName,
        knownAs: parsedMemberObj.knownAs,
        email: parsedMemberObj.email,
        dateOfBirth: parsedMemberObj.dateOfBirth,
        dailyLiving: parsedMemberObj.dailyLiving,
        communication: parsedMemberObj.communication,
        connectingWithOthers: parsedMemberObj.connectingWithOthers,
        learning: parsedMemberObj.learning,
        independence: parsedMemberObj.independence,
        apartment: parsedMemberObj.apartment,
        address: parsedMemberObj.address,
        city: parsedMemberObj.city,
        postcode: parsedMemberObj.postcode,
        state: parsedMemberObj.state,
        ndisMeeting: parsedMemberObj.ndisMeeting,
        planManager: parsedMemberObj.planManager,
        ndisNumber: parsedMemberObj.ndisNumber,
        ndisPlanNumber: parsedMemberObj.ndisPlanNumber,
        planStartDate: parsedMemberObj.planStartDate,
        access: parsedMemberAccessObj,
        profileImage: parsedMemberProfileImageObj,
        pmtnCAccepted: parsedMemberObj.pmtnCAccepted,
        lifeStages: parsedMemberObj.lifeStages,
        phoneNumber: parsedMemberObj.phoneNumber,
        mobilePhone: parsedMemberObj.mobilePhone,
        disabilities: parsedMemberObj.disabilities,
        pmExitDate: parsedMemberObj.pmExitDate,
        latitude: parsedMemberObj.latitude,
        longitude: parsedMemberObj.longitude,
        boundries: parsedMemberObj.boundries,
        disabilityMoreInfo: parsedMemberObj.disabilityMoreInfo,
        apiCalled: new Date(),
        customDisabilities: parsedMemberObj.customDisabilities,
        showBanner: parsedMemberObj.showBanner,
        enableNewPlanOption: parsedMemberObj.enableNewPlanOption,
        isHedgehog: parsedMemberObj.isHedgehog,
      };
    case types.UPDATE_MEMBER:
      const newValue = action.memberObj;
      const updatedState = Object.assign({}, state);
      for (const k in newValue) updatedState[k] = newValue[k];
      return updatedState;
    case types2.IS_SERVICE_AGREEMENTS_ENABLED:
      return {
        ...state,
        isServiceAgreementEnabled: action.serviceAgreement,
      };
    case types.UPDATE_MEMBER_PHOTO_CAPTION:
      const newCaption = nullStringReplacer(action.photoCaption);
      let existingProfileImage = state.profileImage;
      existingProfileImage.photoCaption = newCaption;
      return {
        ...state,
        profileImage: existingProfileImage,
      };
    default:
      return state;
  }
}
