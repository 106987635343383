/*
 * Author: Andrew Lee
 * Date: 03/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a custom component that will behave like an auto complete text input where it will have a drop down list of the results that it is passed in to render.
 */

import React, {Component} from 'react';
import {
  View,
  StyleSheet,
  ScrollView,
  ActivityIndicator,
  Platform,
} from 'react-native';
import {StandardInput, StandardText} from '../Atoms';
import {ActionButton} from './';

import {
  COMMON_BUTTONS as buttons,
  COMMON_BUTTONS,
} from '../../Constants/Messages';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
//Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {INPUT_CONTROL_ACCESSIBILITY} from '../../Constants/Messages';

class AutoComplete extends React.PureComponent {
  constructor(props: any) {
    super(props);

    this._renderResults.bind(this);
  }

  _renderResults = () => {
    const {data, renderItem} = this.props;

    // Get the list of items to be displayed, by mapping the render item into an array that will be put into a view
    let listJSX: any = [];
    data.map((item: any) => {
      let renderedItem = renderItem(item);
      listJSX.push(renderItem(item));
    });

    return (
      <ScrollView
        style={[CommonStyles.heightLimit, CommonStyles.zIndex102]}
        keyboardShouldPersistTaps={'always'}
        nestedScrollEnabled={this.props.nestedScrollEnabled}
      >
        {listJSX.length > 0 && listJSX}
      </ScrollView>
    );
  };

  render() {
    const {containerStyle, listStyle, rowStyle} = this.props;

    let showResults = this._determineShowResults();
    return (
      <View
        style={[
          CommonStyles.content,
          containerStyle,
          CommonStyles.autoCompleteContiner,
        ]}
      >
        <View
          style={[
            rowStyle
              ? [
                  CommonStyles.flexRow,
                  CommonStyles.justifyContentCentre,
                  CommonStyles.alignItemsCenter,
                ]
              : [],
          ]}
        >
          <View
            style={[
              rowStyle ? [CommonStyles.flex065] : [],
              CommonStyles.marginRight10,
            ]}
          >
            <StandardInput
              {...this.props}
              accessibilityLabel={INPUT_CONTROL_ACCESSIBILITY.SEARCH}
              noMargin={true}
            />
          </View>
          {this.props.isLoadingSearch && (
            <View
              style={[
                CommonStyles.flex1,
                CommonStyles.flexColumn,
                CommonStyles.alignItemsCenter,
                CommonStyles.justifyContentCentre,
              ]}
            >
              <ActivityIndicator color={'red'} size="large" />
            </View>
          )}
          {this.determineCanAddCustom(showResults) && (
            <View style={[rowStyle ? CommonStyles.marginLeft5 : {}]}>
              <ActionButton
                isToucable={true}
                customButton={this.props.customButton}
                customText={types.ADD_YOUR_OWN}
                actionType={types.ACTION_BUTTON.ADD}
                onPress={() => {
                  this.props.addCustom(this.props.value);
                }}
              />
            </View>
          )}
        </View>
        <View
          style={[
            CommonStyles.zIndex10,
            CommonStyles.content,
            CommonStyles.flexRow,
          ]}
        >
          <View style={[CommonStyles.flex065]}>
            {showResults && (
              <View
                style={[
                  listStyle,
                  CommonStyles.autoCompleteInput,
                  CommonStyles.elevation10,
                  this.props.border,
                ]}
              >
                {this.props.hideSuggestion
                  ? !this.props.hideSuggestion && this._renderResults()
                  : this._renderResults()}
              </View>
            )}
          </View>
          <View style={[CommonStyles.flex1]} />
        </View>
      </View>
    );
  }

  _determineShowResults = () => {
    let showResults = false;

    if (Array.isArray(this.props.data) && this.props.data.length > 0) {
      showResults = true;
    }

    return showResults;
  };

  // # TODO Later we have to remove canAddCustom prop once the responsive design conclude
  // # Added customButton prop to have new design
  determineCanAddCustom(showResults: any) {
    return (
      this.props.canAddCustom ||
      (this.props.customButton && !showResults && this.props.value.length >= 2)
    );
  }
}

export default AutoComplete;
