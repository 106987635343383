import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {
  View,
  Text,
  Dimensions,
  StyleSheet,
  Image,
  Animated,
  Easing,
  AppState,
} from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import {noWifiIcon} from '../../assets/images';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {ERRORS as errorMessages} from '../../Constants/Messages';
import {OFFLINE_NOTICE_SHAKE_VALUES as shakeValue} from '../../Constants/Constants2';

const {width} = Dimensions.get('window');
class OfflineNotice extends PureComponent {
  shakeAnimValue: any;
  state = {
    isConnected: true,
  };

  constructor() {
    super();
    this.shakeAnimValue = new Animated.Value(0);
  }

  shake() {
    this.shakeAnimValue.setValue(0);
    Animated.timing(this.shakeAnimValue, {
      toValue: 1,
      duration: 400,
      easing: Easing.linear,
    }).start();
  }

  componentDidMount() {
    NetInfo.fetch().then(isConnected => {
      this.setState({isConnected: isConnected});
      this.shake();
    });
    AppState.addEventListener('change', this._handleAppStateChange);
  }

  _handleAppStateChange = (nextAppState: any) => {
    if (nextAppState) {
      NetInfo.fetch().then(isConnected => {
        this.setState({isConnected: isConnected});
      });
    }
  };

  handleConnectivityChange = (isConnected: any) => {
    this.shake();
    if (isConnected) {
      this.setState({isConnected: true});
    } else {
      this.setState({isConnected: false});
    }
  };

  render() {
    if (this.props.offline.shaking) {
      this.shake();
    }
    const marginLeft = this.shakeAnimValue.interpolate({
      inputRange: shakeValue.inputRange,
      outputRange: shakeValue.outputRange,
    });

    if (!this.state.isConnected) {
      return (
        <View
          style={[
            CommonStyles.offlineContainer,
            BrandStyles.noWifiBannerBackGroundColor,
          ]}
        >
          <Animated.View
            style={[
              CommonStyles.flex1,
              CommonStyles.flexDirectionRow,
              CommonStyles.alignCenter,
              {marginLeft},
            ]}
          >
            <Image style={[CommonStyles.wifiIcon]} source={noWifiIcon} />
            <Text
              style={[
                CommonStyles.offlineText,
                BrandStyles.brandNowifiTextColor,
              ]}
              onPress={this.shake.bind(this)}
            >
              {errorMessages.OFFLINE_NOTICE}
            </Text>
          </Animated.View>
        </View>
      );
    }
    return null;
  }
}

const mapStateToProps = (state: any) => ({
  offline: state.OfflineNoticeReducer,
});

export default connect(mapStateToProps)(OfflineNotice);
