import React, {Component} from 'react';
import {View, Text, FlatList, StyleSheet, Alert} from 'react-native';
import {orderBy} from 'lodash';

// Import Stylesheets
import BrandStyles from '../Styles/BrandStyles';
import CommonStyles from '../Styles/CommonStyles';
import {BrandColors} from '../Styles/Colours';

import {CommonActions, StackActions} from '@react-navigation/native';
import BasicPopupAlert from '../Components/Organisms/BasicPopupAlert';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {ADD_NEW_PROVIDER as messages} from '../Constants/Messages';
import * as PayInvoiceActions from '../Actions/PayInvoiceActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import DeviceInfo from 'react-native-device-info';
import {putMemberProvider} from '../API/PlanDataAPI';

import {callAPIs} from '../API/APICaller';
import {debounce} from 'lodash';
import {
  StandardInput,
  StandardSearch,
  StandardTouchableOpacity,
  StandardText,
} from '../Components/Atoms';

import {
  DocumentsTopBar,
  Container,
  GridView,
  FormTitle,
  BrandActivityIndicator,
} from '../Components/Molecules';
import {BasicForm} from '../Components/Organisms';

import {getProviders, postProvider} from '../API/ProviderAPI';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {focusNextField} from '../Components/FocusNextField/FocusNextField';
import logger from 'helpers/Logger';
import {isTablet} from '../Helpers/PlatformSynchronizer';

const INPUTS = {
  SCROLL_VIEW: 'scrollView',
  ORGANIZATION: 'firstName',
  ABN: 'abn',
  BSB: 'bsb',
  EMAIL: 'email',
  BANKACCOUNTNUMBER: 'bankaccountnumber',
};

const LABELS = {
  BILL_TO: 'Leap in! bills to ',
};
class AddProvider extends Component {
  inputs: any;
  state = {
    selectedProvider: {},
    validBankAccNumber: '',
    validBSB: '',
    validEmail: '',
    validABN: '',
    validOrganisationName: '',
    loading: false,
    errorPopUpVisible: false,
    searchQuery: '',
    filteredProviders: [],
    enableEdit: true,
    providerBillerKey: '',
  };

  constructor(props: any) {
    super(props);
    this.inputs = {};
    this._showAlert = this._showAlert.bind(this);
    this._closeAlert = this._closeAlert.bind(this);
  }

  _closeAlert() {
    this.setState({
      errorPopUpVisible: false,
      loading: false,
    });
    if (types.isWeb) {
      this.props.setLoading(false);
    }
  }

  focusNextField(nextField: any) {
    if (this.inputs[nextField].focus) {
      this.inputs[nextField].focus();
    }
  }

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        headerTitle={messages.HEADER_TITLE}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    let cancelContainerStyle, cancelStyle, saveContainerStyle, contentStyle;
    if (this.state.errorPopUpVisible && this.state.saveAction) {
      cancelContainerStyle = [
        BrandStyles.primaryBgColor1,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerFormAction,
        CommonStyles.marginRight10,
      ];

      cancelStyle = [
        CommonStyles.customFontBold,
        CommonStyles.buttonFormAction,
        BrandStyles.TextColor4,
      ];

      saveContainerStyle = [
        CommonStyles.buttonContainerFormAction,
        BrandStyles.primaryBgColor4,
        BrandStyles.borderColor4,
      ];

      contentStyle = [CommonStyles.textAlignLeft];

      if (types.isWeb && this.props.isMobile) {
        cancelContainerStyle.push(CommonStyles.maxWidth100);
        saveContainerStyle.push(CommonStyles.maxWidth100);
      }
    }
    return (
      <BasicForm
        headerDisplayString={messages.MAIN_TITLE}
        disableMore={true}
        HeaderTextColor={BrandStyles.TextColor5}
        HeaderBoarderColor={BrandStyles.brandBorderColor4}
        showButton={false}
        close={() => this._closeAddproviderForm()}
        showButton={false}
        save={() => this._submit()}
        buttonsPositionBottom={true}
      >
        {this._renderAddProviderComponents()}
        <BasicPopupAlert
          visible={this.state.errorPopUpVisible}
          close={this.state.closeAction}
          cancelLabel={this.state.alertButtonText}
          saveAction={this.state.saveAction}
          saveLabel={this.state.saveButtonText}
          headerText={this.state.alertHeader}
          messageText={this.state.alertMessage}
          cancelContainerStyle={cancelContainerStyle}
          saveContainerStyle={saveContainerStyle}
          cancelStyle={cancelStyle}
          contentStyle={this.state.memberProviderExists ? contentStyle : null}
          style={this._determinePopUpStyle()}
          isMobile={this.props.isMobile}
        />
      </BasicForm>
    );
  };

  _determinePopUpStyle = () => {
    const styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      if (this.props.isMobile) {
        styles.push([CommonStyles.containerInstructionPopUpWebMobileLarge]);
        return styles;
      }
      styles.push([CommonStyles.containerInstructionPopUpWebLarge]);
      return styles;
    }
    if (isTablet() || types.isWeb) {
      styles.push(
        CommonStyles.containerInstructionPopUpTablet,
        CommonStyles.height300,
      );
      return styles;
    }
    styles.push(CommonStyles.changePasswordPopUpMobile);
    return styles;
  };

  _closeAddproviderForm = () => {
    if (types.isWeb) {
      this.props.navigation.goBack();
    } else {
      this.props.navigation.dispatch(CommonActions.goBack());
    }
  };

  _setFilterdProviders(data: any) {
    if (data[0].content.length > 0) {
      const filteredProviders: any = [];
      data[0].content.map((provider: any) => {
        if (provider.isVerified) {
          filteredProviders.push(provider);
        }
      });

      if (
        this.state.searchQuery !== '' &&
        this.state.searchQuery !== undefined
      ) {
        this.setState({filteredProviders});
      }
    } else {
      this.setState({filteredProviders: []});
    }
  }

  _fetchSearchData(query: any) {
    callAPIs(
      [getProviders(this.props.loadedMemberId.loadedMemberId, query)],
      (data: any) => this._setFilterdProviders(data),
      () => {},
    );
  }

  _searchProviders(query: any) {
    this.setState({
      searchQuery: query,
      showResults: true,
      providerBillerKey: '',
    });

    if (query === '' || query === undefined || query < 1) {
      this.setState({filteredProviders: []});
      return;
    }

    this._debouncedSearchProvider(query);
  }

  _debouncedSearchProvider = debounce(
    query => this._fetchSearchData(query),
    1000,
  );

  _loadProvider = (provider: any) => {
    this.setState({
      showResults: false,
    });

    if (provider.isLPPartnered) {
      this._showAlert(
        types2.ERROR,
        types.EXISTING_PARTNER_PROVIDER,
        types2.OK,
        () => {
          this.setState({
            searchQuery: '',
            errorPopUpVisible: false,
          });
        },
        true,
      );
    } else {
      if (provider.isVerified) {
        this.setState({
          enableEdit: false,
          providerBillerKey: provider.billerKey,
        });
      } else {
        this.setState({
          enableEdit: true,
        });
      }

      const selectedProvider = {
        organisation: provider.providerFriendlyName
          ? provider.providerFriendlyName
          : provider.providerVerifiedName,
        abn: provider.abn.toString(),
        bsb: provider.bankAccountBsb,
        accountNumber: provider.bankAccountNumber,
        email: provider.email,
        verifiedName: provider.providerVerifiedName,
      };

      this.setState({
        searchQuery: '',
        selectedProvider,
        validOrganisationName: '',
        validABN: '',
        validEmail: '',
        validBSB: '',
        validBankAccNumber: '',
      });
    }
  };

  _showProviders() {
    if (this.state.filteredProviders.length > 0) {
      const orderedProviders = orderBy(
        this.state.filteredProviders,
        [
          provider =>
            provider.providerFriendlyName
              ? provider.providerFriendlyName.toLowerCase()
              : provider.providerVerifiedName.toLowerCase(),
        ],
        ['asc'],
      );
      return (
        <View
          style={[
            CommonStyles.autoCompleteInput,
            CommonStyles.elevation10,
            BrandStyles.primaryBgColor1,
            BrandStyles.brandBorderColor2,
            CommonStyles.borderWidth1,
            CommonStyles.autoCompletePopOverContainer,
          ]}
        >
          <FlatList
            data={orderedProviders}
            renderItem={provider => {
              return (
                <StandardTouchableOpacity
                  key={provider.index}
                  onPress={() => {
                    this._loadProvider(provider.item);
                  }}
                  style={[
                    CommonStyles.autoCompleteSearchBorder,
                    CommonStyles.flexRow,
                  ]}
                >
                  <StandardText style={[CommonStyles.textSearchProvider]}>
                    {provider.item.providerFriendlyName
                      ? provider.item.providerFriendlyName
                      : provider.item.providerVerifiedName}
                  </StandardText>
                </StandardTouchableOpacity>
              );
            }}
          />
        </View>
      );
    } else {
      return null;
    }
  }

  debouncedABN = debounce(input => this.filterProviderABN(input), 2000);

  debounceProviderABN = (input: any) => {
    if (input === '' || input === undefined || input < 1) {
      return;
    }

    this.debouncedABN(input);
  };

  filterProviderABN = () => {
    const abnLengthOne = 9;
    const abnLengthTwo = 11;
    const abn = this.state.selectedProvider.abn;

    //Check if entered abn value length is valid
    if (abn && (abn.length === abnLengthOne || abn.length === abnLengthTwo)) {
      callAPIs(
        [getProviders(this.props.loadedMemberId.loadedMemberId, abn)],
        (data: any) => {
          if (data[0].content.length > 0) {
            const filteredProviders = data[0].content;
            const abnProvider = filteredProviders.filter(
              (provider: any) => provider.abn === abn,
            );
            if (abnProvider.length > 0) {
              this.state.existingMemberProvider = abnProvider[0];
              this.autoFillProviderDetails(abnProvider[0]);
            }
          }
        },
        () => {},
      );
    }
  };

  autoFillProviderDetails = (provider: any) => {
    let displayText = '';
    if (provider.isNdisRegisteredProvider) {
      this.confirmAutoFill(provider);
    } else {
      if (provider.isLPPartnered) {
        this._showAlert(
          types2.ERROR,
          types.EXISTING_PARTNER_PROVIDER,
          types2.OK,
          () => {
            this.setState(prevState => ({
              selectedProvider: {
                ...prevState.selectedProvider,
                abn: '',
              },
              errorPopUpVisible: false,
            }));
          },
          true,
        );
      } else if (provider.isVerified) {
        const providerName = provider.providerFriendlyName
          ? provider.providerFriendlyName
          : provider.providerVerifiedName;

        if (
          this.state.selectedProvider.organisation &&
          this.state.selectedProvider.organisation !== ''
        ) {
          displayText =
            types.EXISTING_VERIFIED_PROVIDER[0] +
            providerName +
            types.EXISTING_VERIFIED_PROVIDER[1] +
            this.state.selectedProvider.organisation +
            types.EXISTING_VERIFIED_PROVIDER[2] +
            providerName +
            '?';
        } else {
          displayText =
            types.EXISTING_VERIFIED_PROVIDER[0] +
            providerName +
            types.EXISTING_VERIFIED_PROVIDER[3];
        }
        this._showAlert(
          types2.CHECKING,
          displayText,
          types2.NO,
          () => {
            this.setState(prevState => ({
              selectedProvider: {
                ...prevState.selectedProvider,
                abn: '',
              },
              errorPopUpVisible: false,
            }));
          },
          true,
          types2.YES,
          () => this.confirmAutoFill(provider),
        );
      }
    }
  };

  confirmAutoFill = (provider: any) => {
    if (provider.isVerified) {
      this.setState({
        enableEdit: false,
        providerBillerKey: provider.billerKey,
      });
    } else {
      this.setState({
        enableEdit: true,
      });
    }

    const selectedProvider = {
      organisation: this.state.selectedProvider.organisation
        ? this.state.selectedProvider.organisation
        : provider.providerVerifiedName,
      abn: provider.abn.toString(),
      bsb: provider.bankAccountBsb,
      accountNumber: provider.bankAccountNumber,
      email: provider.email,
      verifiedName: provider.providerVerifiedName,
    };
    this.setState({
      selectedProvider,
      validOrganisationName: '',
      validABN: '',
      validEmail: '',
      validBSB: '',
      validBankAccNumber: '',
      errorPopUpVisible: false,
    });
  };

  _setFilterdProviders(data: any) {
    if (data[0].content.length > 0) {
      const filteredProviders: any = [];

      data[0].content.map((provider: any) => {
        if (provider.isVerified) {
          filteredProviders.push(provider);
        }
      });

      if (
        this.state.searchQuery !== '' &&
        this.state.searchQuery !== undefined
      ) {
        this.setState({filteredProviders});
      }
    } else {
      this.setState({filteredProviders: []});
    }
  }

  _fetchSearchData(query: any) {
    callAPIs(
      [getProviders(this.props.loadedMemberId.loadedMemberId, query)],
      (data: any) => this._setFilterdProviders(data),
      () => {},
    );
  }

  _searchProviders(query: any) {
    this.setState({
      searchQuery: query,
      showResults: true,
      providerBillerKey: '',
    });

    if (query === '' || query === undefined || query < 1) {
      this.setState({filteredProviders: []});
      return;
    }

    this._debouncedSearchProvider(query);
  }

  _debouncedSearchProvider = debounce(
    query => this._fetchSearchData(query),
    1000,
  );

  _loadProvider = (provider: any) => {
    this.setState({
      showResults: false,
    });

    if (provider.isLPPartnered) {
      this._showAlert(
        types2.ERROR,
        types.EXISTING_PARTNER_PROVIDER,
        types2.OK,
        () => {
          this.setState({
            searchQuery: '',
            errorPopUpVisible: false,
          });
        },
        true,
      );
    } else {
      if (provider.isVerified) {
        this.setState({
          enableEdit: false,
          providerBillerKey: provider.billerKey,
        });
      } else {
        this.setState({
          enableEdit: true,
        });
      }

      const selectedProvider = {
        organisation: provider.providerFriendlyName
          ? provider.providerFriendlyName
          : provider.providerVerifiedName,
        abn: provider.abn.toString(),
        bsb: provider.bankAccountBsb,
        accountNumber: provider.bankAccountNumber,
        email: provider.email,
        verifiedName: provider.providerVerifiedName,
      };

      this.setState({
        searchQuery: '',
        selectedProvider,
        validOrganisationName: '',
        validABN: '',
        validEmail: '',
        validBSB: '',
        validBankAccNumber: '',
      });
    }
  };

  _showProviders() {
    if (this.state.filteredProviders.length > 0) {
      const orderedProviders = orderBy(
        this.state.filteredProviders,
        [
          provider =>
            provider.providerFriendlyName
              ? provider.providerFriendlyName.toLowerCase()
              : provider.providerVerifiedName.toLowerCase(),
        ],
        ['asc'],
      );
      return (
        <View
          style={[
            CommonStyles.autoCompleteInput,
            CommonStyles.elevation10,
            BrandStyles.primaryBgColor1,
            BrandStyles.brandBorderColor2,
            CommonStyles.borderWidth1,
            CommonStyles.autoCompletePopOverContainer,
          ]}
        >
          <FlatList
            data={orderedProviders}
            renderItem={provider => {
              return (
                <StandardTouchableOpacity
                  key={provider.index}
                  onPress={() => {
                    this._loadProvider(provider.item);
                  }}
                  style={[
                    CommonStyles.autoCompleteSearchBorder,
                    CommonStyles.flexRow,
                  ]}
                >
                  <StandardText style={[CommonStyles.textSearchProvider]}>
                    {provider.item.providerFriendlyName
                      ? provider.item.providerFriendlyName
                      : provider.item.providerVerifiedName}
                  </StandardText>
                </StandardTouchableOpacity>
              );
            }}
          />
        </View>
      );
    } else {
      return null;
    }
  }

  debouncedABN = debounce(input => this.filterProviderABN(input), 2000);

  debounceProviderABN = (input: any) => {
    if (input === '' || input === undefined || input < 1) {
      return;
    }

    this.debouncedABN(input);
  };

  filterProviderABN = () => {
    const abn = this.state.selectedProvider.abn;
    if (abn && (abn.length === 9 || abn.length === 11)) {
      callAPIs(
        [getProviders(this.props.loadedMemberId.loadedMemberId, abn)],
        (data: any) => {
          if (data[0].content.length > 0) {
            const filteredProviders = data[0].content;
            const abnProvider = filteredProviders.filter(
              (provider: any) => provider.abn === abn,
            );
            if (abnProvider.length > 0) {
              this.state.existingMemberProvider = abnProvider[0];
              this.autoFillProviderDetails(abnProvider[0]);
            }
          }
        },
        () => {},
      );
    }
  };

  autoFillProviderDetails = (provider: any) => {
    let displayText = '';
    if (provider.isNdisRegisteredProvider) {
      this.confirmAutoFill(provider);
    } else {
      if (provider.isLPPartnered) {
        this._showAlert(
          types2.ERROR,
          types.EXISTING_PARTNER_PROVIDER,
          types2.OK,
          () => {
            this.setState(prevState => ({
              selectedProvider: {
                ...prevState.selectedProvider,
                abn: '',
              },
              errorPopUpVisible: false,
            }));
          },
          true,
        );
      } else if (provider.isVerified) {
        const providerName = provider.providerFriendlyName
          ? provider.providerFriendlyName
          : provider.providerVerifiedName;
        if (
          this.state.selectedProvider.organisation &&
          this.state.selectedProvider.organisation !== ''
        ) {
          displayText =
            types.EXISTING_VERIFIED_PROVIDER[0] +
            providerName +
            types.EXISTING_VERIFIED_PROVIDER[1] +
            this.state.selectedProvider.organisation +
            types.EXISTING_VERIFIED_PROVIDER[2] +
            providerName +
            '?';
        } else {
          displayText =
            types.EXISTING_VERIFIED_PROVIDER[0] +
            providerName +
            types.EXISTING_VERIFIED_PROVIDER[3];
        }
        this._showAlert(
          types2.CHECKING,
          displayText,
          types2.NO,
          () => {
            this.setState(prevState => ({
              selectedProvider: {
                ...prevState.selectedProvider,
                abn: '',
              },
              errorPopUpVisible: false,
            }));
          },
          true,
          types2.YES,
          () => this.confirmAutoFill(provider),
        );
      }
    }
  };

  confirmAutoFill = (provider: any) => {
    if (provider.isVerified) {
      this.setState({
        enableEdit: false,
        providerBillerKey: provider.billerKey,
      });
    } else {
      this.setState({
        enableEdit: true,
      });
    }

    const selectedProvider = {
      organisation: this.state.selectedProvider.organisation
        ? this.state.selectedProvider.organisation
        : provider.providerVerifiedName,
      abn: provider.abn.toString(),
      bsb: provider.bankAccountBsb,
      accountNumber: provider.bankAccountNumber,
      email: provider.email,
      verifiedName: provider.providerVerifiedName,
    };
    this.setState({
      searchQuery: '',
      selectedProvider,
      validOrganisationName: '',
      validABN: '',
      validEmail: '',
      validBSB: '',
      validBankAccNumber: '',
      errorPopUpVisible: false,
    });
  };

  _renderAddProviderComponents = () => {
    return (
      <View>
        <View style={[CommonStyles.content]}>
          <View>
            <StandardSearch
              editable={true}
              noBorder={false}
              border={CommonStyles.borderWidth0}
              underlineColorAndroid="transparent"
              value={this.state.searchQuery}
              placeholder={types2.SEARCH_PROVIDERS}
              onChangeText={(text: any) => this._searchProviders(text)}
              webStyles={CommonStyles.StandardSearchContainerWeb}
              iconStyle={[
                types.isWeb
                  ? CommonStyles.searchBoxIconSmall
                  : CommonStyles.searchBoxIcon,
              ]}
              style={[CommonStyles.font14, CommonStyles.searchInputProvider]}
              disabledPlaceHolderStyleChange={true}
            />
          </View>
        </View>
        <View style={[CommonStyles.marginBottom10, CommonStyles.zIndex100]}>
          {this.state.showResults &&
            this.state.searchQuery !== '' &&
            this._showProviders()}
        </View>
        <View style={[CommonStyles.content, CommonStyles.zIndex01]}>
          <View style={[CommonStyles.subHeadingContainerMandotory]}>
            <FormTitle
              hasError={this.state.validOrganisationName}
              containsData={
                this.state.selectedProvider.organisation
                  ? this.state.selectedProvider.organisation.trim()
                  : this.state.selectedProvider.organisation
              }
              text={'Organisation name'}
              style={CommonStyles.textInputAddProvider}
            />
          </View>
          <View style={CommonStyles.marginBottom20}>
            <StandardInput
              noMargin={true}
              accessibilityLabel="Organisation name"
              placeholder="Organisation name"
              multiline={false}
              mandatory={true}
              key={'organisation'}
              onSubmitEditing={() => {
                focusNextField(this.inputs, INPUTS.SCROLL_VIEW, INPUTS.ABN);
              }}
              returnKeyType={'next'}
              value={this.state.selectedProvider.organisation}
              onRef={(ref: any) => {
                this.inputs[INPUTS.ORGANIZATION] = ref;
              }}
              blurOnSubmit={false}
              onChangeText={(orgName: any) =>
                this.setState(prevState => ({
                  selectedProvider: {
                    ...prevState.selectedProvider,
                    organisation: orgName,
                  },
                }))
              }
              defaultValue={
                this.props.payInvoice.selectedProvider != undefined
                  ? this.props.payInvoice.selectedProvider.organisation
                  : ''
              }
              underlineColorAndroid="transparent"
              style={[
                CommonStyles.ModalTextInputWrapper,
                BrandStyles.primaryBgColor1,
              ]}
              errorMessage={this.state.validOrganisationName}
            />
            <View style={CommonStyles.flex1EndContent}>
              <StandardText style={[CommonStyles.textProvider]}>
                {this.state.selectedProvider.verifiedName
                  ? LABELS.BILL_TO + this.state.selectedProvider.verifiedName
                  : ''}
              </StandardText>
            </View>
          </View>
        </View>

        <View style={[CommonStyles.content]}>
          <View style={[CommonStyles.subHeadingContainerMandotory]}>
            <FormTitle
              hasError={this.state.validABN}
              containsData={this.state.selectedProvider.abn}
              text={'ABN'}
              style={CommonStyles.textInputAddProvider}
            />
          </View>
          <View>
            <StandardInput
              readOnly={!this.state.enableEdit}
              accessibilityLabel="A B N"
              keyboardType="numeric"
              placeholder="ABN"
              multiline={false}
              mandatory={true}
              value={this.state.selectedProvider.abn}
              key={'abn'}
              returnKeyType={'next'}
              onRef={(ref: any) => {
                this.inputs[INPUTS.ABN] = ref;
              }}
              onSubmitEditing={() => {
                focusNextField(this.inputs, INPUTS.SCROLL_VIEW, INPUTS.EMAIL);
              }}
              onChangeText={(abn: any) => {
                this.setState(prevState => ({
                  selectedProvider: {
                    ...prevState.selectedProvider,
                    abn: abn.replace(/[^0-9]/g, ''),
                  },
                }));
                if (types.isWeb) {
                  this.debounceProviderABN(abn);
                }
              }}
              blurOnSubmit={false}
              underlineColorAndroid="transparent"
              style={[
                CommonStyles.ModalTextInputWrapper,
                BrandStyles.primaryBgColor1,
              ]}
              defaultValue={
                this.props.payInvoice.selectedProvider != undefined
                  ? this.props.payInvoice.selectedProvider.abn
                  : ''
              }
              onEndEditing={this.filterProviderABN}
              errorMessage={this.state.validABN}
              onEndEditing={this.filterProviderABN}
            />
          </View>
        </View>

        <View style={[CommonStyles.content]}>
          <View style={[CommonStyles.subHeadingContainerMandotory]}>
            <FormTitle
              hasError={this.state.validEmail}
              containsData={this.state.selectedProvider.email}
              text={'Email'}
              style={CommonStyles.textInputAddProvider}
            />
          </View>
          <View>
            <StandardInput
              readOnly={!this.state.enableEdit}
              accessibilityLabel="Email"
              placeholder="Email"
              multiline={false}
              key={'email'}
              mandatory={true}
              returnKeyType={'next'}
              value={this.state.selectedProvider.email}
              onRef={(ref: any) => {
                this.inputs[INPUTS.EMAIL] = ref;
              }}
              onSubmitEditing={() => {
                focusNextField(this.inputs, INPUTS.SCROLL_VIEW, INPUTS.BSB);
              }}
              onChangeText={(email: any) =>
                this.setState(prevState => ({
                  selectedProvider: {
                    ...prevState.selectedProvider,
                    email: email.replace(/^\s+|\s+$/g, ''),
                  },
                }))
              }
              blurOnSubmit={false}
              underlineColorAndroid="transparent"
              style={[
                CommonStyles.ModalTextInputWrapper,
                BrandStyles.primaryBgColor1,
              ]}
              defaultValue={
                this.props.payInvoice.selectedProvider != undefined
                  ? this.props.payInvoice.selectedProvider.email
                  : ''
              }
              errorMessage={this.state.validEmail}
            />
          </View>
        </View>

        <View style={CommonStyles.flexRow}>
          <View style={[CommonStyles.content, CommonStyles.flex025]}>
            <View style={[CommonStyles.subHeadingContainerMandotory]}>
              <FormTitle
                hasError={this.state.validBSB}
                containsData={this.state.selectedProvider.bsb}
                text={'BSB'}
                style={CommonStyles.textInputAddProvider}
              />
            </View>
            <View>
              <StandardInput
                readOnly={!this.state.enableEdit}
                accessibilityLabel="B S B"
                keyboardType="numeric"
                placeholder="BSB"
                multiline={false}
                mandatory={true}
                value={this.state.selectedProvider.bsb}
                key={'bsb'}
                returnKeyType={'next'}
                onRef={(ref: any) => {
                  this.inputs[INPUTS.BSB] = ref;
                }}
                onSubmitEditing={() => {
                  focusNextField(
                    this.inputs,
                    INPUTS.SCROLL_VIEW,
                    INPUTS.BANKACCOUNTNUMBER,
                  );
                }}
                onChangeText={(bsb: any) =>
                  this.setState(prevState => ({
                    selectedProvider: {
                      ...prevState.selectedProvider,
                      bsb: bsb.replace(/^\s+|\s+$/g, '').replace(/[^0-9]/g, ''),
                    },
                  }))
                }
                blurOnSubmit={false}
                underlineColorAndroid="transparent"
                style={[
                  CommonStyles.ModalTextInputWrapper,
                  BrandStyles.primaryBgColor1,
                ]}
                defaultValue={
                  this.props.payInvoice.selectedProvider != undefined
                    ? this.props.payInvoice.selectedProvider.bsb
                    : ''
                }
                errorMessage={this.state.validBSB}
              />
            </View>
          </View>

          <View
            style={[
              CommonStyles.content,
              CommonStyles.flex075,
              CommonStyles.marginLeft10,
            ]}
          >
            <View style={[CommonStyles.subHeadingContainerMandotory]}>
              <FormTitle
                hasError={this.state.validBankAccNumber}
                containsData={this.state.selectedProvider.accountNumber}
                text={'Bank account number'}
                style={CommonStyles.textInputAddProvider}
              />
            </View>
            <View>
              <StandardInput
                readOnly={!this.state.enableEdit}
                accessibilityLabel="Bank account number"
                keyboardType="numeric"
                placeholder="Bank account number"
                multiline={false}
                mandatory={true}
                value={this.state.selectedProvider.accountNumber}
                key={'accountNumber'}
                onRef={(ref: any) => {
                  this.inputs[INPUTS.BANKACCOUNTNUMBER] = ref;
                }}
                returnKeyType={'done'}
                blurOnSubmit={true}
                onChangeText={(account: any) =>
                  this.setState(prevState => ({
                    selectedProvider: {
                      ...prevState.selectedProvider,
                      accountNumber: account
                        .replace(/^\s+|\s+$/g, '')
                        .replace(/[^0-9]/g, ''),
                    },
                  }))
                }
                underlineColorAndroid="transparent"
                style={[
                  CommonStyles.ModalTextInputWrapper,
                  BrandStyles.primaryBgColor1,
                ]}
                defaultValue={
                  this.props.payInvoice.selectedProvider != undefined
                    ? this.props.payInvoice.selectedProvider.accountNumber
                    : ''
                }
                errorMessage={this.state.validBankAccNumber}
              />
            </View>
          </View>
        </View>
      </View>
    );
  };
  validateFields = () => {
    // Used to set the state for failed fields - we build it up as to only set the state once
    // If the error string is blank it means it passed validation :)
    // Additionaly it all sets them to passed if the user fixed previous validation issues
    var validateErrors = {
      organisationNameError: '',
      aBNError: '',
      emailError: '',
      bSBError: '',
      bankAccNumberError: '',
    };

    if (
      this.state.selectedProvider.organisation == undefined ||
      this.state.selectedProvider.organisation.trim() == ''
    ) {
      validateErrors.organisationNameError =
        types.ORGANISATION_NAME_NOT_ENTERED;
    }

    //validate email address format
    const reLengthWithSpecialChar = /^.{1,30}$/;
    const reEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.state.enableEdit) {
      if (
        this.state.selectedProvider.abn == '' ||
        this.state.selectedProvider.abn == undefined
      ) {
        validateErrors.aBNError = types.ABN_NOT_ENTERED;
      }

      if (
        this.state.selectedProvider.email == '' ||
        this.state.selectedProvider.email == undefined
      ) {
        validateErrors.emailError = types.EMAIL_NOT_ENTERED;
      }

      if (
        this.state.selectedProvider.bsb == '' ||
        this.state.selectedProvider.bsb == undefined
      ) {
        validateErrors.bSBError = types.BSB_NOT_ENTERED;
      }

      if (
        this.state.selectedProvider.accountNumber == '' ||
        this.state.selectedProvider.accountNumber == undefined
      ) {
        validateErrors.bankAccNumberError = types.ACCNUMBER_NOT_ENTERED;
      }

      if (
        validateErrors.emailError == '' &&
        !reEmail.test(this.state.selectedProvider.email)
      ) {
        validateErrors.emailError = types.EMAIL_INVALID_FORMAT;
      }
    }

    if (
      validateErrors.organisationNameError == '' &&
      !reLengthWithSpecialChar.test(this.state.selectedProvider.organisation)
    ) {
      validateErrors.organisationNameError =
        types.ORGANISATION_NAME_INVALID_FORMAT;
    } else if (
      validateErrors.organisationNameError == '' &&
      this.props.payInvoice.memberBillers &&
      this.props.payInvoice.memberBillers.some(
        (item: any) => this.state.selectedProvider.organisation === item.name,
      )
    ) {
      validateErrors.organisationNameError =
        types.ORGANISATION_NAME_INVALID_FORMAT;

      this._showAlert(
        types2.ERROR,
        types.ORGANISATION_NAME_EXISTS_ALERT,
        types2.OK,
        this._closeAlert,
        true,
      );
    }
    if (this.state.enableEdit) {
      var reLengthABN11 = /^[0-9]{11}$/;
      var reLengthABN9 = /^[0-9]{9}$/;
      if (
        validateErrors.aBNError == '' &&
        !reLengthABN11.test(this.state.selectedProvider.abn) &&
        !reLengthABN9.test(this.state.selectedProvider.abn)
      ) {
        validateErrors.aBNError = types.ABN_INVALID_FORMAT;
      }

      var reLengthBSB = /^[0-9]{6}$/;
      if (
        validateErrors.bSBError == '' &&
        !reLengthBSB.test(this.state.selectedProvider.bsb)
      ) {
        validateErrors.bSBError = types.BSB_INVALID_FORMAT;
      }

      var reLengthBankAccNumber = /^[0-9]{1,9}$/;
      if (
        validateErrors.bankAccNumberError == '' &&
        !reLengthBankAccNumber.test(this.state.selectedProvider.accountNumber)
      ) {
        validateErrors.bankAccNumberError = types.ACCNUMBER_INVALID_FORMAT;
      }
    }

    // Set the state to show the validation on the components
    this.setState({
      validOrganisationName: validateErrors.organisationNameError,
      validABN: validateErrors.aBNError,
      validEmail: validateErrors.emailError,
      validBSB: validateErrors.bSBError,
      validBankAccNumber: validateErrors.bankAccNumberError,
    });

    // Now return if the there are valid fields
    for (let [key, value] of Object.entries(validateErrors)) {
      if (value != '') {
        return false;
      }
    }
    return true;
  };

  _getPreviousValue = (value: any) => {
    return `(was ${value})`;
  };

  _createPopupMessage = (
    existingMemberProvider: any,
    currentMemberProvider: any,
  ) => {
    let popupMessage = `${types.UPDATE_EXISTING_PROVIDER_CHANGED.INITIAL_MESSAGE} "${existingMemberProvider.abn}".`;
    let changedProperties = [];
    let unchangedProperties = [];

    // check name
    if (currentMemberProvider.organisation !== existingMemberProvider.name) {
      changedProperties.push(
        `${
          types.UPDATE_EXISTING_PROVIDER_CHANGED.NAME
        } ${this._getPreviousValue(existingMemberProvider.name)}`,
      );
    } else {
      unchangedProperties.push(types.UPDATE_EXISTING_PROVIDER_CHANGED.NAME);
    }

    // Check email
    if (
      currentMemberProvider.email.toLowerCase() !==
      existingMemberProvider.email.toLowerCase()
    ) {
      changedProperties.push(
        `${
          types.UPDATE_EXISTING_PROVIDER_CHANGED.EMAIL
        } ${this._getPreviousValue(existingMemberProvider.email)}`,
      );
    } else {
      unchangedProperties.push(types.UPDATE_EXISTING_PROVIDER_CHANGED.EMAIL);
    }

    // Check back account number
    if (
      currentMemberProvider.accountNumber !==
      existingMemberProvider.bankAccountNumber
    ) {
      changedProperties.push(
        `${
          types.UPDATE_EXISTING_PROVIDER_CHANGED.ACCOUNT_NUMBER
        } ${this._getPreviousValue(existingMemberProvider.bankAccountNumber)}`,
      );
    } else {
      unchangedProperties.push(
        types.UPDATE_EXISTING_PROVIDER_CHANGED.ACCOUNT_NUMBER,
      );
    }

    // check bsb
    if (currentMemberProvider.bsb !== existingMemberProvider.bankAccountBsb) {
      changedProperties.push(
        `${types.UPDATE_EXISTING_PROVIDER_CHANGED.BSB} ${this._getPreviousValue(
          existingMemberProvider.bankAccountBsb,
        )}`,
      );
    } else {
      unchangedProperties.push(types.UPDATE_EXISTING_PROVIDER_CHANGED.BSB);
    }

    if (changedProperties.length > 0) {
      var changedPropertyString = changedProperties.join(',\n\t');
      popupMessage += `${types.UPDATE_EXISTING_PROVIDER_CHANGED.ENTER_DIFFERENT} \n\t${changedPropertyString}\n`;
    }

    if (unchangedProperties.length > 0) {
      var unchangedPropertyString = unchangedProperties.join(', ');
      popupMessage += `${types.UPDATE_EXISTING_PROVIDER_CHANGED.KEEP_SAME} ${unchangedPropertyString} ${types.UPDATE_EXISTING_PROVIDER_CHANGED.AS_PREVIOUS}`;
    }

    popupMessage += types.UPDATE_EXISTING_PROVIDER_CHANGED.REPLACE_PROVIDER;
    return popupMessage;
  };

  // Update(De-active) existing unverified member provider
  // And Create new member provider relationship
  _updateAndAddNewMemberProvider = (
    currentMemberProvider: any,
    existingMemberProvider: any,
  ) => {
    const updateMemberProviderDetails = {
      memberProviderId: existingMemberProvider[0].memberProviderId,
      providerDetails: {
        abn: currentMemberProvider.abn,
        accountNumber: currentMemberProvider.accountNumber,
        bsb: currentMemberProvider.bsb,
        email: currentMemberProvider.email,
        name: currentMemberProvider.organisation,
        active: false,
      },
    };

    const newMemberProviderDetails = {
      name: currentMemberProvider.organisation,
      email: currentMemberProvider.email,
      abn: currentMemberProvider.abn,
      bankAccount: {
        bsb: currentMemberProvider.bsb,
        accountNumber: currentMemberProvider.accountNumber,
        accountHolder: currentMemberProvider.organisation,
      },
    };

    const callBackMemberProvider = (data: any) => {
      if (data[1].content.message.hasOwnProperty('billerKey')) {
        this._setNewlyAddedProvider(data[1]);
      } else {
        logger.log('billerKey  error');
        if (types.isWeb) {
          this.props.setLoading(false);
        } else {
          this.setState({loading: false});
        }
      }
    };

    const errorCallBackMemberProvider = (err: any) => {
      logger.log('notifications error: ', err);
      if (types.isWeb) {
        this.props.setLoading(false);
      } else {
        this.setState({loading: false});
      }
    };

    this.setState({errorPopUpVisible: false});
    const APIArray = [
      putMemberProvider(
        this.props.loadedMemberId.loadedMemberId,
        updateMemberProviderDetails,
      ),
      postProvider(
        this.props.loadedMemberId.loadedMemberId,
        newMemberProviderDetails,
        false,
      ),
    ];

    callAPIs(APIArray, callBackMemberProvider, errorCallBackMemberProvider);
  };

  _setNewlyAddedProvider = (newProvider: any) => {
    this.setState(prevState => ({
      selectedProvider: {
        ...prevState.selectedProvider,
        billerKey: newProvider.content.message.billerKey,
      },
    }));

    callAPIs(
      [getProviders(this.props.loadedMemberId.loadedMemberId, '', true, false)],
      (data: any) => {
        const updatedMemberBillers = orderBy(
          data[0].content,
          [biller => biller.name.toLowerCase()],
          ['asc'],
        );
        this.props.actions.payInvoiceActions.getMemberBillersList(
          updatedMemberBillers,
        );
        this.props.actions.payInvoiceActions.addNewProviderAction(
          this.state.selectedProvider,
        );
        if (types.isWeb) {
          this.props.setLoading(false);
        } else {
          this.setState({loading: false});
        }
        this.props.navigation.goBack();
      },
      (err: any) => {
        if (types.isWeb) {
          this.props.setLoading(false);
        } else {
          this.setState({loading: false});
        }
        this.props.actions.payInvoiceActions.addNewProviderAction(
          this.state.selectedProvider,
        );
        this.props.navigation.goBack();
        logger.log('notifications error: ', err);
      },
    );
  };

  _submit = () => {
    if (types.isWeb) {
      this.props.setLoading(true);
    } else {
      this.setState({loading: true});
    }
    if (this.validateFields()) {
      const providerDetails = {
        name: this.state.selectedProvider.organisation.trim(),
        email: this.state.selectedProvider.email,
        abn: this.state.selectedProvider.abn,
        bankAccount: {
          bsb: this.state.selectedProvider.bsb,
          accountNumber: this.state.selectedProvider.accountNumber,
          accountHolder: this.state.selectedProvider.organisation.trim(),
        },
      };

      //If user adding an already verified provider send the biller key
      if (this.state.providerBillerKey) {
        providerDetails.billerKey = this.state.providerBillerKey;
      }

      var currentMemberProvider = this.state.selectedProvider;

      var isAbnAllZeros = false;

      var elevenZeroABN = /^[0]{11}$/;
      var nineZeroABN = /^[0]{9}$/;
      if (
        elevenZeroABN.test(currentMemberProvider.abn) ||
        nineZeroABN.test(currentMemberProvider.abn)
      ) {
        isAbnAllZeros = true;
      }

      var memberProviderList = [];

      if (
        this.props.payInvoice.memberBillers &&
        this.props.payInvoice.memberBillers.length > 0
      ) {
        memberProviderList = this.props.payInvoice.memberBillers;
      }

      var existingMemberProvider = memberProviderList.filter(
        (provider: any) => provider.abn === this.state.selectedProvider.abn,
      );

      // If user is trying to update unverified provider(member provider for that user)
      // then this If condition is working.
      // First Inactive the member provider and create a new provider relationship to that member
      if (
        !isAbnAllZeros &&
        existingMemberProvider !== null &&
        existingMemberProvider !== undefined &&
        existingMemberProvider.length > 0 &&
        existingMemberProvider[0].isVerified == false
      ) {
        const popupMessage = this._createPopupMessage(
          existingMemberProvider[0],
          currentMemberProvider,
        );

        this.setState({memberProviderExists: true});

        this._showAlert(
          types2.CHECKING,
          popupMessage,
          messages.NO,
          this._closeAlert,
          true,
          messages.YES,
          () =>
            this._updateAndAddNewMemberProvider(
              currentMemberProvider,
              existingMemberProvider,
            ),
        );
      } else {
        callAPIs(
          [
            postProvider(
              this.props.loadedMemberId.loadedMemberId,
              providerDetails,
              false,
            ),
          ],
          (data: any) => {
            if (data[0]) {
              if (data[0].content.message === `Duplicate`) {
                return;
              }
            }

            if (data[0].content.message.hasOwnProperty('billerKey')) {
              this._setNewlyAddedProvider(data[0]);
            } else {
              logger.log('billerKey  error');
              if (types.isWeb) {
                this.props.setLoading(false);
              } else {
                this.setState({loading: false});
              }
            }
          },
          (err: any) => {
            logger.log('notifications error: ', err);
            if (types.isWeb) {
              this.props.setLoading(false);
            } else {
              this.setState({loading: false});
            }
          },
        );
      }
    } else {
      if (types.isWeb) {
        this.props.setLoading(false);
      } else {
        this.setState({loading: false});
      }
    }
  };

  _showAlert(
    alertHeader: any,
    alertMessage: any,
    alertButtonText: any,
    closeAction: any,
    errorPopUpVisible: any,
    saveButtonText: any,
    saveAction: any,
  ) {
    this.setState({
      alertHeader,
      alertMessage,
      alertButtonText,
      closeAction,
      errorPopUpVisible,
      saveButtonText,
      saveAction,
    });
  }
}

const mapStateToProps = (state: any) => ({
  payInvoice: state.PayInvoiceReducer,
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    payInvoiceActions: bindActionCreators(PayInvoiceActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(AddProvider);
