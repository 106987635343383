/*
 * Authour: Missaka Herath
 * Date: 03/04/2024
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is the reducer for payment history for redux
 */

import * as types from '../Constants/Constants';

export default function PaymentHistoryReducer(
  state = {},
  action: any,
) {
  switch (action.type) {
    case types.SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.payload,
      };
    case types.SET_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.payload,
      };
    case types.SET_PAYMENT_HISTORY_DATA:
      return {
        ...state,
        paymentHistoryData: action.payload,
      };
    default:
      return state;
  }
}
