/*
 * Author: Shelan Cooray
 * Date: 11/12/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Screen for display details for completed goal including goal progress
 */

import React, {Component} from 'react';
import {View} from 'react-native';
//import {NavigationActions} from 'react-navigation-awseeley--enhanced';
import {SummaryTile, Container} from '../Components/Molecules';
import {StandardText} from '../Components/Atoms';
import {GoalProgressList} from '../Components/Organisms';
import {SCREEN_TYPE_MAIN, ACCESS_FULL, isWeb} from '../Constants/Constants';
import {CommonActions, StackActions} from '@react-navigation/native';
import {
  GOAL_SCREEN_LABELS,
  DATE_FORMATS,
  NAVIGATION_TEXT,
} from '../Constants/Constants2';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {callAPIs} from '../API/APICaller';
import {putGoalForPlan} from '../API/PlanDataAPI';
import {getValue} from '../API/WebPersistenceStore';

import * as GoalActions from '../Actions/GoalActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
// #TODO - SHELAN incoperate Uncomplete icon when vector icon available
import {addIcon} from '../assets/images/vector-icons';

import {ThumbsUpRed} from '../assets/images';

import {formatDDMMMYYYYDate} from '../Helpers/Utils';

class CompletedGoal extends Component {
  state = {
    isLoading: false,
    completedGoalObject: this.props.navigationParams.params
      ? this.props.navigationParams.params.completedGoalObject
      : JSON.parse(getValue('completedGoalObject')),
  };

  constructor(props: any) {
    super(props);
  }

  render() {
    if (isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        needsSidebar={false}
        ignorePadding={true}
        screenType={SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        loading={this.state.isLoading}
        headerTitle={NAVIGATION_TEXT.COMPLETED_GOAL}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        toggleMenu={this.props.screenProps?.toggleMenu}
        currentScreenName={GOAL_SCREEN_LABELS.COMPLETED_GOAL}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _renderGoalSummary = () => {
    return (
      <View style={[CommonStyles.marginTop10]}>
        <StandardText
          style={[
            CommonStyles.customFontBold,
            isWeb ? CommonStyles.font17 : CommonStyles.font20,
            BrandStyles.TextColor5,
          ]}
        >
          {GOAL_SCREEN_LABELS.MY_GOAL_WAS}
        </StandardText>
        <StandardText
          style={[
            CommonStyles.customFont,
            isWeb ? CommonStyles.font15 : CommonStyles.font18,
            BrandStyles.TextColor5,
            CommonStyles.marginTop10,
          ]}
        >
          {this.state.completedGoalObject.goalName}
        </StandardText>
        <StandardText
          style={[
            CommonStyles.customFont,
            isWeb ? CommonStyles.font15 : CommonStyles.font18,
            CommonStyles.marginTop10,
            BrandStyles.TextColor10,
            CommonStyles.italicFont,
          ]}
        >
          {GOAL_SCREEN_LABELS.COMPLETED +
            formatDDMMMYYYYDate(
              this.state.completedGoalObject.goalCompletedDate,
              DATE_FORMATS.TYPE_TWO,
            )}
        </StandardText>
      </View>
    );
  };

  _renderGoalProgress = () => {
    const currenGoalProgress = this.props.goals.currenGoalProgress
      ? this.props.goals.currenGoalProgress
      : JSON.parse(getValue('currentGoalProgress'));
    if (currenGoalProgress.length > 0) {
      return <GoalProgressList goalProgressList={currenGoalProgress} />;
    } else {
      return null;
    }
  };

  _createMainContents = () => {
    return (
      <View
        style={[
          CommonStyles.content,
          CommonStyles.marginTop10,
          CommonStyles.marginLeft10,
          CommonStyles.marginRight10,
        ]}
      >
        {/* #TODO - SHELAN incoperate Uncomplete icon when vector icon available}*/}
        <SummaryTile
          headerDisplayString={GOAL_SCREEN_LABELS.COMPLETED_GOAL}
          imageIcon={true}
          headerIconImage={ThumbsUpRed}
          HeaderTextColor={BrandStyles.TextColor3}
          HeaderBoarderColor={BrandStyles.borderColor3}
          hideYellowStar={true}
          showButton={this._determineAccess()}
          showButtonTitle={GOAL_SCREEN_LABELS.UNCOMPLETE}
          modal={() => this._unCompleteGoal()}
          fontSize={isWeb ? CommonStyles.font20 : CommonStyles.rpfont20}
          disableMore={true}
          iconStyle={[CommonStyles.titleGoalSection]}
        />
        {this._renderGoalSummary()}
        {this._renderGoalProgress()}
      </View>
    );
  };

  // Function for the Uncomplete button action
  _unCompleteGoal = () => {
    if (isWeb) {
      this.props.setLoading(true);
    } else {
      this.setState({isLoading: true});
    }

    const goalObject = this.state.completedGoalObject;
    const requestObject = {
      id: this.props.loadedMemberId.loadedMemberId,
      planId: this.props.member.planId,
      currentGoalId: goalObject.goalId,
      isActive: true,
      goalName: goalObject.goalName,
      listPosition: goalObject.goalListPosition,
    };

    const putGoalForPlanCallback = (data: any) => {
      const payload = data[0];
      if (payload) {
        if (isWeb) {
          this.props.setLoading(false);
          this.props.navigation.navigate('Goal', null, isWeb);
        } else {
          const actionToDispatch = CommonActions.reset({
            index: 1,
            routes: [ 
              {name: 'Home'}, 
              {name: 'Goal'}
            ],
          });

          this.setState({isLoading: false}, () => {
            this.props.navigation.dispatch(actionToDispatch);
          });
        }
      } else {
        if (isWeb) {
          this.props.setLoading(false);
        } else {
          this.setState({isLoading: false});
        }
      }
    };

    callAPIs(
      [putGoalForPlan(requestObject, this.props.user.demoProfile)],
      putGoalForPlanCallback,
      null,
      () => {
        if (isWeb) {
          this.props.setLoading(false);
        } else {
          this.setState({isLoading: false});
        }
      },
    );
  };

  _determineAccess = () => {
    if (this.props.member.access !== undefined) {
      return this.props.member.access.profile === ACCESS_FULL ? true : false;
    }
  };
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  loadedMemberId: state.LoadedMemberReducer,
  goals: state.GoalReducer,
  navigationParams: state.NavigationParamsReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    GoalActions: bindActionCreators(GoalActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CompletedGoal);
