/*
 * Author: Brad D'Costa
 * Date: 07/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Summary tile on the WhereILive screen for the Home Section
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View, Text} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {MESSAGES} from '../../Constants/Messages';

import {StandardText} from '../Atoms';
import {SummaryTile} from '../Molecules';

import {HomePurple3x} from '../../assets/images/vector-icons';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class WhereILiveHome extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <SummaryTile
        headerDisplayString={MESSAGES.WHERE_I_LIVE_SCREEN.HOME_TILE_TITLE}
        headerIconImage={HomePurple3x}
        modal={this.props.modal}
        containsData={this._determineContainsData()}
        actionOverride={this._determineAccess()}
        HeaderTextColor={BrandStyles.TextColor4}
        HeaderBoarderColor={BrandStyles.borderColor4}
      >
        {this._renderHome()}
      </SummaryTile>
    );
  }

  _determineAccess = () => {
    if (Object.getOwnPropertyNames(this.props.readOnly).length === 0) {
      return null;
    } else {
      let readOnly = this.props.readOnly;
      if (readOnly.access) {
        if (readOnly.access.profile) {
          if (readOnly.access.profile == types.ACCESS_READ_ONLY) {
            return types.ACTION_BUTTON.VIEW;
          }
        }
      }
    }
    return null;
  };

  _determineContainsData = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return false;
    } else {
      var checkArray = [
        this.props.reducer.homeType.type,
        this.props.reducer.livingWith,
        this.props.reducer.numberOfPeople,
      ];
      var dataCheck = false;
      for (var i = 0; i < checkArray.length; i++) {
        if (checkArray[i]) {
          if (Array.isArray(checkArray[i])) {
            if (checkArray[i].length > 0) {
              dataCheck = true;
              break;
            }
          } else {
            dataCheck = true;
            break;
          }
        }
      }
      return dataCheck;
    }
  };

  // #TODO - Remove unwanted code block
  // _determineIcon = () => {
  //   if (this._determineContainsData()) {
  //     return homeIconRed;
  //   } else {
  //     return homeIconCharcoal;
  //   }
  // };

  _renderHome = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      return (
        <View>
          <StandardText style={CommonStyles.customFont}>
            {this._renderCombinedTypeLivingWith()}
            {this._renderLivingWithCount()}
          </StandardText>
        </View>
      );
    }
  };

  _renderCombinedTypeLivingWith = () => {
    if (this._renderHomeType() == null && this._renderLivingWith() == null) {
      return null;
    } else {
      if (this._renderHomeType() != null && this._renderLivingWith() == null) {
        return (
          <StandardText
            style={[
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
            ]}
          >
            I live in a {this._renderHomeType()}.{' '}
          </StandardText>
        );
      } else if (
        this._renderHomeType() == null &&
        this._renderLivingWith() != null
      ) {
        return (
          <StandardText
            style={[
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
            ]}
          >
            I live {this._renderLivingWith()}.{' '}
          </StandardText>
        );
      } else {
        return (
          <StandardText
            style={[
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
            ]}
          >
            I live in a {this._renderHomeType()} {this._renderLivingWith()}.{' '}
          </StandardText>
        );
      }
    }
  };

  _renderHomeType = () => {
    var homeType = '';
    if (this.props.reducer.homeType.description) {
      homeType = this.props.reducer.homeType.description;
    }

    if (homeType == '') {
      return null;
    } else {
      return homeType.toLowerCase();
    }
  };

  _renderLivingWith = () => {
    var livingWith = '';
    var livingWithArray = this.props.reducer.livingWith;
    for (var i = 0; i < livingWithArray.length; i++) {
      if (livingWith != '') {
        livingWith = livingWith + ' and ' + livingWithArray[i].description;
      } else {
        livingWith += livingWithArray[i].description;
      }
    }
    if (livingWith == '') {
      return null;
    } else {
      return livingWith.toLowerCase();
    }
  };

  _renderLivingWithCount = () => {
    var livingWithCount = '';
    if (this.props.reducer.numberOfPeople != 0) {
      if (this.props.reducer.numberOfPeople == 1) {
        livingWithCount = this.props.reducer.numberOfPeople + ' person';
      } else {
        livingWithCount = this.props.reducer.numberOfPeople + ' people';
      }
    }

    if (livingWithCount == '') {
      return null;
    } else {
      return (
        <StandardText
          style={[
            CommonStyles.SummaryTileText,
            BrandStyles.TextColor5,
            CommonStyles.customFont,
          ]}
        >
          All up I live with {livingWithCount}
        </StandardText>
      );
    }
  };
}

export default WhereILiveHome;
