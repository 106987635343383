/*
 * Author: Andrew Lee
 * Date: 14/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a modal screen that will be used to enter in the additional information for the about me screen.
 * @param visible Whether the modal should be visible or not.
 * @param saveFunction The function that should executed when the save button is pressed.
 * @param closeFunction the function that should be executed when the close button is pressed.
 */

import React, {Component} from 'react';
import {
  View,
  FlatList,
  StyleSheet,
  ScrollView,
  Image,
  TextInput,
} from 'react-native';
import {StandardInput, StandardText} from '../Components/Atoms';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// Import messages
import {MESSAGES as messages} from '../Constants/Messages';

// Import organisms required
import {BasicModal} from '../Components/Organisms';
import {
  ModalSubtitle,
  ModalTitle,
  SummaryTileHeader,
} from '../Components/Molecules';

// Import images
import {
  impactDisabilityCommunication,
  impactDisabilityConnect,
  impactDisabilityDailyLiving,
  impactDisabilityIndependence,
  impactDisabilityLearning,
} from '../assets/images/vector-icons';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

class AboutMeAdditionalInfo extends Component {
  state = {
    dailyLiving: '',
    communication: '',
    connectingWithOthers: '',
    learning: '',
    independence: '',
  };

  // Create an array of objects that can be looped to create the inputs that are needed
  additionalInfo = [
    {
      title: messages.MORE_ABOUT_ME.DAILY_LIVING_TITLE,
      subtitle: messages.MORE_ABOUT_ME.DAILY_LIVING_SUBTITLE,
      icon: impactDisabilityDailyLiving,
      value: () => {
        return this.state.dailyLiving;
      },
      onChangeFunction: (dailyLiving: any) => {
        this.setState({dailyLiving: dailyLiving});
      },
    },
    {
      title: messages.MORE_ABOUT_ME.COMMUNICATION_TITLE,
      subtitle: messages.MORE_ABOUT_ME.COMMUNICATION_SUBTITLE,
      icon: impactDisabilityCommunication,
      value: () => {
        return this.state.communication;
      },
      onChangeFunction: (communication: any) => {
        this.setState({communication});
      },
    },
    {
      title: messages.MORE_ABOUT_ME.CONNECTING_TITLE,
      subtitle: messages.MORE_ABOUT_ME.CONNECTING_SUBTITLE,
      icon: impactDisabilityConnect,
      value: () => {
        return this.state.connectingWithOthers;
      },
      onChangeFunction: (connectingWithOthers: any) => {
        this.setState({connectingWithOthers});
      },
    },
    {
      title: messages.MORE_ABOUT_ME.LEARNING_TITLE,
      subtitle: messages.MORE_ABOUT_ME.LEARNING_SUBTITLE,
      icon: impactDisabilityLearning,
      value: () => {
        return this.state.learning;
      },
      onChangeFunction: (learning: any) => {
        this.setState({learning});
      },
    },
    {
      title: messages.MORE_ABOUT_ME.INDEPENDENCE_TITLE,
      subtitle: messages.MORE_ABOUT_ME.INDEPENDENCE_SUBTITLE,
      icon: impactDisabilityIndependence,
      value: () => {
        return this.state.independence;
      },
      onChangeFunction: (independence: any) => {
        this.setState({independence});
      },
    },
  ];

  constructor(props: any) {
    super(props);
    this._createInput = this._createInput.bind(this);
    this._createAllInputs = this._createAllInputs.bind(this);
    this._closeModal = this._closeModal.bind(this);
    this._saveModal = this._saveModal.bind(this);
  }

  componentDidMount() {
    this._setModalState();
  }

  render() {
    return (
      <BasicModal
        visible={this.props.visible}
        save={this._saveModal}
        close={this._closeModal}
        loading={this.props.loading}
        saveApiError={this.props.saveApiError}
        saveApiErrorCode={this.props.saveApiErrorCode}
        readOnly={this.props.member.access.profile}
      >
        <ModalTitle
          style={[CommonStyles.customFontBold]}
          text={messages.MORE_ABOUT_ME.TITLE}
        />
        <ModalSubtitle
          fontStyle={CommonStyles.customFont}
          text={[messages.MORE_ABOUT_ME.MODAL_SUBTEXT]}
        />
        <StandardText
          style={[
            CommonStyles.customFont,
            BrandStyles.borderColor5,
            BrandStyles.TextColor6,
            CommonStyles.AboutMeAdditionalInfoText,
          ]}
        >
          {messages.MORE_ABOUT_ME.CHALLENGE_TEXT1}
        </StandardText>
        <ModalSubtitle
          fontStyle={CommonStyles.customFont}
          text={[
            messages.MORE_ABOUT_ME.CHALLENGE_TEXT2,
            messages.MORE_ABOUT_ME.CHALLENGE_TEXT3,
            messages.MORE_ABOUT_ME.CHALLENGE_TEXT4,
            messages.MORE_ABOUT_ME.CHALLENGE_TEXT5,
          ]}
        />
        {this._createAllInputs()}
      </BasicModal>
    );
  }

  _createAllInputs = () => {
    let modalJSX: any = [];
    let counter = 0;
    // Loop through the additional info array
    this.additionalInfo.map(additionalInfoItem => {
      modalJSX.push(
        this._createInput(
          counter,
          additionalInfoItem.title,
          additionalInfoItem.subtitle,
          additionalInfoItem.icon,
          additionalInfoItem.value,
          additionalInfoItem.onChangeFunction,
        ),
      );

      counter++;
    });

    return modalJSX;
  };

  _createInput(
    counter: any,
    headerTitle: any,
    subtitle: any,
    icon: any,
    value: any,
    onChangeFunction: any,
  ) {
    return (
      <View key={counter}>
        <SummaryTileHeader
          displayString={headerTitle}
          accessibilityLabel={headerTitle}
          imageSource={icon}
        />
        <ModalSubtitle fontStyle={CommonStyles.customFont} text={[subtitle]} />
        <StandardInput
          readOnly={this.props.member.access.profile}
          multiline={true}
          numberOfLines={6}
          value={value()}
          onChangeText={(text: any) => onChangeFunction(text)}
          underlineColorAndroid="transparent"
          style={[
            CommonStyles.ModalTextInputWrapper,
            BrandStyles.primaryBgColor1,
            CommonStyles.textAlignVerticalTop,
            CommonStyles.TextWrapperHeight50,
          ]}
          blurOnSubmit={false}
        />
      </View>
    );
  }

  _saveModal = () => {
    // Get the entire member object
    let tempMemberObject = {};

    // Update the member object
    tempMemberObject.id = this.props.member.id;
    tempMemberObject.dailyLiving = this.state.dailyLiving;
    tempMemberObject.communication = this.state.communication;
    tempMemberObject.connectingWithOthers = this.state.connectingWithOthers;
    tempMemberObject.learning = this.state.learning;
    tempMemberObject.independence = this.state.independence;

    // Call the saving function
    this.props.saveFunction(tempMemberObject);
  };

  _closeModal = () => {
    // Closing the modal - reset the state of the modal to be from the member reducer
    this._setModalState();

    // Call the passed in close modal button
    this.props.closeFunction();
  };

  _setModalState = () => {
    this.setState({
      dailyLiving: this.props.member.dailyLiving,
      communication: this.props.member.communication,
      connectingWithOthers: this.props.member.connectingWithOthers,
      learning: this.props.member.learning,
      independence: this.props.member.independence,
    });
  };
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
});

export default connect(mapStateToProps)(AboutMeAdditionalInfo);
