/*
 * Author: Shelan Cooray
 * Date: 10/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is the form that will be used for the user to be able to select disabilities that affect their lives.
 * It will use an auto complete library to help display a searchable list of items that can be selected. Tapping on the list item will select the item (populate the input field) and then selecting the add will add to the list.
 */

import React, {Component} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import {StandardInput, StandardText} from '../Components/Atoms';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

// Import messages
import {DISABILITY as messages} from '../Constants/Messages';

// Import organisms required
import {BasicForm} from '../Components/Organisms';
import {
  FormSubtitle,
  FormTitle,
  Container,
  AutoComplete,
  AboutMeDisabilitySelected,
} from '../Components/Molecules';
import {StandardTouchableOpacity} from '../Components/Atoms';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {DisabilityPurple3x} from '../assets/images/vector-icons';

import {callAPIs} from '../API/APICaller';
import {getDisabilities} from '../API/ReferenceAPI';
import {updateMember, getMember} from '../API/MemberAPI';

import * as MemberActions from '../Actions/MemberActions';
import * as GeneralActions from '../Actions/GeneralActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {BrandColors} from '../Styles/Colours';
import { DOMPurify } from 'helpers/dompurify';
class AboutMeDisabilityForm extends Component {
  state = {
    loading: true,
    disabilitiesRef: [], // This is the reference data for all the disabilities
    disabilitiesSelected: [],
    customDisabilitiesSelected: [],
    initialFormState: {},
    displayDisabilities: [],
    disabilitesMoreInfo: '',
    saveApiError: false,
    saveApiErrorCode: null,
    searchText: '',
    keyboardDismissMode: 'none',
    refreshing: false,
  };

  constructor(props: any) {
    super(props);

    this._fetchDisabilityData.bind(this);
    this._fetchDisabilityCallback.bind(this);
    this._saveDisabilityModal = this._saveDisabilityModal.bind(this);
    this._closeDisabilityModal = this._closeDisabilityModal.bind(this);
    this._searchDisabilities.bind(this);
    this._renderSearchItem.bind(this);
    this._removeSelectedDisability.bind(this);
    this._addDisability.bind(this);
    this._createMainContents.bind(this);
    this.fetchData.bind(this);
  }

  componentDidMount() {
    // Fetch the reference data as it's mounting
    this.setState({loading: true});
    this._fetchDisabilityData();
    this.fetchData();
  }

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }

    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.DISABILITY}
        hasFormContentChanged={this._hasFormContentChanged}
        currentScreenName={AboutMeDisabilityForm.name}
        onRefresh={() => {
          this.setState({refreshing: true});
          this.fetchData();
        }}
        refreshing={this.state.refreshing}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  fetchData = () => {
    const callbackFunction = (data: any) => {
      const member = data[0].content;
      this.props.actions.MemberActions.actionGetMember(
        member,
        this.props.user.id,
        this.props.user.isEmployee,
        this.props.user.isFinancialStaff,
      );

      this._setDisabilityState();
      this.setState({loading: false, refreshing: false});
    };
    callAPIs(
      [
        getMember(
          this.props.loadedMemberId.loadedMemberId,
          this.props.user.demoProfile,
        ),
      ],
      callbackFunction,
      null,
      () => {
        this.setState({loading: false, refreshing: false});
      },
    );
  };

  _createMainContents = () => {
    const filteredDisabilities = this._searchDisabilities();

    return (
      <BasicForm
        headerDisplayString={messages.SUMMARY_TITLE}
        headerIconImage={DisabilityPurple3x}
        readOnly={this.props.member.access.profile}
        disableMore={true}
        close={this._closeDisabilityModal}
        save={this._saveDisabilityModal}
        hideActionButton={true}
        buttonsPositionTop={true}
        contentWidthFull={true}
      >
        <View>
          <FormTitle
            text={messages.MODAL_TITLE}
            containsData={this._onDisabilityChancge(
              this.state.disabilitiesSelected,
              this.state.customDisabilitiesSelected,
            )}
          />
          <FormSubtitle text={[messages.MY_DISABILITY]} />
          <View style={CommonStyles.autoCompleteZindex}>
            <AutoComplete
              readOnly={this.props.member.access.profile}
              data={filteredDisabilities}
              listStyle={[CommonStyles.content, styles.listContainer]}
              onChangeText={(text: any) => {
                const sanitizedText = DOMPurify.sanitize(text);

                this.setState({searchText: sanitizedText})}}
              returnKeyType={'done'}
              onSubmitEditing={() => {
                if (this.state.searchText.length > 0) {
                  this._addCustomDisability(this.state.searchText);
                }
              }}
              renderItem={(item: any) => this._renderSearchItem(item)}
              style={[
                CommonStyles.ModalTextInputWrapper,
                CommonStyles.StandardFontSize,
                styles.inputPadding,
                CommonStyles.marginBottom5,
              ]}
              value={this.state.searchText}
              underlineColorAndroid="transparent"
              customButton={true}
              addCustom={this._addCustomDisability}
              onFocus={() => {
                // Since the focus is on the auto complete, any drag should dismiss the keyboard
                this.setState({keyboardDismissMode: 'on-drag'});
              }}
              onBlur={() => {
                // No longer focused on the auto complete, remove the dismiss mode
                this.setState({keyboardDismissMode: 'none'});
              }}
            />
            {this._renderSelectedDisabilities()}
          </View>
          <View>
            <FormTitle
              text={messages.MORE_INFO_TITLE}
              containsData={this.state.disabilitesMoreInfo}
            />
          </View>
          <FormSubtitle text={[messages.MORE_INFO_SUB_TITLE]} />
          <StandardInput
            readOnly={this.props.member.access.profile}
            multiline={true}
            value={this.state.disabilitesMoreInfo}
            onChangeText={(text: any) =>{
              const sanitizedText = DOMPurify.sanitize(text);

              this.setState({disabilitesMoreInfo: sanitizedText})
            }}
            underlineColorAndroid="transparent"
            style={[
              CommonStyles.ModalTextInputWrapperAutoHeight,
              BrandStyles.primaryBgColor1,
              CommonStyles.textAlignVerticalTop,
              types.isWeb ? CommonStyles.font15 : {},
            ]}
            blurOnSubmit={false}
            minHeight={150}
          />
        </View>
      </BasicForm>
    );
  };

  _onDisabilityChancge = (disability: any, customDisability: any) => {
    let disabilityArray = disability.concat(customDisability);
    if (Array.isArray(disabilityArray) && disabilityArray.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  _searchDisabilities = () => {
    let searchTerm = this.state.searchText;
    if (searchTerm === '' || searchTerm.length < 2) {
      return [];
    }

    // Need to search through the disabilities on their type
    const {disabilitiesRef} = this.state;

    return disabilitiesRef.filter(disability =>
      disability.type.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  };

  _renderSearchItem = (item: any) => {
    return (
      <StandardTouchableOpacity
        accessibilityLabel={item.type + ' selected'}
        key={item.id}
        style={[styles.listItem]}
        onPress={() => {
          this._addDisability(item);
        }}
      >
        <StandardText
          style={[CommonStyles.customFont, CommonStyles.StandardFontSize]}
        >
          {item.type}
        </StandardText>
      </StandardTouchableOpacity>
    );
  };

  _renderSelectedDisabilities = () => {
    // Go through the list of selected disabilities to render on screen
    const {
      disabilitiesSelected,
      customDisabilitiesSelected,
      displayDisabilities,
    } = this.state;

    let disabilitesSelectedJSX: any = [];

    let indexCounter = 0;

    displayDisabilities.map(disability => {
      disabilitesSelectedJSX.push(
        <AboutMeDisabilitySelected
          key={indexCounter}
          index={indexCounter}
          disability={disability}
          removeDisability={this._removeSelectedDisability}
          readOnly={this.props.member.access.profile}
        />,
      );
      indexCounter++;
    });

    return (
      <View
        style={[styles.selectedDisabilityPadding, CommonStyles.DisabilityList]}
      >
        {(disabilitiesSelected.length > 0 ||
          customDisabilitiesSelected.length > 0) &&
          disabilitesSelectedJSX}
      </View>
    );
  };

  _removeSelectedDisability = (disabilityType: any) => {
    let tempSelectedDisabilities = JSON.parse(
      JSON.stringify(this.state.disabilitiesSelected),
    ).filter(function (disability: any) {
      return disability.type != disabilityType;
    });

    let tempSelectedCustomDisabilities = JSON.parse(
      JSON.stringify(this.state.customDisabilitiesSelected),
    ).filter(function (disability: any) {
      return disability.type != disabilityType;
    });

    let tempDisplayDisabilities = JSON.parse(
      JSON.stringify(this.state.displayDisabilities),
    ).filter(function (disability: any) {
      return disability.type != disabilityType;
    });

    // Update the state
    this.setState({
      disabilitiesSelected: tempSelectedDisabilities,
      customDisabilitiesSelected: tempSelectedCustomDisabilities,
      displayDisabilities: tempDisplayDisabilities,
    });
  };

  _fetchDisabilityData = () => {
    // Check to see if there is cached items in redux
    const disabilitiesRef = this.props.general.disabilitiesReference;
    if (disabilitiesRef && disabilitiesRef.disabilities) {
      this.setState({
        disabilitiesRef: disabilitiesRef.disabilities,
      });
    }
    // No cache in redux, so fetch the data from lambda.
    else {
      callAPIs([getDisabilities()], this._fetchDisabilityCallback, null, () => {
        this.setState({loading: false});
      });
    }
  };

  _addDisability = (item: any) => {
    let selectedDisabilites = this.state.disabilitiesSelected;
    let displayDisabilities = this.state.displayDisabilities;

    // Ensure that the already selected disabilities do not contain the selected item
    let filteredDisabilities = selectedDisabilites.filter(disability => {
      return disability.id == item.id;
    });

    // Check to see if there is an element that already has the same id, and if so, do not add it to the list.
    if (filteredDisabilities.length > 0) {
      this.setState({
        searchText: '',
      });
    }
    // Otherwise it is a new item, so add the item.
    else {
      if (!selectedDisabilites.some(eachItem => eachItem.type == item.type)) {
        selectedDisabilites.unshift(item);
      }
      if (!displayDisabilities.some(eachItem => eachItem.type == item.type)) {
        displayDisabilities.unshift(item);
      }
      this.setState({
        disabilitiesSelected: selectedDisabilites,
        searchText: '',
        selectedDisabilites: selectedDisabilites,
      });
    }
  };
  _addCustomDisability = (item: any) => {
    item = {type: item};

    let selectedDisabilites = this.state.customDisabilitiesSelected;
    let displayDisabilities = this.state.displayDisabilities;

    // Ensure that the already selected disabilities do not contain the selected item
    let filteredDisabilities = selectedDisabilites.filter(disability => {
      return disability.type == item.type;
    });

    // Check to see if there is an element that already has the same id, and if so, do not add it to the list.
    if (filteredDisabilities.length > 0) {
      this.setState({
        searchText: '',
      });
    }
    // Otherwise it is a new item, so add the item.
    else {
      if (!selectedDisabilites.some(eachItem => eachItem.type == item.type)) {
        selectedDisabilites.unshift(item);
      }
      if (!displayDisabilities.some(eachItem => eachItem.type == item.type)) {
        displayDisabilities.unshift(item);
      }
      this.setState({
        customDisabilitiesSelected: selectedDisabilites,
        searchText: '',
        selectedDisabilites: selectedDisabilites,
      });
    }
  };

  _fetchDisabilityCallback = (data: any) => {
    const disabilityContent = data[0].content.disabilities;

    // Get the disability reference data from the content and save it to redux
    this.props.actions.GeneralActions.getDisabilityReferenceData(
      disabilityContent,
    );
    this.setState({
      disabilitiesRef: disabilityContent,
    });
  };

  _setDisabilityState = () => {
    // Deep clone the member's selected disabilities, so that when changing the state, it will not modify the redux model.
    let selectedDisabilites = [];
    if (this.props.member.disabilities) {
      selectedDisabilites = JSON.parse(
        JSON.stringify(this.props.member.disabilities),
      );
    }

    // Show added custom disabilities in disability modal
    let selectedCustomDisabilites = [];
    if (this.props.member.customDisabilities) {
      selectedCustomDisabilites = JSON.parse(
        JSON.stringify(this.props.member.customDisabilities),
      );
    }

    this.setState({
      loading: false,
      disabilitiesSelected: selectedDisabilites,
      customDisabilitiesSelected: selectedCustomDisabilites,
      initialFormState: {
        disabilitiesSelected: JSON.parse(JSON.stringify(selectedDisabilites)),
        customDisabilitiesSelected: JSON.parse(
          JSON.stringify(selectedCustomDisabilites),
        ),
        disabilitesMoreInfo: JSON.parse(
          JSON.stringify(this.props.member.disabilityMoreInfo),
        ),
        searchText: this.state.searchText,
      },
      displayDisabilities: selectedDisabilites.concat(
        selectedCustomDisabilites,
      ),
      disabilitesMoreInfo: this.props.member.disabilityMoreInfo,
    });
  };

  _hasFormContentChanged = () => {
    let initialFormState = this.state.initialFormState;
    let currentFormState = {
      disabilitiesSelected: this.state.disabilitiesSelected,
      customDisabilitiesSelected: this.state.customDisabilitiesSelected,
      disabilitesMoreInfo: this.state.disabilitesMoreInfo,
      searchText: this.state.searchText,
    };
    return (
      JSON.stringify(initialFormState) !== JSON.stringify(currentFormState)
    );
  };

  _closeDisabilityModal = () => {
    this.props.navigation.goBack();
  };

  _saveDisabilityModal = () => {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({loading: true});

    const {
      disabilitiesSelected,
      customDisabilitiesSelected,
      disabilitesMoreInfo,
    } = this.state;

    // Need to change the disabilities selected into an array of just ids
    let disabilityIds = disabilitiesSelected.map(disabilityItem => {
      return disabilityItem.id;
    });
    let customDisabilities = customDisabilitiesSelected.map(disabilityItem => {
      return disabilityItem.type;
    });

    let memberUpdateObject = {
      id: this.props.member.id,
      disabilities: disabilityIds,
      customDisabilities: customDisabilities,
      disabilityMoreInfo: disabilitesMoreInfo,
    };

    let updateMemberCallback = (data: any) => {
      let clonedDisabilities = JSON.parse(JSON.stringify(disabilitiesSelected));
      let clonedCustomDisabilities = JSON.parse(
        JSON.stringify(customDisabilitiesSelected),
      );

      this.props.actions.MemberActions.actionUpdateMember({
        disabilities: clonedDisabilities,
        disabilityMoreInfo: disabilitesMoreInfo,
        customDisabilities: clonedCustomDisabilities,
      });
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({loading: false});
      this.props.navigation.goBack();
    };

    const updateMemberErrorCallback = (err: any) => {
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
        loading: false,
      });
    };

    callAPIs(
      [updateMember(memberUpdateObject, this.props.user.demoProfile, false)],
      updateMemberCallback,
      updateMemberErrorCallback,
    );
  };
}

const styles = StyleSheet.create({
  listContainer: {
    backgroundColor: BrandColors.WHITE,
    borderWidth: 1,
    borderColor: BrandColors.BLACK,
    marginRight: 20,
  },

  listItem: {
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },

  inputPadding: {
    paddingLeft: 20,
  },

  selectedDisabilityPadding: {
    paddingTop: 5,
    paddingBottom: 30,
  },
});

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
  general: state.GeneralReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    MemberActions: bindActionCreators(MemberActions, dispatch),
    GeneralActions: bindActionCreators(GeneralActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AboutMeDisabilityForm);
