/*
 * Author: Sujeban Elankeswaran
 * Date: 07/01/2020
 * Copyright © 2020 Leap in!. All rights reserved.
 *
 * Enquiry actions for redux
 */
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const actionSetEnquiryDescription = (enquiryDescription: any) => ({
  type: types.SET_ENQUIRY_DESCRIPTION,
  enquiryDescription,
});

export const actionMemberAddressChecked = (payload: any) => ({
  type: types.SET_MEMBER_ADDRESS_CHECKED,
  payload,
});

export const actionCrewMobileNumberChecked = (payload: any) => ({
  type: types.SET_CREW_MOBILE_NUMBER_CHECKED,
  payload,
});

export const actionMemberPhoneNumberChecked = (payload: any) => ({
  type: types.SET_MEMBER_PHONE_NUMBER_CHECKED,
  payload,
});

export const actionMemberMobilePhoneChecked = (payload: any) => ({
  type: types.SET_MEMBER_MOBILE_PHONE_CHECKED,
  payload,
});

export const actionMemberDisabilitiesChecked = (payload: any) => ({
  type: types.SET_MEMBER_DISABILITIES_CHECKED,
  payload,
});

export const actionCrewRelationshipChecked = (payload: any) => ({
  type: types.SET_CREW_RELATIONSHIP_CHECKED,
  payload,
});
