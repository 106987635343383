/*
 * Author: Shelan Cooray
 * Date: 31/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Construct support summary details for each summary tile on Support Landing page.
 * Frequency, support crew, and traffic light details.
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import {StandardText} from './';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import CommonStyles from '../../Styles/CommonStyles';

class SupportTileDetails extends Component {
  constructor(props: any) {
    super(props);
    this._renderFrequency = this._renderFrequency.bind(this);
    this._renderCrew = this._renderCrew.bind(this);
  }

  _renderFrequency = () => {
    if (this.props.frequency != null) {
      return (
        <StandardText>
          <StandardText
            style={[
              CommonStyles.text_SupportContainer,
              CommonStyles.customFontBold,
            ]}
          >
            {types.FREQUENCY}:{' '}
          </StandardText>
          <StandardText
            style={[
              CommonStyles.text_SupportContainer,
              CommonStyles.customFontBold,
            ]}
          >
            {this.props.frequency}
          </StandardText>
        </StandardText>
      );
    }
  };

  _renderCrew = () => {
    if (this.props.crew != undefined && this.props.crew.length > 0) {
      let crewDisplayText = '';
      for (let i = 0; i < this.props.crew.length; i++) {
        const crewMember = this.props.crew[i];
        if (crewDisplayText != '') {
          crewDisplayText += `, `;
          crewDisplayText += this._getName(crewMember);
        } else {
          crewDisplayText += this._getName(crewMember);
        }
      }
      return (
        <StandardText>
          <StandardText
            style={[
              CommonStyles.text_SupportContainer,
              CommonStyles.customFont,
            ]}
          >
            {crewDisplayText}
          </StandardText>
        </StandardText>
      );
    }
    return null;
  };

  _getName = (crew: any) => {
    if (
      !(crew.firstName && crew.lastName) ||
      (crew.firstName == '' && crew.lastName == '')
    ) {
      return `${crew.orgName}`;
    } else {
      if (crew.orgName && crew.orgName != '') {
        return `${crew.firstName} ${crew.lastName} ${crew.orgName}`;
      } else {
        return `${crew.firstName} ${crew.lastName}`;
      }
    }
  };

  render() {
    return (
      <View
        style={[
          CommonStyles.contentWrapper,
          CommonStyles.justifyContentSpaceBetween,
        ]}
      >
        <View style={[CommonStyles.flex1]}>
          {this._renderFrequency()}
          {this._renderCrew()}
        </View>
      </View>
    );
  }
}

export default SupportTileDetails;
