/*
 * Author: Brad D'Costa
 * Date: 11/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Web Service class to handle the Member API calls
 */

// Custom functions for helping connect to the secure AWS API Gateway
import {
  initaliseAwsClient,
  configuredAWSLamda,
  buildLambda,
  parseResult,
  apiErrorHandler,
} from './APIHelper';
import AWS from 'aws-sdk';
import {AWS_LAMBDA_SERVICES} from '../environments';
import logger from 'helpers/Logger';

//* ********************************
//*       R E F E R E N C E
//* ********************************

/*
 * Returns reference data toi be used for selecting information about living arrangement
 * @async
 * @returns {Promise<object>} The reference living arrangement object
 */
export const getRefLivingArrangement = (autoHandleError = true) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getLivingReference = configuredAWSLamda();
      const requestBody = {};
      const functionName = 'getLivingReference';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.REFERENCE;
      const params = buildLambda(requestBody, functionName, serviceName);
      getLivingReference.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/*
 * Returns reference data toi be used for selecting information about living arrangement
 * @async
 * @returns {Promise<object>} The reference living arrangement object
 */
export const getRefTAndC = (callback: any) => {
  AWS.config.credentials.get((err: any) => {
    if (err) {
      logger.log('There was an error getting credentials:', err);
    } else {
      // Use the lambda function directly from the AWS sdk
      const getTAndCReference = configuredAWSLamda();
      const requestBody = {};
      const functionName = 'getTAndCReference';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.REFERENCE;
      const params = buildLambda(requestBody, functionName, serviceName);
      getTAndCReference.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
        } else {
          const result = parseResult(data);
          callback(result);
        }
      });
    }
  });
};

/*
 * Returns the Supports categories for a given Member ID
 * @async
 * @returns {Promise<object>} The Supports object
 */
export const getSupportCategories = (autoHandleError = true) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getSupportCategoryListReference = configuredAWSLamda();
      const functionName = 'getSupportCategoryListReference';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.REFERENCE;
      const params = buildLambda(null, functionName, serviceName);
      getSupportCategoryListReference.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/*
 * Returns the Supports groups for a given Member ID
 * @async
 * @returns {Promise<object>} The Support groups object
 */
export const getSupportGroupItems = (
  requestBody: any,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getGroupItemsReference = configuredAWSLamda();
      const functionName = 'getGroupItemsReference';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.REFERENCE;
      const params = buildLambda(requestBody, functionName, serviceName);
      getGroupItemsReference.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};
/*
 * Returns the Supports items for a given Member ID
 * @async
 * @returns {Promise<object>} The Support groups object
 */
export const getSupportItems = (requestBody: any, autoHandleError = true) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getSupportItemsReference = configuredAWSLamda();
      const functionName = 'getSupportItemsReference';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.REFERENCE;
      const params = buildLambda(requestBody, functionName, serviceName);
      getSupportItemsReference.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/*
 * Returns the the goal templates
 */
export const getGoalTemplateReference = (autoHandleError = true) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getGoalTemplateReference = configuredAWSLamda();
      const functionName = 'getGoalTemplateReference';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.REFERENCE;
      const params = buildLambda({}, functionName, serviceName);
      getGoalTemplateReference.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/*
 * Returns the the goal steps for a given template id
 */
export const getGoalStepReference = (
  goalTemplateId: any,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getGoalTemplateReference = configuredAWSLamda();
      const functionName = 'getGoalStepReference';
      logger.log('Lambda::', functionName);
      const requestBody = {
        templateId: goalTemplateId,
      };
      const serviceName = AWS_LAMBDA_SERVICES.REFERENCE;
      const params = buildLambda(requestBody, functionName, serviceName);
      getGoalTemplateReference.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/*
 * Returns the the goal steps for a given template id
 */
export const getGoalFiltersReference = (autoHandleError = true) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getGoalFiltersReference = configuredAWSLamda();
      const functionName = 'getGoalFiltersReference';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.REFERENCE;
      const params = buildLambda({}, functionName, serviceName);
      getGoalFiltersReference.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/*
 * Returns a list of crew members associated with the person
 * @async
 * @param {string} personId The person ID of the user you want to return crew for
 * @returns {Promise<array>} An array of crew member objects
 */
export const getCrewCategories = (personId: any, autoHandleError = true) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getCrewCategories = configuredAWSLamda();
      const requestBody = {id: personId};
      const functionName = 'getCrewCategoryReference';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.REFERENCE;
      const params = buildLambda(requestBody, functionName, serviceName);

      getCrewCategories.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/*
 * Returns the Supports categories for a given Member ID
 * @async
 * @returns {Promise<object>} The Supports object
 */
export const getLifeStages = (autoHandleError = true) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getLifeStagesReference = configuredAWSLamda();
      const functionName = 'getLifeStagesReference';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.REFERENCE;
      const params = buildLambda({}, functionName, serviceName);
      getLifeStagesReference.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/**
 * Fetches the data for the disability reference data.
 * @param {function} callback The callback when the function returns with data or error.
 */
export const getDisabilities = (autoHandleError = true) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Create the lambda function call to fetch the reference data
      const getDisabilityReference = configuredAWSLamda();
      const functionName = 'getDisabilitiesReference';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.REFERENCE;
      const params = buildLambda({}, functionName, serviceName);

      getDisabilityReference.invoke(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const getBudgetCategories = (autoHandleError = false) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // ID is an optional argument, if passed it will overwrite the exising member
      const getBudgetCategories = configuredAWSLamda();
      const functionName = 'getBudgetCategories';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.REFERENCE;
      const params = buildLambda({}, functionName, serviceName);
      getBudgetCategories.invoke(params, (err, data) => {
        if (err) {
          logger.log('The getBudgetCategories error is:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/**
 * Fetches inactivity time thresholds for each user types.
 */
export const getInactiveTimeReference = (autoHandleError = false) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Create the lambda function call to fetch the reference data
      const getInactiveTimeReference = configuredAWSLamda();
      const functionName = 'getInactiveTimeReference';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.REFERENCE;
      const params = buildLambda({}, functionName, serviceName);
      getInactiveTimeReference.invoke(params, (err, data) => {
        if (err) {
          logger.log('The getInactiveTimeReference error is:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};
