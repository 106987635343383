/*
 * Author: Declan Hart
 * Date: 16/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Display Support details
 */

import React, {Component} from 'react';
import {StyleSheet, View, Image, TextInput} from 'react-native';

import {editIcon} from '../../assets/images';
import {StandardInput, StandardText} from '../Atoms';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import { DOMPurify } from 'helpers/dompurify';

class SupportDescription extends Component {
  constructor(props: any) {
    super(props);
    this.onTextChange = this.onTextChange.bind(this);
  }

  onTextChange(description: any) {
    this.props.save(description);
  }

  render() {
    return (
      <View style={this.props.style}>
        <StandardInput
          readOnly={this.props.readOnly}
          accessibilityLabel="Support description"
          border={BrandStyles.borderColor4}
          key="description"
          value={this.props.description}
          onChangeText={(description: any) => {
            const sanitizedDescription = DOMPurify.sanitize(description);

            this.onTextChange(sanitizedDescription)}}
          blurOnSubmit
          multiline
          numberOfLines={5}
          style={[
            CommonStyles.ModalTextInputWrapperAutoHeight,
            BrandStyles.primaryBgColor1,
            CommonStyles.textAlignVerticalTop,
          ]}
          underlineColorAndroid="transparent"
          errorMessage={this.props.descriptionError}
        />
      </View>
    );
  }
}

export default SupportDescription;
