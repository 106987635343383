import {BrandColors} from './Colours';

export const GREEN_BUDGET = [
  {
    key: 'unspent',
    backgroundColor: BrandColors.SPENDBAR_GREEN.DARK,
    textColor: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },
  {
    key: 'allocated',
    backgroundColor: BrandColors.SPENDBAR_GREEN.LIGHT,
    textColor: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },
  {
    key: 'spent',
    backgroundColor: BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY,
    textColor: BrandColors.WHITE,
  },
];

export const RED_BUDGET = [
  {
    key: 'unspent',
    backgroundColor: BrandColors.SPENDBAR_RED.DARK,
    textColor: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },
  {
    key: 'allocated',
    backgroundColor: BrandColors.SPENDBAR_RED.LIGHT,
    textColor: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },
  {
    key: 'spent',
    backgroundColor: BrandColors.SPENDBAR_RED.NONE,
    textColor: BrandColors.WHITE,
  },
];

export const RED_APPROVALS = [
  {
    key: 'spent',
    backgroundColor: BrandColors.SPENDBAR_GREEN.DARK,
    textColor: BrandColors.WHITE,
  },
  {
    key: 'amount approving',
    backgroundColor: BrandColors.SPENDBAR_GREEN.LIGHT,
    textColor: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },
  {
    key: 'remaining',
    backgroundColor: BrandColors.SPENDBAR_GREEN.NONE,
    textColor: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  },
];

export const BLACK_BUDGET_TITLE = {
  budget: BrandColors.BLACK,
  funding: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
};

export const CHARCOAL_BUDGET_TITLE = {
  budget: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
  funding: BrandColors.CHARCOAL_TINT60,
};
