import React, {Component} from 'react';
import {View, Linking} from 'react-native';
//import CheckBox from 'react-native-checkbox';

import {StandardText, StandardTouchableOpacity, CheckBox} from '../Atoms';
import {UncheckedBoxPurple2x, CheckedBoxPurple2x,} from '../../assets/images';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import {isTablet} from '../../Helpers/PlatformSynchronizer';

import {
  LOGIN_SIGNUP_SCREEN,
  ACCESSIBILITY_SCREEN_TITLES,
} from '../../Constants/Messages';

import {APP_ID} from '../../environments';

import * as brand from '../../Constants/BrandConstants';

class SignUpTermsCheckBox extends Component {
  _closeAlert: any;
  _showAlert: any;
  constructor(props: any) {
    super(props);
    this._navigationCallback = this._navigationCallback.bind(this);
  }
  render() {
    let termsAndCLabel = ACCESSIBILITY_SCREEN_TITLES.NOT_SELECTED;
    if (this.props.termsandconditionsaccepted) {
      termsAndCLabel = ACCESSIBILITY_SCREEN_TITLES.SELECTED;
    }
    return (
      <View
        style={[
          CommonStyles.flex1,
          CommonStyles.alignCenter,
          CommonStyles.marginTop30,
        ]}
      >
        <View
          style={[
            CommonStyles.alignCenter,
            CommonStyles.alignSelfCenter,
            CommonStyles.flexDirectionRow,
            CommonStyles.marginBottom5,
            isTablet() ? CommonStyles.marginRightLeft2p : null,
            CommonStyles.flex1,
          ]}
        >
          <View
            style={[
              CommonStyles.termsOfuseBox,
              CommonStyles.flexDirectionRow,
              CommonStyles.flexWrap,
            ]}
            accessible={true}
            accessibilityLabel={termsAndCLabel}
          >
            <CheckBox
              checkboxStyle={
                this.props.displayTermsandCErrorMessage && [
                  CommonStyles.CheckBoxBorderWidth,
                  BrandStyles.errorBorder,
                ]
              }
              label=""
              checkedImage={CheckedBoxPurple2x}
              uncheckedImage={UncheckedBoxPurple2x}
              onChange={() => {
                this.props.saveTnCField();
              }}
              value={this.props.termsandconditionsaccepted}
            />
          </View>
          <View
            style={[
              !isTablet() ? CommonStyles.termsOfuseText : null,
              CommonStyles.flexDirectionRow,
              CommonStyles.flexWrap,
              CommonStyles.flex1,
            ]}
            accessible={true}
            accessibilityLabel={
              LOGIN_SIGNUP_SCREEN.TERMS_OF_USE_TEXT_1 +
              LOGIN_SIGNUP_SCREEN.TERMS_OF_USE_TEXT_2 +
              types2.COMPONENT_TYPES.BUTTON
            }
          >
            <StandardTouchableOpacity
              onPress={() => {
                if (types.isWeb) {
                  window.open(brand.APP_SIGNUP_TERMS_AND_CONDITIONS_URL);
                } else {
                  Linking.openURL(brand.APP_SIGNUP_TERMS_AND_CONDITIONS_URL);
                }
              }}
              accessibilityLabel={LOGIN_SIGNUP_SCREEN.TERMS_OF_USE_TEXT_2}
            >
              <View
                style={[
                  CommonStyles.flexDirectionRow,
                  CommonStyles.marginBottom5,
                  CommonStyles.flex1,
                  CommonStyles.flexWrap,
                ]}
              >
                <StandardText
                  style={[
                    BrandStyles.TextColor5,
                    types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont12,
                    CommonStyles.customFont,
                    CommonStyles.alignItemsCenter,
                  ]}
                >
                  {LOGIN_SIGNUP_SCREEN.TERMS_OF_USE_TEXT_1}
                </StandardText>
                <StandardText
                  style={[
                    BrandStyles.TextColor5,
                    CommonStyles.termsAndConditionsLine3,
                    types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont12,
                    CommonStyles.customFontBold,
                    CommonStyles.alignItemsCenter,
                  ]}
                >
                  {LOGIN_SIGNUP_SCREEN.TERMS_OF_USE_TEXT_2}
                </StandardText>
              </View>
            </StandardTouchableOpacity>
          </View>
        </View>
        <View>{this.props.displayErrorMessage && this.renderTnCError()}</View>
      </View>
    );
  }

  renderTnCError() {
    return (
      <StandardText
        accessibilityLabel={ACCESSIBILITY_SCREEN_TITLES.TERM_CONDITION}
        style={[
          CommonStyles.inputErrorMessageText,
          CommonStyles.LoginSignFS13MR20MT20PB10,
        ]}
      >
        {types.TANDC_NOT_ACCEPTED}
      </StandardText>
    );
  }

  //check for internet connectivity before navigating to Webview
  _navigationCallback = (navScreen: any) => {
    if (this.props.isConnected) {
      this.props.navigation.navigate(navScreen);
    } else {
      this._showAlert(
        types2.ALERT_TITLE,
        types2.CONNECTION_ERROR,
        types2.OK_GOT_IT,
        this._closeAlert,
        true,
        false,
      );
    }
  };
}

export default SignUpTermsCheckBox;
