/*
 * Author: Andrew Lee
 * Date: 07/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a preauthentication helper that is to make it easier for the pre-authentication to be called and then call anything following it so that it would be able to continue executing the rest of the functionality.
 */

import {Alert, BackHandler, Platform} from 'react-native';

import {getGoogleSignin} from '../Helpers/PlatformUtils';

import {RNkeychain as Keychain} from '../Helpers/PlatformSynchronizer';

import * as env from '../environments';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {
  ERRORS as errorMessages,
  COMMON_BUTTONS as buttonText,
} from '../Constants/Messages';

import {store} from '../App';
import {getData} from './StorageHelper';
import logger from 'helpers/Logger';

import {
  loginExistingGoogle,
  loginExistingFacebook,
  loginCognito,
  logoutCognito,
  logoutFacebook,
  logoutGoogle,
} from './AuthenticationAPI';
import {actionLoginSuccess} from '../Actions/AuthenticationActions';

import {removeItem} from '../API/WebPersistenceStore';

export const preAuthenticate = (
  updateAutoLoginFlag: any,
  completeHandler: any,
  handleError = false,
) => {
  const checkedUpdateAutoLoginFlag = () => {
    if (updateAutoLoginFlag) {
      updateAutoLoginFlag();
    }
  };

  // If the user has logged in before we store how they logged in (Email, Facebook, Google)
  // Under the login last type key. Based on this we then check for a valid login
  // And take the user to their profile
  getData(types.LOGIN_LAST_TYPE)
    .then(loginType => {
      switch (loginType) {
        // The user is logged in using Facebook
        case types.LOGIN_TYPE_FACEBOOK:
          // Check for existing Facebook login
          // AccessToken.getCurrentAccessToken().then(data => {
          // Only if there is a token we login
          const data = undefined;
          if (data) {
            // Check for expiry & token data exists. FB SDK refreshes the token automatically for us.
            const expiryDate = new Date(data.expirationTime);
            const today = new Date();

            if (expiryDate > today) {
              // We have a signed in Facebook User. Log them into AWS so we can call the secure APIs.
              // Populate redux with their login info so we can access later and redirect to landing page.
              loginExistingFacebook(data)
                .then(response => {
                  store.dispatch(
                    actionLoginSuccess(
                      types.LOGIN_TYPE_FACEBOOK,
                      response.accessToken,
                      response.awsIdentity,
                    ),
                  );
                  checkedUpdateAutoLoginFlag();
                  completeHandler();
                })
                .catch(error => {
                  // If we have no internet connection or AWS is down just continue but dont set the ID.
                  logger.log('loginExitingFacebook error: ', error);
                  store.dispatch(
                    actionLoginSuccess(
                      types.LOGIN_TYPE_FACEBOOK,
                      data.accessToken,
                      '',
                    ),
                  );
                  logger.log('existing Facebook Login, but Offline');
                  retryHandler(error, updateAutoLoginFlag, completeHandler);
                });
            }
          } else {
            retryHandler({code: 404}, updateAutoLoginFlag, completeHandler);
          }
          // });
          break;
        // The user is logged in using Google
        case types.LOGIN_TYPE_GOOGLE:
          // This prevents jest from throwing an error when using jest. It returns undefined from the Google sign on library otherwise.
          if (Platform.OS === types2.ANDROID) {
            // Configure our GoogleSignIn.
            getGoogleSignin()
              .configure({
                webClientId: env.GOOGLE_WEB_CLIENT_ID,
              })
              .then(() => {
                // Check of the use has logged in using google sign in
                getGoogleSignin()
                  .currentUserAsync()
                  .then((user: any) => {
                    if (user) {
                      // We have a signed in Google User. Log them into AWS so we can call the secure APIs.
                      // Populate redux with their login info so we can access later and redirect to landing page.
                      loginExistingGoogle(user)
                        .then(response => {
                          store.dispatch(
                            actionLoginSuccess(
                              types.LOGIN_TYPE_GOOGLE,
                              response.googleToken,
                              response.awsIdentity,
                            ),
                          );
                          checkedUpdateAutoLoginFlag();
                          completeHandler();
                        })
                        .catch(error => {
                          // If we have no internet connection or AWS is down just continue but dont set the ID.
                          logger.log('loginExitingGoogle error', error);
                          store.dispatch(
                            actionLoginSuccess(
                              types.LOGIN_TYPE_GOOGLE,
                              user.accessToken,
                              '',
                            ),
                          );
                          logger.log('existing Google Login, but Offline');
                          retryHandler(
                            error,
                            updateAutoLoginFlag,
                            completeHandler,
                          );
                        });
                    } else {
                      logger.log('No User exists');
                      retryHandler(
                        {code: 404},
                        updateAutoLoginFlag,
                        completeHandler,
                      );
                    }
                  })
                  .done();
              })
              .catch((error: any) => {
                // There was some sort of errror so don't login and log it to the console.
                logger.log('Google Sign In Config Error ', error.name);
                retryHandler(error, updateAutoLoginFlag, completeHandler);
              });
          }
          break;
        // The user is logged in using Email
        case types.LOGIN_TYPE_EMAIL:
          // Load the user's login details from the encrypted keychain
          if (!types.isWeb) {
            Keychain.getInternetCredentials(types.COGNITO_KEY).then(
              credentials => {
                if (credentials) {
                  // We have a signed in Cognito User. Log them into AWS so we can call the secure APIs.
                  // Populate redux with their login info so we can access later and redirect to landing page.
                  loginCognito(credentials.username, credentials.password)
                    .then(response => {
                      store.dispatch(
                        actionLoginSuccess(
                          types.LOGIN_TYPE_EMAIL,
                          response.cognitoIdToken,
                          response.awsIdentity,
                        ),
                      );
                      logger.log('existing Cognito Login');
                      checkedUpdateAutoLoginFlag();
                      completeHandler();
                    })
                    .catch(error => {
                      // There was some sort of errror so don't login and log it to the console.
                      logger.log('There was an error ', error.code);
                      retryHandler(error, updateAutoLoginFlag, completeHandler);
                    });
                } else {
                  retryHandler(
                    {code: 404},
                    updateAutoLoginFlag,
                    completeHandler,
                  );
                }
              },
            );
          }

          break;
      }
    })
    .catch(error => {
      // Check the error, if the error is LOGIN_NO_TYPE, do nothing
      if (error && error.code != types.LOGIN_NO_TYPE) {
        retryHandler(error, updateAutoLoginFlag, completeHandler);
      } else {
        // Continue since the error is either null, or is login no type
        checkedUpdateAutoLoginFlag();
        completeHandler();
      }
    });
};

export const checkInactiveTime = (
  timeOutThreshold = types.USER_INACTIVITY_TIMEOUT,
) =>
  new Promise((resolve, reject) => {
    getData(types.LAST_ACTIVE_TIME)
      .then(result => {
        const lastActiveTime = parseInt(result);
        const currentTime = new Date().getTime();
        // Inactive time limit is reached when last active time is compared to current time
        // There for force logout the user
        if (lastActiveTime && currentTime - lastActiveTime > timeOutThreshold) {
          resolve(true);
        } else {
          resolve(false);
        }
      })
      .catch(error => {
        reject(error);
      });
  });

export const logUserOut = () =>
  new Promise((resolve, reject) => {
    getData(types.LOGIN_LAST_TYPE)
      .then(loginType => {
        switch (loginType) {
          // Based on how their logged in call the appropiate logout function
          case types.LOGIN_TYPE_FACEBOOK:
            logoutFacebook();
            resolve();
            break;
          case types.LOGIN_TYPE_GOOGLE:
            logoutGoogle()
              .then(() => {
                resolve();
              })
              .catch(error => {
                Alert.alert(types2.ERROR, error);
              });
            break;
          case types.LOGIN_TYPE_EMAIL:
            if (types.isWeb) {
              removeItem('cognitoId');
              removeItem('awsIdentity');
              removeItem('loadedMemberId');
              removeItem(types2.WEB_STORE.CURRENT_MENU);
            }
            logoutCognito()
              .then(result => {
                resolve();
              })
              .catch(error => {
                Alert.alert(types2.ERROR, error);
              });
            break;
          default:
            resolve();
            break;
        }
      })
      .catch(error => {
        resolve(error);
      });
  });

const retryHandler = (
  err: any,
  updateAutoLoginFlag: any,
  completeHandler: any,
) => {
  logger.log(err);
  updateAutoLoginFlag();
  completeHandler();
};
