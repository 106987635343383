/*
 * Author: Shelan Cooray
 * Date: 13/12/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The screen to display and enter goal progress
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import {SCREEN_TYPE_MAIN, ACCESS_FULL, isWeb} from '../Constants/Constants';
import {NAVIGATION_TEXT, GOAL_SCREEN_LABELS} from '../Constants/Constants2';
import {
  GoalProgressPreviewSummary,
  GoalProgressTracker,
  GoalProgressList,
} from '../Components/Organisms';
import {SummaryTile, Container} from '../Components/Molecules';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {callAPIs} from '../API/APICaller';
import {postGoalProgress, getGoalProgress} from '../API/PlanDataAPI';
import {getValue} from '../API/WebPersistenceStore';

import * as GoalActions from '../Actions/GoalActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {goalsIconRed} from '../assets/images/vector-icons';

class GoalProgress extends Component {
  state = {
    isLoading: false,
    currentGoal: this.props.navigationParams.params
      ? this.props.navigationParams.params.currentGoal
      : JSON.parse(getValue('currentGoal')),
    progress: '',
    progressComment: '',
  };

  constructor(props: any) {
    super(props);
    this._saveProgressOption = this._saveProgressOption.bind(this);
    this._saveProgressComment = this._saveProgressComment.bind(this);
  }

  render() {
    if (isWeb) {
      return this.createMainContents();
    }
    return (
      <Container
        contents={this.createMainContents}
        needsSidebar={false}
        screenType={SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        loading={this.state.isLoading}
        headerTitle={NAVIGATION_TEXT.GOAL_TRACKER}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  createMainContents = () => {
    return (
      <View style={[CommonStyles.singleMainContentContainer]}>
        <View accessible={false} style={[CommonStyles.columnContainer]}>
          <SummaryTile
            headerDisplayString={GOAL_SCREEN_LABELS.GOAL_TRACKER}
            headerIconImage={goalsIconRed}
            HeaderTextColor={BrandStyles.TextColor3}
            HeaderBoarderColor={BrandStyles.borderColor3}
            hideYellowStar={true}
            showButton={false}
            fontSize={CommonStyles.rpfont20}
            disableMore={true}
          />
          {this.renderGoalPreview()}
          {this.renderGoalProgressForm()}
          {this.renderGoalProgress()}
        </View>
      </View>
    );
  };

  renderGoalPreview = () => {
    if (this.state.currentGoal) {
      return (
        <View>
          <GoalProgressPreviewSummary
            readOnly={this.props.member}
            goalItem={this.state.currentGoal}
          />
        </View>
      );
    }
  };

  renderGoalProgressForm = () => {
    return (
      <View>
        <GoalProgressTracker
          cancel={this._cancelProgress}
          save={this._submitProgress}
          saveProgressOption={this._saveProgressOption}
          saveProgressComment={this._saveProgressComment}
          readOnly={!this.determineAccess()}
        />
      </View>
    );
  };

  renderGoalProgress = () => {
    const currenGoalProgress = this.props.goals.currenGoalProgress
      ? this.props.goals.currenGoalProgress
      : JSON.parse(getValue('currentGoalProgress'));
    if (currenGoalProgress.length > 0) {
      return <GoalProgressList goalProgressList={currenGoalProgress} />;
    } else {
      return null;
    }
  };

  _setLoading = (loadingState: any) => {
    if (isWeb) {
      this.props.setLoading(loadingState);
    } else {
      this.setState({isLoading: loadingState});
    }
  };

  _saveProgressOption = (option: any) => {
    this.setState({progress: option});
  };

  _saveProgressComment = (comment: any) => {
    this.setState({progressComment: comment});
  };

  _cancelProgress = () => {
    this.props.navigation.goBack();
  };

  _submitProgress = () => {
    this._setLoading(true);
    const goalObject = this.props.goals.currentGoal
      ? this.props.goals.currentGoal
      : JSON.parse(getValue('currentGoal'));

    const progressObject = {
      id: this.props.member.id,
      goalId: goalObject.currentGoalId,
      progress: this.state.progress,
      progressComment: this.state.progressComment,
    };

    const postGoalProgressCallback = (data: any) => {
      const payload = data[0];
      if (payload.content) {
        this._getGoalProgress(goalObject);
        this.setState({
          progress: '',
          progressComment: '',
        });
        this._setLoading(false);
      } else {
        this.setState({
          progress: '',
          progressComment: '',
        });
        this._setLoading(false);
      }
    };

    callAPIs(
      [postGoalProgress(progressObject, this.props.user.demoProfile)],
      postGoalProgressCallback,
      null,
      () => {
        this._setLoading(false);
      },
    );
  };

  _getGoalProgress = (goalObject: any) => {
    const getGoalProgressCallbackFunction = (data: any) => {
      const goalProgress = data[0].content.goalProgress;
      this.props.actions.GoalActions.getGoalProgress(goalProgress);
      this._setLoading(false);
    };

    callAPIs(
      [
        getGoalProgress(
          this.props.loadedMemberId.loadedMemberId,
          goalObject.currentGoalId,
          this.props.user.demoProfile,
        ),
      ],
      getGoalProgressCallbackFunction,
      null,
      () => {
        this._setLoading(false);
      },
    );
  };

  determineAccess() {
    if (this.props.member.access != undefined) {
      return this.props.member.access.profile == ACCESS_FULL ? true : false;
    }
  }
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  goals: state.GoalReducer,
  loadedMemberId: state.LoadedMemberReducer,
  navigationParams: state.NavigationParamsReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    GoalActions: bindActionCreators(GoalActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GoalProgress);
