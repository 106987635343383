/*
Author: Declan Hart
Date: 11/1/18
Copyright © 2017 LeapIn. All rights reserved.

The Support Category Selector component, displays the categories for supports to filter the support items.
*/

import React, {Component} from 'react';
import {View} from 'react-native';
import {StandardText, StandardPicker} from './';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class SingleSelectDriverQuestion extends Component {
  state = {
    selectedOption: 0,
  };

  constructor(props: any) {
    super(props);
    this.renderOptions = this.renderOptions.bind(this);
    this.handleIndexChange = this.handleIndexChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.selectedResponse) {
      if (this.props.selectedResponse.length > 0) {
        this.setState({
          selectedOption: this.props.selectedResponse[0].value,
        });
      }
    }
  }

  renderOptions() {
    return this.props.driver.values.map((value: any, index: any) => {
      return {
        type: value.key,
        description: value.description,
      };
    });
  }

  handleIndexChange(type: any, itemValue: any) {
    this.setState({selectedOption: itemValue});
    this.props.save(type, itemValue);
  }

  render() {
    return (
      <View>
        <View style={[CommonStyles.leftAboutMeModalContentContainer]}>
          <StandardText
            style={[
              CommonStyles.customFontMedium,
              CommonStyles.ModalLabel,
              BrandStyles.TextColor5,
            ]}
          >
            {this.props.driver.driverQuestion}
          </StandardText>
        </View>
        <View
          style={[
            CommonStyles.rightAboutMeModalContentContainer,
            CommonStyles.maxWidth400,
          ]}
        >
          <View
            style={[BrandStyles.secondaryBgColor3, CommonStyles.borderRadius10]}
          >
            <StandardPicker
              access={this.props.readOnly}
              pickerOptions={this.renderOptions()}
              style={[
                CommonStyles.standardPicker,
                CommonStyles.standardPickerBackgroundColorGray,
              ]}
              selectedValue={this.state.selectedOption}
              onValueChange={(itemValue: any, itemIndex: any) =>
                this.handleIndexChange(this.props.driver.driverCode, itemValue)
              }
            />
          </View>
        </View>
      </View>
    );
  }
}

export default SingleSelectDriverQuestion;
