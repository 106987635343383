import React, {Component} from 'react';
import {
  View,
  ScrollView,
  Animated,
  Dimensions,
  StyleSheet,
  Linking,
  Platform,
} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  StandardButton,
  SecureStandardInput,
  StandardText,
} from '../Components/Atoms';
import {BrandActivityIndicator} from '../Components/Molecules/BrandActivityIndicator';
import {
  OfflineNotice,
  DeepLinkBanner,
  HeaderBanner,
  LoginFields,
  BasicPopupAlert,
} from '../Components/Organisms';
import {
  LoginSignUpTitle,
  ForgotPasswordButton,
  Container,
  NoAccountButton,
  FormSubtitle,
  ReturnToWelcome,
  Copyrights,
  ScreenTitle,
  DataSharingConsentModal,
} from '../Components/Molecules';
import logger from 'helpers/Logger';
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {CommonActions} from '@react-navigation/native';
import {
  LOGIN_SIGNUP_SCREEN,
  COMMON_BUTTONS,
  PREAUTHENTICATION_SCREEN,
} from '../Constants/Messages';

import AuthenticationUtil from '../Helpers/AuthenticationUtil';
import {putTemporaryPassword} from '../API/UserAPI';
import * as AuthenticationActions from '../Actions/AuthenticationActions';
import * as loadedMemberActions from '../Actions/LoadedMemberActions';
import * as MemberActions from '../Actions/MemberActions';
import * as UserActions from '../Actions/UserActions';
import * as AdminActions from '../Actions/AdminActions';
import * as GuideMeActions from '../Actions/GuideMeActions';
import * as OfflineNoticeActions from '../Actions/OfflineNoticeActions';
import {KeyboardAwareScrollView} from 'helpers/KeyboardAwareScrollView';

import * as validations from '../Helpers/validationUtils';
import {getStoreUrl} from '../Helpers/Utils';
import {APP_ID} from '../environments';

// Get the device height & width
let ScreenHeight = Dimensions.get('window').height;
let ScreenWidth = Dimensions.get('window').width;

// Setup animations
const animationValue = new Animated.Value(0);

// Maps the animation values to start from the top of the screen out of view and finish at the bottom of the screen in view
const animateTopToBottom = animationValue.interpolate({
  inputRange: [0, 1],
  outputRange: [-ScreenHeight, 0.0],
});
// Maps the animation values to start from the bottom of the screen out of view and finish at the top of the screen in view
const animateBottomToTop = animationValue.interpolate({
  inputRange: [0, 1],
  outputRange: [ScreenHeight, 0.0],
});

class Login extends Component {
  state = {
    email: '',
    password: '',
    termsandconditionsaccepted: false,
    loading: false,
    firstNameError: '',
    knownasError: '',
    lastNameError: '',
    emailError: '',
    passwordError: '',
    termsandconditionsacceptedError: '',
    passwordChallenge: false,
    newPassword: '',
    newPasswordError: '',
    cognitoUserAttributes: {},
    cognitoUser: {},
    displayTermsandCErrorMessage: false,
    isConnected: true,
    isFirstTimeLogin: false,
    orientation: '',
    showDataSharingConsentModal: false,
    dataSharingConsentModalRoutingObject: {},
    errorPopUpVisible: false,
    temporaryPasswordExpiredMessageVisible: false,
    showPlanReadyError: false,
    captchToken: null,
    saveDataCallback: null,
  };

  constructor(props: any) {
    super(props);
    this.saveField = this.saveField.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.saveEmailCredentials = this.saveEmailCredentials.bind(this);
    this.updateState = this.updateState.bind(this);
    this.login = this.login.bind(this);
    this.navigationToSignUp = this.navigationToSignUp.bind(this);
    this.navigationToWelcome = this.navigationToWelcome.bind(this);
  }

  render() {
    return (
      <View
        style={[
          CommonStyles.screenWrapperContainer,
          BrandStyles.primaryBgColor1,
        ]}
      >
        {types.isWeb && <HeaderBanner />}
        <OfflineNotice />
        {types.isWeb && <DeepLinkBanner />}
        {this.state.showDataSharingConsentModal && (
          <DataSharingConsentModal
            closeModal={(result: any) =>
              this._closeDataSharingConsentModalAndContinue(result)
            }
            isVisible={this.state.showDataSharingConsentModal}
            hideModal={() => {
              this.setState({showDataSharingConsentModal: false});
            }}
            setLoading={() => {
              this.setState({loading: true});
            }}
            nav={this.props.navigation}
            isMobile={this.props.isMobile}
          />
        )}
        <View
          style={[
            CommonStyles.alignItemsCenter,
            CommonStyles.marginBottom10,
            CommonStyles.zIndex102,
            CommonStyles.flexDirectionRow,
          ]}
        >
          <LoginSignUpTitle
            title={
              LOGIN_SIGNUP_SCREEN.LOGIN_TITLE_1 +
              ' ' +
              LOGIN_SIGNUP_SCREEN.LOGIN_TITLE_2
            }
            backButton={true}
            nav={this.props.navigation}
            screen={types2.SIGNUP_LOGIN_FLOW.WELCOME}
          />
        </View>
        <KeyboardAwareScrollView
          contentContainerStyle={[
            CommonStyles.alignItemsCenter,
            !types.isWeb && {
              ...CommonStyles.margin10P,
              ...CommonStyles.marginTop0,
            },
          ]}
          showsVerticalScrollIndicator={false}
        >
          <View>
            {!types.isWeb && (
              <View style={CommonStyles.screenTitleContainer}>
                <ScreenTitle
                  title={
                    LOGIN_SIGNUP_SCREEN.LOGIN_TITLE_1 +
                    ' ' +
                    LOGIN_SIGNUP_SCREEN.LOGIN_TITLE_2
                  }
                />
              </View>
            )}
            <LoginFields
              email={this.state.email}
              emailError={this.state.emailError}
              password={this.state.password}
              passwordError={this.state.passwordError}
              scrollToPosition={() => {}}
              saveField={this.saveField}
              onEditSubmit={this.login}
            />
            {this._renderNewPasswordInputField()}
            <View style={CommonStyles.paddingTop20}>
              <StandardButton
                style={[
                  types.isWeb ? CommonStyles.font18 : CommonStyles.rpfont22MS,
                  CommonStyles.customFont,
                  CommonStyles.textAlignCentre,
                  CommonStyles.fontWeightBold,
                  BrandStyles.brandBlockTxtColor6,
                ]}
                containerStyle={[
                  BrandStyles.primaryBgColor1,
                  CommonStyles.elevation3,
                  BrandStyles.imageBorderColor,
                  CommonStyles.borderRadius5,
                  CommonStyles.borderWidth1,
                  CommonStyles.rploginsingupBtnWidth,
                  CommonStyles.padding10,
                ]}
                onPress={() => this.login()}
              >
                {PREAUTHENTICATION_SCREEN.BUTTON_LOGIN}
              </StandardButton>
            </View>
          </View>
          <ForgotPasswordButton email={this.state.email} navigation={this.props.navigation} />
          <NoAccountButton navigate={this.navigationToSignUp} />
          <ReturnToWelcome navigate={this.navigationToWelcome} />
        </KeyboardAwareScrollView>
        {!types.isWeb && (
          <View
            style={[
              CommonStyles.alignCenter,
              BrandStyles.copyrightsBarBgColor,
              CommonStyles.loginButtonWrapper,
            ]}
          >
            <Copyrights />
          </View>
        )}
        <BasicPopupAlert
          visible={this.state.errorPopUpVisible}
          close={this.closeAlert}
          cancelLabel={
            this.state.buttonText ? this.state.buttonText : COMMON_BUTTONS.OK
          }
          headerText={this.state.alertHeader}
          messageText={this.state.alertMessage}
          forceUpdate={this.state.forceUpdate}
          latestVersion={this.state.latestVersion}
          showPlanReadyError={this.state.showPlanReadyError}
          isMobile={this.props.isMobile}
        />
        <BrandActivityIndicator enableWeb={true} loading={this.state.loading} />
      </View>
    );
  }

  _renderNewPasswordInputField() {
    // There is a password challenge, so add a new field for the user to enter a new password
    if (this.state.passwordChallenge) {
      return (
        <View
          key="newPasswordContainer"
          style={[CommonStyles.textInputLoginSignUp, CommonStyles.marginTop15]}
        >
          <FormSubtitle text={['New Password']} />
          <SecureStandardInput
            applyWrapper={false}
            key="newPassword"
            accessibilityLabel="New Password"
            ref="newPassword"
            value={this.state.newPassword}
            onChangeText={(newPassword: any) => this.setState({newPassword})}
            blurOnSubmit={true}
            secureTextEntry={true}
            style={[CommonStyles.ModalTextInputWrapper]}
            noMargin={true}
            underlineColorAndroid="transparent"
            errorMessage={this.state.newPasswordError}
          />
          <StandardText
            style={[
              BrandStyles.TextColor5,
              CommonStyles.customFont,
              CommonStyles.rpfont10,
              CommonStyles.alignSelfCenter,
              CommonStyles.rpMarginTop5p,
            ]}
          >
            {LOGIN_SIGNUP_SCREEN.PASSWORD_REQUIREMENT}
          </StandardText>
        </View>
      );
    }
  }

  saveField(fieldName: any, value: any) {
    switch (fieldName) {
      case types.EMAIL:
        this.setState({email: value});
        break;
      case types.PASSWORD:
        this.setState({password: value});
        break;
      default:
        break;
    }
  }

  login() {
    this.setState({loading: true});
    if (!this.validateFields()) {
      this.setState({loading: false});
      return;
    }
    if (this.state.passwordChallenge) {
      const auth = new AuthenticationUtil(
        null,
        null,
        this.state.email,
        this.state.password,
        null,
      );
      auth.respondToPasswordChallenge(
        this.state.newPassword,
        () => this.saveEmailCredentials(auth),
        () => {},
        this.updateState,
        this.state.cognitoUserAttributes,
        this.state.cognitoUser,
      );
    } else {
      const auth = new AuthenticationUtil(
        null,
        null,
        this.state.email,
        this.state.password,
        null,
      );
      auth.login(
        () => this.saveEmailCredentials(auth),
        this.authFailure,
        this.updateState,
      );
    }
  }

  updateState(stateObj: any) {
    this.setState(stateObj);
  }

  saveEmailCredentials = (auth: any) => {
    auth.saveEmailCredentials(
      this.props.actions,
      this.updateState,
      (actionToDispatch: any, routingObj: any, saveDataCallback: any) => {
        if (types.isWeb) {
          this.props.navigation.navigate(actionToDispatch);
        } else {
          if (
            // actionToDispatch &&
            // actionToDispatch.actions &&
            // actionToDispatch.actions.length &&
            // actionToDispatch.actions[0].params &&
            // actionToDispatch.actions[0].params.userObj &&
            // actionToDispatch.actions[0].params.userObj.user
            actionToDispatch?.payload?.routes.length &&
            actionToDispatch?.payload?.routes[0].params?.userObj?.user
          ) {
            if (
              !this._determineShowDataSharingConsentModal(
                actionToDispatch,
                routingObj,
                saveDataCallback,
              )
            ) {
              if (saveDataCallback) saveDataCallback();
              let routeDispatch = actionToDispatch
              if(actionToDispatch.payload){
                routeDispatch = CommonActions.reset({
                  index: 0,
                  routes: [
                    {
                      name:actionToDispatch?.payload?.routes[0].name, 
                      params:actionToDispatch?.payload?.routes[0].params }
                  ],
                });
              }
           
              this.props.navigation.dispatch(routeDispatch);
            }
          }
        }
      },
      this.authFailure,
    );
  };

  authFailure = (error: any) => {
    this.setState({
      loading: false,
      alertHeader: error.title,
      alertMessage: error.body,
      buttonText: error.buttonText,
      forceUpdate: error.forceUpdate,
      resendPassword: error.resendPassword,
      errorPopUpVisible: true,
    });
  };

  closeAlert = () => {
    if (this.state.forceUpdate) {
      const updateLink = getStoreUrl(Platform.OS);
      Linking.openURL(updateLink);
    } else if (this.state.resendPassword) {
      this.setState({loading: true});
      putTemporaryPassword(this.state.email)
        .then(() => {
          this.setState({
            alertHeader: LOGIN_SIGNUP_SCREEN.TEMP_PASSWORD_SENT_MODAL_HEADER,
            alertMessage: LOGIN_SIGNUP_SCREEN.TEMP_PASSWORD_SENT_MODAL_MESSAGE,
          });
          this.setState({loading: false});
        })
        .catch(err => {
          this.setState({loading: false});
        });
    }
    this.setState({
      errorPopUpVisible: false,
    });
  };

  validateFields() {
    let validationErrors = {
      email: validations.validateEmail(this.state.email),
      password: validations.validatePassword(this.state.password),
    };
    this.setState({
      emailError: validationErrors.email,
      passwordError: validationErrors.password,
    });
    if (this.state.passwordChallenge) {
      validationErrors.newPassword = validations.validatePassword(
        this.state.newPassword,
      );
      this.setState({
        newPasswordError: validationErrors.newPassword,
      });
    }
    for (let [key, value] of Object.entries(validationErrors)) {
      if (value !== '') {
        // announceForAccessibility(readThisText);
        return false;
      }
    }
    return true;
  }

  navigationToSignUp() {
    this.props.navigation.navigate('ChooseParticipantOrCrew');
    this.setState({loading: false});
  }
  navigationToWelcome() {
    this.props.navigation.navigate('Welcome');
    this.setState({loading: false});
  }

  _determineShowDataSharingConsentModal(
    actionToDispatch: any,
    routingObject: any,
    saveDataCallback: any,
  ) {
    const user = actionToDispatch?.payload?.routes[0].params?.userObj?.user;
    let showDataSharingConsentModal = !user.tAndCAcceptedBrands.find(
      (x: any) => x == APP_ID,
    );
    this.setState({
      showDataSharingConsentModal: showDataSharingConsentModal,
      dataSharingConsentModalRoutingObject: actionToDispatch,
      saveDataCallback,
    });

    return showDataSharingConsentModal;
  }

  _closeDataSharingConsentModalAndContinue = (continueNavigation: any) => {
    this.setState({
      showDataSharingConsentModal: false,
      loading: false,
    });

    // Breaks the navigation
    if (!continueNavigation) {
      this.setState({
        email: '',
        password: '',
      });
      return;
    }
    if (this.state.saveDataCallback) {
      this.state.saveDataCallback();
    }
    this.props.navigation.dispatch(
      this.state.dataSharingConsentModalRoutingObject,
    );
  };
}

const mapStateToProps = (state: any) => ({
  auth: state.AuthenticationReducer,
  loadedMemberId: state.LoadedMemberReducer,
  user: state.UserReducer,
  admin: state.AdminReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    authActions: bindActionCreators(AuthenticationActions, dispatch),
    adminActions: bindActionCreators(AdminActions, dispatch),
    loadedMemberActions: bindActionCreators(loadedMemberActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
    OfflineNoticeActions: bindActionCreators(OfflineNoticeActions, dispatch),
    memberActions: bindActionCreators(MemberActions, dispatch),
    guideMeActions: bindActionCreators(GuideMeActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
