import React, {Component} from 'react';
// import WebContainer from './Components/Molecules/WebContainer';
import WebRoutesGenerator from './NativeWebRouteWrapper';
import {ModalContainer} from 'react-router-modal';
import * as env from './environments';
import {AppContainer, WebContainer} from './Components/Molecules';
import {GestureHandlerRootView, enableLegacyWebImplementation} from 'react-native-gesture-handler';

import {
  Splash,
  LoginSignUp,
  TermsAndConditions,
  ChooseParticipantOrCrew,
  ForgotPassword,
  ProfilePictureForm,
  CrewCreateMember,
  Welcome,
  ChooseDemoProfile,
  ChooseGuideMeType,
  SignUp,
  Login,
  UnderMaintenance,
} from './Screens';

import {Provider} from 'react-redux';
import reduxStore from './ReduxStore/ReduxStore';
import {createStore} from 'redux';
import reducer from './reducers';
import {AWS_LAMBDA_CONFIG_ITEM, APP_VERSION, DATA_DOG, DATA_DOG_STAFF} from './environments';
import * as types from './Constants/Constants';
import * as types2 from './Constants/Constants2';
import {GoogleAnalyticsInitialiser} from './Helpers/PlatformSynchronizer';
import {datadogRum} from '@datadog/browser-rum';
import GLogin from './Screens/authentication/login';
import {APP_TYPE_STAFF} from './Constants/Constants';
enableLegacyWebImplementation(false)
const configureStore = () => createStore(reducer);

reduxStore.init(configureStore);
export const store = reduxStore.getStore();
if (DATA_DOG.ENABLED) {
  datadogRum.init({
    applicationId: DATA_DOG.APPLICATION_ID,
    clientToken: DATA_DOG.CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: DATA_DOG.DD_SERVICE,
    env: AWS_LAMBDA_CONFIG_ITEM,
    version: APP_VERSION,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: DATA_DOG.allowedTracingUrls
  });
  datadogRum.startSessionReplayRecording();
}

const host = window.location.hostname.toLowerCase();
let loginComponent = Login;
if (env.IS_STAFF_APP === APP_TYPE_STAFF) {
  loginComponent = GLogin;
}

const routeMap = {
  Splash: {
    component: Splash,
    path: '/',
    exact: true,
  },
  Welcome: {
    component: Welcome,
    path: '/welcome',
  },
  ChooseDemoProfile: {
    component: ChooseDemoProfile,
    path: '/ChooseDemoProfile',
  },
  ChooseGuideMeType: {
    component: ChooseGuideMeType,
    path: '/ChooseGuideMeType',
  },
  SignUp: {
    component: SignUp,
    path: '/SignUp',
  },
  Login: {
    component: loginComponent,
    path: '/Login',
  },
  LoginSignUp: {
    component: LoginSignUp,
    path: '/LoginSignUp/:screenMode?/:enableBack?',
  },
  ChooseParticipantOrCrew: {
    component: ChooseParticipantOrCrew,
    path: '/ChooseParticipantOrCrew',
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: '/TermsAndConditions',
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword',
  },
  Home: {
    component: WebContainer,
    path: '/Home',
  },
  ProfilePictureForm: {
    component: ProfilePictureForm,
    path: '/ProfilePictureForm',
  },
  CrewCreateMember: {
    component: CrewCreateMember,
    path: '/CrewCreateMember/:screenMode?/:planType?/:signUpType?',
  },
  UnderMaintenance: {
    component: UnderMaintenance,
    path: '/under-maintenance',
  },
};
class App extends Component {
  state = {
    width: window.innerWidth,
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
    GoogleAnalyticsInitialiser(window.location.pathname);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({width: window.innerWidth});
  };

  render() {
    const isMobile = this.state.width <= types2.THEME_SWITCH_WIDTH;
    return (
      <GestureHandlerRootView style={{flex:1}}>
      <Provider store={store}>
        <AppContainer>
          {WebRoutesGenerator({routeMap}, null, isMobile, this.state.width)}
          <ModalContainer />
        </AppContainer>
      </Provider>
      </GestureHandlerRootView>
    );
  }
}

export default App;
