/*
 * Author: Brad D'Costa
 * Date: 29/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Health and Wellbeing actions for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const actionGetHealthWellbeing = (HealthObj: any) => ({
  type: types.GET_HEALTH_WELLBEING,
  HealthObj,
});

export const actionUpdateHealthWellbeingGoingWell = (goingWellObj: any) => ({
  type: types.UPDATE_HEALTH_WELLBEING_GOING_WELL,
  goingWellObj,
});

export const actionUpdateHealthWellbeingConcerns = (concernsObj: any) => ({
  type: types.UPDATE_HEALTH_WELLBEING_CONCERNS,
  concernsObj,
});
