/*
 * Author: Shelan Cooray
 * Date: 01/07/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Carousel Component for Popup Modal
 *
 */

import React, {Component} from 'react';
import {ScrollView, View, StyleSheet, Dimensions} from 'react-native';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import logger from 'helpers/Logger';
import {BrandColors} from '../../Styles/Colours';
import {NEXT} from '../../Constants/Constants2';
import {StandardButton, StandardText} from './';

// import Orientation from 'react-native-orientation-enhanced';
import {focusOnView} from '../../Helpers/PlatformSynchronizer';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

// #TODO this component can be customize depend on our requirement
class Carousel extends Component {
  scrollView: any;
  constructor(props: any) {
    super(props);
    this.onPress = this.onPress.bind(this);
    this.getButtonText = this.getButtonText.bind(this);
  }

  state = {
    offset: 0,
  };

  componentDidMount() {
    // if (!types.isWeb) {
    //   Orientation.addOrientationListener(this._orientationDidChange);
    // }
  }

  componentWillUnmount() {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation,
    });

    const guideMeStep = this.props.guideMe.welcomeGuideStep;
    if (
      this.props.guideMe.showWelcomeGuide ||
      this.props.guideMe.showWelcomeManagedGuide
    ) {
      const width = Dimensions.get('window').width;
      this.scrollView.scrollTo({
        x: (guideMeStep - 1) * parseInt(width * 0.7),
      });
    }
  };

  onPress = () => {
    const {children = []} = this.props;
    const guideMeStep = this.props.guideMe.welcomeGuideStep;

    if (
      guideMeStep === children.length ||
      !(
        this.props.guideMe.showWelcomeGuide ||
        this.props.guideMe.showWelcomeManagedGuide
      )
    ) {
      this.props.action();
      if (this.props.guideMe.showWelcomeGuide) {
        this.props.guideMeActions.showWelcomeGuide(false);
        this.setState({
          offset: 0,
        });
      }
      if (this.props.guideMe.showWelcomeManagedGuide) {
        this.props.guideMeActions.showWelcomeManagedGuide(false);
        this.setState({
          offset: 0,
        });
      }
    } else {
      const width = Dimensions.get('window').width;
      let percentageScroll = 0.7;
      if (types.isWeb) {
        percentageScroll = this.props.isMobile ? 0.7 : 0.4;
      }
      this.scrollView.scrollTo({
        x: guideMeStep * parseInt(width * percentageScroll),
      });
    }
  };

  setActiveStep = (width: any, offset: any) => {
    if (
      this.props.guideMe.showWelcomeGuide ||
      this.props.guideMe.showWelcomeManagedGuide
    ) {
      const {children = []} = this.props;
      for (let i = 0; i < children.length; i++) {
        const diff = Math.abs(width * i - offset);
        if (diff <= 10) {
          const stepValue = i + 1;
          this.props.guideMeActions.setWelcomeGuideStep(stepValue);
        }
      }
    }
  };

  renderDots = (width: any) => {
    const {children = []} = this.props;
    let dots = [];

    for (var i = 0; i < children.length; i++) {
      let key = width * i;
      let diff = Math.abs(key - this.state.offset);
      dots.push(
        <View
          style={diff <= 10 ? CommonStyles.filledDot : CommonStyles.emptyDot}
          key={key}
        />,
      );
    }

    return <View style={CommonStyles.dotStyle}>{dots}</View>;
  };

  getButtonText = () => {
    const {children = []} = this.props;
    if (
      this.props.guideMe.welcomeGuideStep == children.length ||
      !(
        this.props.guideMe.showWelcomeGuide ||
        this.props.guideMe.showWelcomeManagedGuide
      )
    ) {
      return this.props.buttonText;
    } else {
      return NEXT;
    }
  };

  render() {
    const {
      backgroundColor = BrandColors.WHITE,
      children = [],
      showDots = true,
    } = this.props;

    let width = 0;
    if (types.isWeb) {
      if (this.props.isMobile) {
        width = this.props.windowWidth * 0.7;
      } else {
        width = this.props.windowWidth * 0.4;
      }
    } else {
      width = parseInt(Dimensions.get('window').width * 0.7);
    }

    let pages = [];

    for (var i = 0; i < children.length; i++) {
      pages.push(
        <ScrollView showsVerticalScrollIndicator={false} style={[{width: width}, CommonStyles.flex1]} key={i}>
          {children[i]}
        </ScrollView>,
      );
    }

    return (
      <View style={[CommonStyles.popUpContainer, CommonStyles.paddingBottom10]}>
        <View
          style={[
            {width: width},
            types.isWeb
              ? CommonStyles.popUpContentContainer
              : [CommonStyles.flex065, CommonStyles.marginTop10],
          ]}
        >
          <ScrollView
            horizontal={true}
            pagingEnabled={true}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            ref={ref => {
              this.scrollView = ref;
            }}
            onScroll={e => {
              this.setState({offset: e.nativeEvent.contentOffset.x});
              this.setActiveStep(width, e.nativeEvent.contentOffset.x);
            }}
            style={[{width: width}, CommonStyles.flex1]}
          >
            {pages}
          </ScrollView>
          {showDots ? this.renderDots(width) : null}
        </View>
        <View style={CommonStyles.flex1}>
          <View style={CommonStyles.dividerStyle} />
          <StandardButton
            onPress={() => this.onPress()}
            accessibilityLabel={this.getButtonText()}
          >
            <View
              style={CommonStyles.popUpButton}
              accessible={true}
              ref={ref => {
                if (ref) focusOnView(ref);
              }}
            >
              <StandardText
                style={[
                  CommonStyles.popUpButtonText,
                  CommonStyles.customFontBold,
                ]}
                allowFontScaling={false}
              >
                {this.getButtonText()}
              </StandardText>
            </View>
          </StandardButton>
        </View>
      </View>
    );
  }
}

export default Carousel;
