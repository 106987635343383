/*
 * Author: Declan Hart
 * Date: 12/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a component to display ratio questions
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import {
  StandardText,
  StandardSegmentedControl,
  Frequency,
  SelectWhenDoYouUse,
} from './';

import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

const Usage = function (props: any) {
  return (
    <View style={CommonStyles.spaceAround}>
      <View style={[CommonStyles.flexDirectionRow, {marginBottom: 5}]}>
        <StandardText
          style={[
            CommonStyles.title_SupportQuestions,
            CommonStyles.customFontBold,
          ]}
        >
          {types2.SELECT_WHEN_DO_YOU_USE}
        </StandardText>
      </View>
      <SelectWhenDoYouUse
        values={props.options}
        save={props.save}
        selectedValues={props.selectedValues}
        type={types.USAGE}
        saveType={types.UNITOFMEASURE}
        readOnly={props.readOnly}
      />
    </View>
  );
};

export default Usage;
