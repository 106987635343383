/*
 * Author: Nirodha Perera
 * Date: 15/10/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * GuideMe Modal Content View
 *
 */

import React, {Component} from 'react';
import {ScrollView, View, StyleSheet, Dimensions, Image} from 'react-native';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import {BrandColors} from '../../Styles/Colours';
import {WELCOME_GUIDE_THEMES} from '../../Constants/Constants2';
import {StandardButton, StandardText} from '../Atoms';

import {APP_ID} from '../../environments';
import { isWeb} from '../../Constants/Constants';
import {getBrandIcon, getBrandGuideTitle} from '../../Helpers/BrandUtils';

class GuideMeView extends Component {
  constructor(props: any) {
    super(props);
  }

  getWidth = (percentageWidth: any) => {
    return Dimensions.get('window').width * (percentageWidth / 100);
  };

  getMainTitleContainerStyle = () => {
    const style = [
      CommonStyles.viewWhiteBorder,
      CommonStyles.alignSelfCenter,
      CommonStyles.zIndex02,
      {marginRight: this.getWidth(12)},
    ];

    if (
      this.props.titleTheme === WELCOME_GUIDE_THEMES.Red ||
      this.props.titleTheme === WELCOME_GUIDE_THEMES.RedPurple
    ) {
      style.push(BrandStyles.borderColor3, BrandStyles.primaryBgColor3);
    } else if (this.props.titleTheme === WELCOME_GUIDE_THEMES.Purple) {
      style.push(BrandStyles.borderColor4, BrandStyles.primaryBgColor4);
    } else if (this.props.titleTheme === WELCOME_GUIDE_THEMES.SummerGreen) {
      style.push(BrandStyles.borderColor13, BrandStyles.headerBgColor1);
    } else if (this.props.titleTheme === WELCOME_GUIDE_THEMES.Corn) {
      style.push(BrandStyles.borderColor14, BrandStyles.headerBgColor2);
    } else {
      style.push(BrandStyles.borderColor12, BrandStyles.secondaryBgColor2);
    }

    return style;
  };

  getMainTitleStyle = () => {
    const style = [
      CommonStyles.customFontBold,
      CommonStyles.loginSignUpHeadingPadding,
      CommonStyles.textGuideMe,
      CommonStyles.fontWeightBold,
    ];

    if (
      this.props.titleTheme === WELCOME_GUIDE_THEMES.YellowBlack ||
      this.props.titleTheme === WELCOME_GUIDE_THEMES.SummerGreen ||
      this.props.titleTheme === WELCOME_GUIDE_THEMES.Corn
    ) {
      style.push(BrandStyles.TextColor2);
    } else {
      style.push(BrandStyles.TextColor1);
    }

    return style;
  };

  getSubTitleStyle = () => {
    const style = [
      CommonStyles.customFontBold,
      CommonStyles.viewWhiteBorder,
      CommonStyles.loginSignUpHeadingPadding,
      CommonStyles.textGuideMe,
      CommonStyles.fontWeightBold,
    ];

    if (
      this.props.titleTheme === WELCOME_GUIDE_THEMES.Red ||
      this.props.titleTheme === WELCOME_GUIDE_THEMES.RedPurple
    ) {
      style.push(BrandStyles.borderColor3, BrandStyles.TextColor3);
    } else if (this.props.titleTheme === WELCOME_GUIDE_THEMES.Purple) {
      style.push(BrandStyles.borderColor4, BrandStyles.TextColor4);
    } else if (this.props.titleTheme === WELCOME_GUIDE_THEMES.YellowBlack) {
      style.push(BrandStyles.borderColor12, BrandStyles.TextColor2);
    } else if (this.props.titleTheme === WELCOME_GUIDE_THEMES.SummerGreen) {
      style.push(BrandStyles.borderColor13, BrandStyles.TextColor2);
    } else if (this.props.titleTheme === WELCOME_GUIDE_THEMES.Corn) {
      style.push(BrandStyles.borderColor14, BrandStyles.TextColor2);
    } else {
      style.push(BrandStyles.borderColor12, BrandStyles.TextColor12);
    }

    return style;
  };

  render() {
    if (this.props.titleTypeImage) {
      return (
        <View
          style={[
            CommonStyles.flex1,
            CommonStyles.rpMarginBottom10p,
            CommonStyles.alignItemsCenter,
          ]}
        >
          <View style={CommonStyles.alignCenter}>
            <Image
              style={CommonStyles.imageGuideMeBrandIcon}
              source={getBrandIcon()}
            />
            <StandardText
              style={[
                CommonStyles.customFontBold,
                CommonStyles.loginSignUpHeadingPadding,
                CommonStyles.textGuideMe,
                CommonStyles.fontWeightBold,
                CommonStyles.textAlignCentre,
                CommonStyles.marginBottom10,
              ]}
            >
              {getBrandGuideTitle()}
            </StandardText>
          </View>
          {this.props.children}
        </View>
      );
    } else {
      return (
        <View
          style={[
            CommonStyles.flex1,
            CommonStyles.rpMarginBottom10p,
            CommonStyles.alignItemsCenter,
            CommonStyles.marginTop10,
          ]}
        >
          <View
            accessible={true}
            accessibilityLabel={
              this.props.mainHeader + ' ' + this.props.subMainHeader
            }
          >
            <View style={this.getMainTitleContainerStyle()}>
              <StandardText style={this.getMainTitleStyle()}>
                {this.props.mainHeader}
              </StandardText>
            </View>
            <View
              style={[
                BrandStyles.primaryBgColor1,
                CommonStyles.alignSelfCenter,
                CommonStyles.zIndex01,
                CommonStyles.secondHeaderTop,
                isWeb
                  ? this.getSubTitleStyle()
                  : {marginLeft: this.getWidth(12)},
              ]}
            >
              <StandardText style={this.getSubTitleStyle()}>
                {this.props.subMainHeader}
              </StandardText>
            </View>
          </View>
          {this.props.children}
        </View>
      );
    }
  }
}

export default GuideMeView;
