/*
 * Author: Brad D'Costa
 * Date: 29/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Living Arrangement actions for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const actionGetLivingArrangement = (livingObj: any) => ({
  type: types.GET_LIVING_ARRANGEMENT,
  livingObj,
});

export const actionUpdateLivingArrangementHome = (homeObj: any) => ({
  type: types.UPDATE_LIVING_ARRANGEMENT_HOME,
  homeObj,
});

export const actionUpdateLivingArrangementModification = (
  modificationObj: any,
) => ({
  type: types.UPDATE_LIVING_ARRANGEMENT_MODIFICATION,
  modificationObj,
});

export const actionSetWhoILiveWithData = (whoILiveWithObj: any) => ({
  type: types.WHO_I_LIVE_WITH.SET_DATA,
  whoILiveWithObj,
});

export const actionResetWhoILiveWithData = (whoILiveWithObj: any) => ({
  type: types.WHO_I_LIVE_WITH.RESET_DATA,
  whoILiveWithObj,
});

export const actionUpdateWhoILiveWithOnSave = (whoILiveWithObj: any) => ({
  type: types.WHO_I_LIVE_WITH.UPDATE_ON_SAVE,
  whoILiveWithObj,
});
