/*
 * Author: Shelan Cooray
 * Date: 07/02/2019
 * Copyright Â© 2018 Leap in!. All rights reserved.
 *
 * This is a screen where display the previous plans using PreviousPlans component
 */

import React, {Component} from 'react';
import {View} from 'react-native';

// Import constants and messages
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
// Import other components
import {Container, BrandActivityIndicator} from '../Components/Molecules';
import {PreviousPlans} from '../Components/Organisms';
class Plans extends Component {
  state = {};

  constructor(props: any) {
    super(props);
  }

  render() {
    if (types.isWeb) {
      return (
        <View>
          <BrandActivityIndicator loading={this.state.loading} />
          {this._createMainContents()}
        </View>
      );
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={false}
        screenType={types.SCREEN_TYPE_MAIN}
        activeScreen={types.SCREEN_SETTINGS}
        selectedIcon={types.SCREEN_SETTINGS}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.PREVIOUS_PLANS}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    return (
      <PreviousPlans
        budget={this.props.budget}
        navigation={this.props.navigation}
      />
    );
  };
}
const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  budget: state.BudgetReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Plans);
