/*
 * Author: Brad D'Costa
 * Date: 29/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Member Favourtie Things information.
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {nullStringReplacer} from '../API/APIHelper';

/* Expected DataModel as per v13 of Swagger Definitions (versioning is based on Confluence versioning)
 *  {
 *      "likeToDo": "string"
 *      "wouldLikeToDo": "string"
 *  }
 */
export default function FavouriteThingsReducer(state = {}, action: any) {
  switch (action.type) {
    case types.GET_FAVOURITE_THINGS:
      const parsedFavouriteObj = {
        likeToDo: nullStringReplacer(action.favouriteObj.likeToDo),
        wouldLikeToDo: nullStringReplacer(action.favouriteObj.wouldLikeToDo),
      };
      return {
        likeToDo: parsedFavouriteObj.likeToDo,
        wouldLikeToDo: parsedFavouriteObj.wouldLikeToDo,
      };
    case types.UPDATE_FAVOURITE_LIKE_TO_DO:
      var newValue = {likeToDo: action.likeToDoObj.likeToDo};
      var updatedState = Object.assign({}, state);
      for (var k in newValue) updatedState[k] = newValue[k];
      return updatedState;
    case types.UPDATE_FAVOURITE_WOULD_LIKE_TO:
      var newValue = {wouldLikeToDo: action.wouldLikeToObj.wouldLikeToDo};
      var updatedState = Object.assign({}, state);
      for (var k in newValue) updatedState[k] = newValue[k];
      return updatedState;
    default:
      return state;
  }
}
