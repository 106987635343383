/*
 * Author: Shelan Cooray
 * Date: 07/02/2019
 * Copyright © 2019 Leap in!. All rights reserved.
 *
 * This component is to facilitate previous plans on Budget Landing screen
 * When no active plans available
 * And display Previous plans on Plans Screen
 */

import React, {Component} from 'react';
import {View, Image} from 'react-native';
import {formatDDMMMYYDate} from '../../Helpers/Utils';

// Import constants and messages
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {PLANS_SCREEN as messages} from '../../Constants/Messages';

// Import APIs
import DeviceInfo from 'react-native-device-info';

// Import other components
import {StandardText, StandardTouchableOpacity} from '../Atoms';
import {SummaryTile, CurrencyText} from '../Molecules';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {InformationIconx3, GreyClockIcon3x} from '../../assets/images';
import {InvoiceDollarRed2x} from '../../assets/images/vector-icons';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as NavigationParamActions from '../../Actions/NavigationParamActions';

const PREVIOUS_PLANS = 'PreviousPlans';
const BUDGETS_AND_PAYMENTS_SCREEN = 'BudgetsPayments';

class PreviousPlans extends Component {
  state = {};

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <View style={[CommonStyles.content]}>
        <SummaryTile
          disableMore={true}
          headerDisplayString={messages.HEADER_TITLE}
          headerIconImage={InvoiceDollarRed2x}
          HeaderTextColor={BrandStyles.headerContainsDataText}
          HeaderBoarderColor={BrandStyles.headerContainsDataBorder}
          hideYellowStar={true}
          disableMore={true}
          showButton={false}
        />
        <View style={types.isWeb ? [CommonStyles.alignItemsNormal] : []}>
          {this._renderPlans()}
        </View>
      </View>
    );
  }

  _renderPlans = () => {
    const plans = this.props.budget.plansObject;
    if (plans) {
      return plans.map((plan: any, index: any) => (
        <StandardTouchableOpacity
          activeOpacity={0.6}
          onPress={() => this.tappedTile(index)}
          style={[
            CommonStyles.flexRow,
            index !== 0
              ? [
                  CommonStyles.marginTop10,
                  CommonStyles.invoiceApprovalDetailWrapper,
                ]
              : null,
            plan.planStatus === types.PLAN_STATUS.ACTIVE
              ? CommonStyles.previousPlansActiveWapper
              : null,
          ]}
        >
          <View
            style={[CommonStyles.justifyFlexColumnStart, CommonStyles.flex05]}
          >
            <View
              style={[
                CommonStyles.MultiPlanContainer,
                types.PLANS_TYPE[plan.planStatus].bubbleBgColorStyle,
                plan.planStatus === types.PLAN_STATUS.ACTIVE
                  ? CommonStyles.height40
                  : null,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.customFontBold,
                  plan.planStatus === types.PLAN_STATUS.ACTIVE
                    ? CommonStyles.font20
                    : CommonStyles.font18,
                  types.PLANS_TYPE[plan.planStatus].bubbleTextColorStyle,
                ]}
              >
                {types.PLANS_TYPE[plan.planStatus].desc}
              </StandardText>
            </View>
            <View style={[CommonStyles.flexRow]}>
              <Image
                style={[
                  CommonStyles.containerBudgetApprovalCard_ImageDate,
                  CommonStyles.marginTop20,
                ]}
                source={GreyClockIcon3x}
              />
              <StandardText
                style={[
                  CommonStyles.customFont,
                  CommonStyles.font14,
                  CommonStyles.marginTop20,
                  CommonStyles.customFontV,
                ]}
              >
                {`${formatDDMMMYYDate(plan.startDate)} - ${formatDDMMMYYDate(
                  plan.endDate,
                )}`}
              </StandardText>
            </View>
          </View>
          <View
            style={[
              CommonStyles.justifyFlexColumnEnd,
              CommonStyles.flex05,
              CommonStyles.marginTop20,
            ]}
          >
            {plan.planStatus === types.PLAN_STATUS.CLAIMABLE &&
            plan.planClaimUntilDate ? (
              <View
                style={[
                  CommonStyles.flexRow,
                  CommonStyles.justifyContentFlexEnd,
                ]}
              >
                <StandardText
                  style={[
                    CommonStyles.customFont,
                    CommonStyles.font14,
                    CommonStyles.customFontV,
                    CommonStyles.textAlignRight,
                  ]}
                >
                  {types2.CLAIM_UNTIL}
                </StandardText>
                <StandardText
                  style={[
                    CommonStyles.marginLeft10,
                    CommonStyles.customFont,
                    CommonStyles.font14,
                    CommonStyles.customFontV,
                    CommonStyles.textAlignRight,
                  ]}
                >
                  {plan.planClaimUntilDate
                    ? `${formatDDMMMYYDate(plan.planClaimUntilDate)}`
                    : ``}
                </StandardText>
              </View>
            ) : null}

            <View
              style={[
                CommonStyles.flexRow,
                CommonStyles.justifyContentFlexEnd,
                CommonStyles.paddingTop10,
                plan.planStatus === types.PLAN_STATUS.ACTIVE
                  ? CommonStyles.paddingTop80
                  : null,
                plan.planStatus === types.PLAN_STATUS.IN_ACTIVE
                  ? CommonStyles.paddingTop50
                  : null,
              ]}
            >
              {plan.planStatus === types.PLAN_STATUS.CLAIMABLE ? (
                <Image
                  style={[CommonStyles.planAmountInfoButtonImage]}
                  source={InformationIconx3}
                />
              ) : null}

              <CurrencyText
                value={
                  plan.planStatus === types.PLAN_STATUS.CLAIMABLE
                    ? plan.totalBudget.unspent
                    : plan.totalBudget.total
                }
                isPositive
                integerStyle={
                  plan.planStatus === types.PLAN_STATUS.ACTIVE
                    ? [
                        CommonStyles.font27,
                        CommonStyles.documentTitleColor,
                        CommonStyles.customFontInput,
                      ]
                    : [
                        CommonStyles.font23,
                        CommonStyles.customFont,
                        CommonStyles.documentTitleColor,
                      ]
                }
                decimalStyle={
                  plan.planStatus === types.PLAN_STATUS.ACTIVE
                    ? [
                        CommonStyles.font20,
                        CommonStyles.customFontInput,
                        CommonStyles.documentTitleColor,
                      ]
                    : [
                        CommonStyles.font18,
                        CommonStyles.customFont,
                        CommonStyles.documentTitleColor,
                      ]
                }
                containerStyle={[
                  CommonStyles.flexDirectionRow,
                  CommonStyles.textAlignRight,
                ]}
              />
            </View>

            <StandardText
              style={[
                CommonStyles.customFont,
                plan.planStatus === types.PLAN_STATUS.ACTIVE
                  ? CommonStyles.font20
                  : CommonStyles.font18,
                CommonStyles.textAlignRight,
                CommonStyles.documentTitleColor,
              ]}
            >
              {plan.planStatus === types.PLAN_STATUS.CLAIMABLE
                ? 'Funding remaining'
                : 'Total funding'}
            </StandardText>
          </View>
        </StandardTouchableOpacity>
      ));
    } else {
      return null;
    }
  };

  _getStyleByDeviceInfo = (tab: any, normal: any) =>
    DeviceInfo.isTablet() ? tab : normal;

  tappedTile = (index: any) => {
    this.props.actions.navigationParamActions.setParam({
      planKey: this.props.budget.plansObject[index]
        ? this.props.budget.plansObject[index].planKey
        : '',
      planStatus: this.props.budget.plansObject[index]
        ? this.props.budget.plansObject[index].planStatus
        : '',
      navigateFrom: PREVIOUS_PLANS,
      planClaimUntilDate:
        this.props.budget.plansObject[index].planClaimUntilDate,
    });
    this.props.navigation.navigate(BUDGETS_AND_PAYMENTS_SCREEN, {
      navigateFrom: PREVIOUS_PLANS,
    });
  };
}

const mapStateToProps = (state: any) => ({
  navigationParams: state.NavigationParamsReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    navigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviousPlans);
