/*
 * Author: Shelan Cooray
 * Date: 10/12/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The goal preview component to display selected
 * goal details in goal progress(Goal Tracker) screen
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import {FILE_TYPE} from '../../Constants/Constants2';

import {StandardText} from '../Atoms';
import {PhotoTile} from '../Molecules';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class GoalProgressPreviewSummary extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <View style={CommonStyles.flexDirectionRow}>
        <View style={CommonStyles.oneContainer}>
          <View style={CommonStyles.flexDirectionRow}>
            <PhotoTile
              style={{width: 75, height: 75}}
              bottomMargin={false}
              apiFileType={FILE_TYPE.GOAL_PHOTO}
              imageObject={this.props.goalItem.goalPhoto}
              showCaption={false}
              clickable={false}
              photoBorderRadius={CommonStyles.borderRadiusRound}
              lastUpdate={this._lastUpdateTime(this.props.goalItem)}
            />
            <View style={[CommonStyles.flex1, CommonStyles.marginTop10]}>
              {this.renderGoalName()}
              {this.renderGoalSteps()}
            </View>
          </View>
        </View>
      </View>
    );
  }

  renderGoalName = () => {
    const goalItem = this.props.goalItem;
    if (goalItem.goalName) {
      return (
        <StandardText
          style={[
            CommonStyles.SummaryTileText,
            BrandStyles.TextColor5,
            CommonStyles.customFontBold,
          ]}
        >
          {goalItem.goalName}
        </StandardText>
      );
    } else {
      return null;
    }
  };

  renderGoalSteps = () => {
    const goalItem = this.props.goalItem;
    let currentGoalSteps = goalItem.currentGoalSteps;
    if (currentGoalSteps === undefined) {
      return null;
    } else {
      currentGoalSteps = currentGoalSteps.slice(0, 4);
      let counter = 1;
      let displayString = '';
      for (let i = 0; i < currentGoalSteps.length; i++) {
        if (displayString != '') {
          displayString += '\n' + counter + '. ' + currentGoalSteps[i].stepName;
        } else {
          displayString += counter + '. ' + currentGoalSteps[i].stepName;
        }
        counter++;
      }
      return (
        <View style={[CommonStyles.flexDirectionRow, CommonStyles.marginTop10]}>
          <StandardText
            style={[
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
            ]}
          >
            {displayString}
          </StandardText>
        </View>
      );
    }
  };

  _lastUpdateTime = (goalItem: any) => {
    if (
      goalItem.goalPhoto !== undefined &&
      goalItem.imageUploadObject === undefined &&
      goalItem.goalPhoto.lastModified
    ) {
      return new Date(goalItem.goalPhoto.lastModified);
    } else {
      return new Date();
    }
  };
}

export default GoalProgressPreviewSummary;
