/*
 * Author: Sujeban Elankeswaran
 * Date: 06/01/2020
 * Copyright © 2020 Leap in!. All rights reserved.
 *
 * The ReferralProvidersList flat list component to render all the providers using the ReferralListItem.
 */

import React from 'react';
import {FlatList} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {ReferralListItem} from './';
import * as ProviderActions from '../../Actions/ProviderActions';

class ReferralProvidersList extends React.Component {
  render() {
    return (
      <React.Fragment>
        <FlatList
          data={this.props.providers.providerList}
          renderItem={item => <ReferralListItem {...this.props} data={item} />}
          keyExtractor={item => item.id}
          extraData={this.props}
        />
      </React.Fragment>
    );
  }
}

//For the reducer connection
const mapStateToProps = (state: any) => ({
  providers: state.ProviderReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    ProviderActions: bindActionCreators(ProviderActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReferralProvidersList);
