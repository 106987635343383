/**
 * Author: Brad D'Costa
 * Date: 19/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Function to determine whether the logged in user has read or full access to budget data
 *
 * @returns {boolean} true if the user has read or edit access
 */

import {orderBy} from 'lodash';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const hasBudgetAccess = (memberReducer: any) => {
  if (Object.getOwnPropertyNames(memberReducer).length === 0) {
    return false;
  }
  const memberObj = memberReducer;
  if (memberObj.access && memberObj.access.finances) {
    if (
      memberObj.access.finances === types.ACCESS_READ_ONLY ||
      memberObj.access.finances === types.ACCESS_FULL
    ) {
      return true;
    }
  }

  return false;
};

// Helper function to store budget category related data into redux store
export const filterBudgetCategories = (budgetsPayload: any) => {
  let mappedCategories: any = [];
  const coreCategories = budgetsPayload.map((item: any) => {
    const budgetCategory = {};

    budgetCategory.name = item.Description;
    budgetCategory.categoryId = item.SupportCategoryId;
    budgetCategory.color = item.Colour;
    budgetCategory.secondaryColor = item.SecondaryColour;
    budgetCategory.icon = item.Icon;

    return budgetCategory;
  });

  mappedCategories = mappedCategories.concat(coreCategories);

  return orderBy(mappedCategories, ['categoryId'], ['asc']);
};
