/*
 * Author: Josh Merkas
 * Date: 29/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The buttons used on the documents screen - edit, download, delete
 */

import React, {Component} from 'react';
import {View, StyleSheet, Image, TouchableHighlight} from 'react-native';
import {editIcon, downloadIcon, deleteIcon} from '../../assets/images';
import {StandardTouchableOpacity, StandardText} from '../Atoms';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class EditIconComponent extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <StandardTouchableOpacity
        accessibilityLabel={this.props.iconText}
        style={this._determineStyle()}
        onPress={() => {
          this.props.iconPressCallback(this.props.editDoc);
        }}
      >
        <StandardText
          style={[
            CommonStyles.customFontMedium,
            BrandStyles.TextColor6,
            CommonStyles.SummaryTileHeaderButtonText,
          ]}
        >
          {this.props.iconText}
        </StandardText>
        <Image
          style={[CommonStyles.summaryTileButtonIcon]}
          source={this.props.editIconImage}
        />
      </StandardTouchableOpacity>
    );
  }

  _determineStyle = () => {
    let exportedStyles = [style.editView];
    if (!this.props.notCentered) {
      exportedStyles.push(CommonStyles.justifyCenter);
    } else {
      exportedStyles.push(CommonStyles.justifyEnd);
    }
    return exportedStyles;
  };
}

const style = StyleSheet.create({
  editView: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
});

export default EditIconComponent;
