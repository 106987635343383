/*
 * Author: Brad D'Costa
 * Date: 18/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * logged in user actions for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const actionGetUser = (userObj: any) => ({
  type: types.GET_USER,
  userObj,
});

export const actionUpdateUserPlanType = (planType: any) => ({
  type: types.UPDATE_USER_PLAN_TYPE,
  planType,
});

export const actionUpdateSignUpType = (signUpType: any) => ({
  type: types.UPDATE_USER_SIGNUP_TYPE,
  signUpType,
});

export const actionUpdateIsMember = (isMember: any) => ({
  type: types.UPDATE_USER_ISMEMBER,
  isMember,
});

export const actionUpdateIsCrew = (isCrew: any) => ({
  type: types.UPDATE_USER_ISCREW,
  isCrew,
});

export const actionUpdateNumberOfMembers = (numberofmembers: any) => ({
  type: types.UPDATE_USER_NUMBEROFMEMBERS,
  numberofmembers,
});

export const setFirstTimeSignUp = (isFirstTime, passwordChallenge) => ({
  type: types.SET_FIRST_TIME_SIGN_UP,
  isFirstTime,
  passwordChallenge,
});

export const setGuideStatus = (isOnboardingGuideRequired: any) => ({
  type: types.UPDATE_USER_GUIDE_STATUS,
  isOnboardingGuideRequired,
});

export const actionGetMostRecentMembers = (memberObj: any) => ({
  type: types.GET_MOST_RECENT_MEMBERS,
  memberObj,
});

export const actionUpdateUser = (userObj: any) => ({
  type: types.UPDATE_USER,
  userObj,
});
