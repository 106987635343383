/*
 * Author: Declan Hart
 * Date: 18/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Admin reducer for feature flags
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function AdminReducer(state = {}, action: any) {
  switch (action.type) {
    case types.GET_FEATURE_FLAGS:
      return {
        ...state,
        featureFlags: action.flagsObj,
      };
    default:
      return state;
  }
}
