/*
 * Author: Anuja Ulpathakubura
 * Date: 01/11/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Web Service class to handle the rating API calls
 */

import {
  configuredAWSLamda,
  buildLambda,
  parseResult,
  apiErrorHandler,
} from './APIHelper';
import {AWS_LAMBDA_SERVICES} from '../environments';

// Imports for token and device information
import {Platform} from 'react-native';

import AWS from 'aws-sdk';
import {getVersion} from '../Helpers/PlatformSynchronizer';
import logger from 'helpers/Logger';

/*
 * Insert a rating against provider
 * @param {object} the rating details object
 * @returns {Promise<object>} The result of the rating
 */
export const postRating = (requestObj: any, autoHandleError = true) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const putUserByCognitoId = configuredAWSLamda();
      const requestBody = requestObj;

      // Add in the version into the request body
      requestBody.version = getVersion;
      const functionName = 'postRating';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.CUSTOMERFEEDBACK;
      const params = buildLambda(requestBody, functionName, serviceName);
      // const startTime = new Date().getTime();
      putUserByCognitoId.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const putRating = (requestObj: any, autoHandleError = true) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const putUserByCognitoId = configuredAWSLamda();
      const requestBody = requestObj;

      // Add in the version into the request body
      requestBody.version = getVersion;
      const functionName = 'putRating';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.CUSTOMERFEEDBACK;
      const params = buildLambda(requestBody, functionName, serviceName);
      // const startTime = new Date().getTime();
      putUserByCognitoId.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

export const createRatingObject = (
  type: any,
  ratingValue: any,
  comment: any,
  providerKey: any,
  providerName: any,
  itemKey: any,
  itemNumber: any,
) => {
  const ratingObject = {
    type,
    ratingValue,
    comment,
    providerKey,
    providerName,
    itemKey,
    itemNumber,
  };
  return ratingObject;
};

export const createRatingUpdateObject = (
  id: any,
  ratingValue: any,
  comment: any,
) => {
  const ratingUpdateObject = {
    id,
    ratingValue,
    comment,
  };
  return ratingUpdateObject;
};
