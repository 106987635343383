/*
 * Author: Andrew Seeley
 * Date: 08/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a standard input component which adds support for the following
 * - Error state with message
 * - Set's a default accessibilitylabel if none is passed for automation testing
 */

import React, {Component} from 'react';
import {View, TextInput, Keyboard, Text} from 'react-native';
import {StandardText} from './';
import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';
import {BrandColors} from '../../Styles/Colours';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {INPUT_CONTROL_ACCESSIBILITY} from '../../Constants/Messages';
import logger from 'helpers/Logger';

let borderStyle: any = [];
class StandardInput extends Component {
  constructor(props: any) {
    super(props);

    this.setRef = this.setRef.bind(this);
    this.state = {
      placeholder: props.value !== null && props.value.length === 0,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event: any) {
    // This is to determine the placeholder text to resize on web
    // StandardInput fields
    if (types.isWeb && this.props.disabledPlaceHolderStyleChange) {
      this.setState({
        placeholder: true,
      });
    } else {
      this.setState({
        placeholder:
          event.nativeEvent.text !== null &&
          event.nativeEvent.text.length === 0,
      });
      this.props.onChange && this.props.onChange(event);
    }
  }

  render() {
    let applyWrapper;
    let noMargin;

    // If there is no error don't display a border, otherwise show a border if an error message is passed
    if (this.props.errorMessage != '' && this.props.errorMessage != undefined) {
      borderStyle = [{borderWidth: 3}, BrandStyles.errorBorder];
    } else {
      if (!this.props.noBorder) {
        borderStyle = [{borderWidth: 1}];
        if (this.props.border) {
          borderStyle.push(this.props.border);
        }
      }
    }

    if (this.props.applyWrapper == undefined) {
      applyWrapper = true;
    } else {
      applyWrapper = this.props.applyWrapper;
    }

    if (this.props.noMargin == undefined) {
      noMargin = false;
    } else {
      noMargin = this.props.noMargin;
    }

    let readOnly = false;
    if (
      this.props.readOnly == types.ACCESS_READ_ONLY ||
      this.props.readOnly === true
    ) {
      readOnly = true;
    } else {
      readOnly = false;
    }

    if (this.props.disabled == true) {
      readOnly = true;
    }

    let readOutLabel =
      this.props.accessibilityLabel === this.props.placeholder &&
      this.props.value.length == 0
        ? ''
        : this.props.accessibilityLabel;
    // If there is no acessibility label set use the placeholder text (For automation testing)
    // Otherwise use the label

    const {placeholderStyle, style, onChange, ...props} = this.props;

    //This is for Web App to replace readonly fields with just Text values
    if (types.isWeb && readOnly) {
      let value = this.props.placeholder;
      if (this.props.value.length > 0) {
        value = this.props.value;
      }

      return (
        <View style={this._mainStyles(noMargin)}>
          <View style={[CommonStyles.ModalTextInputContainer]}>
            <Text {...props} style={this._InputStyles(readOnly)}>
              {value}
            </Text>
          </View>
        </View>
      );
    }

    if (applyWrapper) {
      return (
        <View style={this._mainStyles(noMargin)}>
          <View style={[CommonStyles.ModalTextInputContainer]}>
            <TextInput
              {...props}
              //editable={!readOnly}
              readOnly={readOnly}
              placeholderTextColor={BrandColors.RESPONSIVE_DESIGN_COLORS.GREY}
              ref={input => this.setRef(input)}
              onChange={this.handleChange}
              style={
                this.state.placeholder && types.isWeb
                  ? [
                      this._InputStyles(readOnly),
                      placeholderStyle
                        ? placeholderStyle
                        : CommonStyles.placeholderText,
                    ]
                  : this._InputStyles(readOnly)
              }
              accessibilityLabel={
                readOnly
                  ? readOutLabel + INPUT_CONTROL_ACCESSIBILITY.DISABLED
                  : this.props.mandatory
                  ? readOutLabel + INPUT_CONTROL_ACCESSIBILITY.MANDATORY
                  : readOutLabel
              }
            />
          </View>
          {this._renderErrorMessage()}
        </View>
      );
    } else {
      return (
        <View style={this._mainStyles(noMargin)}>
          <TextInput
            {...props}
           // editable={!readOnly}
            readOnly={readOnly}
            placeholderTextColor={BrandColors.RESPONSIVE_DESIGN_COLORS.GREY}
            ref={input => this.setRef(input)}
            onChange={this.handleChange}
            style={
              this.state.placeholder && types.isWeb
                ? [
                    this._InputStyles(readOnly),
                    placeholderStyle
                      ? placeholderStyle
                      : CommonStyles.placeholderText,
                  ]
                : this._InputStyles(readOnly)
            }
          />
          {this._renderErrorMessage()}
        </View>
      );
    }
  }

  _InputStyles = (readOnly: any) => {
    let exportedStyles = [
      CommonStyles.customFontInput,
      CommonStyles.font18,
      CommonStyles.inputFieldWrapper,
      BrandStyles.TextColor2,
      this.props.style,
    ];
    if (readOnly) {
      exportedStyles.push(BrandStyles.readOnlyTextfieldGrey);
    }
    if (types.isWeb) {
      exportedStyles.push(CommonStyles.padding15);
    }
    exportedStyles = [...exportedStyles, ...borderStyle];
    return exportedStyles;
  };

  setRef = (textInput: any) => {
    if (this.props.onRef) {
      this.props.onRef(textInput);
    }
  };

  _mainStyles = (noMarginFlag: any) => {
    const styles = [CommonStyles.width100];
    if (!noMarginFlag) {
      styles.push(CommonStyles.marginBottom20);
    }
    return styles;
  };
  _renderErrorMessage = () => {
    if (this.props.errorMessage) {
      return (
        <StandardText
          accessibilityLabel={this.props.errorMessage}
          style={[CommonStyles.inputErrorMessageText, CommonStyles.customFont]}
        >
          {this.props.errorMessage}
        </StandardText>
      );
    } else {
      return null;
    }
  };
}

StandardInput.defaultProps = {
  value: '',
};

export default StandardInput;
