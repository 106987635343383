import React from 'react';
import {View, Text, Image, StyleSheet} from 'react-native';
import PropTypes from 'prop-types';

import CommonStyles from '../../Styles/CommonStyles';

export const NotificationBadge = (props: any) => (
  <View style={[CommonStyles.zIndex03Notification]}>
    {props.count > 0 && (
      <View style={props.badgeContainerStyle}>
        <View
          style={[
            CommonStyles.tabIconBadge,
            props.bgColor,
            CommonStyles.zIndex03NotificationIconBadge,
          ]}
        >
          <Text style={[CommonStyles.tabIconBadgeText, props.txtColor]}>
            {props.count}
          </Text>
        </View>
      </View>
    )}
  </View>
);

NotificationBadge.propTypes = {
  count: PropTypes.number.isRequired,
};

NotificationBadge.defaultProps = {
  badgeContainerStyle: CommonStyles.tabIconBadgeWrapper,
};

export default NotificationBadge;
