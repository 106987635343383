/*
 * Author: Gayan
 * Date: 12/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Favourite Things Screen, a Favourite Things placeholder screen.
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {StandardInput} from '../Components/Atoms';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {MESSAGES} from '../Constants/Messages';

import {BasicForm} from '../Components/Organisms';
import {
  Container,
  CheckBoxButton,
  NumberSpinner,
  FormTitle,
  FormSubtitle,
  GridView,
} from '../Components/Molecules';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';

import {HomePurple} from '../assets/images/vector-icons';

import * as MemberActions from '../Actions/MemberActions';
import * as GeneralActions from '../Actions/GeneralActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';

class WhereILiveHomeForm extends Component {
  constructor(props: any) {
    super(props);
    this._save = this._save.bind(this);
  }

  state = {
    modalData: this.props.navigationParams.params.modalData,
    liveInArray: this.props.navigationParams.params.liveInArray,
    liveWithArray: this.props.navigationParams.params.liveWithArray,
    loading: false,
    initialFormState: {},
  };

  componentDidMount() {
    this._setInitialState();
  }

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.HOME}
        hasFormContentChanged={this._hasFormContentChanged}
        currentScreenName={WhereILiveHomeForm.name}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    const paramValues = this.props.navigationParams.params;
    return (
      <BasicForm
        headerDisplayString={MESSAGES.WHERE_I_LIVE_SCREEN.HOME_TILE_TITLE}
        headerIconImage={HomePurple}
        close={paramValues.close}
        save={this._save}
        disableMore={true}
        readOnly={paramValues.readOnly}
        showButton={
          paramValues.readOnly == types.ACCESS_READ_ONLY ? false : true
        }
        hideActionButton={true}
        buttonsPositionTop={true}
        contentWidthFull={true}
      >
        <View style={[CommonStyles.flex1, CommonStyles.paddingBottom10]}>
          <FormTitle
            style={types.isWeb ? [CommonStyles.font15] : null}
            text={MESSAGES.WHERE_I_LIVE_SCREEN.HOME_MODAL_HOME_TYPE_TITLE}
            containsData={this.state.liveInArray}
          />
          <FormSubtitle
            text={[MESSAGES.WHERE_I_LIVE_SCREEN.HOME_MODAL_HOME_TYPE_SUBTITLE]}
          />
          {this._renderHomeTypes()}
          <FormTitle
            style={types.isWeb ? [CommonStyles.font15] : null}
            text={MESSAGES.WHERE_I_LIVE_SCREEN.HOME_MODAL_LIVE_WITH_TITLE}
            containsData={this.state.liveWithArray}
          />
          <FormSubtitle
            style={types.isWeb ? [CommonStyles.font15] : null}
            text={[MESSAGES.WHERE_I_LIVE_SCREEN.HOME_MODAL_LIVE_WITH_SUBTITLE]}
          />
          {this._renderLivingWith()}
          <FormTitle
            text={
              MESSAGES.WHERE_I_LIVE_SCREEN.HOME_MODAL_NUMBER_OF_PEOPLE_TITLE
            }
            containsData={
              paramValues.modalData
                ? paramValues.modalData.numberOfPeople
                : null
            }
          />
          {this._renderNumberSpinner()}
        </View>
      </BasicForm>
    );
  };

  _save() {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({loading: true});
    this.props.navigationParams.params.save();
  }

  _renderHomeTypes() {
    if (
      !this.state ||
      !this.state.modalData ||
      !this.state.modalData.homeTypes
    ) {
      return null;
    } else {
      return (
        <GridView
          viewWidth={this.props.viewWidth}
          isMobile={this.props.isMobile}
          itemDimension={400}
        >
          {this._renderHomeTypesArray()}
        </GridView>
      );
    }
  }

  _renderHomeTypesArray = () => {
    return this.state.modalData.homeTypes.map((homeType: any, index: any) => {
      return (
        <CheckBoxButton
          readOnly={this.props.navigationParams.params.readOnly}
          key={homeType.type}
          data={homeType}
          circular={true}
          index={index}
          onChange={(checkBoxObj: any) => {
            this._updateHomeTypes(checkBoxObj);
          }}
        />
      );
    });
  };

  _updateHomeTypes = (checkBoxObj: any) => {
    let tempSelectedHomeType: any = [];
    //trigger update of homeTypes array
    var homeTypesArray = this.state.modalData.homeTypes.slice();

    for (var i = 0; i < homeTypesArray.length; i++) {
      if (homeTypesArray[i].type != checkBoxObj.type) {
        homeTypesArray[i].selected = false;
      }
    }
    homeTypesArray.forEach(function (item: any) {
      if (item.selected == true) {
        tempSelectedHomeType.push(item);
      }
    });
    this.props.navigationParams.params.setSelectedLiveIn(tempSelectedHomeType);
    var stateObj = this.state.modalData;
    stateObj.homeTypes = homeTypesArray.slice();
    this.setState({modalData: stateObj, liveInArray: tempSelectedHomeType});
  };

  _renderLivingWith() {
    if (
      !this.state ||
      !this.state.modalData ||
      !this.state.modalData.livingWith
    ) {
      return null;
    } else {
      return (
        <GridView
          viewWidth={this.props.viewWidth}
          isMobile={this.props.isMobile}
          itemDimension={400}
        >
          {this._renderLivingWithArray()}
        </GridView>
      );
    }
  }

  _renderLivingWithArray = () => {
    return this.state.modalData.livingWith.map(
      (livingWith: any, index: any) => {
        return (
          <CheckBoxButton
            readOnly={this.props.navigationParams.params.readOnly}
            key={livingWith.type}
            data={livingWith}
            index={index}
            circular={true}
            onChange={(checkBoxObj: any) => {
              this._updateLivingWith(checkBoxObj);
            }}
          />
        );
      },
    );
  };

  _updateLivingWith = (checkBoxObj: any) => {
    let tempSelectedLivingWith: any = [];
    //trigger update of livingWith array
    var livingWithArray = this.state.modalData.livingWith.slice();
    livingWithArray.forEach(function (item: any) {
      if (item.selected == true) {
        tempSelectedLivingWith.push(item);
      }
    });
    this.props.navigationParams.params.setSelectedLiveWith(
      tempSelectedLivingWith,
    );

    var stateObj = this.state.modalData;
    stateObj.livingWith = livingWithArray.slice();
    this.setState({
      modalData: stateObj,
      liveWithArray: tempSelectedLivingWith,
    });
  };

  _renderNumberSpinner() {
    if (
      !this.state ||
      !this.state.modalData ||
      !this.state.modalData.homeTypes
    ) {
      return null;
    } else {
      return (
        <NumberSpinner
          readOnly={this.props.navigationParams.params.readOnly}
          min={0}
          default={this.state.modalData.numberOfPeople}
          showBorder={false}
          color="#ffffff"
          buttonTextColor="#222538"
          numColor="#6D6E79"
          underlayColor="#bdbec3"
          onNumChange={(num: any) => {
            this._updateNumberSpinner(num);
          }}
        />
      );
    }
  }

  _updateNumberSpinner = (newValue: any) => {
    var stateObj = this.state.modalData;
    stateObj.numberOfPeople = newValue;
    this.setState({modalData: stateObj});
  };

  _setInitialState = () => {
    var noOfPeople =
      this.props.navigationParams.params.modalData.numberOfPeople;
    this.setState({
      initialFormState: {
        modalData: this.props.navigationParams.params.modalData,
        liveInArray: this.props.navigationParams.params.liveInArray,
        liveWithArray: this.props.navigationParams.params.liveWithArray,
        numberOfPeople: noOfPeople,
      },
    });
  };

  _hasFormContentChanged = () => {
    let initialFormState = this.state.initialFormState;
    let currentFormState = {
      modalData: this.state.modalData,
      liveInArray: this.state.liveInArray,
      liveWithArray: this.state.liveWithArray,
      numberOfPeople:
        this.props.navigationParams.params.modalData.numberOfPeople,
    };
    return (
      JSON.stringify(initialFormState) !== JSON.stringify(currentFormState)
    );
  };
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
  general: state.GeneralReducer,
  sideMenu: state.SideMenuReducer,
  navigationParams: state.NavigationParamsReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    MemberActions: bindActionCreators(MemberActions, dispatch),
    GeneralActions: bindActionCreators(GeneralActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WhereILiveHomeForm);
