import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const loadNotifications = (notifications: any) => ({
  type: types.LOAD_NOTIFICATIONS,
  notifications,
});

export const notificationClick = (status: any) => ({
  type: types.CLICK_NOTIFICATIONS,
  status,
});

export const notificationPageNumber = (pageNumber: any) => ({
  type: types.NOTIFICATIONS_PAGE_NUMBER,
  pageNumber,
});
