/*
 * Author: Shelan Cooray
 * Date: 23/05/2019
 * Copyright © 2019 Leap in!. All rights reserved.
 *
 * Custom Summary tile for WEB to facilitate file upload functionality
 */

import React, {Component} from 'react';
import {Image, View, AppState, Platform} from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import logger from 'helpers/Logger';
import * as OfflineNoticeActions from '../../Actions/OfflineNoticeActions';
import {StandardTouchableOpacity, StandardText} from '../Atoms';
import {ActionButton} from './';
import CustomIcon from '../../assets/images/CustomIcon';

// Import Stylesheets
import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';
import {BrandColors} from '../../Styles/Colours';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {FilePickerSingleton} from '../../Helpers/PlatformSynchronizer';

import {MissingInformation3X, InformationIconx3} from '../../assets/images';

class SummaryTileWebUpload extends Component {
  filePickerRef: any;
  state = {
    isConnected: true,
  };
  subscription: any;

  constructor(props: any) {
    super(props);
    this._buttonOnPress = this._buttonOnPress.bind(this);
  }

  componentDidMount() {
    NetInfo.addEventListener(this.handleConnectivityChange);
    NetInfo.fetch().then(isConnected => {
      this.setState({isConnected: isConnected});
    });
    this.subscription= AppState.addEventListener('change', this._handleAppStateChange);
  }



  componentWillUnmount() {
    // AppState.removeEventListener('change', this.onAppStateChange);
    if (this.subscription) {
      this.subscription.remove();
    }
  }

  handleConnectivityChange = (isConnected: any) => {
    this.setState({isConnected: isConnected});
  };

  _handleAppStateChange = (nextAppState: any) => {
    if (nextAppState) {
      NetInfo.fetch().then(isConnected => {
        this.setState({isConnected: isConnected});
      });
    }
  };

  _buttonOnPress = () => {
    this.props.actions.OfflineNoticeActions.shakeOfflineNotice();
  };

  render() {
    if (this.props.showButton) {
      return (
        <FilePickerSingleton
          onRef={(ref: any) => {
            if (types.isWeb && ref) {
              this.filePickerRef = ref;
            }
          }}
          maxSize={1000}
          style={{backgroundColor: BrandColors.WHITE}}
          onChange={(event: any) => {
            if (types.isWeb) {
              event.persist();
              if (
                event.target &&
                event.target.files &&
                event.target.files.length
              ) {
                this.props.webFileUpload(event.target.files[0]);
              }
            }
          }}
          onError={(errMsg: any) => {
            logger.log('Error', errMsg);
          }}
        >
          <StandardTouchableOpacity
            accessibilityLabel={
              this.props.accessibilityLabel + ' ' + this.props.actionType
            }
            activeOpacity={0.6}
            onPress={() => {
              if (types.isWeb && this.filePickerRef) {
                this.filePickerRef.click();
              }
            }}
          >
            {this._mainComponent()}
          </StandardTouchableOpacity>
        </FilePickerSingleton>
      );
    } else {
      return this._mainComponent();
    }
  }

  _mainComponent = () => {
    return (
      <View style={this._determineContainerStyle()}>
        {this._renderImage(this.props.imageSource)}
        <View
          style={[
            CommonStyles.flex1,
            CommonStyles.flexDirectionRow,
            types.isWeb ? CommonStyles.marginLeft10 : null,
            Platform.OS === types2.IOS
              ? CommonStyles.marginRight20
              : CommonStyles.marginRight40,
          ]}
        >
          <View style={CommonStyles.flexShrink1}>
            <StandardText style={this._determineTextStyle()}>
              {this.props.displayString}
            </StandardText>
          </View>
          {this._renderYellowStar()}
        </View>
        {this.props.hideActionButton ? null : (
          <View style={CommonStyles.alignFlexStart}>
            {this._renderActionButton()}
          </View>
        )}
      </View>
    );
  };

  _renderYellowStar() {
    if (!this.props.hideYellowStar && !this.props.containsData) {
      return (
        <View>
          <Image
            style={[CommonStyles.yellowStar]}
            source={
              this.props.hasError ? InformationIconx3 : MissingInformation3X
            }
          />
        </View>
      );
    }
  }

  _renderActionButton = () => {
    if (this.props.customAddButton) {
      return this.props.customAddButton;
    }
    if (this.props.showButton) {
      return (
        <ActionButton
          actionType={this.props.actionType}
          showButtonTitle={this.props.showButtonTitle}
          showIconString={this.props.showIconString}
          imageActionIcon={this.props.imageActionIcon}
        />
      );
    }
    return null;
  };

  _renderImage = (imageSource: any) => {
    let styles = [];
    if (this.props.iconStyle) {
      styles = this.props.iconStyle;
    } else {
      styles = [CommonStyles.titleBoxIcon, CommonStyles.titleBoxIconMargin];
    }

    styles.push(this._determineTextColor());
    if (this.props.imageIcon) {
      return (
        <Image
          accessible={false}
          source={this.props.imageSource}
          style={styles}
        />
      );
    } else {
      if (this.props.imageSource != undefined) {
        return (
          <CustomIcon accessible={false} name={imageSource} style={styles} />
        );
      } else {
        return null;
      }
    }
  };

  _determineTextColor = () => {
    const styles = [];
    if (this.props.textColor) {
      styles.push(
        this.props.secondLevel ? BrandStyles.TextColor2 : this.props.textColor,
      );
    } else {
      styles.push(BrandStyles.TextColor3);
    }
    return styles;
  };

  _determineTextStyle = () => {
    const styles = [
      CommonStyles.customFontBold,
      CommonStyles.titleBoxIconMargin,
      this._determineTextColor(),
    ];

    if (this.props.fontSize) {
      styles.push(this.props.fontSize);
    } else {
      styles.push(CommonStyles.SummaryTileHeaderText);
    }

    return styles;
  };

  _determineContainerStyle = () => {
    const styles = [
      CommonStyles.alignSelfFlexStart,
      CommonStyles.content,
      CommonStyles.flexRow,
      CommonStyles.marginBottom15,
    ];

    if (this.props.ignorePadding) {
      styles.push(CommonStyles.SummaryTileHeader);
    } else {
      styles.push(CommonStyles.SummaryTileHeaderContainer);
    }
    if (this.props.boarderColor) {
      styles.push(
        this.props.secondLevel
          ? BrandStyles.borderColor2
          : this.props.boarderColor,
      );
    } else {
      styles.push(BrandStyles.borderColor3);
    }
    if (this.props.alignItemsCenter) {
      styles.push(CommonStyles.alignItemsCenter);
    }
    return styles;
  };
}

SummaryTileWebUpload.defaultProps = {
  hasError: false,
};

const mapStateToProps = (state: any) => ({
  offline: state.OfflineNoticeReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    OfflineNoticeActions: bindActionCreators(OfflineNoticeActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SummaryTileWebUpload);
