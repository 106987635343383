import React from 'react';

import {View} from 'react-native';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import {StandardText} from '../Atoms';

import {ORIENTATION} from '../../Constants/Constants2';
import {isTablet} from '../../Helpers/PlatformSynchronizer';
import {COPYRIGHTS} from '../../Constants/BrandConstants';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {isIphoneX} from 'react-native-iphone-x-helper';

const Copyrights = function (props: any) {
  return (
    <View
      style={[
        CommonStyles.flexDirectionRow,
        CommonStyles.copyrightsContainer,
        BrandStyles.copyrightsBarBgColor,
        isIphoneX() && CommonStyles.marginBottom10,
        types.isWeb && CommonStyles.padding10,
      ]}
    >
      <StandardText
        style={[
          CommonStyles.fontWeightBold,
          CommonStyles.flexWrap,
          BrandStyles.brandBlockTxtColorWhite,
          CommonStyles.font18,
        ]}
      >
        {COPYRIGHTS}
      </StandardText>
    </View>
  );
};

export default Copyrights;
