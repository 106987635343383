/*
 * Author: Andrew Lee
 * Date: 03/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is the modal that will be used for the user to be able to select disabilities that affect their lives. It will use an auto complete library to help display a searchable list of items that can be selected. Tapping on the list item will select the item (populate the input field) and then selecting the add will add to the list.
 */

import React, {Component} from 'react';
import {View, StyleSheet, TouchableOpacity} from 'react-native';
import {StandardInput, StandardText} from '../Components/Atoms';

import {CommonActions, StackActions} from '@react-navigation/native';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as types from '../Constants/Constants';

// Import messages
import {DISABILITY as messages} from '../Constants/Messages';

// Import organisms required
import {BasicModal} from '../Components/Organisms';
import {
  ModalSubtitle,
  ModalTitle,
  SummaryTileHeader,
  AutoComplete,
  AboutMeDisabilitySelected,
} from '../Components/Molecules';
import {StandardTouchableOpacity} from '../Components/Atoms';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {callAPIs} from '../API/APICaller';
import {getDisabilities} from '../API/ReferenceAPI';
import {updateMember} from '../API/MemberAPI';

import * as MemberActions from '../Actions/MemberActions';
import * as GeneralActions from '../Actions/GeneralActions';
import {BrandColors} from '../Styles/Colours';

class AboutMeDisabilityModal extends Component {
  state = {
    loading: true,
    disabilitiesRef: [], // This is the reference data for all the disabilities
    disabilitiesSelected: [],
    customDisabilitiesSelected: [],
    disabilitesMoreInfo: '',
    saveApiError: false,
    saveApiErrorCode: null,
    searchText: '',
    keyboardDismissMode: 'none',
  };

  constructor(props: any) {
    super(props);

    this._fetchDisabilityData.bind(this);
    this._fetchDisabilityCallback.bind(this);
    this._saveDisabilityModal.bind(this);
    this._closeDisabilityModal.bind(this);
    this._searchDisabilities.bind(this);
    this._renderSearchItem.bind(this);
    this._removeSelectedDisability.bind(this);
    this._removeSelectedCustomDisability.bind(this);
    this._addDisability.bind(this);
  }

  componentDidMount() {
    // Fetch the reference data as it's mounting
    this._fetchDisabilityData();
  }

  render() {
    const filteredDisabilities = this._searchDisabilities();

    return (
      <BasicModal
        visible={true}
        save={this._saveDisabilityModal}
        readOnly={this.props.member.access.profile}
        close={this._closeDisabilityModal}
        loading={this.state.loading}
        saveApiError={this.state.saveApiError}
        saveApiErrorCode={this.state.saveApiErrorCode}
        keyboardPersist="never"
        keyboardDismiss={this.state.keyboardDismissMode}
      >
        <ModalTitle text={messages.MODAL_TITLE} />
        <AutoComplete
          readOnly={this.props.member.access.profile}
          data={filteredDisabilities}
          listStyle={[CommonStyles.content, styles.listContainer]}
          onChangeText={(text: any) => this.setState({searchText: text})}
          placeholder={messages.INPUT_PLACEHOLDER}
          renderItem={(item: any) => {
            return this._renderSearchItem(item);
          }}
          style={[
            CommonStyles.ModalTextInputWrapper,
            CommonStyles.StandardFontSize,
            styles.inputPadding,
          ]}
          value={this.state.searchText}
          underlineColorAndroid="transparent"
          canAddCustom={true}
          addCustom={this._addCustomDisability}
          onClear={() => {
            // Clear the search text
            this.setState({searchText: ''});
          }}
          onFocus={() => {
            // Since the focus is on the auto complete, any drag should dismiss the keyboard
            this.setState({keyboardDismissMode: 'on-drag'});
          }}
          onBlur={() => {
            // No longer focused on the auto complete, remove the dismiss mode
            this.setState({keyboardDismissMode: 'none'});
          }}
        />
        {this._renderSelectedDisabilities()}
        <SummaryTileHeader
          displayString={messages.MORE_INFO_TITLE}
          accessibilityLabel={messages.MORE_INFO_TITLE}
        />
        <ModalSubtitle
          fontStyle={CommonStyles.customFont}
          text={[messages.MORE_INFO_SUB_TITLE]}
        />
        <StandardInput
          readOnly={this.props.member.access.profile}
          multiline={true}
          numberOfLines={6}
          value={this.state.disabilitesMoreInfo}
          onChangeText={(text: any) =>
            this.setState({disabilitesMoreInfo: text})
          }
          underlineColorAndroid="transparent"
          style={[
            CommonStyles.ModalTextInputWrapper,
            BrandStyles.primaryBgColor1,
            CommonStyles.textAlignVerticalTop,
          ]}
          blurOnSubmit={false}
          height={150}
        />
      </BasicModal>
    );
  }

  _searchDisabilities = () => {
    let searchTerm = this.state.searchText;
    if (searchTerm === '' || searchTerm.length < 2) {
      return [];
    }

    // Need to search through the disabilities on their type
    const {disabilitiesRef} = this.state;
    const regex = new RegExp(`${searchTerm.trim()}`, 'i');

    return disabilitiesRef.filter(
      disability => disability.type.search(regex) >= 0,
    );
  };

  _renderSearchItem = (item: any) => {
    return (
      <StandardTouchableOpacity
        accessibilityLabel={item.type + ' selected'}
        key={item.id}
        style={[styles.listItem]}
        onPress={() => {
          this._addDisability(item);
        }}
      >
        <StandardText
          style={[CommonStyles.customFont, CommonStyles.StandardFontSize]}
        >
          {item.type}
        </StandardText>
      </StandardTouchableOpacity>
    );
  };

  _renderSelectedDisabilities = () => {
    // Go through the list of selected disabilities to render on screen
    const {disabilitiesSelected, customDisabilitiesSelected} = this.state;

    let disabilitesSelectedJSX: any = [];

    let indexCounter = 0;
    disabilitiesSelected.map(disability => {
      disabilitesSelectedJSX.push(
        <AboutMeDisabilitySelected
          key={indexCounter}
          index={indexCounter}
          disability={disability}
          removeDisability={this._removeSelectedDisability}
          readOnly={this.props.member.access.profile}
        />,
      );
      indexCounter++;
    });
    let customDisabilityIndexCounter = 0;
    customDisabilitiesSelected.map(disability => {
      disabilitesSelectedJSX.push(
        <AboutMeDisabilitySelected
          key={'custom_' + customDisabilityIndexCounter}
          index={customDisabilityIndexCounter}
          disability={disability}
          removeDisability={this._removeSelectedCustomDisability}
          readOnly={this.props.member.access.profile}
        />,
      );
      customDisabilityIndexCounter++;
    });

    return (
      <View
        style={[
          styles.selectedDisabilityPadding,
          CommonStyles.flexRow,
          CommonStyles.flexWrap,
        ]}
      >
        {(disabilitiesSelected.length > 0 ||
          customDisabilitiesSelected.length > 0) &&
          disabilitesSelectedJSX}
      </View>
    );
  };

  _removeSelectedDisability = (index: any) => {
    let tempSelectedDisabilities = JSON.parse(
      JSON.stringify(this.state.disabilitiesSelected),
    );

    // Remove the index from the temp selected disabilities
    tempSelectedDisabilities.splice(index, 1);

    // Update the state
    this.setState({
      disabilitiesSelected: tempSelectedDisabilities,
    });
  };

  _removeSelectedCustomDisability = (index: any) => {
    let tempSelectedCustomDisabilities = JSON.parse(
      JSON.stringify(this.state.customDisabilitiesSelected),
    );

    // Remove the index from the temp selected custom disabilities
    tempSelectedCustomDisabilities.splice(index, 1);

    // Update the state
    this.setState({
      customDisabilitiesSelected: tempSelectedCustomDisabilities,
    });
  };

  _fetchDisabilityData = () => {
    // Check to see if there is cached items in redux
    let disabilitiesRef = this.props.general.disabilitiesReference;
    if (disabilitiesRef && disabilitiesRef.disabilities) {
      this._setDisabilityState(disabilitiesRef.disabilities);
    }
    // No cache in redux, so fetch the data from lambda.
    else {
      callAPIs([getDisabilities()], this._fetchDisabilityCallback, null, () => {
        this.setState({loading: false});
      });
    }
  };

  _addDisability = (item: any) => {
    let selectedDisabilites = this.state.disabilitiesSelected;

    // Ensure that the already selected disabilities do not contain the selected item
    let filteredDisabilities = selectedDisabilites.filter(disability => {
      return disability.id == item.id;
    });

    // Check to see if there is an element that already has the same id, and if so, do not add it to the list.
    if (filteredDisabilities.length > 0) {
      this.setState({
        searchText: '',
      });
    }
    // Otherwise it is a new item, so add the item.
    else {
      selectedDisabilites.push(item);
      this.setState({
        disabilitiesSelected: selectedDisabilites,
        searchText: '',
      });
    }
  };
  _addCustomDisability = (item: any) => {
    item = {type: item};

    let selectedDisabilites = this.state.customDisabilitiesSelected;

    // Ensure that the already selected disabilities do not contain the selected item
    let filteredDisabilities = selectedDisabilites.filter(disability => {
      return disability.type == item.type;
    });

    // Check to see if there is an element that already has the same id, and if so, do not add it to the list.
    if (filteredDisabilities.length > 0) {
      this.setState({
        searchText: '',
      });
    }
    // Otherwise it is a new item, so add the item.
    else {
      selectedDisabilites.push(item);
      this.setState({
        customDisabilitiesSelected: selectedDisabilites,
        searchText: '',
      });
    }
  };

  _fetchDisabilityCallback = (data: any) => {
    let disabilityContent = data[0].content.disabilities;

    // Get the disability reference data from the content and save it to redux
    this.props.actions.GeneralActions.getDisabilityReferenceData(
      disabilityContent,
    );

    this._setDisabilityState(disabilityContent);
  };

  _setDisabilityState = (disabilitiesRef: any) => {
    // Deep clone the member's selected disabilities, so that when changing the state, it will not modify the redux model.
    let selectedDisabilites = [];
    if (this.props.member.disabilities) {
      selectedDisabilites = JSON.parse(
        JSON.stringify(this.props.member.disabilities),
      );
    }

    // Show added custom disabilities in disability modal
    let selectedCustomDisabilites = [];
    if (this.props.member.customDisabilities) {
      selectedCustomDisabilites = JSON.parse(
        JSON.stringify(this.props.member.customDisabilities),
      );
    }

    this.setState({
      loading: false,
      disabilitiesRef,
      disabilitiesSelected: selectedDisabilites,
      customDisabilitiesSelected: selectedCustomDisabilites,
      disabilitesMoreInfo: this.props.member.disabilityMoreInfo,
    });
  };

  _closeDisabilityModal = () => {
    this.props.navigation.dispatch(CommonActions.goBack());
  };

  _saveDisabilityModal = () => {
    this.setState({loading: true});

    const {
      disabilitiesSelected,
      customDisabilitiesSelected,
      disabilitesMoreInfo,
    } = this.state;

    // Need to change the disabilities selected into an array of just ids
    let disabilityIds = disabilitiesSelected.map(disabilityItem => {
      return disabilityItem.id;
    });
    let customDisabilities = customDisabilitiesSelected.map(disabilityItem => {
      return disabilityItem.type;
    });

    let memberUpdateObject = {
      id: this.props.member.id,
      disabilities: disabilityIds,
      customDisabilities: customDisabilities,
      disabilityMoreInfo: disabilitesMoreInfo,
    };

    let updateMemberCallback = (data: any) => {
      // Update the member redux with the newly written data
      let clonedDisabilities = JSON.parse(JSON.stringify(disabilitiesSelected));
      let clonedCustomDisabilities = JSON.parse(
        JSON.stringify(customDisabilitiesSelected),
      );

      this.props.actions.MemberActions.actionUpdateMember({
        disabilities: clonedDisabilities,
        disabilityMoreInfo: disabilitesMoreInfo,
        customDisabilities: clonedCustomDisabilities,
      });

      this.setState({loading: false});
      // Navigate back
      this.props.navigation.dispatch(CommonActions.goBack());
    };

    const updateMemberErrorCallback = (err: any) => {
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
        loading: false,
      });
    };

    callAPIs(
      [updateMember(memberUpdateObject, false)],
      updateMemberCallback,
      updateMemberErrorCallback,
    );
  };
}

const styles = StyleSheet.create({
  listContainer: {
    backgroundColor: BrandColors.WHITE,
    borderWidth: 1,
    borderColor: BrandColors.BLACK,
    marginRight: 20,
  },

  listItem: {
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 10,
    paddingBottom: 10,
  },

  inputPadding: {
    paddingLeft: 20,
  },

  selectedDisabilityPadding: {
    paddingTop: 20,
    paddingBottom: 30,
  },
});

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  user: state.UserReducer,
  loadedMemberId: state.loadedMemberReducer,
  general: state.GeneralReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    MemberActions: bindActionCreators(MemberActions, dispatch),
    GeneralActions: bindActionCreators(GeneralActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AboutMeDisabilityModal);
