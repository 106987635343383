/*
 * Authour: Nirodha Perera
 * Date: 08/07/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is the action for guideme for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const getSideMenuContent = (dynamicSideMenuContent: any) => ({
  type: types2.GET_SIDEMENU,
  dynamicSideMenuContent,
});

export const setSideMenuStatus = (sideMenuIsOpen: any) => ({
  type: types2.SIDEMENU_IS_OPEN,
  sideMenuIsOpen,
});

export const setCurrentScreen = (currentScreen: any) => ({
  type: types2.SET_CURRENT_SCREEN,
  currentScreen,
});
