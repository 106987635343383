//Sets Up a Basic Form with a save and cancel button and
//receives some components from parent class (Text, TextInput through (props.children))
import React, {Component} from 'react';
import {
  Text,
  View,
  FlatList,
  StyleSheet,
  ScrollView,
  Image,
  Dimensions,
  AppState,
} from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {isTablet} from '../../Helpers/PlatformSynchronizer';
import * as OfflineNoticeActions from '../../Actions/OfflineNoticeActions';
import {StandardButton, StandardText} from '../Atoms';
import {BrandActivityIndicator, SummaryTile, ModalApiError} from '../Molecules';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

const {height, width} = Dimensions.get('window');
class BasicForm extends Component {
  state = {
    isConnected: true,
    viewContainerDimensions: undefined,
  };
  subscription: any;

  constructor(props: any) {
    super(props);
    this._renderCloseStyle = this._renderCloseStyle.bind(this);
    this._renderCloseContainerStyle =
      this._renderCloseContainerStyle.bind(this);
    this._renderSaveStyle = this._renderSaveStyle.bind(this);
    this._renderCloseContainerStyle =
      this._renderCloseContainerStyle.bind(this);
    this._renderCustomButtonContainerStyle =
      this._renderCustomButtonContainerStyle.bind(this);
    this.renderDeleteButton.bind(this);
    this._setRef.bind(this);
    this._buttonOnPress = this._buttonOnPress.bind(this);
  }

  componentDidMount() {
    if (!types.isWeb) {
      NetInfo.addEventListener(this.handleConnectivityChange);
      NetInfo.fetch().then((isConnected: any) => {
        this.setState({isConnected: isConnected});
      });
    }

    this.subscription=AppState.addEventListener('change', this._handleAppStateChange);
  }

  

  componentWillUnmount() {
    // AppState.removeEventListener('change', this.onAppStateChange);
    if (this.subscription) {
      this.subscription.remove();
    }
  }

  _handleAppStateChange = (nextAppState: any) => {
    if (nextAppState && !types.isWeb) {
      NetInfo.fetch().then((isConnected: any) => {
        this.setState({isConnected: isConnected});
      });
    }
  };

  handleConnectivityChange = (isConnected: any) => {
    if (isConnected) {
      this.setState({isConnected: true});
    } else {
      this.setState({isConnected: false});
    }
  };

  _setRef = (scrollView: any) => {
    if (this.props.onRef) {
      this.props.onRef(scrollView);
    }
  };

  render() {
    let customActionButtons = false;
    if (this.props.customActionButtons != undefined) {
      customActionButtons = this.props.customActionButtons;
    }

    let viewActionButtons = false;
    if (
      this.props.buttonsPositionBottom ||
      (this.state.viewContainerDimensions &&
        this.state.viewContainerDimensions.viewHeight +
          types2.ADDED_VIEW_HEIGHT >=
          height)
    ) {
      viewActionButtons = true;
    }

    let containerStyle = null;
    if (types.isWeb && !this.props.contentWidthFull) {
      containerStyle = CommonStyles.width70;
    }

    return (
      <View onLayout={this._onScreenLayout} style={[CommonStyles.flex1]}>
        {this._renderHeader()}
        <View style={containerStyle}>
          {this.props.saveApiError && (
            <ModalApiError errorCode={this.props.saveApiErrorCode} />
          )}
          {this.props.innerTitle && (
            <StandardText
              style={[
                CommonStyles.fontWeightBold,
                CommonStyles.rpfont15,
                CommonStyles.marginBottom20,
                CommonStyles.customFont,
              ]}
            >
              {this.props.innerTitle}
            </StandardText>
          )}
          {this.props.topDescription && this.props.topDescriptionValue()}
          {this.props.buttonsPositionTop && (
            <View style={CommonStyles.marginBottom10}>
              {this._renderActionButtons(customActionButtons)}
            </View>
          )}
          {this.props.children}
          {this.props.disableButtomButtons || !viewActionButtons
            ? null
            : this._renderActionButtons(customActionButtons)}
        </View>
        <BrandActivityIndicator loading={this.state.loading} />
      </View>
    );
  }

  _onScreenLayout = (event: any) => {
    //Get layout width and height
    const viewWidth = event.nativeEvent.layout.width;
    const viewHeight = event.nativeEvent.layout.height;

    this.setState({viewContainerDimensions: {viewWidth, viewHeight}});
  };

  _renderHeader() {
    let fontSize = CommonStyles.font23;
    if (types.isWeb) {
      fontSize = CommonStyles.font20;
    }
    if (this.props.hideTitle === undefined || !this.props.hideTitle) {
      return (
        <SummaryTile
          headerDisplayString={this.props.headerDisplayString}
          headerIconImage={this.props.headerIconImage}
          HeaderTextColor={
            this.props.HeaderTextColor
              ? this.props.HeaderTextColor
              : BrandStyles.TextColor4
          }
          HeaderBoarderColor={
            this.props.HeaderBoarderColor
              ? this.props.HeaderBoarderColor
              : BrandStyles.borderColor4
          }
          secondLevel={this.props.secondLevel}
          showButtonTitle={this.props.showButtonTitle}
          modal={this.props.modal}
          showButton={this.props.showButton}
          hideYellowStar={true}
          fontSize={this.props.fontSize ? this.props.fontSize : fontSize}
          disableMore={this.props.disableMore}
          actionOverride={this.props.actionOverride}
          hideActionButton={this.props.hideActionButton}
          imageActionIcon={this.props.imageActionIcon}
          imageIcon={this.props.imageIcon}
          containsData={this.props.containsData}
        />
      );
    }
  }

  _renderActionButtons = (customActionButtons: any) => {
    if (customActionButtons) {
      return null;
    } else {
      return (
        <View style={this._renderCustomButtonContainerStyle()}>
          <View style={CommonStyles.ModalFooterLeft}>
            {this.renderCloseButton()}
            {this.renderDeleteButton()}
          </View>
          <View style={CommonStyles.ModalFooterRight}>
            {this.renderSaveButton()}
          </View>
        </View>
      );
    }
  };

  _renderCustomButtonContainerStyle = () => {
    const styles = this.props.buttonsPositionTop
      ? [CommonStyles.formActionButtons]
      : [CommonStyles.ModalActionButtons];

    if (this.props.buttonContainerStyle) {
      styles.push(this.props.buttonContainerStyle);
    }
    if (isTablet && !types.isWeb) {
      styles.push([CommonStyles.marginLeftTab, CommonStyles.marginRightTab]);
    }
    return styles;
  };

  _renderCloseStyle = () => {
    if (this.props.closeStyle != types2.UNDEFINED) {
      return this.props.closeStyle.style;
    } else {
      return [
        BrandStyles.brandBlockTxtColor6,
        CommonStyles.buttonFormAction,
        CommonStyles.customFontBold,
      ];
    }
  };

  _renderCloseContainerStyle = () => {
    if (this.props.closeStyle != types2.UNDEFINED) {
      return this.props.closeStyle.containerStyle;
    } else {
      return [
        BrandStyles.primaryBgColor1,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerFormAction,
      ];
    }
  };

  _renderSaveStyle = () => {
    if (this.props.saveStyle != types2.UNDEFINED) {
      return this.props.saveStyle.style;
    } else {
      return [
        BrandStyles.brandBlockTxtColor5,
        CommonStyles.buttonFormAction,
        CommonStyles.customFontBold,
      ];
    }
  };

  _renderSaveContainerStyle = () => {
    if (this.props.saveStyle != types2.UNDEFINED) {
      return this.props.saveStyle.containerStyle;
    } else {
      return [
        BrandStyles.primaryBgColor4,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerFormAction,
      ];
    }
  };

  _renderDeleteStyle = () => {
    if (this.props.deleteStyle != types2.UNDEFINED) {
      return this.props.deleteStyle.style;
    } else {
      return [
        BrandStyles.brandBlockTxtColor6,
        CommonStyles.buttonFormAction,
        CommonStyles.customFontBold,
      ];
    }
  };

  _renderDeleteContainerStyle = () => {
    if (this.props.deleteStyle != types2.UNDEFINED) {
      return this.props.deleteStyle.containerStyle;
    } else {
      return [
        BrandStyles.primaryBgColor1,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerFormAction,
      ];
    }
  };

  renderCloseButton() {
    if (this.props.close && !this.props.hideCloseButton) {
      let cancelText = types2.CANCEL;
      if (this.props.closeLabel) {
        cancelText = this.props.closeLabel;
      }
      return (
        <StandardButton
          onPress={this.props.close}
          style={this._renderCloseStyle()}
          containerStyle={this._renderCloseContainerStyle()}
          accessibilityLabel={types2.CANCEL}
        >
          {cancelText}
        </StandardButton>
      );
    } else {
      return null;
    }
  }

  renderSaveButton() {
    if (
      this.props.readOnly != undefined &&
      (this.props.readOnly == types.ACCESS_READ_ONLY ||
        this.props.readOnly == types.ACCESS_NONE)
    ) {
      return null;
    } else if (this.props.save) {
      let saveText = 'Save';

      if (this.props.saveLabel) {
        saveText = this.props.saveLabel;
      }

      let saveContainerStyle = [
        CommonStyles.ModalButtonContainer,
        CommonStyles.ModalSaveButton,
      ];

      if (this.props.saveContainerStyle) {
        saveContainerStyle = saveContainerStyle.concat(
          this.props.saveContainerStyle,
        );
      }
      // No style passed in, so default it to the green color.
      else {
        saveContainerStyle.push(BrandStyles.primaryBgColor6);
        saveContainerStyle.push(BrandStyles.borderColor6);
      }

      return (
        <StandardButton
          onPress={
            this.state.isConnected ? this.props.save : this._buttonOnPress
          }
          style={this._renderSaveStyle()}
          containerStyle={this._renderSaveContainerStyle()}
          accessibilityLabel={saveText}
        >
          {saveText}
        </StandardButton>
      );
    } else {
      return null;
    }
  }

  _buttonOnPress = () => {
    this.props.actions.OfflineNoticeActions.shakeOfflineNotice();
  };

  renderDeleteButton() {
    let isDecisionMaker = true;
    let deleteText;

    if (this.props.isDecisionMaker != undefined) {
      isDecisionMaker = this.props.isDecisionMaker;
    }
    if (isDecisionMaker) {
      if (this.props.delete) {
        if (this.props.deleteText) {
          deleteText = this.props.deleteText;
        } else {
          deleteText = 'Delete';
        }

        return (
          <StandardButton
            readOnly={this.props.readOnly}
            onPress={
              this.state.isConnected ? this.props.delete : this._buttonOnPress
            }
            style={this._renderDeleteStyle()}
            containerStyle={this._renderDeleteContainerStyle()}
            accessibilityLabel={deleteText}
          >
            {deleteText}
          </StandardButton>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}

BasicForm.defaultProps = {
  scrollEnabled: true,
};

const mapStateToProps = (state: any) => ({
  offline: state.OfflineNoticeReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    OfflineNoticeActions: bindActionCreators(OfflineNoticeActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicForm);
