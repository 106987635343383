import DonutChart from 'react-svg-donut-chart';
import React from 'react';
import {APP_VERSION, BUILD_VERSION, GA_TRACKING_CODE} from '../environments';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';
import FilePicker from '../Components/Molecules/FilePicker';
import FileUploader from './FileUploader';
import fileDownload from 'js-file-download';
import NumericInput from 'react-numeric-input';
import ReactGA from 'react-ga';
import {GoogleSignin} from '../Helpers/PlatformUtils';
export {createIconSetFromFontello} from 'react-native-web-vector-icons';
export {
  getManufacturer,
  getBrand,
  getModel,
} from 'react-native-device-info';
export const getGoogleSignin = GoogleSignin();

export const compressImage = (imageuri: any) =>
  new Promise((resolve, reject) => {
    resolve(imageuri);
  });

export const FilePickerSingleton = (props: any) => {
  return <FilePicker {...props} />;
};

export const PieChartSVG = (props: any) => {
  return <DonutChart spacing={1} data={props.data} />;
};
export const DocumentPickerSingleton = (pageX: any, pageY: any) =>
  new Promise((resolve, reject) => {});

export const announceForAccessibility = (itemLabel: any) =>
  new Promise((resolve, reject) => {
    resolve(itemLabel);
  });
export const SingletonDatePicker = (props: any) => {
  return <DatePicker {...props} />;
};
export const isTablet = () =>
  new Promise((resolve, reject) => {
    resolve(false)
  });

export const getInitialOrientation = () =>
  new Promise((resolve, reject) => {
    resolve(false)
  });

export const getVersion = APP_VERSION;
export const getBuildNumber = BUILD_VERSION;

export const RNFetchBlobSingleton = null;
export const Firebase = null;
export const PDF = null;
export const RNkeychain = null;
export const FileUploaderSingleton = FileUploader;

export const FileDownloaderSingleton = fileDownload;
export const getTimezone = () => new Date().getTimezoneOffset;

export const SingletonNumberSpinner = (props: any) => {
  return <NumericInput mobile {...props} />;
};

export const SingletonFileUploader = (signedUrl: any, file: any) =>
  fetch(signedUrl, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
    },
    body: file,
  });

export const GoogleAnalyticsInitialiser = (screenPath: any) => {
  ReactGA.initialize(GA_TRACKING_CODE);
  ReactGA.pageview(screenPath);
};

export const focusOnView = (ref: any) =>
  new Promise((resolve, reject) => {
    resolve(ref);
  });
export const RNShare = null;

export const getFirebaseMessageToken = async () => {
  return '';
};

export const firebaseAnalysisLogEvent = async (type: any, param: any) => {
  return;
};

export const SingltonImagePicker = async()=>{ return;};

export const firebaseAnalysisSetUserProperty = async (
  name: any,
  value: any,
) => {
  return;
};