/*
 * Author: Declan Hart
 * Date: 13/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the TitleBox of the participant.
 */

import React, {Component} from 'react';
import {Image, View, StyleSheet} from 'react-native';
import {StandardText} from '../Atoms';

// Import Stylesheets
import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';

class TitleBox extends Component {
  constructor(props: any) {
    super(props);
  }
  render() {
    let background =
      this.props.selected === undefined || this.props.selected
        ? BrandStyles.primaryBgColor3
        : BrandStyles.secondaryBgColor2;
    let textColor =
      this.props.selected === undefined || this.props.selected
        ? BrandStyles.TextColor1
        : BrandStyles.TextColor5;
    let elevated =
      this.props.selected != undefined ? CommonStyles.titleBoxElevation : null;
    return (
      <View
        style={
          this.props.style || [
            CommonStyles.titleBox,
            elevated,
            background,
            CommonStyles.flexDirectionRow,
          ]
        }
      >
        {this._renderImage(this.props.imageSource)}
        <StandardText
          style={[
            CommonStyles.customFontBold,
            textColor,
            CommonStyles.titleBoxText,
          ]}
        >
          {this.props.displayString}
        </StandardText>
      </View>
    );
  }

  _renderImage = (imageSource: any) => {
    if (this.props.imageSource != undefined) {
      return (
        <Image
          style={[CommonStyles.titleBoxIcon, CommonStyles.titleBoxIconMargin]}
          source={imageSource}
        />
      );
    } else {
      return null;
    }
  };
}

export default TitleBox;
