/*
 * Author: Brad D'Costa
 * Date: 07/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Summary tile on the WhereILive screen for the WhoILiveWith Section
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View, Text} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {MESSAGES} from '../../Constants/Messages';

import {StandardText} from '../Atoms';
import {PhotoTile, SummaryTile, GridView} from '../Molecules';

import {CrewPurple3x} from '../../assets/images/vector-icons';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class WhereILiveCrew extends Component {
  _selectedCrew: any;
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <SummaryTile
        headerDisplayString={MESSAGES.WHERE_I_LIVE_SCREEN.LIVE_WITH_TILE_TITLE}
        headerIconImage={CrewPurple3x}
        modal={this.props.modal}
        containsData={this._determineContainsData()}
        actionOverride={this._determineAccess()}
        HeaderTextColor={BrandStyles.TextColor4}
        HeaderBoarderColor={BrandStyles.borderColor4}
      >
        {this._renderWhoILiveWith()}
      </SummaryTile>
    );
  }

  _determineAccess = () => {
    if (Object.getOwnPropertyNames(this.props.readOnly).length === 0) {
      return null;
    } else {
      let readOnly = this.props.readOnly;
      if (readOnly.access) {
        if (readOnly.access.profile) {
          if (readOnly.access.profile == types.ACCESS_READ_ONLY) {
            return types.ACTION_BUTTON.VIEW;
          }
        }
      }
    }
    return null;
  };

  _determineContainsData = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return false;
    } else {
      var checkArray = [this.props.reducer.liveWithDisplayData];
      var dataCheck = false;
      for (var i = 0; i < checkArray.length; i++) {
        if (checkArray[i]) {
          if (Array.isArray(checkArray[i])) {
            if (checkArray[i].length > 0) {
              dataCheck = true;
              break;
            }
          } else {
            dataCheck = true;
            break;
          }
        }
      }
      return dataCheck;
    }
  };

  // #TODO - Remove unwanted code block
  // _determineIcon = () => {
  //   if (this._determineContainsData()) {
  //     return crewIconRed;
  //   } else {
  //     return crewIconCharcoal;
  //   }
  // };

  _renderWhoILiveWith = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      if (!this.props.reducer.liveWithDisplayData) {
        return null;
      } else {
        if (this.props.reducer.liveWithDisplayData.length == 0) {
          return null;
        } else {
          let filteredAray = this.props.reducer.liveWithDisplayData.filter(
            function (crew: any) {
              return crew.selected === true;
            },
          );
          return (
            <View
              style={[
                CommonStyles.contentFlexWrap,
                CommonStyles.alignFlexStart,
              ]}
            >
              <GridView
                viewWidth={this.props.viewWidth}
                isMobile={this.props.isMobile}
                itemDimension={400}
              >
                {this._renderCrewImages(filteredAray)}
              </GridView>
            </View>
          );
        }
      }
    }
  };

  _renderCrewImages(crews: any) {
    return crews.map((crewItem: any) => {
      // Set the caption - it is their known as if they have one, otherwise user their name
      let caption = '';
      let crewImage = null;
      if (crewItem.knownAs != null && crewItem.knownAs != '') {
        caption = crewItem.knownAs;
      } else {
        caption = `${crewItem.firstName} ${crewItem.lastName}`;
      }

      // Check if the user has a profile image, if so set it
      if (crewItem.profileImage) {
        if (crewItem.profileImage.url) {
          crewImage = crewItem.profileImage;
        }
      }

      return (
        <PhotoTile
          key={crewItem.crewId}
          style={{width: 75, height: 75}}
          apiFileType={types2.FILE_TYPE.CREW_PROFILE}
          imageObject={crewImage}
          showCaption={true}
          caption={caption}
          actionCallback={this._selectedCrew}
          actionData={crewItem}
          clickable={false}
          photoBorderRadius={CommonStyles.borderRadiusRound}
        />
      );
    });
  }
}

export default WhereILiveCrew;
