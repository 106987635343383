/*
 * Author: Declan Hart
 * Date: 16/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Display Support details
 */

import React, {Component} from 'react';
import {StyleSheet, View, Image, TextInput} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import {StandardText} from '../Atoms';
import {TrafficLightOption} from './';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class SupportTrafficLights extends Component {
  state = {
    keep: true,
    change: true,
    stop: true,
  };

  constructor(props: any) {
    super(props);
    this.toggleIcon = this.toggleIcon.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.toggleIcon(this.props.selected);
  }

  toggleIcon(option: any) {
    this.props.onPress(option);
    if (option === types.KEEP) {
      this.setState({
        keep: true,
        change: false,
        stop: false,
      });
    } else if (option === types.CHANGE) {
      this.setState({
        keep: false,
        change: true,
        stop: false,
      });
    } else if (option === types.STOP) {
      this.setState({
        keep: false,
        change: false,
        stop: true,
      });
    }
  }

  render() {
    return (
      <View style={this.props.style}>
        <View style={[CommonStyles.justifyFlexRowStart]}>
          <StandardText
            style={[
              CommonStyles.title_SupportQuestions,
              CommonStyles.customFontBold,
            ]}
          >
            {types2.SUPPORT_TRAFFIC_LIGHT_QUESTION}
          </StandardText>
        </View>
        <View
          style={[CommonStyles.justifyFlexRowStart, CommonStyles.alignCenter]}
        >
          <TrafficLightOption
            title={types.KEEP}
            selected={this.state.keep}
            onPress={this.toggleIcon}
            readOnly={this.props.readOnly}
          />
          <TrafficLightOption
            title={types.CHANGE}
            selected={this.state.change}
            onPress={this.toggleIcon}
            readOnly={this.props.readOnly}
          />
          <TrafficLightOption
            title={types.STOP}
            selected={this.state.stop}
            onPress={this.toggleIcon}
            readOnly={this.props.readOnly}
          />
        </View>
      </View>
    );
  }
}

export default SupportTrafficLights;
