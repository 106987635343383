/*
 * Author: Brad D'Costa
 * Date: 19/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Budget actions for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const actionSetCategoryGroup = (selectedCategoryGroup: any) => ({
  type: types.SET_CATEGORY_GROUP,
  selectedCategoryGroup,
});

export const actionSetCategoryIndex = (selectedCategoryIndex: any) => ({
  type: types.SET_CATEGORY_INDEX,
  selectedCategoryIndex,
});

export const actionGetBudgets = (budgetObj: any) => ({
  type: types.GET_BUDGETS,
  budgetObj,
});

export const actionAddPreviousPlans = (plansObject: any) => ({
  type: types.ADD_PLANS,
  plansObject,
});

export const actionUpdateBudgetUnderMaintenanceStatus = (status: any) => ({
  type: types.ADD_BUDGET_UNDER_MAINTENANCE_STATUS,
  status,
});

export const addTransactions = (transactionsObject: any) => ({
  type: types.ADD_TRANSACTIONS,
  transactionsObject,
});

export const actionGetServiceAgreementsList = (
  serviceAgreementsListObj: any,
) => ({
  type: types.GET_SERVICE_AGREEMENTS_LIST,
  serviceAgreementsListObj,
});

export const actionGetBudgetCategories = (budgetCategories: any) => ({
  type: types.GET_BUDGET_CATEGORIES,
  budgetCategories,
});

export const setPmExpiredState = (status: any) => ({
  type: types.SET_PM_EXPIRED,
  status,
});
