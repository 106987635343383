import React, {Component} from 'react';
import {RadioGroup, RadioButton} from './';
import {StandardText} from '../Atoms';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {isWeb} from '../../Constants/Constants';
import {CREW_TYPE} from '../../Constants/Constants2';

class CrewOrganisationRadio extends Component {
  render() {
    return (
      <RadioGroup
        disableUnSelect={true}
        style={[
          CommonStyles.flexDirectionRow,
          CommonStyles.justifyCenter,
          CommonStyles.padding20,
        ]}
        onSelect={(index: any, value: any) =>
          this.props.onSelectSubOption(index, value)
        }
      >
        <RadioButton
          key={CREW_TYPE.PERSON}
          value={CREW_TYPE.PERSON}
          isSelected={this.props.profileTypePerson}
          style={[CommonStyles.paddingRight25]}
          buttonStyle={CommonStyles.radioButtonPMSmallChanged}
        >
          <StandardText
            style={[
              isWeb ? CommonStyles.font15 : CommonStyles.font17,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
              CommonStyles.paddingTop5,
              CommonStyles.paddingLeft10,
            ]}
          >
            Person
          </StandardText>
        </RadioButton>
        <RadioButton
          key={CREW_TYPE.ORGANISATION}
          value={CREW_TYPE.ORGANISATION}
          isSelected={!this.props.profileTypePerson}
          style={[, CommonStyles.paddingRight25]}
          buttonStyle={CommonStyles.radioButtonPMSmallChanged}
        >
          <StandardText
            style={[
              isWeb ? CommonStyles.font15 : CommonStyles.font17,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
              CommonStyles.paddingTop5,
              CommonStyles.paddingLeft10,
            ]}
          >
            Organisation
          </StandardText>
        </RadioButton>
      </RadioGroup>
    );
  }
}

export default CrewOrganisationRadio;
