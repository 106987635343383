/*
Author: Brad D'Costa
Date: 13/12/17
Copyright © 2017 Ernst & Young. All rights reserved.

Constants for the deployed environment.
*/
import AWS from 'aws-sdk';

export const cognito = {
  REGION: 'ap-southeast-2',
  USER_POOL_ID: 'ap-southeast-2_eoov9QTuB',
  USER_POOL_WEB_CLIENT_ID: '6oda6qivgk0msenbnp6bve504f',
  OAUTH: {
    DOMAIN: '$BR_COGNITO_OAUTH_DOMAIN',
    SCOPE: ['phone', 'email', 'profile', 'openid'],
    REDIRECT_URL: '$BR_COGNITO_REDIRECT_URL',
    RESPONSE_TYPE: 'code',
    OPTIONS: {
      DATA_COLLECTION_FLAG: true,
    },
  },
};
export const IS_STAFF_APP = '$BR_IS_STAFF_APP';
export const AWS_REGION = 'ap-southeast-2';
export const AWS_API_VERSION = '2015-03-31';
export const AWS_CLOUDWATCH_API_VERSION = '2010-08-01';
export const AWS_STUBBED = false;
export const AWS_LAMBDA_PREFIX = 'libe';
export const AWS_LAMBDA_CONFIG_ITEM = 'prod';
export const AWS_LAMBDA_QUALIFIER = '$LATEST';
export const AWS_LAMBDA_SERVICES = {
  ADMIN: 'admin',
  USER: 'user',
  PERSON: 'person',
  MEMBER: 'member',
  CREW: 'crew',
  ABOUTME: 'aboutme',
  PLANDATA: 'plandata',
  REFERENCE: 'reference',
  NOTIFICATION: 'notification',
  PDFGENERATION: 'pdfGeneration',
  PARTNERPROVIDER: 'partnerProvider',
  CUSTOMERFEEDBACK: 'ratings',
  PROVIDER: 'provider',
  DEMO_PROFILE: 'demoProfiles',
  LOYALTY:'loyalty'
};

export const GOOGLE_CAPTCHA_CLIENT_KEY = '6Lci-akUAAAAALp8eLR1mAHoVszwmEkkkmsj_T1C';
export const GA_TRACKING_CODE = 'UA-116820193-4';

AWS.config.region = AWS_REGION;

// The app constant
export const APP_ID = 'Leap in';
export const APP_WEB_CHANNEL = 'web';

// App version info
export const APP_VERSION = '8.2.0';
export const BUILD_VERSION = '828';

export const COGNITO_IDENTITY_POOL = 'ap-southeast-2:f5c063b5-3cf9-4c16-8615-11cf0a7d1ecd';
export const COGNITO_USER_POOL_ID = 'ap-southeast-2_eoov9QTuB';
export const COGNITO_CLIENT_ID = '6oda6qivgk0msenbnp6bve504f';
export const COGNITO_EMAIL_SIGNUP_URL = `cognito-idp.${AWS_REGION}.amazonaws.com/${COGNITO_USER_POOL_ID}`;

export const DEMO_COGNITO_USER_POOL_ID = 'ap-southeast-2_zFh5Spum5';
export const DEMO_COGNITO_CLIENT_ID = '2iudv31amsmah59nik12p3i7np';
export const DEMO_COGNITO_EMAIL_SIGNUP_URL = `cognito-idp.${AWS_REGION}.amazonaws.com/${DEMO_COGNITO_USER_POOL_ID}`;

export const PM_DEMO_EMAIL = 'rachel.wang@leapin.com.au';
export const PR_DEMO_EMAIL = 'john.sampson@leapin.com.au';
export const DEMO_PASSWORD = 'leapinDemo';
export const API_URL = 'https://api.leapin.com.au';
export const DEMO_CREATION_URL = 'https://api.leapin.com.au/demoProfiles/createdemoprofile';
export const DEMO_CREATION_API_KEY = 'uvwLoA1YwT825QzsRkCnJ1NLJjseRXPe7yRZJ3Zu';
export const PM_SIGN_UP_URL = 'https://www.leapin.com.au/ndis-plan-management/register/';

export const GOOGLE_WEB_CLIENT_ID = '437067483616-3r12k6mjv3nrjt36h69e595252nkdnb8.apps.googleusercontent.com';

export const MDS_CLIENT_SERVICE = {
  BASE_URL: 'https://api.mapds.com.au',
  SUGGEST_PATH: '/place/suggest',
  API_KEY: 'TGVhcEluX1dTQVBJOn5GMkFTSnA0',
};

export const DATA_DOG = {
  ENABLED: true,
  APPLICATION_ID: '20d8299e-16d9-45bf-811e-75fb5017c3d9',
  CLIENT_TOKEN: 'pub86b9cfc81035526fb22e7944a8d53734',
  DD_SERVICE: 'planready-web-prod',
  allowedTracingUrls: [
    (url) => url.startsWith('$API_URL')
  ]
};
