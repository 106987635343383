/*
 * Author: Declan Hart
 * Date: 13/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the profiletile of the participant.
 */

import React, {Component} from 'react';
import {Text, View, Image, Alert} from 'react-native';

import {PlaceholderImage} from '../../assets/images';
import {MemberDetails} from './';
import {StandardTouchableOpacity} from '../Atoms';
import BasicPopupAlert from '../Organisms/BasicPopupAlert';
// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as TabBarActions from '../../Actions/TabBarActions';
import * as SideMenuActions from '../../Actions/SideMenuActions';
import * as UserActions from '../../Actions/UserActions';

import {MEMBER_TYPE_NOT_SPECIFIED as MemberTypeNotSpecified} from '../../Constants/Messages';

class ProfileTile extends Component {
  constructor(props: any) {
    super(props);
    this.navigateProfile = this.navigateProfile.bind(this);
    this.createLocationString = this.createLocationString.bind(this);
    this._closeAlert = this._closeAlert.bind(this);
    this._showAlert = this._showAlert.bind(this);
  }

  state = {errorPopUpVisible: false};

  render() {
    const locationString = this.createLocationString();

    let memberStatus = this.props.person.status;
    if (
      this.props.person.typeConfirmed != undefined &&
      this.props.person.typeConfirmed == 0
    ) {
      memberStatus = MemberTypeNotSpecified.status;
    }

    return (
      <StandardTouchableOpacity
        activeOpacity={0.8}
        onPress={() => this.navigateProfile()}
        accessibilityLabel={`View Profile for${this.props.person.firstName}${this.props.person.lastName}`}
        style={[CommonStyles.profileTileWrapper, BrandStyles.primaryBgColor1]}
      >
        <MemberDetails
          firstName={this.props.person.firstName}
          lastName={this.props.person.lastName}
          location={locationString}
          status={memberStatus}
        />
        <BasicPopupAlert
          visible={this.state.errorPopUpVisible}
          close={this.state.closeAction}
          cancelLabel={this.state.alertButtonText}
          headerText={this.state.alertHeader}
          messageText={this.state.alertMessage}
        />
      </StandardTouchableOpacity>
    );
  }

  createLocationString() {
    // Create the string for the location
    let locationString = '';
    if (this.props.person.city) {
      locationString = this.props.person.city;
    }

    if (this.props.person.state) {
      if (locationString.length > 0) {
        locationString += ', ';
      }

      locationString += this.props.person.state;
    }

    return locationString;
  }

  _closeAlert() {
    this.setState({
      errorPopUpVisible: false,
    });
  }
  navigateProfile() {
    this.props.actions.TabBarActions.addSelectedTab(types2.PROFILE);
    this.props.actions.TabBarActions.getGuideMeMember(this.props.person);

    if (
      this.props.person.typeConfirmed != undefined &&
      this.props.person.typeConfirmed == 0
    ) {
      this._showAlert(
        types2.ALERT_TITLE,
        MemberTypeNotSpecified.MESSAGE,
        types2.OK,
        this._closeAlert,
        true,
      );
    } else {
      this.props.nav(this.props.person);
    }
  }

  _showAlert(
    alertHeader: any,
    alertMessage: any,
    alertButtonText: any,
    closeAction: any,
    errorPopUpVisible: any,
  ) {
    this.setState({
      alertHeader: alertHeader,
      alertMessage: alertMessage,
      alertButtonText: alertButtonText,
      closeAction: closeAction,
      errorPopUpVisible: errorPopUpVisible,
    });
  }
}

const mapStateToProps = (state: any) => ({
  tabBar: state.TabBarReducer,
  sideMenu: state.SideMenuReducer,
  user: state.UserReducer,
});
const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    TabBarActions: bindActionCreators(TabBarActions, dispatch),
    UserActions: bindActionCreators(UserActions, dispatch),
    SideMenuActions: bindActionCreators(SideMenuActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileTile);
