/*
 * Author: Shelan Cooray
 * Date: 10/10/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The profile screen, this is the landing page when you sign up/login.
 */

import React, {Component} from 'react';
import {View, Image} from 'react-native';

import {BUDGETS_AND_PAYMENTS_SCREEN} from '../../Constants/Messages';
import {StandardButton, StandardText} from '../Atoms';

import {LILogo, BudgetOnBoarding} from '../../assets/images';

import {closeIcon} from '../../assets/images/vector-icons';
import CustomIcon from '../../assets/images/CustomIcon';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import DeviceInfo from 'react-native-device-info';
import {isTablet} from '../../Helpers/PlatformSynchronizer';

const messages = BUDGETS_AND_PAYMENTS_SCREEN;

class MyProfileBanner extends Component {
  state = {};

  constructor(props: any) {
    super(props);
  }
  render() {
    return (
      <View style={[CommonStyles.containerOnBoardingBanner]}>
        <View
          style={[
            CommonStyles.flex07,
            CommonStyles.marginLeft15,
            CommonStyles.marginBottom10,
            isTablet ? CommonStyles.marginTop30 : CommonStyles.marginTop15,
          ]}
        >
          <View
            style={[
              CommonStyles.flexDirectionRow,
              CommonStyles.paddingBottom10,
              CommonStyles.alignCenter,
            ]}
          >
            <Image
              style={[
                CommonStyles.budgetImage,
                CommonStyles.marginBottom10,
                CommonStyles.marginRight20,
              ]}
              source={LILogo}
            />
            <StandardText
              style={[
                isTablet ? CommonStyles.font30 : CommonStyles.font25,
                CommonStyles.customFontBold,
                BrandStyles.TextColor1,
                CommonStyles.textAlignCentre,
                isTablet ? CommonStyles.marginBottom15 : {},
              ]}
            >
              {messages.ONBOARDING_BANNER_TITLE}
            </StandardText>
          </View>
          <View
            style={[
              CommonStyles.flexDirectionColumn,
              CommonStyles.marginBottom10,
              CommonStyles.alignItemsCenter,
            ]}
          >
            <StandardText
              style={[
                CommonStyles.font18,
                CommonStyles.customFontBold,
                BrandStyles.TextColor1,
                CommonStyles.textAlignCentre,
                isTablet
                  ? CommonStyles.marginBottom5
                  : CommonStyles.marginBottom10,
              ]}
            >
              {messages.ONBOARDING_BANNER_TEXT1}
            </StandardText>
            <StandardText
              style={[
                CommonStyles.font18,
                CommonStyles.customFontBold,
                BrandStyles.TextColor1,
                CommonStyles.textAlignCentre,
              ]}
            >
              {messages.ONBOARDING_BANNER_TEXT2}
            </StandardText>
          </View>
        </View>
        <View
          style={[
            CommonStyles.flex03,
            CommonStyles.marginTop5,
            CommonStyles.marginRight5,
            CommonStyles.flexDirectionRow,
            CommonStyles.justifyContentFlexEnd,
          ]}
        >
          <View
            style={[
              CommonStyles.flexDirectionColumn,
              CommonStyles.justifyContentFlexEnd,
            ]}
          >
            <Image
              style={[
                isTablet
                  ? CommonStyles.imageAvatarOnBoardingMyProfile
                  : CommonStyles.imageAvatarOnBoardingMyProfileMobile,
              ]}
              source={BudgetOnBoarding}
              resizeMode="cover"
            />
          </View>

          <View
            style={[
              CommonStyles.flexDirectionRow,
              CommonStyles.alignItemsFlexStart,
            ]}
          >
            <StandardButton
              onPress={() => this.props.close()}
              accessibilityLabel={'Close'}
            >
              <CustomIcon
                name={closeIcon}
                style={[CommonStyles.iconCloseWhite]}
              />
            </StandardButton>
          </View>
        </View>
      </View>
    );
  }
}

export default MyProfileBanner;
