/*
 * Author: Andrew Seeley
 * Date: 19/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Combines and exports the reducers as a single reducer
 */

import {combineReducers} from 'redux';
import AdminReducer from './AdminReducer';
import AuthenticationReducer from './AuthenticationReducer';
import AvatarReducer from './AvatarReducer';
import BudgetReducer from './BudgetReducer';
import CrewReducer from './CrewReducer';
import DocumentListReducer from './DocumentListReducer';
import FavouriteThingsReducer from './FavouriteThingsReducer';
import GeneralReducer from './GeneralReducer';
import GoalReducer from './GoalReducer';
import GuideMeReducer from './GuideMeReducer';
import HealthWellbeingReducer from './HealthWellbeingReducer';
import LivingArrangementReducer from './LivingArrangementReducer';
import LivingArrangementRefReducer from './LivingArrangementRefReducer';
import LoadedMemberReducer from './LoadedMemberReducer';
import MemberReducer from './MemberReducer';
import PlanManagerReducer from './PlanManagerReducer';
import ReachabilityReducer from './ReachabilityReducer';
import ServiceAgreementsListReducer from './ServiceAgreementsListReducer';
import SupportCategoriesReducer from './SupportCategoriesReducer';
import SupportItemGroupsReducer from './SupportItemGroupsReducer';
import SupportItemsReducer from './SupportItemsReducer';
import SupportsReducer from './SupportsReducer';
import TabBarReducer from './TabBarReducer';
import UserReducer from './UserReducer';
import NotificationReducer from './NotificationReducer';
import NavigationReducer from './NavigationReducer';
import PayInvoiceReducer from './PayInvoiceReducer';
import SideMenuReducer from './SideMenuReducer';
import OfflineNoticeReducer from './OfflineNoticeReducer';
import LogoutReducer from './LogoutReducer';
import NavigationParamsReducer from './NavigationParamsReducer';
import ProviderReducer from './ProviderReducer';
import EnquiryReducer from './EnquiryReducer';
import PaymentHistoryReducer from './paymentHistoryReducer'

import {LOGOUT} from '../Constants/Constants';

const appReducer = combineReducers({
  AdminReducer,
  AuthenticationReducer,
  AvatarReducer,
  BudgetReducer,
  CrewReducer,
  DocumentListReducer,
  FavouriteThingsReducer,
  GeneralReducer,
  GoalReducer,
  GuideMeReducer,
  HealthWellbeingReducer,
  LivingArrangementReducer,
  LivingArrangementRefReducer,
  LoadedMemberReducer,
  MemberReducer,
  PlanManagerReducer,
  ReachabilityReducer,
  ServiceAgreementsListReducer,
  SupportCategoriesReducer,
  SupportItemGroupsReducer,
  SupportItemsReducer,
  SupportsReducer,
  TabBarReducer,
  UserReducer,
  NotificationReducer,
  NavigationReducer,
  PayInvoiceReducer,
  SideMenuReducer,
  OfflineNoticeReducer,
  LogoutReducer,
  NavigationParamsReducer,
  ProviderReducer,
  EnquiryReducer,
  PaymentHistoryReducer
});

const rootReducer = (state: any, action: any) => {
  // If an action logout is sent to the reducer, nuke the whlole reducer
  // So there is nothing in it
  if (action.type == LOGOUT) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
