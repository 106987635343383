/*
 * Author: Sujeban Elankeswaran
 * Date: 30/12/2019
 * Copyright © 2019 Leap in!. All rights reserved.
 *
 * Enquiry info.
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function EnquiryReducer(
  state = {
    memberAddressChecked: true,
    crewMobileNumberChecked: true,
    memberPhoneNumberChecked: true,
    memberMobilePhoneChecked: true,
    memberDisabilitiesChecked: true,
    crewRelationshipChecked: true,
  },
  action: any,
) {
  switch (action.type) {
    case types.SET_ENQUIRY_DESCRIPTION:
      return {
        ...state,
        enquiryDescription: action.enquiryDescription,
      };
    case types.SET_MEMBER_ADDRESS_CHECKED:
      return {
        ...state,
        memberAddressChecked: action.payload,
      };
    case types.SET_CREW_MOBILE_NUMBER_CHECKED:
      return {
        ...state,
        crewMobileNumberChecked: action.payload,
      };
    case types.SET_MEMBER_PHONE_NUMBER_CHECKED:
      return {
        ...state,
        memberPhoneNumberChecked: action.payload,
      };
    case types.SET_MEMBER_MOBILE_PHONE_CHECKED:
      return {
        ...state,
        memberMobilePhoneChecked: action.payload,
      };
    case types.SET_MEMBER_DISABILITIES_CHECKED:
      return {
        ...state,
        memberDisabilitiesChecked: action.payload,
      };
    case types.SET_CREW_RELATIONSHIP_CHECKED:
      return {
        ...state,
        crewRelationshipChecked: action.payload,
      };
    default:
      return state;
  }
}
