/*
 * Author: Shelan Cooray
 * Date: 05/09/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the segmented checkboxes for add support screen
 */

import React, {Component} from 'react';
import {View} from 'react-native';
import {includes} from 'lodash';
import {CheckBoxButton} from './';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

// Import Stylesheets
import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';

class SegmentedCheckBox extends Component {
  state = {
    props: {},
    valueArray: [],
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    this.setState({props: this.props});

    let values = this.props.values;
    if (this.props.displayLables) {
      values = this.props.displayLables;
    }

    let checkBoxArray = values.map((value: any, index: any) => {
      if (includes(this.props.selectedIndices, index)) {
        return {description: value, selected: true};
      } else {
        return {description: value, selected: false};
      }
    });
    this.setState({valueArray: checkBoxArray});
  }

  render() {
    return (
      <View
        style={[
          CommonStyles.justifyFlexRowStart,
          CommonStyles.flexWrap,
          CommonStyles.marginLeft10,
        ]}
      >
        {this._renderOptions(2, this.props.access)}
      </View>
    );
  }

  _renderOptions = (col: any, access: any) => {
    if (this.state.valueArray == 0) {
      return null;
    } else {
      var renderArray = this.state.valueArray;
      return renderArray.map((option, index) => (
        <CheckBoxButton
          readOnly={access}
          key={index}
          data={option}
          circular={true}
          iconStyle={[CommonStyles.checkBoxIcon_Segmented]}
          textStyle={[
            CommonStyles.customFont,
            CommonStyles.font18,
            CommonStyles.marginLeft5,
          ]}
          columns={col}
          onChange={(object: any) =>
            this._handleTabPress(
              index,
              this.props.multiple,
              this.props.selectedIndex,
              this.props.onChange,
            )
          }
        />
      ));
    }
  };

  _handleTabPress = (
    index: any,
    multiple: any,
    selectedIndex: any,
    onChange: any,
  ) => {
    if (multiple) {
      onChange(index);
    } else if (selectedIndex !== index) {
      onChange(index);
    }
  };
}

export default SegmentedCheckBox;
