import {cognito} from './environments';

const awsConfig = {
  Auth: {
    region: cognito.REGION,
    userPoolId: cognito.USER_POOL_ID,
    userPoolWebClientId: cognito.USER_POOL_WEB_CLIENT_ID,
  },
  oauth: {
    domain: cognito.OAUTH.DOMAIN,
    scope: cognito.OAUTH.SCOPE,
    redirectSignIn: cognito.OAUTH.REDIRECT_URL,
    redirectSignOut: cognito.OAUTH.REDIRECT_URL,
    responseType: cognito.OAUTH.RESPONSE_TYPE,
    options: {
      AdvancedSecurityDataCollectionFlag:
        cognito.OAUTH.OPTIONS.DATA_COLLECTION_FLAG,
    },
  },
};

export default awsConfig;
