/*
 * Author: Declan Hart
 * Date: 15/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The select support item component, allows user to select the support group.
 */

import React, {Component} from 'react';
import {
  Text,
  View,
  FlatList,
  StyleSheet,
  ScrollView,
  TextInput,
  Image,
  TouchableOpacity,
} from 'react-native';

import {StandardText, StandardTile, StandardButton} from '../Atoms';
import {PhotoTile, SelectedItem} from './';
import {CloseButtonCharcoal3x, SICloseIcon} from '../../assets/images';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class SelectedCrewMember extends Component {
  constructor(props: any) {
    super(props);
    this.onTextChange = this.onTextChange.bind(this);
  }

  onTextChange(detail: any) {
    this.props.save(this.props.crew, detail);
  }

  render() {
    let caption = '';
    if (this.props.crew.firstName != null && this.props.crew.firstName != '') {
      if (this.props.crew.knownAs != null && this.props.crew.knownAs != '') {
        caption = this.props.crew.knownAs;
      } else {
        caption = `${this.props.crew.firstName} ${this.props.crew.lastName}`;
      }
    } else {
      caption = this.props.crew.orgName;
    }

    let crewImage = null;
    if (this.props.crew.profileImage) {
      if (this.props.crew.profileImage.profileImageURL) {
        crewImage = this.props.crew.profileImage;
      }
    }
    let background;
    let textColor = BrandStyles.TextColor5;

    if (this.props.readOnly == 'read') {
      background = BrandStyles.readOnlyCheckboxGrey;
      textColor = BrandStyles.TextColor1;
    }
    return (
      <View
        style={[CommonStyles.flexDirectionRow, CommonStyles.alignItemsCenter]}
      >
        <PhotoTile
          style={StyleSheet.flatten(CommonStyles.PhotoTileStandardSize)}
          apiFileType={types2.FILE_TYPE.CREW_PROFILE}
          imageObject={crewImage}
          readOnly={types.ACCESS_READ_ONLY}
          actionData={this.props.crew}
          accessibilityLabel={`image for ${this.props.crew.firstName} ${this.props.crew.lastName}`}
        />
        <View
          key={this.props.indexCounter}
          style={[
            CommonStyles.flexRow,
            BrandStyles.brandBorderColor2,
            CommonStyles.SISelectedContainer,
            CommonStyles.height40,
          ]}
        >
          <View
            style={[
              background,
              CommonStyles.alignCenter,
              CommonStyles.paddingLeft10,
            ]}
          >
            <StandardText
              style={[CommonStyles.customFont, textColor, CommonStyles.font20]}
            >
              {caption}
            </StandardText>
          </View>
          <View
            style={[
              CommonStyles.SIClosePadding,
              BrandStyles.brandBorderColor2,
              CommonStyles.justifyCenter,
            ]}
          >
            <StandardButton
              onPress={() => this.props.remove(this.props.crew.crewId)}
              accessibilityLabel={`Remove ${caption}`}
            >
              <Image
                source={CloseButtonCharcoal3x}
                style={[CommonStyles.SICloseIcon]}
              />
            </StandardButton>
          </View>
        </View>
      </View>
    );
  }
}

export default SelectedCrewMember;
