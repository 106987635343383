/*
 * Author: Mayuran
 * Date: 17/09/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 */

import React, {Component} from 'react';
import {BasicOverlayPopupModal} from '../Molecules';
import {StandardText} from './';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {BrandColors} from '../../Styles/Colours';
import DeviceInfo from 'react-native-device-info';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {View} from 'react-native';
import {isTablet} from '../../Helpers/PlatformSynchronizer';

class StandardAlert extends Component {
  render() {
    return (
      <BasicOverlayPopupModal
        visible={
          this.props.message
            ? this.props.message.length > 0
            : this.props.messageComponent
            ? true
            : false
        }
        style={this._determinePopUpStyle(isTablet)}
        headerText={this.props.modalTitle && this.props.modalTitle}
        disableInfoImg={true}
        headerStyle={this.props.headerStyle && this.props.headerStyle}
        backdropOpacity={0.2}
        divider={true}
        close={this.props.close}
        cancelContainerStyle={CommonStyles.popUpOkButton}
        cancelStyle={[
          CommonStyles.buttonPopupOk,
          CommonStyles.customFontBold,
          this.props.cancelBtnStyle,
        ]}
        cancelLabel={types2.OK_GOT_IT}
      >
        <View>
          {this.props.messageComponent ? (
            this.props.messageComponent
          ) : (
            <StandardText
              style={[
                CommonStyles.font20,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
                CommonStyles.textAlignCentre,
              ]}
            >
              {this.props.message}
            </StandardText>
          )}
        </View>
      </BasicOverlayPopupModal>
    );
  }
  _determinePopUpStyle = (isTablet: any) => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      styles.push([
        CommonStyles.containerInstructionPopUpWebLarge,
        CommonStyles.height250,
        this.props.webStyle,
      ]);
    } else if (isTablet()) {
      styles.push([
        CommonStyles.containerInstructionPopUpTablet,
        CommonStyles.height250,
        this.props.tabletStyle,
      ]);
    } else {
      styles.push(
        CommonStyles.containerInstructionPopUpMobile,
        CommonStyles.height230,
        CommonStyles.width360,
        this.props.mobileStyle,
      );
    }
    return styles;
  };
}

StandardAlert.defaultProps = {
  modalTitle: '',
};

export default StandardAlert;
