/*
 * Author: Brad D'Costa
 * Date: 19/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Members budget information.
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {nullStringReplacer} from '../API/APIHelper';

/* Expected DataModel as per v45 of Swagger Definitions (versioning is based on Confluence versioning)
 *  {
 *    TBC
 *  }
 */
export default function BudgetReducer(state = {}, action: any) {
  switch (action.type) {
    case types.GET_BUDGETS:
      return {
        startDate: nullStringReplacer(action.budgetObj.startDate),
        endDate: nullStringReplacer(action.budgetObj.endDate),
        ndisPlanNumber: nullStringReplacer(action.budgetObj.ndisPlanNumber),
        budget: action.budgetObj.budget,
        core: action.budgetObj.core,
        capitalSupports: action.budgetObj.capitalSupports,
        capacityBuilding: action.budgetObj.capacityBuilding,
        apiCalled: new Date(),
        isBudgetUnderMaintenanceStatus:
          action.budgetObj.isBudgetUnderMaintenance,
      };
    case types.SET_CATEGORY_GROUP:
      return {
        selectedCategoryGroup: action.selectedCategoryGroup,
      };
    case types.SET_CATEGORY_INDEX:
      return {
        selectedCategoryIndex: action.selectedCategoryIndex,
      };
    case types.ADD_PLANS:
      return {
        ...state,
        plansObject: action.plansObject,
      };

    case types.GET_BUDGET_CATEGORIES:
      return {
        ...state,
        budgetCategories: action.budgetCategories,
      };
    case types.SET_PM_EXPIRED:
      return {
        ...state,
        isPmExpired: action.status,
      };
    case types.ADD_TRANSACTIONS: {
      // Add any the transactions to their appropiate budget items
      const transactions = action.transactionsObject.transactions;

      const core = JSON.parse(JSON.stringify(state.core));
      const capitalSupports = JSON.parse(JSON.stringify(state.capitalSupports));
      const capacityBuilding = JSON.parse(
        JSON.stringify(state.capacityBuilding),
      );
      const allSectionArrays = [core, capitalSupports, capacityBuilding];

      // Loop through all the transactions
      for (
        let idxTransactions = 0;
        idxTransactions < transactions.length;
        idxTransactions++
      ) {
        const currentTransaction = transactions[idxTransactions];
        // Add the transaction to the right object
        for (
          let idxSectionArrays = 0;
          idxSectionArrays < allSectionArrays.length;
          idxSectionArrays++
        ) {
          const sectionArray = allSectionArrays[idxSectionArrays];
          // Go over each section, look for any matching names and if so add the object
          for (
            let idxSectionBudget = 0;
            idxSectionBudget < sectionArray.length;
            idxSectionBudget++
          ) {
            const budgetItem = sectionArray[idxSectionBudget];

            const budgetCategoryCode = budgetItem.categoryId.toString();
            let currentTransactionCode = currentTransaction.coverage.code;
            // Replace leading zeros found on transactions API
            currentTransactionCode = currentTransactionCode
              .toString()
              .replace(/^0+/, '');

            // If we have a match add the transactions to that budget item
            if (budgetCategoryCode == currentTransactionCode) {
              // If the item has no transactions add it

              if (budgetItem.transactions == undefined) {
                budgetItem.transactions = {};
              }

              // Add the transaction to the budget item category it belongs in
              let biller = currentTransaction.billerName;
              // If biller name is blank it categeroize it as Other
              if (biller == '') {
                biller = 'Other';
              }
              // Put the transaction in it's category
              if (budgetItem.transactions[biller] == undefined) {
                budgetItem.transactions[biller] = [];
              }
              budgetItem.transactions[biller].push(currentTransaction);
            }
          }
        }
      }

      return {
        ...state,
        core: allSectionArrays[0],
        capitalSupports: allSectionArrays[1],
        capacityBuilding: allSectionArrays[2],
      };
    }
    default:
      return state;
  }
}
