/*
 * Authour: Andrew Lee
 * Date: 09/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a generic side bar which content can be loaded into so that it can be reused across multiple screens.
 * @param {function} content The content that will be loaded into the sidebar
 * @param {boolean}
 */

import React, {Component} from 'react';
import {View} from 'react-native';

class EmptyComponent extends Component {
  state = {};

  constructor(props: any) {
    super(props);
  }

  render() {
    return <View></View>;
  }
}
export default EmptyComponent;
