/*
 * Authour: Nirodha Perera
 * Date: 16/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The modal to select filters
 */

import React, {Component} from 'react';
import {StyleSheet, View, ScrollView, Modal} from 'react-native';

import {CommonActions} from '@react-navigation/native';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

import {StandardText} from '../Components/Atoms';
import {
  Container,
  GridView,
  FormTitle,
  RadioButton,
} from '../Components/Molecules';
import {BasicForm} from '../Components/Organisms';

import {callApiChecker} from '../API/APIHelper';
import {callAPIs} from '../API/APICaller';
import {getGoalFiltersReference} from '../API/ReferenceAPI';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import * as GoalActions from '../Actions/GoalActions';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import * as messages from '../Constants/Messages';
import {LifestageCharcoal} from '../assets/images/vector-icons';

class GoalFiltersForm extends Component {
  constructor(props: any) {
    super(props);
    this._renderCategoryRadioButtons.bind(this);
    this._updateCategoryFilters.bind(this);
    this._renderLifeStageRadioButtons.bind(this);
    this._reapplyRadioButtons.bind(this);
    this._createMainContents.bind(this);
  }

  state = {
    loading: false,
    goalsFilters: [],
    selectedCategories: [],
    selectedLifeStages: [],
    selectedFilterNames: [],
  };

  componentDidMount() {
    this._setLoading(true);
    let getGoalFiltersReferenceCallback = (data: any) => {
      let payload = data[0];
      // Create a deep copy of the payload.
      this.props.actions.GoalActions.getGoalFiltersReference(
        JSON.parse(JSON.stringify(payload.content)),
      );

      let goalFilter = JSON.parse(
        JSON.stringify(this.props.goals.goalsFilters.referenceData),
      );
      goalFilter = this._preSelectedFilter(goalFilter);
      this._reapplyRadioButtons();
      goalFilter = this._repopulateGoalFilters(goalFilter);
      this._setLoading(false);
      this.setState({goalsFilters: goalFilter});
    };

    if (this.props.goals.goalsFilters == null) {
      // First time calling the api
      callAPIs(
        [getGoalFiltersReference()],
        getGoalFiltersReferenceCallback,
        null,
        () => {
          this._setLoading(false);
        },
      );
    } else {
      // API already called once, check the timeout. If not expired redux will continue to be used
      if (
        callApiChecker(
          this.props.goals.goalsFilters.apiCalled,
          types.API_REFERENCE_TIMEOUT_CHECK,
        )
      ) {
        callAPIs(
          [getGoalFiltersReference()],
          getGoalFiltersReferenceCallback,
          null,
          () => {
            this._setLoading(false);
          },
        );
      } else {
        let goalFilter = this.props.navigationParams.params.isthereFilter;
        if (this.props.navigationParams.params.setFilterLifeStages == true) {
          goalFilter = this._preSelectedFilter(goalFilter);
        }
        this._reapplyRadioButtons();
        goalFilter = this._repopulateGoalFilters(goalFilter);
        this._setLoading(false);
        this.setState({goalsFilters: goalFilter});
      }
    }
  }

  _setLoading = (loadingState: any) => {
    if (types.isWeb) {
      this.props.setLoading(loadingState);
    } else {
      this.setState({isLoading: loadingState});
    }
  };

  _repopulateGoalFilters(goalFilters: any) {
    let selectedCategories = this.props.goals.goalsFilters.selectedCategories;
    let selectedLifeStages = this.props.goals.goalsFilters.selectedLifeStages;

    if (goalFilters.length > 0) {
      goalFilters.categories = goalFilters.categories.map((x: any) => {
        let filter = {
          description: x.description,
          id: x.id,
          type: x.type,
        };

        if (selectedCategories.find((y: any) => x.id === y)) {
          filter.selected = true;
        }

        return filter;
      });

      goalFilters.lifeStages = goalFilters.lifeStages.map((x: any) => {
        let filter = {
          description: x.description,
          id: x.id,
          type: x.type,
        };

        if (selectedLifeStages.find((y: any) => x.id === y)) {
          filter.selected = true;
        }

        return filter;
      });
    }

    return goalFilters;
  }

  _preSelectedFilter(goalFilter: any) {
    if (Object.getOwnPropertyNames(this.props.member).length > 0) {
      for (let i = 0; i < this.props.member.lifeStages.length; i++) {
        const lifeStages = goalFilter.lifeStages ? goalFilter.lifeStages : [];
        for (let n = 0; n < lifeStages.length; n++) {
          lifeStages[n].type = types.GOALS.FILTER.LIFESTAGE;
          if (
            this.props.member.lifeStages[i].lifeStageDescription ==
            lifeStages[n].description
          ) {
            lifeStages[n].selected = true;
            this._updateCategoryFilters(lifeStages[n]);
          }
        }
      }
      return goalFilter;
    }
    return goalFilter;
  }

  _reapplyRadioButtons() {
    const updatedState = {};

    if (this.props.goals.goalsFilters.selectedCategories) {
      updatedState.selectedCategories = JSON.parse(
        JSON.stringify(this.props.goals.goalsFilters.selectedCategories),
      );
    }

    if (this.props.goals.goalsFilters.selectedLifeStages) {
      updatedState.selectedLifeStages = JSON.parse(
        JSON.stringify(this.props.goals.goalsFilters.selectedLifeStages),
      );
    }

    if (this.props.goals.goalsFilters.selectedFilterNames) {
      updatedState.selectedFilterNames = JSON.parse(
        JSON.stringify(this.props.goals.goalsFilters.selectedFilterNames),
      );
    }

    this.setState(updatedState);
  }

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        headerTitle={types2.NAVIGATION_TEXT.GOAL_FILTERS}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        toggleMenu={this.props.screenProps?.toggleMenu}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    return (
      <BasicForm
        save={this._saveFilterModal}
        close={this._closeFilterModal}
        headerDisplayString={messages.ADD_GOALS.GOAL_FILTERS}
        headerIconImage={LifestageCharcoal}
        buttonsPositionBottom={true}
        hideActionButton={true}
        contentWidthFull={true}
      >
        <View style={[CommonStyles.flex1]}>
          <FormTitle
            text={messages.ADD_GOALS.CHOOSE_FILTERS}
            borderStyle={[CommonStyles.flexWrap, CommonStyles.flex1]}
            containsData={this.state.selectedFilterNames}
            renderGoalFilters={types.isWeb ? false : true}
          />
          <StandardText
            style={[
              CommonStyles.font17,
              BrandStyles.TextColor5,
              CommonStyles.customFontBold,
              CommonStyles.marginBottom10,
              CommonStyles.marginTop10,
            ]}
          >
            {' '}
            {messages.ADD_GOALS.LIFE_STAGE}
          </StandardText>
          <StandardText
            style={[
              types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
            ]}
          >
            {' '}
            {messages.ADD_GOALS.SELECT_OPTIONS}
          </StandardText>
          {this._renderLifeStageSection()}
          <StandardText
            style={[
              CommonStyles.font17,
              BrandStyles.TextColor5,
              CommonStyles.customFontBold,
              CommonStyles.marginBottom10,
              CommonStyles.marginTop20,
            ]}
          >
            {' '}
            {messages.ADD_GOALS.CATEGORY}{' '}
          </StandardText>
          <StandardText
            style={[
              types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
            ]}
          >
            {' '}
            {messages.ADD_GOALS.SELECT_OPTIONS}{' '}
          </StandardText>
          {this._renderCategorySection()}
        </View>
      </BasicForm>
    );
  };

  _renderCategorySection() {
    if (this.state.goalsFilters.length == 0) {
      return null;
    } else {
      if (types.isWeb) {
        return (
          <GridView
            viewWidth={this.props.viewWidth}
            itemDimension={types2.ITEM_DIMENSION}
            isMobile={this.props.isMobile}
          >
            {this._renderCategoryRadioButtons()}
          </GridView>
        );
      } else {
        return this._renderCategoryRadioButtons();
      }
    }
  }

  _renderLifeStageSection = () => {
    if (this.state.goalsFilters.length == 0) {
      return null;
    } else {
      if (types.isWeb) {
        return (
          <GridView
            viewWidth={this.props.viewWidth}
            itemDimension={types2.ITEM_DIMENSION}
            isMobile={this.props.isMobile}
          >
            {this._renderLifeStageRadioButtons()}
          </GridView>
        );
      } else {
        return this._renderLifeStageRadioButtons();
      }
    }
  };

  _renderCategoryRadioButtons = () => {
    var renderArray = this.state.goalsFilters.categories;
    return renderArray.map((option: any, index: any) => {
      option.type = types.GOALS.FILTER.CATEGORY;
      return (
        <RadioButton
          key={option.id}
          id={option.id}
          value={option.description}
          style={
            index == renderArray.length - 1
              ? CommonStyles.radioButtonPM_DividerStyleBorderless
              : CommonStyles.radioButtonPM_DividerStyleMultiselect
          }
          buttonStyle={CommonStyles.radioButtonPMSmallChanged}
          isSelected={this.determineSelected(option)}
          data={option}
          onChange={(checkBoxObj: any) => {
            this._updateCategoryFilters(checkBoxObj);
          }}
        >
          <View style={[CommonStyles.radioButtonPMSmall_ContainerMultiselect]}>
            <View
              style={[
                CommonStyles.flexDirectionRow,
                CommonStyles.justifyContentSpaceBetween,
              ]}
            >
              <StandardText
                style={[
                  types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
                  BrandStyles.TextColor5,
                  CommonStyles.customFont,
                ]}
              >
                {option.description}
              </StandardText>
            </View>
          </View>
        </RadioButton>
      );
    });
  };

  _renderLifeStageRadioButtons() {
    var renderArray = this.state.goalsFilters.lifeStages;
    return renderArray.map((option: any, index: any) => {
      option.type = types.GOALS.FILTER.LIFESTAGE;
      return (
        <RadioButton
          key={option.id}
          id={option.id}
          value={option.description}
          style={
            index == renderArray.length - 1
              ? CommonStyles.radioButtonPM_DividerStyleBorderless
              : CommonStyles.radioButtonPM_DividerStyleMultiselect
          }
          buttonStyle={CommonStyles.radioButtonPMSmallChanged}
          isSelected={this.determineSelected(option)}
          data={option}
          onChange={(checkBoxObj: any) => {
            this._updateCategoryFilters(checkBoxObj);
          }}
        >
          <View style={[CommonStyles.radioButtonPMSmall_ContainerMultiselect]}>
            <View
              style={[
                CommonStyles.flexDirectionRow,
                CommonStyles.justifyContentSpaceBetween,
              ]}
            >
              <StandardText
                style={[
                  types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
                  BrandStyles.TextColor5,
                  CommonStyles.customFont,
                ]}
              >
                {option.description}
              </StandardText>
            </View>
          </View>
        </RadioButton>
      );
    });
  }

  _updateCategoryFilters = (filterObj: any) => {
    const selectedFilterNames = this.state.selectedFilterNames;

    let filterArray: any = [];
    let updateObject = {};

    if (filterObj.type == types.GOALS.FILTER.CATEGORY) {
      filterArray = this.state.selectedCategories;
      updateObject = {
        selectedCategories: filterArray,
        selectedFilterNames: selectedFilterNames,
      };
    } else if (filterObj.type == types.GOALS.FILTER.LIFESTAGE) {
      filterArray = this.state.selectedLifeStages;
      updateObject = {
        selectedLifeStages: filterArray,
        selectedFilterNames: selectedFilterNames,
      };
    }

    // Find out if the op is a remove or add
    if (!this.determineSelected(filterObj)) {
      filterArray.push(filterObj.id);
      selectedFilterNames.push(filterObj.description);
    } else {
      // Remmove the ID from the array
      var index = filterArray.indexOf(filterObj.id);
      filterArray.splice(index, 1);
      var dIndex = selectedFilterNames.indexOf(filterObj.description);
      selectedFilterNames.splice(dIndex, 1);
    }

    // Update the state
    this.setState(updateObject);
  };

  determineSelected(filterObj: any) {
    const selectedFilter = this.state.selectedFilterNames;

    // Adding one will have the option as {key: , description: }
    if (
      selectedFilter.filter(item => item === filterObj.description).length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  _saveFilterModal = () => {
    this.props.actions.GoalActions.setSelectedFilters(
      JSON.parse(JSON.stringify(this.state.goalsFilters)),
      this.state.selectedCategories,
      this.state.selectedLifeStages,
      this.state.selectedFilterNames,
    );
    if (types.isWeb) {
      this.props.actions.NavigationParamActions.setParam({
        isFilterApplied: true,
      });
      this.props.navigation.goBack();
    } else {
      this.props.navigation.dispatch(CommonActions.goBack());
    }
  };

  _closeFilterModal = () => {
    if (types.isWeb) {
      this.props.navigation.goBack();
    } else {
      this.props.navigation.dispatch(CommonActions.goBack());
    }
  };
}

const mapStateToProps = (state: any) => ({
  goals: state.GoalReducer,
  member: state.MemberReducer,
  navigationParams: state.NavigationParamsReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    GoalActions: bindActionCreators(GoalActions, dispatch),
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GoalFiltersForm);
