/*
 * Author: Shelan Cooray
 * Date: 14/09/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Display Favourites Things Today Add/Edit Form.
 */

import React, {Component} from 'react';
import {View} from 'react-native';
import {StandardInput} from '../Components/Atoms';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {MESSAGES} from '../Constants/Messages';

import {BasicForm} from '../Components/Organisms';
import {FormTitle, FormSubtitle, Container} from '../Components/Molecules';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

//Import Stylesheets

import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {FavouriteThingsPurple3x} from '../assets/images/vector-icons';
import {callAPIs} from '../API/APICaller';
import {getFavourites, updateFavourites} from '../API/AboutMeAPI';
import * as FavouriteThingsActions from '../Actions/FavouriteThingsActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';

import { DOMPurify } from 'helpers/dompurify';

class FavouriteThingsTodayForm extends Component {
  state = {
    likeToDo: '',
    likeToDoMainScreen: '',
    likeToDoForm: '',
    loading: true,
    saveApiError: false,
    refreshing: false,
  };

  constructor(props: any) {
    super(props);
    this._saveForm = this._saveForm.bind(this);
    this._cancelForm = this._cancelForm.bind(this);
    this._createMainContents.bind(this);
    this.fetchData.bind(this);
  }

  componentDidMount() {
    this.setState({loading: true});
    this.fetchData();
  }

  fetchData = () => {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    const callbackFunction = (data: any) => {
      const favouriteThings = data[0].content;
      if (
        JSON.stringify(this.props.favouriteThings) !==
        JSON.stringify(favouriteThings)
      ) {
        this.props.actions.FavouriteThingsActions.actionGetFavouriteThings(
          favouriteThings,
        );
      }
      this._initialiseDataInState(this.props.favouriteThings);
    };
    callAPIs(
      [
        getFavourites(
          this.props.loadedMemberId.loadedMemberId,
          this.props.user.demoProfile,
        ),
      ],
      callbackFunction,
      null,
      () => {
        if (types.isWeb) {
          this.props.setLoading(false);
        }
        this.setState({loading: false, refreshing: false});
      },
    );
  };

  _initialiseDataInState = (data: any) => {
    if (types.isWeb) {
      this.props.setLoading(false);
    }
    this.setState({
      likeToDo: data.likeToDo,
      likeToDoMainScreen: data.likeToDo,
      likeToDoForm: data.likeToDo,
      likeToDoFormInitialState: data.likeToDo,
      loading: false,
      refreshing: false,
    });
  };

  _hasFormContentChanged = () => {
    return (
      JSON.stringify(this.state.likeToDoFormInitialState) !==
      JSON.stringify(this.state.likeToDoForm)
    );
  };

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }

    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.TODAY_I_LIKE_TO}
        hasFormContentChanged={this._hasFormContentChanged}
        currentScreenName={FavouriteThingsTodayForm.name}
        onRefresh={() => {
          this.setState({refreshing: true});
          this.fetchData();
        }}
        refreshing={this.state.refreshing}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _cancelForm = () => {
    let csData = '';
    let stateUpdate = {};
    csData = this.state.likeToDoMainScreen;
    stateUpdate = {
      likeToDoMainScreen: csData,
      likeToDoForm: this.state.likeToDo,
      saveApiError: false,
    };
    this.setState(stateUpdate);
    this.props.navigation.goBack();
  };

  _saveForm = () => {
    let msData = '';
    let saveObj = {};
    msData = this.state.likeToDoForm;
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({loading: true, saveApiError: false});
    saveObj = {likeToDo: msData};
    this._saveFavourite(saveObj);
  };

  _saveFavourite = (saveObj: any) => {
    const updateFavouritesCallback = () => {
      this.props.actions.FavouriteThingsActions.actionUpdateFavouriteLikeToDo(
        saveObj,
      );
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        loading: false,
        likeToDoMainScreen: saveObj.likeToDo,
        likeToDoForm: saveObj.likeToDo,
        likeToDo: saveObj.likeToDo,
      });
      this.props.navigation.goBack();
    };

    const updateFavouritesErrorCallback = (err: any) => {
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
        loading: false,
      });
      this.props.navigation.goBack();
    };

    callAPIs(
      [
        updateFavourites(
          this.props.loadedMemberId.loadedMemberId,
          this.props.member.planId,
          saveObj,
          this.props.user.demoProfile,
        ),
      ],
      updateFavouritesCallback,
      updateFavouritesErrorCallback,
    );
  };

  _createMainContents = () => {
    return (
      <BasicForm
        headerDisplayString={MESSAGES.FAVOURITE_THINGS_SCREEN.TODAY_TILE_TITLE}
        headerIconImage={FavouriteThingsPurple3x}
        disableMore={true}
        readOnly={this.props.member.access.profile}
        close={() => this._cancelForm()}
        save={() => this._saveForm()}
        hideActionButton={true}
        buttonsPositionTop={true}
        contentWidthFull={true}
      >
        <View>
          <FormTitle
            text={MESSAGES.FAVOURITE_THINGS_SCREEN.TODAY_MODAL_TITLE}
            containsData={this.state.likeToDoForm}
          />
          <FormSubtitle
            text={[MESSAGES.FAVOURITE_THINGS_SCREEN.TODAY_MODAL_SUBTITLE]}
          />
          <StandardInput
            readOnly={this.props.member.access.profile}
            multiline={true}
            value={this.state.likeToDoForm}
            accessibilityLabel={
              MESSAGES.FAVOURITE_THINGS_SCREEN.TODAY_MODAL_PLACEHOLDER
            }
            onChangeText={(likeToDoFormDescription: any) =>{
              const sanitizedlikeToDoFormDescription = DOMPurify.sanitize(likeToDoFormDescription);

              this.setState({likeToDoForm: sanitizedlikeToDoFormDescription})
           } }
            underlineColorAndroid="transparent"
            style={[
              CommonStyles.ModalTextInputWrapperAutoHeight,
              BrandStyles.primaryBgColor1,
              CommonStyles.textAlignVerticalTop,
              types.isWeb ? CommonStyles.font15 : {},
            ]}
            blurOnSubmit={false}
          />
        </View>
      </BasicForm>
    );
  };
}

const mapStateToProps = (state: any) => ({
  favouriteThings: state.FavouriteThingsReducer,
  member: state.MemberReducer,
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    FavouriteThingsActions: bindActionCreators(
      FavouriteThingsActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FavouriteThingsTodayForm);
