import React, {Component} from 'react';
import {View} from 'react-native';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import {StandardButton, StandardText} from '../Atoms';

import {FORGOT_PASSWORD} from '../../Constants/Messages';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

class ForgotPasswordButton extends Component {
  render() {
    return (
      <StandardButton
        accessibilityLabel={
          FORGOT_PASSWORD.BUTTON_TEXT_1 + FORGOT_PASSWORD.BUTTON_TEXT_2
        }
        style={[
          CommonStyles.textAlignCentre,
          CommonStyles.buttonText,
          BrandStyles.TextColor2,
          CommonStyles.LoginSignUpP10,
          CommonStyles.rpfont12,
          CommonStyles.customFont,
        ]}
        containerStyle={[
          CommonStyles.LoginSignUpMT10,
          CommonStyles.paddingTop20,
          true ? CommonStyles.LoginSignUpMB10 : null,
        ]}
        onPress={() =>
          this.props.navigation.navigate('ForgotPassword', {
            enableBack: true,
            email: this.props.email ? this.props.email : '',
          })
        }
      >
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.alignFlexStart,
            CommonStyles.leftMargin10,
          ]}
        >
          <View>
            <StandardText
              style={[
                CommonStyles.customFont,
                BrandStyles.TextColor5,
                types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont12,
              ]}
            >
              {FORGOT_PASSWORD.BUTTON_TEXT_1}
            </StandardText>
          </View>
          <View>
            <StandardText
              style={[
                BrandStyles.TextColor5,
                CommonStyles.width100,
                CommonStyles.termsAndConditionsLine3,
                types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont12,
                CommonStyles.customFontBold,
              ]}
            >
              {FORGOT_PASSWORD.BUTTON_TEXT_2}
            </StandardText>
          </View>
        </View>
      </StandardButton>
    );
  }
}

export default ForgotPasswordButton;
