/*
 * Author: Shelan Cooray
 * Date: 21/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a summary screen to display the invoices that require the member to approve.
 */

import React, {Component} from 'react';
import {View, Image} from 'react-native';
import {find, groupBy, values, flatten} from 'lodash';

import {
  Container,
  FormTitle,
  SummaryCard,
  BasicOverlayPopupModal,
} from '../Components/Molecules';
import {StandardText} from '../Components/Atoms';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {APPROVALS_SUMMARY as messages, MESSAGES} from '../Constants/Messages';

import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {RightTick3x, InformationIconx3} from '../assets/images';

import {ApprovalsRed3x} from '../assets/images/vector-icons';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {callAPIs} from '../API/APICaller';
import {
  getApprovalRequired,
  getBudget,
  getPlans,
  putBulkInvoiceApproval,
  putInvoiceApproval,
  putAgreementApproval,
} from '../API/PlanDataAPI';
import {getBudgetCategories} from '../API/ReferenceAPI';
import * as BudgetActions from '../Actions/BudgetActions';
import * as MemberActions from '../Actions/MemberActions';
import {setServiceAgreementFeature} from '../Helpers/Utils';
import {filterBudgetCategories} from '../Helpers/determineAnyBudgetAccess';
import DeviceInfo from 'react-native-device-info';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {getValue, setValue, removeItem} from '../API/WebPersistenceStore';
import logger from 'helpers/Logger';

// Constants
const APPROVALS_INDEX = 0;
const BUDGET_INDEX = 1;
const BUDGET_CATEGORIES = 2;
const PLANS_INDEX = 3;

class ApprovalsSummary extends Component {
  state = {
    loading: true,
    approvalList: undefined,
    selectedItem: {},
    approveModalVisible: false,
    messageHeader: '',
    messageBody: '',
    errorPopup: false,
    isFetching: false,
    noOfItems: 0,
  };

  constructor(props: any) {
    super(props);
    this._filterBudgetCategoryStyles.bind(this);
    this._renderApprovePopUp.bind(this);
    this._modifyDataForList = this._modifyDataForList.bind(this);
    this._fetchApprovalData = this._fetchApprovalData.bind(this);
    this._refreshScreen = this._refreshScreen.bind(this);
    this._showApprovalPopUp = this._showApprovalPopUp.bind(this);
  }

  componentDidMount() {
    // There is no caching for the approvals, as each time the component is mounted, it should reload the approvals data that is contained in this screen.
    this._fetchApprovalData(true);
  }

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        ignorePadding={true}
        customStyle={CommonStyles.containerBudgetSummary}
        needsSidebar={false}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.APPROVAL}
        refreshing={this.state.isFetching}
        onRefresh={() => this._refreshScreen()}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    return (
      <View
        style={[
          CommonStyles.flexColumn,
          CommonStyles.content,
          CommonStyles.marginRight10,
        ]}
      >
        <View style={[CommonStyles.marginLeft10, CommonStyles.marginRight10]}>
          <FormTitle
            text={'Approvals'}
            textColor={BrandStyles.TextColor3}
            containsData={true}
            imageSource={ApprovalsRed3x}
            borderStyle={CommonStyles.MessageHeaderBorder}
            style={types.isWeb ? [CommonStyles.font20] : null}
          />
        </View>
        {this._renderList()}
        {types.isWeb
          ? this._renderApprovePopUp(false)
          : this._renderApprovePopUp(DeviceInfo.isTablet())}
      </View>
    );
  };

  _fetchApprovalData = (retrieveBudget: any) => {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    const dataCallback = (data: any) => {
      const approvalList = this._modifyDataForList(
        data[APPROVALS_INDEX].content,
      );

      // If the budget needs to be retrieved and updated
      if (retrieveBudget) {
        if (!(data[BUDGET_INDEX].statusCode == types2.NO_PLANS_ERROR_CODE)) {
          const budgetPayload = data[BUDGET_INDEX].content;

          // Check if Service Agreement feature is disabled from the LIBE
          if (budgetPayload.isServiceAgreementsEnabled != null) {
            if (types.isWeb) {
              setServiceAgreementFeature(
                budgetPayload.isServiceAgreementsEnabled,
              );
            } else {
              this.props.actions.MemberActions.actionSetServiceAgreementEnabled(
                budgetPayload.isServiceAgreementsEnabled,
              );
            }
          }

          const budgetsCategories = data[BUDGET_CATEGORIES].content;
          this.props.actions.BudgetActions.actionGetBudgets(budgetPayload);
          this.props.actions.BudgetActions.actionGetBudgetCategories(
            filterBudgetCategories(budgetsCategories),
          );
        }
        if (data[PLANS_INDEX]) {
          const plans = data[PLANS_INDEX].content;
          this.props.actions.BudgetActions.actionAddPreviousPlans(plans);
        }
      }

      if (types.isWeb) {
        this.props.setLoading(false);
      }
      // Update the state
      this.setState({
        loading: false,
        isFetching: false,
        approvalList,
      });

      if (
        this.props.navigationParams.params &&
        this.props.navigationParams.params.data
      ) {
        const item = flatten(approvalList).filter(
          item => item.invoiceKey == this.props.navigationParams.params.data,
        );

        if (item && item.length > 0) {
          this._itemViewPress(item[0]);
        }
      }

      if (getValue(types2.BOOK_MARK_EXTRA_ID)) {
        const item = flatten(approvalList).filter(
          item => item.invoiceKey == getValue(types2.BOOK_MARK_EXTRA_ID),
        );
        removeItem(types2.BOOK_MARK_EXTRA_ID);
        if (item && item.length > 0) {
          this._itemViewPress(item[0]);
        }
      }
    };

    const loadedMemberId = this.props.loadedMemberId.loadedMemberId;
    const apiArray = [
      getApprovalRequired(loadedMemberId, this.props.user.demoProfile),
    ];

    if (retrieveBudget) {
      apiArray.push(getBudget(loadedMemberId, this.props.user.demoProfile));
      apiArray.push(getBudgetCategories());
      apiArray.push(getPlans(loadedMemberId, this.props.user.demoProfile));
    }

    callAPIs(apiArray, dataCallback, null, () => {
      if (types.isWeb) {
        this.props.setLoading(true);
      }
      this.setState({loading: false, isFetching: false});
    });
  };

  _groupApprovalInvoices = (approvalInvoices: any) => {
    // group the invoice approvals with a groupId by groupId
    // filter out groups which has length less than 2 and
    // get the rest to a single array and group them again with the same group clause
    const groupableInvoices = approvalInvoices.filter((x: any) => x.groupId);
    const groupedApprovalInvoices = groupBy(
      flatten(
        values(groupBy(groupableInvoices, x => x.groupId)).filter(
          x => x.length > 1,
        ),
      ),
      x => x.groupId,
    );
    Object.keys(groupedApprovalInvoices).forEach((key, index) => {
      groupedApprovalInvoices[key] = {
        added: false,
        items: groupedApprovalInvoices[key],
      };
    });

    return groupedApprovalInvoices;
  };

  _sortInvoicesByGroupId = (invoice1: any, invoice2: any) => {
    //Check if grouping is enabled if so group mapped invoices by groupId
    if (!invoice1.groupId || !invoice2.groupId) {
      return 0;
    }
    if (invoice1.groupId.toLowerCase() > invoice2.groupId.toLowerCase()) {
      return 1;
    }
    if (invoice1.groupId.toLowerCase() < invoice2.groupId.toLowerCase()) {
      return -1;
    }
    return 0;
  };

  _modifyDataForList = (initialList: any) => {
    const budgetCategories = this.props.budgets.budgetCategories;

    let mappedItems = [];
    let invoicedItems: any = [];
    let noOfItems = 0;

    // Go through the data and massage the data into a format that is useful for the summary list component
    const approvalInvoices = initialList.invoices;
    if (approvalInvoices && Array.isArray(approvalInvoices)) {
      noOfItems = approvalInvoices.length;
      const groupedInvoices = this._groupApprovalInvoices(approvalInvoices);
      const mappedInvoices = approvalInvoices
        .map(approvalInvoiceItem => {
          const mappedInvoiceItem = {biller: {}};

          mappedInvoiceItem.isHedgehog = this.props.member.isHedgehog;
          mappedInvoiceItem.invoiceDate = approvalInvoiceItem.date;
          mappedInvoiceItem.invoiceKey = approvalInvoiceItem.invoiceKey;
          mappedInvoiceItem.type = types2.APPROVAL_TYPE.INVOICE;
          mappedInvoiceItem.id = approvalInvoiceItem.id;
          mappedInvoiceItem.biller.name = approvalInvoiceItem.provider;
          mappedInvoiceItem.invoiceNumber = this._getTypeName(
            types2.APPROVAL_TYPE.INVOICE,
            approvalInvoiceItem.number,
          );
          mappedInvoiceItem.number = approvalInvoiceItem.number;
          mappedInvoiceItem.total = approvalInvoiceItem.total;
          mappedInvoiceItem.uri = approvalInvoiceItem.uri;
          mappedInvoiceItem.isMemberSubmitted =
            approvalInvoiceItem.isMemberSubmitted;
          mappedInvoiceItem.groupId = approvalInvoiceItem.groupId;
          mappedInvoiceItem.providerFriendlyName =
            approvalInvoiceItem.providerFriendlyName;

          // If the item contains a groupId and groupId matches a group in groupedInvoicesLits
          // Then add the invoices group to the item and remove the group from the groupedInvoicesLits
          // So the only the first item in the list will contain the groupedList
          if (
            approvalInvoiceItem.groupId &&
            groupedInvoices[approvalInvoiceItem.groupId]
          ) {
            mappedInvoiceItem.groupedInvoices =
              groupedInvoices[approvalInvoiceItem.groupId];
            delete groupedInvoices[approvalInvoiceItem.groupId];
          }
          return mappedInvoiceItem;
        })
        .sort((inv1, inv2) => this._sortInvoicesByGroupId(inv1, inv2));

      // Push the mapped invoice items into the mapped items
      invoicedItems = invoicedItems.concat(mappedInvoices);

      const groupedApprovalInvoices =
        this._groupApprovalInvoices(invoicedItems);

      let resultList: any = [];
      let count: any;
      invoicedItems.map((approvalInvoiceItem, index) => {
        if (groupedApprovalInvoices[approvalInvoiceItem.groupId]) {
          if (!groupedApprovalInvoices[approvalInvoiceItem.groupId].added) {
            resultList.push(
              groupedApprovalInvoices[approvalInvoiceItem.groupId].items,
            );
            groupedApprovalInvoices[approvalInvoiceItem.groupId].added = true;
          }
        } else {
          if (index == count + 1) {
            const lastIndex = resultList[resultList.length - 1];
            lastIndex.push(approvalInvoiceItem);
          } else {
            resultList.push([approvalInvoiceItem]);
          }
          count = index;
        }
      });

      mappedItems = resultList;
    }

    // Go through the service agreement information
    const approvalServiceAgreements = initialList.serviceAgreements;
    if (approvalServiceAgreements && Array.isArray(approvalServiceAgreements)) {
      noOfItems = approvalServiceAgreements.length + noOfItems;
      const mappedServiceAgreements = approvalServiceAgreements.map(
        approvalServiceItem => {
          const mappedServiceItem = {biller: {}};

          mappedServiceItem.invoiceDate = approvalServiceItem.startDate;
          mappedServiceItem.type = types2.APPROVAL_TYPE.SERVICE;
          mappedServiceItem.invoiceKey = approvalServiceItem.snowId;
          mappedServiceItem.id = approvalServiceItem.id;
          mappedServiceItem.biller.name = approvalServiceItem.providerName;
          let serviceNumber = messages.SERVICE_AGREEMENT;
          if (approvalServiceItem.contractNumber) {
            serviceNumber = approvalServiceItem.contractNumber;
          }
          mappedServiceItem.invoiceNumber = serviceNumber;
          mappedServiceItem.budgetCategory = approvalServiceItem.budgetCategory;
          mappedServiceItem.description = approvalServiceItem.description;
          mappedServiceItem.startDate = approvalServiceItem.startDate;
          mappedServiceItem.endDate = approvalServiceItem.endDate;
          mappedServiceItem.total = approvalServiceItem.value
            ? approvalServiceItem.value
            : 0;
          mappedServiceItem.budgetCategoryData =
            this._filterBudgetCategoryStyles(
              budgetCategories,
              approvalServiceItem.budgetCategoryId,
            );

          return mappedServiceItem;
        },
      );

      // Push the mapped service agreement items into the mapped items
      if (mappedItems.length > 0 && mappedServiceAgreements.length > 0) {
        if (mappedItems[mappedItems.length - 1][0].groupedInvoices) {
          mappedItems.push(mappedServiceAgreements);
        } else {
          const lastIndex = mappedItems[mappedItems.length - 1];
          const services = lastIndex.concat(mappedServiceAgreements);
          delete mappedItems[mappedItems.length - 1];
          mappedItems.push(services);
        }
      } else if (mappedServiceAgreements.length > 0) {
        mappedItems.push(mappedServiceAgreements);
      }
    }
    this.setState({noOfItems});
    return mappedItems;
  };

  _filterBudgetCategoryStyles = (
    budgetCategories: any,
    budgetCategoryId: any,
  ) => {
    return find(budgetCategories, {categoryId: budgetCategoryId});
  };

  _renderList = () => {
    if (this.state.approvalList) {
      return (
        <View style={[CommonStyles.flex1, CommonStyles.paddingBottom100]}>
          <View style={[CommonStyles.alignItemsCenter]}>
            <StandardText
              style={[
                CommonStyles.textResultSummary,
                CommonStyles.customFontBold,
                CommonStyles.paddingBottom0,
                BrandStyles.TextColor13,
              ]}
            >
              {`${types2.RESULT_COUNT.SHOW}${this.state.noOfItems}${types2.RESULT_COUNT.RESULTS}`}
            </StandardText>
          </View>
          <SummaryCard
            itemDimension={400}
            isMobile={this.props.isMobile}
            viewWidth={this.props.viewWidth}
            data={this.state.approvalList}
            extraData={this.state}
            isPositive={true}
            itemViewPress={this._itemViewPress.bind(this)}
            itemApprovePress={this._showApprovalPopUp.bind(this)}
            approveInvoiceGroup={this._approveInvoiceGroup}
            readOnly={this.props.member.access.finances}
            disabled={
              this.props.member.access.finances !== types.ACCESS_FULL
                ? true
                : false
            }
          />
          {Array.isArray(this.state.approvalList) &&
          !this.state.approvalList.length
            ? this._showEmptyView()
            : null}
        </View>
      );
    } else {
      return null;
    }
  };

  _showEmptyView = () => {
    return (
      <View style={[CommonStyles.flex1, CommonStyles.alignCenter]}>
        <Image style={[CommonStyles.imageNoApprovals]} source={RightTick3x} />
        <StandardText
          style={[CommonStyles.approvalEmptyMsg, BrandStyles.TextColor5]}
        >
          {messages.NO_APPROVAL_MESSAGE}
        </StandardText>
        {!types.isWeb ? (
          <StandardText
            style={[CommonStyles.approvalEmptyMsg, BrandStyles.TextColor5]}
          >
            {types.PULL_TO_REFRESH}
          </StandardText>
        ) : null}
      </View>
    );
  };

  _getTypeName = (type: any, value: any) => {
    let messageType = '';
    if (type === types2.APPROVAL_TYPE.INVOICE) {
      messageType = messages.TYPE.INVOICE;
    } else if (type === types2.APPROVAL_TYPE.SERVICE) {
      messageType = messages.TYPE.SERVICE;
    }

    return `${messageType} #${value}`;
  };

  _itemViewPress = (item: any) => {
    let routeName = null;

    let notificationData = null;

    if (
      this.props.navigationParams.params &&
      this.props.navigationParams.params.notificationData
    ) {
      notificationData = this.props.navigationParams.params.notificationData;
    }

    if (item.type === types2.APPROVAL_TYPE.INVOICE) {
      routeName = 'ApprovalsDetailsInvoice';
      if (!types.isWeb && this.props.member.isHedgehog) {
        routeName = 'ApprovalsDetailsInvoiceHH';
      }
    } else if (item.type === types2.APPROVAL_TYPE.SERVICE) {
      routeName = 'ApprovalsDetailsServAgreement';
    }

    if (routeName) {
      this.props.actions.navigationParamActions.setParam({
        selectedItem: item,
        refreshSummary: this._refreshScreen.bind(this),
        notificationData: notificationData,
      });
      this.props.navigation.navigate(routeName, {});
    }
  };
  _showInvoiceApprovalPopUp = (header: any, message: any) =>
    this.setState({
      errorPopup: false,
      loading: false,
      messageHeader: header,
      messageBody: message,
      approveModalVisible: true,
    });

  _approveInvoiceGroup = async (invoices: any) => {
    const invokeBulkInvoiceApprovalAPI = (invoices: any) =>
      new Promise((resolve, reject) => {
        callAPIs(
          [
            putBulkInvoiceApproval(
              this.props.loadedMemberId.loadedMemberId,
              invoices.map((i: any) => ({
                invoiceId: i.id,
                invoiceURI: i.uri,
              })),
              types2.APPROVAL.APPROVE,
            ),
          ],
          (result: any) => resolve(result[0].content),
          (err: any) => reject(err),
        );
      });
    try {
      if (types.isWeb) {
        this.props.setLoading(true);
      } else {
        this.setState({loading: true});
      }
      const invoiceApproveResponse = await invokeBulkInvoiceApprovalAPI(
        invoices,
      );
      this._showInvoiceApprovalPopUp(
        messages.APPROVED_INVOICE,
        invoiceApproveResponse.message,
      );
    } catch (error) {
      this._showInvoiceApprovalPopUp(messages.APPROVAL_FAILED, error.message);
    }
  };

  _itemApprovePress = (item: any) => {
    if (types.isWeb) {
      this.props.setLoading(true);
    } else {
      this.setState({loading: true});
    }
    const approveCallback = (data: any) => {
      if (this.state.selectedItem.type === types2.APPROVAL_TYPE.INVOICE) {
        // Show success popup for invoices
        let responseMessage = '';

        if (data.length > 0) {
          responseMessage = data[0].content.message;
        }
        if (types.isWeb) {
          this.props.setLoading(false);
        }
        this.setState({
          errorPopup: false,
          loading: false,
          messageHeader: messages.APPROVED_INVOICE,
          messageBody: responseMessage,
          approveModalVisible: true,
        });
      } else {
        if (types.isWeb) {
          this.props.setLoading(false);
        }
        this.setState({
          loading: false,
          messageHeader: messages.APPROVED_SA,
          messageBody: messages.APPROVED_MESSAGE,
          approveModalVisible: true,
        });
      }
    };

    const approveErrorCallback = (err: any) => {
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
        loading: false,
      });

      if (this.state.selectedItem.type === types2.APPROVAL_TYPE.INVOICE) {
        // Show error popup for invoices
        if (this.props.user.demoProfile) {
          this.setState({
            errorPopup: true,
            messageHeader: types2.DEMO_APPROVAL_FAIL.TITLE,
            messageBody: types2.DEMO_APPROVAL_FAIL.BODY,
            approveModalVisible: true,
          });
        } else {
          this.setState({
            errorPopup: true,
            messageHeader: messages.APPROVAL_FAILED,
            messageBody: err.message,
            approveModalVisible: true,
          });
        }
      }
    };

    // Call the API to approve the invoice
    if (item.type === types2.APPROVAL_TYPE.INVOICE) {
      callAPIs(
        [
          putInvoiceApproval(
            this.props.loadedMemberId.loadedMemberId,
            item.uri,
            item.id,
            types2.APPROVAL.APPROVE,
            null,
            this.props.user.demoProfile,
          ),
        ],
        approveCallback,
        approveErrorCallback,
      );
    } else if (item.type === types2.APPROVAL_TYPE.SERVICE) {
      callAPIs(
        [
          putAgreementApproval(
            this.props.loadedMemberId.loadedMemberId,
            item.id,
            types2.APPROVAL.APPROVE,
          ),
        ],
        approveCallback,
        approveErrorCallback,
      );
    }
  };

  _refreshScreen = () => {
    // Need to refresh the summary screen, so bring up the loading spinner and then call the api
    this.setState({isFetching: true});
    this._fetchApprovalData(true);
  };

  _renderApprovePopUp = (isTablet: any) => {
    return (
      <BasicOverlayPopupModal
        visible={this.state.approveModalVisible}
        style={this._determinePopUpStyle(isTablet)}
        backdropOpacity={0.2}
        divider={true}
        close={this._closeApprovePopUp}
        cancelContainerStyle={CommonStyles.popUpOkButton}
        cancelStyle={[CommonStyles.buttonPopupOk, CommonStyles.customFontBold]}
        cancelLabel={types2.OK_GOT_IT}
      >
        <View>{this.getPopUpContent(isTablet)}</View>
      </BasicOverlayPopupModal>
    );
  };

  getPopUpContent(isTablet: any) {
    let messageFontSize = CommonStyles.rpfont15;
    let headerFontSize = CommonStyles.font20;
    if (isTablet) {
      messageFontSize = CommonStyles.font20;
      headerFontSize = CommonStyles.font25;
    } else if (types.isWeb) {
      messageFontSize = CommonStyles.font15;
      headerFontSize = CommonStyles.font18;
    }
    return (
      <View style={[]}>
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.scrollView_subContentCenter,
            CommonStyles.paddingBottom5p,
          ]}
        >
          {this.state.errorPopup ? (
            <View>
              <Image
                visible={this.state.errorPopup}
                style={[CommonStyles.changePasswordButtonImage]}
                source={InformationIconx3}
                accessibilityLabel={
                  MESSAGES.ACCESSIBILITY.IMPORTANT_INFORMATION
                }
                accessible={true}
              />
            </View>
          ) : null}
          <View style={[CommonStyles.scrollView_subContentCenter]}>
            <StandardText
              style={[
                headerFontSize,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
              ]}
            >
              {this.state.messageHeader}
            </StandardText>
          </View>
        </View>
        <View
          style={[
            CommonStyles.paddingTop5,
            CommonStyles.scrollView_subContentCenter,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFont,
              BrandStyles.TextColor5,
              messageFontSize,
            ]}
          >
            {this.state.messageBody}
          </StandardText>
        </View>
      </View>
    );
  }

  _determinePopUpStyle = (isTablet: any) => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      styles.push([CommonStyles.containerInstructionPopUpWeb]);

      if (this.props.isMobile) {
        styles.push([CommonStyles.containerPopupWebMobile]);
      }
    } else if (isTablet) {
      styles.push([
        CommonStyles.containerInstructionPopUpTablet,
        CommonStyles.height320,
      ]);
    } else {
      styles.push(
        CommonStyles.containerInstructionPopUpMobile,
        CommonStyles.height300,
        CommonStyles.width340,
      );
    }
    return styles;
  };

  _showApprovalPopUp = (item: any) => {
    this.setState(
      {
        selectedItem: item,
      },
      () => {
        this._itemApprovePress(this.state.selectedItem);
      },
    );
  };

  _closeApprovePopUp = () => {
    this._refreshScreen();
    this.setState({approveModalVisible: false});
  };
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  loadedMemberId: state.LoadedMemberReducer,
  budgets: state.BudgetReducer,
  navigationParams: state.NavigationParamsReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    BudgetActions: bindActionCreators(BudgetActions, dispatch),
    navigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    MemberActions: bindActionCreators(MemberActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalsSummary);
