/*
 * Author: Andrew Lee
 * Date: 16/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Tabbar actions for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const displayTabBar = () => ({
  type: types2.TAB_BAR_SHOW,
});

export const hideTabBar = () => ({
  type: types2.TAB_BAR_HIDE,
});

export const displayGuideMe = (selectedTab, isManualOpen = false) => ({
  type: types2.GUIDEME_SHOW,
  selectedTab,
  isManualOpen,
});

export const hideGuideMe = (selectedTab: any) => ({
  type: types2.GUIDEME_HIDE,
  selectedTab,
});

export const addSelectedTab = (selectedTab: any) => ({
  type: types2.ADD_SELECTED_TAB,
  selectedTab,
});

export const getGuideMeMember = (guideMeMember: any) => ({
  type: types2.GET_GUIDEME_MEMBER,
  guideMeMember,
});
