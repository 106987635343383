/*
 * Author: Andrew Seeley
 * Date: 04/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a wrapper around RN Text with a standard styling applied.
 */

import React, {Component} from 'react';
import {Text, StyleSheet} from 'react-native';

class StandardText extends Component {
  render() {
    let accessibilityLabel = '';
    // If an accessibility label is set use it
    // Otherwise us the button text for its label
    if (this.props.accessibilityLabel) {
      accessibilityLabel = this.props.accessibilityLabel;
    } else {
      // Any sort of children could be passed to this button, e.g some buttons use images instead.
      // So first of all extract any test that may have been passed, if any we use that for the accessibility label
      // Otherwise if there is no text passed (E.g just a button with an image only) throw an error as we will

      // If multiple items are passed in the props children
      // Look for any text and concatenate it to use it as the accessibility label
      if (Array.isArray(this.props.children)) {
        accessibilityLabel = '';
        for (const i in this.props.children) {
          // If it's a string build the accessibility label
          if (this.isString(this.props.children[i])) {
            accessibilityLabel += this.props.children[i];
          }
        }
      } else {
        // No array was passed in, it's just a single item so check if it's just a string
        if (this.isString(this.props.children)) {
          accessibilityLabel = this.props.children;
          // If there was no text passed in set it as empty
        } else {
          accessibilityLabel = '';
        }
      }
    }

    return (
      <Text
        allowFontScaling={this.props.allowFontScaling}
        accessibilityLabel={accessibilityLabel}
        style={this.props.style}
        onPress={this.props.onPress ? this.props.onPress : null}
        accessibilityLiveRegion={this.props.accessibilityLiveRegion}
        numberOfLines={
          this.props.numberOfLines ? this.props.numberOfLines : null
        }
        ellipsizeMode={
          this.props.ellipsizeMode ? this.props.ellipsizeMode : null
        }
      >
        {this.props.children}
      </Text>
    );
  }

  isString(item: any) {
    if (item instanceof String || typeof item === 'string') {
      return true;
    }
    return false;
  }
}

export default StandardText;
