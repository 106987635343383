/*
 * Authour: Andrew Seeley
 * Date: 09/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This screen will hold the rendering and logic of the displaying the crew that the member has attached to their account.
 */

import React, {Component} from 'react';
import {StyleSheet, View, TouchableHighlight} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {orderBy, forEach} from 'lodash';

import {BasicForm} from '../Components/Organisms';
import {
  Container,
  PhotoTile,
  FormTitle,
  GridView,
  FormSubtitle,
} from '../Components/Molecules';

// Import Stylesheets

import {callAPIs} from '../API/APICaller';
import {getMember} from '../API/MemberAPI';
import {getCrewAccessLevels} from '../API/CrewAPI';
import {getCrew} from '../API/PersonAPI';
import {getCrewCategories} from '../API/ReferenceAPI';
import {setValue, removeItem} from '../API/WebPersistenceStore';

import CommonStyles from '../Styles/CommonStyles';

import {
  SCREEN_TYPE_MAIN,
  ACCESS_FULL,
  FORM_MODE_NEW,
  FORM_MODE_EDIT,
  isWeb,
} from '../Constants/Constants';
import {
  NAVIGATION_TEXT,
  FILE_TYPE,
  ITEM_DIMENSION,
  WEB_STORE,
  NAV_SCREENS,
} from '../Constants/Constants2';

import {MESSAGES} from '../Constants/Messages';

import * as CrewActions from '../Actions/CrewActions';
import * as SideMenuActions from '../Actions/SideMenuActions';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {types} from 'react-native-mime-types-enhanced';

const ORDER_BY_NAME = 'orderByName';
class Crew extends Component {
  state = {
    loading: false,
    lastUpdate: new Date(),
    refreshing: false,
  };

  constructor(props: any) {
    super(props);
    this.renderCrewCategories.bind(this);
    this._headerTitle.bind(this);
    this._editCrewForm.bind(this);
    this._showCrewForm.bind(this);
    this._navigatedBack.bind(this);
    this._renderIndividualCrew.bind(this);
    this.fetchData.bind(this);
  }

  render() {
    if (isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={true}
        screenType={SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={NAVIGATION_TEXT.CREW}
        onRefresh={() => {
          this.setState({refreshing: true});
          this.fetchData();
        }}
        refreshing={this.state.refreshing}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  componentDidMount() {
    this._setLoading(true);
    this.fetchData();
  }

  _setLoading = (loadingState: any) => {
    if (isWeb) {
      this.props.setLoading(loadingState);
    } else {
      this.setState({loading: loadingState});
    }
  };

  fetchData = () => {
    const RESULT_INDEX = {
      CREW_CATEGORIES: 0,
      CREW: 1,
      CREW_ACCESS: 2,
    };

    const callbackFunction = (results: any) => {
      const crewCategoryPayload =
        results[RESULT_INDEX.CREW_CATEGORIES].content.categories;
      const personCrewsPayload = results[RESULT_INDEX.CREW].personCrews.content;
      const organisationCrewsPayload =
        results[RESULT_INDEX.CREW].organisationCrews.content;
      const crewsAccessPayload = results[RESULT_INDEX.CREW_ACCESS].content;

      // Write the data to redux
      this.props.actions.CrewActions.actionGetCrews(
        crewCategoryPayload,
        personCrewsPayload,
        organisationCrewsPayload,
        crewsAccessPayload,
      );
      this._setLoading(false);
      this.setState({refreshing: false});
    };

    callAPIs(
      [
        getCrewCategories(
          this.props.loadedMemberId.loadedMemberId,
          this.props.user.demoProfile,
        ),
        getCrew(
          this.props.loadedMemberId.loadedMemberId,
          this.props.user.demoProfile,
        ),
        getCrewAccessLevels(
          this.props.loadedMemberId.loadedMemberId,
          this.props.user.demoProfile,
        ),
      ],
      callbackFunction,
      null,
      () => {
        this._setLoading(false);
        this.setState({refreshing: false});
      },
    );
  };

  _createMainContents = () => {
    return (
      <BasicForm
        headerDisplayString={'Who is important in your life'}
        readOnly={'full'}
        disableMore={true}
        showButton={this.props.member.access.profile === ACCESS_FULL}
        modal={this._showCrewForm}
        readOnly={this.props.member.access.profile}
        contentWidthFull={true}
      >
        <FormSubtitle text={[MESSAGES.PLACEHOLDER.CREW_SUBTEXT]} />
        <View style={[CommonStyles.flex1]}>{this.renderCrewCategories()}</View>
      </BasicForm>
    );
  };

  renderCrewCategories() {
    if (this.props.crews !== undefined) {
      return this.props.crews.PersonOrgCrew.map((crews: any) => (
        <View
          style={[CommonStyles.paddingBottom10]}
          key={`view${crews.description}`}
        >
          <FormTitle text={crews.description} containsData={crews} />
          <View
            style={[
              CommonStyles.justifyCenter,
              CommonStyles.flex1,
              CommonStyles.paddingLeft20,
            ]}
          >
            <GridView
              viewWidth={this.props.viewWidth - 75}
              itemDimension={ITEM_DIMENSION}
              isMobile={this.props.isMobile}
              renderItem={(item: any) => this._renderIndividualCrew(item)}
            >
              {this._orderedCrew(crews.crews)}
            </GridView>
          </View>
        </View>
      ));
    } else {
      return null;
    }
  }

  _orderedCrew = (crews: any) => {
    let orderdCrews = forEach(crews, c => {
      // Creating a runtime key by assigning firstName/knownAs(if a person) or orgName(if an organization)
      // to 'orderByName' to sort by Alphabatic order
      if (c.memberType === 'person') {
        if (c.knownAs !== null && c.knownAs !== '') {
          c[ORDER_BY_NAME] = c.knownAs;
        } else {
          c[ORDER_BY_NAME] = c.firstName;
        }
      } else {
        c[ORDER_BY_NAME] = c.orgName;
      }
    });
    orderdCrews = orderBy(
      orderdCrews,
      [crew => crew.orderByName.toLowerCase()],
      ['asc'],
    );
    return orderdCrews;
  };

  _renderIndividualCrew = (crewItem: any) => {
    let crewImage = null;
    let caption = '';
    // Set the caption - it is their known as if they have one, otherwise user their name
    if (crewItem.memberType === 'person') {
      if (crewItem.knownAs != null && crewItem.knownAs !== '') {
        caption = crewItem.knownAs;
      } else {
        caption = `${crewItem.firstName} ${crewItem.lastName}`;
      }
    } else {
      caption = crewItem.orgName;
    }

    // Check if the user has a profile image, if so set it
    if (crewItem.profileImage && crewItem.profileImage.url) {
      crewImage = crewItem.profileImage;
    }

    return (
      <TouchableHighlight
        onPress={this._editCrewForm.bind(this, crewItem)}
        underlayColor="white"
      >
        <View style={CommonStyles.paddingBottom20}>
          <PhotoTile
            key={crewItem.crewId}
            style={StyleSheet.flatten(CommonStyles.CrewPhotoTile)}
            apiFileType={FILE_TYPE.CREW_PROFILE}
            imageObject={crewImage}
            showCaption={true}
            caption={caption}
            actionCallback={this._editCrewForm}
            actionData={crewItem}
            accessibilityLabel={caption + ' crew edit'}
            lastUpdate={this.state.lastUpdate}
            photoBorderRadius={CommonStyles.borderRadiusRound}
          />
        </View>
      </TouchableHighlight>
    );
  };

  _showCrewForm = () => {
    const navigationParams = {
      screenMode: FORM_MODE_NEW,
      editEmail: true,
      onBack: this._navigatedBack,
    };
    if (isWeb) {
      removeItem(WEB_STORE.CREW_NAVIGATION_PARAMS);
      // Reset crew state
      removeItem(WEB_STORE.CREW_STATE);
      // Remove stored crew type
      removeItem(WEB_STORE.CREW_TYPE);
      setValue(
        WEB_STORE.CREW_NAVIGATION_PARAMS,
        JSON.stringify(navigationParams),
      );
    }
    this.props.actions.NavigationParamActions.setParam(navigationParams);
    this.props.navigation.navigate(NAV_SCREENS.CREW_FORM);
  };

  // Show the modal in edit mode, the crew selected is passed onto the function
  _editCrewForm = (crew: any) => {
    let editEmail = true;
    if (crew.email) {
      editEmail = false;
    }
    const navigationParams = {
      screenMode: FORM_MODE_EDIT,
      selectedCrew: crew,
      editEmail: editEmail,
      onBack: this._navigatedBack,
    };
    if (isWeb) {
      removeItem(WEB_STORE.CREW_NAVIGATION_PARAMS);
      // Reset crew state
      removeItem(WEB_STORE.CREW_STATE);
      setValue(
        WEB_STORE.CREW_NAVIGATION_PARAMS,
        JSON.stringify(navigationParams),
      );
    }
    this.props.actions.NavigationParamActions.setParam(navigationParams);
    this.props.navigation.navigate(NAV_SCREENS.CREW_FORM);
  };

  // Set the header title
  _headerTitle = () => {
    let titleString = '';
    if (this.props.member.knownAs) {
      titleString = this.props.member.knownAs + "'s crew";
    } else {
      titleString = this.props.member.firstName + "'s crew";
    }
    return titleString;
  };

  _navigatedBack = () => {
    // Update the state
    this.setState({lastUpdate: new Date()});
  };
}

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  member: state.MemberReducer,
  crews: state.CrewReducer,
  loadedMemberId: state.LoadedMemberReducer,
  sideMenu: state.SideMenuReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    CrewActions: bindActionCreators(CrewActions, dispatch),
    SideMenuActions: bindActionCreators(SideMenuActions, dispatch),
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Crew);
