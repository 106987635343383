/*
 * Author: Tharindu Seneviratne
 * Date: 29/01/2019
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the Payment History Item list as separate sections for HH member
 */

import React, {Component} from 'react';
import {View, Image} from 'react-native';

import {StandardText} from '../Atoms';
import {
  CurrencyText,
  PaymentListHeader,
  AccordianInvoiceTile,
} from './';
import {
  formatDurationString,
  formatDDMMMYYDate,
  formatQuantity,
} from '../../Helpers/Utils';
import {isWeb} from '../../Constants/Constants';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import {GreyClockIcon3x} from '../../assets/images';
import logger from 'helpers/Logger';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import * as Images from '../../assets/images';

class PaymentListHH extends Component {
  constructor(props: any) {
    super(props);
    this._renderTableContents = this._renderTableContents.bind(this);
  }

  render() {
    return <View>{this._renderLineItem()}</View>;
  }

  _renderLineItem = () => {
    let dataArray = this.props.data;
    if (Array.isArray(dataArray) && dataArray.length > 0) {
      return dataArray.map((item, index) => {
        const itemContent = [
          item.itemCode,
          item.description,
          item.budgetCategory,
          this._renderQuantity(item.quantity, item.duration),
          item.unitPrice,
          item.total,
          this._renderDateRange(item.startDate, item.endDate),
          this._renderQuantity(item.claimedQuantity, item.claimedDuration),
          item.claimedUnitPrice,
          item.claimedTotal,
        ];

        itemContent.push(item.funded);

        let addSeparator = true;

        if (index == dataArray.length - 1) {
          addSeparator = false;
        }

        return this._renderTableContents(
          itemContent,
          index,
          addSeparator,
          item.claimedTotal !== item.total,
        );
      });
    }
  };

  _renderQuantity = (quantity: any, duration: any) => {
    if (quantity !== undefined && quantity !== null) {
      return formatQuantity(quantity);
    } else if (duration) {
      return formatDurationString(duration);
    }
  };

  _renderDateRange = (startDate: any, endDate: any) => {
    if (endDate == undefined) {
      return formatDDMMMYYDate(startDate);
    }
    return formatDDMMMYYDate(startDate) + ' - ' + formatDDMMMYYDate(endDate);
  };

  _renderTableContents = (
    contents: any,
    key: any,
    addSeparator: any,
    showActual: any,
  ) => {
    const [
      itemCode,
      itemDesc,
      itemCategory,
      itemQty,
      itemUnitPrice,
      itemTotal,
      dateRange,
      claimedQuantity,
      claimedUnitPrice,
      claimedTotal,
      itemFunded,
    ] = contents;

    let viewKey = key;
    if (!viewKey) {
      viewKey = 0;
    }

    return (
      <View
        key={viewKey}
        style={
          addSeparator
            ? CommonStyles.containerList_Item
            : CommonStyles.containerList_LastItem
        }
      >
        <View
          style={[
            CommonStyles.justifyFlexRowStart,
            CommonStyles.marginBottom5,
            CommonStyles.alignItemsCenter,
          ]}
        />
        <View
          style={[
            CommonStyles.justifyFlexRowStart,
            CommonStyles.marginTop10,
            CommonStyles.marginBottom10,
          ]}
        >
          <View
            style={[
              isWeb
                ? [CommonStyles.flex02, CommonStyles.alignCenter]
                : CommonStyles.flex1,
              CommonStyles.flexDirectionColumn,
              CommonStyles.justifyContentFlexStart,
            ]}
          >
            <StandardText
              style={[
                CommonStyles.title_ListContainerSemiBold,
                CommonStyles.customFontSemiBold,
              ]}
            >
              {this.props.itemCode}
            </StandardText>
            <View
              style={[
                CommonStyles.containerGreyTextPayment,
                CommonStyles.marginTop10,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.title_ListContainer,
                  CommonStyles.customFont,
                  isWeb ? [CommonStyles.containerInvoiceIdTextPayment] : null,
                ]}
              >
                {itemCode}
              </StandardText>
            </View>
          </View>
          <View
            style={[
              isWeb ? CommonStyles.flex025 : CommonStyles.flex1,
              CommonStyles.flexDirectionColumn,
              CommonStyles.alignCenter,
            ]}
          >
            <StandardText
              style={[
                CommonStyles.title_ListContainerSemiBold,
                CommonStyles.customFontSemiBold,
              ]}
            >
              {this.props.itemDesc}
            </StandardText>
            <View
              style={[
                isWeb
                  ? [CommonStyles.alignItemsFlexStart, CommonStyles.alignCenter]
                  : CommonStyles.containerList_ItemFigureTitle,
                CommonStyles.marginTop10,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.title_ListContainer,
                  CommonStyles.customFont,
                ]}
              >
                {itemDesc}
              </StandardText>
            </View>
          </View>
          <View
            style={[
              isWeb ? CommonStyles.flex02 : CommonStyles.flex1,
              CommonStyles.flexDirectionColumn,
              CommonStyles.alignCenter,
              CommonStyles.justifyContentFlexStart,
            ]}
          >
            <StandardText
              style={[
                CommonStyles.title_ListContainerSemiBold,
                CommonStyles.customFontSemiBold,
              ]}
            >
              {this.props.dateRange}
            </StandardText>
            <View
              style={[
                isWeb ? CommonStyles.alignCenter : CommonStyles.flex1,
                CommonStyles.marginTop10,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.title_ListContainer,
                  CommonStyles.customFont,
                ]}
              >
                {dateRange}
              </StandardText>
            </View>
          </View>
          <View
            style={[
              isWeb
                ? [CommonStyles.flex015]
                : [CommonStyles.flex1, CommonStyles.alignCenter],
              CommonStyles.flexDirectionColumn,
            ]}
            accessible={true}
          >
            <StandardText
              style={[
                CommonStyles.title_ListContainerSemiBold,
                CommonStyles.customFontSemiBold,
              ]}
            >
              {this.props.itemClaimedQty}
            </StandardText>
            <View
              style={[
                isWeb
                  ? CommonStyles.alignItemsCenter
                  : CommonStyles.containerList_ItemFigureTitle,
                CommonStyles.marginTop10,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.title_ListContainer,
                  CommonStyles.customFont,
                ]}
              >
                {claimedQuantity}
              </StandardText>
            </View>
          </View>
          <View
            style={[
              isWeb
                ? [CommonStyles.flex01]
                : [CommonStyles.flex1, CommonStyles.alignCenter],
              CommonStyles.flexDirectionColumn,
            ]}
            accessible={true}
          >
            <StandardText
              style={[
                CommonStyles.title_ListContainerSemiBold,
                CommonStyles.customFontSemiBold,
                CommonStyles.textAlignCentre,
              ]}
            >
              {this.props.itemClaimedPrice}
            </StandardText>
            <View
              style={[
                isWeb
                  ? CommonStyles.alignItemsCenter
                  : CommonStyles.containerList_ItemFigureTitle,
                CommonStyles.marginTop10,
              ]}
            >
              <CurrencyText
                value={claimedUnitPrice}
                isPositive={true}
                integerStyle={[
                  CommonStyles.font20,
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                ]}
              />
            </View>
          </View>
          <View
            style={[
              isWeb
                ? [CommonStyles.flex01]
                : [CommonStyles.flex1, CommonStyles.alignCenter],
              CommonStyles.flexDirectionColumn,
            ]}
            accessible={true}
          >
            <StandardText
              style={[
                CommonStyles.title_ListContainerSemiBold,
                CommonStyles.customFontSemiBold,
                CommonStyles.textAlignCentre,
              ]}
              accessibilityLabel={
                itemFunded
                  ? this.props.itemFundedTotal
                  : this.props.itemClaimedTotal
              }
            >
              {itemFunded
                ? this.props.itemFundedTotal
                : this.props.itemClaimedTotal}
            </StandardText>
            <View
              style={[
                isWeb
                  ? CommonStyles.alignItemsCenter
                  : CommonStyles.containerList_ItemFigureTitle,
                CommonStyles.marginTop10,
              ]}
            >
              <CurrencyText
                value={itemFunded ? itemFunded : claimedTotal}
                isPositive={true}
                integerStyle={[
                  CommonStyles.font20,
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                ]}
              />
            </View>
          </View>
        </View>
        {showActual && this.renderAccordian(contents, itemUnitPrice, itemTotal)}
      </View>
    );
  };

  renderAccordian(contents: any, itemUnitPrice: any, itemTotal: any) {
    const [
      itemCode,
      itemDesc,
      itemCategory,
      itemQty,
      dateRange,
      claimedQuantity,
      claimedUnitPrice,
      claimedTotal,
      itemFunded,
    ] = contents;
    return (
      <AccordianInvoiceTile
        containerStyle={[
          CommonStyles.borderTopWidth0,
          CommonStyles.justifyContentFlexStart,
        ]}
        headerStyle={[
          CommonStyles.borderTopWidth0,
          CommonStyles.justifyContentFlexStart,
        ]}
        title={this.props.actualInvoiced}
        expandedTitle={this.props.actualInvoicedHide}
        collapsedTitle={this.props.actualInvoiced}
        openByDefault={false}
        buttonColor={
          BrandStyles[
            types2.INVOICE_ACCORDIAN_SECTIONS[types2.ACTUAL_INVOICED].TextColor
          ]
        }
        icons={{
          expand:
            Images[
              types2.INVOICE_ACCORDIAN_SECTIONS[types2.ACTUAL_INVOICED].Expand
            ],
          collapse:
            Images[
              types2.INVOICE_ACCORDIAN_SECTIONS[types2.ACTUAL_INVOICED].Collapse
            ],
        }}
      >
        <View
          style={[
            CommonStyles.justifyFlexRowStart,
            CommonStyles.marginBottom10,
          ]}
        >
          <View
            style={[
              CommonStyles.flexWrap,
              CommonStyles.alignItemsCenter,
              CommonStyles.marginLeft10,
            ]}
          >
            <StandardText
              style={[
                CommonStyles.title_ListContainerSemiBold,
                CommonStyles.customFontSemiBold,
                CommonStyles.marginBottom10,
              ]}
            >
              {this.props.itemInvoicedQuantity}
            </StandardText>
            <StandardText
              style={[
                CommonStyles.title_ListContainer,
                CommonStyles.customFont,
              ]}
            >
              {itemQty}
            </StandardText>
          </View>

          <View
            style={[
              CommonStyles.flexWrap,
              CommonStyles.alignItemsCenter,
              CommonStyles.marginLeft10,
            ]}
          >
            <StandardText
              style={[
                CommonStyles.title_ListContainerSemiBold,
                CommonStyles.customFontSemiBold,

                CommonStyles.marginBottom10,
              ]}
            >
              {this.props.itemInvoicedPrice}
            </StandardText>
            <CurrencyText
              value={itemUnitPrice}
              isPositive={true}
              integerStyle={[
                CommonStyles.font20,
                CommonStyles.customFont,
                BrandStyles.TextColor5,
              ]}
            />
          </View>

          <View
            style={[
              CommonStyles.flexWrap,
              CommonStyles.alignItemsCenter,
              CommonStyles.marginLeft10,
            ]}
          >
            <StandardText
              style={[
                CommonStyles.title_ListContainerSemiBold,
                CommonStyles.customFontSemiBold,
                CommonStyles.marginBottom10,
              ]}
            >
              {this.props.itemInvoicedTotal}
            </StandardText>
            <CurrencyText
              value={itemTotal}
              isPositive={true}
              integerStyle={[
                CommonStyles.font20,
                CommonStyles.customFont,
                BrandStyles.TextColor5,
              ]}
            />
          </View>
        </View>
      </AccordianInvoiceTile>
    );
  }
}

export default PaymentListHH;
