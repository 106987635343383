/*
 * Author: Tharindu Seneviratne
 * Date: 27/01/2019
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Invoice Header used on the Payments Details Screen for HH member
 */

import React, {Component} from 'react';
import {View, Text, Image, Platform} from 'react-native';
import {StandardText} from '../Atoms';

import {
  Container,
  CurrencyText,
  AccordianInvoiceDate,
  AccordianInvoiceNotes,
  BasicOverlayPopupModal,
} from './';
import CustomIcon from '../../assets/images/CustomIcon';
import {UploadRed4x} from '../../assets/images/vector-icons';

//Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {BrandColors} from '../../Styles/Colours';
import {
  BasicPopupAlert,
  DocumentRestrictedPopUp,
} from '../Organisms';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {
  PAYMENTS_DETAILS_SCREEN,
  INVOICE_DETAILS_SCREEN,
} from '../../Constants/Messages';
import {formatDDMMMYYDate} from '../../Helpers/Utils';
import {_generateDisplayDate} from '../../Helpers/generateDisplayDate';
import {StandardTouchableOpacity, StandardButton} from '../Atoms';
import {
  viewIconPurple2x,
  ExpandCharcoal3x,
  CollapseCharcoal3x,
} from '../../assets/images';
import * as mime from 'react-native-mime-types-enhanced';

import {
  isTablet,
  RNFetchBlobSingleton,
  DocumentPickerSingleton,
  FilePickerSingleton,
} from '../../Helpers/PlatformSynchronizer';
import {fileUploadRestrictCheck} from '../../Helpers/FileHelper';

import {callAPIs} from '../../API/APICaller';
import {getUploadURL} from '../../API/MemberAPI';
import {putInvoice} from '../../API/PlanDataAPI';
import FileUploader from '../../Helpers/FileUploader';

const INVOICE_DETAILS = 0;

const MEMBER_INVOICE = 'memberInvoice';
const MEMBER_INVOICE_PATH = 'member-invoices/';
const PHOTO = 'photo';
const IMAGES = 'images';

class InvoiceHeaderHH extends Component {
  filePickerRef: any;
  constructor(props: any) {
    super(props);
    this._closeAlert = this._closeAlert.bind(this);
    this._showAlert = this._showAlert.bind(this);
  }

  state = {
    errorPopUpVisible: false,
    uploadInvoiceConfirmation: false,
    documentRestrictPoupVisible: false,
    uploadEvent: null,
    saveApiErrorCode: '',
    saveApiError: false,
    contentTypeChange: false,
    attachementContentType: '',
  };

  _closeAlert() {
    this.setState({
      errorPopUpVisible: false,
    });
  }

  render() {
    return (
      <View>
        <View style={CommonStyles.columnContainer}>
          <View style={[CommonStyles.justifyFlexRowStart]}>
            <View
              style={[CommonStyles.justifyFlexColumnStart, CommonStyles.flex06]}
            >
              <View accessible={true}>
                <StandardText
                  style={[
                    CommonStyles.customFontSemiBold,
                    BrandStyles.TextColor5,
                    types.isWeb ? [CommonStyles.font15] : [CommonStyles.font18],
                  ]}
                >
                  {PAYMENTS_DETAILS_SCREEN.INVOICE_NUMBER}
                </StandardText>
                <StandardText
                  style={[
                    CommonStyles.customFont,
                    BrandStyles.TextColor2,
                    types.isWeb ? [CommonStyles.font15] : [CommonStyles.font18],
                  ]}
                >
                  {this.props.loadedInvoice.invoiceNumber}
                </StandardText>
              </View>
              {this._renderInvoiceDocumentLink()}
            </View>
            <View
              accessible={true}
              style={[
                CommonStyles.justifyFlexColumnStart,
                CommonStyles.flex04,
                CommonStyles.marginBottom10,
              ]}
            >
              {this._renderClaimedTotal()}
            </View>
          </View>
          {this._renderInvoiceTotal()}
        </View>
        <View style={CommonStyles.marginTop10}>
          {this._renderInvoiceDate()}
          {this._renderInvoiceNotes()}
        </View>
        <DocumentRestrictedPopUp
          visible={this.state.documentRestrictPoupVisible}
          close={this._closeDocumentAlert}
        />
        <BasicPopupAlert
          visible={this.state.errorPopUpVisible}
          close={this.state.closeAction}
          cancelLabel={this.state.alertButtonText}
          headerText={this.state.alertHeader}
          messageText={this.state.alertMessage}
          isMobile={this.props.isMobile}
        />
      </View>
    );
  }

  _renderInvoiceDate = () => {
    return (
      <AccordianInvoiceDate
        leftAlignContent={true}
        isMobile={this.props.isMobile}
        viewWidth={this.props.viewWidth}
        invoiceDetails={this.props.invoiceDetails}
        user={this.props.userReducer}
      />
    );
  };

  _determineClaimedTotalLabel = () => {
    if (this.props.invoiceDetails.isFunded) {
      return PAYMENTS_DETAILS_SCREEN.FUNDED_TOTAL;
    }
    return PAYMENTS_DETAILS_SCREEN.CLAIMED_TOTAL;
  };

  _renderInvoiceNotes = () => {
    if (
      this.props.invoiceDetails.memberNote ||
      this.props.userReducer.isEmployee
    ) {
      return (
        <AccordianInvoiceNotes
          leftAlignContent={true}
          isMobile={this.props.isMobile}
          viewWidth={this.props.viewWidth}
          invoiceDetails={this.props.invoiceDetails}
          updateNote={this.props.updateNotes}
          isEmployee={this.props.userReducer.isEmployee}
        />
      );
    }
    return null;
  };

  _renderInvoiceTotal = () => {
    if (
      (this.props.invoiceDetails.isFunded &&
        this.props.invoiceDetails.funded !== this.props.invoiceDetails.total) ||
      this.props.invoiceDetails.total !== this.props.invoiceDetails.claimable
    ) {
      if (isTablet) {
        return (
          <View>
            {this._renderTotal()}
            <View style={[CommonStyles.justifyFlexRowEnd]}>
              <StandardText
                style={[
                  CommonStyles.customFontSemiBold,
                  BrandStyles.TextColor10,
                  types.isWeb ? [CommonStyles.font13] : [CommonStyles.font15],
                ]}
              >
                {PAYMENTS_DETAILS_SCREEN.INVOICED_TOTAL}
              </StandardText>
            </View>
          </View>
        );
      } else {
        return (
          <View>
            {this._renderTotal()}
            <View style={[CommonStyles.justifyFlexRowEnd]}>
              <StandardText
                style={[CommonStyles.customFont, BrandStyles.TextColor10]}
              >
                {PAYMENTS_DETAILS_SCREEN.INVOICED_TOTAL}
              </StandardText>
            </View>
          </View>
        );
      }
    }
  };

  _renderClaimedTotal = () => {
    if (isTablet) {
      return (
        <View>
          {this._renderClaimedTotalValue()}
          <View style={[CommonStyles.justifyFlexRowEnd]}>
            <StandardText
              style={[
                CommonStyles.customFontSemiBold,
                BrandStyles.TextColor5,
                types.isWeb
                  ? [CommonStyles.font15, CommonStyles.marginTop5]
                  : [CommonStyles.font18],
              ]}
            >
              {this._determineClaimedTotalLabel()}
            </StandardText>
          </View>
        </View>
      );
    } else {
      return (
        <View>
          {this._renderClaimedTotalValue()}
          <View style={[CommonStyles.justifyFlexRowEnd]}>
            <StandardText
              style={[
                CommonStyles.title_ListContainer,
                CommonStyles.customFont,
                CommonStyles.marginRight0,
              ]}
            >
              {this._determineClaimedTotalLabel()}
            </StandardText>
          </View>
        </View>
      );
    }
  };

  _renderInvoiceDocumentLink = () => {
    const invoiceDocument = this.props.invoiceDetails.invoiceDocument;
    if (invoiceDocument) {
      return (
        <View style={[CommonStyles.contentWrapper, CommonStyles.marginTop10]}>
          <View
            style={[
              CommonStyles.flex1,
              CommonStyles.flexRow,
              CommonStyles.justifyContentFlexStart,
            ]}
          >
            <StandardTouchableOpacity
              accessibilityLabel={PAYMENTS_DETAILS_SCREEN.OPEN_DOC}
              onPress={() => this._openDocument(invoiceDocument)}
              style={CommonStyles.flexRow}
            >
              <StandardText
                style={[
                  CommonStyles.InvoiceHeaderItem,
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                  CommonStyles.InvoiceTextWidth,
                  CommonStyles.marginRight10,
                ]}
                numberOfLines={1}
              >
                {this.props.loadedInvoice.invoiceNumber +
                  this._getFileExtension(invoiceDocument.contentType) +
                  ' '}
              </StandardText>
              {this._renderEyeIcon()}
            </StandardTouchableOpacity>
            {this._renderUploadIcon()}
          </View>
          {this._renderUploadConfirmationPopup(false)}
        </View>
      );
    } else if (
      this.props.userReducer.isFinancialStaff ||
      this.props.userReducer.isEmployee
    ) {
      return (
        <View style={[CommonStyles.contentWrapper, CommonStyles.marginTop10]}>
          <View style={[CommonStyles.flex1, CommonStyles.flexRow]}>
            {this._renderUploadIcon()}
          </View>
          {this._renderUploadConfirmationPopup(true)}
        </View>
      );
    }
  };

  _renderEyeIcon = () => {
    if (
      !(
        this.props.userReducer.isFinancialStaff ||
        this.props.userReducer.isEmployee
      )
    ) {
      return (
        <Image
          style={[CommonStyles.spendingSupportIconView]}
          source={viewIconPurple2x}
        />
      );
    }
  };

  _renderUploadIcon = () => {
    if (
      this.props.userReducer.isFinancialStaff ||
      this.props.userReducer.isEmployee
    ) {
      return (
        <StandardTouchableOpacity
          accessibilityLabel={PAYMENTS_DETAILS_SCREEN.OPEN_DOC}
          style={CommonStyles.InvoiceHeaderDocumentName}
          onPress={(event: any) => {
            this._openUpLoadPopUp(event);
          }}
        >
          <CustomIcon
            style={[
              CommonStyles.reUploadIcon,
              {
                color: BrandColors.RESPONSIVE_DESIGN_COLORS.RED,
              },
            ]}
            name={UploadRed4x}
          />
        </StandardTouchableOpacity>
      );
    }
  };

  _openUpLoadPopUp = (event: any) => {
    this.setState({uploadEvent: event, uploadInvoiceConfirmation: true});
  };

  _showAlert(
    alertHeader: any,
    alertMessage: any,
    alertButtonText: any,
    closeAction: any,
    errorPopUpVisible: any,
  ) {
    this.setState({
      alertHeader: alertHeader,
      alertMessage: alertMessage,
      alertButtonText: alertButtonText,
      closeAction: closeAction,
      errorPopUpVisible: errorPopUpVisible,
    });
  }

  _renderUploadConfirmationPopup = (isEmpty: any) => {
    return (
      <BasicOverlayPopupModal
        visible={this.state.uploadInvoiceConfirmation}
        style={this._determinePopUpStyle()}
        backdropOpacity={0.2}
        divider={false}
        close={this._closeUpLoadPopUp}
        cancelLabel={PAYMENTS_DETAILS_SCREEN.UPLOAD_INVOICE_POPUP.NO}
        saveComponent={this.getSaveComponent.bind(this)}
      >
        <View style={[CommonStyles.justifyFlexColumnStart]}>
          <View style={[CommonStyles.alignItemsCenter]}>
            <StandardText
              style={[
                CommonStyles.font20,
                BrandStyles.TextColor10,
                CommonStyles.customFontSemiBold,
                CommonStyles.paddingBottom20,
              ]}
            >
              {isEmpty
                ? PAYMENTS_DETAILS_SCREEN.UPLOAD_INVOICE_POPUP_EMPTY.TITLE
                : PAYMENTS_DETAILS_SCREEN.UPLOAD_INVOICE_POPUP.TITLE}
            </StandardText>
          </View>
          <View style={[CommonStyles.alignItemsCenter]}>
            <StandardText
              style={[
                CommonStyles.font18,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
                CommonStyles.paddingBottom20,
                CommonStyles.textAlignCentre,
              ]}
            >
              {isEmpty
                ? PAYMENTS_DETAILS_SCREEN.UPLOAD_INVOICE_POPUP_EMPTY.MESSAGE
                : PAYMENTS_DETAILS_SCREEN.UPLOAD_INVOICE_POPUP.MESSAGE}
            </StandardText>
          </View>
        </View>
      </BasicOverlayPopupModal>
    );
  };

  _webFileUploadOnChange = (fileObject: any, invoiceDocument: any) => {
    const responseModified = fileObject;
    if (fileUploadRestrictCheck(fileObject.name)) {
      this.setState({
        uploadInvoiceConfirmation: false,
        documentRestrictPoupVisible: true,
      });
    } else {
      if (invoiceDocument) {
        responseModified.fileName = invoiceDocument.name;
      }
      
      this._uploadAttachmentToServer(responseModified, invoiceDocument);
      this.setState({uploadInvoiceConfirmation: false});
    }
  };

  getSaveComponent = () => {
    const invoiceDocument = this.props.invoiceDetails.invoiceDocument;
    return (
      <FilePickerSingleton
        onRef={(ref: any) => {
          if (types.isWeb && ref) {
            this.filePickerRef = ref;
          }
        }}
        extensions={types.VALID_DOCUMENT_TYPES}
        maxSize={1000}
        //assign inline styles for the file picker
        style={{
          marginLeft: 10,
          backgroundColor: BrandColors.WHITE,
        }}
        onChange={(event: any) => {
          if (types.isWeb) {
            event.persist();
            if (event.target && event.target.files && event.target.files.length)
              this._webFileUploadOnChange(
                event.target.files[0],
                invoiceDocument,
              );
          }
        }}
        onError={(errMsg: any) => {
          this.props.actions.payInvoiceActions.setInvoiceDoc(null);
          this.props.reloading();
        }}
      >
        <StandardTouchableOpacity
          accessibilityLabel={PAYMENTS_DETAILS_SCREEN.UPLOAD_INVOICE_POPUP.YES}
          mandatory={true}
          style={[BrandStyles.primaryBgColor1]}
          onPress={() => {
            if (types.isWeb && this.filePickerRef) {
              this.filePickerRef.click();
            }
            this._onPick(invoiceDocument);
          }}
        >
          <StandardButton
            onPress={() => {
              if (types.isWeb && this.filePickerRef) {
                this.filePickerRef.click();
              }
              this._onPick(invoiceDocument);
            }}
            style={[
              BrandStyles.TextColor1,
              CommonStyles.buttonFormAction,
              CommonStyles.customFontBold,
            ]}
            containerStyle={[
              CommonStyles.buttonContainerFormAction,
              BrandStyles.primaryBgColor4,
              BrandStyles.borderColor4,
            ]}
            accessibilityLabel={
              PAYMENTS_DETAILS_SCREEN.UPLOAD_INVOICE_POPUP.YES
            }
          >
            {PAYMENTS_DETAILS_SCREEN.UPLOAD_INVOICE_POPUP.YES}
          </StandardButton>
        </StandardTouchableOpacity>
      </FilePickerSingleton>
    );
  };

  _closeUpLoadPopUp = () => {
    this.setState({uploadInvoiceConfirmation: false});
  };

  _closeDocumentAlert = () => {
    this.setState({
      documentRestrictPoupVisible: false,
    });
  };

  _determinePopUpStyle = () => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      styles.push([CommonStyles.containerInstructionPopUpWeb]);

      if (this.props.isMobile) {
        styles.push([CommonStyles.containerPopupWebMobile]);
      }
    } else if (isTablet) {
      styles.push([
        CommonStyles.containerInstructionPopUpTablet,
        CommonStyles.minHeight300,
      ]);
    } else {
      styles.push([
        CommonStyles.containerInstructionPopUpMobile,
        CommonStyles.minHeight230,
      ]);
    }
    return styles;
  };

  _onPick = (invoiceDocument: any) => {
    if (!types.isWeb) {
      const invoiceDoc = invoiceDocument;

      if (invoiceDoc && invoiceDoc.s3FileKey) {
        invoiceDoc.fileName = invoiceDoc.s3FileKey
          .split(MEMBER_INVOICE_PATH)
          .pop();
      }
      let timeoutPicture: any = null;
      this.setState(
        {
          uploadInvoiceConfirmation: false,
        },
        () => {
          if (Platform.OS === types2.ANDROID) {
            if (timeoutPicture) clearTimeout(timeoutPicture);
            timeoutPicture = setTimeout(
              () => this._uploadAttachement(invoiceDoc),
              500,
            );
          } else if (Platform.OS === types2.IOS) {
            setTimeout(() => this._uploadAttachement(invoiceDoc), 500);
          }
        },
      );
    }
  };

  _uploadAttachement = (invoiceDocument: any) => {
    const event = this.state.uploadEvent;
    const pageX = event.nativeEvent ? event.nativeEvent.pageX : 0;
    const pageY = event.nativeEvent ? event.nativeEvent.pageY : 0;

    DocumentPickerSingleton(pageX, pageY).then(response => {
      this._uploadAttachmentToServer(response, invoiceDocument);
    });
  };

  // Uploading Invoice to S3
  _uploadAttachmentToServer(filePickerResponse: any, invoiceDocument: any) {
    const fileName = invoiceDocument
      ? invoiceDocument.fileName
      : filePickerResponse.name;
    const requestObject = {
      fileType: MEMBER_INVOICE,
      filename: fileName,
      contentType: filePickerResponse.type,
    };
    const invoiceDetials = {
      invoiceAttachmentFileName: MEMBER_INVOICE_PATH + fileName,
    };

    const getUploadURLCallbackFunction = (dataSet: any, err: any) => {
      const data = dataSet[0];
      if (data) {
        if (parseInt(data.statusCode) === 200) {
          const url = data.content.uploadURL;
          let cleanUri = '';

          if (!types.isWeb) {
            if (Platform.OS === types2.IOS) {
              cleanUri = decodeURI(
                filePickerResponse.path.replace('file://', ''),
              );
            }
            if (Platform.OS === types2.ANDROID) {
              cleanUri = filePickerResponse.path;
            }
            RNFetchBlobSingleton.fetch(
              'PUT',
              url,
              {'Content-Type': filePickerResponse.type},
              RNFetchBlobSingleton.wrap(cleanUri),
            )
              .then(() => {
                if (
                  !invoiceDocument ||
                  invoiceDocument.contentType !== requestObject.contentType ||
                  this.state.attachementContentType !==
                    requestObject.contentType
                ) {
                  this.setState({
                    contentTypeChange: true,
                    attachementContentType: requestObject.contentType,
                  });
                }
                this._submitAttachementChange(invoiceDetials);
                this.props.fetchInvoiceData();
              })
              .catch(error => {
                this._showAlert(
                  types2.ERROR,
                  INVOICE_DETAILS_SCREEN.COULDNT_SAVE_INVOICE_ERROR_TEXT,
                  types2.OK,
                  this._closeAlert,
                  true,
                );
                this.props.loading(false);
                this.setState({
                  saveApiErrorCode: null,
                  saveApiError: true,
                });
              });
          } else {
            fetch(url, {
              method: 'PUT',
              headers: {
                'Content-Type': filePickerResponse.type,
              },
              body: filePickerResponse,
            })
              .then(() => {
                if (
                  !invoiceDocument ||
                  invoiceDocument.contentType !== requestObject.contentType ||
                  this.state.attachementContentType !==
                    requestObject.contentType
                ) {
                  this.setState({
                    contentTypeChange: true,
                    attachementContentType: requestObject.contentType,
                  });
                }
                this._submitAttachementChange(invoiceDetials);
                this.props.fetchInvoiceData();
              })
              .catch(error => {
                this._showAlert(
                  types2.ERROR,
                  INVOICE_DETAILS_SCREEN.COULDNT_SAVE_INVOICE_ERROR_TEXT,
                  types2.OK,
                  this._closeAlert,
                  true,
                );
                this.props.loading(false);
                this.setState({
                  saveApiErrorCode: null,
                  saveApiError: true,
                });
              });
          }
        } else {
          //error code returned by lambda
          if (data.Payload.statusCode === 403) {
            this.props.loading(false);
            this._showAlert(
              types2.ERROR,
              INVOICE_DETAILS_SCREEN.COULDNT_SAVE_INVOICE_ERROR_TEXT,
              types2.OK,
              this._closeAlert,
              true,
            );
          }
        }
      } else if (err) {
        if (err.name === types.NETWORK_FAILURE) {
          this._showAlert(
            types2.ERROR,
            INVOICE_DETAILS_SCREEN.COULDNT_SAVE_INVOICE_NETWORK_ERROR_TEXT,
            types2.OK,
            this._closeAlert,
            true,
          );
          this.props.loading(false);
        } else {
          this._showAlert(
            types2.ERROR,
            INVOICE_DETAILS_SCREEN.COULDNT_SAVE_INVOICE_ERROR_TEXT,
            types2.OK,
            this._closeAlert,
            true,
          );
          this.props.loading(false);
        }
      } else {
        this._showAlert(
          types2.ERROR,
          INVOICE_DETAILS_SCREEN.COULDNT_SAVE_INVOICE_ERROR_TEXT,
          types2.OK,
          this._closeAlert,
          true,
        );
        this.props.loading(false);
      }
    };

    const getUploadURLErrorCallbackFunction = (err: any, reject: any) => {
      this.props.loading(false);
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
      });
    };

    // Upload URL
    this.props.loading(true);
    this.setState({
      saveApiError: false,
    });
    callAPIs(
      [
        getUploadURL(
          this.props.loadedMemberId.loadedMemberId,
          requestObject,
          false,
        ),
      ],
      getUploadURLCallbackFunction,
      getUploadURLErrorCallbackFunction,
    );
  }

  // LIBE response whether audit data added to invoice table successully
  _submitAttachementChange = (invoiceDetails: any) => {
    const invoiceKey = this.props.loadedInvoice.invoiceKey;
    const putInvoiceCallbackFunction = (data: any) => {
      if (parseInt(data[0].statusCode) !== 200) {
        this._showAlert(
          types2.ERROR,
          INVOICE_DETAILS_SCREEN.COULDNT_SAVE_INVOICE_ERROR_TEXT,
          types2.OK,
          this._closeAlert,
          true,
        );
        this.props.loading(false);
      }
    };

    const putInvoiceErrorCallbackFunction = (err: any, reject: any) => {
      this.props.loading(false);
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
      });
      reject(err);
    };

    callAPIs(
      [
        putInvoice(
          this.props.loadedMemberId.loadedMemberId,
          invoiceKey,
          invoiceDetails,
          false,
        ),
      ],
      putInvoiceCallbackFunction,
      putInvoiceErrorCallbackFunction,
    );
  };

  _getFileExtension(contentType: any) {
    let extension = mime.extension(contentType);
    if (extension) {
      return '.' + extension;
    }
    return '.jpeg';
  }

  _openDocument(document: any) {
    let fileType = document.contentType;
    if (Platform.OS === types2.IOS) {
      this.props.loading(true);
      const fileNameModified =
        document.fileName + this._getFileExtension(document.contentType);
      const ios = RNFetchBlobSingleton.ios;
      const dirs = RNFetchBlobSingleton.fs.dirs;
      RNFetchBlobSingleton.config({
        path: dirs.DocumentDir + '/LeapIn/' + fileNameModified,
        fileCache: true,
      })
        .fetch('GET', document.url, {
          // Headers here
        })
        .then(res => {
          this.props.loading(false);
          ios
            .openDocument(res.path())
            .catch((err: any) =>
              this._showAlert(
                types2.ERROR,
                types2.DOWNLOAD_ERROR,
                types2.OK,
                this._closeAlert,
                true,
              ),
            );
        })
        .catch(err => {
          this.props.loading(false);
          this._showAlert(
            types2.ERROR,
            types2.DOWNLOAD_ERROR,
            types2.OK,
            this._closeAlert,
            true,
          );
        });
    } else if (Platform.OS === types2.ANDROID) {
      if (fileType == types2.PDF) {
        this.props.navigation.navigate('PdfViewer', {
          url: document.url,
          fileName: document.fileName,
        });
      } else if (types2.IMAGE_FILE_TYPES.includes(fileType)) {
        this.props.navigation.navigate('WebViewer', {
          url: document.url,
          fileName: document.fileName,
        });
      } else {
        // Download the file locally if it is not supported
        const url = document.url;
        const {config, fs} = RNFetchBlobSingleton;
        const DownloadDir = fs.dirs.DownloadDir;
        const options = {
          fileCache: true,
          addAndroidDownloads: {
            useDownloadManager: true,
            notification: true,
            mime: document.contentType,
            title: document.fileName + ' has been downloaded',
            description: 'File downloaded by file manager',
            mediaScannable: true,
            path: DownloadDir + '/LeapIn/' + document.fileName,
          },
        };
        config(options)
          .fetch('GET', url)
          .then(res => {});
      }
    }
    if (types.isWeb) {
      window.open(document.url);
    }
  }

  _renderClaimedTotalValue = () => {
    if (this.props.invoiceDetails.isFunded) {
      return (
        <CurrencyText
          value={this.props.invoiceDetails.funded}
          isPositive={false}
          integerStyle={
            types.isWeb
              ? [
                  CommonStyles.font30,
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                ]
              : [
                  CommonStyles.font40,
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                ]
          }
        />
      );
    }
    if (
      this.props.invoiceDetails.claimable !== undefined ||
      this.props.invoiceDetails.claimable !== null
    ) {
      return (
        <CurrencyText
          value={this.props.invoiceDetails.claimable}
          isPositive={false}
          integerStyle={
            types.isWeb
              ? [
                  CommonStyles.font30,
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                ]
              : [
                  CommonStyles.font40,
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                ]
          }
        />
      );
    }
  };
  _renderTotal = () => {
    if (this.props.invoiceDetails.total) {
      return (
        <CurrencyText
          value={this.props.invoiceDetails.total}
          isPositive={false}
          integerStyle={
            types.isWeb
              ? [
                  CommonStyles.font20,
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                ]
              : [
                  CommonStyles.font30,
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                ]
          }
        />
      );
    }
  };
}

export default InvoiceHeaderHH;
