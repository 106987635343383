/*
 * Author: Brad D'Costa
 * Date: 05/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays a bullet point for the Pros and Cons component
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {MESSAGES} from '../../Constants/Messages';

import {StandardText} from '../Atoms';
import {} from './';

import {ThumbsDownReversedRed, TickGreen} from '../../assets/images';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class ProConBullets extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <View
        style={[
          CommonStyles.flexDirectionRow,
          CommonStyles.alignItemsFlexStart,
          CommonStyles.containerProConBullets,
        ]}
      >
        <View style={[CommonStyles.flex1, CommonStyles.flexDirectionRow]}>
          <View style={[CommonStyles.containerProConBullets_Points]} />
          <StandardText
            style={[
              CommonStyles.customFont,
              BrandStyles.TextColor5,
              types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
              CommonStyles.marginRight10,
              CommonStyles.textLineHeight25,
            ]}
          >
            {this.props.data}
          </StandardText>
        </View>
      </View>
    );
  }
}

export default ProConBullets;
