/*
 * Author: Sujeban Elankeswaran
 * Date: 07/01/2020
 * Copyright © 2020 Leap in!. All rights reserved.
 *
 * The Public API is used where all the public api is stored.
 */

import {MDS_CLIENT_SERVICE} from '../environments';

//* ********************************
//*       FETCH AUTO SUGGESTION FOR MEMBER ADDRESS
//* ********************************

/*
 * Returns the address auto suggestion for member address
 * @async
 * @param {string} address The member address that will return the suggestion for
 * @returns {Promise<object>} The address auto suggestion object
 */
export const fetchAddressAutoSuggestion = (address: any) => {
  return new Promise((resolve, reject) => {
    const options = {
      method: 'GET',
      headers: new Headers([
        ['Authorization', `Basic ${MDS_CLIENT_SERVICE.API_KEY}`],
      ]),
    };
    // Search result limit
    const limit = 15;
    fetch(
      `${MDS_CLIENT_SERVICE.BASE_URL}${MDS_CLIENT_SERVICE.SUGGEST_PATH}?limit=${limit}&query=${address}&datasets=gnaf`,
      options,
    )
      .then(function (response) {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then(function (data) {
        resolve(data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
