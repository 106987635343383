/*
 * Author: Declan Hart
 * Date: 20/3/2019
 * Copyright Â© 2018 Leap in!. All rights reserved.
 *
 * This component is a coloured text label
 */

import React from 'react';
import {View} from 'react-native';
import {StandardText} from './';
import {isWeb} from '../../Constants/Constants';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

const ColouredLabel = function (props: any) {
  var backgroundColour = props.backgroundStyle
    ? props.backgroundStyle
    : CommonStyles.ServiceAgreementStatusContainerGreyTextWrapper;
  return (
    <View
      style={
        isWeb ? null : [CommonStyles.justifyFlexRowEnd, CommonStyles.flex03]
      }
    >
      <View
        style={[
          backgroundColour,
          CommonStyles.minWidth120,
          CommonStyles.justifyContentCentre,
          isWeb
            ? [CommonStyles.maxWidth120, CommonStyles.maxHeight28]
            : [
                CommonStyles.minWidth120,
                CommonStyles.textAlignVerticalCenter,
                CommonStyles.height20,
              ],
        ]}
      >
        <StandardText
          style={[
            CommonStyles.title_ListContainerSemiBold,
            CommonStyles.marginRight0,
            BrandStyles.TextColor1,
            CommonStyles.customFontSemiBold,
            CommonStyles.font16,
            isWeb
              ? [CommonStyles.paddingTop5, CommonStyles.paddingBottom5]
              : null,
          ]}
        >
          {props.message}
        </StandardText>
      </View>
    </View>
  );
};

export default ColouredLabel;
