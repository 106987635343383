/*
 * Author: Nirodha Perera
 * Date: 06/09/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a support category tile, for the add supports
 *
 */

import React, {Component} from 'react';
import {View, Image} from 'react-native';
import {StandardTouchableOpacity, StandardText, StandardButton} from '../Atoms';

//Import Stylesheets

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {BrandColors} from '../../Styles/Colours';

import {CloseButtonWhite3x} from '../../assets/images';

class SupportCategoryTile extends Component {
  state = {
    selectedColor: '',
  };

  constructor(props: any) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    if (!this.props.selectedColor) {
      let borderColor = this.props.tileColor;
      this.setState({
        selectedColor: borderColor,
      });
    } else {
      this.setState({
        selectedColor: '#' + this.props.selectedColor,
      });
    }
  }

  render() {
    let margin = 4;

    let isSelected =
      this.props.selected &&
      this.props.selected.toString() ===
        this.props.category.categoryId.toString();
    // #TODO - once vector icon task completed apply similar randomized color to icon
    let icon = isSelected
      ? this.props.category.selectedIcon.url
      : this.props.category.deselectedIcon.url;
    let bgColor = isSelected
      ? {backgroundColor: this.state.selectedColor}
      : BrandStyles.primaryBgColor1;
    let textColor = isSelected
      ? BrandStyles.TextColor1
      : {color: this.state.selectedColor};
    let borderColor = isSelected
      ? BrandStyles.borderColor1
      : {borderColor: this.state.selectedColor};
    if (this.props.actionCallback) {
      return (
        <StandardTouchableOpacity
          style={[
            CommonStyles.tileCategory,
            bgColor,
            borderColor,
            {margin: margin},
          ]}
          accessibilityLabel={this.props.category.description}
          onPress={() =>
            this.props.actionCallback(
              this.props.category.categoryId,
              this.state.selectedColor,
            )
          }
        >
          <View style={CommonStyles.flexDirectionRow}>
            {this.renderImage({uri: icon})}
            <StandardText
              style={[
                CommonStyles.customFontBold,
                CommonStyles.goalInspText,
                CommonStyles.textAlignCentre,
                textColor,
                CommonStyles.alignCenter,
                CommonStyles.flexWrap,
                CommonStyles.flex1,
              ]}
            >
              {this.props.category.description}
            </StandardText>
            {this.renderCloseButton(
              this.props.showRemoveButton,
              this.props.removeCallback,
            )}
          </View>
        </StandardTouchableOpacity>
      );
    } else {
      return (
        <View
          style={[
            CommonStyles.tileCategory,
            bgColor,
            borderColor,
            {margin: margin},
          ]}
          accessibilityLabel={this.props.category.description}
        >
          <View style={CommonStyles.flexDirectionRow}>
            {this.renderImage({uri: icon})}
            <StandardText
              style={[
                CommonStyles.customFontBold,
                CommonStyles.goalInspText,
                CommonStyles.textAlignCentre,
                textColor,
                CommonStyles.alignCenter,
                CommonStyles.flexWrap,
                CommonStyles.flex1,
              ]}
            >
              {this.props.category.description}
            </StandardText>
            {this.renderCloseButton(
              this.props.showRemoveButton,
              this.props.removeCallback,
            )}
          </View>
        </View>
      );
    }
  }

  renderImage(imageSource: any) {
    if (imageSource != undefined) {
      return (
        <Image
          style={[
            CommonStyles.supportCategoryTitleBoxIcon,
            CommonStyles.titleBoxIconMargin,
            CommonStyles.marginLeft5,
          ]}
          source={imageSource}
        />
      );
    } else {
      return null;
    }
  }

  renderCloseButton(showRemoveButton: any, removeCallback: any) {
    if (showRemoveButton) {
      return (
        <View
          style={[CommonStyles.SIClosePadding, BrandStyles.brandBorderColor2]}
        >
          <StandardButton
            onPress={() => removeCallback()}
            accessibilityLabel={`Remove selected support category`}
          >
            <Image
              source={CloseButtonWhite3x}
              style={[CommonStyles.SICloseIcon]}
            />
          </StandardButton>
        </View>
      );
    } else {
      return null;
    }
  }
}

export default SupportCategoryTile;
