/*
 * Author: Sujeban Elankeswaran
 * Date: 20/06/22
 * Copyright © 2022 Leap in!. All rights reserved.
 *
 * This component is a header line
 */

import React from 'react';
import {View} from 'react-native';
import CommonStyles from '../../Styles/CommonStyles';

const HeaderLine = (props: any) => {
  return (
    <View
      style={[
        CommonStyles.headerLine,
        [{borderBottomColor: props.color}],
        props.style,
      ]}
    />
  );
};

export default HeaderLine;
