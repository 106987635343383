/*
 * Author: Brad D'Costa
 * Date: 04/04/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays a title and expand/collapse button.
 * clicking the expand/collapse button will show/hide the content within the tile
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {MESSAGES, ACCORDIAN} from '../../Constants/Messages';

import {StandardText, StandardTouchableOpacity} from '../Atoms';

import {
  arrowCollapseCharcoal60,
  arrowExpandCharcoal60,
} from '../../assets/images';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class AccordianInvoiceTile extends Component {
  openByDefault: any;
  state = {
    collapsed: true,
  };

  constructor(props: any) {
    super(props);
    this._determineLabelStyle = this._determineLabelStyle.bind(this);
    this.openByDefault = props.openByDefault ? props.openByDefault : false;
  }

  componentDidMount() {
    if (this.openByDefault) {
      this._toggleAccordian();
    }
  }

  render() {
    return (
      <View
        style={[
          CommonStyles.containerInvoice_Accordian,
          this.props.containerStyle,
        ]}
      >
        <View
          style={[
            CommonStyles.containerInvoice_AccordianHeader,
            BrandStyles.borderColor6,
            this.props.headerStyle,
          ]}
        >
          <StandardTouchableOpacity
            accessibilityLabel={this._determineLabel()}
            activeOpacity={0.6}
            onPress={() => {
              this._toggleAccordian();
            }}
          >
            <View style={[CommonStyles.containerInvoice_AccordianButton]}>
              <Image
                style={[CommonStyles.accordianButtonIcon]}
                source={this._determineIcon()}
              />
              <StandardText style={this._determineLabelStyle()}>
                {this.determineTitle()}
              </StandardText>
            </View>
          </StandardTouchableOpacity>
        </View>
        {this._renderChildren()}
      </View>
    );
  }

  determineTitle = () => {
    if (!this.state.collapsed) {
      if (this.props.expandedTitle) {
        return this.props.expandedTitle;
      }
      return this.props.title;
    }

    if (this.state.collapsed) {
      if (this.props.collapsedTitle) {
        return this.props.collapsedTitle;
      }
      return this.props.title;
    }
  };

  _renderChildren = () => {
    if (this.state.collapsed) {
      return null;
    } else {
      return (
        <View
          style={[
            CommonStyles.summaryTileText,
            CommonStyles.summaryTileTextContainer,
          ]}
        >
          {this.props.children}
        </View>
      );
    }
  };

  _determineIcon = () => {
    if (this.state.collapsed) {
      return this.props.icons.expand;
    } else {
      return this.props.icons.collapse;
    }
  };

  _determineLabel = () => {
    if (this.state.collapsed) {
      return ACCORDIAN.EXPAND;
    } else {
      return ACCORDIAN.COLLAPSE;
    }
  };

  _determineLabelStyle = () => {
    let styles = [CommonStyles.customFontSemiBold, CommonStyles.font18];
    if (this.props.buttonColor) {
      styles.push(this.props.buttonColor);
    } else {
      styles.push(BrandStyles.TextColor5);
    }
    return styles;
  };

  _toggleAccordian = () => {
    let flag = !this.state.collapsed;
    if (this.props.callback) {
      this.props.callback(flag);
    }
    this.setState({collapsed: flag});
  };
}

export default AccordianInvoiceTile;
