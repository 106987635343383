/*
 * Author: Declan Hart
 * Date: 16/1/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Display Support details
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import {StandardText, StandardInput} from './';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

class TimesPerYear extends Component {
  state = {
    selectedValue: '',
  };

  constructor(props: any) {
    super(props);
    this.handleTextSubmit = this.handleTextSubmit.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.selectedValues) {
      if (this.props.selectedValues.length > 0) {
        this.props.selectedValues.map((element: any) => {
          if (element.units) {
            this.setState({
              selectedValue: element.units.toString(),
            });
          }
        });
      }
    }
  }

  handleTextSubmit(number: any) {
    this.setState({
      selectedValue: number,
    });
    this.props.save(types.TIMESPERYEAR, null, null, number, null);
  }

  render() {
    return (
      <View style={[CommonStyles.spaceAround, CommonStyles.flex1]}>
        <View style={[CommonStyles.marginBottom5]}>
          <StandardText
            style={[
              CommonStyles.title_SupportQuestions,
              CommonStyles.customFontBold,
            ]}
          >
            {types2.TIMES_PER_YEAR_QUESTION}
          </StandardText>
        </View>
        <View style={CommonStyles.costInputWrapper}>
          <StandardInput
            readOnly={this.props.readOnly}
            keyboardType={'numeric'}
            accessibilityLabel="Times per year"
            placeholder="0"
            key="times_year"
            value={this.state.selectedValue}
            placeholder=""
            onChangeText={(times: any) => this.handleTextSubmit(times)}
            border={BrandStyles.borderColor4}
            blurOnSubmit={true}
            applyWrapper={false}
            underlineColorAndroid="transparent"
            style={[
              CommonStyles.costInputField,
              CommonStyles.textInputWrapper_Support,
            ]}
          />
        </View>
      </View>
    );
  }
}

export default TimesPerYear;
