import {
  FIRSTNAME_NOT_ENTERED,
  LASTNAME_NOT_ENTERED,
  EMAIL_INVALID_FORMAT,
  PASSWORD_CONTAINS_SPACES,
  PASSWORD_INVALID_LENGTH,
  TANDC_NOT_ACCEPTED,
} from '../Constants/Constants';

const validateFirstName = (firstname: any) => {
  if (firstname === '') {
    return FIRSTNAME_NOT_ENTERED;
  } else {
    return '';
  }
};

//Checks if the LastName field has input
const validateLastName = (lastname: any) => {
  if (lastname === '') {
    return LASTNAME_NOT_ENTERED;
  } else {
    return '';
  }
};
//Checks if a Valid Email Address has been entered
const validateEmail = (email: any) => {
  var re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(email)) {
    return '';
  } else {
    return EMAIL_INVALID_FORMAT;
  }
};

//Checks if Password field has input
const validatePassword = (password: any) => {
  var re = /.{8,}/gim;
  if (re.test(password)) {
    // Ensure there is no whitespace in the password
    if (password.indexOf(' ') >= 0) {
      return PASSWORD_CONTAINS_SPACES;
    }
    return '';
  } else {
    return PASSWORD_INVALID_LENGTH;
  }
};

const validateCheckBox = (value: any) => {
  if (!value) {
    return TANDC_NOT_ACCEPTED;
  } else {
    return '';
  }
};

export {
  validateEmail,
  validatePassword,
  validateFirstName,
  validateLastName,
  validateCheckBox,
};
