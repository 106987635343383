import {Platform} from 'react-native';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {PLAYSTORE_URL, APPSTORE_URL} from '../Constants/BrandConstants';
import {APP_ID} from '../environments';
import {isWeb} from '../Constants/Constants';
import {APP_ID as appIds, UTCSuffix} from '../Constants/Constants2';
import {BCLogoBlueBg, UCQLogo, UCNLogo, WesleyLogo} from '../assets/icon.png';
import {isArray, forEach, sortBy} from 'lodash';
import {setValue, getValue, removeItem} from '../API/WebPersistenceStore';
import * as env from '../environments';
import logger from 'helpers/Logger';
import parseQueryParameters from 'parse-url-query-params';



/**
 * A simple function to capitalize the first character of a string
 * @param {String} str The string to capatilize
 */
export const capitalize = (str: any) =>
  str.replace(/^./, (match: any) => match.toUpperCase());

/**
 * Accepts a number and returns a currency format
 * E.g 12 -> $12.00
 * E.g 10.50 -> $10.50
 * @param {Number} num The number you wish to format as currency
 * @param {String} currencySymbol The currency symbol prefix
 */
export const formatAsCurrency = (num: any, currencySymbol: any, fixed: any) => {
  let number = num;
  let decimals = fixed;
  if (number === null || isNaN(number)) {
    number = 0.0;
  }
  number = parseFloat(number); // Make sure the input is a number
  decimals = parseInt(decimals); // Decimal position

  if (currencySymbol !== '') {
    return (
      currencySymbol +
      number
        .toFixed(decimals)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        .replace('-', '')
    );
  }
  return number
    .toFixed(decimals)
    .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
    .replace('-', '');
};

/**
 * Accepts a date and return formated date to current timezones
 * E.g 2018-08-31T02:24:19.000Z -> 31 Aug 2018, 7:54am
 * @param {String} lastModifiedDate date
 */
export const formatDateDocument = (lastModifiedDate: any) => {
  let formatDate = new Date(lastModifiedDate);
  const weekday = types2.DOCUMENT_WEEKDAY;
  const months = types2.DOCUMENT_MONTHS;
  const day = weekday[formatDate.getDay()];
  const monthDay = formatDate.getDate();
  const month = months[formatDate.getMonth()];
  const year = formatDate.getFullYear();
  const hour = formatDate.getHours();
  const min =
    (formatDate.getMinutes() < 10 ? '0' : '') + formatDate.getMinutes();

  const AmPmcheck = hour >= 12 ? 'pm' : 'am';
  const ampm = AmPmcheck;
  let hourcheck = hour % 12;

  if (hourcheck === 0) {
    hourcheck = 12;
  }
  formatDate = `${monthDay} ${month} ${year}, ${hourcheck}:${min}${ampm}`; // day + '. '
  formatDate = formatDate.toString();
  return formatDate;
};

/**
 * Accepts a date as yyyy-mm-dd
 * Returns it in d-MMMM-yy
 * @param {String} date Date to convert
 */
export const formatDDMMMYYYYDate = (date: any, format: any) => {
  let formatDate = new Date(date);
  const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const day = weekday[formatDate.getDay()];
  const monthDay = formatDate.getDate();
  const month = months[formatDate.getMonth()];
  const year = formatDate.getFullYear();
  const hour = formatDate.getHours();
  const min = formatDate.getMinutes();

  const AmPmcheck = hour >= 12 ? 'pm' : 'am';
  const ampm = AmPmcheck;
  let hourcheck = hour % 12;

  if (hourcheck === 0) {
    hourcheck = 12;
  }

  if (format === types2.DATE_FORMATS.TYPE_ONE) {
    formatDate = `${day} ${monthDay} ${month.slice(0, 3)} ${year}`;
  } else if (format === types2.DATE_FORMATS.TYPE_TWO) {
    formatDate = `${monthDay} ${month.slice(0, 3)} ${year}`;
  } else {
    formatDate = `${monthDay} ${month} ${year}`;
  }

  return formatDate;
};

export const paginate = (array: any, page_size: any, page_number: any) => {
  --page_number; // because pages logically start with 1, but technically with 0
  return array.slice(page_number * page_size, (page_number + 1) * page_size);
};

export const getMonthYear = (date: any) => {
  let formatDate = new Date(date);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const year = formatDate.getFullYear();
  const month = months[formatDate.getMonth()];

  return `${month} ${year}`;
};

/**
 * Accepts a date, and will return a string that is formatted as dd/MM/yyyy
 * @param {string} date
 */
export const formatDate = (date: any) => {
  let formatDate = new Date(date);
  if(date?.includes("/") && Platform.OS===types2.IOS){
    const dateString = date;// 6/25/2024  2024-06-24
    const dateParts = dateString.split('/'); 
    const format=`${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`
    formatDate = new Date(format);
  }
  let monthDay = formatDate.getDate();
  let month = formatDate.getMonth() + 1;
  const year = formatDate.getFullYear();
  if (monthDay < 10) {
    monthDay = `0${monthDay}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  formatDate = `${monthDay}/${month}/${year}`;
  formatDate = formatDate.toString();
  return formatDate;
};

/**
 * Accepts a date, and will return a string that is formatted as yyyy-MM-dd
 * @param {string} date
 */
export const formatDateToString = (date: any, separator = '-') => {
  let formatDate = new Date(date);
  let monthDay = formatDate.getDate();
  let month = formatDate.getMonth() + 1;
  const year = formatDate.getFullYear();
  if (monthDay < 10) {
    monthDay = `0${monthDay}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  formatDate = `${year}${separator}${month}${separator}${monthDay}`;
  formatDate = formatDate.toString();
  return formatDate;
};

/**
 * Accepts a date, and will return a string that is formatted as dd MMM yy
 * ex: 02 Aug 18
 * @param {string} date
 */
export const formatDDMMMYYDate = (date: any) => {
  let formatDate = new Date(date);
  if(date?.includes("/") && Platform.OS===types2.IOS){
    const dateString = date;// 6/25/2024  2024-06-24
    const dateParts = dateString.split('/'); 
    const format=`${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`
    formatDate = new Date(format);
  }
  let monthDay = formatDate.getDate();
  const month = formatDate.getMonth();
  const year = formatDate.getFullYear();
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  if (monthDay < 10) {
    monthDay = `0${monthDay}`;
  }

  formatDate = `${monthDay} ${months[month]} ${year.toString().substring(2)}`;
  formatDate = formatDate.toString();
  return formatDate;
};

/**
 * Accepts a date, and will return a string that is formatted as ddMMyyyy
 * @param {string} date
 */
export const formatDateForPlanID = (date: any) => {
  let formatDate = new Date(date);
  let monthDay = formatDate.getDate();
  let month = formatDate.getMonth() + 1;
  const year = formatDate.getFullYear();
  if (monthDay < 10) {
    monthDay = `0${monthDay}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  formatDate = monthDay + month + year;
  formatDate = formatDate.toString();
  return formatDate;
};

export const formatDateForReviewSummary = (date: any) => {
  if (typeof date === 'string') {
    const p = date.split(/\D/g);
    return [p[2], p[1], p[0]].join('/');
  }
  return [
    date.getDate().toString(),
    formatTime(date.getMonth() + 1).toString(),
    date.getFullYear().toString(),
  ].join('/');
};

/**
 * This will determine what tile to display based if the member has a known as and first name
 * If knowAs exists then return <knownas> <title>
 * Otherwise if first name exists return <first name> <title>
 * If they both dont exist return <title>
 * @param {Object} memberObject The member object from redux
 * @param {String} title The title of the screen
 */
export const getScreenTitle = (memberObject: any, title: any) => {
  let displayTitle = '';
  if (memberObject.knownAs) {
    displayTitle = `${memberObject.knownAs}'s ${title}`;
  } else if (memberObject.firstName) {
    displayTitle = `${memberObject.firstName}'s ${title}`;
  } else {
    displayTitle = capitalize(title);
  }
  return displayTitle;
};

/**
 * Calculate the difference in days between dates
 * @param {Date} firstDate The first date
 * @param {Date} secondDate The second date
 */
export const DifferenceInDays = (firstDate: any, secondDate: any) =>
  Math.round((secondDate - firstDate) / (1000 * 60 * 60 * 24));

export const formatDateRange = (startDate: any, endDate: any) => {
  if (startDate) {
    const startDateString = formatDate(startDate);
    let endDateString = 'N/A';

    if (endDate) {
      endDateString = formatDate(endDate);
    }

    return `${startDateString} - ${endDateString}`;
  }

  return null;
};
export const formatTime = (time: any) => {
  if (time) {
    return parseInt(time) > 9 ? `${parseInt(time)}` : `0${parseInt(time)}`;
  }
  return '';
};
export const formatNumber = (number: any) => {
  if (number) {
    return parseFloat(number);
  }
  return 0.0;
};

export const formatDurationString = (duration: any) => {
  const strArray = duration.split(':');
  let hourStr = '';
  let minStr = '';
  if (strArray[0] == '1') {
    hourStr = `${strArray[0]} Hr`;
  } else if (strArray[0] == '0') {
    hourStr = '';
  } else {
    hourStr = `${strArray[0]} Hrs`;
  }

  if (strArray[1] == '00') {
    minStr = '';
  } else {
    minStr = `${strArray[1]} mins`;
  }

  let displayString = '';
  if (hourStr && minStr) {
    displayString = `${hourStr} ${minStr}`;
  } else {
    displayString = hourStr + minStr;
  }

  return displayString;
};

export const formatQuantity = (quantity: any) => {
  return quantity + ' Qty';
};

export const removeLeadingZeros = (string: any) => string.replace(/\b0+/g, '');

/**
 * Accepts a date, and will return a string that is formatted as day at hour:minutespm or day Month at hour:minutespm
 * @param {string} date
 */
export const formatDateOnCurrentMonth = (date: any) => {
  const dateString = date.toString();
  let formatDate = dateString.split('.');
  formatDate = formatDate[0];
  if (isWeb) {
    formatDate = formatDate + UTCSuffix;
  }
  formatDate = new Date(formatDate);

  const weekday = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const day = weekday[formatDate.getDay()];
  const dateValue = formatDate.getDate();
  const month = months[formatDate.getMonth()];
  const hour = formatDate.getHours();
  const min = formatDate.getMinutes();
  const year = formatDate.getFullYear();

  const AmPmcheck = hour >= 12 ? 'pm' : 'am';
  const ampm = AmPmcheck;
  let hourcheck = hour % 12;

  if (hourcheck === 0) {
    hourcheck = 12;
  }

  let sameWeek = false;
  const currentDate = new Date();
  if (getWeekNumber(currentDate) === getWeekNumber(formatDate)) {
    sameWeek = true;
  }

  formatDate = `${dateValue}${getOrdinalSuffix(
    dateValue,
  )} ${month} ${year}, ${hourcheck}:${addZero(min)}${ampm}`;
  if (sameWeek) {
    formatDate = `${day} ${dateValue}${getOrdinalSuffix(
      dateValue,
    )}, ${hourcheck}:${addZero(min)}${ampm}`;
  }

  return formatDate;
};

/**
 * Accepts a date, and will return the week number
 * @param {string} date
 */
function getWeekNumber(date: any) {
  date = new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()),
  );
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));
  const yearStart = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  const weekNo = Math.ceil(((date - yearStart) / 86400000 + 1) / 7);
  return weekNo;
}

// add zero to a number if < 10
function addZero(i: any) {
  if (i < 10) {
    i = `0${i}`;
  }
  return i;
}

// return postive value
export const getPostive = (num: any) => {
  if (num < 0) {
    return Math.abs(num);
  }
  return num;
};

// Return st, nd, rd and th (ordinal) suffix when a number is passed
function getOrdinalSuffix(digit: any) {
  var j = digit % 10,
    k = digit % 100;
  if (j == 1 && k != 11) {
    return 'st';
  }
  if (j == 2 && k != 12) {
    return 'nd';
  }
  if (j == 3 && k != 13) {
    return 'rd';
  }
  return 'th';
}

// Returns an object with given object and keys after
// trimming the start and trailing spaces.
export const trimObjectProperties = (object: any, keyArray: any) => {
  let trimObject = object;
  keyArray.forEach((key: any) => {
    trimObject[key] = trimObject[key].trim();
  });
  return trimObject;
};

/* This function identify small tab devices and
 * return true/false depend on that
 */
export const getContentLayout = (isTablet: any, width: any, height: any) => {
  let deviceIsTablet = true;
  if (
    !isTablet ||
    width < types2.MEDIA_QUERY.CUT_OFF ||
    height < types2.MEDIA_QUERY.CUT_OFF
  ) {
    deviceIsTablet = false;
  }
  return deviceIsTablet;
};

/**
 * This function will calculate the percentages of the arcs in a pie-chart
 * Given an array of object that has values to
 *  @param {Array.<{value: Number}>} pieData
 *  @param {String} valueKey
 */
export const calculatePieChartArcPercentages = (
  pieData: any,
  valueKey: any,
  pieChartSortOrder: any,
) => {
  // Do the initial validation required to perform the task
  if (
    !isArray(pieData) ||
    !pieData.length ||
    typeof valueKey !== 'string' ||
    pieData.filter(x => !Object.hasOwnProperty.call(x, valueKey)).length ||
    pieData.filter(x => typeof x[valueKey] !== 'number').length
  ) {
    return [];
  }
  const baseTotal = pieData
    .map(x => x[valueKey])
    .reduce((acc, val) => acc + val);
  // Calculates how much percentage on pie-chart should be allocated based on the values
  // If the Percentage is less than 1 round it to 1 and recalculate max value so
  // Sum of the percentages will be equal to 100
  let percentageBasedPieData = pieData
    .map(x => {
      x[valueKey] = Math.ceil((x[valueKey] / baseTotal) * 100);
      return x;
    })
    .sort((x, y) => {
      return x[valueKey] < y[valueKey] ? -1 : x[valueKey] > y[valueKey] ? 1 : 0;
    });

  // Get the max value from sorted list and substract the sum of the rest
  // To fit the sum of the values in 100
  let maxValue = percentageBasedPieData.pop();
  maxValue[valueKey] =
    100 -
    percentageBasedPieData
      .map(x => x[valueKey])
      .reduce((acc, val) => acc + val);

  percentageBasedPieData.push(maxValue);

  percentageBasedPieData = sortBy(percentageBasedPieData, data => {
    return pieChartSortOrder.indexOf(data.key);
  });

  return percentageBasedPieData;
};

export const setServiceAgreementFeature = (isServiceAgreementsEnabled: any) => {
  if (types.isWeb) {
    removeItem(types2.WEB_STORE.SERVICE_AGREEMENTS_ENABLED);
    setValue(
      types2.WEB_STORE.SERVICE_AGREEMENTS_ENABLED,
      isServiceAgreementsEnabled,
    );
  }
};

// return Play Store/ App Store url based on device OS
export const getStoreUrl = (deviceOS: any) => {
  if (deviceOS == types2.IOS) {
    return APPSTORE_URL;
  } else if (deviceOS == types2.ANDROID) {
    return PLAYSTORE_URL;
  }
};

/**
 * This function will calculate the total of Draft Invoice support Items
 *  @param {Array of Object} claims
 */
export const findTotal = (claims: any) => {
  let totalValue = 0.0;
  forEach(claims, claim => {
    const unitPriceIncTax = claim.unitPriceIncTax;
    let lineItem = 0.0;
    if (claim.quantity.hasOwnProperty('duration')) {
      const duration = claim.quantity.duration.split(':');
      lineItem =
        unitPriceIncTax * (parseInt(duration[0]) + parseInt(duration[1]) / 60);
    } else if (claim.quantity.hasOwnProperty('units')) {
      lineItem = unitPriceIncTax * claim.quantity.units;
    }
    totalValue += lineItem;
  });
  return totalValue;
};

/**
 * This function will calculate the total of Sigle support item of a Draft Invoice
 *  @param {Object} claim
 */
export const findOneItemTotal = (claim: any) => {
  let totalValue = 0.0;
  const unitPriceIncTax = claim.unitPriceIncTax;
  if (claim.quantity.hasOwnProperty('duration')) {
    const duration = claim.quantity.duration.split(':');
    totalValue =
      unitPriceIncTax * (parseInt(duration[0]) + parseInt(duration[1]) / 60);
  } else if (claim.quantity.hasOwnProperty('units')) {
    totalValue = unitPriceIncTax * claim.quantity.units;
  }
  return totalValue;
};

export const calculateDimensions = (
  itemDimension: any, //item width
  staticDimension: any, //total dimension static
  totalDimension: any,
) => {
  const usableTotalDimension = staticDimension || totalDimension;
  const availableDimension = usableTotalDimension; // One spacing extra
  const itemTotalDimension = Math.min(itemDimension, availableDimension); // itemTotalDimension should not exceed availableDimension
  const itemsPerRow = Math.floor(availableDimension / itemTotalDimension);
  const containerDimension = availableDimension / itemsPerRow;

  return {
    itemTotalDimension,
    availableDimension,
    itemsPerRow,
    containerDimension,
  };
};

export const getParamValue = (
  navigation: any,
  param: any,
  defaultValue: any,
  key: any,
) => {
  let paramValue;
  //const paramValue = navigation.getParam(param, defaultValue);
  if(types.isWeb) {
    //return  this.props.navigation.getParam(key, undefined) ;
    paramValue =  navigation.getParam(param, undefined) ;
   }else {
   // return this.props.route[key] ? this.props.route[key] : undefined;
    paramValue = navigation?.params[param] || defaultValue;
   }

  if (paramValue && key) {
    return paramValue.key;
  } else {
    return paramValue;
  }
};
/*
 * This function will return an object of key param values
 * @param {string} url
 */
export const getDeepLinkParams = (url: any) => {
  if (!url) return null;
  let params: any = parseQueryParameters(decodeURIComponent(url));
  return params;
};

export const setProfilePicture = (requestObject: any, props: any) => {
  const imageObjectState = {
    lastModified: new Date(),
    key: requestObject.imagePath,
    url: requestObject.imagePath,
    photoCaption: requestObject.photoCaption,
    localImage: true,
  };
  const saveObj = {
    id: props.member.id,
    profileImage: imageObjectState,
  };
  props.actions.MemberActions.actionUpdateMember(saveObj);
};

export const setGoalPicture = (requestObj: any, props: any) => {
  let goalItem = props.goals.currentGoal;
  if (goalItem === undefined) {
    props.goals.goals.map((item: any) => {
      if (requestObj.forGoal === item.currentGoalId) {
        goalItem = item;
      }
    });
  }
  // Build the request object for the image upload
  let requestObject = props.goals.currentGoal
    ? props.goals.currentGoal.imageUploadObject
    : goalItem.goalPhoto;

  requestObject = {
    ...requestObject,
    forGoal: goalItem.currentGoalId,
  };
  const goalImageObject = {
    lastModified: new Date(),
    key: requestObject.localUri,
    url: requestObject.localUri,
    photoCaption: requestObject.photoCaption,
    localImage: true,
  };
  const goalId = requestObj.forGoal;
  props.actions.GoalActions.setGoalImageInList(goalId, goalImageObject);
};

export const goalCaptionUpdate = (
  requestObj: any,
  caption: any,
  props: any,
) => {
  let goalPhoto;
  let currentGoalId;
  if (props.goals.currentGoal === undefined) {
    props.goals.goals.map((item: any) => {
      if (requestObj.forGoal === item.currentGoalId) {
        goalPhoto = item.goalPhoto;
        currentGoalId = item.currentGoalId;
      }
    });
  } else {
    goalPhoto = props.goals.currentGoal.goalPhoto;
    currentGoalId = props.goals.currentGoal.currentGoalId;
  }
  let goalImageObject = {};
  // First check goal photo available in goal already
  // If avaialable set the photo caption to goalPhoto object in redux
  // If not set the photo caption to goal object it self in redux
  if (goalPhoto !== undefined) {
    goalImageObject = {
      lastModified: goalPhoto.lastModified,
      key: goalPhoto.key,
      url: goalPhoto.url,
      localImage: true,
    };
    props.actions.GoalActions.setGoalImageInList(currentGoalId, {
      ...goalImageObject,
      photoCaption: caption,
    });
  } else {
    props.actions.GoalActions.addPhotoCaption(currentGoalId, caption);
  }
};

export const setSupportPicture = (requestObj: any, props: any) => {
  let supportItem;
  props.supports.supports.map((item: any) => {
    if (requestObj.forSupport === item.currentSupportId) {
      supportItem = item;
    }
  });

  // Build the request object for the image upload
  let requestObject = props.supports.currentSupport
    ? props.supports.currentSupport.imageUploadObject
    : supportItem.supportPhoto;

  requestObject = {
    ...requestObject,
    forSupport: supportItem.currentSupportId,
  };
  const supportImageObject = {
    lastModified: new Date(),
    key: requestObject.localUri,
    url: requestObject.localUri,
    photoCaption: requestObject.photoCaption,
    localImage: true,
  };
  const supportId = supportItem.currentSupportId;
  props.actions.SupportsActions.setSupportImageInList(
    supportId,
    supportImageObject,
  );
};

export const supportCaptionUpdate = (
  requestObj: any,
  caption: any,
  props: any,
) => {
  let supportPhoto;
  let currentSupportId;
  props.supports.supports.map((item: any) => {
    if (requestObj.forSupport === item.currentSupportId) {
      supportPhoto = item.supportPhoto;
      currentSupportId = item.currentSupportId;
    }
  });
  let supportImageObject = {};
  // First check support photo available in support already
  // If avaialable set the photo caption to supportPhoto object in redux
  // If not set the photo caption to support object it self in redux
  if (supportPhoto !== undefined) {
    supportImageObject = {
      lastModified: supportPhoto.lastModified,
      key: supportPhoto.key,
      url: supportPhoto.url,
      localImage: true,
    };
    props.actions.SupportsActions.setSupportImageInList(currentSupportId, {
      ...supportImageObject,
      photoCaption: caption,
    });
  } else {
    props.actions.SupportsActions.addPhotoCaption(currentSupportId, caption);
  }
};

export const uploadCallBack = (requestObject: any) => {
  requestObject.localUri = requestObject.uri;
  requestObject.localImage = true;
};
