/*
 * Author: Declan Hart
 * Date: 12/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a component to display ratio questions
 */

import React from 'react';
import {View} from 'react-native';

import {StandardText, StandardSegmentedControl} from './';

import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

const TimeOfDay = function (props: any) {
  return (
    <View style={{marginTop: 10, marginBottom: 10}}>
      <View style={[{marginBottom: 5}]}>
        <StandardText
          style={[
            CommonStyles.title_SupportQuestions,
            CommonStyles.customFontBold,
          ]}
        >
          {types2.SELECT_SUPPORT_TIME}
        </StandardText>
      </View>
      <StandardSegmentedControl
        readOnly={props.readOnly}
        values={props.values}
        selectedValues={props.selectedValues}
        multiple
        save={props.save}
        type={types.TIMEOFDAY}
        saveType={types.UNITOFMEASURE}
      />
    </View>
  );
};

export default TimeOfDay;
