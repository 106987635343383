/*
 * Authour: Andrew Lee
 * Date: 05/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This will display a set of buttons, one for cancelling, and one for saving.
 * @param {callback} cancelCallback The callback function when the cancel button is pressed.
 * @param {callback} saveCallback The callback function when the save button is pressed.
 */

import React, {Component} from 'react';
import {View, StyleSheet} from 'react-native';
import {StandardButton} from '../Atoms';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class CancelSaveButtons extends Component {
  state = {
    showCancel: true,
  };

  constructor(props: any) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    this.setState({showCancel: this.props.showCancel});
  }

  render() {
    const saveButton = this.props.saveButton ? this.props.saveButton : 'Save';
    return (
      <View style={[styles.buttonContainer, this.props.containerStyle]}>
        {/* Insert the cancel button at this point */}

        {this.renderCancel()}

        {/* Insert the save button at this point */}
        <StandardButton
          readOnly={this.props.readOnly}
          style={[
            BrandStyles.brandBlockTxtColor5,
            CommonStyles.buttonFormAction,
          ]}
          containerStyle={[
            BrandStyles.primaryBgColor4,
            BrandStyles.brandBorderColor1,
            CommonStyles.buttonContainerFormAction,
          ]}
          onPress={this.props.saveCallback.bind(this)}
          accessibilityLabel={saveButton}
          disabled={this.props.saveDisabled}
        >
          {saveButton}
        </StandardButton>
      </View>
    );
  }

  renderCancel = () => {
    if (this.state.showCancel) {
      const cancelButton = this.props.cancelButton
        ? this.props.cancelButton
        : 'Cancel';
      return (
        <StandardButton
          style={[
            BrandStyles.brandBlockTxtColor6,
            CommonStyles.buttonFormAction,
            CommonStyles.customFontBold,
          ]}
          containerStyle={[
            BrandStyles.primaryBgColor1,
            BrandStyles.brandBorderColor1,
            CommonStyles.buttonContainerFormAction,
            this.props.cancelStyle,
          ]}
          onPress={this.props.cancelCallback.bind(this)}
          accessibilityLabel="Cancel"
        >
          {cancelButton}
        </StandardButton>
      );
    }
  };
}

// TODO: Merge with CommonStyles once is in develop branch
const styles = StyleSheet.create({
  buttonContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
  },

  buttonText: {
    fontSize: 20,
  },

  button: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 30,
    marginBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 0,
  },
});

export default CancelSaveButtons;
