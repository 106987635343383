/*
 * Author: Brad D'Costa
 * Date: 30/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Summary tile on the MyProfile screen for the Crew Section
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View, Text, TouchableHighlight} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import {StandardText} from '../Atoms';
import {SummaryTile, PhotoTile, GridView} from '../Molecules';

import {crewIconCharcoal, crewIconRed} from '../../assets/images/vector-icons';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import {isTablet} from '../../Helpers/PlatformSynchronizer';
//import DeviceInfo from 'react-native-device-info';
//import Orientation from 'react-native-orientation-enhanced';

import {connect} from 'react-redux';
import * as SideMenuActions from '../../Actions/SideMenuActions';
import * as NavigationParamActions from '../../Actions/NavigationParamActions';
import {setValue, removeItem} from '../../API/WebPersistenceStore';
import {bindActionCreators} from 'redux';

// The limit of how many crew we will show on this screen

const MEMBER_TYPE = {
  PERSON: 'person',
  ORGANISATION: 'organisation',
};

class MyProfileCrew extends Component {
  state = {
    lastUpdate: new Date(),
    orientation: '',
  };

  constructor(props: any) {
    super(props);
    this.createCrewComponentArray.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProp: any) {
    let previousLastUpdate = null;
    if (this.props && this.props.reducer && this.props.reducer.lastUpdated) {
      previousLastUpdate = this.props.reducer.lastUpdated;
    }

    let nextLastUpdate = null;
    if (nextProp && nextProp.reducer && nextProp.reducer.lastUpdated) {
      nextLastUpdate = nextProp.reducer.lastUpdated;
    }

    if (!previousLastUpdate && nextLastUpdate) {
      this.setState({lastUpdate: nextLastUpdate});
    } else if (previousLastUpdate && nextLastUpdate) {
      if (previousLastUpdate.getTime() != nextLastUpdate.getTime()) {
        this.setState({lastUpdate: nextLastUpdate});
      }
    }
  }

  // UNSAFE_componentWillMount() {
  //   const initial = Orientation.getInitialOrientation();
  //   this.setState({
  //     orientation: initial
  //   });
  // }
  // componentDidMount() {
  //   Orientation.addOrientationListener(this._orientationDidChange);
  // }
  // componentWillReceiveProps(props) {
  //   this.setState({
  //     isOpen: props.sideMenu ? props.sideMenu.sideMenuIsOpen : false
  //   });
  // }

  // _orientationDidChange = orientation => {
  //   this.setState({
  //     orientation
  //   });
  // };

  render() {
    let itemLength = this.props.reducer.crewIndividual
      ? this.props.reducer.crewIndividual.length
      : 0;
    let maxLenth = types.DISPLAY_MAX_CREWS_MYPROFILE_TABLET;

    if (!isTablet) {
      maxLenth = types.DISPLAY_MAX_CREWS_MYPROFILE_PHONE;
    }

    return (
      <SummaryTile
        headerDisplayString="Crew"
        headerIconImage={crewIconRed}
        screenType={types.SCREEN_TYPE_MAIN}
        modal={this.props.modal}
        containsData={this._determineContainsData()}
        actionOverride={this._determineAccess()}
        displayMore={itemLength > maxLenth}
      >
        {this._renderCrew()}
      </SummaryTile>
    );
  }

  _determineAccess = () => {
    if (Object.getOwnPropertyNames(this.props.readOnly).length === 0) {
      return null;
    } else {
      let readOnly = this.props.readOnly;
      if (readOnly.access) {
        if (readOnly.access.profile) {
          if (this.props.readOnly.access.profile == types.ACCESS_READ_ONLY) {
            return types.ACTION_BUTTON.VIEW;
          }
        }
      }
    }
    return null;
  };

  _determineContainsData = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return false;
    } else {
      if (
        this.props.reducer.crewIndividual &&
        this.props.reducer.crewIndividual.length == 0
      ) {
        return false;
      } else {
        return true;
      }
    }
  };

  _determineIcon = () => {
    if (this._determineContainsData()) {
      return crewIconRed;
    } else {
      return crewIconCharcoal;
    }
  };

  _renderCrew() {
    //TODO: update once reducer connected
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      if (
        !this.props.reducer.crewIndividual ||
        this.props.reducer.crewIndividual.length == 0
      ) {
        return null;
      } else {
        const crewToDisplay = this.props.reducer.crewIndividual.slice(
          0,
          isTablet
            ? types.DISPLAY_MAX_CREWS_MYPROFILE_TABLET
            : types.DISPLAY_MAX_CREWS_MYPROFILE_PHONE,
        );
        return (
          <View style={[CommonStyles.justifyCenter, CommonStyles.flex1]}>
            <GridView
              viewWidth={this.props.viewWidth}
              itemDimension={types2.ITEM_DIMENSION}
              childDimension={true}
              isMobile={this.props.isMobile}
            >
              {this.createCrewComponentArray(crewToDisplay)}
            </GridView>
          </View>
        );
      }
    }
  }

  createCrewComponentArray(crews: any) {
    return crews.map((crew: any) => {
      return (
        <PhotoTile
          key={crew.crewId}
          style={{width: 75, height: 75}}
          apiFileType={types2.FILE_TYPE.CREW_PROFILE}
          imageObject={this.getCrewImage(crew)}
          showCaption={true}
          caption={this.getName(crew)}
          captionNumberOfLines={1}
          accessibilityLabel={this.getName(crew)}
          captionEllipsizeMode="tail"
          actionCallback={this._selectedCrew}
          actionData={crew}
          clickable={true}
          lastUpdate={this.state.lastUpdate}
          photoBorderRadius={CommonStyles.borderRadiusRound}
        />
      );
    });
  }

  getCrewImage(crewItem: any) {
    if (crewItem.profileImage) {
      if (crewItem.profileImage.url) {
        return crewItem.profileImage;
      }
    }
    return null;
  }

  getName = (crew: any) => {
    if (!crew.firstName || crew.firstName == '') {
      return `${crew.orgName}`;
    } else {
      if (crew.orgName && crew.orgName != '') {
        return `${crew.firstName} ${crew.lastName} ${crew.orgName}`;
      } else {
        return `${crew.firstName} ${crew.lastName}`;
      }
    }
  };

  _navigatedBack = () => {
    this.setState({lastUpdate: new Date()});
  };

  _selectedCrew = (crew: any) => {
    let editEmail = true;
    if (crew.email) {
      editEmail = false;
    }
    const navigationParams = {
      screenMode: types.FORM_MODE_EDIT,
      selectedCrew: crew,
      editEmail,
      onBack: this._navigatedBack,
    };
    if (types.isWeb) {
      removeItem(types2.WEB_STORE.CREW_NAVIGATION_PARAMS);
      // Reset crew state
      removeItem(types2.WEB_STORE.CREW_STATE);
      setValue(
        types2.WEB_STORE.CREW_NAVIGATION_PARAMS,
        JSON.stringify(navigationParams),
      );
    }
    this.props.actions.NavigationParamActions.setParam(navigationParams);
    this.props.navigation.navigate(types2.NAV_SCREENS.CREW_FORM);
  };
}

const mapStateToProps = (state: any) => ({
  sideMenu: state.SideMenuReducer,
});
const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SideMenuActions: bindActionCreators(SideMenuActions, dispatch),
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(MyProfileCrew);
