/**
 * Author: Anuja Ulpathakumbura
 * Date: 13/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 */

import React, {Component} from 'react';
import {View, StyleSheet, Keyboard, Platform} from 'react-native';
//import {NavigationActions} from 'react-navigation-awseeley--enhanced';
import {CommonActions, StackActions} from '@react-navigation/native';
import {
  StandardInput,
  StandardText,
  StandardPicker,
  StandardTouchableOpacity,
  StandardAlert,
  StandardButton,
} from '../Components/Atoms';
import {BasicOverlayPopupModal, SelectedItem} from '../Components/Molecules';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {announceForAccessibility} from '../Helpers/PlatformSynchronizer';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import * as messages from '../Constants/Messages';
import {isTablet} from '../Helpers/PlatformSynchronizer';
import {fetchAddressAutoSuggestion} from '../API/PublicAPI';
import {formatDateToString} from '../Helpers/Utils';
import {debounce} from 'lodash';
import DeviceInfo from 'react-native-device-info';
// Import organisms required
import {BasicForm} from '../Components/Organisms';
import {
  FormSubtitle,
  FormTitle,
  DatePicker,
  Container,
  RequiredIndicator,
  ActionButton,
  PhotoTile,
  AutoComplete,
} from '../Components/Molecules';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {AboutMePurple3x, addIcon} from '../assets/images/vector-icons';

import {callAPIs} from '../API/APICaller';
import {getMember, updateMember, getAvatarImages} from '../API/MemberAPI';

import {trimObjectProperties} from '../Helpers/Utils';

import * as MemberActions from '../Actions/MemberActions';
import * as AvatarListActions from '../Actions/AvatarListActions';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as GeneralActions from '../Actions/GeneralActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';

import {MESSAGES} from '../Constants/Messages';
import logger from 'helpers/Logger';
import {setValue, removeItem} from '../API/WebPersistenceStore';

import { DOMPurify } from 'helpers/dompurify';

const INPUTS = {
  SCROLL_VIEW: 'scrollView',
  MY_DETAILS_MODAL_FIRST_NAME: 'myDetailsModalFirstname',
  MY_DETAILS_MODAL_CALL_ME: 'myDetailsModalCallme',
  MY_DETAILS_MODAL_LAST_NAME: 'myDetailsModalLastname',
  MY_DETAILS_MODAL_ADDRESS: 'myDetailsModalAddress',
  MY_DETAILS_MODAL_CITY: 'myDetailsModalCity',
  MY_DETAILS_MODAL_APARTMENT: 'myDetailsModalApartment',
  MY_DETAILS_MODAL_POST_CODE: 'myDetailsModalPostcode',
  MY_DETAILS_MODAL_PHONE_NUMBER: 'myDetailsModalPhoneNumber',
  MY_DETAILS_MODAL_MOBILE_PHONE: 'myDetailsModalMobilePhone',
  MY_DETAILS_MODAL_NDIS_NUMBER: 'myDetailsModalNDISNumber',
};

const PROFILE_PICTURE_FORM = 'ProfilePictureForm';
class AboutMeMyDetailsForm extends Component {
  inputs: any;
  state = {
    myDetailsVisibleModal: false,
    additionalInfoVisibleModal: false,
    lifestageVisibleModal: false,
    primaryDisabilityVisibleModal: false,
    //Local data states
    lifestagesRef: [],
    selectedLifeStages: [],
    aboutMe: {},
    aboutMeModals: {},
    aboutMeInitialState: {},
    validFirstName: '',
    validLastName: '',
    validPhoneNumber: '',
    validMobilePhone: '',
    validPostCode: '',
    validState: '',
    validDOB: '',
    loading: true,
    statePicker: types2.MY_DETAILS.STATES,
    displayStateError: true,
    displayDOBError: true,
    stateErrorBorderWidth: 0,
    dobErrorBorderWidth: 0,
    saveApiError: false,
    validNDIS: '',
    lastUpdate: new Date(),
    refreshing: false,
    placeSuggestion: [],
    hideSuggestion: false,
    disableAddress: true,
    showManualEntryAddressPopupAlert: false,
    fetchAddressErrorPopup: false,
    isLoadingSearch: false,
    setSuggestedAddress: '',
  };

  constructor(props: any) {
    super(props);
    this._saveMyDetails = this._saveMyDetails.bind(this);
    this._checkEditAccess = this._checkEditAccess.bind(this);
    this._validateMyDetailsInfo = this._validateMyDetailsInfo.bind(this);
    this.focusNextField = this.focusNextField.bind(this);
    this.inputs = {
      myDetailsModal: {},
    };
    this._createMainContents.bind(this);
    this.fetchData.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (
      this.props.member.profileImage &&
      this.props.member.profileImage !== nextProps.member.profileImage
    ) {
      const lastUpdate = new Date(nextProps.member.profileImage.lastModified);
      this.setState({lastUpdate});
    }
  }

  UNSAFE_componentWillMount() {
    const initalImage = this.props.member.profileImage;
    this.setState({
      selectedModalImage: initalImage,
    });
  }

  componentDidMount() {
    this.setState({loading: true});

    this.fetchData();
  }

  fetchData = () => {
    if (types.isWeb) {
      if (this.props.general.screenName) {
        if (this.props.general.screenName === 'FOCUSADDRESS') {
          this.setState({disableAddress: false}, () => {
            this.focusNextField(
              this.inputs.myDetailsModal,
              INPUTS.MY_DETAILS_MODAL_ADDRESS,
            );
          });
        }
      }
    } else if (!types.isWeb) {   
      if (this.props.route.params) {
        if ( this.props.route.params?.focus === 'address') {
          this.setState({disableAddress: false});
        }
      }
    }
    const callbackFunction = (data: any) => {
      const member = data[0].content;
      this.props.actions.MemberActions.actionGetMember(
        member,
        this.props.user.id,
        this.props.user.isEmployee,
        this.props.user.isFinancialStaff,
      );

      const reduxMember = this.props.member;
      this._initialiseMemberDataInState(reduxMember);

      if (!types.isWeb) {
        if (this.props.route.params) {
          if (this.props.route.params?.focus === 'address') {
            this.focusNextField(
              this.inputs.myDetailsModal,
              INPUTS.MY_DETAILS_MODAL_ADDRESS,
            );
          }
        }
      }
    };

    callAPIs(
      [
        getMember(
          this.props.loadedMemberId.loadedMemberId,
          this.props.user.demoProfile,
        ),
      ],
      callbackFunction,
      null,
      () => {
        this.setState({loading: false, refreshing: false});
      },
    );
  };

  refreshData = () => {
    const callbackFunction = (data: any) => {
      const member = data[0].content;
      this.props.actions.MemberActions.actionGetMember(
        member,
        this.props.user.id,
        this.props.user.isEmployee,
        this.props.user.isFinancialStaff,
      );
    };
    callAPIs(
      [
        getMember(
          this.props.loadedMemberId.loadedMemberId,
          this.props.user.demoProfile,
        ),
      ],
      callbackFunction,
      null,
      () => {},
    );
  };

  _initialiseMemberDataInState = (data: any) => {
    this.setState(
      {
        aboutMe: data,
        aboutMeMainScreen: data,
        aboutMeModals: data,
        aboutMeInitialState: data,
        loading: false,
        refreshing: false,
        setSuggestedAddress: data.address,
      },
      () => {
        if (
          this.state.aboutMe.boundries.SA1 === 0 &&
          this.state.aboutMe.boundries.SA2 === 0 &&
          this.state.aboutMe.boundries.SA3 === 0 &&
          this.state.aboutMe.boundries.SA4 === 0
        ) {
          this.setState({disableAddress: true}, () => {
            this.focusNextField(
              this.inputs.myDetailsModal,
              INPUTS.MY_DETAILS_MODAL_ADDRESS,
            );
          });
        }
      },
    );
  };

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.MY_DETAILS}
        hasFormContentChanged={this._hasFormContentChanged}
        currentScreenName={AboutMeMyDetailsForm.name}
        onRefresh={() => {
          this.setState({refreshing: true});
          this.fetchData();
        }}
        refreshing={this.state.refreshing}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  /*
   * Changes the focus to the given field
   * @param {string} nextField The ref name of the input on the next field.
   */
  focusNextField(inputs: any, nextField: any) {
    if (inputs[nextField].focus) {
      inputs[nextField].focus();
    }
  }

  getProfileImage = () => {
    let initalImage = this.props.member.profileImage;
    let selectedImageUrl = {};

    if (!initalImage.url) {
      selectedImageUrl = null;
    } else {
      selectedImageUrl = initalImage;
    }

    return selectedImageUrl;
  };

  renderCrewPicture = (showInLeftSide: any) => {
    let actionText = types2.ABOUT_ME_PROFILE_PICTURE_PROMPT_TEXT.EDIT;
    let actionType = types.ACTION_BUTTON.EDIT;

    if (!this.getProfileImage()) {
      actionText = types2.ABOUT_ME_PROFILE_PICTURE_PROMPT_TEXT.ADD;
      actionType = types.ACTION_BUTTON.ADD;
    }

    return (
      <View
        style={[
          CommonStyles.zIndex100,
          CommonStyles.flexDirectionRow,
          CommonStyles.paddingTop15,
          showInLeftSide
            ? CommonStyles.alignFlexStart
            : CommonStyles.alignFlexEnd,
        ]}
      >
        <PhotoTile
          key={this.props.member.id}
          style={StyleSheet.flatten(
            CommonStyles.containerPhotoTileImage_Summary,
          )}
          flexRatio={CommonStyles.flex1}
          imageObject={this.getProfileImage()}
          clickable={false}
          lastUpdate={this.state.lastUpdate}
          apiFileType={types2.FILE_TYPE.SUPPORT_PHOTO}
        />
        <View
          style={[
            CommonStyles.flexDirectionColumn,
            CommonStyles.alignFlexStart,
          ]}
        >
          {this.props.member.access.profile != types.ACCESS_READ_ONLY ? (
            <StandardTouchableOpacity
              style={[
                CommonStyles.addSupportContainer,
                CommonStyles.marginTop5,
              ]}
              accessibilityLabel="Add profile picture"
              onPress={this._viewSelectProfilePicture}
            >
              <ActionButton
                actionType={actionType}
                image={addIcon}
                customText={actionText}
              />
            </StandardTouchableOpacity>
          ) : null}
        </View>
      </View>
    );
  };

  _viewSelectProfilePicture = () => {
    const navigationParams = this.props.actions.NavigationParamActions.setParam(
      {
        imageObject: this.state.selectedModalImage,
        visible: this.state.profilePictureModalVisible,
        forceMemberImageUpload: true,
      },
    );
    if (types.isWeb) {
      removeItem(types2.WEB_STORE.NAVIGATION_PARAMS);
      setValue(
        types2.WEB_STORE.NAVIGATION_PARAMS,
        JSON.stringify(navigationParams),
      );
    }
    this.props.navigation.navigate(PROFILE_PICTURE_FORM);
    this._getAvatarImages();
  };

  _getAvatarImages = () => {
    let callbackFunction = (data: any) => {
      var avatarList = data[0].content;
      this.props.actions.AvatarListActions.actionGetAvatarList(avatarList);

      if (types.isWeb) {
        removeItem(types2.WEB_STORE.AVATAR_LISTOBJ);
        setValue(types2.WEB_STORE.AVATAR_LISTOBJ, JSON.stringify(avatarList));
      }

      this.setState({
        loading: false,
      });
    };

    callAPIs(
      [getAvatarImages(this.props.loadedMemberId.loadedMemberId)],
      callbackFunction,
      null,
      () => {
        this.setState({loading: false});
      },
    );
  };

  fetchAddressSuggestion = (text: any) => {
    this.setState({placeSuggestion: [], isLoadingSearch: true}, () => {
      fetchAddressAutoSuggestion(this.state.aboutMeModals.address).then(
        data => {
          if (text.length >= 1) {
            let results = data.Results;
            let places: any = [];
            results.map((item: any) => {
              const place = item.Value;
              const location = item.Coordinate;

              const placeArr = place.split(',');
              const locArr = location.split(',');

              let address = `${placeArr[0]}, ${placeArr[1]}, ${placeArr[2]}, ${placeArr[3]}`;

              let latitude = locArr[1];
              let longitude = locArr[0];

              if (placeArr.length === 5) {
                places.push({
                  address,
                  location: {
                    latitude,
                    longitude,
                  },
                });
              }
            });
            this.setState({placeSuggestion: places, isLoadingSearch: false});
          }
        },
        error => {
          this.setState({
            fetchAddressErrorPopup: true,
            isLoadingSearch: false,
          });
        },
      );
    });
  };
  _renderfetchAddressErrorPopup = () => {
    return (
      this.state.fetchAddressErrorPopup && (
        <StandardAlert
          modalTitle={messages.ERRORS.GENERIC_ISSUE_ALERT_TITLE}
          headerStyle={[CommonStyles.font23, CommonStyles.customFontBold]}
          close={() => {
            this.setState({fetchAddressErrorPopup: false});
          }}
          message={messages.ERRORS.GENERIC_ISSUE_ALERT_MSG}
        />
      )
    );
  };
  setAddress = (place: any) => {
    const placeArr = place.address.split(',');
    this.setState({setSuggestedAddress: placeArr[0]});
    this.setState(prevState => ({
      aboutMeModals: {
        ...prevState.aboutMeModals,
        address: placeArr[0],
        city: placeArr[1].replace(/\s/g, ''),
        state: placeArr[2].replace(/\s/g, ''),
        postcode: placeArr[3].replace(/\s/g, ''),
        latitude: place.location.latitude,
        longitude: place.location.longitude,
      },
    }));
  };

  getAddressSuggestion = () => {
    let placeSuggestion = this.state.placeSuggestion;
    if (placeSuggestion === '' || placeSuggestion.length < 2) {
      return [];
    }

    return this.state.placeSuggestion;
  };
  _renderSearchItem = (item: any) => {
    return (
      <StandardTouchableOpacity
        accessibilityLabel={item.place + ' selected'}
        key={item.place}
        style={CommonStyles.autoSuggestionItem}
        onPress={() => {
          this.setAddress(item);
          this.focusNextField(
            this.inputs.myDetailsModal,
            INPUTS.MY_DETAILS_MODAL_PHONE_NUMBER,
          );
        }}
      >
        <StandardText
          style={[CommonStyles.customFont, CommonStyles.StandardFontSize]}
        >
          {item.address}
        </StandardText>
      </StandardTouchableOpacity>
    );
  };
  _determinePopUpStyle = () => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      if (this.props.isMobile) {
        styles.push([CommonStyles.containerInstructionPopUpWebMobileLarge]);
        return styles;
      }
      styles.push([
        CommonStyles.containerInstructionPopUpWebLarge,
        CommonStyles.maxWidth500,
      ]);
    } else {
      if (isTablet()) {
        styles.push([
          CommonStyles.containerInstructionPopUpTablet,
          CommonStyles.showManualEntryAddressPopupAlert,
        ]);
      } else {
        styles.push([
          CommonStyles.containerInstructionPopUpMobile,
          Platform.OS === types2.ANDROID && CommonStyles.height400,
        ]);
      }
    }
    return styles;
  };

  debouncedSearchFilteredSupportFunc = debounce(() => {
    this.fetchAddressSuggestion(this.state.aboutMeModals.address);
  }, 1000);

  debouncedSearchFilteredSupport(query: any) {
    this.setState(
      prevState => ({
        aboutMeModals: {
          ...prevState.aboutMeModals,
          address: query,
        },
      }),
      () => this.debouncedSearchFilteredSupportFunc(),
    );
  }

  toggleDisableAddress = () => {
    this.setState(
      {
        disableAddress: !this.state.disableAddress,
      },
      () => {
        if (!this.state.disableAddress) {
          this.setState({showManualEntryAddressPopupAlert: true});
        }
        if (this.state.disableAddress) {
          this.setState(
            prevState => ({
              aboutMeModals: {
                ...prevState.aboutMeModals,
                address: '',
                city: '',
                state: '',
                postcode: '',
                apartment: '',
              },
            }),
            () => {
              this.setState(
                {
                  placeSuggestion: [],
                  setSuggestedAddress: '',
                },
                () => {
                  this.focusNextField(
                    this.inputs.myDetailsModal,
                    INPUTS.MY_DETAILS_MODAL_ADDRESS,
                  );
                },
              );
            },
          );
        }
      },
    );
  };

  getPopUpContent() {
    let messageFontSize = CommonStyles.font17;
    let headerFontSize = CommonStyles.font20;
    if (isTablet()) {
      messageFontSize = CommonStyles.font15;
      headerFontSize = CommonStyles.font18;
    } else if (types.isWeb) {
      messageFontSize = CommonStyles.font20;
      headerFontSize = CommonStyles.font25;
    }

    return (
      <View>
        <View
          style={
            types.isWeb
              ? [CommonStyles.marginBottom10, CommonStyles.textAlignCentre]
              : [
                  CommonStyles.flexDirectionRow,
                  CommonStyles.popTitleText,
                  CommonStyles.scrollView_subContentCenter,
                ]
          }
        >
          <View style={[CommonStyles.scrollView_subContentCenter]}>
            <StandardText
              style={[
                headerFontSize,
                BrandStyles.TextColor10,
                CommonStyles.customFontBold,
              ]}
            >
              {types2.ABOUT_ME_MY_DETAILS_FORM.MANUAL_ADDRESS_POPUP.TITLE}
            </StandardText>
          </View>
        </View>
        <View
          style={
            types.isWeb
              ? {}
              : [
                  CommonStyles.flexDirectionRow,
                  CommonStyles.scrollView_subContentCenter,
                ]
          }
        >
          <View style={[CommonStyles.scrollView_subContentLeft]}>
            <StandardText
              style={[
                messageFontSize,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
              ]}
            >
              {types2.ABOUT_ME_MY_DETAILS_FORM.MANUAL_ADDRESS_POPUP.MESSAGE}
            </StandardText>
            <StandardText
              style={[
                messageFontSize,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
                CommonStyles.marginTop10,
              ]}
            >
              {types2.ABOUT_ME_MY_DETAILS_FORM.MANUAL_ADDRESS_POPUP.MESSAGE2}
            </StandardText>
          </View>
        </View>
        <View
          style={[
            CommonStyles.containerInstructionDevider,
            CommonStyles.paddingBottom5p,
          ]}
        />
        <View
          style={[
            CommonStyles.ModalActionButtons,
            CommonStyles.justifyContentSpaceBetween,
          ]}
        >
          <StandardButton
            onPress={() =>
              this.setState({
                disableAddress: true,
                showManualEntryAddressPopupAlert: false,
              })
            }
            style={[
              BrandStyles.brandBlockTxtColor6,
              CommonStyles.buttonFormAction,
            ]}
            containerStyle={[
              BrandStyles.primaryBgColor1,
              BrandStyles.brandBorderColor1,
              CommonStyles.buttonContainerFormAction,
              CommonStyles.paddingLeftRight8,
            ]}
            accessibilityLabel={types2.ACCESSIBILITY_LABELS.SEARCH_AGAIN}
          >
            {types2.ABOUT_ME_MY_DETAILS_FORM.SEARCH_AGAIN}
          </StandardButton>

          <StandardButton
            onPress={() =>
              this.setState({showManualEntryAddressPopupAlert: false})
            }
            style={[
              BrandStyles.brandBlockTxtColor5,
              CommonStyles.buttonFormAction,
            ]}
            containerStyle={[
              BrandStyles.primaryBgColor4,
              BrandStyles.brandBorderColor1,
              CommonStyles.buttonContainerFormAction,
              CommonStyles.paddingLeftRight8,
            ]}
            accessibilityLabel={types2.ACCESSIBILITY_LABELS.TYPE_MY_OWN}
          >
            {types2.ABOUT_ME_MY_DETAILS_FORM.TYPE_MY_OWN}
          </StandardButton>
        </View>
      </View>
    );
  }

  _createMainContents = () => {
    return (
      <BasicForm
        headerDisplayString={MESSAGES.MY_DETAILS.TITLE}
        headerIconImage={AboutMePurple3x}
        save={this._saveMyDetails}
        close={this._closeMyDetails}
        showButton={false}
        disableMore={true}
        readOnly={this.props.member.access.profile}
        buttonsPositionTop={true}
        hideActionButton={true}
        contentWidthFull={true}
      >
        <View style={[CommonStyles.AboutMeModalContentContainer]}>
          {this.renderCrewPicture(true)}
          <View>
            <FormTitle
              containsData={this.state.aboutMeModals.firstName}
              text={MESSAGES.MY_DETAILS.FIRST_NAME}
            />
            <RequiredIndicator />
            <StandardInput
              readOnly={this._checkEditAccess()}
              multiline={false}
              onRef={(ref: any) => {
                this.inputs.myDetailsModal[INPUTS.MY_DETAILS_MODAL_FIRST_NAME] =
                  ref;
              }}
              onSubmitEditing={() => {
                this.focusNextField(
                  this.inputs.myDetailsModal,
                  INPUTS.MY_DETAILS_MODAL_CALL_ME,
                );
              }}
              value={this.state.aboutMeModals.firstName}
              accessibilityLabel="First name"
              onChangeText={(myDetailsModalFirstname: any) =>
             {   
                const sanitizedFirstName = DOMPurify.sanitize(myDetailsModalFirstname);
                this.setState(prevState => ({
                  aboutMeModals: {
                    ...prevState.aboutMeModals,
                    firstName: sanitizedFirstName,
                  },
                }))}
              }
              blurOnSubmit={false}
              returnKeyType={'next'}
              underlineColorAndroid="transparent"
              style={[
                CommonStyles.ModalTextInputWrapper,
                BrandStyles.primaryBgColor1,
              ]}
              errorMessage={this.state.validFirstName}
            />
          </View>
          <View>
            <FormTitle
              containsData={this.state.aboutMeModals.knownAs}
              text={MESSAGES.MY_DETAILS.CALL_ME}
            />
            <StandardText
              style={[
                CommonStyles.marginBottom10,
                types.isWeb ? CommonStyles.font15 : CommonStyles.font18,
              ]}
            >
              {MESSAGES.MY_DETAILS.LIKE_TO_BE_CALLED}
            </StandardText>
            <StandardInput
              readOnly={this.props.member.access.profile === 'full' ? false : this.props.member.access.profile}
              multiline={false}
              onRef={(ref: any) => {
                this.inputs.myDetailsModal[INPUTS.MY_DETAILS_MODAL_CALL_ME] =
                  ref;
              }}
              onSubmitEditing={() => {
                this.focusNextField(
                  this.inputs.myDetailsModal,
                  INPUTS.MY_DETAILS_MODAL_LAST_NAME,
                );
              }}
              returnKeyType={'next'}
              value={this.state.aboutMeModals.knownAs}
              accessibilityLabel={MESSAGES.MY_DETAILS.CALL_ME_PLACEHOLDER}
              onChangeText={(myDetailsModalCallme: any) =>
               { 
                const sanitizedCallMeValue = DOMPurify.sanitize(myDetailsModalCallme);
                this.setState(prevState => ({
                  aboutMeModals: {
                    ...prevState.aboutMeModals,
                    knownAs: sanitizedCallMeValue,
                  },
                }))}
              }
              blurOnSubmit={false}
              underlineColorAndroid="transparent"
              style={[
                CommonStyles.ModalTextInputWrapper,
                BrandStyles.primaryBgColor1,
              ]}
            />
          </View>
          <View>
            <FormTitle
              containsData={this.state.aboutMeModals.lastName}
              text={MESSAGES.MY_DETAILS.LAST_NAME}
            />
            <RequiredIndicator />
            <StandardInput
              readOnly={this._checkEditAccess()}
              multiline={false}
              onRef={(ref: any) => {
                this.inputs.myDetailsModal[INPUTS.MY_DETAILS_MODAL_LAST_NAME] =
                  ref;
              }}
              onSubmitEditing={() => {
                this.focusNextField(
                  this.inputs.myDetailsModal,
                  INPUTS.MY_DETAILS_MODAL_ADDRESS,
                );
              }}
              returnKeyType={'next'}
              value={this.state.aboutMeModals.lastName}
              accessibilityLabel="Last name"
              onChangeText={(myDetailsModalLastname: any) =>
             {   
                const sanitizedLastName = DOMPurify.sanitize(myDetailsModalLastname);
                this.setState(prevState => ({
                  aboutMeModals: {
                    ...prevState.aboutMeModals,
                    lastName: sanitizedLastName,
                  },
                }))}
              }
              blurOnSubmit={false}
              underlineColorAndroid="transparent"
              style={[
                CommonStyles.ModalTextInputWrapper,
                BrandStyles.primaryBgColor1,
              ]}
              errorMessage={this.state.validLastName}
            />
          </View>
          <View styles={[CommonStyles.zIndex100]}>
            <FormTitle
              containsData={this.state.aboutMeModals.dateOfBirth}
              text={MESSAGES.MY_DETAILS.DATE_OF_BIRTH}
            />
            <DatePicker
              readOnly={this.props.member.access.profile}
              value={this.state.aboutMeModals.dateOfBirth}
              errorBorder={this.state.dobErrorBorderWidth}
              accessibilityLabel={types2.DATE_PICKER_PLACEHOLDER.DATE_OF_BIRTH}
              save={(date: any) => {
                this.setState(prevState => ({
                  aboutMeModals: {
                    ...prevState.aboutMeModals,
                    dateOfBirth: date,
                  },
                }));
                // modalData.dateOfBirth = date
              }}
            />
            {this._showDOBError(this.state.displayDOBError)}
          </View>
          <View style={types.isWeb ? CommonStyles.zIndexN1 : null}>
            <FormTitle
              containsData={this.state.aboutMeModals.address}
              text={MESSAGES.MY_DETAILS.ADDRESS}
            />
            <FormSubtitle text={[MESSAGES.PLACEHOLDER.APARTMENT]} />
            <StandardInput
              accessibilityLabel={MESSAGES.PLACEHOLDER.APARTMENT}
              readOnly={this.props.member.access.profile === 'full' ? false : this.props.member.access.profile}
              multiline={false}
              onRef={(ref: any) => {
                this.inputs.myDetailsModal[INPUTS.MY_DETAILS_MODAL_APARTMENT] =
                  ref;
              }}
              onSubmitEditing={() => {
                !this.state.disableAddress &&
                  this.focusNextField(
                    this.inputs.myDetailsModal,
                    INPUTS.MY_DETAILS_MODAL_CITY,
                  );
              }}
              returnKeyType={types2.RETURN_KEY_TYPE.NEXT}
              ref={INPUTS.MY_DETAILS_MODAL_APARTMENT}
              value={this.state.aboutMeModals.apartment}
              onChangeText={(myDetailsModalApartment: any) =>
            {    
              const sanitizedApartment = DOMPurify.sanitize(myDetailsModalApartment);
                this.setState(prevState => ({
                  aboutMeModals: {
                    ...prevState.aboutMeModals,
                    apartment: sanitizedApartment,
                  },
                }))}
              }
              //blurOnSubmit={false}
              //underlineColorAndroid="transparent"
              style={[
                CommonStyles.ModalTextInputWrapper,
                BrandStyles.primaryBgColor1,
              ]}
            />            
            <FormSubtitle text={[MESSAGES.PLACEHOLDER.ADDRESS]} />

            <StandardText
              style={[CommonStyles.marginBottom10, CommonStyles.underlineText]}
              containerStyle={CommonStyles.padding5}
              onPress={() => this.toggleDisableAddress()}
            >
              {this.state.disableAddress
                ? messages.ABOUT_ME_MY_DETAILS_FORM.CANT_FIND_MY_ADDRESS
                : messages.ABOUT_ME_MY_DETAILS_FORM.BACK_TO_AUTO_SUGGESTION}
            </StandardText>
            {!this.state.disableAddress ? (
              <StandardInput
                accessibilityLabel={MESSAGES.PLACEHOLDER.ADDRESS}
                readOnly={this.props.member.access.profile === 'full' ? false : this.props.member.access.profile}
                multiline={false}
                onRef={(ref: any) => {
                  this.inputs.myDetailsModal[INPUTS.MY_DETAILS_MODAL_ADDRESS] =
                    ref;
                }}
                onSubmitEditing={() => {
                  this.focusNextField(
                    this.inputs.myDetailsModal,
                    INPUTS.MY_DETAILS_MODAL_CITY,
                  );
                }}
                returnKeyType={'next'}
                value={this.state.aboutMeModals.address}
                onChangeText={(myDetailsModalAddress: any) =>
                 { 
                  const sanitizedAddress = DOMPurify.sanitize(myDetailsModalAddress);
                  this.setState(prevState => ({
                    aboutMeModals: {
                      ...prevState.aboutMeModals,
                      address: sanitizedAddress,
                      latitude: 0,
                      longitude: 0,
                    },
                  }))}
                }
                blurOnSubmit={false}
                underlineColorAndroid="transparent"
                style={[
                  CommonStyles.ModalTextInputWrapper,
                  BrandStyles.primaryBgColor1,
                  BrandStyles.brandBorderColor2,
                ]}
              />
            ) : (
              <View style={CommonStyles.autoCompleteZindex}>
                <AutoComplete
                  onRef={(ref: any) => {
                    this.inputs.myDetailsModal[
                      INPUTS.MY_DETAILS_MODAL_ADDRESS
                    ] = ref;
                  }}
                  data={this.getAddressSuggestion()}
                  listStyle={[CommonStyles.content, CommonStyles.listContainer]}
                  hideSuggestion={this.state.hideSuggestion}
                  isLoadingSearch={this.state.isLoadingSearch}
                  onChangeText={(myDetailsModalAddress: any) =>{
                    const sanitizedAddress = DOMPurify.sanitize(myDetailsModalAddress);
                    this.debouncedSearchFilteredSupport(sanitizedAddress)}
                  }
                  rowStyle={true}
                  returnKeyType={'done'}
                  onSubmitEditing={() => {
                    this.focusNextField(
                      this.inputs.myDetailsModal,
                      INPUTS.MY_DETAILS_MODAL_PHONE_NUMBER,
                    );
                    this.setState({placeSuggestion: []});
                  }}
                  renderItem={(item: any) => this._renderSearchItem(item)}
                  style={[
                    CommonStyles.ModalTextInputWrapper,
                    CommonStyles.marginBottom5,
                  ]}
                  onClear={() => {
                    // Clear the search text
                    this.setState({
                      placeSuggestion: [],
                    });
                  }}
                  nestedScrollEnabled={true}
                  value={this.state.aboutMeModals.address}
                  underlineColorAndroid="transparent"
                  onFocus={() => {
                    // Since the focus is on the auto complete, any drag should dismiss the keyboard
                    this.setState({
                      keyboardDismissMode: 'on-drag',
                      hideSuggestion: false,
                    });
                  }}
                  onBlur={() => {
                    // No longer focused on the auto complete, remove the dismiss mode
                    setTimeout(
                      () => {
                        this.setState({
                          keyboardDismissMode: 'none',
                          hideSuggestion: true,
                          placeSuggestion: [],
                        });
                      },
                      types.isWeb ? 300 : 0,
                    );
                  }}
                />
              </View>
            )}

            <FormSubtitle text={[MESSAGES.PLACEHOLDER.CITY]} />
            <StandardInput
              accessibilityLabel={MESSAGES.PLACEHOLDER.CITY}
              readOnly={this.props.member.access.profile === 'full' ? false : this.props.member.access.profile}
              multiline={false}
              onRef={(ref: any) => {
                this.inputs.myDetailsModal[INPUTS.MY_DETAILS_MODAL_CITY] = ref;
              }}
              onSubmitEditing={() => {
                !this.state.disableAddress &&
                  this.focusNextField(
                    this.inputs.myDetailsModal,
                    INPUTS.MY_DETAILS_MODAL_POST_CODE,
                  );
              }}
              returnKeyType={'next'}
              ref={INPUTS.MY_DETAILS_MODAL_CITY}
              value={this.state.aboutMeModals.city}
              onChangeText={(myDetailsModalCity: any) =>
                {         
                const sanitizedtCity = DOMPurify.sanitize(myDetailsModalCity);
                this.setState(prevState => ({
                  aboutMeModals: {
                    ...prevState.aboutMeModals,
                    city: sanitizedtCity,
                  },
                }))}
              }
              blurOnSubmit={false}
              underlineColorAndroid="transparent"
              style={[
                CommonStyles.ModalTextInputWrapper,
                BrandStyles.primaryBgColor1,
              ]}
              disabled={this.state.disableAddress}
            />
            <View style={[CommonStyles.twoColumns]}>
              <View style={[CommonStyles.twoColumnsLeft]}>
                <FormSubtitle text={[MESSAGES.PLACEHOLDER.POSTCODE]} />
                <StandardInput
                  accessibilityLabel={MESSAGES.PLACEHOLDER.POSTCODE}
                  readOnly={this.props.member.access.profile === 'full' ? false : this.props.member.access.profile}
                  multiline={false}
                  keyboardType="numeric"
                  maxLength={4}
                  onRef={(ref: any) => {
                    this.inputs.myDetailsModal[
                      INPUTS.MY_DETAILS_MODAL_POST_CODE
                    ] = ref;
                  }}
                  onSubmitEditing={() => {
                    !this.state.disableAddress &&
                      this.focusNextField(
                        this.inputs.myDetailsModal,
                        INPUTS.MY_DETAILS_MODAL_PHONE_NUMBER,
                      );
                  }}
                  returnKeyType={'next'}
                  value={this.state.aboutMeModals.postcode}
                  accessibilityLabel={types.POST_CODE_ACCESSIBILITY}
                  onChangeText={(myDetailsModalPostcode: any) =>
                  {    
                    const sanitizedPostalCode = DOMPurify.sanitize(myDetailsModalPostcode);

                    this.setState(prevState => ({
                      aboutMeModals: {
                        ...prevState.aboutMeModals,
                        postcode: sanitizedPostalCode.replace(/[^0-9]/g, ''),
                      },
                    }))}
                  }
                  blurOnSubmit={false}
                  underlineColorAndroid="transparent"
                  style={[
                    CommonStyles.ModalTextInputWrapper,
                    BrandStyles.primaryBgColor1,
                  ]}
                  errorMessage={this.state.validPostCode}
                  disabled={this.state.disableAddress}
                />
              </View>
              <View style={[CommonStyles.twoColumnsRight]}>
                <FormSubtitle text={[MESSAGES.PLACEHOLDER.STATE]} />
                <View
                  style={[
                    CommonStyles.ModalPickerInputContainer,
                    BrandStyles.errorBorder,
                    {borderWidth: this.state.stateErrorBorderWidth},
                    CommonStyles.pickerBorderRadius,
                  ]}
                >
                  <View
                    style={[
                      BrandStyles.secondaryBgColor3,
                      CommonStyles.borderRadius10,
                    ]}
                  >
                    <StandardPicker
                      access={
                        this.state.disableAddress
                          ? types.ACCESS_READ_ONLY
                          : null
                      }
                      pickerOptions={this.state.statePicker}
                      style={[
                        CommonStyles.standardPicker,
                        CommonStyles.standardPickerBackgroundColorGray,
                      ]}
                      selectedValue={this.state.aboutMeModals.state}
                      onValueChange={(itemValue: any) => {
                        if (itemValue !== 'State') {
                          this.setState(prevState => ({
                            aboutMeModals: {
                              ...prevState.aboutMeModals,
                              state: itemValue,
                            },
                          }));
                        }
                      }}
                    />
                  </View>
                </View>
                {this._showStateError(this.state.displayStateError)}
              </View>
            </View>
          </View>
          <View>
            <FormTitle
              containsData={this.state.aboutMeModals.phoneNumber}
              text={MESSAGES.MY_DETAILS.HOME_PHONE}
            />
            <StandardInput
              readOnly={this.props.member.access.profile === 'full' ? false : this.props.member.access.profile}
              multiline={false}
              onRef={(ref: any) => {
                this.inputs.myDetailsModal[
                  INPUTS.MY_DETAILS_MODAL_PHONE_NUMBER
                ] = ref;
              }}
              value={this.state.aboutMeModals.phoneNumber}
              accessibilityLabel="Home phone"
              onChangeText={(myDetailsModalPhoneNumber: any) =>
              {
                const sanitizedPhoneNumber = DOMPurify.sanitize(myDetailsModalPhoneNumber);
                this.setState(prevState => ({
                  aboutMeModals: {
                    ...prevState.aboutMeModals,
                    phoneNumber: sanitizedPhoneNumber,
                  },
                }))}
              }
              underlineColorAndroid="transparent"
              style={[
                CommonStyles.ModalTextInputWrapper,
                BrandStyles.primaryBgColor1,
              ]}
              errorMessage={this.state.validPhoneNumber}
            />
          </View>
          <View>
            <FormTitle
              containsData={this.state.aboutMeModals.mobilePhone}
              text={MESSAGES.MY_DETAILS.MOBILE_PHONE}
            />
            <StandardInput
            readOnly={this.props.member.access.profile === 'full' ? false : this.props.member.access.profile}
              multiline={false}
              onRef={(ref: any) => {
                this.inputs.myDetailsModal[
                  INPUTS.MY_DETAILS_MODAL_MOBILE_PHONE
                ] = ref;
              }}
              value={this.state.aboutMeModals.mobilePhone}
              accessibilityLabel="Mobile phone"
              onChangeText={(myDetailsModalMobilePhone: any) =>
            {
            const sanitizedMobilePhone = DOMPurify.sanitize(myDetailsModalMobilePhone);

            this.setState(prevState => ({
                  aboutMeModals: {
                    ...prevState.aboutMeModals,
                    mobilePhone: sanitizedMobilePhone,
                  },
                }))}
              }
              underlineColorAndroid="transparent"
              style={[
                CommonStyles.ModalTextInputWrapper,
                BrandStyles.primaryBgColor1,
              ]}
              errorMessage={this.state.validMobilePhone}
            />
          </View>
          <View>
            <FormTitle
              containsData={this.state.aboutMeModals.ndisMeeting}
              text={MESSAGES.MY_DETAILS.NDIS_PLAN_DATE}
            />
            <DatePicker
              readOnly={this.props.member.access.profile}
              value={this.state.aboutMeModals.ndisMeeting}
              accessibilityLabel={'N D I S plan Date is '}
              save={(date: any) => {
                this.state.aboutMeModals.ndisMeeting = date;
              }}
            />
          </View>
          <View>
            <FormTitle
              containsData={this.state.aboutMeModals.ndisNumber}
              text={MESSAGES.MY_DETAILS.NDIS_NUMBER}
            />
            <StandardInput
             readOnly={this.props.member.access.profile === 'full' ? false : this.props.member.access.profile}
              multiline={false}
              onRef={(ref: any) => {
                this.inputs.myDetailsModal[
                  INPUTS.MY_DETAILS_MODAL_NDIS_NUMBER
                ] = ref;
              }}
              value={this.state.aboutMeModals.ndisNumber}
              accessibilityLabel={MESSAGES.MY_DETAILS.NDIS_NUMBER}
              onChangeText={(myDetailsModalNDISNumber: any) =>
             {
              const sanitizedNDISNumber = DOMPurify.sanitize(myDetailsModalNDISNumber);

              this.setState(prevState => ({
                  aboutMeModals: {
                    ...prevState.aboutMeModals,
                    ndisNumber: sanitizedNDISNumber,
                  },
                }))}
              }
              keyboardType="numeric"
              blurOnSubmit={false}
              underlineColorAndroid="transparent"
              style={[
                CommonStyles.ModalTextInputWrapper,
                BrandStyles.primaryBgColor1,
              ]}
              errorMessage={this.state.validNDIS}
              disabled={
                this.props.member.memberStatus !=
                types2.MEMBER_STATUS_DATA.PLAN_READY
                  ? true
                  : false
              }
            />
          </View>
        </View>
        <BasicOverlayPopupModal
          visible={this.state.showManualEntryAddressPopupAlert}
          style={this._determinePopUpStyle()}
          backdropOpacity={0.2}
          divider={false}
          close={() => {}}
          hideActionButton={true}
          hideCloseButton={true}
          cancelContainerStyle={CommonStyles.popUpOkButton}
          cancelStyle={CommonStyles.buttonPopupOk}
        >
          <View>{this.getPopUpContent()}</View>
        </BasicOverlayPopupModal>
        {this._renderfetchAddressErrorPopup()}
      </BasicForm>
    );
  };
  _saveAboutMe = (saveObj: any) => {
    Keyboard.dismiss();
    let updateMemberCallback = () => {
      // Successful save -- update redux store and hide modal
      this.props.actions.MemberActions.actionUpdateMember(saveObj);
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      //  The callback is asynchronous since the NavigationAction reset tries to
      //  execute after navigating to a screen which results in improper execution
      //  when not using asynchronous.
      this.setState({loading: false, aboutMe: saveObj}, async () => {
        // When mobile platform
        if (!types.isWeb) {
          if (this.props.navigation.state.params) {
            if (
              this.props.navigation.state.params.goBack === 'ProviderEnquiry'
            ) {
              this.props.navigation.navigate('ProviderEnquiry');
              this.props.actions.GeneralActions.skipUnwantedStackScreen(true);
            } else if (
              this.props.navigation.state.params.goBack ===
              'SpendingSupportItemDetail'
            ) {
              await this.refreshData();
              await Keyboard.dismiss();
              await CommonActions.reset({
                key: null,
                index: 0,
                routes: [
                  {name: 'AboutMe'}
               ],
              });
              await this.props.navigation.navigate(
                'SpendingSupportItemDetail',
                {
                  group: this.props.navigation.state.params.group,
                  index: this.props.navigation.state.params.index,
                },
              );
            }
          }
        }

        if (this.props.general.screenName) {
          this.props.navigation.navigate(this.props.general.screenName);
          this.props.actions.GeneralActions.skipUnwantedStackScreen(true);
          this.props.actions.GeneralActions.navigateBackToPreviousScreen('');
        }
      });
      if (
        !this.props.general.screenName ||
        !this.props.navigation.state.params
      ) {
        this.fetchData();
        this.props.navigation.goBack();
      }
    };

    const updateMemberErrorCallback = (err: any) => {
      logger.log('The err in the update member is: ', err);
      // Error code returned by lambda
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
        loading: false,
      });
    };

    callAPIs(
      [updateMember(saveObj, this.props.user.demoProfile)],
      updateMemberCallback,
      updateMemberErrorCallback,
    );
  };

  _hasFormContentChanged = () => {
    let initialObject = {
      firstName: this.state.aboutMeInitialState.firstName,
      knownAs: this.state.aboutMeInitialState.knownAs,
      lastName: this.state.aboutMeInitialState.lastName,
      dateOfBirth: this.state.aboutMeInitialState.dateOfBirth,
      address: this.state.aboutMeInitialState.address,
      apartment: this.state.aboutMeInitialState.apartment,
      city: this.state.aboutMeInitialState.city,
      postcode: this.state.aboutMeInitialState.postcode,
      state: this.state.aboutMeInitialState.state,
      phoneNumber: this.state.aboutMeInitialState.phoneNumber,
      ndisMeeting: this.state.aboutMeInitialState.ndisMeeting,
      ndisNumber: this.state.aboutMeInitialState.ndisNumber,
      mobilePhone: this.state.aboutMeInitialState.mobilePhone,
    };
    let currentObject = {
      firstName: this.state.aboutMeModals.firstName,
      knownAs: this.state.aboutMeModals.knownAs,
      lastName: this.state.aboutMeModals.lastName,
      dateOfBirth: this.state.aboutMeModals.dateOfBirth,
      address: this.state.aboutMeModals.address,
      apartment: this.state.aboutMeModals.apartment,
      city: this.state.aboutMeModals.city,
      postcode: this.state.aboutMeModals.postcode,
      state: this.state.aboutMeModals.state,
      phoneNumber: this.state.aboutMeModals.phoneNumber,
      ndisMeeting: this.state.aboutMeModals.ndisMeeting,
      ndisNumber: this.state.aboutMeModals.ndisNumber,
      mobilePhone: this.state.aboutMeModals.mobilePhone,
    };
    return JSON.stringify(initialObject) !== JSON.stringify(currentObject);
  };

  _saveMyDetails = () => {
    const validateFields = this._validateMyDetailsInfo();
    if (!validateFields) {
      return;
    }
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({loading: true, saveApiError: false});

    if (!this.state.disableAddress) {
      this.setState(
        prevState => ({
          aboutMeModals: {
            ...prevState.aboutMeModals,
            latitude: '0',
            longitude: '0',
            boundries: {SA1: 0, SA2: 0, SA3: 0, SA4: 0},
          },
        }),
        () => {
          let msData = JSON.parse(JSON.stringify(this.state.aboutMeModals));
          try {
            msData = trimObjectProperties(
              msData,
              types2.USER_TRIM_FIELDS_ABOUT_ME,
            );
          } catch (error) {}

          //Keys to remove
          const removeKeysArr = [
            'apiCalled',
            'communication',
            'connectingWithOthers',
            'dailyLiving',
            'disabilities',
            'disabilityMoreInfo',
            'customDisabilities',
            'independence',
            'learning',
            'lifeStages',
            'planManager',
            'profileImage',
          ];
          for (let i = 0; i < removeKeysArr.length; i++) {
            delete msData[removeKeysArr[i]];
          }
          if (msData.dateOfBirth) {
            msData.dateOfBirth = formatDateToString(msData.dateOfBirth);
          }
          this._saveAboutMe(msData);
        },
      );
    } else {
      if (this.state.setSuggestedAddress === '') {
        this.setState(
          prevState => ({
            aboutMeModals: {
              ...prevState.aboutMeModals,
              latitude: '0',
              longitude: '0',
              boundries: {SA1: 0, SA2: 0, SA3: 0, SA4: 0},
            },
          }),
          () => {
            let msData = JSON.parse(JSON.stringify(this.state.aboutMeModals));
            try {
              msData = trimObjectProperties(
                msData,
                types2.USER_TRIM_FIELDS_ABOUT_ME,
              );
            } catch (error) {}

            //Keys to remove
            const removeKeysArr = [
              'apiCalled',
              'communication',
              'connectingWithOthers',
              'dailyLiving',
              'disabilities',
              'disabilityMoreInfo',
              'customDisabilities',
              'independence',
              'learning',
              'lifeStages',
              'planManager',
              'profileImage',
            ];
            for (let i = 0; i < removeKeysArr.length; i++) {
              delete msData[removeKeysArr[i]];
            }
            if (msData.dateOfBirth) {
              msData.dateOfBirth = formatDateToString(msData.dateOfBirth);
            }
            this._saveAboutMe(msData);
          },
        );
      } else {
        let msData = JSON.parse(JSON.stringify(this.state.aboutMeModals));
        try {
          msData = trimObjectProperties(
            msData,
            types2.USER_TRIM_FIELDS_ABOUT_ME,
          );
        } catch (error) {}

        //Keys to remove
        const removeKeysArr = [
          'apiCalled',
          'communication',
          'connectingWithOthers',
          'dailyLiving',
          'disabilities',
          'disabilityMoreInfo',
          'customDisabilities',
          'independence',
          'learning',
          'lifeStages',
          'planManager',
          'profileImage',
        ];
        for (let i = 0; i < removeKeysArr.length; i++) {
          delete msData[removeKeysArr[i]];
        }
        if (msData.dateOfBirth) {
          msData.dateOfBirth = formatDateToString(msData.dateOfBirth);
        }

        this._saveAboutMe(msData);
      }
    }
  };

  _closeMyDetails = () => {
    var csData = this.state.aboutMe;
    const stateUpdate = {
      aboutMeModals: csData,
    };
    this.setState(stateUpdate);
    this.props.navigation.goBack();
  };

  _validateMyDetailsInfo() {
    // Checks the required feilds or the number only fields before trying to save to backend.
    var validateErrors = {
      validFirstName: '',
      validLastName: '',
      validDOB: '',
      validPostCode: '',
      validState: '',
      validPhoneNumber: '',
      validMobilePhone: '',
      validNDIS: '',
    };

    let trimedFirstName =
      this.state.aboutMeModals && this.state.aboutMeModals.firstName
        ? this.state.aboutMeModals.firstName.trim()
        : null;
    let trimedLastName =
      this.state.aboutMeModals && this.state.aboutMeModals.lastName
        ? this.state.aboutMeModals.lastName.trim()
        : null;

    if (
      this.state.aboutMeModals &&
      (!trimedFirstName ||
        trimedFirstName == '' ||
        this.state.aboutMeModals.firstName == undefined)
    ) {
      validateErrors.validFirstName = types.FIRSTNAME_NOT_ENTERED;
    }

    if (
      this.state.aboutMeModals &&
      (!trimedLastName ||
        trimedLastName == '' ||
        this.state.aboutMeModals.lastName == undefined)
    ) {
      validateErrors.validLastName = types.LASTNAME_NOT_ENTERED;
    }

    validateErrors.validPostCode = this._validatePostCode();
    validateErrors.validPhoneNumber = this._validatePhoneNumber();
    validateErrors.validMobilePhone = this._validateMobilePhone();
    validateErrors.validDOB = this._validateDOB();
    validateErrors.validNDIS = this._validateNDISNumber();

    this.setState({
      validFirstName: validateErrors.validFirstName,
      validLastName: validateErrors.validLastName,
      validDOB: validateErrors.validDOB,
      validPostCode: validateErrors.validPostCode,
      validState: validateErrors.validState,
      validPhoneNumber: validateErrors.validPhoneNumber,
      validMobilePhone: validateErrors.validMobilePhone,
      validNDIS: validateErrors.validNDIS,
    });

    var readThisText =
      validateErrors.validDOB +
      ' ' +
      validateErrors.validState +
      ' ' +
      validateErrors.validPostCode +
      ' ' +
      validateErrors.validPhoneNumber;

    for (let [key, value] of Object.entries(validateErrors)) {
      if (value != '') {
        announceForAccessibility(readThisText);
        return false;
      }
    }
    return true;
  }

  _validateDOB() {
    if (this._inValidDate()) {
      this.setState({displayDOBError: false, dobErrorBorderWidth: 3});
      return types.DOB_INCORECT;
    }
    this.setState({displayDOBError: true, dobErrorBorderWidth: 0});
    return '';
  }
  _inValidDate() {
    if (this.state.aboutMeModals.dateOfBirth) {
      var dob = this.getParsedDate(this.state.aboutMeModals.dateOfBirth);
      var dobValue = new Date(dob[0], dob[1], dob[2]);
      var now = new Date();
      if (dobValue > now) {
        return true;
      }
    }

    return false;
  }

  _checkEditAccess() {
    if (
      (this.props.member.memberStatus == types2.MEMBER_STATUS_DATA.ONBOARDING) |
      (this.props.member.memberStatus == types2.MEMBER_STATUS_DATA.MANAGED)
    ) {
      return types.ACCESS_READ_ONLY;
    } else {
      return this.props.member.access.profile === 'full' ? false : this.props.member.access.profile;
    }
  }

  getParsedDate = (date: any) => {
    var days = String(date).split('-');
    return [parseInt(days[0]), parseInt(days[1]) - 1, parseInt(days[2])];
  };

  // Shows the DOB error message as it isnt using the Standard input component but a datepickercomponent
  _showDOBError = (DOBCheck: any) => {
    if (DOBCheck != false) {
      return null;
    } else {
      return (
        <StandardText
          accessibilityLabel="Date of Birth is Required"
          style={[
            CommonStyles.inputErrorMessageText,
            CommonStyles.AboutMeStandardTextMarginTop,
            CommonStyles.customFont,
          ]}
        >
          {this._inValidDate() ? types.DOB_INCORECT : types.DOB_NOT_SELECTED}
        </StandardText>
      );
    }
  };
  // Check if post code is is 4 digits long and that it is only digits
  _validatePostCode() {
    if (this.state.aboutMeModals.postcode) {
      if (isNaN(this.state.aboutMeModals.postcode)) {
        return types.INVALID_POSTCODE_NAN;
      } else if (
        this.state.aboutMeModals.postcode.length > 0 &&
        this.state.aboutMeModals.postcode.length < 4
      ) {
        return types.INVALID_POSTCODE_LENGTH;
      } else {
        return '';
      }
    }
    return '';
  }

  _validateState() {
    if (this.state.aboutMeModals.state === '') {
      this.setState({displayStateError: false, stateErrorBorderWidth: 3});
      return types.STATE_NOT_SELECTED;
    } else {
      this.setState({displayStateError: true, stateErrorBorderWidth: 0});
      return '';
    }
  }

  // Shows the state picker error message as it using the picker component and not the standardinput component
  _showStateError = (StateCheck: any) => {
    if (StateCheck != false) {
      return null;
    } else {
      return (
        <StandardText
          accessibilityLabel="State is Required"
          style={[
            CommonStyles.inputErrorMessageText,
            {
              marginTop: -20,
            } /*styles.termsAndConditionsError, {fontSize: 13, marginRight: 20, marginTop: -20, paddingBottom: 10}*/,
          ]}
        >
          {types.STATE_NOT_SELECTED}
        </StandardText>
      );
    }
  };

  // Checks that the phone number only contains numbers
  _validatePhoneNumber() {
    if (this.state.aboutMeModals.phoneNumber) {
      if (this.state.aboutMeModals.phoneNumber.length > 25) {
        return message.INVALID_HOME_PHONE; //Invalid Fields
      } else {
        return ''; // valid fields
      }
    }
    return '';
  }
  _validateMobilePhone() {
    if (this.state.aboutMeModals.mobilePhone) {
      if (this.state.aboutMeModals.mobilePhone.length > 25) {
        return types.INVALID_MOBILE_PHONE; //Invalid Fields
      } else {
        return ''; // valid fields
      }
    }
    return '';
  }
  // Checks that the NDIS number only contains digits
  _validateNDISNumber() {
    if (this.state.aboutMeModals.ndisNumber) {
      if (isNaN(this.state.aboutMeModals.ndisNumber)) {
        return types.INVALID_NDIS_NUMBER;
      } else {
        return '';
      }
    }
    return '';
  }
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
  navigationParams: state.NavigationParamsReducer,
  general: state.GeneralReducer,
  admin: state.AdminReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    MemberActions: bindActionCreators(MemberActions, dispatch),
    AvatarListActions: bindActionCreators(AvatarListActions, dispatch),
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    GeneralActions: bindActionCreators(GeneralActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AboutMeMyDetailsForm);
