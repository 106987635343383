/*
 * Author: Brad D'Costa
 * Date: 29/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Member actions for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const actionGetMember = (
  memberObj,
  loggedInUserId,
  isEmployee,
  isFinancialStaff,
) => ({
  type: types.GET_MEMBER,
  memberObj,
  loggedInUserId,
  isEmployee,
  isFinancialStaff,
});

export const actionUpdateMember = (memberObj: any) => ({
  type: types.UPDATE_MEMBER,
  memberObj,
});

export const actionUpdateMemberPhotoCaption = (photoCaption: any) => ({
  type: types.UPDATE_MEMBER_PHOTO_CAPTION,
  photoCaption,
});

export const actionSetServiceAgreementEnabled = (serviceAgreement: any) => ({
  type: types2.IS_SERVICE_AGREEMENTS_ENABLED,
  serviceAgreement,
});
