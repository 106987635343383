/*
 * Author: Shelan Cooray
 * Date: 30/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Render this component by Support screen when there are no supports already.
 */

import React, {Component} from 'react';
import {View} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as messages from '../../Constants/Messages';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {
  supportsIconRed,
  MediumRedTickCircleRed3x,
  MediumRedEmptyCircleRed3x,
} from '../../assets/images/vector-icons';

import {SummaryTile, RadioGroup, RadioButton} from '../Molecules';
import {StandardText, StandardButton} from '../Atoms';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import * as SupportsActions from '../../Actions/SupportsActions';

class AddSupportOptions extends Component {
  state = {
    isLoading: false,
    selectedSupport: messages.ADD_SUPPORTS_BY.NONE,
  };

  constructor(props: any) {
    super(props);
    this._navigateNext = this._navigateNext.bind(this);
  }

  render() {
    return (
      <View style={[CommonStyles.flexColumn, CommonStyles.content]}>
        <View>
          <View>
            <SummaryTile
              headerDisplayString={messages.ADD_SUPPORTS_BY.TITLE}
              headerIconImage={supportsIconRed}
              HeaderTextColor={BrandStyles.TextColor3}
              HeaderBoarderColor={BrandStyles.borderColor3}
              hideYellowStar={true}
              showButton={false}
              fontSize={CommonStyles.rpfont20}
              disableMore={true}
            />
          </View>

          <View style={[CommonStyles.justifyFlexRowStart]}>
            <StandardText
              style={[
                CommonStyles.text_SupportContainer,
                CommonStyles.customFont,
              ]}
            >
              {messages.ADD_SUPPORTS_BY.DESCRIPTION}
            </StandardText>
          </View>
          <View
            style={[
              CommonStyles.justifyFlexColumnStart,
              CommonStyles.marginRight20,
            ]}
          >
            <View style={[CommonStyles.justifyFlexColumnStart]}>
              {this._createOptionContents()}
            </View>
            <View
              style={[CommonStyles.justifyFlexRowEnd, CommonStyles.marginTop20]}
            >
              <View>{this._renderNextButton()}</View>
            </View>
          </View>
        </View>
      </View>
    );
  }

  _renderRadioButtons = (options: any) => {
    return options.map((option: any, index: any) => (
      <RadioButton
        key={index}
        value={option.OPTION_KEY}
        isSelected={false}
        style={[CommonStyles.paddingTop10, CommonStyles.paddingRight10]}
        buttonStyle={
          types.isWeb
            ? CommonStyles.radioButtonPMWeb
            : CommonStyles.radioButtonPM
        }
        buttonTick={MediumRedTickCircleRed3x}
        buttonEmpty={MediumRedEmptyCircleRed3x}
        readOnly={this.props.member.access.profile}
        disabled={
          this.props.member.access.profile !== types.ACCESS_FULL ? true : false
        }
      >
        <View style={[CommonStyles.radioButtonSupport_Container]}>
          <View
            style={[
              CommonStyles.flex1,
              CommonStyles.flexDirectionRow,
              CommonStyles.justifyContentSpaceBetween,
            ]}
          >
            <StandardText
              style={[
                types.isWeb ? CommonStyles.font18 : CommonStyles.font20,
                BrandStyles.TextColor5,
                CommonStyles.customFontSemiBold,
              ]}
            >
              {messages.ADD_SUPPORTS_BY.SUPPORT_OPTIONS[index].OPTION_TITLE}
            </StandardText>
          </View>
          <View
            style={[
              CommonStyles.flex1,
              CommonStyles.flexDirectionRow,
              CommonStyles.paddingTop10,
            ]}
          >
            <StandardText
              style={[
                CommonStyles.text_SupportContainer,
                CommonStyles.alignSelfFlexStart,
                CommonStyles.customFont,
              ]}
            >
              {messages.ADD_SUPPORTS_BY.SUPPORT_OPTIONS[index].OPTION_DESC}
            </StandardText>
          </View>
        </View>
      </RadioButton>
    ));
  };

  _createOptionContents = () => {
    return (
      <View style={[CommonStyles.containerSupport_Options]}>
        <RadioGroup
          onSelect={(index: any, value: any) => this._onSelect(index, value)}
        >
          {this._renderRadioButtons(messages.ADD_SUPPORTS_BY.SUPPORT_OPTIONS)}
        </RadioGroup>
      </View>
    );
  };

  _renderNextButton = () => {
    return (
      <StandardButton
        onPress={this._navigateNext}
        style={this._renderButtonStyle()}
        containerStyle={this._renderButtonContainerStyle()}
        accessibilityLabel={types2.NEXT}
        disabled={
          this.state.selectedSupport === messages.ADD_SUPPORTS_BY.NONE
            ? true
            : false
        }
      >
        {types2.NEXT}
      </StandardButton>
    );
  };

  _renderButtonStyle = () => {
    if (this.state.selectedSupport === messages.ADD_SUPPORTS_BY.NONE) {
      return [BrandStyles.TextColor10, CommonStyles.buttonFormAction];
    } else {
      return [BrandStyles.TextColor1, CommonStyles.buttonFormAction];
    }
  };

  _renderButtonContainerStyle = () => {
    if (this.state.selectedSupport === messages.ADD_SUPPORTS_BY.NONE) {
      return [
        BrandStyles.secondaryBgColor3,
        BrandStyles.borderColor8,
        CommonStyles.buttonContainerFormAction,
      ];
    } else {
      return [
        BrandStyles.primaryBgColor4,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerFormAction,
      ];
    }
  };

  _onSelect = (index: any, value: any) => {
    if (index == 0) {
      if (value) {
        this.setState({selectedSupport: messages.ADD_SUPPORTS_BY.CATEGORY});
      } else {
        this.setState({selectedSupport: messages.ADD_SUPPORTS_BY.NONE});
      }
    } else if (index == 1) {
      if (value) {
        this.setState({selectedSupport: messages.ADD_SUPPORTS_BY.CREW});
      } else {
        this.setState({selectedSupport: messages.ADD_SUPPORTS_BY.NONE});
      }
    }
  };

  _navigateNext = () => {
    if (this.state.selectedSupport === messages.ADD_SUPPORTS_BY.CATEGORY) {
      this.props.actions.SupportsActions.setSupportInitialState();
      this.props.navigation.navigate('AddSupportsByCategory', {
        categories: this.props.categories,
        fromScreen: messages.ADD_SUPPORTS_BY.CATEGORY,
      });
    } else if (this.state.selectedSupport === messages.ADD_SUPPORTS_BY.CREW) {
      this.props.actions.SupportsActions.setSupportInitialState();
      this.props.navigation.navigate('SelectAddCrewSupport', {
        categories: this.props.categories,
      });
    }
  };
}

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
  member: state.MemberReducer,
  supports: state.SupportsReducer,
  categories: state.SupportCategoriesReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSupportOptions);
