//Sets Up a Basic Modal with a save and cancel button and
//receives some components from parent class (Text, TextInput through (props.children))
import React, {Component} from 'react';
import {
  Text,
  View,
  FlatList,
  StyleSheet,
  ScrollView,
  Image,
} from 'react-native';

import {StandardButton} from '../Atoms';
import {BrandActivityIndicator, ModalApiError} from '../Molecules';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import Modal from 'react-native-modal';

import {closeIcon} from '../../assets/images';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import {OfflineNotice} from './';

import {KeyboardAwareScrollView} from 'helpers/KeyboardAwareScrollView';

class BasicModal extends Component {
  constructor(props: any) {
    super(props);
    this.renderDeleteButton.bind(this);
    this._setRef.bind(this);
  }

  _setRef = (scrollView: any) => {
    if (this.props.onRef) {
      this.props.onRef(scrollView);
    }
  };

  render() {
    let customActionButtons = false;
    if (this.props.customActionButtons != undefined) {
      customActionButtons = this.props.customActionButtons;
    }

    return (
      <Modal style={this.props.style} isVisible={this.props.visible}>
        <BrandActivityIndicator loading={this.props.loading} />
        <View
          style={[
            CommonStyles.standardModal,
            BrandStyles.lightGreyBgColour,
            CommonStyles.paddingTop25,
          ]}
        >
          <View style={CommonStyles.modalHeader}>
            <View style={CommonStyles.closeIconPosition}>
              <StandardButton
                onPress={this.props.close}
                accessibilityLabel="Close Modal"
              >
                <Image source={closeIcon} style={CommonStyles.closeIconImage} />
              </StandardButton>
            </View>
          </View>
          <OfflineNotice />
          <KeyboardAwareScrollView
            style={CommonStyles.modalContent}
            contentContainerStyle={CommonStyles.modalContentScroll}
            keyboardShouldPersistTaps="always"
            scrollEnabled={this.props.scrollEnabled}
            ref={input => this._setRef(input)}
          >
            {this.props.saveApiError && (
              <ModalApiError errorCode={this.props.saveApiErrorCode} />
            )}
            {this.props.children}
            {this._renderActionButtons(customActionButtons)}
          </KeyboardAwareScrollView>
        </View>
      </Modal>
    );
  }

  _renderActionButtons = (customActionButtons: any) => {
    if (customActionButtons) {
      return null;
    } else {
      return (
        <View style={CommonStyles.ModalActionButtons}>
          <View style={CommonStyles.ModalFooterLeft}>
            {this.renderDeleteButton()}
          </View>
          <View style={CommonStyles.ModalFooterRight}>
            {this.renderCloseButton()}
            {this.renderSaveButton()}
          </View>
        </View>
      );
    }
  };

  renderSaveButton() {
    if (
      this.props.readOnly != undefined &&
      (this.props.readOnly == types.ACCESS_READ_ONLY ||
        this.props.readOnly == types.ACCESS_NONE)
    ) {
      return null;
    } else if (this.props.save) {
      let saveText = 'Save';

      if (this.props.saveLabel) {
        saveText = this.props.saveLabel;
      }

      let saveContainerStyle = [
        CommonStyles.ModalButtonContainer,
        CommonStyles.ModalSaveButton,
      ];

      if (this.props.saveContainerStyle) {
        saveContainerStyle = saveContainerStyle.concat(
          this.props.saveContainerStyle,
        );
      }
      // No style passed in, so default it to the green color.
      else {
        saveContainerStyle.push(BrandStyles.primaryBgColor6);
        saveContainerStyle.push(BrandStyles.borderColor6);
      }

      return (
        <StandardButton
          onPress={this.props.save}
          style={[
            BrandStyles.TextColor2,
            CommonStyles.customFontMedium,
            CommonStyles.fontWeight400,
            CommonStyles.buttonText,
          ]}
          containerStyle={saveContainerStyle}
          accessibilityLabel={saveText}
        >
          {saveText}
        </StandardButton>
      );
    } else {
      return null;
    }
  }

  renderCloseButton() {
    if (this.props.close && !this.props.hideCloseButton) {
      return (
        <StandardButton
          onPress={this.props.close}
          style={[
            BrandStyles.TextColor6,
            CommonStyles.customFontMedium,
            CommonStyles.fontWeight400,
            CommonStyles.buttonText,
          ]}
          containerStyle={[
            CommonStyles.ModalButtonContainer,
            CommonStyles.ModalCancelButton,
            BrandStyles.primaryBgColor1,
          ]}
          accessibilityLabel="Cancel"
        >
          Cancel
        </StandardButton>
      );
    } else {
      return null;
    }
  }

  renderDeleteButton() {
    let isDecisionMaker = true;
    if (this.props.isDecisionMaker != undefined) {
      isDecisionMaker = this.props.isDecisionMaker;
    }

    if (isDecisionMaker) {
      if (this.props.delete) {
        if (this.props.deleteText) {
          deleteText = this.props.deleteText;
        } else {
          deleteText = 'Delete';
        }

        return (
          <StandardButton
            readOnly={this.props.readOnly}
            onPress={this.props.delete}
            style={[
              BrandStyles.TextColor1,
              CommonStyles.customFontMedium,
              CommonStyles.fontWeight400,
              CommonStyles.buttonText,
            ]}
            containerStyle={[
              CommonStyles.ModalButtonContainer,
              CommonStyles.ModalCancelButton,
              BrandStyles.primaryBgColor3,
            ]}
            accessibilityLabel={deleteText}
          >
            {deleteText}
          </StandardButton>
        );
      } else {
        return null;
      }

      return (
        <StandardButton
          onPress={this.props.delete}
          style={[
            BrandStyles.TextColour2,
            CommonStyles.customFontMedium,
            CommonStyles.fontWeight400,
            CommonStyles.buttonText,
          ]}
          containerStyle={[
            CommonStyles.ModalButtonContainer,
            CommonStyles.ModalCancelButton,
            BrandStyles.secondaryBgColour2,
          ]}
          accessibilityLabel={deleteText}
        >
          {deleteText}
        </StandardButton>
      );
    } else {
      return null;
    }
  }
}

BasicModal.defaultProps = {
  scrollEnabled: true,
  style: [],
};

export default BasicModal;
