/*
 * Author: Tharindu Seneviratne
 * Date: 04/11/2020
 * Copyright © 2020 Leap in!. All rights reserved.
 *
 * Displays the Title which would appear in the top of the screen
 */
import React from 'react';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {StandardText} from '../Atoms';

const ScreenTitle = (props: any) => {
  return (
    <StandardText
      style={[
        CommonStyles.fontWeightBold,
        CommonStyles.flexWrap,
        CommonStyles.textAlignCentre,
        CommonStyles.font25,
        BrandStyles.screenTitleTextColor,
      ]}
    >
      {props.title || ''}
    </StandardText>
  );
};

export default ScreenTitle;
