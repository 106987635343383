/*
 * Author: Nirodha Perera
 * Date: 17/10/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Svg mask to draw highlight in the selected component
 *
 */
import React, {Component} from 'react';
import {View, Animated, Easing, Dimensions} from 'react-native';
import Svg from 'react-native-svg';
import AnimatedSvgPath from './AnimatedPath';

// import Orientation from 'react-native-orientation-enhanced';
import {isWeb} from '../../Constants/Constants';
import {ORIENTATION} from '../../Constants/Constants2';

import {BrandColors} from '../../Styles/Colours';

const windowDimensions = Dimensions.get('window');
const path = (
  size: any,
  position: any,
  sizeTwo: any,
  positionTwo: any,
  canvasSize: any,
) => `M0,0H${canvasSize.x}V${canvasSize.y}H0V0ZM${position.x._value},${
  position.y._value
}
    H${position.x._value + size.x._value}V${
  position.y._value + size.y._value
}H${position.x._value}V${position.y._value}ZM${positionTwo.x._value},${
  positionTwo.y._value
}
    H${positionTwo.x._value + sizeTwo.x._value}V${
  positionTwo.y._value + sizeTwo.y._value
}H${positionTwo.x._value}V${positionTwo.y._value}Z`;

const pathHighlight = (
  size: any,
  position: any,
  sizeTwo: any,
  positionTwo: any,
) => `M${position.x._value},${position.y._value}
    H${position.x._value + size.x._value}V${
  position.y._value + size.y._value
}H${position.x._value}V${position.y._value}ZM${positionTwo.x._value},${
  positionTwo.y._value
}
    H${positionTwo.x._value + sizeTwo.x._value}V${
  positionTwo.y._value + sizeTwo.y._value
}H${positionTwo.x._value}V${positionTwo.y._value}Z`;

class SvgMask extends Component {
  static defaultProps = {
    animationDuration: 300,
    easing: Easing.linear,
  };

  state = {
    orientation: '',
  };

  constructor(props: any) {
    super(props);
    this.getViewSize.bind(this);

    this.state = {
      canvasSize: this.getViewSize(
        windowDimensions.width,
        windowDimensions.height,
      ),
      sizeOne: new Animated.ValueXY(props.overlayHighlightOne.size),
      positionOne: new Animated.ValueXY(props.overlayHighlightOne.position),
      sizeTwo: props.overlayHighlightTwo
        ? new Animated.ValueXY(props.overlayHighlightTwo.size)
        : new Animated.ValueXY(0),
      positionTwo: props.overlayHighlightTwo
        ? new Animated.ValueXY(props.overlayHighlightTwo.position)
        : new Animated.ValueXY(0),
    };
  }

  getViewSize = (width: any, height: any) => {
    let size = {};
    if (height > width && this.state.orientation === ORIENTATION.LANDSCAPE) {
      size = {
        x: height,
        y: width,
      };
    } else {
      size = {
        x: width,
        y: height,
      };
    }
    return size;
  };

  UNSAFE_componentWillMount() {
    // if (!isWeb) {
    //   Orientation.getOrientation((err: any, orientation: any) => {
    //     this.setState({orientation});
    //   });
    // }
  }

  componentDidMount() {
    // if (!isWeb) {
    //   Orientation.addOrientationListener(this._orientationDidChange);
    // }
  }

  componentWillUnmount() {
    // if (!isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation,
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (
      nextProps.overlayHighlightOne &&
      (this.props.overlayHighlightOne.position !==
        nextProps.overlayHighlightOne.position ||
        this.props.overlayHighlightOne.size !==
          nextProps.overlayHighlightOne.size)
    ) {
      this.state.sizeOne.setValue(nextProps.overlayHighlightOne.size);
      this.state.positionOne.setValue(nextProps.overlayHighlightOne.position);
    }

    if (nextProps.overlayHighlightTwo) {
      this.state.sizeTwo.setValue(nextProps.overlayHighlightTwo.size);
      this.state.positionTwo.setValue(nextProps.overlayHighlightTwo.position);
    }
  }

  handleLayout = () => {
    this.setState({
      canvasSize: this.getViewSize(
        windowDimensions.width,
        windowDimensions.height,
      ),
    });
  };

  getViewHeight = () => {
    if (
      windowDimensions.height > windowDimensions.width &&
      this.state.orientation === ORIENTATION.LANDSCAPE
    ) {
      return windowDimensions.width;
    } else {
      return windowDimensions.height;
    }
  };

  getViewWidth = () => {
    if (
      windowDimensions.height > windowDimensions.width &&
      this.state.orientation === ORIENTATION.LANDSCAPE
    ) {
      return windowDimensions.height;
    } else {
      return windowDimensions.width;
    }
  };

  render() {
    return (
      <View pointerEvents="box-none" onLayout={this.handleLayout}>
        <Svg
          pointerEvents="none"
          width={this.getViewWidth()}
          height={this.getViewHeight()}
        >
          <AnimatedSvgPath
            fill={BrandColors.BLACK}
            fillOpacity={0.4}
            fillRule="evenodd"
            strokeWidth={2}
            d={path(
              this.state.sizeOne,
              this.state.positionOne,
              this.state.sizeTwo,
              this.state.positionTwo,
              this.state.canvasSize,
            )}
          />
          <AnimatedSvgPath
            fillRule="evenodd"
            fill="transparent"
            strokeWidth={3}
            d={pathHighlight(
              this.state.sizeOne,
              this.state.positionOne,
              this.state.sizeTwo,
              this.state.positionTwo,
            )}
            stroke={BrandColors.RESPONSIVE_DESIGN_COLORS.YELLOW}
          />
        </Svg>
      </View>
    );
  }
}

export default SvgMask;
