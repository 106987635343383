/**
 * Author: Andrew Seeley
 * Date: 14/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This will show the spending on an individual support item
 * @param {String} name The name of the support item
 * @param {String} color The primary color of the graph & text
 * @param {String} secondaryColor the secondary color of the graph
 * @param {Any} icon The icon for the support
 * @param {Object} budget The budget values for the support that matches the swagger api
 * @param {Object} item The item for this support. Used on callback when the user taps view
 * @param {Function} viewCallback The viewcallback to call when the user taps on the view button
 */

import React, {Component} from 'react';
import {View, Text, Image, StyleSheet, Alert} from 'react-native';
import PropTypes from 'prop-types';
import {PieChartSVG} from '../../Helpers/PlatformSynchronizer';
import {sortBy} from 'lodash';
import {StandardText, StandardTouchableOpacity} from '../Atoms';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {BrandColors} from '../../Styles/Colours';
import {capitalize, formatAsCurrency, getPostive} from '../../Helpers/Utils';
import {CurrencyText} from './';

import {
  BUDGET_SPEND_GRAPHS as messages,
  BENEFIT_OF_LI_SCREEN as alertMessages,
} from '../../Constants/Messages';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import {viewIconCharcoal60} from '../../assets/images';

const OUTER_RADIOUS = 44;
class SpendingSupportItem extends Component {
  state = {
    viewWidth: 200,
  };

  constructor(props: any) {
    super(props);
    this._setupChartData.bind(this);
  }

  find_dimesions(layout: any) {
    if (!types.isWeb) {
      const {x, y, width, height} = layout;
      this.setState({
        viewWidth: width,
      });
    }
  }

  render() {
    let clickable = this.props.clickable;
    let title = this.props.name;
    let color = this.props.color;
    let secondaryColor = this.props.secondaryColor;
    let budget = this.props.budget;
    let unspent = budget.unspent;

    if (budget.allocated && budget.allocated != null) {
      budget.unspent += budget.allocated;
      delete budget.allocated;
    }

    let total = parseFloat(budget.spent) + parseFloat(budget.unspent);
    let totalUnassignedText = formatAsCurrency(
      budget.unspent,
      '$',
      types2.DECIMAL_ACCURACY,
    );
    const pieChartSortOrder = JSON.parse(JSON.stringify(this.props.sortOrder));
    const pieData = this._setupChartData(
      budget,
      color,
      secondaryColor,
      pieChartSortOrder,
    );

    return (
      <View
        style={
          types.isWeb
            ? [CommonStyles.height100, CommonStyles.budgetsTilesContainer]
            : CommonStyles.budgetsTilesContainer
        }
      >
        <StandardTouchableOpacity
          disabled={!clickable}
          accessibilityLabel={messages.VIEW}
          style={[
            BrandStyles.primaryBgColor1,
            clickable
              ? CommonStyles.budgetsTileWrapper
              : CommonStyles.marginTop10,
          ]}
          onPress={() => this._viewSupportItem()}
        >
          <View
            style={[
              CommonStyles.flexDirectionRow,
              CommonStyles.paddingLeft10,
              CommonStyles.justifyCenter,
              CommonStyles.alignCenter,
              CommonStyles.paddingTop10,
            ]}
          >
            {/* Pie chart */}
            <View
              style={[
                CommonStyles.spendingItemSpacing,
                types.isWeb ? CommonStyles.pieChartSupportItemWeb : null,
              ]}
            >
              <PieChartSVG
                style={CommonStyles.pieChartSupportItem}
                data={pieData}
                sort={() => {}}
                outerRadius={OUTER_RADIOUS}
              />
            </View>

            {/* Data */}
            <View
              style={[
                CommonStyles.justifyCenter,
                CommonStyles.alignCenter,
                CommonStyles.flexDirectionRow,
              ]}
            />

            <View
              style={[
                CommonStyles.flex1,
                CommonStyles.flexRow,
                CommonStyles.spendingItemSpacing,
                CommonStyles.paddingLeft10,
                CommonStyles.alignItemsCenter,
                CommonStyles.flexDirectionRow,
              ]}
              onLayout={event => {
                this.find_dimesions(event.nativeEvent.layout);
              }}
            >
              <Image
                style={[CommonStyles.spendingSupportIcon]}
                source={{uri: this.props.icon.url}}
              />
              {clickable
                ? this._renderTitle(title, color, clickable)
                : this._renderCurrencyText(color, unspent, total)}
            </View>
          </View>
          <View
            style={[
              CommonStyles.flexRow,
              types.isWeb ? CommonStyles.alignItemsFlexEnd : null,
            ]}
          >
            {clickable
              ? [
                  this._renderCurrencyText(color, unspent, total),
                  this._renderViewButton(),
                ]
              : this._renderTitle(title, color, clickable)}
          </View>
        </StandardTouchableOpacity>
      </View>
    );
  }

  /**
   * Take in the budget data and colours and return a data strucutre that the pie graphing can accept
   * @param {Object} budget The budget data to format into the values the pie chart will accept
   * @param {String} color The primary color of the graph
   * @param {String} secondaryColor The secondary color of the graph
   */
  _setupChartData(
    budget: any,
    color: any,
    secondaryColor: any,
    pieChartSortOrder: any,
  ) {
    let data = [];
    let graphSection;

    if (budget.allocated && budget.allocated != null) {
      budget.unspent += budget.allocated;
      delete budget.allocated;
    }

    // Go over all the budget values
    for (var key in budget) {
      if (budget.hasOwnProperty(key)) {
        // Setup the values for a 'section' of a pie graph
        graphSection = {
          key: key,
          value: getPostive(parseInt(budget[key])),
        };
        // Set the fill color for the section, default is grey (For unassigned)
        let fill = BrandColors.RESPONSIVE_DESIGN_COLORS.LIGHT_GREY;
        if (key == 'unspent') {
          fill = color;
        } else if (key == 'allocated') {
          fill = secondaryColor;
        }
        // Add the fill color for the section
        if (types.isWeb) {
          graphSection.stroke = fill;
          graphSection.strokeWidth = 10;
        } else {
          graphSection.svg = {
            fill: fill,
            stroke: BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL,
            strokeWidth: '1',
          };
        }

        data.push(graphSection);
      }
    }
    data = sortBy(data, item => {
      return pieChartSortOrder.indexOf(item.key);
    });
    return data;
  }

  /**
   * View the support item
   */
  _viewSupportItem = () => {
    console.log('debug',this.props.group,this.props.index)
    
    this.props.viewCallback(this.props.group, this.props.index);
  };

  _renderViewButton = () => {
    return (
      <View
        style={[
          CommonStyles.alignItemsFlexEnd,
          CommonStyles.justifyContentFlexEnd,
        ]}
      >
        <View
          style={[
            CommonStyles.flexRow,
            CommonStyles.justifyCenter,
            CommonStyles.alignItemsFlexEnd,
            types.isWeb ? CommonStyles.margin1 : CommonStyles.rpMargin5,
          ]}
        >
          <View>
            <StandardText
              style={[
                CommonStyles.spendSupportViewText,
                CommonStyles.alignCenter,
                CommonStyles.fontWeight400,
                BrandStyles.TextColor10,
                CommonStyles.customFont,
              ]}
              accessibilityLabel={messages.VIEW}
            >
              {messages.VIEW}
            </StandardText>
          </View>
          <View>
            <Image
              style={[
                CommonStyles.spendingSupportIconView,
                CommonStyles.marginLeft5,
              ]}
              source={viewIconCharcoal60}
            />
          </View>
        </View>
      </View>
    );
  };

  _renderTitle = (title: any, color: any, clickable: any) => {
    const widthStyle = clickable
      ? {width: this.state.viewWidth - types2.VIEW_WIDTH_OFFSET}
      : CommonStyles.width100;
    return (
      <StandardText
        style={[
          clickable ? null : [CommonStyles.flex1, CommonStyles.paddingTop5],
          CommonStyles.spendSupportText,
          CommonStyles.customFontSemiBold,
          CommonStyles.fontWeightBG400,
          CommonStyles.marginLeft5,
          CommonStyles.marginRight10,
          CommonStyles.flexWrap,
          {color},
          widthStyle,
        ]}
      >
        {title}
      </StandardText>
    );
  };

  _renderCurrencyText = (color: any, unspent: any, total: any) => {
    return (
      <View
        style={[
          CommonStyles.flex1,
          CommonStyles.marginTop10,
          CommonStyles.paddingLeft10,
        ]}
      >
        <View
          style={[
            CommonStyles.flexRow,
            types.isWeb
              ? [CommonStyles.alignItemsFlexEnd, CommonStyles.margin1]
              : null,
          ]}
        >
          <CurrencyText
            value={unspent}
            isPositive={true}
            integerStyle={[
              CommonStyles.customFont,
              CommonStyles.font18,
              {color: color},
            ]}
            decimalStyle={[
              CommonStyles.font13,
              CommonStyles.customFont,
              {color: color},
            ]}
            noContainer={true}
          />
          <StandardText
            style={[
              CommonStyles.spendSupportText,
              CommonStyles.customFont,
              CommonStyles.fontWeightBG400,
              {color: color},
            ]}
          >
            {' ' + messages.UNASSIGNED_SUPPORT}
          </StandardText>
        </View>
        <View style={[CommonStyles.flexRow]}>
          <StandardText
            style={[
              CommonStyles.spendSupportText,
              CommonStyles.customFont,
              CommonStyles.fontWeightBG400,
              BrandStyles.TextColor5,
              CommonStyles.spendingItemSpacing,
            ]}
          >
            {messages.TOTAL_SUPPORT + ' '}
          </StandardText>
          <CurrencyText
            value={total}
            isPositive={true}
            integerStyle={[
              CommonStyles.customFont,
              BrandStyles.TextColor5,
              CommonStyles.font18,
            ]}
            decimalStyle={[
              CommonStyles.font13,
              CommonStyles.customFont,
              BrandStyles.TextColor5,
            ]}
            containerStyle={[CommonStyles.flex1, CommonStyles.flexDirectionRow]}
          />
        </View>
      </View>
    );
  };
}

// Warn the user if props are not passed
SpendingSupportItem.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  secondaryColor: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  budget: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  viewCallback: PropTypes.func.isRequired,
};

export default SpendingSupportItem;
