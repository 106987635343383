/*
 * Author: Declan Hart
 * Date: 14/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the profiletile of the participant.
 */

import React, {Component} from 'react';
import {View, StyleSheet} from 'react-native';
import {StandardText} from '../Atoms';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {MEMBER_STATUS} from '../../Constants/Messages';

class MemberDetails extends Component {
  constructor(props: any) {
    super(props);
  }

  _getMemberStatus() {
    switch (this.props.status) {
      case types2.MEMBER_STATUS_DATA.PLAN_READY:
        return MEMBER_STATUS.PLAN_READY;

      case types2.MEMBER_STATUS_DATA.ONBOARDING:
        return MEMBER_STATUS.ONBOARDING;

      case types2.MEMBER_STATUS_DATA.MANAGED:
        return MEMBER_STATUS.MANAGED;
    }
  }

  render() {
    const locationText = (
      <StandardText
        style={[
          CommonStyles.customFont,
          CommonStyles.ProfileTileSubText,
          BrandStyles.TextColor5,
        ]}
      >
        {this.props.location}{' '}
      </StandardText>
    );

    return (
      <View style={[CommonStyles.oneProfileTileContainer]}>
        <StandardText
          style={[
            CommonStyles.customFontBold,
            CommonStyles.ProfileTileHeaderText,
            CommonStyles.rpMarginBottom10p,
            BrandStyles.TextColor5,
            CommonStyles.paddingRight10,
          ]}
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          {this.props.firstName} {this.props.lastName}
        </StandardText>
        {this.props.location ? locationText : null}
        <StandardText
          style={[
            CommonStyles.customFont,
            CommonStyles.ProfileTileSubText,
            BrandStyles.TextColor5,
          ]}
        >
          {this._getMemberStatus()}{' '}
        </StandardText>
      </View>
    );
  }
}

export default MemberDetails;
