/*
 * Author: Brad D'Costa
 * Date: 30/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Summary tile on the MyProfile screen for the About Me Section
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import {StandardText} from '../Atoms';
import {SummaryTile, PhotoTile} from '../Molecules';

import {
  aboutMeIconCharcoal,
  aboutMeIconRed,
} from '../../assets/images/vector-icons';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class MyProfileAboutMe extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <SummaryTile
        headerDisplayString="About me"
        headerIconImage={aboutMeIconRed}
        screenType={types.SCREEN_TYPE_MAIN}
        modal={this.props.modal}
        containsData={this._determineContainsData()}
        actionOverride={this._determineAccess()}
        displayMore={this._checkMoreData()}
      >
        {this._renderAboutMe()}
      </SummaryTile>
    );
  }

  _checkMoreData() {
    if (
      this.props.reducer &&
      (this.props.reducer.disabilityMoreInfo ||
        this.props.reducer.communication ||
        this.props.reducer.connectingWithOthers ||
        (this.props.reducer.customDisabilities &&
          this.props.reducer.customDisabilities.length) ||
        this.props.reducer.dailyLiving ||
        this.props.reducer.independence ||
        this.props.reducer.learning ||
        (this.props.reducer.lifeStages &&
          this.props.reducer.lifeStages.length) ||
        this.props.reducer.ndisMeeting ||
        this.props.reducer.phoneNumber)
    ) {
      return true;
    } else {
      return false;
    }
  }

  _determineAccess = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      if (this.props.reducer.access) {
        if (this.props.reducer.access.profile) {
          if (this.props.reducer.access.profile == types.ACCESS_READ_ONLY) {
            return types.ACTION_BUTTON.VIEW;
          }
        }
      }
    }
    return null;
  };

  _determineContainsData = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return false;
    } else {
      var checkArray = [
        this.props.reducer.address,
        this.props.reducer.city,
        this.props.reducer.email,
        this.props.reducer.knownAs,
        this.props.reducer.postcode,
        this.props.reducer.state,
      ];
      var dataCheck = false;
      for (var i = 0; i < checkArray.length; i++) {
        if (checkArray[i] !== '') {
          dataCheck = true;
          break;
        }
      }
      return dataCheck;
    }
  };

  _determinIcon = () => {
    if (this._determineContainsData()) {
      return aboutMeIconRed;
    } else {
      return aboutMeIconCharcoal;
    }
  };

  _renderAboutMe = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      return (
        <View>
          {this._renderProfilePicture()}
          {this._renderKnownAsSummary()}
          {this._renderAddressSummary()}
          {this._renderEmailSummary()}
        </View>
      );
    }
  };

  _renderProfilePicture = () => {
    if (
      this.props.reducer.profileImage &&
      this.props.reducer.profileImage.url
    ) {
      const readOutProfilePicture = this.props.reducer.profileImage.photoCaption
        ? `${this.props.reducer.profileImage.photoCaption} profile picture`
        : `${this.props.reducer.firstName} profile picture`;

      return (
        <View
          style={[
            CommonStyles.flexRow,
            CommonStyles.flexWrap,
            CommonStyles.alignItemsTop,
          ]}
        >
          <View accessible={true} accessibilityLabel={readOutProfilePicture}>
            <PhotoTile
              key={this.props.reducer.id}
              style={StyleSheet.flatten([
                CommonStyles.containerPhotoTileMyProfile,
              ])}
              flexRatio={CommonStyles.flex1}
              apiFileType={types2.FILE_TYPE.MEMBER_PROFILE}
              imageObject={this.props.reducer.profileImage}
              showCaption={false}
              clickable={false}
              disabled={true}
              lastUpdate={
                this.props.reducer.profileImage &&
                this.props.reducer.profileImage.lastModified
                  ? new Date(this.props.reducer.profileImage.lastModified)
                  : null
              }
            />
          </View>

          <StandardText
            style={[
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
              CommonStyles.myProfileAvatarName,
            ]}
          >
            {this.props.reducer.firstName + ' ' + this.props.reducer.lastName}
          </StandardText>
        </View>
      );
    } else {
      return null;
    }
  };

  _renderKnownAsSummary = () => {
    if (this.props.reducer.knownAs == '') {
      return null;
    } else {
      return (
        <View
          accessible={true}
          style={[
            CommonStyles.flexRow,
            CommonStyles.flexWrap,
            CommonStyles.alignItemsCenter,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFontBold,
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
            ]}
          >
            Call Me:{' '}
          </StandardText>
          <StandardText
            style={[
              CommonStyles.customFont,
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
            ]}
          >
            {this.props.reducer.knownAs}
          </StandardText>
        </View>
      );
    }
  };

  _renderAddressSummary = () => {
    if (
      this.props.reducer.address == '' &&
      this.props.reducer.city == '' &&
      this.props.reducer.postcode == '' &&
      this.props.reducer.state == ''
    ) {
      return null;
    } else {
      if (this.props.reducer.address == '') {
        return (
          <View
            accessible={true}
            style={[
              CommonStyles.flexRow,
              CommonStyles.flexWrap,
              CommonStyles.alignItemsCenter,
            ]}
          >
            <StandardText
              style={[
                CommonStyles.customFontBold,
                CommonStyles.SummaryTileText,
                BrandStyles.TextColor5,
              ]}
            >
              Lives At:{' '}
            </StandardText>
            <StandardText
              style={[
                CommonStyles.customFont,
                CommonStyles.SummaryTileText,
                BrandStyles.TextColor5,
              ]}
            >
              {this.props.reducer.apartment} {this.props.reducer.city}{' '}
              {this.props.reducer.postcode} {this.props.reducer.state}
            </StandardText>
          </View>
        );
      } else {
        return (
          <View
            accessible={true}
            style={[
              CommonStyles.flexRow,
              CommonStyles.flexWrap,
              CommonStyles.alignFlexStart,
            ]}
          >
            <StandardText
              style={[
                CommonStyles.customFontBold,
                CommonStyles.SummaryTileText,
                BrandStyles.TextColor5,
                CommonStyles.maxWidth93p,
              ]}
            >
              Lives At:{' '}
            </StandardText>
            <StandardText
              style={[
                CommonStyles.customFont,
                CommonStyles.SummaryTileText,
                BrandStyles.TextColor5,
                CommonStyles.maxWidth93p,
              ]}
            >
              {this.props.reducer.apartment} {this.props.reducer.address},{' '}
              {this.props.reducer.city} {this.props.reducer.postcode}{' '}
              {this.props.reducer.state}
            </StandardText>
          </View>
        );
      }
    }
  };

  _renderEmailSummary = () => {
    if (this.props.reducer.email == '') {
      return null;
    } else {
      return (
        <View
          accessible={true}
          style={[
            CommonStyles.flexRow,
            CommonStyles.flexWrap,
            CommonStyles.alignItemsCenter,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFontBold,
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
            ]}
          >
            Email:{' '}
          </StandardText>
          <StandardText
            style={[
              CommonStyles.customFont,
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
            ]}
          >
            {this.props.reducer.email}
          </StandardText>
        </View>
      );
    }
  };
}

export default MyProfileAboutMe;
