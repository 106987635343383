import {Route} from 'react-router-dom';
import {ModalRoute} from 'react-router-modal';
import React from 'react';
import PropTypes from 'prop-types';
import {routePaths} from '../Constants/Constants2';
import {removeItem, setValue} from '../API/WebPersistenceStore';

//Enables navigation functionlity for web
function Wrapper({element, history, match, closeModal}: any) {
  const navigate = (to: any, params: any, replaceRoute: any) => {
    const routeTo = routePaths[to];
    let url = '';
    let currentScreen = '';
    let state = {};
    for (const property in routePaths) {
      if (match.path === routePaths[property].path) {
        currentScreen = property;
      }
    }

    if (currentScreen !== '') {
      setValue('prevScreen', currentScreen);
    }

    if (routeTo) {
      url = routeTo.path;
      // Replace params ids in the url with actual values
      if (params && Object.keys(params).length > 0) {
        Object.keys(params).forEach(param => {
          const re = RegExp(`\:${param}\\??`); // eslint-disable-line no-useless-escape
          url = url.replace(re, escape(params[param]));
        });
      }
      // removing empty params from url - every string between /: and ?
      url = url.replace(/\/:(.*?)(?=\/|$)/g, '');
      // if the route is not a modal
      if (!routeTo.modal) {
        if (replaceRoute) {
          history.replace(url);
        } else {
          history.push(url);
        }
        // if the route is a modal
      } else {
        // checking if the url ends with a slash or not
        const slash = /\/$/.test(match.url) ? '' : '';
        // current url in the browser + slash + modal url with parameters
        url = match.url + slash + url;
        // removing the */ from the url
        url = url.replace(/\*\/?/g, '');
        if (replaceRoute) {
          history.replace(url);
        } else {
          history.push(url);
        }
      }
    }
  };

  const getParam = (param: any, alternative: any) =>
    match.params[param] || alternative;

  const goBack = () => {
    history.goBack();
  };

  return React.cloneElement(element, {
    navigation: {navigate, getParam, goBack},
    history,
    closeModal,
  });
}

Wrapper.propTypes = {
  element: PropTypes.element,
  history: PropTypes.object,
  routeMap: PropTypes.object,
  closeModal: PropTypes.func,
  match: PropTypes.object,
};

const WebRoutesGenerator = (
  {routeMap}: any,
  setLoading: any,
  isMobile: any,
  viewWidth: any,
) => {
  return Object.keys(routeMap).map(route => {
    const currentRoute = routeMap[route];
    const Component = currentRoute.component;
    if (currentRoute.modal) {
      return (
        <ModalRoute
          key={currentRoute.path}
          path={currentRoute.path}
          component={(props: any) => (
            <Wrapper
              element={
                <Component
                  setLoading={setLoading}
                  isMobile={isMobile}
                  viewWidth={viewWidth}
                />
              }
              {...props}
            />
          )}
        />
      );
    } else {
      return (
        <Route
          key={currentRoute.path}
          path={currentRoute.path}
          exact={currentRoute.exact}
          render={(props: any) => (
            <Wrapper
              element={
                <Component
                  setLoading={setLoading}
                  isMobile={isMobile}
                  viewWidth={viewWidth}
                />
              }
              {...props}
            />
          )}
        />
      );
    }
  });
};

WebRoutesGenerator.propTypes = {
  routeMap: PropTypes.object,
};

export default WebRoutesGenerator;
