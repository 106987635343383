/*
 * Author: Nirodha Perera
 * Date: 11/07/2019
 * Copyright © 2019 Leap in!. All rights reserved.
 *
 * This component redirects user based on the device
 */

import React, {Component} from 'react';
import {TouchableOpacity, Text, Image, View} from 'react-native';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {isMobile} from 'react-device-detect';
import {LILogo} from '../../assets/images';

import {closeIcon} from '../../assets/images/vector-icons';

import CustomIcon from '../../assets/images/CustomIcon';
import {getValue, setValue} from '../../API/WebPersistenceStore';
import {
  DEEPLINK_NAVIGATION,
  ENVIRONMENTS,
  DEEPLINK_URLS,
} from '../../Constants/Constants2';
import {DEEPLINK_BANNER} from '../../Constants/Messages';
import {AWS_LAMBDA_CONFIG_ITEM} from '../../environments';

import {StandardButton, StandardText} from '../Atoms';
class DeepLinkBanner extends Component {
  constructor() {
    super();
  }

  state = {
    showViewAppBanner: false,
  };

  componentDidMount() {
    const isDeepLinkNavigation = getValue(DEEPLINK_NAVIGATION);
    if (JSON.parse(isDeepLinkNavigation)) {
      this.setState({showViewAppBanner: true});
    }
  }

  getDeepLinkUrl = (env: any) => {
    switch (env) {
      case ENVIRONMENTS.DEV:
        return DEEPLINK_URLS.DEV;
      case ENVIRONMENTS.QA:
        return DEEPLINK_URLS.QA;
      case ENVIRONMENTS.UAT:
        return DEEPLINK_URLS.UAT;
      default:
        return DEEPLINK_URLS.PROD;
    }
  };

  render() {
    const navigationUrl = this.getDeepLinkUrl(AWS_LAMBDA_CONFIG_ITEM);

    if (isMobile && this.state.showViewAppBanner) {
      return (
        <View
          style={[CommonStyles.bannerContainer, BrandStyles.lightGreyBgColour]}
        >
          <View style={CommonStyles.bannerLeft}></View>
          <View>
            <TouchableOpacity
              style={CommonStyles.bannerButtonContainer}
              onPress={() => {
                window.open(navigationUrl);
              }}
            >
              <Image style={CommonStyles.bannerIcon} source={LILogo} />
              <Text style={[BrandStyles.TextColor5, CommonStyles.bannerText]}>
                {DEEPLINK_BANNER.VIEW_ON_APP}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={CommonStyles.bannerRight}>
            <StandardButton
              onPress={() => {
                setValue(DEEPLINK_NAVIGATION, false);
                this.setState({showViewAppBanner: false});
              }}
              accessibilityLabel={DEEPLINK_BANNER.CLOSE}
            >
              <CustomIcon name={closeIcon} style={CommonStyles.font25} />
            </StandardButton>
          </View>
        </View>
      );
    } else {
      return null;
    }
  }
}

export default DeepLinkBanner;
