/*
 * Author: Brad D'Costa
 * Date: 20/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Web Service class to handle the Crew Service API calls
 */

// Custom functions for helping connect to the secure AWS API Gateway
import {
  initaliseAwsClient,
  configuredAWSLamda,
  buildLambda,
  parseResult,
  apiErrorHandler,
} from './APIHelper';
import AWS from 'aws-sdk';
import {AWS_LAMBDA_SERVICES} from '../environments';
import logger from 'helpers/Logger';

//* ********************************
//*            C R E W
//* ********************************

/*
 * Returns a the access level for all crews, the crew is joined by the relationshipID
 * @async
 * @param {string} personId The person ID of the user you want to return crew access levels for
 * @returns {Promise<array>} An array of the access levels for a given crew
 */
export const getCrewAccessLevels = (
  personId: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getCrewAccessLevels = configuredAWSLamda();
      const requestBody = {
        isDemoProfile,
        id: personId,
      };
      const functionName = 'getRelationshipAccess';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.CREW;
      const params = buildLambda(requestBody, functionName, serviceName);
      getCrewAccessLevels.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/*
 * Submits a request for access to a members profile
 * @async
 * @param {string} personId The person ID of the requesting user
 * @param {object} requestObj The object containing the member email and access level being requested
 * @returns {Promise<object>} A success message that the request was sent
 */
export const requestPersonAccess = (
  personId: any,
  email: any,
  callback: any,
) => {
  AWS.config.credentials.get((err: any) => {
    if (err) {
      logger.log('There was an error getting credentials:', err);
    } else {
      // Use the lambda function directly from the AWS sdk
      const getUserByCognitoId = configuredAWSLamda();
      const requestBody = {id: personId, email};
      const functionName = 'putCrewAccessByMemberEmail';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.CREW;
      const params = buildLambda(requestBody, functionName, serviceName);
      getUserByCognitoId.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
        } else {
          const result = parseResult(data);
          callback(result);
        }
      });
    }
  });
};
