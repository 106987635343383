/*
 * Author: Shelan Cooray
 * Date: 27/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The service agreement detail screen. It will display each of the pre authorization items on screen with other details.
 */

import React, {Component} from 'react';
import {View, Image, Platform} from 'react-native';
import {requestWritePermission} from '../Helpers/FileHelper';
import {connect} from 'react-redux';

import {
  SummaryTile,
  Container,
  ServiceAgreementList,
  SimpleSpendingBar,
  CurrencyText,
} from '../Components/Molecules';

import {ServiceAgreementAttachmentsPopup} from '../Components/Organisms';
import {
  StandardText,
  StandardTouchableOpacity,
  ColouredLabel,
} from '../Components/Atoms';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {SERVICE_AGREEMENTS_DETAILS_SCREEN as messages} from '../Constants/Messages';
import {GreyClockIcon3x, EyeGray4} from '../assets/images';
import logger from 'helpers/Logger';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {formatDDMMMYYDate} from '../Helpers/Utils';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {RED_BUDGET} from '../Styles/SpendingBarColors';

import {RNFetchBlobSingleton} from '../Helpers/PlatformSynchronizer';
import {bindActionCreators} from 'redux';

class ServiceAgreementsDetails extends Component {
  itemDetails: any;
  title: any;
  state = {
    attachmentVisible: false,
  };
  constructor(props: any) {
    super(props);
    const {itemDetails, title} = this.props.navigationParams.params;
    this.itemDetails = itemDetails;
    this.title = title;

    this._createMainContents.bind(this);
    this._renderAgreementDetails.bind(this);
    this._showAttachmentModal.bind(this);
  }

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        screenType={types.SCREEN_TYPE_MAIN}
        ignorePadding={true}
        customStyle={CommonStyles.containerInvoiceDetails}
        nav={this.props.navigation}
        headerTitle={types2.NAVIGATION_TEXT.SERVICE_AGREEMENT}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        needsSidebar={false}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    return (
      <View
        style={[
          CommonStyles.flexColumn,
          CommonStyles.content,
          CommonStyles.containerInvoice_Summary,
        ]}
      >
        <View>
          <SummaryTile
            headerDisplayString={messages.MAIN_TITLE}
            imageIcon={true}
            headerIconImage={{
              uri: this.itemDetails.budgetCategoryData.icon.url,
            }}
            HeaderTextColor={{
              color: this.itemDetails.budgetCategoryData.color,
            }}
            HeaderBoarderColor={{
              borderColor: this.itemDetails.budgetCategoryData.color,
            }}
            hideYellowStar={true}
            showButton={false}
            fontSize={CommonStyles.rpfont20}
            disableMore={true}
          />
        </View>
        {this._renderAttachmentPopup()}
        {this._renderAgreementDetails()}
      </View>
    );
  };

  _renderAgreementDetails = () => {
    return (
      <View>
        <View style={[CommonStyles.containerSA_Summary]}>
          <View style={[CommonStyles.justifyFlexColumnStart]}>
            <View style={[CommonStyles.justifyFlexRowSpace]}>
              <View
                style={[CommonStyles.justifyFlexRowStart, CommonStyles.flex55]}
              >
                <StandardText
                  style={[
                    CommonStyles.customFontSemiBold,
                    types.isWeb ? CommonStyles.font15 : CommonStyles.font20,
                    BrandStyles.TextColor5,
                  ]}
                >
                  {this.itemDetails.providerName}
                </StandardText>
              </View>
              {this._renderStatusLabel()}
            </View>
            <View
              style={[
                CommonStyles.justifyFlexRowStart,
                CommonStyles.alignItemsCenter,
              ]}
            >
              <Image
                style={CommonStyles.containerBudgetApprovalCard_ImageDate}
                source={GreyClockIcon3x}
              />
              <StandardText
                style={[
                  CommonStyles.font15,
                  CommonStyles.customFont,
                  BrandStyles.TextColor10,
                ]}
              >
                {this._renderDateRange()}
              </StandardText>
            </View>
            <View
              style={[
                CommonStyles.justifyFlexRowStart,
                CommonStyles.alignItemsCenter,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.title_ListContainer,
                  CommonStyles.marginBottom10,
                  CommonStyles.customFont,
                ]}
              >
                {this.itemDetails.categoryDescription}
              </StandardText>
            </View>
            <View
              style={[
                CommonStyles.justifyFlexRowStart,
                CommonStyles.marginBottom10,
              ]}
            >
              <View style={[CommonStyles.justifyFlexColumnStart]}>
                <StandardText
                  style={[
                    CommonStyles.title_ListContainer,
                    CommonStyles.customFont,
                    CommonStyles.marginTop10,
                  ]}
                >
                  {this.itemDetails.contractNumber
                    ? this.itemDetails.contractNumber
                    : ''}
                </StandardText>
                {this.itemDetails.attachments
                  ? this.itemDetails.attachments.length != 0
                    ? this._renderShowAttachments()
                    : null
                  : null}
              </View>
              <View style={[CommonStyles.justifyFlexColumnEnd]}>
                <CurrencyText
                  value={this.itemDetails.totalBudget}
                  isPositive={true}
                  integerStyle={[
                    types.isWeb ? CommonStyles.font30 : CommonStyles.font40,
                    CommonStyles.customFont,
                    BrandStyles.TextColor5,
                  ]}
                />
                <View
                  style={[
                    CommonStyles.justifyFlexRowEnd,
                    CommonStyles.flex3,
                    types.isWeb ? CommonStyles.marginTop25 : {},
                  ]}
                >
                  <StandardText
                    style={[
                      CommonStyles.title_ListContainer,
                      CommonStyles.marginRight0,
                      CommonStyles.customFont,
                    ]}
                  >
                    {messages.TOTAL_ALLOCATED}
                  </StandardText>
                </View>
              </View>
            </View>

            {/* Render the used and remaining amount on the screen. */}
            {this._renderUsedRemaining()}

            {/* Render the spent bar on screen */}
            {this._renderSpentBar()}
          </View>
        </View>
        {/* Render the list with all the details */}
        {this._renderPreAuthItems()}
      </View>
    );
  };

  _renderAttachmentPopup = () => {
    return (
      <ServiceAgreementAttachmentsPopup
        attachmentVisible={this.state.attachmentVisible}
        close={this._closeAttachmentModal}
        _openDocument={this._openDocument}
        attachments={this.itemDetails.attachments}
      />
    );
  };

  _renderShowAttachments = () => {
    return (
      <StandardTouchableOpacity
        accessible
        activeOpacity={0.6}
        onPress={this._showAttachmentModal}
      >
        <View
          style={[
            CommonStyles.justifyFlexRowStart,
            CommonStyles.alignItemsCenter,
            CommonStyles.marginTop10,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.title_ListContainer,
              CommonStyles.customFontSemiBold,
              CommonStyles.underlineText,
              BrandStyles.TextColor10,
            ]}
          >
            {types2.ATTACHMENTS}
          </StandardText>
          <Image
            style={[CommonStyles.viewAttachments, CommonStyles.marginLeft5]}
            source={EyeGray4}
          />
        </View>
      </StandardTouchableOpacity>
    );
  };

  _renderDateRange = () => {
    return (
      formatDDMMMYYDate(this.itemDetails.startDate) +
      ' - ' +
      formatDDMMMYYDate(this.itemDetails.endDate)
    );
  };

  _renderUsedRemaining = () => {
    return (
      <View
        style={[CommonStyles.justifyFlexRowStart, CommonStyles.marginTop20]}
      >
        <View
          style={[
            CommonStyles.justifyFlexColumnStart,
            CommonStyles.alignItemsFlexStart,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFont,
              BrandStyles.TextColor5,
              CommonStyles.font18,
            ]}
          >
            {messages.SPENT}
          </StandardText>
          <CurrencyText
            value={this.itemDetails.usedBudget}
            isPositive={true}
            integerStyle={[
              CommonStyles.title_ListContainer,
              CommonStyles.marginRight0,
              CommonStyles.customFontSemiBold,
            ]}
            decimalStyle={[
              CommonStyles.title_ListContainerSmall,
              CommonStyles.marginRight0,
              CommonStyles.customFont,
            ]}
            containerStyle={[
              CommonStyles.justifyFlexRowStart,
              CommonStyles.customFontSemiBold,
            ]}
          />
        </View>
        <View
          style={[
            CommonStyles.justifyFlexColumnEnd,
            CommonStyles.alignItemsFlexEnd,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFont,
              BrandStyles.TextColor5,
              CommonStyles.font18,
            ]}
          >
            {messages.REMAINING}
          </StandardText>
          <CurrencyText
            value={this.itemDetails.remainingBudget}
            isPositive={true}
            integerStyle={[
              CommonStyles.title_ListContainer,
              CommonStyles.marginRight0,
              CommonStyles.customFontSemiBold,
            ]}
            decimalStyle={[
              CommonStyles.title_ListContainerSmall,
              CommonStyles.marginRight0,
              CommonStyles.customFont,
            ]}
            containerStyle={[
              CommonStyles.flexRow,
              CommonStyles.justifyEnd,
              CommonStyles.marginLeft5,
            ]}
          />
        </View>
      </View>
    );
  };

  _renderStatusLabel = (item: any) => {
    var message = messages.INACTIVE;
    var backgroundColour =
      CommonStyles.ServiceAgreementStatusContainerGreyTextWrapper;
    if (this.itemDetails.active) {
      message = messages.ACTIVE;
      backgroundColour = CommonStyles.containerPurpleTextWrapper;
    }
    return (
      <ColouredLabel message={message} backgroundStyle={backgroundColour} />
    );
  };

  _renderStatusLabel = (item: any) => {
    let message = messages.INACTIVE;
    let backgroundColour =
      CommonStyles.ServiceAgreementStatusContainerGreyTextWrapper;
    if (this.itemDetails.active) {
      message = messages.ACTIVE;
      backgroundColour = CommonStyles.containerPurpleTextWrapper;
    }
    return (
      <ColouredLabel message={message} backgroundStyle={backgroundColour} />
    );
  };

  _openDocument = async (document: any, forceDownload: any) => {
    this.setState({
      attachmentVisible: false,
    });
    if (types.isWeb) {
      window.open(document.signedUrl);
    }
    // this.setState({ loading: true });
    if (Platform.OS === types2.IOS) {
      const ios = RNFetchBlobSingleton.ios;
      const dirs = RNFetchBlobSingleton.fs.dirs;
      RNFetchBlobSingleton.config({
        path: dirs.DocumentDir + '/LeapIn/' + document.name,
        fileCache: true,
      })
        .fetch('GET', document.signedUrl, {
          // Headers here
        })
        .then(res => {
          // this.setState({ loading: false });
          ios.openDocument(res.path()).catch((err: any) => logger.log(err));
        })
        .catch(err => {
          logger.log(err);
        });
    } else if (Platform.OS === types2.ANDROID) {
      const fileType = this.getFileExtension(document.name)[0];
      if (fileType == types2.PDF) {
        this.props.navigation.navigate('PdfViewer', {
          url: document.signedUrl,
          fileName: document.name,
        });
        // this.setState({ loading: false });
      } else if (
        types2.IMAGE_FILE_TYPES.includes(fileType) &&
        forceDownload != true
      ) {
        this.props.navigation.navigate('WebViewer', {
          url: document.signedUrl,
          fileName: document.name,
        });
        // this.setState({ loading: false });
      } else {
        const granted = await requestWritePermission();
        if (granted === true) {
          const {config, fs} = RNFetchBlobSingleton;
          const DownloadDir = fs.dirs.DownloadDir;
          const options = {
            fileCache: true,
            addAndroidDownloads: {
              useDownloadManager: true,
              notification: true,
              mime: document.contentType,
              title: document.name + ' has been downloaded',
              description: 'File downloaded by file manager',
              mediaScannable: true,
              path: DownloadDir + '/LeapIn/' + document.name,
            },
          };
          config(options)
            .fetch('GET', document.signedUrl)
            .then(res => {});
          this.setState({loading: false});
        }
      }
    }
  };

  getFileExtension = (filename: any) => {
    const fileType: any = [];
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : fileType;
  };
  _showAttachmentModal = () => {
    this.setState({
      attachmentVisible: true,
    });
  };

  _closeAttachmentModal = () => {
    this.setState({
      attachmentVisible: false,
    });
  };
  _renderPreAuthItems = () => {
    return (
      <ServiceAgreementList
        titles={messages.TABLE_TITLES}
        data={this.itemDetails.preauthorization}
      />
    );
  };

  _renderSpentBar = () => {
    const budgetSpent = {
      total: this.itemDetails.totalBudget,
      unspent: this.itemDetails.remainingBudget,
      spent: this.itemDetails.usedBudget,
    };

    return (
      <View style={[CommonStyles.ServiceAgreementsDetailsHMB20H]}>
        <SimpleSpendingBar
          budget={budgetSpent}
          budgetColors={RED_BUDGET}
          sortOrder={[types2.BUDGET_KEYS.SPENT, types2.BUDGET_KEYS.UNSPENT]}
        />
      </View>
    );
  };
}

const mapStateToProps = (state: any) => ({
  navigationParams: state.NavigationParamsReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    navigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceAgreementsDetails);
