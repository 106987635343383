/*
 * Author: Andrew Seeley
 * Date: 25/01/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a webviewer screen - you can pass in a uri and it will open it up.
 */

import React, { Component } from 'react';
import { Image, Platform, View } from 'react-native';
//import { WebView as WebViewMobile } from 'react-native-webview';

import { StandardButton } from '../Components/Atoms';


import { CommonActions } from '@react-navigation/native';

// Import Stylesheets
import BrandStyles from '../Styles/BrandStyles';
import CommonStyles from '../Styles/CommonStyles';

//import { WebView as WebViewWeb } from 'react-native-webview';
import {WebViewWeb as WebView} from 'helpers/ReactNativeWebview';
import { OfflineNotice } from '../Components/Organisms';
import * as types from '../Constants/Constants';
import { closeIcon } from '../assets/images/index';

let url: any, html: any;
class WebViewer extends Component {
  render() {
    url = this._getParams('url');
    html = this._getParams('html');

    return (
      <View style={CommonStyles.screenWrapper}>
        <View
          style={[
            CommonStyles.webViewHeader,
            CommonStyles.flexRow,
            BrandStyles.primaryBgColor1,
          ]}
        >
          {/* Remove URL from WebViewer */}
          {/* <StandardText style={[CommonStyles.textAlignVerticalCenter, CommonStyles.customFont, CommonStyles.bodyText, CommonStyles.webViewText, BrandStyles.TextColor5]} numberOfLines={1}>{url}</StandardText> */}
          <View
            style={[
              CommonStyles.closeIconPosition,
              CommonStyles.justifyCenter,
              {paddingTop: 0},
            ]}
          >
            <StandardButton
              accessibilityLabel="Close viewer"
              onPress={this.closeView.bind(this)}
            >
              <Image source={closeIcon} style={CommonStyles.closeIconImage} />
            </StandardButton>
          </View>
        </View>
        <OfflineNotice />
        {this._renderWebView()}
      </View>
    );
  }
  _getParams = (key: any) => {
    if(types.isWeb) {
      return this.props.navigation.getParam(key, undefined) || undefined;
     }
    return this.props.route.params? this.props.route.params[key] : undefined;
  };
  _renderWebView() {
    if (html !== undefined && html !== '') {
      if(Platform.OS==='web'){
        return <WebView style={{width: '100%', height:'100%'}} title="MeetingChecklist" source={html} srcDoc={true} />
      }
      return <WebView source={html} srcDoc={true}  javaScriptEnabled domStorageEnabled />;
    }
    return <Image source={{uri:url}} style={{flex:1,resizeMode:'contain'}} />;
  }

  closeView() {
    this.props.navigation.dispatch(CommonActions.goBack());
  }
}

export default WebViewer;
