// This file contain all the content related to PlanManagement Option Learn More Screens.

export const LEARN_MORE_PLAN_MANAGEMENT = {
  OPTION_TITLE: 'Option 1 - Plan Management',
  HEADER_TITLE: 'Plan Management',
  NOTICE:
    'When you’re plan managed, the NDIS pays someone you choose, to help you manage your money and pay your bills… think of it like your own NDIS funded book keeper or accountant with a support team.',
  HEADINGS: [
    {
      MAIN_HEADING: 'What it looks like',
      SUB_HEADING: '',
      BULLETS: [
        'All of the good bits of being self managed without having to worry about budgeting, record keeping and paperwork.',
        'The NDIS adds extra funds in your plan to cover the cost of your plan manager.',
        'Your invoices can go direct to your plan manager who coordinates claiming and payment direct with the NDIS and your providers.',
      ],
    },
    {
      MAIN_HEADING:
        'Answer yes to these three questions and Plan Management is for you:',
      SUB_HEADING: '',
      BULLETS: [
        'Do you want to buy supports from non-NDIS registered providers (like Jim’s Mowing, therapists or individual support workers who have not registered or community organisations)?',
        'Would you like to have the choice and control of being self managed without the administrative obligations?',
        'Would you like someone to assist you to find supports, set up service agreements, track goals and you make the most of your plan?',
      ],
    },
  ],

  PROS_AND_CONS: {
    HEADING: 'Compare the pros and cons',
    PROS: [
      'The NDIS adds extra funds in your plan to cover the cost of your plan manager.',
      'Your invoices can go direct to your plan manager who coordinates claiming and payment direct with the NDIS and your providers.',
      'You have complete control of funds.',
      'You can choose any provider to help you meet your goals (registered or not).',
      'You have the power to approve each payment from your plan to providers.',
      'Billing issues and questions are resolved for you.',
      'No book-keeping or financial tracking.',
      'No upfront costs.',
      'No separate bank account required.',
      'No audits.',
    ],
    CONS: [
      'You can’t have services with prices set above the NDIA price guide.',
    ],
  },
};

export const LEARN_MORE_SELF_MANAGED = {
  OPTION_TITLE: 'Option 2 - Self Management',
  HEADER_TITLE: 'Self Management',
  NOTICE:
    'The hands-on option where you look after all your own invoices and keep all your own records.',
  HEADINGS: [
    {
      MAIN_HEADING: 'What it looks like',
      SUB_HEADING: '',
      BULLETS: [
        'You can use your funds for any providers or mainstream supports and services (they don’t have to be NDIS registered providers or even disability specific providers).',
        'You’ll need to open a new bank account just for the NDIS.',
        'You’ll need to use a computer or fill in forms to get the money paid back to your bank account for your supports.',
        'You need to keep full records of all invoices and receipts.',
      ],
    },
  ],

  PROS_AND_CONS: {
    HEADING: 'Compare the pros and cons',
    PROS: [
      'You have complete control of funds.',
      'You have the power to approve each payment from your plan to providers.',
      'You can choose any provider to help you meet your goals.',
      'You can pay higher rates for valued staff.',
    ],
    CONS: [
      'You need to be good with finances and budgeting.',
      'You may have to pay upfront at times and wait to be reimbursed.',
      'You need to be organised and keep good records.',
      'You can be audited.',
    ],
  },
};

export const LEARN_MORE_NDIA_MANAGEMENT = {
  OPTION_TITLE: 'Option 3 - NDIA Management',
  HEADER_TITLE: 'NDIA Management',
  NOTICE:
    'Your registered service providers are paid directly by the NDIA (the Agency) after the services are provided.',
  HEADINGS: [
    {
      MAIN_HEADING: 'What it looks like',
      SUB_HEADING: '',
      BULLETS: [
        'You can only use NDIS registered providers for supports and services with your NDIS funds. Invoices are submitted direct to the NDIS after the services has been delivered and then the NDIS pays them directly.',
        "You can check in on the MyPlace NDIS web portal to see how you're tracking with your funds, see what has been spent and what is remaining.",
        "Being agency managed offers the least amount of choice and control, however is a good option for people who don't require much flexibility and want to only use registered providers.",
      ],
    },
  ],

  PROS_AND_CONS: {
    HEADING: 'Compare the pros and cons',
    PROS: [
      'No book-keeping or financial tracking.',
      'No upfront costs.',
      'No seperate bank account required.',
      'No audits.',
    ],
    CONS: [
      "No option to choose disability or mainstream providers who aren't NDIS registered.",
      'Less control and choice for how to spend your funds.',
      'Can be inflexible (registered support worker agenices may have less flexiblility than non-registered ones).',
    ],
  },
};

export const MANAGED_OPTIONS = [
  {
    OPTION_KEY: 'plan_managed',
    SUB_OPTIONS: [
      {
        SUB_OPTION_KEY: 'leap_in',
        SUB_OPTION_INDEX: 0,
        SUB_OPTION_TITLE: 'want to choose Leap in! for plan management',
      },
      {
        SUB_OPTION_KEY: 'other',
        SUB_OPTION_INDEX: 1,
        SUB_OPTION_TITLE: "haven't decide yet or want another plan manager",
      },
    ],
    OPTION_TITLE: 'Option 1 - Plan Management',
    OPTION_DESC:
      'Here, the NDIS pays your Plan Manager, to help you manage your money and pay your bills. Think of it like your own NDIS funded book keeper or accountant with a support team.',
  },
  {
    OPTION_KEY: 'self',
    SUB_OPTIONS: [],
    OPTION_TITLE: 'Option 2 - Self Management',
    OPTION_DESC:
      'This is the hands-on option where you look after all your own invoices and keep all your own records.',
  },
  {
    OPTION_KEY: 'agency',
    SUB_OPTIONS: [],
    OPTION_TITLE: 'Option 3 - NDIA Management',
    OPTION_DESC:
      "When you're Agency Managed, your registered services providers are paid directly by the NDIA (the Agency) after the services are provided.",
  },
];

export const PLAN_MANAGED_POP_UP = {
  POP_UP_TITLE: 'Select Leap in or other plan manager request',
  OPTIONS: [
    'want to choose Leap in! for plan management',
    "haven't decide yet or want another plan manager",
  ],
};

export const NOTICE1 =
  "There are three ways to manage your NDIS funds. You'll need to choose one before your NDIS Plan Meeting.";
export const NOTICE2 =
  'Choose your preferred option below to having it included in your Plan Summary report to help your discussion.';
export const COMPARE_PROS_CONS = 'Compare the pros and cons';
export const PROS = 'Pros';
export const CONS = 'Cons';
export const POPUP_WIDTH_TABLET_LANDSCAPE = '65%';
export const POPUP_WIDTH_TABLET_PORTRAIT = '80%';
