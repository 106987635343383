/*
 * Author: Nirodha Perera
 * Date: 10/12/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The goal preview component to display selected
 * goal details in goal preview summary
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View} from 'react-native';

import {GOAL_SCREEN_LABELS, FILE_TYPE} from '../../Constants/Constants2';
import {
  ACCESS_READ_ONLY,
  ACTION_BUTTON,
  ACCESS_FULL,
} from '../../Constants/Constants';

import {StandardText, StandardTouchableOpacity} from '../Atoms';
import {SummaryTile, PhotoTile, ActionButton} from '../Molecules';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class GoalPreview extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <StandardTouchableOpacity
        activeOpacity={0.8}
        onPress={this.props.onPress}
        accessibilityLabel={GOAL_SCREEN_LABELS.ADD_GOAL}
        style={[
          CommonStyles.containerGoalDetails,
          BrandStyles.primaryBgColor1,
          CommonStyles.paddingLeft20,
          CommonStyles.paddingRight20,
          CommonStyles.marginBottom20,
        ]}
      >
        <View style={CommonStyles.flexDirectionRow}>
          <View style={CommonStyles.oneContainer}>
            <View style={CommonStyles.flexDirectionRow}>
              <PhotoTile
                borderRadius={CommonStyles.borderRadius15}
                style={StyleSheet.flatten(
                  CommonStyles.containerPhotoTileGoalImage,
                )}
                imageGap={5}
                bottomMargin={false}
                flexRatio={CommonStyles.flex1}
                apiFileType={FILE_TYPE.GOAL_PHOTO}
                imageObject={this.props.goalItem.goalPhoto}
                showCaption={false}
                actionCallback={this.props.actionCallback}
                actionData={this.props.goalItem}
                clickable={true}
                readOnly={
                  this.props.readOnly == true ? ACCESS_READ_ONLY : ACCESS_FULL
                }
                rightMargin={false}
                lastUpdate={this.lastUpdateTime(this.props.goalItem)}
              />
              <View style={[CommonStyles.flex1, CommonStyles.marginTop10]}>
                {this.renderGoalName()}
                {this.renderGoalSteps()}
              </View>
            </View>
            {this.renderActionButtons()}
          </View>
        </View>
      </StandardTouchableOpacity>
    );
  }

  lastUpdateTime = (goalItem: any) => {
    if (
      goalItem.goalPhoto !== undefined &&
      goalItem.imageUploadObject === undefined &&
      goalItem.goalPhoto.lastModified
    ) {
      return new Date(goalItem.goalPhoto.lastModified);
    } else {
      return new Date();
    }
  };

  renderActionButtons = () => {
    return (
      <View
        style={[CommonStyles.paddingTop10, CommonStyles.justifyFlexRowSpace]}
      >
        <View>
          {!this.props.readOnly ? (
            <ActionButton
              actionType={ACTION_BUTTON.DELETE}
              isToucable={true}
              onPress={this.props.deleteGoal}
              containerStyle={[
                CommonStyles.flexDirectionRow,
                CommonStyles.alignItemsCenter,
              ]}
            />
          ) : null}
        </View>
        <View>
          <ActionButton
            actionType={
              this.props.readOnly ? ACTION_BUTTON.VIEW : ACTION_BUTTON.EDIT
            }
            isToucable={true}
            onPress={this.props.onPress}
            containerStyle={[
              CommonStyles.flexDirectionRow,
              CommonStyles.alignItemsCenter,
            ]}
          />
        </View>
      </View>
    );
  };

  renderGoalName = () => {
    const goalItem = this.props.goalItem;
    if (goalItem.goalName) {
      return (
        <StandardText
          style={[
            CommonStyles.SummaryTileText,
            BrandStyles.TextColor5,
            CommonStyles.customFontBold,
          ]}
        >
          {goalItem.goalName}
        </StandardText>
      );
    } else {
      return null;
    }
  };

  renderGoalSteps = () => {
    const goalItem = this.props.goalItem;
    let currentGoalSteps = goalItem.currentGoalSteps;
    if (currentGoalSteps === undefined) {
      return null;
    } else {
      currentGoalSteps = currentGoalSteps.slice(0, 4);
      let counter = 1;
      let displayString = [];
      for (let i = 0; i < currentGoalSteps.length; i++) {
        displayString.push({
          index: counter,
          string: currentGoalSteps[i].stepName,
        });
        counter++;
      }

      return displayString.map(item => (
        <View style={CommonStyles.flexDirectionRow}>
          <View style={CommonStyles.flexDirectionColumn}>
            <StandardText
              style={[
                CommonStyles.SummaryTileText,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
              ]}
            >
              {item.index + '. '}
            </StandardText>
          </View>
          <View style={CommonStyles.flexDirectionColumn}>
            <StandardText
              style={[
                CommonStyles.SummaryTileText,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
                CommonStyles.justifyContentFlexStart,
              ]}
            >
              {item.string}
            </StandardText>
          </View>
        </View>
      ));
    }
  };
}

export default GoalPreview;
