/*
 * Author: Brad D'Costa
 * Date: 29/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the Summary Tile Header with a checkbox.
 */

import React, {Component} from 'react';
import {Image, View, StyleSheet} from 'react-native';
import {StandardTouchableOpacity, StandardText} from '../Atoms';
import {ActionButton} from './';

import {TickWhite} from '../../assets/images/vector-icons';
import CustomIcon from '../../assets/images/CustomIcon';

// Import Stylesheets
import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';

class SummaryTileCheckboxHeader extends Component {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    // this.setState({data: this.props.data})
  }

  render() {
    if (this.props.showButton) {
      return (
        <StandardTouchableOpacity
          accessibilityLabel={
            this.props.accessibilityLabel + ' ' + this.props.actionType
          }
          activeOpacity={0.6}
          onPress={this.props.onPress}
        >
          {this._mainComponent()}
        </StandardTouchableOpacity>
      );
    } else {
      return this._mainComponent();
    }
  }

  _mainComponent = () => {
    return (
      <View style={this._determineContainerStyle()}>
        <View style={[CommonStyles.flexDirectionRow]}>
          {this._renderImage(this.props.imageSource)}
          <StandardText style={this._determineTextStyle()}>
            {this.props.displayString}
          </StandardText>
        </View>
        {this._renderActionButton()}
      </View>
    );
  };

  _renderActionButton = () => {
    if (this.props.readOnly) {
      return this._commonRender();
    } else {
      return (
        <StandardTouchableOpacity
          accessibilityLabel={''}
          activeOpacity={0.6}
          onPress={() => {
            this._toggleCheckbox();
          }}
        >
          {this._commonRender()}
        </StandardTouchableOpacity>
      );
    }
  };

  _commonRender = () => {
    return (
      <View
        style={[CommonStyles.flexDirectionRow, CommonStyles.alignItemsCenter]}
      >
        {this._renderDisplayText()}
        <View style={this._determinCheckboxContainerStyle()}>
          <CustomIcon
            style={[CommonStyles.CheckBoxButtonIcon]}
            name={this._determineCheckboxIcon()}
          />
        </View>
      </View>
    );
  };

  _renderDisplayText = () => {
    if (
      this.props.data.selected ||
      (this.props.data.selected && this.props.readOnly)
    ) {
      return (
        <StandardText
          style={[
            CommonStyles.customFontMedium,
            CommonStyles.SummaryTileHeaderButtonText,
            BrandStyles.TextColor5,
            {marginRight: 15},
          ]}
        >
          {this.props.data.displayText}
        </StandardText>
      );
    } else {
      return null;
    }
  };

  _renderImage = (imageSource: any) => {
    if (this.props.imageSource != undefined) {
      return (
        <Image
          accessible={false}
          style={[CommonStyles.titleBoxIcon, CommonStyles.titleBoxIconMargin]}
          source={imageSource}
        />
      );
    } else {
      return null;
    }
  };

  _determineTextStyle = () => {
    var styles = [
      this.props.secondLevel
        ? CommonStyles.customFontSemiBold
        : CommonStyles.customFontBold,
      CommonStyles.SummaryTileHeaderText,
      CommonStyles.textAlignVerticalBottom,
    ];
    if (this.props.containsData) {
      styles.push(BrandStyles.headerContainsDataText);
    } else {
      styles.push(BrandStyles.TextColor5);
    }
    return styles;
  };

  _determineContainerStyle = () => {
    var styles = [
      CommonStyles.flexDirectionRow,
      CommonStyles.justifyContentSpaceBetween,
      CommonStyles.content,
      CommonStyles.SummaryTileHeaderContainer,
    ];
    if (this.props.containsData) {
      styles.push(BrandStyles.headerContainsDataBorder);
    } else {
      styles.push(BrandStyles.borderColor5);
    }
    return styles;
  };

  _determinCheckboxContainerStyle = () => {
    var styles = [
      CommonStyles.flexDirectionRow,
      CommonStyles.alignItemsCenter,
      CommonStyles.CheckBoxButtonContainer,
      CommonStyles.alignSelfCenter,
      CommonStyles.margin5,
    ];
    if (this.props.data.selected) {
      if (this.props.readOnly) {
        styles.push([BrandStyles.readOnlyCheckboxGrey]);
      } else {
        styles.push([BrandStyles.primaryBgColor3]);
      }
    } else {
      styles.push([CommonStyles.CheckBoxButtonNoIcon, CommonStyles.elevation5]);
      if (this.props.readOnly) {
        styles.push([BrandStyles.readOnlyTextfieldGrey]);
      } else {
        styles.push([BrandStyles.primaryBgColor1]);
      }
    }

    return styles;
  };

  _determineCheckboxIcon = () => {
    if (this.props.data.selected) {
      return TickWhite;
    }
  };

  _toggleCheckbox = () => {
    var tempObj = JSON.parse(JSON.stringify(this.props.data));
    tempObj.selected = !tempObj.selected;
    if (tempObj.selected) {
      tempObj.displayText = 'Selected';
    } else {
      tempObj.displayText = 'Select';
    }
    this.props.onChange(tempObj);
  };
}

export default SummaryTileCheckboxHeader;
