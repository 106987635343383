/*
 * Author: Declan Hart
 * Date: 15/07/2019
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays a title and expand/collapse button.
 * clicking the expand/collapse button will show/hide the Notes within the tile
 */

import React, {Component} from 'react';
import {Image, View} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import * as messages from '../../Constants/Messages';

import {StandardText, StandardTouchableOpacity} from '../Atoms';
import {GridView, InvoiceNotes, InvoiceDetailsTile} from './';
import {formatDDMMMYYDate} from '../../Helpers/Utils';

import {
  arrowCollapseCharcoal60,
  arrowExpandCharcoal60,
  GreyClockIcon3x,
  approvalCharcoal,
  editIconCharcoal60,
  emailIcon,
  addIconGrey,
  editIcon,
} from '../../assets/images';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

const SHOW_NOTES = 'Show notes';
const HIDE_NOTES = 'Hide notes';
const CUT_OFF_PADDING = 75;
const TWO_COLUMNS = 2;
const ONE_COLUMN = 1;
const DATE_TYPE = 'date';
const STRING_TYPE = 'string';

class AccordianInvoiceNotes extends Component {
  state = {
    collapsed: types.isWeb ? false : true,
  };

  constructor(props: any) {
    super(props);
  }

  setDimesions(layout: any) {
    const {x, y, width, height} = layout;
    this.setState({
      viewWidth: width,
    });
  }

  render() {
    if (!this.props.invoiceDetails.memberNote && !this.props.isEmployee) {
      return null;
    }

    return (
      <View>
        <StandardTouchableOpacity
          accessibilityLabel={this._determineLabel()}
          activeOpacity={0.6}
          onPress={() => {
            this._toggleAccordian();
          }}
        >
          <View
            style={[
              CommonStyles.flex1,
              CommonStyles.flexRow,
              this.props.leftAlignContent ? null : CommonStyles.justifyEnd,
              CommonStyles.alignItemsCenter,
              CommonStyles.paddingTop10,
            ]}
          >
            {this.props.isEmployee ? (
              <Image style={CommonStyles.containerClock} source={editIcon} />
            ) : null}
            <StandardText
              style={[
                CommonStyles.customFont,
                BrandStyles.TextColor10,
                types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
                CommonStyles.marginRight10,
              ]}
            >
              {this.state.collapsed ? SHOW_NOTES : HIDE_NOTES}
            </StandardText>

            <View
              style={[
                CommonStyles.flexDirectionRow,
                CommonStyles.alignItemsCenter,
              ]}
            >
              <Image
                style={[CommonStyles.accordianButtonIcon]}
                source={this._determineIcon()}
              />
            </View>
          </View>
        </StandardTouchableOpacity>
        <View
          style={[
            CommonStyles.paddingTop10,
            CommonStyles.paddingBottom10,
            types.isWeb ? CommonStyles.alignItemsCenter : null,
          ]}
        >
          {this._renderChildren()}
        </View>
      </View>
    );
  }

  _renderChildren = () => {
    let titles = messages.APPROVALS_INVOICE_DETAILS;
    if (this.state.collapsed) {
      return null;
    } else {
      let outerViewWidth = 0;
      return (
        <View
          style={this._determineGridContainerStyle()}
          onLayout={event => {
            outerViewWidth = event.nativeEvent.layout.width;
          }}
        >
          <GridView
            viewWidth={
              types.isWeb
                ? this.props.viewWidth - CUT_OFF_PADDING
                : outerViewWidth
            }
            isMobile={this.props.isMobile}
            maximumColumns={this.props.isEmployee ? TWO_COLUMNS : ONE_COLUMN}
            isStatic={true}
          >
            {this.props.invoiceDetails.memberNote || this.props.isEmployee ? (
              <InvoiceDetailsTile
                title={
                  this.props.isEmployee
                    ? titles.NOTES_FOR_MEMBER_STAFF
                    : titles.NOTES_FOR_MEMBER
                }
                contents={this._renderNote(
                  this.props.invoiceDetails.memberNote,
                  types2.INVOICE_NOTES.MEMBER_NOTE,
                )}
              />
            ) : null}
            {this.props.isEmployee ? (
              <InvoiceDetailsTile
                title={titles.WORK_NOTES}
                contents={this._renderNote(
                  this.props.invoiceDetails.workNote,
                  types2.INVOICE_NOTES.WORK_NOTE,
                )}
              />
            ) : null}
          </GridView>
        </View>
      );
    }
  };

  _determineGridContainerStyle = () => {
    let styles: any = [];
    if (types.isWeb && this.props.isMobile) {
      styles = [CommonStyles.marginRight25];
    }
    return styles;
  };

  _determineIcon = () => {
    if (this.state.collapsed) {
      return arrowExpandCharcoal60;
    } else {
      return arrowCollapseCharcoal60;
    }
  };

  _determineLabel = () => {
    if (this.state.collapsed) {
      return SHOW_NOTES;
    } else {
      return HIDE_NOTES;
    }
  };

  _renderNote = (note: any, type: any) => {
    return (
      <InvoiceNotes
        note={note}
        readOnly={!this.props.isEmployee || this.props.readOnly}
        noteType={type}
        editInvoiceNotes={(note: any, type: any) =>
          this.props.updateNote(note, type)
        }
      />
    );
  };

  _toggleAccordian = () => {
    let flag = !this.state.collapsed;
    if (this.props.callback) {
      this.props.callback(flag);
    }
    this.setState({collapsed: flag});
  };
}

export default AccordianInvoiceNotes;
