/*
 * Author: Shelan Cooray
 * Date: 06/03/2019
 * Copyright © 2019 Leap in!. All rights reserved.
 *
 * This screen is to facilitate members on web responsive mobile mode
 */

import React, {Component} from 'react';
import {View, Text} from 'react-native';
import {connect} from 'react-redux';
import {WebMembers} from '../Components/Organisms';

class WebMembersScreen extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <View>
        <WebMembers
          {...this.props}
          user={this.props.user}
          nav={this.props.navigation}
        />
      </View>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
});

export default connect(mapStateToProps)(WebMembersScreen);
