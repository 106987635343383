/*
 * Author: Brad D'Costa
 * Date: 26/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Budgets and Payments Screen.
 */

import React, {Component} from 'react';
import {
  View,
  FlatList,
  StyleSheet,
  ScrollView,
  Image,
  Linking,
} from 'react-native';

import {StandardText} from '../Components/Atoms';
import {
  Container,
  CrewNoAccess,
  SummaryTile,
  BudgetLocked,
  BrandActivityIndicator,
} from '../Components/Molecules';
import {OfflineNotice, PreviousPlans} from '../Components/Organisms';
import {BudgetsPaymentsView} from './';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {BUDGETS_AND_PAYMENTS_SCREEN as messages} from '../Constants/Messages';
import {MY_BUDGETS} from '../Constants/Messages';

// import { LILogo } from '../assets/images';

import {
  NextArrowCharcoal,
  PaymentHistoryRed3x,
  InvoiceDollarRed2x,
} from '../assets/images/vector-icons';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {BrandColors} from '../Styles/Colours';

import {setServiceAgreementFeature, getDeepLinkParams} from '../Helpers/Utils';
// import DeviceInfo from 'react-native-device-info';
import DeviceInfo from 'react-native-device-info';

import {callAPIs} from '../API/APICaller';
import {getMember} from '../API/MemberAPI';
import {
  getTransactionHistory,
  getBudget,
  getDraftInvoices,
  getPlans,
} from '../API/PlanDataAPI';
import {getBudgetCategories} from '../API/ReferenceAPI';

import {
  filterBudgetCategories,
  hasBudgetAccess,
} from '../Helpers/determineAnyBudgetAccess';
import * as BudgetActions from '../Actions/BudgetActions';
import * as PayInvoiceActions from '../Actions/PayInvoiceActions';
import * as MemberActions from '../Actions/MemberActions';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {getValue, removeItem} from '../API/WebPersistenceStore';

import {PM_SIGN_UP_URL} from '../environments';

const navTypeSA = 'ServiceAgreement';
const PREVIOUS_PLANS = 'PreviousPlans';

const PLAN_RESULT_INDEX = {
  BUDGETS: 0,
  BUDGET_CATEGORIES: 1,
  DRAFT_INVOICES: 2,
  PLANS: 3,
};
class BudgetsPayments extends Component {
  state = {
    saveAPIError: false,
    refreshing: false,
    isPmExpired: this.props.budgets.isPmExpired,
    planStatus: '',
  };
  linking: any;

  constructor(props: any) {
    super(props);
    this._createMainContents.bind(this);
    this._isLeapInSelected.bind(this);
    this._renderContents.bind(this);
    this._navigateToMoreInformation.bind(this);
    this._renderInstructionPopUp.bind(this);
    this.fetchData.bind(this);
    this.onRefresh.bind(this);
  }

  // screenListenerFocus = null;
  // screenListenerBlur = null;

  componentWillUnmount() {
    // if (this.screenListenerFocus != null) {
    //   this.screenListenerFocus.remove();
    // }

    // if (this.screenListenerBlur != null) {
    //   this.screenListenerBlur.remove();
    // }

  //  this.linking.remove();
  }

  handleOpenURL = (event: any) => {
    this.navigate(event.url);
  };

  navigate = (url: any) => {
    if (url) {
      const paramValues = getDeepLinkParams(url);
      const navType = paramValues['type'];
      if (
        navType === types2.DEEP_LINK_NAV_TYPES.SA ||
        navType === types2.DEEP_LINK_NAV_TYPES.INVOICE
      ) {
        const itemId = paramValues['id'];
        if (itemId !== '') {
          this.props.actions.NavigationParamActions.setParam({
            data: itemId,
          });
          this.props.navigation.navigate(types2.ApprovalsSummary);
        } else {
          this.props.navigation.navigate(
            types2.DEEP_LINK_SCREENS.APPROVAL_SUMMARY,
          );
        }
      }
      if (navType === types2.DEEP_LINK_NAV_TYPES.PAYMENT_HISTORY) {
        this.props.navigation.navigate(
          types2.DEEP_LINK_SCREENS.PAYMENT_HISTORY,
        );
      }
      if (navType === types2.DEEP_LINK_NAV_TYPES.PREVIOUS_PLANS) {
        this.props.navigation.navigate(types2.DEEP_LINK_SCREENS.PREVIOUS_PLANS);
      }
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (
      nextProps.tabBar.selectedTab !== this.props.tabBar.selectedTab &&
      this._isPlanExpired()
    ) {
      this.setState({planStatus: types2.PLAN_STATUS_DATA.COMPLETED});
      this._updateMember();
    }

    if (
      this._isMemberOnBoarded() &&
      nextProps.tabBar.selectedTab !== this.props.tabBar.selectedTab &&
      nextProps.member.planStatus === types2.PLAN_STATUS_DATA.PM_ACTIVE &&
      this.state.planStatus === types2.PLAN_STATUS_DATA.COMPLETED
    ) {
      this.setState({
        planStatus: types2.PLAN_STATUS_DATA.PM_ACTIVE,
        loading: true,
      });
      this.fetchData();
    }
  }

  componentDidMount() {
    if (this._isLeapInAndOnboardedActive()) {
      this.setState({loading: true});
      this.fetchData();
    }

    if (!types.isWeb) {
      Linking.getInitialURL().then(url => {
        this.navigate(url);
      });

      this.linking=Linking.addEventListener('url', this.handleOpenURL);
    }
  }

  render() {

    if (!this.props.member.id && this.props.user.isCrew) {
      return (
        <View style={[CommonStyles.content]}>
          <OfflineNotice />
          <CrewNoAccess />
        </View>
      );
    }

    if (types.isWeb && !this.props.loadedMemberId.loadedMemberId) {
      return (
        <View style={[CommonStyles.content]}>
          <OfflineNotice />
          <CrewNoAccess />
        </View>
      );
    }

    if (this._isNoAccess()) {
      return (
        <View style={[CommonStyles.content]}>
          <OfflineNotice />
          <CrewNoAccess
            customText={
              this.props.member.firstName +
              ' has not given you access to this information. Please contact them if you wish to have access.'
            }
          />
        </View>
      );
    }

    if (types.isWeb) {
      return (
        <View>
          <BrandActivityIndicator loading={this.props.loading} />
          {this._createMainContents()}
        </View>
      );
    }

    return (
      <View style={[CommonStyles.content]}>
        <Container
          contents={this._createMainContents}
          ignorePadding={this._determineContainerPadding()}
          loading={this.state.loading}
          sidebarMode={types2.SIDEBAR_MODE.PLAN_MANAGEMENT}
          needsSidebar={this._isLeapInAndOnboardedActive()}
          screenType={types.SCREEN_TYPE_MAIN}
          hideBackButton={!this.props.navigation?.state?.params}
          nav={this.props.navigation}
          headerTitle={types2.NAVIGATION_TEXT.MY_BUDGETS}
          toggleMenu={this.props.screenProps?.toggleMenu}
          getInitialMenuState={this.props.screenProps?.getInitialMenuState}
          removeExtraOffset={
            !this._isMemberOnBoarded() || this._isPlanExpired()
          }
          removeBottomPadding={true}
          onRefresh={this._isLeapInAndOnboardedActive() && this.onRefresh}
          refreshing={
            this._isLeapInAndOnboardedActive() && this.state.refreshing
          }
          demoProfile={this.props.user.demoProfile}
          showConfirmLogout={
            this.props.actions.LogoutConfirmationActions.showConfirmLogout
          }
          isBudgetUnderMaintenanceStatus={
            this.props.budgets.isBudgetUnderMaintenanceStatus
          }
        />
      </View>
    );
  }

  onRefresh = () => {
    this.setState({refreshing: true});
    this.fetchData();
  };

  fetchData = () => {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    let callBudgets = false;
    // Call API to get transaction history
    const callbackFunction = (results: any) => {
      if (results[PLAN_RESULT_INDEX] != undefined) {
        if (
          results[PLAN_RESULT_INDEX.BUDGETS].statusCode ==
          types2.NO_PLANS_ERROR_CODE
        ) {
          callBudgets = false;
        }
      }
      if (callBudgets) {
        const budgetsPayload = results[PLAN_RESULT_INDEX.BUDGETS].content;
        // Check if Service Agreement feature is disabled from the LIBE
        if (budgetsPayload.isServiceAgreementsEnabled != null) {
          if (types.isWeb) {
            setServiceAgreementFeature(
              budgetsPayload.isServiceAgreementsEnabled,
            );
          } else {
            this.props.actions.MemberActions.actionSetServiceAgreementEnabled(
              budgetsPayload.isServiceAgreementsEnabled,
            );
          }
        }

        const budgetsCategories =
          results[PLAN_RESULT_INDEX.BUDGET_CATEGORIES].content;
        this.props.actions.BudgetActions.actionGetBudgets(budgetsPayload);
        this.props.actions.BudgetActions.actionGetBudgetCategories(
          filterBudgetCategories(budgetsCategories),
        );
        const draftInvoicesPayload =
          results[PLAN_RESULT_INDEX.DRAFT_INVOICES].content;
        this.props.actions.PayInvoiceActions.setDraftInvoicesCount(
          draftInvoicesPayload.length,
        );
      }

      if (results[PLAN_RESULT_INDEX.PLANS]) {
        const plans = results[PLAN_RESULT_INDEX.PLANS].content;
        this.props.actions.BudgetActions.actionAddPreviousPlans(plans);
      }
      if (types.isWeb) {
        this.props.setLoading(false);
        if (this._isFromPlansDeepLink()) {
          this.props.navigation.navigate(
            types2.DEEP_LINK_SCREENS.PREVIOUS_PLANS,
          );
          removeItem(types2.BOOK_MARK_PREVIOUS_PLANS);
        }
      }
      this.setState({loading: false, refreshing: false});
    };

    const APIArray = [];

    //Do checks for budgets first and then push to APIArray if needed
    if (this._fetchBudgets()) {
      const planKey =
        this.props.navigationParams?.params &&
        this.props.navigationParams?.params.planKey
          ? this.props.navigationParams?.params.planKey
          : null;
      APIArray.push(
        getBudget(this.props.member.id, this.props.user.demoProfile, planKey),
      );
      APIArray.push(getBudgetCategories());
      // TODO: Pagitionation for draft invoices
      APIArray.push(
        getDraftInvoices(
          this.props.loadedMemberId.loadedMemberId,
          this.props.user.demoProfile,
          // searchQuery,
          // pageNumber,
          // pageSize,
          false,
        ),
      );
      APIArray.push(
        getPlans(this.props.member.id, this.props.user.demoProfile),
      );

      callBudgets = true;
    } else {
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({loading: false, refreshing: false});
    }

    callAPIs(APIArray, callbackFunction, null, () => {
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({loading: false, refreshing: false});
    });
  };

  /**
   * This function will check whether the budgetAPI should be called based on the data returned from the memberAPI
   */
  _fetchBudgets = () => {
    if (hasBudgetAccess(this.props.member)) {
      const memberObj = this.props.member;
      const userObj = this.props.user;

      // fetch budget of the selected person if logged in user is a employee
      if (
        this._isPMExit() &&
        (userObj.isEmployee === true || userObj.isFinancialStaff === true)
      ) {
        return true;
      }

      if (
        memberObj.memberStatus === types2.MEMBER_STATUS_DATA.MANAGED &&
        (memberObj.planStatus === types2.PLAN_STATUS_DATA.PM_ACTIVE ||
          memberObj.planStatus === types2.PLAN_STATUS_DATA.COMPLETED)
      ) {
        return true;
      }
    }
  };

  _updateMember = () => {
    this.setState({refreshing: true});
    const callbackFunction = (data: any) => {
      const member = data[0].content;
      this.props.actions.MemberActions.actionGetMember(
        member,
        this.props.user.id,
        this.props.user.isEmployee,
        this.props.user.isFinancialStaff,
      );

      this.setState({
        selectedLifeStages: this.props.member.lifeStages,
        loading: false,
        refreshing: false,
      });
    };
    callAPIs(
      [getMember(this.props.loadedMemberId.loadedMemberId)],
      callbackFunction,
      null,
      () => {
        this.setState({loading: false, refreshing: false});
      },
    );
  };

  _renderContents = () => {
    // Member has selected leap in to be their manager and they have been onboarded
    if (this._isLeapInAndOnboardedActive()) {
      let navigationParams = this.props.navigationParams?.params;
      if (!this.props.navigationParams?.params) {
        navigationParams = {
          planStatus: this.state.planStatus,
          planClaimUntilDate: '',
        };
      }

      return (
        <BudgetsPaymentsView
          viewWidth={this.props.viewWidth}
          isMobile={this.props.isMobile}
          navigationParams={navigationParams}
          navigation={this.props.navigation}
        />
      );
    }

    return null;
  };

  _isFromPlansDeepLink = () => {
    if (types.isWeb && JSON.parse(getValue('bookMarkPreviousPlans')) === true) {
      return true;
    }
  };

  _createMainContents = () => {
    if (
      (this._isMemberOnBoarded() && !this._isPlanExpired()) ||
      (this._isCurrentUserStaff() && this._isPMExit())
    ) {
      return (
        <View style={[CommonStyles.content]}>
          {this._isPMExit() &&
            (this.props.user.isFinancialStaff || this.props.user.isCrew) && (
              <StandardText
                style={[CommonStyles.memberExistBannerTextContainer]}
              >
                {MY_BUDGETS.EXITED_PLAN_MANAGEMENT_MESSAGE}
              </StandardText>
            )}

          <View style={[CommonStyles.marginLeft10, CommonStyles.marginRight10]}>
            <SummaryTile
              headerDisplayString={'Budgets Summary'}
              headerIconImage={InvoiceDollarRed2x}
              HeaderTextColor={BrandStyles.TextColor3}
              HeaderBoarderColor={BrandStyles.borderColor3}
              hideYellowStar={true}
              showButton={false}
              fontSize={
                types.isWeb ? CommonStyles.font20 : CommonStyles.rpfont20
              }
              disableMore={true}
            />
          </View>
          <View style={[CommonStyles.singleMainContentContainer]}>
            {this._renderContents()}
          </View>
        </View>
      );
    } else if (this._isPMExit()) {
      return this._renderInstructionPopUp();
    } else if (this._isPlanExpired()) {
      return (
        <View style={[CommonStyles.content]}>
          <View style={[CommonStyles.margin10]}>
            <PreviousPlans
              budget={this.props.budgets}
              navigation={this.props.navigation}
            />
          </View>
        </View>
      );
    } else {
      return this._renderInstructionPopUp();
    }
  };

  _isCurrentUserStaff = () => {
    const userObj = this.props.user;

    // fetch budget of the selected person if logged in user is a employee
    if (userObj.isEmployee === true || userObj.isFinancialStaff === true) {
      return true;
    }
    return false;
  };

  _isMemberOnBoarded = () => {
    const planManaged = this.props.member.memberStatus;
    if (planManaged == types2.MEMBER_STATUS_DATA.MANAGED) {
      return true;
    }
    return false;
  };

  _isMemberOnBoarding = () => {
    const planManaged = this.props.member.memberStatus;
    if (planManaged == types2.MEMBER_STATUS_DATA.ONBOARDING) {
      return true;
    }
    return false;
  };

  _isLeapInSelected = () => {
    // Get the member's plan manager from redux
    const planManager = this.props.member.planManager;
    if (
      planManager === types2.PLAN_MANAGER_DATA.LEAP_IN ||
      planManager === types2.PLAN_MANAGER_DATA.PLAN_MANAGED
    ) {
      return true;
    }

    // All other types of plan managers will return false
    return false;
  };

  _isPlanManageActiveOrComplete = () => {
    // Get the members status from redux
    const planStatus = this.props.member.planStatus;
    if (
      planStatus === types2.PLAN_STATUS_DATA.PM_ACTIVE ||
      planStatus === types2.PLAN_STATUS_DATA.COMPLETED
    ) {
      return true;
    }
    return false;
  };

  _getParams = () => {
    const value =
      this.props.navigation && this.props.navigationParams
        ? this.props.navigationParams?.params
        : null;
    return value;
  };

  _isPMExit = () => {
    let isPMExit = false;

    if (this.props.member.pmExitDate != '') {
      isPMExit = true;
    }

    return isPMExit;
  };

  _isPlanExpired = () => {
    let isPlanExpired = false;
    const planStatus = this.props.member.planStatus;
    const navigationParams = this._getParams();
    if (this._isPMExit()) {
      return isPlanExpired;
    }
    if (
      planStatus === types2.PLAN_STATUS_DATA.COMPLETED ||
      this.props.budgets.isPmExpired
    ) {
      isPlanExpired = true;
    }

    if (
      navigationParams &&
      navigationParams?.navigateFrom === PREVIOUS_PLANS &&
      (navigationParams?.planStatus === types2.PLAN_STATUS_DATA.CLAIMABLE ||
        navigationParams?.planStatus === types2.PLAN_STATUS_DATA.INACTIVE)
    ) {
      isPlanExpired = false;
    }
    return isPlanExpired;
  };

  _isLeapInAndOnboardedActive = () => {
    return (
      (this._isMemberOnBoarded() && this._isPlanManageActiveOrComplete()) ||
      (this._isCurrentUserStaff() && this._isPMExit())
    );
  };

  _isReadOnly = () => {
    if (Object.getOwnPropertyNames(this.props.member).length === 0) {
      return true;
    } else {
      let readOnly = this.props.member;
      if (readOnly.access) {
        if (readOnly.access.finances) {
          if (readOnly.access.finances == types.ACCESS_READ_ONLY) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
    return true;
  };

  _isNoAccess = () => {
    if (Object.getOwnPropertyNames(this.props.member).length === 0) {
      return true;
    } else {
      let readOnly = this.props.member;
      if (readOnly.access) {
        if (readOnly.access.finances) {
          if (readOnly.access.finances == types.ACCESS_NONE) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
    return true;
  };

  _determineContainerPadding = () => {
    if (
      !this._isLeapInSelected() ||
      !this._isMemberOnBoarded() ||
      this._isPlanExpired()
    ) {
      return true;
    } else {
      return false;
    }
  };

  _renderInstructionPopUp = (isTablet: any) => {
    let nav = null;
    let close = null;
    let accessibilityLabel = null;
    let textColor = BrandStyles.TextColor5;
    let saveContainerStyle = null;
    let cancelContainerStyle = null;

    if (!(this._isMemberOnBoarding() || this._isPlanExpired())) {
      const iconSource = NextArrowCharcoal;
      nav = this._navigateToMoreInformation;
      close = this._closePlanManagedModal;
      accessibilityLabel = messages.ACCESSIBILITY_MESSAGE;
      textColor = BrandStyles.TextColor1;
      saveContainerStyle = [
        CommonStyles.buttonContainerBudgetPlanReady,
        BrandStyles.primaryBgColor1,
        BrandStyles.borderColor4,
      ];
      cancelContainerStyle = [
        CommonStyles.buttonContainerBudgetPlanReady,
        BrandStyles.primaryBgColor1,
        BrandStyles.borderColor4,
        CommonStyles.paddingTop15,
        CommonStyles.paddingBottom15,
      ];
    }

    return (
      <BudgetLocked
        style={[CommonStyles.containerPopup, CommonStyles.alignSelfCenter]}
        close={close}
        cancelStyle={[
          BrandStyles.brandBlockTxtColor6,
          CommonStyles.buttonFormActionBudget,
          CommonStyles.customFontBold,
        ]}
        cancelContainerStyle={cancelContainerStyle}
        cancelLabel={messages.LEARN_MORE}
        save={nav}
        saveStyle={[
          BrandStyles.brandBlockTxtColor6,
          CommonStyles.buttonFormActionBudget,
          CommonStyles.customFontBold,
        ]}
        saveContainerStyle={saveContainerStyle}
        saveLabel={messages.SIGN_ME_UP}
        inScreenPopup={true}
        screenProps={this.props.screenProps}
        isMemberOnBoarding={this._isMemberOnBoarding()}
        textColor={textColor}
        isBudgetExpired={this._isPlanExpired()}
        isMobile={this.props.isMobile}
      />
    );
  };

  _closePlanManagedModal = () => {
    this.props.navigation.navigate('PlanManagement');
  };

  _navigateToMoreInformation = () => {
    if (this.props.user.demoProfile) {
      if (types.isWeb) {
        window.open(PM_SIGN_UP_URL);
      } else {
        Linking.openURL(PM_SIGN_UP_URL);
      }
    } else {
      this.props.navigation.navigate('UploadPersonalDetailsPM');
    }
  };
}

const styles = StyleSheet.create({
  messageBarContainer: {
    backgroundColor: BrandColors.WHITE,
    opacity: 0.9,
  },
});

//For the reducer connection
const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  member: state.MemberReducer,
  budgets: state.BudgetReducer,
  loadedMemberId: state.LoadedMemberReducer,
  tabBar: state.TabBarReducer,
  navigationParams: state.NavigationParamsReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    BudgetActions: bindActionCreators(BudgetActions, dispatch),
    PayInvoiceActions: bindActionCreators(PayInvoiceActions, dispatch),
    MemberActions: bindActionCreators(MemberActions, dispatch),
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(BudgetsPayments);
