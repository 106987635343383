/*
 * Author: Brad D'Costa
 * Date: 01/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The WhereILive Screen.
 */

import React, {Component} from 'react';
import {View, Platform} from 'react-native';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {MESSAGES} from '../Constants/Messages';
import {isEmpty} from 'lodash';
import reduxStore from '../ReduxStore/ReduxStore';


import {CommonActions, StackActions} from '@react-navigation/native';


import {
  WhereILiveCrew,
  WhereILiveCrewForm,
  WhereILiveHome,
  WhereILiveHomeModal,
  WhereILiveModifications,
  WhereILiveModificationsModal,
} from '../Components/Organisms';

import {Container} from '../Components/Molecules';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';

import {callAPIs} from '../API/APICaller';
import {getLivingArrangement, updateLivingArrangement} from '../API/AboutMeAPI';
import {getRefLivingArrangement} from '../API/ReferenceAPI';
import * as LivingArrangementActions from '../Actions/LivingArrangementActions';
import * as LivingArrangementRefActions from '../Actions/LivingArrangementRefActions';
import * as CrewActions from '../Actions/CrewActions';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {IOS, ANDROID} from '../Constants/Constants2';
import logger from 'helpers/Logger';

class WhereILive extends Component {
  liveInArray: any;
  liveWithArray: any;
  constructor(props: any) {
    super(props);
    this.saveHomeModal = this.saveHomeModal.bind(this);
    this.saveWhoILiveWithModal = this.saveWhoILiveWithModal.bind(this);
    this.saveModificationsModal = this.saveModificationsModal.bind(this);
    this.showWhereILiveHomeForm = this.showWhereILiveHomeForm.bind(this);
    this.showWhoILiveWithForm = this.showWhoILiveWithForm.bind(this);
    this.showWhereILiveModificationsForm =
      this.showWhereILiveModificationsForm.bind(this);
    this.createMainContents = this.createMainContents.bind(this);
    this.closeHomeModal = this.closeHomeModal.bind(this);
    this.closeModificationsModal = this.closeModificationsModal.bind(this);
    this.setSelectedLiveIn = this.setSelectedLiveIn.bind(this);
    this.setSelectedLiveWith = this.setSelectedLiveWith.bind(this);
    this.updateModalDataStatus = this.updateModalDataStatus.bind(this);
    this.liveInArray = [];
    this.liveWithArray = [];

    this.state = {
      homeModalVisible: false,
      whoILiveWithModalVisible: false,
      modificationsModalVisible: false,
      homeSection: {},
      homeModal: {},
      homeModalOriginal: {},
      whoILiveWithSection: {},
      whoILiveWithModal: {},
      modificationsSection: {},
      modificationsModal: {},
      loading: true,
      saveApiError: false,
      selectedLiveIn: [],
      selectedLiveWith: [],
    };
  }

  componentDidMount() {
    this._fetchData();
  }

  componentDidUpdate(prevProps: any) {
    if (isEmpty(prevProps.livingArrangementRef) && this.props.livingArrangementRef.homeTypes) {
      this.finishedLoading();
    }
  }

  _setLoading = (loadingState: any) => {
    if (types.isWeb) {
      this.props.setLoading(loadingState);
    } else {
      this.setState({loading: loadingState});
    }
  };

  _fetchData = () => {
    this._setLoading(true);
    const downloadCallback = (data: any) => {
      const livingArrange = data[0].content;
      const livingArrangeRef = data[1].content;

      this.props.actions.LivingArrangementActions.actionGetLivingArrangement(
        livingArrange,
      );
      const reduxLivingArrangement = this.props.livingArrangement;
      this.initialiseDataInState(reduxLivingArrangement);

      this.props.actions.LivingArrangementRefActions.actionGetLivingArrangementRef(
        livingArrangeRef,
      );

     this.finishedLoading();
    };

    callAPIs(
      [
        getLivingArrangement(
          this.props.loadedMemberId.loadedMemberId,
          this.props.user.demoProfile,
        ),
        getRefLivingArrangement(),
      ],
      downloadCallback,
      null,
      () => {

        this._setLoading(false);
      },
    );
  };

  initialiseDataInState(data: any) {
    this.setState({
      homeSection: {
        homeType: data.homeType,
        livingWith: data.livingWith,
        numberOfPeople: data.numberOfPeople,
      },
      whoILiveWithSection: {
        supportAtHome: data.supportAtHome,
      },
      modificationsSection: {
        modification: data.modification,
      },
      homeModal: {
        homeType: data.homeType,
        livingWith: data.livingWith,
        numberOfPeople: data.numberOfPeople,
      },
      whoILiveWithModal: {
        supportAtHome: data.supportAtHome,
      },
      modificationsModal: {
        modification: data.modification,
      },
    });
  }

  setSelectedLiveIn(liveIn: any) {
    this.liveInArray = liveIn;
    this.setState({selectedLiveIn: liveIn});
  }

  setSelectedLiveWith(liveWith: any) {
    this.liveWithArray = liveWith;
    this.setState({selectedLiveWith: liveWith});
  }

  finishedLoading() {
    // need to combine the data!
    this.combineHomeModalData();
    this.combineModifications();
    this.combineCrew();
    this._setLoading(false);
  }

  combineHomeModalData() {
    const homeTypes = this.combineHomeTypes();
    const livingWith = this.combineLivingWith();
    const numberOfPeople = this.combineNumOfPeople();

    const tempObj = {
      homeTypes,
      livingWith,
      numberOfPeople,
    };

    const stateObj = JSON.parse(JSON.stringify(tempObj));
    const originalStateObj = JSON.parse(JSON.stringify(tempObj));
    this.setState({
      homeModal: stateObj,
      homeModalOriginal: originalStateObj,
    });
  }

  combineHomeTypes() {
    const combinedArray = this.props.livingArrangementRef?.homeTypes?.slice();
    let checkingItem = this.props.livingArrangement;
    if (Object.getOwnPropertyNames(checkingItem).length > 0) {
      // there are some keys returned for our user
      // check if we have homeTypes key
      if (checkingItem.hasOwnProperty('homeType')) {
        checkingItem = checkingItem.homeType;
        // iterate array now
        combinedArray.forEach((item: any, index: any) => {
          if (item.type === checkingItem.type) {
            combinedArray[index].selected = true;
            this.liveInArray.push(combinedArray[index]);
          } else {
            combinedArray[index].selected = false;
          }
        });
      } else {
        combinedArray.forEach((item: any, index: any) => {
          combinedArray[index].selected = false;
        });
      }
    } else {
      combinedArray.forEach((item: any, index: any) => {
        combinedArray[index].selected = false;
      });
    }
    this.setSelectedLiveIn(this.liveInArray);
    return combinedArray.slice();
  }

  combineLivingWith() {
    const combinedArray = this.props.livingArrangementRef.livingWith.slice();
    const checkingItem = this.props.livingArrangement;
    // initialise all items to false
    combinedArray.forEach((item: any, index: any) => {
      combinedArray[index].selected = false;
    });

    if (Object.getOwnPropertyNames(checkingItem).length > 0) {
      // there are some keys returned for our user
      // check if we have livingWith key
      if (checkingItem.hasOwnProperty('livingWith')) {
        const checkingItemArray = checkingItem.livingWith;
        // iterate array now
        for (let i = 0; i < combinedArray.length; i++) {
          for (let j = 0; j < checkingItemArray.length; j++) {
            if (combinedArray[i].type === checkingItemArray[j].type) {
              combinedArray[i].selected = true;
              this.liveWithArray.push(combinedArray[i]);
            }
          }
        }
        // do another pass of the array and set all remaining to false
        combinedArray.forEach((item: any, index: any) => {
          if (item.selected !== true) {
            combinedArray[index].selected = false;
          }
        });
      } else {
        combinedArray.forEach((item: any, index: any) => {
          combinedArray[index].selected = false;
        });
      }
    } else {
      combinedArray.forEach((item: any, index: any) => {
        combinedArray[index].selected = false;
      });
    }
    this.setSelectedLiveWith(this.liveWithArray);
    return combinedArray.slice();
  }

  combineNumOfPeople() {
    const checkingItem = this.props.livingArrangement;
    let tempNumber = 0;
    if (Object.getOwnPropertyNames(checkingItem).length > 0) {
      // there are some keys returned for our user
      // check if we have numberOfPeople key
      if (checkingItem.hasOwnProperty('numberOfPeople')) {
        tempNumber = checkingItem.numberOfPeople;
      }
    }
    return tempNumber;
  }

  combineModifications() {
    let checkingItem = this.props.livingArrangement;
    const combinedArray = [
      {description: 'Yes', selected: false},
      {description: 'No', selected: false},
    ];
    let modificationDetails = '';
    let isModifiedFlag = false;
    if (Object.getOwnPropertyNames(checkingItem).length > 0) {
      // there are some keys returned for our user
      // check if we have modification key
      if (checkingItem.hasOwnProperty('modification')) {
        checkingItem = checkingItem.modification;
        modificationDetails = checkingItem.detail;
        isModifiedFlag = checkingItem.isModified;
        // iterate array now
        if (checkingItem.isModified === 1 || checkingItem.isModified === true) {
          combinedArray[0].selected = true;
          combinedArray[1].selected = false;
        }
        if (
          checkingItem.isModified === 0 ||
          checkingItem.isModified === false
        ) {
          combinedArray[0].selected = false;
          combinedArray[1].selected = true;
        }
      } else {
        combinedArray[0].selected = false;
        combinedArray[1].selected = false;
      }
    }
    const tempObj = {
      isModified: combinedArray,
      details: modificationDetails,
      isModifiedFlag,
    };

    const stateObj = JSON.parse(JSON.stringify(tempObj));
    const originalStateObj = JSON.parse(JSON.stringify(tempObj));
    this.setState({
      modificationsModal: stateObj,
      modificationsModalOriginal: originalStateObj,
    });

    this._setInitialState(tempObj);
  }

  combineCrew() {
    const combinedArray = JSON.parse(JSON.stringify(this.props.crews.crew));
    const checkingItem = this.props.livingArrangement;

    const displayArray = [];
    // initialise all items to false
    combinedArray.forEach((item: any, index: any) => {
      const crewArray = combinedArray[index].crews;
      crewArray.forEach((CAitem: any, CAindex: any) => {
        crewArray[CAindex].selected = false;
      });
    });
    if (Object.getOwnPropertyNames(checkingItem).length > 0) {
      // there are some keys returned for our user
      // check if we have livingWith key
      if (checkingItem.hasOwnProperty('supportAtHome')) {
        const checkingItemArray = checkingItem.supportAtHome;
        // get newly created crew ID from redux
        const newCrewId = this.props.crews.newCrewId;
        if (undefined != newCrewId) {
          checkingItemArray.push({id: newCrewId});
        }

        // iterate the checkingItemArray
        for (let k = 0; k < checkingItemArray.length; k++) {
          let foundItem = false;
          for (let i = 0; i < combinedArray.length; i++) {
            const crewArray = combinedArray[i].crews;
            for (let j = 0; j < crewArray.length; j++) {
              if (
                crewArray[j].crewId === checkingItemArray[k].crewId ||
                crewArray[j].crewId === checkingItemArray[k].id
              ) {
                if (crewArray[j].crewId == newCrewId) {
                  if (crewArray[j].selected) {
                    crewArray[j].selected = true;
                  } else {
                    crewArray[j].selected = false;
                  }
                } else {
                  crewArray[j].selected = true;
                }
                displayArray.push(crewArray[j]);
                foundItem = true;
                //remove temporarily added newly created crew ID to avoid duplicating
                if (crewArray[j].crewId === newCrewId) {
                  checkingItemArray.pop();
                }
                break;
              }
            }
            if (foundItem) {
              break;
            }
          }
        }
      }
      // reset redux new crew Id
      this.props.actions.CrewActions.removeWhoILiveWIthCrew();
    }
    const reduxObj = {
      originalData: JSON.parse(JSON.stringify(combinedArray)),
      modalData: JSON.parse(JSON.stringify(combinedArray)),
      displayData: JSON.parse(JSON.stringify(displayArray)),
    };
    // SETWHOILIVEWITHDATA
    this.props.actions.LivingArrangementActions.actionSetWhoILiveWithData(
      reduxObj,
    );
  }

  // Callback functions for the screen
  showWhereILiveHomeForm() {
    this.props.actions.NavigationParamActions.setParam({
      modalData: this.state.homeModal,
      mainScreenData: this.state.modificationsSection,
      refReducer: this.props.livingArrangementRef,
      dataReducer: this.props.livingArrangement,
      readOnly: this.props.member.access.profile,
      loading: this.state.loading,
      save: this.saveHomeModal,
      close: this.closeHomeModal,
      saveApiError: this.state.saveApiError,
      saveApiErrorCode: this.state.saveApiErrorCode,
      setSelectedLiveIn: this.setSelectedLiveIn,
      liveInArray: this.liveInArray,
      setSelectedLiveWith: this.setSelectedLiveWith,
      liveWithArray: this.liveWithArray,
    });
    this.props.navigation.navigate('WhereILiveHomeForm');
  }

  saveHomeModal() {
    if (
      !this.state.homeModal ||
      !this.state.homeModal.homeTypes ||
      !this.state.homeModal.livingWith
    ) {
      return null;
    }
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({loading: true, saveApiError: false});
    const msdata = this.state.homeModal;
    let homeTypeSave = null;
    let homeDescSave = null;
    const livingWithSave = [];
    // find hometype that is needed to be saved
    for (var i = 0; i < msdata.homeTypes.length; i++) {
      if (msdata.homeTypes[i].selected === true) {
        homeTypeSave = msdata.homeTypes[i].type;
        homeDescSave = msdata.homeTypes[i].description;
        break;
      }
    }
    // find livingWith that is needed to be saved
    for (var i = 0; i < msdata.livingWith.length; i++) {
      if (msdata.livingWith[i].selected === true) {
        const tempObj = {
          type: msdata.livingWith[i].type,
          description: msdata.livingWith[i].description,
        };
        livingWithSave.push(tempObj);
      }
    }
    const saveObj = {
      homeType: homeTypeSave,
      numberOfPeople: msdata.numberOfPeople,
      livingWith: livingWithSave,
    };

    const reduxObj = {
      homeType: {
        type: homeTypeSave,
        description: homeDescSave,
      },
      numberOfPeople: msdata.numberOfPeople,
      livingWith: livingWithSave,
    };

    this.saveLivingWith(saveObj, reduxObj, 'modalHome');
  }

  closeHomeModal() {
    const csData = JSON.parse(JSON.stringify(this.state.homeModalOriginal));
    const stateUpdate = {
      homeModalVisible: false,
      homeModal: csData,
      saveApiError: false,
    };
    this.setState(stateUpdate);
    if (types.isWeb) {
      this.props.navigation.goBack();
    } else {
      this.props.navigation.dispatch(CommonActions.goBack());
    }
  }

  showWhoILiveWithForm() {
    const reduxObj = {
      originalData: this.props.livingArrangement.liveWithOriginalData,
      modalData: this.props.livingArrangement.liveWithOriginalData,
      displayData: this.props.livingArrangement.liveWithDisplayData,
    };
    // SETWHOILIVEWITHDATA
    this.props.actions.LivingArrangementActions.actionSetWhoILiveWithData(
      reduxObj,
    );

    this.props.actions.NavigationParamActions.setParam({
      modalData: this.state.modificationsModal,
      mainScreenData: this.state.modificationsSection,
      refReducer: this.props.livingArrangementRef,
      dataReducer: this.props.livingArrangement,
      loading: this.state.loading,
      readOnly: this.props.member.access.profile,
      save: this.saveWhoILiveWithModal,
      close: this.closeModificationsModal,
      saveApiError: this.state.saveApiError,
      saveApiErrorCode: this.state.saveApiErrorCode,
    });
    this.props.navigation.navigate('WhereILiveCrewForm');
  }

  saveWhoILiveWithModal(liveWithModalData: any) {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({loading: true, saveApiError: false});
    const msdata = JSON.parse(JSON.stringify(liveWithModalData));
    const tempArray = [];
    const tempReduxArray = [];
    const tempDisplayArray = [];
    // iterate category Arrays first
    for (let i = 0; i < msdata.length; i++) {
      // now iterate the crew in this category
      for (let j = 0; j < msdata[i].crews.length; j++) {
        const checkItem = msdata[i].crews[j];
        if (checkItem.selected) {
          const tempObj = {crewId: checkItem.crewId};
          const reduxTempObj = {id: checkItem.crewId};
          tempArray.push(tempObj);
          tempReduxArray.push(reduxTempObj);
          tempDisplayArray.push(msdata[i].crews[j]);
        }
      }
    }
    const saveObj = {
      supportAtHome: tempArray,
    };
    const reduxObj = {
      supportAtHome: tempArray,
      liveWithDisplayData: tempDisplayArray,
    };

    this.saveLivingWith(saveObj, reduxObj, 'modalWhoILiveWith');
  }

  showWhereILiveModificationsForm() {
    this.props.actions.NavigationParamActions.setParam({
      modalData: this.state.modificationsModal,
      mainScreenData: this.state.modificationsSection,
      refReducer: this.props.livingArrangementRef,
      dataReducer: this.props.livingArrangement,
      readOnly: this.props.member.access.profile,
      loading: this.state.loading,
      save: this.saveModificationsModal,
      close: this.closeModificationsModal,
      saveApiError: this.state.saveApiError,
      saveApiErrorCode: this.state.saveApiErrorCode,
      updateModalDataStatus: this.updateModalDataStatus,
      modalDataStatus: this.state.modalDataStatus,
      hasFormContentChanged: this._hasFormContentChanged,
    });
    this.props.navigation.navigate('WhereILiveModificationsForm');
  }

  _setInitialState = (modificationsModalData: any) => {
    var initialModalData = modificationsModalData;
    this.setState({
      initialFormState: {
        modalData: initialModalData,
      },
    });
  };

  _hasFormContentChanged = () => {
    let initialFormState = this.state.initialFormState;
    let currentFormState = {
      modalData: this.state.modificationsModal,
    };
    return (
      JSON.stringify(initialFormState) !== JSON.stringify(currentFormState)
    );
  };

  saveModificationsModal() {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({loading: true, saveApiError: false});
    const msdata = this.state.modificationsModal;
    let detailsSave = '';
    if (msdata.isModifiedFlag !== '') {
      detailsSave = msdata.details;
    }
    const saveObj = {
      modification: {
        isModified: this._generateIsModifiedFlag(msdata.isModified),
        detail: detailsSave,
      },
    };
    this.saveLivingWith(saveObj, saveObj, 'modalModifications');
  }

  _generateIsModifiedFlag(checkBoxInputs: any) {
    if (
      checkBoxInputs[0].selected == false &&
      checkBoxInputs[1].selected == false
    ) {
      return null;
    }
    if (
      checkBoxInputs[0].selected == true &&
      checkBoxInputs[1].selected == false
    ) {
      return true;
    }
    return false;
  }

  closeModificationsModal = () => {
    if (this.state.modificationsModalOriginal) {
      const csData = JSON.parse(
        JSON.stringify(this.state.modificationsModalOriginal),
      );
      const stateUpdate = {
        modificationsModalVisible: false,
        modificationsModal: csData,
        saveApiError: false,
      };
      this.setState(stateUpdate);
    }
    if (types.isWeb) {
      this.props.navigation.goBack();
    } else {
      this.props.navigation.dispatch(CommonActions.goBack());
    }
  };

  saveLivingWith(saveObj: any, reduxObj: any, modal: any) {
    this._setLoading(true);
    const updateLivingCallback = (data: any) => {
      // Successful save -- update redux store and hide modal
      if (modal === 'modalHome') {
        this.props.actions.LivingArrangementActions.actionUpdateLivingArrangementHome(
          reduxObj,
        );
        this.combineHomeModalData();
        this._setLoading(false);
        this.setState({homeModalVisible: false});
        if (types.isWeb) {
          this.props.navigation.goBack();
        } else {
          this.props.navigation.dispatch(CommonActions.goBack());
        }
      } else if (modal === 'modalModifications') {
        this.props.actions.LivingArrangementActions.actionUpdateLivingArrangementModification(
          reduxObj,
        );
        this.combineModifications();
        this._setLoading(false);
        this.setState({modificationsModalVisible: false});
        if (types.isWeb) {
          this.props.navigation.goBack();
        } else {
          this.props.navigation.dispatch(CommonActions.goBack());
        }
      } else if (modal === 'modalWhoILiveWith') {
        const reduxDataObj = {
          supportAtHome: JSON.parse(JSON.stringify(reduxObj.supportAtHome)),
          liveWithOriginalData: JSON.parse(
            JSON.stringify(this.props.livingArrangement.liveWithModalData),
          ),
          liveWithDisplayData: JSON.parse(
            JSON.stringify(reduxObj.liveWithDisplayData),
          ),
        };
        this.props.actions.LivingArrangementActions.actionUpdateWhoILiveWithOnSave(
          reduxDataObj,
        );
        this._setLoading(false);
        this.setState({whoILiveWithModalVisible: false});
        if (types.isWeb) {
          this.props.navigation.goBack();
        } else {
          this.props.navigation.dispatch(CommonActions.goBack());
        }
      }
    };

    const updateLivingErrorCallback = (err: any) => {
      logger.log('WhereILive update error: ', err);
      this._setLoading(false);
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
      });
    };
    callAPIs(
      [
        updateLivingArrangement(
          this.props.loadedMemberId.loadedMemberId,
          this.props.member.planId,
          saveObj,
          this.props.user.demoProfile,
        ),
      ],
      updateLivingCallback,
      updateLivingErrorCallback,
    );
  }

  createMainContents() {
    return (
      <View style={[CommonStyles.content]}>
        <View style={[CommonStyles.singleMainContentContainer]}>
          <WhereILiveHome
            reducer={this.props.livingArrangement}
            readOnly={this.props.member}
            modal={this.showWhereILiveHomeForm}
          />
          <WhereILiveCrew
            reducer={this.props.livingArrangement}
            readOnly={this.props.member}
            modal={this.showWhoILiveWithForm}
            viewWidth={this.props.viewWidth}
            isMobile={this.props.isMobile}
          />
          <WhereILiveModifications
            reducer={this.props.livingArrangement}
            readOnly={this.props.member}
            modal={this.showWhereILiveModificationsForm}
          />
        </View>
      </View>
    );
  }

  updateModalDataStatus(status: any) {
    this.setState({modalDataStatus: status});
  }

  render() {
    if (types.isWeb) {
      return this.createMainContents();
    }
    return (
      <Container
        contents={this.createMainContents}
        loading={this.state.loading}
        needsSidebar
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.WHERE_I_LIVE}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  crews: state.CrewReducer,
  user: state.UserReducer,
  member: state.MemberReducer,
  livingArrangement: state.LivingArrangementReducer,
  livingArrangementRef: state.LivingArrangementRefReducer,
  loadedMemberId: state.LoadedMemberReducer,
  navigationParams: state.NavigationParamsReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    LivingArrangementActions: bindActionCreators(
      LivingArrangementActions,
      dispatch,
    ),
    LivingArrangementRefActions: bindActionCreators(
      LivingArrangementRefActions,
      dispatch,
    ),
    CrewActions: bindActionCreators(CrewActions, dispatch),
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WhereILive);
