/*
 * Author: Brad D'Costa
 * Date: 01/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Summary tile on the About Me screen for the More About Me Section
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View, Text} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import {StandardText} from '../Atoms';
import {SummaryTile} from '../Molecules';

import {
  ImpactOfMyDiabilityPurple3x,
  moreAboutMeCharcoal,
  moreAboutMeRed,
} from '../../assets/images/vector-icons';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

// Import messages
import {
  MORE_ABOUT_ME_SUMMARY_TITLES as messages,
  MESSAGES as messageContent,
} from '../../Constants/Messages';

class AboutMeMoreAboutMe extends Component {
  messageItems = [
    {
      title: messages.DAILY_LIVING,
      itemValue: () => {
        return this.props.reducer.dailyLiving;
      },
    },
    {
      title: messages.COMMUNICATION,
      itemValue: () => {
        return this.props.reducer.communication;
      },
    },
    {
      title: messages.CONNECTING,
      itemValue: () => {
        return this.props.reducer.connectingWithOthers;
      },
    },
    {
      title: messages.LEARNING,
      itemValue: () => {
        return this.props.reducer.learning;
      },
    },
    {
      title: messages.INDEPENDENCE,
      itemValue: () => {
        return this.props.reducer.independence;
      },
    },
  ];

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <SummaryTile
        headerDisplayString={messageContent.MORE_ABOUT_ME.TITLE}
        headerIconImage={ImpactOfMyDiabilityPurple3x}
        modal={this.props.form}
        containsData={this._determineContainsData()}
        HeaderTextColor={BrandStyles.TextColor4}
        HeaderBoarderColor={BrandStyles.borderColor4}
        actionOverride={this._determineAccess()}
      >
        {this._renderMessages()}
      </SummaryTile>
    );
  }

  _determineAccess = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      if (this.props.reducer.access) {
        if (this.props.reducer.access.profile) {
          if (this.props.reducer.access.profile == types.ACCESS_READ_ONLY) {
            return types.ACTION_BUTTON.VIEW;
          }
        }
      }
    }
    return null;
  };

  _determineContainsData = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return false;
    } else {
      var checkArray = [
        this.props.reducer.dailyLiving,
        this.props.reducer.communication,
        this.props.reducer.connectingWithOthers,
        this.props.reducer.learning,
        this.props.reducer.independence,
      ];
      var dataCheck = false;
      for (var i = 0; i < checkArray.length; i++) {
        if (checkArray[i] !== '') {
          dataCheck = true;
          break;
        }
      }
      return dataCheck;
    }
  };

  _determinIcon = () => {
    if (this._determineContainsData()) {
      return moreAboutMeRed;
    } else {
      return moreAboutMeCharcoal;
    }
  };

  _renderMessages = () => {
    let modalJSX: any = [];
    let counter = 0;
    this.messageItems.map(messageItem => {
      if (messageItem.itemValue()) {
        modalJSX.push(this._renderMessage(counter, messageItem.title));
        counter++;
      }
    });

    return modalJSX;
  };

  _renderMessage = (counter: any, titleText: any) => {
    return (
      <StandardText
        key={counter}
        style={[
          CommonStyles.SummaryTileText,
          BrandStyles.TextColor5,
          CommonStyles.customFont,
        ]}
      >
        {titleText}
      </StandardText>
    );
  };
}

export default AboutMeMoreAboutMe;
