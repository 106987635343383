/*
 * Author: Nirodha Perera
 * Date: 07/12/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Component for completed goals in summary screen
 */

import React, {Component} from 'react';
import {View} from 'react-native';
import {GOAL_SCREEN_LABELS, DATE_FORMATS} from '../../Constants/Constants2';
import {StandardText, StandardTouchableOpacity} from '../Atoms';

import {TickCirclePurple} from '../../assets/images/vector-icons';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {formatDDMMMYYYYDate} from '../../Helpers/Utils';
import CustomIcon from '../../assets/images/CustomIcon';
class CompletedGoalDetails extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <StandardTouchableOpacity
        activeOpacity={0.8}
        onPress={this.props.onPress}
        accessibilityLabel={GOAL_SCREEN_LABELS.ADD_GOAL}
        style={[
          CommonStyles.containerCompletedGoalDetails,
          BrandStyles.primaryBgColor1,
        ]}
      >
        <View style={CommonStyles.flexDirectionRow}>
          <View style={CommonStyles.oneContainer}>
            <View style={CommonStyles.flexDirectionRow}>
              <CustomIcon
                style={[CommonStyles.titleBoxIcon, CommonStyles.marginRight15]}
                name={TickCirclePurple}
              />
              {this._renderGoalName()}
            </View>
            <View
              style={[
                CommonStyles.justifyFlexRowEnd,
                CommonStyles.paddingTop15,
              ]}
            >
              {this._renderCompletedDate()}
            </View>
          </View>
        </View>
      </StandardTouchableOpacity>
    );
  }

  _renderGoalName = () => {
    const goalItem = this.props.goalItem;
    if (goalItem && goalItem.goalName) {
      return (
        <StandardText
          style={[
            CommonStyles.flex1,
            CommonStyles.SummaryTileText,
            BrandStyles.TextColor5,
            CommonStyles.customFontBold,
          ]}
        >
          {goalItem.goalName}
        </StandardText>
      );
    } else {
      return null;
    }
  };

  _renderCompletedDate = () => {
    const goalItem = this.props.goalItem;

    if (goalItem && goalItem.completedDate) {
      const dateValue = formatDDMMMYYYYDate(
        goalItem.completedDate,
        DATE_FORMATS.TYPE_TWO,
      );
      if (goalItem.goalName) {
        return (
          <StandardText
            style={[
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor10,
              CommonStyles.italicFont,
              CommonStyles.customFont,
            ]}
          >
            {GOAL_SCREEN_LABELS.COMPLETED + dateValue}
          </StandardText>
        );
      } else {
        return null;
      }
    }
  };
}

export default CompletedGoalDetails;
