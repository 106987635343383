/*
 * Author: Shelan Cooray
 * Date: 10/12/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The goal preview component to display selected
 * goal details in goal progress(Goal Tracker) screen
 */

import React, {Component} from 'react';
import {View} from 'react-native';
//import DeviceInfo from 'react-native-device-info';
import {isTablet} from '../../Helpers/PlatformSynchronizer';

import {isWeb} from '../../Constants/Constants';
import {
  GOAL_PROGRESS_TYPES,
  GOAL_SCREEN_LABELS,
  GOAL_PROGRESS_ARRAY,
} from '../../Constants/Constants2';

import {StandardText, StandardInput, StandardButton} from '../Atoms';
import {ProgressOption} from '../Molecules';
import {
  SupportsChangeSelected,
  SupportsKeepSelected,
  SupportsStopSelected,
} from '../../assets/images';
// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

const SAVE = 'Save';
const CANCEL = 'Cancel';

class GoalProgressTracker extends Component {
  state = {
    progressOption: '',
    progressComment: '',
    progressData: [],
    tempProgressObj: {},
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    this.setState({
      progressData: JSON.parse(JSON.stringify(GOAL_PROGRESS_ARRAY)),
    });
  }

  render() {
    return (
      <View>
        {!this.props.readOnly && this._renderProgressActions()}
        {!this.props.readOnly && this._renderAddInformation()}

        {this._renderActionButtons()}
      </View>
    );
  }

  _renderAddInformation = () => {
    return (
      <View style={[CommonStyles.marginTop10]}>
        <StandardText
          style={[
            CommonStyles.SummaryTileText,
            BrandStyles.TextColor5,
            CommonStyles.customFontBold,
            CommonStyles.marginBottom10,
          ]}
        >
          {GOAL_SCREEN_LABELS.GOAL_PROGRESS_COMMENT_TITLE}
        </StandardText>
        <StandardInput
          readOnly={false}
          multiline={true}
          value={this.state.progressComment}
          placeholder={GOAL_SCREEN_LABELS.GOAL_PROGRESS_COMMENT_PLACEHOLDER}
          onChangeText={(comment: any) =>
            this._onProgressCommentChange(comment)
          }
          underlineColorAndroid="transparent"
          style={[
            CommonStyles.ModalTextInputWrapperAutoHeight,
            BrandStyles.primaryBgColor1,
            CommonStyles.textAlignVerticalTop,
            isWeb ? CommonStyles.font15 : null,
          ]}
          blurOnSubmit={false}
        />
      </View>
    );
  };

  _renderProgressActions = () => {
    return (
      <View style={[CommonStyles.marginTop10]}>
        <StandardText
          style={[
            CommonStyles.SummaryTileText,
            BrandStyles.TextColor5,
            CommonStyles.customFontBold,
            CommonStyles.marginBottom10,
          ]}
        >
          {GOAL_SCREEN_LABELS.HOW_ARE_YOU_DOING}
        </StandardText>
        <View
          style={[
            isWeb || isTablet()
              ? CommonStyles.containerGoalProgressOptions
              : CommonStyles.containerGoalProgressOptionsMobile,
          ]}
        >
          {this._renderProgressOptions()}
        </View>
      </View>
    );
  };

  _renderProgressOptions = () => {
    const progressOptionArray = this.state.progressData;
    return progressOptionArray.map(progressOption => (
      <ProgressOption
        key={progressOption.KEY}
        data={progressOption}
        optionTitle={progressOption.VALUE}
        containerProgressStyle={
          isWeb || isTablet()
            ? [CommonStyles.containerProgressOption, CommonStyles.marginLeft30]
            : [CommonStyles.containerProgressOption]
        }
        optionImage={this._setProgressOptionIcon(progressOption)}
        onChange={(toggleObj: any) => {
          this._toggleGoalProgress(toggleObj);
        }}
      />
    ));
  };

  _renderActionButtons = () => {
    return (
      <View style={this._renderCustomButtonContainerStyle()}>
        <View style={CommonStyles.ModalFooterLeft}>
          <StandardButton
            onPress={() => this._canelProgress()}
            style={[
              BrandStyles.brandBlockTxtColor6,
              CommonStyles.buttonFormAction,
              CommonStyles.customFontBold,
            ]}
            containerStyle={[
              BrandStyles.primaryBgColor1,
              BrandStyles.brandBorderColor1,
              CommonStyles.buttonContainerFormAction,
            ]}
            accessibilityLabel={CANCEL}
          >
            {CANCEL}
          </StandardButton>
        </View>
        <View style={CommonStyles.ModalFooterRight}>
          {!this.props.readOnly ? (
            <StandardButton
              onPress={() => this._saveProgress()}
              style={this._renderSaveStyle()}
              containerStyle={this._renderSaveContainerStyle()}
              accessibilityLabel={SAVE}
              disabled={
                this.state.progressOption === '' &&
                this.state.progressComment === ''
                  ? true
                  : false
              }
            >
              {SAVE}
            </StandardButton>
          ) : null}
        </View>
      </View>
    );
  };

  _toggleGoalProgress = (toggleObj: any) => {
    const tempSelectedOption: any = [];
    let tempOption = '';
    const progressOptionArray = this.state.progressData;

    for (let i = 0; i < progressOptionArray.length; i++) {
      if (progressOptionArray[i].KEY !== toggleObj.KEY) {
        progressOptionArray[i].selected = false;
      }
    }
    progressOptionArray.forEach(item => {
      if (item.selected === true) {
        tempSelectedOption.push(item);
      }
    });

    if (tempSelectedOption.length > 0) {
      tempOption = tempSelectedOption[0].KEY;
    }

    this.props.saveProgressOption(tempOption);

    this.setState({
      progressData: progressOptionArray,
      progressOption: tempOption,
      tempProgressObj: toggleObj,
    });
  };

  _onProgressCommentChange = (comment: any) => {
    this.props.saveProgressComment(comment);
    this.setState({
      progressComment: comment,
    });
  };

  _setProgressOptionIcon = (progressOption: any) => {
    switch (progressOption.KEY) {
      case GOAL_PROGRESS_TYPES.GREAT.KEY:
        return SupportsKeepSelected;
      case GOAL_PROGRESS_TYPES.NOT_GREAT.KEY:
        return SupportsStopSelected;
      case GOAL_PROGRESS_TYPES.OK.KEY:
        return SupportsChangeSelected;
      default:
        return SupportsStopSelected;
    }
  };

  _saveProgress = () => {
    const progressSelectedObj = this.state.tempProgressObj;
    progressSelectedObj.selected = false;

    this.props.save();

    this._toggleGoalProgress(progressSelectedObj);
    this.setState({
      progressComment: '',
      progressOption: '',
    });
  };

  _canelProgress = () => {
    this.props.cancel();
    this.setState({
      progressComment: '',
      progressOption: '',
    });
  };

  _renderCustomButtonContainerStyle = () => {
    const styles = [CommonStyles.flexDirectionRow, CommonStyles.marginTop10];
    if (isWeb || isTablet()) {
      styles.push([CommonStyles.marginLeftTab, CommonStyles.marginRightTab]);
    }

    return styles;
  };

  _renderSaveStyle = () => {
    if (this.state.progressOption === '' && this.state.progressComment === '') {
      return [BrandStyles.TextColor10, CommonStyles.buttonFormAction];
    } else {
      return [BrandStyles.TextColor1, CommonStyles.buttonFormAction];
    }
  };

  _renderSaveContainerStyle = () => {
    if (this.state.progressOption === '' && this.state.progressComment === '') {
      return [
        BrandStyles.secondaryBgColor3,
        BrandStyles.borderColor8,
        CommonStyles.buttonContainerFormAction,
      ];
    } else {
      return [
        BrandStyles.primaryBgColor4,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerFormAction,
      ];
    }
  };
}

export default GoalProgressTracker;
