/*
 * Author: Shelan Cooray
 * Date: 14/12/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Common component to facilitate toogle button option with a image and text
 * ie: For Goal Tracker Goal Progress Options(Great, Not Great, Ok)
 */

import React, {Component} from 'react';
import {View, Image} from 'react-native';

import {StandardText, StandardTouchableOpacity} from '../Atoms';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class ProgressOption extends Component {
  state = {props: {}};

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    this.setState({props: this.props});
  }

  render = () => {
    return (
      <StandardTouchableOpacity
        activeOpacity={0.8}
        onPress={() => {
          this._toggleCheckbox();
        }}
        accessibilityLabel={this.props.optionTitle}
        style={this._determineOptionStyle()}
      >
        <View style={[CommonStyles.alignItemsCenter]}>
          <View>
            <Image
              style={this._determineIconStyle()}
              source={this.props.optionImage}
            />
          </View>
          <StandardText
            style={[CommonStyles.customFont, BrandStyles.TextColor5]}
          >
            {this.props.optionTitle}
          </StandardText>
        </View>
      </StandardTouchableOpacity>
    );
  };

  _determineOptionStyle = () => {
    let styles = [CommonStyles.containerProgressOption];
    if (this.props.containerProgressStyle) {
      styles = this.props.containerProgressStyle;
    }

    if (this.props.data.selected) {
      styles.push(BrandStyles.secondaryBgColor3);
    } else {
      styles.push(BrandStyles.primaryBgColor1);
    }
    return styles;
  };

  _determineIconStyle = () => {
    let styles = [CommonStyles.progressOptionIcon];
    if (this.props.iconStyle) {
      styles = this.props.iconStyle;
    }
    return styles;
  };

  _toggleCheckbox = () => {
    const setStateCallbackFunction = () => {
      this.props.onChange(this.state.props.data);
    };
    const stateObj = this.state.props;
    if (this.props.isSelected === undefined) {
      stateObj.data.selected = !stateObj.data.selected;
    }
    this.setState({props: stateObj}, setStateCallbackFunction);
  };
}

export default ProgressOption;
