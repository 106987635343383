/*
 * Author: Shelan Cooray
 * Date: 19/10/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Popup Modal Component for when user try to upload restricted file format
 */

import React from 'react';
import {View} from 'react-native';

import {StandardText} from '../Atoms';
import {BasicOverlayPopupModal} from '../Molecules';

import {OK_GOT_IT} from '../../Constants/Constants2';
import {DOCUMENTS_SCREEN as messages} from '../../Constants/Messages';
import {isWeb} from '../../Constants/Constants';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {isTablet} from '../../Helpers/PlatformSynchronizer';

const DocumentRestrictedPopUp = function (props: any) {
  const _determinePopUpStyle = () => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (isWeb) {
      styles.push([CommonStyles.containerInstructionPopUpWeb]);

      if (props.isMobile) {
        styles.push([CommonStyles.containerPopupWebMobile]);
      }
    } else {
      styles = [
        CommonStyles.flexDirectionRow,
        CommonStyles.scrollView_subContentCenter,
        CommonStyles.paddingBottom20,
      ];
    }
    return styles;
  };

  let headerFontSize = CommonStyles.font20;
  let fontSize = CommonStyles.font18;
  if (isTablet) {
    headerFontSize = CommonStyles.font25;
    fontSize = CommonStyles.font18;
  } else if (isWeb) {
    headerFontSize = CommonStyles.font18;
    fontSize = CommonStyles.font15;
  }

  return (
    <BasicOverlayPopupModal
      visible={props.visible}
      style={_determinePopUpStyle()}
      backdropOpacity={0.2}
      divider={false}
      close={props.close}
      cancelContainerStyle={CommonStyles.popUpOkButton}
      cancelStyle={[CommonStyles.buttonPopupOk, CommonStyles.customFontBold]}
      cancelLabel={OK_GOT_IT}
    >
      <View>
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.scrollView_subContentCenter,
            CommonStyles.paddingBottom20,
          ]}
        >
          <View style={[CommonStyles.scrollView_subContentCenter]}>
            <StandardText
              style={[
                headerFontSize,
                BrandStyles.TextColor10,
                CommonStyles.customFontSemiBold,
                CommonStyles.textAlignCentre,
              ]}
            >
              {messages.DOCUMENT_RESTRICT_TITLE}
            </StandardText>
          </View>
        </View>
        <View
          style={[CommonStyles.alignItemsCenter, CommonStyles.marginBottom20]}
        >
          <StandardText
            style={[
              fontSize,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
              CommonStyles.textAlignCentre,
            ]}
          >
            {messages.DOCUMENT_RESTRICT_MESSAGE}
          </StandardText>
        </View>
        <View style={[CommonStyles.containerInstructionDevider]} />
      </View>
    </BasicOverlayPopupModal>
  );
};

export default DocumentRestrictedPopUp;
