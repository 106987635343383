/*
 * Author: Roshan Piumal
 * Date: 07/06/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This component will track the user's inactive time when the app is running
 */

import { CommonActions } from '@react-navigation/native';
import logger from 'helpers/Logger';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { AppState } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { checkInactiveTime, logUserOut } from '../../API/PreauthenticationHelper';
import { getDataLoggedIn, storeData } from '../../API/StorageHelper';
import * as UserActions from '../../Actions/AuthenticationActions';
import * as types from '../../Constants/Constants';
import {
  USER_INACTIVITY_CHECK_INTERVAL,
  USER_INACTIVITY_TIMEOUT,
} from '../../Constants/Constants';
import UserInactivity from './UserInactivity';

class InactivityTracker extends Component {
  subscription: any;
  constructor(props: any) {
    super(props);
  }
  state = {
    checkInterval: USER_INACTIVITY_CHECK_INTERVAL,
    timeOut: USER_INACTIVITY_TIMEOUT,
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  onInactivity = (time: any) => {
    logUserOut().then(() => {
      let lastScreen = this.getLastScreen();
      if (lastScreen !== 'LoginSignUp') {
        return this.navigateToLoginSignUpScreen();
      }
    });
  };

  getLastScreen() {
    return this.props.getNavigator().state.nav.routes[0].routeName;
  }

  navigateToLoginSignUpScreen() {
    getDataLoggedIn(types.PREVIOUSLY_SIGNIN).then(alreadyloggedIn => {
      const actionToDispatch = CommonActions.reset({
        index: 0,
        routes: [{
          name:'Welcome'
        }
        ],
      });    
      this.props.getNavigator().dispatch(actionToDispatch);
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (
      nextProps.user &&
      nextProps.user.id &&
      (nextProps.user.isCrew ||
        nextProps.user.isEmployee ||
        nextProps.user.isMember)
    ) {
      this.setState({
        timeOut: this._getTimeOutForUserType(nextProps.user),
      });

      this.checkInactiveTimeAndLogUserOut();
    }

    if (nextProps.logout.logUserOut) {
      logUserOut().then(() => {
        return this.navigateToLoginSignUpScreen();
      });
    }
  }

  _getTimeOutForUserType = (user: any) => {
    if (user.isEmployee) {
      return user.inactiveTimeoutThreshold.EmployeeInactiveTimeThreshold;
    }

    if (user.isCrew) {
      return (
        user.inactiveTimeoutThreshold.CrewInactiveTimeThreshold ||
        USER_INACTIVITY_TIMEOUT
      );
    }

    if (user.isMember) {
      return (
        user.inactiveTimeoutThreshold.MemberInactiveTimeThreshold ||
        USER_INACTIVITY_TIMEOUT
      );
    }

    return USER_INACTIVITY_TIMEOUT;
  };

  UNSAFE_componentWillMount() {
    this.subscription = AppState.addEventListener('change', this.onAppStateChange);
  }

  componentWillUnmount() {
    // AppState.removeEventListener('change', this.onAppStateChange);
    if (this.subscription) {
      this.subscription.remove();
    }
  }

  // When the app goes the background or app is killed
  // Records the last active time in AsyncStorage
  onAppStateChange = (nextAppState: any) => {
    if (nextAppState.match(/inactive|background/)) {
      this.recordLastActiveTime();
    }

    if (nextAppState === 'active') {
      return this.checkInactiveTimeAndLogUserOut();
    }
  };

  checkInactiveTimeAndLogUserOut = () => {
    checkInactiveTime(this.state.timeOut)
      .then(isInactiveTimeReached => {
        if (isInactiveTimeReached) {
          logUserOut().then(() => {
            let lastScreen = this.getLastScreen();
            if (lastScreen !== 'LoginSignUp') {
              return this.navigateToLoginSignUpScreen();
            }
          });
        }
      })
      .catch(error => {
        logger.log(error);
      });
  };

  recordLastActiveTime() {
    return storeData(types.LAST_ACTIVE_TIME, new Date().getTime().toString());
  }

  render() {
    const {children} = this.props;
    return (
      <UserInactivity
        recordLastActiveTime={this.recordLastActiveTime}
        timeForInactivity={this.state.timeOut}
        checkInterval={this.state.checkInterval}
        onInactivity={this.onInactivity}
      >
        {children}
      </UserInactivity>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  logout: state.LogoutReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    userActions: bindActionCreators(UserActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(InactivityTracker);
