/*
 * Author: Declan Hart
 * Date: 03/03/2020
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This component displays the top banner on the screen
 */
import React from 'react';
import {View, Image} from 'react-native';
import {StandardText, StandardTouchableOpacity} from '../Atoms';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {LILogoWhiteText, ReturnWhite} from '../../assets/images';
import CustomIcon from '../../assets/images/CustomIcon';
import {RefreshIcon} from '../../assets/images/vector-icons';

import {LOGIN_SIGNUP_SCREEN} from '../../Constants/Messages';

import * as brand from '../../Constants/BrandConstants';

const HeaderBanner = (props: any) => {
  return (
    <View
      style={[
        CommonStyles.flex1,
        CommonStyles.justifyFlexRowSpace,
        CommonStyles.alignItemsCenter,
        BrandStyles.primaryBgColor3,
      ]}
    >
      <StandardTouchableOpacity
        activeOpacity={0.8}
        onPress={() => window.open(brand.LEAP_IN_URL)}
        accessibilityLabel={LOGIN_SIGNUP_SCREEN.RETURN_TO_LEAPIN}
        style={[CommonStyles.loginTileWrapper]}
      >
        <View style={[CommonStyles.flexDirectionRow, CommonStyles.flexWrap]}>
          <Image
            source={LILogoWhiteText}
            style={[CommonStyles.imageBrandLogoText]}
          />
        </View>
      </StandardTouchableOpacity>
      <StandardTouchableOpacity
        activeOpacity={0.8}
        onPress={() => window.open(brand.LEAP_IN_URL)}
        accessibilityLabel={LOGIN_SIGNUP_SCREEN.RETURN_TO_LEAPIN}
        style={[CommonStyles.loginTileWrapper]}
      >
        <View style={[CommonStyles.flexDirectionRow, CommonStyles.flexWrap]}>
          <View
            style={[
              CommonStyles.paddingLeft10,
              CommonStyles.flexDirectionColumn,
              CommonStyles.alignCenter,
            ]}
          >
            <StandardText
              style={[
                CommonStyles.font18,
                CommonStyles.customFont,
                BrandStyles.TextColor1,
              ]}
            >
              {LOGIN_SIGNUP_SCREEN.RETURN_TO_LEAPIN}
            </StandardText>
          </View>
          <Image source={ReturnWhite} style={[CommonStyles.headerItemIcon]} />
        </View>
      </StandardTouchableOpacity>
    </View>
  );
};

export default HeaderBanner;
