/*
 * Author: Joshua Merkas
 * Date: 28/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Settings Screen, a flat list for showing the different settings.
 */

import React, {Component} from 'react';
import {View, Image, Linking, Alert, StyleSheet, Platform} from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import {AWS_LAMBDA_CONFIG_ITEM} from '../environments';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as env from '../environments';

import {
  StandardText,
  StandardTouchableOpacity,
  StandardButton,
  SimpleCheckbox,
  HeaderLine,
} from '../Components/Atoms';
import {
  Container,
  BasicOverlayPopupModal,
  GridView,
} from '../Components/Molecules';

import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import * as MemberActions from '../Actions/MemberActions';

import {
  logoutFacebook,
  logoutGoogle,
  logoutCognito,
} from '../API/AuthenticationAPI';
//import {NavigationActions, StackActions} from 'react-navigation';
import {CommonActions, StackActions} from '@react-navigation/native';

import {SETTINGS_SCREEN as messages, MESSAGES} from '../Constants/Messages';
import {APP_TYPE_STAFF} from '../Constants/Constants';

// Import stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

// Import assets
import {
  LILogo,
  ChangePasswordRed4x,
  SettingsRed3x,
  Logout4x,
  AddMembersRed4x,
  CloseAccountRedx4,
  CloseMyAccount,
  MBCLogo,
} from '../assets/images';
import {callAPIs} from '../API/APICaller';
import {deactiveteUser} from '../API/UserAPI';
import {authenticateMember} from '../API/MemberAPI';
import * as OfflineNoticeActions from '../Actions/OfflineNoticeActions';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import * as brand from '../Constants/BrandConstants';
import {getDataLoggedIn, getData} from '../API/StorageHelper';
import {
  getVersion,
  getBuildNumber,
  isTablet,
} from '../Helpers/PlatformSynchronizer';
import {BasicForm} from '../Components/Organisms';
import {BrandColors} from '../Styles/Colours';
import logger from 'helpers/Logger';

const MAX_COLUMNS = 2;
const ENVIRONMENT = AWS_LAMBDA_CONFIG_ITEM;
const welcome = 'Welcome';
class Settings extends Component {
  navigateToPreAuthentication: any;
  state = {
    instructionModalVisible: false,
    loading: false,
    isConnected: true,
    deleteAllData: false,
  };

  constructor(props: any) {
    super(props);
    this._closePlanManagedModal = this._closePlanManagedModal.bind(this);
    this._closeAccount = this._closeAccount.bind(this);
    this._renderCloseMyAccButton = this._renderCloseMyAccButton.bind(this);
    this._deleteAllDataCheckboxHandler =
      this._deleteAllDataCheckboxHandler.bind(this);
  }

  componentDidMount() {
    NetInfo.addEventListener(this.handleConnectivityChange);
  }

  handleConnectivityChange = (isConnected: any) => {
    if (isConnected) {
      this.setState({isConnected});
    } else {
      this.setState({isConnected});
    }
  };

  render() {
    
      return (
        <BasicForm
          headerDisplayString={messages.HEADER_TITLE}
          headerIconImage={SettingsRed3x}
          HeaderTextColor={BrandStyles.headerContainsDataText}
          HeaderBoarderColor={BrandStyles.headerContainsDataBorder}
          disableMore={true}
          hideYellowStar={true}
          actionOverride={true}
          buttonsPositionBottom={true}
          imageIcon={true}
          contentWidthFull={true}
        >
          {this._createMainContents()}
        </BasicForm>
      );
  }

  _createMainContents = () => (
    <View style={[CommonStyles.content]}>
      <View style={[CommonStyles.mainContentContainer]}>
        <View style={[CommonStyles.settingsContainer]}>
          {this._renderInstructionPopUp()}
          <View style={[CommonStyles.flexRow]}>
            <GridView maximumColumns={MAX_COLUMNS} isStatic={true}>
              <View style={[CommonStyles.flex05, CommonStyles.marginBottom20]}>
                <StandardTouchableOpacity
                  activeOpacity={0.8}
                  onPress={this.GetItem.bind(this, messages.CHANGE_MY_PASSWORD)}
                  accessibilityLabel={messages.CHANGE_MY_PASSWORD}
                  style={[
                    CommonStyles.settingsTileWrapper,
                    BrandStyles.primaryBgColor1,
                  ]}
                >
                  <View style={[CommonStyles.settingsButtonContainer]}>
                    <Image
                      style={[CommonStyles.settingsButtonImage]}
                      source={ChangePasswordRed4x}
                    />
                    <StandardText
                      style={[
                        CommonStyles.settingsButtonText,
                        CommonStyles.customFont,
                      ]}
                    >
                      {messages.CHANGE_MY_PASSWORD}
                    </StandardText>
                  </View>
                </StandardTouchableOpacity>
              </View>
              {this._renderCloseMyAccButton()}
              {this._renderAddNewMember()}
              <View style={[CommonStyles.flex05, CommonStyles.marginBottom20]}>
                <StandardTouchableOpacity
                  activeOpacity={0.8}
                  onPress={this.logout.bind(this)}
                  accessibilityLabel={messages.LOG_OUT}
                  style={[
                    CommonStyles.settingsTileWrapper,
                    BrandStyles.primaryBgColor1,
                  ]}
                >
                  <View style={[CommonStyles.settingsButtonContainer]}>
                    <Image
                      style={[CommonStyles.settingsButtonImage]}
                      source={Logout4x}
                    />
                    <StandardText
                      style={[
                        CommonStyles.settingsButtonText,
                        CommonStyles.customFont,
                      ]}
                    >
                      {messages.LOG_OUT}
                    </StandardText>
                  </View>
                </StandardTouchableOpacity>
              </View>

              {env.IS_STAFF_APP === APP_TYPE_STAFF && (
                <View
                  style={[CommonStyles.flex05, CommonStyles.marginBottom20]}
                >
                  <StandardTouchableOpacity
                    activeOpacity={0.8}
                    onPress={this.redirectToMBCPage.bind(this)}
                    accessibilityLabel={messages.LOGIN_TO_MEMBER_BENEFITS_CLUB}
                    style={[
                      CommonStyles.settingsTileWrapper,
                      BrandStyles.primaryBgColor1,
                    ]}
                  >
                    <View style={[CommonStyles.settingsButtonContainer]}>
                      <Image
                        style={[CommonStyles.settingsButtonImage]}
                        source={MBCLogo}
                      />
                      <StandardText
                        accessibilityLabel={
                          messages.LOGIN_TO_MEMBER_BENEFITS_CLUB
                        }
                        style={[
                          CommonStyles.settingsButtonText,
                          CommonStyles.customFont,
                        ]}
                      >
                        {messages.LOGIN_TO_MBC}
                      </StandardText>
                    </View>
                  </StandardTouchableOpacity>
                </View>
              )}
            </GridView>
          </View>

          <View
            style={[
              CommonStyles.paddingTop10,
              CommonStyles.flex1,
              CommonStyles.justifyContentFlexEnd,
            ]}
          >
            <StandardButton
              accessibilityLabel={messages.ABOUT + brand.APP_COMPANY_NAME}
              buttonType={types2.COMPONENT_TYPES.LINK}
              style={[
                CommonStyles.textAlignCentre,
                CommonStyles.buttonText,
                BrandStyles.TextColor2,
                CommonStyles.LoginSignUpP10,
                CommonStyles.rpfont12,
                CommonStyles.customFont,
              ]}
              containerStyle={[CommonStyles.LoginSignUpMT10]}
              onPress={this.GetItem.bind(
                this,
                messages.ABOUT + brand.APP_COMPANY_NAME,
              )}
            >
              <View>
                <Image
                  style={[CommonStyles.settingsButtonLogo]}
                  source={LILogo}
                />
              </View>
            </StandardButton>
            <StandardButton
              accessibilityLabel={messages.ABOUT_LEAP_IN}
              buttonType={types2.COMPONENT_TYPES.LINK}
              style={[
                CommonStyles.textAlignCentre,
                CommonStyles.buttonText,
                BrandStyles.TextColor2,
                CommonStyles.LoginSignUpP10,
                CommonStyles.rpfont12,
                CommonStyles.customFont,
              ]}
              containerStyle={[CommonStyles.LoginSignUpMT10]}
              onPress={this.GetItem.bind(
                this,
                messages.ABOUT + brand.APP_COMPANY_NAME,
              )}
            >
              <View>
                <StandardText
                  style={[
                    CommonStyles.settingsLinkButton,
                    CommonStyles.customFont,
                  ]}
                >
                  {messages.ABOUT_LEAP_IN}
                </StandardText>
              </View>
            </StandardButton>
            <StandardButton
              accessibilityLabel={messages.TERMS_OF_USE_AND_PRIVACY}
              buttonType={types2.COMPONENT_TYPES.LINK}
              style={[
                CommonStyles.textAlignCentre,
                CommonStyles.buttonText,
                BrandStyles.TextColor2,
                CommonStyles.LoginSignUpP10,
                CommonStyles.rpfont12,
                CommonStyles.customFont,
              ]}
              containerStyle={[CommonStyles.LoginSignUpMT10]}
              onPress={this.GetItem.bind(
                this,
                messages.TERMS_OF_USE_AND_PRIVACY_POLICY,
              )}
            >
              <View>
                <StandardText
                  style={[
                    CommonStyles.settingsLinkButton,
                    CommonStyles.customFont,
                  ]}
                >
                  {messages.TERMS_OF_USE_AND_PRIVACY}
                </StandardText>
              </View>
            </StandardButton>
            <StandardButton
              accessibilityLabel={messages.PLAN_MANAGEMENT_TERMS}
              buttonType={types2.COMPONENT_TYPES.LINK}
              style={[
                CommonStyles.textAlignCentre,
                CommonStyles.buttonText,
                BrandStyles.TextColor2,
                CommonStyles.LoginSignUpP10,
                CommonStyles.rpfont12,
                CommonStyles.customFont,
              ]}
              containerStyle={[CommonStyles.LoginSignUpMT10]}
              onPress={this.GetItem.bind(this, messages.PLAN_MANAGED_TS_N_CS)}
            >
              <View>
                <StandardText
                  style={[
                    CommonStyles.settingsLinkButton,
                    CommonStyles.customFont,
                  ]}
                >
                  {messages.PLAN_MANAGEMENT_TERMS}
                </StandardText>
              </View>
            </StandardButton>
            <View
              style={[CommonStyles.flexDirectionRow, CommonStyles.alignFlexEnd]}
            >
              <StandardText style={CommonStyles.customFontV}>
                {messages.APP_VERSION + getVersion}
              </StandardText>
            </View>
            {this._renderBuildInfo()}
          </View>
        </View>
      </View>
    </View>
  );

  _renderAddNewMember = () => {
    return (
      <View style={[CommonStyles.flex05, CommonStyles.marginBottom20]}>
        <StandardTouchableOpacity
          activeOpacity={0.8}
          onPress={this.GetItem.bind(this, messages.LINK_MEMBER_TO_MY_ACCOUNT)}
          accessibilityLabel={messages.ADD_NEW_MEMBER}
          style={[
            CommonStyles.settingsTileWrapper,
            BrandStyles.primaryBgColor1,
          ]}
        >
          <View style={[CommonStyles.settingsButtonContainer]}>
            <Image
              style={[CommonStyles.settingsButtonImage]}
              source={AddMembersRed4x}
            />
            <StandardText
              style={[CommonStyles.settingsButtonText, CommonStyles.customFont]}
            >
              {messages.ADD_NEW_MEMBER}
            </StandardText>
          </View>
        </StandardTouchableOpacity>
      </View>
    );
  };

  _closePlanManagedModal() {
    this.setState({instructionModalVisible: false});
  }

  _determinePopUpStyle = (isTablet: any) => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (isTablet) {
      styles.push([
        BrandStyles.brandBlockBgColorWhite,
        CommonStyles.borderRadius20,
        CommonStyles.customSettingModalContainer,
      ]);
    } else {
      styles.push(
        BrandStyles.brandBlockBgColorWhite,
        CommonStyles.customSettingModalContainer,
        CommonStyles.width70,
      );
    }
    return styles;
  };

  _renderBuildInfo = () => {
    if (types.BUILD_ENVIROMENT_DISPLAY_ARRAY.includes(ENVIRONMENT)) {
      return (
        <View>
          {this._renderBuildNo()}
          {this._renderBuildEnv()}
        </View>
      );
    }
  };

  _renderBuildNo = () => {
    return (
      <View style={[CommonStyles.flexDirectionRow, CommonStyles.alignFlexEnd]}>
        <StandardText style={CommonStyles.customFontV}>
          {messages.BUILD_NO + getBuildNumber}
        </StandardText>
      </View>
    );
  };

  _renderBuildEnv = () => {
    let buildEnv = '';
    switch (ENVIRONMENT) {
      case messages.DEV:
        buildEnv = messages.DEV_DISPLAY;
        break;
      case messages.QA:
        buildEnv = messages.QA_DISPLAY;
        break;
      case messages.UAT:
        buildEnv = messages.UAT_DISPLAY;
        break;
      default:
        buildEnv = '';
    }

    return (
      <View style={[CommonStyles.flexDirectionRow, CommonStyles.alignFlexEnd]}>
        <StandardText style={CommonStyles.customFontV}>
          {messages.ENVIRONMENT + buildEnv}
        </StandardText>
      </View>
    );
  };

  _renderInstructionPopUp() {
    let isTabletDevice = false;
   
    return (
      <BasicOverlayPopupModal
        visible={this.state.instructionModalVisible}
        style={this._determinePopUpStyle(isTabletDevice)}
        backdropOpacity={0.2}
        headerTextV2={types2.DELETE_ACCOUNT.SETTINGS_AND_INFO}
        divider={false}
        close={this._closePlanManagedModal}
        hideCloseButton={true}
        cancelContainerStyle={CommonStyles.popUpOkButton}
        cancelStyle={[CommonStyles.buttonPopupOk, CommonStyles.customFontBold]}
        cancelLabel={types2.OK_GOT_IT}
      >
        <View>
          {this.props.member.memberStatus ===
          types2.MEMBER_STATUS_DATA.PLAN_READY
            ? this.getPopUpContent(isTabletDevice)
            : this.getBasicPopUpContent(isTabletDevice)}
        </View>
      </BasicOverlayPopupModal>
    );
  }

  getPopUpContent(isTablet: any) {
    return (
      <View>
        <View style={[CommonStyles.flexDirectionRow]}>
          <View style={[CommonStyles.paddingBottom15, CommonStyles.width100]}>
            <Image
              style={[CommonStyles.closeMyAccountIcon]}
              source={CloseMyAccount}
            />
            <StandardText
              style={[
                isTablet ? CommonStyles.font20 : CommonStyles.font15,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
                CommonStyles.customFontBold,
              ]}
            >
              {messages.CLOSE_MY_ACCOUNT}
            </StandardText>
            <HeaderLine
              color={BrandColors.RESPONSIVE_DESIGN_COLORS.RED}
              style={[CommonStyles.marginTop5]}
            />
          </View>
        </View>

        <View
          style={[CommonStyles.paddingBottom15, CommonStyles.phototileCorner]}
        >
          <StandardText
            style={[
              CommonStyles.customFont,
              BrandStyles.TextColor5,
              CommonStyles.customFontBold,
              isTablet ? CommonStyles.font18 : CommonStyles.rpfont15,
            ]}
          >
            {messages.PERMANENTLY_CLOSE_MESSAGE}
          </StandardText>
        </View>
        <View
          style={[
            CommonStyles.paddingTop5,
            CommonStyles.flexDirectionRow,
            CommonStyles.paddingBottom15,
            CommonStyles.alignItemsCenter,
          ]}
        >
          <SimpleCheckbox
            isChecked={this.state.deleteAllData}
            onValueChange={() => this._deleteAllDataCheckboxHandler()}
          />

          <StandardText
            style={[
              CommonStyles.paddingLeft10,
              isTablet ? CommonStyles.font18 : CommonStyles.rpfont15,
              BrandStyles.TextColor2,
              CommonStyles.paddingRight10,
            ]}
          >
            {types2.DELETE_ACCOUNT.DELETE_ALL_DATA}
          </StandardText>
        </View>

        <View style={[CommonStyles.ModalActionButtons]}>
          <View
            style={[
              CommonStyles.ModalFooterLeft,
              {
                display: this.props.isMobile ? 'none' : 'flex',
              },
            ]}>
            <StandardButton
              onPress={this._closePlanManagedModal}
              style={[
                BrandStyles.brandBlockTxtColor5,
                CommonStyles.buttonFormAction,
                CommonStyles.customFontBold,
              ]}
              containerStyle={[
                BrandStyles.primaryBgColor4,
                BrandStyles.brandBorderColor1,
                CommonStyles.buttonContainerFormAction,
              ]}
              accessibilityLabel={messages.NO}
            >
              {messages.NO}
            </StandardButton>
          </View>
          <View style={CommonStyles.ModalFooterRight}>
            <StandardButton
              onPress={this._closeAccount}
              style={[
                BrandStyles.brandBlockTxtColor5,
                CommonStyles.buttonFormAction,
                CommonStyles.customFontBold,
              ]}
              containerStyle={[
                BrandStyles.primaryBgColor3,
                BrandStyles.borderColor3,
                CommonStyles.buttonContainerFormAction,
              ]}
              accessibilityLabel={messages.YES_CLOSE_MY_ACCOUNT}
            >
              {messages.YES_CLOSE_MY_ACCOUNT}
            </StandardButton>
          </View>
        </View>
      </View>
    );
  }

  getBasicPopUpContent(isTablet: any) {
    return (
      <View>
        <View style={[CommonStyles.flexDirectionRow]}>
          <View style={[CommonStyles.paddingBottom15, CommonStyles.width100]}>
            <Image
              style={[CommonStyles.closeMyAccountIcon]}
              source={CloseMyAccount}
            />
            <StandardText
              style={[
                isTablet ? CommonStyles.font20 : CommonStyles.font15,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
                CommonStyles.customFontBold,
              ]}
            >
              {messages.ASSISTANCE}
            </StandardText>
            <HeaderLine
              color={BrandColors.RESPONSIVE_DESIGN_COLORS.RED}
              style={[CommonStyles.marginTop5]}
            />
          </View>
        </View>

        <View
          style={[CommonStyles.phototileCorner, CommonStyles.paddingBottom15]}
        >
          <StandardText
            style={[
              CommonStyles.customFont,
              BrandStyles.TextColor5,
              isTablet ? CommonStyles.font18 : CommonStyles.rpfont15,
            ]}
          >
            Do you have questions or need help? Please call{' '}
            <StandardText
              style={[CommonStyles.customFontBold]}
              onPress={() => {
                Linking.openURL('tel:1300 05 78 78');
              }}
            >
              1300 05 78 78{' '}
            </StandardText>
            or email{' '}
            <StandardText
              style={[CommonStyles.customFontBold]}
              onPress={() => {
                Linking.openURL('mailto:crew@leapin.com.au');
              }}
            >
              crew@leapin.com.au
            </StandardText>
            .{'\n'}
          </StandardText>
          <StandardText
            style={[
              CommonStyles.paddingTop5,
              CommonStyles.customFont,
              BrandStyles.TextColor5,
              isTablet ? CommonStyles.font18 : CommonStyles.rpfont15,
            ]}
          >
            {messages.PERMANENTLY_CLOSE_ACTIVE_USER_LINE_2}
          </StandardText>
          <StandardText
            style={[
              CommonStyles.paddingTop5,
              CommonStyles.customFont,
              BrandStyles.TextColor5,
              isTablet ? CommonStyles.font18 : CommonStyles.rpfont15,
            ]}
          >
            {messages.PERMANENTLY_CLOSE_ACTIVE_USER_LINE_3}
          </StandardText>
        </View>

        <View style={[CommonStyles.subTitleSelectProvider]}>
          <View style={[CommonStyles.alignSelfCenter]}>
            <StandardButton
              onPress={() => this.setState({instructionModalVisible: false})}
              style={[
                BrandStyles.brandBlockTxtColor5,
                CommonStyles.buttonFormAction,
                CommonStyles.customFontBold,
              ]}
              containerStyle={[
                BrandStyles.primaryBgColor3,
                BrandStyles.borderColor3,
                CommonStyles.buttonContainerFormAction,
              ]}
              accessibilityLabel={types2.OK_GOT_IT}
            >
              {types2.OK_GOT_IT}
            </StandardButton>
          </View>
        </View>
      </View>
    );
  }
  logout() {
    this.props.actions.LogoutConfirmationActions.showConfirmLogout();
  }

  redirectToMBCPage() {
    this.props.setLoading(true);
    callAPIs(
      [authenticateMember()],
      (response: any) => {
        if (response && response[0]) {
          const payload = response[0].body;
          const parsedpayload = JSON.parse(payload);
          let baseUrl = parsedpayload.stores[0];
          baseUrl = baseUrl.substring(0, baseUrl.length - 1);
          const path = parsedpayload.path;
          const accessCode = parsedpayload.access_token;
          const url = baseUrl + path + '/?token=' + accessCode;
          this._openURL(url);
          this.props.setLoading(false);
        }
      },
      (err: any) => {
        logger.log('Login to MBC error: ', err);
        return this.props.setLoading(false);
      },
    );
  }

  //Checks which item in the FlatList was clicked and navigates to the matching screen.
  GetItem(item: any) {
    if (!this.state.isConnected) {
      this.props.actions.OfflineNoticeActions.shakeOfflineNotice();
    } else if (item === messages.CHANGE_MY_PASSWORD) {
      return this.props.navigation.navigate('ChangePassword');
    } else if (item === messages.LINK_MEMBER_TO_MY_ACCOUNT) {
      return this.props.navigation.navigate('CrewCreateMember', {
        screenMode: types.ADD_MEMBER,
      });
    } else if (item === messages.ABOUT + brand.APP_COMPANY_NAME) {
      this._openURL(brand.APP_ABOUT);
    } else if (item === messages.TERMS_OF_USE_AND_PRIVACY_POLICY) {
      this._openURL(brand.APP_SIGNUP_TERMS_AND_CONDITIONS_URL);
    } else if (item === messages.CLOSE_MY_ACCOUNT) {
      return this._confirmCloseAccount();
    } else if (item === messages.PLAN_MANAGED_TS_N_CS) {
      this._openURL(brand.APP_PLAN_MANAGER_TERMS_AND_CONDITIONS_URL);
    }
  }
  //confirm before closing an account
  _confirmCloseAccount() {
    this.setState({instructionModalVisible: true});
  }
  //check for internet connectivity before navigating to Webview

  _openURL = (url: any) => {
    if (this.state.isConnected) {
      
        window.open(url);
     
    } else {
      this.props.actions.OfflineNoticeActions.shakeOfflineNotice();
    }
  };

  /*
   * Will trigger the Lambda to deactivate account. If successful it will then call the
   * logoutUser function
   */
  _closeAccount = () => {
    this.setState({loading: true, instructionModalVisible: false});
    let deactiveteUserCallbackFunction = (data: any) => {
      this.logoutUser();
    };
    callAPIs(
      [
        deactiveteUser({
          id: this.props.user.id,
          email: this.props.user.email,
          firstname: this.props.user.firstName,
          lastname: this.props.user.lastName,
          deleteAllData: this.state.deleteAllData,
        }),
      ],
      deactiveteUserCallbackFunction,
      null,
      () => {
        this.setState({loading: false});
      },
    );
  };

  _deleteAllDataCheckboxHandler = () => {
    this.setState({
      deleteAllData: !this.state.deleteAllData,
    });
  };

  /*
   * Will log the user out of the app depending on how they logged in and navigate bac
   * to the pre-authenticaiton screen
   */
  logoutUser() {
    // this.setState({loading: true})
    // Check how they last logged in
    getData(types.LOGIN_LAST_TYPE).then(loginType => {
      switch (loginType) {
        // Based on how their logged in call the appropiate logout function
        // From the web service, then navigate to the pre authentication screen
        case types.LOGIN_TYPE_FACEBOOK:
          logoutFacebook();
          this.navigateToPreAuthentication();
          break;
        case types.LOGIN_TYPE_GOOGLE:
          logoutGoogle()
            .then(() => {
              this.navigateToPreAuthentication();
            })
            .catch(error => {
              Alert.alert(types2.ERROR, error);
            });
          break;
        case types.LOGIN_TYPE_EMAIL:
          logoutCognito()
            .then(result => {
              this.props.actions.MemberActions.actionUpdateMember({
                demoProfile: false,
                showCase: false,
              });
              this._navigateToPreAuth();
            })
            .catch(error => {
              Alert.alert(types2.ERROR, error);
            });
          break;
      }
    });
  }

  /*
   * Clear the redux store of your authentication details.
   * Then navigate back to the pre-authenticaiton screen
   * And set it to be the only item in the stack navigation
   */

  _navigateToPreAuth = () => {
    getDataLoggedIn(types.PREVIOUSLY_SIGNIN).then(alreadyloggedIn => {
      const actionToDispatch = CommonActions.reset({
        index: 0,
        routes: [
          {
          name : 'Notifications',    
          params: {
          screenMode: alreadyloggedIn
            ? types.LOGINSIGNUP_MODE_LOGIN
            : types.LOGINSIGNUP_MODE_SIGNUP,
          }}
          // CommonActions.navigate({
          //   name: 'Notifications',
          //   params: {
          //     screenMode: alreadyloggedIn
          //       ? types.LOGINSIGNUP_MODE_LOGIN
          //       : types.LOGINSIGNUP_MODE_SIGNUP,
          //   },
          // })
        ],
      });
      
        this.props.navigation.navigate(welcome, null, types.isWeb);
    
    });
  };

  _crewCloseAccountCheck = () => {
    let closeAccount = true;

    if (this.props.user.demoProfile) {
      closeAccount = false;
    }
    return closeAccount;
  };

  _renderCloseMyAccButton = () => {
    if (this._crewCloseAccountCheck()) {
      return (
        <View style={[CommonStyles.flex05, CommonStyles.marginBottom20]}>
          <StandardTouchableOpacity
            activeOpacity={0.8}
            onPress={this.GetItem.bind(this, messages.CLOSE_MY_ACCOUNT)}
            accessibilityLabel={messages.CLOSE_MY_ACCOUNT}
            style={[
              CommonStyles.settingsTileWrapper,
              BrandStyles.primaryBgColor1,
            ]}
          >
            <View style={[CommonStyles.settingsButtonContainer]}>
              <Image
                style={[CommonStyles.settingsButtonImage]}
                source={CloseAccountRedx4}
              />
              <StandardText
                style={[
                  CommonStyles.settingsButtonText,
                  CommonStyles.customFont,
                ]}
              >
                {messages.CLOSE_MY_ACCOUNT}
              </StandardText>
            </View>
          </StandardTouchableOpacity>
        </View>
      );
    } else {
      return null;
    }
  };
}

const mapStateToProps = (state: any) => ({
  auth: state.AuthenticationReducer,
  user: state.UserReducer,
  offline: state.OfflineNoticeReducer,
  logout: state.LogoutReducer,
  member: state.MemberReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    OfflineNoticeActions: bindActionCreators(OfflineNoticeActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
    MemberActions: bindActionCreators(MemberActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
