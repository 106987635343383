/*
 * Author: Nirodha Perera
 * Date: 29/02/2019
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 */

import React, {Component} from 'react';
import {View} from 'react-native';
import {WEB_LEFT_NAVIGATION_WIDTH} from '../../Constants/Constants2';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';

//Import Utils
import {calculateDimensions} from '../../Helpers/Utils';

const WIDTH_OFFSET = 50;
class GridView extends Component {
  state = {
    orientation: '',
  };

  constructor(props: any) {
    super(props);
    this._renderGrid.bind(this);
  }

  render() {
    return (
      <View
        style={[
          CommonStyles.flex1,
          CommonStyles.justifyCenter,
          this.props.style,
        ]}
      >
        {this._renderGrid(this.props.children)}
      </View>
    );
  }

  _renderGrid(children: any) {
    let {itemsPerRow} = calculateDimensions(
      this.props.itemDimension,
      this.props.staticDimension,
      this.props.viewWidth,
    );

    if (this.props.maximumColumns && itemsPerRow > this.props.maximumColumns) {
      itemsPerRow = this.props.maximumColumns;
    }

    if (this.props.isStatic && this.props.maximumColumns) {
      itemsPerRow = this.props.maximumColumns;
    }

    let itemWidth = 0;

    const totalPaddingWeb = 310;
    const totalPaddingMobile = 30;

    if (!this.props.isMobile) {
      itemWidth = (this.props.viewWidth - totalPaddingWeb) / itemsPerRow;
    } else {
      itemWidth = (this.props.viewWidth - totalPaddingMobile) / itemsPerRow;
    }

    let rows = [];
    let i = 0;
    children = children.filter(function (element: any) {
      return element !== null;
    });
    const maxColumns =
      itemsPerRow > children.length ? children.length : itemsPerRow;
    while (i < children.length) {
      rows.push(
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.justifyContentFlexStart,
          ]}
        >
          {this._renderRow(i, maxColumns, itemWidth, children)}
        </View>,
      );
      i = parseInt(i) + parseInt(maxColumns);
    }
    return rows;
  }

  _renderRow(i: any, maxColumns: any, itemWidth: any, children: any) {
    let columns = [];
    for (let j = 0; j < maxColumns; j++) {
      let child = children[parseInt(i) + parseInt(j)];
      if (this.props.renderItem && child) {
        child = this.props.renderItem(child);
      }
      columns.push(<View style={{width: itemWidth}}>{child}</View>);
    }
    return columns;
  }

  _determinChildWidth(maxColumns: any) {
    let styles = [];
    const minusNavWidth = WEB_LEFT_NAVIGATION_WIDTH + WIDTH_OFFSET;
    if (this.props.childDimension && this.props.viewWidth && maxColumns) {
      styles.push({
        width: (this.props.viewWidth - minusNavWidth) / maxColumns,
      });
    }
    return styles;
  }
}

GridView.defaultProps = {
  itemDimension: 120,
};

export default GridView;
