/*
Author: Declan Hart
Date: 11/1/18
Copyright © 2017 LeapIn. All rights reserved.

The Support Category Selector component, displays the categories for supports to filter the support items.
*/

import React, {Component} from 'react';
import {View} from 'react-native';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import {StandardText} from './';
import {CheckBoxButton} from '../Molecules';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';

let selectedOptions;
class MultiSelectDriverQuestion extends Component {
  state = {
    selectedOption: -1,
    text: types.ACTION_BUTTON.EDIT,
    selectedOptions: this.props.selectedResponse
      ? this.props.selectedResponse
      : [],
    data: this.props.driver.values,
    type: this.props.driver.driverCode,
  };

  constructor(props: any) {
    super(props);
    this.renderSelections = this.renderSelections.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  determineIndex(option: any) {
    selectedOptions = this.state.selectedOptions;
    var index = -1;
    selectedOptions.filter((o: any, idx: any, array: any) => {
      if (o.value === option.description) {
        index = idx;
        return true;
      } else {
        return false;
      }
    });
    return index;
  }

  renderSelections() {
    let result: any = [];
    if (this.props.selectedResponse && this.props.selectedResponse.length > 0) {
      this.state.text = types.ACTION_BUTTON.EDIT;
      this.props.selectedResponse.map((response: any) => {
        result.push(response.value);
      });
    } else {
      this.state.text = types.ACTION_BUTTON.ADD;
    }
    return result.join(', ');
  }

  selectOption(option: any) {
    let selectedOptions = this.state.selectedOptions;
    // Update the option to include key value name-value to work with AddSupportDetails screen
    option.value = option.description;

    if (!this.determineSelected(option)) {
      selectedOptions.push(option);
      this.props.save(this.state.type, option);
    } else if (this.determineSelected(option)) {
      selectedOptions.splice(this.determineIndex(option), 1);
      this.props.save(this.state.type, option);
    }
    this.setState({
      selectedOptions: selectedOptions,
    });
  }

  determineSelected(option: any) {
    selectedOptions = this.state.selectedOptions;

    // Adding one will have the option as {key: , description: }
    if (
      selectedOptions.filter((o: any) => o.value === option.description)
        .length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  renderOptions() {
    return this.state.data.map((option: any, index: any) => {
      return (
        <CheckBoxButton
          key={index}
          data={option}
          circular={true}
          iconStyle={[CommonStyles.checkBoxIcon_Segmented]}
          textStyle={[CommonStyles.customFont, CommonStyles.font18]}
          columns={1}
          readOnly={this.props.readOnly}
          isSelected={this.determineSelected(option)}
          onChange={this.selectOption}
        />
      );
    });
  }

  render() {
    return (
      <View style={CommonStyles.spaceAround}>
        <View style={[CommonStyles.marginBottom5]}>
          <StandardText
            style={[
              CommonStyles.title_SupportQuestions,
              CommonStyles.customFontBold,
            ]}
          >
            {this.props.driver.driverQuestion}
          </StandardText>
        </View>
        <View
          style={[
            CommonStyles.paddingLeft10,
            CommonStyles.paddingRight10,
            CommonStyles.flexWrap,
            CommonStyles.justifyFlexRowStart,
          ]}
        >
          {this.renderOptions()}
        </View>
      </View>
    );
  }
}

export default MultiSelectDriverQuestion;
