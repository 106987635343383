/*
 * Author: Declan Hart
 * Date: 28/04/2020
 * Copyright © 2020 Leap in!. All rights reserved.
 *
 * Component to let user know we're attempting to pre-authenticate
 */

import React from 'react';

import {View, ActivityIndicator} from 'react-native';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {BrandColors} from '../../Styles/Colours';
import {StandardText} from '../Atoms';

const PreAuthenticationCheckMessage = (props: any) => {
  return (
    <View
      style={[
        BrandStyles.preAuthenticationBlockContainerSolid,
        CommonStyles.loadingModal,
        CommonStyles.navigationConfirmModal,
        BrandStyles.borderColor6,
        CommonStyles.preAuthMessageBox,
        props?.styles || {}
      ]}
    >
      <ActivityIndicator
        color={BrandColors.RESPONSIVE_DESIGN_COLORS.RED}
        size="large"
      />
      <StandardText
        style={[CommonStyles.marginTop10, CommonStyles.buttonFormAction]}
      >
        Checking your previous session
      </StandardText>
    </View>
  );
};

export default PreAuthenticationCheckMessage;
