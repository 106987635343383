/*
 * Author: Shelan Cooray
 * Date: 05/03/2019
 * Copyright © 2019 Leap in!. All rights reserved.
 *
 * This component is to facilitate accordian behaviour of Web Side Menu
 */

import React from 'react';
import {View} from 'react-native';
import {WebMembers} from '../Organisms';

import {MEMBERS} from '../../Constants/Constants2';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import CustomIcon from '../../assets/images/CustomIcon';
import { StandardText, StandardTouchableOpacity } from '../Atoms';


class WebAccordianMenu extends React.Component {
  constructor(props: any) {
    super(props);
    const openSections = {};
    this.state = {
      collapsed: false,
      openSections,
    };
  }

  render() {
    let {buttonBackground, textColor, border} = this.determineStyles(
      this.props.isOpen,
      this.props.item.id === this.props.isItemHighLighted,
    );
    return (
      <View
        id={this.props.item.id}
        style={this.props.item.id === MEMBERS ? CommonStyles.zIndex01 : null}
      >
        <View>
          <StandardTouchableOpacity
            onPress={() => {
              this.props.onPress(this.props.item.id);
              this.props.navigateToScreen(
                this.props.item.id,
                this.props.isMobile,
              );
            }}
            onLayout={({nativeEvent}: any) => {
              this.props.layoutInfo(this.props.item.id, nativeEvent);
            }}
            style={[
              CommonStyles.containerMenuItem,
              buttonBackground,
              border,
              CommonStyles.containerMainMenuOption,
            ]}
          >
            <StandardText
              style={[
                textColor,
                CommonStyles.font18,
                CommonStyles.marginRight10,
              ]}
            >
              {this.props.item.title}
            </StandardText>
            <CustomIcon
              style={[
                this.props.isOpen
                  ? CommonStyles.mainMenuIconSelected
                  : CommonStyles.mainMenuIcon,
                CommonStyles.zIndex02Notification,
                CommonStyles.marginLeft5,
              ]}
              name={this.props.item.image}
            />
          </StandardTouchableOpacity>
        </View>
        {this._renderChildren(
          this.props.item,
          this.props.isMobile,
          this.props.isOpen,
        )}
      </View>
    );
  }

  _renderChildren = (item: any, isMobile: any, isOpen: any) => {
    if (!isOpen) {
      return null;
    } else {
      if (item.id === MEMBERS) {
        return (
          <View style={[CommonStyles.zIndex100]}>
            <WebMembers
              user={this.props.user}
              nav={this.props.navigation}
              setLoading={this.props.setLoading}
            />
          </View>
        );
      }
      return this.props.getSecondaryMenuItems(isMobile, item.id);
    }
  };

  determineStyles = (isOpen: any, isItemHighLighted: any) => {
    let buttonBackground = BrandStyles.primaryBtnColor1;
    let textColor = BrandStyles.TextColor1;
    let border = null;

    if (isOpen) {
      buttonBackground = BrandStyles.secondaryBtnColor1;
      textColor = BrandStyles.TextColor3;
      border = CommonStyles.borderPrimaryMenu;
    }
    if (isItemHighLighted) {
      border = BrandStyles.borderColor12;
      buttonBackground = BrandStyles.brandBlockBgColor9;
    }

    return {buttonBackground, textColor, border};
  };
}

export default WebAccordianMenu;
