/*
 * Author: Declan Hart
 * Date: 12/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Manage Participants Screen, this is the Manage Participants main section.
 */

import React, {Component} from 'react';
import {Text, View, ScrollView, StyleSheet} from 'react-native';

import ProfileTile from '../Molecules/ProfileTile';
import BrandActivityIndicator from '../Molecules/BrandActivityIndicator';
import {SectionHeader, SummaryTile, GridView} from '../Molecules';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {StandardText} from '../Atoms';
import {MembersRedx3} from '../../assets/images/vector-icons';

class ManageParticipantsMembers extends Component {
  state = {
    loading: false,
    length: 0,
  };

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <View style={[CommonStyles.content]}>
        <SummaryTile
          disableMore={true}
          headerDisplayString={types2.LINKED_MEMBERS}
          headerIconImage={MembersRedx3}
          HeaderTextColor={BrandStyles.headerContainsDataText}
          HeaderBoarderColor={BrandStyles.headerContainsDataBorder}
          modal={this.props.add}
          hideYellowStar={true}
        />
        <View style={[CommonStyles.mainContentContainer]}>
          {this.props.members.length == 0 ? (
            <View style={[CommonStyles.content]}>
              <StandardText style={CommonStyles.customFont}>
                You have no members added yet. Click “Add member” button to add.
              </StandardText>
            </View>
          ) : (
            <View style={[CommonStyles.profileTilesContainer]}>
              <View style={CommonStyles.alignItemsCenter}>
                <StandardText
                  style={[
                    CommonStyles.textResultSummary,
                    CommonStyles.customFontBold,
                    BrandStyles.TextColor13,
                  ]}
                >
                  {this.props.resultSummary}
                </StandardText>
              </View>
              <GridView
                style={[
                  CommonStyles.paddingBottom10,
                  CommonStyles.paddingRight5,
                  CommonStyles.paddingLeft2,
                ]}
              >
                {this.getPersonTileArray(this.props.members)}
              </GridView>
            </View>
          )}
        </View>
      </View>
    );
  }

  getPersonTileArray(personArray: any) {
    return personArray.map((person: any) => {
      return <ProfileTile nav={this.props.navigate} person={person} />;
    });
  }
}

export default ManageParticipantsMembers;
