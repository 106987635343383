/*
 * Authour: Andrew Lee
 * Date: 09/01/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is the action for crews for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const actionGetCrews = (
  crews,
  personCrewMembers,
  organisationCrewMembers,
  crewAccessLevels,
) => ({
  type: types.GET_CREWS,
  crews,
  personCrewMembers,
  organisationCrewMembers,
  crewAccessLevels,
});

export const actionWhoILiveWithCrew = (newCrewId: any) => ({
  type: types2.LIVE_WITH_NEW_CREW,
  newCrewId,
});

export const removeWhoILiveWIthCrew = () => ({
  type: types2.REMOVE_LIVE_WITH_NEW_CREW,
});

export const actionGetCrewCategories = (crewCategories: any) => ({
  type: types.GET_CREW_CAT,
  crewCategories,
});

export const addEditCrewToList = (crew: any) => ({
  type: types.CREWS.ADD_EDIT_CREW_LIST,
  crew,
});

export const deleteCrewFromList = (crew: any) => ({
  type: types.CREWS.DELETE_CREW_FROM_LIST,
  crew,
});

export const setCurrentCrew = (crew: any) => ({
  type: types.CREWS.SET_CREW_ITEM,
  crew,
});

export const setLastUpdated = () => ({
  type: types.CREWS.CREW_UPDATED,
});
