/*
 * Author: Nirodha Perera
 * Date: 10/12/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The screen to display selected goal preview summary
 */

import React, {Component} from 'react';
import {View, StyleSheet, Alert, Image} from 'react-native';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

//import {NavigationActions} from 'react-navigation-awseeley--enhanced';
import {CommonActions, StackActions} from '@react-navigation/native';
import {OfflineNotice, GoalPreview} from '../Components/Organisms';
import {
  SummaryTile,
  Container,
  CrewNoAccess,
  BasicOverlayPopupModal,
} from '../Components/Molecules';
import {StandardText, StandardTouchableOpacity} from '../Components/Atoms';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {callAPIs} from '../API/APICaller';
import {uploadImageToS3, setImageRedux} from '../Helpers/ImageHelper';
import {getUploadURL, getAvatarImages} from '../API/MemberAPI';
import {getGoalTemplateReference} from '../API/ReferenceAPI';
import {setValue, getValue, removeItem} from '../API/WebPersistenceStore';

import {
  getGoalsForPlan,
  deleteGoalForPlan,
  putGoalForPlan,
  getGoalProgress,
} from '../API/PlanDataAPI';
import CustomIcon from '../assets/images/CustomIcon';
import * as GoalActions from '../Actions/GoalActions';
import * as AvatarListActions from '../Actions/AvatarListActions';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {NailedIt, GreyAvatarWithHat} from '../assets/images';
import logger from 'helpers/Logger';

import {
  goalsIconRed,
  CameraWhite,
  ThumbsUpRed,
} from '../assets/images/vector-icons';
import {isTablet} from '../Helpers/PlatformSynchronizer';
import {isEmpty, find} from 'lodash';

const home = 'Profile';
const goal = 'GoalSummary';
const addGoals = 'AddGoals';
const CURRENT_GOAL_ID = 'currentGoalID';
const CURRENT_GOAL = 'currentGoal';
class GoalPreviewSummary extends Component {
  state = {
    isLoading: false,
    approveDeleteModal: false,
    approveDoneModal: false,
  };

  constructor(props: any) {
    super(props);
    this.navigateGoalEdit.bind(this);
    this.openApprovePopUp.bind(this);
    this.closeApprovePopUp.bind(this);
    this.renderApprovePopUp.bind(this);
    this.deleteGoalItem.bind(this);
    this.getAddGoalImagePopup.bind(this);
    this.completeGoal.bind(this);
    this.renderPopupContent.bind(this);
    this.navigateToGoalSummary.bind(this);
    this.addGoal.bind(this);
    this.determineAccess.bind(this);
    this._navigateAddGoalImage.bind(this);
  }

  componentDidMount() {
    if (types.isWeb) {
      this._fetchData();
    }
  }

  _setLoading = (loadingState: any) => {
    if (types.isWeb) {
      this.props.setLoading(loadingState);
    } else {
      this.setState({isLoading: loadingState});
    }
  };

  _fetchData = () => {
    this._setLoading(true);

    // Make sure we have the goal template data
    const callBack = (data: any) => {
      const goalsPayload = data[0].content.currentGoals;
      const templatePayload = data[1].content.templates;
      const currentGoalItem = find(goalsPayload, [
        'currentGoalId',
        parseInt(getValue('currentGoalID')),
      ]);

      this.props.actions.GoalActions.actionGetGoals(goalsPayload);
      this.props.actions.GoalActions.getGoalTemplates(templatePayload);

      // Update redux with the current goal
      this.props.actions.GoalActions.editExistingGoal(currentGoalItem);
      if (types.isWeb) {
        removeItem(types2.WEB_STORE.GOALS);
        setValue(types2.WEB_STORE.GOALS, JSON.stringify(currentGoalItem));
      }
      this._setLoading(false);
      this._setLoading(false);
    };

    callAPIs(
      [
        getGoalsForPlan(
          this.props.member.planId,
          this.props.loadedMemberId.loadedMemberId,
          this.props.user.demoProfile,
        ),
        getGoalTemplateReference(),
      ],
      callBack,
      null,
      () => {
        this._setLoading(false);
      },
    );
  };

  render() {
    // If we are a crew & there is no loaded member ID lockout the screen
    if (!this.props.member.id && this.props.user.isCrew) {
      return (
        <View style={[CommonStyles.content]}>
          <OfflineNotice />
          <CrewNoAccess />
        </View>
      );
    }
    if (types.isWeb) {
      return this.createMainContents();
    }
    return (
      <Container
        contents={this.createMainContents}
        needsSidebar={false}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        modals={() => [this.renderApprovePopUp()]}
        loading={this.state.isLoading}
        headerTitle={types2.NAVIGATION_TEXT.GOAL_DETAIL}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  createMainContents = () => {
    return (
      <View style={[CommonStyles.singleMainContentContainer]}>
        <View accessible={false} style={[CommonStyles.columnContainer]}>
          <SummaryTile
            headerDisplayString={types2.GOAL_SCREEN_LABELS.PREVIEW_GOAL}
            headerIconImage={goalsIconRed}
            HeaderTextColor={BrandStyles.TextColor3}
            HeaderBoarderColor={BrandStyles.borderColor3}
            hideYellowStar={true}
            showButton={false}
            fontSize={CommonStyles.rpfont20}
            disableMore={true}
          />
          {this.renderGoalPreview()}

          {this.determineAccess() &&
            this.renderActionButton(
              CameraWhite,
              this.getAddGoalImagePopup,
              types2.GOAL_SCREEN_LABELS.ADD_PHOTO,
            )}
          {this.renderActionButton(
            goalsIconRed,
            this.updateGoalProgress,
            this.determineAccess()
              ? types2.GOAL_SCREEN_LABELS.UPDATE_PROGRESS
              : types2.GOAL_SCREEN_LABELS.VIEW_PROGRESS,
          )}
          {this.determineAccess() &&
            this.renderActionButton(
              ThumbsUpRed,
              this.completeGoal,
              types2.GOAL_SCREEN_LABELS.DONE,
            )}
          {types.isWeb ? this.renderApprovePopUp() : null}
        </View>
      </View>
    );
  };

  renderGoalPreview = () => {
    if (
      this.props.goals &&
      !isEmpty(this.props.goals) &&
      this.props.goals.currentGoal
    ) {
      return (
        <View>
          <GoalPreview
            readOnly={!this.determineAccess()}
            onPress={this.navigateGoalEdit}
            goalItem={this.props.goals.currentGoal}
            deleteGoal={this.openApprovePopUp}
            navigation={this.props.navigation}
            actionCallback={this._navigateAddGoalImage}
          />
        </View>
      );
    }
  };

  _navigateAddGoalImage = () => {
    const navigationParams = this.props.actions.NavigationParamActions.setParam(
      {
        imageObject: this.props.goals.currentGoal.goalPhoto,
        headerTitle: types2.ADD_GOAL_PICTURE_TITLE,
        photoType: types2.FILE_TYPE.GOAL_PHOTO,
        goalItem: this.props.goals.currentGoal,
      },
    );
    if (types.isWeb) {
      removeItem(types2.WEB_STORE.NAVIGATION_PARAMS);
      setValue(
        types2.WEB_STORE.NAVIGATION_PARAMS,
        JSON.stringify(navigationParams),
      );
    }
    this.props.navigation.navigate('ProfilePictureForm');
    this.getAvatarImages();
  };

  getAvatarImages = () => {
    const callbackFunction = (data: any) => {
      const avatarList = data[0].content;
      this.props.actions.AvatarListActions.actionGetAvatarList(avatarList);

      if (types.isWeb) {
        removeItem(types2.WEB_STORE.AVATAR_LISTOBJ);
        setValue(types2.WEB_STORE.AVATAR_LISTOBJ, JSON.stringify(avatarList));
      }
    };

    callAPIs(
      [getAvatarImages(this.props.loadedMemberId.loadedMemberId)],
      callbackFunction,
      null,
      () => {
        this.setState({isLoading: false});
      },
    );
  };

  getAddGoalImagePopup = () => {
    this._navigateAddGoalImage();
  };

  updateGoalProgress = () => {
    const goalItem = this.props.goals.currentGoal;
    this._setLoading(true);

    const getGoalProgressCallbackFunction = (data: any) => {
      const goalProgress = data[0].content.goalProgress;
      this.props.actions.GoalActions.getGoalProgress(goalProgress);
      this._setLoading(false);
      this.props.actions.NavigationParamActions.setParam({
        currentGoal: goalItem,
      });
      if (types.isWeb) {
        removeItem('currentGoal');
        removeItem('currentGoalProgress');
        setValue('currentGoal', JSON.stringify(goalItem));
        setValue('currentGoalProgress', JSON.stringify(goalProgress));
      }
      this.props.navigation.navigate('GoalProgress');
    };

    callAPIs(
      [
        getGoalProgress(
          this.props.loadedMemberId.loadedMemberId,
          goalItem.currentGoalId,
          this.props.user.demoProfile,
        ),
      ],
      getGoalProgressCallbackFunction,
      null,
      () => {
        this._setLoading(false);
      },
    );
  };

  completeGoal = () => {
    this._setLoading(true);
    const goalObject = this.props.goals.currentGoal;
    goalObject.id = this.props.member.id;
    goalObject.planId = this.props.member.planId;

    // Set the goal status to completed
    goalObject.isActive = false;

    const putGoalForPlanCallback = (data: any) => {
      const payload = data[0];
      this._setLoading(false);
      goalObject.currentGoalId = payload.content.currentGoalId;
      this.props.actions.GoalActions.updateGoalInList(goalObject);
      this.setState({approveDoneModal: true});
    };

    callAPIs(
      [putGoalForPlan(goalObject, this.props.user.demoProfile)],
      putGoalForPlanCallback,
      null,
      () => {
        this._setLoading(false);
      },
    );
  };

  renderActionButton = (icon: any, action: any, label: any) => {
    return (
      <View style={CommonStyles.containerGoalActionButton}>
        <StandardTouchableOpacity
          activeOpacity={0.8}
          onPress={action}
          accessibilityLabel={types2.GOAL_SCREEN_LABELS.ADD_GOAL}
          style={[CommonStyles.buttonGoalActions, BrandStyles.primaryBgColor1]}
        >
          <View style={[CommonStyles.flexRow, CommonStyles.alignItemsCenter]}>
            <CustomIcon
              style={[CommonStyles.titleBoxIcon, CommonStyles.margin10]}
              name={icon}
            />
            <StandardText
              style={[
                CommonStyles.textGoalButton,
                CommonStyles.customFontBold,
                BrandStyles.TextColor3,
              ]}
            >
              {label}
            </StandardText>
          </View>
        </StandardTouchableOpacity>
      </View>
    );
  };

  navigateGoalEdit = () => {
    // Update redux with the current goal
    if (types.isWeb) {
      removeItem('currentGoal');
      setValue('currentGoal', JSON.stringify(this.props.goals.currentGoal));
    }
    this.props.actions.GoalActions.editExistingGoal(
      this.props.goals.currentGoal,
    );

    // Navigate to the page
    this.props.actions.NavigationParamActions.setParam({
      isFilterApplied: false,
    });
    this.props.navigation.navigate(addGoals, {
      screenMode: types.SCREEN_MODE_EDIT,
    });
  };

  // Save the goal image to a signed URL
  saveGoalImage(goalItem: any, completionHandler: any) {
    // Build the request object for the image upload
    let requestObject = this.props.goals.currentGoal.imageUploadObject;
    requestObject = {
      ...requestObject,
      forGoal: goalItem.currentGoalId,
    };

    // Creating the callback function when the URL is returned
    const getUploadURLCallbackFunction = (data: any) => {
      const url = data[0].content.uploadURL;
      uploadImageToS3(url, requestObject.contentType, requestObject.path)
        .then(response => {
          // Add the goal image to the goal in redux
          const goalImageObject = {
            lastModified: new Date(),
            key: requestObject.localUri,
            url: requestObject.localUri,
            localImage: true,
          };
          const goalId = goalItem.currentGoalId;

          // Find the goal in redux for the goals list, to add the image to it
          // So it will render on the goal summary screen
          this.props.actions.GoalActions.setGoalImageInList(
            goalId,
            goalImageObject,
          );

          completionHandler();
        })
        .catch(error => {
          logger.log('The error is:', error);
          Alert.alert(`Error: ${error.code} Message: ${error.message}`);
        });
    };

    // Call the API to get the signed URL
    callAPIs(
      [getUploadURL(this.props.loadedMemberId.loadedMemberId, requestObject)],
      getUploadURLCallbackFunction,
      null,
      () => {
        this.setState({loading: false});
      },
    );
  }

  openApprovePopUp = () => {
    this.setState({approveDeleteModal: true});
  };

  closeApprovePopUp = () => {
    this.setState({approveDeleteModal: false});
  };

  determinePopUpStyle = (isTablet: any) => {
    const styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      styles.push([CommonStyles.containerInstructionPopUpWeb]);

      if (this.props.isMobile) {
        styles.push([
          CommonStyles.containerPopupWebMobile,
          CommonStyles.popUpWebMobileTop,
        ]);
      }
    } else if (isTablet()) {
      styles.push([
        CommonStyles.containerInstructionPopUpTablet,
        this.determinePopUpHeight(),
      ]);
    } else {
      styles.push(
        CommonStyles.containerInstructionPopUpMobile,
        this.determinePopUpHeight(),
      );
    }
    return styles;
  };

  determinePopUpHeight = (isTablet: any) => {
    if (this.state.approveDoneModal) {
      if (isTablet) {
        return CommonStyles.height450;
      } else {
        return CommonStyles.height380;
      }
    } else {
      if (isTablet) {
        return CommonStyles.height300;
      } else {
        return CommonStyles.height230;
      }
    }
  };

  deleteGoalItem = () => {
    this.setState({approveDeleteModal: false});
    this._setLoading(true);

    const deleteGoalForPlanCallback = () => {
      // Remove from redux
      this.props.actions.GoalActions.deleteExistingGoalInList(
        this.props.goals.currentGoal.currentGoalId,
      );

      this._setLoading(false);
      if (types.isWeb) {
        // Navigate back to the goal summary page
        this.props.navigation.navigate('Goal', null, types.isWeb);
      } else {
        // Navigate back to the goal summary page
        this.props.navigation.goBack();
      }
    };

    callAPIs(
      [
        deleteGoalForPlan(
          this.props.member.planId,
          this.props.loadedMemberId.loadedMemberId,
          this.props.goals.currentGoal.currentGoalId,
          this.props.user.demoProfile,
        ),
      ],
      deleteGoalForPlanCallback,
      null,
      () => {
        this._setLoading(false);
      },
    );
  };

  renderPopupContent = () => {
    if (this.state.approveDoneModal) {
      return (
        <View style={[CommonStyles.justifyFlexColumnStart]}>
          <View style={[CommonStyles.alignItemsCenter]}>
            <StandardText
              style={[
                types.isWeb ? CommonStyles.font18 : CommonStyles.font20,
                BrandStyles.TextColor10,
                CommonStyles.customFontSemiBold,
                CommonStyles.paddingBottom20,
              ]}
            >
              {types2.GOAL_SCREEN_LABELS.GOAL_COMPLETE}
            </StandardText>
          </View>
          <View style={CommonStyles.photoTileContainer}>
            <Image
              style={CommonStyles.imageGoalCompleteAvatar}
              source={GreyAvatarWithHat}
            />
            <Image
              style={CommonStyles.imageGoalCompleteText}
              source={NailedIt}
            />
          </View>
          <View style={[CommonStyles.alignItemsCenter]}>
            <StandardText
              style={[
                types.isWeb ? CommonStyles.font15 : CommonStyles.font18,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
                CommonStyles.paddingBottom10,
                CommonStyles.paddingTop10,
                CommonStyles.textAlignCentre,
              ]}
            >
              {types2.GOAL_SCREEN_LABELS.COMPLETE_MESSAGE}
            </StandardText>
          </View>
        </View>
      );
    } else {
      return (
        <View style={[CommonStyles.justifyFlexColumnStart]}>
          <View style={[CommonStyles.alignItemsCenter]}>
            <StandardText
              style={[
                types.isWeb ? CommonStyles.font18 : CommonStyles.font20,
                BrandStyles.TextColor10,
                CommonStyles.customFontSemiBold,
                CommonStyles.paddingBottom20,
              ]}
            >
              {types2.GOAL_SCREEN_LABELS.DELETE_GOAL}
            </StandardText>
          </View>
          <View style={[CommonStyles.alignItemsCenter]}>
            <StandardText
              style={[
                types.isWeb ? CommonStyles.font15 : CommonStyles.font18,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
                CommonStyles.paddingBottom20,
                CommonStyles.textAlignCentre,
              ]}
            >
              {types2.GOAL_SCREEN_LABELS.DELETE_MESSAGE}
            </StandardText>
          </View>
        </View>
      );
    }
  };

  navigateToGoalSummary = () => {
    this.setState({approveDoneModal: false});
    if (types.isWeb) {
      this.props.navigation.navigate(goal, null, types.isWeb);
    } else {
      const actionToDispatch = CommonActions.reset({
        index: 1,
        routes: [
          {name: home},
          {name: goal}
        ],
      });
      setTimeout(() => {
        this.props.navigation.dispatch(actionToDispatch);
     }, 2000);
    }
  };

  addGoal = () => {
    this.setState({approveDoneModal: false});
    this.props.actions.GoalActions.addNewGoal(
      this.props.member.id,
      this.props.member.planId,
    );
    if (types.isWeb) {
      removeItem(CURRENT_GOAL);
      removeItem(CURRENT_GOAL_ID);
      this.props.navigation.navigate(
        addGoals,
        {
          screenMode: types.SCREEN_MODE_NEW,
        },
        types.isWeb,
      );
    } else {
      const actionToDispatch = CommonActions.reset({
        index: 2,
        routes: [
          {name: home},
          {name: goal},
          {name: addGoals, params: {
            screenMode: types.SCREEN_MODE_NEW,
          } 
        }
        ],
      });
      setTimeout(() => {
        this.props.navigation.dispatch(actionToDispatch);
     }, 2000);
    }
  };

  renderApprovePopUp = () => {
    let close = this.closeApprovePopUp;
    let save = this.deleteGoalItem;
    let cancelLabel = types2.CANCEL;
    let saveLabel = types2.DELETE;

    if (this.state.approveDoneModal) {
      close = this.navigateToGoalSummary;
      save = this.addGoal;
      cancelLabel = types2.BACK;
      saveLabel = types2.GOAL_SCREEN_LABELS.NEW_GOAL;
    }

    return (
      <BasicOverlayPopupModal
        visible={this.state.approveDeleteModal || this.state.approveDoneModal}
        style={this.determinePopUpStyle(isTablet)}
        backdropOpacity={0.2}
        divider={false}
        close={close}
        cancelLabel={cancelLabel}
        saveLabel={saveLabel}
        save={save}
      >
        {this.renderPopupContent()}
      </BasicOverlayPopupModal>
    );
  };

  determineAccess() {
    if (this.props.member.access != undefined) {
      return this.props.member.access.profile == types.ACCESS_FULL
        ? true
        : false;
    }
  }
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  goals: state.GoalReducer,
  loadedMemberId: state.LoadedMemberReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    AvatarListActions: bindActionCreators(AvatarListActions, dispatch),
    GoalActions: bindActionCreators(GoalActions, dispatch),
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GoalPreviewSummary);
