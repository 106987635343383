/**
 * Author: Sujeban Elankeswaran
 * Date: 20/12/2019
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This shows the Referral Providers About Screen.
 */

import React, {Component} from 'react';
import {ScrollView, View, Image, Platform} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
//import {NavigationActions} from 'react-navigation-awseeley--enhanced';
import {CommonActions, StackActions} from '@react-navigation/native';
import DeviceInfo from 'react-native-device-info';
import {
  StandardText,
  StandardInput,
  StandardButton,
  StandardAlert,
} from '../Components/Atoms';
import {
  Container,
  CancelSaveButtons,
  BasicOverlayPopupModal,
} from '../Components/Molecules';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {
  MediumRedTickCircleRed3x,
  MediumRedEmptyCircleRed3x,
  TickCircleGray,
  InformationIconx3,
} from '.././assets/images';
import {sendEmailProvider} from '../API/ProviderAPI';
import {callAPIs} from '../API/APICaller';
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {
  PROVIDER_ENQUIRY as providerEnquiryMessages,
  MESSAGES,
} from '../Constants/Messages';

import {getProviderBySysId} from '../API/ProviderAPI';
import * as SupportsActions from '../Actions/SupportsActions';
import * as BudgetActions from '../Actions/BudgetActions';
import * as PayInvoiceActions from '../Actions/PayInvoiceActions';
import * as MemberActions from '../Actions/MemberActions';
import * as ProviderActions from '../Actions/ProviderActions';
import * as EnquiryActions from '../Actions/EnquiryActions';
import * as GeneralActions from '../Actions/GeneralActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
// import Orientation from 'react-native-orientation-enhanced';

class ProviderEnquiry extends Component {
  state = {
    loading: true,
    orientation: '',
    isOpen: this.props.sideMenu ? this.props.sideMenu.sideMenuIsOpen : false,
    refreshing: false,
    disabled: false,
    enquiryDetails: [],
    memberDescription: '',
    providerid: '',
    showPopupAlert: false,
  };

  constructor(props: any) {
    super(props);
    this._createMainContents = this._createMainContents.bind(this);
    this.onRefresh = this.onRefresh.bind();
  }

  // UNSAFE_componentWillMount() {
  //   if (!types.isWeb) {
  //     Orientation.getOrientation((err: any, orientation: any) => {
  //       this.setState({orientation});
  //     });
  //   }
  // }
  componentWillUnmount() {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
    if (!types.isWeb) {
      this.props.actions.EnquiryActions.actionMemberAddressChecked(true);
      this.props.actions.EnquiryActions.actionMemberPhoneNumberChecked(true);
      this.props.actions.EnquiryActions.actionMemberMobilePhoneChecked(true);
      this.props.actions.EnquiryActions.actionCrewMobileNumberChecked(true);
      this.props.actions.EnquiryActions.actionCrewRelationshipChecked(true);
      this.props.actions.EnquiryActions.actionMemberDisabilitiesChecked(true);

      if (this.props.general.skipUnwantedStack) {
        this.props.actions.GeneralActions.skipUnwantedStackScreen(false);
        this.props.navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [
              {name: 'BudgetsPayments'},
            ],
          }),
        );
      }
      this.props.actions.EnquiryActions.actionSetEnquiryDescription('');
    }
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({orientation});
  };

  UNSAFE_componentWillReceiveProps(props: any) {
    this.setState({
      isOpen: props.sideMenu ? props.sideMenu.sideMenuIsOpen : false,
    });
  }

  componentDidMount() {
    this.setState({loading: true});
    this.callApi();
    if (types.isWeb && !this.props.general.skipUnwantedStack) {
      this.props.actions.EnquiryActions.actionSetEnquiryDescription('');
      this.props.actions.EnquiryActions.actionMemberAddressChecked(true);
      this.props.actions.EnquiryActions.actionMemberPhoneNumberChecked(true);
      this.props.actions.EnquiryActions.actionMemberMobilePhoneChecked(true);
      this.props.actions.EnquiryActions.actionCrewMobileNumberChecked(true);
      this.props.actions.EnquiryActions.actionCrewRelationshipChecked(true);
      this.props.actions.EnquiryActions.actionMemberDisabilitiesChecked(true);
    }
  }

  async callApi() {
    this.setState({memberLocal: this.props.member});
    if (this.props.providers.selectedProvider === null) {
      if (types.isWeb) {
        this.props.setLoading(true);
      }
      await this.fetchProviders();
    } else {
      this.setState({loading: false});
    }
  }

  fetchProviders = () => {
    const providerId = this._getParams('providerid', undefined);
    if (providerId) {
      this.setState({providerid: providerId}, () => {
        callAPIs(
          [getProviderBySysId(this.state.providerid)],
          (data: any) => {
            if (data) {
              const obj = JSON.parse(data[0].content.body);
              this.props.actions.ProviderActions.actionSetSelectedProvider(
                obj.data[0],
              );
              if (types.isWeb) {
                this.props.setLoading(false);
              }
              this.setState({loading: false});
            }
          },
          null,
          () => {},
        );
      });
    } else {
      this.props.navigation.navigate('BudgetsPayments');
    }
  };

  componentDidUpdate(prevProps: any) {
    if (prevProps.member !== this.props.member) {
      return true;
    }
  }

  onRefresh = () => {
    this.setState({refreshing: true});
  };

  _getParams = (key: any) => {
    if(types.isWeb) {
      return this.props.navigation.getParam(key, undefined) || undefined;
     }
     return this.props.route.params? this.props.route.params[key] : undefined;
  };

  sendEnquiry = () => {
    const memberPayload = {};
    const crewPayload = {};
    const user = this.props.user;
    if (user.demoProfile) {
      this.setState({
        errorPopup: true,
        messageHeader: types2.DEMO_REFERRAL_FAIL.TITLE,
        messageBody: types2.DEMO_REFERRAL_FAIL.BODY,
        errorModalVisible: true,
      });
      return;
    }
    const {
      memberAddressChecked,
      crewMobileNumberChecked,
      memberPhoneNumberChecked,
      memberMobilePhoneChecked,
      memberDisabilitiesChecked,
      crewRelationshipChecked,
    } = this.props.enquiry;
    let crewInfo = [];
    if (user.isCrew) {
      crewInfo = this.props.crews.crewIndividual.filter(function (crew: any) {
        return crew.crewId === user.id;
      });
    }

    // Mandatory info
    const member = this.props.member;
    memberPayload.Id = member.id;
    memberPayload.firstName = member.firstName;
    memberPayload.lastName = member.lastName;
    memberPayload.email = member.email;

    if (user.isCrew && crewInfo.length !== 0) {
      crewPayload.firstName = crewInfo[0].firstName;
      crewPayload.lastName = crewInfo[0].lastName;
      crewPayload.email = crewInfo[0].email;

      if (crewInfo[0].phoneNumber && crewMobileNumberChecked) {
        crewPayload.mobilePhone = crewInfo[0].phoneNumber;
      }

      if (crewInfo[0].relationship && crewRelationshipChecked) {
        crewPayload.relationship = crewInfo[0].relationship;
      }
    }

    if (member.address && memberAddressChecked) {
      memberPayload.address = member.address;
    }

    if (member.city && memberAddressChecked) {
      memberPayload.city = member.city;
    }

    if (member.postcode && memberAddressChecked) {
      memberPayload.postcode = member.postcode;
    }

    if (user.isMember) {
      if (member.phoneNumber && memberPhoneNumberChecked) {
        memberPayload.phoneNumber = member.phoneNumber;
      }
    }
    if (member.mobilePhone && memberMobilePhoneChecked) {
      memberPayload.mobilePhone = member.mobilePhone;
    }

    if (member.disabilities && memberDisabilitiesChecked) {
      memberPayload.disabilities = member.disabilities.map(
        (disability: any, index: any) =>
          index === 0 ? disability.type : ' ' + disability.type,
      );
    }

    const payload = [
      {member: memberPayload},
      {crew: crewPayload},
      {provider: this.props.providers.selectedProvider},
      {note: this.props.enquiry.enquiryDescription},
    ];
    callAPIs(
      [sendEmailProvider(payload)],
      () => {
        this.setState({showPopupAlert: true});
      },
      null,
      () => {},
    );
  };

  _renderSubmittedPopup = () => {
    return (
      this.state.showPopupAlert && (
        <StandardAlert
          modalTitle={types2.Provider_Enquiry.ENQUIRY_SENT}
          headerStyle={[
            BrandStyles.TextColor10,
            CommonStyles.font23,
            CommonStyles.customFontBold,
          ]}
          mobileStyle={CommonStyles.enquiryPopup}
          tabletStyle={CommonStyles.enquiryPopupTablet}
          webStyle={CommonStyles.enquiryPopup}
          close={() => {
            this.setState({showPopupAlert: false}, () => {
              this.props.actions.EnquiryActions.actionMemberAddressChecked(
                true,
              );
              this.props.actions.EnquiryActions.actionMemberPhoneNumberChecked(
                true,
              );
              this.props.actions.EnquiryActions.actionMemberMobilePhoneChecked(
                true,
              );
              this.props.actions.EnquiryActions.actionCrewMobileNumberChecked(
                true,
              );
              this.props.actions.EnquiryActions.actionCrewRelationshipChecked(
                true,
              );
              this.props.actions.EnquiryActions.actionMemberDisabilitiesChecked(
                true,
              );
              this.props.actions.EnquiryActions.actionSetEnquiryDescription('');
              if (this.props.general.skipUnwantedStack) {
                if (types.isWeb) {
                  this.props.navigation.navigate('BudgetsPayments');
                  this.props.actions.GeneralActions.skipUnwantedStackScreen(
                    false,
                  );
                } else {
                  this.props.actions.GeneralActions.skipUnwantedStackScreen(
                    false,
                  );
                  this.props.navigation.dispatch(
                    CommonActions.reset({
                      index: 0,
                      routes: [
                        {name: 'BudgetsPayments'}
                      ],
                    }),
                  );
                }
              } else {
                this.props.navigation.goBack();
              }
            });
          }}
          message={providerEnquiryMessages.WE_HAVE_SENT_ENQUIRY(
            this.props.providers.selectedProvider.orgName,
          )}
        />
      )
    );
  };

  _renderErrorPopUp = (isTablet: any) => {
    return (
      <BasicOverlayPopupModal
        visible={this.state.errorModalVisible}
        style={this._determinePopUpStyle(isTablet)}
        backdropOpacity={0.2}
        divider={true}
        close={() => {
          this.setState({errorModalVisible: false});
        }}
        cancelContainerStyle={CommonStyles.popUpOkButton}
        cancelStyle={[CommonStyles.buttonPopupOk, CommonStyles.customFontBold]}
        cancelLabel={types2.OK_GOT_IT}
      >
        <View>{this.getPopUpContent(isTablet)}</View>
      </BasicOverlayPopupModal>
    );
  };

  getPopUpContent(isTablet: any) {
    let messageFontSize = CommonStyles.rpfont15;
    let headerFontSize = CommonStyles.font20;
    if (isTablet) {
      messageFontSize = CommonStyles.font20;
      headerFontSize = CommonStyles.font25;
    } else if (types.isWeb) {
      messageFontSize = CommonStyles.font15;
      headerFontSize = CommonStyles.font18;
    }
    return (
      <View style={[]}>
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.scrollView_subContentCenter,
            CommonStyles.paddingBottom5p,
          ]}
        >
          {this.state.errorPopup ? (
            <View>
              <Image
                visible={this.state.errorPopup}
                style={[CommonStyles.changePasswordButtonImage]}
                source={InformationIconx3}
                accessibilityLabel={
                  MESSAGES.ACCESSIBILITY.IMPORTANT_INFORMATION
                }
                accessible={true}
              />
            </View>
          ) : null}
          <View style={[CommonStyles.scrollView_subContentCenter]}>
            <StandardText
              style={[
                headerFontSize,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
              ]}
            >
              {this.state.messageHeader}
            </StandardText>
          </View>
        </View>
        <View
          style={[
            CommonStyles.paddingTop5,
            CommonStyles.scrollView_subContentCenter,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFont,
              BrandStyles.TextColor5,
              messageFontSize,
            ]}
          >
            {this.state.messageBody}
          </StandardText>
        </View>
      </View>
    );
  }

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        sidebarMode={types2.SIDEBAR_MODE.PLAN_MANAGEMENT}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={
          this.props.providers.selectedProvider
            ? this.props.providers.selectedProvider.orgName +
              ' ' +
              types2.NAVIGATION_TEXT.ENQUIRY_PROVIDER
            : ' ' + types2.NAVIGATION_TEXT.ENQUIRY_PROVIDER
        }
        onRefresh={this.onRefresh}
        refreshing={this.state.refreshing}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    const member = this.props.member;
    const user = this.props.user;
    let crewInfo = '';
    if (user.isCrew) {
      crewInfo = this.props.crews.crewIndividual.filter(function (crew: any) {
        return crew.crewId === user.id;
      });
    }

    const isCrew = user.isCrew && crewInfo.length !== 0;
    const isMember = user.isMember || user.isEmployee;
    const {
      memberAddressChecked,
      crewMobileNumberChecked,
      memberPhoneNumberChecked,
      memberMobilePhoneChecked,
      memberDisabilitiesChecked,
      crewRelationshipChecked,
    } = this.props.enquiry;
    let imageArr;
    if (!this.state.loading) {
      imageArr =
        this.props.providers.selectedProvider.images.length !== 0 &&
        this.props.providers.selectedProvider.images[0].imageUrl.split('.');
    }
    return (
      !this.state.loading && (
        <ScrollView style={[CommonStyles.content, BrandStyles.primaryBgColour]}>
          {this.props.providers.selectedProvider.images.length === 0 ||
            (imageArr[4] === types2.XML ? null : (
              <Image
                style={CommonStyles.referralItemImage}
                resizeMode={'contain'}
                source={{
                  uri: this.props.providers.selectedProvider.images[0].imageUrl,
                }}
              />
            ))}
          <StandardText
            style={[
              BrandStyles.TextColor4,
              CommonStyles.font22,
              CommonStyles.customFontBold,
            ]}
          >
            {this.props.providers.selectedProvider
              ? this.props.providers.selectedProvider.orgName
              : ''}
          </StandardText>
          <StandardText
            style={[
              BrandStyles.TextColor10,
              CommonStyles.SummaryTileText,
              CommonStyles.marginBottom5,
            ]}
          >
            {providerEnquiryMessages.TELL_PROVIDER_WHAT_YOU_ARE_LOOKING_FOR(
              this.props.providers.selectedProvider
                ? this.props.providers.selectedProvider.orgName
                : '',
            )}
          </StandardText>
          <StandardInput
            blurOnSubmit={true}
            multiline={true}
            minHeight={300}
            noMargin={true}
            style={[
              CommonStyles.textbox,
              CommonStyles.ModalTextInputWrapperAutoHeight,
              BrandStyles.primaryBgColor1,
              Platform.OS === types2.ANDROID &&
                CommonStyles.textAlignVerticalTop,
            ]}
            value={this.props.enquiry.enquiryDescription}
            onChangeText={(text: any) =>
              this.props.actions.EnquiryActions.actionSetEnquiryDescription(
                text,
              )
            }
            onSubmitEditing={this.props.onSubmitEditing}
            underlineColorAndroid="transparent"
          />

          <StandardText
            style={[
              CommonStyles.SummaryTileText,
              CommonStyles.marginTop5,
              CommonStyles.customFontBold,
              BrandStyles.TextColor5,
              CommonStyles.MessageTileTextWrap,
            ]}
          >
            {types2.Provider_Enquiry.INCLUDE_THIS_INFO_IN_YOUR_ENQUIRY}
          </StandardText>
          <View style={CommonStyles.flexDirectionColumn}>
            <View
              style={[
                CommonStyles.providerEnquiryPrePopulateItem,
                types.isWeb
                  ? CommonStyles.rpMarginTop4p
                  : CommonStyles.rpMarginTop5p,
              ]}
            >
              <StandardButton
                accessibilityLabel={
                  types2.ACCESSIBILITY_LABELS.MEMBER_FULLNAME_AND_EMAIL
                }
                disabled={true}
              >
                <Image
                  style={[
                    types.isWeb
                      ? CommonStyles.enquiryCheckbox
                      : CommonStyles.radioButtonPMSmall,
                  ]}
                  source={TickCircleGray}
                />
              </StandardButton>
              <View
                style={[
                  CommonStyles.flexColumn,
                  CommonStyles.providerEnquiryPrePopulate,
                ]}
              >
                <View
                  style={[
                    CommonStyles.flexRow,
                    CommonStyles.MessageTileTextWrap,
                    CommonStyles.alignItemsCenter,
                  ]}
                >
                  <StandardText
                    style={[
                      CommonStyles.SummaryTileText,
                      BrandStyles.TextColor2,
                      CommonStyles.customFontBold,
                    ]}
                  >
                    {isCrew
                      ? types2.Provider_Enquiry.ENQUIRY_FROM + ' '
                      : types2.Provider_Enquiry.FULL_NAME + ' '}
                  </StandardText>
                  <StandardText
                    style={[
                      CommonStyles.SummaryTileText,
                      BrandStyles.TextColor2,
                      CommonStyles.marginBottom3,
                    ]}
                  >
                    {`${member.firstName} ${member.lastName}`}
                  </StandardText>
                </View>
                <View
                  style={[
                    CommonStyles.flexRow,
                    CommonStyles.MessageTileTextWrap,
                    CommonStyles.alignItemsCenter,
                  ]}
                >
                  <StandardText
                    style={[
                      CommonStyles.SummaryTileText,
                      BrandStyles.TextColor2,
                      CommonStyles.customFontBold,
                    ]}
                  >
                    {types2.Provider_Enquiry.EMAIL + ' '}
                  </StandardText>
                  <StandardText
                    style={[
                      CommonStyles.SummaryTileText,
                      BrandStyles.TextColor2,
                      CommonStyles.marginBottom3,
                    ]}
                  >
                    {`${member.email}`}
                  </StandardText>
                </View>
              </View>
            </View>

            {isCrew && (
              <View
                style={[
                  CommonStyles.providerEnquiryPrePopulateItem,
                  types.isWeb
                    ? CommonStyles.rpMarginTop4p
                    : CommonStyles.rpMarginTop5p,
                ]}
              >
                <StandardButton
                  accessibilityLabel={
                    types2.ACCESSIBILITY_LABELS.CREW_FULLNAME_AND_EMAIL
                  }
                  disabled={true}
                >
                  <Image
                    style={
                      types.isWeb
                        ? CommonStyles.enquiryCheckbox
                        : CommonStyles.radioButtonPMSmall
                    }
                    source={TickCircleGray}
                  />
                </StandardButton>
                <View
                  style={[
                    CommonStyles.flexColumn,
                    CommonStyles.providerEnquiryPrePopulate,
                  ]}
                >
                  <View
                    style={[
                      CommonStyles.flexRow,
                      CommonStyles.MessageTileTextWrap,
                      CommonStyles.alignItemsCenter,
                    ]}
                  >
                    <StandardText
                      style={[
                        CommonStyles.SummaryTileText,
                        BrandStyles.TextColor2,
                        CommonStyles.customFontBold,
                      ]}
                    >
                      {types2.Provider_Enquiry.ON_BEHALF_OF + ' '}
                    </StandardText>
                    <StandardText
                      style={[
                        CommonStyles.SummaryTileText,
                        BrandStyles.TextColor2,
                        CommonStyles.marginBottom3,
                      ]}
                    >
                      {`${user.firstName} ${user.lastName}`}
                    </StandardText>
                  </View>
                  <View
                    style={[
                      CommonStyles.flexRow,
                      CommonStyles.MessageTileTextWrap,
                      CommonStyles.alignItemsCenter,
                    ]}
                  >
                    <StandardText
                      style={[
                        CommonStyles.SummaryTileText,
                        BrandStyles.TextColor2,
                        CommonStyles.customFontBold,
                      ]}
                    >
                      {types2.Provider_Enquiry.EMAIL + ' '}
                    </StandardText>
                    <StandardText
                      style={[
                        CommonStyles.SummaryTileText,
                        BrandStyles.TextColor2,
                        CommonStyles.marginBottom3,
                      ]}
                    >
                      {`${user.email}`}
                    </StandardText>
                  </View>
                </View>
              </View>
            )}
            <StandardText
              style={[
                CommonStyles.font17,
                CommonStyles.marginTop5,
                CommonStyles.customFontBold,
                BrandStyles.TextColor5,
                CommonStyles.MessageTileTextWrap,
              ]}
            >
              {
                types2.Provider_Enquiry
                  .SELECT_EXTRA_INFORMATION_YOU_WOULD_LIKE_TO_SHARE
              }
            </StandardText>
            <View
              style={[
                CommonStyles.providerEnquiryPrePopulateItem,
                types.isWeb
                  ? CommonStyles.rpMarginTop4p
                  : CommonStyles.rpMarginTop5p,
              ]}
            >
              <StandardButton
                accessibilityLabel={types2.ACCESSIBILITY_LABELS.MEMBER_ADDRESS}
                onPress={() =>
                  this.props.actions.EnquiryActions.actionMemberAddressChecked(
                    !memberAddressChecked,
                  )
                }
              >
                <Image
                  style={
                    types.isWeb
                      ? CommonStyles.enquiryCheckbox
                      : CommonStyles.radioButtonPMSmall
                  }
                  source={
                    !member.address
                      ? MediumRedEmptyCircleRed3x
                      : memberAddressChecked
                      ? MediumRedTickCircleRed3x
                      : MediumRedEmptyCircleRed3x
                  }
                />
              </StandardButton>
              <View
                style={[
                  CommonStyles.flexColumn,
                  CommonStyles.providerEnquiryPrePopulate,
                ]}
              >
                <View
                  style={[
                    CommonStyles.flexRow,
                    CommonStyles.MessageTileTextWrap,
                  ]}
                >
                  <StandardText
                    style={[
                      CommonStyles.SummaryTileText,
                      !(memberAddressChecked && member.address)
                        ? BrandStyles.TextColor10
                        : BrandStyles.TextColor2,
                      CommonStyles.customFontBold,
                    ]}
                  >
                    {types2.Provider_Enquiry.ADDRESS + ' '}
                  </StandardText>
                  <StandardText
                    style={[
                      CommonStyles.SummaryTileText,
                      !(memberAddressChecked && member.address)
                        ? BrandStyles.TextColor10
                        : BrandStyles.TextColor2,
                    ]}
                  >
                    {' '}
                    {!member.address
                      ? types2.Provider_Enquiry.NOT_RECORDED
                      : member.address}
                  </StandardText>
                </View>
                <View
                  style={[
                    CommonStyles.flexRow,
                    CommonStyles.MessageTileTextWrap,
                  ]}
                >
                  <StandardText
                    style={[
                      CommonStyles.SummaryTileText,
                      memberAddressChecked
                        ? BrandStyles.TextColor2
                        : BrandStyles.TextColor10,
                    ]}
                  >
                    {!member.address ? '' : `${member.city} ${member.postcode}`}
                  </StandardText>
                </View>
              </View>
            </View>
            {isMember ? (
              <React.Fragment>
                <View
                  style={[
                    CommonStyles.providerEnquiryPrePopulateItem,
                    types.isWeb
                      ? CommonStyles.rpMarginTop4p
                      : CommonStyles.rpMarginTop5p,
                  ]}
                >
                  <StandardButton
                    accessibilityLabel={
                      types2.ACCESSIBILITY_LABELS.MEMBER_HOME_PHONE
                    }
                    onPress={() =>
                      member.phoneNumber.length !== 0 &&
                      this.props.actions.EnquiryActions.actionMemberPhoneNumberChecked(
                        !memberPhoneNumberChecked,
                      )
                    }
                  >
                    <Image
                      style={
                        types.isWeb
                          ? CommonStyles.enquiryCheckbox
                          : CommonStyles.radioButtonPMSmall
                      }
                      source={
                        member.phoneNumber.length === 0
                          ? MediumRedEmptyCircleRed3x
                          : memberPhoneNumberChecked
                          ? MediumRedTickCircleRed3x
                          : MediumRedEmptyCircleRed3x
                      }
                    />
                  </StandardButton>
                  <View
                    style={[
                      CommonStyles.flexColumn,
                      CommonStyles.providerEnquiryPrePopulate,
                    ]}
                  >
                    <View
                      style={[
                        CommonStyles.flexRow,
                        CommonStyles.MessageTileTextWrap,
                      ]}
                    >
                      <StandardText
                        style={[
                          CommonStyles.SummaryTileText,
                          !(
                            memberPhoneNumberChecked &&
                            member.phoneNumber.length !== 0
                          )
                            ? BrandStyles.TextColor10
                            : BrandStyles.TextColor2,
                          CommonStyles.customFontBold,
                        ]}
                      >
                        {types2.Provider_Enquiry.HOME_PHONE}
                      </StandardText>
                    </View>
                    <View
                      style={[
                        CommonStyles.flexRow,
                        CommonStyles.MessageTileTextWrap,
                      ]}
                    >
                      <StandardText
                        style={[
                          CommonStyles.SummaryTileText,
                          !(
                            memberPhoneNumberChecked &&
                            member.phoneNumber.length !== 0
                          )
                            ? BrandStyles.TextColor10
                            : BrandStyles.TextColor2,
                        ]}
                      >
                        {`${
                          member.phoneNumber.length === 0
                            ? types2.Provider_Enquiry.NOT_RECORDED
                            : member.phoneNumber
                        }`}
                      </StandardText>
                    </View>
                  </View>
                </View>
                <View
                  style={[
                    CommonStyles.providerEnquiryPrePopulateItem,
                    types.isWeb
                      ? CommonStyles.rpMarginTop4p
                      : CommonStyles.rpMarginTop5p,
                  ]}
                >
                  <StandardButton
                    accessibilityLabel={
                      types2.ACCESSIBILITY_LABELS.MEMBER_MOBILE_PHONE
                    }
                    onPress={() =>
                      member.mobilePhone.length !== 0 &&
                      this.props.actions.EnquiryActions.actionMemberMobilePhoneChecked(
                        !memberMobilePhoneChecked,
                      )
                    }
                  >
                    <Image
                      style={
                        types.isWeb
                          ? CommonStyles.enquiryCheckbox
                          : CommonStyles.radioButtonPMSmall
                      }
                      source={
                        member.mobilePhone.length === 0
                          ? MediumRedEmptyCircleRed3x
                          : memberMobilePhoneChecked
                          ? MediumRedTickCircleRed3x
                          : MediumRedEmptyCircleRed3x
                      }
                    />
                  </StandardButton>
                  <View
                    style={[
                      CommonStyles.flexColumn,
                      CommonStyles.providerEnquiryPrePopulate,
                    ]}
                  >
                    <View
                      style={[
                        CommonStyles.flexRow,
                        CommonStyles.MessageTileTextWrap,
                      ]}
                    >
                      <StandardText
                        style={[
                          CommonStyles.SummaryTileText,
                          !(
                            memberMobilePhoneChecked &&
                            member.mobilePhone.length !== 0
                          )
                            ? BrandStyles.TextColor10
                            : BrandStyles.TextColor2,
                          CommonStyles.customFontBold,
                        ]}
                      >
                        {types2.Provider_Enquiry.MOBILE_PHONE + ' '}
                      </StandardText>
                    </View>
                    <View
                      style={[
                        CommonStyles.flexRow,
                        CommonStyles.MessageTileTextWrap,
                      ]}
                    >
                      <StandardText
                        style={[
                          CommonStyles.SummaryTileText,
                          memberMobilePhoneChecked
                            ? BrandStyles.TextColor2
                            : BrandStyles.TextColor10,
                        ]}
                      >
                        {`${
                          member.mobilePhone.length === 0
                            ? types2.Provider_Enquiry.NOT_RECORDED
                            : member.mobilePhone
                        }`}
                      </StandardText>
                    </View>
                  </View>
                </View>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <View
                  style={[
                    CommonStyles.providerEnquiryPrePopulateItem,
                    types.isWeb
                      ? CommonStyles.rpMarginTop4p
                      : CommonStyles.rpMarginTop5p,
                  ]}
                >
                  <StandardButton
                    accessibilityLabel={
                      types2.ACCESSIBILITY_LABELS.CREW_MOBILE_PHONE
                    }
                    onPress={() =>
                      crewInfo[0].phoneNumber &&
                      crewInfo[0].phoneNumber.length !== 0 &&
                      this.props.actions.EnquiryActions.actionCrewMobileNumberChecked(
                        !crewMobileNumberChecked,
                      )
                    }
                  >
                    <Image
                      style={
                        types.isWeb
                          ? CommonStyles.enquiryCheckbox
                          : CommonStyles.radioButtonPMSmall
                      }
                      source={
                        (crewInfo[0].phoneNumber &&
                          crewInfo[0].phoneNumber.length === 0) ||
                        crewInfo[0].phoneNumber === null
                          ? MediumRedEmptyCircleRed3x
                          : crewMobileNumberChecked
                          ? MediumRedTickCircleRed3x
                          : MediumRedEmptyCircleRed3x
                      }
                    />
                  </StandardButton>
                  <View
                    style={[
                      CommonStyles.flexColumn,
                      CommonStyles.providerEnquiryPrePopulate,
                    ]}
                  >
                    <View
                      style={[
                        CommonStyles.flexRow,
                        CommonStyles.MessageTileTextWrap,
                        CommonStyles.alignItemsCenter,
                      ]}
                    >
                      <StandardText
                        style={[
                          CommonStyles.SummaryTileText,
                          !(
                            crewMobileNumberChecked &&
                            crewInfo[0].phoneNumber &&
                            crewInfo[0].phoneNumber.length !== 0
                          )
                            ? BrandStyles.TextColor10
                            : BrandStyles.TextColor2,
                          CommonStyles.customFontBold,
                        ]}
                      >
                        {`${user.firstName}'s Number `}
                      </StandardText>
                      <StandardText
                        style={[
                          CommonStyles.SummaryTileText,
                          !(
                            crewMobileNumberChecked &&
                            crewInfo[0].phoneNumber &&
                            crewInfo[0].phoneNumber.length !== 0
                          )
                            ? BrandStyles.TextColor10
                            : BrandStyles.TextColor2,
                        ]}
                      >
                        {types2.Provider_Enquiry.CREW}
                      </StandardText>
                    </View>
                    <View
                      style={[
                        CommonStyles.flexRow,
                        CommonStyles.MessageTileTextWrap,
                      ]}
                    >
                      <StandardText
                        style={[
                          CommonStyles.SummaryTileText,
                          !(
                            crewMobileNumberChecked &&
                            crewInfo[0].phoneNumber &&
                            crewInfo[0].phoneNumber.length !== 0
                          )
                            ? BrandStyles.TextColor10
                            : BrandStyles.TextColor2,
                        ]}
                      >
                        {`${
                          crewInfo[0].phoneNumber &&
                          crewInfo[0].phoneNumber.length === 0
                            ? types2.Provider_Enquiry.NOT_RECORDED
                            : crewInfo[0].phoneNumber === null
                            ? types2.Provider_Enquiry.NOT_RECORDED
                            : crewInfo[0].phoneNumber
                        }`}
                      </StandardText>
                    </View>
                  </View>
                </View>
                <View
                  style={[
                    CommonStyles.providerEnquiryPrePopulateItem,
                    types.isWeb
                      ? CommonStyles.rpMarginTop4p
                      : CommonStyles.rpMarginTop5p,
                  ]}
                >
                  <StandardButton
                    accessibilityLabel={
                      types2.ACCESSIBILITY_LABELS.MEMBER_MOBILE_PHONE
                    }
                    onPress={() =>
                      member.mobilePhone.length !== 0 &&
                      this.props.actions.EnquiryActions.actionMemberMobilePhoneChecked(
                        !memberMobilePhoneChecked,
                      )
                    }
                  >
                    <Image
                      style={
                        types.isWeb
                          ? CommonStyles.enquiryCheckbox
                          : CommonStyles.radioButtonPMSmall
                      }
                      source={
                        member.mobilePhone.length === 0
                          ? MediumRedEmptyCircleRed3x
                          : memberMobilePhoneChecked
                          ? MediumRedTickCircleRed3x
                          : MediumRedEmptyCircleRed3x
                      }
                    />
                  </StandardButton>
                  <View
                    style={[
                      CommonStyles.flexColumn,
                      CommonStyles.providerEnquiryPrePopulate,
                    ]}
                  >
                    <View
                      style={[
                        CommonStyles.flexRow,
                        CommonStyles.MessageTileTextWrap,
                        CommonStyles.alignItemsCenter,
                      ]}
                    >
                      <StandardText
                        style={[
                          CommonStyles.SummaryTileText,
                          !(
                            memberMobilePhoneChecked &&
                            member.mobilePhone.length !== 0
                          )
                            ? BrandStyles.TextColor10
                            : BrandStyles.TextColor2,
                          CommonStyles.customFontBold,
                        ]}
                      >
                        {`${member.firstName}'s Number `}
                      </StandardText>
                      <StandardText
                        style={[
                          CommonStyles.SummaryTileText,
                          !(
                            memberMobilePhoneChecked &&
                            member.mobilePhone.length !== 0
                          )
                            ? BrandStyles.TextColor10
                            : BrandStyles.TextColor2,
                        ]}
                      >
                        {types2.Provider_Enquiry.PARTICIPANT}
                      </StandardText>
                    </View>
                    <View
                      style={[
                        CommonStyles.flexRow,
                        CommonStyles.MessageTileTextWrap,
                      ]}
                    >
                      <StandardText
                        style={[
                          CommonStyles.SummaryTileText,
                          !(
                            memberMobilePhoneChecked &&
                            member.mobilePhone.length !== 0
                          )
                            ? BrandStyles.TextColor10
                            : BrandStyles.TextColor2,
                        ]}
                      >
                        {`${
                          member.mobilePhone.length === 0
                            ? types2.Provider_Enquiry.NOT_RECORDED
                            : member.mobilePhone
                        }`}
                      </StandardText>
                    </View>
                  </View>
                </View>
                <View
                  style={[
                    CommonStyles.providerEnquiryPrePopulateItem,
                    types.isWeb
                      ? CommonStyles.rpMarginTop4p
                      : CommonStyles.rpMarginTop5p,
                  ]}
                >
                  <StandardButton
                    accessibilityLabel={
                      types2.ACCESSIBILITY_LABELS.RELATIONSHIP
                    }
                    onPress={() =>
                      crewInfo[0].relationship.length !== 0 &&
                      this.props.actions.EnquiryActions.actionCrewRelationshipChecked(
                        !crewRelationshipChecked,
                      )
                    }
                  >
                    <Image
                      style={
                        types.isWeb
                          ? CommonStyles.enquiryCheckbox
                          : CommonStyles.radioButtonPMSmall
                      }
                      source={
                        crewInfo[0].relationship.length === 0
                          ? MediumRedEmptyCircleRed3x
                          : crewRelationshipChecked
                          ? MediumRedTickCircleRed3x
                          : MediumRedEmptyCircleRed3x
                      }
                    />
                  </StandardButton>
                  <View
                    style={[
                      CommonStyles.flexColumn,
                      CommonStyles.providerEnquiryPrePopulate,
                    ]}
                  >
                    <View
                      style={[
                        CommonStyles.flexRow,
                        CommonStyles.MessageTileTextWrap,
                      ]}
                    >
                      <StandardText
                        style={[
                          CommonStyles.SummaryTileText,
                          !(
                            crewRelationshipChecked &&
                            crewInfo[0].relationship.length !== 0
                          )
                            ? BrandStyles.TextColor10
                            : BrandStyles.TextColor2,
                          CommonStyles.customFontBold,
                        ]}
                      >
                        {`${types2.Provider_Enquiry.RELATIONSHIP_TO} ${member.firstName}`}
                      </StandardText>
                    </View>
                    <View
                      style={[
                        CommonStyles.flexRow,
                        CommonStyles.MessageTileTextWrap,
                      ]}
                    >
                      <StandardText
                        style={[
                          CommonStyles.SummaryTileText,
                          !(
                            crewRelationshipChecked &&
                            crewInfo[0].relationship.length !== 0
                          )
                            ? BrandStyles.TextColor10
                            : BrandStyles.TextColor2,
                        ]}
                      >
                        {`${
                          crewInfo[0].relationship.length === 0
                            ? types2.Provider_Enquiry.NOT_RECORDED
                            : crewInfo[0].relationship
                        }`}
                      </StandardText>
                    </View>
                  </View>
                </View>
              </React.Fragment>
            )}
            <View
              style={[
                CommonStyles.providerEnquiryPrePopulateItem,
                types.isWeb
                  ? CommonStyles.rpMarginTop4p
                  : CommonStyles.rpMarginTop5p,
              ]}
            >
              <StandardButton
                accessibilityLabel={
                  types2.ACCESSIBILITY_LABELS.MEMBER_DISABILITIES
                }
                onPress={() =>
                  member.disabilities.length !== 0 &&
                  this.props.actions.EnquiryActions.actionMemberDisabilitiesChecked(
                    !memberDisabilitiesChecked,
                  )
                }
              >
                <Image
                  style={
                    types.isWeb
                      ? CommonStyles.enquiryCheckbox
                      : CommonStyles.radioButtonPMSmall
                  }
                  source={
                    member.disabilities.length === 0
                      ? MediumRedEmptyCircleRed3x
                      : memberDisabilitiesChecked
                      ? MediumRedTickCircleRed3x
                      : MediumRedEmptyCircleRed3x
                  }
                />
              </StandardButton>
              <View
                style={[
                  CommonStyles.flexColumn,
                  CommonStyles.providerEnquiryPrePopulate,
                ]}
              >
                <View
                  style={[
                    CommonStyles.flexRow,
                    CommonStyles.MessageTileTextWrap,
                  ]}
                >
                  <StandardText
                    style={[
                      CommonStyles.SummaryTileText,
                      !(
                        memberDisabilitiesChecked &&
                        member.disabilities.length !== 0
                      )
                        ? BrandStyles.TextColor10
                        : BrandStyles.TextColor2,
                      CommonStyles.customFontBold,
                    ]}
                  >
                    {types2.Provider_Enquiry.DISABILITIES + ' '}
                  </StandardText>
                </View>
                <View
                  style={[
                    CommonStyles.flexRow,
                    CommonStyles.MessageTileTextWrap,
                  ]}
                >
                  <StandardText
                    style={[
                      CommonStyles.SummaryTileText,
                      !(
                        memberDisabilitiesChecked &&
                        member.disabilities.length !== 0
                      )
                        ? BrandStyles.TextColor10
                        : BrandStyles.TextColor2,
                    ]}
                  >
                    {`${
                      member.disabilities.length === 0
                        ? types2.Provider_Enquiry.NOT_RECORDED
                        : member.disabilities.map(
                            (disability: any, index: any) =>
                              index === 0
                                ? disability.type
                                : ' ' + disability.type,
                          )
                    }`}
                  </StandardText>
                </View>
              </View>
            </View>

            <View
              style={[
                CommonStyles.flexRow,
                CommonStyles.rpMarginTop10p,
                types.isWeb
                  ? CommonStyles.ModalFooterLeft
                  : CommonStyles.ModalFooterCenter,
              ]}
            >
              <CancelSaveButtons
                showCancel
                containerStyle={
                  types.isWeb
                    ? CommonStyles.ModalFooterLeft
                    : [
                        CommonStyles.ModalFooterCenter,
                        CommonStyles.justifyContentSpaceBetween,
                        CommonStyles.paddingLeftRight8,
                      ]
                }
                style={[CommonStyles.alignFlexEnd, CommonStyles.content]}
                cancelStyle={[CommonStyles.marginRight10]}
                cancelCallback={() => {
                  this.props.navigation.navigate('AboutMeMyDetailsForm', {
                    goBack: 'ProviderEnquiry',
                  });
                  this.props.actions.GeneralActions.navigateBackToPreviousScreen(
                    'ProviderEnquiry',
                  );
                }}
                cancelButton="Update Details"
                saveCallback={() => this.sendEnquiry()}
                saveDisabled={false}
                saveButton={'Send Enquiry'}
              />
            </View>
          </View>
          {this._renderSubmittedPopup()}
          {this._renderErrorPopUp(types.isWeb ? false : DeviceInfo.isTablet())}
        </ScrollView>
      )
    );
  };

  _determinePopUpStyle = (isTablet: any) => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      styles.push([CommonStyles.containerInstructionPopUpWeb]);

      if (this.props.isMobile) {
        styles.push([CommonStyles.containerPopupWebMobile]);
      }
    } else if (isTablet) {
      styles.push([
        CommonStyles.containerInstructionPopUpTablet,
        CommonStyles.height320,
      ]);
    } else {
      styles.push(
        CommonStyles.containerInstructionPopUpMobile,
        CommonStyles.height300,
        CommonStyles.width340,
      );
    }
    return styles;
  };
}

// Warn the user if props are not passed
ProviderEnquiry.propTypes = {};

//For the reducer connection
const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  member: state.MemberReducer,
  budget: state.BudgetReducer,
  crews: state.CrewReducer,
  loadedMemberId: state.LoadedMemberReducer,
  providers: state.ProviderReducer,
  enquiry: state.EnquiryReducer,
  general: state.GeneralReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
    BudgetActions: bindActionCreators(BudgetActions, dispatch),
    PayInvoiceActions: bindActionCreators(PayInvoiceActions, dispatch),
    MemberActions: bindActionCreators(MemberActions, dispatch),
    ProviderActions: bindActionCreators(ProviderActions, dispatch),
    EnquiryActions: bindActionCreators(EnquiryActions, dispatch),
    GeneralActions: bindActionCreators(GeneralActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProviderEnquiry);
