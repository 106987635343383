/*
 * Authour: Andrew Seeley
 * Date: 09/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This class is a helper for common image functions
 */

import {Platform} from 'react-native';

import {IOS, ANDROID} from '../Constants/Constants2';
import logger from 'helpers/Logger';
import * as types from '../Constants/Constants';

import {compressImage, RNFetchBlobSingleton} from './PlatformSynchronizer';
import {callAPIs} from '../API/APICaller';
import {getFileBase64Content} from '../API/MemberAPI';
const SHA1 = require('crypto-js/sha1');

/**
 * Compress the image with a max height and width of 400px, PNG format and 80% compression
 * @param {String} imageuri Local uri to the image we want to compress
 */

/**
 * This function will upload an image to an s3 signed URL and compress it in the process
 * @param {String} signedUrl The signed URL to upload the image to
 * @param {String} contentType The content type of the image
 * @param {String} imageUri The local path to the image URI to upload from
 */

export const uploadImageToS3WithoutResize = (
  signedUrl: any,
  contentType: any,
  imageUri: any,
) =>
  new Promise((resolve, reject) => {
    if (types.isWeb) {
      const base64Src = 'data:image/png;base64,' + imageUri;
      fetch(base64Src)
        .then(res => res.blob())
        .then(blob => {
          fetch(signedUrl, {
            method: 'PUT',
            headers: {'Content-Type': 'application/octet-stream'},
            body: blob,
          })
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              logger.log('The error is:', error);
              this.setState({
                saveApiErrorCode: null,
                saveApiError: true,
                loading: false,
              });
              reject(error);
            });
        });
    } else {
      RNFetchBlobSingleton.fetch(
        'PUT',
        signedUrl,
        {'Content-Type': 'application/octet-stream'},
        imageUri,
      )
        .uploadProgress((written, total) => {})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          logger.log('The error is:', error);
          this.setState({
            saveApiErrorCode: null,
            saveApiError: true,
            loading: false,
          }); // CONFIRM THIS
          reject(error);
        });
    }
  });

export const uploadImageToS3 = (
  signedUrl: any,
  contentType: any,
  imageUri: any,
  fileDetails: any,
) =>
  new Promise((resolve, reject) => {
    // First of all compress the image
    compressImage(imageUri)
      .then(response => {
        // Upload the compressed image

        if (types.isWeb) {
          fetch(signedUrl, {
            method: 'PUT',
            headers: {'Content-Type': contentType},
            body: fileDetails,
          })
            .then(res => {
              resolve(res);
            })
            .catch(error => {
              logger.log('The error is:', error);
              this.setState({
                saveApiErrorCode: null,
                saveApiError: true,
                loading: false,
              });
              reject(error);
            });
        } else {
          let promise = RNFetchBlobSingleton;
          let cleanUri = response.uri;
          if (Platform.OS === IOS) {
            cleanUri = decodeURI(response.uri.replace('file://', ''));
            const extention = cleanUri.split('.').pop();
            const name = `profilePicture.${extention}`;
            const consolidatedKey = `${name}`;
            const dirs = RNFetchBlobSingleton.fs.dirs;
            const imagePath = `${dirs.CacheDir}_downloaded_images/${consolidatedKey}`;
            promise = RNFetchBlobSingleton.config({path: imagePath});
          }
          promise
            .fetch(
              'PUT',
              signedUrl,
              {'Content-Type': contentType},
              RNFetchBlobSingleton.wrap(cleanUri),
            )
            .uploadProgress((written, total) => {})
            .then(res => {
              resolve(res);
            })
            .catch(error => {
              logger.log('The error is:', error);
              this.setState({
                saveApiErrorCode: null,
                saveApiError: true,
                loading: false,
              }); // CONFIRM THIS
              reject(error);
            });
        }
      })
      .catch(error => {
        logger.log('Image compress error');
        reject(error);
      });
  });

export const fetchImageFromUrl = (imageItem: any) =>
  new Promise((resolve, reject) => {
    if (!types.isWeb) {
      RNFetchBlobSingleton.fetch('GET', imageItem.avatarImageUrl).then(res => {
        const base64Str = res.base64();
        resolve(base64Str);
      });
    } else {
      const callback = (data: any) => {
        resolve(data[0].content);
      };

      const errorCallback = (err: any) => {
        reject(err);
      };
      const requestBody = {
        bucketName: imageItem.bucketName,
        key: imageItem.key,
      };
      callAPIs([getFileBase64Content(requestBody)], callback, errorCallback);
    }
  });

// NOTE: Move and refactored this function from GoalHelper.js to this file since it's similar
// Functionality is using the Support Summary Landing Page image change functionality
// #TODO - Later we can remove GoalHelper function once we tested goal functionalities
/**
 * Set the image in redux, and return a  image to be set in the state
 * That the phototile compoonent can use
 * @param {*} requestObject
 * @param {*} setRedux
 * @param {*} type
 */
export const setImageRedux = (requestObject: any, setRedux: any, type: any) => {
  const imageObjectUrl = requestObject.uri
    ? requestObject.uri
    : requestObject.url;
  const fileName = requestObject.fileName
    ? requestObject.fileName
    : requestObject.filename
    ? requestObject.filename
    : imageObjectUrl.split('/').pop();
  // Used to display the image
  const imageObjectState = {
    lastModified: new Date(),
    key: imageObjectUrl,
    url: imageObjectUrl,
    localImage: true,
    photoCaption: requestObject.photoCaption,
  };

  // Sometimes Android returns a null for images content type
  // So if we don't know what it is use a jpg file.
  let contentType = 'image/jpeg';
  if (requestObject.type != null) {
    contentType = 'image/jpeg';
    switch (requestObject.type) {
      case 'png':
        contentType = 'image/png';
        break;
      case 'jpg':
        contentType = 'image/jpeg';
        break;
      case 'jpeg':
        contentType = 'image/jpeg';
        break;
      case 'tif':
        contentType = 'image/tiff';
        break;
      case 'tiff':
        contentType = 'image/tiff';
        break;
      default:
        contentType = 'image/jpeg';
        break;
    }
  }

  // Uses to upload the image to the API & get a signed URL
  const imageObjectUpload = {
    fileType: type,
    filename: fileName,
    contentType,
    path: requestObject.path,
    localUri: imageObjectUrl,
    lastModified: new Date(),
    key: imageObjectUrl,
    url: imageObjectUrl,
    localImage: true,
    photoCaption: requestObject.photoCaption,
  };

  setRedux(imageObjectUpload);

  return imageObjectState;
};
