import React, {Component} from 'react';
import {View, FlatList, Text, Linking} from 'react-native';
import logger from 'helpers/Logger';
import {
  Container,
  FormTitle,
  PaginationNavigator,
} from '../Components/Molecules';
import {NotificationRow} from '../Components/Organisms';
import {NotificationBadge} from '../Components/Molecules/NotificationBadge';
import {StandardText} from '../Components/Atoms';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// Import Stylesheets and Constants
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {MessagesRed3x} from '../assets/images/vector-icons';

import * as MemberActions from '../Actions/MemberActions';
import * as NotificationActions from '../Actions/NotificationActions';
import * as SideMenuActions from '../Actions/SideMenuActions';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {callAPIs} from '../API/APICaller';

import {getNotifications, updateNotifications} from '../API/NotificationAPI';

const NEXT = 'next';
const PREVIOUS = 'previous';
class Notifications extends Component {
  _createAllModals: any;
  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      expandedNotificationId: '',
      pageSize: 10,
      totalPages: 1,
      isPaginationMetaDataAvailable: false,
      refreshing: false,
      totalCount: 0,
    };

    this._paginationAction = this._paginationAction.bind(this);
    this._createMainContents.bind(this);
  }

  componentDidMount() {
    let pageNumber = 1;
    if (this.props.notifications && this.props.notifications.pageNumber) {
      pageNumber = this.props.notifications.pageNumber;
    }
    this.fetchData('', pageNumber, this.state.pageSize);
  }

  refreshFirstPage = () => {
    this.setState({refreshing: true});
    this.fetchData('', 1, this.state.pageSize);
  };

  fetchData(notificationId: any, pageNumber: any, pageSize: any) {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    let notificationUserId = this.props.user.id;
    if (this.props.loadedMemberId !== undefined) {
      notificationUserId = this.props.loadedMemberId.loadedMemberId;
    }

    const downloadCallback = (data: any) => {
      if (
        this.props.notifications &&
        this.props.notifications.notificationData
      ) {
        const currentCount =
          this.props.notifications.notificationData.paginationMetadata
            .totalCount;
        const newCount = data[0].content.paginationMetadata.totalCount;
        this.setState({totalCount: newCount});

        if (newCount > currentCount) {
          this.props.notifications.notificationData.paginationMetadata.totalCount =
            newCount;
          this.refreshFirstPage();
          return;
        }
      }

      this.props.actions.NotificationActions.loadNotifications(data[0].content);

      const paginationMetadata = data[0].content.paginationMetadata;
      if (!!paginationMetadata) {
        this.props.actions.NotificationActions.notificationPageNumber(
          paginationMetadata.currentPage,
        );
      }
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        loading: false,
        refreshing: false,
        expandedNotificationId: notificationId,
        isPaginationMetaDataAvailable: !!paginationMetadata,
        pageSize: !!paginationMetadata
          ? paginationMetadata.pageSize
          : this.state.pageSize,
        totalPages: !!paginationMetadata
          ? paginationMetadata.totalPages
          : this.state.totalPages,
      });
    };

    callAPIs(
      [
        getNotifications(
          notificationUserId,
          pageNumber,
          pageSize,
          false,
          this.props.user.demoProfile,
        ),
      ],
      downloadCallback,
      (err: any) => logger.log('notifications error: ', err),
      () => {
        this.props.setLoading(false);
        this.setState({loading: false});
      },
    );
  }

  _updateNotificationRead(
    notificationId: any,
    groupedNotificationIds: any,
    link: any,
    isActioned: any,
  ) {
    let notificationsCallbackFunction = (data: any) => {
      this.setState({refreshing: true});
      this.fetchData(
        notificationId,
        this.props.notifications.pageNumber,
        this.state.pageSize,
      );
    };

    let notificationsCallbackErrorFunction = (err: any) => {
      logger.log('notifications error: ', err);
    };

    let notificationUserId = this.props.user.id;
    if (this.props.loadedMemberId !== undefined) {
      notificationUserId = this.props.loadedMemberId.loadedMemberId;
    }

    let notification = {
      id: notificationId,
      status: types.NOTIFICATION_STATUS.READ,
      isActioned: isActioned,
    };

    if (groupedNotificationIds) {
      notification.groupedNotificationIds = groupedNotificationIds;
    }

    callAPIs(
      [updateNotifications(notificationUserId, notification, false)],
      notificationsCallbackFunction,
      notificationsCallbackErrorFunction,
      () => {
        this.props.setLoading(false);
        this.setState({loading: false});
      },
    );

    if (link !== types2.ZERO_STRING) {
      const navigationLink = link.split('/');
      if (navigationLink.length > 1) {
        if (types2.HTTP_TYPES.includes(navigationLink[0])) {
          return Linking.openURL(link);
        }

        if (
          types2.BUDGET_SCREENS_FOR_NOTIFICATIONS.includes(navigationLink[0])
        ) {
          this.props.actions.SideMenuActions.setCurrentScreen(types2.BUDGETS);
        }

        this.props.actions.navigationParamActions.setParam({
          data: navigationLink[1],
          notificationData: notification,
        });

        this.props.navigation.navigate(navigationLink[0]);
      } else {
        this.props.navigation.navigate('ApprovalsSummary');
      }
    }
  }

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        modals={this._createAllModals}
        needsSidebar={true}
        refreshing={this.state.refreshing}
        onRefresh={() => {
          this.setState({refreshing: true});
          this.fetchData(
            '',
            this.props.notifications.pageNumber,
            this.state.pageSize,
          );
        }}
        screenType={types.SCREEN_TYPE_MAIN}
        activeScreen={types.SCREEN_NOTIFICATIONS}
        selectedIcon={types.SCREEN_NOTIFICATIONS}
        nav={this.props.navigation}
        toggleMenu={
          this.props.screenProps ? this.props.screenProps?.toggleMenu : null
        }
        getInitialMenuState={
          this.props.screenProps
            ? this.props.screenProps?.getInitialMenuState
            : null
        }
        headerTitle={types2.NAVIGATION_TEXT.MESSAGES}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    return (
      <View style={[CommonStyles.flexColumn, CommonStyles.content]}>
        <FormTitle
          text={'Messages'}
          textColor={BrandStyles.TextColor3}
          imageSource={MessagesRed3x}
          renderNotification={() => this._showNotificationBadge()}
          containsData={this.props.notifications}
          borderStyle={CommonStyles.MessageHeaderBorder}
          style={[CommonStyles.notificationHeader, CommonStyles.font20]}
        />
        {this._renderFlatList()}
        {this.state.isPaginationMetaDataAvailable && (
          <PaginationNavigator
            pageNumber={this.props.notifications.pageNumber}
            totalPages={this.state.totalPages}
            pageSize={this.state.pageSize}
            goToNextPage={() => this._paginationAction(NEXT)}
            goToPreviousPage={() => this._paginationAction(PREVIOUS)}
            style={CommonStyles.notificationPagination}
          />
        )}
      </View>
    );
  };

  _paginationAction = (action: any) => {
    let currentPage = this.props.notifications.pageNumber;
    if (action === NEXT) {
      currentPage = currentPage + 1;
    } else {
      currentPage = currentPage - 1;
    }
    this.fetchData('', currentPage, this.state.pageSize);
    this.props.actions.NotificationActions.notificationPageNumber(currentPage);
  };

  _showNotificationBadge() {
    if (this._showNotification() && this.props.notifications.notificationData) {
      let badgeBgColor = BrandStyles.notificationBadgeBgColor;
      let badgeTxtColor = BrandStyles.notificationBadgeTextColor;
      if (
        this.props.selectedIcon != undefined &&
        this.props.selectedIcon == types.SCREEN_NOTIFICATIONS
      ) {
        badgeBgColor = BrandStyles.notificationSelectedBadgeBgColor;
        badgeTxtColor = BrandStyles.notificationSelectedBadgeTextColor;
      }
      return (
        <NotificationBadge
          count={this.props.notifications.notificationData.totalUnreadCount}
          bgColor={badgeBgColor}
          txtColor={badgeTxtColor}
        />
      );
    }
  }

  _showNotification() {
    let showNotification = false;
    if (this.props.member.access != undefined) {
      if (this.props.member.access.notifications != 'none') {
        showNotification = true;
      }
    } else {
      showNotification = true;
    }
    return showNotification;
  }

  _renderFlatList() {
    return (
      <View
        style={[CommonStyles.notificationContent, CommonStyles.marginTop20]}
      >
        {this.props.notifications.notificationData &&
        this.props.notifications.notificationData.notifications.length ? (
          <View>
            {this.renderResultSummary()}
            {this.props.notifications.notificationData.notifications.map(
              (notification: any) => this.renderFlatListItem(notification),
            )}
          </View>
        ) : (
          this._showEmptyListView()
        )}
      </View>
    );
  }

  renderResultSummary = () => {
    if (this.state.totalCount > 0) {
      const pageResultCount =
        this.props.notifications.pageNumber * this.state.pageSize;
      const lastIndex =
        pageResultCount > this.state.totalCount
          ? this.state.totalCount
          : pageResultCount;
      const startIndex =
        this.props.notifications.pageNumber === 1
          ? this.props.notifications.pageNumber
          : (this.props.notifications.pageNumber - 1) * this.state.pageSize + 1;
      return (
        <View style={CommonStyles.alignItemsCenter}>
          <StandardText
            style={[
              CommonStyles.textResultSummary,
              CommonStyles.customFontBold,
              BrandStyles.TextColor13,
            ]}
          >
            {`${types2.RESULT_COUNT.SHOW}${startIndex}-${lastIndex}${types2.RESULT_COUNT.OF}${this.state.totalCount}${types2.RESULT_COUNT.RESULTS}`}
          </StandardText>
        </View>
      );
    }
  };

  _showEmptyListView() {
    return (
      <View style={[CommonStyles.flex1CenterContent]}>
        <Text
          style={[CommonStyles.emptyNotificationsText, BrandStyles.TextColor2]}
        >
          {types.NO_NOTIFICATION}
        </Text>

        {!types.isWeb && (
          <Text style={[BrandStyles.TextColor2]}>{types.PULL_TO_REFRESH}</Text>
        )}
      </View>
    );
  }

  renderFlatListItem(item: any) {
    return (
      <NotificationRow
        updateNotification={this._updateNotificationRead.bind(this)}
        key={'nr' + item.Id}
        userId={this.props.user.id}
        expandedNotificationId={this.state.expandedNotificationId}
        navigation={this.props.navigation}
        loading={this.state.loading}
        {...item}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
  notifications: state.NotificationReducer,
});
const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    MemberActions: bindActionCreators(MemberActions, dispatch),
    NotificationActions: bindActionCreators(NotificationActions, dispatch),
    SideMenuActions: bindActionCreators(SideMenuActions, dispatch),
    navigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
