/*
 * Author: Mayuran
 * Date: 27/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Modal contents on the WhereILive screen for the Who I Live With Modal
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View, Text, Platform} from 'react-native';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {MESSAGES} from '../Constants/Messages';

import {StandardText, StandardTouchableOpacity} from '../Components/Atoms';
import {
  ActionButton,
  CheckBoxButton,
  ModalSubtitle,
  ModalTitle,
  PhotoTile,
  SummaryTile,
  Container,
  GridView,
  RadioButton,
} from '../Components/Molecules';
import {BasicForm} from '../Components/Organisms';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as LivingArrangementActions from '../Actions/LivingArrangementActions';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import CrewForm from '../Screens/CrewForm';
import {callAPIs} from '../API/APICaller';
import {getLivingArrangement, updateLivingArrangement} from '../API/AboutMeAPI';
import {setValue, removeItem} from '../API/WebPersistenceStore';
import {IOS} from '../Constants/Constants';
import {} from '../assets/images/index';
import {CrewPurple} from '../assets/images/vector-icons';

const RESULT_INDEX = {
  MEMBER: 0,
  LIVING_ARRANGEMENT: 1,
  FAVOURITES: 2,
  HEALTH: 3,
  CREW_CATEGORIES: 4,
  CREW: 5,
  CREW_ACCESS: 6,
};
class WhereILiveCrewForm extends Component {
  _editCrewForm: any;
  constructor(props: any) {
    super(props);
    this._selectedCrew = this._selectedCrew.bind(this);
    this._renderCrewTiles = this._renderCrewTiles.bind(this);
  }

  state = {
    loading: false,
    liveWithModalData: this.props.livingArrangement.liveWithModalData,
    initialFormState: {},
  };

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        headerTitle={types2.NAVIGATION_TEXT.WHO_I_LIVE_WITH}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        hasFormContentChanged={this._hasFormContentChanged}
        currentScreenName={WhereILiveCrewForm.name}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  componentDidMount() {
    this._setInitialState();
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.livingArrangement.liveWithModalData) {
      this.setState({
        liveWithModalData: nextProps.livingArrangement.liveWithModalData,
      });
    }
  }

  _createMainContents = () => {
    var renderingCrews = [];
    if (Array.isArray(this.state.liveWithModalData)) {
      let family = this.state.liveWithModalData[0]['crews'];
      if (family != undefined) {
        renderingCrews.push(...family);
      }
      let community = this.state.liveWithModalData[2]['crews'];
      if (community != undefined) {
        renderingCrews.push(...community);
      }
      let support = this.state.liveWithModalData[1]['crews'];
      if (support != undefined) {
        renderingCrews.push(...support);
      }
      return (
        <View
          style={[
            CommonStyles.flex1,
            CommonStyles.flexDirectionColumn,
            CommonStyles.alignSelfFlexStart,
            CommonStyles.zIndexCrew1,
          ]}
        >
          <BasicForm
            headerIconImage={CrewPurple}
            headerDisplayString={
              MESSAGES.WHERE_I_LIVE_SCREEN.CREW_MODAL_LIVE_WITH_TITLE
            }
            disableMore={true}
            showButton={
              this.props.navigationParams.params.readOnly == types.ACCESS_FULL
            }
            showButtonTitle={'Add Crew'}
            close={renderingCrews.length > 0 ? this._cancelModal : null}
            save={renderingCrews.length > 0 ? this._saveModal : null}
            modal={this._showCrewForm}
            readOnly={this.props.navigationParams.params.readOnly}
            buttonsPositionTop={true}
            innerTitle={
              renderingCrews.length > 0
                ? types.WHO_I_LIVEWITH_SELECT_MESSAGE
                : null
            }
            contentWidthFull={true}
          >
            {this._renderCrewTiles(renderingCrews)}
          </BasicForm>
        </View>
      );
    }
  };

  _cancelModal = () => {
    this.props.navigationParams.params.close();
  };

  _saveModal = () => {
    this.state.loading = true;
    this.props.actions.LivingArrangementActions.actionResetWhoILiveWithData(
      this.state.liveWithModalData,
    );
    this.props.actions.NavigationParamActions.setParam({
      isCrewSelected: true,
      ...this.props.navigationParams.params,
    });

    setTimeout(() => {
      this.props.navigationParams.params.save(this.state.liveWithModalData);
    }, 1000);
  };

  _showCrewForm = () => {
    const navigationParams = {
      screenMode: types.FORM_MODE_NEW,
      editEmail: true,
      whoILive: true,
      selectedModalData: this.state.liveWithModalData,
      ...this.props.navigationParams.params,
    };
    if (types.isWeb) {
      removeItem(types2.WEB_STORE.CREW_NAVIGATION_PARAMS);
      // Reset crew state
      removeItem(types2.WEB_STORE.CREW_STATE);
      setValue(
        types2.WEB_STORE.CREW_NAVIGATION_PARAMS,
        JSON.stringify(navigationParams),
      );
    }
    this.props.actions.NavigationParamActions.setParam(navigationParams);
    this.props.navigation.navigate(types2.NAV_SCREENS.CREW_FORM);
  };

  _renderCrewTiles = (renderingCrews: any) => {
    if (renderingCrews.length == 0) {
      return (
        <View style={CommonStyles.orContainer}>
          <StandardText
            style={[
              CommonStyles.textWrapper,
              BrandStyles.TextColor10,
              CommonStyles.customFontMedium,
              CommonStyles.font18,
            ]}
          >
            {types.WHO_I_LIVEWITH_NO_CREW_MESSAGE}
          </StandardText>
        </View>
      );
    } else {
      return (
        <GridView
          viewWidth={this.props.viewWidth}
          isMobile={this.props.isMobile}
          itemDimension={400}
          renderItem={(item: any) => this._renderIndividualCrew(item)}
        >
          {renderingCrews}
        </GridView>
      );
    }
  };

  _renderIndividualCrew = (crewItem: any) => {
    // Set the caption - it is their known as if they have one, otherwise user their name
    let caption;
    if (crewItem.knownAs != null && crewItem.knownAs != '') {
      caption = crewItem.knownAs;
    } else {
      caption = `${crewItem.firstName} ${crewItem.lastName}`;
    }

    // Check if the user has a profile image, if so set it
    let crewImage = null;
    if (crewItem.profileImage) {
      if (crewItem.profileImage.url) {
        crewImage = crewItem.profileImage;
      }
    }
    return (
      <View
        style={[
          CommonStyles.marginLeft20,
          CommonStyles.flexDirectionRow,
          CommonStyles.alignSelfFlexStart,
        ]}
      >
        <View style={[CommonStyles.justifyContentCentre]}>
          <RadioButton
            id={crewItem.crewId}
            value={crewItem.crewId}
            disabled={
              this.props.navigationParams.params.readOnly ==
              types.ACCESS_READ_ONLY
            }
            isSelected={crewItem.selected}
            style={[
              CommonStyles.radioButtonPM_DividerStyleBorderless,
              types.isWeb
                ? CommonStyles.paddingBottom20
                : CommonStyles.paddingBottom40,
            ]}
            buttonStyle={CommonStyles.radioButtonPMMedium}
            onChange={this._selectedCrew}
          />
        </View>
        <View
          style={[
            CommonStyles.flex1,
            CommonStyles.flexWrap,
            CommonStyles.paddingBottom20,
          ]}
        >
          <PhotoTile
            key={crewItem.crewId}
            style={StyleSheet.flatten(CommonStyles.CrewPhotoTile)}
            clickable={false}
            readOnly={this.props.navigationParams.params.readOnly}
            apiFileType={types2.FILE_TYPE.CREW_PROFILE}
            imageObject={crewImage}
            showCaption={true}
            caption={caption}
            actionCallback={this._editCrewForm}
            actionData={crewItem}
            accessibilityLabel={caption + ' crew edit'}
            lastUpdate={this.state.lastUpdate}
            photoBorderRadius={CommonStyles.borderRadiusRound}
          />
        </View>
      </View>
    );
  };

  _selectedCrew = (crewItem: any) => {
    var dataArray = JSON.parse(JSON.stringify(this.state.liveWithModalData));
    var foundItem = false;
    for (var i = 0; i < dataArray.length; i++) {
      var crewArray = dataArray[i].crews;
      for (var j = 0; j < crewArray.length; j++) {
        if (crewArray[j].crewId == crewItem.id) {
          crewArray[j].selected = !crewArray[j].selected;
          foundItem = true;
          break;
        }
      }
      if (foundItem) {
        break;
      }
    }
    this.setState({liveWithModalData: dataArray});
  };

  _setInitialState = () => {
    this.setState({
      initialFormState: {
        liveWithModalData: this.props.livingArrangement.liveWithModalData,
      },
    });
  };

  _hasFormContentChanged = () => {
    let initialFormState = this.state.initialFormState;
    let currentFormState = {
      liveWithModalData: this.state.liveWithModalData,
    };
    return (
      JSON.stringify(initialFormState) !== JSON.stringify(currentFormState)
    );
  };
}

const mapStateToProps = (state: any) => ({
  livingArrangement: state.LivingArrangementReducer,
  loadedMemberId: state.LoadedMemberReducer,
  crews: state.CrewReducer,
  member: state.MemberReducer,
  navigationParams: state.NavigationParamsReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    LivingArrangementActions: bindActionCreators(
      LivingArrangementActions,
      dispatch,
    ),
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WhereILiveCrewForm);
