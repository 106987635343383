import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
// import index from 'react-native-safe-area-view';

export const setPayInvoiceStep = (payInvoiceStep: any) => ({
  type: types.GET_PAY_INVOICE_STEP,
  payInvoiceStep,
});

export const setPayInvoiceView = (payInvoiceView: any) => ({
  type: types.GET_PAY_INVOICE_VIEW,
  payInvoiceView,
});

export const getMemberBillersList = (memberBillers: any) => ({
  type: types.GET_MEMBER_BILLERS,
  memberBillers,
});

export const addNewProviderAction = (selectedProvider: any) => ({
  type: types.ADD_NEW_PROVIDER,
  selectedProvider,
});

export const addInvoiceAction = (invoiceDetails: any) => ({
  type: types.ADD_INVOICE_ACTION,
  invoiceDetails,
});

export const addSupportItemsAction = (supportItems: any) => ({
  type: types.ADD_SUPPORT_ITEMS_ACTION,
  supportItems,
});

export const setPaymentSubmitResult = (paymentResult: any) => ({
  type: types.SET_PAYMENT_SUBMIT_RESULT,
  paymentResult,
});

export const removeSupportItemsAction = (index: any) => ({
  type: types.REMOVE_SUPPORT_ITEMS_ACTION,
  index,
});

export const setInvoiceDoc = (invoiceDoc: any) => ({
  type: types.SET_INVOICE_DOC,
  invoiceDoc,
});

export const setInitialState = () => ({
  type: types.RESET_STORE,
});

export const setDraftInvoicesCount = (draftInvoicesCount: any) => ({
  type: types.SET_DRAFT_INVOICES_COUNT,
  draftInvoicesCount: draftInvoicesCount,
});
