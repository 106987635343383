import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    StyleSheet,
    Image,
    Text,
    View,
    TouchableHighlight,
    ImageSourcePropType,
    ViewStyle,
    TextStyle,
    TouchableHighlightProps,
} from 'react-native';
import {CheckbocChecked, CheckbocDisabled} from '../../assets/images';

//const CB_ENABLED_IMAGE = require('./cb_enabled.png');
//const CB_DISABLED_IMAGE = require('./cb_disabled.png');

interface CheckBoxProps {
    accessible?: boolean;
    accessibilityLabel?: string;
    testID?: string;
    label?: string;
    labelBefore?: boolean;
    labelStyle?: TextStyle | TextStyle[];
    labelLines?: number;
    checkboxStyle?: ViewStyle | ViewStyle[];
    containerStyle?: ViewStyle | ViewStyle[];
    checked?: boolean | null;
    checkedImage?: ImageSourcePropType;
    uncheckedImage?: ImageSourcePropType;
    underlayColor?: string;
    onChange?: (newValue: boolean) => void;
    hitSlop?: TouchableHighlightProps['hitSlop'];
    disabled?: boolean;
}

const CheckBox: React.FC<CheckBoxProps> = ({
    accessible,
    accessibilityLabel,
    testID,
    label = 'Label',
    labelBefore = false,
    labelStyle,
    labelLines = 1,
    checkboxStyle,
    containerStyle,
    checked = null,
    checkedImage = CheckbocChecked,
    uncheckedImage = CheckbocDisabled,
    underlayColor = 'transparent',
    onChange,
    hitSlop,
    disabled,
}) => {
    const [internalChecked, setInternalChecked] = useState(false);

    const handleChange = () => {
        if (onChange && typeof checked === 'boolean') {
            onChange(!checked);
        } else {
            const newState = !internalChecked;
            setInternalChecked(newState);
            if (onChange) {
                onChange(newState);
            }
        }
    };

    const source = typeof checked === 'boolean' ? (checked ? checkedImage : uncheckedImage) : (internalChecked ? checkedImage : uncheckedImage);

    return (
        <TouchableHighlight
            accessible={accessible}
            accessibilityLabel={accessibilityLabel}
            testID={testID}
            onPress={handleChange}
            underlayColor={underlayColor}
            style={[styles.flexContainer, containerStyle]}
            checkbox={hitSlop}
            disabled={disabled}
        >
            {labelBefore ? (
                <View style={[styles.container, styles.flexContainer]}>
                    {label ? (
                        <View
                            style={styles.labelContainer}
                            accessible={accessible}
                            accessibilityLabel={`${accessibilityLabel}Label`}
                            testID={`${testID}Label`}
                        >
                            <Text numberOfLines={labelLines} style={[styles.label, labelStyle]}>
                                {label}
                            </Text>
                        </View>
                    ) : null}
                    <Image
                        style={[styles.checkbox, checkboxStyle]}
                        source={source}
                        accessible={accessible}
                        accessibilityLabel={`${accessibilityLabel}Checkbox`}
                        testID={`${testID}Checkbox`}
                    />
                </View>
            ) : (
                <View style={[styles.container, containerStyle]}>
                    <Image
                        style={[styles.checkbox, checkboxStyle]}
                        source={source}
                        accessible={accessible}
                        accessibilityLabel={`${accessibilityLabel}Checkbox`}
                        testID={`${testID}Checkbox`}
                    />
                    {label ? (
                        <View
                            style={styles.labelContainer}
                            accessible={accessible}
                            accessibilityLabel={`${accessibilityLabel}Label`}
                            testID={`${testID}Label`}
                        >
                            <Text numberOfLines={labelLines} style={[styles.label, labelStyle]}>
                                {label}
                            </Text>
                        </View>
                    ) : null}
                </View>
            )}
        </TouchableHighlight>
    );
};

const styles = StyleSheet.create({
    flexContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
    },
    checkbox: {
        width: 26,
        height: 26,
    },
    labelContainer: {
        marginLeft: 10,
        marginRight: 10,
    },
    label: {
        fontSize: 15,
        color: 'grey',
    },
});

export default CheckBox;
