/*
 * Author: Declan Hart
 * Date: 18/11/2019
 * Copyright © 2019 Leap in!. All rights reserved.
 *
 * The Web Service class to handle the Admin Service API calls, namely get feature flags
 */

// Custom functions for helping connect to the secure AWS API Gateway
import {
  initaliseAwsClient,
  configuredAWSLamda,
  buildLambda,
  parseResult,
  apiErrorHandler,
} from './APIHelper';
import AWS from 'aws-sdk';
import {AWS_LAMBDA_SERVICES} from '../environments';
import logger from 'helpers/Logger';
import {APP_VERSION} from '../environments';

export const getFeatureFlags = (
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getFeatureFlags = configuredAWSLamda();
      const requestBody = {
        isDemoProfile,
        version: APP_VERSION,
      };

      const functionName = 'getFeatureFlags';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.ADMIN;
      const params = buildLambda(requestBody, functionName, serviceName);
      getFeatureFlags.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data, true);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};
