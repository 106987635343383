/*
 * Author: Declan Hart
 * Date: 09/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Supports information.
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function SupportsReducer(state = {}, action: any) {
  switch (action.type) {
    case types.GET_SUPPORTS:
      return {
        ...state,
        supports: action.SupportsObj.currentSupports,
        apiCalled: new Date(),
      };
    case types.SUPPORTS.SET_SUPPORT_IMAGE:
      return {
        ...state,
        currentSupport: {
          ...state.currentSupport,
          imageUploadObject: action.imageObjectUpload,
        },
      };
    case types.SUPPORTS.SET_SUPPORT_IMAGE_LIST_LOCAL:
      const supportList = state.supports;
      for (const i in supportList) {
        if (supportList[i].currentSupportId === action.supportId) {
          supportList[i].supportPhoto = action.imageObjectUpload;
        }
      }

      return {
        ...state,
        supports: supportList,
      };
    case types.CREATE_SUPPORT:
      return {
        ...state,
        newSupport: action.SupportObj,
      };
    case types.DELETE_SUPPORT:
      return {
        ...state,
      };
    case types.ADD_OWN_SUPPORT_ITEMS_ACTION:
      return {
        ...state,
        OwnSupports: state.OwnSupports,
      };
    case types.REMOVE_OWN_SUPPORT_ITEMS_ACTION:
      return {
        ...state,
        OwnSupports: state.OwnSupports.filter(
          (item: any, index: any) => index !== action.index,
        ),
      };
    case types.RESET_TEMP_SUPPORT:
      return {
        ...state,
        addSupportStep: 0,
        tempSupportItems: [],
        tempSupportCategories: [],
        tempSupportCrew: [],
      };
    case types.ADD_TEMP_SUPPORT_ITEMS:
      return {
        ...state,
        tempSupportItems: action.tempSupportItems,
      };
    case types.ADD_TEMP_SUPPORT_CATEGORIES:
      return {
        ...state,
        tempSupportCategories: action.tempSupportCategories,
      };
    case types.ADD_TEMP_SUPPORT_CREW:
      return {
        ...state,
        tempSupportCrew: action.tempSupportCrew,
      };
    case types.ADD_SUPPORT_STEP:
      return {
        ...state,
        addSupportStep: action.addSupportStep,
      };
    case types.ADD_SUPPORT_PHOTO_CAPTION:
      const supports = state.supports;
      for (const i in supports) {
        if (supports[i].currentSupportId === action.supportId) {
          supports[i].photoCaption = action.photoCaption;
        }
      }

      return {
        ...state,
        supports: supports,
      };
    case types.SET_LIST_REFERRALS:
      return {
        referrals: action.referrals,
      };
    default:
      return state;
  }
}
