/*
 * Author: Nirodha Perera
 * Date: 04/10/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the Payment History Item list as seperate sections
 */

import React, {Component} from 'react';
import {View, Image} from 'react-native';

import {StandardText} from '../Atoms';
import {CurrencyText, PaymentListHeader} from './';
import {
  formatDurationString,
  formatDDMMMYYDate,
  formatQuantity,
} from '../../Helpers/Utils';
import {isWeb} from '../../Constants/Constants';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import {GreyClockIcon3x} from '../../assets/images';
import logger from 'helpers/Logger';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

class PaymentList extends Component {
  constructor(props: any) {
    super(props);
    this._renderTableContents = this._renderTableContents.bind(this);
  }

  render() {
    return (
      <View>
        {this._renderTableHeaderRow()}
        {this._renderLineItem()}
      </View>
    );
  }

  _renderLineItem = () => {
    let dataArray = this.props.data;
    if (Array.isArray(dataArray) && dataArray.length > 0) {
      return dataArray.map((item, index) => {
        const itemContent = [
          item.itemCode,
          item.description,
          item.budgetCategory,
          this._renderQuantity(item.quantity, item.duration),
          item.unitPrice,
          item.total,
          this._renderDateRange(item.startDate, item.endDate),
        ];

        if (item.funded !== undefined) {
          itemContent.push(item.funded);
        }

        let addSeparator = true;

        if (index == dataArray.length - 1) {
          addSeparator = false;
        }

        return this._renderTableContents(itemContent, index, addSeparator);
      });
    }
  };

  _renderQuantity = (quantity: any, duration: any) => {
    if (quantity !== undefined && quantity !== 0) {
      return formatQuantity(quantity);
    } else {
      return formatDurationString(duration);
    }
  };

  _renderDateRange = (startDate: any, endDate: any) => {
    if (endDate == undefined) {
      return formatDDMMMYYDate(startDate);
    }
    return formatDDMMMYYDate(startDate) + ' - ' + formatDDMMMYYDate(endDate);
  };

  _renderTableHeaderRow = () => {
    return (
      <View>
        <View
          style={[
            CommonStyles.justifyFlexRowStart,
            CommonStyles.marginBottom5,
            CommonStyles.alignItemsCenter,
          ]}
        />
        <View
          style={[
            CommonStyles.justifyFlexRowStart,
            CommonStyles.marginTop10,
            CommonStyles.marginBottom10,
          ]}
        >
          <View
            style={[
              isWeb ? CommonStyles.flex02 : CommonStyles.flex1,
              CommonStyles.flexDirectionColumn,
            ]}
          >
            <View
              style={[isWeb ? CommonStyles.alignCenter : CommonStyles.flex1]}
            >
              <StandardText
                style={[
                  CommonStyles.title_ListContainerSemiBold,
                  CommonStyles.customFontSemiBold,
                ]}
              >
                {this.props.itemCode}
              </StandardText>
            </View>
          </View>
          <View
            style={[
              isWeb ? CommonStyles.flex025 : CommonStyles.flex1,
              CommonStyles.flexDirectionColumn,
            ]}
          >
            <View
              style={[isWeb ? CommonStyles.alignCenter : CommonStyles.flex1]}
            >
              <StandardText
                style={[
                  CommonStyles.title_ListContainerSemiBold,
                  CommonStyles.customFontSemiBold,
                ]}
              >
                {this.props.itemDesc}
              </StandardText>
            </View>
          </View>
          <View
            style={[
              isWeb ? CommonStyles.flex02 : CommonStyles.flex1,
              CommonStyles.flexDirectionColumn,
            ]}
          >
            <View
              style={[isWeb ? CommonStyles.alignCenter : CommonStyles.flex1]}
            >
              <StandardText
                style={[
                  CommonStyles.title_ListContainerSemiBold,
                  CommonStyles.customFontSemiBold,
                ]}
              >
                {this.props.dateRange}
              </StandardText>
            </View>
          </View>
          <View
            style={[
              isWeb
                ? [CommonStyles.flex015]
                : [CommonStyles.flex1, CommonStyles.alignCenter],
              CommonStyles.flexDirectionColumn,
            ]}
          >
            <View
              style={[
                isWeb
                  ? CommonStyles.alignItemsCenter
                  : CommonStyles.containerList_ItemFigureTitle,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.title_ListContainerSemiBold,
                  CommonStyles.customFontSemiBold,
                ]}
              >
                {this.props.itemQty}
              </StandardText>
            </View>
          </View>
          <View
            style={[
              isWeb
                ? [CommonStyles.flex01]
                : [CommonStyles.flex1, CommonStyles.alignCenter],
              CommonStyles.flexDirectionColumn,
            ]}
          >
            <View
              style={[
                isWeb
                  ? CommonStyles.alignItemsCenter
                  : CommonStyles.containerList_ItemFigureTitle,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.title_ListContainerSemiBold,
                  CommonStyles.customFontSemiBold,
                ]}
              >
                {this.props.itemUnitPrice}
              </StandardText>
            </View>
          </View>
          <View
            style={[
              isWeb
                ? [CommonStyles.flex01]
                : [CommonStyles.flex1, CommonStyles.alignCenter],
              CommonStyles.flexDirectionColumn,
            ]}
          >
            <View
              style={[
                isWeb
                  ? CommonStyles.alignItemsCenter
                  : CommonStyles.containerList_ItemFigureTitle,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.title_ListContainerSemiBold,
                  CommonStyles.customFontSemiBold,
                  CommonStyles.textAlignCentre,
                ]}
              >
                {this.props.itemTotalPrice}
              </StandardText>
            </View>
          </View>
          <View
            style={[
              isWeb
                ? [CommonStyles.flex01]
                : [CommonStyles.flex1, CommonStyles.alignCenter],
              CommonStyles.flexDirectionColumn,
            ]}
          >
            <View
              style={[
                isWeb
                  ? CommonStyles.alignItemsCenter
                  : CommonStyles.containerList_ItemFigureTitle,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.title_ListContainerSemiBold,
                  CommonStyles.customFontSemiBold,
                  CommonStyles.textAlignCentre,
                ]}
              >
                {this.props.itemFundedPrice}
              </StandardText>
            </View>
          </View>
        </View>
      </View>
    );
  };

  _fundedTotal = (itemFunded: any) => {
    if (itemFunded !== undefined) {
      return (
        <View
          style={[
            isWeb
              ? [CommonStyles.flex01]
              : [CommonStyles.flex1, CommonStyles.alignCenter],
            CommonStyles.flexDirectionColumn,
          ]}
        >
          <View
            style={[
              isWeb
                ? CommonStyles.alignItemsCenter
                : CommonStyles.containerList_ItemFigureTitle,
            ]}
          >
            <CurrencyText
              value={itemFunded}
              isPositive={true}
              integerStyle={[
                CommonStyles.font20,
                CommonStyles.customFont,
                BrandStyles.TextColor5,
              ]}
            />
          </View>
        </View>
      );
    }
  };

  _renderTableContents = (contents: any, key: any, addSeparator: any) => {
    const [
      itemCode,
      itemDesc,
      itemCategory,
      itemQty,
      itemUnitPrice,
      itemTotal,
      dateRange,
      itemFunded,
    ] = contents;

    let viewKey = key;
    if (!viewKey) {
      viewKey = 0;
    }

    return (
      <View
        key={viewKey}
        style={
          addSeparator
            ? CommonStyles.containerList_Item
            : CommonStyles.containerList_LastItem
        }
      >
        <View
          style={[
            CommonStyles.justifyFlexRowStart,
            CommonStyles.marginBottom5,
            CommonStyles.alignItemsCenter,
          ]}
        />
        <View
          style={[
            CommonStyles.justifyFlexRowStart,
            CommonStyles.marginTop10,
            CommonStyles.marginBottom10,
          ]}
        >
          <View
            style={[
              isWeb
                ? [CommonStyles.flex02, CommonStyles.alignCenter]
                : CommonStyles.flex1,
              CommonStyles.flexDirectionColumn,
            ]}
          >
            <View style={CommonStyles.containerGreyTextPayment}>
              <StandardText
                style={[
                  CommonStyles.title_ListContainer,
                  CommonStyles.customFont,
                  isWeb ? [CommonStyles.containerInvoiceIdTextPayment] : null,
                ]}
              >
                {itemCode}
              </StandardText>
            </View>
          </View>
          <View
            style={[
              isWeb ? CommonStyles.flex025 : CommonStyles.flex1,
              CommonStyles.flexDirectionColumn,
            ]}
          >
            <View
              style={[
                isWeb
                  ? [CommonStyles.alignItemsFlexStart, CommonStyles.alignCenter]
                  : CommonStyles.containerList_ItemFigureTitle,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.title_ListContainer,
                  CommonStyles.customFont,
                ]}
              >
                {itemDesc}
              </StandardText>
            </View>
          </View>
          <View
            style={[
              isWeb ? CommonStyles.flex02 : CommonStyles.flex1,
              CommonStyles.flexDirectionColumn,
            ]}
          >
            <View
              style={[isWeb ? CommonStyles.alignCenter : CommonStyles.flex1]}
            >
              <StandardText
                style={[
                  CommonStyles.title_ListContainer,
                  CommonStyles.customFont,
                ]}
              >
                {dateRange}
              </StandardText>
            </View>
          </View>
          <View
            style={[
              isWeb
                ? [CommonStyles.flex015]
                : [CommonStyles.flex1, CommonStyles.alignCenter],
              CommonStyles.flexDirectionColumn,
            ]}
          >
            <View
              style={[
                isWeb
                  ? CommonStyles.alignItemsCenter
                  : CommonStyles.containerList_ItemFigureTitle,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.title_ListContainer,
                  CommonStyles.customFont,
                ]}
              >
                {itemQty}
              </StandardText>
            </View>
          </View>
          <View
            style={[
              isWeb
                ? [CommonStyles.flex01]
                : [CommonStyles.flex1, CommonStyles.alignCenter],
              CommonStyles.flexDirectionColumn,
            ]}
          >
            <View
              style={[
                isWeb
                  ? CommonStyles.alignItemsCenter
                  : CommonStyles.containerList_ItemFigureTitle,
              ]}
            >
              <CurrencyText
                value={itemUnitPrice}
                isPositive={true}
                integerStyle={[
                  CommonStyles.font20,
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                ]}
              />
            </View>
          </View>
          <View
            style={[
              isWeb
                ? [CommonStyles.flex01]
                : [CommonStyles.flex1, CommonStyles.alignCenter],
              CommonStyles.flexDirectionColumn,
            ]}
          >
            <View
              style={[
                isWeb
                  ? CommonStyles.alignItemsCenter
                  : CommonStyles.containerList_ItemFigureTitle,
              ]}
            >
              <CurrencyText
                value={itemTotal}
                isPositive={true}
                integerStyle={[
                  CommonStyles.font20,
                  CommonStyles.customFont,
                  BrandStyles.TextColor5,
                ]}
              />
            </View>
          </View>
          {this._fundedTotal(itemFunded)}
        </View>
      </View>
    );
  };
}

export default PaymentList;
