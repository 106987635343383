/*
 * Author: Andrew Lee
 * Date: 07/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is the class for helping with making API calls in a manner that will allow the credentials to be refreshed, as well as some error handling to be included in the API call.
 */

import AWS from 'aws-sdk';
import {Alert} from 'react-native';
import logger from 'helpers/Logger';
import NetInfo from '@react-native-community/netinfo';

import {
  ERRORS as errorMessages,
  COMMON_BUTTONS as buttonText,
} from '../Constants/Messages';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {store} from '../App';
import {preAuthenticate} from '../API/PreauthenticationHelper';
import {shakeOfflineNotice} from '../Actions/OfflineNoticeActions';
import {logUserOut} from '../Actions/LogoutActions';
import {getValue} from '../API/WebPersistenceStore';
import * as env from '../environments';
import {checkTokenExpiration} from './AuthenticationAPI';

/**
 * This function is a helper function that will fetch the credentials from AWS and then wait until all the API calls in the array has finished before calling the callback functions.
 * @param {array} apiArray The array of apis that need to be called and waited for a response.
 * @param {function} successHandler The handler for when the api calls are all successful. It will return an array of results that will match the position of the array called.
 * @param {function} errorHandler The handler for when errors are encountered in the api calls
 * @param {function} setStateHandler The handler for setting the state, if the calling component does not pass in the error handler.
 */
const callAPIs = (
  apiArray: any,
  successHandler: any,
  errorHandler: any,
  setStateHandler: any,
) => {
  NetInfo.fetch().then(isConnected => {
    if (!isConnected) {
      if (isFunction(setStateHandler)) {
        setStateHandler();
      }
      store.dispatch(shakeOfflineNotice());
    }
  });

  if (!isFunction(successHandler)) {
    logger.log("You're missing the success handler!");
  }

  if (!isFunction(errorHandler) && !isFunction(setStateHandler)) {
    logger.log("You're missing either an error handler or set state handler!");
  }

  const completeAPICalls = () => {
    // Loop through the promises and embed it into the promise all
    const promisesArray = apiArray.map((sendRequest: any) => {
      if (isFunction(sendRequest)) {
        return sendRequest();
      }

      logger.log(
        'There is an api function that does not have a send request!!',
      );
      return null;
    });

    // Can complete the calls, needs to wait for all the promises to finish
    Promise.all(promisesArray)
      .then(results => {
        successHandler(results);
      })
      .catch(err => {
        if (err && !err.apiError && isFunction(errorHandler)) {
          const modifiedError = err;
          // Convert the err into something that can be consumed by all apis
          if (err.name) {
            modifiedError.code = err.name;
          }
          errorHandler(modifiedError);
        }
        // There is an error, but the api does not want to handle the error, so handle the error here.
        else if (err && (err.apiError || !isFunction(errorHandler))) {
          // This will be the catch all for the different errors and the api error is set to true so it will be handled here
          handleError(err, {
            apiArray,
            successHandler,
            errorHandler,
            setStateHandler,
          });
        } else {
          // No error handling required for this action
          logger.log('Some error occurred: ', err);
          errorHandler && errorHandler(err);
        }
      });
  };
  if (types.isWeb && getValue('cognitoId')) {
    let cognitoSignupUrl = store.getState().UserReducer.demoProfile
      ? env.DEMO_COGNITO_EMAIL_SIGNUP_URL
      : env.COGNITO_EMAIL_SIGNUP_URL;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: env.COGNITO_IDENTITY_POOL,
      Logins: {
        [cognitoSignupUrl]: getValue('cognitoId'),
      },
    });
    AWS.config.region = env.AWS_REGION;
  }

  AWS.config.credentials.get((err: any) => {
    // There are errors, so need to work out what kind of error occurred.
    if (err) {
      logger.log('There was an error getting credentials: ', err.code, err);
      if (err.code == types.NOT_AUTHORIZED) {
        // The credentials has expired, so need to refresh the credential
        AWS.config.credentials.refresh((err: any) => {
          // There was an error refreshing the credential, so call the error handler
          if (err) {
            logger.log(
              'There was an error with the credential refresh: ',
              err,
              err.name,
              err.code,
            );

            if (types.isWeb) {
              checkTokenExpiration(
                () => {
                  callAPIs(
                    apiArray,
                    successHandler,
                    errorHandler,
                    setStateHandler,
                  );
                },
                (err: any) => {
                  logger.log(err);
                  store.dispatch(logUserOut(true));
                },
              );
            } else {
              // Since the token refresh has failed, try to reauthenticate using keychain, and once complete, to retry
              preAuthenticate(null, () => {
                callAPIs(
                  apiArray,
                  successHandler,
                  errorHandler,
                  setStateHandler,
                );
              });
            }
          }
          // There is no error, so can proceed with the API calls
          else {
            completeAPICalls();
          }
        });
      } else {
        logger.log('There were some other issue that occurred');
        if (isFunction(errorHandler)) {
          errorHandler(err);
        } else {
          Alert.alert(types2.ERROR, types2.MISSING_ERROR_HANDLER);
        }
      }
    }
    // There are not errors, so can continue calling the complete API calls
    else {
      completeAPICalls();
    }
  });
};

/**
 * Function to do some checks on the function call.
 * @param {function} functionToCheck The function to be checked if it is an actual function, and if it exists.
 */
const isFunction = (functionToCheck: any) =>
  functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';

/**
 *
 * @param {Error} err This is an error object that will be used to determine what the error alert to be displayed would be.
 * @param {any} retryCall This will hold all the information that is required to make a try again function call.
 */
const handleError = (err: any, retryCall: any) => {
  const alertTitle = errorMessages.GENERIC_ISSUE_ALERT_TITLE;
  let alertMessage = errorMessages.GENERIC_ISSUE_ALERT_MSG;

  // Build the buttons that would be presented to the user
  const alertButtons = [
    {
      text: buttonText.TRY_AGAIN,
      onPress: () => {
        callAPIs(
          retryCall.apiArray,
          retryCall.successHandler,
          retryCall.errorHandler,
          retryCall.setStateHandler,
        );
      },
    },
    {
      text: buttonText.CLOSE,
      onPress: () => {
        // Call the set state handler - should be making the loading opacity disappear
        const setStateHandler = retryCall.setStateHandler;
        if (isFunction(setStateHandler)) {
          setStateHandler();
        } else {
          logger.log('Missing the set state handler when using auto handler!!');
        }
      },
    },
  ];

  const modifiedError = err;
  // Determine if the error has a name instead of code, and if it has name, then overwrite the code with the name
  if (err.name) {
    modifiedError.code = err.name;
  }

  logger.log('APICaller handleError with error: ', modifiedError);

  // Go through the error and determine what kind of error code it is to display the correct message.
  if (err.code == 403) {
    alertMessage = errorMessages.UNAUTHORISED;
  } else if (err.code == 500) {
    alertMessage = errorMessages.GENERIC_GET;
  }

  // Present the alert on screen
  if (err.code == types.NETWORK_FAILURE) {
    // Call the set state handler - should be making the loading opacity disappear
    const setStateHandler = retryCall.setStateHandler;
    if (isFunction(setStateHandler)) {
      store.dispatch(shakeOfflineNotice());
      setStateHandler();
    }
  } else if (err.code == types2.NO_PLANS_ERROR_CODE) {
    logger.log('APICaller errorHandler received NO_PLANS_ERROR_CODE');
  } else {
    Alert.alert(alertTitle, alertMessage, alertButtons, {cancelable: false});
  }
};

export {callAPIs};
