/*
 * Authour: Andrew Seeley
 * Date: 09/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This screen will hold the rendering and logic of the displaying the crew that the member has attached to their account.
 */

import React, {Component} from 'react';
import ReactNative, {
  View,
  Modal,
  Image,
  ActivityIndicator,
  Platform,
  Alert,
} from 'react-native';
// import {NavigationActions} from 'react-navigation-awseeley--enhanced';
//import {StackActions} from 'react-navigation';
import {CommonActions, StackActions} from '@react-navigation/native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {debounce} from 'lodash';

import {
  uploadImageToS3,
  uploadImageToS3WithoutResize,
} from '../Helpers/ImageHelper';
import {trimObjectProperties} from '../Helpers/Utils';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {ADD_CREW as crewMessages} from '../Constants/Messages';
import {MESSAGES} from '../Constants/Messages';

import {
  Container,
  ActionButton,
  AutoComplete,
  SelectedItem,
  CrewOrganisationRadio,
  GridView,
  RequiredIndicator,
  FormTitle,
  CrewContactInformation,
  CrewFirstName,
  CrewLastName,
  BasicOverlayPopupModal,
  FormSubtitle,
} from '../Components/Molecules';
import {BasicForm, BasicPopupAlert} from '../Components/Organisms';
import {routeTolanding} from '../Components/RouteToLanding/RouteToLanding';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {USER_TYPE} from '../Constants/Constants2';

// Import API
import {callAPIs} from '../API/APICaller';
import {getMember} from '../API/MemberAPI';
import {getCrewAccessLevels} from '../API/CrewAPI';
import {getCrew} from '../API/PersonAPI';
import {getCrewCategories} from '../API/ReferenceAPI';
import {
  putCrewByPersonId,
  deleteCrew,
  getUploadURL,
  getAvatarImages,
  getExistingOrganisations,
  putCrewOrganization,
  updateCrewOrganization,
} from '../API/MemberAPI';
import * as CrewActions from '../Actions/CrewActions';
import * as LivingArrangementActions from '../Actions/LivingArrangementActions';
import * as MemberActions from '../Actions/MemberActions';
import * as AvatarListActions from '../Actions/AvatarListActions';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {getValue, setValue, removeItem} from '../API/WebPersistenceStore';
import * as loadedMemberActions from '../Actions/LoadedMemberActions';

import {
  StandardInput,
  StandardText,
  StandardPicker,
  StandardTouchableOpacity,
  StandardButton,
} from '../Components/Atoms';

// Import the placeholder image
import {
  InformationIconx3,
  CameraCircle,
  CameraSquare,
} from '../assets/images/index';
import {
  addIcon,
  aboutMeIconCharcoal,
  ContactInfoCharcoal,
  padlockCharcoal,
  crewIconGray,
} from '../assets/images/vector-icons';
import {isTablet} from '../Helpers/PlatformSynchronizer';
import logger from 'helpers/Logger';

import { DOMPurify } from 'helpers/dompurify';

const INPUTS = {
  SCROLL_VIEW: 'scrollView',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE_NUMBER: 'phoneNumber',
  EMAIL: 'email',
  RELATIONSHIP: 'relationship',
  ORG_NAME: 'orgName',
  SUPPORT: 'support',
  CREW_TYPE: {
    CUSTOM: 'custom',
    SERACHED: 'searched',
    PERSON: 'person',
    ORGANISATION: 'organisation',
  },
};
let modalMode: any, initalImage: any;
const CUT_OFF_PADDING = 75;

class CrewForm extends Component {
  inputs: any;
  state = {
    loading: false,
    addNewOne: false,
    orgAdded: false,
    orgSelected: [],
    organisation: '',
    newOrgAdd: false,
    searchedOrg: [],
    orgReadOnly: types.ACCESS_FULL,
    searchText: '',
    showAddCustomOrg: false,
    selectedSuportName:
      this.props.navigationParams.params &&
      this.props.navigationParams.params.itemselectedSuportItem
        ? this.props.navigationParams.params.itemselectedSuportItem
            .selectedSuportName
        : '',
    billerCode:
      this.props.navigationParams.params &&
      this.props.navigationParams.params.itemselectedSuportItem
        ? this.props.navigationParams.params.itemselectedSuportItem.billerCode
        : '',
    filteredSuport: {},
    selectedSuportItem: {},
    scrollEnabled: true,
    crewEmailAvailable: true,
    modalTitle: 'Add to your crew',
    selectedCrew: {
      // This is a 'placeholder' object. It is used when creating a new crew.
      id: '', // Member person id that the crew is being attached to
      crewId: '', // The crew persions id's that is being modifed (optional), none creates a new one
      firstName: '',
      lastName: '',
      category: 'family',
      phoneNumber: '',
      email: '',
      relationship: '',
      orgName: '',
      support: '',
      notificationType: '',
      ndisRegistrationNumber: '',
      grantAccessToYourAccount: 'no',
      access: {
        profile: 'none',
        finances: 'none',
        documents: 'none',
        notifications: 'none',
        decisionMaker: 'false',
      },
    },
    selectedCrewForm: {
      id: '', // Member person id that the crew is being attached to
      crewId: '', // The crew persions id's that is being modifed (optional), none creates a new one)
      firstName: '',
      lastName: '',
      category: 'family',
      phoneNumber: '',
      email: '',
      relationship: '',
      orgName: '',
      support: '',
      notificationType: '',
      ndisRegistrationNumber: '',
      isCrewActive: '',
      grantAccessToYourAccount: 'no',
      access: {
        profile: 'none',
        finances: 'none',
        documents: 'none',
        notifications: 'none',
        decisionMaker: 'false',
      },
    },
    selectedModalImage: null, // Stores the response from the modal image picker. If null the user has not selected an image for upload
    validFirstName: '',
    validLastName: '',
    validEmail: '',
    validPhoneNumber: '',
    valideOrganisation: '',
    selectedAccess: {
      finances: false,
      documents: false,
      profile: false,
    },
    avatarList: null,
    profilePictureModalVisible: false,
    selectedImageUrl: !isTablet ? CameraSquare : CameraCircle,
    personTextColor: BrandStyles.TextColor1,
    organisationTextColor: BrandStyles.TextColor2,
    personColor: BrandStyles.primaryBgColor3,
    organisationColor: BrandStyles.darkgreyColor,
    profileTypePerson: true,
    editOrg: false,
    orientation: '',
    isOpen: this.props.sideMenu ? this.props.sideMenu.sideMenuIsOpen : false,
    viewContainerDimensions: undefined,
    whyInvitePopupVisible: false,
    crewDeletionConfirmationVisible: false,
  };

  constructor(props: any) {
    super(props);

    this.inputs = {};
    this.setAccessDefaultOnDecisionMaking.bind(this);
    this._getAvatarImages.bind(this);
    this._viewSelectProfilePicture.bind(this);
    this.toggleProfileType.bind(this);
    this._removeSelectedOrganisation.bind(this);
    this._renderSelectedOrganisations.bind(this);
    this.selectOption.bind(this);
    this._renderSearch = this._renderSearch.bind(this);
    this._AddOrg = this._AddOrg.bind(this);
    this._createMainContents.bind(this);
    this._editPhoneNumber = this._editPhoneNumber.bind(this);
    this._editEmail.bind(this);
    this.editFirstName = this.editFirstName.bind(this);
    this.editLastName = this.editLastName.bind(this);
    this.onSelectSubOption = this.onSelectSubOption.bind(this);
  }

  UNSAFE_componentWillMount() {
    // Grab the crew from redux & set it in the state...
    initalImage = null;
    modalMode = this._getParamValue('screenMode');
    if (modalMode == types.FORM_MODE_EDIT) {
      // Get the selcted crew
      const crew = this._getParamValue('selectedCrew');
      if (crew['memberType'] == 'organisation') {
        crew['support'] = crew['extraInformation'];
        const selectedOrganisation = this.state.orgSelected;
        selectedOrganisation.push(crew['orgName']);
        const phoneNo = crew['phoneNumber'];
        crew['phoneNumber'] = phoneNo.replace(/\s/g, '');
        this.setState({
          profileTypePerson: false,
          orgSelected: selectedOrganisation,
          organisation: '',
          orgAdded: true,
          addNewOne: false,
          newOrgAdd: true,
          searchedOrg: [],
          editOrg: true,
        });
      }
      if (crew['memberType'] === 'person') {
        const selectedOrganisation = this.state.orgSelected;
        let orgAdded = false;
        if (crew['orgName']) {
          selectedOrganisation.push(crew['orgName']);
          orgAdded = true;
        }
        this.setState({
          profileTypePerson: true,
          orgSelected: selectedOrganisation,
          organisation: '',
          orgAdded,
          addNewOne: false,
          newOrgAdd: true,
          searchedOrg: [],
          editOrg: true,
        });
      }
      // Check if we need to show any account access stuff
      crew['grantAccessToYourAccount'] = 'no';

      // Loop over the access the crew has. If any of them are read only or above
      // Set grantAccessToYourAccount to yes which will cause
      // The granular controls for access to render
      const accessLevels = crew['access'];
      if (accessLevels) {
        Object.keys(accessLevels).forEach(key => {
          if (
            accessLevels[key] !== 'none' &&
            accessLevels[key] !== 'false' &&
            key !== types2.CREW_ACCCESS_KEYS.DECISION_MAKER
          ) {
            crew['grantAccessToYourAccount'] = 'yes';
          }
        });
      }

      // DB stores blank emails as null, otherwise a duplicate error occurs
      // So if it's a null email set it back to a string
      if (crew['email'] == null) {
        crew['email'] = '';
      }

      let previousState;
      if (types.isWeb) {
        //Get stored crew values
        previousState = JSON.parse(getValue(types2.WEB_STORE.CREW_STATE));
      }
      if (previousState) {
        // Set selected image
        previousState.selectedImageUrl = this.state.selectedImageUrl;
        this.setState(previousState);
      } else {
        // This is used to set the image in the modal, if it is null
        initalImage = crew.profileImage;
        this.setState({
          selectedCrewForm: crew,
          selectedModalImage: initalImage,
          modalTitle: 'Edit crew',
        });
      }

      // This will set the placeholder image
      this._setImageDetails();
    } else {
      let previousState;
      if (types.isWeb) {
        // Get stored crew values
        previousState = JSON.parse(getValue(types2.WEB_STORE.CREW_STATE));
      }
      if (previousState) {
        // Set selected image
        previousState.selectedImageUrl = this.state.selectedImageUrl;
        this.setState(previousState);
      } else {
        this.setState({
          modalTitle: 'Add crew',
          selectedModalImage: null,
          selectedCrewForm: this.state.selectedCrew,
        });
      }

      this._setImageDetails();
    }
  }

  componentDidMount() {
    this._setInitialState();
  }

  focusNextField(inputs: any, nextField: any) {
    if (inputs[nextField].focus) {
      inputs[nextField].focus();
    }
  }

  _setImageDetails = () => {
    if (
      types.isWeb &&
      this.props.navigationParams.params &&
      this.props.navigationParams.params.crewImage
    ) {
      const crewImage = this.props.navigationParams.params.crewImage;
      if (crewImage.uri) {
        this.setState({
          selectedModalImage: crewImage,
          selectedImageUrl: {uri: crewImage.uri},
        });
      }else if (crewImage.localUri) {
        this.setState({
          selectedImageUrl: {uri: URL.createObjectURL(crewImage)},
        });
      } else {
        this.setState({
          selectedImageUrl: CameraCircle,
        });
      }
    } else {
      if (!initalImage) {
        this.setState({
          selectedImageUrl: isTablet ? CameraSquare : CameraCircle,
        });
      } else if (initalImage.uri) {
        this.setState({selectedImageUrl: {uri: initalImage.uri}});
      } else {
        this.setState({selectedImageUrl: {uri: initalImage.url}});
      }
    }
  };

  _getParamValue = (paramValue: any) => {
    if (
      this.props.navigationParams.params &&
      this.props.navigationParams.params[paramValue]
    ) {
      return this.props.navigationParams.params[paramValue];
    } else {
      if (types.isWeb) {
        const navigationParams = JSON.parse(
          getValue(types2.WEB_STORE.CREW_NAVIGATION_PARAMS),
        );
        return navigationParams[paramValue];
      }
    }
  };

  _setLoading = (loadingState: any) => {
    if (types.isWeb) {
      this.props.setLoading(loadingState);
    } else {
      this.setState({loading: loadingState});
    }
  };

  _goBack = () => {
    if (types.isWeb) {
      // Remove stored crew type
      removeItem(types2.WEB_STORE.CREW_TYPE);
      this.props.navigation.goBack();
    } else {
      this.props.navigation.dispatch(CommonActions.goBack());
    }
  };

  render() {
    let screenMode = this._getParamValue('screenMode');
    let headerText = types2.NAVIGATION_TEXT.EDIT_CREW;
    if (screenMode == types.FORM_MODE_NEW) {
      headerText = types2.NAVIGATION_TEXT.ADD_CREW;
    }

    if (types.isWeb) {
      return this._createMainContents();
    }

    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={headerText}
        hasFormContentChanged={this._hasFormContentChanged}
        currentScreenName={CrewForm.name}
        scrollEnabled={this.state.scrollEnabled}
        isScroll={true}
        onRef={(ref: any) => {
          this.inputs[INPUTS.SCROLL_VIEW] = ref;
        }}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  find_dimesions(layout: any) {
    const {x, y, width, height} = layout;
    this.setState({
      viewWidth: width,
    });
  }

  _createMainContents = () => {
    let screenMode = this._getParamValue('screenMode');
    return (
      <View
        style={[
          CommonStyles.flex1,
          CommonStyles.flexDirectionColumn,
          CommonStyles.alignSelfFlexStart,
          CommonStyles.zIndexCrew1,
        ]}
      >
        {screenMode == types.FORM_MODE_NEW ? (
          <BasicForm
            headerDisplayString={crewMessages.TITLE}
            headerIconImage={crewIconGray}
            secondLevel={true}
            disableMore={true}
            showButton={false}
            readOnly={this.props.member.access.profile}
            contentWidthFull={true}
          >
            <View
              style={[
                BrandStyles.lightGreyBgColour,
                CommonStyles.phototileCorner,
                types.isWeb ? CommonStyles.marginBottom20 : {},
              ]}
            >
              {this._renderToggleButtonView()}
            </View>
          </BasicForm>
        ) : null}
        {this.state.profileTypePerson ? (
          <BasicForm
            headerDisplayString={
              screenMode == types.FORM_MODE_NEW
                ? crewMessages.CREW_PROFILE
                : crewMessages.UPDATE_YOUR_CREW
            }
            headerIconImage={aboutMeIconCharcoal}
            secondLevel={true}
            disableMore={true}
            buttonsPositionTop={true}
            disableButtomButtons={true}
            close={() => this._closeCrewForm()}
            save={() => this._saveCrewForm()}
            readOnly={this.props.member.access.profile}
            actionOverride={
              screenMode != types.FORM_MODE_NEW
                ? types.ACTION_BUTTON.DELETE
                : null
            }
            showButton={
              screenMode != types.FORM_MODE_NEW &&
              this.props.member.access.profile == types.ACCESS_FULL
                ? true
                : false
            }
            modal={
              screenMode != types.FORM_MODE_NEW
                ? () => this._confirmCrewDeletion()
                : null
            }
            contentWidthFull={true}
          >
            <View
              style={[CommonStyles.flex1]}
              onLayout={event => {
                this.find_dimesions(event.nativeEvent.layout);
              }}
            >
              {this.renderPersonProfile()}
            </View>
          </BasicForm>
        ) : (
          <View style={CommonStyles.zIndexCrew2}>
            <BasicForm
              headerDisplayString={crewMessages.ORGANISATION_PROFILE}
              headerIconImage={aboutMeIconCharcoal}
              secondLevel={true}
              disableMore={true}
              buttonsPositionTop={true}
              disableButtomButtons={true}
              close={() => this._closeCrewForm()}
              showButton={false}
              save={() => this._saveCrewForm()}
              readOnly={this.props.member.access.profile}
              actionOverride={
                screenMode != types.FORM_MODE_NEW
                  ? types.ACTION_BUTTON.DELETE
                  : null
              }
              showButton={screenMode != types.FORM_MODE_NEW ? true : false}
              modal={
                screenMode != types.FORM_MODE_NEW
                  ? () => this._confirmCrewDeletion()
                  : null
              }
              contentWidthFull={true}
            >
              <View style={[CommonStyles.flex1]}>
                {this.renderCrewOrganizationProfile()}
              </View>
            </BasicForm>
          </View>
        )}

        <View style={CommonStyles.zIndexCrew1}>
          <BasicForm
            headerDisplayString={crewMessages.CONTACT_INFO}
            headerIconImage={ContactInfoCharcoal}
            secondLevel={true}
            disableMore={true}
            buttonsPositionBottom={
              !(this._isDecisionMaker() && this.state.profileTypePerson)
            }
            close={
              this._isDecisionMaker() && this.state.profileTypePerson
                ? null
                : () => this._closeCrewForm()
            }
            showButton={false}
            save={
              this._isDecisionMaker() && this.state.profileTypePerson
                ? null
                : () => this._saveCrewForm()
            }
            readOnly={this.props.member.access.profile}
            contentWidthFull={true}
          >
            <View
              style={[
                CommonStyles.flex1,
                CommonStyles.zIndexCrew1,
                types.isWeb ? CommonStyles.marginBottom20 : {},
              ]}
            >
              <CrewContactInformation
                renderEmail={
                  this._getParamValue('screenMode') === types.FORM_MODE_EDIT &&
                  this.state.selectedCrewForm.email.trim() !== ''
                    ? true
                    : this.state.selectedCrewForm.grantAccessToYourAccount ===
                      'yes'
                    ? false
                    : true
                }
                renderPhoneNumber={true}
                phoneNumber={this.state.selectedCrewForm.phoneNumber}
                email={this.state.selectedCrewForm.email}
                ndisRegistrationNumber={
                  this.state.selectedCrewForm.ndisRegistrationNumber
                }
                editEmail={this._editEmail}
                validEmail={this.state.validEmail}
                editPhoneNumber={this._editPhoneNumber}
                validPhoneNumber={this.state.validPhoneNumber}
                isEmailEditable={this.isContactEditable()}
                isPhoneNumberEditable={this.isPhoneNumberEditable()}
                viewWidth={this.props.viewWidth}
                isMobile={this.props.isMobile}
              />
            </View>
          </BasicForm>
        </View>

        {this._isDecisionMaker() && this.state.profileTypePerson ? (
          <BasicForm
            headerDisplayString={crewMessages.ACCESS_SECTION}
            headerIconImage={padlockCharcoal}
            secondLevel={true}
            disableMore={true}
            readOnly={this.props.member.access.profile}
            close={() => this._closeCrewForm()}
            showButton={false}
            save={() => this._saveCrewForm()}
            buttonsPositionBottom={
              this._isDecisionMaker() && this.state.profileTypePerson
            }
            contentWidthFull={true}
          >
            <View style={[CommonStyles.flex1]}>
              {this._renderAccessSection()}
            </View>
          </BasicForm>
        ) : null}
        {this._renderWhyInvitePopUp(isTablet())}
        {this._renderCrewDeleteConfirmation()}
      </View>
    );
  };

  _isDecisionMaker = () => {
    if (Object.getOwnPropertyNames(this.props.member).length === 0) {
      return false;
    } else {
      if (!this.props.member.access) {
        return false;
      } else {
        if (this.props.member.access.decisionMaker) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  _getAvatarImages = () => {
    let callbackFunction = (data: any) => {
      var avatarList = data[0].content;
      this.props.actions.AvatarListActions.actionGetAvatarList(avatarList);

      if (types.isWeb) {
        removeItem(types2.WEB_STORE.AVATAR_LISTOBJ);
        setValue(
          types2.WEB_STORE.AVATAR_LISTOBJ,
          JSON.stringify(
            this.props.actions.AvatarListActions.actionGetAvatarList(avatarList)
              .avatarlistObj,
          ),
        );
      }

      this.setState({
        loading: false,
      });
    };

    callAPIs(
      [getAvatarImages(this.props.loadedMemberId.loadedMemberId)],
      callbackFunction,
      null,
      () => {
        this.setState({loading: false});
      },
    );
  };

  _viewSelectProfilePicture = () => {
    const navigationParams = this.props.actions.NavigationParamActions.setParam(
      {
        ...this.props.navigationParams.params,
        imageObject: this.state.selectedModalImage,
        visible: this.state.profilePictureModalVisible,
        uploadCallBack: this._setCrewImageCallBack,
        callBack: true,
        headerTitle: types2.ADD_CREW_PIC_TITLE,
      },
    );

    if (types.isWeb) {
      setValue(types2.WEB_STORE.CREW_STATE, JSON.stringify(this.state));
    }
    if (types.isWeb) {
      removeItem(types2.WEB_STORE.NAVIGATION_PARAMS);
      setValue(
        types2.WEB_STORE.NAVIGATION_PARAMS,
        JSON.stringify(navigationParams),
      );
    }
    this.props.navigation.navigate('ProfilePictureForm');
    this._getAvatarImages();
  };

  _removeSelectedOrganisation = (index: any) => {
    let screenMode = this._getParamValue('screenMode');
    if (this.state.profileTypePerson || screenMode != types.FORM_MODE_EDIT) {
      let tempSelectedOrganisations = JSON.parse(
        JSON.stringify(this.state.orgSelected),
      );

      // Remove the index from the temp selected Organisations
      tempSelectedOrganisations.splice(index, 1);

      // Update the state
      if (this.isContactEditable()) {
        this.setState(prevState => ({
          orgSelected: tempSelectedOrganisations,
          orgAdded: false,
          selectedSuportItem: {},
          newOrgAdd: false,
          selectedCrewForm: {
            ...prevState.selectedCrewForm,
            category: 'family',
            phoneNumber: '',
            email: '',
            ndisRegistrationNumber: '',
            selectedSuportName: '',
            orgName: '',
          },
        }));
      } else {
        this.setState(prevState => ({
          orgSelected: tempSelectedOrganisations,
          orgAdded: false,
          selectedSuportItem: {},
          newOrgAdd: false,
          selectedCrewForm: {
            ...prevState.selectedCrewForm,
            selectedSuportName: '',
            orgName: '',
          },
        }));
      }
    }
  };

  _renderSelectedOrganisations = () => {
    // Go through the list of selected Organisations to render on screen
    const {orgSelected, editOrg} = this.state;

    let orgSelectedJSX: any = [];

    let indexCounter = 0;
    orgSelected.map(organisation => {
      orgSelectedJSX.push(
        <SelectedItem
          showRemoveButton={true}
          key={indexCounter}
          index={indexCounter}
          organisation={organisation}
          removeOrg={this._removeSelectedOrganisation}
        />,
      );
      indexCounter++;
    });

    if (indexCounter > 0) {
      return (
        <View
          style={[
            CommonStyles.selectedDisabilityPadding,
            types.isWeb ? CommonStyles.flex1 : CommonStyles.flexRow,
            CommonStyles.flexWrap,
          ]}
        >
          {orgSelected.length > 0 && orgSelectedJSX}
        </View>
      );
    } else {
      return null;
    }
  };

  _addNewOrg() {
    if (this.state.addNewOne) {
      this.setState({addNewOne: false});
    } else {
      this.setState({addNewOne: true});
    }
  }

  _AddOrg() {
    if (this.state.organisation != '') {
      let selectedOrganisation = this.state.orgSelected;
      selectedOrganisation.push(this.state.organisation);
      this.setState({
        orgSelected: selectedOrganisation,
        organisation: '',
        orgAdded: true,
        addNewOne: false,
        newOrgAdd: true,
        searchedOrg: [],
        showAddCustomOrg: false,
        searchText: '',
        filteredSuport: [],
        scrollEnabled: true,
      });

      this.setState(prevState => ({
        selectedCrewForm: {
          ...prevState.selectedCrewForm,
          orgName: selectedOrganisation,
        },
      }));
    }
  }

  _setFilterdSupport(data: any) {
    this.setState({isLoadingSearch: false});
    if (data[0].content.length > 0) {
      this.setState({scrollEnabled: false});
      var filteredSuport = data[0].content.filter(function (
        item: any,
        index: any,
        array: any,
      ) {
        return item.SnowReference;
      });
      if (this.state.searchText != '' && this.state.searchText != undefined) {
        this.setState({
          filteredSuport: filteredSuport,
          showAddCustomOrg: false,
        });
      }
    } else {
      this.setState({filteredSuport: [], showAddCustomOrg: true});
    }
  }

  _fetchSearchData() {
    callAPIs(
      [getExistingOrganisations(this.state.searchText, false)],
      (data: any) => this._setFilterdSupport(data),
      (err: any) => logger.log('notifications error: ', err),
    );
    this.setState({isLoadingSearch: true});
  }
  debouncedSearchFilteredSupportFunc = debounce(
    () => this._fetchSearchData(),
    1000,
  );

  debouncedSearchFilteredSupport(query: any) {
    this.setState({
      searchText: query,
      selectedSuportName: query,
      organisation: query,
      billerCode: '',
    });

    this.debouncedSearchFilteredSupportFunc();
  }

  _renderSearchItem = (item: any) => {
    return (
      <StandardTouchableOpacity
        accessibilityLabel={item.type + ' selected'}
        key={item.RegisteredProviderName}
        style={[CommonStyles.listItem]}
        onPress={() => {
          this._setSuportItem(item);
        }}
      >
        <StandardText style={CommonStyles.StandardFontSize}>
          <StandardText style={[CommonStyles.customFont]}>
            {item.RegisteredProviderName}
          </StandardText>
          {/* <StandardText style={[CommonStyles.customFontLight]}>{'  (' +item.SnowReference + ')'}</StandardText> */}
        </StandardText>
      </StandardTouchableOpacity>
    );
  };

  _setSuportItem = (item: any) => {
    let selectedOrganisation = this.state.orgSelected;
    selectedOrganisation.push(item.RegisteredProviderName);
    this.setState(prevState => ({
      filteredSuport: [],
      searchText: item.RegisteredProviderName,
      billerCode: item.SnowReference,
      scrollEnabled: true,
      supportItems: {
        ...prevState.supportItems,
        billerKey: item.key,
        billerCode: item.SnowReference,
        selectedSuportName: item.RegisteredProviderName,
        selectedSuportItem: item,
      },
      orgSelected: selectedOrganisation,
      organisation: '',
      orgAdded: true,
      addNewOne: false,
      newOrgAdd: false,
      searchedOrg: item,
      searchText: '',
      selectedSuportName: '',
      billerCode: '',
      filteredSuport: {},
    }));

    if (this.isContactEditable()) {
      this.setState(prevState => ({
        selectedCrewForm: {
          ...prevState.selectedCrewForm,
          phoneNumber: item.PhoneNumber.replace(/\s/g, ''),
          email: item.EmailAddress,
          ndisRegistrationNumber: item.NDISRegistrationNumber,
          orgName: selectedOrganisation,
        },
      }));
    } else {
      this.setState(prevState => ({
        selectedCrewForm: {
          ...prevState.selectedCrewForm,
          orgName: selectedOrganisation,
        },
      }));
    }
    this.focusNextField(this.inputs, INPUTS.SUPPORT);
  };

  _renderSearch() {
    if (!this.state.orgAdded) {
      return (
        <View style={[CommonStyles.flex1]}>
          <View style={[CommonStyles.content, CommonStyles.zIndex1]}>
            <View
              style={[
                CommonStyles.inputContainerFullWidth,
                CommonStyles.paddingBottom10,
                CommonStyles.zIndex1,
              ]}
            >
              <AutoComplete
                onRef={(ref: any) => {
                  this.inputs[INPUTS.ORG_NAME] = ref;
                }}
                onSubmitEditing={() => {
                  if (this.state.searchText.length > 0) {
                    this._AddOrg();
                  }
                  this.focusNextField(this.inputs, INPUTS.SUPPORT);
                }}
                returnKeyType={'done'}
                readOnly={this.props.member.access.profile}
                data={this.state.filteredSuport}
                listStyle={[CommonStyles.content, CommonStyles.listContainer]}
                onChangeText={(text: any) =>{
                  const sanitizedText = DOMPurify.sanitize(text);

                  this.debouncedSearchFilteredSupport(sanitizedText)
                }}
                rowStyle={true}
                renderItem={(item: any) => {
                  return this._renderSearchItem(item);
                }}
                style={[
                  CommonStyles.ModalTextInputWrapper,
                  CommonStyles.StandardFontSize,
                  CommonStyles.paddingLeft20,
                ]}
                value={this.state.searchText}
                underlineColorAndroid="transparent"
                isLoadingSearch={this.state.isLoadingSearch}
                customButton={this.state.showAddCustomOrg}
                addCustom={this._AddOrg}
                onClear={() => {
                  // Clear the search text
                  this.setState({
                    searchText: '',
                    selectedSuportName: '',
                    billerCode: '',
                    selectedSuportItem: {},
                    filteredSuport: {},
                    scrollEnabled: true,
                  });
                }}
                onFocus={() => {
                  // Since the focus is on the auto complete, any drag should dismiss the keyboard
                  this.setState({keyboardDismissMode: 'on-drag'});
                }}
                onBlur={() => {
                  // No longer focused on the auto complete, remove the dismiss mode
                  this.setState({keyboardDismissMode: 'none'});
                }}
                errorMessage={this.state.valideOrganisation}
              />
            </View>
          </View>
        </View>
      );
    } else {
      return null;
    }
  }

  _determineGridMaxColumns = () => {
    let maxColumns = types2.TWO_COLUMN;

    if (types.isWeb && this.props.isMobile) {
      return types2.ONE_COLUMN;
    }

    if (!types.isWeb && !isTablet()) {
      return types2.ONE_COLUMN;
    }
    return maxColumns;
  };

  renderCrewOrganizationProfile() {
    let showInLeftSide = this.state.viewWidth < types2.MEDIA_QUERY.MEDIUM;
    return (
      <View style={CommonStyles.zIndex1}>
        <View style={CommonStyles.zIndex100}>
          <GridView
            viewWidth={this.props.viewWidth}
            isMobile={this.props.isMobile}
            maximumColumns={this._determineGridMaxColumns()}
            isStatic={true}
          >
            {[
              this.renderCrewPicture(showInLeftSide),
              this._renderCategory(
                this.props.crews.organisationCrew,
                this.selectOption(this.state.selectedCrewForm.category).type,
              ),
              this.renderOrganizationPersonal(),
            ]}
          </GridView>
        </View>
        {this.renderExtraInformation()}
      </View>
    );
  }

  renderOrganizationPersonal = () => [
    <View style={[CommonStyles.zIndex102]}>
      <View style={[CommonStyles.leftAboutMeModalContentContainer]}>
        <FormTitle
          actionButtonLeft={types.isWeb}
          starLeft={types.isWeb}
          containsData={
            this.state.profileTypePerson
              ? this.state.selectedCrewForm.orgName
              : this.state.filteredSuport
          }
          text={
            this.state.profileTypePerson
              ? crewMessages.PROFILE.ORG_NAME_IF_APPLICABLE
              : crewMessages.PROFILE.ORG_NAME
          }
          style={[CommonStyles.customFontBold, CommonStyles.AboutMeModalLabel]}
        />
        {this.state.profileTypePerson ? null : <RequiredIndicator />}
      </View>

      <View style={[this._setSearchHeight()]}>{this._renderSearch()}</View>
      <View style={CommonStyles.paddingRight5}>
        {this._renderSelectedOrganisations()}
      </View>
    </View>,
  ];

  _setSearchHeight = () => {
    if (this.state.filteredSuport.length > 0) {
      return CommonStyles.height250;
    } else {
      return null;
    }
  };

  renderExtraInformation = () => {
    return (
      <View>
        <View style={[CommonStyles.flex1]}>
          <FormTitle
            containsData={this.state.selectedCrewForm.support}
            text={crewMessages.PROFILE.NOTES}
            style={[
              CommonStyles.customFontBold,
              CommonStyles.AboutMeModalLabel,
            ]}
          />
        </View>
        <View style={[CommonStyles.rightAboutMeModalContentContainer]}>
          <StandardInput
            readOnly={this.props.member.access.profile}
            value={this.state.selectedCrewForm.support}
            onRef={(ref: any) => {
              this.inputs[INPUTS.SUPPORT] = ref;
            }}
            multiline={true}
            accessibilityLabel="Start typing"
            numberOfLines={4}
            onChangeText={(support: any) =>
              {
                const sanitizedSupport = DOMPurify.sanitize(support);

                this.setState({
                selectedCrewForm: {
                  ...this.state.selectedCrewForm,
                  support: sanitizedSupport,
                },
              })}
            }
            blurOnSubmit={false}
            returnKeyType={'next'}
            style={[
              CommonStyles.ModalTextInputWrapperAutoHeight,
              BrandStyles.primaryBgColor1,
              CommonStyles.customFont,
              CommonStyles.textAlignVerticalTop,
              CommonStyles.textInputMultuline,
            ]}
            underlineColorAndroid="transparent"
          />
        </View>
      </View>
    );
  };

  renderCrewPicture = (showInLeftSide: any) => {
    if (this.state.selectedModalImage == null) {
      return (
        <View
          style={[
            CommonStyles.zIndex1,
            CommonStyles.flexDirectionRow,
            CommonStyles.paddingTop15,
            CommonStyles.alignFlexStart,
            types.isWeb ? CommonStyles.marginBottom10 : {},
          ]}
        >
          <Image
            style={[
              !showInLeftSide
                ? CommonStyles.phototileCrewContainer1
                : CommonStyles.phototileCrewContainer2,
            ]}
            source={this.state.selectedImageUrl}
          />
          <View
            style={[CommonStyles.flexDirectionColumn, CommonStyles.alignCenter]}
          >
            {this.props.member.access.profile != types.ACCESS_READ_ONLY ? (
              <StandardTouchableOpacity
                style={[
                  CommonStyles.addSupportContainer,
                  CommonStyles.marginTop5,
                ]}
                accessibilityLabel="Add picture"
                onPress={this._viewSelectProfilePicture.bind(this)}
              >
                <ActionButton
                  actionType={types.ACTION_BUTTON.ADD}
                  image={addIcon}
                />
              </StandardTouchableOpacity>
            ) : null}
          </View>
        </View>
      );
    } else {
      return (
        <View
          style={[
            CommonStyles.zIndex100,
            CommonStyles.flexDirectionRow,
            CommonStyles.paddingTop15,
            types.isWeb
              ? CommonStyles.marginBottom10
              : showInLeftSide
              ? CommonStyles.alignFlexStart
              : CommonStyles.alignFlexEnd,
          ]}
        >
          <Image
            style={[CommonStyles.phototileCrewContainer2]}
            source={this.state.selectedImageUrl}
          />
          <View
            style={[
              CommonStyles.flexDirectionColumn,
              CommonStyles.alignFlexStart,
            ]}
          >
            {this.props.member.access.profile != types.ACCESS_READ_ONLY ? (
              <StandardTouchableOpacity
                style={[
                  CommonStyles.addSupportContainer,
                  CommonStyles.marginTop5,
                ]}
                accessibilityLabel="edit picture"
                onPress={this._viewSelectProfilePicture.bind(this)}
              >
                <ActionButton
                  actionType={types.ACTION_BUTTON.EDIT}
                  image={addIcon}
                />
              </StandardTouchableOpacity>
            ) : null}
          </View>
        </View>
      );
    }
  };
  _renderCategory = (options: any, selectedValue: any) => {
    return (
      <View
        style={[
          CommonStyles.zIndex100,
          types.isWeb ? CommonStyles.marginRight10 : {},
        ]}
      >
        <View style={[CommonStyles.leftAboutMeModalContentContainer]}>
          <FormTitle
            containsData={this.state.selectedCrewForm.category}
            text={crewMessages.PROFILE.CATEGORY}
            style={[
              CommonStyles.customFontBold,
              CommonStyles.AboutMeModalLabel,
              types.isWeb && !this.props.isMobile
                ? CommonStyles.marginBottom30
                : {},
            ]}
          />
        </View>
        <RequiredIndicator />
        <View
          style={[BrandStyles.secondaryBgColor3, CommonStyles.borderRadius10]}
        >
          <StandardPicker
            access={this.props.member.access.profile}
            pickerOptions={options}
            style={[
              CommonStyles.standardPicker,
              CommonStyles.standardPickerBackgroundColorGray,
            ]}
            selectedValue={selectedValue}
            onValueChange={(itemValue: any, itemIndex: any) =>
              this.setState(prevState => ({
                selectedCrewForm: {
                  ...prevState.selectedCrewForm,
                  category: itemValue,
                },
              }))
            }
          />
        </View>
      </View>
    );
  };

  editFirstName(firstName: any) {
    this.setState({
      selectedCrewForm: {
        ...this.state.selectedCrewForm,
        firstName,
      },
    });
  }
  editLastName(lastName: any) {
    this.setState({
      selectedCrewForm: {
        ...this.state.selectedCrewForm,
        lastName,
      },
    });
  }

  renderBasicInformation = (showInLeftSide: any) => [
    <CrewFirstName
      firstName={this.state.selectedCrewForm.firstName}
      readOnly={this.props.member.access.profile}
      validFirstName={this.state.validFirstName}
      focusNextField={this.focusNextField}
      editFirstName={this.editFirstName}
      inputs={this.inputs}
      currentInputRef={INPUTS.FIRST_NAME}
      nextInputRef={INPUTS.LAST_NAME}
    />,
    /* Last Name */
    <CrewLastName
      lastName={this.state.selectedCrewForm.lastName}
      readOnly={this.props.member.access.profile}
      validLastName={this.state.validLastName}
      focusNextField={this.focusNextField}
      editLastName={this.editLastName}
      inputs={this.inputs}
      currentInputRef={INPUTS.LAST_NAME}
      nextInputRef={INPUTS.RELATIONSHIP}
    />,
    /* Relationship to you */
    <View style={types.isWeb ? CommonStyles.marginRight10 : {}}>
      <FormTitle
        containsData={this.state.selectedCrewForm.relationship}
        text={crewMessages.PROFILE.RELATIONSHIP}
        style={[CommonStyles.customFontBold, CommonStyles.AboutMeModalLabel]}
      />
      <View style={[CommonStyles.rightAboutMeModalContentContainer]}>
        <StandardInput
          readOnly={this.props.member.access.profile}
          value={this.state.selectedCrewForm.relationship}
          onRef={(ref: any) => {
            this.inputs[INPUTS.RELATIONSHIP] = ref;
          }}
          onSubmitEditing={() => {
            if (!this.state.orgAdded) {
              this.focusNextField(this.inputs, INPUTS.ORG_NAME);
            } else {
              this.focusNextField(this.inputs, INPUTS.SUPPORT);
            }
          }}
          returnKeyType={'next'}
          accessibilityLabel="Relationship to you"
          onChangeText={(relationship: any) =>{
            const sanitizedRelationship = DOMPurify.sanitize(relationship);

            this.setState({
              selectedCrewForm: {
                ...this.state.selectedCrewForm,
                relationship: sanitizedRelationship,
              },
            })}
          }
          blurOnSubmit={false}
          style={[
            CommonStyles.ModalTextInputWrapper,
            BrandStyles.primaryBgColor1,
            CommonStyles.customFont,
          ]}
          underlineColorAndroid="transparent"
        />
      </View>
    </View>,

    /* Organisation Name */
    <View>{this.renderOrganizationPersonal()}</View>,
  ];

  renderPersonProfile() {
    let showInLeftSide = this.state.viewWidth < types2.MEDIA_QUERY.MEDIUM;
    return (
      <View>
        {this.state.viewWidth < types2.MEDIA_QUERY.MEDIUM ? (
          <GridView
            viewWidth={this.props.viewWidth}
            isMobile={this.props.isMobile}
            maximumColumns={this._determineGridMaxColumns()}
            isStatic={true}
          >
            {[
              this.renderCrewPicture(showInLeftSide),
              this._renderCategory(
                this.props.crews.crew,
                this.state.selectedCrewForm.category,
              ),
              ...this.renderBasicInformation(showInLeftSide),
            ]}
          </GridView>
        ) : (
          <GridView
            viewWidth={this.props.viewWidth}
            isMobile={this.props.isMobile}
            maximumColumns={this._determineGridMaxColumns()}
            isStatic={true}
          >
            {[
              this._renderCategory(
                this.props.crews.crew,
                this.state.selectedCrewForm.category,
              ),
              this.renderCrewPicture(showInLeftSide),
              ...this.renderBasicInformation(showInLeftSide),
            ]}
          </GridView>
        )}
        {this.renderExtraInformation()}
      </View>
    );
  }

  toggleProfileType = (status: any) => {
    this.setState(prevState => ({
      selectedCrewForm: {
        ...prevState.selectedCrewForm,
        phoneNumber: '',
        email: '',
        ndisRegistrationNumber: '',
      },
      validFirstName: '',
      validEmail: '',
      validPhoneNumber: '',
      valideOrganisation: '',
      validLastName: '',
    }));

    if (types.isWeb) {
      // Store selected crew type
      setValue(types2.WEB_STORE.CREW_TYPE, status);
    }

    this.setState({
      profileTypePerson: status,
      orgSelected: [],
      orgAdded: false,
      selectedSuportItem: {},
      newOrgAdd: false,
      searchText: '',
    });

    this.setState(prevState => ({
      selectedCrewForm: {
        ...prevState.selectedCrewForm,
        category: 'family',
      },
    }));
  };

  _renderAddOrgPersonTitle() {
    let screenMode = this._getParamValue('screenMode');
    if (screenMode == types.FORM_MODE_NEW) {
      return (
        <View style={[CommonStyles.accessAdditionalInfo]}>
          <StandardText
            style={[
              CommonStyles.customFont,
              types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
              BrandStyles.TextColor5,
              CommonStyles.paddingTop10,
              CommonStyles.paddingRight10,
            ]}
          >
            {crewMessages.CREW_INFO}
          </StandardText>
        </View>
      );
    } else {
      return null;
    }
  }

  selectOption(value: any) {
    return this.props.crews.organisationCrew.find((option: any) => {
      return option.type === value;
    });
  }

  _renderToggleButtonView() {
    return (
      <View
        style={[
          CommonStyles.flexDirectionRow,
          CommonStyles.alignCenter,
          CommonStyles.paddingBottom10,
        ]}
      >
        <Image
          style={CommonStyles.ToggleButtonImageView}
          source={InformationIconx3}
          accessible={true}
          accessibilityLabel={MESSAGES.ACCESSIBILITY.IMPORTANT_INFORMATION}
        />
        <GridView
          maximumColumns={1}
          viewWidth={this.props.viewWidth - CUT_OFF_PADDING}
          isMobile={this.props.isMobile}
          isStatic={true}
          style={[
            CommonStyles.paddingLeft10,
            types.isWeb
              ? [CommonStyles.flex1, CommonStyles.alignItemsCenter]
              : {},
          ]}
        >
          {this._renderAddOrgPersonTitle()}
          <CrewOrganisationRadio
            profileTypePerson={this.state.profileTypePerson}
            onSelectSubOption={this.onSelectSubOption}
          />
        </GridView>
      </View>
    );
  }

  onSelectSubOption(index: any, value: any) {
    this.toggleProfileType(index == 0 ? true : false);
  }

  _editPhoneNumber(phoneNumber: any) {
    this.setState({
      selectedCrewForm: {
        ...this.state.selectedCrewForm,
        phoneNumber: phoneNumber.replace(/[^0-9]/g, ''),
      },
    });
  }

  isContactEditable() {
    let crewIsActive =
      this.state.selectedCrewForm.isActiveUser === 1 ? true : false;
    return (
      this.props.member.access.profile !== types.ACCESS_READ_ONLY &&
      !crewIsActive
    );
  }

  isPhoneNumberEditable() {
    return this.props.member.access.profile !== types.ACCESS_READ_ONLY;
  }

  _editEmail = (email: any) => {
    let emailTrimmed = email.trim();
    this.setState({
      selectedCrewForm: {
        ...this.state.selectedCrewForm,
        email: emailTrimmed,
      },
    });
  };

  _renderAccessSection = () => {
    return (
      <View style={[CommonStyles.marginBottom10]}>
        <View style={[CommonStyles.sectionContainer]}>
          {/* Give this person access to my account */}
          <View>
            <View
              style={[
                types.isWeb
                  ? CommonStyles.notificationContainer
                  : CommonStyles.accessAdditionalInfo,
              ]}
            >
              {this._renderWarning(
                crewMessages.ACCESS.GIVE_ACCESS,
                crewMessages.ACCESS_INFO,
              )}
            </View>
            <View
              style={[CommonStyles.maxWidth100, CommonStyles.paddingBottom20]}
            >
              <View
                style={[
                  BrandStyles.secondaryBgColor3,
                  CommonStyles.borderRadius10,
                ]}
              >
                <StandardPicker
                  access={this.props.member.access.profile}
                  pickerOptions={types2.PICKER.YES_OR_NO}
                  style={[
                    CommonStyles.standardPicker,
                    CommonStyles.standardPickerBackgroundColorGray,
                  ]}
                  selectedValue={
                    this.state.selectedCrewForm.grantAccessToYourAccount
                  }
                  onValueChange={(itemValue: any, itemIndex: any) => {
                    let profileAccess = 'none';
                    if (itemValue == 'yes') {
                      profileAccess = types.ACCESS_READ_ONLY;
                    }
                    this.setState({
                      selectedCrewForm: {
                        ...this.state.selectedCrewForm,
                        grantAccessToYourAccount: itemValue,
                        access: {...this.state.access, profile: profileAccess},
                      },
                    });
                  }}
                />
              </View>
            </View>
          </View>
        </View>
        {/* Only show if the following if you have given yes is set for give person access to my account*/}
        {this.renderProfilePermissions()}
      </View>
    );
  };

  _renderWarning(title: any, warningMessage: any) {
    return (
      <View style={[CommonStyles.flexDirectionRow, CommonStyles.alignCenter]}>
        <Image
          style={CommonStyles.ToggleButtonImageView}
          source={InformationIconx3}
          accessible={true}
          accessibilityLabel={MESSAGES.ACCESSIBILITY.IMPORTANT_INFORMATION}
        />
        <View
          style={[
            CommonStyles.flexDirectionColumn,
            CommonStyles.crewaAccessWarning,
            CommonStyles.paddingLeft10,
            CommonStyles.marginRight10,
            CommonStyles.marginBottom10,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFontSemiBold,
              CommonStyles.ModalLabel,
              BrandStyles.TextColor5,
              CommonStyles.marginRight10,
              types.isWeb ? CommonStyles.font15 : CommonStyles.font20,
            ]}
          >
            {title}
          </StandardText>
          <View
            style={[CommonStyles.paddingTop10, CommonStyles.paddingBottom5]}
          >
            <StandardText
              style={[
                CommonStyles.customFont,
                types.isWeb ? CommonStyles.font15 : CommonStyles.font20,
                BrandStyles.TextColor5,
                CommonStyles.marginRight10,
                ,
                CommonStyles.marginBottom10,
              ]}
            >
              {warningMessage}
            </StandardText>
          </View>
          <View style={[CommonStyles.paddingBottom5]}>
            <StandardTouchableOpacity
              accessibilityLabel={crewMessages.ACCESS_INFO_LINK}
              style={[
                CommonStyles.documentName,
                CommonStyles.documentTileMarginTouchableRight,
              ]}
              onPress={() => this._showWhyInviteModal()}
            >
              <StandardText
                style={[
                  CommonStyles.customFontBold,
                  types.isWeb ? CommonStyles.font15 : CommonStyles.font20,
                  CommonStyles.underlineText,
                  BrandStyles.TextColor4,
                  CommonStyles.marginRight10,
                  CommonStyles.marginBottom10,
                ]}
              >
                {crewMessages.ACCESS_INFO_LINK}
              </StandardText>
            </StandardTouchableOpacity>
          </View>
        </View>
      </View>
    );
  }

  _determinePopUpStyle = (isTablet: any) => {
    const styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      styles.push([CommonStyles.containerInstructionPopUpWeb]);

      if (this.props.isMobile) {
        styles.push([
          CommonStyles.containerPopupWebMobile,
          CommonStyles.popUpWebMobileTop,
        ]);
      }
    } else if (isTablet) {
      styles.push(CommonStyles.containerWhyInvitePopUpTablet);
    } else {
      styles.push(CommonStyles.containerWhyInvitePopUpMobile);
    }
    return styles;
  };

  _showWhyInviteModal = () => {
    this.setState({whyInvitePopupVisible: true});
  };

  _closeWhyInviteModal = () => {
    this.setState({whyInvitePopupVisible: false});
  };

  _renderWhyInvitePopUp = (isTablet: any) => {
    let fontSize = CommonStyles.rpfont15;
    let headerFontSize = CommonStyles.font20;
    if (types.isWeb) {
      fontSize = CommonStyles.font15;
      headerFontSize = CommonStyles.font18;
    } else if (isTablet) {
      fontSize = CommonStyles.font18;
      headerFontSize = CommonStyles.font25;
    }

    return (
      <BasicOverlayPopupModal
        visible={this.state.whyInvitePopupVisible}
        style={this._determinePopUpStyle(isTablet)}
        innerStyle={CommonStyles.paddingBottom10}
        backdropOpacity={0.2}
        divider={true}
        close={this._closeWhyInviteModal}
        cancelContainerStyle={CommonStyles.popUpOkButton}
        cancelStyle={[CommonStyles.buttonPopupOk, CommonStyles.customFontBold]}
        cancelLabel={types2.OK_GOT_IT}
      >
        <View>
          <View
            style={[
              CommonStyles.flexDirectionRow,
              CommonStyles.scrollView_subContentCenter,
              CommonStyles.paddingBottom20,
              isTablet ? {} : CommonStyles.marginTop10,
            ]}
          >
            <View>
              <Image
                style={[CommonStyles.changePasswordButtonImage]}
                source={InformationIconx3}
              />
            </View>
            <View style={[CommonStyles.scrollView_subContentCenter]}>
              <StandardText
                style={[
                  headerFontSize,
                  BrandStyles.TextColor5,
                  CommonStyles.customFont,
                ]}
              >
                {crewMessages.WHY_INVITE_CREW_TITLE}
              </StandardText>
            </View>
          </View>
          <StandardText
            style={[
              fontSize,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
              CommonStyles.paddingBottom20,
            ]}
          >
            {crewMessages.WHY_INVITE_MESSAGE1}
          </StandardText>
          <StandardText
            style={[fontSize, BrandStyles.TextColor5, CommonStyles.customFont]}
          >
            {crewMessages.WHY_INVITE_MESSAGE2}
          </StandardText>
        </View>
      </BasicOverlayPopupModal>
    );
  };

  _renderCrewDeleteConfirmation = () => {
    return (
      <BasicPopupAlert
        visible={this.state.crewDeletionConfirmationVisible}
        close={() =>
          this.setState({
            loading: false,
            crewDeletionConfirmationVisible: false,
          })
        }
        style={this._getCrewDeleteConfirmationModalStyle(isTablet)}
        cancelContainerStyle={[
          BrandStyles.primaryBgColor1,
          BrandStyles.brandBorderColor1,
          CommonStyles.buttonContainerFormAction,
        ]}
        cancelStyle={[
          BrandStyles.brandBlockTxtColor6,
          CommonStyles.buttonFormAction,
          CommonStyles.customFontBold,
        ]}
        cancelLabel={crewMessages.DELETE_CONFIRMATION.CANCEL_TEXT}
        headerText={crewMessages.DELETE_CONFIRMATION.HEADER}
        messageText={this._getCrewDeleteConfirmationDescription()}
        saveAction={() => this._deleteCrewForm()}
        saveLabel={crewMessages.DELETE_CONFIRMATION.CONFIRM_TEXT}
      />
    );
  };

  _getCrewDeleteConfirmationModalStyle = (isTablet: any) => {
    const styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      styles.push([CommonStyles.containerInstructionPopUpWeb]);

      if (this.props.isMobile) {
        styles.push([CommonStyles.containerPopupWebMobile]);
      }
    } else if (isTablet()) {
      styles.push([
        CommonStyles.containerInstructionPopUpTablet,
        CommonStyles.minHeight300,
      ]);
    } else {
      styles.push([
        CommonStyles.containerInstructionPopUpMobile,
        CommonStyles.minHeight230,
      ]);
    }
    return styles;
  };

  _getCrewDeleteConfirmationDescription = () => {
    // If the crew is trying to delete their own profile from crew list
    // Show a more descriptive confirmation description
    if (this.props.user.id === this.state.selectedCrewForm.crewId) {
      return crewMessages.DELETE_CONFIRMATION.SELF_DELETE_DESCRIPTION(
        this.props.member.firstName,
        this.props.member.lastName,
      );
    }
    return crewMessages.DELETE_CONFIRMATION.GENERIC_DELETE_DESCRIPTION;
  };

  _setCrewImageCallBack = (requestObject: any) => {
    requestObject.localUri = requestObject.uri;
    requestObject.localImage = true;
    this.setState({selectedModalImage: requestObject});
    //Todo refactor to a function
    if (types.isWeb && requestObject) {
      if (requestObject.uri) {
        this.setState({selectedImageUrl: {uri: requestObject.uri}});
      } else if (requestObject.localUri) {
        this.setState({
          selectedImageUrl: {uri: URL.createObjectURL(requestObject)},
        });
      } else {
        this.setState({
          selectedImageUrl: CameraCircle,
        });
      }
    } else {
      if (!requestObject) {
        this.setState({
          selectedImageUrl: !isTablet ? CameraSquare : CameraCircle,
        });
      } else if (requestObject.uri) {
        this.setState({selectedImageUrl: {uri: requestObject.uri}});
      } else {
        this.setState({selectedImageUrl: {uri: requestObject.url}});
      }
    }
  };

  _renderCrewContactInformation = () => {
    return (
      <View style={[CommonStyles.flex1, CommonStyles.zIndexCrew1]}>
        <CrewContactInformation
          renderEmail={true}
          renderPhoneNumber={false}
          phoneNumber={this.state.selectedCrewForm.phoneNumber}
          email={this.state.selectedCrewForm.email}
          ndisRegistrationNumber={
            this.state.selectedCrewForm.ndisRegistrationNumber
          }
          editEmail={this._editEmail}
          validEmail={this.state.validEmail}
          editPhoneNumber={this._editPhoneNumber}
          validPhoneNumber={this.state.validPhoneNumber}
          isEmailEditable={this.isContactEditable()}
          isPhoneNumberEditable={this.isPhoneNumberEditable()}
          viewWidth={this.props.viewWidth}
          isMobile={this.props.isMobile}
        />
      </View>
    );
  };

  renderProfilePermissions() {
    if (this.state.selectedCrewForm.grantAccessToYourAccount === 'yes') {
      return (
        <View>
          <View style={[CommonStyles.notificationContainer]}>
            {this._getParamValue('screenMode') === types.FORM_MODE_EDIT &&
            this._getParamValue('selectedCrew').email.trim() !== ''
              ? null
              : this.state.selectedCrewForm.grantAccessToYourAccount === 'yes'
              ? this._renderCrewContactInformation()
              : null}
            {this._renderWarning(
              crewMessages.ACCESS.DECISION_TITLE,
              crewMessages.ACCESS.DECISION_DESCRIPTION,
            )}
            <GridView
              viewWidth={this.props.viewWidth}
              isMobile={this.props.isMobile}
              itemDimension={types2.ITEM_DIMENSION}
            >
              {/* Allow person to make decisions on my behalf */}
              <View style={[CommonStyles.paddingRight10]}>
                <View>
                  <StandardText
                    style={[
                      CommonStyles.customFontMedium,
                      CommonStyles.ModalLabel,
                    ]}
                  >
                    {'\xa0'}
                  </StandardText>
                </View>
                <View
                  style={[
                    CommonStyles.maxWidth100,
                    BrandStyles.secondaryBgColor3,
                    CommonStyles.borderRadius10,
                  ]}
                >
                  <StandardPicker
                    access={this.props.member.access.profile}
                    pickerOptions={types2.PICKER.YES_OR_NO}
                    style={[
                      CommonStyles.standardPicker,
                      CommonStyles.standardPickerBackgroundColorGray,
                    ]}
                    selectedValue={
                      this.state.selectedCrewForm.access.decisionMaker
                        ? types2.PICKER.YES
                        : types2.PICKER.NO
                    }
                    onValueChange={(itemValue: any, itemIndex: any) => {
                      this.setAccessDefaultOnDecisionMaking(itemValue);
                    }}
                  />
                </View>
              </View>
              {this.renderNotificationPicker()}
            </GridView>
          </View>
          <View style={[CommonStyles.selectTextContainer]}>
            <StandardText
              style={[CommonStyles.customFontMedium, BrandStyles.TextColor6]}
            >
              {crewMessages.ACCESS.ACCESS_DESCRIPTION}
            </StandardText>
          </View>
          {/* Access profile */}
          <GridView
            viewWidth={this.props.viewWidth}
            isMobile={this.props.isMobile}
            itemDimension={types2.ITEM_DIMENSION}
          >
            {this.renderProfileAccess()}
            {/* Access Documents */}
            {this.renderDocumentsPicker()}
            {/* Access Finances */}
            {this.renderFinancePicker()}
          </GridView>
        </View>
      );
    } else {
      return null;
    }
  }

  setAccessDefaultOnDecisionMaking = (itemValue: any) => {
    let notifications = types.ACCESS_NONE;
    let profile = types.ACCESS_READ_ONLY;
    let finances = types.ACCESS_NONE;
    let documents = types.ACCESS_NONE;
    let notificationType = types.NOTIFICATION_IN_APP;

    if (itemValue === 'yes') {
      profile = types.ACCESS_FULL;
      notificationType = types.NOTIFICATION_IN_APP;
      finances = types.ACCESS_FULL;
      documents = types.ACCESS_FULL;
      notifications = types.ACCESS_READ_ONLY;
    }

    this.setState({
      selectedCrewForm: {
        ...this.state.selectedCrewForm,
        access: {
          ...this.state.selectedCrewForm.access,
          decisionMaker: itemValue == 'yes',
          profile,
          notifications,
          finances,
          documents,
        },
        notificationType,
      },
    });
  };

  renderNotificationPicker = () => {
    if (this.state.selectedCrewForm.access.decisionMaker) {
      var options = [
        {description: 'View in app', type: types.NOTIFICATION_IN_APP},
        {
          description: 'View in app and receive emails',
          type: types.NOTIFICATION_IN_APP_EMAIL,
        },
      ];
      return (
        <View>
          <StandardText
            style={[
              CommonStyles.customFontMedium,
              CommonStyles.ModalLabel,
              BrandStyles.TextColor5,
            ]}
          >
            {crewMessages.ACCESS.NOTIFICATIONS}
          </StandardText>
          <View
            style={[BrandStyles.secondaryBgColor3, CommonStyles.borderRadius10]}
          >
            <View style={[CommonStyles.PickerBackground]}>
              <StandardPicker
                access={this.props.member.access.profile}
                style={[
                  CommonStyles.standardPicker,
                  CommonStyles.standardPickerBackgroundColorGray,
                ]}
                pickerOptions={options}
                selectedValue={this.state.selectedCrewForm.notificationType}
                onValueChange={(itemValue: any, itemIndex: any) =>
                  this.setState({
                    selectedCrewForm: {
                      ...this.state.selectedCrewForm,
                      access: {
                        ...this.state.selectedCrewForm.access,
                        notifications:
                          itemValue == types.NOTIFICATION_IN_APP
                            ? types.ACCESS_READ_ONLY
                            : types.ACCESS_FULL,
                      },
                      notificationType: itemValue,
                    },
                  })
                }
              />
            </View>
          </View>
        </View>
      );
    }
  };

  renderFinancePicker = () => {
    var options = [
      {description: 'No access', type: types.ACCESS_NONE},
      {description: 'Read only', type: types.ACCESS_READ_ONLY},
      {description: 'Access to edit', type: types.ACCESS_FULL},
    ];
    return (
      <View style={types.isWeb ? CommonStyles.marginRight10 : {}}>
        <View style={[CommonStyles.leftAboutMeModalContentContainer]}>
          <StandardText
            style={[
              CommonStyles.customFontMedium,
              CommonStyles.ModalLabel,
              BrandStyles.TextColor5,
            ]}
          >
            {crewMessages.ACCESS.FINANCES}
          </StandardText>
        </View>
        <View style={[CommonStyles.rightAboutMeModalContentContainer]}>
          <View
            style={[BrandStyles.secondaryBgColor3, CommonStyles.borderRadius10]}
          >
            <StandardPicker
              access={this.props.member.access.profile}
              style={[
                CommonStyles.standardPicker,
                CommonStyles.standardPickerBackgroundColorGray,
              ]}
              pickerOptions={options}
              selectedValue={this.state.selectedCrewForm.access.finances}
              onValueChange={(itemValue: any, itemIndex: any) =>
                this.setState({
                  selectedCrewForm: {
                    ...this.state.selectedCrewForm,
                    access: {
                      ...this.state.selectedCrewForm.access,
                      finances: itemValue,
                    },
                  },
                  selectedAccess: {finances: true},
                })
              }
            />
          </View>
        </View>
      </View>
    );
  };

  renderDocumentsPicker = () => {
    var options = [
      {description: 'No access', type: types.ACCESS_NONE},
      {description: 'Read only', type: types.ACCESS_READ_ONLY},
      {description: 'Access to edit', type: types.ACCESS_FULL},
    ];
    return (
      <View style={types.isWeb ? CommonStyles.marginRight10 : {}}>
        <View style={[CommonStyles.leftAboutMeModalContentContainer]}>
          <StandardText
            style={[
              CommonStyles.customFontMedium,
              CommonStyles.ModalLabel,
              BrandStyles.TextColor5,
            ]}
          >
            {crewMessages.ACCESS.DOCUMENTS}
          </StandardText>
        </View>
        <View style={[CommonStyles.rightAboutMeModalContentContainer]}>
          <View
            style={[BrandStyles.secondaryBgColor3, CommonStyles.borderRadius10]}
          >
            <StandardPicker
              access={this.props.member.access.profile}
              style={[
                CommonStyles.standardPicker,
                CommonStyles.standardPickerBackgroundColorGray,
              ]}
              pickerOptions={options}
              selectedValue={this.state.selectedCrewForm.access.documents}
              onValueChange={(itemValue: any, itemIndex: any) =>
                this.setState({
                  selectedCrewForm: {
                    ...this.state.selectedCrewForm,
                    access: {
                      ...this.state.selectedCrewForm.access,
                      documents: itemValue,
                    },
                  },
                  selectedAccess: {documents: true},
                })
              }
            />
          </View>
        </View>
      </View>
    );
  };

  renderProfileAccess = () => {
    return (
      <View style={types.isWeb ? CommonStyles.marginRight10 : {}}>
        <View style={[CommonStyles.leftAboutMeModalContentContainer]}>
          <StandardText
            style={[
              CommonStyles.customFontMedium,
              CommonStyles.ModalLabel,
              BrandStyles.TextColor5,
            ]}
          >
            {crewMessages.ACCESS.PROFILE}
          </StandardText>
        </View>
        {this.renderProfileContent()}
      </View>
    );
  };

  renderProfileContent = () => {
    if (this.state.selectedCrewForm.access.decisionMaker) {
      return this.renderProfileText();
    } else {
      return this.renderProfilePicker();
    }
  };

  renderProfilePicker() {
    var options = [
      {description: 'Read only', type: types.ACCESS_READ_ONLY},
      {description: 'Access to edit', type: types.ACCESS_FULL},
    ];
    return (
      <View style={[CommonStyles.rightAboutMeModalContentContainer]}>
        <View
          style={[BrandStyles.secondaryBgColor3, CommonStyles.borderRadius10]}
        >
          <StandardPicker
            access={this.props.member.access.profile}
            pickerOptions={options}
            style={[
              CommonStyles.standardPicker,
              CommonStyles.standardPickerBackgroundColorGray,
            ]}
            selectedValue={this.state.selectedCrewForm.access.profile}
            onValueChange={(itemValue: any, itemIndex: any) =>
              this.setState({
                selectedCrewForm: {
                  ...this.state.selectedCrewForm,
                  access: {
                    ...this.state.selectedCrewForm.access,
                    profile: itemValue,
                  },
                },
              })
            }
          />
        </View>
      </View>
    );
  }

  renderProfileText = () => (
    <View
      style={[
        CommonStyles.content,
        BrandStyles.modalSectionBgColor1,
        types.isWeb
          ? CommonStyles.rightAboutMeModalContentContainer
          : CommonStyles.readOnlyProfileAccess,
      ]}
    >
      <StandardText
        style={[
          CommonStyles.customFontMedium,
          types.isWeb
            ? [CommonStyles.font17, CommonStyles.paddingTop5]
            : CommonStyles.CrewFormFS20,
          BrandStyles.TextColor5,
        ]}
      >
        Access to edit
      </StandardText>
    </View>
  );

  _saveCrewForm = () => {
    console.log("logging data ooooooo")
    this.setState({saveApiError: false});
    const validateFields = this.validateFields();
    if (!validateFields) {
      return;
    }
    this._setLoading(true);

    // Setup the save array, add the crew object first
    let saveArray = [this._saveModalData()];

    // If its an edit & there is a new image
    // We can bundle the calls together, as to save an image we need a crewId
    // Which is known when editing and existing crew
    if (
      modalMode == types.FORM_MODE_EDIT &&
      this.state.selectedModalImage != initalImage
    ) {
      saveArray = [this._saveModalData(), this._saveModalImage()];
    }

    // Call the promises
    let putCrewPayload: any;
    Promise.all(saveArray)
      .then(values => {
        putCrewPayload = values[0];
        let newCrewId: any;
        // Update the selected crew with the new ID. Needed to upload the image and delete the crew.
        if (this.state.profileTypePerson) {
          newCrewId = putCrewPayload.content.id;
          // store new crew ID in redux to get selected by default in Who I live with section
          if (
            this.props.navigationParams.params &&
            this.props.navigationParams.params.whoILive
          ) {
            this.props.actions.CrewActions.actionWhoILiveWithCrew(newCrewId);
          }
        } else {
          newCrewId = putCrewPayload.content.organisationId;
        }
        this.setState({
          selectedCrewForm: {
            ...this.state.selectedCrewForm,
            crewId: newCrewId,
          },
        });

        // If new crew upload the image
        if (
          modalMode == types.FORM_MODE_NEW &&
          this.state.selectedModalImage != null
        ) {
          this._saveModalImage(newCrewId)
            .then(response => {
              this._saveUpdateStateRedux(putCrewPayload, newCrewId);
            })
            .catch(error => {
              logger.log('Error resolving all the promises: ', error);
              var tempCrew = JSON.parse(
                JSON.stringify(this.state.selectedCrew),
              );
              this.setState({
                selectedCrewForm: tempCrew,
                selectedModalImage: null,
              });
              this._setLoading(false);
            });
        } else {
          this._saveUpdateStateRedux(putCrewPayload, newCrewId);
        }
      })
      .catch(error => {
        var tempCrew = JSON.parse(JSON.stringify(this.state.selectedCrew));
        this.setState({
          selectedCrewForm: tempCrew,
          selectedModalImage: null,
        });
        this._setLoading(false);
      });
  };

  _updateCrewRedux() {
    const RESULT_INDEX = {
      MEMBER: 0,
      CREW_CATEGORIES: 1,
      CREW: 2,
      CREW_ACCESS: 3,
    };

    let callbackFunction = (results: any) => {
      let crewCategoryPayload =
        results[RESULT_INDEX.CREW_CATEGORIES].content.categories;
      let personCrewsPayload = results[RESULT_INDEX.CREW].personCrews.content;
      let organisationCrewsPayload =
        results[RESULT_INDEX.CREW].organisationCrews.content;
      let crewsAccessPayload = results[RESULT_INDEX.CREW_ACCESS].content;

      // Write the data to redux
      this.props.actions.CrewActions.actionGetCrews(
        crewCategoryPayload,
        personCrewsPayload,
        organisationCrewsPayload,
        crewsAccessPayload,
      );
    };

    callAPIs(
      [
        getMember(this.props.loadedMemberId.loadedMemberId),
        getCrewCategories(this.props.loadedMemberId.loadedMemberId),
        getCrew(this.props.loadedMemberId.loadedMemberId),
        getCrewAccessLevels(this.props.loadedMemberId.loadedMemberId),
      ],
      callbackFunction,
      null,
      () => {
        this.setState({loading: false});
      },
    );
  }

  combineCrewForWhoILiveWith(newCrewIdInput: any) {
    const combinedArray = JSON.parse(JSON.stringify(this.props.crews.crew));
    const checkingItem = this.props.livingArrangement;

    const familyCrewsModalData =
      this.props.livingArrangement.liveWithModalData[0].crews;
    // initialise all items to false
    combinedArray.forEach((item: any, index: any) => {
      const crewArray = combinedArray[index].crews;
      crewArray.forEach((CAitem: any, CAindex: any) => {
        crewArray[CAindex].selected = false;
        for (let i = 0; i < familyCrewsModalData.length; i++) {
          if (familyCrewsModalData[i].crewId == crewArray[CAindex].crewId) {
            crewArray[CAindex].selected = familyCrewsModalData[i].selected;
            break;
          }
        }
      });
    });

    const existingModal =
      this.props.navigationParams.params.selectedModalData[0].crews;
    const newFamilyModal = JSON.parse(JSON.stringify(combinedArray));
    newFamilyModal.forEach((item: any, index: any) => {
      const crewArray = newFamilyModal[index].crews;
      crewArray.forEach((CAitem: any, CAindex: any) => {
        if (crewArray[CAindex].crewId == newCrewIdInput) {
          crewArray[CAindex].selected = true;
        } else {
          crewArray[CAindex].selected = false;
        }
        for (let i = 0; i < existingModal.length; i++) {
          if (existingModal[i].crewId == crewArray[CAindex].crewId) {
            crewArray[CAindex].selected = existingModal[i].selected;
            break;
          }
        }
      });
    });

    const reduxObj = {
      originalData: JSON.parse(JSON.stringify(combinedArray)),
      modalData: JSON.parse(JSON.stringify(newFamilyModal)),
      displayData: JSON.parse(JSON.stringify(combinedArray[0].crews)),
    };
    // SETWHOILIVEWITHDATA
    this.props.actions.NavigationParamActions.setParam({
      isNewCrewAdded: true,
      ...this.props.navigationParams.params,
    });
    this.props.actions.LivingArrangementActions.actionSetWhoILiveWithData(
      reduxObj,
    );
  }

  _saveUpdateStateRedux(putCrewPayload: any, newCrewId: any) {
    // Update the state & redux
    this._updateCrewRedux();
    // Call the update function if it exists
    if (this.props.navigationParams && this.props.navigationParams.params) {
      if (this.props.navigationParams.params.whoILive) {
        setTimeout(
          function (this: any) {
            this._setLoading(false);
            this.combineCrewForWhoILiveWith(newCrewId);
            this._goBack();
          }.bind(this),
          2000,
        );
      } else if (this.props.navigationParams.params.isFromSupport) {
        setTimeout(
          function (this: any) {
            this._setLoading(false);
            this.props.navigationParams.params.getNewCrew(
              this.state.selectedCrewForm,
            );
            if (types.isWeb) {
              setValue(
                types2.WEB_STORE.SELECTE_CREW_SUPPORT,
                JSON.stringify(this.state.selectedCrewForm),
              );
            }
            this._goBack();
          }.bind(this),
          2000,
        );
      } else if (this.props.navigationParams.params.isFromSupportDetails) {
        setTimeout(
          function (this: any) {
            this._setLoading(false);
            this.props.navigationParams.params.setSelectedCrew(
              this.state.selectedCrewForm,
            );
            if (types.isWeb) {
              removeItem(types2.WEB_STORE.PREV_SCREEN);
              setValue(
                types2.WEB_STORE.PREV_SCREEN,
                types2.WEB_STORE.CREW_FORM,
              );
            }
            this._goBack();
          }.bind(this),
          2000,
        );
      } else {
        this._setLoading(false);
        this._goBack();
      }
      if (this.props.navigationParams.params.onBack) {
        this.props.navigationParams.params.onBack();
      }

      // Go back to the previous screen
    } else {
      this._setLoading(false);
      this._goBack();
    }
  }

  // Save an image for a given crewID
  _saveModalImage(newCrewId?:string) {
    var that = this;
    return new Promise(function (this: any, resolve, reject) {
      if (that.state.selectedModalImage != null) {
        // Make sure we have an image
        // Sometimes Android returns a null for images content type
        // So if we don't know what it is use a jpg file.
        let contentType = 'application/octet-stream';
        if (!that.props.avatar.isAvatarImageSelected) {
          if (that.state.selectedModalImage.type == null) {
            contentType = 'image/jpeg';
          } else {
            contentType = that.state.selectedModalImage.type;
          }
        }

        let uploadFileType = USER_TYPE.CREW.API_FILE_TYPE;
        if (!that.state.profileTypePerson) {
          uploadFileType = USER_TYPE.ORGANISATION.API_FILE_TYPE;
        }
       
        // Build the request object for the image upload
        const requestObject = {
          fileType: uploadFileType,
          filename: that.state.selectedModalImage.fileName,
          contentType: contentType,
          forCrew: that.state.selectedCrewForm.crewId || newCrewId,
          photoCaption: that.state.selectedModalImage.photoCaption,
        };

        // Creating the callback function when the URL is returned
        let getUploadURLCallbackFunction = (data: any) => {
          //Successful save -- update redux store and hide modal
          let url = data[0].content.uploadURL;
          if (!that.props.avatar.isAvatarImageSelected) {
            uploadImageToS3(
              url,
              that.state.selectedModalImage.type,
              that.state.selectedModalImage.originalPath,
              that.state.selectedModalImage,
            )
              .then(response => {
                resolve(response);
              })
              .catch(error => {
                logger.log('Image upload error');
                this.setState({
                  saveApiErrorCode: null,
                  saveApiError: true,
                  loading: false,
                });
                reject(error);
              });
          } else {
            uploadImageToS3WithoutResize(
              url,
              that.state.selectedModalImage.type,
              that.props.avatar.image64,
            )
              .then(response => {
                resolve(response);
              })
              .catch(error => {
                logger.log('Image upload error');
                this.setState({
                  saveApiErrorCode: null,
                  saveApiError: true,
                  loading: false,
                });
                reject(error);
              });
          }
        };

        const getUploadURLErrorCallbackFunction = (err: any) => {
          that.setState({
            saveApiErrorCode: err.code,
            saveApiError: true,
            loading: false,
          });
          reject(err);
        };

        // Call the API to get the signed URL
        callAPIs(
          [
            getUploadURL(
              that.props.loadedMemberId.loadedMemberId,
              requestObject,
            ),
          ],
          getUploadURLCallbackFunction,
          getUploadURLErrorCallbackFunction,
        );
      } else {
        reject('No image found to upload');
      }
    });
    return;
  }

  _saveModalData() {
    if (this.state.profileTypePerson) {
      var that = this;
      return new Promise(function (resolve, reject) {
        let crewObjectSave = JSON.parse(
          JSON.stringify(that.state.selectedCrewForm),
        );
        crewObjectSave = trimObjectProperties(
          crewObjectSave,
          types2.USER_TRIM_FIELDS,
        );

        // This object is used for the put call
        // Check that the access has been granted, and if not, strip out the access object from the crew object that is being saved
        if (that.state.selectedCrewForm.grantAccessToYourAccount == 'no') {
          delete crewObjectSave.access;
        }

        that.refactorCrewObject(crewObjectSave); // Remove fields not needed by the API
        crewObjectSave.id = that.props.member.id; // The member ID that is creating/editing this new crew change
        let putCrewCallback = (data: any) => {
          resolve(data[0]);
        };

        const putCrewErrorCallback = (err: any) => {
          that.setState({
            saveApiErrorCode: err.code,
            saveApiError: true,
            loading: false,
          });
          reject(err);
        };

        // Call API to create/update the crew
        callAPIs(
          [putCrewByPersonId(crewObjectSave, that.props.user.demoProfile)],
          putCrewCallback,
          putCrewErrorCallback,
        );
      });
    } else {
      var that = this;
      return new Promise(function (resolve, reject) {
        let organisationName = that.state.orgSelected[0];
        let category = that.state.selectedCrewForm.category;
        let extarInfo = that.state.selectedCrewForm.support;
        let phoneNumber = that.state.selectedCrewForm.phoneNumber;
        let email = that.state.selectedCrewForm.email;
        let orgData = [];
        let selectedOrg = that.state.searchedOrg;

        if (that.state.editOrg) {
          let crewObject = that.refactorCrewObject(that.state.selectedCrewForm);
          orgData = {
            id: that.props.member.id,
            organizationId: crewObject.crewId,
            category: category,
            extraInformation: extarInfo,
            organizationName: organisationName,
            phoneNumber: phoneNumber,
            email: email,
          };
        } else {
          if (that.state.newOrgAdd) {
            orgData = {
              id: that.props.member.id,
              isMemberOrganization: true,
              category: category,
              extraInformation: extarInfo,
              organizationName: organisationName,
              phoneNumber: phoneNumber,
              email: email,
            };
          } else {
            let contactNumber = selectedOrg.PhoneNumber.replace(/[^0-9]/g, '');
            // Check if the user change the contact information of a selected organisation.
            if (
              selectedOrg &&
              (contactNumber !== phoneNumber ||
                selectedOrg.EmailAddress !== email)
            ) {
              orgData = {
                id: that.props.member.id,
                isMemberOrganization: false,
                category: category,
                extraInformation: extarInfo,
                phoneNumber: phoneNumber,
                email: email,
                ndisRegistrationNumber:
                  that.state.searchedOrg.NDISRegistrationNumber,
              };
            } else {
              orgData = {
                id: that.props.member.id,
                isMemberOrganization: false,
                category: category,
                extraInformation: extarInfo,
                ndisRegistrationNumber:
                  that.state.searchedOrg.NDISRegistrationNumber,
              };
            }
          }
        }

        let crewOrgData = JSON.parse(JSON.stringify(orgData)); // This object is used for the put call

        let putCrewCallback = (data: any) => {
          resolve(data[0]);
        };

        const putCrewErrorCallback = (err: any) => {
          that.setState({
            saveApiErrorCode: err.code,
            saveApiError: true,
            loading: false,
          });
          reject(err);
        };

        if (that.state.editOrg) {
          //update Organisation
          callAPIs(
            [updateCrewOrganization(crewOrgData)],
            putCrewCallback,
            putCrewErrorCallback,
          );
        } else {
          // Call API to create/update the crew
          callAPIs(
            [putCrewOrganization(crewOrgData)],
            putCrewCallback,
            putCrewErrorCallback,
          );
        }
      });
    }
  }

  _confirmCrewDeletion = () =>
    this.setState({crewDeletionConfirmationVisible: true});

  _deleteCrewForm = () => {
    this._setLoading(true);
    this.setState({
      saveApiError: false,
      crewDeletionConfirmationVisible: false,
    });

    // Append the member ID that the crew is being deleted to for the API call
    const crewObject = this.refactorCrewObject(this.state.selectedCrewForm);

    const routeFunction = (routingObject: any) => {
      if (this.props.user.isCrew) {
        this._navigateTo(routingObject);
      }
    };

    let isOrganisationCrew = false;
    if (!this.state.profileTypePerson) {
      isOrganisationCrew = true;
    }

    const deleteObject = {
      crewId: crewObject.crewId,
      id: this.props.member.id,
      isOrganisationCrew: isOrganisationCrew,
    };

    const deleteCrewRlnByPersonIdCallback = (data: any) => {
      this._reSetloadedMember();
      if (this.props.user.isCrew && this.props.user.id === crewObject.crewId) {
        routeTolanding(this.props.auth.awstoken.IdentityId, routeFunction);
        this.props.actions.MemberActions.actionUpdateMember({id: undefined});
      } else {
        //Successful save -- Update redux store
        this._updateCrewRedux();

        // Navigate back
        this._setLoading(false);
        this._goBack();
      }
    };

    const deleteCrewRlnByPersonIdErrorCallback = (err: any) => {
      logger.log('CrewForm deleteCrewForm error: ', err);
      this._setLoading(false);
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
      });
    };

    // Callback to API
    callAPIs(
      [deleteCrew(deleteObject, this.props.user.demoProfile)],
      deleteCrewRlnByPersonIdCallback,
      deleteCrewRlnByPersonIdErrorCallback,
    );
  };

  _navigateTo(routingObj: any) {
    if (types.isWeb) {
      return this.props.navigation.navigate(routingObj);
    } else {
      const actionToDispatch = CommonActions.reset({
        index: 0,
        routes: [
          {
            name: routingObj.routeName,
            params: routingObj.params,
          },
        ],
      });
      this.setState({loading: false});
      this.props.navigation.dispatch(actionToDispatch);
    }
  }

  // Removes unneeded key-value pairs from the crewObject for the API put request
  refactorCrewObject = (crewObject: any) => {
    delete crewObject['grantAccessToYourAccount']; // This key is not needed from the API
    return crewObject;
  };

  _closeCrewForm = () => {
    if (this.props.navigation) {
      this._goBack();
    }
  };

  validateFields = () => {
    // Used to set the state for failed fields - we build it up as to only set the state once
    // If the error string is blank it means it passed validation :)
    // Additionaly it all sets them to passed if the user fixed previous validation issues
    var validateErrors = {
      firstNameError: '',
      lastNameError: '',
      emailError: '',
      phoneNumberError: '',
      orgError: '',
    };

    if (
      this.state.profileTypePerson &&
      (this.state.selectedCrewForm.firstName == '' ||
        this.state.selectedCrewForm.firstName == undefined)
    ) {
      validateErrors.firstNameError = types.FIRSTNAME_NOT_ENTERED;
    }

    if (
      this.state.profileTypePerson &&
      (this.state.selectedCrewForm.lastName == '' ||
        this.state.selectedCrewForm.lastName == undefined)
    ) {
      validateErrors.lastNameError = types.LASTNAME_NOT_ENTERED;
    }

    if (
      this.state.selectedCrewForm.phoneNumber != '' &&
      this.state.selectedCrewForm.phoneNumber != undefined
    ) {
      let reg = /[^0-9]/;
      if (reg.test(this.state.selectedCrewForm.phoneNumber)) {
        validateErrors.phoneNumberError = types.INVALID_PHONE_NAN;
      } else {
        validateErrors.phoneNumberError = '';
      }
    }

    if (this.state.selectedCrewForm.email != '') {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!re.test(this.state.selectedCrewForm.email)) {
        validateErrors.emailError = types.EMAIL_INVALID_FORMAT;
      } else {
        validateErrors.emailError = '';
      }
    } else if (this.state.selectedCrewForm.grantAccessToYourAccount == 'yes') {
      validateErrors.emailError = types.EMAIL_REQUIRED_TO_GIVE_ACCESS;
    } else if (
      this.state.profileTypePerson == false &&
      this.state.selectedCrewForm.email == ''
    ) {
      validateErrors.emailError = types.EMAIL_NOT_ENTERED;
    } else {
      validateErrors.emailError = '';
    }
    //Check if crew is already added.
    if (
      this.props.crews.crewIndividual.find(
        (crewMember: any) =>
          crewMember.crewId !== this.state.selectedCrewForm.crewId &&
          crewMember.email &&
          crewMember.email.toUpperCase() ===
            this.state.selectedCrewForm.email.toUpperCase(),
      )
    ) {
      return Alert.alert(types2.CREW_ALREADY_EXIST);
    }

    if (!this.state.profileTypePerson && this.state.orgSelected.length == 0) {
      validateErrors.orgError = 'Select or add a organisation';
    }

    // Set the state to show the validation on the components
    this.setState({
      validFirstName: validateErrors.firstNameError,
      validLastName: validateErrors.lastNameError,
      validEmail: validateErrors.emailError,
      validPhoneNumber: validateErrors.phoneNumberError,
      valideOrganisation: validateErrors.orgError,
    });

    // Now return if the there are valid fields
    for (let [key, value] of Object.entries(validateErrors)) {
      if (value != '') {
        return false;
      }
    }
    return true;
  };

  _setInitialState = () => {
    if (this._getParamValue('screenMode') === types.FORM_MODE_EDIT) {
      this.setState({
        initialFormState: {
          selectedCrewForm: this._getParamValue('selectedCrew'),
        },
      });
    }
    if (types.isWeb) {
      // Set crew type selected
      const isCrewOrg = getValue(types2.WEB_STORE.CREW_TYPE) === 'false';
      if (isCrewOrg) {
        this.setState({profileTypePerson: false});
      }
    }
  };

  _hasFormContentChanged = () => {
    let initialFormState = this.state.initialFormState;
    let currentFormState = {
      selectedCrewForm: this.state.selectedCrewForm,
      selectedModalImage: this.state.selectedModalImage,
    };
    return (
      JSON.stringify(initialFormState) !== JSON.stringify(currentFormState)
    );
  };

  _reSetloadedMember = () => {
    if (this.state.selectedCrewForm.crewId === this.props.user.id)
      this.props.actions.loadedMemberActions.setInitialState();
  };
}

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  member: state.MemberReducer,
  crews: state.CrewReducer,
  loadedMemberId: state.LoadedMemberReducer,
  avatar: state.AvatarReducer,
  auth: state.AuthenticationReducer,
  livingArrangement: state.LivingArrangementReducer,
  navigationParams: state.NavigationParamsReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    LivingArrangementActions: bindActionCreators(
      LivingArrangementActions,
      dispatch,
    ),
    CrewActions: bindActionCreators(CrewActions, dispatch),
    MemberActions: bindActionCreators(MemberActions, dispatch),
    AvatarListActions: bindActionCreators(AvatarListActions, dispatch),
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    loadedMemberActions: bindActionCreators(loadedMemberActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CrewForm);
