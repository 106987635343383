/*
 * Author: Andrew Seeley
 * Date: 18/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Web Service class to handle the Authentication API calls
 */

// Login library for Cognito
import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserAttribute,
  AuthenticationDetails,
} from 'amazon-cognito-identity-js';
import AWS from 'aws-sdk';
// import {
//   AccessToken,
//   GraphRequest,
//   GraphRequestManager,
// } from 'react-native-fbsdk';
import {getGoogleSignin, getVersion} from '../Helpers/PlatformSynchronizer';
// import {LoginManager} from 'react-native-fbsdk';
import {logPerf} from '../API/APIHelper';
import {callAPIs} from '../API/APICaller';
import {createUser, createUserObject, putUser} from './UserAPI';
import {putCognitoIdByEmail} from './PersonAPI';

import {RNkeychain as Keychain} from '../Helpers/PlatformSynchronizer';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import * as env from '../environments';
import {Auth} from 'aws-amplify';
import {removeItem} from '../API/StorageHelper';

// Import Redux Store requirements
import reduxStore from '../ReduxStore/ReduxStore';
import logger from 'helpers/Logger';
import {setValue} from '../API/WebPersistenceStore';

//* *****************************************************************
//*          L O G I N / A U T H E N T I C A T I O N
//* *****************************************************************

export const getAwsAuth = async () => {
  const user = await Auth.currentAuthenticatedUser({
    bypassCache: false,
  });
  return user;
};

/*
 * Logs in as a Staff member- authentication is based on hh cognito userpool
 */
export const getCurrentAuthenticatedUser = async (success: any) => {
  getAwsAuth()
    .then(user => {
      const idToken = user.signInUserSession.idToken;
      setValue('cognitoId', idToken.jwtToken);
      setValue(
        'awsIdentity',
        user.storage[`aws.cognito.identity-id.${env.COGNITO_IDENTITY_POOL}`],
      );

      loginAWS(idToken.jwtToken, types.LOGIN_TYPE_EMAIL, () => {})
        .then(response => {
          success();
        })
        .catch(error => {
          logger.log('Login AWS Error:', error);
        });
    })
    .catch(() => {
      console.log('catch');
    });
};

export const userLogOut = () => {
  logoutCognito();
  return Auth.signOut();
};

/*
 * Logs into AWS using either Facebook, Google or Cognito
 * @async
 * @param {string} token The token of the login identity you are using.
 * @param {string} loginType The way you are logging into the app. Enum of either Facebook, Google or Email.
 * @returns {Promise<string>} AWS identity ID of your login.
 */
export const loginAWS = (
  token: any,
  loginType: any,
  reject: any,
  isDemoProfile = false,
) => {
  // Not really needed since can move to environment file?
  AWS.config = new AWS.Config({
    region: env.AWS_REGION,
    httpOptions: {
      connectTimeout: types.LAMBDA_CONNECT_TIMEOUT,
      timeout: types.LAMBDA_TIMEOUT,
    },
  });

  // Depending on what our login type is we need to setup different config fo AWS.
  switch (loginType) {
    // Setup Facebook config
    case types.LOGIN_TYPE_FACEBOOK:
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: env.COGNITO_IDENTITY_POOL,
        Logins: {
          'graph.facebook.com': token,
        },
      });
      break;
    // Setup Google config
    case types.LOGIN_TYPE_GOOGLE:
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: env.COGNITO_IDENTITY_POOL,
        Logins: {
          'accounts.google.com': token,
        },
      });
      break;
    // Setup Email Config
    case types.LOGIN_TYPE_EMAIL:
      if (isDemoProfile) {
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: env.COGNITO_IDENTITY_POOL,
          Logins: {
            [env.DEMO_COGNITO_EMAIL_SIGNUP_URL]: token,
          },
        });
      } else {
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: env.COGNITO_IDENTITY_POOL,
          Logins: {
            [env.COGNITO_EMAIL_SIGNUP_URL]: token,
          },
        });
      }
      break;
    default:
      // There was an invalid login type, reject the promise and take no further action.
      reject('Invalid login type');
  }

  // Now we have confgured AWS for the method of login, attempt to login and return the ID on success, otherwise the error on failure.
  return new Promise((resolve, reject) => {
    const startTime = new Date().getTime();
    AWS.config.credentials.clearCachedId();
    AWS.config.credentials.get((error: any) => {
      if (error) {
        logPerf('LoginAWS', new Date().getTime() - startTime, false);
        reject(`There has been an error getting credentials${error}`);
      } else {
        logPerf('LoginAWS', new Date().getTime() - startTime);
        resolve(AWS.config.credentials.data);
      }
    });
  });
};

/*
 * Facebook login. This is called after Facebook has logged in (Using SDK with native login button works this way)
 * The sign up also calls this method and creates a new account using the facebook profile
 * The result of the login is passed and errors if there is any.
 * @async
 * @param {object} error Optional: Error that may have occured on Facebook login.
 * @param {object} result The result of the Facebook login.
 * @returns {Promise<object>} An object with your Facebook token and AWS identity.
 */
export const loginFacebook = (result: any, isSignUp = false) =>
  new Promise((resolve, reject) => {
    if (result.isCancelled) {
      reject('Login was cancelled');
    } else {
      // Facebook login was successful, now login to AWS using the Facebook token.
      // AccessToken.getCurrentAccessToken()
      //   .then(data => {
      //     loginAWS(data.accessToken, types.LOGIN_TYPE_FACEBOOK)
      //       .then(response => {
      //         const refactoredResponse = refactorAwsIdentityObject(response);
      //         const loginInfo = {
      //           facebookToken: data.accessToken,
      //           awsIdentity: refactoredResponse,
      //         };
      //         // If it's a new sign up get the first name, last name and email from Facebook
      //         // Then call AWS to create a new user.
      //         if (isSignUp) {
      //           // Get the logged in Facebook details (name & email)
      //           getLoggedInFacebookProfile(data.accessToken)
      //             .then(response => {
      //               // Create a new user Object that matches the one expected by the API.
      //               const userType = getSignUpTypeParticipantCrew();
      //               const userApi = createUserObject(
      //                 response.email,
      //                 response.firstName,
      //                 response.lastName,
      //                 '',
      //                 loginInfo.awsIdentity.IdentityId,
      //                 true,
      //                 userType.isMember,
      //                 userType.isCrew,
      //               );
      //               // This function gets called when we create a new user
      //               const createUserCallback = (err, data) => {
      //                 if (err) {
      //                   logger.log('Create user error happened ', err);
      //                   reject(err);
      //                 } else if (data.Payload.statusCode != '200') {
      //                   logger.log(
      //                     'Error has occurred during user creation, please contact your administrator',
      //                   );
      //                   // TODO: logout cognito to clean up the local account info
      //                   reject(data);
      //                 } else {
      //                   resolve(loginInfo);
      //                 }
      //               };
      //               // Create a new user
      //               createUser(userApi, createUserCallback);
      //             })
      //             .catch(error => {
      //               logger.log('Facebook login failed ', error);
      //               reject(error);
      //             });
      //           // Otherweise we are an existing Facebook user so we are logged in already - return the login info.
      //         } else {
      //           resolve(loginInfo); // Return our login information which is set in Redux.
      //         }
      //       })
      //       .catch(error => {
      //         logger.log('AWS promise error ', error);
      //         reject(error); // Error from loginAWS promise
      //       });
      //   })
      // .catch(error => {
      //   reject(`React native facebook token error ${error}`);
      // });
    }
  });

/*
 * Gets the profile log the current logged in Facebook account (first name, last name, email)
 * @async
 * @returns Callback<object> An object with the current logged in facebook users profile details
 */
export const getLoggedInFacebookProfile = () =>
  new Promise((resolve, reject) => {
    // const req = new GraphRequest(
    //   '/me',
    //   {
    //     parameters: {
    //       fields: {
    //         string: 'email,first_name,last_name',
    //       },
    //     },
    //   },
    //   (err, res) => {
    //     if (err) {
    //       logger.log(err);
    //       reject(err);
    //     }
    //     const profile = {
    //       firstName: res.first_name,
    //       lastName: res.last_name,
    //       email: res.email,
    //     };
    //     resolve(profile);
    //   },
    // );
    // new GraphRequestManager().addRequest(req).start();
  });

/*
 * Facebook existing login. This is called when you have a previous Facebook login on the app.
 * The result of the login is passed and errors if there is any.
 * @async
 * @param {object} data The result of the Facebook login.
 * @returns {Promise<object>} An object with your Facebook token and AWS identity.
 */
export const loginExistingFacebook = (data: any) =>
  new Promise((resolve, reject) => {
    loginAWS(data.accessToken, types.LOGIN_TYPE_FACEBOOK)
      .then(response => {
        const refactoredResponse = refactorAwsIdentityObject(response);
        const loginInfo = {
          facebookToken: data.accessToken,
          awsIdentity: refactoredResponse,
        };
        resolve(loginInfo); // Return our login information which is set in Redux.
      })
      .catch(error => {
        reject(error); // AWS Login Error
      })
      .done();
  });

export const logoutFacebook = () => {
  // LoginManager.logOut();
  removeItem(types.LOGIN_LAST_TYPE);
};

/*
 * Google login. This is called when you login with Google.
 * The sign up also calls this method and creates a new account using the google account
 * @async
 * @returns {Promise<object>} An object with your Google token and AWS identity.
 */
export const loginGoogle = (isSignUp = false) =>
  new Promise((resolve, reject) => {
    getGoogleSignin
      .signIn()
      .then((user: any) => {
        loginAWS(user.idToken, types.LOGIN_TYPE_GOOGLE)
          .then(response => {
            const refactoredResponse = refactorAwsIdentityObject(response);
            const loginInfo = {
              googleToken: user.idToken,
              awsIdentity: refactoredResponse,
            };

            // If it's a new sign up get the first name, last name and email from Google
            // Then call AWS to create a new user.
            if (isSignUp) {
              // Create a new user Object that matches the one expected by the API.
              // This function gets called when we create a new user
              const userType = getSignUpTypeParticipantCrew();
              const userApi = createUserObject(
                user.email,
                user.givenName,
                user.familyName,
                '',
                loginInfo.awsIdentity.IdentityId,
                true,
                userType.isMember,
                userType.isCrew,
              );
              const createUserCallback = (err: any, data: any) => {
                if (err) {
                  logger.log('Create user error happened ', err);
                  reject(err);
                } else if (data.Payload.statusCode != '200') {
                  logger.log(
                    'Error has occurred during user creation, please contact your administrator',
                  );
                  // TODO: logout cognito to clean up the local account info
                  reject(data);
                } else {
                  resolve(loginInfo);
                }
              };
              // Create a new user
              createUser(userApi, createUserCallback);
            } else {
              resolve(loginInfo); // Return our login information which is set in Redux.
            }
          })
          .catch(error => {
            removeItem(types.LOGIN_LAST_TYPE);
            logger.log('AWS Sign in error ', error);
            reject(error); // AWS Login Error
          });
      })
      .catch((error: any) => {
        removeItem(types.LOGIN_LAST_TYPE);
        reject(`Google sign in error ${error}`);
      })
      .done();
  });

const getSignUpTypeParticipantCrew = () => {
  // Get the sign up type n return it yeahh
  const reduxState = reduxStore.getStore().getState();

  return {
    isMember: !!reduxState.UserReducer.isMember,
    isCrew: !!reduxState.UserReducer.isCrew,
  };
};

/*
 * Google existing login. This is called when you have a previous Google login on the app.
 * @async
 * @params {object} user An object provided by Google authentication
 * @returns {Promise<object>} An object with your Google token and AWS identity.
 */
export const loginExistingGoogle = (user: any) =>
  new Promise((resolve, reject) => {
    loginAWS(user.idToken, types.LOGIN_TYPE_GOOGLE)
      .then(response => {
        const refactoredResponse = refactorAwsIdentityObject(response);
        const loginInfo = {
          googleToken: user.idToken,
          awsIdentity: refactoredResponse,
        };
        resolve(loginInfo); // Return our login information which is set in Redux.
      })
      .catch(error => {
        reject(error); // AWS Login Error
      })
      .done();
  });

/*
 * Google logout. This is called when you logout using Google.
 * @async
 * @returns {Promise<>} If successfull returns an empty promise, otherwise reject the promise.
 */
export const logoutGoogle = () =>
  new Promise((resolve, reject) => {
    getGoogleSignin
      .revokeAccess()
      .then(() => getGoogleSignin.signOut())
      .then(() => {
        // If the sign out was sucessfull then sign out of AWS
        removeItem(types.LOGIN_LAST_TYPE);
        resolve();
      })
      .catch((error: any) => {
        // The sign out was unsuccessfull.
        reject('Google sign out error: ', error);
      });
  });

/*
 * Cognoto login. This is called when you login with with Email & Password using Cognito.
 * @async
 * @param {string} username The person's username which is their email address.
 * @param {string} password The person's password.
 * @returns {Promise<object>} An object with your Cognito token and AWS identity.
 */
export const loginCognito = (
  username: any,
  password: any,
  isDemoProfile = false,
) =>
  new Promise((resolve, reject) => {
    if (AWS && AWS.config && AWS.config.credentials) {
      AWS.config.credentials.clearCachedId();
    }

    // Lower case everything in username
    const lowerCaseUsername = username.toLowerCase();
    // Setup authentication data needed for Cognito login
    const authenticationData = {
      Username: lowerCaseUsername,
      Password: password,
    };

    if (!types.isWeb) {
      // Save credentials to keychain (encrypted). This is so the user can be automatically logged in again later.
      Keychain.setInternetCredentials(
        'cognito',
        lowerCaseUsername,
        password,
      ).then(() => {});
    }

    // Now we can create our authentication details
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const cognitoUser = buildCognitoUser(username, isDemoProfile);

    // Now login with cognito
    cognitoUser.authenticateUser(authenticationDetails, {
      // If the cognito login works get it's token & login with AWS.
      onSuccess(result) {
        const cognitoIdToken = result.getIdToken().getJwtToken();
        loginAWS(
          cognitoIdToken,
          types.LOGIN_TYPE_EMAIL,
          () => {},
          isDemoProfile,
        )
          .then(response => {
            const refactoredResponse = refactorAwsIdentityObject(response);
            const loginInfo = {
              cognitoIdToken,
              awsIdentity: refactoredResponse,
              type: types.COGNITO_TYPE_LOGIN,
            };
            resolve(loginInfo); // Return our login information which is set in Redux.
          })
          .catch(error => {
            logger.log('Login AWS Error:', error);
            reject(error); // AWS Login Error
          });
      },

      // Cognito login failed
      onFailure(error) {
        reject(error);
      },

      newPasswordRequired(userAttributes, requiredAttributes) {
        const challengeInfo = {
          type: types.COGNITO_TYPE_CHALLENGE,
          userAttributes,
          cognitoUser,
        };
        resolve(challengeInfo);
      },
    });
  });

export const cognitoPasswordChallenge = (
  username: any,
  newPassword: any,
  userAttributes: any,
  cognitoUser: any,
) =>
  new Promise((resolve, reject) => {
    const modifiedUserAttributes = userAttributes;

    delete modifiedUserAttributes.email_verified;
    // Delete the email_verified attribute
    cognitoUser.completeNewPasswordChallenge(
      newPassword,
      modifiedUserAttributes,
      {
        // If the cognito login works get it's token & login with AWS.
        onSuccess(result: any) {
          const cognitoIdToken = result.getIdToken().getJwtToken();
          loginAWS(cognitoIdToken, types.LOGIN_TYPE_EMAIL)
            .then(response => {
              setValue('cognitoId', cognitoIdToken);
              const refactoredResponse = refactorAwsIdentityObject(response);
              const loginInfo = {
                cognitoIdToken,
                awsIdentity: refactoredResponse,
                type: types.COGNITO_TYPE_LOGIN,
              };
              // Call the update user by email
              const updateUserCallback = (err: any, data: any) => {
                if (err) {
                  reject(err);
                } else if (data.Payload.statusCode != '200') {
                  reject(data);
                } else {
                  // The cognito id has been updated via email
                  resolve(loginInfo);
                }
              };

              putCognitoIdByEmail(
                username,
                loginInfo.awsIdentity.IdentityId,
                updateUserCallback,
              );
            })
            .catch(error => {
              reject(error); // AWS Login Error
            });
        },

        // Cognito login failed
        onFailure(error: any) {
          logger.log('Cognito challenge error with:', error);
          reject(error);
        },
      },
    );
  });

export const logoutCognito = () =>
  new Promise((resolve, reject) => {
    const user = getLocalCognitoUser();
    if (user != null) {
      user.signOut();
    }
    if (AWS && AWS.config && AWS.config.credentials) {
      AWS.config.credentials.clearCachedId();
    }

    if (types.isWeb) {
      localStorage.clear();
      resolve('');
    } else {
      // Remove the keychain login & last login type
      Keychain.resetInternetCredentials('cognito')
        .then(value => {
          removeItem(types.LOGIN_LAST_TYPE);
          resolve(value);
        })
        .catch(error => {
          resolve(error);
        });
    }
  });

export const signUpCognito = (
  email: any,
  password: any,
  firstname: any,
  lastname: any,
  knownas: any,
  captchatoken: any,
  selectedUserType = false,
  isDemoProfile = false,
) =>
  new Promise((resolve, reject) => {
    if (AWS && AWS.config && AWS.config.credentials) {
      AWS.config.credentials.clearCachedId();
    }
    const lowerCaseEmail = email.toLowerCase();
    const username = lowerCaseEmail;
    // FIXME: This will need to be made into configuration
    let poolData = {
      UserPoolId: env.COGNITO_USER_POOL_ID,
      ClientId: env.COGNITO_CLIENT_ID,
    };
    if (isDemoProfile) {
      poolData = {
        UserPoolId: env.DEMO_COGNITO_USER_POOL_ID,
        ClientId: env.DEMO_COGNITO_CLIENT_ID,
      };
    }
    const userPool = new CognitoUserPool(poolData);

    // Get the attribute list that will need to be written to the user pool
    const attributeList = [];
    const dataEmail = {
      Name: 'email',
      Value: lowerCaseEmail,
    };

    const dataFamilyName = {
      Name: 'family_name',
      Value: lastname,
    };

    const dataGivenName = {
      Name: 'given_name',
      Value: firstname,
    };

    // Convert the data into cognito data
    const attributeEmail = new CognitoUserAttribute(dataEmail);
    const attributeFamilyName = new CognitoUserAttribute(dataFamilyName);
    const attributeGivenName = new CognitoUserAttribute(dataGivenName);

    attributeList.push(attributeEmail);
    attributeList.push(attributeFamilyName);
    attributeList.push(attributeGivenName);

    if (types.isWeb) {
      const captachToekn = {
        Name: 'custom:captcha_access_token',
        Value: captchatoken,
      };
      if (captchatoken !== null || captchatoken !== undefined) {
        const attributeCaptchaToken = new CognitoUserAttribute(captachToekn);
        attributeList.push(attributeCaptchaToken);
      }
    }

    // Internal function that can be called after, but still has scope to this.
    const onSignUpSuccess = (authenticationDetails: any, cognitoUser: any) => {
      // TODO - create cognito user

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess(result: any) {
          const cognitoIdToken = result.getIdToken().getJwtToken();
          setValue('cognitoId', cognitoIdToken);
          onAuthenticationSuccess
            .call(this, result)
            .then(result => {
              const refactoredResponse = refactorAwsIdentityObject(result);
              const loginInfo = {
                cognitoIdToken,
                awsIdentity: refactoredResponse,
              };

              // Create a new user now that all the cognito stuff is done
              const userType = getSignUpTypeParticipantCrew();
              const userApi = createUserObject(
                email,
                firstname,
                lastname,
                knownas,
                loginInfo.awsIdentity.IdentityId,
                true,
                userType.isMember,
                userType.isCrew,
                selectedUserType,
              );
              const createUserCallback = (err: any, data: any) => {
                if (err) {
                  logger.log('Create user error happened ', err);
                  reject(err);
                } else if (data.Payload.statusCode == types.VERSION_ERROR) {
                  logger.log('Version error mismatch');
                  reject({
                    code: types.VERSION_ERROR,
                    message: data.Payload.content.message,
                  });
                } else if (data.Payload.statusCode != '200') {
                  logger.log(
                    'Error has occurred during user creation, please contact your administrator',
                  );
                  // TODO: logout cognito to clean up the local account info
                  reject(data);
                } else {
                  resolve(loginInfo);
                }
              };

              // Create a new user
              createUser(userApi, createUserCallback);
            })
            .catch(error => {
              logger.log('onAuthenticationSuccess error ', error);
              reject(error);
            });
        },

        onFailure(err: any) {
          // FIXME: Need to do something here for error signing in after sign up
          reject(err);
        },
      });
    };

    // Sign up via Cognito
    userPool.signUp(username, password, attributeList, null, (err, result) => {
      if (err) {
        reject(err);
        return;
      }

      const cognitoUser = result.user;

      // Sign into the user
      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password,
      });

      // Sign up success
      onSignUpSuccess(authenticationDetails, cognitoUser);
    });
  });

export const onAuthenticationSuccess = (result: any) =>
  new Promise((resolve, reject) => {
    // Add the cognito user into the federated id
    AWS.config.region = env.AWS_REGION;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      // FIXME: This will need to come from configurations - as well as the logins, as that will change according to environment.
      IdentityPoolId: env.COGNITO_IDENTITY_POOL,
      Logins: {
        [env.COGNITO_EMAIL_SIGNUP_URL]: result.getIdToken().getJwtToken(),
      },
    });
    AWS.config.credentials.clearCachedId();
    AWS.config.credentials.get((error: any) => {
      if (error) {
        reject(error);
      } else {
        resolve(AWS.config.credentials.data);
      }
    });
  });

/*
 * Checks for and returns a local cognito user if logged in.
 *
 * @returns {object} The user object. If none found it will return null.
 */
export const getLocalCognitoUser = () => {
  const data = {
    UserPoolId: env.COGNITO_USER_POOL_ID,
    ClientId: env.COGNITO_CLIENT_ID,
  };

  const userPool = new CognitoUserPool(data);
  const user = userPool.getCurrentUser();

  if (user != null) {
    return user;
  }
  return null;

  // Token will refresh automatically with Android & iOS SDK as per. 30 day expirty
  // http://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-tokens-with-identity-providers.html
};

/*
 * Cognoto login. This is called when you login with with Email & Password using Cognito.
 * @async
 * @param {string} currentPassword The current password of the user.
 * @param {string} newPassword The new users password.
 * @returns {Promise<object>} An object with the results of changing your password.
 */
export const changeCognitoPassword = (currentPassword: any, newPassword: any) =>
  new Promise((resolve, reject) => {
    // Get the user logged in (if there is one)
    if (getLocalCognitoUser != null) {
      const user = getLocalCognitoUser();

      // Before we are able to change the user's password we have to get their session
      user.getSession((err: any, session: any) => {
        if (err) {
          reject(err);
        }
      });

      // Now change their password
      user.changePassword(currentPassword, newPassword, (err, result) => {
        if (err) {
          reject(err);
        }
        // Password change was successful
        resolve(result);
      });
    } else {
      reject('No user found');
    }
  });

/**
 * Cognito forgot password. This will allow the user to reset their password.
 * @async
 * @param {string} username The username that is going to be used to create the cognito user.
 * @returns {Promise<object>} On success will return the user cognito object that can be used to then follow up with confirming the password.
 */
export const forgotCognitoPassword = (username: any) =>
  new Promise((resolve, reject) => {
    // Create the cognito user that is required
    const cognitoUser = buildCognitoUser(username);

    cognitoUser.forgotPassword({
      onSuccess(data) {
        resolve();
      },

      onFailure(err) {
        logger.log('An error was encountered', err);
        reject(err);
      },
    });
  });

/**
 * This function will allow the user to confirm their password that needs to be reset to.
 * @param {string} username The username / email that has had the password reset
 * @param {string} tokenCode The token the user would have received in the email
 * @param {string} password The password that the user is resetting to.
 * @returns {Promise<object>} On success of the password being confirmed, the promise will resolve, otherwise will be rejected.
 */
export const confirmCognitoPassword = (
  username: any,
  tokenCode: any,
  password: any,
) =>
  new Promise((resolve, reject) => {
    const cognitoUser = buildCognitoUser(username);
    cognitoUser.confirmPassword(tokenCode, password, {
      onSuccess() {
        resolve();
      },

      onFailure(err) {
        logger.log('Setting new password failed with error', err);
        reject(err);
      },
    });
  });

/**
 * This will build the cognito user that is required when using the cognito functionality
 * @param {string} username The username that is being created for the cognito user.
 * @returns {object} The cognito user object that is being used.
 */
const buildCognitoUser = (username: any, isDemoProfile: any) => {
  // Lower case the user name
  const lowerCaseUsername = username.toLowerCase();
  // Setup & connect to the Cognito user pool.
  let poolData = {
    UserPoolId: env.COGNITO_USER_POOL_ID,
    ClientId: env.COGNITO_CLIENT_ID,
  };
  if (isDemoProfile) {
    poolData = {
      UserPoolId: env.DEMO_COGNITO_USER_POOL_ID,
      ClientId: env.DEMO_COGNITO_CLIENT_ID,
    };
  }
  const userPool = new CognitoUserPool(poolData);
  const userData = {
    Username: lowerCaseUsername,
    Pool: userPool,
  };

  // Now we have got a new cognito user.
  const cognitoUser = new CognitoUser(userData);
  return cognitoUser;
};

/*
This function will take the response from AWS after a successful login to refactor it for our redux store.
It returns a JSON object without the nested credentials structure
* @param {object} awsObject The object returned by Cognito after successful login
* @returns {object} The refactored object to store in redux

*/
const refactorAwsIdentityObject = (awsObject: any) => {
  const refactoredAwsObj = {
    AccessKeyId: awsObject.Credentials.AccessKeyId,
    Expiration: awsObject.Credentials.Expiration,
    SecretKey: awsObject.Credentials.SecretKey,
    SessionToken: awsObject.Credentials.SessionToken,
    IdentityId: awsObject.IdentityId,
  };
  return refactoredAwsObj;
};

export const checkTokenExpiration = (
  completeHandler: any,
  errorHandler: any,
) => {
  const poolData = {
    UserPoolId: env.COGNITO_USER_POOL_ID,
    ClientId: env.COGNITO_CLIENT_ID,
  };
  const userPool = new CognitoUserPool(poolData);
  const currentUser = userPool.getCurrentUser();
  //checks if the token id is valid
  //if not it uses the refresh token to retrieve a new token id
  if (currentUser) {
    currentUser.getSession(function (err: any, data: any) {
      if (err) {
        errorHandler(err);
      } else {
        const cognitoId = data.getIdToken().jwtToken;
        setValue('cognitoId', cognitoId);
        completeHandler();
      }
    });
  } else {
    errorHandler('No user found');
  }
};

export const getCurrentUserIdToken = () => {
  return new Promise((resolve, reject) => {
    const poolData = {
      UserPoolId: env.COGNITO_USER_POOL_ID,
      ClientId: env.COGNITO_CLIENT_ID,
    };
    const userPool = new CognitoUserPool(poolData);
    const currentUser = userPool.getCurrentUser();
    //checks if the token id is valid
    //if not it uses the refresh token to retrieve a new token id
    if (currentUser) {
      currentUser.getSession(function (err: any, data: any) {
        if (err) {
          resolve(null);
        } else {
          const cognitoId = data.getIdToken().jwtToken;
          resolve(cognitoId);
        }
      });
    } else {
      resolve(null);
    }
  });
};
