/*
 * Author: Andrew Seeley
 * Date: 20/11/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Terms & Conditions Modal that pops up if you show the terms and conditions or they update.
 */

import React, {Component} from 'react';
import {View, Platform, Dimensions} from 'react-native';
//import {WebView as WebViewWeb} from 'react-native-webview';
import {BackButton} from '../Components/Atoms';
//import {WebView as WebViewMobile} from 'react-native-webview';
import {WebViewWeb as WebView} from 'helpers/ReactNativeWebview';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import * as brand from '../Constants/BrandConstants';
import {OfflineNotice, DeepLinkBanner} from '../Components/Organisms';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

class TermsAndConditions extends Component {
  render() {
    return (
      <View
        style={[
          CommonStyles.content,
          {height: Dimensions.get('window').height},
        ]}
      >
        <View
          style={[
            CommonStyles.webViewHeader,
            BrandStyles.ContainerBackgroundColor,
          ]}
        >
          {this._renderBackButton()}
        </View>
        <OfflineNotice />
        {types.isWeb && <DeepLinkBanner />}
      
          {types.isWeb ? (
            <WebView
              title="GuideMeSummary"
              style={CommonStyles.WebViewStyle}
              source={brand.APP_HELP_URL}
            />
          ) : (
            <WebView
            source={brand.APP_HELP_URL}
            domStorageEnabled={true}
            startInLoadingState={true}
          />
          )}
      </View>
    );
  }

  _renderBackButton() {
    if (Platform.OS === types2.IOS) {
      if (brand.APP_BRAND == types2.APP_BRAND.UCQ) {
        return (
          <BackButton
            isBlack
            goBack={() => {
              this.props.navigation.goBack();
            }}
          />
        );
      }
      return (
        <BackButton
          goBack={() => {
            this.props.navigation.goBack();
          }}
        />
      );
    }
  }
}

export default TermsAndConditions;
