/**
 * Author: Sujeban Elankeswaran
 * Date: 20/12/2019
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This shows the Referral Providers About Screen.
 */

import React, {Component} from 'react';
import {ScrollView, View, Image} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Markdown from 'react-native-easy-markdown';
import {
  StandardText,
  StandardButton,
  AboutProviderInfoItem,
} from '../Components/Atoms';
import {Container} from '../Components/Molecules';
import {MediumRedTickCircleRed3x, NDISBadge} from '../assets/images';
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import * as SupportsActions from '../Actions/SupportsActions';
import * as BudgetActions from '../Actions/BudgetActions';
import * as PayInvoiceActions from '../Actions/PayInvoiceActions';
import * as MemberActions from '../Actions/MemberActions';
import * as ProviderActions from '../Actions/ProviderActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {getProviderBySysId} from '../API/ProviderAPI';
import {callAPIs} from '../API/APICaller';

// import Orientation from 'react-native-orientation-enhanced';

class AboutProvider extends Component {
  state = {
    loading: true,
    orientation: '',
    isOpen: this.props.sideMenu ? this.props.sideMenu.sideMenuIsOpen : false,
    refreshing: false,
    providerid: '',
    provider: null,
    webheight: 100,
  };

  constructor(props: any) {
    super(props);
    this._createMainContents = this._createMainContents.bind(this);
    this.onRefresh = this.onRefresh.bind();
  }

  // UNSAFE_componentWillMount() {
  //   if (!types.isWeb) {
  //     Orientation.getOrientation((err: any, orientation: any) => {
  //       this.setState({orientation});
  //     });
  //   }
  // }
  componentWillUnmount() {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({orientation});
  };

  UNSAFE_componentWillReceiveProps(props: any) {
    this.setState({
      isOpen: props.sideMenu ? props.sideMenu.sideMenuIsOpen : false,
    });
  }

  componentDidMount() {
    this.fetchProviderInfo();
  }

  onRefresh = () => {
    this.setState({refreshing: true});
  };

  fetchProviderInfo = () => {
    const providerId = this._getParams('providerid', undefined);
    if (providerId) {
      callAPIs(
        [getProviderBySysId(providerId)],
        (data: any) => {
          if (data) {
            const obj = JSON.parse(data[0].content.body);
            this.setState({provider: obj.data[0]}, () => {
              this.setState({loading: false});
            });
          }
        },
        null,
        () => {},
      );
    } else {
      this.props.navigation.goBack();
    }
  };

  listDirectoryDescription = () => {
    // List the Directory Description of the Provider
    return this.state.provider.directoryDescriptionMarkdown ? (
      <View style={CommonStyles.marginBottom10}>
        <Markdown
          style={{
            text: [
              CommonStyles.referralDescription,
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
            ],
          }}
        >
          {this.state.provider.directoryDescriptionMarkdown}
        </Markdown>
      </View>
    ) : null;
  };

  listDetailDescription = () => {
    // List the Detail Description of the Provider
    return this.state.provider.detailDescriptionMarkdown ? (
      <React.Fragment>
        <StandardText
          style={[
            CommonStyles.font17,
            CommonStyles.marginBottom20,
            CommonStyles.marginTop5,
            BrandStyles.TextColor5,
            CommonStyles.customFontBold,
          ]}
        >
          {types2.About_Provider.WHAT_WE_DO}
        </StandardText>
        <View style={CommonStyles.marginBottom10}>
          <Markdown
            style={{
              text: [
                CommonStyles.referralDescription,
                CommonStyles.SummaryTileText,
                BrandStyles.TextColor5,
              ],
            }}
          >
            {this.state.provider.detailDescriptionMarkdown}
          </Markdown>
        </View>
      </React.Fragment>
    ) : null;
  };

  getMemberAddress = () => {
    let address =
      this.state.provider.streetAddress !== '' &&
      this.state.provider.streetAddress + ', ';
    address +=
      this.state.provider.state !== '' && this.state.provider.state + ', ';
    address +=
      this.state.provider.postCode !== '' && this.state.provider.postCode;
    return address;
  };
  _getParams = (key: any) => {
    if(types.isWeb) {
      return this.props.navigation.getParam(key, undefined) || undefined;
     }
     
    return this.props.route.params? this.props.route.params[key] : undefined;
  };

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        sidebarMode={types2.SIDEBAR_MODE.PLAN_MANAGEMENT}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.ABOUT_PROVIDER}
        onRefresh={this.onRefresh}
        refreshing={this.state.refreshing}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    let imageArr;
    if (!this.state.loading) {
      imageArr =
        this.state.provider.images.length !== 0 &&
        this.state.provider.images[0].imageUrl.split('.');
    }
    return (
      !this.state.loading && (
        <ScrollView style={[CommonStyles.content, BrandStyles.primaryBgColour]}>
          <View style={CommonStyles.referralInfoPadding}>
            <View
              style={[
                CommonStyles.justifyContentFlexStart,
                CommonStyles.alignItemsFlexStart,
              ]}
            >
              {this.state.provider.images.length === 0 ||
              imageArr[4] === types2.XML ? null : (
                <Image
                  style={CommonStyles.referralItemImage}
                  resizeMode={'contain'}
                  source={{uri: this.state.provider.images[0].imageUrl}}
                />
              )}
            </View>
            <StandardText
              style={[
                CommonStyles.font22,
                CommonStyles.marginTop5,
                CommonStyles.customFontBold,
                BrandStyles.TextColor4,
                CommonStyles.marginBottom10,
                CommonStyles.flexWrap,
              ]}
            >
              {this.state.provider.orgName}
            </StandardText>
            {this.listDirectoryDescription()}
            {this.listDetailDescription()}
            {this.state.provider.email ? (
              <AboutProviderInfoItem
                containerStyle={[
                  CommonStyles.flexRow,
                  CommonStyles.flexWrap,
                  CommonStyles.alignItemsCenter,
                ]}
                title={types2.About_Provider.EMAIL}
                content={this.state.provider.email}
              />
            ) : null}
            {this.state.provider.phone ? (
              <AboutProviderInfoItem
                containerStyle={[
                  CommonStyles.flexRow,
                  CommonStyles.flexWrap,
                  CommonStyles.alignItemsCenter,
                ]}
                title={types2.About_Provider.CONTACT}
                content={this.state.provider.phone}
              />
            ) : null}
            {this.state.provider.streetAddress ||
            this.state.provider.state ||
            this.state.provider.postCode ? (
              <AboutProviderInfoItem
                containerStyle={[
                  CommonStyles.flexRow,
                  CommonStyles.flexWrap,
                  CommonStyles.alignItemsCenter,
                ]}
                title={types2.About_Provider.LOCATION}
                content={this.getMemberAddress()}
              />
            ) : null}
          </View>
          {this.state.provider.peopleTypeList.length !== 0 && (
            <View
              style={[
                CommonStyles.referralInfoPadding,
                CommonStyles.rpMarginTop10p,
              ]}
            >
              <AboutProviderInfoItem
                containerStyle={CommonStyles.flexColumn}
                title={types2.About_Provider.WE_SUPPORT}
                content={this.state.provider.peopleTypeList.map(
                  (peopleType: any, index: any) =>
                    index === 0 ? peopleType : ', ' + peopleType,
                )}
              />
            </View>
          )}
          {this.state.provider.disabilityList.length !== 0 && (
            <View style={[CommonStyles.referralInfoPadding]}>
              <AboutProviderInfoItem
                containerStyle={[
                  CommonStyles.flexColumn,
                  CommonStyles.rpMarginTop10p,
                ]}
                title={
                  types2.About_Provider.DIAGNOSES_AND_CONDITIONS_WE_SUPPORT
                }
                content={this.state.provider.disabilityList.map(
                  (disability: any, index: any) =>
                    index === 0 ? disability : ', ' + disability,
                )}
              />
            </View>
          )}
          {this.state.provider.serviceTypeList.length !== 0 && (
            <View style={[CommonStyles.referralInfoPadding]}>
              <AboutProviderInfoItem
                containerStyle={[
                  CommonStyles.flexColumn,
                  CommonStyles.rpMarginTop10p,
                ]}
                title={types2.About_Provider.SERVICE_TYPES}
                content={this.state.provider.serviceTypeList.map(
                  (serviceType: any, index: any) =>
                    index === 0 ? serviceType : ', ' + serviceType,
                )}
              />
            </View>
          )}
          {this.state.provider.isNdisRegistered && (
            <View style={[CommonStyles.referralInfoPadding]}>
              <View
                style={[
                  CommonStyles.flexRow,
                  {justifyContent: 'space-between'},
                ]}
              >
                <AboutProviderInfoItem
                  containerStyle={[
                    CommonStyles.flexColumn,
                    CommonStyles.rpMarginTop10p,
                  ]}
                  title={types2.About_Provider.REGISTERED_NDIS_PROVIDER}
                  content={this.state.provider.ndisNumber}
                />
                <View
                  style={[CommonStyles.rpMarginTop10p, CommonStyles.flexRow]}
                >
                  <Image style={CommonStyles.ndisImage} source={NDISBadge} />
                  <Image
                    style={CommonStyles.ndisBadge}
                    source={MediumRedTickCircleRed3x}
                  />
                </View>
              </View>
            </View>
          )}
          <View style={[CommonStyles.referralEnquireView]}>
            <StandardButton
              style={[
                BrandStyles.brandBlockTxtColor5,
                CommonStyles.buttonFormAction,
                CommonStyles.referralEnquireBtn,
              ]}
              containerStyle={[
                BrandStyles.primaryBgColor4,
                BrandStyles.brandBorderColor1,
                CommonStyles.buttonContainerFormAction,
                CommonStyles.rpMarginTop10p,
              ]}
              onPress={() => {
                this.props.actions.ProviderActions.actionSetSelectedProvider(
                  this.state.provider,
                );
                this.props.navigation.navigate('ProviderEnquiry', {
                  providerid: this.state.provider.providerRecordId,
                });
              }}
              accessibilityLabel={types2.ACCESSIBILITY_LABELS.ENQUIRY}
            >
              {types2.REFERRAL_LIST_ITEM.ENQUIRE}
            </StandardButton>
          </View>
        </ScrollView>
      )
    );
  };
}

// Warn the user if props are not passed
AboutProvider.propTypes = {};

//For the reducer connection
const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  member: state.MemberReducer,
  budget: state.BudgetReducer,
  loadedMemberId: state.LoadedMemberReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
    BudgetActions: bindActionCreators(BudgetActions, dispatch),
    PayInvoiceActions: bindActionCreators(PayInvoiceActions, dispatch),
    MemberActions: bindActionCreators(MemberActions, dispatch),
    ProviderActions: bindActionCreators(ProviderActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutProvider);
