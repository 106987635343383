/*
 * Author: Brad D'Costa
 * Date: 29/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Member Health and Wellbeing information.
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {nullStringReplacer} from '../API/APIHelper';

/* Expected DataModel as per v13 of Swagger Definitions (versioning is based on Confluence versioning)
 *  {
 *      "ongoingHealth": {
 *          "description": "string"
 *      },
 *      "healthConcerns": {
 *          "description": "string",
 *           "difficulty": "string",
 *           "support": "string"
 *      }
 *  }
 */

export default function HealthWellbeingReducer(state = {}, action: any) {
  switch (action.type) {
    case types.GET_HEALTH_WELLBEING:
      var parsedOngoingHealthObj = {
        description: nullStringReplacer(
          action.HealthObj.ongoingHealth.description,
        ),
      };
      var parsedHealthConcernsObj = {
        description: nullStringReplacer(
          action.HealthObj.healthConcerns.description,
        ),
        difficulty: nullStringReplacer(
          action.HealthObj.healthConcerns.difficulty,
        ),
        support: nullStringReplacer(action.HealthObj.healthConcerns.support),
      };
      return {
        ongoingHealth: parsedOngoingHealthObj,
        healthConcerns: parsedHealthConcernsObj,
      };
    case types.UPDATE_HEALTH_WELLBEING_GOING_WELL:
      var newValue = {ongoingHealth: action.goingWellObj.ongoingHealth};
      var updatedState = Object.assign({}, state);
      for (var k in newValue) updatedState[k] = newValue[k];
      return updatedState;
    case types.UPDATE_HEALTH_WELLBEING_CONCERNS:
      var newValue = {healthConcerns: action.concernsObj.healthConcerns};
      var updatedState = Object.assign({}, state);
      for (var k in newValue) updatedState[k] = newValue[k];
      return updatedState;
    default:
      return state;
  }
}
