/*
 * Authour: Andrew Seeley
 * Date: 01/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is the action for goals for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

// Actions related to the whole goal list, this is shown in the goal summary screen
export const actionGetGoals = (goalObj: any) => ({
  type: types.GOALS.GET_GOALS,
  goalObj,
});

export const addGoalToList = (goal: any) => ({
  type: types.GOALS.ADD_GOAL_TO_LIST,
  goalToAdd: goal,
});

export const deleteExistingGoalInList = (goalId: any) => ({
  type: types.GOALS.DELETE_GOAL_FOR_ID,
  goalId,
});

export const setGoalImageInList = (goalId, imageObjectUpload) => ({
  type: types.GOALS.SET_GOAL_IMAGE_LIST_LOCAL,
  goalId,
  imageObjectUpload,
});

export const updateGoalInList = (goal: any) => ({
  type: types.GOALS.UPDATE_GOAL_IN_LIST,
  goal,
});

export const updateGoalStepInList = (step: any) => ({
  type: types.GOALS.UPDATE_GOAL_STEP,
  step,
});

export const updateGoalName = (goal: any) => ({
  type: types.GOALS.UPDATE_GOAL_NAME,
  goal,
});

// Actions related to the current goal the user is adding / editing..

// This will add a new goal, it sets up the reducer with a new goal object
// As per the swagger definition structure. We don't need for edit as the api
// Return will already have the info
export const addNewGoal = (memberId, memberPlanId) => ({
  type: types.GOALS.ADD_NEW_GOAL,

  newGoal: {
    id: memberId,
    planId: memberPlanId,
    goalName: '',
    listPosition: 1, // The default position if the user has no goals. Reducer will update if it needs to change
    currentGoalSteps: [],
  },
});

export const editExistingGoal = (goalObject: any) => ({
  type: types.GOALS.EDIT_EXISTING_GOAL,
  goal: goalObject,
});

export const addGoalStep = (stepName, stepDescription) => ({
  type: types.GOALS.ADD_STEP,
  stepName,
  stepDescription,
});

export const setGoalId = (goalId: any) => ({
  type: types.GOALS.SET_GOAL_ID,
  goalId,
});

export const setGoalImage = (imageObjectUpload: any) => ({
  type: types.GOALS.SET_GOAL_IMAGE,
  imageObjectUpload,
});

export const setGoalName = (goalName: any) => ({
  type: types.GOALS.SET_GOAL_NAME,
  goalName,
});

export const setGoalTemplate = (templateId, templateName) => ({
  type: types.GOALS.SET_GOAL_TEMPLATE,
  templateId,
  templateName,
});

export const editGoalStep = (step: any) => ({
  type: types.GOALS.EDIT_GOAL_STEP,
  step,
});

export const backupGoalReadOnly = () => ({
  type: types.GOALS.BACKUP_GOAL_READONLY,
});

export const cancelEdits = () => ({
  type: types.GOALS.CANCEL_GOAL_EDITS,
});

// Goal template actions
export const getGoalTemplates = (templates: any) => ({
  type: types.GOALS.GET_GOAL_TEMPLATES,
  templates,
});

export const setGoalStepsForTemplate = (templateid, goalSteps) => ({
  type: types.GOALS.SET_GOAL_STEPS_FOR_TEMPLATE,
  templateid,
  goalSteps,
});

// Filter actions
export const getGoalFiltersReference = (referenceObj: any) => ({
  type: types.GOALS.GET_FILTERS_REFERENCE,
  referenceObj,
});

export const setSelectedFilters = (
  referenceData,
  selectedCategories,
  selectedLifeStages,
  selectedFilterNames,
) => ({
  type: types.GOALS.SET_FILTERS_SELECTED,
  referenceData,
  selectedCategories,
  selectedLifeStages,
  selectedFilterNames,
});

export const addGoalStepList = (currentGoalStepList: any) => ({
  type: types.GOALS.SET_STEP_LIST,
  currentGoalStepList,
});

export const getGoalProgress = (goalProgressObj: any) => ({
  type: types.GOALS.GET_GOAL_PROGRESS,
  goalProgressObj,
});

export const addPhotoCaption = (goalId, photoCaption) => ({
  type: types.ADD_GOAL_PHOTO_CAPTION,
  goalId,
  photoCaption,
});
