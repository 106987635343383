/*
 * Author: Brad D'Costa
 * Date: 30/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Summary tile for a goal. Shows the first goal with a max of 5 steps.
 */

import React, {Component} from 'react';
import {View, TouchableHighlight} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import {StandardText} from '../Atoms';
import {SummaryTile, PhotoTile, GridView} from '../Molecules';

import {
  goalsIconCharcoal,
  goalsIconRed,
} from '../../assets/images/vector-icons';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {BrandColors} from '../../Styles/Colours';

import {isTablet} from '../../Helpers/PlatformSynchronizer';
//import DeviceInfo from 'react-native-device-info';
//import Orientation from 'react-native-orientation-enhanced';

import {connect} from 'react-redux';
import * as GoalActions from '../../Actions/GoalActions';

import {bindActionCreators} from 'redux';

import {callAPIs} from '../../API/APICaller';
import {getGoalTemplateReference} from '../../API/ReferenceAPI';
import {setValue, removeItem} from '../../API/WebPersistenceStore';

const GOAL_PREVIEW = 'GoalPreview';
const CURRENT_GOAL_ID = 'currentGoalID';
class MyProfileGoals extends Component {
  constructor(props: any) {
    super(props);
    this._renderGoalSteps.bind(this);
  }

  state = {
    orientation: '',
  };

  render() {
    const itemLength = this.props.reducer.goals
      ? this.props.reducer.goals.length
      : 0;
    let maxLenth = types.DISPLAY_MAX_GOALS_MYPROFILE_TABLET;

    if (!isTablet()) {
      maxLenth = types.DISPLAY_MAX_GOALS_MYPROFILE_PHONE;
    }

    return (
      <SummaryTile
        headerDisplayString="Goals"
        headerIconImage={goalsIconRed}
        screenType={types.SCREEN_TYPE_MAIN}
        modal={this.props.modal}
        containsData={this._determineContainsData()}
        actionOverride={this._determineAccess()}
        displayMore={itemLength > maxLenth}
      >
        {this._renderGoals()}
      </SummaryTile>
    );
  }

  _determineAccess = () => {
    if (Object.getOwnPropertyNames(this.props.readOnly).length === 0) {
      return null;
    } else {
      const readOnly = this.props.readOnly;
      if (readOnly.access) {
        if (readOnly.access.profile) {
          if (this.props.readOnly.access.profile === types.ACCESS_READ_ONLY) {
            return types.ACTION_BUTTON.VIEW;
          }
        }
      }
    }
    return null;
  };

  _determineContainsData = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return false;
    } else {
      const checkArray = [this.props.reducer.goals];
      let dataCheck = false;
      for (let i = 0; i < checkArray.length; i++) {
        if (checkArray[i]) {
          if (Array.isArray(checkArray[i])) {
            // THIS IS SPECIFIC TO THIS COMPONENT TO CHECK LENGTH GREATER THAN 1 (AS THERE IS A DUMMY ENTRY IN THE ARRAY
            // IF ADDING MORE ITEMS TO CHECK ARRARY ABOVE  YOU WILL NEED TO MAKE THIS SMARTER SO IT WILL CHECK LENGTH >0
            // FOR ANY OTHER ARRAYS
            if (checkArray[i].length > 1) {
              dataCheck = true;
              break;
            }
          } else {
            dataCheck = true;
            break;
          }
        }
      }
      return dataCheck;
    }
  };

  _determineIcon = () => {
    if (this._determineContainsData()) {
      return goalsIconRed;
    } else {
      return goalsIconCharcoal;
    }
  };

  _renderGoals() {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      if (this.props.reducer.goals) {
        if (this.props.reducer.goals.length < 2) {
          return null;
        } else {
          const goalsToDisplay = this.props.reducer.goals.slice(
            0,
            isTablet
              ? types.DISPLAY_MAX_GOALS_MYPROFILE_TABLET
              : types.DISPLAY_MAX_GOALS_MYPROFILE_PHONE,
          );
          return (
            <View style={[CommonStyles.justifyCenter]}>
              <GridView
                viewWidth={this.props.viewWidth}
                itemDimension={types2.ITEM_DIMENSION}
                childDimension={true}
                isMobile={this.props.isMobile}
              >
                {this.createGoalsArray(goalsToDisplay)}
              </GridView>
            </View>
          );
        }
      } else {
        return null;
      }
    }
  }

  createGoalsArray(goals: any) {
    return goals.map((goalItem: any) => {
      if (!goalItem.isAdd) {
        return (
          <TouchableHighlight
            onPress={() => this._selectedGoal(goalItem)}
            underlayColor={BrandColors.WHITE}
            style={CommonStyles.marginBottom20}
          >
            <View
              style={[
                CommonStyles.flexDirectionRow,
                types.isWeb ? CommonStyles.alignItemsCenter : null,
              ]}
            >
              <PhotoTile
                style={{width: 75, height: 75}}
                bottomMargin={false}
                apiFileType={types2.FILE_TYPE.GOAL_PHOTO}
                imageObject={this._getGoalPhoto(goalItem)}
                showCaption={false}
                actionCallback={this._selectedGoal}
                actionData={goalItem}
                clickable={true}
                photoBorderRadius={CommonStyles.borderRadiusRound}
                lastUpdate={this._lastUpdateTime(goalItem)}
              />
              <View
                style={[
                  CommonStyles.flex1,
                  CommonStyles.myProfileTileTextContainer,
                ]}
              >
                {this._renderGoalName(goalItem)}
                {this._renderGoalSteps(goalItem)}
              </View>
            </View>
          </TouchableHighlight>
        );
      } else {
        return null;
      }
    });
  }

  _getGoalPhoto = (goalItem: any) => {
    if (
      goalItem.goalPhoto !== undefined &&
      goalItem.imageUploadObject === undefined
    ) {
      return goalItem.goalPhoto;
    } else if (
      goalItem.imageUploadObject &&
      goalItem.imageUploadObject.localImage
    ) {
      return goalItem.imageUploadObject;
    } else {
      return null;
    }
  };

  _lastUpdateTime = (goalItem: any) => {
    if (
      goalItem.goalPhoto !== undefined &&
      goalItem.imageUploadObject === undefined &&
      goalItem.goalPhoto.lastModified
    ) {
      return new Date(goalItem.goalPhoto.lastModified);
    } else {
      return new Date();
    }
  };

  _selectedGoal = (goalItem: any, goalTitle: any) => {
    this.setState({isLoading: true});

    // Make sure we have the goal template data
    const getGoalTemplateReferenceCallback = (data: any) => {
      const payload = data[0];
      this.setState({isLoading: false});
      this.props.actions.GoalActions.getGoalTemplates(
        payload.content.templates,
      );

      // Update redux with the current goal
      this.props.actions.GoalActions.editExistingGoal(goalItem);
      // Navigate to the page
      if (types.isWeb) {
        removeItem(CURRENT_GOAL_ID);
        setValue(CURRENT_GOAL_ID, goalItem.currentGoalId);
      }
      this.props.navigation.navigate(GOAL_PREVIEW, {
        screenMode: types.SCREEN_MODE_EDIT,
        screenTitle: goalTitle,
      });
    };

    if (this.props.goals.goalsTemplates == null) {
      // First time calling the API
      callAPIs(
        [getGoalTemplateReference()],
        getGoalTemplateReferenceCallback,
        null,
        () => {
          this.setState({isLoading: true});
        },
      );
    } else {
      // Update redux with the current goal
      this.props.actions.GoalActions.editExistingGoal(goalItem);
      this.setState({isLoading: false});
      // Navigate to the page
      if (types.isWeb) {
        removeItem(CURRENT_GOAL_ID);
        setValue(CURRENT_GOAL_ID, goalItem.currentGoalId);
      }
      this.props.navigation.navigate(GOAL_PREVIEW, {
        screenMode: types.SCREEN_MODE_EDIT,
        screenTitle: goalTitle,
      });
    }
  };

  _renderGoalName = (goalItem: any) => {
    if (goalItem.goalName) {
      return (
        <StandardText
          style={[
            CommonStyles.SummaryTileText,
            BrandStyles.TextColor5,
            CommonStyles.customFontBold,
          ]}
        >
          {goalItem.goalName}
        </StandardText>
      );
    } else {
      return null;
    }
  };

  _renderGoalSteps = (goalItem: any) => {
    let currentGoalSteps = goalItem.currentGoalSteps;
    if (currentGoalSteps == undefined) {
      return null;
    } else {
      currentGoalSteps = currentGoalSteps.slice(0, 4);
      let counter = 1;
      let displayString = [];
      for (let i = 0; i < currentGoalSteps.length; i++) {
        displayString.push({
          index: counter,
          string: currentGoalSteps[i].stepName,
        });
        counter++;
      }

      return displayString.map(item => (
        <View style={CommonStyles.flexDirectionRow}>
          <View style={CommonStyles.flexDirectionColumn}>
            <StandardText
              style={[
                CommonStyles.SummaryTileText,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
              ]}
            >
              {item.index + '. '}
            </StandardText>
          </View>
          <View style={[CommonStyles.columnContainer]}>
            <StandardText
              style={[
                CommonStyles.SummaryTileText,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
                CommonStyles.justifyContentFlexStart,
              ]}
            >
              {item.string}
            </StandardText>
          </View>
        </View>
      ));
    }
  };
}

const mapStateToProps = (state: any) => ({
  goals: state.GoalReducer,
});
const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    GoalActions: bindActionCreators(GoalActions, dispatch),
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(MyProfileGoals);
