import React from 'react';

import {View, Image, TouchableOpacity} from 'react-native';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import {StandardText} from '../Atoms';

import {ORIENTATION} from '../../Constants/Constants2';

const OptionTile = function (props: any) {
  return (
    <TouchableOpacity onPress={() => props.onPress(props.option)}>
      <View
        style={[
          CommonStyles.borderRadius2,
          props.option.backgroundStyle,
          !props.removePadding && CommonStyles.marginTop10,
          props.option.isHalfHeight
            ? CommonStyles.ParticipantOrCrewButtonHalfHeightContainer
            : CommonStyles.ParticipantOrCrewButtonContainer,
          CommonStyles.flexDirectionRow,
          CommonStyles.width400,
          props.minWidth,
        ]}
      >
        <View
          style={[
            CommonStyles.flexDirectionColumn,
            CommonStyles.justifyCenter,
            CommonStyles.flex1,
            CommonStyles.marginRight20,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFont,
              CommonStyles.fontWeightBold,
              CommonStyles.flexWrap,
              props.option.textStyle,
              props.option.textSize,
            ]}
          >
            {props.option.title}
          </StandardText>
          <StandardText
            style={[
              CommonStyles.customFont,
              props.option.textStyle,
              props.option.subTextSize,
              CommonStyles.SignUpCrewInstructionsMT3,
            ]}
          >
            {props.option.subtitle
              ? props.orientation === ORIENTATION.LANDSCAPE
                ? props.option.subtitle.landscape
                : props.option.subtitle.portrait
              : null}
          </StandardText>
        </View>

        {props.option.image && (
          <View
            style={[
              CommonStyles.alignCenter,
              props.removePadding && CommonStyles.paddingLeft10,
              BrandStyles.brandBlockBgColorWhite,
              CommonStyles.borderRadius10,
              CommonStyles.alignSelfCenter,
              CommonStyles.optionTileImageContainer,
              !props.option.removeImagePadding &&
                CommonStyles.optionTileImagePadding,
              props.option.addImageBorder && CommonStyles.optionTileImageBorder,
              props.option.addImageBorder && props.option.imageBorderColor,
              props.option.isAvatar && CommonStyles.optionTileAvatarContainer,
            ]}
          >
            <Image
              style={[
                props.isProfileImage ? CommonStyles.ModalProfilePicture : null,
                props.isProfileImage ? CommonStyles.margin0 : null,
                props.option.isSmallIcon
                  ? CommonStyles.widthHeight50
                  : CommonStyles.containerPhotoTileMyProfile,
                props.option.isIcon ? null : CommonStyles.paddingTop20,
                props.option.removeImagePadding &&
                  CommonStyles.containerPhotoTileMyProfileNoPadding,
                props.option.isAvatar && CommonStyles.borderRadius10,
              ]}
              source={props.option.image}
              resizeMode={'contain'}
            />
          </View>
        )}
      </View>
    </TouchableOpacity>
  );
};

export default OptionTile;
