/*
 * Author: Josh Merkas
 * Date: 28/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Payments History Screen
 */
import React, {Component} from 'react';

import {
  View,
  Text,
  FlatList,
  StyleSheet,
  ScrollView,
  Image,
} from 'react-native';
//import { DocumentsTopBar } from '../Components/Molecules'

import {RedArrow} from '../../assets/images';
import {StandardText, StandardTouchableOpacity} from '../Atoms';

//Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {BrandColors} from '../../Styles/Colours';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import {formatAsCurrency} from '../../Helpers/Utils';

class SummaryList extends Component {
  state = {};

  constructor(props: any) {
    super(props);
    this._renderValue.bind(this);
    this._onPress.bind(this);
  }

  render() {
    return (
      <View style={[CommonStyles.DocumentListContainer]}>
        <View style={style.flatListContainer}>
          <FlatList
            ListHeaderComponent={this.props.header}
            data={this.props.data}
            extradata={this.props.extraData}
            ItemSeparatorComponent={this._renderSeparator}
            keyExtractor={item => item.invoiceKey}
            renderItem={({item}) => this._renderPaymentListItems(item)}
          />
        </View>
      </View>
    );
  }

  _renderSeparator = () => <View style={style.separator} />;

  _renderPaymentListItems = (item: any) => (
    <StandardTouchableOpacity
      accessibilityLabel={item.invoiceNumber}
      onPress={() => this._onPress(item)}
    >
      <View style={style.itemContainer}>
        <StandardText
          style={[
            style.heading1,
            CommonStyles.font17,
            CommonStyles.customFont,
            BrandStyles.TextColor5,
          ]}
        >
          {this._formatDate(item.invoiceDate)}
        </StandardText>
        <StandardText
          style={[
            style.heading2,
            CommonStyles.font17,
            CommonStyles.customFont,
            BrandStyles.TextColor5,
          ]}
        >
          {item.biller.name}
        </StandardText>
        <StandardText
          style={[
            style.heading5,
            CommonStyles.font17,
            CommonStyles.customFont,
            BrandStyles.TextColor5,
          ]}
        >
          {item.invoiceNumber}
        </StandardText>
        <View style={[style.heading4]}>
          <StandardText
            style={[
              {paddingRight: 10},
              CommonStyles.font17,
              CommonStyles.customFont,
              BrandStyles.TextColor5,
            ]}
          >
            {this._renderValue(item)}
          </StandardText>
          <Image
            style={[CommonStyles.summaryTileButtonIcon]}
            source={RedArrow}
          />
        </View>
      </View>
    </StandardTouchableOpacity>
  );

  _formatDate(date: any) {
    let formatDate = new Date(date);
    let monthDay = formatDate.getDate();
    let month = formatDate.getMonth() + 1;
    let year = formatDate.getFullYear();
    if (monthDay < 10) {
      monthDay = '0' + monthDay;
    }
    if (month < 10) {
      month = '0' + month;
    }

    formatDate = monthDay + '/' + month + '/' + year;
    formatDate = formatDate.toString();
    return formatDate;
  }

  _renderValue = (item: any) => {
    const formattedCurrency = formatAsCurrency(item.total, '$', 2);

    if (this.props.isPositive && item.total < 0) {
      return '-' + formattedCurrency;
    } else if (this.props.isPositive) {
      return formattedCurrency;
    } else {
      return '-' + formattedCurrency;
    }
  };

  _onPress = (item: any) => {
    if (this.props.itemOnPress) {
      this.props.itemOnPress(item);
    }
  };
}

const style = StyleSheet.create({
  separator: {
    height: 1,
    width: '100%',
    backgroundColor: 'black',
  },

  flatListContainer: {
    //  flex: 20,
  },

  itemContainer: {
    flex: 1,
    height: 50,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 30,
    paddingBottom: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  heading1: {
    flex: 1.7,
  },

  heading2: {
    flex: 3,
  },

  heading5: {
    flex: 2,
  },

  heading3: {
    flex: 1,
  },

  heading4: {
    flex: 2.3,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
});

export default SummaryList;
