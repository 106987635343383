/*
 * Author: Declan Hart
 * Date: 09/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Web Service class to handle the Supports API calls
 */

// Custom functions for helping connect to the secure AWS API Gateway
import {
  initaliseAwsClient,
  configuredAWSLamda,
  buildLambda,
  parseResult,
  apiErrorHandler,
} from './APIHelper';
import AWS from 'aws-sdk';
import {AWS_LAMBDA_SERVICES} from '../environments';
import logger from 'helpers/Logger';

//* ********************************
//*      NotificationsData
//* ********************************

/*
 * Returns the getNotifications for a given personId/UserID
 * @async
 * @param {string} personId The Id of the user
 * @returns {Promise<object>} The living arrangements object
 */
export const getNotifications = (
  personId: any,
  pageNumber = null,
  pageSize = null,
  autoHandleError = true,
  isDemoProfile = false,
) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getNotificationsById = configuredAWSLamda();
      const requestBody = {
        id: personId,
        pageNumber: pageNumber,
        pageSize: pageSize,
        isDemoProfile,
      };
      const functionName = 'getNotifications';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.NOTIFICATION;
      const params = buildLambda(requestBody, functionName, serviceName);
      getNotificationsById.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });
  return sendRequest;
};

export const updateNotifications = (
  personId: any,
  notification: any,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getNotificationsById = configuredAWSLamda();
      const requestBody = {id: personId, notification};
      const functionName = 'putNotificationStatus';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.NOTIFICATION;
      const params = buildLambda(requestBody, functionName, serviceName);
      getNotificationsById.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });
  return sendRequest;
};
