/*
 * Authour: Andrew Seeley
 * Date: 01/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Goals information that has been obtained.
 *
 * Reducer Structure
 * {
 *   goals: [Array of goals that matches swagger]
 *   apiCalled: Date get goals api was last called
 *   goalsTemplates: {
 *       templates: Stored the templates from the api & their goal steps if loaded
 *       filterdTemplates: A filtered list of templates
 *       apiCalled: Date get goal tempaltes reference api was called
 *   }
 *
 *   currentGoal: {
 *       id: The member id of the person creating the goal
 *       planId: The plan id the goal is being created against
 *       currentGoalId: The ID of the current goal when editing or saved
 *       goalName: The name of the current goal
 *       templateId: The template ID of the current goal (if the user has chosen one)
 *       listPosition: The list position to put the current goal into
 *       currentGoalSteps: [The steps of the current goal
 *    }
 * }
 *
 *
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {nullStringReplacer} from '../API/APIHelper';
import {orderBy} from 'lodash';

export default function GoalReducer(state = {}, action: any) {
  switch (action.type) {
    case types.GOALS.GET_GOALS:
      // We need to add a 'dummy' goal at the end with isAdd to true - this will allow the UI to have a tile to add a goal.
      let newGoals = action.goalObj;
      // iterate over goals returned from API and make sure there is a goalName key and a currentGoalsSteps key
      // if not initialise goalName as empty string and currentGoalsSteps as empty array
      for (let i = 0; i < action.goalObj.length; i++) {
        const currentGoalObj = action.goalObj[i];
        currentGoalObj.goalName = nullStringReplacer(currentGoalObj.goalName);
        if (!currentGoalObj.currentGoalSteps) {
          currentGoalObj.currentGoalSteps = [];
        } else {
          const goalStepsArr = currentGoalObj.currentGoalSteps;
          // iterate over goalSteps returned from API and make sure there is a stepName key and a stepDescription key
          // if not initialise missing keys as empty string
          for (let j = 0; j < goalStepsArr.length; j++) {
            const currentStepsObj = goalStepsArr[j];
            currentStepsObj.stepName = nullStringReplacer(
              currentStepsObj.stepName,
            );
            currentStepsObj.stepDescription = nullStringReplacer(
              currentStepsObj.stepDescription,
            );
          }
        }
      }

      newGoals.push({
        currentGoalId: 'newGoal',
        isAdd: true,
        listPosition: Number.MAX_SAFE_INTEGER,
      });
      // Sort the new goals array on listPosition, ascending.
      newGoals.sort((a: any, b: any) => a.listPosition > b.listPosition);

      // Also sort all the goal steps in a goal based on the listPosition, ascending
      for (const i in newGoals) {
        if (newGoals[i].currentGoalSteps) {
          // Make sure goal steps exist
          newGoals[i].currentGoalSteps.sort(
            (a: any, b: any) => a.listPosition > b.listPosition,
          );
        }
      }

      return {
        ...state,
        goals: action.goalObj,
        apiCalled: new Date(),
      };
    case types.GOALS.ADD_GOAL_TO_LIST:
      // Append the new goal to the goals we have..
      let goalsList = state.goals;

      // Init the array if we have nothing.
      if (goalsList === undefined) {
        goalsList = [];
      }

      // Add the goal as the second last item in the array
      // As the last item is always the dummy goal to build
      // Tap here to add another goal
      goalsList.splice(goalsList.length - 1, 0, action.goalToAdd);

      return {
        ...state,
        goals: goalsList,
      };

    case types.GOALS.GET_GOAL_TEMPLATES:
      return {
        ...state,
        goalsTemplates: {
          templates: action.templates,
          filteredTemplates: JSON.parse(JSON.stringify(action.templates)),
          apiCalled: new Date(),
        },
      };
    case types.GOALS.ADD_NEW_GOAL:{
    // We need to get the current goals list - so we can set the listposition of the goal we
      // Are adding to the end
      let goalsList = state.goals;

      // The default position is set in the Action as 1 - this is if the user
      // Has no goals added
      // Otherwise set it to be the last item
      if (goalsList.length > 1) {
        action.newGoal.listPosition = goalsList.length;
      }

      return {
        ...state,
        currentGoal: action.newGoal,
        backupCurrentGoal: JSON.parse(JSON.stringify(action.newGoal)),
      };
    }
    case types.GOALS.UPDATE_GOAL_IN_LIST:{
      // Update a goal in the goals list
     let goalsList = JSON.parse(JSON.stringify(state.goals));
      for (const i in goalsList) {
        if (goalsList[i].currentGoalId == action.goal.currentGoalId) {
          goalsList[i] = JSON.parse(JSON.stringify(action.goal));
        }
      }

      return {
        ...state,
        goals: goalsList,
      };
    }
    case types.GOALS.SET_GOAL_IMAGE:
      return {
        ...state,
        currentGoal: {
          ...state.currentGoal,
          imageUploadObject: action.imageObjectUpload,
          goalPhoto: action.imageObjectUpload,
        },
      };
    case types.GOALS.UPDATE_GOAL_STEP: {
      let newCurrentGoal = JSON.parse(JSON.stringify(state.currentGoal));

      // Find the step in the current goal
      for (let i = 0; i < newCurrentGoal.currentGoalSteps.length; i++) {
        if (
          newCurrentGoal.currentGoalSteps[i].currentGoalStepId ==
          action.step.currentGoalStepId
        ) {
          newCurrentGoal.currentGoalSteps[i] = action.step; // Update the step in redux
        }
      }

      return {
        ...state,
        currentGoal: newCurrentGoal,
      };
    }
    case types.GOALS.UPDATE_GOAL_NAME: {
      let newCurrentGoal = state.currentGoal;
      newCurrentGoal.goalName = action.goal.goalName;

      return {
        ...state,
        currentGoal: newCurrentGoal,
      };
    }
    case types.GOALS.EDIT_EXISTING_GOAL: {
      return {
        ...state,
        currentGoal: JSON.parse(JSON.stringify(action.goal)),
        backupCurrentGoal: JSON.parse(JSON.stringify(action.goal)),
      };
    }
    case types.GOALS.SET_GOAL_ID: {
      return {
        ...state,
        currentGoal: {
          ...state.currentGoal,
          currentGoalId: action.goalId,
        },
      };
    }
    case types.GOALS.SET_GOAL_IMAGE_LIST_LOCAL:
      // Find the goal from the goalId & set the image
      goalsList = state.goals;
      for (const i in goalsList) {
        if (goalsList[i].currentGoalId == action.goalId) {
          goalsList[i].goalPhoto = action.imageObjectUpload;
        }
      }

      return {
        ...state,
        goals: goalsList,
      };
    case types.GOALS.DELETE_GOAL_FOR_ID:
      goalsList = state.goals;
      for (const i in goalsList) {
        if (goalsList[i].currentGoalId == action.goalId) {
          goalsList.splice(i, 1);
          break;
        }
      }

      return {
        ...state,
        goals: goalsList,
      };
    case types.GOALS.EDIT_GOAL:
      return {
        ...state,
        currentGoal: JSON.parse(JSON.stringify(action.existingGoalObj)),
        backupCurrentGoal: JSON.parse(JSON.stringify(action.existingGoalObj)),
      };
    case types.GOALS.SET_GOAL_NAME: {
      return {
        ...state,
        currentGoal: {
          ...state.currentGoal,
          goalName: action.goalName,
        },
      };
    }
    case types.GOALS.SET_GOAL_TEMPLATE: {
      return {
        ...state,
        currentGoal: {
          ...state.currentGoal,
          templateId: action.templateId,
          templateName: action.templateName,
        },
      };
    }
    case types.GOALS.EDIT_GOAL_STEP: {
      return {
        ...state,
        currentGoalStep: action.step,
      };
    }
    case types.GOALS.BACKUP_GOAL_READONLY: {
      // Get the current goal & back it up incase the user makes edits,
      // Then decides to cancel the deits
      return {
        ...state,
        backupCurrentGoal: JSON.parse(JSON.stringify(state.currentGoal)),
      };
    }
    case types.GOALS.CANCEL_GOAL_EDITS: {
      return {
        ...state,
        currentGoal: JSON.parse(JSON.stringify(state.backupCurrentGoal)),
      };
    }
    case types.GOALS.ADD_STEP: {
      // Get the latest goal step to set & append.
      let updatedCurrentGoalSteps = state.currentGoal.currentGoalSteps;
      // Set the goal position to be the next item in the array
      let goalPosition = updatedCurrentGoalSteps.length + 1;

      // Setup the new goal structure to match swagger API
      let newStep = {
        currentGoalStepId: goalPosition,
        stepName: action.stepName,
        stepDescription: action.stepDescription,
        listPosition: goalPosition,
      };

      // Add the goal to the goals array
      updatedCurrentGoalSteps.push(newStep);

      // UpdcurrentGoalStepsate redux
      return {
        ...state,
        currentGoal: {
          ...state.currentGoal,
          currentGoalSteps: updatedCurrentGoalSteps,
        },
      };
    }
    case types.GOALS.SET_GOAL_STEPS_FOR_TEMPLATE: {
      let updatedTemplates = state.goalsTemplates.templates;

      for (const i in updatedTemplates) {
        if (updatedTemplates[i].id == action.templateid) {
          // Add the goal steps onto it
          updatedTemplates[i].goalSteps = action.goalSteps;
        }
      }

      return {
        ...state,
        goalsTemplates: {
          ...state.goalsTemplates,
          templates: updatedTemplates,
        },
      };
    }
    case types.GOALS.GET_FILTERS_REFERENCE: {
      // Store the goal filter options
      return {
        ...state,
        goalsFilters: {
          ...state.goalsFilters,
          referenceData: action.referenceObj,
          apiCalled: new Date(),
          selectedCategories: [],
          selectedLifeStages: [],
          selectedFilterNames: [],
        },
      };
    }
    case types.GOALS.SET_FILTERS_SELECTED: {
      // Go through each goal template item & see if it matches our filters.
      let filterData = state.goalsTemplates.templates.filter(
        (template: any) => {
          // Assume we have found the item initally - in the case the user has selected no filters
          // Everything will return
          let foundInCategories = true;
          let foundInLifeStages = true;

          // If the user has selected categories
          if (action.selectedCategories.length > 0) {
            // Return true if our selected categories id's array contains anything
            // From the current template array of category id's
            foundInCategories = action.selectedCategories.some((r: any) =>
              template.categories.includes(r),
            );
          }
          // If the user has selected life stages
          if (action.selectedLifeStages.length > 0) {
            // Return true if our selected lifestage id's array contains anything
            // From the current template array of lifestage id's
            foundInLifeStages = action.selectedLifeStages.some((r: any) =>
              template.lifeStages.includes(r),
            );
          }

          // Return true if the template matches, otherwise false if the filter's don't match
          return foundInCategories && foundInLifeStages;
        },
      );

      return {
        ...state,
        goalsTemplates: {
          ...state.goalsTemplates,
          filteredTemplates: filterData,
        },
        goalsFilters: {
          ...state.goalsFilters,
          referenceData: action.referenceData,
          selectedCategories: action.selectedCategories,
          selectedLifeStages: action.selectedLifeStages,
          selectedFilterNames: action.selectedFilterNames,
        },
      };
    }
    case types.GOALS.SET_STEP_LIST: {
      return {
        ...state,
        currentGoal: {
          ...state.currentGoal,
          currentGoalSteps: action.currentGoalStepList,
        },
      };
    }
    case types.GOALS.GET_GOAL_PROGRESS: {
      const goalProgressArray = orderBy(
        action.goalProgressObj,
        ['createdDate'],
        ['desc'],
      );
      return {
        ...state,
        currenGoalProgress: goalProgressArray,
      };
    }

    case types.ADD_GOAL_PHOTO_CAPTION:
      const goals = state.goals;
      for (const i in goals) {
        if (goals[i].currentGoalId === action.goalId) {
          goals[i].photoCaption = action.photoCaption;
        }
      }
      return {
        ...state,
        goals: goals,
      };

    default:
      return state;
  }
}
