/*
 * Author: Mayuran
 * Date: 18/02/2019
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const setValue = function (key: any, value: any) {
  if (types.isWeb) {
    localStorage.setItem(key, value);
  }
};

export const getValue = function (key: any) {
  if (types.isWeb) {
    return localStorage.getItem(key);
  }
};

export const removeItem = function (key: any) {
  if (types.isWeb) {
    localStorage.removeItem(key);
  }
};
