import React, {Component} from 'react';
import {
  AppRegistry,
  StyleSheet,
  FlatList,
  Text,
  View,
  Alert,
  Platform,
  Image,
  TouchableOpacity,
} from 'react-native';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import {StandardTouchableOpacity, StandardText} from '../Atoms';
import {GridView} from '../Molecules';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import * as ImageCache from '../../API/ImageCache';
// Import the connect as redux is required for member details
import {connect} from 'react-redux';
import {BrandActivityIndicator} from '../Molecules';
import {getValue, setValue, removeItem} from '../../API/WebPersistenceStore';
import {bindActionCreators} from 'redux';
import * as AvatarListActions from '../../Actions/AvatarListActions';

class AvatarGrid extends Component {
  state = {
    viewWidth: '',
    imageUrls: [],
  };

  UNSAFE_componentWillMount() {
    if (
      types.isWeb &&
      getValue(types2.WEB_STORE.AVATAR_LISTOBJ) &&
      this.props.avatars.avatarlistObj === undefined
    ) {
      this.props.actions.AvatarListActions.actionGetAvatarList(
        JSON.parse(getValue(types2.WEB_STORE.AVATAR_LISTOBJ)),
      );
    }
    this.props.isLoading(true);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    let tempUrls: any = [];
    if (prevProps.avatars.avatarlistObj !== this.props.avatars.avatarlistObj) {
      if (types.isWeb) {
        return this.props.avatars.avatarlistObj.map((item: any) => {
          const mapping = {item: item, localUrl: item.avatarImageUrl};
          let newUrls = tempUrls.slice();
          newUrls.push(mapping);
          tempUrls = newUrls;

          if (this.props.avatars.avatarlistObj.length === tempUrls.length) {
            this.setState({
              imageUrls: newUrls,
            });
            this.props.isLoading(false);
          }
        });
      }

      // If native app - return with image cache
      return this.props.avatars.avatarlistObj.map((item: any) => {
        ImageCache.getAvatarImage(item).then(cachedUrl => {
          const mapping = {item: item, localUrl: cachedUrl};
          let newUrls = tempUrls.slice();
          newUrls.push(mapping);
          tempUrls = newUrls;

          if (this.props.avatars.avatarlistObj.length === tempUrls.length) {
            this.setState({
              imageUrls: newUrls,
            });
            this.props.isLoading(false);
          }
        });
      });
    }
  }

  setAvatarImage(item: any) {
    this.props.setProfileImage(item);
    this.props.getBase64Image(item);
  }

  _renderAvatars = () => {
    return this.state.imageUrls.map(mapping => {
      return (
        <StandardTouchableOpacity
          accessibilityLabel={`Select Profile Photo${
            mapping.item.key.split('/')[1].split('.')[0]
          }`}
          onPress={this.setAvatarImage.bind(this, mapping.item)}
          style={CommonStyles.AvatarGridBlockStyle}
        >
          <Image
            style={[
              CommonStyles.content,
              CommonStyles.justifyCenter,
              CommonStyles.avatarBorderRadius20,
            ]}
            source={{uri: mapping.item.avatarImageUrl}}
          />
        </StandardTouchableOpacity>
      );
    });
  };

  find_dimesions(layout: any) {
    const {x, y, width, height} = layout;
    this.setState({
      viewWidth: width,
    });
  }

  render() {
    return (
      <View
        style={[CommonStyles.content, CommonStyles.justifyCenter]}
        onLayout={event => {
          this.find_dimesions(event.nativeEvent.layout);
        }}
      >
        <GridView
          viewWidth={this.props.viewWidth}
          itemDimension={160}
          isMobile={this.props.isMobile}
          maximumColumns={
            types.isWeb
              ? null
              : this.state.viewWidth < types2.MEDIA_QUERY.MEDIUM
              ? types2.THREE_COLUMN
              : types2.FOUR_COLUMN
          }
          isStatic={true}
        >
          {this._renderAvatars()}
        </GridView>
      </View>
    );
  }
}

const mapStateToProps = (state: any) => ({
  avatars: state.AvatarReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    AvatarListActions: bindActionCreators(AvatarListActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AvatarGrid);
