/*
 * Author: Anuja Ulpathakumbura
 * Date: 19/10/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Popup Modal Component to view service agreement attachments
 * TODO: opening/ downloading a document from the popup not working yet.
 * This modal should be used with SA approval details as well according to the requirement WTMP-4156
 */

import React from 'react';
import {View, Image} from 'react-native';

import {StandardText, StandardTouchableOpacity} from '../Atoms';
import {BasicOverlayPopupModal} from '../Molecules';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {EyeGray4, downloadIconCharcoal60} from '../../assets/images';
// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {isTablet} from '../../Helpers/PlatformSynchronizer';

const ServiceAgreementAttachmentsPopup = function (props: any) {
  return (
    <BasicOverlayPopupModal
      visible={props.attachmentVisible}
      style={_determinePopUpStyle(isTablet)}
      backdropOpacity={0.2}
      divider={false}
      close={props.close}
      cancelContainerStyle={CommonStyles.popUpOkButton}
      cancelStyle={CommonStyles.buttonPopupOk}
      cancelLabel={types2.CLOSE}
    >
      <View>
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.scrollView_subContentCenter,
            CommonStyles.paddingBottom20,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFontSemiBold,
              CommonStyles.font30,
              BrandStyles.TextColor10,
            ]}
          >
            {types2.ATTACHMENTS}
          </StandardText>
        </View>
        <View style={[CommonStyles.attachmentContainer]}>
          {props.attachments
            ? _renderAttachmentsList(props.attachments, props._openDocument)
            : null}
        </View>
      </View>
      <View style={[CommonStyles.containerInstructionDevider]} />
    </BasicOverlayPopupModal>
  );
};

const _determinePopUpStyle = (isTablet: any) => {
  let styles = [
    CommonStyles.containerRoundCorners,
    CommonStyles.alignSelfCenter,
  ];
  if (isTablet) {
    styles.push([
      CommonStyles.containerInstructionPopUpTablet,
      CommonStyles.height500,
      _determineModalHeight(),
    ]);
  } else {
    styles.push(
      CommonStyles.containerInstructionPopUpMobile,
      CommonStyles.height400,
      _determineModalHeight(),
    );
  }
  return styles;
};

const _determineModalHeight = () => {
  if (isTablet) {
    return CommonStyles.height450;
  } else {
    return CommonStyles.height360;
  }
};

/**
 * This method will render each attachment and view/ download button within modal
 */
const _renderAttachmentsList = (attachments: any, _openDocument: any) => {
  let attachmentList: any = [];
  attachments
    .filter(function (attachment: any) {
      return attachment !== null;
    })
    .forEach(function (attachment: any) {
      let attach = (
        <View style={[CommonStyles.marginBottom20]}>
          <View
            style={[
              CommonStyles.flex1,
              CommonStyles.flexRow,
              CommonStyles.wrapTileContainer,
            ]}
          >
            <View
              style={[
                CommonStyles.padding10,
                isTablet ? CommonStyles.flex04 : null,
              ]}
            >
              <StandardTouchableOpacity
                accessible
                activeOpacity={0.6}
                onPress={() => {
                  _openDocument(attachment, true);
                }}
              >
                <StandardText
                  style={[
                    CommonStyles.underlineText,
                    CommonStyles.font20,
                    CommonStyles.customFont,
                  ]}
                >
                  {attachment.name}
                </StandardText>
              </StandardTouchableOpacity>
            </View>
            <View
              style={[
                isTablet ? CommonStyles.flex06 : null,
                CommonStyles.flexRow,
                CommonStyles.justifyContentFlexEnd,
              ]}
            >
              <View>
                <StandardTouchableOpacity
                  accessible
                  activeOpacity={0.6}
                  onPress={() => {
                    _openDocument(attachment, true);
                  }}
                >
                  <View
                    style={[
                      CommonStyles.justifyFlexRowStart,
                      CommonStyles.alignItemsCenter,
                      CommonStyles.marginTop10,
                    ]}
                  >
                    <StandardText
                      style={[
                        CommonStyles.title_ListContainer,
                        CommonStyles.customFontSemiBold,
                        BrandStyles.TextColor10,
                      ]}
                    >
                      {types2.DOWNLOAD}
                    </StandardText>
                    <Image
                      style={[
                        CommonStyles.downloadAttachments,
                        CommonStyles.marginLeft5,
                      ]}
                      source={downloadIconCharcoal60}
                    />
                  </View>
                </StandardTouchableOpacity>
              </View>
              <View style={[CommonStyles.marginLeft30]}>
                <StandardTouchableOpacity
                  accessible
                  activeOpacity={0.6}
                  onPress={() => {
                    _openDocument(attachment, false);
                  }}
                >
                  <View
                    style={[
                      CommonStyles.justifyFlexRowStart,
                      CommonStyles.alignItemsCenter,
                      CommonStyles.marginTop10,
                    ]}
                  >
                    <StandardText
                      style={[
                        CommonStyles.title_ListContainer,
                        CommonStyles.customFontSemiBold,
                        BrandStyles.TextColor10,
                      ]}
                    >
                      {types2.VIEW}
                    </StandardText>
                    <Image
                      style={[CommonStyles.viewAttachments]}
                      source={EyeGray4}
                    />
                  </View>
                </StandardTouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      );

      attachmentList.push(attach);
    });

  return attachmentList;
};

export default ServiceAgreementAttachmentsPopup;
