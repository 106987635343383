/*
 * Author: Andrew Seeley
 * Date: 20/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Screen that holds sign up options
 */

import React, {Component} from 'react';
import {
  Platform,
  StyleSheet,
  View,
  Animated,
  Easing,
  Image,
  Dimensions,
} from 'react-native';
import FitImage from 'react-native-fit-image';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {BrandIcons} from '../Styles/BrandIcons';

import * as UserActions from '../Actions/UserActions';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import * as brand from '../Constants/BrandConstants';

import {OfflineNotice} from '../Components/Organisms';

import AuthHero from '../assets/images/iStock-585800040.jpg';
import {StandardButton, StandardText} from '../Components/Atoms';

import {MESSAGES as Messages} from '../Constants/Messages';

// Get the device height & width
const ScreenHeight = Dimensions.get('window').height;
const ScreenWidth = Dimensions.get('window').width;

// Setup animations
const animationValue = new Animated.Value(0);

// Maps the animation values to start from the top of the screen out of view and finish at the bottom of the screen in view
const animateTopToBottom = animationValue.interpolate({
  inputRange: [0, 1],
  outputRange: [-ScreenHeight, 0.0],
});
// Maps the animation values to start from the bottom of the screen out of view and finish at the top of the screen in view
const animateBottomToTop = animationValue.interpolate({
  inputRange: [0, 1],
  outputRange: [ScreenHeight, 0.0],
});

const styles = StyleSheet.create({
  // Animations for the class
  heroBanner: {
    transform: [{translateY: animateTopToBottom}],
  },
  heroImage: {
    height: ScreenHeight,
    width: ScreenWidth * 0.34,
  },
  /* Overlay View - sits on top of the whole view as RN Android cannot overflow over flexboxes
                This enables the Leap In text to start on top */
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
    transform: [{translateY: animateBottomToTop}],
  },
  // This is the right hand side content background.
  // It ensures the colour is set and animates from the bottom to the topz
  backgroundContent: {
    flex: 66,
    transform: [{translateY: animateBottomToTop}],
    backgroundColor: '#e32625',
  },

  // CSS that is only used in this class
  title1: {
    marginTop: 20,
    marginBottom: 20,
    marginRight: 40,
  },
  yesExistingAccountButton: {
    marginRight: 120,
    marginTop: 25,
    elevation: 20,
  },
  orText: {
    elevation: 20,
    // Pad out the or text a bit more
    paddingLeft: 20,
    paddingRight: 20,
  },
  noNewAccountButton: {
    elevation: 20,
    marginLeft: 120,
  },
  pleaseChooseOption: {
    position: 'absolute',
    bottom: 5,
    marginRight: 15,
    right: 25,
    fontSize: 30,
  },
  helpText: {
    fontSize: 21,
  },
  helpTextyesExistingAccount: {
    marginRight: 120,
    marginTop: 0,
  },
  helpTextnoNewAccountButton: {
    marginLeft: 200,
    marginTop: 20,
  },
});

class SignUpOptionsAccountType extends Component {
  constructor(props: any) {
    super(props);
    this.renderBackButton = this.renderBackButton.bind(this);
  }

  render() {
    Animated.timing(animationValue, {
      toValue: 1,
      duration: 3000,
    }).start();

    return (
      <View style={[CommonStyles.screenWrapperContainer]}>
        <View
          style={[CommonStyles.content]}
          contentContainerStyle={[
            CommonStyles.alignItemsCenter,
            CommonStyles.justifyContentCentre,
            CommonStyles.scrollViewVerticalCentre,
          ]}
        >
          {/* Overlay View - sits on top of the whole view as RN Android cannot overflow over flexboxes
                This enables the Leap In  text to start on top */}
          <OfflineNotice />
          <Animated.View style={styles.overlay}>
            <View style={CommonStyles.SignUpOptionsAccountTypeFFDJCAI}>
              <View
                style={[
                  CommonStyles.authenticationWhiteBlock,
                  styles.title1,
                  CommonStyles.alignFlexEnd,
                ]}
              >
                <StandardText
                  style={[
                    CommonStyles.customFontBold,
                    CommonStyles.authenticationWhiteBlockTextOne,
                    BrandStyles.SignUpOptionsTextColor,
                  ]}
                >
                  Does the person have a {brand.LEAP_IN_COMPANY_NAME} account?
                </StandardText>
              </View>

              <StandardText
                style={[
                  CommonStyles.customFont,
                  BrandStyles.TextColor1,
                  styles.helpText,
                  styles.helpTextyesExistingAccount,
                ]}
              >
                I would like to help!
              </StandardText>

              <StandardButton
                style={[
                  CommonStyles.authenticationTransparentBlockText,
                  CommonStyles.customFontBold,
                  CommonStyles.fontWeight400,
                  BrandStyles.TextColor1,
                ]}
                containerStyle={[
                  CommonStyles.authenticationTransparentBlock,
                  BrandStyles.SignUpOptionsBackgroundColor,
                  styles.yesExistingAccountButton,
                  CommonStyles.viewWhiteBorder,
                ]}
                onPress={() => this.navigateToNext(false)}
              >
                Yes
              </StandardButton>

              <View
                style={[CommonStyles.authenticationWhiteBlock, styles.orText]}
              >
                <StandardText
                  style={[
                    CommonStyles.customFontBold,
                    CommonStyles.authenticationWhiteBlockTextOne,
                    BrandStyles.SignUpOptionsTextColor,
                  ]}
                >
                  or
                </StandardText>
              </View>

              <StandardButton
                style={[
                  CommonStyles.authenticationTransparentBlockText,
                  CommonStyles.customFontBold,
                  CommonStyles.fontWeight400,
                  BrandStyles.TextColor1,
                ]}
                containerStyle={[
                  CommonStyles.authenticationTransparentBlock,
                  BrandStyles.SignUpOptionsBackgroundColor,
                  styles.noNewAccountButton,
                  CommonStyles.viewWhiteBorder,
                ]}
                onPress={() => this.navigateToNext(true)}
              >
                No
              </StandardButton>

              <StandardText
                style={[
                  CommonStyles.customFont,
                  BrandStyles.TextColor1,
                  styles.helpText,
                  styles.helpTextnoNewAccountButton,
                ]}
              >
                I would like to create an account with/for the participant
              </StandardText>

              <StandardText
                style={[
                  CommonStyles.customFontMedium,
                  BrandStyles.TextColor1,
                  styles.pleaseChooseOption,
                  CommonStyles.Transparent,
                ]}
              >
                {' '}
                Please choose an option{' '}
              </StandardText>
            </View>
            <View>
              {Platform.OS === types2.IOS ? this.renderBackButton() : null}
            </View>
          </Animated.View>
          {/* Underlying View */}
          <View style={[CommonStyles.flexRow]}>
            {/* The hero image that comes in from the top */}
            <View style={CommonStyles.SignUpOptionsF34}>
              <Animated.View style={styles.heroBanner}>
                <FitImage style={styles.heroImage} source={AuthHero} />
                <Image
                  style={CommonStyles.liLogoCorner}
                  source={BrandIcons.LOGO_ICON}
                />
              </Animated.View>
            </View>
            {/* The content view - this sets it's background colour and animation to come in from the bottom */}
            <Animated.View style={styles.backgroundContent} />
          </View>
        </View>
      </View>
    );
  }

  navigateToNext(newAccount: any) {
    if (newAccount) {
      // TODO - THIS SCREEN WILL BE DIFFERENT - IS IN A FUTURE SPRINT
      this.props.navigation.navigate('LoginSignUp', {
        screenMode: types.LOGINSIGNUP_MODE_REQUESTACCESS,
      });
    } else {
      this.props.navigation.navigate('LoginSignUp', {
        screenMode: types.LOGINSIGNUP_MODE_REQUESTACCESS,
      });
    }
  }

  renderBackButton() {
    return (
      <StandardButton
        style={[
          CommonStyles.textAlignCentre,
          CommonStyles.font37,
          CommonStyles.customFont,
          CommonStyles.buttonText,
          BrandStyles.TextColor1,
          {padding: 10},
        ]}
        containerStyle={[
          BrandStyles.LoginSignUpBackgroundColor,
          CommonStyles.elevation5,
          BrandStyles.borderColor1,
          {
            borderWidth: 4,
            marginTop: 10,
            width: 100,
            marginLeft: 100,
          },
        ]}
        onPress={() => this.props.navigation.goBack()}
      >
        {Messages.GO_BACK.CANCEL}
      </StandardButton>
    );
  }
}

const mapStateToProps = (state: any) => ({
  profile: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(UserActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpOptionsAccountType);
