import React, {Component} from 'react';
import {View} from 'react-native';
import {StandardTouchableOpacity, StandardText} from './';
import CustomIcon from '../../assets/images/CustomIcon';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class WebReturnButton extends Component {
  state = {};

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <StandardTouchableOpacity
        activeOpacity={0.8}
        onPress={this.props.onPress}
        accessibilityLabel={this.props.accessibilityLabel}
        style={[CommonStyles.loginTileWrapper]}
      >
        <View style={[CommonStyles.flexDirectionRow, CommonStyles.flexWrap]}>
          <CustomIcon
            style={[CommonStyles.headerItemIcon]}
            name={this.props.buttonIcon}
          />
          <View
            style={[
              CommonStyles.paddingLeft10,
              CommonStyles.flexDirectionColumn,
              CommonStyles.alignCenter,
            ]}
          >
            <StandardText
              style={[
                CommonStyles.font18,
                CommonStyles.customFont,
                BrandStyles.TextColor1,
              ]}
            >
              {this.props.buttonLabel}
            </StandardText>
          </View>
        </View>
      </StandardTouchableOpacity>
    );
  }
}

export default WebReturnButton;
