/*
 * Author: Shelan Cooray
 * Date: 05/09/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The first landing page when no supports available
 */

import React, {Component} from 'react';

import {AddSupportOptions} from '../Components/Organisms';
import {Container} from '../Components/Molecules';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import * as SupportsActions from '../Actions/SupportsActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {getValue, removeItem} from '../API/WebPersistenceStore';

class AddSupportsBy extends Component {
  state = {
    isLoading: true,
  };

  constructor(props: any) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    if (types.isWeb && JSON.parse(getValue('supportCrew'))) {
      removeItem('supportCrew');
    }
    this.setState({isLoading: false});
  }

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        loading={this.state.isLoading}
        contents={this._createMainContents}
        needsSidebar={false}
        screenType={types.SCREEN_TYPE_MAIN}
        headerTitle={types2.NAVIGATION_TEXT.SUPPORTS}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    return (
      <AddSupportOptions
        navigation={this.props.navigation}
        readOnly={this.props.member.access.profile}
      />
    );
  };
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  categories: state.SupportCategoriesReducer,
  loadedMemberId: state.LoadedMemberReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSupportsBy);
