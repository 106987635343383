/*
 * Author: Brad D'Costa
 * Date: 05/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the Title, edit button and summary for edit screens.
 */

import React, {Component} from 'react';
import {
  Text,
  View,
  FlatList,
  StyleSheet,
  ScrollView,
  Image,
} from 'react-native';

import {
  SummaryTileHeader,
  SummaryTileCheckboxHeader,
  SummaryTileWebUpload,
} from './';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import {StandardButton, StandardText} from '../Atoms';

class SummaryTile extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    var buttonKey = `button${this.props.headerDisplayString}`;

    var showButton = '';
    var containsData = '';
    var actionOverride = '';
    var checkboxStyle = '';
    var checkBoxReadOnly = '';

    // By default show the button
    if (this.props.showButton == undefined) {
      showButton = true;
    } else {
      showButton = this.props.showButton;
    }

    // By default use containsData false styling
    if (this.props.containsData == undefined) {
      containsData = false;
    } else {
      containsData = this.props.containsData;
    }

    // By default use edit/add functionality
    if (this.props.actionOverride == undefined) {
      actionOverride = false;
    } else {
      actionOverride = this.props.actionOverride;
    }

    // By default use standard SummaryTileHeader
    if (this.props.checkboxStyle == undefined) {
      checkboxStyle = false;
    } else {
      checkboxStyle = this.props.checkboxStyle;
    }

    if (this.props.checkBoxReadOnly == undefined) {
      checkBoxReadOnly = false;
    } else {
      checkBoxReadOnly = this.props.checkBoxReadOnly;
    }

    let ignorePadding;
    if (this.props.ignorePadding == undefined) {
      ignorePadding = false;
    } else {
      ignorePadding = this.props.ignorePadding;
    }

    return (
      <View
        style={[
          ignorePadding
            ? CommonStyles.flexDirectionColumn
            : CommonStyles.SummaryTileContainer,
        ]}
      >
        {this._determineHeaderType(
          showButton,
          containsData,
          actionOverride,
          checkboxStyle,
          checkBoxReadOnly,
          ignorePadding,
        )}
        {this._renderSubContent()}
        {this._renderMoreDots()}
      </View>
    );
  }

  _renderSubContent() {
    if (this.props.children) {
      return (
        <View
          style={[
            CommonStyles.summaryTileText,
            CommonStyles.summaryTileTextContainer,
          ]}
        >
          {this.props.children}
        </View>
      );
    }
  }

  _renderMoreDots() {
    if (this.props.displayMore) {
      return (
        <View style={[CommonStyles.moreDotsContainer]}>
          <StandardButton
            containerStyle={[CommonStyles.moredotsButton]}
            onPress={this.props.modal}
            accessibilityLabel={types2.VIEW_MORE}
          >
            <StandardText style={[CommonStyles.rpfont40]}>...</StandardText>
          </StandardButton>
        </View>
      );
    }
  }

  _determineHeaderType = (
    showButton: any,
    containsData: any,
    actionOverride: any,
    checkboxStyle: any,
    checkBoxReadOnly: any,
    ignorePadding: any,
  ) => {
    if (this.props.isWebFileUpload) {
      return (
        <SummaryTileWebUpload
          key={this.props.headerDisplayString}
          displayString={this.props.headerDisplayString}
          accessibilityLabel={this.props.headerDisplayString}
          imageSource={this.props.headerIconImage}
          containsData={containsData}
          onPress={this.props.modal}
          showButton={showButton}
          showButtonTitle={this.props.showButtonTitle}
          secondLevel={this.props.secondLevel}
          actionType={this._determineActionType(containsData, actionOverride)}
          textColor={this.props.HeaderTextColor}
          fontSize={this.props.fontSize}
          boarderColor={this.props.HeaderBoarderColor}
          hideYellowStar={this.props.hideYellowStar}
          showIconString={this.props.showIconString}
          ignorePadding={ignorePadding}
          hasError={this.props.hasError}
          hideActionButton={this.props.hideActionButton}
          alignItemsCenter={this.props.alignItemsCenter}
          imageIcon={this.props.imageIcon}
          imageActionIcon={this.props.imageActionIcon}
          iconStyle={this.props.iconStyle}
          webFileUpload={this.props.webFileUpload}
          customAddButton={this.props.customAddButton}
        />
      );
    }

    if (checkboxStyle) {
      return (
        <SummaryTileCheckboxHeader
          key={this.props.headerDisplayString}
          displayString={this.props.headerDisplayString}
          accessibilityLabel={this.props.headerDisplayString}
          imageSource={this.props.headerIconImage}
          containsData={containsData}
          onPress={this.props.modal}
          showButton={showButton}
          showButtonTitle={this.props.showButtonTitle}
          secondLevel={this.props.secondLevel}
          actionType={this._determineActionType(containsData, actionOverride)}
          data={this.props.data}
          onChange={this.props.onChange}
          readOnly={checkBoxReadOnly}
        />
      );
    } else {
      return (
        <SummaryTileHeader
          key={this.props.headerDisplayString}
          displayString={this.props.headerDisplayString}
          accessibilityLabel={this.props.headerDisplayString}
          imageSource={this.props.headerIconImage}
          containsData={containsData}
          onPress={this.props.modal}
          showButton={showButton}
          showButtonTitle={this.props.showButtonTitle}
          secondLevel={this.props.secondLevel}
          actionType={this._determineActionType(containsData, actionOverride)}
          textColor={this.props.HeaderTextColor}
          fontSize={this.props.fontSize}
          boarderColor={this.props.HeaderBoarderColor}
          hideYellowStar={this.props.hideYellowStar}
          showIconString={this.props.showIconString}
          ignorePadding={ignorePadding}
          hasError={this.props.hasError}
          hideActionButton={this.props.hideActionButton}
          alignItemsCenter={this.props.alignItemsCenter}
          imageIcon={this.props.imageIcon}
          imageActionIcon={this.props.imageActionIcon}
          iconStyle={this.props.iconStyle}
          customAddButton={this.props.customAddButton}
        />
      );
    }
  };

  _determineActionType = (containsData: any, actionOverride: any) => {
    if (actionOverride) {
      return actionOverride;
    } else {
      if (containsData) {
        return types.ACTION_BUTTON.EDIT;
      } else {
        return types.ACTION_BUTTON.ADD;
      }
    }
  };
}

export default SummaryTile;
