/*
 * Author: Nirodha Perera
 * Date: 17/10/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Svg Path component to draw the selected path
 *
 */

import React, {Component} from 'react';

import {Animated} from 'react-native';
import {Path} from 'react-native-svg';

class SvgPathWrap extends Component {
  render() {
    return <Path {...this.props} />;
  }
}

const AnimatedSvgPath = Animated.createAnimatedComponent(SvgPathWrap);

export default AnimatedSvgPath;
