/*
 * Author: Gayan
 * Date: 09/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Favourite Things Screen, a Favourite Things placeholder screen.
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {StandardInput, StandardText} from '../Components/Atoms';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {MESSAGES} from '../Constants/Messages';

import {BasicForm} from '../Components/Organisms';
import {
  ModalSubtitle,
  ModalTitle,
  Container,
  CheckBoxButton,
  RadioGroup,
  RadioButton,
  FormTitle,
  FormSubtitle,
} from '../Components/Molecules';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {callApiChecker} from '../API/APIHelper';
import {callAPIs} from '../API/APICaller';
import {getFavourites, updateFavourites} from '../API/AboutMeAPI';
import * as FavouriteThingsActions from '../Actions/FavouriteThingsActions';

import * as MemberActions from '../Actions/MemberActions';
import * as GeneralActions from '../Actions/GeneralActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';

import {HomeModificationPurple3x} from '../assets/images/vector-icons';
import { DOMPurify } from 'helpers/dompurify';

class WhereILiveModificationsForm extends Component {
  constructor(props: any) {
    super(props);
    this._save = this._save.bind(this);
  }

  state = {
    modalData: this.props.navigationParams.params
      ? this.props.navigationParams.params.modalData
      : {},
    loading: false,
    modalDataStatus: this._getModalDataStatus(),
    initialFormState: {},
  };

  _getModalDataStatus() {
    if (this.props.navigationParams.params) {
      if (this.props.navigationParams.params.modalDataStatus) {
        return true;
      }

      if (this.props.navigationParams.params.modalData.isModifiedFlag === 0) {
        return true;
      }

      if (this.props.navigationParams.params.modalData.isModifiedFlag === '') {
        return false;
      } else {
        return this.props.navigationParams.params.modalData.isModifiedFlag;
      }
    }
  }

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.HOME_MODIFICATION}
        hasFormContentChanged={
          this.props.navigationParams.params.hasFormContentChanged
        }
        currentScreenName={WhereILiveModificationsForm.name}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => (
    <BasicForm
      headerDisplayString={
        MESSAGES.WHERE_I_LIVE_SCREEN.MODIFICATIONS_TILE_TITLE
      }
      headerIconImage={HomeModificationPurple3x}
      close={this.props.navigationParams.params.close}
      save={this._save}
      disableMore={true}
      showButton={
        this.props.navigationParams.params.readOnly == types.ACCESS_FULL
      }
      readOnly={this.props.navigationParams.params.readOnly}
      hideActionButton={true}
      buttonsPositionTop={true}
      contentWidthFull={true}
    >
      <View>
        <FormTitle
          text={
            MESSAGES.WHERE_I_LIVE_SCREEN.HAS_MODIFICATIONS_MODAL_MODIFIED_TITLE
          }
          containsData={this.state.modalDataStatus}
        />
        <FormSubtitle
          text={[
            MESSAGES.WHERE_I_LIVE_SCREEN.MODIFICATIONS_MODAL_MODIFIED_SUBTITLE,
          ]}
        />
        <View style={{flex: 1, flexDirection: 'row', flexWrap: 'wrap'}}>
          {this._renderModificationsCheckBoxes()}
        </View>
        {this._renderModificationInput()}
      </View>
    </BasicForm>
  );

  _save() {
    this.setState({loading: true});
    this.props.navigationParams.params.save();
  }

  _renderModificationsCheckBoxes = () => {
    if (
      !this.state ||
      !this.state.modalData ||
      !this.state.modalData.isModified
    ) {
      return null;
    } else {
      var renderArray = this.state.modalData.isModified;

      return renderArray.map((isModified: any) => (
        <CheckBoxButton
          readOnly={this.props.navigationParams.params.readOnly}
          key={isModified.description}
          data={isModified}
          circular={true}
          onChange={(checkBoxObj: any) => {
            this._updateModification(checkBoxObj);
          }}
        />
      ));
    }
  };

  _updateModification = (checkBoxObj: any) => {
    //trigger update of homeTypes array
    let containsData = false;
    var isModifiedArray = this.state.modalData.isModified.slice();
    var isModifiedFlag = '';
    for (var i = 0; i < isModifiedArray.length; i++) {
      if (isModifiedArray[i].description != checkBoxObj.description) {
        isModifiedArray[i].selected = false;
      }
    }

    isModifiedArray.forEach(function (item: any) {
      if (item.selected == true) {
        containsData = true;
      }
    });

    //reset flag
    if (isModifiedArray[0].selected == true) {
      isModifiedFlag = true;
    } else if (isModifiedArray[1].selected == true) {
      isModifiedFlag = false;
    } else {
      isModifiedFlag = '';
    }
    this._updateModificationDetails('');
    this.setState({modalDataStatus: containsData});
    this.props.navigationParams.params.updateModalDataStatus(containsData);

    var stateObj = this.state.modalData;
    stateObj.isModified = isModifiedArray.slice();
    stateObj.isModifiedFlag = isModifiedFlag;
    this.setState({modalData: stateObj});
  };

  _renderModificationInput = () => {
    if (this.state == null) {
      return null;
    } else {
      const paramValues = this.props.navigationParams.params.modalData;
      return (
        <View>
          {this.state.modalData.isModifiedFlag === true ||
          this.state.modalData.isModifiedFlag === 1 ? (
            <View>
              <FormTitle
                text={
                  MESSAGES.WHERE_I_LIVE_SCREEN
                    .MODIFICATIONS_MODAL_MODIFIED_HOW_TITLE
                }
                containsData={paramValues ? paramValues.details : null}
              />
              <FormSubtitle
                text={[
                  MESSAGES.WHERE_I_LIVE_SCREEN
                    .MODIFICATIONS_MODAL_MODIFIED_HOW_SUBTITLE,
                ]}
              />
              {this._renderModificationTextInput()}
            </View>
          ) : null}

          {this.state.modalData.isModifiedFlag === false ||
          this.state.modalData.isModifiedFlag === 0 ? (
            <View>
              <FormTitle
                text={
                  MESSAGES.WHERE_I_LIVE_SCREEN
                    .MODIFICATIONS_MODAL_MODIFIED_TITLE
                }
                containsData={paramValues ? paramValues.details : null}
              />
              {this._renderModificationTextInput()}
            </View>
          ) : null}
          {this.state.modalData.isModifiedFlag === ''
            ? this._renderModificationTextInput()
            : null}
        </View>
      );
    }
  };

  _renderModificationTextInput = () => {
    return (
      <StandardInput
        readOnly={this.props.navigationParams.params.readOnly}
        multiline={true}
        value={this.state.modalData.details}
        onChangeText={(text: any) => {
          const sanitizedText = DOMPurify.sanitize(text);

          this._updateModificationDetails(sanitizedText)}}
        underlineColorAndroid="transparent"
        style={[
          CommonStyles.ModalTextInputWrapperAutoHeight,
          BrandStyles.primaryBgColor1,
          CommonStyles.textAlignVerticalTop,
          types.isWeb ? CommonStyles.font15 : {},
        ]}
        blurOnSubmit={false}
      />
    );
  };
  _determineContainsData = () => {
    var checkArray = [this.props.livingArrangement.modification.isModified];
    var dataCheck = false;
    for (var i = 0; i < checkArray.length; i++) {
      if (checkArray[i] !== '') {
        dataCheck = true;
        break;
      }
    }
    this.setState({hasData: dataCheck});
    return dataCheck;
  };

  _updateModificationDetails = (details: any) => {
    var stateObj = this.state.modalData;
    stateObj.details = details;
    this.setState({modalData: stateObj});
  };
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
  general: state.GeneralReducer,
  livingArrangement: state.LivingArrangementReducer,
  navigationParams: state.NavigationParamsReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    MemberActions: bindActionCreators(MemberActions, dispatch),
    GeneralActions: bindActionCreators(GeneralActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WhereILiveModificationsForm);
