/*
 * Author: Sujeban Elankeswaran
 * Date: 27/12/2019
 * Copyright © 2019 Leap in!. All rights reserved.
 *
 * Provider reducer
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function ProviderReducer(
  state = {
    selectedProvider: null,
    providerList: [],
  },
  action: any,
) {
  switch (action.type) {
    case types.SET_PROVIDER_LIST:
      return {
        ...state,
        providerList: action.providerList,
      };
    case types.SET_SELECTED_PROVIDER:
      return {
        ...state,
        selectedProvider: action.selectedProvider,
      };
    default:
      return state;
  }
}
