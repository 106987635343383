/*
 * Author: Shelan Cooray
 * Date: 24/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This molecule is to display the list that is contained in the service agreement details screen.
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import {APPROVALS_AGREEMENT_DETAILS as messages} from '../../Constants/Messages';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import {StandardText} from '../Atoms';
import {CurrencyText} from './';
import {formatDurationString, formatQuantity} from '../../Helpers/Utils';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class ServiceAgreementList extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <View style={CommonStyles.paddingTop5}>{this._renderLineItem()}</View>
    );
  }

  _renderLineItem = () => {
    const dataArray = this.props.data;
    if (Array.isArray(dataArray) && dataArray.length > 0) {
      return dataArray.map((item, index) => (
        <View key={index} style={[CommonStyles.containerList_Item]}>
          <View style={CommonStyles.justifyFlexRowStart}>
            <View
              style={[
                CommonStyles.containerGreyTextWrapper,
                CommonStyles.marginBottom5,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.title_ListContainer,
                  CommonStyles.customFont,
                ]}
              >
                {item.code}
              </StandardText>
            </View>
          </View>
          <View
            style={[CommonStyles.justifyFlexRowStart, CommonStyles.marginTop5]}
          >
            <StandardText
              style={[
                CommonStyles.title_ListContainer,
                CommonStyles.customFont,
              ]}
            >
              {item.description}
            </StandardText>
          </View>
          <View
            style={[
              CommonStyles.justifyFlexRowStart,
              CommonStyles.marginTop10,
              CommonStyles.marginBottom10,
            ]}
          >
            <View
              style={[
                CommonStyles.flex1,
                CommonStyles.justifyFlexColumnStart,
                CommonStyles.alignCenter,
              ]}
            >
              {this._renderQuantity(item.quantity, item.duration)}
            </View>
            <View style={[CommonStyles.flex1]}>
              {this._renderCurrencyBox(messages.PRICE, item.unitPrice)}
            </View>
            <View style={[CommonStyles.flex1]}>
              {this._renderCurrencyBox(messages.BUDGET, item.initialBudget)}
            </View>
          </View>
        </View>
      ));
    }
  };

  _renderQuantity = (quantity: any, duration: any) => {
    let value = '';
    if (quantity !== undefined && quantity !== 0) {
      value = formatQuantity(quantity);
      return this._renderQuantityBox(value);
    } else if (
      duration !== undefined &&
      !(
        duration === types2.ZERO_DURATION_TWO ||
        duration === types2.ZERO_DURATION_ONE
      )
    ) {
      value = formatDurationString(duration);
      return this._renderQuantityBox(value);
    }
    return null;
  };

  _renderQuantityBox = (value: any) => {
    return (
      <View
        style={[
          CommonStyles.flex1,
          CommonStyles.flexDirectionColumn,
          CommonStyles.alignCenter,
        ]}
      >
        <View style={[CommonStyles.containerList_ItemFigureTitle]}>
          <StandardText
            style={[
              CommonStyles.title_ListContainerSemiBold,
              CommonStyles.customFontSemiBold,
            ]}
          >
            {messages.QUANTITY}
          </StandardText>
        </View>
        <View style={[CommonStyles.containerList_ItemFigureTitle]}>
          <StandardText
            style={[CommonStyles.title_ListContainer, CommonStyles.customFont]}
          >
            {value}
          </StandardText>
        </View>
      </View>
    );
  };

  _renderCurrencyBox = (label: any, value: any) => {
    if (value !== 0 || value !== 0.0) {
      return (
        <View
          style={[
            CommonStyles.flex1,
            CommonStyles.justifyFlexColumnStart,
            CommonStyles.alignCenter,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.title_ListContainerSemiBold,
              CommonStyles.customFontSemiBold,
            ]}
          >
            {label}
          </StandardText>
          <CurrencyText
            value={value}
            isPositive={true}
            integerStyle={[
              types.isWeb ? CommonStyles.font20 : CommonStyles.font30,
              CommonStyles.customFont,
              BrandStyles.TextColor5,
            ]}
          />
        </View>
      );
    }
  };
}

export default ServiceAgreementList;
