import React, {Component} from 'react';
import {View, Text, FlatList, StyleSheet, Image} from 'react-native';
import {RedArrow, arrowExpandWhite, UploadIconRed} from '../assets/images';
import {
  StandardText,
  StandardInput,
  StandardTouchableOpacity,
  StandardButton,
} from '../Components/Atoms';

import {
  DocumentsTopBar,
  Container,
  CrewNoAccess,
  FormTitle,
  BottomMessageBar,
  SummaryTile,
  SummaryList,
  DatePicker,
  AutoComplete,
  FormSubtitle,
} from '../Components/Molecules';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {debounce} from 'lodash';

import * as PayInvoiceActions from '../Actions/PayInvoiceActions';

import {callAPIs} from '../API/APICaller';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {
  getClaimableItemsSearchRes,
  getMostUsedClaimableItems,
} from '../API/MemberAPI';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {SUPPORT_ITEM_SCREEN as messages} from '../Constants/Messages';
import {formatDate, formatTime, formatNumber} from '../Helpers/Utils';

import {focusNextField} from '../Components/FocusNextField/FocusNextField';
import logger from 'helpers/Logger';

const INPUTS = {
  SCROLL_VIEW: 'scrollView',
  SUPPORT_ITEM: 'supportitem',
  RATE: 'rate',
  HOUR: 'hour',
  MINUTES: 'minutes',
  UNIT: 'unit',
  ITEM_TOTAL: 'itemtotal',
  START_DATE: 'startdate',
  END_DATE: 'enddate',
};

const TOP = 'top';
const TOP_END = 'top-end';

class SupportItem extends Component {
  datePickers: any;
  inputs: any;
  scrollView: any;
  constructor(props: any) {
    super(props);
    this.inputs = {};
    this.datePickers = {};
    this.validateAdding = this.validateAdding.bind(this);

    this.state = {
      searchText: '',
      selectedSuportName: this._getParamInSelectedItem('selectedSuportName'),
      billerCode: this._getParamInSelectedItem('billerCode'),
      filteredSuport: {},
      selectedSuportItem: {},
      supportItems: this._getParam('itemselectedSuportItem'),
      validEmail: '',
      validSupportName: '',
      validRate: '',
      validHour: '',
      validMin: '',
      validUnit: '',
      validTimeUnit: '',
      validStartDate: '',
      validEndDate: '',
      startDateBorderWidth: 0,
      endDateBorderWidth: 0,
      quatityTypeUnit: this._getParamInSelectedItem('unit') ? true : false,
      supportItemsCollections: [],
      timeColor: this._getParamInSelectedItem('unit')
        ? [BrandStyles.darkgreyColor, BrandStyles.borderColor6]
        : [BrandStyles.primaryBgColor3, BrandStyles.borderColor3],
      unitColor: this._getParamInSelectedItem('unit')
        ? [BrandStyles.primaryBgColor3, BrandStyles.borderColor3]
        : [BrandStyles.darkgreyColor, BrandStyles.borderColor6],
      mostUsedClaimableItems: {},
      loading: true,
      scrollEnabled: true,
      accessUnit: this.props.member.access.finances,
      accessTime: this.props.member.access.finances,
      searchIndex: 2,
    };
  }

  _getParamInSelectedItem = (key: any) => {
    // const { route } = this.props;
    // const params = route.params || {};
    // const selectedSupportItem = params['itemselectedSuportItem'];
    let selectedSupportItem;
    if(types.isWeb) {
      selectedSupportItem=   this.props.navigation.getParam('itemselectedSuportItem', undefined) ;
     }else {
      selectedSupportItem =  this.props.route.params['itemselectedSuportItem'] ? this.props.route.params['itemselectedSuportItem'] : undefined;
     }

    if (selectedSupportItem) {
      return selectedSupportItem.key;
    }
    return '';
  };
  _getParam = (key: any) => {
    // const { route } = this.props;
    // const params = route.params || {};
    let selectedSupportItem;
    if(types.isWeb) {
      selectedSupportItem=   this.props.navigation.getParam(key, undefined) ;
     }else {
      selectedSupportItem =  this.props.route.params[key] ? this.props.route.params[key] : undefined;
     }
   // const selectedSupportItem = params[key];
    if (selectedSupportItem) {
      return selectedSupportItem;
    }
    return {};
  };

  componentDidMount() {
    this.props.onRef(this);
    // const { route } = this.props;
    // const params = route.params || {};
    let addNewSupportItem;
    if(types.isWeb) {
      addNewSupportItem=   this.props.navigation.getParam('addNewSupportItem', undefined) ;
     }else {
      addNewSupportItem =  this.props.route.params['addNewSupportItem'] ? this.props.route.params['addNewSupportItem'] : undefined;
     }
    if (this.props.isDraftEdit && this.props.supportData) {
      this.setState(
        {
          selectedSuportName: this.props.supportData.selectedSuportItem.name,
          billerCode: this.props.supportData.billerCode,
          supportItems: this.props.supportData,
          quatityTypeUnit: this.props.supportData.unit ? true : false,
        },
        () => {
          if (
            this.props.supportData.hour !== '' &&
            this.props.supportData.hour !== undefined
          ) {
            this._disableUnit(this.props.supportData.hour);
          } else {
            this._disableUnit(this.props.supportData.mins);
          }
          this._disableTime(this.props.supportData.unit);
        },
      );
    }

    if (addNewSupportItem) {
      const supportitems = this.props.payInvoice.supportItems;
      this.setState(prevState => ({
        supportItems: {
          ...prevState.supportItems,
          supportItemKey:
            supportitems && supportitems.length > 0
              ? supportitems[supportitems.length - 1].supportItemKey + 1
              : 1,
        },
      }));
    }

    callAPIs(
      [getMostUsedClaimableItems(this.props.loadedMemberId.loadedMemberId)],
      (data: any) => this._setMostUsedClaimableItems(data),
      null,
      () => {
        this.setState({loading: false});
      },
    );
  }
  focusNextField(nextField: any) {
    if (this.inputs[nextField].focus) {
      this.inputs[nextField].focus();
    }
  }

  render() {
    return this._createMainContents();
  }

  debouncedSearchFilteredSupportFunc = debounce(
    () =>
      callAPIs(
        [
          getClaimableItemsSearchRes(
            this.props.loadedMemberId.loadedMemberId,
            this.state.searchText,
            false,
          ),
        ],
        (data: any) => this._setFilterdSupport(data),
        (err: any) => logger.log('notifications error: ', err),
      ),
    1000,
  );

  debouncedSearchFilteredSupport(query: any) {
    this.setState({
      searchText: query,
      selectedSuportName: query,
      billerCode: '',
    });

    if (query == '' || query == undefined || query < 1) {
      this.setState({
        filteredSuport: this.state.mostUsedClaimableItems,
        searchIndex: 10,
      });
      return;
    }

    this.debouncedSearchFilteredSupportFunc();
  }

  _renderSupportItemSuggestion = () => {
    return (
      <View
        style={
          types.isWeb
            ? [CommonStyles.supportItemFit, {zIndex: this.state.searchIndex}]
            : CommonStyles.zIndex1
        }
      >
        <StandardText
          style={[
            BrandStyles.TextColor5,
            types.isWeb ? CommonStyles.font15 : CommonStyles.font20,
            CommonStyles.customFont,
            CommonStyles.textAlignVerticalCenter,
            CommonStyles.paddingBottom10,
          ]}
        >
          {messages.TITLE_1}
        </StandardText>
        <View style={[CommonStyles.paddingBottom10, CommonStyles.zIndex1]}>
          <AutoComplete
            readOnly={false}
            data={this.state.filteredSuport}
            mandatory={true}
            listStyle={[CommonStyles.content, CommonStyles.listContainer]}
            onChangeText={(text: any) =>
              this.debouncedSearchFilteredSupport(text)
            }
            renderItem={(item: any) => this._renderSearchItem(item)}
            style={[
              CommonStyles.ModalTextInputWrapper,
              CommonStyles.StandardFontSize,
              CommonStyles.paddingLeft20,
            ]}
            value={
              this.state.billerCode
                ? this.state.selectedSuportName +
                  ' (' +
                  this.state.billerCode +
                  ')'
                : this.state.selectedSuportName
            }
            underlineColorAndroid="transparent"
            onClear={() => {
              // Clear the search text
              this.setState({
                searchText: '',
                selectedSuportName: '',
                billerCode: '',
                selectedSuportItem: {},
                filteredSuport: {},
                scrollEnabled: true,
              });
            }}
            onFocus={() => {
              // Since the focus is on the auto complete, any drag should dismiss the keyboard
              this.setState({keyboardDismissMode: 'on-drag'});
              if (
                this.state.searchText == '' ||
                this.state.searchText == undefined
              ) {
                this.setState({
                  filteredSuport: this.state.mostUsedClaimableItems,
                  searchIndex: 10,
                  scrollEnabled: false,
                });
              }
            }}
            onBlur={() => {
              // No longer focused on the auto complete, remove the dismiss mode
              this.setState({keyboardDismissMode: 'none'});
            }}
            errorMessage={this.state.validSupportName}
          />
        </View>
      </View>
    );
  };
  _renderRate = () => {
    return (
      <View
        style={
          types.isWeb
            ? [
                CommonStyles.maxWidth160,
                CommonStyles.paddingLeft10,
                CommonStyles.paddingRight35,
              ]
            : null
        }
      >
        <View style={[!types.isWeb ? CommonStyles.paddingBottom10 : null]}>
          <View style={[CommonStyles.subHeadingContainerMandotory]}>
            <FormTitle
              hasError={this.state.validRate}
              text={messages.RATE_IN_DOLLARS}
              containsData={this.state.supportItems.rate}
              style={[
                BrandStyles.TextColor5,
                CommonStyles.customFontBold,
                CommonStyles.fontWeight400,
                types.isWeb ? CommonStyles.font15 : CommonStyles.font20,
              ]}
            />
          </View>
        </View>
        <View style={[]}>
          <StandardInput
            accessibilityLabel="Rate"
            placeholder="$"
            disabledPlaceHolderStyleChange={true}
            multiline={false}
            mandatory={true}
            readOnly={this.props.member.access.finances}
            returnKeyType={'next'}
            onRef={(ref: any) => {
              this.inputs[INPUTS.RATE] = ref;
            }}
            onSubmitEditing={() => {
              {
                this.state.quatityTypeUnit
                  ? focusNextField(this.inputs, INPUTS.SCROLL_VIEW, INPUTS.UNIT)
                  : focusNextField(
                      this.inputs,
                      INPUTS.SCROLL_VIEW,
                      INPUTS.HOUR,
                    );
              }
            }}
            value={
              this.state.supportItems.rate !== undefined
                ? this.state.supportItems.rate.toString()
                : ''
            }
            keyboardType="default"
            blurOnSubmit={false}
            onChangeText={(rate: any) =>
              this.setState(prevState => ({
                supportItems: {
                  ...prevState.supportItems,
                  rate: rate,
                  itemtotal:
                    '' +
                    formatNumber(rate) *
                      (formatNumber(prevState.supportItems.unit) +
                        formatNumber(prevState.supportItems.hour) +
                        formatNumber(prevState.supportItems.mins) / 60),
                },
              }))
            }
            underlineColorAndroid="transparent"
            style={[
              CommonStyles.ModalTextInputWrapper,
              BrandStyles.primaryBgColor1,
            ]}
            errorMessage={this.state.validRate}
          />
        </View>
      </View>
    );
  };

  _renderHoursMinutes = () => {
    return (
      <View
        style={
          types.isWeb
            ? [CommonStyles.maxWidth200, CommonStyles.paddingLeft10]
            : null
        }
      >
        <View style={[CommonStyles.subHeadingContainerMandotory]}>
          {!types.isWeb ? (
            <FormTitle
              hasError={this.state.validHour.concat(this.state.validMin)}
              text={'Time'}
              containsData={
                !this.state.quatityTypeUnit &&
                (this.state.supportItems.hour == undefined ||
                  this.state.supportItems.mins == undefined)
                  ? null
                  : 'valid'
              }
              style={[
                BrandStyles.TextColor5,
                CommonStyles.customFontBold,
                CommonStyles.fontWeight400,
                CommonStyles.font20,
              ]}
            />
          ) : null}
        </View>
        <View style={[CommonStyles.contentWrapper]}>
          <View
            style={[
              CommonStyles.flex1,
              types.isWeb ? null : CommonStyles.paddingLeft10,
            ]}
          >
            <FormTitle
              text={['Hours']}
              disableYellowStar={true}
              style={[
                BrandStyles.TextColor5,
                CommonStyles.customFontBold,
                CommonStyles.fontWeight400,
                types.isWeb
                  ? CommonStyles.font15
                  : CommonStyles.formSubtitleText,
              ]}
            />
            <StandardInput
              accessibilityLabel="Hours"
              disabledPlaceHolderStyleChange={true}
              multiline={false}
              keyboardType="numeric"
              readOnly={this.state.accessTime}
              onRef={(ref: any) => {
                this.inputs[INPUTS.HOUR] = ref;
              }}
              onSubmitEditing={() => {
                focusNextField(this.inputs, INPUTS.SCROLL_VIEW, INPUTS.MINUTES);
              }}
              returnKeyType={'next'}
              onChangeText={(hour: any) => {
                this.setState(prevState => ({
                  quatityTypeUnit: false,
                  supportItems: {
                    ...prevState.supportItems,
                    hour: hour,
                    unit: '',
                    itemtotal:
                      '' +
                      formatNumber(prevState.supportItems.rate) *
                        (formatNumber(hour) +
                          formatNumber(prevState.supportItems.mins) / 60),
                  },
                }));

                this._disableUnit(hour);
              }}
              onEndEditing={(event: any) => {
                this.setState(prevState => ({
                  supportItems: {
                    ...prevState.supportItems,
                    hour: formatTime(prevState.supportItems.hour),
                  },
                }));
              }}
              onBlur={() => {
                if (types.isWeb) {
                  this.setState(prevState => ({
                    supportItems: {
                      ...prevState.supportItems,
                      hour: formatTime(prevState.supportItems.hour),
                    },
                  }));
                }
              }}
              value={this.state.supportItems.hour}
              blurOnSubmit={false}
              underlineColorAndroid="transparent"
              style={[
                CommonStyles.ModalTextInputWrapper,
                BrandStyles.primaryBgColor1,
                CommonStyles.PlatformMarginR15,
              ]}
              noMargin={types.isWeb ? true : false}
              border={this.state.timeUnitBorder}
              errorMessage={this.state.validHour}
            />
          </View>
          <View
            style={[
              CommonStyles.flex1,
              types.isWeb ? CommonStyles.paddingLeft10 : null,
            ]}
          >
            <FormTitle
              text={types.isWeb ? ['Minutes'] : ['Mins']}
              disableYellowStar={true}
              style={[
                BrandStyles.TextColor5,
                CommonStyles.customFontBold,
                CommonStyles.fontWeight400,
                types.isWeb
                  ? CommonStyles.font15
                  : CommonStyles.formSubtitleText,
              ]}
            />
            <StandardInput
              accessibilityLabel="Minutes"
              disabledPlaceHolderStyleChange={true}
              multiline={false}
              maxLength={2}
              readOnly={this.state.accessTime}
              onRef={(ref: any) => {
                this.inputs[INPUTS.MINUTES] = ref;
              }}
              onSubmitEditing={() => {
                focusNextField(
                  this.inputs,
                  INPUTS.SCROLL_VIEW,
                  INPUTS.START_DATE,
                );
              }}
              returnKeyType={'next'}
              onChangeText={(mins: any) => {
                this.setState(prevState => ({
                  quatityTypeUnit: false,
                  supportItems: {
                    ...prevState.supportItems,
                    mins: mins,
                    unit: '',
                    itemtotal:
                      '' +
                      formatNumber(prevState.supportItems.rate) *
                        (formatNumber(mins) / 60 +
                          formatNumber(prevState.supportItems.hour)),
                  },
                }));

                this._disableUnit(mins);
              }}
              onEndEditing={(event: any) =>
                this.setState(prevState => ({
                  supportItems: {
                    ...prevState.supportItems,
                    mins: formatTime(prevState.supportItems.mins),
                  },
                }))
              }
              onBlur={() => {
                if (types.isWeb) {
                  this.setState(prevState => ({
                    supportItems: {
                      ...prevState.supportItems,
                      mins: formatTime(prevState.supportItems.mins),
                    },
                  }));
                }
              }}
              keyboardType="numeric"
              blurOnSubmit={true}
              value={this.state.supportItems.mins}
              underlineColorAndroid="transparent"
              noMargin={types.isWeb ? true : false}
              style={[
                CommonStyles.ModalTextInputWrapper,
                BrandStyles.primaryBgColor1,
              ]}
              border={this.state.timeUnitBorder}
              errorMessage={this.state.validMin}
            />
          </View>
        </View>
        {this._renderErrorMessage(this.state.validTimeUnit)}
      </View>
    );
  };
  _renderOrLabel = () => {
    return (
      <View>
        <View style={[types.isWeb ? CommonStyles.paddingLeft10 : null]}>
          <View style={[CommonStyles.alignCenter]}>
            <FormTitle
              text={['OR']}
              disableYellowStar={true}
              style={[
                BrandStyles.TextColor5,
                CommonStyles.customFont,
                CommonStyles.fontWeight400,
                types.isWeb ? CommonStyles.font15 : CommonStyles.font20,
              ]}
            />
          </View>
        </View>
      </View>
    );
  };
  _renderUnit = () => {
    return (
      <View
        style={
          types.isWeb
            ? [CommonStyles.maxWidth100, CommonStyles.paddingLeft10]
            : null
        }
      >
        <View style={[CommonStyles.subHeadingContainerMandotory]}>
          <FormTitle
            hasError={this.state.validUnit}
            text={'Unit'}
            containsData={
              this.state.quatityTypeUnit &&
              this.state.supportItems.unit == undefined
                ? null
                : 'valid'
            }
            style={[
              BrandStyles.TextColor5,
              CommonStyles.customFontBold,
              CommonStyles.fontWeight400,
              types.isWeb ? CommonStyles.font15 : CommonStyles.font20,
            ]}
          />
        </View>
        <View style={[]}>
          <View style={CommonStyles.flex1}>
            <StandardInput
              disabledPlaceHolderStyleChange={true}
              accessibilityLabel="Unit"
              multiline={false}
              returnKeyType={'next'}
              readOnly={this.state.accessUnit}
              onSubmitEditing={() => {
                focusNextField(
                  this.inputs,
                  INPUTS.SCROLL_VIEW,
                  INPUTS.START_DATE,
                );
              }}
              onChangeText={(unit: any) => {
                this.setState(prevState => ({
                  quatityTypeUnit: true,
                  supportItems: {
                    ...prevState.supportItems,
                    hour: '',
                    mins: '',
                    unit: unit,
                    itemtotal:
                      '' +
                      formatNumber(prevState.supportItems.rate) *
                        formatNumber(unit),
                  },
                }));

                this._disableTime(unit);
              }}
              keyboardType="default"
              value={
                this.state.supportItems.unit !== undefined
                  ? this.state.supportItems.unit.toString()
                  : ''
              }
              blurOnSubmit={true}
              underlineColorAndroid="transparent"
              style={[
                CommonStyles.ModalTextInputWrapper,
                BrandStyles.primaryBgColor1,
              ]}
              border={this.state.timeUnitBorder}
              errorMessage={this.state.validUnit}
            />
          </View>
        </View>
        {!types.isWeb ? this._renderItemTotal() : null}
      </View>
    );
  };
  _renderItemTotal = () => {
    return (
      <View style={[types.isWeb ? CommonStyles.paddingLeft10 : null]}>
        <View style={CommonStyles.paddingBottom10}>
          <StandardText
            style={[
              CommonStyles.formTitleText,
              CommonStyles.customFont,
              BrandStyles.TextColor5,
              CommonStyles.textAlignVerticalCenter,
              types.isWeb ? CommonStyles.font15 : null,
            ]}
          >
            Item total
          </StandardText>
        </View>
        <View style={[CommonStyles.flex1]}>
          <StandardInput
            placeholder="$"
            disabledPlaceHolderStyleChange={true}
            multiline={false}
            accessibilityLabel="Item total"
            returnKeyType={'next'}
            keyboardType="numeric"
            readOnly={types.ACCESS_READ_ONLY}
            value={
              '' +
              parseFloat(
                this.state.supportItems.itemtotal
                  ? this.state.supportItems.itemtotal
                  : '0',
              ).toFixed(2)
            }
            blurOnSubmit={true}
            onRef={(ref: any) => {
              this.inputs[INPUTS.ITEM_TOTAL] = ref;
            }}
            underlineColorAndroid="transparent"
            style={[
              CommonStyles.ModalTextInputWrapper,
              BrandStyles.primaryBgColor1,
            ]}
          />
        </View>
      </View>
    );
  };

  _renderStartDate = () => {
    return (
      <View
        style={
          types.isWeb
            ? [
                CommonStyles.paddingLeft10,
                CommonStyles.paddingRight10,
                CommonStyles.marginRight40,
              ]
            : null
        }
      >
        {!types.isWeb ? (
          <View style={CommonStyles.paddingBottom10}>
            <View style={[CommonStyles.subHeadingContainerMandotory]}>
              <FormTitle
                hasError={this.state.validStartDate}
                text={messages.TITLE_3}
                containsData={this.state.supportItems.startdate}
                style={[
                  BrandStyles.TextColor5,
                  CommonStyles.customFontBold,
                  CommonStyles.fontWeight400,
                  CommonStyles.font20,
                ]}
              />
            </View>
          </View>
        ) : null}
        <View
          style={[types.isWeb ? CommonStyles.maxWidth125 : {}]}
          ref={ref => {
            this.scrollView = ref;
          }}
        >
          <FormTitle
            text={['Start Date (dd/mm/yyyy)']}
            disableYellowStar={types.isWeb ? false : true}
            containsData={this.state.supportItems.startdate}
            style={[
              BrandStyles.TextColor5,
              CommonStyles.customFontBold,
              CommonStyles.fontWeight400,
              types.isWeb ? CommonStyles.font15 : CommonStyles.formSubtitleText,
            ]}
          />
          <DatePicker
            maxDate={new Date()}
            accessibilityLabel={'start date'}
            mandatory={true}
            errorBorder={this.state.startDateBorderWidth}
            onRef={(ref: any) => {
              this.inputs[INPUTS.START_DATE] = ref;
            }}
            value={
              this.state.supportItems.startdate != undefined
                ? this.state.supportItems.startdate
                : ''
            }
            popUpPlacement={TOP}
            errorMessage={this.state.validStartDate}
            readOnly={this.props.member.access.finances}
            inputs={this.inputs}
            //nextFocus={INPUTS.END_DATE}
            save={(startdate: any) => {
              if (
                startdate.displayText !=
                types2.DATE_PICKER_PLACEHOLDER.INVOICE_DATE
              ) {
                this.setState(prevState => ({
                  supportItems: {
                    ...prevState.supportItems,
                    startdate: startdate,
                  },
                }));
              }
            }}
          />
        </View>
      </View>
    );
  };

  _renderEndDate = () => {
    return (
      <View style={types.isWeb ? [CommonStyles.paddingLeft10] : null}>
        <View style={!types.isWeb ? CommonStyles.paddingBottom10 : null}>
          <View style={[CommonStyles.subHeadingContainerMandotory]} />
        </View>
        <View
          style={[
            types.isWeb
              ? [CommonStyles.paddingLeft10, CommonStyles.maxWidth125]
              : {},
          ]}
        >
          <FormTitle
            text={['End Date (dd/mm/yyyy)']}
            disableYellowStar={true}
            style={[
              BrandStyles.TextColor5,
              CommonStyles.customFontBold,
              CommonStyles.fontWeight400,
              types.isWeb ? CommonStyles.font15 : CommonStyles.formSubtitleText,
            ]}
          />
          <DatePicker
            accessibilityLabel={'end date'}
            errorBorder={this.state.endDateBorderWidth}
            value={
              this.state.supportItems.enddate != undefined
                ? this.state.supportItems.enddate
                : ''
            }
            onRef={(ref: any) => {
              this.inputs[INPUTS.END_DATE] = ref;
            }}
            popUpPlacement={this._determinEndDatePlaceMent()}
            errorMessage={this.state.validEndDate}
            readOnly={this.props.member.access.finances}
            inputs={this.inputs}
            //nextFocus={INPUTS.END_DATE}
            save={(enddate: any) => {
              if (
                enddate.displayText !=
                types2.DATE_PICKER_PLACEHOLDER.INVOICE_DATE
              ) {
                this.setState(prevState => ({
                  supportItems: {
                    ...prevState.supportItems,
                    enddate: enddate,
                  },
                }));
              }
            }}
          />
        </View>
      </View>
    );
  };

  _determinEndDatePlaceMent = () => {
    if (types.isWeb) {
      if (this.props.isMobile) {
        return TOP_END;
      }
      return TOP;
    }
  };

  _createMainContents = () => {
    return (
      <View
        style={[
          CommonStyles.flex1,
          types.isWeb ? CommonStyles.paddingBottom50 : null,
        ]}
      >
        <View style={[CommonStyles.content]}>
          <View style={[types.isWeb ? CommonStyles.contentWrap : null]}>
            {this._renderSupportItemSuggestion()}
            {this._renderRate()}
            <View style={types.isWeb ? [CommonStyles.flexRow] : null}>
              {this._renderHoursMinutes()}
              {this._renderOrLabel()}
              {this._renderUnit()}
            </View>
            <View
              style={
                types.isWeb
                  ? [
                      CommonStyles.flexRow,
                      CommonStyles.zIndex05,
                      CommonStyles.marginRight75,
                    ]
                  : null
              }
            >
              <View style={types.isWeb ? CommonStyles.zIndex04 : null}>
                {this._renderStartDate()}
              </View>
              <View
                style={
                  types.isWeb
                    ? [CommonStyles.zIndex03, CommonStyles.marginLeft10]
                    : null
                }
              >
                {this._renderEndDate()}
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  };

  _disableUnit(time: any) {
    let accessUnit = this.props.member.access.finances;

    if (time && time !== '') {
      accessUnit = types.ACCESS_READ_ONLY;
    }
    this.setState({accessUnit: accessUnit});
  }

  _disableTime(unit: any) {
    let accessTime = this.props.member.access.finances;

    if (unit && unit !== '') {
      accessTime = types.ACCESS_READ_ONLY;
    }
    this.setState({accessTime: accessTime});
  }

  _addAnotherItem() {
    if (this.validateFields()) {
      this.props.actions.payInvoiceActions.addSupportItemsAction(
        this.state.supportItems,
      );
      this.props.navigation.navigate('SupportItem', {
        addNewSupportItem: true,
      });
    }
  }

  validateAdding() {
    if (this.validateFields()) {
      this.props.actions.payInvoiceActions.addSupportItemsAction(
        this.state.supportItems,
      );
      return true;
    }
    return false;
  }

  validateFields = () => {
    // Used to set the state for failed fields - we build it up as to only set the state once
    // If the error string is blank it means it passed validation :)
    // Additionaly it all sets them to passed if the user fixed previous validation issues
    var validateErrors = {
      supportNameError: '',
      rateError: '',
      hourError: '',
      minError: '',
      startDateError: '',
      endDateError: '',
      unitError: '',
      timeOrUnitError: '',
    };

    if (this.state.billerCode == '' || this.state.billerCode == undefined) {
      validateErrors.supportNameError = types.SUPPORTITEM_NOT_ENTERED;
    }

    if (
      this.state.supportItems.rate == '' ||
      this.state.supportItems.rate == undefined
    ) {
      validateErrors.rateError = types.RATE_NOT_ENTERED;
    }

    if (this.state.quatityTypeUnit) {
      if (
        this.state.supportItems.unit == '' ||
        this.state.supportItems.unit == undefined
      ) {
        validateErrors.timeOrUnitError = types.TIME_OR_UNIT_NOT_ENTERED;
      }
    } else {
      if (
        this.state.supportItems.hour == '' ||
        this.state.supportItems.hour == undefined
      ) {
        validateErrors.timeOrUnitError = types.TIME_OR_UNIT_NOT_ENTERED;
      }

      if (
        this.state.supportItems.mins == '' ||
        this.state.supportItems.mins == undefined
      ) {
        validateErrors.timeOrUnitError = types.TIME_OR_UNIT_NOT_ENTERED;
      }
    }

    if (
      this.state.supportItems.startdate == '' ||
      this.state.supportItems.startdate == undefined
    ) {
      validateErrors.startDateError = types.STARTDATE_NOT_ENTERED;
      this.setState({
        startDateBorderWidth: 3,
      });
    } else {
      this.setState({
        startDateBorderWidth: 0,
      });
    }

    if (
      this.state.supportItems.enddate == '' ||
      this.state.supportItems.enddate == undefined
    ) {
      validateErrors.endDateError = types.ENDDATE_NOT_ENTERED;
    }

    var reNumber = /^[0-9]*$/;
    var reDecimalNumber = /^\d+(\.\d{1,2})?$/;
    if (
      validateErrors.rateError == '' &&
      !reDecimalNumber.test(this.state.supportItems.rate)
    ) {
      validateErrors.rateError = types.RATE_INVALID_FORMAT;
    }

    var reNumberTwoDigit = /^[0-9]{2}$/;
    if (
      !this.state.quatityTypeUnit &&
      validateErrors.timeOrUnitError == '' &&
      !reNumber.test(this.state.supportItems.hour)
    ) {
      validateErrors.hourError = types.HOURS_INVALID_FORMAT;
    }

    if (!this.state.quatityTypeUnit && validateErrors.timeOrUnitError == '') {
      if (!reNumberTwoDigit.test(this.state.supportItems.mins)) {
        validateErrors.minError = types.MINS_INVALID_FORMAT;
      } else if (parseFloat(this.state.supportItems.mins) > 59) {
        validateErrors.minError = types.MINS_INVALID_FORMAT;
      }
    }

    if (
      this.state.quatityTypeUnit &&
      validateErrors.timeOrUnitError == '' &&
      !reNumber.test(this.state.supportItems.unit) &&
      !reDecimalNumber.test(this.state.supportItems.unit)
    ) {
      validateErrors.unitError = types.UNIT_INVALID_FORMAT;
    }

    if (
      validateErrors.startDateError == '' &&
      validateErrors.endDateError == ''
    ) {
      if (
        this.state.supportItems.startdate &&
        this.state.supportItems.enddate
      ) {
        if (
          this.state.supportItems.enddate < this.state.supportItems.startdate
        ) {
          validateErrors.endDateError = types.ENDDATE_INVALID_FORMAT;
          this.setState({
            startDateBorderWidth: 3,
            endDateBorderWidth: 3,
          });
        } else {
          this.setState({
            startDateBorderWidth: 0,
            endDateBorderWidth: 0,
          });
        }
      }
    } else {
      validateErrors.endDateError = '';
      this.setState({
        endDateBorderWidth: 0,
      });
    }

    if (
      validateErrors.timeOrUnitError &&
      validateErrors.timeOrUnitError !== ''
    ) {
      this.setState({
        timeUnitBorder: [
          CommonStyles.textInputErrorBorder,
          BrandStyles.errorBorder,
        ],
      });
    } else {
      this.setState({timeUnitBorder: undefined});
    }

    // Set the state to show the validation on the components
    this.setState({
      validSupportName: validateErrors.supportNameError,
      validRate: validateErrors.rateError,
      validUnit: validateErrors.unitError,
      validHour: validateErrors.hourError,
      validMin: validateErrors.minError,
      validStartDate: validateErrors.startDateError,
      validEndDate: validateErrors.endDateError,
      validTimeUnit: validateErrors.timeOrUnitError,
    });

    // Now return if the there are valid fields
    for (let [key, value] of Object.entries(validateErrors)) {
      if (value != '') {
        return false;
      }
    }
    return true;
  };

  getParsedDate = (date: any) => {
    var days = String(date).split('-');
    return [parseInt(days[0]), parseInt(days[1]) - 1, parseInt(days[2])];
  };

  _renderErrorMessage = (errorMessage: any) => {
    if (errorMessage != undefined) {
      if (errorMessage != '') {
        return (
          <StandardText
            accessibilityLabel={errorMessage}
            style={[CommonStyles.textInputError]}
          >
            {errorMessage}
          </StandardText>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  _setFilterdSupport(data: any) {
    if (data[0].content.length > 0) {
      var filteredSuport = data[0].content.filter(function (
        item: any,
        index: any,
        array: any,
      ) {
        return item.code;
      });
      if (this.state.searchText != '' && this.state.searchText != undefined) {
        this.setState({filteredSuport, searchIndex: 10});
      }
    }
  }

  _setMostUsedClaimableItems(data: any) {
    this.setState({loading: false});

    if (data[0].content.length > 0) {
      var filteredSuport = data[0].content.filter(function (
        item: any,
        index: any,
        array: any,
      ) {
        return item.code;
      });
      if (this.state.searchText == '' || this.state.searchText == undefined) {
        this.setState({
          mostUsedClaimableItems: filteredSuport,
        });
      }
    }
  }

  _renderSearchItem = (item: any) => {
    return (
      <StandardTouchableOpacity
        accessibilityLabel={item.type + ' selected'}
        key={item.name}
        style={[CommonStyles.listItem]}
        onPress={() => {
          this._setSuportItem(item);
          focusNextField(this.inputs, INPUTS.SCROLL_VIEW, INPUTS.RATE);
        }}
      >
        <StandardText style={CommonStyles.StandardFontSize}>
          <StandardText style={[CommonStyles.customFont]}>
            {item.name}
          </StandardText>
          <StandardText style={[CommonStyles.customFontLight]}>
            {'  (' + item.code + ')'}
          </StandardText>
        </StandardText>
      </StandardTouchableOpacity>
    );
  };

  _setSuportItem = (item: any) => {
    this.setState({
      searchIndex: 2,
      filteredSuport: [],
      searchText: item.name,
      selectedSuportName: item.name,
      selectedSuportItem: item,
      billerCode: item.code,
      scrollEnabled: true,
    });

    this.setState(prevState => ({
      supportItems: {
        ...prevState.supportItems,
        billerKey: item.key,
        billerCode: item.code,
        selectedSuportName: item.name,
        selectedSuportItem: item,
      },
    }));
  };
}

const mapStateToProps = (state: any) => ({
  payInvoice: state.PayInvoiceReducer,
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
  member: state.MemberReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    payInvoiceActions: bindActionCreators(PayInvoiceActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportItem);
