/*
 * Author: Gayan
 * Date: 22/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Reducer for
 * Hiding the Offline Notice programatically
 * Shake the Offline Notice when user clicks Save buttons
 *
 */
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function OfflineNoticeReducer(
  state = {shaking: false},
  action: any,
) {
  switch (action.type) {
    case types2.OFFLINE_NOTICE_SHAKE:
      return {
        ...state,
        shaking: true,
      };
    default:
      return state;
  }
}
