/*
 * Author: Shelan Cooray
 * Date: 13/11/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Draft Claim Invoices Screen
 */

import React, {Component} from 'react';
import {View} from 'react-native';
import {InvoiceDollarRed2x} from '../assets/images/vector-icons';

import {
  Container,
  SummaryTile,
  PaymentDetailTile,
} from '../Components/Molecules';
import {StandardSearch, StandardText} from '../Components/Atoms';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {callAPIs} from '../API/APICaller';
import {getDraftInvoices} from '../API/PlanDataAPI';
import {getProviders} from '../API/ProviderAPI';
import {getMostUsedClaimableItems} from '../API/MemberAPI';
import * as PayInvoiceActions from '../Actions/PayInvoiceActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {getValue, removeItem} from '../API/WebPersistenceStore';

import {
  formatDate,
  formatTime,
  formatNumber,
  findTotal,
  findOneItemTotal,
} from '../Helpers/Utils';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {PAYMENTS_HISTORY_SCREEN as messages} from '../Constants/Messages';

import {orderBy, debounce, find, forEach} from 'lodash';

class DraftClaimInvoices extends Component {
  state = {
    loading: true,
    invoiceHistoryList: [],
    searchInput: '',
    pageSize: 10,
    pageNumber: 1,
    totalPages: 1,
    isPaginationMetaDataAvailable: false,
    refreshing: false,
  };
  constructor(props: any) {
    super(props);
    this._createMainContents.bind(this);
    this._itemOnPress.bind(this);
    this.onRefresh.bind(this);
  }

  componentDidMount = () => {
    // TODO: For Pagination
    // const searchInput =
    //   this.props.navigation.state.params &&
    //   this.props.navigation.state.params.data
    //     ? this.props.navigation.state.params.data
    //     : this.state.searchInput;
    // const pageNumber = searchInput ? 1 : this.state.pageNumber;
    if (types.isWeb) {
      this.props.setLoading(true);
    } else {
      this.setState({loading: true});
    }

    // TODO: For Pagination
    //this._loadInitialData(searchInput, pageNumber, this.state.pageSize);
    this._loadInitialData();
  };

  _loadInitialData = () => {
    const callbackFunction = (data: any) => {
      const getSupportItems = (claims: any) => {
        const supportArray: any = [];
        forEach(claims, claim => {
          supportArray.push({
            billerCode: claim.itemCode,
            selectedSuportItem: {
              description:
                claim.itemDescription !== ''
                  ? claim.itemDescription
                  : claim.itemName,
              name: claim.itemName,
            },
            budgetCategory: claim.itemCode,
            unit: claim.quantity.units ? claim.quantity.units : undefined,
            hour: claim.quantity.duration
              ? claim.quantity.duration.split(':')[0]
              : '',
            mins: claim.quantity.duration
              ? claim.quantity.duration.split(':')[1]
              : '',
            rate: claim.unitPriceIncTax,
            itemtotal: findOneItemTotal(claim),
            startdate: claim.serviceStartTime,
            enddate: claim.serviceEndTime,
          });
        });
        return supportArray;
      };

      // TODO: For Pagination
      // let pageNumberValue = pageNumber;
      const orderedProviders = orderBy(
        data[0].content,
        [biller => biller.name.toLowerCase()],
        ['asc'],
      );
      this.props.actions.payInvoiceActions.getMemberBillersList(
        orderedProviders,
      );
      let invoiceHistory = data[1].content;

      invoiceHistory = invoiceHistory.map((invoice: any) => {
        const billerKey = invoice.billerKey;
        const biller = find(orderedProviders, ['billerKey', billerKey]);
        invoice.biller = biller;
        invoice.invoice = {
          invoiceNumber: invoice.invoiceNumber,
          invoiceDate: invoice.invoiceDate,
          workNote: invoice.workNote,
          memberNote: invoice.memberNote,
          invoiceDocPath: {
            uri:
              invoice.invoiceDocument !== null
                ? invoice.invoiceDocument.url
                : '',
            fileName:
              invoice.invoiceDocument !== null
                ? invoice.invoiceDocument.fileName
                : '',
            type:
              invoice.invoiceDocument !== null
                ? invoice.invoiceDocument.contentType
                : 'image/jpeg',
            s3FileKey:
              invoice.invoiceDocument !== null
                ? invoice.invoiceDocument.s3FileKey
                : '',
          },
        };

        invoice.supportItems = getSupportItems(invoice.claims);
        invoice.total = findTotal(invoice.claims);
        return invoice;
      });

      // TODO: For Pagination
      // const paginationMetadata = data[0].content.paginationMetadata;
      // if (
      //   pullRefresh &&
      //   JSON.stringify(invoiceHistory.invoices) !==
      //     JSON.stringify(this.state.invoiceHistoryList)
      // ) {
      //   pageNumberValue = 1;
      // } else {
      //   pageNumberValue = !!paginationMetadata
      //     ? paginationMetadata.currentPage
      //     : this.state.pageNumber;
      // }
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        invoiceHistoryList: invoiceHistory,
        loading: false,
        refreshing: false,
        // TODO: For Pagination
        // isPaginationMetaDataAvailable: !!paginationMetadata,
        // pageNumber: pageNumberValue,
        // pageSize: !!paginationMetadata
        //   ? paginationMetadata.pageSize
        //   : this.state.pageSize,
        // totalPages: !!paginationMetadata
        //   ? paginationMetadata.totalPages
        //   : this.state.totalPages
      });
      if (getValue(types2.BOOK_MARK_EXTRA_ID)) {
        const item = invoiceHistory.filter(
          (item: any) => item.invoiceId == getValue(types2.BOOK_MARK_EXTRA_ID),
        );
        removeItem(types2.BOOK_MARK_EXTRA_ID);
        if (item && item.length > 0) {
          this._itemOnPress(item[0]);
        }
      }
    };

    callAPIs(
      [
        getProviders(this.props.loadedMemberId.loadedMemberId, '', true, false),
        getDraftInvoices(
          this.props.loadedMemberId.loadedMemberId,
          // searchQuery,
          // pageNumber,
          // pageSize,
          false,
        ),
      ],
      callbackFunction,
      null,
      () => {
        if (types.isWeb) {
          this.props.setLoading(true);
        }
        this.setState({loading: false, refreshing: false});
      },
    );
  };

  onRefresh = () => {
    this.setState({refreshing: true});
    // this._loadInitialData(
    //   this.state.searchInput,
    //   this.state.pageNumber,
    //   this.state.pageSize,
    //   true
    // );
    this._loadInitialData();
  };

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        headerTitle={types2.NAVIGATION_TEXT.DRAFT_CLAIMS_HISTORY}
        needsSideBar={false}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        ignorePadding={true}
        onRefresh={this.onRefresh}
        refreshing={this.state.refreshing}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    return (
      <View style={[CommonStyles.flexColumn, CommonStyles.content]}>
        <View style={[CommonStyles.marginLeft10, CommonStyles.marginRight10]}>
          <SummaryTile
            headerDisplayString={'Draft Invoices to Claim'}
            headerIconImage={InvoiceDollarRed2x}
            HeaderTextColor={BrandStyles.TextColor3}
            HeaderBoarderColor={BrandStyles.borderColor3}
            hideYellowStar={true}
            showButton={false}
            fontSize={CommonStyles.summaryTileHeaders}
            disableMore={true}
          />
        </View>
        {this.renderResultSummary()}
        {/* TODO: For Pagination */}
        {/* <View style={[CommonStyles.searchContainer]}>
        <StandardSearch
          underlineColorAndroid="transparent"
          value={this.state.searchInput}
          placeholder={'Search draft claims'}
          onChangeText={searchInput =>
            this.setState({ searchInput }, () => {
              debounce(() => {
                this.setState({ loading: true });
                // this._loadInitialData(
                //   this.state.searchInput,
                //   1,
                //   this.state.pageSize
                // );
                this._loadInitialData();
              }, 3000)();
            })
          }
        />
      </View> */}
        {this._renderList()}
        {/*For Pagination */}
        {/* {this.state.isPaginationMetaDataAvailable && (
        <PaginationNavigator
          pageNumber={this.state.pageNumber}
          totalPages={this.state.totalPages}
          pageSize={this.state.pageSize}
          goToNextPage={() => {
            this.setState({ loading: true });
            this._loadInitialData(
              this.state.searchInput,
              ++this.state.pageNumber,
              this.state.pageSize
            );
          }}
          goToPreviousPage={() => {
            this.setState({ loading: true });
            this._loadInitialData(
              this.state.searchInput,
              --this.state.pageNumber,
              this.state.pageSize
            );
          }}
        />
      )} */}
      </View>
    );
  };

  renderResultSummary = () => {
    if (
      this.state.invoiceHistoryList &&
      this.state.invoiceHistoryList.length > 0
    ) {
      const resultCount = this.state.invoiceHistoryList.length;
      return (
        <View style={CommonStyles.alignItemsCenter}>
          <StandardText
            style={[
              CommonStyles.textResultSummary,
              CommonStyles.customFontBold,
              BrandStyles.TextColor13,
            ]}
          >
            {`${types2.RESULT_COUNT.SHOW}${resultCount}${types2.RESULT_COUNT.RESULTS}`}
          </StandardText>
        </View>
      );
    }
  };

  _renderList = () => {
    return (
      <PaymentDetailTile
        viewWidth={this.props.viewWidth}
        isMobile={this.props.isMobile}
        categorize={false}
        data={this.state.invoiceHistoryList}
        extraData={this.state}
        isPositive={true}
        itemOnPress={this._itemOnPress.bind(this)}
      />
    );
  };

  _itemOnPress = (item: any) => {
    this.props.actions.payInvoiceActions.setInitialState();
    this.props.actions.payInvoiceActions.addNewProviderAction({
      organisation: item.providerVerifiedName
        ? item.providerVerifiedName
        : item.biller.name,
      abn: item.biller.abn,
      email: item.biller.email,
      bsb: item.biller.abn,
      accountNumber: item.biller.abn,
      billerKey: item.biller.billerKey,
      providerFriendlyName: item.providerFriendlyName,
      memberProviderId: item.biller.memberProviderId,
    });
    this.props.actions.payInvoiceActions.addInvoiceAction(item.invoice);
    item.supportItems.map((supportItem: any) => {
      this.props.actions.payInvoiceActions.addSupportItemsAction(supportItem);
    });
    this.props.navigation.navigate('ReviewInvoiceSummary', {
      isDraftInvoice: true,
      invoiceId: item.invoiceId,
      saveAsDraft: false,
      itemTotal: this.state.invoiceHistoryList.length,
    });
  };
}

const mapStateToProps = (state: any) => ({
  loadedMemberId: state.LoadedMemberReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    payInvoiceActions: bindActionCreators(PayInvoiceActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DraftClaimInvoices);
