/*
 * Author: Declan Hart
 * Date: 08/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Display message when no data is avaliable
 */

import React, {Component} from 'react';
import {View, Image, Picker, Platform} from 'react-native';

import {StandardText, StandardButton, StandardPicker} from '../Atoms';

import {addIcon} from '../../assets/images';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {announceForAccessibility} from '../../Helpers/PlatformSynchronizer';

class SelectOrAddCrew extends Component {
  state = {
    selectedOption: 0,
    addNewCrewForDone: false,
  };

  constructor(props: any) {
    super(props);
    this.renderOptions = this.renderOptions.bind(this);
    this.handleIndexChange = this.handleIndexChange.bind(this);
    this.getCrewName = this.getCrewName.bind(this);
  }

  getCrewName(crew: any) {
    let name = '';
    if (crew.memberType == 'person') {
      name = crew.firstName + ' ' + crew.lastName;
    } else {
      name = crew.orgName;
    }
    return name;
  }

  renderOptions() {
    let crewMembers = [
      {
        description: types2.SELECT_OR_NEW_CREW_MEMBERS,
        type: 0,
      },
    ];
    if (this.props.crew != null && this.props.crew.crewIndividual) {
      this.props.crew.crewIndividual.map((crew: any, index: any) => {
        crewMembers.push({
          description: this.getCrewName(crew),
          type: crew.crewId,
        });
      });
    }
    crewMembers.push({
      description: types2.ADD_A_NEW_CREW_MEMBER,
      type: types2.ADD_A_NEW_CREW_MEMBER,
    });
    return crewMembers;
  }

  handleIndexChange(itemValue: any) {
    if (
      itemValue != types2.SELECT_OR_NEW_CREW_MEMBERS &&
      itemValue != types2.ADD_A_NEW_CREW_MEMBER &&
      this.props.crew.crewIndividual
    ) {
      let selectedCrew = this.props.crew.crewIndividual.find((crew: any) => {
        return crew.crewId.toString() === itemValue.toString();
      });

      this.setState(
        {selectedOption: itemValue, addNewCrewForDone: false},
        () => {
          this.props.save(selectedCrew, null);
        },
      );
      const textToRead = 'Selected ' + this.getCrewName(selectedCrew);

      announceForAccessibility(textToRead);
    } else if (itemValue == types2.ADD_A_NEW_CREW_MEMBER) {
      if (types.isWeb) {
        return this.props.save('', types2.ADD_A_NEW_CREW_MEMBER);
      }
      if (Platform.OS === types2.ANDROID) {
        this.props.save('', types2.ADD_A_NEW_CREW_MEMBER);
      } else {
        this.setState(
          {selectedOption: itemValue, addNewCrewForDone: true},
          () => {},
        );
      }
    } else {
      this.setState({addNewCrewForDone: false});
    }
  }

  render() {
    return (
      <View
        style={[BrandStyles.secondaryBgColor3, CommonStyles.borderRadius10]}
      >
        <StandardPicker
          selectedValue={this.state.selectedOption}
          pickerOptions={this.renderOptions()}
          onValueChange={(itemValue: any, itemIndex: any) =>
            this.handleIndexChange(itemValue)
          }
          style={[
            CommonStyles.standardPicker,
            CommonStyles.standardPickerBackgroundColorGray,
          ]}
          onDonePress={() => this._onDonePress()}
        />
      </View>
    );
  }
  _onDonePress() {
    if (this.state.addNewCrewForDone) {
      this.props.save('', types2.ADD_A_NEW_CREW_MEMBER);
    }
  }
}

export default SelectOrAddCrew;
