/*
 * Authour: Shelan Cooray
 * Date: 05/07/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Guide Me instruction that has been obtained.
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function GuideMeReducer(
  state = {welcomeGuideStep: 0},
  action: any,
) {
  switch (action.type) {
    case types2.GET_GUIDEME:
      return {
        ...state,
        guideMeContent: action.guideMeContent,
      };
    case types2.PREVIOUS_SCREEN_INFO:
      return {
        previousScreenInfo: action.previousScreenInfo,
      };
    case types2.SET_CURRENT_SCREEN:
      return {
        ...state,
        currentScreen: action.currentScreen,
      };
    case types2.SHOW_WELCOME_GUIDE:
      return {
        ...state,
        showWelcomeGuide: action.showWelcomeGuide,
      };
    case types2.WELCOME_GUIDE_STEP:
      return {
        ...state,
        welcomeGuideStep: action.welcomeGuideStep,
      };
    case types2.OVERLAY_HIGHLIGHT_ONE:
      return {
        ...state,
        overlayHighlightOne: action.overlayHighlightOne,
      };
    case types2.OVERLAY_HIGHLIGHT_TWO:
      return {
        ...state,
        overlayHighlightTwo: action.overlayHighlightTwo,
      };
    case types2.SHOW_WELCOME_MANAGED_GUIDE:
      return {
        ...state,
        showWelcomeManagedGuide: action.showWelcomeManagedGuide,
      };
    case types2.SET_DEMO_GUIDE_ME:
      return {
        ...state,
        demoGuideMeType: action.setGuideMeDemoType,
      };
    case types2.IS_DEMO_PROFILE_FIRST_TIME:
      return {
        ...state,
        isDemoProfileFirstTime: action.isDemoProfileFirstTime,
      };
    case types2.SET_HIGHLIGHTED_MENU_ITEM:
      return {
        ...state,
        highlightedMenuItem: action.setHighlightedMenuItem,
      };
    default:
      return state;
  }
}
