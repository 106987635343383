/*
 * Author: Shelan Cooray
 * Date: 01/09/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * export const file for vector icon names
 */

export const UploadRed = 'upload-plan-red';
export const UploadRed2x = 'upload-plan-red';
export const UploadRed3x = 'upload-plan-red';
export const UploadRed4x = 'share-red';
export const UploadCharcoal = 'upload-plan-red';
export const UploadCharcoal2x = 'upload-plan-red';
export const UploadCharcoal3x = 'upload-plan-red';
export const UploadPurple = 'upload-plan-red';
export const UploadPurple2x = 'upload-plan-red';
export const UploadPurple3x = 'upload-plan-red';
export const aboutMeIconCharcoal = 'aboutme-red';
export const aboutMeIconRed = 'aboutme-red';
export const aboutMeIconWhite = 'about-me--white';
export const addIcon = 'add---grey-2';
export const addIconCharcoal60 = 'add---grey-2';
export const addIconGrey = 'add---grey-2';
export const addIconWhite = 'add---grey-2';
export const addPhotoPurple = 'add---grey-2';
export const approvalCharcoal = 'approval-1_charcoal';
export const anyFileIcon = 'file-any-icon';
export const arrowCollapseCharcoal60 = 'arrow-collapse--charcoal60';
export const arrowExpandCharcoal60 = 'arrow-expand--charcoal60';
export const arrowExpandWhite = 'arrow-expand--white';
export const backArrowWhite = 'back-button-black--1';
export const backArrowPurple = 'back-button-black--1';
export const backArrowblack = 'back-button-black--1';
export const BenefitHeroImage = 'iStock-860943062.jpg';
export const BudgetsIconRed = 'dollar-sign';
export const BudgetsIconWhite = 'dollar-sign';
export const BudgetsPlaceholderImage = 'bp-placeholder';
export const CalanderCharcol = 'calendar--charcoal';
export const CalendarRed = 'calendar--red';
export const CameraWhite = 'camera-charcoal';
export const CameraSquare = 'camera-icon-square-x4';
export const CameraCircle = 'camera-icon-circle-x4';
export const ChangeCharcoal = 'change--charcoal';
export const checklistIconCharcoal = 'checklist--charcoal';
export const checklistIconWhite = 'checklist--white';
export const CircledTickImage = 'circled-tick-confirmation';
export const closeIcon = 'close-button-popup';
export const closeIconCharcoal60 = 'close-charcoal-60';
export const ContactInfoCharcoal = 'icon-contactinfo-charcoal';
export const crewAccess16x9 = 'request-access-asset-option1';
export const crewAccess16x9BC = 'request-access-asset-option1BC.jpg';
export const crewAccess16x9UCQ = 'request-access-asset-option1UCQ';
export const crewIconCharcoal = 'crew-2-charcoal';
export const crewIconRed = 'crew-2-charcoal';
export const crewIconWhite = 'crew-2-charcoal';
export const crewIconGray = 'crew-2-charcoal';
export const deleteIcon = 'bin-red';
export const deleteIconCharcoal60 = 'bin-red';
export const submitPreApproved = 'submit-pre-approved-invoice';
export const docFileIcon = 'doc-file-icon';
export const DocumentsImageCharcoal = 'documents--charcoal';
export const DocumentsImageRed = 'documents--red';
export const DocumentsImageWhite = 'documents--white';
export const MoreImageWhite = 'tick-rejected-black';
export const downloadIconCharcoal = 'download--charcoal';
export const downloadIconCharcoal60 = 'download--charcoal60';
export const editIcon = 'edit-';
export const editIconCharcoal60 = 'edit-';
export const emailIcon = 'icon-email-charcoal';
export const errorRed = 'error--red';
export const errorWhite = 'white-tick-rejected';
export const errorCharcoal = 'charcoal-tick-rejected';
export const EyeGray4 = 'icon-view-charcoal';
export const FacebookLogo = 'fblogo-blue';
export const favouriteFutureCharcoal = 'favourites-2-charcoal';
export const favouriteFutureRed = 'favourites-future--red';
export const favouriteThingsIconCharcoal = 'favourites--charcoal';
export const favouriteThingsIconRed = 'favourite-things';
export const favouriteThingsIconWhite = 'favourite-things';
export const FilterFunnel = 'filter-funnel-charcoal';
export const filterIcon = 'filter--charcoal';
export const ForgotPasswordImage = 'iStock-603871162.jpg';
export const GoalInsparation = 'placeholders/GoalInsparation';
export const goalsIconCharcoal = 'goals--charcoal';
export const goalsIconRed = 'goals-red';
export const goalsIconSelectedRed = 'goals-selected--red';
export const goalsIconWhite = 'goals--white';
export const GoalSuggestedSteps = 'placeholders/GoalSuggestSteps';
export const GoogleLogo = 'google-favicon';
export const GuideMeImage = 'guide-me-';
export const healthWellbeingIconCharcoal = 'health--charcoal';
export const healthWellbeingIconRed = 'health-+-wellbeing';
export const healthWellbeingIconWhite = 'health--white';
export const HelpImageCharcoal = 'help--charcoal';
export const HelpImageWhite = 'help--white';
export const homeIconCharcoal = 'home-charcoal';
export const homeIconRed = 'home-charcoal';
export const homeIconWhite = 'home-charcoal';
export const homeSupportIconCharcoal = 'homesupport--charcoal';
export const homeSupportIconRed = 'homesupport--red';
export const ImageWhite = 'image--white';
export const impactDisabilityCommunication =
  'assistancewithsocialparticipation-#97106c';
export const impactDisabilityConnect = 'increasedsocial-participation-black';
export const impactDisabilityDailyLiving = 'assistancewithdailyliving-#c41d21';
export const impactDisabilityIndependence = 'self-care-black';
export const impactDisabilityLearning = 'impact-black';
export const invoiceCharcoal = 'invoice_charcoal';
export const jpgFileIcon = 'jpg-file-icon';
export const LifestageCharcoal = 'lifestage';
export const LifestageRed = 'lifestage--red';
export const LibraryWhite = 'library-white';

//./LeapIn_app_icon_red_180x180px_sRGB.png

export const LILogo = 'logo';
export const BCLogo = 'BlueCare_app_icon_red_180x180px_sRGB';
export const UCQLogo = 'UCQ_app_icon_180x180px_sRGB';
export const BCLogoBlueBg = 'BlueCare_app_icon_blue_144x144px_sRGB';
export const LoginSignUpImage = 'iStock-94474531.jpg';
export const moreAboutMeCharcoal = 'aboutme-red';
export const moreAboutMeRed = 'aboutme-red';
export const mobileCharcoal = 'mobile-charcoal';
export const mobileRed = 'mobile-red';
export const NextArrowCharcoal = 'next-arrow-charcoal';
export const padlockCharcoal = 'accessrights-charcoal';
export const paymentHistoryCharcoal = 'payments-history--charcoal';
export const pdfFileIcon = 'pdf-file-icon';
export const PlaceholderGrey = 'PlaceholderGrey';
export const PlaceholderImage = 'PlaceholderImage';
export const PlaceholderImageProfile = 'head-silhouette';
export const PlanManagementIconCharcoal = 'plan-management-black';
export const PlanManagementIconRed = 'plan-management-black';
export const PlanSummaryIconCharcoal = 'PlanSummaryRed';
export const PlanSummaryIconRed = 'PlanSummaryRed';
export const printIconCharcoal = 'print-charcoal';
export const prioritiseIconCharcoal60 = 'prioritise--charcoal60';
export const PRManageMembersImage = 'members';
export const PRManageMembersSelectedImage = 'managemembers-selected--red';
export const PRNotificationsImage = 'notifications--white';
export const PRNotificationsSelectedImage = 'notifications-selected--black';
export const PRPlanMeetingImage = 'planmeeting--white';
export const PRPlanMeetingSelectedImage = 'plan-meeting-red-active';
export const PRProfileImage = 'my-profile-white';
export const PRProfileSelectedImage = 'profile-selected--red';
export const RedArrow = 'red_arrow';
export const SearchImageCharcoal = 'search';
export const ServiceAgreementsCharcoal = 'service-agreements--charcoal';
export const SettingsImageCharcoal = 'settings--charcoal';
export const SettingsImageWhite = 'settings-rev';
export const settingsScreenLogo = 'settings-logo';
export const shareIconCharcoal = 'icon-share-charcoal';
export const SidebarBackground = 'iStock-469910608.jpg';
export const SidebarBackgroundPlanManagement = 'iStock-612730282.jpg';
export const SignUpOptionsImage = 'nathan-anderson-157614.jpg';
export const SignUpOptionsNewExistingImage = 'iStock-585800040.jpg';
export const sortingArrowsWhiteIcon = 'sorting-arrows-white';
export const sortingDownArrowWhiteIcon = 'sorting-downarrow-white';
export const sortingUpArrowWhiteIcon = 'sorting-uparrow-white';
export const SupportsChangeSelected = 'supports-change-selected';
export const SupportsChangeUnselected = 'supports-change-unselected';
export const supportsIconCharcoal = 'supports--charcoal';
export const supportsIconRed = 'suports-red';
export const supportsOthers = 'supports-others';
export const supportsIconWhite = 'supports--white';
export const SupportsKeepSelected = 'supports-keep-selected';
export const SupportsKeepUnselected = 'supports-keep-unselected';
export const SupportsStopSelected = 'supports-stop-selected';
export const SupportsStopUnselected = 'supports-stop-unselected';
export const TermsConditionsPMImage = 'iStock-174632986.jpg';
export const ThumbsDownCharcoal = 'thumbs-down-charcoal';
export const ThumbsDownRed = 'thumbs-down--red';
export const ThumbsDownReversedRed = 'thumbs-down-reversed--red';
export const ThumbsDownWhite = 'thumbs-down-white';
export const ThumbsUpCharcoal = 'thumbs-up-characoal';
export const ThumbsUpGreen = 'thumbs-up--green';
export const ThumbsUpRed = 'thumbs-up-red';
export const ThumbsUpWhite = 'thumbs-up-white';
export const tickCharcoal60 = 'check_tick';
export const TickCirclePurple = 'circle-tick';
export const TickCircleGreen = 'circle-tick';
export const TickCircleGray = 'circle-tick';
export const TickGreen = 'check_tick';
export const TickWhite = 'check_tick';
export const UploadIconCharcoal = 'upload-plan-charcoal';
export const UploadIconRed = 'upload-plan-red';
export const UploadIconRedX = 'upload-red-x4';
export const TrackerSplashGoal = 'photo-1464582883107-8adf2dca8a9f.jpeg';
export const viewIconCharcoal = 'icon-view-charcoal';
export const viewIconCharcoal60 = 'icon-view-charcoal';
export const supportCapital = 'capital-supports_charcoal';
export const supportCore = 'core-supports_charcoal';
export const supportCapacity = 'capacity-building_charcoal';
export const Tracking = 'tracking-black';
export const Refresh = 'refresh';
export const DotIcon = 'Dot-icon';
export const BudgetsIconSmall = 'dollar-sign';
export const CalendarIconSmall = 'calendar--charcoal--small';
export const ServiceAgreementsIconSmall = 'service-agreements-charcoal';
export const LILogoSmall = 'logo';
export const BCLogoSmall = 'BlueCare_app_icon_red_180x180px_sRGB--small';
export const PlanSummaryIconCharcoalSmall = 'plansummary-red';
export const BudgetsIconCharcoal = 'dollar-sign';
export const goalsIconSelectedCharcoal = 'goals-selected--charcoal';
export const PRPlanMeetingSelectedCharcoal = 'planmeeting--charcoal';
export const PRProfileSelectedCharcoal = 'profile-selected--charcoal';
export const PRManageMembersSelectedImageCharcoal =
  'managemembers-selected--charcoal';
export const skipButton = 'skip-button-black';
export const helpRedIcon = 'help-red';
export const documentsRedIcon = 'documents-charcoal';
export const messagesRedIcon = 'messages-red';
export const MessagesGrey3x = 'messages-red';
export const settingsRedIcon = 'settings-red';
export const planManagementOptionsRedIcon = 'plan-management-black';
export const openDockedDrawer = 'open-docked-drawer';
export const closeDockedDrawer = 'closed---docked-drawer';

// New Red Icons
export const ApprovalsRed = 'approval-red';
export const ApprovalsRed2x = 'approval-red';
export const ApprovalsRed3x = 'approval-red';
export const ClaimInvoiceRed = 'red-claim-invoice';
export const ClaimInvoiceRed2x = 'red-claim-invoice';
export const ClaimInvoiceRed3x = 'red-claim-invoice';
export const DocumentsRed = 'documents-icon-charcoal';
export const DocumentsRed2x = 'documents-icon-charcoal';
export const DocumentsRed3x = 'documents-icon-charcoal';
export const HelpRed = 'help-red';
export const HelpRed2x = 'help-red-2x';
export const HelpRedIcon3x = 'help-red-3x';
export const LISignUpPlanLogo = 'logo';
export const LISignUpPlanLogo2x = 'logo';
export const LISignUpPlanLogo3x = 'logo';
export const MessagesRed = 'notifications-2-rev';
export const MessagesRed2x = 'notifications-2-rev';
export const MessagesRed3x = 'notifications-2-rev';
export const NDISCheckListRed = 'checklist-rev';
export const NDISCheckListRed2x = 'checklist-rev';
export const NDISCheckListRed3x = 'checklist-rev';
export const PaymentHistoryRed = 'payments-history-charcoal';
export const PaymentHistoryRed2x = 'payments-history-charcoal';
export const PaymentHistoryRed3x = 'payments-history-charcoal';
export const PlanManagementOptionsRed = 'plan-management-black';
export const PlanManagementOptionsRed2x = 'plan-management-black';
export const PlanManagementOptionsRed3x = 'plan-management-black';
export const PlanSummaryRed = 'plansummary-red';
export const PlanSummaryRed2x = 'plansummary-red';
export const PlanSummaryRed3x = 'plansummary-red';
export const SettingsRed = 'settings-red';
export const SettingsRed2x = 'settings-red';
export const SettingsRed3x = 'settings-red';
export const ServiceAgreement = 'service-agreements-charcoal';
export const ServiceAgreement2x = 'service-agreements-charcoal';
export const ServiceAgreement3x = 'service-agreements-charcoal';
export const skipButtonBlack = 'skip_button_black';
export const participant_image = 'participant_image';
export const crew_image = 'crew_image';
export const dropDownArrow = 'drop-down-arrow';
export const noWifiIcon = 'no_wifi_icon';
export const passwordResetAvatar = 'password_reset_avatar';
export const ExplorePlanManagement = 'binoculars';
export const ExplorePlanManagement2x = 'binoculars';
export const ExplorePlanManagement3x = 'binoculars';
export const ExplorePlanManagement4x = 'binoculars';
export const MediumRedTickCircleRed = 'medium-red-tick-circle';
export const MediumRedTickCircleRed2x = 'medium-red-tick-circle';
export const MediumRedTickCircleRed3x = 'medium-red-tick-circle';
export const MediumRedEmptyCircleRed = 'medium-red-tick-circle-empty';
export const MediumRedEmptyCircleRed2x = 'medium-red-tick-circle-empty';
export const MediumRedEmptyCircleRed3x = 'medium-red-tick-circle-empty';
export const FavouriteThingsPurple = 'favourite-things';
export const FavouriteThingsPurple2x = 'favourite-things';
export const FavouriteThingsPurple3x = 'favourite-things';
export const FavouriteThingsCharcoal = 'favourite-things';
export const FavouriteThingsCharcoal2x = 'favourite-things';
export const FavouriteThingsCharcoal3x = 'favourite-things';
export const FavouriteThingsRed = 'favourite-things';
export const FavouriteThingsRed2x = 'favourite-things';
export const FavouriteThingsRed3x = 'favourite-things';
export const FavouriteThingsPurplePlus = 'favourite-future-charcoal';
export const FavouriteThingsPurplePlus2x = 'favourite-future-charcoal';
export const FavouriteThingsPurplePlus3x = 'favourite-future-charcoal';
export const FavouriteThingsCharcoalPlus = 'favourite-future-charcoal';
export const FavouriteThingsCharcoalPlus2x = 'favourite-future-charcoal';
export const FavouriteThingsCharcoalPlus3x = 'favourite-future-charcoal';
export const FavouriteThingsRedPlus = 'favourite-future-charcoal';
export const FavouriteThingsRedPlus2x = 'favourite-future-charcoal';
export const FavouriteThingsRedPlus3x = 'favourite-future-charcoal';
export const ThreeDot = '3dot';
export const MissingInformation = 'missing-information-mobile'; // to be updated with the original image
export const MissingInformation2X = 'missing-information-2x'; // to be updated with the original image
export const MissingInformation3X = 'missing-information-3x'; // to be updated with the original image
export const DocumentsPurple = 'documents-icon-charcoal';
export const DocumentsPurple2x = 'documents-icon-charcoal';
export const DocumentsPurple3x = 'documents-icon-charcoal';
export const ThumbsDownPurple = 'thumbs-down-red';
export const ThumbsDownPurple2x = 'thumbs-down-red';
export const ThumbsDownPurple3x = 'thumbs-down-red';
export const ThumbsUpPurple = 'thumbs-up-red';
export const ThumbsUpPurple2x = 'thumbs-up-red';
export const ThumbsUpPurple3x = 'thumbs-up-red';
export const AboutMePurple = 'aboutme-red';
export const AboutMePurple2x = 'aboutme-red';
export const AboutMePurple3x = 'aboutme-red';
export const CrewPurple = 'crew-2-charcoal';
export const CrewPurple2x = 'crew-2-charcoal';
export const CrewPurple3x = 'crew-2-charcoal';
export const HomePurple = 'home-charcoal';
export const HomePurple2x = 'home-charcoal';
export const HomePurple3x = 'home-charcoal';
export const HomeModificationPurple = 'homesupport-red';
export const HomeModificationPurple2x = 'homesupport-red';
export const HomeModificationPurple3x = 'homesupport-red';
export const HomeModificationRed = 'homesupport-red';
export const HomeModificationRed2x = 'homesupport-red';
export const HomeModificationRed3x = 'homesupport-red';
export const HomeModificationCharcoal = 'homesupport-red';
export const HomeModificationCharcoal2x = 'homesupport-red';
export const HomeModificationCharcoal3x = 'homesupport-red';
export const CloseButtonPruple = 'close-button-purple-mobile';
export const CloseButtonPruple2x = 'close-button-purple-2x';
export const CloseButtonPruple3x = 'close-button-purple-3x';
export const CloseButtonCharcoal = 'close-button-charcoal-mobile';
export const CloseButtonCharcoal2x = 'close-button-charcoal-2x';
export const CloseButtonCharcoal3x = 'close-button-charcoal-3x';
export const CloseButtonPopupModal = 'close-button-popup-modal-mobile';
export const CloseButtonPopupModal2x = 'close-button-popup-modal-2x';
export const CloseButtonPopupModal3x = 'close-button-popup-modal-3x';
export const CloseButtonRed = 'close-button-red-mobile';
export const CloseButtonRed2x = 'close-button-red-2x';
export const CloseButtonRed3x = 'close-button-red-3x';
export const CheckedBoxPurple = 'checked-box-purple-mobile';
export const CheckedBoxPurple2x = 'checked-box-purple-x2';
export const CheckedBoxPurple3x = 'checked-box-purple-x3';
export const UncheckedBoxPurple = 'unchecked-box-purple-mobile';
export const UncheckedBoxPurple2x = 'unchecked-box-purple-x2';
export const UncheckedBoxPurple3x = 'unchecked-box-purple-x3';
export const ImpactOfMyDiabilityPurple =
  'impact-of-my-disability-purple-mobile';
export const ImpactOfMyDiabilityPurple2x = 'impact-of-my-disability-purple-2x';
export const ImpactOfMyDiabilityPurple3x = 'icon-moreaboutme-red';
export const ImpactOfMyDiabilityRed = 'impact-of-my-disability-red-mobile';
export const ImpactOfMyDiabilityRed2x = 'impact-of-my-disability-red-2x';
export const ImpactOfMyDiabilityRed3x = 'impact-of-my-disability-red-3x';
export const ImpactOfMyDiabilityCharcoal =
  'impact-of-my-disability-charcoal-mobile';
export const ImpactOfMyDiabilityCharcoal2x =
  'impact-of-my-disability-charcoal-2x';
export const ImpactOfMyDiabilityCharcoal3x =
  'impact-of-my-disability-charcoal-3x';
export const DisabilityPurple = 'icon-primarydisability-red';
export const DisabilityPurple2x = 'icon-primarydisability-red';
export const DisabilityPurple3x = 'icon-primarydisability-red';
export const DisabilityRed = 'icon-primarydisability-red';
export const DisabilityRed2x = 'icon-primarydisability-red';
export const DisabilityRed3x = 'icon-primarydisability-red';
export const DisabilityCharcoal = 'icon-primarydisability-red';
export const DisabilityCharcoal2x = 'icon-primarydisability-red';
export const DisabilityCharcoal3x = 'icon-primarydisability-red';
export const LifeStagePurple = 'lifestage';
export const LifeStagePurple2x = 'lifestage';
export const LifeStagePurple3x = 'lifestage';
export const LifeStageRed = 'lifestage';
export const LifeStageRed2x = 'lifestage';
export const LifeStageRed3x = 'lifestage';
export const LifeStageCharcoal = 'lifestage';
export const LifeStageCharcoal2x = 'lifestage';
export const LifeStageCharcoal3x = 'lifestage';
export const SignUpForPMAvatar3x = 'sign-up-avatar-3x';
export const Logout = 'logout-2';
export const Logout2x = 'logout-2';
export const Logout3x = 'logout-2';
export const Logout4x = 'logout-2';
export const GreyClockIcon = 'time';
export const GreyClockIcon2x = 'time';
export const GreyClockIcon3x = 'time';
export const SADetail3x = 'service-aggreement-detail-3x';
export const RightTick3x = 'right-tick-3x';
export const CollapseCharcoal3x = 'collapse-charcoal-3x';
export const CollapsePurple3x = 'collapse-purple-3x';
export const CollapseRed3x = 'collapse-red-3x';
export const ExpandCharcoal3x = 'expand-charcoal-3x';
export const ExpandPurple3x = 'expand-purple-3x';
export const ExpandRed3x = 'expand-red-3x';
export const CameraGreyMobile = 'camera-grey-mobile';
export const CameraGrey2x = 'camera-grey-x2';
export const CameraGrey3x = 'camera-grey-x3';
export const CameraGrey4x = 'camera-grey-x4';
export const GalleryGrayMobile = 'gallery-grey-mobile';
export const GalleryGray2x = 'gallery-grey-x2';
export const GalleryGray3x = 'gallery-grey-x3';
export const GalleryGray4x = 'gallery-grey-x4';
export const EyeRed = 'icon-view-charcoal';
export const PrinterRed = 'print-charcoal';
export const EyeRed2x = 'icon-view-charcoal';
export const PrinterRed2x = 'print-charcoal';
export const EyeRed3x = 'icon-view-charcoal';
export const EyeRed4x = 'eye-view-grey';
export const PrinterRed3x = 'print-charcoal';
export const PrinterRed4x = 'print-charcoal';
export const ChangePasswordRed2x = 'change-password-red-x2';
export const ChangePasswordRed3x = 'change-password-red-x3';
export const ChangePasswordRed4x = 'change-password-red-x4';
export const InvoiceDollarPurple2x = 'dollar-sign';
export const InvoiceDollarPurple3x = 'dollar-sign';
export const InvoiceDollarRed2x = 'dollar-sign';
export const InvoiceDollarCharcoal2x = 'dollar-sign';
export const viewIconPurple2x = 'view-icon-purple-2x';
export const CloseAccountRedx1 = 'close-account-red-x1';
export const CloseAccountRedx2 = 'close-account-red-x2';
export const CloseAccountRedx3 = 'close-account-red-x3';
export const CloseAccountRedx4 = 'close-account-red-x4';
export const HideCharcoal60 = 'hide--charcoal60';
export const CollapseCharcoalx3 = 'caret-up';
export const ExpandCharcoalx3 = 'caret-down-charcoal';
export const MyDocumentsMobile = 'service-agreements-charcoal';
export const MyDocuments2x = 'service-agreements-charcoal';
export const MyDocuments3x = 'service-agreements-charcoal';
export const MyDocuments4x = 'service-agreements-charcoal';
export const MyDocumentsRed4x = 'service-agreements-charcoal';
export const DocumentPurpleMobile = 'documents-charcoal';
export const DocumentPurple2x = 'documents-charcoal';
export const DocumentPurple3x = 'documents-charcoal';
export const DocumentPurple4x = 'documents-charcoal';
export const InformationIconx3 = 'information-yellow-charcoal-x3';
export const MembersRed = 'members';
export const MembersRedx2 = 'members';
export const MembersRedx3 = 'members';
export const MembersWhite = 'members';
export const MembersWhitex2 = 'members';
export const MembersWhitex3 = 'members';
export const MembersCharcoal = 'members';
export const MembersCharcoalx2 = 'members';
export const MembersCharcoalx3 = 'members';
export const SupportCharcoal = 'suports-red';
export const SupportCharcoal2x = 'suports-red';
export const SupportCharcoal3x = 'suports-red';
export const SupportRed = 'suports-red';
export const SupportRed2x = 'suports-red';
export const SupportRed3x = 'suports-red';
export const SupportPurple = 'suports-red';
export const SupportPurple2x = 'suports-red';
export const SupportPurple3x = 'suports-red';
export const AddMembersRed4x = 'add-member';
export const RefreshIcon = 'refresh-black';
export const CheckbocChecked = 'cb_enabled';
export const CheckbocDisabled =  'cb_disabled';
