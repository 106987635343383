/*
 * Author: Declan Hart
 * Date: 09/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Supports actions for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const actionGetSupports = (SupportsObj: any) => ({
  type: types.GET_SUPPORTS,
  SupportsObj,
});
export const actionGetSupportsCategories = (CategoriesObj: any) => ({
  type: types.GET_SUPPORT_CATEGORIES,
  CategoriesObj,
});
export const actionGetSupportGroups = (groupObj: any) => ({
  type: types.GET_SUPPORT_ITEM_GROUPS,
  groupObj,
});
export const actionGetSupportItems = (itemsObj: any) => ({
  type: types.GET_SUPPORT_ITEMS,
  itemsObj,
});
export const actionCreateSupport = (SupportObj: any) => ({
  type: types.CREATE_SUPPORT,
  SupportObj,
});
export const actionDeleteSupport = (support: any) => ({
  type: types.DELETE_SUPPORT,
  support,
});
export const setSupportImage = (imageObjectUpload: any) => ({
  type: types.SUPPORTS.SET_SUPPORT_IMAGE,
  imageObjectUpload,
});
export const setSupportImageInList = (supportId, imageObjectUpload) => ({
  type: types.SUPPORTS.SET_SUPPORT_IMAGE_LIST_LOCAL,
  supportId,
  imageObjectUpload,
});
export const addOwnSupportItemsAction = (OwnSupports: any) => ({
  type: types.ADD_OWN_SUPPORT_ITEMS_ACTION,
  OwnSupports,
});
export const removeOwnSupportItemsAction = (index: any) => ({
  type: types.REMOVE_OWN_SUPPORT_ITEMS_ACTION,
  index,
});
export const setSupportInitialState = () => ({
  type: types.RESET_TEMP_SUPPORT,
});
export const addTempSupportItemsAction = (tempSupportItems: any) => ({
  type: types.ADD_TEMP_SUPPORT_ITEMS,
  tempSupportItems,
});
export const addTempSupportCategoriesAction = (tempSupportCategories: any) => ({
  type: types.ADD_TEMP_SUPPORT_CATEGORIES,
  tempSupportCategories,
});
export const addTempSupportCrewAction = (tempSupportCrew: any) => ({
  type: types.ADD_TEMP_SUPPORT_CREW,
  tempSupportCrew,
});
export const addSupportStepAction = (addSupportStep: any) => ({
  type: types.ADD_SUPPORT_STEP,
  addSupportStep,
});
export const addPhotoCaption = (supportId, photoCaption) => ({
  type: types.ADD_SUPPORT_PHOTO_CAPTION,
  supportId,
  photoCaption,
});

export const actionSetReferral = (referrals: any) => ({
  type: types.SET_LIST_REFERRALS,
  referrals,
});
