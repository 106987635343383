/*
 * Author: Shelan Cooray
 * Date: 04/07/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * GuideMeModal Pop-up for Primary Navigation
 */

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Alert} from 'react-native';

import PopupModal from '../Components/Molecules/PopupModal';
import * as TabBarActions from '../Actions/TabBarActions';
import * as GuideMeActions from '../Actions/GuideMeActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import * as UserActions from '../Actions/UserActions';
import {callAPIs} from '../API/APICaller';
import {getGuideMeContent} from '../API/GuideMeAPI';
import {removeItem} from '../API/WebPersistenceStore';
import logger from 'helpers/Logger';

import {updateMember} from '../API/MemberAPI';
import {
  APP_ID,
  WELCOME_GUIDE_ME,
  ON_BOARDED_WELCOME_GUIDE_ME,
  GUIDEME_GENERAL_CONTENT,
  GUIDE_ME,
  WELCOME_GUIDE_ME_DUAL_BRANDED,
  MEMBER_STATUS_DATA,
  WELCOME_GUIDE_ME_YOORALLA,
  WELCOME_GUIDE_ME_WEB,
  SHOW_BOOK_MARK_GUIDE_ME,
  BUDGETS,
} from '../Constants/Constants2';
import {isWeb }from '../Constants/Constants';
import {ERRORS} from '../Constants/Messages';

import {APP_ID as appIds} from '../environments';

class GuideMeModal extends Component {
  state = {
    loading: true,
    dataArray: [],
  };

  constructor(props: any) {
    super(props);
    this._closeModal.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.tabBar && this.props.tabBar.showGuideMe) {
      if (
        this.props.guideMe.showWelcomeGuide &&
        !(this.props.user.demoProfile && this.props.user.isShowcase)
      ) {
        if (appIds === APP_ID.LEAPIN) {
          if (isWeb) {
            this.props.GuideMeActions.getGuideMeResults(WELCOME_GUIDE_ME_WEB);
            this.setState({
              loading: false,
              dataArray: WELCOME_GUIDE_ME_WEB,
            });
          } else {
            this.props.GuideMeActions.getGuideMeResults(WELCOME_GUIDE_ME);
            this.setState({
              loading: false,
              dataArray: WELCOME_GUIDE_ME,
            });
          }
        } else if (appIds === APP_ID.YOORALLA) {
          this.props.GuideMeActions.getGuideMeResults(
            WELCOME_GUIDE_ME_DUAL_BRANDED,
          );
          this.setState({
            loading: false,
            dataArray: WELCOME_GUIDE_ME_YOORALLA,
          });
        } else {
          this.props.GuideMeActions.getGuideMeResults(
            WELCOME_GUIDE_ME_DUAL_BRANDED,
          );
          this.setState({
            loading: false,
            dataArray: WELCOME_GUIDE_ME_DUAL_BRANDED,
          });
        }
      } else if (this.props.guideMe.showWelcomeManagedGuide) {
        this.props.GuideMeActions.getGuideMeResults(
          ON_BOARDED_WELCOME_GUIDE_ME,
        );
        this.setState({
          loading: false,
          dataArray: ON_BOARDED_WELCOME_GUIDE_ME,
        });
      } else if (this.props.user.demoProfile && this.props.user.isShowcase) {
        if (this.props.guideMe.demoGuideMeType === BUDGETS) {
          this.setState({
            loading: false,
            dataArray: ON_BOARDED_WELCOME_GUIDE_ME,
          });
        } else {
          this.setState({
            loading: false,
            dataArray: WELCOME_GUIDE_ME_YOORALLA,
          });
        }
      } else {
        this._retrieveGuideMeContent(this.props.guideMe.currentScreen);
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (
      this.props.tabBar.showGuideMe !== nextProps.tabBar.showGuideMe &&
      nextProps.tabBar.showGuideMe
    ) {
      if (this.props.user.demoProfile && this.props.user.isShowcase) {
        if (this.props.guideMe.demoGuideMeType === BUDGETS) {
          this.setState({
            loading: false,
            dataArray: ON_BOARDED_WELCOME_GUIDE_ME,
          });
        } else {
          if (isWeb) {
            this.setState({
              loading: false,
              dataArray: WELCOME_GUIDE_ME_WEB,
            });
          } else {
            this.setState({
              loading: false,
              dataArray: WELCOME_GUIDE_ME,
            });
          }
        }
      } else if (nextProps.guideMe.showWelcomeGuide) {
        if (isWeb) {
          this.props.GuideMeActions.getGuideMeResults(WELCOME_GUIDE_ME_WEB);
          this.setState({
            loading: false,
            dataArray: WELCOME_GUIDE_ME_WEB,
          });
        } else {
          this.props.GuideMeActions.getGuideMeResults(WELCOME_GUIDE_ME);
          this.setState({
            loading: false,
            dataArray: WELCOME_GUIDE_ME,
          });
        }
      } else if (
        nextProps.guideMe.showWelcomeManagedGuide &&
        nextProps.member.memberStatus === MEMBER_STATUS_DATA.MANAGED
      ) {
        this.props.GuideMeActions.getGuideMeResults(
          ON_BOARDED_WELCOME_GUIDE_ME,
        );
        this.setState({
          loading: false,
          dataArray: ON_BOARDED_WELCOME_GUIDE_ME,
        });
      } else if (this.props.user.demoProfile && this.props.user.isShowcase) {
        if (this.props.guideMe.demoType === BUDGETS) {
          this.setState({
            loading: false,
            dataArray: ON_BOARDED_WELCOME_GUIDE_ME,
          });
        } else {
          this.setState({
            loading: false,
            dataArray: WELCOME_GUIDE_ME_YOORALLA,
          });
        }
      } else {
        //Set showWelcomeManagedGuide to false if user status is not plan managed
        if (nextProps.guideMe.showWelcomeManagedGuide) {
          this.props.GuideMeActions.showWelcomeManagedGuide(false);
        }
        this._retrieveGuideMeContent(nextProps.guideMe.currentScreen);
      }
    }
  }

  _retrieveGuideMeContent = (currentScreen: any) => {
    this.setState({
      loading: true,
      dataArray: [],
    });

    const callbackFunction = (results: any) => {
      let guideMePayload = results[0].content.instructionResult;

      if (!Array.isArray(guideMePayload) || !guideMePayload.length) {
        guideMePayload = GUIDEME_GENERAL_CONTENT;
      }

      // Write the data to redux
      this.props.GuideMeActions.getGuideMeResults(guideMePayload);
      this.setState({
        loading: false,
        dataArray: this.props.guideMe.guideMeContent,
      });
    };

    const guideMeErrorCallback = (err: any) => {
      if (err.message) {
        if (err.message !== ERRORS.NO_INTERNET) {
          Alert.alert(ERRORS.GENERIC_GUIDE_ME_ERROR_ALERT);
        }
        this.setState({
          loading: false,
        });
      }
    };

    callAPIs(
      [
        getGuideMeContent(
          this.props.loadedMemberId.loadedMemberId,
          currentScreen,
          this.props.user.demoProfile,
        ),
      ],
      callbackFunction,
      guideMeErrorCallback,
      () => {
        this.setState({
          loading: false,
        });
      },
    );
  };

  _closeModal = () => {
    if (this.props.user.demoProfile && this.props.user.isShowcase) {
      this.props.TabBarActions.hideGuideMe(this.props.tabBar.selectedTab);
      return this.props.LogoutConfirmationActions.hideConfirmLogout({
        logUserOut: true,
      });
    }

    if (this.props.guideMe.showWelcomeManagedGuide) {
      const updateUserObj = {
        id: this.props.user.id,
        isOnboardingGuideRequired: 0,
        updateGuideRequiredFlag: true,
      };

      const updateMemberCallback = () => {
        this.props.UserActions.setGuideStatus(
          updateUserObj.isOnboardingGuideRequired,
        );
        this.setState({loading: false});
      };

      callAPIs(
        [updateMember(updateUserObj)],
        updateMemberCallback,
        null,
        () => {
          this.setState({loading: false});
        },
      );
    }

    if (isWeb) {
      removeItem(SHOW_BOOK_MARK_GUIDE_ME);
    }
    this.props.GuideMeActions.setHighlightedMenuItem(null);

    this.props.TabBarActions.hideGuideMe(this.props.tabBar.selectedTab);
  };

  render() {
    return (
      <PopupModal
        visible={this.props.tabBar.showGuideMe}
        close={this._closeModal}
        navigation={this.props.navigation}
        dataArray={this.state.dataArray}
        loading={this.state.loading}
        modalTitle={GUIDE_ME}
        windowWidth={this.props.windowWidth}
        isMobile={this.props.isMobile}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  tabBar: state.TabBarReducer,
  guideMe: state.GuideMeReducer,
  loadedMemberId: state.LoadedMemberReducer,
  member: state.MemberReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  TabBarActions: bindActionCreators(TabBarActions, dispatch),
  GuideMeActions: bindActionCreators(GuideMeActions, dispatch),
  UserActions: bindActionCreators(UserActions, dispatch),

  LogoutConfirmationActions: bindActionCreators(
    LogoutConfirmationActions,
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(GuideMeModal);
