/*
 * Author: Brad D'Costa
 * Date: 20/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The initial screen for Plan Management Terms and Conditions
 */

import React, {Component} from 'react';
import {View, Dimensions} from 'react-native';
//import {WebView} from 'react-native-webview';
import {WebViewWeb as WebView} from 'helpers/ReactNativeWebview';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import * as brand from '../Constants/BrandConstants';
import {TERMS_CONDITIONS_FULL_PM_SCREEN} from '../Constants/Messages';

import {StandardText, StandardButton} from '../Components/Atoms';
import {Container} from '../Components/Molecules';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {updateMember} from '../API/MemberAPI';
import {callAPIs} from '../API/APICaller';
import * as MemberActions from '../Actions/MemberActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {firebaseAnalysisLogEvent} from '../Helpers/PlatformSynchronizer';
import { RootTabContext } from '../context/RootTabContext';

class TermsAndConditionsFullPM extends Component {
  static context:React.ContextType<typeof RootTabContext> = RootTabContext;
  state = {
    loading: false,
    saveAPIError: false,
  };
  constructor(props: any) {
    super(props);
  }

  render() {
    if (types.isWeb) {
      return this.createMainContents();
    } else {
      return (
        <Container
          contents={this.createMainContents}
          ignorePadding={true}
          removeBottomPadding={true}
          loading={this.state.loading}
          modals={[]}
          needsSidebar={false}
          screenType={types.SCREEN_TYPE_MAIN}
          activeScreen={types.SCREEN_TERMS_CONDITIONS_FULL_PM}
          nav={this.props.navigation}
          toggleMenu={this.context?.toggleMenu}
          getInitialMenuState={this.context?.getInitialMenuState}
          headerTitle={types2.NAVIGATION_TEXT.TERMS_AND_CONDITIONS}
          demoProfile={this.props.user.demoProfile}
          showConfirmLogout={
            this.props.actions.LogoutConfirmationActions.showConfirmLogout
          }
        />
      );
    }
  }

  createMainContents = () => {
    return (
      <View style={[CommonStyles.content]}>
        <View
          style={[
            CommonStyles.singleMainContentContainerForTANDC,
            CommonStyles.marginTop35,
            types.isWeb
              ? {height: Dimensions.get('window').height * 0.7}
              : null,
          ]}
        >
          {types.isWeb ? (
            <WebView
              title="TermsAndConditions"
              style={CommonStyles.WebViewStyle}
              source={brand.APP_PLAN_MANAGER_TERMS_AND_CONDITIONS_URL}
            />
          ) : (
            <WebView
            source={brand.APP_PLAN_MANAGER_TERMS_AND_CONDITIONS_URL}
            javaScriptEnabled={true}
            domStorageEnabled={true}
            startInLoadingState={true}
          />
          )}
        </View>
        <View style={[CommonStyles.containerTerms, CommonStyles.elevation5]}>
          <View style={[CommonStyles.ModalActionButtons]}>
            <View
              style={[
                CommonStyles.ModalFooterLeft,
                CommonStyles.paddingBottom10,
              ]}
            >
              <StandardButton
                onPress={() =>
                  this.props.navigation.navigate('TermsAndConditionsPM')
                }
                style={[
                  BrandStyles.brandBlockTxtColor6,
                  CommonStyles.buttonFormAction,
                  CommonStyles.customFontBold,
                ]}
                containerStyle={[
                  BrandStyles.primaryBgColor1,
                  BrandStyles.brandBorderColor1,
                  CommonStyles.buttonContainerFormAction,
                ]}
                accessibilityLabel={
                  TERMS_CONDITIONS_FULL_PM_SCREEN.CANCEL_BUTTON_TEXT
                }
              >
                {TERMS_CONDITIONS_FULL_PM_SCREEN.CANCEL_BUTTON_TEXT}
              </StandardButton>
            </View>
            <View
              style={[
                CommonStyles.ModalFooterRight,
                CommonStyles.paddingBottom10,
              ]}
            >
              <StandardButton
                onPress={() => {
                  if (!types.isWeb) {
                    firebaseAnalysisLogEvent(
                      types.FIREBASE_ANALYTICS_EVENTS
                        .PLAN_MANAGEMENT_TERMS_AGREED,
                      {},
                    );
                  }

                  this.navigateToUploadPlan();
                }}
                style={[
                  BrandStyles.brandBlockTxtColor2,
                  CommonStyles.buttonFormAction,
                  CommonStyles.customFontBold,
                ]}
                containerStyle={[
                  BrandStyles.primaryBgColor4,
                  BrandStyles.brandBorderColor1,
                  CommonStyles.buttonContainerFormAction,
                ]}
                accessibilityLabel={TERMS_CONDITIONS_FULL_PM_SCREEN.BUTTON}
              >
                {TERMS_CONDITIONS_FULL_PM_SCREEN.BUTTON}
              </StandardButton>
            </View>
          </View>
        </View>
      </View>
    );
  };

  _navigateToUploaPlanScreen = () => {
    this.setState({loading: false});
    if (types.isWeb) {
      this.props.setLoading(false);
    }
    this.props.navigation.navigate('UploadPlan');
  };

  navigateToUploadPlan() {
    this.setState({loading: true});
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    //First trigger a save and pmtnCAccepted
    let saveObj = {
      id: this.props.loadedMemberId.loadedMemberId,
      pmtnCAccepted: true,
    };

    // if pmtnCAccepted is set to true no need to update the user to set pmtnCAccepted to true
    if (this.props.member.pmtnCAccepted) {
      return this._navigateToUploaPlanScreen();
    }

    let updateMemberCallback = (data: any) => {
      var member = data[0].content;
      this.props.actions.MemberActions.actionGetMember(
        member,
        this.props.user.id,
        this.props.user.isEmployee,
        this.props.user.isFinancialStaff,
      );

      this._navigateToUploaPlanScreen();
    };

    callAPIs([updateMember(saveObj)], updateMemberCallback, null, () => {
      this.setState({loading: false});
      if (types.isWeb) {
        this.props.setLoading(false);
      }
    });
  }
}

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  member: state.MemberReducer,
  loadedMemberId: state.LoadedMemberReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    MemberActions: bindActionCreators(MemberActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TermsAndConditionsFullPM);
