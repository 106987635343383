/*
 * Author: Shelan Cooray
 * Date: 28/09/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Very Basic Popup alert Component with Backdrop Overlay to Use Across Leap in App
 */

import React, {Component} from 'react';
import {View, Image} from 'react-native';

import {BasicOverlayPopupModal} from '../Molecules';
import {StandardText} from '../Atoms';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

//import DeviceInfo from 'react-native-device-info';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {ERRORS, MESSAGES} from '../../Constants/Messages';

//Import Images
import {InformationIconx3} from '../../assets/images';

class BasicPopupAlert extends Component {
  isTablet: any;
  constructor(props: any) {
    super(props);
    this.isTablet = false; //DeviceInfo.isTablet();
  }

  getPopUpContent = () => {
    return (
      <View styles={[CommonStyles.popUpAlertAlign]}>
        <View style={[CommonStyles.flexDirectionRow, CommonStyles.alignCenter]}>
          <View>
            <Image
              style={[CommonStyles.basicAlertButtonImage]}
              source={InformationIconx3}
              accessibilityLabel={MESSAGES.ACCESSIBILITY.IMPORTANT_INFORMATION}
              accessible={true}
            />
          </View>
          <View style={[CommonStyles.alignCenter]}>
            <StandardText
              allowFontScaling={false}
              style={this.getHeadingTextStyle()}
            >
              {this.props.headerText}
            </StandardText>
          </View>
        </View>
        <View
          style={[CommonStyles.popUpModalAlert, CommonStyles.textAlignCentre]}
        >
          <StandardText allowFontScaling={false} style={this.getTextStyle()}>
            {this.props.messageText}
          </StandardText>
          {this.props.subContent}
        </View>
        {this.props.forceUpdate && this._renderLatestVersion()}
      </View>
    );
  };

  getHeadingTextStyle = () => {
    let styles = [CommonStyles.customFont, BrandStyles.TextColor5];
    if (types.isWeb || !this.isTablet) {
      styles.push([
        CommonStyles.font18,
        CommonStyles.customFontBold,
        BrandStyles.TextColor10,
      ]);
    } else {
      styles.push(CommonStyles.font25);
    }
    return styles;
  };

  getTextStyle = () => {
    let styles = [
      CommonStyles.customFont,
      BrandStyles.TextColor5,
      this.isTablet ? CommonStyles.font18 : CommonStyles.rpfont15,
    ];

    if (
      this.props.contentStyle != undefined ||
      this.props.contentStyle != null
    ) {
      styles.push(this.props.contentStyle);
    }
    return styles;
  };

  _renderLatestVersion = () => {
    return (
      <View
        style={[
          CommonStyles.alignFlexStart,
          CommonStyles.paddingTop10,
          CommonStyles.marginLeft5,
        ]}
      >
        <StandardText>
          {` ${types2.LATEST_VERSION} ${this.props.latestVersion}`}
        </StandardText>
      </View>
    );
  };
  _closeErrorPopUp = () => {
    this.setState({
      visible: false,
    });
  };

  _determinePopUpStyle = () => {
    const styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (!types.isWeb) {
      if (this.isTablet) {
        styles.push(CommonStyles.containerInstructionPopUpTablet);
      } else {
        styles.push(CommonStyles.changePasswordPopUpMobile);
      }
    } else {
      styles.push(CommonStyles.containerInstructionPopUpWeb);

      if (this.props.isMobile) {
        styles.push([CommonStyles.containerPopupWebMobile]);
      }
    }
    return styles;
  };

  render() {
    return (
      <BasicOverlayPopupModal
        visible={this.props.visible}
        style={this.props.style || this._determinePopUpStyle()}
        backdropOpacity={this.props.backdropOpacity}
        divider={this.props.divider}
        close={this.props.close}
        cancelContainerStyle={this.props.cancelContainerStyle}
        cancelStyle={this.props.cancelStyle}
        contentStyle={this.props.contentStyle}
        saveContainerStyle={this.props.saveContainerStyle}
        cancelLabel={this.props.cancelLabel}
        save={this.props.saveAction}
        saveLabel={this.props.saveLabel}
      >
        <View>{this.getPopUpContent()}</View>
      </BasicOverlayPopupModal>
    );
  }
}

BasicPopupAlert.defaultProps = {
  headerText: ERRORS.TRY_AGAIN,
  messageText: ERRORS.TRY_AGAIN_MESSAGE,
  forceUpdate: null,
  latestVersion: '',
  backdropOpacity: 0.2,
  divider: true,
  cancelContainerStyle: CommonStyles.popUpOkButton,
  cancelStyle: [CommonStyles.buttonPopupOk, CommonStyles.customFontBold],
  cancelLabel: types2.OK_GOT_IT,
  saveLabel: null,
  saveAction: null,
};

export default BasicPopupAlert;
