/*
 * Author: Tharindu Bandara
 * Date: 18/02/2019
 * Copyright © 2019 Leap in!. All rights reserved.
 *
 * Navigation Params Reducer
 */
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function NavigationParamsReducer(state = {}, action: any) {
  switch (action.type) {
    case types.SET_NAVIGATION_PARAM:
      return {
        ...state,
        params: action.params,
      };

    default:
      return state;
  }
}
