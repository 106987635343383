import logger from 'helpers/Logger';
import {
  RESTRICTED_FILE_EXTENSIONS,
  ALLOWED_PHOTO_EXTENSIONS,
} from '../Constants/Constants2';

import {RNFetchBlobSingleton} from './PlatformSynchronizer';

export const uploadFileToS3 = (
  signedUrl: any,
  contentType: any,
  imageUri: any,
) =>
  new Promise((resolve, reject) => {
    // First of all compress the image
    const cleanUri = decodeURI(imageUri.replace('file://', ''));
    RNFetchBlobSingleton.fetch(
      'PUT',
      signedUrl,
      {'Content-Type': contentType},
      RNFetchBlobSingleton.wrap(imageUri),
    )
      .uploadProgress((written, total) => {})
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        logger.log('The error is:', error);
        this.setState({
          saveApiErrorCode: null,
          saveApiError: true,
          loading: false,
        }); // CONFIRM THIS
        reject(error);
      });
  });

/**
 * This function will check given file extension is an allowed
 * one to upload via app
 * @param {String} file
 */
export const fileUploadRestrictCheck = (file: any) => {
  let fileCheck = false;
  const extention = file.split('.').pop().toLocaleLowerCase();

  if (RESTRICTED_FILE_EXTENSIONS.includes(extention)) {
    fileCheck = true;
  }
  return fileCheck;
};

/**
 * This function will check given file extension is an allowed
 * image type to upload via web app
 * @param {String} file
 */
export const photoUploadAllowedCheck = (file: any) => {
  let fileCheck = false;
  const extention = file.split('.').pop().toLocaleLowerCase();

  if (!ALLOWED_PHOTO_EXTENSIONS.includes(extention)) {
    fileCheck = true;
  }
  return fileCheck;
};


export const requestWritePermission = async ()=>{
  return;
}