/*
 * Author: Andrew Seeley
 * Date: 28/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Due to react native's wonderful consistency of the style componenent
 * This class holds a branded activity indicator that can be uses throughout the app
 * This enables us to change it's color in once place
 */

import React, {Component} from 'react';
import {View, ActivityIndicator} from 'react-native';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {BrandColors} from '../../Styles/Colours';
import {isWeb} from '../../Constants/Constants';
import {ACCESSIBILITY_LABELS} from '../../Constants/Constants2';
import {focusOnView} from '../../Helpers/PlatformSynchronizer';
import {StandardText} from '../Atoms';

export class BrandActivityIndicator extends Component {
  loadingIcon: any;
  dispatchFocusInOrder() {
    setTimeout(
      function (this: any) {
        const latestItem = [this.loadingIcon]
          .filter(item => item !== undefined && item != null)
          .pop();
        if (latestItem) {
          focusOnView(latestItem);
        }
      }.bind(this),
      50,
    );
  }

  getOverlayStyles = () => {
    let styles = [CommonStyles.fullscreenActivityIndicator];
    if (this.props.overlayStyles) {
      styles = this.props.overlayStyles;
    }
    return styles;
  };

  getMessageBackgroundStyle = () => {
    if (this.props.loadingText) {
      return [
        BrandStyles.preAuthenticationBlockContainerSolid,
        CommonStyles.loadingModal,
        CommonStyles.navigationConfirmModal,
        BrandStyles.borderColor6,
      ];
    }
    return [];
  };

  render() {
    if (this.props.loading && ((isWeb && this.props.enableWeb) || !isWeb)) {
      if (this.props.noBackground) {
        return (
          <View style={[CommonStyles.bottomPositionActivityIndicator]}>
            <View
              accessible={true}
              accessibilityLabel={ACCESSIBILITY_LABELS.SCREEN_LOADING}
              ref={ref => {
                this.loadingIcon = ref;
              }}
              onLayout={() => {
                this.dispatchFocusInOrder();
              }}
              style={BrandStyles.preAuthenticationBlockContainerSolid}
            >
              <ActivityIndicator
                color={BrandColors.RESPONSIVE_DESIGN_COLORS.CHARCOAL}
                size="large"
              />
              {this.props.loadingText && (
                <StandardText>{this.props.loadingText}</StandardText>
              )}
            </View>
          </View>
        );
      }
      return (
        <View style={this.getOverlayStyles()}>
          <View
            accessible={true}
            accessibilityLabel={ACCESSIBILITY_LABELS.SCREEN_LOADING}
            ref={ref => {
              this.loadingIcon = ref;
            }}
            onLayout={() => {
              this.dispatchFocusInOrder();
            }}
            style={this.getMessageBackgroundStyle()}
          >
            <ActivityIndicator
              color={BrandColors.RESPONSIVE_DESIGN_COLORS.RED}
              size="large"
            />
            {this.props.loadingText && (
              <StandardText
                style={[
                  CommonStyles.marginTop10,
                  CommonStyles.buttonFormAction,
                ]}
              >
                {this.props.loadingText}
              </StandardText>
            )}
          </View>
        </View>
      );
    } else {
      return null;
    }
  }
}

export default BrandActivityIndicator;
