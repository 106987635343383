/*
 * Author: Brad D'Costa
 * Date: 29/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the text and icon combination for the API Error scenario used throughout the app.
 */

import React, {Component} from 'react';
import {Image, View} from 'react-native';

import {StandardText} from '../Atoms';
import {errorRed} from '../../assets/images';
import {
  MISSING_MANDATORY_CHECKBOX,
  NETWORK_FAILURE,
} from '../../Constants/Constants';
import {ERRORS} from '../../Constants/Messages';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';

class ModalApiError extends Component {
  constructor(props: any) {
    super(props);
  }
  render() {
    let errorText = '';
    if (this.props.errorCode === 403) {
      errorText = ERRORS.UNAUTHORISED;
    } else if (this.props.errorCode === MISSING_MANDATORY_CHECKBOX) {
      errorText = ERRORS.MISSING_MANDATORY_CHECKBOX;
    } else {
      errorText = ERRORS.GENERIC;
    }
    if (this.props.errorCode === NETWORK_FAILURE) {
      return null;
    }
    return (
      <View
        style={[CommonStyles.flexDirectionRow, CommonStyles.alignItemsCenter]}
      >
        <View>
          <Image style={[CommonStyles.errorApiIcon]} source={errorRed} />
        </View>
        <View style={[CommonStyles.flex1, CommonStyles.flexDirectionColumn]}>
          <StandardText
            style={[
              CommonStyles.SummaryTileText,
              CommonStyles.inputErrorMessageText,
              CommonStyles.customFont,
            ]}
          >
            {errorText}
          </StandardText>
        </View>
      </View>
    );
  }
}

export default ModalApiError;
