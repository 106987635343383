/*
 * Author: Brad D'Costa
 * Date: 30/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Summary tile on the HealthWelbeing screen for the Documents Section
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View, Platform, Alert} from 'react-native';
// #TODO - Enable Document Picker
// import {
//   DocumentPicker,
//   DocumentPickerUtil
// } from 'react-native-document-picker-enhanced';
// import ImagePicker from 'react-native-image-picker-enhanced';
import * as mime from 'react-native-mime-types-enhanced';
import {
  RNFetchBlobSingleton,
  DocumentPickerSingleton,
  FilePickerSingleton,
  FileDownloaderSingleton,
} from '../../Helpers/PlatformSynchronizer';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as NavigationParamActions from '../../Actions/NavigationParamActions';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {MESSAGES, ERRORS} from '../../Constants/Messages';

import {StandardText, StandardAlert, StandardTouchableOpacity} from '../Atoms';
import {DocumentRestrictedPopUp} from './';
import {SummaryTile, ActionButton} from '../Molecules';

import {DocumentsPurple3x} from '../../assets/images/vector-icons';

import {fileUploadRestrictCheck} from '../../Helpers/FileHelper';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {BrandColors} from '../../Styles/Colours';

class HealthWellbeingDocuments extends Component {
  state = {
    documentList: [],
    loading: true,
    filePickerResponse: {},
    categories: types2.DOC_UPLOAD.CATEGORIES,
    documentCategory: 3,
    permissionAlertMessage: '',
    documentRestrictPoupVisible: false,
  };

  constructor(props: any) {
    super(props);
    this._navigateToDocumentAddForm =
      this._navigateToDocumentAddForm.bind(this);
    this._uploadDocument = this._uploadDocument.bind(this);
  }

  render() {
    return (
      <SummaryTile
        headerDisplayString={
          MESSAGES.HEALTH_WELLBEING_SCREEN.DOCUMENTS_TILE_TITLE
        }
        headerIconImage={DocumentsPurple3x}
        screenType={types.SCREEN_TYPE_MAIN}
        modal={(event: any) => {
          this.onPick(event);
        }}
        containsData={this._determineContainsData()}
        showButton={this._determineAccess()}
        actionOverride={'Add'}
        HeaderTextColor={BrandStyles.TextColor4}
        HeaderBoarderColor={BrandStyles.borderColor4}
        webFileUpload={this._webFileUploadOnChange}
        isWebFileUpload={types.isWeb ? true : false}
      >
        {this._renderDocuments()}
        {this._renderPermisionPopup()}
        <DocumentRestrictedPopUp
          visible={this.state.documentRestrictPoupVisible}
          close={this._closeDocumentAlert}
          isMobile={this.props.isMobile}
        />
      </SummaryTile>
    );
  }

  _closeDocumentAlert = () => {
    this.setState({
      documentRestrictPoupVisible: false,
    });
  };

  _webFileUploadOnChange = (fileObject: any) => {
    const responseModified = fileObject;
    if (fileUploadRestrictCheck(fileObject.name)) {
      this.setState({documentRestrictPoupVisible: true});
    } else {
      responseModified.fileName = fileObject.name;
      this.props.actions.NavigationParamActions.setParam({
        isFromHealthwellbeing: true,
        filePickerResponse: responseModified,
        categories: this.state.categories,
        documentCategory: this.state.documentCategory,
      });

      this.props.navigation.navigate(types.DOCUMENTADDFORM);
    }
  };

  /**
   * this function will show the file picker
   */

  timeoutPicture = null;
  onPick(event: any) {
    event.persist();
    if (Platform.OS === types2.ANDROID) {
      if (this.timeoutPicture) clearTimeout(this.timeoutPicture);
      this.timeoutPicture = setTimeout(() => this._uploadDocument(event), 500);
    } else {
      this._uploadDocument(event);
    }
  }

  _uploadDocument(event: any) {
    if (
      this.props.member &&
      this.props.member.access.document == types.ACCESS_READ_ONLY
    ) {
      return;
    }
    const pageX = event.nativeEvent ? event.nativeEvent.pageX : 0;
    const pageY = event.nativeEvent ? event.nativeEvent.pageY : 0;
    const options = {
      title: MESSAGES.SELECT_DOCUMENT,
      customButtons: [{name: types.FROM_DRIVES_KEY, title: types.FROM_DRIVES}],
      mediaType: 'photo',
      storageOptions: {
        skipBackup: true,
        path: 'images',
      },
    };

    DocumentPickerSingleton(pageX, pageY, options).then(response => {
      const osType=Platform.OS === types2.ANDROID
      let assets = response.assets?.[0];
      if(Platform.OS === types2.IOS){
        assets = response
      }
      if (response.documentRestrictPoupVisible) {
        this.setState({documentRestrictPoupVisible: true});
      } else {
        this.props.actions.NavigationParamActions.setParam({
          isFromHealthwellbeing: true,
          filePickerResponse: {
            fileName: assets.fileName,
            path:Platform.OS === types2.IOS ? assets.path : assets.uri,
            type: assets.type
              ? assets.type
              : mime.types[assets.fileName.split('.').pop()],
            uri: assets.uri,
          },
          categories: this.state.categories,
          documentCategory: this.state.documentCategory,
        });

        this.props.navigation.navigate(types.DOCUMENTADDFORM);
      }
    });
  }
  _closeAlertMessage = () => {
    this.setState({permissionAlertMessage: ''});
  };
  _renderPermisionPopup() {
    return (
      <StandardAlert
        modalTitle={ERRORS.GENERIC_ISSUE_ALERT_TITLE}
        close={this._closeAlertMessage}
        message={this.state.permissionAlertMessage}
      />
    );
  }

  /**
   * function to navigate to upload document screen
   */
  _navigateToDocumentAddForm = () => {
    this.props.actions.NavigationParamActions.setParam({
      isFromHealthwellbeing: true,
    });

    this.props.navigation.navigate(types.DOCUMENTADDFORM);
  };
  _determineAccess() {
    if (Object.getOwnPropertyNames(this.props.readOnly).length === 0) {
      return true;
    } else {
      let readOnly = this.props.readOnly;
      if (readOnly.access) {
        if (readOnly.access.documents) {
          if (readOnly.access.documents == types.ACCESS_READ_ONLY) {
            return false;
          }
        }
      }
    }
    return true;
  }

  _determineContainsData = () => {
    var documents = this._findHealthDocuments();
    if (documents.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  _findHealthDocuments = () => {
    var documents = [];
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
    } else {
      if (this.props.reducer.documentList.length == 0) {
      } else {
        for (var i = 0; i < this.props.reducer.documentList.length; i++) {
          var doc = this.props.reducer.documentList[i];
          if (doc.category === 'Health') {
            documents.push(doc.filename);
          }
        }
      }
    }
    return documents;
  };

  _renderDocuments() {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      if (this.props.reducer.documentList.length == 0) {
        return (
          <View>
            <StandardText
              style={[
                CommonStyles.SummaryTileText,
                BrandStyles.TextColor6,
                CommonStyles.customFont,
              ]}
            >
              {MESSAGES.HEALTH_WELLBEING_SCREEN.NO_HEALTH_DOCUMENTS}
            </StandardText>
          </View>
        );
      } else {
        var documents = this._findHealthDocuments();
        if (documents.length == 0) {
          return (
            <View>
              <StandardText
                style={[
                  CommonStyles.SummaryTileText,
                  BrandStyles.TextColor6,
                  CommonStyles.customFont,
                ]}
              >
                {MESSAGES.HEALTH_WELLBEING_SCREEN.NO_HEALTH_DOCUMENTS}
              </StandardText>
            </View>
          );
        } else {
          return <View>{this._renderHealthDocuments(documents)}</View>;
        }
      }
    }
  }

  _renderHealthDocuments = (documents: any) => {
    return documents.map((doc: any) => (
      <StandardText
        style={[
          CommonStyles.SummaryTileText,
          BrandStyles.TextColor5,
          CommonStyles.customFont,
        ]}
        key={doc}
      >
        {doc}
      </StandardText>
    ));
  };
}

const mapStateToProps = (state: any) => ({
  navigationParams: state.NavigationParamsReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HealthWellbeingDocuments);
