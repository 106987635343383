/*
 * Authour: Nirodha Perera
 * Date: 09/07/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Side Menu instruction that has been obtained.
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {storeData, getData} from '../API/StorageHelper';
// Detect device is a tablet or mobile
//import DeviceInfo from 'react-native-device-info';

export default function SideMenuReducer(state = {}, action: any) {
  switch (action.type) {
    case types2.GET_SIDEMENU:
      return {
        ...state,
        sideMenuContent: action.dynamicSideMenuContent,
      };
    case types2.SIDEMENU_IS_OPEN:
      const isTablet = false; // DeviceInfo.isTablet();
      if (isTablet) {
        storeData(types2.SIDEMENU_STATUS, action.sideMenuIsOpen.toString());
      }
      if (action.sideMenuIsOpen !== undefined) {
        return {
          ...state,
          sideMenuIsOpen: action.sideMenuIsOpen,
        };
      }
      return state;

    case types2.SET_CURRENT_SCREEN:
      return {
        ...state,
        currentScreen: action.currentScreen,
      };
    default:
      return state;
  }
}
