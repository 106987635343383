/*
 * Author: Brad D'Costa
 * Date: 05/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Summary tile on the FavouriteThings screen for the Today Section
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View, Text} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {MESSAGES} from '../../Constants/Messages';

import {StandardText} from '../Atoms';
import {SummaryTile} from '../Molecules';

import {
  FavouriteThingsCharcoal3x,
  FavouriteThingsPurple3x,
} from '../../assets/images/vector-icons';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class FavouriteThingsToday extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <SummaryTile
        headerDisplayString={MESSAGES.FAVOURITE_THINGS_SCREEN.TODAY_TILE_TITLE}
        headerIconImage={FavouriteThingsPurple3x}
        modal={this.props.form}
        containsData={this._determineContainsData()}
        HeaderTextColor={BrandStyles.TextColor4}
        HeaderBoarderColor={BrandStyles.borderColor4}
        actionOverride={this._determineAccess()}
      >
        {this._renderToday()}
      </SummaryTile>
    );
  }

  _determineAccess = () => {
    if (Object.getOwnPropertyNames(this.props.readOnly).length === 0) {
      return null;
    } else {
      let readOnly = this.props.readOnly;
      if (readOnly.access) {
        if (readOnly.access.profile) {
          if (this.props.readOnly.access.profile == types.ACCESS_READ_ONLY) {
            return types.ACTION_BUTTON.VIEW;
          }
        }
      }
    }
    return null;
  };

  _determineContainsData = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return false;
    } else {
      var checkArray = [this.props.reducer.likeToDo];
      var dataCheck = false;
      for (var i = 0; i < checkArray.length; i++) {
        if (checkArray[i] !== '') {
          dataCheck = true;
          break;
        }
      }
      return dataCheck;
    }
  };

  _determineIcon = () => {
    if (this._determineContainsData()) {
      return FavouriteThingsPurple3x;
    } else {
      return FavouriteThingsCharcoal3x;
    }
  };

  _renderToday = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      if (this.props.reducer.likeToDo == '') {
        return null;
      } else {
        return (
          <View>
            <StandardText
              style={[
                CommonStyles.SummaryTileText,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
              ]}
            >
              {this.props.reducer.likeToDo}
            </StandardText>
          </View>
        );
      }
    }
  };
}

export default FavouriteThingsToday;
