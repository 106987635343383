/*
 * Author: Nirodha Perera
 * Date: 24/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Payment History Symmary Detail Tile
 */
import React, {Component} from 'react';
import {View, FlatList, Text} from 'react-native';
import {GreyClockIcon3x} from '../../assets/images/vector-icons';
import {StandardText, StandardTouchableOpacity} from '../Atoms';
import {CurrencyText, GridView} from './';

import CustomIcon from '../../assets/images/CustomIcon';
import {isTablet} from '../../Helpers/PlatformSynchronizer';

//Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import {
  MINUS,
  EMPTY_STRING,
  ITEM_DIMENSION,
  ITEM_DIMENSION_OFFSET_PH,
} from '../../Constants/Constants2';

import {
  formatAsCurrency,
  formatDDMMMYYDate,
  getMonthYear,
} from '../../Helpers/Utils';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

class PaymentDetailTile extends Component {
  state = {};

  constructor(props: any) {
    super(props);
    this._renderValue.bind(this);
    this._onPress.bind(this);
    this._renderNegativeSign = this._renderNegativeSign.bind(this);
    this._getStyleByDeviceInfo = this._getStyleByDeviceInfo.bind(this);
    this._renderTiles = this._renderTiles.bind(this);
  }

  render() {
    if (types.isWeb) {
      if (this.props.categorize) {
        return (
          <View style={[CommonStyles.content]}>
            {this.renderCategorizedList()}
          </View>
        );
      } else {
        return (
          <View style={[CommonStyles.mainContentContainer]}>
            <View style={[CommonStyles.content]}>
              <GridView
                viewWidth={this.props.viewWidth}
                itemDimension={ITEM_DIMENSION}
                isMobile={this.props.isMobile}
              >
                {this._renderTiles(this.props.data)}
              </GridView>
            </View>
          </View>
        );
      }
    }

    return (
      <View style={[CommonStyles.mainContentContainer]}>
        <View style={[CommonStyles.content]}>
          <FlatList
            data={this.props.data}
            extradata={this.props.extraData}
            keyExtractor={item => item.invoiceKey}
            renderItem={({item}) => this._renderPaymentListItems(item)}
          />
        </View>
      </View>
    );
  }

  renderCategorizedList = () => {
    let flatList;
    if (this.props.data.length === 0) {
      flatList = (
        <StandardText
          numberOfLines={types.isWeb ? 1 : null}
          style={[CommonStyles.customFont, CommonStyles.marginLeft10]}
        >
          {types2.NO_RECORDS}
        </StandardText>
      );
    } else {
      flatList = (
        <View style={[CommonStyles.DocumentListContainer]}>
          <View style={[CommonStyles.flex1]}>{this._renderListItems()}</View>
        </View>
      );
    }

    return flatList;
  };

  _getStyleByDeviceInfo = (tab: any, normal: any) => {
    return isTablet ? tab : normal;
  };

  // Renders the items in the flatlist.
  _renderListItems = () => {
    let itemList: any = [];

    this.props.data.map((item: any) => {
      itemList.push(
        <View style={CommonStyles.flex1}>
          {this._categoryDescription(item[0])}
          <GridView
            viewWidth={this.props.viewWidth}
            itemDimension={ITEM_DIMENSION}
            isMobile={this.props.isMobile}
          >
            {this._renderTiles(item[1])}
          </GridView>
        </View>,
      );
    });

    return itemList;
  };

  _renderTiles = (dList: any) => {
    let outcomeList: any = [];
    dList.forEach((item: any) => {
      outcomeList.push(this._renderPaymentListItems(item));
    });
    return outcomeList;
  };

  _categoryDescription(category: any) {
    return (
      <StandardText
        numberOfLines={types.isWeb ? 1 : null}
        style={[
          CommonStyles.customFontBold,
          types.isWeb ? CommonStyles.font18 : CommonStyles.font20,
          CommonStyles.documentTitleMargin,
          CommonStyles.documentTitleColor,
        ]}
      >
        {getMonthYear(category)}
      </StandardText>
    );
  }

  _renderPaymentListItems = (item: any) => {
    const billerName = item.biller ? item.biller.name : '';
    const providerVerifiedName = item.providerVerifiedName
      ? item.providerVerifiedName
      : billerName;
    return (
      <View
        style={
          types.isWeb
            ? [CommonStyles.height100, CommonStyles.budgetsTilesContainer]
            : CommonStyles.budgetsTilesContainer
        }
      >
        <StandardTouchableOpacity
          onPress={() => this._onPress(item)}
          activeOpacity={0.8}
          style={[
            types.isWeb ? null : CommonStyles.containerSummaryCard,
            CommonStyles.budgetsTileWrapper,
            BrandStyles.primaryBgColor1,
          ]}
          accessibilityLabel={'Payment Tile of ' + item.invoiceNumber}
        >
          <View
            style={[
              CommonStyles.justifyFlexRowStart,
              CommonStyles.marginBottom5,
            ]}
          >
            <View
              style={[
                CommonStyles.justifyFlexColumnStart,
                CommonStyles.justifyContentSpaceBetween,
              ]}
            >
              <View style={CommonStyles.justifyFlexRowSpace}>
                <View
                  style={[
                    CommonStyles.flex07,
                    CommonStyles.flexDirectionRow,
                    CommonStyles.alignItemsFlexStart,
                  ]}
                >
                  <StandardText
                    numberOfLines={types.isWeb ? 2 : null}
                    style={[
                      types.isWeb ? CommonStyles.font15 : CommonStyles.font18,
                      CommonStyles.customFontSemiBold,
                      BrandStyles.TextColor4,
                      CommonStyles.billerNameStyle,
                    ]}
                  >
                    {item.providerFriendlyName
                      ? item.providerFriendlyName
                      : providerVerifiedName}
                  </StandardText>
                </View>
                <View
                  style={[
                    types.isWeb ? CommonStyles.flex04 : CommonStyles.flex03,
                    CommonStyles.flexDirectionRow,
                    CommonStyles.alignItemsFlexEnd,
                    CommonStyles.justifyContentFlexEnd,
                  ]}
                >
                  <StandardText
                    style={[
                      CommonStyles.font15,
                      CommonStyles.customFont,
                      BrandStyles.TextColor10,
                      CommonStyles.textAlignRight,
                    ]}
                  >
                    {typeof item.state === 'object'
                      ? item.state.displayName
                      : item.state}
                  </StandardText>
                </View>
              </View>

              <View
                style={[
                  CommonStyles.justifyFlexRowSpace,
                  CommonStyles.paddingTop10,
                  CommonStyles.flexWrap,
                  CommonStyles.alignItemsCenter,
                ]}
              >
                <View
                  style={[CommonStyles.flex1, CommonStyles.alignItemsFlexStart]}
                >
                  <StandardText
                    numberOfLines={types.isWeb ? 1 : null}
                    style={[
                      CommonStyles.marginTop10,
                      CommonStyles.flex1,
                      types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
                      CommonStyles.customFont,
                      BrandStyles.TextColor5,
                    ]}
                  >
                    {item.invoiceNumber}
                  </StandardText>
                </View>
                <View
                  style={[
                    CommonStyles.flex1,
                    CommonStyles.alignItemsCenter,
                    CommonStyles.justifyCenter,
                    CommonStyles.flexDirectionRow,
                  ]}
                >
                  <CustomIcon
                    style={[
                      CommonStyles.containerBudgetApprovalCard_ImageDate,
                      this._getStyleByDeviceInfo(
                        CommonStyles.font18,
                        CommonStyles.font15,
                      ),
                      types.isWeb ? CommonStyles.font15 : null,
                      BrandStyles.TextColor10,
                      CommonStyles.marginBottom0,
                      CommonStyles.marginTop0,
                    ]}
                    name={GreyClockIcon3x}
                  />
                  <StandardText
                    numberOfLines={types.isWeb ? 1 : null}
                    style={[
                      CommonStyles.font15,
                      CommonStyles.customFont,
                      BrandStyles.TextColor10,
                    ]}
                  >
                    {formatDDMMMYYDate(item.invoiceDate)}
                  </StandardText>
                </View>
                <View
                  style={[
                    CommonStyles.flex1,
                    CommonStyles.alignItemsCenter,
                    CommonStyles.justifyEnd,
                  ]}
                >
                  {this._renderValue(item)}
                </View>
              </View>
            </View>
          </View>
        </StandardTouchableOpacity>
      </View>
    );
  };

  _renderNegativeSign = (item: any) => {
    if (this.props.isPositive && item.total < 0) {
      return MINUS;
    } else {
      return EMPTY_STRING;
    }
  };

  _renderValue = (item: any) => {
    return (
      <CurrencyText
        value={item.total}
        isPositive={true}
        integerStyle={[
          types.isWeb ? CommonStyles.font20 : CommonStyles.font25,
          CommonStyles.customFont,
          BrandStyles.TextColor5,
        ]}
        containerStyle={[CommonStyles.alignFlexEnd, CommonStyles.justifyEnd]}
      />
    );
  };

  _onPress = (item: any) => {
    if (this.props.itemOnPress) {
      this.props.itemOnPress(item);
    }
  };
}

export default PaymentDetailTile;
