/*
 * Author: Anuja Ulpathakumbura
 * Date: 15/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Logout actions for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const showConfirmLogout = () => ({
  type: types.SHOW_LOGOUT_CONFIRMATION,
});

export const hideConfirmLogout = (payload: any) => ({
  type: types.HIDE_LOGOUT_CONFIRMATION,
  payload,
});

export const logUserOut = (logUserOut: any) => ({
  type: types.LOGOUT_USER,
  logUserOut,
});
