/*
 * Author: Shelan Cooray
 * Date: 05/07/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Web Service class to handle the GuideMe API calls
 */

import {
  configuredAWSLamda,
  buildLambda,
  parseResult,
  apiErrorHandler,
} from './APIHelper';
import {AWS_LAMBDA_SERVICES} from '../environments';
import logger from 'helpers/Logger';
import reduxStore from '../ReduxStore/ReduxStore';

//* ********************************
//*          G U I D E M E
//* ********************************

/*
 * Returns a guide me content with a given member ID and screen name
 * @async
 * @param {string} memberId The member ID of the user you want to return
 * @param {string} screenName The current screen of the user wants to have guide
 * @returns {Promise<object>} The GuideMe Content object
 */
export const getGuideMeContent = (
  memberId: any,
  screenName: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const reduxState = reduxStore.getStore().getState();
  if(!memberId && Object.getOwnPropertyNames(reduxState.UserReducer).length){
    memberId = reduxState.UserReducer.id;
  }

  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getGuideMeContenByScreen = configuredAWSLamda();
      const requestBody = {
        id: memberId,
        primaryMenu: screenName,
        isDemoProfile,
      };
      const functionName = 'getGuideMeInstructions';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.REFERENCE;
      const params = buildLambda(requestBody, functionName, serviceName);
      getGuideMeContenByScreen.invoke(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};
