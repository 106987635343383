import React, {Component} from 'react';
import {View} from 'react-native';
import {GridView, FormTitle} from './';
import {StandardInput} from '../Atoms';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {ADD_CREW as crewMessages} from '../../Constants/Messages';
import {focusNextField} from '../../Components/FocusNextField/FocusNextField';
import {isWeb} from '../../Constants/Constants';
import {ONE_COLUMN, TWO_COLUMN} from '../../Constants/Constants2';
import {isTablet} from '../../Helpers/PlatformSynchronizer';
import { DOMPurify } from 'helpers/dompurify';
const INPUTS = {
  PHONE_NUMBER: 'phoneNumber',
  EMAIL: 'email',
};
const SCROLL_VIEW_NAME = 'scrollView';

class CrewContactInformation extends Component {
  inputs: any;
  constructor(props: any) {
    super(props);
    this._renderPhoneNumber = this._renderPhoneNumber.bind(this);
    this._renderEmail = this._renderEmail.bind(this);
    this.inputs = {};
  }

  render() {
    return (
      <GridView
        viewWidth={this.props.viewWidth}
        isMobile={this.props.isMobile}
        maximumColumns={this._determineGridMaxColumns()}
        isStatic={true}
      >
        {this._renderPhoneNumber()}
        {this._renderEmail()}
      </GridView>
    );
  }

  _determineGridMaxColumns = () => {
    let maxColumns = TWO_COLUMN;

    if (isWeb && this.props.isMobile) {
      return ONE_COLUMN;
    }

    if (!isWeb && !isTablet()) {
      return ONE_COLUMN;
    }
    return maxColumns;
  };

  _renderPhoneNumber() {
    if (this.props.renderPhoneNumber === true) {
      return (
        <View
          style={[
            this.props.ndisRegistrationNumber ? null : CommonStyles.flex1,
            isWeb ? CommonStyles.marginRight10 : {},
          ]}
        >
          <View style={[isWeb ? {} : CommonStyles.flex1]}>
            <FormTitle
              containsData={this.props.phoneNumber}
              text={crewMessages.PROFILE.PHONE_NUMBER}
              style={[
                CommonStyles.customFontBold,
                CommonStyles.AboutMeModalLabel,
              ]}
            />
          </View>
          <View
            style={[isWeb ? {} : CommonStyles.flex1, CommonStyles.height80]}
          >
            <StandardInput
              readOnly={!this.props.isPhoneNumberEditable}
              value={this.props.phoneNumber}
              onRef={(ref: any) => {
                this.inputs[INPUTS.PHONE_NUMBER] = ref;
              }}
              onSubmitEditing={() => {
                focusNextField(this.inputs, SCROLL_VIEW_NAME, INPUTS.EMAIL);
              }}
              returnKeyType={'next'}
              accessibilityLabel="Phone number"
              keyboardType="numeric"
              onChangeText={(phoneNumber: any) => {
                this.props.editPhoneNumber(phoneNumber);
              }}
              // blurOnSubmit={false}
              style={[
                CommonStyles.ModalTextInputWrapper,
                BrandStyles.primaryBgColor1,
              ]}
              underlineColorAndroid="transparent"
              errorMessage={this.props.validPhoneNumber}
            />
          </View>
        </View>
      );
    }
    return null;
  }

  _renderEmail() {
    if (this.props.renderEmail === true) {
      return (
        <View
          style={[
            this.props.ndisRegistrationNumber
              ? CommonStyles.flex2
              : CommonStyles.flex1,
            isWeb ? CommonStyles.marginRight10 : {},
          ]}
        >
          <View style={[isWeb ? {} : CommonStyles.flex1]}>
            <FormTitle
              containsData={this.props.email}
              text={crewMessages.PROFILE.EMAIL}
              style={[
                CommonStyles.customFontBold,
                CommonStyles.AboutMeModalLabel,
              ]}
            />
          </View>
          <View
            style={[isWeb ? {} : CommonStyles.flex1, CommonStyles.height80]}
          >
            <StandardInput
              readOnly={!this.props.isEmailEditable}
              value={this.props.email}
              onRef={(ref: any) => {
                this.inputs[INPUTS.EMAIL] = ref;
              }}
              returnKeyType={'next'}
              accessibilityLabel="Email address"
              onChangeText={(email: any) => {{
                const sanitizedEmail = DOMPurify.sanitize(email);

                this.props.editEmail(sanitizedEmail);
              }}}
              style={[
                CommonStyles.ModalTextInputWrapper,
                BrandStyles.primaryBgColor1,
              ]}
              underlineColorAndroid="transparent"
              errorMessage={this.props.validEmail}
            />
          </View>
        </View>
      );
    }
    return null;
  }
}

export default CrewContactInformation;
