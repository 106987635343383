/*
 * AvatarList actions for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const actionGetAvatarList = (avatarlistObj: any) => ({
  type: types.GET_AVATAR_LIST,
  avatarlistObj,
});

export const actionGetAvatarImage64 = (image64: any) => ({
  type: types.GET_AVATAR_IMAGE64,
  image64,
});

export const actionGetAvatarStatus = (isAvatarImageSelected: any) => ({
  type: types.GET_AVATAR_STATUS,
  isAvatarImageSelected,
});
