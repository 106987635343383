/*
 * Author: Brad D'Costa
 * Date: 29/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the text and icon combination used throughout the app.
 */

import React, {Component} from 'react';
import {Image, View, StyleSheet} from 'react-native';

import {StandardText, StandardTouchableOpacity, StandardButton} from '../Atoms';
import {
  editIconCharcoal60,
  addIconGrey,
  printIconCharcoal,
  shareIconCharcoal,
  tickCharcoal60,
  viewIconCharcoal,
  viewIconCharcoal60,
  deleteIconCharcoal60,
  submitPreApproved,
  downloadIconCharcoal60,
} from '../../assets/images/vector-icons';
import CustomIcon from '../../assets/images/CustomIcon';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

// Import Stylesheets
import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';

class ActionButton extends Component {
  constructor(props: any) {
    super(props);
  }
  render() {
    return this._determineType();
  }

  //Currently only catering for add and edit action buttons
  _determineType = () => {
    if (this.props.actionType || this.props.children) {
      if (this.props.isToucable) {
        return this._renderAsButton();
      } else {
        return this._commonContent();
      }
    } else {
      return null;
    }
  };

  _renderAsButton = () => {
    let accessibilityLabel = this.props.accessibilityLabel;
    if (!accessibilityLabel) {
      accessibilityLabel = this.props.actionType;
    }

    if (this.props.customButton) {
      return (
        <StandardButton
          onPress={this.props.onPress}
          style={[
            BrandStyles.brandBlockTxtColor6,
            CommonStyles.buttonFormAction,
            CommonStyles.customFontBold,
          ]}
          containerStyle={[
            BrandStyles.primaryBgColor1,
            BrandStyles.brandBorderColor1,
            CommonStyles.buttonContainerFormActionCustom,
          ]}
          accessibilityLabel={this._determineText()}
        >
          {this._determineText()}
        </StandardButton>
      );
    } else {
      return (
        <StandardTouchableOpacity
          accessibilityLabel={accessibilityLabel}
          activeOpacity={0.6}
          onPress={this.props.onPress}
          readOnly={this.props.readOnly}
        >
          {this._commonContent()}
        </StandardTouchableOpacity>
      );
    }
  };

  _determineText = () => {
    let displayText = this.props.actionType;

    if (this.props.customText) {
      return this.props.customText;
    }
    // Action type is null or undefined, so the display text should take the children
    if (!displayText) {
      displayText = this.props.children;
    } else if (this.props.title != undefined) {
      displayText = displayText + ' ' + this.props.title;
    }

    return displayText;
  };

  _commonContent = (tintStyle: any) => {
    var displayText = this.props.actionType;
    if (this.props.customText) {
      displayText = this.props.customText;
    }
    // Action type is null or undefined, so the display text should take the children
    if (!displayText) {
      displayText = this.props.children;
    } else if (this.props.title != undefined) {
      displayText = displayText + ' ' + this.props.title;
    }

    if (this.props.showButtonTitle) {
      displayText = this.props.showButtonTitle;
    }

    var tintStyle = true;
    if (this.props.tint != undefined) {
      tintStyle = this.props.tint;
    }

    let labelLocationBottom = false;
    if (this.props.labelStyle == 'bottom') {
      labelLocationBottom = true;
    }

    if (labelLocationBottom) {
      return (
        <View style={this._determineContainerStyle(labelLocationBottom)}>
          {this._renderIcon(tintStyle)}

          {this._renderButtonString(tintStyle, displayText)}
        </View>
      );
    } else {
      return (
        <View style={this._determineContainerStyle(labelLocationBottom)}>
          {this._renderButtonString(tintStyle, displayText)}
          {this._renderIcon(tintStyle)}
        </View>
      );
    }
  };

  _renderIcon = (tintStyle: any) => {
    if (this.props.imageActionIcon) {
      return (
        <Image
          style={[
            CommonStyles.summaryTileButtonIcon,
            CommonStyles.marginBottom5,
          ]}
          source={this._determineIcon(tintStyle)}
        />
      );
    } else {
      if (this._determineIcon(tintStyle) != undefined) {
        return (
          <CustomIcon
            style={[
              this.props.actionType == types.ACTION_BUTTON.SUBMIT_PRE_APPROVED
                ? CommonStyles.summaryTileButtonIconCustomStyle
                : CommonStyles.summaryTileButtonIcon,
            ]}
            name={this._determineIcon(tintStyle)}
          />
        );
      } else {
        return null;
      }
    }
  };

  _renderButtonString = (tintStyle: any, displayText: any) => {
    var showIconString = true;
    if (this.props.showIconString != undefined) {
      showIconString = this.props.showIconString;
    }
    if (showIconString) {
      return (
        <StandardText
          allowFontScaling={false}
          style={this._determineTextStyle(tintStyle)}
        >
          {displayText}
        </StandardText>
      );
    } else {
      return null;
    }
  };

  _determineContainerStyle = (labelLocationBottom: any) => {
    const styles = [];

    if (this.props.containerStyle) {
      styles.push(this.props.containerStyle);
    } else {
      if (!labelLocationBottom) {
        styles.push(CommonStyles.flexDirectionRow);
      }
      if (
        this.props.actionType === types.ACTION_BUTTON.EDIT ||
        this.props.actionType === types.ACTION_BUTTON.REVIEW
      ) {
        styles.push(CommonStyles.alignItemsFlexEnd);
      } else if (this.props.actionType === types.ACTION_BUTTON.VIEW) {
        if (labelLocationBottom) {
          styles.push(CommonStyles.alignItemsFlexEnd);
        } else {
          styles.push(CommonStyles.alignItemsCenter);
        }
      } else if (
        this.props.actionType == types.ACTION_BUTTON.ADD ||
        this.props.actionType == types.ACTION_BUTTON.PRINT ||
        this.props.actionType == types.ACTION_BUTTON.SHARE ||
        this.props.actionType == types.ACTION_BUTTON.DELETE ||
        this.props.actionType == types.ACTION_BUTTON.SUBMIT_PRE_APPROVED ||
        this.props.actionType == types.ACTION_BUTTON.DOWNLOAD
      ) {
        styles.push(CommonStyles.alignItemsCenter);
      }
      if (this.props.marginBefore) {
        styles.push({marginLeft: this.props.marginBefore});
      }
    }

    return styles;
  };

  _determineTextStyle = (tintStyle: any) => {
    var styles = [
      CommonStyles.customFontMedium,
      CommonStyles.SummaryTileHeaderButtonText,
    ];

    // Override the styles if there is styles being passed in
    if (this.props.style) {
      styles = this.props.style;
    }

    if (tintStyle) {
      styles.push(BrandStyles.TextColor10);
    } else {
      styles.push(BrandStyles.TextColor5);
    }
    return styles;
  };

  _determineIcon = (tintStyle: any) => {
    var image = null;
    switch (this.props.actionType) {
      case types.ACTION_BUTTON.EDIT:
        image = editIconCharcoal60;
        break;
      case types.ACTION_BUTTON.ADD:
        image = addIconGrey;
        break;
      case types.ACTION_BUTTON.VIEW:
        if (tintStyle) {
          image = viewIconCharcoal60;
        } else {
          image = viewIconCharcoal;
        }
        break;
      case types.ACTION_BUTTON.PRINT:
        image = printIconCharcoal;
        break;
      case types.ACTION_BUTTON.SHARE:
        image = shareIconCharcoal;
        break;
      case types.ACTION_BUTTON.REVIEW:
        image = tickCharcoal60;
        break;
      case types.ACTION_BUTTON.DELETE:
        image = deleteIconCharcoal60;
        break;
      case types.ACTION_BUTTON.SUBMIT_PRE_APPROVED:
        image = submitPreApproved;
        break;
      case types.ACTION_BUTTON.DOWNLOAD:
        image = downloadIconCharcoal60;
        break;
      default:
        image = null;
    }
    return image;
  };
}

export default ActionButton;
