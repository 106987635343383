/*
 * Author: Roshan Piumal
 * Date: 02/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Data sharing consent modal
 */

import React, {Component} from 'react';
import {View, Linking, Text, Image} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
//import CheckBox from 'react-native-checkbox';
import {BasicOverlayPopupModal} from './';
import {StandardButton, StandardText, StandardTouchableOpacity, CheckBox} from '../Atoms';

import * as UserActions from '../../Actions/UserActions';

import {APP_ID} from '../../environments';
import {
  DATA_SHARING_CONSENT_MODAL,
  NAVIGATION_CONFIRM_MODAL,
  POPUP_TITLES,
} from '../../Constants/Messages';

import CommonStyles from '../../Styles/CommonStyles';
import {BrandColors} from '../../Styles/Colours';
import BrandStyles from '../../Styles/BrandStyles';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {CheckedBoxPurple3x, UncheckedBoxPurple3x} from '../../assets/images';

import {callAPIs} from '../../API/APICaller';
import {putUserTnC} from '../../API/UserAPI';
import {logUserOut} from '../../API/PreauthenticationHelper';

import {isTablet} from '../../Helpers/PlatformSynchronizer';
// import Orientation from 'react-native-orientation-enhanced';
import {getStyleByOrientation} from '../../Helpers/GetStyleByOrientation'

class DataSharingConsentModal extends Component {
  state = {
    isTnCAccepted: false,
    continueButtonColor: BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
    keepTnCForOtherBrands: true,
    tnCUpdateInProgress: false,
    modalHightWidth: isTablet()
      ? CommonStyles.dataSharingConsentModalTablet
      : CommonStyles.dataSharingConsentModalMobile,
    orientation: '',
  };

  constructor(props: any) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    // if (!types.isWeb) {
    //   Orientation.getOrientation((err: any, orientation: any) => {
    //     this.setState({
    //       orientation: orientation,
    //     });
    //   });
    // }
  }

  componentDidMount() {
    // if (!types.isWeb) {
    //   Orientation.addOrientationListener(this._orientationDidChange);
    // }
  }

  componentWillUnmount() {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation: orientation,
    });
  };

  _determinePopUpStyle = () => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      if (this.props.isMobile) {
        styles.push([CommonStyles.containerInstructionPopUpWebMobileLarge]);
        return styles;
      }
      styles.push([CommonStyles.containerInstructionPopUpWebLarge]);
    } else {
      if (isTablet()) {
        styles.push([
          CommonStyles.containerInstructionPopUpTablet,
          CommonStyles.height500,
          this._determineModalHeight(),
        ]);
      } else {
        styles.push(
          CommonStyles.containerInstructionPopUpMobile,
          CommonStyles.height400,
          this._determineModalHeight(),
        );
      }
    }
    return styles;
  };
  _getUserFriendlyAppName(brand: any) {
    switch (brand) {
      case types2.APP_ID.LEAPIN:
        return types2.USER_FRIENDLY_APP_NAME.LEAPIN;
      case types2.APP_ID.BLUE_CARE:
        return types2.USER_FRIENDLY_APP_NAME.BLUE_CARE;
      case types2.APP_ID.UCQ:
        return types2.USER_FRIENDLY_APP_NAME.UCQ;
      case types2.APP_ID.WESLEY:
        return types2.USER_FRIENDLY_APP_NAME.WESLEY;
      case types2.APP_ID.UCN:
        return types2.USER_FRIENDLY_APP_NAME.UCN;
      case types2.APP_ID.YOORALLA:
        return types2.USER_FRIENDLY_APP_NAME.YOORALLA;
      default:
        return types2.USER_FRIENDLY_APP_NAME.LEAPIN;
    }
  }
  getPopUpContent() {
    let messageFontSize = CommonStyles.font17;
    let headerFontSize = CommonStyles.font20;
    if (isTablet()) {
      messageFontSize = CommonStyles.font15;
      headerFontSize = CommonStyles.font18;
    } else if (types.isWeb) {
      messageFontSize = CommonStyles.font20;
      headerFontSize = CommonStyles.font25;
    }

    return (
      <View>
        <View
          style={
            types.isWeb
              ? [CommonStyles.marginBottom10, CommonStyles.textAlignCentre]
              : [
                  CommonStyles.flexDirectionRow,
                  CommonStyles.popTitleText,
                  CommonStyles.scrollView_subContentCenter,
                ]
          }
        >
          <View style={[CommonStyles.scrollView_subContentCenter]}>
            <StandardText
              style={[
                headerFontSize,
                BrandStyles.TextColor10,
                CommonStyles.customFontBold,
              ]}
            >
              {POPUP_TITLES.DATA_SHARING_CONSENT}
            </StandardText>
          </View>
        </View>
        <View
          style={
            types.isWeb
              ? {}
              : [
                  CommonStyles.flexDirectionRow,
                  CommonStyles.scrollView_subContentCenter,
                  CommonStyles.paddingBottom5p,
                ]
          }
        >
          <View style={[CommonStyles.scrollView_subContentCenter]}>
            <StandardText
              style={[
                messageFontSize,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
              ]}
            >
              {`${
                DATA_SHARING_CONSENT_MODAL.TITLE_TEXT_1
              } ${this._getUserFriendlyAppName(APP_ID)} ${
                DATA_SHARING_CONSENT_MODAL.TITLE_TEXT_2
              }`}
            </StandardText>
          </View>
        </View>
        <View
          style={[
            CommonStyles.paddingTop5,
            CommonStyles.scrollView_subContentCenter,
          ]}
        >
          {this._renderModalContent()}
        </View>
        <View
          style={[
            CommonStyles.containerInstructionDevider,
            CommonStyles.paddingBottom5p,
          ]}
        />
        <View style={[CommonStyles.ModalActionButtons]}>
          <View
            style={[
              types.isWeb
                ? CommonStyles.ModalFooterCenter
                : CommonStyles.ModalFooterLeft,
            ]}
          >
            <StandardButton
              onPress={this._logUserOutAndCloseModal}
              style={[
                BrandStyles.brandBlockTxtColor6,
                CommonStyles.buttonFormAction,
              ]}
              containerStyle={[
                BrandStyles.primaryBgColor1,
                BrandStyles.brandBorderColor1,
                CommonStyles.buttonContainerFormAction,
              ]}
              accessibilityLabel={
                NAVIGATION_CONFIRM_MODAL.NAVIGATION_CONFIRM_MODAL_CANCEL
              }
            >
              {NAVIGATION_CONFIRM_MODAL.NAVIGATION_CONFIRM_MODAL_CANCEL}
            </StandardButton>
          </View>
          <View
            style={[
              types.isWeb
                ? CommonStyles.ModalFooterCenter
                : CommonStyles.ModalFooterRight,
            ]}
          >
            <StandardButton
              onPress={() => {
                if (!this.state.tnCUpdateInProgress) {
                  this._updateUserTncAndContinue();
                }
              }}
              style={[
                BrandStyles.brandBlockTxtColor5,
                CommonStyles.buttonFormAction,
              ]}
              containerStyle={[
                this.state.isTnCAccepted
                  ? BrandStyles.primaryBgColor4
                  : BrandStyles.secondaryBgColor5,
                this.state.isTnCAccepted
                  ? BrandStyles.brandBorderColor1
                  : BrandStyles.borderColor8,
                CommonStyles.buttonContainerFormAction,
              ]}
              accessibilityLabel={
                NAVIGATION_CONFIRM_MODAL.NAVIGATION_CONFIRM_MODAL_OK
              }
            >
              {DATA_SHARING_CONSENT_MODAL.CONTINUE_TEXT}
            </StandardButton>
          </View>
        </View>
      </View>
    );
  }

  render() {
    return (
      <BasicOverlayPopupModal
        visible={this.props.isVisible}
        style={this._determinePopUpStyle()}
        backdropOpacity={0.2}
        divider={false}
        close={this._logUserOutAndCloseModal}
        hideCloseButton={true}
        cancelContainerStyle={CommonStyles.popUpOkButton}
        cancelStyle={CommonStyles.buttonPopupOk}
      >
        <View>{this.getPopUpContent()}</View>
      </BasicOverlayPopupModal>
    );
  }

  _renderAppName(APP_ID: any) {
    switch (APP_ID) {
      case types2.APP_ID.LEAPIN:
        return types2.APP_NAME.LEAPIN;
      case types2.APP_ID.BLUE_CARE:
        return types2.APP_NAME.BLUE_CARE;
      case types2.APP_ID.UCQ:
        return types2.APP_NAME.UCQ;
      case types2.APP_ID.WESLEY:
        return types2.APP_NAME.WESLEY;
      case types2.APP_ID.UCN:
        return types2.APP_NAME.UCN;
      case types2.APP_ID.YOORALLA:
        return types2.APP_NAME.YOORALLA;
      default:
        return types2.APP_NAME.LEAPIN;
    }
  }

  _renderTermsLink = () => {
    return (
      <StandardTouchableOpacity
        onPress={() => {
          if (types.isWeb) {
            window.open(this._findTermsLinkForBrand(APP_ID));
          } else {
            Linking.openURL(this._findTermsLinkForBrand(APP_ID));
          }
        }}
        accessibilityLabel={DATA_SHARING_CONSENT_MODAL.TERMS}
      >
        <StandardText
          style={[
            BrandStyles.TextColor5,
            CommonStyles.fontWeightBold,
            CommonStyles.textDecorationLineUnderline,
            CommonStyles.customFont,
            types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
          ]}
        >
          {DATA_SHARING_CONSENT_MODAL.TERMS}
        </StandardText>
      </StandardTouchableOpacity>
    );
  };

  _getUserFriendlyAppName(brand: any) {
    switch (brand) {
      case types2.APP_ID.LEAPIN:
        return types2.USER_FRIENDLY_APP_NAME.LEAPIN;
      case types2.APP_ID.BLUE_CARE:
        return types2.USER_FRIENDLY_APP_NAME.BLUE_CARE;
      case types2.APP_ID.UCQ:
        return types2.USER_FRIENDLY_APP_NAME.UCQ;
      case types2.APP_ID.WESLEY:
        return types2.USER_FRIENDLY_APP_NAME.WESLEY;
      case types2.APP_ID.UCN:
        return types2.USER_FRIENDLY_APP_NAME.UCN;
      case types2.APP_ID.YOORALLA:
        return types2.USER_FRIENDLY_APP_NAME.YOORALLA;
      default:
        return types2.USER_FRIENDLY_APP_NAME.LEAPIN;
    }
  }

  _renderTermsLinksForCurrentlyAcceptedBrands = () => {
    const otherBrands = this._getConsentAgreedOtherBrandNames();

    if (APP_ID != types2.APP_NAME.LEAPIN) {
      for (var i = 0; i < otherBrands.length; i++) {
        if (otherBrands[i] === types2.APP_ID.LEAPIN) otherBrands.splice(i, 1);
      }
    }

    return otherBrands.map((brand: any, i: any) => {
      return (
        <StandardTouchableOpacity
          key={i}
          onPress={() => {
            if (types.isWeb) {
              window.open(this._findTermsLinkForBrand(brand));
            } else {
              Linking.openURL(this._findTermsLinkForBrand(brand));
            }
          }}
          accessibilityLabel={brand}
        >
          <StandardText
            style={[
              BrandStyles.TextColor5,
              CommonStyles.fontWeightBold,
              CommonStyles.textDecorationLineUnderline,
              CommonStyles.customFont,
              types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
            ]}
          >
            {`${this._getUserFriendlyAppName(brand)}${
              i == otherBrands.length - 1 ? '' : ','
            } `}
          </StandardText>
        </StandardTouchableOpacity>
      );
    });
  };

  _renderKeepTnCForOtherAcceptedBrandsCheckBox = () => {
    return (
      <View
        style={[
          CommonStyles.flexDirectionRow,
          CommonStyles.paddingRight20,
          CommonStyles.paddingTop15,
        ]}
      >
        <View style={[CommonStyles.flexDirectionRow]}>
          {/* <CheckBox
            checkedImage={CheckedBoxPurple3x}
            uncheckedImage={UncheckedBoxPurple3x}
            label=""
            uncheckedColor={[BrandStyles.brandBorderColor3]}
            accessibilityLabel="Accept terms and conditions"
            onChange={() =>
              this.setState({
                keepTnCForOtherBrands: !this.state.keepTnCForOtherBrands,
              })
            }
            checked={this.state.keepTnCForOtherBrands}
          /> */}
        </View>
        <View
          style={[
            CommonStyles.flexWrap,
            CommonStyles.paddingRight20,
            this._determinTermsLinkTextContainerStyle(),
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFont,
              types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
            ]}
          >
            {`${DATA_SHARING_CONSENT_MODAL.SHARE_PROFILE_INFO_TEXT}`}
          </StandardText>
          {this._renderTermsLinksForCurrentlyAcceptedBrands()}
        </View>
      </View>
    );
  };

  _renderAcceptTnCForCurrentBrandCheckBox = () => {
    return (
      <View style={[CommonStyles.flexDirectionRow]}>
        <View style={[CommonStyles.flexDirectionRow]}>
          <CheckBox
            checkedImage={CheckedBoxPurple3x}
            uncheckedImage={UncheckedBoxPurple3x}
            label=""
            uncheckedColor={[BrandStyles.brandBorderColor3]}
            accessibilityLabel="Accept terms and conditions"
            onChange={() => {
              this.setState({isTnCAccepted: !this.state.isTnCAccepted}, () => {
                if (this.state.isTnCAccepted) {
                  this.setState({
                    continueButtonColor:
                      BrandColors.RESPONSIVE_DESIGN_COLORS.PURPLE,
                  });
                } else {
                  this.setState({
                    continueButtonColor:
                      BrandColors.RESPONSIVE_DESIGN_COLORS.GREY,
                  });
                }
              });
            }}
          />
        </View>
        <View style={this._determinTermsLinkTextContainerStyle()}>
          <StandardText
            style={[
              CommonStyles.customFont,
              types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
            ]}
          >
            {DATA_SHARING_CONSENT_MODAL.ACCEPT_TERMS_TEXT_1}
          </StandardText>
          {this._renderTermsLink()}
          <StandardText
            style={[
              CommonStyles.customFont,
              types.isWeb ? CommonStyles.font15 : CommonStyles.font17,
            ]}
          >
            {DATA_SHARING_CONSENT_MODAL.ACCEPT_TERMS_TEXT_2}
          </StandardText>
        </View>
      </View>
    );
  };

  _determinTermsLinkTextContainerStyle = () => {
    let styles = [CommonStyles.flexDirectionRow, CommonStyles.marginLeft20];
    if (types.isWeb) {
      if (this.props.isMobile) {
        styles.push([CommonStyles.flexWrap, CommonStyles.maxWidth250]);
        return styles;
      }
      styles.push([CommonStyles.maxWidth600]);
    }
    return styles;
  };

  _renderModalContent = () => {
    return (
      <View
        style={[CommonStyles.containerBasicPopUp, CommonStyles.paddingLeft20]}
      >
        {this._renderAcceptTnCForCurrentBrandCheckBox()}
        {this._checkPreviouslyAcceptedBrands()}
      </View>
    );
  };

  _determineModalHeight = () => {
    var agreedOtherBrands = this._getConsentAgreedOtherBrandNames();

    if (isTablet()) {
      if (agreedOtherBrands.length === 1) {
        return CommonStyles.height450;
      }
    } else {
      if (agreedOtherBrands.length > 1) {
        return CommonStyles.height450;
      } else {
        return CommonStyles.height360;
      }
    }
  };

  _checkPreviouslyAcceptedBrands = () => {
    if (APP_ID != types2.APP_NAME.LEAPIN) {
      var agreedOtherBrands = this._getConsentAgreedOtherBrandNames();

      // Check if user accepted only Leapin before, then we do not opt sharing option
      if (agreedOtherBrands.length === 1) {
        if (agreedOtherBrands[0] === types2.APP_ID.LEAPIN) {
          return null;
        } else {
          return this._getDataSharingConsentForOtherBrands();
        }
      } else if (agreedOtherBrands.length > 1) {
        return this._getDataSharingConsentForOtherBrands();
      }
    } else {
      return this._getDataSharingConsentForOtherBrands();
    }
  };

  _getDataSharingConsentForOtherBrands = () => {
    return (
      this._showKeepTnCForOtherAcceptedBrandsCheckBox()
    );
  };

  _findTermsLinkForBrand = (brand: any) => {
    let termsLink;
    switch (brand) {
      case types2.APP_ID.LEAPIN:
        termsLink = types2.TERMS_LINKS.LEAP_IN;
        break;

      case types2.APP_ID.UCQ:
        termsLink = types2.TERMS_LINKS.UCQ;
        break;

      case types2.APP_ID.WESLEY:
        termsLink = types2.TERMS_LINKS.WESLEY;
        break;

      case types2.APP_ID.BLUE_CARE:
        termsLink = types2.TERMS_LINKS.BLUE_CARE;
        break;

      case types2.APP_ID.UCN:
        termsLink = types2.TERMS_LINKS.UCN;
        break;

      case types2.APP_ID.YOORALLA:
        termsLink = types2.TERMS_LINKS.YOORALLA;
        break;

      default:
        termsLink = types2.TERMS_LINKS.LEAP_IN;
        break;
    }
    return termsLink;
  };

  _isObjectEmpty = (obj: any) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  _renderContinueButton = () => {
    return (
      <View>
        <View
          style={[
            CommonStyles.dataSharingConsentModal_dividerStyle,
            isTablet()
              ? getStyleByOrientation(
                  CommonStyles.dataSharingConsentModal_dividerWidthTablet,
                  this.state.orientation,
                )
              : getStyleByOrientation(
                  CommonStyles.dataSharingConsentModal_dividerWidthMobile,
                  this.state.orientation,
                ),
            CommonStyles.alignCenter,
          ]}
        />
        <View
          style={[
            CommonStyles.alignCenter,
            CommonStyles.rpMarginTop10p,
            CommonStyles.justifyCenter,
          ]}
        >
          <StandardButton
            onPress={() => {
              if (!this.state.tnCUpdateInProgress) {
                this._updateUserTncAndContinue();
              }
            }}
            accessibilityLabel={DATA_SHARING_CONSENT_MODAL.CONTINUE_TEXT}
          >
            <StandardText
              style={[
                CommonStyles.dataSharingConsentModal_continueButton,
                CommonStyles.customFontBold,
                {color: this.state.continueButtonColor},
              ]}
            >
              {DATA_SHARING_CONSENT_MODAL.CONTINUE_TEXT}
            </StandardText>
          </StandardButton>
        </View>
      </View>
    );
  };

  _updateUserTncAndContinue = () => {
    // Disables the continue button if tnc is not accepted
    if (!this.state.isTnCAccepted) {
      return;
    }

    this.setState({
      tnCUpdateInProgress: true,
    });

    let userId = this.props.user.id;
    // If the user has deselected the keep tnc for other tnc accepted brands
    // Populate the removedBrandTnCs array with brand names to send with putUserTnC call
    let removedBrandTnCs = !this.state.keepTnCForOtherBrands
      ? this._getConsentAgreedOtherBrandNames()
      : [];

    this.props.hideModal();
    this.props.setLoading();

    // Accept User's TnC and continue
    callAPIs(
      [putUserTnC(userId, removedBrandTnCs)],
      () => {
        this.props.closeModal(true);
      },
      null,
      () => {},
    );
  };

  _logUserOutAndCloseModal = () => {
    logUserOut().then(() => {
      // return false to break the navigation
      this.props.closeModal(false);
    });
  };

  _getConsentAgreedOtherBrandNames = () => {
    return this.props.user.tAndCAcceptedBrands.filter((x: any) => x !== APP_ID);
  };

  _showKeepTnCForOtherAcceptedBrandsCheckBox = () => {
    return !!this.props.user.tAndCAcceptedBrands.filter(
      (x: any) => x !== APP_ID,
    ).length;
  };
}

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  payInvoice: state.PayInvoiceReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    userActions: bindActionCreators(UserActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataSharingConsentModal);
