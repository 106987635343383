import BrandStyles from './BrandStyles';
import {BrandColors} from './Colours';
import CommonStyles from './CommonStyles';

export const DEMO_OPTIONS_COLORS_WELCOME_SHOW_ME_AROUND = {
  isGradient: false,
  borderStyle: BrandStyles.borderColor12,
  backgroundStyle: BrandStyles.opTileShowMeRoundBgColor,
  textStyle: BrandStyles.TextColor1,
  textSize: CommonStyles.font25,
  subTextSize: CommonStyles.font16,
  addImageBorder: false,
};
export const DEMO_OPTIONS_COLORS_WELCOME_EXPLORE = {
  isGradient: false,
  borderStyle: BrandStyles.borderColor5,
  backgroundStyle: BrandStyles.opTileExplAppBgColor,
  textStyle: BrandStyles.TextColor1,
  textSize: CommonStyles.font25,
  subTextSize: CommonStyles.font16,
  addImageBorder: false,
};

export const DEMO_OPTIONS_COLORS_WELCOME_SCREEN_SIGN_UP_PROFILE = {
  isGradient: false,
  borderStyle: BrandStyles.borderColor4,
  backgroundStyle: BrandStyles.opTileCreateProfileBgColor,
  textStyle: BrandStyles.TextColor1,
  textSize: CommonStyles.font25,
  subTextSize: CommonStyles.font16,
  addImageBorder: false,
};
export const DEMO_OPTIONS_COLORS_WELCOME_SIGN_UP = {
  isGradient: false,
  borderStyle: BrandStyles.borderColor3,
  backgroundStyle: BrandStyles.opTileSignupBgColor,
  textStyle: BrandStyles.TextColor2,
  textSize: CommonStyles.font25,
  subTextSize: CommonStyles.font16,
  addImageBorder: false,
};
export const DEMO_OPTIONS_COLORS_WELCOME_LOGIN = {
  isGradient: false,
  borderStyle: BrandStyles.borderColor3,
  backgroundStyle: BrandStyles.opTileLoginBgColor,
  textStyle: BrandStyles.TextColor1,
  textSize: CommonStyles.font25,
  subTextSize: CommonStyles.font16,
  addImageBorder: false,
};

export const DEMO_OPTIONS_COLORS_PARTICIPANT = {
  isGradient: false,
  borderStyle: BrandStyles.borderColor4,
  backgroundStyle: BrandStyles.opTileParticipantBgColor,
  textStyle: BrandStyles.TextColor1,
  textSize: CommonStyles.font25,
  subTextSize: CommonStyles.font16,
  addImageBorder: false,
};
export const DEMO_OPTIONS_COLORS_CREW = {
  isGradient: false,
  borderStyle: BrandStyles.borderColor3,
  backgroundStyle: BrandStyles.opTileCrewBgColor,
  textStyle: BrandStyles.TextColor1,
  textSize: CommonStyles.font25,
  subTextSize: CommonStyles.font16,
  addImageBorder: false,
};

export const DEMO_OPTIONS_COLORS_NDIS_P_1 = {
  isGradient: false,
  borderStyle: BrandStyles.borderColor3,
  backgroundStyle: BrandStyles.opTileNDISp1BgColor,
  textStyle: BrandStyles.TextColor1,
  textSize: CommonStyles.font25,
  subTextSize: CommonStyles.font16,
  addImageBorder: true,
  imageBorderColor: BrandStyles.optionTileAvatarBorderColor,
  isAvatar: true,
};
export const DEMO_OPTIONS_COLORS_NDIS_P_2 = {
  isGradient: false,
  borderStyle: BrandStyles.borderColor12,
  backgroundStyle: BrandStyles.opTileNDISp2BgColor,
  textStyle: BrandStyles.TextColor1,
  textSize: CommonStyles.font25,
  subTextSize: CommonStyles.font16,
  addImageBorder: true,
  imageBorderColor: BrandStyles.optionTileAvatarBorderColor,
  isAvatar: true,
};
export const DEMO_OPTIONS_COLORS_SUPPORT_COO = {
  isGradient: false,
  borderStyle: BrandStyles.borderColor4,
  backgroundStyle: BrandStyles.opTileSupportCooBgColor,
  textStyle: BrandStyles.TextColor2,
  textSize: CommonStyles.font25,
  subTextSize: CommonStyles.font16,
  addImageBorder: true,
  imageBorderColor: BrandStyles.optionTileAvatarBorderColor,
  isAvatar: true,
};

export const DEMO_OPTIONS_CHOOSE_GUIDE_ME_TYPE_NDIS_PLANING = {
  isGradient: false,
  borderStyle: BrandStyles.borderColor3,
  backgroundStyle: BrandStyles.opTileNDISPlaningBgColor,
  textStyle: BrandStyles.TextColor1,
  textSize: CommonStyles.font25,
  subTextSize: CommonStyles.font16,
  addImageBorder: false,
};
export const DEMO_OPTIONS_CHOOSE_GUIDE_ME_TYPE_MG_BUDGET = {
  isGradient: false,
  borderStyle: BrandStyles.borderColor4,
  backgroundStyle: BrandStyles.opTileMgBudgetsCooBgColor,
  textStyle: BrandStyles.TextColor1,
  textSize: CommonStyles.font25,
  subTextSize: CommonStyles.font16,
  addImageBorder: false,
};

export const LEFT_ROTATE_STRIPE = {
  background: `repeating-linear-gradient(135deg,${BrandColors.UNDER_MAINTENANCE_YELLOW},${BrandColors.UNDER_MAINTENANCE_YELLOW} 15px,#000 15px,#000 30px)`,
  height: 15,
  width: '100%',
};

export const RIGHT_ROTATE_STRIPE = {
  background: `repeating-linear-gradient(45deg,${BrandColors.UNDER_MAINTENANCE_YELLOW},${BrandColors.UNDER_MAINTENANCE_YELLOW} 15px,#000 15px,#000 30px)`,
  height: 15,
  width: '100%',
};
