/*
 * Author: Brad D'Costa
 * Date: 09/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Managed Member information << used when loading data for a memeber you are managing
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function LoadedMemberReducer(state = {}, action: any) {
  switch (action.type) {
    case types.GET_LOADED_MEMBER_ID:
      return {
        ...state,
        loadedMemberId: action.loadedMemberId,
      };
    case types.UPDATE_LOADED_MEMBER_ID:
      var newValue = action.loadedMemberId;
      var updatedState = Object.assign({}, state);
      for (const k in newValue) updatedState[k] = newValue[k];
      return updatedState;
    case types.NEWLY_ADDED_MEMBER_ID:
      return {
        ...state,
        newlyAddedMemberId: action.newlyAddedMemberId,
      };
    case types.RESET:
      return {};
    default:
      return state;
  }
}
