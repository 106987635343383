/*
 * Avatar reducer for profile image update.
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function AvatarReducer(state = {}, action: any) {
  switch (action.type) {
    case types.GET_AVATAR_LIST:
      return {
        ...state,
        avatarlistObj: action.avatarlistObj,
      };
    case types.GET_AVATAR_IMAGE64:
      return {
        ...state,
        image64: action.image64,
      };
    case types.GET_AVATAR_STATUS:
      return {
        ...state,
        isAvatarImageSelected: action.isAvatarImageSelected,
      };
    default:
      return state;
  }
}
