/*
Author: Justin Kilimnik
Date: 17/01/18
Copyright © 2017 LeapIn. All rights reserved.

Icons which can change per brand style.
*/

import {
  PRManageMembersImage,
  crewAccess16x9BC,
  crewAccess16x9UCQ,
  LILogoSmall,
  BCLogoSmall,
  LILogo,
  BCLogo,
  UCQLogo,
  BCLogoBlueBg,
  skipButtonBlack,
  MembersCharcoalx3,
  ExploreBlack,
  ProfileBlack,
  SignupBlack,
  LoginBlack,
  Binoculars3x,
  LILogoWhiteText,
  CrewBlack,
} from '../assets/images';

import {
  crewAccess16x9,
  goalsIconWhite,
  goalsIconSelectedRed,
  MembersWhitex3,
  PRManageMembersSelectedImage,
  PRProfileImage,
  PRProfileSelectedImage,
  PRPlanMeetingImage,
  PRPlanMeetingSelectedImage,
  GuideMeImage,
  BudgetsIconRed,
  BudgetsIconWhite,
  SettingsImageWhite,
  SettingsImageCharcoal,
  HelpImageWhite,
  HelpImageCharcoal,
  DocumentsImageWhite,
  DocumentsImageCharcoal,
  PRNotificationsImage,
  PRNotificationsSelectedImage,
  MoreImageWhite,
  skipButton,
} from '../assets/images/vector-icons';

export const BrandIcons = {
  HEADER_TAB_NOTIFICATIONS_ICON_TYPE_MAIN: PRNotificationsImage,
  HEADER_TAB_DOCUMENTS_ICON_TYPE_MAIN: DocumentsImageWhite,
  HEADER_TAB_SETTINGS_ICON_TYPE_MAIN: SettingsImageWhite,
  HEADER_TAB_HELP_ICON_TYPE_MAIN: HelpImageWhite,

  HEADER_TAB_MORE_ICON_TYPE_MAIN: MoreImageWhite,

  HEADER_TAB_NOTIFICATIONS_ICON_TYPE_MAIN_OPTION: PRNotificationsSelectedImage,
  HEADER_TAB_DOCUMENTS_ICON_TYPE_MAIN_OPTION: DocumentsImageCharcoal,
  HEADER_TAB_SETTINGS_ICON_TYPE_MAIN_OPTION: SettingsImageCharcoal,
  HEADER_TAB_HELP_ICON_TYPE_MAIN_OPTION: HelpImageCharcoal,

  HEADER_TAB_NOTIFICATIONS_ICON_TYPE_EDIT: PRNotificationsSelectedImage,
  HEADER_TAB_DOCUMENTS_ICON_TYPE_EDIT: DocumentsImageCharcoal,
  HEADER_TAB_SETTINGS_ICON_TYPE_EDIT: SettingsImageCharcoal,
  HEADER_TAB_HELP_ICON_TYPE_EDIT: HelpImageCharcoal,

  BOTTOM_TAB_PROFILE_ICON: PRProfileImage,
  BOTTOM_TAB_GOALS_ICON: goalsIconWhite,
  BOTTOM_TAB_BUDGETS_ICON: BudgetsIconWhite,
  BOTTOM_TAB_PLAN_MEETING_ICON: PRPlanMeetingImage,
  BOTTOM_TAB_PLAN_MANAGE_MEMBER_ICON: MembersWhitex3,
  BOTTOM_TAB_GUIDEME_ICON: GuideMeImage,

  BOTTOM_TAB_PROFILE_ICON_SELECTED: PRProfileSelectedImage,
  BOTTOM_TAB_GOALS_ICON_SELECTED: goalsIconSelectedRed,
  BOTTOM_TAB_BUDGETS_ICON_SELECTED: BudgetsIconRed,
  BOTTOM_TAB_PLAN_MEETING_ICON_SELECTED: PRPlanMeetingSelectedImage,
  BOTTOM_TAB_PLAN_MANAGE_MEMBER_ICON_SELECTED: PRManageMembersSelectedImage,

  CREW_ACCESS_16x9: crewAccess16x9,

  LOGO_ICON: LILogo,
  LOGO_ICON_SMALL: LILogoSmall,

  LOGO_ICON_BUTTON: LILogo,
  PLAN_MANAGE_LOGO_ICON_BUTTON: LILogo,

  SKIP_BUTTON: skipButton,

  LOGO_URL: 'logo.png',

  LOGO_WHITE: LILogoWhiteText,

  WELCOME_EXPLORE: ExploreBlack,
  WELCOME_PROFILE: ProfileBlack,
  WELCOME_SIGN_UP: SignupBlack,
  WELCOME_LOGIN: LoginBlack,
  WELCOME_SHOW_ME_AROUND: Binoculars3x,
  SIGNUP_CREW: CrewBlack,
};
