import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function NavigationReducer(
  state = {showConfirmNavigation: false},
  action: any,
) {
  switch (action.type) {
    case types.DETERMINE_SHOW_CONFIRM_NAVIGATION:
      return {
        ...state,
        navigateToIndex: action.payload.navigateToIndex,
        navigateToIndexParams: action.payload.navigateToIndexParams,
        determineShowConfirmNavigation: true,
      };
    case types.SHOW_CONFIRM_NAVIGATION:
      return {
        ...state,
        showConfirmNavigation: true,
        determineShowConfirmNavigation: false,
      };
    case types.HIDE_CONFIRM_NAVIGATION:
      return {
        ...state,
        showConfirmNavigation: false,
        determineShowConfirmNavigation: false,
        navigateToIndex: null,
        navigateToIndexParams: null,
      };
    default:
      return state;
  }
}
