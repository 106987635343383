import React, {Component} from 'react';
import {View} from 'react-native';

import {StandardText, StandardTouchableOpacity} from '../Atoms';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {LOGIN_SIGNUP_SCREEN} from '../../Constants/Messages';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

class LoginSignUpTitle extends Component {
  mainTitle: any;
  render() {
    return (
      <View
        accessibilityLabel={this.props.title}
        accessible={true}
        style={[
          CommonStyles.height55,
          CommonStyles.flex1,
          CommonStyles.alignItemsCenter,
          CommonStyles.marginBottom10,
          types.isWeb && CommonStyles.paddingTop50,
        ]}
        ref={ref => {
          this.mainTitle = ref;
        }}
      >
        <View
          style={[
            CommonStyles.flex1,
            CommonStyles.alignCenter,
            CommonStyles.zIndex02,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFontBold,
              CommonStyles.loginSignUpHeadingPadding,
              types.isWeb ? CommonStyles.font27 : CommonStyles.rpfont22,
              CommonStyles.fontWeightBold,
            ]}
          >
            {this.props.title}
          </StandardText>
        </View>
      </View>
    );
  }
}

export default LoginSignUpTitle;
