/*
 * Author: Andrew Lee
 * Date: 16/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This will hold the functionality to navigate to the next input field given the scroll view reference at which it should be scrolling.
 */

import {findNodeHandle} from 'react-native';
import {KeyboardAwareScrollView} from 'helpers/KeyboardAwareScrollView';

/**
 * This function will look for the scroll view in the list of inputs, and also use the next field name to find the next input that the scroll view will need to scroll to.
 * @param {array} inputs The array of inputs as reference that can be used to get the objects.
 * @param {string} scrollViewName The scroll view name that is stored in the array of inputs.
 * @param {string} nextFieldName The next field focus name.
 */
const focusNextField = (
  inputs: any,
  scrollViewName: any,
  nextFieldName: any,
) => {
  const scrollView = inputs[scrollViewName];
  const nextField = inputs[nextFieldName];

  // Ensure both the scroll view and next field is there
  if (scrollView && nextField) {
    // Make sure there is a focus
    if (nextField.focus) {
      // Measure the layout in the next field
      nextField.measureLayout(
        findNodeHandle(scrollView),
        (x: any, y: any, width: any, height: any) => {
          if (scrollView instanceof KeyboardAwareScrollView) {
            nextField.focus();
          } else if (scrollView.scrollTo) {
            nextField.focus();
            // Scroll the input field to the top
            scrollView.scrollTo({x: 0, y, animdated: true});
          }
        },
      );
    }
  } else {
    if (inputs[nextFieldName] && inputs[nextFieldName].focus) {
      inputs[nextFieldName].focus();
    }
  }
};

export {focusNextField};
