/*
Author: Justin Kilimnik
Date: 02/03/18
Copyright © 2018 Leap in!. All rights reserved.

The Upload NDIS Plan.
*/

import React, {Component} from 'react';
import {View, Image, Platform, Alert, Dimensions} from 'react-native';

//import {NavigationActions} from 'react-navigation-awseeley--enhanced';
import {CommonActions} from '@react-navigation/native';
import {StackActions} from 'react-navigation';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
  StandardText,
  StandardInput,
  StandardButton,
  StandardTouchableOpacity,
} from '../Components/Atoms';
import {
  Container,
  DatePicker,
  BasicOverlayPopupModal,
  FormTitle,
} from '../Components/Molecules';
import {
  DocumentRestrictedPopUp,
  BasicPopupAlert,
} from '../Components/Organisms';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {getMember, updateMember, getUploadURL} from '../API/MemberAPI'; // For T&C acceptance - to move l8r
import {callAPIs} from '../API/APICaller';
import * as MemberActions from '../Actions/MemberActions';
import {
  formatDateForPlanID,
  removeLeadingZeros,
  getContentLayout,
  formatDateToString,
} from '../Helpers/Utils';
import {fileUploadRestrictCheck} from '../Helpers/FileHelper';

// import {
//   DocumentPicker,
//   DocumentPickerUtil
// } from 'react-native-document-picker-enhanced';
import * as mime from 'react-native-mime-types-enhanced';
// import Orientation from 'react-native-orientation-enhanced';
import {getStyleByOrientation} from '../../Helpers/GetStyleByOrientation'
import {BrandColors} from '../Styles/Colours';

import logger from 'helpers/Logger';
// Import assets
import {SignUpForPMAvatar3x, ApprovalsRed3x} from '../assets/images';
import {UploadRed4x} from '../assets/images/vector-icons';
import CustomIcon from '../assets/images/CustomIcon';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {UPLOAD_PLAN_SCREEN} from '../Constants/Messages';
import {isEmpty} from 'lodash';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {
  RNFetchBlobSingleton,
  isTablet,
  DocumentPickerSingleton,
  FilePickerSingleton,
  firebaseAnalysisLogEvent,
} from '../Helpers/PlatformSynchronizer';
import {getValue, removeItem} from '../API/WebPersistenceStore';

const {height, width} = Dimensions.get('window');

const HOME_SCREEN = 'Home';
const HOME_PROFILE_SCREEN = 'Profile';

class UploadPlan extends Component {
  filePickerRef: any;
  filePickerResponse: any;
  validNdisNumber: any;
  validPlanStartDate: any;
  constructor(props: any) {
    super(props);
    this.state = {
      loading: false,
      saveApiError: false,
      ndisNumber: '',
      planStartDate: '',
      planStartDateBorderWidth: 0,
      displayPlanStartDateError: false,
      displayNDISError: false,
      showPMRegisteredConfirmationModal: false,
      orientation: '',
      isOpen: this.props.sideMenu ? this.props.sideMenu.sideMenuIsOpen : false,
      documentRestrictPoupVisible: false,
      errorPopUpVisible: false,
    };

    this.validNdisNumber = false;
    this.validPlanStartDate = false;
    this.filePickerResponse = {};

    this._closeAlert = this._closeAlert.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props: any) {
    this.setState({
      isOpen: props.sideMenu ? props.sideMenu.sideMenuIsOpen : false,
    });
  }

  UNSAFE_componentWillMount = () => {
    if (
      types.isWeb &&
      this.props.member &&
      JSON.parse(getValue(types2.BOOK_MARK_SETUP_NEW_PLAN))
    ) {
      removeItem(types2.BOOK_MARK_SETUP_NEW_PLAN);
      this._deepLinkNavigator();
    }

    // if (!types.isWeb) {
    //   Orientation.getOrientation((err: any, orientation: any) => {
    //     this.setState({
    //       orientation,
    //     });
    //   });
    // }
  };

  componentWillUnmount = () => {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
  };

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation,
    });
  };

  _deepLinkNavigator = () => {
    if (
      this.props.member.memberStatus === types2.MEMBER_STATUS_DATA.PLAN_READY &&
      !this.props.member.pmtnCAccepted
    ) {
      return this.props.navigation.navigate(
        types2.DEEP_LINK_SCREENS.SIGNUP_FOR_PLAN_MANAGEMENT,
      );
    }
  };

  componentDidMount = () => {
    // if (!types.isWeb) {
    //   Orientation.addOrientationListener(this._orientationDidChange);
    // }
    if (
      Object.getOwnPropertyNames(this.props.member).length === 0 ||
      !this.props.member.planId
    ) {
      // reducer is empty, let's trigger the APIcall first
      this.setState({
        loading: true,
      });
      if (types.isWeb) {
        this.props.setLoading(true);
      }
      const callbackFunction = (data: any) => {
        const member = data[0].content;
        this.props.actions.MemberActions.actionGetMember(
          member,
          this.props.user.id,
          this.props.user.isEmployee,
          this.props.user.isFinancialStaff,
        );
        const reduxMember = this.props.member;
        this.initializeDataInState(reduxMember);
      };
      callAPIs(
        [getMember(this.props.loadedMemberId.loadedMemberId)],
        callbackFunction,
        null,
        () => {
          if (types.isWeb) {
            this.props.setLoading(false);
          }
          this.setState({
            loading: false,
          });
        },
      );
    } else {
      const reduxMember = this.props.member;
      this.initializeDataInState(reduxMember);
    }
  };

  initializeDataInState = (data: any) => {
    if (types.isWeb) {
      this.props.setLoading(false);
    }
    this.setState({
      ndisNumber: data.ndisNumber,
      loading: false,
    });
  };

  _closeAlert() {
    this.setState({
      errorPopUpVisible: false,
    });
  }

  _showAlert(alertMessage: any, errorPopUpVisible: any) {
    this.setState({
      alertMessage,
      errorPopUpVisible,
    });
  }

  _closePMRegisteredConfirmationModal = () => {
    this.setState({showPMRegisteredConfirmationModal: false});
    if (types.isWeb) {
      this.props.navigation.navigate('Profile');
    } else {
      const actionToDispatch = CommonActions.navigate({
        name:HOME_PROFILE_SCREEN,
        params:{}
      });

      this.props.navigation.dispatch(actionToDispatch);
    }
  };

  _closeDocumentAlert = () => {
    this.setState({
      documentRestrictPoupVisible: false,
    });
  };

  _determinePopUpStyle = () => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      styles.push([CommonStyles.containerInstructionPopUpWeb]);

      if (this.props.isMobile) {
        styles.push([CommonStyles.containerPopupWebMobile]);
      }
    }
    if (isTablet()) {
      styles.push(
        this.state.changePasswordSuccess
          ? CommonStyles.containerInstructionPopUpTablet
          : CommonStyles.containerChangePasswordPopUpTablet,
      );
    } else {
      styles.push(CommonStyles.changePasswordPopUpMobile);
    }
    return styles;
  };

  _renderPMRegisteredConfirmationModal = () => (
    <BasicOverlayPopupModal
      visible={this.state.showPMRegisteredConfirmationModal}
      style={this._determinePopUpStyle()}
      backdropOpacity={0.2}
      divider={true}
      close={this._closePMRegisteredConfirmationModal}
      cancelContainerStyle={CommonStyles.popUpOkButton}
      cancelStyle={CommonStyles.buttonPopupOk}
      cancelLabel={types2.OK_GOT_IT}
    >
      <View>{this.getPopUpContent()}</View>
    </BasicOverlayPopupModal>
  );

  getPopUpContent() {
    return (
      <View>
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.scrollView_subContentCenter,
          ]}
        >
          <View style={[CommonStyles.scrollView_subContentCenter]}>
            <StandardText
              style={[
                isTablet() ? CommonStyles.font25 : CommonStyles.font20,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
              ]}
            >
              {UPLOAD_PLAN_SCREEN.MODAL_WELCOME_TEXT}
            </StandardText>
          </View>
        </View>
        <View
          style={[
            CommonStyles.paddingTop10p,
            CommonStyles.scrollView_subContentCenter,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFont,
              BrandStyles.TextColor5,
              isTablet() ? CommonStyles.font18 : CommonStyles.rpfont15,
              CommonStyles.customFont,
              CommonStyles.paddingBottom5p,
            ]}
          >
            {UPLOAD_PLAN_SCREEN.MODAL_WE_WILL_BE_IN_TOUCH_TEXT}
          </StandardText>
        </View>
      </View>
    );
  }

  createMainContents = () => (
    <View
      style={[CommonStyles.content, BrandStyles.primaryBgColour4]}
      contentContainerStyle={[
        CommonStyles.alignItemsCenter,
        CommonStyles.justifyContentCentre,
        CommonStyles.scrollViewVerticalCentre,
      ]}
    >
      {this.state.showPMRegisteredConfirmationModal &&
        this._renderPMRegisteredConfirmationModal()}
      <DocumentRestrictedPopUp
        visible={this.state.documentRestrictPoupVisible}
        close={this._closeDocumentAlert}
      />
      <View
        style={[
          CommonStyles.mainContentContainer,
          BrandStyles.primaryBgColour1,
        ]}
      >
        <View style={[CommonStyles.singleMainContentContainer]}>
          <View style={[CommonStyles.SummaryTileContainer]}>
            <View
              style={[
                CommonStyles.justifyContentSpaceBetween,
                BrandStyles.borderColor3,
                CommonStyles.content,
                CommonStyles.SummaryTileHeaderContainer,
                CommonStyles.flexRow,
                CommonStyles.rpMarginBottom10p,
              ]}
            >
              <Image
                style={[
                  CommonStyles.leapInReadPlanManagedTnCIcon,
                  CommonStyles.rpMarginBottom5p,
                  CommonStyles.titleBoxIconMargin,
                  types.isWeb ? CommonStyles.marginLeft20 : {},
                ]}
                source={ApprovalsRed3x}
              />
              <View
                style={[
                  CommonStyles.flex1,
                  CommonStyles.flexDirectionRow,
                  CommonStyles.alignItemsFlexEnd,
                  CommonStyles.rpMarginBottom5p,
                ]}
              >
                <StandardText
                  style={[
                    CommonStyles.font20,
                    CommonStyles.customFontSemiBold,
                    BrandStyles.TextColor3,
                  ]}
                >
                  {UPLOAD_PLAN_SCREEN.LETS_GET_STARTED_TEXT}
                </StandardText>
              </View>
            </View>
          </View>
          <View style={CommonStyles.flexDirectionRow}>
            <View style={[CommonStyles.flex1, CommonStyles.zIndex04]}>
              <Image
                style={[
                  [
                    isTablet()
                      ? [
                          CommonStyles.signUpForUploadPlanAvatarIconLarge,
                          CommonStyles.top15p,
                          this._getLandscapeScale(),
                        ]
                      : [
                          CommonStyles.signUpForPlanManagementAvatarIcon,
                          CommonStyles.top30p,
                        ],
                  ],
                  CommonStyles.zIndex05,
                ]}
                source={SignUpForPMAvatar3x}
              />
            </View>
            <View
              style={[
                CommonStyles.zIndex02,
                CommonStyles.flex1,
                this._getLandscapeMargin(),
              ]}
            >
              <StandardText
                style={[
                  types.isWeb ? CommonStyles.font18 : CommonStyles.rpfont20,
                  BrandStyles.TextColor5,
                  CommonStyles.customFont,
                  CommonStyles.textAlignCentre,
                  CommonStyles.zIndex03,
                ]}
              >
                {this.state.displayNDISError ||
                this.state.displayPlanStartDateError
                  ? UPLOAD_PLAN_SCREEN.MESSAGE_TEXT_1_FORM_VALIDATION_FAILED
                  : UPLOAD_PLAN_SCREEN.MESSAGE_TEXT_1_FORM_INITIAL}
              </StandardText>
              <StandardText
                style={[
                  types.isWeb ? CommonStyles.font18 : CommonStyles.rpfont20,
                  BrandStyles.TextColor5,
                  CommonStyles.customFont,
                  CommonStyles.textAlignCentre,
                  CommonStyles.paddingTop10,
                  CommonStyles.zIndex03,
                ]}
              >
                {UPLOAD_PLAN_SCREEN.MESSAGE_TEXT_2}
              </StandardText>
            </View>
          </View>

          <View
            style={[
              isTablet() ? CommonStyles.paddingTop20 : CommonStyles.marginTop90,
            ]}
          >
            <View>
              <FormTitle
                text={UPLOAD_PLAN_SCREEN.NDIS_PARTICIPANT_ID}
                containsData={this.state.ndisNumber}
                hasError={this.state.displayNDISError}
              />
              <StandardInput
                keyboardType={'numeric'}
                accessibilityLabel={
                  UPLOAD_PLAN_SCREEN.NDIS_PARTICIPANT_ID_ACCESSIBILITY
                }
                key="participant_id"
                value={this.state.ndisNumber}
                blurOnSubmit={true}
                applyWrapper={false}
                errorMessage={this.checkError(
                  this.state.displayNDISError,
                  types.NDIS_PARTICIPANT_ID_IS_MANDATORY_ERROR_TEXT,
                )}
                onChangeText={(ndisNumber: any) => this.setState({ndisNumber})}
                underlineColorAndroid="transparent"
                style={[
                  CommonStyles.ModalTextInputWrapper,
                  BrandStyles.primaryBgColour1,
                ]}
                disabled={
                  this.props.member.enableNewPlanOption &&
                  this.props.member.memberStatus !==
                    types2.MEMBER_STATUS_DATA.PLAN_READY
                }
              />
            </View>
            <View>
              <FormTitle
                text={UPLOAD_PLAN_SCREEN.PLAN_START_DATE}
                containsData={this.state.planStartDate}
                hasError={this.state.displayPlanStartDateError}
              />
              <DatePicker
                value={this.state.planStartDate}
                errorBorder={this.state.planStartDateBorderWidth}
                placeholderText={
                  types.isWeb
                    ? null
                    : types2.DATE_PICKER_PLACEHOLDER.PLAN_START_DATE
                }
                accessibilityLabel={
                  types2.DATE_PICKER_PLACEHOLDER.PLAN_START_DATE
                }
                marginRight={false}
                save={(plandate: any) => {
                  if (
                    plandate != types2.DATE_PICKER_PLACEHOLDER.PLAN_START_DATE
                  ) {
                    this.setState(prevState => ({
                      planStartDate: plandate,
                    }));
                  }
                }}
              />
              {this._showError(
                this.state.displayPlanStartDateError,
                types.PLAN_START_DATE_NOT_SELECTED,
                types.PLAN_START_DATE_NOT_SELECTED_ACCESSIBLE_ERROR_TEXT,
              )}
            </View>
          </View>
          <View
            style={[
              CommonStyles.rpMarginBottom20p,
              CommonStyles.rpMarginTop20p,
              types.isWeb ? CommonStyles.zIndexN1 : null,
            ]}
          >
            <View style={[CommonStyles.flex1]}>
              <View
                style={[
                  CommonStyles.flexDirectionRow,
                  CommonStyles.alignCenter,
                ]}
              >
                <View style={[CommonStyles.paddingRight20]}>
                  <FilePickerSingleton
                    onRef={(ref: any) => {
                      if (types.isWeb && ref) {
                        this.filePickerRef = ref;
                      }
                    }}
                    extensions={types.VALID_DOCUMENT_TYPES}
                    maxSize={1000}
                    //assign inline styles for the file picker
                    style={{
                      marginLeft: 10,
                      backgroundColor: BrandColors.WHITE,
                    }}
                    onChange={(event: any) => {
                      if (types.isWeb) {
                        event.persist();
                        if (
                          event.target &&
                          event.target.files &&
                          event.target.files.length
                        )
                          this._webUploadDocument(event.target.files[0]);
                      }
                    }}
                    onError={(errMsg: any) => {
                      logger.log(errMsg);
                    }}
                  >
                    <StandardTouchableOpacity
                      accessibilityLabel={'Upload your plan'}
                      style={[
                        CommonStyles.tapToUploadContainer,
                        BrandStyles.primaryBgColor1,
                      ]}
                      onPress={(event: any) => {
                        if (types.isWeb && this.filePickerRef) {
                          this.filePickerRef.click();
                        }
                        this._uploadDocument(event);
                      }}
                    >
                      <CustomIcon
                        style={[CommonStyles.summaryTileButtonIcon]}
                        name={this.determineUploadIcon()}
                      />
                      <View style={[CommonStyles.rpMarginLeft20p]}>
                        <StandardText
                          style={[
                            CommonStyles.customFontBold,
                            CommonStyles.titleBoxIconMargin,
                            CommonStyles.SummaryTileHeaderText,
                            CommonStyles.paddingLeft10,
                            BrandStyles.TextColor2,
                            !types.isWeb ? CommonStyles.rpMarginTop5p : null,
                          ]}
                        >
                          {UPLOAD_PLAN_SCREEN.UPLOAD_FILE_TEXT}
                        </StandardText>
                      </View>
                    </StandardTouchableOpacity>
                  </FilePickerSingleton>
                </View>
              </View>
              <View
                style={[
                  CommonStyles.flexDirectionRow,
                  CommonStyles.alignCenter,
                ]}
              >
                {this.state.uploadedFile && (
                  <StandardText
                    style={[
                      CommonStyles.inputErrorMessageText,
                      CommonStyles.UploadPlanMTm20,
                      BrandStyles.TextColor3,
                      CommonStyles.rpMarginTop10p,
                      CommonStyles.textAlignCentre,
                    ]}
                  >
                    {this.state.uploadedFile}
                  </StandardText>
                )}
              </View>
            </View>
          </View>
          <View
            style={[
              CommonStyles.ModalActionButtons,
              types.isWeb
                ? [CommonStyles.zIndexN1, CommonStyles.paddingBottom100]
                : null,
            ]}
          >
            <View style={CommonStyles.ModalFooterLeft}>
              <StandardButton
                onPress={() => {
                  if (this.props.member.pmtnCAccepted) {
                    return this.props.navigation.goBack();
                  }
                  return this.props.navigation.navigate(
                    'TermsAndConditionsFullPM',
                  );
                }}
                style={[
                  BrandStyles.brandBlockTxtColor6,
                  CommonStyles.buttonFormAction,
                  CommonStyles.customFontBold,
                ]}
                containerStyle={[
                  BrandStyles.primaryBgColor1,
                  BrandStyles.brandBorderColor1,
                  CommonStyles.buttonContainerFormAction,
                ]}
                accessibilityLabel={UPLOAD_PLAN_SCREEN.CANCEL_BUTTON_TEXT}
              >
                {UPLOAD_PLAN_SCREEN.CANCEL_BUTTON_TEXT}
              </StandardButton>
            </View>
            <View style={CommonStyles.ModalFooterRight}>
              <StandardButton
                onPress={async () => {
                  await this._submit(true);
                }}
                style={[
                  BrandStyles.brandBlockTxtColor2,
                  CommonStyles.buttonFormAction,
                  CommonStyles.customFontBold,
                ]}
                containerStyle={[
                  BrandStyles.primaryBgColor4,
                  BrandStyles.brandBorderColor1,
                  CommonStyles.buttonContainerFormAction,
                ]}
                accessibilityLabel={UPLOAD_PLAN_SCREEN.SAVE_BUTTON_TEXT}
              >
                {UPLOAD_PLAN_SCREEN.SAVE_BUTTON_TEXT}
              </StandardButton>
            </View>
          </View>
          <View style={[CommonStyles.ModalSingleRow]} />
        </View>
      </View>
      <BasicPopupAlert
        visible={this.state.errorPopUpVisible}
        close={this._closeAlert}
        cancelLabel={types2.OK}
        headerText={types2.ERROR}
        messageText={this.state.alertMessage}
        isMobile={this.props.isMobile}
      />
    </View>
  );

  _getLandscapeMargin() {
    if (isTablet() && this.state.orientation === types2.ORIENTATION.LANDSCAPE) {
      return [CommonStyles.signUpForPlanManagementAvatarCloseMarginLC];
    }
  }

  _getLandscapeScale() {
    if (this.state.orientation === types2.ORIENTATION.LANDSCAPE) {
      if (getContentLayout(isTablet(), width, height)) {
        return [CommonStyles.signUpForPMUploadAvatarScaleLC];
      } else {
        return [CommonStyles.signUpForPMUploadAvatarScaleLCSmall];
      }
    } else {
      if (getContentLayout(isTablet(), width, height)) {
        return [CommonStyles.signUpForPMUploadAvatarScalePR];
      } else {
        return [CommonStyles.signUpForPMUploadAvatarScalePRSmall];
      }
    }
  }

  _getContainerLandscapeScale() {
    if (isTablet() && this.state.orientation === types2.ORIENTATION.LANDSCAPE) {
      return [CommonStyles.signUpForPlanManagementUploadContainerLargeScaleLC];
    } else {
      return [CommonStyles.signUpForPlanManagementUploadContainerLargeScalePR];
    }
  }

  _getLandscapeMargin() {
    if (isTablet() && this.state.orientation === types2.ORIENTATION.LANDSCAPE) {
      return [CommonStyles.signUpForPlanManagementAvatarCloseMarginLC];
    }
  }

  _getLandPRScale() {
    if (
      isTablet() &&
      !(this.state.orientation === types2.ORIENTATION.LANDSCAPE) &&
      this.state.isOpen
    ) {
      return CommonStyles.signUpForPlanManagementTextContainerWidthScaleClose;
    } else {
      return CommonStyles.signUpForPlanManagementTextContainerWidthScaleOpen;
    }
  }

  determinePlanDateEntered() {
    if (
      this.state.planStartDate &&
      this.state.planStartDate !==
        types2.DATE_PICKER_PLACEHOLDER.PLAN_START_DATE
    ) {
      return true;
    }
    return false;
  }

  checkError(check: any, text: any) {
    if (check) {
      return text;
    }
    return null;
  }

  _showError = (check: any, text: any, accessibilityLabel: any) => {
    if (!check) {
      return null;
    } else {
      if (!accessibilityLabel) {
        accessibilityLabel = text;
      }
      return (
        <StandardText
          accessibilityLabel={accessibilityLabel}
          style={[
            CommonStyles.inputErrorMessageText,
            CommonStyles.UploadPlanMTm20,
            CommonStyles.customFont,
          ]}
        >
          {text}
        </StandardText>
      );
    }
  };

  _webUploadDocument = (fileObject: any) => {
    if (fileUploadRestrictCheck(fileObject.name)) {
      this.setState({documentRestrictPoupVisible: true});
    } else {
      this.filePickerResponse = fileObject;
      this.filePickerResponse.fileName = fileObject.name;
      // Set the pick file name to be displayed in the screen
      this.setState({
        uploadedFile: this.filePickerResponse.fileName,
      });
    }
  };

  _uploadDocument(event: any) {
    const pageX = event.nativeEvent ? event.nativeEvent.pageX : 0;
    const pageY = event.nativeEvent ? event.nativeEvent.pageY : 0;
    const options = {
      title: UPLOAD_PLAN_SCREEN.SELECT_DOCUMENT,
      customButtons: [{name: types.FROM_DRIVES_KEY, title: types.FROM_DRIVES}],
      mediaType: 'photo',
      storageOptions: {
        skipBackup: true,
        path: 'images',
      },
    };

    DocumentPickerSingleton(pageX, pageY, options).then(response => {
      if (response.documentRestrictPoupVisible) {
        this.setState({documentRestrictPoupVisible: true});
      } else {
        const osType=Platform.OS === types2.ANDROID
        let assets = response.assets?.[0];
        if(Platform.OS === types2.IOS){
          assets = response
        }
        this.filePickerResponse = {
          fileName: assets.fileName,
          path:Platform.OS === types2.IOS ? assets.path : assets.uri,
          type: assets.type
            ? assets.type
            : mime.types[assets.fileName.split('.').pop()],
          uri: assets.uri,
        };
        // Set the pick file name to be displayed in the screen
        this.setState({
          uploadedFile: this.filePickerResponse.fileName,
        });
      }
    });
  }

  uploadPlanToServer(filePickerResponse: any, ndisNumber: any) {
    return new Promise((resolve, reject) => {
      // if there is no file picked resolve the promise
      if (isEmpty(filePickerResponse)) {
        return resolve();
      }

      // Ammend the NDIS Number and Time Stamp to plan attachment file name
      const namePart = filePickerResponse.fileName.split(/\.(?=[^\.]+$)/)[0];
      const extentionPart =
        filePickerResponse.fileName.split(/\.(?=[^\.]+$)/)[1];
      const fileName = `${namePart}_${ndisNumber}_${Date.now()}.${extentionPart}`;

      const requestObject = {
        fileType: types2.FILE_TYPE.DOCUMENT,
        filename: fileName,
        contentType: filePickerResponse.type,
        documentCategory: types2.CURRENT_PLAN_DOC_CATEGORY,
      };
      // Create Callback function with URL Returned
      const getUploadURLCallbackFunction = (data: any, err: any) => {
        data = data[0];
        if (data) {
          if (data.statusCode == 200) {
            //Successful save -- update redux store and hide modal
            let url = data.content.uploadURL;
            var cleanUri = '';
            if (!types.isWeb) {
              if (Platform.OS === types2.IOS) {
                cleanUri = decodeURI(
                  filePickerResponse.path.replace('file://', ''),
                );
              } else if (Platform.OS === types2.ANDROID) {
                cleanUri = filePickerResponse.path;
              }
              RNFetchBlobSingleton.fetch(
                'PUT',
                url,
                {
                  'Content-Type': filePickerResponse.type,
                },
                RNFetchBlobSingleton.wrap(cleanUri),
              )
                .then(() => {
                  this.setState({
                    loading: false,
                  });
                  // Upload successfull resolve the Promise
                  resolve(fileName);
                })
                .catch(error => {
                  logger.log('The error is:', error);
                  this._showAlert(
                    UPLOAD_PLAN_SCREEN.COULDNT_SAVE_PLAN_ERROR_TEXT,
                    true,
                  );
                  this.setState({
                    saveApiErrorCode: null,
                    saveApiError: true,
                    loading: false,
                  });
                  // Reject the Promise because of the error
                  return reject();
                });
            } else {
              filePickerResponse.fileName = fileName;
              fetch(url, {
                method: 'PUT',
                headers: {
                  'Content-Type': filePickerResponse.type,
                },
                body: filePickerResponse,
              })
                .then(() => {
                  this.setState({
                    loading: false,
                  });
                  if (types.isWeb) {
                    this.props.setLoading(false);
                  }
                  // Upload successfull resolve the Promise
                  resolve(fileName);
                })
                .catch(error => {
                  logger.log('The error is:', error);
                  this._showAlert(
                    UPLOAD_PLAN_SCREEN.COULDNT_SAVE_PLAN_ERROR_TEXT,
                    true,
                  );
                  this.setState({
                    saveApiErrorCode: null,
                    saveApiError: true,
                    loading: false,
                  });
                  if (types.isWeb) {
                    this.props.setLoading(false);
                  }
                  // Reject the Promise because of the error
                  return reject();
                });
            }
          } else {
            //error code returned by lambda
            if (data.Payload.statusCode == 403) {
              this.setState({
                saveApiErrorCode: 403,
                saveApiError: true,
                loading: false,
              });
              if (types.isWeb) {
                this.props.setLoading(false);
              }
            } else {
              this.setState({
                saveApiErrorCode: null,
                saveApiError: true,
                loading: false,
              });
              if (types.isWeb) {
                this.props.setLoading(false);
              }
            }
            this._showAlert(
              UPLOAD_PLAN_SCREEN.COULDNT_SAVE_PLAN_ERROR_TEXT,
              true,
            );
            // Reject the Promise because of the error
            return reject();
          }
        } else if (err) {
          // error from AWS APK
          if (err.name === types.NETWORK_FAILURE) {
            this._showAlert(
              UPLOAD_PLAN_SCREEN.COULDNT_SAVE_PLAN_NETWORK_ERROR_TEXT,
              true,
            );
            this.setState({
              saveApiErrorCode: types.NETWORK_FAILURE,
              saveApiError: true,
              loading: false,
            });
            if (types.isWeb) {
              this.props.setLoading(false);
            }
          } else {
            // another error occured other than network failure
            this._showAlert(
              UPLOAD_PLAN_SCREEN.COULDNT_SAVE_PLAN_ERROR_TEXT,
              true,
            );
            this.setState({
              saveApiErrorCode: null,
              saveApiError: true,
              loading: false,
            });
            if (types.isWeb) {
              this.props.setLoading(false);
            }
          }
          // Reject the Promise because of the error
          return reject();
        } else {
          // catch all
          this._showAlert(
            UPLOAD_PLAN_SCREEN.COULDNT_SAVE_PLAN_ERROR_TEXT,
            true,
          );
          this.setState({
            saveApiErrorCode: null,
            saveApiError: true,
            loading: false,
          });
          if (types.isWeb) {
            this.props.setLoading(false);
          }
          // Reject the Promise because of the error
          return reject();
        }
      };

      const getUploadURLErrorCallbackFunction = (err: any, reject: any) => {
        this.setState({
          saveApiErrorCode: err.code,
          saveApiError: true,
          loading: false,
        });
        if (types.isWeb) {
          this.props.setLoading(false);
        }
        reject(err);
      };

      // Upload URL
      this.setState({
        loading: true,
        saveApiError: false,
      });
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      callAPIs(
        [
          getUploadURL(
            this.props.loadedMemberId.loadedMemberId,
            requestObject,
            false,
          ),
        ],
        getUploadURLCallbackFunction,
        getUploadURLErrorCallbackFunction,
      );
    });
  }

  uploadFileText() {
    if (this.state.uploadedFile) {
      return this.state.uploadedFile;
    }
    return UPLOAD_PLAN_SCREEN.UPLOAD_FILE_TEXT;
  }

  uploadFileTextStyle() {
    const styles = [
      CommonStyles.customFontBold,
      CommonStyles.titleBoxIconMargin,
      CommonStyles.SummaryTileHeaderText,
      CommonStyles.rpMarginLeft20p,
    ];

    if (this.state.uploadedFile) {
      styles.push(BrandStyles.TextColor3);
    } else {
      styles.push(BrandStyles.TextColor2);
    }
    return styles;
  }

  determineUploadIcon() {
    return UploadRed4x;
  }

  /* Validate the screen.
   * @return Boolean if valid or not.
   */
  valid = () => {
    var valid = true;

    if (
      !this.state.ndisNumber ||
      this.state.ndisNumber.length == 0 ||
      isNaN(this.state.ndisNumber)
    ) {
      this.setState({displayNDISError: true});
      this.validNdisNumber = false;
      valid = false;
    } else {
      this.setState({displayNDISError: false});
      this.validNdisNumber = true;
    }

    if (!this.state.planStartDate || this.state.planStartDate.length == 0) {
      this.setState({
        displayPlanStartDateError: true,
        planStartDateBorderWidth: 3,
      });
      this.validPlanStartDate = false;
      valid = false;
    } else {
      this.setState({
        displayPlanStartDateError: false,
        planStartDateBorderWidth: 0,
      });
      this.validPlanStartDate = true;
    }

    return valid;
  };

  /* Go next action, if the screen is valid or not (depending on applyValidation input).
   */
  async _submit(applyValidation: any) {
    // If the form validation fails check and save any filled up valid inputs
    if (!this.valid() && applyValidation) {
      // Check if both planStartDate and ndisNumber are not valid
      // If not return because there is nothing to save
      if (!this.validNdisNumber && !this.validPlanStartDate) {
        return;
      }

      // One of NdisNumber or PlanStartDate is valid
      // Update valid NdisNumber or PlanStartDate without blocking the UI
      return this._updateMemberNdisNumberOrPlanStartDate();
    }

    // If the form is valid upload the plan to server and update member data
    if (!applyValidation || this.valid()) {
      if (types.isWeb) {
        this.props.setLoading(true);
      }
      const uploadedFileName = await this.uploadPlanToServer(
        this.filePickerResponse,
        this.state.ndisNumber,
      );
      const memberData = await this._updateMemberAndPromoteToPMRequested(
        uploadedFileName,
      );
      return await this._fetchAndUpdateMember(memberData);
    }
  }

  _updateMemberAndPromoteToPMRequested(uploadedFileName: any) {
    return new Promise((resolve, reject) => {
      // The NDIS Plan number is made up of the NDIS number and the plan start date
      const planStartDate = this.state.planStartDate;
      // TODO: generating ndisPlanNumber from planStaetDate is to be moved to backend (Roshan)
      const ndisPlanNumber = `${this.state.ndisNumber}${formatDateForPlanID(
        planStartDate,
      )}`;

      // Go to the next screen
      const saveObj = {
        ndisNumber: this.state.ndisNumber,
        ndisPlanNumber,
        planStartDate: formatDateToString(planStartDate),
        id: this.props.loadedMemberId.loadedMemberId,
        memberStatus: types2.MEMBER_STATUS_DATA.ONBOARDING,
        planManager: types2.PLAN_MANAGER_DATA.LEAP_IN,
        planStatus: types2.PLAN_STATUS_DATA.PM_REQUESTED,
        isPlanUpload: true,
        planAttachmentFileName: uploadedFileName,
      };

      this.setState({
        loading: true,
      });
      if (types.isWeb) {
        this.props.setLoading(true);
      }

      const callback = (memberData: any) => {
        this.setState({
          showPMRegisteredConfirmationModal: true,
        });
        this._pushAnalyticsLogAndUpdateReduxMember(saveObj);
        resolve(memberData);
      };
      callAPIs(
        [updateMember(saveObj, this.props.member.isDemoProfile, true)],
        callback,
        null,
        () => {
          this.setState({loading: false});
          if (types.isWeb) {
            this.props.setLoading(false);
          }
        },
      );
    });
  }

  _updateMemberNdisNumberOrPlanStartDate() {
    // If either planStartDate or ndisNumber is filled with valid values
    // Add them to updateObject
    const updateObject = {
      id: this.props.loadedMemberId.loadedMemberId,
    };

    // If NDIS number is valid add it to updateObject
    if (this.validNdisNumber) {
      updateObject.ndisNumber = this.state.ndisNumber;
    }

    // If planStartDate is valid add it to updateObject
    if (this.validPlanStartDate) {
      updateObject.planStartDate = formatDateToString(this.state.planStartDate);
    }

    return callAPIs(
      [updateMember(updateObject, true)],
      () => this._pushAnalyticsLogAndUpdateReduxMember(updateObject),
      null,
      () => {
        this.setState({loading: false});
        if (types.isWeb) {
          this.props.setLoading(false);
        }
      },
    );
  }

  _fetchAndUpdateMember(memberData: any) {
    return new Promise((resolve, reject) => {
      const callbackFunction = () => {
        const member = memberData[0].content;
        this.props.actions.MemberActions.actionGetMember(
          member,
          this.props.user.id,
          this.props.user.isEmployee,
          this.props.user.isFinancialStaff,
        );
        const reduxMember = this.props.member;
        this.initializeDataInState(reduxMember);
        resolve();
      };

      callAPIs(
        [getMember(this.props.loadedMemberId.loadedMemberId)],
        callbackFunction,
        null,
        () => {
          this.setState({
            loading: false,
          });
          if (types.isWeb) {
            this.props.setLoading(false);
          }
        },
      );
    });
  }

  _pushAnalyticsLogAndUpdateReduxMember(libeObj: any) {
    if (!types.isWeb) {
      firebaseAnalysisLogEvent(
        types.FIREBASE_ANALYTICS_EVENTS.SIGNUP_FOR_PLAN_MANAGEMENT_COMPLETED,
        {},
      );
    }

    this.props.actions.MemberActions.actionUpdateMember(libeObj);
  }

  render() {
    if (types.isWeb) {
      return this.createMainContents();
    } else {
      return (
        <Container
          contents={this.createMainContents}
          loading={this.state.loading}
          sidebarMode={types2.SIDEBAR_MODE.EMPTY}
          needsSidebar
          customSidebar
          screenType={types.SCREEN_TYPE_MAIN}
          activeScreen={types.SCREEN_UPLOAD_PLAN}
          toggleMenu={this.props.screenProps?.toggleMenu}
          getInitialMenuState={this.props.screenProps?.getInitialMenuState}
          nav={this.props.navigation}
          headerTitle={types2.NAVIGATION_TEXT.SIGN_UP_DETAILS}
          demoProfile={this.props.user.demoProfile}
          showConfirmLogout={
            this.props.actions.LogoutConfirmationActions.showConfirmLogout
          }
        />
      );
    }
  }
}

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
  member: state.MemberReducer,
  loadedMemberId: state.LoadedMemberReducer,
  sideMenu: state.SideMenuReducer,
  livingArrangement: state.LivingArrangementReducer,
  favouriteThings: state.FavouriteThingsReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    MemberActions: bindActionCreators(MemberActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadPlan);
