/*
 * Author: Shelan Cooray
 * Date: 04/09/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the Checkbox button(Defualt is cricular Red color icon) combined with PhotoTile used throughout the app.
 *
 */

import React, {Component} from 'react';
import {Image, View, StyleSheet} from 'react-native';

import {StandardTouchableOpacity} from '../Atoms';
import {PhotoTile} from './';

import {
  MediumRedTickCircleRed3x,
  MediumRedEmptyCircleRed3x,
} from '../../assets/images';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

// Import Stylesheets
import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';
import {MESSAGES} from '../../Constants/Messages';

class PhotoTileCheckBoxButton extends Component {
  state = {props: {}};

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    this.setState({data: this.props.data});
  }

  render() {
    let disabled = false;
    if (this.props.readOnly == types.ACCESS_READ_ONLY) {
      disabled = true;
    }

    return (
      <StandardTouchableOpacity
        accessibilityLabel={
          this.props.photoTileCaption +
          (this.props.data.selected || this.props.isSelected
            ? MESSAGES.ACCESSIBILITY.RADIO_BUTTON.SELECTED
            : MESSAGES.ACCESSIBILITY.RADIO_BUTTON.NOT_SELECTED)
        }
        style={CommonStyles.flexDirectionRow}
        activeOpacity={0.6}
        onPress={() => {
          this._toggleCheckbox();
        }}
        disabled={disabled}
      >
        <View
          style={[CommonStyles.alignSelfCenter, CommonStyles.paddingBottom20]}
        >
          <Image
            style={[this._determineIconStyle()]}
            source={this._determineIcon()}
          />
        </View>
        <View
          style={[
            CommonStyles.flex1,
            CommonStyles.flexWrap,
            CommonStyles.paddingBottom20,
          ]}
        >
          <PhotoTile
            rightMargin={false}
            clickable={this.props.photoTileClickable}
            style={this.props.photoTileStyle}
            borderRadius={this.props.radious}
            apiFileType={this.props.photoTileApiFileType}
            imageObject={this.props.photoTileImageObject}
            showCaption={this.props.photoTileShowCaption}
            caption={this.props.photoTileCaption}
            captionStyles={this.props.photoTileCaptionStyle}
            actionCallback={this.props.photoTileActionCallback}
            actionData={this.props.data}
            photoBorderRadius={this.props.photoBorderRadius}
          />
        </View>
      </StandardTouchableOpacity>
    );
  }

  _determineIcon = () => {
    if (this.props.data.selected || this.props.isSelected) {
      if (this.props.checkBoxTickedIcon) {
        return this.props.checkBoxTickedIcon;
      } else {
        return MediumRedTickCircleRed3x;
      }
    } else {
      if (this.props.checkBoxUnTickedIcon) {
        return this.props.checkBoxUnTickedIcon;
      } else {
        return MediumRedEmptyCircleRed3x;
      }
    }
  };

  _determineIconStyle = () => {
    let styles = [CommonStyles.CheckBoxButtonIcon];
    if (this.props.iconStyle) {
      styles = [this.props.iconStyle];
    }
    return styles;
  };

  _determineIconContainerStyle = () => {
    let styles = [];
    if (this.props.iconContainerStyle) {
      styles = [this.props.iconContainerStyle];
    } else {
      styles = [CommonStyles.alignSelfCenter, CommonStyles.marginRight20];
    }
    return styles;
  };

  _determineLabelColor = () => {
    var styles = [
      CommonStyles.customFontMedium,
      BrandStyles.TextColor1,
      CommonStyles.checkBoxLabelText,
    ];
    if (this.props.data.selected) {
      styles.push([BrandStyles.TextColor1]);
    } else {
      styles.push([BrandStyles.TextColor5]);
    }
    return styles;
  };

  _toggleCheckbox = () => {
    let setStateCallbackFunction = () => {
      this.props.onChange(this.state.data);
    };
    var stateObj = this.state.data;
    stateObj.selected = !stateObj.selected;
    this.setState({data: stateObj}, setStateCallbackFunction);
  };
}

export default PhotoTileCheckBoxButton;
