/*
 * Author: Shelan Cooray
 * Date: 22/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This screen will allow the member to reject the invoice, and be able to give a reason as to why they are rejecting the invoice.
 */

import React, {Component} from 'react';
import {View, Image} from 'react-native';
//import {NavigationActions} from 'react-navigation-awseeley--enhanced';
import {CommonActions, StackActions} from '@react-navigation/native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {StandardButton, StandardInput, StandardText} from '../Components/Atoms';
import {BasicForm} from '../Components/Organisms';
import {
  FormTitle,
  FormSubtitle,
  CheckBoxButton,
  Container,
  BrandActivityIndicator,
  BasicOverlayPopupModal,
} from '../Components/Molecules';

import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {
  APPROVALS_INVOICE_REJECT as messages,
  COMMON_BUTTONS as buttonMessages,
  MESSAGES,
} from '../Constants/Messages';
import {isTablet} from '../Helpers/PlatformSynchronizer';
import {callAPIs} from '../API/APICaller';
import {putInvoiceApproval, putAgreementApproval} from '../API/PlanDataAPI';
import {updateNotifications} from '../API/NotificationAPI';
import logger from 'helpers/Logger';
import {RightTick3x, InformationIconx3} from '../assets/images';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
const SUCCESS_REJECTION = 'SuccessRejection';

class ApprovalsDetailsInvoiceReject extends Component {
  refreshSummary: any;
  selectedItem: any;
  state = {
    loading: false,
    rejectReasonType: '',
    rejectReasonDetail: '',
    rejectReasonsArray: [],
    displayRejectionEmailPopUp: false,
  };

  constructor(props: any) {
    super(props);
    this.selectedItem = this.props.navigationParams.params.selectedItem;
    this.refreshSummary = this.props.navigationParams.params.refreshSummary;
    this._renderCloseStyles = this._renderCloseStyles.bind(this);
    this._renderSaveStyles = this._renderSaveStyles.bind(this);
    this._renderDeclines = this._renderDeclines.bind(this);
    this._deterMineSubtitle = this._deterMineSubtitle.bind(this);
    this._createMainContent.bind(this);
    this._determinePopUpStyle.bind(this);
    this.getInvoiceRejectEmailDecisionButtonGroup =
      this.getInvoiceRejectEmailDecisionButtonGroup.bind(this);
  }

  UNSAFE_componentWillMount() {
    let rejectReasonsRef = types2.INVOICE_REJECT_REASONS.REASONS;
    // Check if the selected item is an invoice or service agreement
    if (this.selectedItem.type === types2.APPROVAL_TYPE.SERVICE) {
      rejectReasonsRef = types2.SA_REJECT_REASONS.REASONS;
    }

    // Need to create an array that has the selected state of the reject reasons
    const rejectReasons = rejectReasonsRef.map(rejectItem => {
      return {
        key: rejectItem.key,
        description: rejectItem.description,
        rejection: rejectItem.rejection,
        selected: false,
      };
    });

    this.setState({rejectReasonsArray: rejectReasons});
  }

  render() {
    if (types.isWeb) {
      return this._createMainContent();
    }
    return (
      <Container
        contents={this._createMainContent}
        loading={this.state.loading}
        needsSidebar={false}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        headerTitle={types2.NAVIGATION_TEXT.APPROVAL}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        toggleMenu={this.props.screenProps?.toggleMenu}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContent = () => {
    // Configure the title according to the type
    let title = messages.TITLE;

    if (this.selectedItem.type === types2.APPROVAL_TYPE.INVOICE) {
      title += messages.INVOICE;
    } else if (this.selectedItem.type === types2.APPROVAL_TYPE.SERVICE) {
      title += messages.AGREEMENT;
    }

    return (
      <BasicForm
        hideTitle={true}
        close={this._closeForm}
        closeStyle={this._renderCloseStyles()}
        save={this._checkRejectionReasonForEmailPopup}
        saveLabel={buttonMessages.CONFIRM_DECLINE}
        saveStyle={this._renderSaveStyles()}
        disableMore={true}
        readOnly={this.props.financeAccess}
        saveApiError={this.state.saveApiError}
        saveApiErrorCode={this.state.saveApiErrorCode}
        buttonsPositionBottom={true}
        contentWidthFull={true}
      >
        <BasicOverlayPopupModal
          visible={this.state.demoErrorPopup}
          style={this._determinePopUpStyle(isTablet())}
          backdropOpacity={0.2}
          divider={true}
          close={this._closeApprovePopUp}
          cancelContainerStyle={CommonStyles.popUpOkButton}
          cancelStyle={[
            CommonStyles.buttonPopupOk,
            CommonStyles.customFontBold,
          ]}
          cancelLabel={types2.OK_GOT_IT}
        >
          <StandardText>{this.getPopUpContent(isTablet())}</StandardText>
        </BasicOverlayPopupModal>

        <BasicOverlayPopupModal
          visible={this.state.displayRejectionEmailPopUp}
          style={this._determinePopUpStyle(isTablet())}
          backdropOpacity={0.2}
          divider={true}
          cancelLabel={types2.CANCEL}
          close={() => {
            this.setState({
              displayRejectionEmailPopUp: false,
            });
          }}
        >
          <View>
            {this.getPopUpContent(isTablet())}
            {this.getInvoiceRejectEmailDecisionButtonGroup(isTablet())}
          </View>
        </BasicOverlayPopupModal>

        <View>
          <FormTitle text={title} containsData={true} />
          <FormSubtitle text={[messages.SUB_TITLE]} />
          <View style={[CommonStyles.ApprovalsDetailsInvoiceRejectFlexRowWrap]}>
            {this._renderDeclines()}
          </View>
          <FormTitle text={messages.TITLE_FURTHER_MORE} containsData={true} />
          {this._deterMineSubtitle()}
          <View>
            <StandardInput
              readOnly={this.props.financeAccess}
              multiline={true}
              numberOfLines={6}
              value={this.state.rejectReasonDetail}
              placeholder={messages.DETAILS_PLACEHOLDER}
              onChangeText={(text: any) =>
                this.setState({rejectReasonDetail: text})
              }
              underlineColorAndroid="transparent"
              style={[
                CommonStyles.ModalTextInputWrapper,
                BrandStyles.primaryBgColor1,
              ]}
              blurOnSubmit={false}
            />
          </View>
        </View>
      </BasicForm>
    );
  };

  _renderCloseStyles = () => {
    return {
      style: [
        BrandStyles.brandBlockTxtColor6,
        CommonStyles.buttonBudgetAction,
        CommonStyles.customFontBold,
      ],
      containerStyle: [
        BrandStyles.primaryBgColor1,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerBudget,
      ],
    };
  };

  _renderSaveStyles = () => {
    return {
      style: [
        BrandStyles.brandBlockTxtColor5,
        CommonStyles.buttonBudgetAction,
        CommonStyles.customFontBold,
      ],
      containerStyle: [
        BrandStyles.primaryBgColor4,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerBudget,
      ],
    };
  };

  _renderDeclines = () => {
    if (this.state.rejectReasonsArray.length === 0) {
      return null;
    } else {
      // Build the JSX array
      return this.state.rejectReasonsArray.map((rejectItem, index) => {
        return (
          <CheckBoxButton
            readOnly={this.props.financeAccess}
            columns={1}
            seperator={true}
            seperatorStyle={CommonStyles.greySeperator_RadioButton_Budgets}
            circular={true}
            key={rejectItem.key}
            data={rejectItem}
            iconStyle={[CommonStyles.checkBoxIcon_Segmented]}
            textStyle={[
              CommonStyles.customFont,
              types.isWeb ? CommonStyles.font15 : CommonStyles.font18,
              CommonStyles.paddingLeft10,
            ]}
            onChange={(checkBoxObj: any) => {
              this._updateSelectedItem(checkBoxObj);
            }}
          />
        );
      });
    }
  };

  _deterMineSubtitle = () => {
    let subTitle = messages.SUB_TITLE_FURTHER_MORE;
    if (this.selectedItem.type === types2.APPROVAL_TYPE.SERVICE) {
      subTitle = messages.SUB_TITLE_FURTHER_MORE_SA;
    }
    return <FormSubtitle text={[subTitle]} />;
  };

  _updateSelectedItem = (itemSelected: any) => {
    // Find the item that was selected previously, and deselect it and then update the item that has been selected in the state
    const {rejectReasonsArray} = this.state;
    let email_popup = false;
    let resolve_invoice = false;

    // Loop through the reject reasons array
    const updateArray = rejectReasonsArray.map(arrayItem => {
      const updateItem = arrayItem;
      if (updateItem.selected) {
        updateItem.selected = false;
      }

      if (updateItem.key === itemSelected.key) {
        updateItem.selected = true;
        email_popup = updateItem.rejection.email_popup;
        resolve_invoice = updateItem.rejection.resolve_invoice;
      }

      return updateItem;
    });

    this.setState({
      rejectReasonsArray: updateArray,
      rejectReasonType: itemSelected.key,
      email_popup: email_popup,
      resolveType: resolve_invoice,
      errorPopup: true,
      messageHeader: `Let ${this.props.navigationParams.params.invoiceDetails.biller.name} know?`,
      messageBody: `Would you like us to email ${this.props.navigationParams.params.invoiceDetails.biller.name} and let them know you are rejecting the invoice? We will include the reason and your notes.`,
    });
  };

  _validateCheckBox = () => {
    let validation = false;
    for (let i = 0; i < this.state.rejectReasonsArray.length; i++) {
      if (this.state.rejectReasonsArray[i].selected) {
        validation = true;
        break;
      }
    }
    return validation;
  };

  _closeApprovePopUp = () => {
    this.setState({
      demoErrorPopup: false,
    });
  };

  getInvoiceRejectEmailDecisionButtonGroup = (isTablet: any) => {
    let btnFontSize = CommonStyles.rpfont15;
    let btnMaxWidth = CommonStyles.buttonMaxWidth70;
    if (isTablet) {
      btnFontSize = CommonStyles.font20;
      btnMaxWidth = CommonStyles.buttonWidth150;
    } else if (types.isWeb) {
      btnFontSize = CommonStyles.font15;
    }
    return (
      <View style={CommonStyles.approvePopup}>
        <StandardButton
          style={[
            BrandStyles.brandBlockTxtColor5,
            CommonStyles.buttonBudgetAction,
            CommonStyles.customFontBold,
            btnFontSize,
            btnMaxWidth,
          ]}
          containerStyle={[
            BrandStyles.primaryBgColor4,
            BrandStyles.brandBorderColor1,
            CommonStyles.buttonContainerBudget,
          ]}
          accessibilityLabel={`No Don't Email Provider`}
          onPress={() => {
            this._rejectItem(types2.INVOICE_REJECTION_EMAIL_OPTIONS.NOT_SEND);
            this.setState({
              displayRejectionEmailPopUp: false,
            });
          }}
        >
          {'No, Don’t Email'}
        </StandardButton>
        <StandardButton
          style={[
            BrandStyles.brandBlockTxtColor5,
            CommonStyles.buttonBudgetAction,
            CommonStyles.customFontBold,
            btnFontSize,
            btnMaxWidth,
          ]}
          containerStyle={[
            BrandStyles.primaryBgColor4,
            BrandStyles.brandBorderColor1,
            CommonStyles.buttonContainerBudget,
          ]}
          accessibilityLabel={`Yes, Email Provider`}
          onPress={() => {
            this._rejectItem(types2.INVOICE_REJECTION_EMAIL_OPTIONS.SEND);
            this.setState({
              displayRejectionEmailPopUp: false,
            });
          }}
        >
          {'Yes, Email Provider'}
        </StandardButton>
      </View>
    );
  };

  getPopUpContent(isTablet: any) {
    let messageFontSize = CommonStyles.rpfont15;
    let headerFontSize = CommonStyles.font20;
    if (isTablet) {
      messageFontSize = CommonStyles.font20;
      headerFontSize = CommonStyles.font25;
    } else if (types.isWeb) {
      messageFontSize = CommonStyles.font15;
      headerFontSize = CommonStyles.font18;
    }
    return (
      <View>
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.scrollView_subContentCenter,
            CommonStyles.paddingBottom5p,
          ]}
        >
          {this.state.errorPopup && (
            <View>
              <Image
                visible={this.state.errorPopup}
                style={[CommonStyles.changePasswordButtonImage]}
                source={InformationIconx3}
                accessibilityLabel={
                  MESSAGES.ACCESSIBILITY.IMPORTANT_INFORMATION
                }
                accessible={true}
              />
            </View>
          )}
          <View
            style={[
              CommonStyles.scrollView_subContentCenter,
              CommonStyles.width100Percent,
            ]}
          >
            <StandardText
              style={[
                headerFontSize,
                BrandStyles.TextColor5,
                CommonStyles.customFont,
              ]}
            >
              {this.state.messageHeader}
            </StandardText>
          </View>
        </View>
        <View
          style={[
            CommonStyles.paddingTop5,
            CommonStyles.scrollView_subContentCenter,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFont,
              BrandStyles.TextColor5,
              messageFontSize,
              CommonStyles.paddingBottom5p,
            ]}
          >
            {this.state.messageBody}
          </StandardText>
        </View>
      </View>
    );
  }
  /**
   * When user rejects the invoice mark the notification as actioned
   */
  _notificationActioned = () => {
    const notificationData =
      this.props.navigationParams.params.notificationData;

    const notification = {
      id: notificationData.id,
      status: notificationData.status,
      isActioned: true,
    };

    const actionedCallback = () => {
      // Navigate and refresh the approval summary page after action is taken.
      this._closeApprovalsDetailsInvoiceReject(SUCCESS_REJECTION);
      this.refreshSummary();
    };

    const actionedErrorCallback = (err: any) => {
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
        loading: false,
      });
    };

    callAPIs(
      [
        updateNotifications(
          this.props.loadedMemberId.loadedMemberId,
          notification,
        ),
      ],
      actionedCallback,
      actionedErrorCallback,
    );
  };

  _checkRejectionReasonForEmailPopup = () => {
    const isReimbursementAccount =
      this.props.navigationParams.params.invoiceDetails.reimbursementAccount;
    if (this.state.email_popup && !isReimbursementAccount) {
      this.setState({
        displayRejectionEmailPopUp: true,
      });
    } else {
      this._rejectItem();
    }
  };

  // #TODO - Test this Action with Invoices & Service Agreements
  _rejectItem = (sendEmailNotification: any) => {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({loading: true, saveApiError: false});

    if (this._validateCheckBox()) {
      const {rejectReasonType, rejectReasonDetail} = this.state;

      // Get the details and create an object ready to send it to backend
      const rejectReasons = {
        type: rejectReasonType,
        details: rejectReasonDetail,
      };

      const callback = () => {
        if (
          this.props.navigationParams &&
          this.props.navigationParams.params.notificationData
        ) {
          this._notificationActioned();
        } else {
          this._closeApprovalsDetailsInvoiceReject(SUCCESS_REJECTION);
          this.refreshSummary();
        }
      };

      const errorCallback = (err: any) => {
        if (types.isWeb) {
          this.props.setLoading(false);
        }
        if (this.props.user.demoProfile) {
          this.setState({
            demoErrorPopup: true,
            messageHeader: types2.DEMO_APPROVAL_FAIL.TITLE,
            messageBody: types2.DEMO_APPROVAL_FAIL.BODY,
          });
        } else {
          this.setState({
            saveApiErrorCode: err.code,
            saveApiError: true,
            loading: false,
          });
        }
      };

      let api = putInvoiceApproval(
        this.props.loadedMemberId.loadedMemberId,
        this.selectedItem.uri,
        this.selectedItem.id,
        types2.APPROVAL.REJECT,
        rejectReasons,
        sendEmailNotification,
        this.props.user.demoProfile,
      );

      if (this.selectedItem.type === types2.APPROVAL_TYPE.SERVICE) {
        api = putAgreementApproval(
          this.props.loadedMemberId.loadedMemberId,
          this.selectedItem.id,
          types2.APPROVAL.REJECT,
          rejectReasons,
        );
      }

      // Call the API to reject the invoice
      callAPIs([api], callback, errorCallback);
    } else {
      logger.log('validation failed');
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        loading: false,
        saveApiErrorCode: types.MISSING_MANDATORY_CHECKBOX,
        saveApiError: true,
      });
    }
  };

  _closeForm = () => {
    this.props.navigation.goBack();
  };

  _closeApprovalsDetailsInvoiceReject = (value: any) => {
    if (types.isWeb) {
      this.props.navigation.navigate('ApprovalsSummary');
    } else {
      const actionToDispatch = CommonActions.reset({
        index: 1,
        routes: [
          {name: 'Budget'},
          {name: 'ApprovalsSummary'}
        ],
      });

      if (value === SUCCESS_REJECTION) {
        
        // this.props.navigation.dispatch(actionToDispatch);
        setTimeout(() => {
          this.props.navigation.dispatch(actionToDispatch);
       }, 2000);
      }
    }
  };

  _determinePopUpStyle = (isTablet: any) => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      styles.push([CommonStyles.containerInstructionPopUpWeb]);

      if (this.props.isMobile) {
        styles.push([CommonStyles.containerPopupWebMobile]);
      }
    } else if (isTablet) {
      styles.push([CommonStyles.containerInstructionPopUpTablet]);
    } else {
      styles.push(
        CommonStyles.containerInstructionPopUpMobile,
        CommonStyles.height300,
        CommonStyles.width340,
      );
    }
    return styles;
  };
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  financeAccess: state.MemberReducer.access.finance,
  loadedMemberId: state.LoadedMemberReducer,
  navigationParams: state.NavigationParamsReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApprovalsDetailsInvoiceReject);
