import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function NotificationReducer(state = {}, action: any) {
  switch (action.type) {
    case types.LOAD_NOTIFICATIONS:
      return {
        ...state,
        notificationData: action.notifications ? action.notifications : [],
      };

    case types.CLICK_NOTIFICATIONS:
      return {
        ...state,
        status: action.status ? action.status : false,
      };

    case types.NOTIFICATIONS_PAGE_NUMBER:
      return {
        ...state,
        pageNumber: action.pageNumber ? action.pageNumber : 1,
      };
    default:
      return state;
  }
}
