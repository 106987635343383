/*
 * Author: Gayan
 * Date: 28/05/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a goal insparation tile, for the add goal & goal steps screens.
 *
 */

import React, {Component} from 'react';
import {
  Text,
  View,
  StyleSheet,
  ScrollView,
  TextInput,
  Image,
  Dimensions,
} from 'react-native';

import {StandardTouchableOpacity, StandardText} from '../Atoms';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

//Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

let ScreenWidth = Dimensions.get('window').width;

class ItemTile extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    let selectedColor = this.props.isSelected
      ? BrandStyles.secondaryBgColor5
      : BrandStyles.primaryBgColor1;

    if (this.props.parentDimensions == undefined) {
      return null;
    }

    let parentWidth = this.props.parentDimensions.width;
    let height = this.props.parentDimensions.height;

    let columns = this.props.columns;
    let margin = 4;
    // Determine the tile width bases on the number of columns we want in the parent container
    let tilewidth = parentWidth / columns;
    // Take into consideration the margins when calculating the width
    tilewidth = tilewidth - margin * 2 - 1;

    return (
      <StandardTouchableOpacity
        style={[
          CommonStyles.goalInspirationTile,
          selectedColor,
          {width: tilewidth, margin: margin},
          BrandStyles.borderColor11,
        ]}
        accessibilityLabel={this.props.content.description}
        onPress={this._actionCallback}
      >
        <StandardText
          style={[
            CommonStyles.customFont,
            CommonStyles.font15,
            CommonStyles.goalInspText,
            CommonStyles.textAlignCentre,
            BrandStyles.TextColor5,
          ]}
        >
          {this.props.content.description}
        </StandardText>
      </StandardTouchableOpacity>
    );
  }

  _actionCallback = () => {
    if (this.props.actionData) {
      this.props.actionCallback(this.props.actionData);
    } else {
      this.props.actionCallback();
    }
  };
}

export default ItemTile;
