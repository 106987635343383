/*
 * Authour: Andrew Lee
 * Email: andrew.l.lee@au.ey.com
 * Date: 06/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This will be the header, and the side components so that it can be reused by all other developers instead of each developer having to add their own set of headers and left right containers.
 * @param {object} contents The contents that will be displayed within the container.
 * @param {array} modals The modals that will need to be loaded
 * @param {boolean} needsSidebar Whether the container needs to include the side bar.
 * @param {boolean} sidebarProfilePicture Whether to display the profile picture component on the side bar.
 * @param {function} sidebarContent The content that should be included in the side bar.
 */

import React, {Component} from 'react';
import {View, Image} from 'react-native';
import {CommonActions} from '@react-navigation/native';
import {AppContainer, Navigation, Body, BodyMobile} from './Webcontainerstyle';
import {MemoryRouter as Router, Switch, Redirect} from 'react-router-dom';
import * as UserActions from '../../Actions/UserActions';
import * as loadedMemberActions from '../../Actions/LoadedMemberActions';
import * as MemberActions from '../../Actions/MemberActions';
import * as GuideMeActions from '../../Actions/GuideMeActions';
import * as NotificationActions from '../../Actions/NotificationActions';
import * as CrewActions from '../../Actions/CrewActions';
import * as TabBarActions from '../../Actions/TabBarActions';
import * as AdminActions from '../../Actions/AdminActions';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as LogoutConfirmationActions from '../../Actions/LogoutActions';
import WebRoutesGenerator from '../../NativeWebRouteWrapper';
import {logUserOut} from '../../API/PreauthenticationHelper';
import {getValue, setValue, removeItem} from '../../API/WebPersistenceStore';

import {getDataLoggedIn} from '../../API/StorageHelper';
import {navigateToBooKMarkURL} from '../../Helpers/SignupNavigationHelper';

import {getUser} from '../../API/UserAPI';
import {getFeatureFlags} from '../../API/AdminAPI';
import {getCrewCategories} from '../../API/ReferenceAPI';
import {getCrewAccessLevels} from '../../API/CrewAPI';
import {getCrew} from '../../API/PersonAPI';
import {getMember} from '../../API/MemberAPI';
import {callAPIs} from '../../API/APICaller';
import {getNotifications} from '../../API/NotificationAPI';
import {isTablet} from '../../Helpers/PlatformSynchronizer';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {userLogOut, getAwsAuth} from '../../API/AuthenticationAPI';
import {
  CREATE_ACCOUNT_FROM_DEMO_MODAL,
  BUDGET_UNDER_MAINTENANCE,
} from '../../Constants/Messages';
import {
  BrandActivityIndicator,
  LogoutConfirmationModal,
  WebHeaderBar,
  RoundPhotoTile,
  FloatingActionButton,
  DemoBanner,
} from '../Molecules';
import {
  StandardText,
  StandardTouchableOpacity,
  HeaderWarningBanner,
} from '../Atoms';
import {
  BasicPopOver,
  WebSideMenu,
  DeepLinkBanner,
  BasicPopupAlert,
} from '../Organisms';
import {GuideMeModal} from '../../Screens';
// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import {
  BudgetsPayments,
  Settings,
  SpendingSupportItemDetail,
  PaymentsHistory,
  ApprovalsDetailsInvoice,
  ApprovalsDetailsInvoiceHH,
  ApprovalsDetailsServAgreement,
  ApprovalsDetailsInvoiceReject,
  PaymentDetails,
  ChangePassword,
  ApprovalsSummary,
  Plans,
  DraftClaimInvoices,
  ClaimInvoice,
  AddProvider,
  Notifications,
  ReviewInvoiceSummary,
  ServiceAgreementsList,
  ServiceAgreementsDetails,
  WebMembersScreen,
  MyProfile,
  AboutMe,
  FavouriteThings,
  HealthWellbeing,
  AboutMeAdditionalInfoForm,
  AboutMeLifeStageForm,
  AboutMeDisabilityForm,
  AboutMeMyDetailsForm,
  WhereILive,
  FavouriteThingsFutureForm,
  FavouriteThingsTodayForm,
  HealthWellbeingGoingWellForm,
  HealthWellbeingToImproveForm,
  PlanManagement,
  LearnMoreOptions,
  WhereILiveHomeForm,
  WhereILiveCrewForm,
  WhereILiveModificationsForm,
  Documents,
  DocumentAddForm,
  DocumentEditForm,
  Supports,
  AddSupportsBy,
  AddSupportsByCategory,
  SelectAddCrewSupport,
  CategorySupports,
  AddSupportDetails,
  AddSupportsByCrew,
  GoalSummary,
  AddGoals,
  GoalPreviewSummary,
  CompletedGoal,
  GoalProgress,
  GoalFiltersForm,
  ProfilePictureForm,
  UploadPlan,
  UploadPersonalDetailsPM,
  TermsAndConditionsPM,
  TermsAndConditionsFullPM,
  Crew,
  CrewForm,
  PlanMeeting,
  PlanSummaryPdfViewer,
  MeetingChecklist,
  ReferralProviders,
  AboutProvider,
  ProviderEnquiry,
} from '../../Screens/';

import {MoreImageWhite} from '../../assets/images/vector-icons';

import {isEmpty} from 'lodash';
import * as env from '../../environments';
import CustomIcon from '../../assets/images/CustomIcon';
import { datadogRum } from '@datadog/browser-rum';
import logger from 'helpers/Logger';

//this is for the staff app. When members are not selected the settings page should work.
const getRouteMapWithoutMemberSelected = () => {
  return {
    Settings: {
      component: Settings,
      path: '/Home/Settings',
    }
  }
}

// Provide navigation route map for web
const getRouteMap = (isMobile: any, user: any, member: any) => {
  return {
    MeetingChecklist: {
      component: MeetingChecklist,
      path: '/Home/MeetingChecklist',
    },
    TermsAndConditionsFullPM: {
      component: TermsAndConditionsFullPM,
      path: '/Home/TermsAndConditionsFullPM',
    },
    TermsAndConditionsPM: {
      component: TermsAndConditionsPM,
      path: '/Home/TermsAndConditionsPM',
    },
    UploadPersonalDetailsPM: {
      component: UploadPersonalDetailsPM,
      path: '/Home/UploadPersonalDetailsPM',
    },
    UploadPlan: {
      component: UploadPlan,
      path: '/Home/UploadPlan',
    },
    AddSupportsByCrew: {
      component: AddSupportsByCrew,
      path: '/Home/AddSupportsByCrew/:fromScreen?/:crewSelected?',
    },
    AddSupportDetails: {
      component: AddSupportDetails,
      path: '/Home/AddSupportDetails/:edit?/:prevScreen?',
    },
    CategorySupports: {
      component: CategorySupports,
      path: '/Home/CategorySupports/:selectedCategory?/:selectedColor?',
    },
    SelectAddCrewSupport: {
      component: SelectAddCrewSupport,
      path: '/Home/SelectAddCrewSupport',
    },
    AddSupportsByCategory: {
      component: AddSupportsByCategory,
      path: '/Home/AddSupportsByCategory',
    },
    AddSupportsBy: {
      component: AddSupportsBy,
      path: '/Home/AddSupportsBy',
    },
    Supports: {
      component: Supports,
      path: '/Home/Supports',
    },
    Goal: {
      component: GoalSummary,
      path: '/Home/Goals',
    },
    AddGoals: {
      component: AddGoals,
      path: '/Home/AddGoals/:screenMode?',
    },
    GoalPreview: {
      component: GoalPreviewSummary,
      path: '/Home/GoalPreviewSummary',
    },
    CompletedGoal: {
      component: CompletedGoal,
      path: '/Home/CompletedGoal',
    },
    GoalProgress: {
      component: GoalProgress,
      path: '/Home/GoalProgress',
    },
    GoalFiltersForm: {
      component: GoalFiltersForm,
      path: '/Home/GoalFiltersForm',
    },
    ChangePassword: {
      component: ChangePassword,
      path: '/Home/ChangePassword',
    },
    Settings: {
      component: Settings,
      path: '/Home/Settings',
    },
    PlanMeeting: {
      component: PlanMeeting,
      path: '/Home/PlanMeeting',
    },
    PlanSummaryPdfViewer: {
      component: PlanSummaryPdfViewer,
      path: '/Home/PlanSummaryPdfViewer',
    },
    MyProfile: {
      component: MyProfile,
      path: '/Home/MyProfile/:screenCameFrom?',
    },
    AboutMe: {
      component: AboutMe,
      path: '/Home/AboutMe',
    },
    FavouriteThings: {
      component: FavouriteThings,
      path: '/Home/FavouriteThings',
    },
    HealthWellbeing: {
      component: HealthWellbeing,
      path: '/Home/HealthWellbeing',
    },
    WhereILive: {
      component: WhereILive,
      path: '/Home/WhereILive',
    },
    AboutMeAdditionalInfoForm: {
      component: AboutMeAdditionalInfoForm,
      path: '/Home/AboutMeAdditionalInfoForm',
    },
    AboutMeLifeStageForm: {
      component: AboutMeLifeStageForm,
      path: '/Home/AboutMeLifeStageForm',
    },
    AboutMeDisabilityForm: {
      component: AboutMeDisabilityForm,
      path: '/Home/AboutMeDisabilityForm',
    },
    AboutMeMyDetailsForm: {
      component: AboutMeMyDetailsForm,
      path: '/Home/AboutMeMyDetailsForm',
    },
    FavouriteThingsFutureForm: {
      component: FavouriteThingsFutureForm,
      path: '/Home/FavouriteThingsFutureForm',
    },
    ProfilePictureForm: {
      component: ProfilePictureForm,
      path: '/Home/ProfilePictureForm',
    },
    FavouriteThingsTodayForm: {
      component: FavouriteThingsTodayForm,
      path: '/Home/FavouriteThingsTodayForm',
    },
    HealthWellbeingGoingWellForm: {
      component: HealthWellbeingGoingWellForm,
      path: '/Home/HealthWellbeingGoingWellForm',
    },
    HealthWellbeingToImproveForm: {
      component: HealthWellbeingToImproveForm,
      path: '/Home/HealthWellbeingToImproveForm',
    },
    PlanManagement: {
      component: PlanManagement,
      path: '/Home/PlanManagement',
    },
    LearnMoreOptions: {
      component: LearnMoreOptions,
      path: '/Home/LearnMoreOptions',
    },
    WhereILiveHomeForm: {
      component: WhereILiveHomeForm,
      path: '/Home/WhereILiveHomeForm',
    },
    WhereILiveCrewForm: {
      component: WhereILiveCrewForm,
      path: '/Home/WhereILiveCrewForm',
    },
    WhereILiveModificationsForm: {
      component: WhereILiveModificationsForm,
      path: '/Home/WhereILiveModificationsForm',
    },
    Crew: {
      component: Crew,
      path: '/Home/Crew',
    },
    CrewForm: {
      component: CrewForm,
      path: '/Home/CrewForm',
    },
    Documents: {
      component: Documents,
      path: '/Home/Documents',
    },
    DocumentEditForm: {
      component: DocumentEditForm,
      path: '/Home/DocumentEditForm',
    },
    DocumentAddForm: {
      component: DocumentAddForm,
      path: '/Home/DocumentAddForm',
    },
    BudgetsPayments: {
      component: BudgetsPayments,
      path: '/Home/BudgetsPayments/:navigateFrom?',
    },
    SpendingSupportItemDetail: {
      component: SpendingSupportItemDetail,
      path: '/Home/SpendingSupportItemDetail/:group?/:index?',
    },
    ApprovalsDetailsInvoice: {
      component:
        member && member.isHedgehog
          ? ApprovalsDetailsInvoiceHH
          : ApprovalsDetailsInvoice,
      path: '/Home/ApprovalsDetailsInvoice',
    },
    ApprovalsDetailsInvoiceReject: {
      component: ApprovalsDetailsInvoiceReject,
      path: '/Home/ApprovalsDetailsInvoiceReject',
    },
    ApprovalsDetailsServAgreement: {
      component: ApprovalsDetailsServAgreement,
      path: '/Home/ApprovalsDetailsServAgreement/:group?/:index?',
    },
    PaymentsHistory: {
      component: PaymentsHistory,
      path: '/Home/PaymentsHistory',
    },
    PaymentDetails: {
      component: PaymentDetails,
      path: '/Home/PaymentDetails',
    },
    ApprovalsSummary: {
      component: ApprovalsSummary,
      path: '/Home/ApprovalsSummary',
    },
    Plans: {
      component: Plans,
      path: '/Home/Plans',
    },
    DraftClaimInvoices: {
      component: DraftClaimInvoices,
      path: '/Home/DraftClaimInvoices',
    },
    ClaimInvoice: {
      component: ClaimInvoice,
      path: '/Home/ClaimInvoice/:isDraftEditing?/:invoiceId?/:itemTotal?',
    },
    AddProvider: {
      component: AddProvider,
      path: '/Home/AddProvider',
    },
    ReviewInvoiceSummary: {
      component: ReviewInvoiceSummary,
      path: '/Home/ReviewInvoiceSummary/:isDraftInvoice?/:invoiceId?/:saveAsDraft?/:itemTotal?',
    },
    Notifications: {
      component: Notifications,
      path: '/Home/Notifications',
    },
    ServiceAgreementsDetails: {
      component: ServiceAgreementsDetails,
      path: '/Home/ServiceAgreementsDetails',
    },
    ServiceAgreementsList: {
      component: ServiceAgreementsList,
      path: '/Home/ServiceAgreementsList',
    },
    ReferralProviders: {
      component: ReferralProviders,
      path: '/Home/ReferralProviders/:category?/:region?/:saregion?',
    },
    AboutProvider: {
      component: AboutProvider,
      path: '/Home/AboutProvider/:providerid?',
    },
    ProviderEnquiry: {
      component: ProviderEnquiry,
      path: '/Home/ProviderEnquiry/:providerid?',
    },
    Members: {
      component:
        isMobile && user.isCrew
          ? WebMembersScreen
          : user.status == 'managed'
          ? BudgetsPayments
          : MyProfile,
      path: '/Home',
    },
  };
};

const RESULT_INDEX = {
  MEMBER: 0,
  CREW_CATEGORIES: 1,
  CREW: 2,
  CREW_ACCESS: 3,
};
class WebContainer extends Component {
  constructor(props: any) {
    super(props);
    this.setLoading = this.setLoading.bind(this);
    this.logoutToCreateAccount = this.logoutToCreateAccount.bind(this);
  }

  state = {
    loggoutOut: false,
    loading: false,
    openPopoverMenu: false,
    createAccountPopup: false,
  };

  componentDidMount() {
    if (isEmpty(this.props.user)) {
      if (env.IS_STAFF_APP === types.APP_TYPE_STAFF) {
        getAwsAuth()
          .then(user => {
            this.fetchPreUserAPIs();
          })
          .catch(() => {
            userLogOut();
            this.props.navigation.navigate('Welcome');
            removeItem('cognitoId');
            removeItem('awsIdentity');
          });
      } else {
        this.fetchPreUserAPIs();
      }
    } else {
      if (this.props.user.isMember) {
        datadogRum.setUser({
          id: this.props.user?.id,
          name: `${this.props.user?.firstName} ${this.props.user?.lastName}`,
          email: this.props?.user?.email
        });
        this._loadNotifications();
        if (this.props.user.isCrew && this.props.loadedMemberId) {
          this.fetchPreUserAPIs();
          setValue(
            types2.WEB_STORE.LOADED_MEMBER_ID,
            this.props.loadedMemberId.loadedMemberId,
          );
        }
      }
      //If user data is already available set guide me content
      this.setGuideMeInfo();
      this.setBookMarkGuideMeInfo();
    }
    if (this.props.member.isDemoProfile) {
      this.props.actions.userActions.setFirstTimeSignUp(true, true);
    }

    if (getValue(types2.BOOK_MARK_PARAMS) !== null) {
      const bookMarkParams = JSON.parse(getValue(types2.BOOK_MARK_PARAMS));
      navigateToBooKMarkURL(window, bookMarkParams);
    }
  }

  // Build the container and then slot in the props that should be JSX
  render() {
     const cognitoId = getValue('awsIdentity');

    if (this.props.general.isSystemUnderMaintained) {
      return <Redirect to={types2.routePaths.UnderMaintenance.path} />;
    }
    if (this.state.loggoutOut || !cognitoId) {
      this.setState({loggoutOut: false});

      return <Redirect to="/" />;
    }
       const routeMap = getRouteMap(
      this.props.isMobile,
      this.props.user,
      this.props.member,
    );

    const routeMapWitoutMember = getRouteMapWithoutMemberSelected(
      this.props.isMobile,
      this.props.user,
      this.props.member,
    );
   
    return (
      <View
        onPress={() => {
          this.setState({openPopoverMenu: false});
        }}
      >
         <WebHeaderBar
          isMobile={this.props.isMobile}
          onLayout={(event: any) => {
            this.setDimesions(event.nativeEvent.layout);
          }}
          name={this.props.user.firstName}
          user={this.props.user}
          nav={this.props.navigation}
          member={this.props.member}
          logout={() => {
            this.props.actions.LogoutConfirmationActions.showConfirmLogout();
          }}
        />
             {this.renderDemoBanner()}
             {this.props.budget.isBudgetUnderMaintenanceStatus &&
        window.location.pathname.includes(types2.BUDGET_PAYMENTS)
          ? this.renderHeaderWarningBanner()
          : null}
        <DeepLinkBanner />
        <LogoutConfirmationModal
          navigation={this.props.navigation}
          isMobile={this.props.isMobile}
        />
        {this.renderMemberMenu()}
        <AppContainer>
          {this.renderMenuPopover()}
          {this.renderWebSideMenu()}
          {this.props.user.firstName && this.props.loadedMemberId.loadedMemberId
            ? this.renderBody(routeMap)
            : null}
          {
             env.IS_STAFF_APP === types.APP_TYPE_STAFF && !this.props.loadedMemberId.loadedMemberId ? this.renderBody(routeMapWitoutMember) : null
          }
          {this.renderBrandActivityIndicator(this.state.loading)}
        </AppContainer>
           <FloatingActionButton
          displayText={types2.GUIDE_ME}
          enableMobileOnly={true}
          isMobile={this.props.isMobile}
        />
        <GuideMeModal
          navigation={this.props.navigation}
          windowWidth={this.props.viewWidth}
          isMobile={this.props.isMobile}
        />
        <BasicPopupAlert
          visible={this.state.createAccountPopup}
          close={() => this.setState({createAccountPopup: false})}
          cancelLabel={'No, cancel'}
          saveAction={this.logoutToCreateAccount}
          cancelContainerStyle={[
            CommonStyles.popUpOkButton,
            BrandStyles.borderColor4,
            CommonStyles.buttonContainerFormAction,
          ]}
          saveLabel={'Yes'}
          headerText={CREATE_ACCOUNT_FROM_DEMO_MODAL.TITLE}
          messageText={CREATE_ACCOUNT_FROM_DEMO_MODAL.CONFIRMATION_MESSAGE}
          style={this._determinePopUpStyle()}
          isMobile={this.props.isMobile}
        />
   </View>)
  }

  renderBody = (routeMap: any) => {
    if (this.props.isMobile) {
      return <BodyMobile>{this.renderWebRouter(routeMap)}</BodyMobile>;
    }
    return <Body>{this.renderWebRouter(routeMap)}</Body>;
  };

  renderWebRouter = (routeMap: any) => {
    return (
      <Switch>
        {WebRoutesGenerator(
          {routeMap},
          this.setLoading,
          this.props.isMobile,
          this.props.viewWidth,
        )}
      </Switch>
    );
  };

  async fetchPreUserAPIs() {
    const cognitoId = getValue('awsIdentity');
    if (cognitoId) {
      let memberObj = null;
      const userObj = await this.requestUserDetails(cognitoId);
      datadogRum.setUser({
        id: userObj?.user?.id,
        name: `${userObj?.user?.firstName} ${userObj?.user?.lastName}`,
        email: userObj?.user?.email
      });
      const loadedMember = this.getLoaderMemberId(userObj);
      if (loadedMember) {
        const isEmployee = userObj.isEmployee;
        memberObj = await this.requestLoadedMemberDetails(
          loadedMember,
          isEmployee,
        );
      }
      //After retrieving member and user info set guide me info
      this.setGuideMeInfo();
      this.setBookMarkGuideMeInfo();
      if (loadedMember) {
        await this._loadNotifications(memberObj);
      }
    }
  }

  getLoaderMemberId(userObj: any) {
    let loadedMemberId;
    const storedMemberId = getValue(types2.WEB_STORE.LOADED_MEMBER_ID);
    const isUserOnlyMember = userObj.isMember && userObj.numberOfMembers == 1;

    if (!(userObj.isMember && userObj.numberOfMembers == 0)) {
      if (storedMemberId) {
        loadedMemberId = storedMemberId;
      } else if (!isUserOnlyMember && userObj.managedMemberId != undefined) {
        loadedMemberId = userObj.managedMemberId;
      }
    } else {
      loadedMemberId = userObj.user.id;
    }
    return loadedMemberId;
  }

  requestUserDetails(cognitoId: any) {
    const that = this;
    return new Promise((resolve, reject) => {
      const getUserCallbackFunction = (data: any) => {
        const userObj = data[0].content;
        const flagsObj = data[1].content;
        this.props.actions.UserActions.actionGetUser(userObj);
        this.props.actions.AdminActions.actionGetFeatureFlags(flagsObj);
        resolve(userObj);
      };
      const getUserErrorCallback = (error: any) => {
        if (error.statusCode == 400 || error.statusCode == 401) {
          that.setState({loggoutOut: true});
          removeItem('cognitoId');
          removeItem('awsIdentity');
        }
        reject(error);
      };
      callAPIs(
        [
          getUser(cognitoId, this.props.user.demoProfile, false),
          getFeatureFlags(this.props.user.demoProfile),
        ],
        getUserCallbackFunction,
        getUserErrorCallback,
      );
    });
  }

  requestLoadedMemberDetails(loadedMember: any, isEmployee: any) {
    const loadedMemberId = parseInt(loadedMember);
    return new Promise((resolve, reject) => {
      const callbackFunction = (results: any) => {
        let member = results[RESULT_INDEX.MEMBER].content;
        let crewCategoryPayload =
          results[RESULT_INDEX.CREW_CATEGORIES].content.categories;
        let personCrewsPayload = results[RESULT_INDEX.CREW].personCrews.content;
        let organisationCrewsPayload =
          results[RESULT_INDEX.CREW].organisationCrews.content;
        let crewsAccessPayload = results[RESULT_INDEX.CREW_ACCESS].content;

        const memberData = {
          member,
          loadedMemberId,
          isEmployee,
        };
        this.props.actions.MemberActions.actionGetMember(
          memberData.member,
          memberData.loadedMemberId,
          memberData.isEmployee,
          memberData.isFinancialStaff,
        );
        this.props.actions.CrewActions.actionGetCrews(
          crewCategoryPayload,
          personCrewsPayload,
          organisationCrewsPayload,
          crewsAccessPayload,
        );
        this.props.actions.loadedMemberActions.actionGetLoadedMemberId(
          loadedMemberId,
        );
        resolve(memberData);
      };
      const errorCallback = (error: any) => {
        logger.log('Received an error', error);
        reject(error);
      };

      callAPIs(
        [
          getMember(loadedMemberId, this.props.user.demoProfile),
          getCrewCategories(loadedMemberId),
          getCrew(loadedMemberId, this.props.user.demoProfile),
          getCrewAccessLevels(loadedMemberId, this.props.user.demoProfile),
        ],
        callbackFunction,
        errorCallback,
      );
    });
  }

  selectedMember = () => {
    const storedMemberId = getValue(types2.WEB_STORE.LOADED_MEMBER_ID);
    if (!(storedMemberId && isEmpty(this.props.member))) {
      return (
        <View style={CommonStyles.containerSelectedMember}>
          <RoundPhotoTile
            lastUpdate={new Date()}
            style={[CommonStyles.roundedMemberImage, CommonStyles.marginRight5]}
            containerStyle={[CommonStyles.containerRoundMemberPhoto]}
            accessible={true}
            imageObject={this.props.member.profileImage}
            apiFileType={'profile'}
            accessibilityLabel={'Member Profile Picture'}
            disabled={true}
          />
          <StandardText style={this._determineMemberTextStyle()}>
            {!isEmpty(this.props.member) && this.props.member.firstName
              ? this.props.member.firstName + ' ' + this.props.member.lastName
              : 'Select a member below'}
          </StandardText>
        </View>
      );
    }
  };

  renderWebSideMenu = () => {
    if (!this.props.isMobile) {
      return (
        <Navigation>
          {this.selectedMember()}
          <WebSideMenu
            isMobile={this.props.isMobile}
            isCrew={this.props.user.isCrew}
            user={this.props.user}
            member={this.props.member}
            navigation={this.props.navigation}
            setLoading={this.setLoading}
            notifications={this.props.notifications}
            notificationData={this.state.notificationData}
          />
        </Navigation>
      );
    }
  };

  renderDemoBanner = () => {
    if (this.props.user.demoProfile) {
      return (
        <DemoBanner
          logout={() => {
            this.props.actions.LogoutConfirmationActions.showConfirmLogout();
          }}
          createAccount={() => this.setState({createAccountPopup: true})}
        />
      );
    }
    return null;
  };

  renderHeaderWarningBanner = () => {
    return (
      <HeaderWarningBanner>
        <strong>{BUDGET_UNDER_MAINTENANCE.HEADER_STRONG}</strong>
        {BUDGET_UNDER_MAINTENANCE.BODY_NORMAL}
        <strong>{BUDGET_UNDER_MAINTENANCE.MOBILE_NUMBER}</strong>
        <br />
        {BUDGET_UNDER_MAINTENANCE.BODY_NORMAL_END}
      </HeaderWarningBanner>
    );
  };

  renderMemberMenu = () => {
    if (this.props.isMobile) {
      return (
        <View style={CommonStyles.containerMenuBar}>
          {this.selectedMember()}
          <StandardTouchableOpacity
            style={[
              CommonStyles.alignItemsCenter,
              CommonStyles.button_more_width,
              CommonStyles.alignFlexEnd,
              CommonStyles.marginTop5,
            ]}
            accessible={true}
            accessibilityLabel={types2.NAVIGATION_TEXT.MEMBERS_MENU}
            activeOpacity={0.6}
            onPress={() => {
              if (this.state.openPopoverMenu) {
                this.setState({openPopoverMenu: false});
              } else {
                this.setState({openPopoverMenu: true});
              }
            }}
          >
            <View
              style={[
                CommonStyles.alignItemsCenter,
                CommonStyles.screenWrapperHeaderContainer,
                CommonStyles.alignFlexEnd,
              ]}
            >
              <CustomIcon
                style={[
                  CommonStyles.screenWrapperHeaderIcon,
                  BrandStyles.TextColor2,
                ]}
                name={MoreImageWhite}
              />
              <StandardText
                allowFontScaling={false}
                style={[
                  CommonStyles.alignItemsCenter,
                  CommonStyles.font13,
                  BrandStyles.TextColor2,
                ]}
              >
                More
              </StandardText>
            </View>
          </StandardTouchableOpacity>
        </View>
      );
    }
  };

  _determineMemberTextStyle = () => {
    let styles = [CommonStyles.webSelectedMembersText];
    if (isEmpty(this.props.member)) {
      styles = [CommonStyles.webUnSelectedMembersText];
    }
    return styles;
  };
  componentDidUpdate() {
    if (this.props.budget.isBudgetUnderMaintenanceStatus) {
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.logout.logUserOut) {
      this.props.actions.LogoutConfirmationActions.hideConfirmLogout({
        logUserOut: false,
      });
      logUserOut().then(() => {
        this.navigateToLoginSignUpScreen();
      });
    }

    if (nextProps.notifications && nextProps.notifications.notificationData) {
      this.setState({
        notificationData: nextProps.notifications.notificationData,
      });
    }
    if (
      this.state.loadedMemberId !== undefined &&
      this.state.loadedMemberId.loadedMemberId !=
        nextProps.loadedMemberId.loadedMemberId
    ) {
      this.setState({loadedMemberId: nextProps.loadedMemberId});
      this._loadNotifications(nextProps.loadedMemberId);
    }
  }

  setBookMarkGuideMeInfo = () => {
    if (getValue(types2.SHOW_BOOK_MARK_GUIDE_ME) !== null) {
      const showBookMarkGuideMe = JSON.parse(
        getValue(types2.SHOW_BOOK_MARK_GUIDE_ME),
      );
      if (
        showBookMarkGuideMe.screen === types2.DEEP_LINK_SCREENS.MY_PROFILE &&
        showBookMarkGuideMe.showGuideMe
      ) {
        this.props.actions.TabBarActions.displayGuideMe(types2.PROFILE);
      }
    }
  };

  _determinePopUpStyle = () => {
    let styles = [
      CommonStyles.containerRoundCorners,
      CommonStyles.alignSelfCenter,
    ];
    if (types.isWeb) {
      styles.push([CommonStyles.containerInstructionPopUpWeb]);

      if (this.props.isMobile) {
        styles.push([CommonStyles.containerPopupWebMobile]);
      }
    } else if (isTablet()) {
      styles.push([
        CommonStyles.containerInstructionPopUpTablet,
        CommonStyles.minHeight300,
      ]);
    } else {
      styles.push([
        CommonStyles.containerInstructionPopUpMobile,
        CommonStyles.minHeight230,
      ]);
    }
    return styles;
  };

  setGuideMeInfo = () => {
    if (this.props.user && this.props.member) {
      if (this.props.user.isCrew) {
        if (
          this.props.user.isFirstTimeLogin &&
          !this.props.user.passwordChallenge
        ) {
          this.props.actions.GuideMeActions.showWelcomeGuide(true);
          this.props.actions.GuideMeActions.setWelcomeGuideStep(1);
          this.props.actions.TabBarActions.displayGuideMe(types2.PROFILE);
          this.props.actions.UserActions.setFirstTimeSignUp(false, false);
        } else if (
          (this.props.user.numberOfMembers === 0 ||
            (this.props.user.numberOfMembers >= 1 &&
              this.props.user.passwordChallenge)) &&
          this.props.user.isFirstTimeLogin
        ) {
          this.props.actions.GuideMeActions.showWelcomeGuide(true);
          this.props.actions.GuideMeActions.setWelcomeGuideStep(1);
          this.props.actions.TabBarActions.displayGuideMe(types2.LINKED_MEMBER);
          this.props.actions.UserActions.setFirstTimeSignUp(false, false);
        }
      } else {
        if (this.props.user.isFirstTimeLogin) {
          this.props.actions.GuideMeActions.showWelcomeGuide(true);
          this.props.actions.GuideMeActions.setWelcomeGuideStep(1);
          this.props.actions.TabBarActions.displayGuideMe(types2.PROFILE);
        }
        this.props.actions.UserActions.setFirstTimeSignUp(false, false);
        if (
          this.props.member.memberStatus ===
            types2.MEMBER_STATUS_DATA.MANAGED &&
          this.props.user.isOnboardingGuideRequired ===
            types2.ONBOARDING_GUIDE_STATUS.DISPLAY
        ) {
          this.props.actions.GuideMeActions.showWelcomeManagedGuide(true);
          this.props.actions.GuideMeActions.setWelcomeGuideStep(1);
          this.props.actions.TabBarActions.displayGuideMe(
            types2.BUDGET_PAYMENTS,
          );
        }
      }
    }
  };

  logoutToCreateAccount() {
    logUserOut().then(() => {
      if (!types.isWeb) {
        const actionToDispatch = CommonActions.reset({
          index: 0,
          routes: [
            {name : 'SignUp'},
          ],
        });
        this.props.navigation.dispatch(actionToDispatch);
      } else {
        this.props.navigation.navigate('SignUp');
      }
    });
  }

  navigateToLoginSignUpScreen() {
    getDataLoggedIn(types.PREVIOUSLY_SIGNIN).then(alreadyloggedIn => {
      this.setState({loggoutOut: true});
    });
  }

  renderMenuPopover = () => {
    if (this.props.isMobile && this.state.openPopoverMenu) {
      return (
        <BasicPopOver
          popOverStyles={CommonStyles.containerPopoverMenu}
          showOverlay={true}
          closePopover={this.closePopover}
        >
          <WebSideMenu
            isMobile={this.props.isMobile}
            isCrew={this.props.user.isCrew}
            user={this.props.user}
            member={this.props.member}
            navigation={this.props.navigation}
            closePopover={this.closePopover}
            setLoading={this.setLoading}
            notifications={this.props.notifications}
            notificationData={this.state.notificationData}
          />
        </BasicPopOver>
      );
    }
  };

  closePopover = () => {
    if (this.state.openPopoverMenu) {
      this.setState({openPopoverMenu: false});
    }
  };

  setDimesions(layout: any) {
    const {height} = layout;
    this.setState({
      menuViewHeight: height,
    });
  }
  // This function is to set loading state from child components
  // which are wrapped with WebContainer
  setLoading = (loading: any) => {
    this.setState({
      loading,
    });
  };

  renderBrandActivityIndicator = (loadingState: any) => (
    <BrandActivityIndicator enableWeb={true} loading={loadingState} />
  );

  rootComponent = () => {
    if (!this.props.user.isCrew) {
      return <BudgetsPayments navigation={this.props.navigation} />;
    }
    return (
      <WebMembersScreen
        user={this.props.user}
        navigation={this.props.navigation}
        setLoading={this.setLoading}
      />
    );
  };

  _loadNotifications(memberObj: any) {
    return new Promise((resolve, reject) => {
      let notificationUserId = this.props.user.id;

      let notificationPageNumber = this.state.notificationData
        ? this.state.notificationData.pageNumber
        : undefined;

      if (memberObj && memberObj.loadedMemberId !== undefined) {
        notificationUserId = memberObj.loadedMemberId;
      }

      if (notificationPageNumber === undefined) {
        notificationPageNumber = 1;
      }
      const downloadCallback = (data: any) => {
        this.props.actions.NotificationActions.loadNotifications(
          data[0].content,
        );
        resolve(data[0].content);
      };
      callAPIs(
        [
          getNotifications(
            notificationUserId,
            notificationPageNumber,
            types.NOTIFICATION_PAGE_SIZE,
            false,
            this.props.user.demoProfile,
          ),
        ],
        downloadCallback,
        (err: any) => {
          reject(err);
          logger.log('notifications error: ', err);
        },
      );
    });
  }
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
  logout: state.LogoutReducer,
  notifications: state.NotificationReducer,
  budget: state.BudgetReducer,
  general: state.GeneralReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    UserActions: bindActionCreators(UserActions, dispatch),
    MemberActions: bindActionCreators(MemberActions, dispatch),
    GuideMeActions: bindActionCreators(GuideMeActions, dispatch),
    loadedMemberActions: bindActionCreators(loadedMemberActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
    NotificationActions: bindActionCreators(NotificationActions, dispatch),
    CrewActions: bindActionCreators(CrewActions, dispatch),
    TabBarActions: bindActionCreators(TabBarActions, dispatch),
    AdminActions: bindActionCreators(AdminActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WebContainer);
