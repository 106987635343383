/*
 * Author: Anuja Ulpathakumbura
 * Date: 29/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Profile picture select/adding screen
 */

import React, {Component} from 'react';
import {View, Platform, Image, ScrollView} from 'react-native';
import * as mime from 'react-native-mime-types-enhanced';
import logger from 'helpers/Logger';

// import the connect as redux is required for member details
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// import stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import * as MemberActions from '../Actions/MemberActions';
import * as NavigationParamActions from '../Actions/NavigationParamActions';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {GalleryGray3x, CameraGrey3x, addPhotoPurple} from '../assets/images';
import {StandardText, StandardButton} from '../Components/Atoms';

import {
  SingltonImagePicker,
  FilePickerSingleton,
} from '../Helpers/PlatformSynchronizer';
// import * as ImagePicker from 'react-native-image-picker';
import {photoUploadAllowedCheck} from '../Helpers/FileHelper';
import {getValue, setValue, removeItem} from '../API/WebPersistenceStore';

import {
  uploadImageToS3,
  fetchImageFromUrl,
  uploadImageToS3WithoutResize,
} from '../Helpers/ImageHelper';
import FileUploader from '../Helpers/FileUploader';
// import the upload URL API
import {getUploadURL} from '../API/MemberAPI';
import {callAPIs} from '../API/APICaller';
import * as ImageCache from '../API/ImageCache';

import * as AvatarListActions from '../Actions/AvatarListActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {
  BasicForm,
  AvatarGrid,
  DocumentRestrictedPopUp,
} from '../Components/Organisms';
import {
  Container,
  BrandActivityIndicator,
  CaptionImage,
} from '../Components/Molecules';
import {BrandColors} from '../Styles/Colours';
import {
  setProfilePicture,
  setGoalPicture,
  goalCaptionUpdate,
  setSupportPicture,
  supportCaptionUpdate,
  uploadCallBack,
} from '../Helpers/Utils';
import * as GoalActions from '../Actions/GoalActions';
import * as SupportsActions from '../Actions/SupportsActions';
import {setImageRedux} from '../Helpers/ImageHelper';
import {launchCamera, launchImageLibrary} from '../Helpers/ImagePicker';

class ProfilePictureForm extends Component {
  filePickerRef: any;
  constructor(props: any) {
    super(props);

    this._createMainContents.bind(this);
    this._renderSaveStyles.bind(this);
    this._closeForm.bind(this);
  }

  state = {
    imagePath: addPhotoPurple,
    profileImage: null,
    lastUpdate: new Date(),
    loading: false,
    saveApiError: false,
    imageSetResponse: null,
    scrollEnabled: true,
    photoCaption: this._initialPhotoCaption(),
    documentRestrictPoupVisible: false,
  };

  UNSAFE_componentWillMount() {
    let navigationParams;
    if (
      types.isWeb &&
      getValue(types2.WEB_STORE.NAVIGATION_PARAMS) &&
      this.props.navigationParams.params === undefined
    ) {
      navigationParams = JSON.parse(
        getValue(types2.WEB_STORE.NAVIGATION_PARAMS),
      ).params.imageObject;
      let imageItem = JSON.parse(getValue(types2.WEB_STORE.GOALS));
      if (imageItem) {
        if (Array.isArray(imageItem)) {
          this.props.actions.GoalActions.actionGetGoals(
            JSON.parse(getValue(types2.WEB_STORE.GOALS)),
          );
        } else {
          this.props.actions.GoalActions.editExistingGoal(
            JSON.parse(getValue(types2.WEB_STORE.GOALS)),
          );
        }
      }
      if (getValue(types2.WEB_STORE.SUPPORTS)) {
        this.props.actions.SupportsActions.actionGetSupports(
          JSON.parse(getValue(types2.WEB_STORE.SUPPORTS)),
        );
      }
    } else {
      navigationParams = this.props.navigationParams.params.imageObject;
    }
    this._fetchImageObjects(navigationParams);
  }

  _getParams(key: any) {
    if (
      types.isWeb &&
      getValue(types2.WEB_STORE.NAVIGATION_PARAMS) &&
      this.props.navigationParams.params === undefined
    ) {
      return JSON.parse(getValue(types2.WEB_STORE.NAVIGATION_PARAMS)).params[
        key
      ];
    } else {
      return this.props.navigationParams.params[key];
    }
  }
  _initialPhotoCaption() {
    let imageObject;
    if (
      types.isWeb &&
      getValue(types2.WEB_STORE.NAVIGATION_PARAMS) &&
      this.props.navigationParams.params === undefined
    ) {
      imageObject = JSON.parse(getValue(types2.WEB_STORE.NAVIGATION_PARAMS))
        .params.imageObject;
    } else {
      imageObject = this.props.navigationParams.params.imageObject;
    }
    const photoType = this._getParams('photoType');
    const supportItem = this._getParams('supportItem');
    const goalItem = this._getParams('goalItem');
    if (imageObject) {
      return imageObject.photoCaption;
    } else {
      switch (photoType) {
        case types2.FILE_TYPE.SUPPORT_PHOTO:
          if (supportItem.photoCaption) {
            return supportItem.photoCaption;
          } else {
            return '';
          }
        case types2.FILE_TYPE.GOAL_PHOTO:
          // #TODO GOAL - When implementing goal photo caption makesure to
          // check photoCaption is sending along with navigation params 'goalItem'
          if (goalItem.photoCaption) {
            return goalItem.photoCaption;
          } else {
            return '';
          }
        default:
          return '';
      }
    }
  }

  // Set imagePath for selected avatar.
  _selectProfilePicture = (imageItem: any) => {
    let url = '';
    if (typeof imageItem === 'object') {
      url = imageItem.avatarImageUrl;
    } else {
      url = imageItem;
    }
    const filePath = url.split('?')[0];
    const fileName = filePath.substr(filePath.lastIndexOf('/') + 1);
    const caption = fileName.split('.')[0].split('-').join(' ');
    this.setState({imagePath: {uri: url}});
    this.setState({photoCaption: caption});
  };

  // Set image base64 for selected avatar.
  _getBase64Image = (imageItem: any) => {
    this.setState({
      loading: true,
    });
    let image64 = fetchImageFromUrl(imageItem).then(result => {
      this.props.actions.AvatarListActions.actionGetAvatarImage64(result);
      this.props.actions.AvatarListActions.actionGetAvatarStatus(true);
      this.setState({
        loading: false,
      });
    });
  };

  _saveFileUploadForm = () => {
    let selectedImageDetails: any = null;
    if (this.props.avatar.isAvatarImageSelected) {
      selectedImageDetails = this.state.imagePath;
      selectedImageDetails = {
        ...selectedImageDetails,
        type: 'image/png',
        fileName: 'default-avatar.png',
        originalPath: this.state.image64,
      };
    } else {
      if (this.state.imageSetResponse) {
        selectedImageDetails = this.state.imageSetResponse;
        selectedImageDetails.uri = this.state.imagePath.uri;
      }
    }
    const skipImageUpload = selectedImageDetails ? false : true;

    if (
      !selectedImageDetails &&
      this._initialPhotoCaption() === this.state.photoCaption
    ) {
      // If profile image is not updated navigate back
      this._closeForm();
      return;
    }
    if (
      !selectedImageDetails &&
      this._initialPhotoCaption() !== this.state.photoCaption
    ) {
      selectedImageDetails = {
        type: 'image/png',
        fileName: 'default-avatar.png',
        originalPath: this.state.image64,
      };
    }

    if (types.isWeb) {
      this.props.setLoading(true);
    } else {
      this.setState({
        loading: true,
      });
    }

    const source = {uri: selectedImageDetails.uri};
    let contentType = selectedImageDetails.type;
    if (this.props.avatar.isAvatarImageSelected) {
      // Set image type octet-stream when avatar image is selected.
      contentType = 'application/octet-stream';
    }
    // Build the request object that would allow for the upload
    let requestObject = this._builRequestObject(
      selectedImageDetails,
      contentType,
    );

    // If there is a crew id, add it in
    if (this.props.crewId) {
      requestObject.forCrew = this.props.crewId;
    }

    let forceUpload = false;
    if (this.props.forceMemberImageUpload === true) {
      forceUpload = true;
    }

    // Check if we have an uploadCallback, if so pass the image back to the function
    // and let the parent component handle what it wants to do with the image
    let callBack;
    if (
      types.isWeb &&
      getValue(types2.WEB_STORE.NAVIGATION_PARAMS) &&
      this.props.navigationParams.params === undefined
    ) {
      callBack = JSON.parse(getValue(types2.WEB_STORE.NAVIGATION_PARAMS)).params
        .callBack;
    } else {
      callBack = this.props.navigationParams.params.callBack;
    }
    if (callBack && !forceUpload) {
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      // add photo caption to send with the selected image to parent component
      selectedImageDetails.photoCaption = this.state.photoCaption;
      this.setState({
        imagePath: source,
        loading: false,
      });
      this.props.actions.NavigationParamActions.setParam({
        ...this.props.navigationParams.params,
        crewImage: selectedImageDetails,
      });
      if (types.isWeb) {
        uploadCallBack(selectedImageDetails);
      } else {
        let uploadCallBack = this.props.navigationParams.params.uploadCallBack;
        uploadCallBack(selectedImageDetails);
      }
      if (
        types.isWeb &&
        getValue(types2.WEB_STORE.AVATAR_LISTOBJ) &&
        getValue(types2.WEB_STORE.NAVIGATION_PARAMS)
      ) {
        removeItem(types2.WEB_STORE.AVATAR_LISTOBJ);
        removeItem(types2.WEB_STORE.NAVIGATION_PARAMS);
      }
      this.props.navigation.goBack();
      return;
    }
    // Creating the callback function when the URL is returned
    const getUploadURLCallbackFunction = (data: any) => {
      const url = data[0].content.uploadURL;
      
      if (!this.props.avatar.isAvatarImageSelected && !skipImageUpload) {

        uploadImageToS3(
          url,
          selectedImageDetails.type,
          selectedImageDetails.originalPath,
          selectedImageDetails,
        )
          .then(response => {
          
            this.setState({
              imagePath: source,
              loading: false,
            });

            if (types.isWeb) {
              this.props.setLoading(false);
              requestObject = {
                ...requestObject,
                imagePath: URL.createObjectURL(selectedImageDetails),
                photoCaption: this.state.photoCaption,
              };
            } else {
              requestObject = {
                ...requestObject,
                imagePath: source.uri,
                path: selectedImageDetails.originalPath,
                uri: selectedImageDetails.uri,
                photoCaption: this.state.photoCaption,
              };
            }

            this._setPhotoType(requestObject);
            this._closeForm();
          })
          .catch(error => {
            logger.log('The error is:', error);
          });
      } else if (!skipImageUpload) {
        
        // Update image without resize when avatar is selected.
        uploadImageToS3WithoutResize(
          url,
          'application/octet-stream',
          this.props.avatar.image64,
        )
          .then(response => {
            
            this.setState({
              imagePath: source,
              loading: false,
            });
            if (types.isWeb) {
              this.props.setLoading(false);
            }

            requestObject = {
              ...requestObject,
              imagePath: source.uri,
              path: selectedImageDetails.originalPath,
              uri: selectedImageDetails.uri,
              photoCaption: this.state.photoCaption,
            };
            this._setPhotoType(requestObject);
            this._closeForm();
          })
          .catch(error => {
            logger.log('The error is:', error);
          });
      } else {
        
        this._setCaptionUpdate(requestObject, this.state.photoCaption);
        this._closeForm();
      }
    };

    // Call the API to get the signed URL
    return callAPIs(
      [
        getUploadURL(
          this.props.loadedMemberId.loadedMemberId,
          requestObject,
          this.props.user.demoProfile,
          getUploadURLCallbackFunction,
        ),
      ],
      getUploadURLCallbackFunction,
      null,
      () => {
        logger.log('Get upload url failed for photo tile');
      },
    );
  };

  takePictureAction = (action: any) => {
    var options: any = {
      mediaType: 'photo',
    };
    const cameraOptions: any = {
      mediaType: 'photo',
      saveToPhotos: true,
    };
    if (action == types2.TAKE_PHOTO) {
      launchCamera(cameraOptions, (response: any) => {
        
        if (response.didCancel) {
          // TODO: Need to update what the cancel should do, most likely nothing
        } else if (response.error) {
          // TODO: If there is an error, most likely should warn the user. Need to update.
        } else {
          const imageUri = response.uri || response.assets?.[0]?.uri;
          
          this._selectProfilePicture(imageUri);
          if (Platform.OS === types2.IOS) {
            const extention = imageUri.split('.').pop();
            const cleanUri = decodeURI(imageUri.replace('file://', ''));
            const imageName = imageUri.split('/').pop();
            const modifiedResponse = {
              ...response.assets?.[0],
              fileName: imageName,
              path: cleanUri,
              type: mime.types[extention],
              uri: cleanUri,
              originalPath: cleanUri
            };
            this.setState({imageSetResponse: modifiedResponse});
          } else if (Platform.OS === types2.ANDROID) {
            const modifiedResponse = {
              ...response.assets?.[0],
            };
            this.setState({imageSetResponse: modifiedResponse});
          }
          this.props.actions.AvatarListActions.actionGetAvatarStatus(false);
        }
      });
    } else {
      launchImageLibrary(options, (response: any) => {
        if (response.didCancel) {
          // TODO: Need to update what the cancel should do, most likely nothing
        } else if (response.error) {
          // TODO: If there is an error, most likely should warn the user. Need to update.
        } else {
          const imageUri = response.uri || response.assets?.[0]?.uri;
          this._selectProfilePicture(imageUri);
          if (Platform.OS === types2.IOS) {
            const extention = imageUri.split('.').pop();
            const cleanUri = decodeURI(imageUri.replace('file://', ''));
            const imageName = imageUri.split('/').pop();

            const modifiedResponse = {
              ...response.assets?.[0],
              fileName: imageName,
              path: cleanUri,
              type: mime.types[extention],
              uri: cleanUri,
              originalPath: cleanUri
            };
            this.setState({imageSetResponse: modifiedResponse});
          } else if (Platform.OS === types2.ANDROID) {
            const fileName = response.assets?.[0]?.fileName;
            const extention = fileName.split('.').pop();
            const modifiedResponse = {
              ...response.assets?.[0],
              fileName: `profilePicture.${extention}`,
              type: this._getFileType(extention),
              path:response.assets?.[0]?.uri,
              uri:response.assets?.[0]?.uri,
              originalPath:response.assets?.[0]?.uri
            };
            this.setState({imageSetResponse: modifiedResponse});
          }
          this.props.actions.AvatarListActions.actionGetAvatarStatus(false);
        }
      });
    }
  };

  _setPhotoType = (requestObject: any) => {
    const photoType = this._getParams('photoType');

    switch (photoType) {
      case types2.FILE_TYPE.SUPPORT_PHOTO:
        setImageRedux(
          requestObject,
          this.props.actions.SupportsActions.setSupportImage,
          types2.FILE_TYPE.SUPPORT_PHOTO,
        );
        return setSupportPicture(requestObject, this.props);
      case types2.FILE_TYPE.GOAL_PHOTO:
        setImageRedux(
          requestObject,
          this.props.actions.GoalActions.setGoalImage,
          types2.FILE_TYPE.GOAL_PHOTO,
        );
        return setGoalPicture(requestObject, this.props);
      default:
        return setProfilePicture(requestObject, this.props);
    }
  };

  _builRequestObject = (selectedImageDetails: any, contentType: any) => {
    const photoType = this._getParams('photoType');
    const supportItem = this._getParams('supportItem');
    const goalItem = this._getParams('goalItem');

    const requestObject = {
      filename: selectedImageDetails.fileName,
      contentType: contentType,
      photoCaption: this.state.photoCaption,
    };
    switch (photoType) {
      case types2.FILE_TYPE.SUPPORT_PHOTO:
        return {
          ...requestObject,
          fileType: types2.FILE_TYPE.SUPPORT_PHOTO,
          forSupport: supportItem.currentSupportId,
        };
      case types2.FILE_TYPE.GOAL_PHOTO:
        // #TODO GOAL - Set Goal Picture - Make sure to pass the 'goalItem' along with navigation params
        // Check the SupportTile.js _navigateAddSupportImage function how it's implemented for supports
        // Similarway goals need to be implement
        return {
          ...requestObject,
          fileType: types2.FILE_TYPE.GOAL_PHOTO,
          forGoal: goalItem.currentGoalId,
        };
      default:
        return {
          ...requestObject,
          fileType: types2.FILE_TYPE.MEMBER_PROFILE,
        };
    }
  };

  _setCaptionUpdate = (requestObject: any) => {
    const photoType = this._getParams('photoType');

    switch (photoType) {
      case types2.FILE_TYPE.SUPPORT_PHOTO:
        return supportCaptionUpdate(
          requestObject,
          this.state.photoCaption,
          this.props,
        );
      case types2.FILE_TYPE.GOAL_PHOTO:
        // #TODO GOAL - Set Goal Picture - Make sure to pass the 'goalItem' along with navigation params
        // Check the SupportTile.js _navigateAddSupportImage function how it's implemented for supports
        // Similarway goals need to be implement
        return goalCaptionUpdate(
          requestObject,
          this.state.photoCaption,
          this.props,
        );
      default:
        return this.props.actions.MemberActions.actionUpdateMemberPhotoCaption(
          this.state.photoCaption,
        );
    }
  };

  // Can't detect the file type on some android devices or when file name has spaces
  _getFileType(extention: any) {
    switch (extention) {
      case 'png':
        return 'image/png';
      case 'jpg':
        return 'image/jpeg';
      case 'jpeg':
        return 'image/jpeg';
      case 'tif':
        return 'image/tiff';
      case 'tiff':
        return 'image/tiff';
      default:
        return 'image/jpeg';
    }
  }

  _fetchImageObjects = (imageObject: any) => {
    // if imageSetResponse has value set to default
    if (this.state.imageSetResponse) {
      this.setState({imageSetResponse: null});
    }

    // if isAvatarImageSelected is default true set to false
    if (this.props.avatar.isAvatarImageSelected && this._getParams('visible')) {
      this.props.actions.AvatarListActions.actionGetAvatarStatus(false);
    }

    if (imageObject) {
      if (imageObject.localUri) {
        // it's a local image so set keys that are required
        imageObject.url = imageObject.localUri;
      }
    }

    if (imageObject && imageObject.key != '') {
      if (imageObject.localImage) {
        this.setState({
          imagePath: {uri: imageObject.url},
        });
      } else {
        // if (Platform.OS === types2.IOS) {
          this.setState({
            imagePath: {uri: imageObject.url},
          });
        // } else {
        //   ImageCache.getImage(imageObject).then(imagePath => {
        //     if (this.state.imagePath && this.state.imagePath.uri != imagePath) {
        //       this.setState({
        //         imagePath: {uri: imagePath},
        //       });
        //     }
        //   });
        // }
      }
    } else {
      this.setState({
        imagePath: addPhotoPurple,
      });
    }
  };

  _renderSaveStyles = () => {
    let styles = {};
    return (styles = {
      style: [
        BrandStyles.brandBlockTxtColor5,
        CommonStyles.buttonFormAction,
        CommonStyles.customFontBold,
      ],
      containerStyle: [
        BrandStyles.primaryBgColor4,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerBudget,
      ],
    });
  };

  _closeForm = () => {
    if (
      types.isWeb &&
      getValue(types2.WEB_STORE.AVATAR_LISTOBJ) &&
      getValue(types2.WEB_STORE.NAVIGATION_PARAMS)
    ) {
      removeItem(types2.WEB_STORE.AVATAR_LISTOBJ);
      removeItem(types2.WEB_STORE.NAVIGATION_PARAMS);
    }
    this.props.navigation.goBack();
  };

  _determineAccess = () => {
    return this.props.member.access.profile;
  };

  _takePhotoContent = (buttonText: any, actionMethod: any, icon: any) => (
    // will render the profile picture select modal buttons
    <View style={[CommonStyles.alignItemsFlexEnd]}>
      <StandardButton
        readOnly={this._determineAccess()}
        onPress={() => {
          if (types.isWeb && this.filePickerRef) {
            this.filePickerRef.click();
          }
          actionMethod(buttonText);
        }}
        style={[
          BrandStyles.TextColor6,
          CommonStyles.customFontBold,
          CommonStyles.fontWeight400,
          CommonStyles.font20,
          CommonStyles.rpMarginRight,
        ]}
        containerStyle={[CommonStyles.alignedButtonContainer]}
        accessibilityLabel={buttonText}>
        {buttonText}
        <View style={[CommonStyles.sidebarInputDateButton]}>
          <Image
            source={icon}
            style={CommonStyles.addProfilePicturePickButton}
          />
        </View>
      </StandardButton>
    </View>
  );

  _photoOptions = () => {
    if (types.isWeb) {
      return (
        <View style={CommonStyles.flex07}>
          <FilePickerSingleton
            onRef={(ref: any) => {
              if (types.isWeb && ref) {
                this.filePickerRef = ref;
              }
            }}
            extensions={types.VALID_DOCUMENT_TYPES}
            maxSize={1000}
            style={{marginLeft: 10, backgroundColor: BrandColors.WHITE}}
            onChange={(event: any) => {
              if (types.isWeb) {
                event.persist();
                if (
                  event.target &&
                  event.target.files &&
                  event.target.files.length
                ) {
                  this._webFileUploadOnChange(event.target.files[0]);
                }
              }
            }}
            onError={(errMsg: any) => {}}>
            {this._takePhotoContent(
              types2.CHOOSE_FILE,
              () => {},
              GalleryGray3x,
            )}
          </FilePickerSingleton>
        </View>
      );
    }
    return (
      <View style={CommonStyles.flex07}>
        {this._takePhotoContent(
          types2.TAKE_PHOTO,
          this.takePictureAction,
          CameraGrey3x,
        )}
        {this._takePhotoContent(
          types2.CHOOSE_FROM_LIB,
          this.takePictureAction,
          GalleryGray3x,
        )}
      </View>
    );
  };

  _webFileUploadOnChange = (fileObject: any) => {
    const responseModified = fileObject;
    if (photoUploadAllowedCheck(fileObject.name)) {
      this.setState({
        documentRestrictPoupVisible: true,
      });
    } else {
      this.props.actions.AvatarListActions.actionGetAvatarStatus(false);
      responseModified.fileName = fileObject.name;
      this.setState({imageSetResponse: responseModified});
      this.setState({
        imagePath: {uri: URL.createObjectURL(responseModified)},
      });
      if (responseModified.fileName) {
        this.setState({
          photoCaption:
            responseModified.fileName.substr(
              0,
              responseModified.fileName.lastIndexOf('.'),
            ) || responseModified.fileName,
        });
      }
    }
  };

  _closeDocumentAlert = () => {
    this.setState({
      documentRestrictPoupVisible: false,
    });
  };

  _isLoading = (states: any) => {
    this.setState({
      loading: states,
    });
  };
  find_dimesions(layout: any) {
    const {width} = layout;
    this.setState({
      viewWidth: width,
    });
  }
  _createMainContents = () => (
    <BasicForm
      headerDisplayString={
        this._getParams('headerTitle')
          ? this._getParams('headerTitle')
          : types2.ADD_PROFILE_PIC_TITLE
      }
      save={this._saveFileUploadForm}
      saveStyle={this._renderSaveStyles()}
      close={this._closeForm}
      disableMore={true}
      showButton={false}
      readOnly={this.props.member.access.profile}
      buttonsPositionTop={true}
      HeaderTextColor={BrandStyles.TextColor5}
      HeaderBoarderColor={BrandStyles.borderColor5}
      contentWidthFull={true}
      buttonsPositionBottom={true}>
      <View
        style={[CommonStyles.SelectProfileContent]}
        onLayout={event => {
          this.find_dimesions(event.nativeEvent.layout);
        }}>
        <View style={[CommonStyles.profilePictureWrapper]}>
          <CaptionImage
            showInLeftSide={this.state.viewWidth < types2.MEDIA_QUERY.MEDIUM}
            value={this.state.photoCaption}
            onChangeText={(photoCaption: any) => {
              this.setState({photoCaption});
            }}>
            <View style={CommonStyles.flex03}>
              <View
                style={[
                  BrandStyles.imageBorderBackground,
                  CommonStyles.ModalProfilePictureContainer,
                  CommonStyles.marginLeft5,
                ]}>
                <View
                  style={[
                    BrandStyles.primaryBgColor3,
                    CommonStyles.ModalProfilePicture,
                  ]}>
                  <Image
                    style={[
                      CommonStyles.ModalProfilePicture,
                      CommonStyles.margin0,
                    ]}
                    source={this.state.imagePath}
                  />
                </View>
              </View>
            </View>
          </CaptionImage>
          {this._photoOptions()}
        </View>

        <View
          style={[
            CommonStyles.AvatarImagesContainer,
            CommonStyles.marginTop10,
          ]}>
          <StandardText
            style={[
              BrandStyles.TextColor6,
              CommonStyles.font15,
              CommonStyles.customFont,
            ]}>
            {types2.CHOOSE_AVATAR}
          </StandardText>
          <View style={CommonStyles.greySeperator} />
          <View>
            <ScrollView
              style={[CommonStyles.scrollViewProfilePic]}
              onTouchStart={ev => {
                this.setState({scrollEnabled: false});
              }}
              onMomentumScrollEnd={e => {
                this.setState({scrollEnabled: true});
              }}
              onScrollEndDrag={e => {
                this.setState({scrollEnabled: true});
              }}>
              <AvatarGrid
                isLoading={this._isLoading.bind(this)}
                getBase64Image={this._getBase64Image.bind(this)}
                setProfileImage={this._selectProfilePicture}
                viewWidth={this.props.viewWidth}
                isMobile={this.props.isMobile}
              />
            </ScrollView>
          </View>
        </View>
        <DocumentRestrictedPopUp
          visible={this.state.documentRestrictPoupVisible}
          close={this._closeDocumentAlert}
        />
      </View>
      <BrandActivityIndicator loading={this.state.loading} />
    </BasicForm>
  );

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    } else {
      return (
        <Container
          contents={this._createMainContents}
          needsSideBar={false}
          screenType={types.SCREEN_TYPE_MAIN}
          activeScreen={types.SCREEN_PROFILE_PICTURE}
          selectedIcon={types.SCREEN_PROFILE_PICTURE}
          nav={this.props.navigation}
          toggleMenu={this.props.screenProps?.toggleMenu}
          getInitialMenuState={this.props.screenProps?.getInitialMenuState}
          scrollEnabled={this.state.scrollEnabled}
          demoProfile={this.props.user.demoProfile}
          showConfirmLogout={
            this.props.actions.LogoutConfirmationActions.showConfirmLogout
          }
        />
      );
    }
  }
}

const mapStateToProps = (state: any) => ({
  loadedMemberId: state.LoadedMemberReducer,
  member: state.MemberReducer,
  avatar: state.AvatarReducer,
  sideMenu: state.SideMenuReducer,
  navigationParams: state.NavigationParamsReducer,
  goals: state.GoalReducer,
  supports: state.SupportsReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    AvatarListActions: bindActionCreators(AvatarListActions, dispatch),
    MemberActions: bindActionCreators(MemberActions, dispatch),
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    GoalActions: bindActionCreators(GoalActions, dispatch),
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePictureForm);
