/*
 * Author: Brad D'Costa
 * Date: 11/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Web Service class to handle the Person API calls
 */

// Custom functions for helping connect to the secure AWS API Gateway
import {
  initaliseAwsClient,
  configuredAWSLamda,
  buildLambda,
  parseResult,
  apiErrorHandler,
} from './APIHelper';
import AWS from 'aws-sdk';
import {AWS_LAMBDA_SERVICES} from '../environments';

import logger from 'helpers/Logger';

//* ********************************
//*          P E R S O N
//* ********************************

/*
 * Returns a list of crew members associated with the person
 * @async
 * @param {string} personId The person ID of the user you want to return crew for
 * @returns {Promise<array>} An array of crew member objects
 */
export const getCrew = (
  personId: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendResponse = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getCrewById = configuredAWSLamda();
      const requestBody = {
        id: personId,
        isDemoProfile,
      };
      const functionName = 'getCrewsByPersonId';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PERSON;
      const params = buildLambda(requestBody, functionName, serviceName);
      getCrewById.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendResponse;
};

/*
 * Returns a list of particpants the crew is supporting
 * @async
 * @param {string} personId The person ID of the user you want to return crew for
 * @returns {Promise<array>} An array of crew member objects
 */
export const getParticipants = (
  personId: any,
  searchQuery = null,
  pageNumber = null,
  pageSize = null,
  isDemoProfile = false,
  isManagedMembers = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getParticipantsById = configuredAWSLamda();
      const requestBody = {
        id: personId,
        searchQuery: searchQuery,
        pageNumber: pageNumber,
        pageSize: pageSize,
        isManagedMembers: isManagedMembers,
        isDemoProfile,
      };
      const functionName = 'getParticipantsByPersonId';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PERSON;
      const params = buildLambda(requestBody, functionName, serviceName);
      const startTime = Date.now();
      getParticipantsById.invoke(params, (err, data) => {
        const millis = Date.now() - startTime;

        console.log(`seconds elapsed = ${Math.floor(millis / 1000)}`);
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data, true);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};

/*
 * Returns a user with a given user ID. This will use an email to retrieve the details.
 * @async
 * @param {string} email The email that is being used to fetch the user.
 * @param {string} cognitoId The cognito id that is going to be updated for the next time.
 * @returns {Promise<object>} The user object
 */
export const putCognitoIdByEmail = (
  email: any,
  cognitoId: any,
  callback: any,
  isDemoProfile = false,
) => {
  AWS.config.credentials.get((err: any) => {
    if (err) {
      logger.log('There was an error getting credentials:', err);
    } else {
      // Use the lambda function directly from the AWS sdk
      const putCognitoIdByEmail = configuredAWSLamda();
      // FCM.getFCMToken().then(token => {
      const requestBody = {
        email,
        cognitoId,
        isDemoProfile,
      };
      const functionName = 'putCognitoIdByEmail';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PERSON;
      const params = buildLambda(requestBody, functionName, serviceName);
      putCognitoIdByEmail.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          callback(err, data);
        } else {
          const result = parseResult(data);
          callback(err, result);
        }
      });
      // });
    }
  });
};

/*
 * Returns a list of recently access members the crew is supporting
 * @param {string} personId The person ID of the user you want to return crew for
 * @returns {Promise<array>} An array of crew member objects
 */
export const getRecentMembers = (
  personId: any,
  isDemoProfile = false,
  autoHandleError = true,
) => {
  const sendRequest = () =>
    new Promise((resolve, reject) => {
      // Use the lambda function directly from the AWS sdk
      const getParticipantsById = configuredAWSLamda();
      const requestBody = {
        personId: personId,
        isDemoProfile,
      };
      const functionName = 'getRecentlyAccessedMembers';
      logger.log('Lambda::', functionName);
      const serviceName = AWS_LAMBDA_SERVICES.PERSON;
      const params = buildLambda(requestBody, functionName, serviceName);

      getParticipantsById.invoke(params, (err, data) => {
        if (err) {
          logger.log('There was an error:', err);
          reject(err);
        } else {
          const result = parseResult(data);
          const errorObject = apiErrorHandler(result.Payload, autoHandleError);
          if (errorObject) {
            reject(errorObject);
          } else {
            resolve(result.Payload);
          }
        }
      });
    });

  return sendRequest;
};
