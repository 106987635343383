/*
 * Author: Andrew Seeley
 * Date: 20/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Authentication reducer for the login flow.
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function AuthenticationReducer(state = {}, action: any) {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        type: action.logintype,
        token: action.token,
        awstoken: action.awstoken,
      };
    case types.LOGIN_FAILURE:
      return {
        loggedIn: false,
        type: action.logintype,
        token: '',
        awstoken: '',
      };
    case types.LOGOUT:
      return {
        loggedIn: false,
        type: '',
        token: '',
        awstoken: '',
      };
    default:
      return state;
  }
}
