/*
 * Author: Declan Hart
 * Date: 12/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a component to display ratio questions
 */

import React from 'react';
import {View} from 'react-native';

import {StandardText, StandardSegmentedControl} from './';

import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

const DaysOfUse = function (props: any) {
  return (
    <View style={CommonStyles.spaceAround}>
      <View style={[{marginBottom: 5}]}>
        <StandardText
          style={[
            CommonStyles.title_SupportQuestions,
            CommonStyles.customFontBold,
          ]}
        >
          {types2.SELECT_SUPPORT_DAYS}
        </StandardText>
      </View>
      <StandardSegmentedControl
        readOnly={props.readOnly}
        values={types.DAYSOFTHEWEEK}
        displayLables={types.DAYSOFWEEK_LABELS}
        selectedValues={props.selectedValues}
        multiple
        save={props.save}
        type={types.DAYSOFUSE}
        saveType={types.UNITOFMEASURE}
      />
    </View>
  );
};

export default DaysOfUse;
