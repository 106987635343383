/*
 * Author: Brad D'Costa
 * Date: 05/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the Title text for modals.
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import {StandardText} from '../Atoms';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class ModalTitle extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <View style={[CommonStyles.ModalTitleContainer]}>
        <StandardText
          style={[
            this.props.style,
            CommonStyles.ModalTitleText,
            CommonStyles.customFontBold,
            BrandStyles.TextColor5,
          ]}
        >
          {this.props.text}
        </StandardText>
      </View>
    );
  }
}

export default ModalTitle;
