/*
 * Author: Tharindu Seneviratne
 * Date: 17/01/2020
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the HH Invoice Item list as separate sections
 */

import React, {Component} from 'react';
import {View, Image} from 'react-native';

import {StandardText} from '../Atoms';
import {CurrencyText, AccordianInvoiceTile} from './';
import {
  formatDurationString,
  formatDDMMMYYDate,
  formatQuantity,
} from '../../Helpers/Utils';
import {isWeb} from '../../Constants/Constants';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import * as Images from '../../assets/images';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import {GreyClockIcon3x} from '../../assets/images';
import DeviceInfo from 'react-native-device-info';

class InvoiceList extends Component {
  constructor(props: any) {
    super(props);
    this._renderTableContents = this._renderTableContents.bind(this);
  }

  render() {
    return <View>{this._renderLineItem()}</View>;
  }

  _renderLineItem = () => {
    let dataArray = this.props.data;
    if (Array.isArray(dataArray) && dataArray.length > 0) {
      return dataArray.map((item, index) => {
        const itemContent = [
          item.itemCode,
          item.description,
          item.budgetCategory,
          this._renderQuantity(item.quantity, item.duration),
          item.unitPrice,
          item.total,
          this._renderDateRange(item.startDate, item.endDate),
          this._renderQuantity(item.claimedQuantity, item.claimedDuration),
          item.claimedUnitPrice,
          item.claimedTotal,
          item.funded,
        ];

        let addSeparator = true;

        if (index == dataArray.length - 1) {
          addSeparator = false;
        }

        return this._renderTableContents(
          itemContent,
          index,
          addSeparator,
          item.claimedTotal !== item.total,
          item.hasApprovalCheckMessages,
        );
      });
    }
  };

  _renderQuantity = (quantity: any, duration: any) => {
    if (quantity !== undefined && quantity !== null) {
      return formatQuantity(quantity);
    }
    if (duration !== undefined && duration !== null) {
      return formatDurationString(duration);
    }
    return '';
  };

  _renderDateRange = (startDate: any, endDate: any) => {
    if (endDate == undefined) {
      return formatDDMMMYYDate(startDate);
    }
    return formatDDMMMYYDate(startDate) + ' - ' + formatDDMMMYYDate(endDate);
  };

  _claimedQtyOrDuration = (itemQty: any, invoiced: any) => {
    return (
      <View
        style={[
          CommonStyles.flex1,
          CommonStyles.flexDirectionColumn,
          CommonStyles.alignCenter,
        ]}
        accessible={true}
      >
        <View
          style={[
            CommonStyles.containerList_ItemFigureTitle,
            CommonStyles.endContent,
          ]}
        >
          <StandardText
            style={[
              isWeb
                ? CommonStyles.title_InvoiceListWebMobile
                : CommonStyles.title_ListContainerSemiBold,
              CommonStyles.customFontSemiBold,
              invoiced
                ? BrandStyles[
                    types2.INVOICE_ACCORDIAN_SECTIONS[types2.ACTUAL_INVOICED]
                      .TextColor
                  ]
                : null,
            ]}
          >
            {invoiced ? invoiced : this.props.itemClaimedQty}
          </StandardText>
        </View>
        <View
          style={[
            CommonStyles.containerList_ItemFigureTitle,
            CommonStyles.endContent,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.title_ListContainer,
              CommonStyles.customFont,
              invoiced
                ? BrandStyles[
                    types2.INVOICE_ACCORDIAN_SECTIONS[types2.ACTUAL_INVOICED]
                      .TextColor
                  ]
                : null,
            ]}
          >
            {itemQty}
          </StandardText>
        </View>
      </View>
    );
  };

  _claimedUnitPrice = (itemUnitPrice: any, invoiced: any) => {
    return (
      <View
        style={[
          CommonStyles.flex1,
          CommonStyles.flexDirectionColumn,
          CommonStyles.alignCenter,
        ]}
        accessible={true}
      >
        <View style={[CommonStyles.flex1, CommonStyles.endContent]}>
          <StandardText
            style={[
              isWeb
                ? CommonStyles.title_InvoiceListWebMobile
                : CommonStyles.title_ListContainerSemiBold,
              CommonStyles.customFontSemiBold,
              CommonStyles.textAlignCentre,
              invoiced
                ? BrandStyles[
                    types2.INVOICE_ACCORDIAN_SECTIONS[types2.ACTUAL_INVOICED]
                      .TextColor
                  ]
                : null,
            ]}
          >
            {invoiced ? invoiced : this.props.itemClaimedPrice}
          </StandardText>
        </View>
        <CurrencyText
          value={itemUnitPrice}
          isPositive={true}
          containerStyle={[
            CommonStyles.flex1,
            CommonStyles.flexDirectionRow,
            CommonStyles.endContent,
          ]}
          integerStyle={[
            isWeb ? CommonStyles.font20 : CommonStyles.font30,
            CommonStyles.customFont,
            BrandStyles.TextColor5,
            invoiced
              ? BrandStyles[
                  types2.INVOICE_ACCORDIAN_SECTIONS[types2.ACTUAL_INVOICED]
                    .TextColor
                ]
              : null,
          ]}
          decimalStyle={
            invoiced
              ? BrandStyles[
                  types2.INVOICE_ACCORDIAN_SECTIONS[types2.ACTUAL_INVOICED]
                    .TextColor
                ]
              : null
          }
        />
      </View>
    );
  };

  _claimedTotal = (itemFunded: any, invoiced: any, funded: any) => {
    return (
      <View
        style={[
          CommonStyles.flex1,
          CommonStyles.flexDirectionColumn,
          CommonStyles.alignCenter,
        ]}
        accessible={true}
      >
        <View style={[CommonStyles.flex1, CommonStyles.endContent]}>
          <StandardText
            style={[
              isWeb
                ? CommonStyles.title_InvoiceListWebMobile
                : CommonStyles.title_ListContainerSemiBold,
              CommonStyles.customFontSemiBold,
              CommonStyles.textAlignCentre,
              invoiced
                ? BrandStyles[
                    types2.INVOICE_ACCORDIAN_SECTIONS[types2.ACTUAL_INVOICED]
                      .TextColor
                  ]
                : null,
            ]}
          >
            {invoiced
              ? invoiced
              : funded !== undefined && funded !== null
              ? this.props.itemFundedTotal
              : this.props.itemClaimedTotal}
          </StandardText>
        </View>
        <CurrencyText
          value={funded !== undefined && funded !== null ? funded : itemFunded}
          isPositive={true}
          containerStyle={[
            CommonStyles.flex1,
            CommonStyles.flexDirectionRow,
            CommonStyles.endContent,
          ]}
          integerStyle={[
            isWeb ? CommonStyles.font20 : CommonStyles.font30,
            CommonStyles.customFont,
            BrandStyles.TextColor5,
            invoiced
              ? BrandStyles[
                  types2.INVOICE_ACCORDIAN_SECTIONS[types2.ACTUAL_INVOICED]
                    .TextColor
                ]
              : null,
          ]}
          decimalStyle={
            invoiced
              ? BrandStyles[
                  types2.INVOICE_ACCORDIAN_SECTIONS[types2.ACTUAL_INVOICED]
                    .TextColor
                ]
              : null
          }
        />
      </View>
    );
  };

  _oneRowClaimItems = (
    itemQty: any,
    itemUnitPrice: any,
    itemTotal: any,
    itemFunded: any,
    showActual: any,
    claimedQuantity: any,
    claimedUnitPrice: any,
    claimedTotal: any,
  ) => {
    return (
      <View style={[]}>
        <View
          style={[CommonStyles.justifyFlexRowStart, CommonStyles.marginTop10]}
        >
          {this._claimedQtyOrDuration(claimedQuantity)}
          {this._claimedUnitPrice(claimedUnitPrice)}
          {this._claimedTotal(claimedTotal, undefined, itemFunded)}
        </View>

        {showActual && (
          <View>{this.renderAccordian(itemQty, itemUnitPrice, itemTotal)}</View>
        )}
      </View>
    );
  };

  renderAccordian(itemQty: any, itemUnitPrice: any, itemTotal: any) {
    return (
      <AccordianInvoiceTile
        containerStyle={[
          CommonStyles.borderTopWidth0,
          CommonStyles.justifyContentFlexStart,
          CommonStyles.paddingBottom0,
        ]}
        headerStyle={[
          CommonStyles.borderTopWidth0,
          CommonStyles.justifyContentFlexStart,
          CommonStyles.paddingBottom0,
        ]}
        title={this.props.actualInvoiced}
        expandedTitle={this.props.actualInvoicedHide}
        collapsedTitle={this.props.actualInvoiced}
        openByDefault={false}
        buttonColor={
          BrandStyles[
            types2.INVOICE_ACCORDIAN_SECTIONS[types2.ACTUAL_INVOICED].TextColor
          ]
        }
        icons={{
          expand:
            Images[
              types2.INVOICE_ACCORDIAN_SECTIONS[types2.ACTUAL_INVOICED].Expand
            ],
          collapse:
            Images[
              types2.INVOICE_ACCORDIAN_SECTIONS[types2.ACTUAL_INVOICED].Collapse
            ],
        }}
      >
        <View
          style={[
            CommonStyles.justifyFlexRowStart,
            !types.isWeb && CommonStyles.marginBottom10,
          ]}
        >
          {this._claimedQtyOrDuration(itemQty, this.props.itemInvoicedQuantity)}
          {this._claimedUnitPrice(itemUnitPrice, this.props.itemInvoicedPrice)}
          {this._claimedTotal(itemTotal, this.props.itemInvoicedTotal)}
        </View>
      </AccordianInvoiceTile>
    );
  }

  _twoByTwoColumnItems = (
    itemQty: any,
    itemUnitPrice: any,
    itemTotal: any,
    itemFunded: any,
    showActual: any,
    claimedQuantity: any,
    claimedUnitPrice: any,
    claimedTotal: any,
  ) => {
    return (
      <View
        style={[CommonStyles.justifyFlexCoulmnStart, CommonStyles.marginTop10]}
      >
        <View
          style={[
            CommonStyles.justifyFlexRowStart,
            CommonStyles.marginTop10,
            CommonStyles.marginBottom10,
          ]}
        >
          {this._claimedQtyOrDuration(claimedQuantity)}
          {this._claimedUnitPrice(claimedUnitPrice)}
        </View>
        <View
          style={[
            CommonStyles.justifyFlexRowStart,
            CommonStyles.marginTop10,
            CommonStyles.marginBottom10,
          ]}
        >
          {this._claimedTotal(claimedTotal, undefined, itemFunded)}
        </View>
        {showActual && (
          <View>
            {showActual && (
              <View>
                {this.renderAccordian(itemQty, itemUnitPrice, itemTotal)}
              </View>
            )}
          </View>
        )}
      </View>
    );
  };

  _decideTwoRows = (
    claimedQuantity: any,
    claimedUnitPrice: any,
    claimedTotal: any,
  ) => {
    let digitLength =
      claimedQuantity.toString().length +
      claimedUnitPrice.toString().length +
      claimedTotal.toString().length;

    if (!types.isWeb && !DeviceInfo.isTablet() && digitLength > 9) {
      return true;
    }
    return false;
  };

  _renderTableContents = (
    contents: any,
    key: any,
    addSeparator: any,
    showActual: any,
    hasApprovalCheckMessages: any,
  ) => {
    const [
      itemCode,
      itemDesc,
      itemCategory,
      itemQty,
      itemUnitPrice,
      itemTotal,
      dateRange,
      claimedQuantity,
      claimedUnitPrice,
      claimedTotal,
      itemFunded,
    ] = contents;

    let viewKey = key;
    if (!viewKey) {
      viewKey = 0;
    }

    return (
      <View
        key={viewKey}
        style={
          addSeparator
            ? CommonStyles.containerList_Item
            : CommonStyles.containerList_LastItem
        }
      >
        <View
          style={[CommonStyles.flexDirectionRow, CommonStyles.alignItemsStart]}
        >
          {hasApprovalCheckMessages && (
            <Image
              style={[CommonStyles.invoiceWarningIcon]}
              source={Images.InformationIconx3}
              accessible={true}
            />
          )}
          <View>
            <View
              style={[
                CommonStyles.justifyFlexRowStart,
                CommonStyles.marginBottom5,
              ]}
            >
              <View style={[CommonStyles.containerGreyTextWrapper]}>
                <StandardText
                  style={[
                    CommonStyles.title_ListContainer,
                    CommonStyles.customFont,
                  ]}
                >
                  {itemCode}
                </StandardText>
              </View>
            </View>
            <View
              style={[
                CommonStyles.justifyFlexRowStart,
                CommonStyles.marginBottom5,
              ]}
            >
              <StandardText
                style={[
                  CommonStyles.title_ListContainer,
                  CommonStyles.customFont,
                ]}
              >
                {itemDesc}
              </StandardText>
            </View>
            <View
              style={[
                CommonStyles.justifyFlexRowStart,
                CommonStyles.marginBottom5,
                CommonStyles.alignItemsCenter,
              ]}
            >
              <Image
                style={CommonStyles.containerBudgetApprovalCard_ImageDate}
                source={GreyClockIcon3x}
              />
              <StandardText
                style={[
                  CommonStyles.font15,
                  CommonStyles.customFont,
                  BrandStyles.TextColor10,
                ]}
              >
                {dateRange}
              </StandardText>
            </View>
          </View>
        </View>

        {this._decideTwoRows(claimedQuantity, claimedUnitPrice, claimedTotal)
          ? this._twoByTwoColumnItems(
              itemQty,
              itemUnitPrice,
              itemTotal,
              itemFunded,
              showActual,
              claimedQuantity,
              claimedUnitPrice,
              claimedTotal,
            )
          : this._oneRowClaimItems(
              itemQty,
              itemUnitPrice,
              itemTotal,
              itemFunded,
              showActual,
              claimedQuantity,
              claimedUnitPrice,
              claimedTotal,
            )}
      </View>
    );
  };
}

export default InvoiceList;
