import React, {Component} from 'react';
import {View, Text, Image, TouchableOpacity, StyleSheet} from 'react-native';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {LILogoWhiteText, addPhotoPurple} from '../../assets/images';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {
  APP_TERMS_OF_USE,
  APP_PRIVACY_POLICY,
} from '../../Constants/BrandConstants';

import {RoundPhotoTile} from './';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as MemberActions from '../../Actions/MemberActions';
import * as AvatarListActions from '../../Actions/AvatarListActions';

import {callAPIs} from '../../API/APICaller';
import {getAvatarImages} from '../../API/MemberAPI';

const buttonStye = [
  CommonStyles.customFont,
  CommonStyles.font16,
  BrandStyles.TextColor1,
  CommonStyles.textAlignVerticalCenter,
  CommonStyles.textAlignCentre,
  CommonStyles.marginTop14,
  CommonStyles.marginRight5,
  CommonStyles.marginLeft5,
];
const PROFILE_PICTURE_FORM = 'ProfilePictureForm';

class WebHeaderBar extends Component {
  state = {
    profilePictureModalVisible: true,
    width: window.innerWidth,
  };

  render() {
    if (!this.props.isMobile) {
      return (
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.webHeaderContainer,
            BrandStyles.ContainerBackgroundColor,
          ]}
        >
          <TouchableOpacity
            onPress={() => this.props.nav.navigate(types2.PROFILE)}
          >
            <img
              src={LILogoWhiteText}
              style={StyleSheet.flatten(CommonStyles.imageBrandLogoText)}
            />
          </TouchableOpacity>
          {this.renderLogInUser()}
          <View style={[CommonStyles.AlignCenter]} />
          {this.renderTermsOfUse()}
          {this.renderSeperator()}
          {this.renderSettings()}
          {this.renderSeperator()}
          {this.renderPrivacy()}
          {this.renderSeperator()}
          {this.renderLogout()}
        </View>
      );
    }
    return (
      <View
        style={[
          CommonStyles.webHeaderContainerMobile,
          BrandStyles.ContainerBackgroundColor,
        ]}
      >
        <View style={CommonStyles.justifyFlexRowSpace}>
          <TouchableOpacity
            onPress={() => this.props.nav.navigate(types2.PROFILE)}
          >
            <img
              src={LILogoWhiteText}
              style={StyleSheet.flatten(CommonStyles.imageBrandLogoText)}
            />
          </TouchableOpacity>
          <View style={CommonStyles.containerHeaderOptions}>
            {this.renderLogInUser()}
          </View>
        </View>
        <View style={CommonStyles.containerHeaderOptions}>
          {this.renderTermsOfUse()}
          {this.renderSeperator()}
          {this.renderSettings()}
          {this.renderSeperator()}
          {this.renderPrivacy()}
          {this.renderSeperator()}
          {this.renderLogout()}
        </View>
      </View>
    );
  }

  renderLogout() {
    return (
      <TouchableOpacity onPress={this.props.logout}>
        <Text style={buttonStye}>{'Log out'}</Text>
      </TouchableOpacity>
    );
  }

  renderTermsOfUse() {
    return (
      <TouchableOpacity onPress={() => this.redirectTermsofuser()}>
        <Text style={buttonStye}>{'Terms of Use'}</Text>
      </TouchableOpacity>
    );
  }

  redirectTermsofuser = () => {
    window.open(APP_TERMS_OF_USE);
  };

  redirectToSettings = () => {
    this.props.nav.navigate('Settings');
  };

  renderSettings() {
    return (
      <TouchableOpacity onPress={() => this.redirectToSettings()}>
        <Text style={buttonStye}>{'Settings'}</Text>
      </TouchableOpacity>
    );
  }

  renderLogInUser() {
    return (
      this.props.user.firstName && (
        <Text
          style={[
            CommonStyles.customFontBold,
            CommonStyles.StandardFontSize,
            BrandStyles.TextColor1,
            CommonStyles.containerHeaderItems,
          ]}
        >
          {this.props.user.firstName + ' logged in'}
        </Text>
      )
    );
  }
  renderPrivacy() {
    return (
      <TouchableOpacity
        onPress={() => {
          this.redirectPrivacy();
        }}
      >
        <Text style={buttonStye}>{'Privacy'}</Text>
      </TouchableOpacity>
    );
  }

  redirectPrivacy = () => {
    window.open(APP_PRIVACY_POLICY);
  };

  renderSeperator() {
    return (
      <Text
        style={[
          CommonStyles.customFontBold,
          CommonStyles.StandardFontSize,
          BrandStyles.TextColor1,
          CommonStyles.textAlignVerticalCenter,
          {marginTop: 12},
        ]}
      >
        {'|'}
      </Text>
    );
  }

  _setProfilePicture = (requestObject: any) => {
    const imageObjectState = {
      lastModified: new Date(),
      key: requestObject.imagePath,
      url: requestObject.imagePath,
      localImage: true,
      photoCaption: requestObject.photoCaption,
    };
    const saveObj = {
      id: this.props.member.id,
      profileImage: imageObjectState,
    };
    this.props.actions.MemberActions.actionUpdateMember(saveObj);
    this.setState({profileImage: imageObjectState, lastUpdate: new Date()});
  };

  _determineAccess = () => {
    if (
      this.props.member === undefined ||
      Object.getOwnPropertyNames(this.props.member).length === 0
    ) {
      return null;
    } else {
      const readOnly = this.props.member;
      if (readOnly.access) {
        if (readOnly.access.profile) {
          if (readOnly.access.profile == types.ACCESS_READ_ONLY) {
            return types.ACCESS_READ_ONLY;
          }
        }
      }
    }
    return null;
  };

  _showProfilePicture = () => {
    this.props.nav.navigate(PROFILE_PICTURE_FORM, {
      imageObject: this.props.member.profileImage,
      setProfilePicture: this._setProfilePicture.bind(this),
      determineAccess: this._determineAccess.bind(this),
      visible: this.state.profilePictureModalVisible,
      forceMemberImageUpload: true,
    });
    this._getAvatarImages();
  };

  _getAvatarImages = () => {
    const callbackFunction = (data: any) => {
      const avatarList = data[0].content;
      this.props.actions.AvatarListActions.actionGetAvatarList(avatarList);
    };
    callAPIs(
      [getAvatarImages(this.props.loadedMemberId.loadedMemberId)],
      callbackFunction,
      null,
      () => {},
    );
  };
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  notifications: state.NotificationReducer,
  user: state.UserReducer,
  sideMenu: state.SideMenuReducer,
  tabBar: state.TabBarReducer,
  loadedMemberId: state.LoadedMemberReducer,
  guideMe: state.GuideMeReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    MemberActions: bindActionCreators(MemberActions, dispatch),
    AvatarListActions: bindActionCreators(AvatarListActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WebHeaderBar);
