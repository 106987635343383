/*
Author: Nirodha Perera
Date: 26/07/2019
Copyright © 2019 Leap in!. All rights reserved.

This component use for rating.
*/
import React, {Component} from 'react';
import {
  StyleSheet,
  View,
  Platform,
  Text,
  Image,
  TouchableOpacity,
} from 'react-native';
import {EmptyStar, FilledOutStar} from '../../assets/images';
import CommonStyles from '../../Styles/CommonStyles';

class StarRating extends Component {
  state = {
    Default_Rating: 0,
    Max_Rating: 0,
  };
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    this.setState({Max_Rating: this.props.maxStars});
    this.setState({Default_Rating: this.props.rating});
  }

  UpdateRating = (key: any) => {
    if (!this.props.disabled) {
      this.props.onStarClick(key);
    }
  };
  renderStar() {
    let React_Native_Rating_Bar = [];
    //Array to hold the filled or empty Stars
    for (let i = 1; i <= this.state.Max_Rating; i++) {
      React_Native_Rating_Bar.push(
        <TouchableOpacity
          activeOpacity={0.7}
          key={i}
          onPress={() => this.UpdateRating(i)}
        >
          <View style={CommonStyles.margin05}>
            <Image
              style={[CommonStyles.screenWrapperHeaderIcon]}
              source={i <= this.props.rating ? FilledOutStar : EmptyStar}
            />
          </View>
        </TouchableOpacity>,
      );
    }
    return React_Native_Rating_Bar;
  }
  render() {
    return (
      <View style={CommonStyles.flex1}>
        <View style={[CommonStyles.documentActions, CommonStyles.centerItem]}>
          {this.renderStar()}
        </View>
      </View>
    );
  }
}

export default StarRating;
