import React from 'react';
import {View} from 'react-native';
import {StandardText} from './';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

export default function AboutProviderInfoItem(props: any) {
  return (
    <View style={props.containerStyle}>
      <StandardText
        style={[
          CommonStyles.customFontBold,
          CommonStyles.SummaryTileText,
          BrandStyles.TextColor5,
        ]}
      >
        {props.title}{' '}
      </StandardText>
      <StandardText
        style={[
          CommonStyles.customFontBoldOriginal,
          CommonStyles.SummaryTileText,
          BrandStyles.TextColor5,
          CommonStyles.marginBottom3,
        ]}
      >
        {props.content}
      </StandardText>
    </View>
  );
}
