/*
 * Author: Sujeban Elankeswaran
 * Date: 06/01/2020
 * Copyright © 2020 Leap in!. All rights reserved.
 *
 * The ReferralLstItem component is the UI component for provider expandable tiles.
 */

import React from 'react';
import {View, Image, Dimensions, Platform} from 'react-native';
import Markdown from 'react-native-easy-markdown';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {StandardButton, StandardText} from '../Atoms';
import {
  arrowCollapseCharcoal60,
  arrowExpandCharcoal60,
  MediumRedTickCircleRed3x,
  NDISBadge,
} from '../../assets/images';
import {isTablet} from '../../Helpers/PlatformSynchronizer';

class ReferralListItem extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = {
      collapse: false,
      collapseTile: true,
    };
  }

  collapseTile = (state: any) => {
    this.setState({collapseTile: state});
  };

  getDescriptionWidth = () => {
    let screenWidth = Dimensions.get('window').width;
    return (screenWidth / types2.AVERAGE_WIDTH_PER_CHARACTER) * 3;
  };

  getArrowIconStyle = () => {
    return [
      CommonStyles.referralItemAccordianButtonIcon,
      Platform.OS === types2.ANDROID
        ? CommonStyles.marginTop5
        : CommonStyles.marginTop2,
      Platform.OS === types2.IOS && CommonStyles.marginTop1,
    ];
  };

  render = () => {
    const imageArr =
      this.props.data.item.images.length !== 0 &&
      this.props.data.item.images[0].imageUrl.split('.');
    const {collapse, collapseTile} = this.state;
    return (
      <StandardButton
        containerStyle={[
          CommonStyles.referralItemView,
          (this.props.data.item.images.length === 0 ||
            imageArr[4] === types2.XML) && {height: 140},
          isTablet() === true && {height: 220},
          !collapseTile && {height: null},
        ]}
        accessibilityLabel={`Referral Button`}
        onPress={() => this.collapseTile(!this.state.collapseTile)}
      >
        <View style={CommonStyles.referralView}>
          {!collapseTile && (
            <View style={[CommonStyles.referralItemLessInfoView]}>
              <StandardButton
                style={[BrandStyles.TextColor5, CommonStyles.StandardFontSize]}
                containerStyle={[
                  CommonStyles.referralItemLessPadding10,
                  CommonStyles.endContent,
                  CommonStyles.alignCenter,
                ]}
                onPress={() => this.collapseTile(true)}
                accessibilityLabel={types2.ACCESSIBILITY_LABELS.LESS_INFO}
              >
                <StandardText>
                  {types2.REFERRAL_LIST_ITEM.LESS_INFO}
                </StandardText>
                <Image
                  style={[CommonStyles.referralItemAccordianButtonIcon]}
                  source={arrowCollapseCharcoal60}
                />
              </StandardButton>
            </View>
          )}
          <View
            style={[
              CommonStyles.referralInfoPadding,
              CommonStyles.justifyContentFlexStart,
              CommonStyles.alignFlexStart,
            ]}
          >
            {this.props.data.item.images.length === 0 ||
            imageArr[4] === types2.XML ? null : (
              <Image
                style={CommonStyles.referralItemImage}
                resizeMode={'contain'}
                source={{uri: this.props.data.item.images[0].imageUrl}}
              />
            )}
          </View>
          <StandardText
            style={[
              CommonStyles.referralTitle,
              BrandStyles.TextColor4,
              CommonStyles.rpfont20,
              CommonStyles.fontWeightBold,
              CommonStyles.width80,
              CommonStyles.flexWrap,
            ]}
            allowFontScaling={true}
          >
            {this.props.data.item.orgName}
          </StandardText>
          <Markdown
            style={{
              text: [
                CommonStyles.referralDescription,
                CommonStyles.paddingLeft10,
                CommonStyles.font17,
                BrandStyles.TextColor5,
              ],
            }}
          >
            {this.props.data.item.directoryDescriptionMarkdown
              ? this.props.data.item.directoryDescriptionMarkdown.length >
                this.getDescriptionWidth()
                ? this.props.data.item.directoryDescriptionMarkdown.substring(
                    0,
                    this.getDescriptionWidth() - 3,
                  ) + '...'
                : this.props.data.item.directoryDescriptionMarkdown
              : ''}
          </Markdown>
          <View style={[CommonStyles.referralItemMoreInfoView]}>
            {!collapseTile && (
              <React.Fragment>
                <StandardButton
                  style={[CommonStyles.referralItemMoreInfoBtn]}
                  containerStyle={CommonStyles.referralItemMorePadding10}
                  onPress={() =>
                    this.props.navigation.navigate('AboutProvider', {
                      providerid: this.props.data.item.providerRecordId,
                    })
                  }
                  accessibilityLabel={types2.ACCESSIBILITY_LABELS.MORE}
                >
                  <StandardText
                    style={[
                      CommonStyles.referralMoreInfoUnderLine,
                      CommonStyles.font14,
                      BrandStyles.TextColor10,
                      CommonStyles.fontWeightBold,
                    ]}
                  >
                    {types2.REFERRAL_LIST_ITEM.MORE}
                  </StandardText>
                </StandardButton>
              </React.Fragment>
            )}
            {collapseTile && (
              <View style={CommonStyles.flexRow}>
                <StandardText>
                  {types2.REFERRAL_LIST_ITEM.MORE_INFO}
                </StandardText>
                <Image
                  style={this.getArrowIconStyle()}
                  source={arrowExpandCharcoal60}
                />
              </View>
            )}
          </View>
          {!collapseTile && (
            <React.Fragment>
              <View style={[CommonStyles.referralInfoPadding]}>
                {this.props.data.item.email ? (
                  <View
                    style={[
                      CommonStyles.flexRow,
                      CommonStyles.flexWrap,
                      CommonStyles.alignItemsCenter,
                    ]}
                  >
                    <StandardText
                      style={[
                        CommonStyles.customFontBold,
                        CommonStyles.font17,
                        BrandStyles.TextColor5,
                      ]}
                    >
                      {types2.REFERRAL_LIST_ITEM.EMAIL + ' '}
                    </StandardText>
                    <StandardText
                      style={[CommonStyles.font17, CommonStyles.marginBottom3]}
                    >
                      {this.props.data.item.email}
                    </StandardText>
                  </View>
                ) : null}
                {this.props.data.item.phone ? (
                  <View
                    style={[
                      CommonStyles.flexRow,
                      CommonStyles.flexWrap,
                      CommonStyles.alignItemsCenter,
                    ]}
                  >
                    <StandardText
                      style={[
                        CommonStyles.customFontBold,
                        CommonStyles.font17,
                        BrandStyles.TextColor5,
                      ]}
                    >
                      {types2.REFERRAL_LIST_ITEM.CONTACT + ' '}
                    </StandardText>
                    <StandardText
                      style={[CommonStyles.font17, CommonStyles.marginBottom3]}
                    >
                      {this.props.data.item.phone}
                    </StandardText>
                  </View>
                ) : null}
                {this.props.data.item.streetAddress ||
                this.props.data.item.state ||
                this.props.data.item.postCode ? (
                  <View
                    style={[
                      CommonStyles.flexRow,
                      CommonStyles.flexWrap,
                      CommonStyles.alignItemsCenter,
                    ]}
                  >
                    <StandardText
                      style={[
                        CommonStyles.customFontBold,
                        CommonStyles.font17,
                        BrandStyles.TextColor5,
                      ]}
                    >
                      {types2.REFERRAL_LIST_ITEM.LOCATION + ' '}
                    </StandardText>
                    <StandardText
                      style={[CommonStyles.font17, CommonStyles.marginBottom3]}
                    >
                      {this.props.data.item.streetAddress +
                        ', ' +
                        this.props.data.item.state +
                        ', ' +
                        this.props.data.item.postCode}
                    </StandardText>
                  </View>
                ) : null}
              </View>

              {this.props.data.item.peopleTypeList.length !== 0 ? (
                <View
                  style={[
                    CommonStyles.referralInfoPadding,
                    CommonStyles.rpMarginTop10p,
                  ]}
                >
                  <View style={[CommonStyles.flexColumn]}>
                    <StandardText
                      style={[
                        CommonStyles.customFontBold,
                        CommonStyles.font17,
                        BrandStyles.TextColor5,
                      ]}
                    >
                      {types2.REFERRAL_LIST_ITEM.WE_SUPPORT + ' '}
                    </StandardText>
                    <StandardText style={[CommonStyles.font17]}>
                      {this.props.data.item.peopleTypeList.map(
                        (peopleType: any, index: any) =>
                          index === 0 ? peopleType : ', ' + peopleType,
                      )}
                    </StandardText>
                  </View>
                </View>
              ) : null}
              {this.props.data.item.disabilityList.length !== 0 ? (
                <View style={[CommonStyles.referralInfoPadding]}>
                  <View
                    style={[
                      CommonStyles.flexColumn,
                      CommonStyles.rpMarginTop10p,
                    ]}
                  >
                    <StandardText
                      style={[
                        CommonStyles.customFontBold,
                        CommonStyles.font17,
                        BrandStyles.TextColor5,
                      ]}
                    >
                      {types2.REFERRAL_LIST_ITEM
                        .DIAGNOSES_AND_CONDITIONS_WE_SUPPORT + ' '}
                    </StandardText>
                    <StandardText style={[CommonStyles.font17]}>
                      {this.props.data.item.disabilityList.map(
                        (disability: any, index: any) =>
                          index === 0 ? disability : ', ' + disability,
                      )}
                    </StandardText>
                  </View>
                </View>
              ) : null}
              {this.props.data.item.serviceTypeList.length !== 0 ? (
                <View style={[CommonStyles.referralInfoPadding]}>
                  <View
                    style={[
                      CommonStyles.flexColumn,
                      CommonStyles.rpMarginTop10p,
                    ]}
                  >
                    <StandardText
                      style={[
                        CommonStyles.customFontBold,
                        CommonStyles.font17,
                        BrandStyles.TextColor5,
                      ]}
                    >
                      {types2.REFERRAL_LIST_ITEM.SERVICE_TYPES + ' '}
                    </StandardText>
                    <StandardText style={[CommonStyles.font17]}>
                      {this.props.data.item.serviceTypeList.map(
                        (service: any, index: any) =>
                          index === 0 ? service : ', ' + service,
                      )}
                    </StandardText>
                  </View>
                </View>
              ) : null}
              {this.props.data.item.isNdisRegistered === 'true' ? (
                <View style={[CommonStyles.referralInfoPadding]}>
                  <View
                    style={[
                      CommonStyles.flexRow,
                      CommonStyles.justifyContentSpaceBetween,
                    ]}
                  >
                    <View
                      style={[
                        CommonStyles.flexColumn,
                        CommonStyles.rpMarginTop10p,
                      ]}
                    >
                      <StandardText
                        style={[
                          CommonStyles.customFontBold,
                          CommonStyles.font17,
                          BrandStyles.TextColor5,
                        ]}
                      >
                        {types2.REFERRAL_LIST_ITEM.REGISTERED_NDIS_PROVIDER +
                          ' '}
                      </StandardText>
                      <StandardText style={[CommonStyles.font17]}>
                        {this.props.data.item.ndisNumber}
                      </StandardText>
                    </View>
                    <View
                      style={[
                        CommonStyles.rpMarginTop10p,
                        CommonStyles.flexRow,
                      ]}
                    >
                      <Image
                        style={CommonStyles.ndisImage}
                        source={NDISBadge}
                      />
                      <Image
                        style={CommonStyles.ndisBadge}
                        source={MediumRedTickCircleRed3x}
                      />
                    </View>
                  </View>
                </View>
              ) : null}
              <View style={[CommonStyles.referralEnquireView]}>
                <StandardButton
                  style={[
                    BrandStyles.brandBlockTxtColor5,
                    CommonStyles.buttonFormAction,
                    CommonStyles.referralEnquireBtn,
                  ]}
                  containerStyle={[
                    BrandStyles.primaryBgColor4,
                    BrandStyles.brandBorderColor1,
                    CommonStyles.buttonContainerFormAction,
                    CommonStyles.rpMarginTop10p,
                  ]}
                  onPress={() => {
                    this.props.actions.ProviderActions.actionSetSelectedProvider(
                      this.props.data.item,
                    );
                    this.props.navigation.navigate('ProviderEnquiry', {
                      providerid: this.props.data.item.providerRecordId,
                    });
                  }}
                  accessibilityLabel={types2.ACCESSIBILITY_LABELS.ENQUIRY}
                >
                  {types2.REFERRAL_LIST_ITEM.ENQUIRE}
                </StandardButton>
              </View>
            </React.Fragment>
          )}
        </View>
      </StandardButton>
    );
  };
}

export default ReferralListItem;
