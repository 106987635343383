import React, {Component} from 'react';
import {View} from 'react-native';

import {LOGIN_SIGNUP_SCREEN} from '../../Constants/Messages';

import {isWeb} from '../../Constants/Constants';
import {COMPONENT_TYPES} from '../../Constants/Constants2';

import {StandardTouchableOpacity, StandardText} from '../Atoms';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

const NoAccountButton = (props: any) => {
  return (
    <View
      style={[
        CommonStyles.flexDirectionRow,
        CommonStyles.flexWrap,
        CommonStyles.alignCenter,
        CommonStyles.paddingTop20,
      ]}
      accessible={true}
      accessibilityLabel={
        LOGIN_SIGNUP_SCREEN.DONT_HAVE_ONE +
        ' ' +
        LOGIN_SIGNUP_SCREEN.HERE +
        ' ' +
        COMPONENT_TYPES.BUTTON
      }
    >
      <StandardTouchableOpacity
        onPress={() => {
          props.navigate();
        }}
      >
        <View style={[CommonStyles.flex1, CommonStyles.flexDirectionRow]}>
          <StandardText
            style={[
              CommonStyles.customFont,
              BrandStyles.TextColor5,
              isWeb ? CommonStyles.font15 : CommonStyles.rpfont12,
            ]}
          >
            {LOGIN_SIGNUP_SCREEN.DONT_HAVE_ONE}
          </StandardText>
          <StandardText
            style={[
              BrandStyles.TextColor5,
              CommonStyles.textLink,
              isWeb ? CommonStyles.font15 : CommonStyles.rpfont12,
              CommonStyles.customFontBold,
            ]}
          >
            {LOGIN_SIGNUP_SCREEN.HERE}
          </StandardText>
        </View>
      </StandardTouchableOpacity>
    </View>
  );
};

export default NoAccountButton;
