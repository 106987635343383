/*
 * Author: Declan Hart
 * Date: 09/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The add support screen, a add supports screen.
 */

import React, {Component} from 'react';
import {
  Text,
  View,
  FlatList,
  StyleSheet,
  ScrollView,
  TextInput,
  Image,
  TouchableOpacity,
} from 'react-native';

import * as messages from '../Constants/Messages';

import {
  SupportCategory,
  NoData,
  SupportCategorySelector,
} from '../Components/Organisms';
import {
  SelectSupportGroup,
  Container,
  ActionButton,
  BrandActivityIndicator,
  ItemTile,
  SelectedItem,
  AutoComplete,
} from '../Components/Molecules';

import {
  StandardText,
  StandardTile,
  StandardButton,
  StandardInput,
  OrLine,
  StandardTouchableOpacity,
} from '../Components/Atoms';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {debounce} from 'lodash';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {callAPIs} from '../API/APICaller';
import {getSupportGroupItems} from '../API/ReferenceAPI';
import * as SupportsActions from '../Actions/SupportsActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {announceForAccessibility} from '../Helpers/PlatformSynchronizer';
import logger from 'helpers/Logger';

class AddSupports extends Component {
  state = {
    selectedCategory: null,
    isLoading: false,
    supportName: '',
    tileContainerDimensions: undefined,
    OwnSupports: {},
    orgSelected: [],
    organisation: '',

    searchText: '',
    selectedSuportName:
      this.props.navigation.state.params &&
      this.props.navigation.state.params.itemselectedSuportItem
        ? this.props.navigation.state.params.itemselectedSuportItem
            .selectedSuportName
        : '',
    billerCode:
      this.props.navigation.state.params &&
      this.props.navigation.state.params.itemselectedSuportItem
        ? this.props.navigation.state.params.itemselectedSuportItem.billerCode
        : '',
    filteredSuport: {},
    selectedSuportItem: {},
    scrollEnabled: true,

    selectedGroup: [],
    selectedCategories: [],

    fromScreen: this.props.navigation.state.params.fromScreen,

    searchTitle: '',
    newSupportTitle: "Can't find? Add a new one",
    supportCategoriesTitle: '',
    mainTitle: 'Add the supports ',
    supportCrew: [],
    itemValue: [],
    addNewOne: false,
  };

  constructor(props: any) {
    super(props);
    this._renderSupportCategories = this._renderSupportCategories.bind(this);
    this._onTilesLayout = this._onTilesLayout.bind(this);
    this.getSupportItems = this.getSupportItems.bind(this);
    this.navigateToCreateSupport = this.navigateToCreateSupport.bind(this);
    this.navigateToCreateCustomSupport =
      this.navigateToCreateCustomSupport.bind(this);
    this._removeSelectedOrganisation.bind(this);
    this._renderSelectedOrganisations.bind(this);
    this._AddOrg = this._AddOrg.bind(this);
    this._showButton.bind(this);
  }

  getSupportItems(searchTerm: any, category: any) {
    this.setState({isLoading: true});
    this.setState({selectedCategory: category});
    let callback = (data: any) => {
      const groups = data[0].content;
      this.props.actions.SupportsActions.actionGetSupportGroups(groups);
      this.setState({
        isLoading: false,
        selectedGroup: [],
      });
    };
    let requestBody = {
      categoryId: category,
      searchTerm: searchTerm,
      page: 1,
      itemsPerPage: 50,
    };

    callAPIs([getSupportGroupItems(requestBody)], callback, null, () => {
      this.setState({isLoading: false});
    });
  }

  navigateToCreateCustomSupport() {
    let selectedSupports: any = [];

    if (this.state.selectedGroup.length > 0) {
      selectedSupports = [...selectedSupports, ...this.state.selectedGroup];
    }

    if (this.state.selectedCategories.length > 0) {
      this.props.actions.SupportsActions.addTempSupportCategoriesAction(
        this.state.selectedCategories,
      );
    }

    if (this.state.itemValue.length > 0) {
      selectedSupports = [...selectedSupports, ...this.state.itemValue];
    }

    if (selectedSupports.length > 0) {
      this.props.actions.SupportsActions.addTempSupportItemsAction(
        selectedSupports,
      );
    }

    this.props.actions.SupportsActions.addSupportStepAction(1);
    this.props.navigation.navigate('AddSupportDetails', {
      selectedSupports: this.state.itemValue,
    });
    this.setState({isLoading: false});
  }

  navigateToCreateSupport(group: any) {
    let selectedGroup = this.state.selectedGroup;

    if (group.isSelected) {
      let start_index = 0;
      selectedGroup.map(selectedSupportGroups => {
        if (selectedSupportGroups.groupId == group.groupId) {
          let selectedGp = selectedGroup;
          selectedGp.splice(start_index, 1);
          selectedGroup = selectedGp;
        }
        start_index = start_index + 1;
      });
      group.isSelected = false;
    } else {
      group.isSelected = true;
      selectedGroup.push(group);
    }

    let selectedCategories = this.state.selectedCategories;
    selectedCategories.push(this.state.selectedCategory);

    this.setState({
      selectedGroup: selectedGroup,
      selectedCategories: selectedCategories,
    });
  }

  componentDidMount() {
    if (this.state.fromScreen == 'category') {
      this.setState({
        searchTitle: "Can't find the support you have? Search here",
        newSupportTitle: "Still can't find? Add your own",
        supportCategoriesTitle: 'Select a category',
        mainTitle: 'What Current support do you have?',
      });
    } else {
      let selectedCrews = this.props.navigation.state.params.crewSelected;
      let crewDisplayText = '';
      for (let i = 0; i < selectedCrews.length; i++) {
        const crewMember = selectedCrews[i];
        if (crewDisplayText != '') {
          if (i == selectedCrews.length - 1) {
            crewDisplayText += ` and `;
            crewDisplayText += this.getName(crewMember);
          } else {
            crewDisplayText += `, `;
            crewDisplayText += this.getName(crewMember);
          }
        } else {
          crewDisplayText += this.getName(crewMember);
        }
      }
      this.setState({
        mainTitle: this.state.mainTitle + crewDisplayText + ' provide you',
      });
    }
  }

  getName = (crew: any) => {
    if (
      !(crew.firstName && crew.lastName) ||
      (crew.firstName == '' && crew.lastName == '')
    ) {
      return `${crew.orgName}`;
    } else {
      if (crew.orgName && crew.orgName != '') {
        return `${crew.firstName} ${crew.lastName} ${crew.orgName}`;
      } else {
        return `${crew.firstName} ${crew.lastName}`;
      }
    }
  };

  render() {
    return (
      <Container
        contents={this._createMainContents}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        headerTitle={types2.NAVIGATION_TEXT.ADD_SUPPORTS}
        nav={this.props.navigation}
        sidebarProfilePicture={true}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        scrollEnabled={this.state.scrollEnabled}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _removeSelectedOrganisation = (index: any) => {
    let orgSelected = JSON.parse(JSON.stringify(this.state.orgSelected));
    let itemValues = JSON.parse(JSON.stringify(this.state.itemValue));

    // Remove the index from the temp selected Organisations
    orgSelected.splice(index, 1);
    itemValues.splice(index, 1);

    // Update the state
    this.setState({
      orgSelected: orgSelected,
      itemValue: itemValues,
    });
  };

  _renderSelectedOrganisations = () => {
    // Go through the list of selected Organisations to render on screen
    const {orgSelected} = this.state;

    let orgSelectedJSX: any = [];

    let indexCounter = 0;
    orgSelected.map(organisation => {
      orgSelectedJSX.push(
        <SelectedItem
          key={indexCounter}
          index={indexCounter}
          organisation={organisation}
          removeOrg={this._removeSelectedOrganisation}
        />,
      );
      indexCounter++;
    });

    return (
      <View style={[CommonStyles.flexRow, CommonStyles.flexWrap]}>
        {orgSelected.length > 0 && orgSelectedJSX}
      </View>
    );
  };

  _AddOrg() {
    let selectedOrganisation = this.state.orgSelected;
    let duplicateItem = false;
    selectedOrganisation.map(org => {
      if (org.toLowerCase() == this.state.organisation.toLowerCase()) {
        duplicateItem = true;
        this.setState({
          organisation: '',
        });
      }
    });

    if (!duplicateItem && this.state.organisation != '') {
      selectedOrganisation.push(this.state.organisation);
      let itemValue = this.state.itemValue;
      itemValue.push({
        description: this.state.organisation,
      });
      this.setState({
        orgSelected: selectedOrganisation,
        organisation: '',
        itemValue: itemValue,
      });
    }
  }

  _setFilterdSupport(data: any) {
    this.setState({scrollEnabled: false, isLoadingSearch: false});
    if (data[0].content.groupItems.length > 0) {
      var filteredSuport = data[0].content.groupItems.filter(function (
        item: any,
        index: any,
        array: any,
      ) {
        return item.groupId;
      });
      if (this.state.searchText != '' && this.state.searchText != undefined) {
        this.setState({
          filteredSuport: filteredSuport,
          isLoadingSearch: false,
        });
      }
    }
  }

  _filteredSupportFailed(err: any) {
    logger.log('notifications error: ', err);
    this.setState({isLoadingSearch: false});
  }

  _fetchSearchData(requestBody: any) {
    callAPIs(
      [getSupportGroupItems(requestBody, false)],
      (data: any) => this._setFilterdSupport(data),
      (err: any) => this._filteredSupportFailed(err),
    );
    this.setState({isLoadingSearch: true});
  }

  debouncedSearchFilteredSupportFunc = debounce(
    requestBody => this._fetchSearchData(requestBody),
    1000,
  );

  debouncedSearchFilteredSupport(query: any) {
    this.setState({
      searchText: query,
      selectedSuportName: query,
      organisation: query,
      billerCode: '',
    });

    let requestBody = {
      searchTerm: query,
      page: 1,
      itemsPerPage: 50,
    };

    this.debouncedSearchFilteredSupportFunc(requestBody);
  }

  _renderSearchItem = (item: any) => {
    return (
      <StandardTouchableOpacity
        accessibilityLabel={item.type + ' selected'}
        key={item.description}
        style={[CommonStyles.listItem]}
        onPress={() => {
          this._setSuportItem(item);
        }}
      >
        <StandardText
          style={[CommonStyles.StandardFontSize, CommonStyles.customFont]}
        >
          <StandardText style={[CommonStyles.customFont]}>
            {item.description}
          </StandardText>
          {/* <StandardText style={[CommonStyles.customFontLight]}>{'  (' +item.SnowReference + ')'}</StandardText> */}
        </StandardText>
      </StandardTouchableOpacity>
    );
  };

  _setSuportItem = (item: any) => {
    this.setState({
      filteredSuport: [],
      searchText: item.description,
      selectedSuportName: item.description,
      selectedSuportItem: item,
      billerCode: item.Id,
      scrollEnabled: true,
    });

    this.setState(prevState => ({
      supportItems: {
        ...prevState.supportItems,
        billerKey: item.Id,
        billerCode: item.Id + '_bc',
        selectedSuportName: item.description,
        selectedSuportItem: item,
      },
    }));

    let selectedOrganisation = this.state.orgSelected;
    let duplicateItem = false;
    selectedOrganisation.map(org => {
      if (org.includes(item.description)) {
        duplicateItem = true;
        this.setState({
          organisation: '',
        });
      }
    });

    if (!duplicateItem) {
      selectedOrganisation.push(item.description);
      let itemValue = this.state.itemValue;
      itemValue.push(item);

      let selectedCategories = this.state.selectedCategories;
      selectedCategories.push(item.categoryId);

      this.setState({
        orgSelected: selectedOrganisation,
        organisation: '',
        itemValue: itemValue,
        selectedCategories: selectedCategories,
      });
    }

    this.setState({
      searchText: '',
      selectedSuportName: '',
      billerCode: '',
      selectedSuportItem: {},
      filteredSuport: {},
    });
  };

  _renderSupportCategories() {
    if (this.state.fromScreen == 'category') {
      if (this.props.categories == undefined) {
        return (
          <NoData
            activity={'Categories'}
            additionalInformation={messages.MESSAGES.NODATA.CATEGORIES}
          />
        );
      } else {
        if (Object.getOwnPropertyNames(this.props.categories).length != 0) {
          return this.props.categories.map((category: any) => (
            <SupportCategorySelector
              key={category.categoryId}
              Category={category}
              selected={this.state.selectedCategory}
              onPress={this.getSupportItems}
            />
          ));
        } else {
          return (
            <NoData
              activity={'Categories'}
              additionalInformation={messages.MESSAGES.NODATA.CATEGORIES}
            />
          );
        }
      }
    } else {
      return null;
    }
  }

  _showSupportItemsTitle() {
    if (
      this.props.supportGroups &&
      Object.getOwnPropertyNames(this.props.supportGroups).length != 0 &&
      this.props.supportGroups.length > 0 &&
      this.state.selectedCategory != null
    ) {
      return (
        <View
          style={[CommonStyles.AddSupportsFlex1, CommonStyles.AddSupportsMT15]}
        >
          <StandardText
            style={[
              CommonStyles.customFontSemiBold,
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
              CommonStyles.AddSupportsMarginBottom,
            ]}
          >
            {'Select relevant supports'}
          </StandardText>
        </View>
      );
    } else {
      // return (<NoData activity={'Support groups'} additionalInformation={messages.MESSAGES.NODATA.CATEGORIES}/>)
      return null;
    }
  }

  _renderSupportItems() {
    if (
      this.props.supportGroups &&
      Object.getOwnPropertyNames(this.props.supportGroups).length != 0 &&
      this.props.supportGroups.length > 0 &&
      this.state.selectedCategory != null
    ) {
      return this.props.supportGroups.map((group: any) => {
        if (group.unitOfMeasure.description != types.HOUR) {
        }
        return (
          <ItemTile
            key={group.groupId}
            content={group}
            isSelected={group.isSelected}
            actionCallback={this.navigateToCreateSupport}
            actionData={group}
            columns={3}
            parentDimensions={this.state.tileContainerDimensions}
          />
        );
      });
    } else {
      // return (<NoData activity={'Support groups'} additionalInformation={messages.MESSAGES.NODATA.CATEGORIES}/>)
      return null;
    }
  }

  _AddSupport() {
    this.props.actions.SupportsActions.addOwnSupportItemsAction(
      this.state.OwnSupports,
    );
  }

  _renderSearchTitle() {
    if (this.state.fromScreen == 'category') {
      return (
        <View style={[CommonStyles.AddSupportsFlex1]}>
          <StandardText
            style={[
              CommonStyles.customFontSemiBold,
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
              CommonStyles.AddSupportsMarginBottom,
            ]}
          >
            {this.state.searchTitle}
          </StandardText>
        </View>
      );
    } else {
      return null;
    }
  }

  _addNewOrg() {
    if (this.state.addNewOne) {
      this.setState({addNewOne: false});
    } else {
      this.setState({addNewOne: true});
    }
  }

  _showButton() {
    return (
      <View
        style={[
          CommonStyles.AddSupportsFlex1,
          CommonStyles.AddSupportsMT15,
          CommonStyles.addSupportsMB,
        ]}
      >
        {this._renderSearchTitle()}
        <View
          style={[
            CommonStyles.inputContainerFullWidth,
            CommonStyles.PlatformPaddingBottom10,
            CommonStyles.addSupportsMR10,
            CommonStyles.addSupportsML2,
            CommonStyles.zIndex1,
          ]}
        >
          <AutoComplete
            readOnly={false}
            data={this.state.filteredSuport}
            listStyle={[CommonStyles.content, CommonStyles.listContainer]}
            onChangeText={(text: any) =>
              this.debouncedSearchFilteredSupport(text)
            }
            placeholder={'Type your current support'}
            renderItem={(item: any) => {
              return this._renderSearchItem(item);
            }}
            style={[
              CommonStyles.ModalTextInputWrapper,
              CommonStyles.StandardFontSize,
              CommonStyles.paddingLeft20,
            ]}
            value={
              this.state.billerCode
                ? this.state.selectedSuportName +
                  ' (' +
                  this.state.billerCode +
                  ')'
                : this.state.selectedSuportName
            }
            underlineColorAndroid="transparent"
            isLoadingSearch={this.state.isLoadingSearch}
            accessibilityLabelClear={'Clear Supports'}
            canAddCustom={true}
            addCustom={this._AddOrg}
            onClear={() => {
              // Clear the search text
              this.setState({
                searchText: '',
                selectedSuportName: '',
                billerCode: '',
                selectedSuportItem: {},
                filteredSuport: {},
                scrollEnabled: true,
              });
            }}
            onFocus={() => {
              // Since the focus is on the auto complete, any drag should dismiss the keyboard
              this.setState({keyboardDismissMode: 'on-drag'});
              if (
                this.state.searchText == '' ||
                this.state.searchText == undefined
              ) {
              }
            }}
            onBlur={() => {
              // No longer focused on the auto complete, remove the dismiss mode
              this.setState({keyboardDismissMode: 'none'});
            }}
            // errorMessage={this.state.validSupportName}
          />
        </View>
        <View
          style={[CommonStyles.flexDirectionRow, CommonStyles.AddSupportsMT15]}
        >
          {this._renderSelectedOrganisations()}
        </View>
        <View
          style={[CommonStyles.AddSupportsFlex1, CommonStyles.AddSupportsAIFE]}
        >
          {this._showNextButton()}
        </View>
      </View>
    );
  }

  _showNextButton = () => {
    if (
      this.state.selectedGroup.length > 0 ||
      this.state.itemValue.length > 0
    ) {
      announceForAccessibility(messages.NEXT_BUTTON.NEXT_BUTTON_MESSAGE);

      return (
        <StandardButton
          containerStyle={[
            CommonStyles.goalsNextButtonContainer,
            CommonStyles.AddSupportsNextBtnW,
            CommonStyles.AddSupportsMB15,
          ]}
          style={[
            BrandStyles.primaryBgColor3,
            BrandStyles.TextColor1,
            CommonStyles.customFontSemiBold,
            CommonStyles.goalsNextButton,
          ]}
          onPress={() => this.navigateToCreateCustomSupport()}
        >
          Next
        </StandardButton>
      );
    } else {
      return null;
    }
  };

  _onTilesLayout = (event: any) => {
    if (this.state.tileContainerDimensions) return;
    let {width, height} = event.nativeEvent.layout;
    this.setState({tileContainerDimensions: {width, height}});
  };

  _renderSupportCategoriesTitle() {
    if (this.state.fromScreen == 'category') {
      return (
        <StandardText
          style={[
            CommonStyles.customFontMedium,
            CommonStyles.font15,
            BrandStyles.TextColor6,
            CommonStyles.AddSupportsMarginBottom,
          ]}
        >
          {this.state.supportCategoriesTitle}
        </StandardText>
      );
    } else {
      return null;
    }
  }

  _createMainContents = () => (
    <View style={[CommonStyles.AddSupportsFlex1]}>
      <StandardText
        style={[
          CommonStyles.customFontSemiBold,
          CommonStyles.SummaryTileText,
          BrandStyles.TextColor5,
          CommonStyles.AddSupportsMarginBottom,
        ]}
      >
        {this.state.mainTitle}
      </StandardText>
      {this._renderSupportCategoriesTitle()}
      <View style={CommonStyles.wrapTileContainer}>
        {this._renderSupportCategories()}
      </View>
      <ScrollView style={[CommonStyles.AddSupportsFlex1]}>
        <View
          onLayout={this._onTilesLayout}
          style={[
            CommonStyles.alignFlexStart,
            CommonStyles.justifyContentFlexStart,
            CommonStyles.contentFlexWrap,
          ]}
        >
          {this._showSupportItemsTitle()}
        </View>
        <View
          onLayout={this._onTilesLayout}
          style={[
            CommonStyles.alignFlexStart,
            CommonStyles.justifyContentFlexStart,
            CommonStyles.contentFlexWrap,
          ]}
        >
          {this._renderSupportItems()}
        </View>
        <View
          style={[
            CommonStyles.alignFlexStart,
            CommonStyles.justifyContentFlexStart,
            CommonStyles.contentFlexWrap,
          ]}
        >
          {this._showButton()}
        </View>
      </ScrollView>
      <BrandActivityIndicator loading={this.state.isLoading} />
    </View>
  );
}

const mapStateToProps = (state: any) => ({
  categories: state.SupportCategoriesReducer.categories,
  supportGroups: state.SupportItemGroupsReducer.supportGroups,
  member: state.MemberReducer,
  supports: state.SupportsReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSupports);
