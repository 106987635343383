/*
 * Author: Declan Hart
 * Date: 16/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Display Support details
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import {StandardText, StandardInput} from './';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

class HourPerVisit extends Component {
  constructor(props: any) {
    super(props);
    this._handleSave = this._handleSave.bind(this);
  }

  _handleSave(itemValue: any) {
    this.props.save(types.HOURS_PER_VIST, itemValue, null, null, null);
  }

  _getHoursPerVisit() {
    if (this.props.selectedValues) {
      return this.props.selectedValues
        .map((hours: any) => hours.value)
        .toString();
    }
    return '';
  }

  render() {
    return (
      <View style={[CommonStyles.spaceAround, CommonStyles.flex1]}>
        <View style={[CommonStyles.marginBottom5]}>
          <StandardText
            style={[
              CommonStyles.title_SupportQuestions,
              CommonStyles.customFontBold,
            ]}
          >
            {types2.HOURS_PER_VIST_QUESTION}
          </StandardText>
        </View>
        <View style={CommonStyles.costInputWrapper}>
          <StandardInput
            readOnly={this.props.readOnly}
            keyboardType="numeric"
            accessibilityLabel="Total hours per visit"
            key="hours_visit"
            value={this._getHoursPerVisit()}
            onChangeText={(value: any) => this._handleSave(value)}
            border={BrandStyles.borderColor4}
            blurOnSubmit
            applyWrapper={false}
            underlineColorAndroid="transparent"
            style={[
              CommonStyles.costInputField,
              CommonStyles.textInputWrapper_Support,
            ]}
          />
        </View>
      </View>
    );
  }
}

export default HourPerVisit;
