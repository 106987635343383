/*
 * Author: Declan Hart
 * Date: 15/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a Tile component with a standard styling applied.
 */

import React, {Component} from 'react';
import {View, Alert} from 'react-native';

import {CancelSaveButtons} from './';
import {StandardButton} from '../Atoms';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import logger from 'helpers/Logger';

class SupportsDeleteCancelSaveButtons extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <View style={CommonStyles.screenFooter}>
        <View style={CommonStyles.ModalFooterRight}>
          <CancelSaveButtons
            showCancel
            style={[CommonStyles.alignFlexEnd, {flex: 1}]}
            cancelCallback={this.props.cancel}
            saveCallback={this.props.save}
            readOnly={this.props.readOnly}
            saveDisabled={this.props.saveDisabled}
            saveButton={this.props.saveButton}
          />
        </View>
      </View>
    );
  }
}

export default SupportsDeleteCancelSaveButtons;
