/*
 * Author: Declan Hart
 * Date: 16/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Display Support details
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import {Cost, TimesPerYear} from '../Atoms';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class UnitOfMeasureEach extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <View style={[CommonStyles.flexRow]}>
        <Cost
          year
          save={this.props.save}
          selectedValues={this.props.values.cost}
          readOnly={this.props.readOnly}
        />
      </View>
    );
  }
}

export default UnitOfMeasureEach;
