/*
 * Author: Andrew Seeley
 * Date: 24/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Summary tile on the About Me screen for the Lifestages Section
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View, Text} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {LIFE_STAGE as messages} from '../../Constants/Messages';

import {StandardText} from '../Atoms';
import {SummaryTile} from '../Molecules';

import {
  LifeStagePurple3x,
  LifestageCharcoal,
  LifestageRed,
} from '../../assets/images/vector-icons';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class AboutMeLifeStage extends Component {
  constructor(props: any) {
    super(props);
    this._renderLifeStages.bind(this);
  }

  render() {
    return (
      <SummaryTile
        headerDisplayString={messages.TITLE}
        headerIconImage={LifeStagePurple3x}
        modal={this.props.form}
        containsData={this._determineContainsData()}
        HeaderTextColor={BrandStyles.TextColor4}
        HeaderBoarderColor={BrandStyles.borderColor4}
        actionOverride={this._determineAccess()}
      >
        {this._renderMyDetail()}
      </SummaryTile>
    );
  }

  _determineAccess = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      if (this.props.reducer.access) {
        if (this.props.reducer.access.profile) {
          if (this.props.reducer.access.profile == types.ACCESS_READ_ONLY) {
            return types.ACTION_BUTTON.VIEW;
          }
        }
      }
    }
    return null;
  };

  _determineContainsData = () => {
    if (this.props.lifeStages == null) {
      return false;
    }

    if (Object.getOwnPropertyNames(this.props.lifeStages).length === 0) {
      return false;
    } else {
      var checkArray = [this.props.lifeStages];
      var dataCheck = false;
      for (var i = 0; i < checkArray.length; i++) {
        if (checkArray[i]) {
          if (Array.isArray(checkArray[i])) {
            if (checkArray[i].length > 0) {
              dataCheck = true;
              break;
            }
          } else {
            dataCheck = true;
            break;
          }
        }
      }
      return dataCheck;
    }
  };

  _determineIcon = () => {
    if (this._determineContainsData()) {
      return LifestageRed;
    } else {
      return LifestageCharcoal;
    }
  };

  _renderMyDetail = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      return <View>{this._renderLifeStages()}</View>;
    }
  };

  _renderLifeStages = () => {
    if (this.props.lifeStages && this.props.lifeStages.length > 0) {
      return this.props.lifeStages.map((lifeStage: any) => {
        return (
          <StandardText
            style={[
              CommonStyles.customFont,
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
            ]}
            key={lifeStage.lifeStageId}
          >
            {lifeStage.lifeStageDescription}
          </StandardText>
        );
      });
    }
  };
}

export default AboutMeLifeStage;
