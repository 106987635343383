/*
 * Author: Sujeban Elankeswaran
 * Date: 06/01/2020
 * Copyright © 2020 Leap in!. All rights reserved.
 *
 * The ReferralBtn is used to show the prompt button for the provider list.
 */

import React from 'react';
import {Image, View, Text} from 'react-native';
import {StandardText, StandardButton} from './';
import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';
import {
  MapPinGeneric,
  InformationIconx3,
  SearchImageCharcoal,
  MarkerImage,
} from '../../assets/images';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {MESSAGES} from '../../Constants/Messages';

function _renderCloseStyle() {
  return [
    BrandStyles.brandBlockTxtColor6,
    CommonStyles.updateAddressBtnTitle,
    CommonStyles.customFontBold,
  ];
}

function _renderCloseContainerStyle() {
  return [
    BrandStyles.primaryBgColor1,
    BrandStyles.brandBorderColor1,
    CommonStyles.updateAddressBtn,
    CommonStyles.alignFlexEnd,
  ];
}

function checkInvalidAddress(memberData: any) {
  if (
    memberData.latitude === '0' &&
    memberData.longitude === '0' &&
    memberData.boundries.SA1 === 0 &&
    memberData.boundries.SA2 === 0 &&
    memberData.boundries.SA3 === 0 &&
    memberData.boundries.SA4 === 0
  ) {
    return false;
  } else if (
    memberData.latitude !== '0' &&
    memberData.longitude !== '0' &&
    memberData.boundries.SA1 === 0 &&
    memberData.boundries.SA2 === 0 &&
    memberData.boundries.SA3 === 0 &&
    memberData.boundries.SA4 === 0
  ) {
    return false;
  } else {
    return true;
  }
}

export default function ReferralBtn({
  memberData,
  emptyProviders,
  togglePopup,
  onPress,
  navigation,
  group,
  index,
  navigateBackToPreviousScreen,
}: any) {
  return (
    <View style={CommonStyles.referralBtnMainView}>
      {checkInvalidAddress(memberData) ? (
        <StandardButton
          containerStyle={CommonStyles.referralButton}
          accessibilityLabel={`Referral Button`}
          onPress={emptyProviders === 0 ? () => togglePopup(true) : onPress}
        >
          <View style={CommonStyles.referralBtnView}>
            <StandardText
              style={[
                CommonStyles.alignCenter,
                CommonStyles.textAlignCentre,
                CommonStyles.textAlignVerticalCenter,
                CommonStyles.customFontBold,
                CommonStyles.font20,
                BrandStyles.TextColor5,
                CommonStyles.referralBtn,
              ]}
              allowFontScaling={true}
            >
              {emptyProviders === 0
                ? MESSAGES.REFERRAL_SCREEN.SHOW_ME_PROVIDERS_THAT_CAN_HELP
                : MESSAGES.REFERRAL_SCREEN
                    .SHOW_ME_PROVIDERS_THAT_SERVICE_MY_REGION}
            </StandardText>
            <Image
              style={[
                emptyProviders === 0
                  ? CommonStyles.referralButtonSearchIcon
                  : CommonStyles.referralButtonMarkerIcon,
              ]}
              source={emptyProviders === 0 ? SearchImageCharcoal : MarkerImage}
            />
          </View>
        </StandardButton>
      ) : (
        <View style={CommonStyles.content}>
          <View style={CommonStyles.invalidAddressView}>
            <Image
              style={[CommonStyles.changePasswordButtonImage]}
              source={InformationIconx3}
              accessibilityLabel={MESSAGES.ACCESSIBILITY.IMPORTANT_INFORMATION}
              accessible={true}
            />
            <Text>{MESSAGES.REFERRAL_SCREEN.NO_PROVIDERS}</Text>
          </View>
          <View style={[CommonStyles.flexRow, CommonStyles.flexWrap]}>
            <View style={[CommonStyles.content]}>
              <Text style={CommonStyles.invalidAddressMessage}>
                {MESSAGES.REFERRAL_SCREEN.UPDATE_ADDRESS}
              </Text>
            </View>
            <View
              style={[
                CommonStyles.content,
                CommonStyles.justifyContentFlexEnd,
                CommonStyles.alignFlexEnd,
              ]}
            >
              <StandardButton
                style={_renderCloseStyle()}
                containerStyle={_renderCloseContainerStyle()}
                accessibilityLabel={types2.CANCEL}
                onPress={() => {
                  navigateBackToPreviousScreen('FOCUSADDRESS');
                  navigation.navigate('AboutMeMyDetailsForm', {
                    focus: types2.REFERRAL_BUTTON.ADDRESS,
                    goBack: types2.REFERRAL_BUTTON.SPENDING_SUPPORT_ITEM_DETAIL,
                    group: group,
                    index: index,
                  });
                }}
              >
                {types2.REFERRAL_BUTTON.UPDATE_ADDRESS}
              </StandardButton>
            </View>
          </View>
        </View>
      )}
    </View>
  );
}
