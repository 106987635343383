/*
 * Authour: Andrew Seeley
 * Date: 20/02/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The modal to select your life stage
 */

import React, {Component} from 'react';
import {StyleSheet, View, ScrollView, Modal} from 'react-native';

import {CommonActions, StackActions} from '@react-navigation/native';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

import {
  Container,
  ModalTitle,
  ModalSubtitle,
  CheckBoxButton,
} from '../Components/Molecules';
import {BasicModal} from '../Components/Organisms';

import {callApiChecker} from '../API/APIHelper';
import {callAPIs} from '../API/APICaller';
import {getLifeStages} from '../API/ReferenceAPI';
import {updateMember} from '../API/MemberAPI';

import * as MemberActions from '../Actions/MemberActions';
import * as GeneralActions from '../Actions/GeneralActions';
import logger from 'helpers/Logger';
class AboutMeLifeStageModal extends Component {
  constructor(props: any) {
    super(props);
    this._reapplyCheckboxes.bind(this);
    this._saveLifestageModal.bind(this);
    this._closeLifestageModal.bind(this);
    this._updateLifeStages.bind(this);
  }

  state = {
    loading: true,
    lifeStages: [], // The lifestage refernce data
    selectedLifeStages: [], // Stores lifestages for save api call & redux update in member
    selectedLifeStageNames: [], // Stores lifestate descriptions for redux update in member
    saveApiError: false,
  };

  componentDidMount() {
    let getLifeStagesCallback = (data: any) => {
      const lifeStages = data[0].content.lifeStages;

      var selectedItems = this._reapplyCheckboxes(
        JSON.parse(JSON.stringify(lifeStages)),
      );
      this.props.actions.GeneralActions.getLifeStageReferenceData(
        selectedItems.lifeStages,
        selectedItems.selectedLifeStages,
        selectedItems.selectedLifeStageNames,
      );
      this.setState({loading: false, lifeStages: selectedItems.lifeStages});
    };

    let loadFromCache = true;
    if (this.props.general == null) {
      loadFromCache = false;
    } else {
      if (this.props.general.lifeStageReference == null) {
        loadFromCache = false;
      }
    }

    if (!loadFromCache) {
      callAPIs([getLifeStages()], getLifeStagesCallback, null, () => {
        this.setState({loading: false});
      });
    } else {
      // Otherwise we have called it before - check the timeout
      if (
        callApiChecker(
          this.props.general.lifeStageReference.apiCalled,
          types.API_REFERENCE_TIMEOUT_CHECK,
        )
      ) {
        callAPIs([getLifeStages()], getLifeStagesCallback, null, () => {
          this.setState({loading: false});
        });
      } else {
        // Load from redux
        this.setState({
          loading: false,
          lifeStages: JSON.parse(
            JSON.stringify(this.props.general.lifeStageReference.lifeStages),
          ),
          selectedLifeStages: JSON.parse(
            JSON.stringify(
              this.props.general.lifeStageReference.selectedLifeStages,
            ),
          ),
          selectedLifeStageNames: JSON.parse(
            JSON.stringify(
              this.props.general.lifeStageReference.selectedLifeStageNames,
            ),
          ),
        });
      }
    }
  }

  render() {
    return (
      <BasicModal
        visible={true}
        save={this._saveLifestageModal}
        close={this._closeLifestageModal}
        loading={this.state.loading}
        saveApiError={this.state.saveApiError}
        saveApiErrorCode={this.state.saveApiErrorCode}
        readOnly={this.props.member.access.profile}
      >
        <ModalTitle text="How would you describe your life stage or current life transitions?" />
        <ModalSubtitle text={['Select all that apply.']} />
        <View style={{flex: 1, flexDirection: 'row', flexWrap: 'wrap'}}>
          {this._renderLifeStageCheckboxes()}
        </View>
      </BasicModal>
    );
  }

  // Reapply the selected checkboxes for the lifestages the member has selected
  _reapplyCheckboxes(lifeStages: any) {
    var selectedLifeStages = [];
    var selectedLifeStageNames = [];
    // Based on the member reducer find out what checkboxes are selected, to set them as selected in the reference data
    if (this.props.member.lifeStages != undefined) {
      if (this.props.member.lifeStages.length > 0) {
        for (let i = 0; i < this.props.member.lifeStages.length; i++) {
          // Select the current lifestage item in the reference data
          for (let k = 0; k < lifeStages.length; k++) {
            if (
              this.props.member.lifeStages[i].lifeStageId == lifeStages[k].id
            ) {
              lifeStages[k].selected = true;
              selectedLifeStages.push(lifeStages[k].id);
              selectedLifeStageNames.push(lifeStages[k].description);
              break;
            }
          }
        }
      }
    }
    return {
      lifeStages: lifeStages,
      selectedLifeStages: selectedLifeStages,
      selectedLifeStageNames: selectedLifeStageNames,
    };
  }

  _renderLifeStageCheckboxes() {
    if (this.state.lifeStages.length == 0) {
      return null;
    } else {
      var renderArray = this.state.lifeStages;
      return renderArray.map(item => {
        return (
          <CheckBoxButton
            readOnly={this.props.member.access.profile}
            style={{}}
            columns={2}
            key={item.id}
            data={item}
            onChange={(checkBoxObj: any) => {
              this._updateLifeStages(checkBoxObj);
            }}
          />
        );
      });
    }
  }

  _updateLifeStages = (lifestageObj: any) => {
    const selectedLifeStages = this.state;
    const selectedLifeStageNames = this.state.selectedLifeStageNames;

    const updateObject = {
      selectedLifeStages,
      selectedLifeStageNames,
    };
    if (lifestageObj.selected) {
      selectedLifeStages.push(lifestageObj.id);
      selectedLifeStageNames.push(lifestageObj.description);
    } else {
      // Remove the ID from the array
      var index = selectedLifeStages.indexOf(lifestageObj.id);
      selectedLifeStages.splice(index, 1);
      var dIndex = selectedLifeStageNames.indexOf(lifestageObj.description);
      selectedLifeStageNames.splice(dIndex, 1);
    }

    // Update the state
    this.setState(updateObject);
  };

  _saveLifestageModal = () => {
    this.setState({loading: true});

    const memberUpdateObject = {
      id: this.props.member.id,
      lifeStages: JSON.parse(JSON.stringify(this.state.selectedLifeStages)),
    };
    const updateMemberCallback = (data: any) => {
      // Build the lifestage object for redux
      var lifeStagesUpdate = [];
      var selectedLifeStages = JSON.parse(
        JSON.stringify(this.state.selectedLifeStages),
      );
      var selectedLifeStageNames = JSON.parse(
        JSON.stringify(this.state.selectedLifeStageNames),
      );
      for (let i = 0; i < this.state.selectedLifeStages.length; i++) {
        var lifeStageObject = {
          lifeStageId: selectedLifeStages[i],
          lifeStageDescription: selectedLifeStageNames[i],
        };
        lifeStagesUpdate.push(lifeStageObject);
      }

      // Update the member with the lifestages they have selected
      this.props.actions.MemberActions.actionUpdateMember({
        lifeStages: lifeStagesUpdate,
      });

      var reduxLifeStages = JSON.parse(JSON.stringify(this.state.lifeStages));
      var reduxSelectedLifeStages = JSON.parse(
        JSON.stringify(this.state.selectedLifeStages),
      );
      var reduxselectedLifeStageNames = JSON.parse(
        JSON.stringify(this.state.selectedLifeStageNames),
      );
      this.props.actions.GeneralActions.getLifeStageReferenceData(
        reduxLifeStages,
        reduxSelectedLifeStages,
        reduxselectedLifeStageNames,
      );
      this.setState({loading: false});
      this.props.navigation.dispatch(CommonActions.goBack());
    };

    const updateMemberErrorCallback = (err: any) => {
      logger.log('updateMemberErrorCallback error: ', err);
      this.setState({
        saveApiErrorCode: err.code,
        saveApiError: true,
        loading: false,
      });
    };

    callAPIs(
      [updateMember(memberUpdateObject, false)],
      updateMemberCallback,
      updateMemberErrorCallback,
    );
  };

  _closeLifestageModal = () => {
    this.props.navigation.dispatch(CommonActions.goBack());
  };
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  user: state.UserReducer,
  loadedMemberId: state.LoadedMemberReducer,
  general: state.GeneralReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    MemberActions: bindActionCreators(MemberActions, dispatch),
    GeneralActions: bindActionCreators(GeneralActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AboutMeLifeStageModal);
