/*
 * Author: Brad D'Costa
 * Date: 05/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the header wrapper which incudes the Documents, Settings and Help buttons
 */

import React, {Component} from 'react';
import {
  View,
  FlatList,
  StyleSheet,
  ScrollView,
  Image,
  Platform,
  AppState,
} from 'react-native';
import NetInfo from '@react-native-community/netinfo';

import {StandardTouchableOpacity, StandardText} from '../Atoms';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {NotificationBadge} from './NotificationBadge';

import {SetProfilePicture} from '../Organisms';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import BasicPopupAlert from '../Organisms/BasicPopupAlert';
import CommonStyles from '../../Styles/CommonStyles';
import {BrandIcons} from '../../Styles/BrandIcons';
import {backArrowWhite, backArrowblack} from '../../assets/images/vector-icons';
import BrandStyles from '../../Styles/BrandStyles';
import * as NotificationActions from '../../Actions/NotificationActions';
import {NOTIFICATION_STATUS} from '../../Constants/Constants';
import {IOS, OFFSET_WIDTH, GUIDE_ME_STEPS} from '../../Constants/Constants2';
import * as brand from '../../Constants/BrandConstants';
import {RoundPhotoTile} from './';

import * as SideMenuActions from '../../Actions/SideMenuActions';
import * as MemberActions from '../../Actions/MemberActions';
import * as GuideMeActions from '../../Actions/GuideMeActions';
import * as AvatarListActions from '../../Actions/AvatarListActions';
import DeviceInfo from 'react-native-device-info';
import {callAPIs} from '../../API/APICaller';
import {getAvatarImages} from '../../API/MemberAPI';
import {focusOnView} from '../../Helpers/PlatformSynchronizer';
import { goBack as navGoBack, replace } from '../../RootNavigation'
import {MESSAGES} from '../../Constants/Messages';

import {
  CommonActions,
  StackActions,
} from '@react-navigation/native';

import CustomIcon from '../../assets/images/CustomIcon';
import * as NavigationParamActions from '../../Actions/NavigationParamActions';

const isTablet = DeviceInfo.isTablet();
const PROFILE_PICTURE_FORM = 'ProfilePictureForm';

class WrapperHeader extends Component {
  goBackbtn: any;
  mainTitle: any;
  profilePic: any;
  state = {
    isConnected: true,
    lastUpdate: new Date(),
    profilePictureModalVisible: false,
    readingNotificationId: '',
    errorPopUpVisible: false,
    moreButtonView: undefined,
    orientation: '',
  };
  subscription: any;

  constructor(props: any) {
    super(props);
    this.renderBackButton = this.renderBackButton.bind(this);
    this._showNotificationsScreen = this._showNotificationsScreen.bind(this);
    this._showDocumentsScreen = this._showDocumentsScreen.bind(this);
    this._showSettingsScreen = this._showSettingsScreen.bind(this);
    this._showHelpScreen = this._showHelpScreen.bind(this);
    this._showPlanSummaryPdfViewerScreen =
      this._showPlanSummaryPdfViewerScreen.bind(this);
    this.renderProfileImage = this.renderProfileImage.bind(this);
    this._setProfilePicture.bind(this);
    this.goBack = this.goBack.bind(this);
    this._closeAlert = this._closeAlert.bind(this);
    this._showAlert = this._showAlert.bind(this);
  }

  componentDidMount() {
    NetInfo.addEventListener(this.handleConnectivityChange);
    this.subscription=AppState.addEventListener('change', this._handleAppStateChange);
    this.dispatchFocusInOrder();
  }
  dispatchFocusInOrder() {
    setTimeout(
      function (this: any) {
        const latestItem = [this.mainTitle, this.profilePic, this.goBackbtn]
          .filter(item => item !== undefined && item != null)
          .pop();
        if (latestItem && !this.props.loading) {
          focusOnView(latestItem);
        }
      }.bind(this),
      500,
    );
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.loading && !this.props.loading) {
      this.dispatchFocusInOrder();
    }
  }
  componentWillUnmount() {
    // AppState.removeEventListener('change', this.onAppStateChange);
    if (this.subscription) {
      this.subscription.remove();
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.member.profileImage != null) {
      this.setState({
        profileImage: nextProps.member.profileImage,
        lastUpdate: new Date(),
      });
    } else {
      this.setState({profileImage: null, lastUpdate: new Date()});
    }

    let offsetHeight = 0;

    if (Platform.OS === IOS) {
      offsetHeight = OFFSET_WIDTH * 4;
    }

    if (
      this.props.guideMe.showWelcomeGuide &&
      this.props.guideMe.welcomeGuideStep !== nextProps.guideMe.welcomeGuideStep
    ) {
      if (nextProps.guideMe.welcomeGuideStep == GUIDE_ME_STEPS.STEP_4) {
        this.props.actions.GuideMeActions.setOverlayHighlightOne({
          size: {
            x: this.state.moreButtonView.width / 3 + OFFSET_WIDTH,
            y: this.state.moreButtonView.height + OFFSET_WIDTH,
          },
          position: {
            x:
              this.state.moreButtonView.x +
              (this.state.moreButtonView.width / 3 + OFFSET_WIDTH * 3) * 2,
            y: this.state.moreButtonView.y + offsetHeight,
          },
        });
      }
    }
    if (!nextProps.isOpen) {
      this.dispatchFocusInOrder();
    }
  }

  _closeAlert() {
    this.setState({
      errorPopUpVisible: false,
    });
  }

  _handleAppStateChange = (nextAppState: any) => {
    if (nextAppState) {
      NetInfo.fetch().then((isConnected: any) => {
        this.setState({isConnected: isConnected});
      });
    }
  };

  handleConnectivityChange = (isConnected: any) => {
    if (isConnected) {
      this.setState({isConnected});
    } else {
      this.setState({isConnected});
    }
  };

  _showAlert(
    alertHeader: any,
    alertMessage: any,
    alertButtonText: any,
    closeAction: any,
    errorPopUpVisible: any,
  ) {
    this.setState({
      alertHeader: alertHeader,
      alertMessage: alertMessage,
      alertButtonText: alertButtonText,
      closeAction: closeAction,
      errorPopUpVisible: errorPopUpVisible,
    });
  }
  render() {
    return (
      <View style={this.props.style}>
        <View
          accessible={true}
          accessibilityLabel={
            this.props.headerTitle + ' ' + types2.ACCESSIBILITY_LABELS.TITLE
          }
          ref={ref => {
            this.mainTitle = ref;
          }}
          style={[
            CommonStyles.alignItemsCenter,
            CommonStyles.justifyContentCentre,
            CommonStyles.wrapperHeaderTitleAbsolute,
            isTablet ? CommonStyles.marginTop20 : CommonStyles.marginTop30,
          ]}
        >
          <StandardText
            allowFontScaling={false}
            style={[
              this._determineTextStyle(types.SCREEN_SETTINGS),
              Platform.OS === IOS
                ? CommonStyles.title_wrapperHeaderPadding
                : null,
              isTablet
                ? [
                    CommonStyles.font20,
                    CommonStyles.title_wrapperHeaderContainer,
                  ]
                : [
                    CommonStyles.font18,
                    CommonStyles.title_wrapperHeaderContainerSmall,
                    this._determineTextWrap(),
                  ],
              BrandStyles.bottomTabIconInactiveColor,
            ]}
          >
            {this.props.headerTitle}
          </StandardText>
        </View>
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.justifyContentSpaceBetween,
          ]}
        >
          <View style={[CommonStyles.flex1, CommonStyles.flexDirectionRow]}>
            {Platform.OS === IOS ? this.renderBackButton() : null}
            <View style={[CommonStyles.paddingTop5Android]}>
              {this.renderProfileImage()}
            </View>
          </View>
          {!this.props.hideMoreButton && this._judgeMoreButtonForDevices()}
        </View>
        <BasicPopupAlert
          visible={this.state.errorPopUpVisible}
          close={this.state.closeAction}
          cancelLabel={this.state.alertButtonText}
          headerText={this.state.alertHeader}
          messageText={this.state.alertMessage}
        />
      </View>
    );
  }

  _determineTextWrap = () => {
    if (
      this.props.headerTitle &&
      this.props.headerTitle.toString().length > 20
    ) {
      return CommonStyles.marginLeft40;
    }
  };

  _closeprofilePictureModal = () => {
    this.setState({profilePictureModalVisible: false});
  };

  _setProfilePicture = (requestObject: any) => {
    imageObjectState = {
      lastModified: new Date(),
      key: requestObject.imagePath,
      url: requestObject.imagePath,
      localImage: true,
      photoCaption: requestObject.photoCaption,
    };
    saveObj = {
      id: this.props.member.id,
      profileImage: imageObjectState,
    };
    this.props.actions.MemberActions.actionUpdateMember(saveObj);
    this.setState({profileImage: imageObjectState, lastUpdate: new Date()});
  };

  _determineAccess = () => {
    if (
      this.props.member === undefined ||
      Object.getOwnPropertyNames(this.props.member).length === 0
    ) {
      return null;
    } else {
      let readOnly = this.props.member;
      if (readOnly.access) {
        if (readOnly.access.profile) {
          if (readOnly.access.profile == types.ACCESS_READ_ONLY) {
            return types.ACCESS_READ_ONLY;
          }
        }
      }
    }
    return null;
  };

  _showProfilePicture = () => {
    this.props.nav.navigate(PROFILE_PICTURE_FORM, {
      imageObject: this.props.member.profileImage,
      setProfilePicture: this._setProfilePicture.bind(this),
      determineAccess: this._determineAccess.bind(this),
      visible: this.state.profilePictureModalVisible,
      forceMemberImageUpload: true,
    });
    this._getAvatarImages();
  };

  _getAvatarImages = () => {
    let callbackFunction = (data: any) => {
      var avatarList = data[0].content;
      this.props.actions.AvatarListActions.actionGetAvatarList(avatarList);
    };
    callAPIs(
      [getAvatarImages(this.props.loadedMemberId.loadedMemberId)],
      callbackFunction,
      null,
      () => {},
    );
  };

  _closeprofilePictureModal = () => {
    this.setState({profilePictureModalVisible: false});
  };

  _judgeMoreButtonForDevices = () => {
    const scene = this.props.sideMenu.currentScreen;
    let accessibleRead = '';
    if (scene === types2.LINKED_MEMBER) {
      accessibleRead = types2.NAVIGATION_TEXT.MEMBERS_MENU;
    } else if (scene === types2.PROFILE) {
      accessibleRead = types2.NAVIGATION_TEXT.PROFILE_MENU;
    } else if (scene === types2.BUDGETS) {
      accessibleRead = types2.NAVIGATION_TEXT.BUDGETS_MENU;
    }

    if (isTablet) {
      return this._renderTabletMoreButton(accessibleRead);
    }
    return this._renderPhoneMoreButton(accessibleRead);
  };

  _renderTabletMoreButton = (accessibleRead: any) => {
    return (
      <View
        onLayout={({nativeEvent}) => {
          this.setState({moreButtonView: nativeEvent.layout});
        }}
        style={[CommonStyles.flex1, CommonStyles.paddingBottom10]}
      >
        <StandardTouchableOpacity
          style={[
            this._determinebkgd(types.SCREEN_SETTINGS),
            CommonStyles.button_more_width,
            CommonStyles.alignFlexEnd,
          ]}
          accessible={true}
          accessibilityLabel={accessibleRead}
          activeOpacity={0.6}
          onPress={() => this.props.toggleMenu()}
        >
          <View
            style={[
              this._determinebkgd(types.SCREEN_SETTINGS),
              CommonStyles.alignFlexEnd,
              CommonStyles.flexDirectionRow,
            ]}
          >
            <StandardText
              allowFontScaling={false}
              style={[
                this._determineTextStyle(types.SCREEN_SETTINGS),
                CommonStyles.paddingscreenWrapperHeaderIcon,
                CommonStyles.paddingTop10iOS,
                isTablet ? CommonStyles.font18 : CommonStyles.font13,
                CommonStyles.button_more,
                BrandStyles.bottomTabIconInactiveColor,
              ]}
            >
              {!this.props.isOpen ? 'More' : 'Less'}
            </StandardText>
            <CustomIcon
              style={[
                CommonStyles.screenWrapperHeaderIcon,
                CommonStyles.button_moreImage,
                BrandStyles.bottomTabIconInactiveColor,
              ]}
              name={BrandIcons.HEADER_TAB_MORE_ICON_TYPE_MAIN}
            />
          </View>
        </StandardTouchableOpacity>
      </View>
    );
  };

  _renderPhoneMoreButton = (accessibleRead: any) => {
    return (
      <View
        onLayout={({nativeEvent}) => {
          this.setState({moreButtonView: nativeEvent.layout});
        }}
        style={[CommonStyles.flex1, CommonStyles.paddingTop5]}
      >
        <StandardTouchableOpacity
          style={[
            this._determinebkgd(types.SCREEN_SETTINGS),
            CommonStyles.button_more_width,
            CommonStyles.alignFlexEnd,
          ]}
          accessible={true}
          accessibilityLabel={accessibleRead}
          activeOpacity={0.6}
          onPress={() => this.props.toggleMenu()}
        >
          <View
            style={[
              this._determinebkgd(types.SCREEN_SETTINGS),
              CommonStyles.alignFlexEnd,
              CommonStyles.wrapperHeaderHeightPhone,
              CommonStyles.button_moreImage,
            ]}
          >
            <CustomIcon
              style={[
                CommonStyles.screenWrapperHeaderIcon,
                BrandStyles.bottomTabIconInactiveColor,
              ]}
              name={BrandIcons.HEADER_TAB_MORE_ICON_TYPE_MAIN}
            />
            <StandardText
              allowFontScaling={false}
              style={[
                this._determineTextStyle(types.SCREEN_SETTINGS),
                CommonStyles.font13,
                BrandStyles.bottomTabIconInactiveColor,
              ]}
            >
              More
            </StandardText>
          </View>
        </StandardTouchableOpacity>
      </View>
    );
  };

  renderProfileImage() {
    if (this.props.activeScreen != types.SCREEN_MANAGE_MEMBERS) {
      let isDisabled = false;
      if (this.props.member.access != undefined) {
        if (this.props.member.access.profile != types.ACCESS_FULL) {
          isDisabled = true;
        }
      }
      if (this.props.member && this.props.member.profileImage !== undefined) {
        const readOutProfilePicture = this.state.profileImage
          ? this.state.profileImage.photoCaption
            ? `${this.state.profileImage.photoCaption} profile picture`
            : `${this.props.member.firstName} profile picture`
          : 'Your profile Photo Upload';
        return (
          <RoundPhotoTile
            style={[
              CommonStyles.paddingscreenWrapperHeaderIcon,
              isTablet
                ? CommonStyles.roundedProfileImage
                : CommonStyles.roundedProfileImageMobile,
            ]}
            accessible={true}
            onRef={(ref: any) => {
              this.profilePic = ref;
            }}
            imageObject={this.state.profileImage}
            apiFileType={types2.FILE_TYPE.MEMBER_PROFILE}
            accessibilityLabel={readOutProfilePicture}
            lastUpdate={this.state.lastUpdate}
            actionCallback={() => this._showProfilePicture()}
            uploadCallBack={this._setProfilePicture}
            disabled={isDisabled}
          />
        );
      }
    }
    return null;
  }

  _showProfilePicture = () => {
    const navigationParams = {
      imageObject: this.props.member.profileImage,
      setProfilePicture: this._setProfilePicture.bind(this),
      determineAccess: this._determineAccess.bind(this),
      visible: this.state.profilePictureModalVisible,
      forceMemberImageUpload: true,
    };
    this.props.actions.NavigationParamActions.setParam(navigationParams);
    this.props.nav.navigate(PROFILE_PICTURE_FORM);
    this._getAvatarImages();
  };

  _renderSettings = () => {
    if (
      this.props.activeScreen == types.SCREEN_UPLOAD_PLAN ||
      this.props.activeScreen == types.SCREEN_TERMS_CONDITIONS_FULL_PM
    ) {
    } else {
      return (
        <StandardTouchableOpacity
          style={this._determinebkgd(types.SCREEN_SETTINGS)}
          accessible={true}
          accessibilityLabel="Show settings screen"
          activeOpacity={0.6}
          onPress={() => this._showSettingsScreen()}
        >
          <View style={this._determinebkgd(types.SCREEN_SETTINGS)}>
            <CustomIcon
              style={[CommonStyles.screenWrapperHeaderIcon]}
              name={this._determineIcon(types.SCREEN_SETTINGS)}
            />
            <StandardText
              allowFontScaling={false}
              style={this._determineTextStyle(types.SCREEN_SETTINGS)}
            >
              Settings
            </StandardText>
          </View>
        </StandardTouchableOpacity>
      );
    }
  };

  _renderDocuments = () => {
    if (
      this.props.activeScreen == types.SCREEN_MANAGE_MEMBERS ||
      this.props.activeScreen == types.SCREEN_SETTINGS ||
      this.props.activeScreen == types.SCREEN_UPLOAD_PLAN ||
      this.props.activeScreen == types.SCREEN_TERMS_CONDITIONS_FULL_PM ||
      (this.props.activeScreen == types.SCREEN_CHANGE_PASSWORD &&
        this.props.user.isCrew)
    ) {
    } else {
      if (Object.getOwnPropertyNames(this.props.member).length === 0) {
        return null;
      } else {
        if (!this.props.member.access) {
          return null;
        } else {
          if (this.props.member.access.documents !== types.ACCESS_NONE) {
            return (
              <StandardTouchableOpacity
                style={this._determinebkgd(types.SCREEN_DOCUMENTS)}
                accessible={true}
                accessibilityLabel="Show documents screen"
                activeOpacity={0.6}
                onPress={() => this._showDocumentsScreen()}
              >
                <View style={this._determinebkgd(types.SCREEN_DOCUMENTS)}>
                  <CustomIcon
                    style={[CommonStyles.screenWrapperHeaderIcon]}
                    name={this._determineIcon(types.SCREEN_DOCUMENTS)}
                  />
                  <StandardText
                    allowFontScaling={false}
                    style={this._determineTextStyle(types.SCREEN_DOCUMENTS)}
                  >
                    Documents
                  </StandardText>
                </View>
              </StandardTouchableOpacity>
            );
          } else {
            return null;
          }
        }
      }
    }
  };

  _renderNotifications = () => {
    let showNotification = false;
    if (
      this.props.activeScreen == types.SCREEN_UPLOAD_PLAN ||
      this.props.activeScreen == types.SCREEN_MANAGE_MEMBERS ||
      this.props.activeScreen == types.SCREEN_SETTINGS ||
      this.props.activeScreen == types.SCREEN_TERMS_CONDITIONS_FULL_PM ||
      (this.props.activeScreen == types.SCREEN_CHANGE_PASSWORD &&
        this.props.user.isCrew)
    ) {
    } else {
      if (this.props.member.access != undefined) {
        if (this.props.member.access.notifications != 'none') {
          showNotification = true;
        }
      } else {
        showNotification = true;
      }

      if (showNotification) {
        return (
          <StandardTouchableOpacity
            style={this._determinebkgd(types.SCREEN_NOTIFICATIONS)}
            accessible={true}
            accessibilityLabel="Show notifications screen"
            activeOpacity={0.6}
            onPress={() => this._showNotificationsScreen()}
          >
            <View style={this._determinebkgd(types.SCREEN_NOTIFICATIONS)}>
              {this._showNotificationBadge()}
              <CustomIcon
                style={[CommonStyles.screenWrapperHeaderIcon]}
                name={this._determineIcon(types.SCREEN_NOTIFICATIONS)}
              />
              <StandardText
                allowFontScaling={false}
                style={this._determineTextStyle(types.SCREEN_NOTIFICATIONS)}
              >
                Notifications
              </StandardText>
            </View>
          </StandardTouchableOpacity>
        );
      } else {
        null;
      }
    }
  };

  _showNotificationBadge() {
    let showNotification = false;
    if (this.props.member.access != undefined) {
      if (this.props.member.access.notifications != 'none') {
        showNotification = true;
      }
    } else {
      showNotification = true;
    }

    if (showNotification) {
      let badgeBgColor = BrandStyles.notificationBadgeBgColor;
      let badgeTxtColor = BrandStyles.notificationBadgeTextColor;
      if (
        this.props.selectedIcon != undefined &&
        this.props.selectedIcon == 'screen_notifications'
      ) {
        badgeBgColor = BrandStyles.notificationSelectedBadgeBgColor;
        badgeTxtColor = BrandStyles.notificationSelectedBadgeTextColor;
      }
      return (
        <NotificationBadge
          count={this._getUnreadNotificationCount()}
          bgColor={badgeBgColor}
          txtColor={badgeTxtColor}
        />
      );
    } else {
      null;
    }
  }

  renderBackButton() {
    if (!this.props.hideBackButton) {
      return (
        <View
          style={[
            isTablet
              ? [
                  CommonStyles.flexDirectionRow,
                  CommonStyles.alignFlexStart,
                  CommonStyles.paddingTop5,
                  CommonStyles.button_backContainer,
                ]
              : [
                  CommonStyles.wrapperHeaderHeightPhone,
                  CommonStyles.button_backContainerSmall,
                ],
          ]}
        >
          <StandardTouchableOpacity
            activeOpacity={0.6}
            onPress={this.goBack}
            ref={ref => {
              this.goBackbtn = ref;
            }}
            accessible={true}
            accessibilityLabel={types2.ACCESSIBILITY_LABELS.GO_BACK}
          >
            <View
              style={[
                isTablet
                  ? [
                      CommonStyles.alignItemsCenter,
                      CommonStyles.flexDirectionRow,
                      CommonStyles.screenWrapperHeaderContainer,
                    ]
                  : [
                      CommonStyles.alignItemsCenter,
                      CommonStyles.flexColumn,
                      CommonStyles.screenWrapperHeaderContainerPhone,
                    ],
              ]}
              accessible={false}
            >
              <CustomIcon
                accessible={false}
                style={[
                  CommonStyles.screenWrapperHeaderIcon,
                  BrandStyles.bottomTabIconInactiveColor,
                ]}
                name={this._determineIcon(types2.BACK_BUTTON)}
              />
              <StandardText
                accessible={false}
                allowFontScaling={false}
                style={[
                  this._determineTextStyle(''),
                  isTablet
                    ? [
                        CommonStyles.paddingLeft10,
                        CommonStyles.paddingTop5,
                        CommonStyles.font18,
                      ]
                    : CommonStyles.font13,
                  BrandStyles.bottomTabIconInactiveColor,
                ]}
              >
                Back
              </StandardText>
            </View>
          </StandardTouchableOpacity>
        </View>
      );
    }
    return null;
  }

  goBack() {
    if (
      this.props.guideMe.previousScreenInfo &&
      this.props.guideMe.previousScreenInfo.fromScreen == types2.GUIDEME &&
      this.props.guideMe.previousScreenInfo.currentScreen ==
        this.props.nav.state.routeName
    ) {
      const actionToDispatch = CommonActions.reset({
        index: 0,
        routes: [{name: 'Home'}],
      });
      this.props.nav.dispatch(actionToDispatch);
    } else if (this.props.screen) {
      //this.props.nav.replace(this.props.screen);
      replace(this.props.screen)
    } else {
      navGoBack();
    }
  }

  //Routing Functions - will only route if not already on the screen you would route to
  _showNotificationsScreen = () => {
    if (this.props.activeScreen != types.SCREEN_NOTIFICATIONS) {
      this.props.nav.navigate('Notifications');
    }
  };

  _showDocumentsScreen = () => {
    if (this.props.activeScreen != types.SCREEN_DOCUMENTS) {
      this.props.nav.navigate('Documents');
    }
  };

  _showSettingsScreen = () => {
    if (this.props.activeScreen != types.SCREEN_SETTINGS) {
      this.props.nav.navigate('Settings');
    }
  };

  _showHelpScreen = () => {
    if (this.props.activeScreen != types.SCREEN_HELP) {
      this._navigationCallback();
    }
  };

  _showPlanSummaryPdfViewerScreen = () => {
    if (this.props.activeScreen != types.SCREEN_PLAN_SUMMARY) {
      this._navigationCallback();
    }
  };

  //check for internet connectivity before navigating to Webview
  _navigationCallback = () => {
    if (this.state.isConnected) {
      this.props.nav.navigate('PdfViewer', {url: brand.APP_HELP_URL});
    } else {
      this._showAlert(
        types2.ALERT_TITLE,
        types2.CONNECTION_ERROR,
        types2.OK,
        this._closeAlert,
        true,
      );
    }
  };

  _loggedInUserName = (firstname: any, lastname: any) => {
    var str = firstname + ' ' + lastname;
    if (str.length > 35) {
      return str.substring(0, 35) + '...';
    } else {
      return str;
    }
  };

  //Styling functions

  /*
   * Function will return the background styling for the icon and text container.
   * If the icon is selected, it will include a white background
   */
  _determinebkgd = (buttonType: any) => {
    var bkgdStyle = [
      CommonStyles.alignItemsCenter,
      isTablet ? CommonStyles.screenWrapperHeaderContainer : [],
    ];
    return bkgdStyle;
  };

  _determineSeperatorStyle = (buttonType: any) => {
    var sepStyle = [CommonStyles.ProfileDetailSeperator];
    if (
      this.props.screenType == types.SCREEN_TYPE_MAIN ||
      this.props.screenType == types.SCREEN_TYPE_MAIN_ALTERNATIVE
    ) {
      sepStyle.push(BrandStyles.primaryBgColor1);
    } else if (this.props.screenType == types.SCREEN_TYPE_EDIT) {
      sepStyle.push(BrandStyles.primaryBgColor2);
    }
    return sepStyle;
  };

  /*
   * Function will return the icon to be displayed
   * White icons are returned for main/alternative main screens
   * Charcoal icons are returned for the secondary screens
   */
  _determineIcon = (buttonType: any) => {
    var iconImage = '';
    if (
      this.props.screenType == types.SCREEN_TYPE_MAIN ||
      !this.props.selectedIcon ||
      this.props.screenType == types.SCREEN_TYPE_MAIN_ALTERNATIVE
    ) {
      if (this.props.screenType == types.SCREEN_TYPE_EDIT) {
        if (buttonType == types.SCREEN_NOTIFICATIONS) {
          iconImage = BrandIcons.HEADER_TAB_NOTIFICATIONS_ICON_TYPE_MAIN_OPTION;
        } else if (buttonType == types.SCREEN_DOCUMENTS) {
          iconImage = BrandIcons.HEADER_TAB_DOCUMENTS_ICON_TYPE_MAIN_OPTION;
        } else if (buttonType == types.SCREEN_SETTINGS) {
          iconImage = BrandIcons.HEADER_TAB_SETTINGS_ICON_TYPE_MAIN_OPTION;
        } else if (buttonType == types.SCREEN_HELP) {
          iconImage = BrandIcons.HEADER_TAB_HELP_ICON_TYPE_MAIN_OPTION;
        } else if ((buttonType = types2.BACK_BUTTON)) {
          iconImage = backArrowblack;
        }
      } else {
        if (buttonType == types.SCREEN_NOTIFICATIONS) {
          iconImage = BrandIcons.HEADER_TAB_NOTIFICATIONS_ICON_TYPE_MAIN;
        } else if (buttonType == types.SCREEN_DOCUMENTS) {
          iconImage = BrandIcons.HEADER_TAB_DOCUMENTS_ICON_TYPE_MAIN;
        } else if (buttonType == types.SCREEN_SETTINGS) {
          iconImage = BrandIcons.HEADER_TAB_SETTINGS_ICON_TYPE_MAIN;
        } else if (buttonType == types.SCREEN_HELP) {
          iconImage = BrandIcons.HEADER_TAB_HELP_ICON_TYPE_MAIN;
        } else if ((buttonType = types2.BACK_BUTTON)) {
          iconImage = backArrowWhite;
        }
      }
    } else if (
      this.props.selectedIcon &&
      this.props.screenType == types.SCREEN_TYPE_EDIT
    ) {
      if (buttonType == types.SCREEN_NOTIFICATIONS) {
        if (buttonType == this.props.selectedIcon) {
          iconImage = BrandIcons.HEADER_TAB_NOTIFICATIONS_ICON_TYPE_EDIT;
        } else {
          iconImage = BrandIcons.HEADER_TAB_NOTIFICATIONS_ICON_TYPE_MAIN_OPTION;
        }
      }
      if (buttonType == types.SCREEN_DOCUMENTS) {
        if (buttonType == this.props.selectedIcon) {
          iconImage = BrandIcons.HEADER_TAB_DOCUMENTS_ICON_TYPE_EDIT;
        } else {
          iconImage = BrandIcons.HEADER_TAB_DOCUMENTS_ICON_TYPE_MAIN_OPTION;
        }
      }

      if (buttonType == types.SCREEN_SETTINGS) {
        if (buttonType == this.props.selectedIcon) {
          iconImage = BrandIcons.HEADER_TAB_SETTINGS_ICON_TYPE_EDIT;
        } else {
          iconImage = BrandIcons.HEADER_TAB_SETTINGS_ICON_TYPE_MAIN_OPTION;
        }
      }

      if (buttonType == types.SCREEN_HELP) {
        if (buttonType == this.props.selectedIcon) {
          iconImage = BrandIcons.HEADER_TAB_HELP_ICON_TYPE_EDIT;
        } else {
          iconImage = BrandIcons.HEADER_TAB_HELP_ICON_TYPE_MAIN_OPTION;
        }
      }

      if (buttonType == types2.BACK_BUTTON) {
        iconImage = backArrowblack;
      }
    }
    return iconImage;
  };

  /*
   * Function will return the text styling
   * White is are returned for main/alternative main screens
   * Charcoal text is returned for the secondary screens
   */
  _determineTextStyle = (buttonType: any) => {
    var textStyle = [CommonStyles.customFont];

    if (
      this.props.screenType == types.SCREEN_TYPE_MAIN ||
      !this.props.selectedIcon ||
      this.props.screenType == types.SCREEN_TYPE_MAIN_ALTERNATIVE
    ) {
      if (this.props.screenType == types.SCREEN_TYPE_EDIT) {
        textStyle.push(BrandStyles.WrapperHeaderEditSelectedTextColor);
      } else {
        textStyle.push(BrandStyles.WrapperHeaderEditTextColor);
      }
    } else if (
      this.props.selectedIcon &&
      this.props.screenType == types.SCREEN_TYPE_EDIT
    ) {
      if (buttonType == this.props.selectedIcon) {
        textStyle.push(BrandStyles.WrapperHeaderEditSelectedIconTextColor);
      } else {
        textStyle.push(BrandStyles.WrapperHeaderEditSelectedTextColor);
      }
    }
    return textStyle;
  };

  _getUnreadNotificationCount() {
    return this.props.notifications.length
      ? this.props.notifications.filter(
          (unredCount: any) => unredCount.Status === NOTIFICATION_STATUS.UNREAD,
        ).length
      : 0;
  }
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  notifications: state.NotificationReducer,
  user: state.UserReducer,
  sideMenu: state.SideMenuReducer,
  tabBar: state.TabBarReducer,
  loadedMemberId: state.LoadedMemberReducer,
  guideMe: state.GuideMeReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SideMenuActions: bindActionCreators(SideMenuActions, dispatch),
    MemberActions: bindActionCreators(MemberActions, dispatch),
    AvatarListActions: bindActionCreators(AvatarListActions, dispatch),
    GuideMeActions: bindActionCreators(GuideMeActions, dispatch),
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WrapperHeader);
