/*
 * Author: Thilina Herath
 * Date: 15/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The NDIS Meeting Checklist Screen.
 */

import React, {Component} from 'react';
import NetInfo from '@react-native-community/netinfo';
import {View, ScrollView, AppState, Dimensions, Platform} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {WebViewWeb as WebView} from 'helpers/ReactNativeWebview';

import {SCREEN_TYPE_MAIN, SCREEN_HELP, isWeb} from '../Constants/Constants';
import {NAVIGATION_TEXT} from '../Constants/Constants2';
import * as brand from '../Constants/BrandConstants';
import {Container} from '../Components/Molecules';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import * as LogoutConfirmationActions from '../Actions/LogoutActions';

class MeetingChecklist extends Component {
  state = {
    loading: false,
    saveAPIError: false,
  };
  subscription: any;

  constructor(props: any) {
    super(props);
    this._createMainContents.bind(this);
    this._createAllModals.bind(this);
    // Network connectivity handler binding to display the message from this page
    this.handleConnectivityChange = this.handleConnectivityChange.bind(this);
  }

  componentDidMount() {
    NetInfo.addEventListener(this.handleConnectivityChange);
    this.subscription=AppState.addEventListener('change', this._handleAppStateChange);
  }

  componentWillUnmount() {
    // AppState.removeEventListener('change', this._handleAppStateChange);
    this.subscription.remove();
  }

  handleConnectivityChange = (isConnected: any) => {
    this.setState({isConnected});
  };

  _handleAppStateChange = (nextAppState: any) => {
    if (nextAppState) {
      NetInfo.fetch().then(isConnected => {
        this.setState({isConnected});
      });
    }
  };

  render() {
    if (isWeb) {
      return this._createMainContents();
    } else {
      return (
        <Container
          contents={this._createMainContents}
          loading={this.state.loading}
          modals={this._createAllModals}
          needsSidebar={false}
          screenType={SCREEN_TYPE_MAIN}
          activeScreen={SCREEN_HELP}
          selectedIcon={SCREEN_HELP}
          nav={this.props.navigation}
          toggleMenu={this.props.screenProps?.toggleMenu}
          getInitialMenuState={this.props.screenProps?.getInitialMenuState}
          headerTitle={NAVIGATION_TEXT.MEETING_CHECKLIST}
          demoProfile={this.props.user.demoProfile}
          showConfirmLogout={
            this.props.actions.LogoutConfirmationActions.showConfirmLogout
          }
        />
      );
    }
  }

  _createAllModals = () => {
    let modalJSX: any = [];

    return modalJSX;
  };

  _createMainContents = () => {
    return (
      <ScrollView
        style={[CommonStyles.content, BrandStyles.primaryBgColor1]}
        contentContainerStyle={[
          CommonStyles.alignItemsCenter,
          CommonStyles.justifyContentCentre,
          CommonStyles.scrollViewVerticalCentre,
        ]}
      >
        <View
          style={[
            CommonStyles.checklistContentContainer,
            BrandStyles.primaryBgColor1,
            isWeb ? {height: Dimensions.get('window').height * 0.9} : null,
          ]}
        >
          {Platform.OS==='web' ? (
            <WebView style={{width: '100%', height:'100%'}} title="MeetingChecklist" srcDoc={false} source={brand.APP_CHECKLIST_URL} />
          ) : (
            <WebView source={brand.APP_CHECKLIST_URL} />
          )}
        </View>
      </ScrollView>
    );
  };
}
const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MeetingChecklist);
