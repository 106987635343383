/*
 * Author: Nirodha Perera
 * Date: 11/03/2019
 * Copyright © 2019 Leap in!. All rights reserved.
 *
 * Floating action button for guide me web mobile version
 */

import React, {Component} from 'react';
import {View} from 'react-native';

//Import assets
import CustomIcon from '../../assets/images/CustomIcon';
import {GuideMeImage} from '../../assets/images/vector-icons';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

//Import custom components
import {StandardTouchableOpacity, StandardText} from '../Atoms';

//Import extermal components
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

//Import redux actions
import * as TabBarActions from '../../Actions/TabBarActions';

//Import constants
import {BUDGETS} from '../../Constants/Constants2';

class FloatingActionButton extends Component {
  clickHandler = () => {
    this.props.actions.TabBarActions.displayGuideMe(BUDGETS);
  };

  getContainerStyle = () => {
    let styles = [CommonStyles.floatingButton];
    if (this.props.containerStyle) {
      styles = this.props.containerStyle;
    }
    return styles;
  };

  getIconStyle = () => {
    let styles = [CommonStyles.floatingIcon];
    if (this.props.iconStyle) {
      styles = this.props.iconStyle;
    }
    return styles;
  };

  getTextStyle = () => {
    let styles = [
      CommonStyles.Transparent,
      CommonStyles.customFont,
      CommonStyles.font13,
      CommonStyles.paddingTop5,
      BrandStyles.TextColor1,
    ];
    if (this.props.textStyle) {
      styles = this.props.textStyle;
    }
    return styles;
  };

  render() {
    if (this.props.enableMobileOnly && !this.props.isMobile) {
      return null;
    } else {
      return (
        <View style={CommonStyles.AlignCenter}>
          <StandardTouchableOpacity
            activeOpacity={0.7}
            onPress={this.clickHandler}
            style={this.getContainerStyle()}
          >
            <CustomIcon style={this.getIconStyle()} name={GuideMeImage} />
            <StandardText style={this.getTextStyle()}>
              {this.props.displayText}
            </StandardText>
          </StandardTouchableOpacity>
        </View>
      );
    }
  }
}

const mapStateToProps = (state: any) => ({
  guideMe: state.GuideMeReducer,
});
const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    TabBarActions: bindActionCreators(TabBarActions, dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FloatingActionButton);
