/*
 * Author: Brad D'Costa
 * Date: 14/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Functions used to determine where to route to on successful login
 */

import {getUser} from '../../API/UserAPI';
import {getMember} from '../../API/MemberAPI';
import {getParticipants} from '../../API/PersonAPI';
import {getFeatureFlags} from '../../API/AdminAPI';
import {callAPIs} from '../../API/APICaller';
import logger from 'helpers/Logger';
import {IS_APP_LOADED_FROM_PLAY_STORE, isWeb} from '../../Constants/Constants';
import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

/*
This function will determine which screen the user is routed to on successful authentication/login
* @param {string} cognitoId the cognitoId of the logged in user that will be passed to the getUser function
* @returns {object} callback the object that will be used by navigate library
*/
export const routeTolanding = (
  cognitoId: any,
  callback: any,
  getMemberData: any,
  isDemoProfile = false,
  isCrewSignUp = false,
) => {
  const getUserCallbackFunction = (data: any) => {
    const userObj = data[0].content;
    const featureFlags = data[1].content;
    let returnObj = {routeName: '', params: {userObj, tabbarFlag: ''}};
    let isAppLoadedFromPlaySore;
    if(typeof sessionStorage !== 'undefined') {
      isAppLoadedFromPlaySore =
      (sessionStorage.getItem(
        IS_APP_LOADED_FROM_PLAY_STORE,
      ) as unknown as boolean) ?? false;
    sessionStorage.removeItem(IS_APP_LOADED_FROM_PLAY_STORE);
    }
    if (!userObj.typeConfirmed) {
      returnObj = {routeName: 'ChooseParticipantOrCrew', params: {userObj}};
    } else if (userObj.isCrew && isCrewSignUp) {
      returnObj = {
        routeName: 'CrewCreateMember',
        params: {userObj, tabbarFlag: '1B'},
      };
    } else if (userObj.isEmployee || userObj.isCrew) {
      let tabsToAccess = [];
      if (userObj.isFinancialStaff && userObj.roleAccess.length > 0) {
        tabsToAccess = userObj.roleAccess;
      }
      returnObj = {
        routeName: isWeb
          ? isAppLoadedFromPlaySore
            ? 'Settings'
            : 'Home'
          : 'TabRoot',
        params: {userObj, tabbarFlag: '1B', tabsToAccess: tabsToAccess},
      };
    } else if (userObj.isMember) {
      // Navigate to the MyProfile page & set it to be the root of the stack so the back button will then close the app.
      returnObj = {
        routeName: isWeb
          ? isAppLoadedFromPlaySore
            ? 'Settings'
            : 'Home'
          : 'TabRoot',
        params: {userObj, tabbarFlag: '1A'},
      };
    }

    let loadedMemberId: any = null;
    const isEmployee = userObj.isEmployee;
    if (userObj.managedMemberId !== undefined) {
      loadedMemberId = userObj.managedMemberId;
    } else {
      loadedMemberId = userObj.user.id;
    }

    if (getMemberData && userObj.isMember && !userObj.isCrew) {
      const callbackFunction = (results: any) => {
        // Get the data
        const payload = results[0].content;
        const memberData = {
          payload,
          loadedMemberId,
          isEmployee,
        };
        callback(returnObj, memberData, null, featureFlags);
      };

      callAPIs(
        [getMember(loadedMemberId, isDemoProfile)],
        callbackFunction,
        null,
      );
    } else {
      if (types.isWeb && userObj.isCrew && !isCrewSignUp) {
        const callbackFunction = (results: any) => {
          // Get the data
          const participants = results[0].content.participants;

          callback(returnObj, null, participants.length, featureFlags);
        };

        callAPIs(
          [getParticipants(userObj.user.id, null, null, null, isDemoProfile)],
          callbackFunction,
          null,
        );
      } else {
        callback(returnObj, null, null, featureFlags);
      }
    }
  };

  const getUserErrorCallback = (error: any) => {
    logger.log('Received an error', error);
    callback(error);
  };
  callAPIs(
    [getUser(cognitoId, isDemoProfile, false), getFeatureFlags(isDemoProfile)],
    getUserCallbackFunction,
    getUserErrorCallback,
  );
};
