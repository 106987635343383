/*
 * Author: Brad D'Costa
 * Date: 03/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Summary tile on the HealthWelbeing screen for the To Improve Section
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View, Text} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {MESSAGES} from '../../Constants/Messages';

import {StandardText} from '../Atoms';
import {SummaryTile} from '../Molecules';

import {ThumbsDownPurple3x} from '../../assets/images/vector-icons';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class HealthWellbeingToImprove extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <SummaryTile
        headerDisplayString={
          MESSAGES.HEALTH_WELLBEING_SCREEN.TO_IMPROVE_TILE_TITLE
        }
        headerIconImage={ThumbsDownPurple3x}
        modal={this.props.form}
        containsData={this._determineContainsData()}
        actionOverride={this._determineAccess()}
        HeaderTextColor={BrandStyles.TextColor4}
        HeaderBoarderColor={BrandStyles.borderColor4}
      >
        {this._renderToImprove()}
      </SummaryTile>
    );
  }

  _determineAccess = () => {
    const readOnly = this.props.readOnly;
    if (Object.getOwnPropertyNames(readOnly).length === 0) {
      return null;
    } else if (
      readOnly.access &&
      readOnly.access.profile &&
      readOnly.access.profile === types.ACCESS_READ_ONLY
    ) {
      return types.ACTION_BUTTON.VIEW;
    }
    return null;
  };

  _determineContainsData = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return false;
    } else {
      const checkArray = [
        this.props.reducer.healthConcerns.description,
        this.props.reducer.healthConcerns.difficulty,
        this.props.reducer.healthConcerns.support,
      ];
      let dataCheck = false;
      for (let i = 0; i < checkArray.length; i++) {
        if (checkArray[i] !== '') {
          dataCheck = true;
          break;
        }
      }
      return dataCheck;
    }
  };

  _renderToImprove = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      if (
        this._renderDescriptionSummary() == null &&
        this._renderDifficultySummary() == null &&
        this._renderSupportSummary() == null
      ) {
        return null;
      } else {
        return (
          <View accessible={true}>
            {this._renderDescriptionSummary()}
            {this._renderDifficultySummary()}
            {this._renderSupportSummary()}
          </View>
        );
      }
    }
  };

  _renderDescriptionSummary = () => {
    if (this.props.reducer.healthConcerns.description == '') {
      return null;
    } else {
      return (
        <Text
          style={[
            CommonStyles.SummaryTileText,
            BrandStyles.TextColor5,
            CommonStyles.customFont,
          ]}
        >
          {this.props.reducer.healthConcerns.description}
        </Text>
      );
    }
  };

  _renderDifficultySummary = () => {
    if (this.props.reducer.healthConcerns.difficulty == '') {
      return null;
    } else {
      return (
        <Text
          style={[
            CommonStyles.SummaryTileText,
            BrandStyles.TextColor5,
            CommonStyles.customFont,
          ]}
        >
          {this.props.reducer.healthConcerns.difficulty}
        </Text>
      );
    }
  };

  _renderSupportSummary = () => {
    if (this.props.reducer.healthConcerns.support == '') {
      return null;
    } else {
      return (
        <Text
          style={[
            CommonStyles.SummaryTileText,
            BrandStyles.TextColor5,
            CommonStyles.customFont,
          ]}
        >
          {this.props.reducer.healthConcerns.support}
        </Text>
      );
    }
  };
}

export default HealthWellbeingToImprove;
