/*
 * Author: Roshan Piumal
 * Date: 15/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Navigation actions for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const determineShowConfirmNavigation = (payload: any) => ({
  type: types.DETERMINE_SHOW_CONFIRM_NAVIGATION,
  payload,
});

export const showConfirmNavigation = (payload: any) => ({
  type: types.SHOW_CONFIRM_NAVIGATION,
  payload,
});

export const hideConfirmNavigation = () => ({
  type: types.HIDE_CONFIRM_NAVIGATION,
});
