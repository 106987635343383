/**
 * Author: Anuja Ulpathakumbura
 * Date: 31/10/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is the component for Star Rating
 */

import React, {Component} from 'react';
import {View, Image} from 'react-native';
import StarRating from '../Atoms/StarRating';
import {StandardText, StandardButton, StandardInput} from '../Atoms';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {ActionButton, BrandActivityIndicator} from './';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import {
  addIcon,
  favouriteFutureCharcoal,
} from '../../assets/images/vector-icons';
import {EmptyStar, FilledOutStar} from '../../assets/images';
import {
  postRating,
  putRating,
  createRatingObject,
  createRatingUpdateObject,
} from '../../API/StarRatingAPI';
import {callAPIs} from '../../API/APICaller';
import logger from 'helpers/Logger';

class StandardStarRating extends Component {
  state = {
    loading: false,
    starCount: 0,
    expandForm: false,
    actionButton: true,
    ratingComment: '',
    ratingDisabled: true,
  };

  constructor(props: any) {
    super(props);
    this._expandFormContent.bind(this);
    this._cancelAction.bind(this);
    this._submitRatingAction.bind(this);
  }

  componentDidMount() {
    this.setState({
      starCount: this.props.ratingValue,
      ratingComment: this.props.ratingComment,
    });
  }

  componentDidUpdate(prevProps: any) {
    if (
      this.props.ratingValue !== prevProps.ratingValue ||
      this.props.ratingComment !== prevProps.ratingComment
    ) {
      this.setState({
        starCount: this.props.ratingValue,
        ratingComment: this.props.ratingComment,
      });
    }
  }

  _onStarRatingPress = (rating: any) => {
    let toggle = 0;
    if (this.state.starCount !== rating) {
      toggle = rating;
    }

    this.setState({
      starCount: toggle,
    });
  };

  _renderSaveStyle = () => {
    if (this.props.saveStyle !== types2.UNDEFINED) {
      return this.props.saveStyle.style;
    } else {
      return [
        BrandStyles.brandBlockTxtColor5,
        CommonStyles.buttonFormAction,
        CommonStyles.customFontBold,
      ];
    }
  };

  _renderSaveContainerStyle = () => {
    if (this.props.saveStyle !== types2.UNDEFINED) {
      return this.props.saveStyle.containerStyle;
    } else {
      return [
        BrandStyles.primaryBgColor4,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerFormAction,
      ];
    }
  };

  _renderCloseStyle = () => {
    if (this.props.closeStyle !== types2.UNDEFINED) {
      return this.props.closeStyle.style;
    } else {
      return [
        BrandStyles.brandBlockTxtColor6,
        CommonStyles.buttonFormAction,
        CommonStyles.customFontBold,
      ];
    }
  };

  _renderCloseContainerStyle = () => {
    if (this.props.closeStyle !== types2.UNDEFINED) {
      return this.props.closeStyle.containerStyle;
    } else {
      return [
        BrandStyles.primaryBgColor1,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerFormAction,
      ];
    }
  };

  _showCommentBox = () => {
    return (
      <View>
        <StandardInput
          //readOnly={this.props.member.access.profile}
          multiline={true}
          value={this.state.ratingComment}
          onChangeText={(text: any) => this.setState({ratingComment: text})}
          underlineColorAndroid="transparent"
          style={[
            CommonStyles.ModalTextInputWrapperAutoHeight,
            BrandStyles.primaryBgColor1,
            CommonStyles.textAlignVerticalTop,
          ]}
          blurOnSubmit={false}
          minHeight={350}
          placeholder={types2.STAR_RATING.COMMENTS_PLACEHOLDER}
        />
      </View>
    );
  };

  _renderCommentText = () => {
    return (
      <View>
        <StandardText>{this.state.ratingComment}</StandardText>
      </View>
    );
  };

  _showActionButtons = () => {
    const saveText = types2.STAR_RATING.SAVE;
    const cancelText = types2.STAR_RATING.CANCEL;
    return (
      <View
        style={[
          CommonStyles.marginBottom20,
          CommonStyles.marginTop10,
          CommonStyles.flex1,
          CommonStyles.flexRow,
          CommonStyles.justifyContentSpaceBetween,
        ]}
      >
        <StandardButton
          onPress={this._cancelAction}
          style={this._renderCloseStyle()}
          containerStyle={this._renderCloseContainerStyle()}
          accessibilityLabel={types2.CANCEL}
        >
          {cancelText}
        </StandardButton>
        <StandardButton
          onPress={this._submitRatingAction}
          style={this._renderSaveStyle()}
          containerStyle={this._renderSaveContainerStyle()}
          accessibilityLabel={saveText}
        >
          {saveText}
        </StandardButton>
      </View>
    );
  };

  _expandFormContent = () => {
    this.setState({
      expandForm: true,
      actionButton: false,
      ratingDisabled: false,
      tempRating: this.state.starCount,
      tempComment: this.state.ratingComment,
    });
  };

  _renderActionButton = () => {
    const actionType = types.ACTION_BUTTON.EDIT;
    return (
      <View>
        <ActionButton
          actionType={actionType}
          isToucable={true}
          image={addIcon}
          showIconString={false}
          onPress={this._expandFormContent}
        />
      </View>
    );
  };

  _cancelAction = () => {
    this.setState({
      expandForm: false,
      actionButton: true,
      ratingDisabled: true,
      starCount: this.state.tempRating,
      ratingComment: this.state.tempComment,
    });
  };

  _submitRatingAction = () => {
    if (types.isWeb) {
      this.props.setLoading(true);
    }
    this.setState({loading: true});
    const id = this.props.ratingId;
    const type = this.props.type;
    const ratingValue = this.state.starCount;
    const comment = this.state.ratingComment;
    const providerKey = this.props.providerKey;
    const providerName = this.props.providerName;
    const itemKey = this.props.itemKey;
    const itemNumber = this.props.itemNumber;
    const ratingObject = createRatingObject(
      type,
      ratingValue,
      comment,
      providerKey,
      providerName,
      itemKey,
      itemNumber,
    );

    const ratingUpdateObject = createRatingUpdateObject(
      id,
      ratingValue,
      comment,
    );
    const ratingCallBack = () => {
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        expandForm: false,
        actionButton: true,
        ratingDisabled: true,
        loading: false,
      });
    };

    const ratingErrorCallBack = (err: any) => {
      if (err.code !== undefined) {
        logger.log('Rating error: ', err);
      }
      if (types.isWeb) {
        this.props.setLoading(false);
      }
      this.setState({
        expandForm: false,
        actionButton: true,
        ratingDisabled: true,
        loading: false,
      });
    };
    if (this.props.ratingComment || this.props.ratingValue) {
      callAPIs(
        [putRating(ratingUpdateObject, false)],
        ratingCallBack,
        ratingErrorCallBack,
      );
    } else {
      callAPIs(
        [postRating(ratingObject, false)],
        ratingCallBack,
        ratingErrorCallBack,
      );
    }
  };

  render() {
    if (this.props.showRating === false) {
      return null;
    }
    const actionText =
      this.state.starCount === 0 || !this.state.starCount
        ? types2.STAR_RATING.NOT_RATED_YET
        : types2.STAR_RATING.YOUR_RATING;
    return (
      <View style={[CommonStyles.marginBottom10]}>
        <View
          style={[
            CommonStyles.flex1,
            CommonStyles.flexDirectionRow,
            CommonStyles.justifyContentSpaceBetween,
            CommonStyles.marginBottom10,
          ]}
        >
          <View
            style={[CommonStyles.flex03, CommonStyles.justifyContentCentre]}
          >
            <StandardText
              style={[
                CommonStyles.customFontSemiBold,
                CommonStyles.font18,
                BrandStyles.TextColor5,
              ]}
            >
              {actionText}
            </StandardText>
          </View>
          <View style={[CommonStyles.flex05]}>
            <StarRating
              maxStars={5}
              rating={this.state.starCount}
              disabled={this.state.ratingDisabled}
              onStarClick={(rating: any) => this._onStarRatingPress(rating)}
            />
          </View>
          <View
            style={[
              CommonStyles.justifyContentCentre,
              CommonStyles.alignItemsFlexEnd,
              CommonStyles.flex01,
              CommonStyles.paddingBottom10,
            ]}
          >
            {this.state.actionButton ? this._renderActionButton() : null}
          </View>
        </View>
        {this.state.expandForm ? null : this._renderCommentText()}
        {this.state.expandForm ? this._showCommentBox() : null}
        {this.state.expandForm ? this._showActionButtons() : null}
        <View
          style={[CommonStyles.starRatingDevider, CommonStyles.paddingBottom5p]}
        />
        <BrandActivityIndicator loading={this.state.loading} />
      </View>
    );
  }
}

export default StandardStarRating;
