/*
 * Author: Lahiru Kurukulasuriya
 * Date: 04/09/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Plan ready summary view page
 */

import React, {Component} from 'react';
import {View, ScrollView, AppState, Dimensions, Platform} from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
//import {WebView as WebViewMobile} from 'react-native-webview';
import {WebViewWeb as WebView} from 'helpers/ReactNativeWebview';
import logger from 'helpers/Logger';
import {
  Container,
  ActionButton,
  EditIconComponent,
} from '../Components/Molecules';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import CommonStyles from '../Styles/CommonStyles';
import {downloadIconCharcoal60} from '../assets/images';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import RenderHtml from 'react-native-render-html';

const DOWNLOAD_AS_PDF = 'Download as PDF';
const DOWNLOAD = 'download';
class PlanSummaryPdfViewer extends Component {
  state = {
    loading: true,
    isConnected: true,
    htmlContent: '',
  };
  subscription: any;

  constructor(props: any) {
    super(props);
    this._createMainContents.bind(this);
    this.handleConnectivityChange.bind(this);
  }

  handleConnectivityChange = (isConnected: any) => {
    this.setState({isConnected});
  };

  _handleAppStateChange = (nextAppState: any) => {
    if (nextAppState) {
      NetInfo.fetch().then(isConnected => {
        this.setState({isConnected: isConnected});
      });
    }
  };

  UNSAFE_componentWillMount() {
    
    if (
      this.props.navigationParams.params &&
      this.props.navigationParams.params.html
    ) {
      this.setState({
        htmlContent: this.props.navigationParams.params.html,
      });
    } else {
      this.props.navigation.navigate('PlanMeeting');
    }
  }

  componentDidMount() {
    NetInfo.addEventListener(this.handleConnectivityChange);

    this.subscription = AppState.addEventListener(
      'change',
      this._handleAppStateChange,
    );

    this.setState({
      loading: false,
    });
  }

  componentWillUnmount() {
    // AppState.removeEventListener('change', this._handleAppStateChange);
    this.subscription.remove();
  }

  _createMainContents = () => {
    //let WebView = WebViewMobile;
    
    return (
      <View
        style={[
          CommonStyles.screenWrapper,
          types.isWeb ? {height: Dimensions.get('window').height * 0.9} : null,
        ]}>
        <View>{types.isWeb ? this._renderDownloadButton() : null}</View>

        <WebView
          title="PlanSummaryPdfViewer"
          srcDoc={true}
          source={this.state.htmlContent}
          javaScriptEnabled={false}
          domStorageEnabled={true}
        />
      </View>
    );
  };

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={false}
        screenType={types.SCREEN_TYPE_MAIN}
        activeScreen={types.SCREEN_PLAN_SUMMARY}
        selectedIcon={types.SCREEN_PLAN_SUMMARY}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.PLAN_MEETING}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _renderDownloadButton = () => {
    if (types.isWeb) {
      return (
        <View
          style={[
            CommonStyles.flex1,
            CommonStyles.flexRow,
            CommonStyles.justifyContentFlexEnd,
          ]}>
          <View style={CommonStyles.marginBottom10}>
            <EditIconComponent
              editIconImage={downloadIconCharcoal60}
              iconText={DOWNLOAD_AS_PDF}
              iconPressCallback={() => {
                this._downloadIconCallBack(DOWNLOAD);
              }}
              editDoc={DOWNLOAD}
            />
          </View>
        </View>
      );
    }
  };

  _downloadIconCallBack = (type: any) => {
    if (
      this.props.navigationParams.params &&
      this.props.navigationParams.params.downloadCallBack
    ) {
      this.props.navigationParams.params.downloadCallBack(type);
    }
  };
}

const mapStateToProps = (state: any) => ({
  navigationParams: state.NavigationParamsReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanSummaryPdfViewer);
