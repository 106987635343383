/*
 * Author: Declan Hart
 * Date: 16/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Display Support details
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import {StandardText, StandardInput} from './';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

class Cost extends Component {
  constructor(props: any) {
    super(props);
    this.handleTextSubmit = this.handleTextSubmit.bind(this);
    this.getCost = this.getCost.bind(this);
  }

  handleTextSubmit(cost: any) {
    // Strip the currency symbol

    if (!types.isWeb) {
      cost = cost.substring(1);
    } else {
      cost = cost.replace(/[^0-9.]/g, '');
      if (!cost) {
        cost = null;
      }
    }

    this.props.save(types.COST, null, cost, null, null);
  }

  getCost() {
    if (this.props.selectedValues) {
      return this.props.selectedValues.map((cost: any) => cost.cost).toString();
    }
    return '0';
  }

  render() {
    return (
      <View style={[CommonStyles.spaceAround, CommonStyles.flex]}>
        <View style={[CommonStyles.marginBottom5]}>
          <StandardText
            style={[
              CommonStyles.title_SupportQuestions,
              CommonStyles.customFontBold,
            ]}
          >
            {this.props.year
              ? types2.TOTAL_COST_PER_YEAR_QUESTION
              : types2.COST_PER_VISIT_QUESTION}
          </StandardText>
        </View>
        <View style={CommonStyles.costInputWrapper}>
          <StandardInput
            readOnly={this.props.readOnly}
            keyboardType={types.isWeb ? null : 'numeric'}
            accessibilityLabel="Total cost per year"
            key="total_cost"
            value={`$${this.getCost()}`}
            onChangeText={(cost: any) => this.handleTextSubmit(cost)}
            border={BrandStyles.borderColor4}
            blurOnSubmit
            applyWrapper={false}
            underlineColorAndroid="transparent"
            style={[
              CommonStyles.costInputField,
              CommonStyles.textInputWrapper_Support,
            ]}
            errorMessage={this.props.costError}
          />
        </View>
      </View>
    );
  }
}

export default Cost;
