/*
 * Author: Brad D'Costa
 * Date: 09/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Loaded Member Id actions for redux
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export const actionGetLoadedMemberId = (loadedMemberId: any) => ({
  type: types.GET_LOADED_MEMBER_ID,
  loadedMemberId,
});

export const actionUpdateLoadedMemberId = (loadedMemberId: any) => ({
  type: types.UPDATE_LOADED_MEMBER_ID,
  loadedMemberId,
});

export const actionNewlyAddedMemberId = (newlyAddedMemberId: any) => ({
  type: types.NEWLY_ADDED_MEMBER_ID,
  newlyAddedMemberId,
});

export const setInitialState = () => ({
  type: types.RESET,
});
