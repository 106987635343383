import React, {Component} from 'react';
import {View} from 'react-native';
import {GridView, FormTitle, RequiredIndicator} from './';
import {StandardInput} from '../Atoms';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {ADD_CREW as crewMessages} from '../../Constants/Messages';
import {isWeb} from '../../Constants/Constants';
import { DOMPurify } from 'helpers/dompurify';
const INPUTS = {
  PHONE_NUMBER: 'phoneNumber',
  EMAIL: 'email',
};

class CrewLastName extends Component {
  inputs: any;
  constructor() {
    super();
    this.inputs = {};
  }

  render() {
    return (
      <View
        style={[
          CommonStyles.marginTop10,
          isWeb ? CommonStyles.marginRight10 : {},
        ]}
      >
        <FormTitle
          containsData={this.props.lastName}
          text={crewMessages.PROFILE.LAST_NAME}
          style={[CommonStyles.customFontBold, CommonStyles.AboutMeModalLabel]}
        />
        <View style={[CommonStyles.rightAboutMeModalContentContainer]}>
          <RequiredIndicator />
          <StandardInput
            readOnly={this.props.readOnly}
            value={this.props.lastName}
            accessibilityLabel="Last Name"
            onRef={(ref: any) => {
              this.props.inputs[this.props.currentInputRef] = ref;
            }}
            onSubmitEditing={() => {
              this.props.focusNextField(
                this.props.inputs,
                this.props.nextInputRef,
              );
            }}
            returnKeyType={'next'}
            onChangeText={(lastName: any) =>{ 
              const sanitizedLastName = DOMPurify.sanitize(lastName);

              this.props.editLastName(sanitizedLastName)}}
            blurOnSubmit={false}
            style={[
              CommonStyles.ModalTextInputWrapper,
              BrandStyles.primaryBgColor1,
              CommonStyles.customFont,
            ]}
            underlineColorAndroid="transparent"
            errorMessage={this.props.validLastName}
          />
        </View>
      </View>
    );
  }
}

export default CrewLastName;
