/*
 * Author: Brad D'Costa
 * Date: 03/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Summary tile on the HealthWelbeing screen for the Going Well Section
 */

import React, {Component} from 'react';
import {StyleSheet, Image, View, Text} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import {MESSAGES} from '../../Constants/Messages';

import {StandardText} from '../Atoms';
import {SummaryTile} from '../Molecules';

import {ThumbsUpPurple3x} from '../../assets/images/vector-icons';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class HealthWellbeingGoingWell extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <SummaryTile
        headerDisplayString={
          MESSAGES.HEALTH_WELLBEING_SCREEN.GOING_WELL_TILE_TITLE
        }
        headerIconImage={ThumbsUpPurple3x}
        modal={this.props.form}
        containsData={this._determineContainsData()}
        actionOverride={this._determineAccess()}
        HeaderTextColor={BrandStyles.TextColor4}
        HeaderBoarderColor={BrandStyles.borderColor4}
      >
        {this._renderGoingWell()}
      </SummaryTile>
    );
  }

  _determineAccess = () => {
    if (Object.getOwnPropertyNames(this.props.readOnly).length === 0) {
      return null;
    } else {
      let readOnly = this.props.readOnly;
      if (readOnly.access) {
        if (readOnly.access.profile) {
          if (readOnly.access.profile == types.ACCESS_READ_ONLY) {
            return types.ACTION_BUTTON.VIEW;
          }
        }
      }
    }
    return null;
  };

  _determineContainsData = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return false;
    } else {
      var checkArray = [this.props.reducer.ongoingHealth.description];
      var dataCheck = false;
      for (var i = 0; i < checkArray.length; i++) {
        if (checkArray[i] !== '') {
          dataCheck = true;
          break;
        }
      }
      return dataCheck;
    }
  };

  _renderGoingWell = () => {
    if (Object.getOwnPropertyNames(this.props.reducer).length === 0) {
      return null;
    } else {
      return (
        <View>
          <Text
            style={[
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
            ]}
          >
            {this.props.reducer.ongoingHealth.description}
          </Text>
        </View>
      );
    }
  };
}

export default HealthWellbeingGoingWell;
