/*
 * Author: Declan Hart
 * Date: 11/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Support Category Selector component, displays the categories for supports to filter the support items.
 */

import React, {Component} from 'react';
import {
  Text,
  View,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  Image,
} from 'react-native';

import {editIcon} from '../../assets/images';

import {TitleBox, SupportTile} from '../Molecules';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class SupportCategorySelector extends Component {
  state = {};

  constructor(props: any) {
    super(props);
  }

  render() {
    let isSelected = this.props.selected === this.props.Category.categoryId;
    let icon = isSelected
      ? this.props.Category.selectedIcon.url
      : this.props.Category.deselectedIcon.url;
    return (
      <TouchableOpacity
        onPress={() => this.props.onPress('', this.props.Category.categoryId)}
        style={CommonStyles.supportCategorySelectorContainer}
      >
        <TitleBox
          selected={isSelected}
          displayString={this.props.Category.description}
          imageSource={{uri: icon}}
        />
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
  },
});

export default SupportCategorySelector;
