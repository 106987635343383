/*
 * Author: Shelan Cooray
 * Date: 11/12/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Component for display single goal progress comment details
 */

import React, {Component} from 'react';
import {Image, View} from 'react-native';
import {isWeb} from '../../Constants/Constants';
import {
  GOAL_SCREEN_LABELS,
  GOAL_PROGRESS_TYPES,
  DATE_FORMATS,
} from '../../Constants/Constants2';
import {StandardText} from '../Atoms';

import {
  SupportsChangeSelected,
  SupportsKeepSelected,
  SupportsStopSelected,
} from '../../assets/images';

import {formatDDMMMYYYYDate} from '../../Helpers/Utils';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

class GoalProgressCommentTile extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <View
        accessibilityLabel={GOAL_SCREEN_LABELS.GOAL_PROGRESS}
        style={[
          CommonStyles.containerGoalProgressTile,
          BrandStyles.primaryBgColor1,
        ]}
      >
        <View style={CommonStyles.flexDirectionRow}>
          <View style={CommonStyles.oneContainer}>
            <View style={CommonStyles.flexDirectionRow}>
              {this._renderProgressImage()}
              {this._renderLoggedDate()}
            </View>
            <View style={[CommonStyles.paddingTop15]}>
              {this._renderProgressComment()}
            </View>
          </View>
        </View>
      </View>
    );
  }

  _renderProgressImage = () => {
    let progressImage = SupportsKeepSelected;
    let progressText = '';

    const progressItem = this.props.progressItem;
    if (progressItem && progressItem.progress) {
      switch (progressItem.progress) {
        case GOAL_PROGRESS_TYPES.GREAT.KEY:
          progressImage = SupportsKeepSelected;
          progressText = GOAL_PROGRESS_TYPES.GREAT.VALUE;
          break;
        case GOAL_PROGRESS_TYPES.NOT_GREAT.KEY:
          progressImage = SupportsStopSelected;
          progressText = GOAL_PROGRESS_TYPES.NOT_GREAT.VALUE;
          break;
        case GOAL_PROGRESS_TYPES.OK.KEY:
          progressImage = SupportsChangeSelected;
          progressText = GOAL_PROGRESS_TYPES.OK.VALUE;
          break;
        default:
          break;
      }

      return (
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.alignItemsFlexEnd,
          ]}
        >
          <Image
            style={[CommonStyles.titleBoxIcon, CommonStyles.marginRight5]}
            source={progressImage}
          />
          <StandardText
            style={[
              isWeb ? CommonStyles.font15 : CommonStyles.font18,
              BrandStyles.TextColor5,
              CommonStyles.customFont,
            ]}
          >
            {progressText}
          </StandardText>
        </View>
      );
    } else {
      return null;
    }
  };

  _renderProgressComment = () => {
    const progressItem = this.props.progressItem;
    if (progressItem && progressItem.progressComment) {
      return (
        <StandardText
          style={[
            isWeb ? CommonStyles.font15 : CommonStyles.font18,
            BrandStyles.TextColor5,
            CommonStyles.customFont,
            CommonStyles.marginBottom5,
          ]}
        >
          {progressItem.progressComment}
        </StandardText>
      );
    } else {
      return null;
    }
  };

  _renderLoggedDate = () => {
    const progressItem = this.props.progressItem;

    if (progressItem && progressItem.createdDate) {
      const dateValue = formatDDMMMYYYYDate(
        progressItem.createdDate,
        DATE_FORMATS.TYPE_TWO,
      );
      return (
        <View style={[CommonStyles.justifyFlexRowEnd]}>
          <StandardText
            style={[
              CommonStyles.SummaryTileText,
              BrandStyles.TextColor10,
              CommonStyles.italicFont,
              CommonStyles.customFont,
            ]}
          >
            {GOAL_SCREEN_LABELS.LOGGED + dateValue}
          </StandardText>
        </View>
      );
    } else {
      return null;
    }
  };
}

export default GoalProgressCommentTile;
