/*
 * Author: Declan Hart
 * Date: 24/02/2020
 * Copyright © 2020 Leap in!. All rights reserved.
 *
 * Screen that allows the user to select which type of Demo Profile they would like to explore.
 */

import React, {Component} from 'react';
import {
  Platform,
  StyleSheet,
  View,
  ScrollView,
  Animated,
  Image,
  Dimensions,
  TouchableOpacity,
  BackHandler,
  AppState,
  Alert,
} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// import {NavigationActions} from 'react-navigation-awseeley--enhanced';
// import Orientation from 'react-native-orientation-enhanced';
import logger from 'helpers/Logger';
import DeviceInfo from 'react-native-device-info';
import NetInfo from '@react-native-community/netinfo';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {BrandIcons} from '../Styles/BrandIcons';

import {RachelWang, JohnSampson, SamWatson} from '../assets/images';
import CustomIcon from '../assets/images/CustomIcon';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {
  ERRORS,
  ACCESSIBILITY_SCREEN_TITLES,
  CHOOSE_PARTICIPANT_CREW,
} from '../Constants/Messages';

import {
  DEMO_PASSWORD,
  DEMO_CREATION_URL,
  DEMO_CREATION_API_KEY,
  API_URL,
} from '../environments';

import {StandardText, BackButton} from '../Components/Atoms';
import {
  OptionTile,
  LoginSignUpTitle,
  Container,
  Copyrights,
  ScreenTitle,
} from '../Components/Molecules';
import * as UserActions from '../Actions/UserActions';
import * as AdminActions from '../Actions/AdminActions';
import * as AuthenticationActions from '../Actions/AuthenticationActions';
import * as GuideMeActions from '../Actions/GuideMeActions';
import * as loadedMemberActions from '../Actions/LoadedMemberActions';
import * as MemberActions from '../Actions/MemberActions';
import * as OfflineNoticeActions from '../Actions/OfflineNoticeActions';

import {
  focusOnView,
  announceForAccessibility,
  isTablet,
  getVersion,
  firebaseAnalysisLogEvent,
} from '../Helpers/PlatformSynchronizer';
import AuthenticationUtil from '../Helpers/AuthenticationUtil';
import {getValue, setValue, removeItem} from '../API/WebPersistenceStore';

import {putUser} from '../API/UserAPI';
import {loginCognito} from '../API/AuthenticationAPI';
import {putCognitoIdByEmail} from '../API/PersonAPI';
import {createDemoProfile} from '../API/DemoProfileAPI';
import {callAPIs} from '../API/APICaller';
import {routeTolanding} from '../Components/RouteToLanding/RouteToLanding';
import {BrandActivityIndicator} from '../Components/Molecules/BrandActivityIndicator';
import {
  OfflineNotice,
  DeepLinkBanner,
  HeaderBanner,
} from '../Components/Organisms';

import {APP_BRAND} from '../Constants/BrandConstants';

import {getDataLoggedIn} from '../API/StorageHelper';

import * as env from '../environments';
import {
  DEMO_OPTIONS_COLORS_NDIS_P_1,
  DEMO_OPTIONS_COLORS_NDIS_P_2,
  DEMO_OPTIONS_COLORS_SUPPORT_COO,
} from '../Styles/OnboardingStyles';

const OPTIONS = [
  {
    ...DEMO_OPTIONS_COLORS_NDIS_P_1,
    title: 'Rachel Wang',
    profileId: 1,
    subtitle: {
      portrait: 'NDIS Participant',
      landscape: 'NDIS Participant',
    },
    image: RachelWang, //participant_image
    screen: 'MyProfile',
    removeImagePadding: true,
    addImageBorder: true,
  },
  {
    ...DEMO_OPTIONS_COLORS_NDIS_P_2,
    title: 'John Sampson',
    profileId: 2,
    subtitle: {
      portrait: 'NDIS Participant',
      landscape: 'NDIS Participant',
    },
    image: JohnSampson, //participant_image
    screen: 'ChooseParticipantOrCrew',
    removeImagePadding: true,
    addImageBorder: true,
  },
  {
    ...DEMO_OPTIONS_COLORS_SUPPORT_COO,
    title: 'Sam Watson',
    profileId: 9,
    subtitle: {
      portrait: 'Support Coordinator',
      landscape: 'Support Coordinator',
    },
    image: SamWatson, //crew_image
    screen: 'SignUp',
    removeImagePadding: true,
    addImageBorder: true,
  },
];
// Get the device height & width
let ScreenHeight = Dimensions.get('window').height;
let ScreenWidth = Dimensions.get('window').width;
const LOGIN_SIGNUP = 'LoginSignUp';

// Setup animations
const animationValue = new Animated.Value(0);

// Maps the animation values to start from the top of the screen out of view and finish at the bottom of the screen in view
const animateTopToBottom = animationValue.interpolate({
  inputRange: [0, 1],
  outputRange: [-ScreenHeight, 0.0],
});
// Maps the animation values to start from the bottom of the screen out of view and finish at the top of the screen in view
const animateBottomToTop = animationValue.interpolate({
  inputRange: [0, 1],
  outputRange: [ScreenHeight, 0.0],
});

export class ChooseDemoProfile extends Component {
  state = {
    loading: false,
    orientation: '',
    isConnected: true,
  };
  subscription: any;

  constructor(props: any) {
    super(props);
    this.saveEmailCredentials = this.saveEmailCredentials.bind(this);
    this.saveEmailCredentialsForCreation =
      this.saveEmailCredentialsForCreation.bind(this);
    this.navigateToScreen = this.navigateToScreen.bind(this);
    this.updateState = this.updateState.bind(this);
    this.createDemoProfile = this.createDemoProfile.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (!types.isWeb) {
      firebaseAnalysisLogEvent(
        types.FIREBASE_ANALYTICS_EVENTS.SIGNUP_MEMBER_TYPE_SELECTION,
        {},
      );
    }
    removeItem(types2.WEB_STORE.LOADED_MEMBER_ID);

    NetInfo.addEventListener(this.handleConnectivityChange);
    NetInfo.fetch().then(isConnected => {
      this.setState({isConnected});
    });

    // if (!types.isWeb) {
    //   Orientation.getOrientation((err: any, orientation: any) => {
    //     this.setState({
    //       orientation,
    //     });
    //   });
    // }
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation,
    });
  };

  componentWillUnmount() {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
    this.subscription.remove();
  }

  componentDidMount() {
    if (!types.isWeb) {
      announceForAccessibility(ACCESSIBILITY_SCREEN_TITLES.PARTICIPANT_OR_CREW);
      // Orientation.addOrientationListener(this._orientationDidChange);
      this.dispatchFocusInOrder();
    }
    this.subscription=AppState.addEventListener('change', this._handleAppStateChange);
  }

  dispatchFocusInOrder() {
    setTimeout(
      function (this: any) {
        if (this.skipButton) {
          focusOnView(this.skipButton);
        }
      }.bind(this),
      1000,
    );
  }

  handleConnectivityChange = (isConnected: any) => {
    if (isConnected) {
      this.setState({isConnected});
    }
  };

  _handleAppStateChange = (nextAppState: any) => {
    if (nextAppState) {
      NetInfo.fetch().then(isConnected => {
        this.setState({isConnected});
      });
    }
  };

  render() {
    return (
      <View
        style={[
          CommonStyles.screenWrapperContainer,
          BrandStyles.primaryBgColor1,
        ]}
      >
        <View style={[CommonStyles.flex1, CommonStyles.flexDirectionColumn]}>
          {types.isWeb && <HeaderBanner />}
          <OfflineNotice />
          {types.isWeb && <DeepLinkBanner />}
          <View
            style={[
              CommonStyles.alignItemsCenter,
              CommonStyles.marginBottom10,
              CommonStyles.zIndex102,
              CommonStyles.flexDirectionRow,
            ]}
          >
            <LoginSignUpTitle
              title={types2.SIGNUP_LOGIN_FLOW.DEMO_PROFILE}
              backButton={true}
              nav={this.props.navigation}
              screen={types2.SIGNUP_LOGIN_FLOW.WELCOME}
            />
          </View>
          <ScrollView
            contentContainerStyle={[
              !types.isWeb
                ? CommonStyles.paddingBottom50
                : CommonStyles.paddingBottom15,
              !types.isWeb && CommonStyles.paddingHorizontal10,
              CommonStyles.alignItemsCenter,
              CommonStyles.flex1,
            ]}
            showsVerticalScrollIndicator={false}
            style={[!types.isWeb ? CommonStyles.flex1 : null]}
          >
            {!types.isWeb && (
              <View style={CommonStyles.screenTitleContainer}>
                <ScreenTitle title={types2.SIGNUP_LOGIN_FLOW.DEMO_PROFILE} />
              </View>
            )}
            <View
              style={[
                CommonStyles.flexDirectionColumn,
                types.isWeb && CommonStyles.alignCenter,
                CommonStyles.optionTileWrapper,
              ]}
            >
              {OPTIONS.map(option => this.renderTiles(option))}
            </View>
            <View style={[CommonStyles.rpMarginBottom20p]} />
          </ScrollView>
          {!types.isWeb && (
            <View
              style={[
                CommonStyles.alignCenter,
                BrandStyles.copyrightsBarBgColor,
                CommonStyles.loginButtonWrapper,
              ]}
            >
              <Copyrights />
            </View>
          )}
        </View>
        <BrandActivityIndicator
          enableWeb={true}
          loading={this.state.loading}
          loadingText={'Please wait a moment while we create your demo profile'}
        />
      </View>
    );
  }

  renderTiles = (option: any) => {
    return (
      <OptionTile
        option={option}
        orientation={this.state.orientation}
        onPress={this.navigateToScreen}
        isProfileImage={true}
        withRadius={true}
      />
    );
  };

  async navigateToScreen(option: any) {
    this.setState({loading: true});
    let email = '';
    if (types.isWeb) {
      try {
        let response = await fetch(DEMO_CREATION_URL, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'x-api-key': DEMO_CREATION_API_KEY,
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            Host: API_URL,
          },
          body: JSON.stringify({
            profileId: option.profileId,
          }),
        });
        let responseJson = await response.json();
        email = JSON.parse(responseJson.body).email;
        const auth = new AuthenticationUtil(
          null,
          null,
          email,
          DEMO_PASSWORD,
          null,
        );
        auth.login(
          () => this.saveEmailCredentials(auth),
          (error: any) => {
            this.onSignInFailure(error);
          },
          this.updateState,
          true,
        );
      } catch (error) {
        this.setState({loading: false});
      }
    } else {
      const auth = new AuthenticationUtil(
        null,
        null,
        env.PR_DEMO_EMAIL,
        DEMO_PASSWORD,
        null,
      );
      auth.login(
        () => this.createDemoProfile(auth, option),
        (error: any) => {
          this.onSignInFailure(error);
        },
        this.updateState,
        true,
      );
    }
  }

  updateState(stateObj: any) {
    this.setState(stateObj);
  }

  createDemoProfile(auth: any, option: any) {
    this.saveEmailCredentialsForCreation(auth);
    const callbackFunction = async (response: any) => {
      auth.logout(this.props.actions);
      let email = JSON.parse(response[0].body).email;
      const demoAuth = new AuthenticationUtil(
        null,
        null,
        email,
        DEMO_PASSWORD,
        null,
      );
      demoAuth.login(
        () => this.saveEmailCredentials(demoAuth),
        (error: any) => {
          this.onSignInFailure(error);
        },
        this.updateState,
        true,
      );
    };
    callAPIs(
      [createDemoProfile(option.profileId)],
      callbackFunction,
      null,
      () => {},
    );
  }

  saveEmailCredentials = (auth: any) => {
    let actionToDispatch = auth.saveEmailCredentials(
      this.props.actions,
      this.updateState,
      (actionToDispatch: any) => {
        if (!types.isWeb) {
          this.props.navigation.dispatch(actionToDispatch);
        } else {
          this.props.navigation.navigate(actionToDispatch);
        }
      },
      this.onSignInFailure,
      true,
    );
  };
  saveEmailCredentialsForCreation = (auth: any) => {
    let actionToDispatch = auth.saveEmailCredentials(
      this.props.actions,
      this.updateState,
      (actionToDispatch: any) => {
        return actionToDispatch;
      },
      this.onSignInFailure,
      true,
      true,
      true,
    );
  };
  onSignInFailure = (error: any) => {
    logger.log('Cognito login error:', error);
    this.setState({loading: false});
  };
}

const mapStateToProps = (state: any) => ({
  auth: state.AuthenticationReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    authActions: bindActionCreators(AuthenticationActions, dispatch),
    adminActions: bindActionCreators(AdminActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
    AuthenticationActions: bindActionCreators(AuthenticationActions, dispatch),
    guideMeActions: bindActionCreators(GuideMeActions, dispatch),
    loadedMemberActions: bindActionCreators(loadedMemberActions, dispatch),
    OfflineNoticeActions: bindActionCreators(OfflineNoticeActions, dispatch),
    memberActions: bindActionCreators(MemberActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChooseDemoProfile);
