/*
 * Author: Declan Hart
 * Date: 16/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Display Support details
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import {DaysOfUse, TimesPerYear} from '../Atoms';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

class UnitOfMeasureDay extends Component {
  state = {
    daysOfWeek: types.DAYSOFTHEWEEK,
    selectedValues: [],
  };

  constructor(props: any) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      selectedValues: this.props.values.daysOfUse,
    });
  }

  render() {
    return (
      <View>
        <DaysOfUse
          options={this.state.daysOfWeek}
          multiple={true}
          save={this.props.save}
          selectedValues={this.state.selectedValues}
          readOnly={this.props.readOnly}
        />
        <TimesPerYear
          save={this.props.save}
          timesPerYear={this.props.values.timesPerYear}
          readOnly={this.props.readOnly}
        />
      </View>
    );
  }
}

export default UnitOfMeasureDay;
