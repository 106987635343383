import styled from 'styled-components';
import {Dimensions} from 'react-native';
import {
  THEME_SWITCH_WIDTH,
  WEB_LEFT_NAVIGATION_WIDTH,
} from '../../Constants/Constants2';
import {BrandColors} from '../../Styles/Colours';

const {height, width} = Dimensions.get('window');
const isMobile = width <= THEME_SWITCH_WIDTH;

export const AppContainer = styled.div`
  display: flex;
  height: ${isMobile ? height + 'px' : '100%'};
  width: 100%;
`;

// width here will decide the width of Left hand
// side navigation bar
export const Navigation = styled.div`
  width: ${WEB_LEFT_NAVIGATION_WIDTH}px;
  flex-shrink: 0;
  background: #fff;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.125);
`;

export const Body = styled.div`
  padding: 12px;
  height: 100%;
  width: 100%;
`;

export const BodyMobile = styled.div`
  padding: 12px;
  height: 100%;
  width: 100%;
  padding-bottom: 100px;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
`;

export const ExampleContainer = styled.div`
  background: #fff;
  border: 1px solid #e5e5e5;
  height: calc(100vh - 40px);
`;

export const ExampleNavigation = styled(Navigation)`
  height: 100%;
  margin-right: 4px;
  border: 1px solid rgba(0, 0, 0, 0.125);
`;

export const ExampleBody = styled.div`
  background: #fff;
  padding: 12px;
  border: 1px solid rgba(0, 0, 0, 0.125);
`;
