/*
 * Author: Brad D'Costa
 * Date: 13/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Function to generate the display date taking in a date Obj
 */

export const _generateDisplayDate = (dateObj: any) => {
  const year = dateObj.getFullYear();
  let month = dateObj.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  let day = '';
  if (dateObj.getDate() < 10) {
    day = `0${dateObj.getDate()}`;
  } else {
    day = dateObj.getDate();
  }
  const returnString = `${day}-${month}-${year}`;
  return returnString;
};
