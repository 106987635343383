/*
 * Author: Justin Kilimnik
 * Date: 05/02/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Plan Meeting Screen
 */

import React, {Component} from 'react';
import {View, Alert, Platform} from 'react-native';
import {
  Container,
  CrewNoAccess,
  PlanSummaryActionTile,
} from '../Components/Molecules';
import {OfflineNotice} from '../Components/Organisms';
import {StandardText, StandardTouchableOpacity} from '../Components/Atoms';
import Utf8 from 'utf8';
import Base64 from 'base64-js';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {uniqBy, filter, find, orderBy, forEach, isEmpty} from 'lodash';

// Extensions
import RNPrint from 'react-native-print';
// import Orientation from 'react-native-orientation-enhanced';
import DeviceInfo from 'react-native-device-info';

// Import Stylesheets and Constants
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {BrandIcons} from '../Styles/BrandIcons';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {APP_CHECKLIST_URL} from '../Constants/BrandConstants';
import {
  MESSAGES,
  PLAN_MANAGEMENT_HTML,
  MY_PLAN_SUMMARY_SCREEN,
} from '../Constants/Messages';

import * as MemberActions from '../Actions/MemberActions';
import * as DocumentListActions from '../Actions/DocumentListActions';
import * as GoalActions from '../Actions/GoalActions';
import * as LivingArrangementActions from '../Actions/LivingArrangementActions';
import * as SupportsActions from '../Actions/SupportsActions';
import * as CrewActions from '../Actions/CrewActions';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {callAPIs} from '../API/APICaller';
import {getDocumentList, getMember, generatePDF} from '../API/MemberAPI';
import {getGoalsForPlan, getSupports} from '../API/PlanDataAPI';
import {getLivingArrangement} from '../API/AboutMeAPI';
import {getSupportCategories, getCrewCategories} from '../API/ReferenceAPI';
import {getCrew} from '../API/PersonAPI';
import {getCrewAccessLevels} from '../API/CrewAPI';
import {getScreenTitle} from '../Helpers/Utils';
import logger from 'helpers/Logger';
import {
  UploadRed4x,
  EyeRed4x,
  PrinterRed4x,
} from '../assets/images/vector-icons';
import {aboutMeIconRed} from '../assets/images/vector-icons';
import {imageIconsWeb, imageIconsMobile, imageIconsMobileBase64} from '../assets/images/plan-meeting';

import CustomIcon from '../assets/images/CustomIcon';
import {_generateDisplayDate} from '../Helpers/generateDisplayDate';

import {RNFetchBlobSingleton, RNShare} from '../Helpers/PlatformSynchronizer';
// Constants
const FIRST_RESULT = 0;
const PLAN_SUMMARY_PDF_VIEW = 'PlanSummaryPdfViewer';
const PLACEHOLDER_IMAGE = 'head-silhouette';

class PlanMeeting extends Component {
  _createAllModals: any;
  state = {
    loading: false,
    icons: {},
    images: {},
    saveAPIError: false,
    isOpen: this.props.sideMenu ? this.props.sideMenu.sideMenuIsOpen : false,
    orientation: '',
  };

  constructor(props: any) {
    super(props);
    this._createMainContents.bind(this);
    this._generateStyleSheet.bind(this);
    this._imageToBase64.bind(this);
    this._getImageFromKey.bind(this);
    this._getMemberTelephone.bind(this);
    this._getMemberDateOfBirth.bind(this);
    this._getMemberName.bind(this);
    this._getMemberAddress.bind(this);
    this._getLifeStage.bind(this);
    this._getHealthWellbeingToImprove.bind(this);
    this._getHomeType.bind(this);
    this._getLivingWith.bind(this);
    this._getLivingWithCount.bind(this);
    this._getHomeModifications.bind(this);
    this._generateSupporthtml.bind(this);
    this._generateGoalshtml.bind(this);
    this._generateDocumentsListhtml.bind(this);
    this._mapImageToSupportOpinion.bind(this);
    this._getLivingWithCrew.bind(this);
    this._getCrewNameFromCrewId.bind(this);
    this._getSupportCategoryFromSupportId.bind(this);
    this._toSentenceCase.bind(this);
    this._getDisability.bind(this);
    this._renderFrequencyCalc.bind(this);
    this._calculateHourWeek.bind(this);
    this._calculateUOMEach.bind(this);
    this._buildImagePromiseArray.bind(this);
    this._buildAPIArray.bind(this);
    this._setStyleByDeviceInfo.bind(this);
    this._generatePDF.bind(this);
  }

  // UNSAFE_componentWillMount() {
  //   if (!types.isWeb) {
  //     Orientation.getOrientation((err: any, orientation: any) => {
  //       this.setState({
  //         orientation: orientation,
  //       });
  //     });
  //   }
  // }

  componentWillUnmount() {
    // if (!types.isWeb) {
    //   Orientation.removeOrientationListener(this._orientationDidChange);
    // }
  }

  _orientationDidChange = (orientation: any) => {
    this.setState({
      orientation: orientation,
    });
  };

  UNSAFE_componentWillReceiveProps(props: any) {
    if (!types.isWeb) {
      this.setState({
        isOpen: props.sideMenu ? props.sideMenu.sideMenuIsOpen : false,
      });
    }
  }

  componentDidMount() {
    // When this screen is loaded, the MyProfile and Crew member selection screen will load most of the data
    // This happens when the app is loaded, or the user loades a member profile as a crew
    // However there are API calls on other screen needed for the PDF document generation
    // So check if they have been called, if not call them.

    // The image icons we need to convert to base64 for the pdf

    let imageIcons = types.isWeb ? imageIconsWeb : imageIconsMobileBase64;
  if(types.isWeb){
      const promiseArray = [];
      // Build the array of promises to get the base64 images
      for (let i = 0; i < imageIcons.length; i++) {
        const imagePromise = this._imageToBase64(imageIcons[i]);
        promiseArray.push(imagePromise);
      }

      // Once we have all the images in base 64 then stop loading and store them
      Promise.all(promiseArray)
        .then(values => {
          const updatedStateImages = [];
          for (let j = 0; j < values.length; j++) {
            updatedStateImages.push(values[j]);
          }
          this.setState({loading: false, icons: updatedStateImages});
        })
        .catch(error => {
          this.setState({loading: false});
        });

  } else {
    this.setState({loading: false, icons: imageIcons});
  }
    
    // Once we have all the images in base 64 then stop loading and store them
    // Generate the images, block out the buttons until done as they are needed.

    // if (!types.isWeb) {
    //   Orientation.addOrientationListener(this._orientationDidChange);
    // }
  }

  _setLoading = (loadingState: any) => {
    if (types.isWeb) {
      this.props.setLoading(loadingState);
    } else {
      this.setState({loading: loadingState});
    }
  };

  render() {
    // If we are a crew & there is no loaded member ID lockout the screen
    if (!this.props.member.id && this.props.user.isCrew) {
      return (
        <View style={[CommonStyles.content]}>
          <OfflineNotice />
          <CrewNoAccess />
        </View>
      );
    }

    if (types.isWeb) {
      return this._createMainContents();
    }

    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        modals={this._createAllModals}
        needsSidebar={true}
        hideBackButton={false}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.PLAN_MEETING}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => (
    <View
      style={[CommonStyles.content, BrandStyles.primaryBgColour4]}
      contentContainerStyle={[
        CommonStyles.alignItemsCenter,
        CommonStyles.justifyContentCentre,
        CommonStyles.scrollViewVerticalCentre,
      ]}>
      <View
        style={[
          CommonStyles.mainContentContainer,
          BrandStyles.primaryBgColour1,
        ]}>
        <View style={[CommonStyles.singleMainContentContainer]}>
          <View style={[CommonStyles.SummaryTileContainer]}>
            {this._renderHeader()}
            <View>
              {this._renderActionTile(
                types2.PDF_VIEW_TYPE.VIEW,
                MY_PLAN_SUMMARY_SCREEN.VIEW_TILE_TITLE,
                EyeRed4x,
                MY_PLAN_SUMMARY_SCREEN.VIEW_TILE_DESCRIPTION,
                CommonStyles.PlanSummaryActionTileIconView,
              )}
              {this._renderActionTile(
                types2.PDF_VIEW_TYPE.PRINT,
                MY_PLAN_SUMMARY_SCREEN.PRINT_TILE_TITLE,
                PrinterRed4x,
                MY_PLAN_SUMMARY_SCREEN.PRINT_TILE_DESCRIPTION,
                CommonStyles.PlanSummaryActionTileIconPrint,
              )}
              {types.isWeb
                ? null
                : this._renderActionTile(
                    types2.PDF_VIEW_TYPE.SHARE,
                    MY_PLAN_SUMMARY_SCREEN.SHARE_TILE_TITLE,
                    UploadRed4x,
                    MY_PLAN_SUMMARY_SCREEN.SHARE_TILE_DESCRIPTION,
                    CommonStyles.PlanSummaryActionTileIconShare,
                  )}
            </View>
          </View>
        </View>
      </View>
    </View>
  );

  _renderHeader = () => (
    <View
      style={[
        CommonStyles.justifyContentSpaceBetween,
        BrandStyles.borderColor3,
        CommonStyles.content,
        CommonStyles.SummaryTileHeaderContainer,
        CommonStyles.flexRow,
        CommonStyles.rpMarginBottom10p,
      ]}>
      <CustomIcon
        style={[
          CommonStyles.leapInReadPlanManagedTnCIcon,
          CommonStyles.rpMarginBottom5p,
          CommonStyles.titleBoxIconMargin,
        ]}
        name={aboutMeIconRed}
      />
      <View
        style={[
          CommonStyles.flex1,
          CommonStyles.flexDirectionRow,
          CommonStyles.alignItemsFlexEnd,
          CommonStyles.rpMarginBottom5p,
        ]}>
        <StandardText
          style={[
            types.isWeb ? CommonStyles.font20 : CommonStyles.font25,
            types.isWeb
              ? CommonStyles.customFontSemiBold
              : CommonStyles.customFontMedium,
            BrandStyles.TextColor3,
          ]}>
          {MY_PLAN_SUMMARY_SCREEN.MAIN_TITLE}
        </StandardText>
      </View>
    </View>
  );

  _renderActionTile(
    actionType: any,
    tileTitle: any,
    iconImage: any,
    description: any,
    style: any,
  ) {
    return (
      <PlanSummaryActionTile
        onPressAction={this._generatePDF.bind(this)}
        type={actionType}
        title={tileTitle}
        image={iconImage}
        description={description}
        iconStyle={style}
        isMobile={this.props.isMobile}
      />
    );
  }

  _determinebkgd = () => {
    var bkgdStyle = [
      CommonStyles.alignItemsCenter,
      CommonStyles.screenWrapperHeaderContainer,
    ];
    return bkgdStyle;
  };

  // In order to generate the PDF we need to generate base 64 images from the signed URL's
  // As we cannot embed these links in the PDF as they are temporary links & require AWS signing with a user's login
  _generatePDF(pdfViewType: any) {
    this._setLoading(true);

    const apiCallingData = this._buildAPIArray();

    const downloadCallback = (data: any) => {
      const dataPosition = apiCallingData.dataPosition;

      const documentList = data[dataPosition.documents].content;
      this.props.actions.DocumentListActions.actionGetDocumentList(
        documentList.documents,
      );

      const goalContent = data[dataPosition.goals].content;
      this.props.actions.GoalActions.actionGetGoals(goalContent.currentGoals);

      const livingArrange = data[dataPosition.livingArrange].content;
      this.props.actions.LivingArrangementActions.actionGetLivingArrangement(
        livingArrange,
      );

      // Supports will always be loaded
      const supportContent = data[dataPosition.supportsCategories].content;

      const supports = data[dataPosition.supports].content;
      this.props.actions.SupportsActions.actionGetSupports(supports);

      // Check for the crews
      const personCrewData = data[dataPosition.crew].personCrews.content;
      const organisationCrewData =
        data[dataPosition.crew].organisationCrews.content;
      const crewCategories =
        data[dataPosition.crewCategories].content.categories;
      const crewAccessLevels = data[dataPosition.crewAccessLevels].content;

      this.props.actions.CrewActions.actionGetCrews(
        crewCategories,
        personCrewData,
        organisationCrewData,
        crewAccessLevels,
      );

      // Check for member
      const memberData = data[dataPosition.member].content;
      this.props.actions.MemberActions.actionGetMember(
        memberData,
        this.props.user.id,
        this.props.user.isEmployee,
        this.props.user.isFinancialStaff,
      );

      // Supports need the image fetched
      this.props.actions.SupportsActions.actionGetSupportsCategories(
        supportContent,
      );

      setTimeout(() => {
         //var categories = supportContent.categories;
      var imagePromiseArray = this._buildImagePromiseArray();
      /*
      //Commented this code - since images are in storage, no need to fetch
      // Convert the support category icons to base64
      for (var i = 0; i < categories.length; i++) {
        var category = categories[i];
        var image = { categoryId: category.categoryId };
        image.url = category.deselectedIcon.url;
        var imagePromise = this._imageToBase64(image);
        imagePromiseArray.push(imagePromise);
      } */

      Promise.all(imagePromiseArray)
        .then(values => {
          var updatedStateImages = [];

          // Go through the promise resolves, any that are images push to the array
          for (let i = 0; i < values.length; i++) {
            const currentValue = values[i];
            if (currentValue != undefined) {
              if (Array.isArray(currentValue)) {
                for (var k = 0; k < currentValue.length; k++) {
                  updatedStateImages.push(currentValue[k]);
                }
              } else {
                updatedStateImages.push(values[i]);
              }
            }
          }
          this._setLoading(false);
          // this.setState({images: updatedStateImages});
          // this.createPDF(pdfViewType);
          this.setState({ images: updatedStateImages }, () => {
            this.createPDF(pdfViewType);
           });
        })
        .catch(error => {
          logger.log(error);
          Alert.alert(types2.ERROR, JSON.stringify(error));
          this._setLoading(false);
        });
      }, 1000);

    
    };

    setTimeout(() => {
      callAPIs(apiCallingData.apiArray, downloadCallback, null, () => {
        this._setLoading(false);
      });
    }, 1000);
  }

  _buildImagePromiseArray = () => {
    // Build the array of images we need to convert to base 64
    const imagesToConvert = [];

    // The member images
    const memberImage = JSON.parse(
      JSON.stringify(this.props.member.profileImage),
    );
    memberImage.key = 'memberImage';
    imagesToConvert.push(memberImage);

    // Images of any crews
    for (let i = 0; i < this.props.crews.crewIndividual.length; i++) {
      const crew = this.props.crews.crewIndividual[i];
      const image = {crewId: crew.crewId};
      if (crew.profileImage == undefined) {
        image.url = '';
      } else {
        image.url = crew.profileImage.url;
        image.photoCaption = crew.profileImage.photoCaption
          ? crew.profileImage.photoCaption
          : 'crew profile picture';
      }
      imagesToConvert.push(image);
    }

    // Images of supports
    for (let j = 0; j < this.props?.supports?.supports?.length; j++) {
      const support = this.props.supports.supports[j];
      const image = {currentSupportId: support.currentSupportId};
      if (support.supportPhoto == undefined) {
        image.url = '';
      } else {
        image.url = support.supportPhoto.url;
        image.photoCaption = support.supportPhoto.photoCaption
          ? support.supportPhoto.photoCaption
          : 'support image';
      }
      imagesToConvert.push(image);
    }

    // Images of goals
    for (let k = 0; k < this.props?.goals?.goals?.length - 1; k++) {
      const goal = this.props.goals.goals[k];
      const image = {currentGoalId: goal.currentGoalId};
      if (goal.goalPhoto === undefined || goal?.goalPhoto?.url === undefined) {
        image.url = '';
      } else {
        image.url = goal.goalPhoto.url;
        image.photoCaption = goal.goalPhoto.photoCaption
          ? goal.goalPhoto.photoCaption
          : 'goal image';
      }
      imagesToConvert.push(image);
    }

    // Build the promise array to load the images
    const promiseArray = [];
    for (let l = 0; l < imagesToConvert.length; l++) {
      const imagePromise = this._imageToBase64(imagesToConvert[l]);
      promiseArray.push(imagePromise);
    }

    return promiseArray;
  };

  _setStyleByDeviceInfo = (
    tabPortrait: any,
    tabHorizontal: any,
    mobile: any,
  ) => {
    return DeviceInfo.isTablet()
      ? !this.state.isOpen &&
        this.state.orientation === types2.ORIENTATION.PORTRAIT
        ? tabPortrait
        : tabHorizontal
      : mobile;
  };

  _buildAPIArray = () => {
    let dataPosition = {};

    let apiArray = [];

    apiArray.push(
      getDocumentList(
        this.props.loadedMemberId.loadedMemberId,
        this.props.user.demoProfile,
      ),
    );
    dataPosition.documents = FIRST_RESULT;

    apiArray.push(
      getGoalsForPlan(
        this.props.member.planId,
        this.props.member.id,
        this.props.user.demoProfile,
      ),
    );
    dataPosition.goals =
      apiArray.length > 0 ? apiArray.length - 1 : FIRST_RESULT;

    apiArray.push(
      getLivingArrangement(
        this.props.loadedMemberId.loadedMemberId,
        this.props.user.demoProfile,
      ),
    );
    dataPosition.livingArrange =
      apiArray.length > 0 ? apiArray.length - 1 : FIRST_RESULT;

    // Add supports
    apiArray.push(getSupportCategories());
    dataPosition.supportsCategories =
      apiArray.length > 0 ? apiArray.length - 1 : FIRST_RESULT;

    // Add crew
    apiArray.push(
      getCrew(
        this.props.loadedMemberId.loadedMemberId,
        this.props.user.demoProfile,
      ),
    );
    dataPosition.crew =
      apiArray.length > 0 ? apiArray.length - 1 : FIRST_RESULT;

    apiArray.push(
      getCrewCategories(
        this.props.loadedMemberId.loadedMemberId,
        this.props.user.demoProfile,
      ),
    );
    dataPosition.crewCategories =
      apiArray.length > 0 ? apiArray.length - 1 : FIRST_RESULT;

    apiArray.push(
      getCrewAccessLevels(
        this.props.loadedMemberId.loadedMemberId,
        this.props.user.demoProfile,
      ),
    );
    dataPosition.crewAccessLevels =
      apiArray.length > 0 ? apiArray.length - 1 : FIRST_RESULT;

    // Add member profile
    apiArray.push(
      getMember(
        this.props.loadedMemberId.loadedMemberId,
        this.props.user.demoProfile,
      ),
    );
    dataPosition.member =
      apiArray.length > 0 ? apiArray.length - 1 : FIRST_RESULT;

    apiArray.push(
      getSupports(
        this.props.member.planId,
        this.props.loadedMemberId.loadedMemberId,
        this.props.user.demoProfile,
      ),
    );
    dataPosition.supports =
      apiArray.length > 0 ? apiArray.length - 1 : FIRST_RESULT;

    return {apiArray, dataPosition};
  };

  /**
   * Converts binary data to Base64
   * @param {*} data
   */
  binaryToBase64(data: any) {
    if (data instanceof ArrayBuffer) {
      data = new Uint16Array(data);
    }
    if (data instanceof Uint16Array) {
      return Base64.fromByteArray(data);
    }
    if (!ArrayBuffer.isView(data)) {
      throw new Error('data must be ArrayBuffer or typed array');
    }
    const {buffer, byteOffset, byteLength} = data;
    return Base64.fromByteArray(
      new Uint16Array(buffer, byteOffset, byteLength),
    );
  }

  /**
   * Converts encoded html into ArrayBuffer
   * @param {String} encodeHtml
   */
  stringToArrayBuffer(encodeHtml: any) {
    const buffer = new ArrayBuffer(encodeHtml.length * 2);
    const bufferView = new Uint16Array(buffer);
    for (let i = 0, strLen = encodeHtml.length; i < strLen; i++) {
      bufferView[i] = encodeHtml.charCodeAt(i);
    }
    return buffer;
  }

  createPDF(pdfViewType: any) {
    // Create PDF. Long Process so we should start spinner as well.
    this._setLoading(true);
    if (pdfViewType === types2.PDF_VIEW_TYPE.VIEW) {
      // Generate HTML that gets rendered in the PDF
      const html = this._generateHTML();
      this._setLoading(false);

      // Navigate to the PlanSummaryPdfViewer page
      this.props.actions.NavigationParamActions.setParam({
        html: html,
        downloadCallBack: this.generatePdfFromHtml,
      });
      this.props.navigation.navigate(PLAN_SUMMARY_PDF_VIEW);
    } else {
      // Generate div content without styles
      this.generatePdfFromHtml(pdfViewType);
    }
  }

  generatePdfFromHtml = (pdfViewType: any) => {
    this._setLoading(true);
    var htmlString = this._generateHTML(false);
    // Wrap content with html and body tags
    htmlString = this._addHtmlBody(htmlString);

    // String to Base64 conversion
    var htmlBytes = Utf8.encode(htmlString);
    htmlBytes = this.stringToArrayBuffer(htmlBytes);

    var htmlEncoded = this.binaryToBase64(htmlBytes);

    // Generate css without styles tag
    var styleString = this._generateStyleSheet(false);
    // Add zooming class to styles
    styleString = this._addZoomClass(styleString);

    // String to Base64 conversion
    var styleBytes = Utf8.encode(styleString);
    styleBytes = this.stringToArrayBuffer(styleBytes);

    var styleEncoded = this.binaryToBase64(styleBytes);

    let options = {
      format: 'A4',
      border: {
        top: '0.5in',
        right: '0.5in',
        left: '0.5in',
        bottom: '0.3in',
      },
      footer: {
        height: '30mm',
        contents: '',
      },
      header: {
        height: '28mm',
        contents: '',
      },
    };

    var pdfPayload = {
      memberId: this.props.loadedMemberId.loadedMemberId,
      options: options,
      html: htmlEncoded,
      styles: styleEncoded,
    };

    // generatePDF callback
    let generatePDFCallback = async (data: any) => {
      this._setLoading(false);

      switch (pdfViewType) {
        case types2.PDF_VIEW_TYPE.DOWNLOAD:
          this._downloadRemotePdf(data[0].url);
          break;
        case types2.PDF_VIEW_TYPE.PRINT:
          if (Platform.OS === 'web') {
            this._downloadRemotePdf(data[0].url);
          } else {
            await this.printRemotePDF(data[0].url);
          }
          break;
        case types2.PDF_VIEW_TYPE.SHARE:
          let base64PDF = this._pdfToBase64(data[0].url)
            .then(res => {
              if (!types.isWeb) {
                RNShare.open({
                  title: 'Share your Plan Ready Summary as PDF',
                  message:
                    'Your plan ready summary is attached in PDF format to take with you for your Plan Meeting.',
                  url: res,
                  subject:
                    this.props.member.firstName + "'s Plan Ready Summary",
                }).catch((err: any) => {
                  err && logger.log(err);
                });
              }
            })
            .catch(errorMessage => {
              logger.log('generatePDF error ', errorMessage);
            });
          break;
        default:
          throw 'Invalid PDF view type';
      }
    };

    // calling the generatePDF lambda function
    callAPIs([generatePDF(pdfPayload)], generatePDFCallback, null, () => {
      this._setLoading(false);
    });
  };

  /**
   * print hosted PDFs
   * @param {*} url: url of the generated PDF
   */
  async printRemotePDF(url: any) {
    await RNPrint.print({filePath: url});
  }

  _downloadRemotePdf = (url: any) => {
    window.open(url);
  };

  _printWebPdf = (url: any) => {
    const w = window.open(
      url,
      '_blank',
      'fullscreen=1,channelmode=1,status=1,resizable=1',
    );
    w.document.write(url);
    w.print();
  };

  _addHtmlBody = (htmlString: any) => {
    return `<html class="zoom"><body> ${htmlString} </body></html>`;
  };

  _planSummaryHeader = () => {
    let memberImage = this._getImageFromKey(
      this.state.images,
      'key',
      'memberImage',
    );

    return `
      <div class="alignLogo zoom" id="pageHeader" style="padding-bottom:20px; margin-bottom:20px; justify-content: space-between;">
        <div style="display:flex; flex-direction: row; align-items: center;">
        ${memberImage ? `<img class="avatar" alt="${
          memberImage.photoCaption
            ? `${memberImage.photoCaption} profile picture`
            : 'member profile picture'
        }" src="${memberImage}"/>` : ''} 
      <p class="alignPlanSummary"><b>${this.props.member.firstName} ${
      this.props.member.lastName
    }</b></br>My Plan Ready Summary</p></div>
    <div>
          <img alt="${
            types2.WEB_HEIGHLIGHT.LEAPIN_LOGO
          }" style="width: 85px; padding-top: 10px" src="${this._getImageFromKey(
      this.state.icons,
      'key',
      'poweredByHeader',
    )}"/>
          <img class="logo" alt="${
            types2.WEB_HEIGHLIGHT.LEAPIN_LOGO
          }" src="${this._getImageFromKey(this.state.icons, 'key', 'logo')}"/>
          </div>
      </div>
    `;
  };

  _planSummaryFooter = (pageNumbered: any) => {
    return `
      <div class="alignLogo zoom" id="pageFooter" style="background-color: #ED1C24; margin-top:20px">
        <div style="padding-left: 20px; text-align:left; width: 30%">
          <img alt="${
            types2.WEB_HEIGHLIGHT.LEAPIN_LOGO
          }" style="width: 100px" src="${this._getImageFromKey(
      this.state.icons,
      'key',
      'poweredByFooter',
    )}"/>
        </div>
    <label ${
      pageNumbered ? 'class="pagination"' : ''
    } style="color: #ffffff; text-align: center; font-size: 10px; width: 30%">

    </label>
        <div style="padding-right: 20px; text-align:right; width: 30%">
          <img alt="${
            types2.WEB_HEIGHLIGHT.CONNECT
          }" style="width: 200px" src="${this._getImageFromKey(
      this.state.icons,
      'key',
      'connectWithUs',
    )}"/>
        </div>
      </div>
    `;
  };

  _generateHTML = (embedStyles = true) => {
    var livingWith =
      this._getHomeType() +
      ' ' +
      this._getLivingWithCount() +
      ' ' +
      this._getLivingWith();
    var generatedStyles = this._generateStyleSheet();

    if (!embedStyles) {
      generatedStyles = '';
    }
console.log("loggin icons",JSON.stringify(this._getImageFromKey(
  this.state.icons,
  'key',
  'myDetailsRed',
)),JSON.stringify(this.state.icons))
    const html = `
        ${generatedStyles}
        ${!embedStyles ? this._planSummaryFooter(!embedStyles) : ''}
        <div class="container">

          ${this._planSummaryHeader()}

            <div class="sectionSubHeading">
                <img class="icon" src="${this._getImageFromKey(
                  this.state.icons,
                  'key',
                  'myDetailsRed',
                )}"/>
                <label><div class="detailSubHeading">My details</div></label>
            </div>

            <div class="split-container">
              <div class="split-item" style="padding:0 !important; margin:0 !important">
                <div><b>Preferred name: </b>${this._getMemberName()}</div>
                <div><b>Date of birth: </b>${this._getMemberDateOfBirth()}</div>
                <div><b>Home address: </b>${this._getMemberAddress()}</div>
                <div><b>Phone number: </b>${this._getMemberTelephone()}</div>
                <div><b>Lifestage: </b>${this._getLifeStage()}</div>
              </div>
              <div class="split-item" style="padding:0 !important; margin:0 !important">
                <div><b>Email address: </b>${this.props.member.email}</div>
                <div><b>NDIS number: </b>${this.props.member.ndisNumber}</div>
                <div><b>NDIS plan meeting date: </b>${
                  this.props.member.ndisMeeting
                    ? _generateDisplayDate(
                        new Date(this.props.member.ndisMeeting),
                      )
                    : ''
                }</div>
              </div>
            </div>

            <div class="sectionSubHeading">
                <img class="icon" src="${this._getImageFromKey(
                  this.state.icons,
                  'key',
                  'disabilityRed',
                )}"/>
                <label><div class="detailSubHeading">Impact of my disability</div></label>
            </div>

            ${this._getDisability()}

            <div>
              <div class="secondaryHeader">
                <img class="iconSecondary" src="${this._getImageFromKey(
                  this.state.icons,
                  'key',
                  'disabilityPurple',
                )}"/>
                <label><div>Daily living and self care</div></label>
              </div>
              <div class="secondaryText">
                ${this.props.member.dailyLiving}
              </div>
            </div>

            <div>
              <div class="secondaryHeader">
                <img class="iconSecondary" src="${this._getImageFromKey(
                  this.state.icons,
                  'key',
                  'disabilityPurple',
                )}"/>
                <label><div>Communication</div></label>
              </div>
              <div class="secondaryText">
                ${this.props.member.communication}
              </div>
            </div>

            <div>
              <div class="secondaryHeader">
                <img class="iconSecondary" src="${this._getImageFromKey(
                  this.state.icons,
                  'key',
                  'disabilityPurple',
                )}"/>
                <label><div>Connecting with others</div></label>
              </div>
              <div class="secondaryText">
                ${this.props.member.connectingWithOthers}
              </div>
            </div>

            <div>
              <div class="secondaryHeader">
                <img class="iconSecondary" src="${this._getImageFromKey(
                  this.state.icons,
                  'key',
                  'disabilityPurple',
                )}"/>
                <label><div>Learning</div></label>
              </div>
              <div class="secondaryText">
                ${this.props.member.learning}
              </div>
            </div>

            <div>
              <div class="secondaryHeader">
                <img class="iconSecondary" src="${this._getImageFromKey(
                  this.state.icons,
                  'key',
                  'disabilityPurple',
                )}"/>
                <label><div>Independence</div></label>
              </div>
              <div class="secondaryText">
                ${this.props.member.independence}
              </div>
            </div>

            <div class="sectionSubHeading">
                <img class="icon" src="${this._getImageFromKey(
                  this.state.icons,
                  'key',
                  'whereILiveRed',
                )}"/>
                <label><div class="detailSubHeading">Where I live</div></label>
            </div>

            <div class="normalizeFont">${livingWith}</div>
            <div class="normalizeFont">${this._getLivingWithCrew()}</div>
            <div class="normalizeFont">${this._getHomeModifications()}</div>

            <div class="sectionSubHeading">
                <img class="icon" src="${this._getImageFromKey(
                  this.state.icons,
                  'key',
                  'favouriteThingsRed',
                )}"/>
                <label><div class="detailSubHeading">My favourite things</div></label>
            </div>
            <div class="normalizeFont">
                <div>${this.props.favouritethings.likeToDo || ''}</div>
                <div>Things I'd like to do: ${
                  this.props.favouritethings.wouldLikeToDo || ''
                }</div>
            </div>

            <div class="sectionSubHeading">
                <img class="icon" src="${this._getImageFromKey(
                  this.state.icons,
                  'key',
                  'healthWellbeingRed',
                )}"/>
                <label><div class="detailSubHeading">My health and wellbeing</div></label>
            </div>
            <div>
                <div class="healthwelbeingitem">
                    <img class="icon" src="${this._getImageFromKey(
                      this.state.icons,
                      'key',
                      'goingWell',
                    )}"/>
                    <div>
                        <div>Going Well</div>
                        <br/>
                        <div class="normalizeFont">${
                          this.props.healthwellbeing.ongoingHealth
                            ? this.props.healthwellbeing.ongoingHealth
                                .description
                            : ''
                        }</div>
                    </div>
                </div>
                <br/>
                <div class="healthwelbeingitem">
                    <img class="icon" src="${this._getImageFromKey(
                      this.state.icons,
                      'key',
                      'wantToImprove',
                    )}"/>
                    <div>
                        <div>Want to improve</div>
                        <br/>
                        <div class="normalizeFont">${this._getHealthWellbeingToImprove()}</div>
                    </div>
                </div>
            </div>

            ${this._generateCrews()}

            ${this._generateSupports()}

            <div class="sectionSubHeading">
                <img class="icon" src="${this._getImageFromKey(
                  this.state.icons,
                  'key',
                  'goals',
                )}"/>
                <label><div class="detailSubHeading">My goals and outcomes</div></label>
            </div>

            ${this._generateGoalshtml()}

            <div class="sectionSubHeading">
                <img class="icon" src="${this._getImageFromKey(
                  this.state.icons,
                  'key',
                  'planManagement',
                )}"/>
                <label><div class="detailSubHeading">Plan Management</div></label>
            </div>

            ${this._generatePlanManagementhtml()}

            <div class="sectionSubHeading">
                <img class="icon" src="${this._getImageFromKey(
                  this.state.icons,
                  'key',
                  'documents',
                )}"/>
                <label><div class="detailSubHeading">Documents</div></label>
            </div>

            ${this._generateDocumentsListhtml()}

            <br/>
            <div>
                <a href="${types2.WEBSITE_HOME}">${types2.WEBSITE_HOME}</a>
            </div>

            ${embedStyles ? this._planSummaryFooter(!embedStyles) : ''}

        </div>
        `;
    return html;
  };

  _toSentenceCase(str: any) {
    var newStr = str.toLowerCase().trim();
    newStr = newStr.charAt(0).toUpperCase() + newStr.slice(1);
    return newStr;
  }

  _generateCrews = () => {
    var crewMainHtml = `
      <div class="sectionSubHeading">
        <img class="icon" src="${this._getImageFromKey(
          this.state.icons,
          'key',
          'crewRed',
        )}"/>
        <label><div class="detailSubHeading">Crew</div></label>
      </div>
      `;

    var familyCrewHtml = ``;
    var supportCrewHtml = ``;
    var communityCrewHtml = ``;

    let family = filter(this.props.crews.PersonOrgCrew, ['type', 'family'])[0];
    let support = filter(this.props.crews.PersonOrgCrew, [
      'type',
      'support',
    ])[0];
    let community = filter(this.props.crews.PersonOrgCrew, [
      'type',
      'community',
    ])[0];

    let crewImageArray = filter(this.state.images, 'crewId');

    if (family.crews.length > 0) {
      crewMainHtml += this._generateCrewSection(
        crewImageArray,
        family,
        'Family and Friends',
      );
    }
    if (support.crews.length > 0) {
      crewMainHtml += this._generateCrewSection(
        crewImageArray,
        support,
        'Paid support',
      );
    }
    if (community.crews.length > 0) {
      crewMainHtml += this._generateCrewSection(
        crewImageArray,
        community,
        'Community support and support circle',
      );
    }

    crewMainHtml += `${familyCrewHtml}${supportCrewHtml}${communityCrewHtml}`;
    return crewMainHtml;
  };

  _orderedCrew = (crews: any) => {
    const ORDER_BY_NAME = 'orderByName';
    let orderdCrews = forEach(crews, c => {
      if (c.memberType === 'person') {
        c[ORDER_BY_NAME] = c.firstName + ' ' + c.lastName;
      } else {
        c[ORDER_BY_NAME] = c.orgName;
      }
    });
    orderdCrews = orderBy(
      orderdCrews,
      [crew => crew.orderByName.toLowerCase()],
      ['asc'],
    );
    return orderdCrews;
  };

  _generateCrewSection = (
    crewImageArray: any,
    crewArray: any,
    headerTitle: any,
  ) => {
    let crewSectionHtml = `<div class="secondHeader"><label>${headerTitle}</label></div>
        <div class="split-container">`;

    const orderedArray = this._orderedCrew(crewArray.crews);

    for (let i = 0; i < orderedArray.length; i++) {
      var crew = orderedArray[i];
      let imageCrew = this._getImageFromKey(
        crewImageArray,
        'crewId',
        crew.crewId,
      );
      let crewImage = imageCrew;
      let crewImageCaption = imageCrew.photoCaption
        ? imageCrew.photoCaption
        : 'crew profile picture';
      let crewName = '';
      let relationship = '';
      let support = '';
      let orgName = '';

      if (crew.memberType === 'person') {
        crewName = crew.firstName + ' ' + crew.lastName;
        relationship = crew.relationship ? crew.relationship : '';
        support = crew.support ? crew.support : '';
        orgName = crew.orgName ? crew.orgName : '';
      } else {
        crewName = crew.orgName;
        relationship = crew.extraInformation ? crew.extraInformation : '';
      }

      crewSectionHtml += `
        <div class="split-item">
          <div class="crewItem">
            <div class="flexMinGap">
             ${crewImage ? `<img alt="${crewImageCaption}" class="photoTileImage" src="${crewImage}">` : '' } 
            </div>
            <div class="flexMinGap">
              <div class="flexMinGapVertical">
                <b>${crewName}</b>
              </div>
              <div class="flexMinGapVertical">
                ${orgName}
              </div>
              <div class="flexMinGapVertical">
                ${relationship}
              </div>
              <div class="flexMinGapVertical">
                ${support}
              </div>
            </div>
          </div>
        </div>
      `;
    }

    return crewSectionHtml + `</div>`;
  };

  _generateSupports = () => {
    var supportMainHtml = `
      <div class="sectionSubHeading">
        <img class="icon" alt = "" src="${this._getImageFromKey(
          this.state.icons,
          'key',
          'supportsRed',
        )}"/>
        <label><div class="detailSubHeading">Supports</div></label>
      </div>
    `;

    if (this.props.supports.supports != undefined) {
      if (this.props.supports.supports.length > 0) {
        let supportArray = this.props.supports.supports;

        for (var i = 0; i < supportArray.length; i++) {
          let currentSupport = supportArray[i];
          let supportCrews = '';
          let supportOrgCrew = '';

          var supportOpinion = currentSupport.opinion
            ? currentSupport.opinion
            : '';
          var supportOpinionImageValue = currentSupport.opinion
            ? this._mapImageToSupportOpinion(supportOpinion)
            : null;
          var supportCategory = currentSupport.categoryId
            ? this._getSupportCategoryFromSupportId(currentSupport.categoryId)
            : 'Others';
          var supportDetail = currentSupport.detail
            ? currentSupport.detail
            : '-';

          let supportImageArray = filter(this.state.images, 'currentSupportId');
          let currentSupportImage = filter(supportImageArray, [
            'currentSupportId',
            currentSupport.currentSupportId,
          ]);
          let supportImage =
            currentSupportImage[0].url != 'head-silhouette.png'
              ? this._getImageFromKey(
                  supportImageArray,
                  'currentSupportId',
                  currentSupport.currentSupportId,
                )
              : null;
          let supportImageCaption = currentSupportImage[0].photoCaption
            ? currentSupportImage[0].photoCaption
            : 'support image';

          if (currentSupport.supportingCrew) {
            for (let k = 0; k < currentSupport.supportingCrew.length; k++) {
              var crew = currentSupport.supportingCrew[k];

              if (crew.knownAs) {
                supportCrews += crew.knownAs;
              } else {
                supportCrews += crew.firstName + ' ' + crew.lastName;
              }

              // Add a comma it's not the last item
              if (k != currentSupport.supportingCrew.length - 1) {
                supportCrews += ', ';
              }
            }
          }

          if (currentSupport.supportingOrgCrew) {
            for (let o = 0; o < currentSupport.supportingOrgCrew.length; o++) {
              let orgCrew = currentSupport.supportingOrgCrew[o];
              supportOrgCrew = orgCrew.orgName;
              if (o != currentSupport.supportingOrgCrew.length - 1) {
                supportOrgCrew += ', ';
              }
            }
          }

          if (supportOrgCrew) {
            if (supportCrews) {
              supportCrews += ', ';
            }
            supportCrews += supportOrgCrew;
          }

          let supportIcon = {};
          if (currentSupport.categoryId) {
            supportIcon = this._getImageFromKey(
              this.state.icons,
              'key',
              currentSupport.categoryId.toString(),
            );
          } else {
            supportIcon = this._getImageFromKey(
              this.state.icons,
              'key',
              'supportsPurple',
            );
          }

          supportMainHtml += `
            <div>
              <div class="secondaryHeader">
                <img class="iconSecondary" src="${supportIcon}"/>
                <div>${supportCategory}</div>
              </div>
              <div class="support-container">
                <div class="support-content">
                  <div class="crewItem">
                    <div class="flexMinGap">
                      ${
                        supportImage
                          ? `<img class="photoTileImage" alt="${supportImageCaption}" src="${supportImage}">`
                          : ``
                      }
                    </div>
                    <div class="flexMinGap">
                      <div><b>${currentSupport.title}</b></div>
                      ${
                        supportCrews
                          ? `<div><b>Crew: ${supportCrews}</b></div>`
                          : ``
                      }
                      <div>${
                        currentSupport.supportFields
                          ? this._getSupportDescription(
                              currentSupport.supportFields,
                            )
                          : ''
                      }</div>
                    </div>
                  </div>
                </div>
                <div class="support-status">
                  <div style="padding-top:5px">
                    <b>Status:</b>
                  </div>
                  <div style="text-align:center; margin-left: 5px">
                  ${
                    supportOpinionImageValue
                      ? `<img class="support-status-icon" alt="" src="${this._getImageFromKey(
                          this.state.icons,
                          'key',
                          supportOpinionImageValue,
                        )}"/>`
                      : ''
                  }
                    <b style="font-size:12px; color:#686868">${supportOpinion}</b>
                  </div>
                </div>
              </div>
            </div>
          `;
        }
      }
    }
    return supportMainHtml;
  };

  _getSupportDescription = (supportFields: any) => {
    let timeOfDay = filter(supportFields, ['fieldType', 'TimeOfDay']);
    let daysOfUse = filter(supportFields, ['fieldType', 'DaysOfUse']);
    let usage = filter(supportFields, ['fieldType', 'Usage']);
    let frequency = filter(supportFields, ['fieldType', 'Frequency']);
    let hours = filter(supportFields, ['fieldType', 'Hours']);
    let cost = filter(supportFields, ['fieldType', 'Cost']);

    let descWhen = ``;
    let descFrequency = ``;
    let descCost = `<div>`;
    let descWhenClose = ``;

    if (timeOfDay.length > 0 || daysOfUse.length > 0 || usage.length > 0) {
      descWhen = `<div>I access this support `;
      descWhenClose = `</div>`;
    }

    if (timeOfDay && timeOfDay.length > 0) {
      descWhen += `in the `;
      for (let index = 0; index < timeOfDay.length; index++) {
        descWhen += `${timeOfDay[index].value}`;
        if (index < timeOfDay.length - 1) {
          descWhen += `, `;
        }
      }
    }

    if (daysOfUse && daysOfUse.length > 0) {
      descWhen += ` on a `;
      for (let index = 0; index < daysOfUse.length; index++) {
        descWhen += `${daysOfUse[index].value}`;
        if (index < daysOfUse.length - 1) {
          if (index == daysOfUse.length - 2) {
            descWhen += `and `;
          } else {
            descWhen += `, `;
          }
        }
      }
    }

    if (usage && usage.length > 0) {
      descWhen += ` `;
      for (let index = 0; index < usage.length; index++) {
        descWhen += `${usage[index].value}`;
        if (index < usage.length - 1) {
          descWhen += `, `;
        }
      }
    }

    if (frequency && frequency.length > 0) {
      descFrequency = `<div>In total, about ${frequency[0].value} times per year.</div>`;
    }

    if (hours.length > 0) {
      descCost += `${hours[0].value} hours `;
      if (cost.length) {
        descCost += `and `;
      }
    }
    if (cost.length > 0) {
      descCost += `$${cost[0].cost} per visit.`;
    }

    descCost += `</div>`;
    descWhen += descWhenClose;

    return descWhen + descFrequency + descCost;
  };

  // TODO: keeping this for now as a reference to the old support section. need to remove this and all associated methods with it
  _generateSupporthtml() {
    var html = '';
    if (this.props.supports.supports == undefined) {
      return '<div></div>';
    }
    if (this.props.supports.supports != undefined) {
      if (this.props.supports.supports.length > 0) {
        const supports = this.props.supports.supports;
        for (var i = 0; i < supports.length; i++) {
          var currentSupport = supports[i];

          var supportTitle = currentSupport.title;
          var supportOpinion =
            currentSupport.opinion != null ? currentSupport.opinion : '-';
          var supportOpinionImageValue = null;
          if (currentSupport.opinion != null) {
            supportOpinionImageValue =
              this._mapImageToSupportOpinion(supportOpinion);
          }
          // var supportOpinionImageValue = this._mapImageToSupportOpinion(supportOpinion);
          var supportCategory =
            currentSupport.categoryId != null
              ? this._getSupportCategoryFromSupportId(currentSupport.categoryId)
              : 'Others';
          var supportDetail =
            currentSupport.detail != null ? currentSupport.detail : '-';
          var supportCrews = '';
          let supportOrgCrew = '';
          //var categoryId = currentSupport.categoryId;
          if (currentSupport.supportingCrew) {
            for (let k = 0; k < currentSupport.supportingCrew.length; k++) {
              var crew = currentSupport.supportingCrew[k];

              if (crew.knownAs) {
                supportCrews += crew.knownAs;
              } else {
                supportCrews += crew.firstName + ' ' + crew.lastName;
              }

              // Add a comma it's not the last item
              if (k != currentSupport.supportingCrew.length - 1) {
                supportCrews += ', ';
              }
            }
          }

          if (currentSupport.supportingOrgCrew) {
            for (let o = 0; o < currentSupport.supportingOrgCrew.length; o++) {
              let orgCrew = currentSupport.supportingOrgCrew[o];
              supportOrgCrew = orgCrew.orgName;
              if (o != currentSupport.supportingOrgCrew.length - 1) {
                supportOrgCrew += ', ';
              }
            }
          }

          if (supportOrgCrew) {
            if (supportCrews) {
              supportCrews += ', ';
            }
            supportCrews += supportOrgCrew;
          }

          supportCrews += '&nbsp';

          let supportImageArray = filter(this.state.images, 'currentSupportId');
          let imageSupport = this._getImageFromKey(
            supportImageArray,
            'currentSupportId',
            currentSupport.currentSupportId,
          );
          let supportImage = imageSupport;
          let supportPhotoCaption = imageSupport.photoCaption
            ? imageSupport.photoCaption
            : 'support image';

          // Build the HTML for this support...
          html += `
                  <div class="supportItem">
                    <div class="flexMinGap">
                      <img class="photoTileImage" alt = "${supportPhotoCaption}" src="${supportImage}">
                    </div>
                    `;

          var supportHtml = '';
          var supportOptionHtml = '';

          if (supportOpinion && supportOpinionImageValue) {
            supportOptionHtml += `
              <div class="flexMinGapVertical">
                  <div class="flexRow">
                    <img class="icon" alt="" src="${this._getImageFromKey(
                      this.state.icons,
                      'key',
                      supportOpinionImageValue,
                    )}"/>
                      <div class="normalizeFont">${supportOpinion}</div>
                  </div>
              </div>
              `;
          }

          var supportDetailsHtml = `
            <div class="flexRow">
                <div class="flexMinGap supportTitleSection">
                    <div class="flexMinGapVertical"><b>Support title:</b></div>
                </div>
                <div class="flexMinGap normalizeFont">
                    <div class="flexMinGapVertical">${supportTitle}</div>
                </div>
            </div>
            <div class="flexRow">
                <div class="flexMinGap supportTitleSection">
                    <div class="flexMinGapVertical"><b>Support description:</b></div>
                </div>
                <div class="flexMinGap normalizeFont">
                    <div class="flexMinGapVertical">${supportDetail}</div>
                </div>
            </div>
            <div class="flexRow">
                <div class="flexMinGap supportTitleSection">
                    <div class="flexMinGapVertical"><b>Crew member:</b></div>
                </div>
                <div class="flexMinGap normalizeFont">
                    <div class="flexMinGapVertical">${supportCrews}</div>
                </div>
            </div>
            <div class="flexRow">
                <div class="flexMinGap supportTitleSection">
                    <div class="flexMinGapVertical"><b>Frequency:</b></div>
                </div>
                <div class="flexMinGap normalizeFont">
                    <div class="flexMinGapVertical">${this._renderFrequencyCalc(
                      currentSupport,
                    )}</div>
                </div>
            </div>
            ${this._renderTimeOfDay(currentSupport.supportFields)}
            ${this._renderDaysOfUse(currentSupport.supportFields)}
            ${this._renderUsage(currentSupport.supportFields)}
            <div class="flexRow">
                <div class="flexMinGap supportTitleSection">
                    ${this._renderDriverQuestions(
                      currentSupport.supportDrivers,
                    )}
                </div>
                <div class="flexMinGap normalizeFont">
                    ${this._renderDriverAnswers(currentSupport.supportDrivers)}
                </div>
            </div>
            `;

          if (
            currentSupport.categoryId != null ||
            currentSupport.currentSupportId != null
          ) {
            let supportIcon = {};
            if (currentSupport.categoryId) {
              let supportImageArray = filter(this.state.images, 'categoryId');
              supportIcon = this._getImageFromKey(
                supportImageArray,
                'categoryId',
                currentSupport.categoryId,
              );
            } else {
              supportIcon = this._getImageFromKey(
                this.state.icons,
                'key',
                'supportsOthers',
              );
            }

            supportHtml += `
              <div class="flexMinGap">
                  <div><b>${supportTitle}</b></div>
                  <div class="flexRow">
                      <img class="icon" alt="" src="${supportIcon}"/>
                      <div class="normalizeFont">${supportCategory}</div>
                  </div>
                  ${supportOptionHtml}
                  ${supportDetailsHtml}
              </div>
              `;
          }

          html += supportHtml + '</div>';
        }
      }
    }

    return html;
  }

  /**
   * Render driver questions for supports
   */
  _renderDriverQuestions(supportDrivers: any) {
    let driverHtml = ``;
    if (supportDrivers && supportDrivers.length > 0) {
      let uniqueDrivers = uniqBy(supportDrivers, 'driverCode');

      uniqueDrivers.forEach(element => {
        driverHtml += `<div class="flexMinGapVertical"><b>${element.driverCode}: </b></div>`;
      });
    }
    return driverHtml;
  }

  /**
   * Render driver question answers for supports
   */
  _renderDriverAnswers(supportDrivers: any) {
    let driverHtml = ``;
    if (supportDrivers && supportDrivers.length > 0) {
      let uniqueDrivers = uniqBy(supportDrivers, 'driverCode');

      for (let index = 0; index < supportDrivers.length; index++) {
        driverHtml += `${supportDrivers[index].value}`;
        if (index < supportDrivers.length - 1) {
          driverHtml += `, `;
        }
      }
    }
    return `<div class="flexMinGapVertical">${driverHtml}</div>`;
  }

  /**
   * Render TimeOfDay
   */
  _renderTimeOfDay(supportFields: any) {
    let html = ``;
    if (supportFields && supportFields.length > 0) {
      let filteredArray = filter(supportFields, ['fieldType', 'TimeOfDay']);

      if (filteredArray.length > 0) {
        for (let index = 0; index < filteredArray.length; index++) {
          html += `${filteredArray[index].value}`;
          if (index < filteredArray.length - 1) {
            html += `, `;
          }
        }

        return `
                    <div class="flexRow">
                    <div class="flexMinGap supportTitleSection">
                        <div class="flexMinGapVertical"><b>What time of the day do you use this support:</b></div>
                    </div>
                    <div class="flexMinGap normalizeFont">
                        <div class="flexMinGapVertical">${html}</div>
                    </div>
                    </div>
                `;
      }
    }
    return html;
  }

  /**
   * Render DaysOfUse
   */
  _renderDaysOfUse(supportFields: any) {
    let html = ``;
    if (supportFields && supportFields.length > 0) {
      let filteredArray = filter(supportFields, ['fieldType', 'DaysOfUse']);

      if (filteredArray.length > 0) {
        for (let index = 0; index < filteredArray.length; index++) {
          html += `${filteredArray[index].value}`;
          if (index < filteredArray.length - 1) {
            html += `, `;
          }
        }

        return `
                    <div class="flexRow">
                    <div class="flexMinGap supportTitleSection">
                        <div class="flexMinGapVertical"><b>What days do you use this support:</b></div>
                    </div>
                    <div class="flexMinGap normalizeFont">
                        <div class="flexMinGapVertical">${html}</div>
                    </div>
                    </div>
                `;
      }
    }
    return html;
  }

  /**
   * Render Usage
   */
  _renderUsage(supportFields: any) {
    let html = ``;
    if (supportFields && supportFields.length > 0) {
      let filteredArray = filter(supportFields, ['fieldType', 'Usage']);

      if (filteredArray.length > 0) {
        for (let index = 0; index < filteredArray.length; index++) {
          html += `${filteredArray[index].value}`;
          if (index < filteredArray.length - 1) {
            html += `, `;
          }
        }

        return `
                    <div class="flexRow">
                    <div class="flexMinGap supportTitleSection">
                        <div class="flexMinGapVertical"><b>When do you use this Service:</b></div>
                    </div>
                    <div class="flexMinGap normalizeFont">
                        <div class="flexMinGapVertical">${html}</div>
                    </div>
                    </div>
                `;
      }
    }
    return html;
  }

  _renderFrequencyCalc(currentSupport: any) {
    if (
      currentSupport.unitOfMeasure != undefined &&
      currentSupport.unitOfMeasure != null
    ) {
      switch (currentSupport.unitOfMeasure) {
        case types.HOUR || types.WEEK:
          return this._calculateHourWeek(currentSupport);
        case types.YEAR:
          return types.YEAR;
        case types.EACH || types.DAY:
          return this._calculateUOMEach(currentSupport);
        default:
          return '-';
      }
    } else {
      return '-';
    }
  }

  _calculateHourWeek(currentSupport: any) {
    if (
      currentSupport.supportFields != null &&
      Array.isArray(currentSupport.supportFields)
    ) {
      let frequencyObject = currentSupport.supportFields.find((item: any) => {
        return item.fieldType === types.FREQUENCY;
      });
      return frequencyObject != undefined ? frequencyObject.value : '-';
    } else {
      return '-';
    }
  }

  _calculateUOMEach(currentSupport: any) {
    if (
      currentSupport.supportFields != null &&
      Array.isArray(currentSupport.supportFields)
    ) {
      let frequencyObject = currentSupport.supportFields.find((item: any) => {
        return item.fieldType === types.TIMESPERYEAR;
      });
      return frequencyObject != undefined && frequencyObject.units != null
        ? frequencyObject.units + ' times per year'
        : '-';
    } else {
      return '-';
    }
  }

  _generateGoalshtml() {
    var html = '';
    if (this.props.goals.goals == undefined) {
      return '<div></div>';
    }
    for (let i = 0; i < this.props.goals.goals.length - 1; i++) {
      let goal = this.props.goals.goals[i];
      if (goal.isActive) {
        html += `
            <div class="section">
              <img class="iconSecondary" alt="" src="${this._getImageFromKey(
                this.state.icons,
                'key',
                'myDetails',
              )}"/>
                <div class="secondaryHeader">${goal.goalName}</div>
            </div>

            <div class="goalitem">
            `;
        if (goal.goalPhoto) {
          let goalImageArray = filter(this.state.images, 'currentGoalId');
          let imageGoal = this._getImageFromKey(
            goalImageArray,
            'currentGoalId',
            goal.currentGoalId,
          );
          let goalImage = imageGoal;
          let goalImageCaption = imageGoal.photoCaption
            ? imageGoal.photoCaption
            : 'goal image';
          html += `
                <div class="flexMinGap">
                  <img class="photoTileImage" alt="${goalImageCaption}" src="${goalImage}")">
                </div>
                `;
        }

        html += `
                <div class="flexMinGap">
            `;

        if (goal.currentGoalSteps == undefined) {
          html += '<div></div>';
        } else {
          // Add the goal steps
          for (let k = 0; k < goal.currentGoalSteps.length; k++) {
            const currentStep = goal.currentGoalSteps[k];
            html += `
                    <div class="normalizeFont">
                        <div>Step ${k + 1}: ${currentStep.stepName}</div>
                        <div>${currentStep.stepDescription}</div>
                    </div>
                    `;
          }
        }

        html += '</div></div>';
      }
    }
    return html;
  }

  _generatePlanManagementhtml() {
    let html = `<div class="normalizeFont">${PLAN_MANAGEMENT_HTML.NOTHING_SELECTED}</div>`;
    if (this.props.member.planManager) {
      let displayStr = '';
      if (this.props.member.planManager == types2.PLAN_MANAGER_DATA.LEAP_IN) {
        displayStr = PLAN_MANAGEMENT_HTML.LEAP_IN_SELECTED;
      } else if (
        this.props.member.planManager == types2.PLAN_MANAGER_DATA.SELF_MANAGED
      ) {
        displayStr = PLAN_MANAGEMENT_HTML.SELF_MANAGED_SELECTED;
      } else if (
        this.props.member.planManager == types2.PLAN_MANAGER_DATA.PLAN_MANAGED
      ) {
        displayStr = PLAN_MANAGEMENT_HTML.PLAN_MANAGED_SELECTED;
      } else if (
        this.props.member.planManager == types2.PLAN_MANAGER_DATA.AGENCY
      ) {
        displayStr = PLAN_MANAGEMENT_HTML.AGENCY_SELECTED;
      } else if (
        this.props.member.planManager == types2.PLAN_MANAGER_DATA.OTHER
      ) {
        displayStr = PLAN_MANAGEMENT_HTML.PLAN_MANAGED_SELECTED;
      } else if (
        this.props.member.planManager == types2.PLAN_MANAGER_DATA.NONE
      ) {
        displayStr = PLAN_MANAGEMENT_HTML.NOTHING_SELECTED;
      }

      html = `
                <div><b>I have chosen to be:</b></div>
                <div class="normalizeFont">${displayStr}</div>
            `;
    }
    return html;
  }

  _generateDocumentsListhtml() {
    var html = '';
    if (this.props.documentlist.documentList == undefined) {
      return '<div></div>';
    } else if (this.props.documentlist.documentList.length > 0) {
      html += '<div><b>I have collected the following documents</b></div>';
      for (let i = 0; i < this.props.documentlist.documentList.length; i++) {
        var document = this.props.documentlist.documentList[i];
        html += `<div class="normalizeFont"><u>${document.filename}</u></div>`;
      }
    } else {
      return '<div></div>';
    }
    return html;
  }

  /**
   * Add zoom out style for the pdf generation
   * @param {*} styleSheet
   */
  _addZoomClass(styleSheet: any) {
    return '.zoom {zoom: 0.75;}' + styleSheet;
  }

  /**
   * Generate styles for the plan meeting pdf
   * @param {*} withTags
   */
  _generateStyleSheet(withTags = true) {
    const styles = `
        @page{
           size: A4;
          margin-top: 5cm;
          margin-bottom: 3.5cm;
          @top-center{
            content: element(header);
            -ro-pdf-tag-type: "Div";
          }
          @bottom-center{
            content: element(footer);
            -ro-pdf-tag-type: "Div";
          }
         }
         #pageFooter{
          position: running(footer);
         }
         img {
          -ro-pdf-tag-type: "Figure";
         }
         img[alt] {
          -ro-alt-text: -ro-attr(alt);
         }
         #pageHeader{
           position: running(header);
         }
         .pagination:after {
          content: "Page " counter(page) " of " counter(pages);
          left: 0;
          top: 100%;
          white-space: nowrap;
          z-index: 20;
          -moz-border-radius: 5px;
          -moz-box-shadow: 0px 0px 4px #222;
          background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
        }
        .container {
            padding-left: 2%;
            padding-right: 2%;
            min-width: 600px;
        }
        .alignPlanSummary {
          font-size: 15pt;
            padding-left: 10px;
            color: #E32625;
        }
        .alignLogo {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 20px;
        }
        .headingBold {
            font-family: 'Helvetica', 'Arial', sans-serif;
            font-size: 15pt;
            font-weight: 800;
        }
        .headingRegular {
            font-family: 'Helvetica', 'Arial', sans-serif;
            font-size: 15pt;
            color: #E32625;
        }
        .subHeading {
            display: block;
            font-size: 1.17em;
            margin-top: 0.83em;
            margin-bottom: 0.83em;
            margin-left: 0.83em;
            margin-right: 0;
            font-weight: bold;
        }
        .detailSubHeading{
            display: block;
            font-size: 1.17em;
            margin-top: 0.6em;
            margin-bottom: 0.5em;
            margin-left: 0;
            margin-right: 0;
            font-weight: bold;
            color: #E32625;
        }
        .secondaryHeader {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          color: #533FAB;
          font-size: 0.95em;
          font-weight: bold;
        }
        .secondHeader {
          color: #533FAB;
          font-size: 0.95em;
          font-weight: bold;
        }
        .secondaryText {
          font-weight: normal;
          padding-left: 35px;
          margin-bottom: 20px;
        }
        .yellowSubHeader {
            width: 100%;
            background-color: #ffb41e;
            align-items: center;
            margin: 5spx;
        }
        .sectionSubHeading {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-bottom: 1px solid #E32625;
            justify-content: flex-start;
            margin-top: 10px;
            margin-bottom: 20px;
        }
        .section {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-top: 10px;
            margin-bottom: 15px;
        }
        .normalizeFont{
            font-weight: normal;
        }
        .member {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .crewItem {
            flex: 1;
            display: flex;
            flex-direction: row;
        }
        .supportItem {
            flex: 1;
            display: flex;
            flex-direction: row;
            margin-bottom: 20px;
        }
        .flexRow {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .crewnamerelationship {
            flex: 1;
            display: flex;
            flex-direction: row;
        }
        .healthwelbeingitem {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: start;
        }
        .goalitem {
            flex: 1;
            display: flex;
            flex-direction: row;
        }
        .supportTitleSection {
            min-width: 150px;
            max-width: 155px;
        }
        .flexMinGap {
            margin-right: 10px;
        }
        .flexMinGapDetail {
            padding-left: 6%;
        }
        .flexMinGapVertical {
            justify-content: top;
            margin-bottom: 5px;
        }
        .icon {
            width: 20px;
            height: 20px;
            padding-right: 6px;
            margin-right: 10px;
        }
        .iconSecondary {
          width: 16px;
          padding-right: 6px;
          margin-right: 10px;
        }
        .photoContainer {
            width: 84px;
            height: 84px;
        }
        .photoTileBG {
            width: 84px;
            height: 84px;
            background-position: center;
            background-size: 100% 100%;
            background-color: #e32625;
            border-radius: 25px;
        }
        .photoTileImage {
          background-position: center;
          background-color: #e32625;
          border-radius: 25px;
          width: 84px;
          height: 84px;
        }
        .logo {
          width: 55px;
        }
        .avatar {
          border-radius: 50%;
          height: 70px;
        }
        .photoTile {
            object-fit: cover;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #e32625;
            width: 78px;
            height: 78px;
        }
        h2 {
            margin-left: 15px;
            font-size: 22pt;
        }
        h3 {
            font-size: 25px;
            padding: 2;
            margin: 0;
        }
        body {
            font-family: 'Helvetica', 'Arial', sans-serif;
        }
        .divTable{
            display: table;
            width: 100%;
        }
        .divTableRow {
            display: table-row;
            width: 100%;
        }
        .divTableHeading {
            display: table-header-group;
        }
        .divTableCell, .divTableHead {
            width: 50%;
            display: table-cell;
        }
        .divTableHeading {
            display: table-header-group;
        }
        .divTableFoot {
            display: table-footer-group;
            font-weight: bold;
        }
        .divTableBody {
            display: table-row-group;
        }
        .split-container {
          padding: 0;
          margin-bottom: 10px;
          list-style: none;
          display: flex;
          flex-flow: row wrap;
          justify-content: start;
        }
        .split-item {
          padding: 5px;
          margin: 10px;
          width: 45%;
        }
        .support-container{
          padding: 0;
          margin-bottom: 10px;
          list-style: none;
          display: flex;
          justify-content: start;
        }
        .support-content{
          padding: 5px;
          margin: 10px;
          width: 70%;
          display: flex;
          justify-content: start;
        }
        .support-status{
          padding: 5px;
          padding-left:8px;
          margin: 10px;
          width: 20%;
          display: flex;
          justify-content: start;
          border-left: 1px solid gray;
        }
        .support-status-icon{
          width: 40px;
          padding-bottom: 5px;
        }
        `;

    if (!withTags) {
      return styles;
    }

    return `<style> ${styles} </style>`;
  }

  /**
   * This will return the base64 conversion result of the pdf file hosted in a s3
   * @param {*} url s3 url of the pdf
   */
  _pdfToBase64(url: any) {
    return new Promise(function (resolve, reject) {
      RNFetchBlobSingleton.fetch('GET', url, {})
        .then(res => {
          var result = 'data:application/pdf;base64,' + res.base64();
          resolve(result);
        })
        .catch((errorMessage: any, statusCode: any) => {
          reject(errorMessage);
        });
    });
  }

  /**
   * This will get an image object and add it's base64 version onto it.
   * @param {object} image An image object - the expected structure is:
   * Image: {
   *  key: Unique key of the image so we can retrive it when generating the PDF
   *  url: The image url, either a S3 URL or a local image file name in the Android assets folder
   * }
   * @returns {Promise<object>} The image with the base64 address
   */
  _imageToBase64(image: any) {
    // TODO - check if it already exists then if so return resolved??
    const that = this;
    return new Promise(function (resolve, reject) {
      // Check if the iage is a URL or local image
      const expression =
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)+[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
      const regex = new RegExp(expression);

      // If the image is from a URL or not defined then load it
      let blob = null;
      let xhr = new XMLHttpRequest();
      let imagePath = '';
      if (image?.url?.match(regex) || image.url == null || image.url == '') {
        if (image.url == null || image.url == '') {
          // Get the placeholder image
          const placeHolderImage = that._getImageFromKey(
            that.state.icons,
            'key',
            PLACEHOLDER_IMAGE,
          );
          // Get the URL & base64 from the placeholder image
          const result = JSON.parse(JSON.stringify(image));
          result.url = placeHolderImage.url;
          result.base64 = placeHolderImage.base64;
          resolve(result);
        } else {
          if (types.isWeb) {
            const result = JSON.parse(JSON.stringify(image));
            resolve(result);
          } else {
            RNFetchBlobSingleton.fetch('GET', image.url, {})
              .then(res => {
                const result = JSON.parse(JSON.stringify(image));
                result.base64 = 'data:image/png;base64,' + res.base64();
                resolve(result);
              })
              .catch((errorMessage: any, statusCode: any) => {
                // Status code is not 200
                reject(errorMessage);
              });
          }
        }
      } else {
        // Get the image from the local file system
        if (types.isWeb) {
          that._fetchBlobWeb(that, xhr, blob, image, true, resolve, reject);
        } else {
          const imagePath = RNFetchBlobSingleton.fs.asset(image.url);
          RNFetchBlobSingleton.fs
            .readFile(imagePath, 'base64')
            .then(data => {
              const result = JSON.parse(JSON.stringify(image));
              result.base64 = 'data:image/png;base64,' + data;
              resolve(result);
            })
            .catch(error => {
              logger.log('Read file error ', error);
              reject(error);
            });
        }
      }
    });
  }

  _fetchBlobWeb = (
    that: any,
    xhr: any,
    blob: any,
    image: any,
    isLocal: any,
    resolve: any,
    reject: any,
  ) => {
    xhr.open('GET', image.url);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      blob = xhr.response;
      let file = blob;
      if (isLocal) {
        file = new File([blob], image.url, {
          type: 'image/png',
          lastModified: Date.now(),
        });
      }
      that
        ._getBase64Local(file)
        .then((data: any) => {
          const result = JSON.parse(JSON.stringify(image));
          result.base64 = data;
          resolve(result);
        })
        .catch((error: any) => {
          reject(error);
        });
    };
    xhr.send();
  };

  _getBase64Local = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  _mapImageToSupportOpinion(opinion: any) {
    switch (opinion) {
      case types.STOP:
        return 'supportStop';
      case types.KEEP:
        return 'supportKeep';
      case types.CHANGE:
        return 'supportChange';
      default:
        logger.log('Invalid support opinion');
        return null;
    }
  }

  _getCrewNameFromCrewId(crewId: any) {
    var crewName = '';
    var crewsArray = this.props.crews.crewIndividual;
    for (var i = 0; i < crewsArray.length; i++) {
      var crew = crewsArray[i];
      if (crewId == crew.crewId) {
        if (crew.knownAs) {
          crewName = crew.knownAs;
        } else {
          crewName = crew.firstName + ' ' + crew.lastName;
        }
        break;
      }
    }
    return crewName;
  }

  _getSupportCategoryFromSupportId(supportid: any) {
    var supportCategories = this.props.supportcategories.categories;
    for (var i = 0; i < supportCategories.length; i++) {
      var category = supportCategories[i];
      if (supportid == category.categoryId) {
        return category.description;
      }
    }
    return supportid;
  }

  /**
   * Given an array return the object for the given key value
   * @param {array} array array to search through
   * @param {string} keyvalue The given key value we want to return an object for
   */
  _getImageFromKey(array: any, key: any, keyvalue: any) {

    if (Array.isArray(array)) {
      const image = find(array, [key, keyvalue]);
      if (image?.key === PLACEHOLDER_IMAGE) {
        return image;
      } else {
        if (image?.base64) {
          return image.base64;
        }
        if (image?.url) {
          return image.url;
        }
      }
    }
    return '';
  }

  /**
   * Get member telephone
   */
  _getMemberTelephone() {
    return this.props.member.phoneNumber != null &&
      this.props.member.phoneNumber != undefined
      ? this.props.member.phoneNumber
      : '';
  }

  /**
   * Get member date of birth
   */
  _getMemberDateOfBirth() {
    return this.props.member.dateOfBirth != null &&
      this.props.member.dateOfBirth != undefined
      ? this.props.member.dateOfBirth
      : '';
  }

  _getMemberName() {
    if (
      this.props.member.knownAs != null &&
      this.props.member.knownAs != '' &&
      /\S/.test(this.props.member.knownAs)
    ) {
      return this.props.member.knownAs + '&nbsp';
    } else {
      return (
        this.props.member.firstName + ' ' + this.props.member.lastName + '&nbsp'
      );
    }
  }

  _getMemberAddress() {
    return (
      (this.props.member.apartment ? this.props.member.apartment + ' ' : '') +
      this.props.member.address +
      ' ' +
      this.props.member.city +
      ' ' +
      this.props.member.postcode +
      ' ' +
      this.props.member.state +
      '&nbsp'
    );
  }

  _getLifeStage() {
    let lifeStageString = '';
    for (let i = 0; i < this.props.member.lifeStages.length; i++) {
      lifeStageString += this.props.member.lifeStages[i].lifeStageDescription;
      if (i != this.props.member.lifeStages.length - 1) {
        lifeStageString += ', ';
      }
    }
    lifeStageString += '&nbsp';
    return lifeStageString;
  }

  _getDisability() {
    let disabilityString =
      '<label><div style="padding-bottom:20px"><b>My disability is: </b></label>';

    for (let i = 0; i < this.props.member.disabilities.length; i++) {
      disabilityString += this.props.member.disabilities[i].type;
      if (i != this.props.member.disabilities.length - 1) {
        disabilityString += ', ';
      }
    }

    if (
      this.props.member.disabilities != 0 &&
      this.props.member.customDisabilities.length != 0
    ) {
      disabilityString += ', ';
    }

    for (let i = 0; i < this.props.member.customDisabilities.length; i++) {
      disabilityString += this.props.member.customDisabilities[i].type;
      if (i != this.props.member.customDisabilities.length - 1) {
        disabilityString += ', ';
      }
    }
    disabilityString += '</div>';

    return disabilityString;
  }

  _getHealthWellbeingToImprove() {
    var healthString = '';
    if (isEmpty(this.props.healthwellbeing)) {
      return healthString;
    }
    if (
      this.props.healthwellbeing &&
      this.props.healthwellbeing.healthConcerns.description != ''
    ) {
      healthString +=
        this.props.healthwellbeing.healthConcerns.description + '.<br/>';
    }
    if (
      this.props.healthwellbeing &&
      this.props.healthwellbeing.healthConcerns.difficulty != ''
    ) {
      healthString +=
        this.props.healthwellbeing.healthConcerns.difficulty + '.<br/>';
    }
    if (
      this.props.healthwellbeing &&
      this.props.healthwellbeing.healthConcerns.support != ''
    ) {
      healthString +=
        this.props.healthwellbeing.healthConcerns.support + '.&nbsp';
    }
    return healthString;
  }

  _getHomeType() {
    var homeType = '';
    if (this.props.livingArrangement?.homeType?.description) {
      let description =
        this.props.livingArrangement?.homeType?.description.toLowerCase();
      if (
        this.props.livingArrangement?.homeType?.type ===
        types2.HOME_TYPES.APARTMENT
      ) {
        homeType = 'In an ' + description + '. ';
      } else if (
        this.props.livingArrangement?.homeType?.type === types2.HOME_TYPES.HOUSE
      ) {
        homeType = 'In a ' + description + '. ';
      } else {
        homeType = 'In ' + description + '. ';
      }
    }

    if (homeType == '') {
      return '';
    } else {
      return homeType;
    }
  }

  _getLivingWith() {
    var livingWith = '';
    var livingWithArray = this.props.livingArrangement?.livingWith;
    for (var i = 0; i < livingWithArray?.length; i++) {
      if (livingWith != '') {
        livingWith = livingWith + ', ' + livingWithArray[i].description;
      } else {
        livingWith += livingWithArray[i].description;
      }
    }
    if (livingWith == '') {
      return '';
    } else {
      return livingWith.toLowerCase() + './';
    }
  }

  _getLivingWithCrew() {
    var crewText = 'I live ';
    var livingWithCrew = this.props.livingArrangement.supportAtHome;

    if (livingWithCrew.length == 0) {
      crewText = '';
      return crewText;
    } else {
      crewText += 'with ';
    }

    for (var i = 0; i < livingWithCrew.length; i++) {
      var crewId = livingWithCrew[i].id;
      crewText += this._getCrewNameFromCrewId(crewId);

      // There will be another crew next, add a comma and space
      if (i != livingWithCrew.length - 1) {
        if (i == livingWithCrew.length - 2) {
          crewText += ' and ';
        } else {
          crewText += ', ';
        }
      }
    }
    crewText += './';
    return crewText + '&nbsp';
  }

  _getLivingWithCount() {
    var livingWithCount = '';
    if (this.props.livingArrangement.numberOfPeople != 0) {
      if (this.props.livingArrangement.numberOfPeople == 1) {
        livingWithCount =
          'All up I live with ' +
          this.props.livingArrangement.numberOfPeople +
          ' person';
      } else {
        livingWithCount =
          'All up I live with ' +
          this.props.livingArrangement.numberOfPeople +
          ' people';
      }
    }

    if (livingWithCount == '') {
      return '';
    } else {
      return livingWithCount;
    }
  }

  _getHomeModifications() {
    if (this.props.livingArrangement.modification.isModified) {
      var modifications = 'Yes, I currently have modifications to my home: ';
      modifications += this.props.livingArrangement.modification.detail + './';
      return modifications;
    } else {
      return '';
    }
  }
}

const mapStateToProps = (state: any) => ({
  member: state.MemberReducer,
  goals: state.GoalReducer,
  user: state.UserReducer,
  favouritethings: state.FavouriteThingsReducer,
  healthwellbeing: state.HealthWellbeingReducer,
  livingArrangement: state.LivingArrangementReducer,
  crews: state.CrewReducer,
  supports: state.SupportsReducer,
  documentlist: state.DocumentListReducer,
  loadedMemberId: state.LoadedMemberReducer,
  supportcategories: state.SupportCategoriesReducer,
  sideMenu: state.SideMenuReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    MemberActions: bindActionCreators(MemberActions, dispatch),
    DocumentListActions: bindActionCreators(DocumentListActions, dispatch),
    GoalActions: bindActionCreators(GoalActions, dispatch),
    LivingArrangementActions: bindActionCreators(
      LivingArrangementActions,
      dispatch,
    ),
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
    CrewActions: bindActionCreators(CrewActions, dispatch),
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanMeeting);
