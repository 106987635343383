/*
 * Author: Declan Hart
 * Date: 05/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the Title, edit button and summary for edit screens.
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import CustomIcon from '../../assets/images/CustomIcon';

import {StandardTouchableOpacity, StandardText} from '../Atoms';

class PlanSummaryActionTile extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <View
        style={[
          CommonStyles.budgetsTilesContainer,
          types.isWeb && !this.props.isMobile
            ? CommonStyles.PlanSummaryWebTileMargin
            : {},
        ]}
      >
        <StandardTouchableOpacity
          accessibilityLabel={this.props.title}
          onPress={() => {
            this.props.onPressAction(this.props.type);
          }}
          activeOpacity={0.8}
          style={[CommonStyles.budgetsTileWrapper, BrandStyles.primaryBgColor1]}
        >
          <View>
            <View style={[CommonStyles.flexDirectionRow]}>
              <View style={[CommonStyles.flex1]}>
                <View
                  style={[
                    CommonStyles.flexDirectionColumn,
                    CommonStyles.flex1,
                    CommonStyles.alignCenter,
                  ]}
                >
                  <CustomIcon
                    name={this.props.image}
                    style={[
                      this.props.iconStyle,
                      CommonStyles.marginBottom5,
                      BrandStyles.TextColor3,
                    ]}
                  />
                  <StandardText
                    style={[
                      CommonStyles.customFontMedium,
                      BrandStyles.TextColor3,
                      types.isWeb ? CommonStyles.font17 : CommonStyles.font22,
                    ]}
                  >
                    {this.props.title}
                  </StandardText>
                </View>
              </View>
              <View style={[CommonStyles.flex2, CommonStyles.alignSelfCenter]}>
                <StandardText
                  style={[
                    CommonStyles.alignSelfCenter,
                    BrandStyles.TextColor5,
                    CommonStyles.customFont,
                    CommonStyles.flexWrap,
                    types.isWeb ? CommonStyles.font15 : CommonStyles.rpfont15,
                  ]}
                >
                  {this.props.description}
                </StandardText>
              </View>
            </View>
          </View>
        </StandardTouchableOpacity>
      </View>
    );
  }
}

export default PlanSummaryActionTile;
