/*
 * Author: Andrew Seeley
 * Date: 20/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Screen that holds sign up options
 */

import React, {Component} from 'react';
import {
  Platform,
  StyleSheet,
  View,
  Animated,
  Easing,
  Image,
  Dimensions,
  ScrollView,
} from 'react-native';
import {StandardButton, StandardText} from '../Components/Atoms';
import FitImage from 'react-native-fit-image';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {BrandIcons} from '../Styles/BrandIcons';
import {getDataLoggedIn} from '../API/StorageHelper';

import * as UserActions from '../Actions/UserActions';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {
  SIGNUP_OPTIONS_NEW_EXISTING_SCREEN,
  ACCESSIBILITY_SCREEN_TITLES,
  MESSAGES as Messages,
} from '../Constants/Messages';
import {announceForAccessibility} from '../Helpers/PlatformSynchronizer';

import {SignUpOptionsNewExistingImage} from '../assets/images';
import {OfflineNotice} from '../Components/Organisms';

// Get the device height & width
let ScreenHeight = Dimensions.get('window').height;
let ScreenWidth = Dimensions.get('window').width;

// Setup animations
const animationValue = new Animated.Value(0);

// Maps the animation values to start from the top of the screen out of view and finish at the bottom of the screen in view
const animateTopToBottom = animationValue.interpolate({
  inputRange: [0, 1],
  outputRange: [-ScreenHeight, 0.0],
});
// Maps the animation values to start from the bottom of the screen out of view and finish at the top of the screen in view
const animateBottomToTop = animationValue.interpolate({
  inputRange: [0, 1],
  outputRange: [ScreenHeight, 0.0],
});

const styles = StyleSheet.create({
  heroBanner: {
    transform: [{translateY: animateTopToBottom}],
  },
  heroImage: {
    height: ScreenHeight,
    width: ScreenWidth * 0.34,
  },
  /* Overlay View - sits on top of the whole view as RN Android cannot overflow over flexboxes
                This enables the Leap Into text to start on top of the */
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: '22%',
    zIndex: 100,
    transform: [{translateY: animateBottomToTop}],
  },
  // This is the right hand side content background.
  // It ensures the colour is set and animates from the bottom to the topz
  backgroundContent: {
    flex: 66,
    transform: [{translateY: animateBottomToTop}],
    backgroundColor: '#e32625',
  },
});

let headerText,
  secondaryHeaderText,
  option1Text,
  option1HelperText,
  option2Text,
  option2HelperText,
  accessibility_title;

class SignUpOptionsNewExisting extends Component {
  constructor(props: any) {
    super(props);
    this.navigateToNext = this.navigateToNext.bind(this);
    this.renderBackButton = this.renderBackButton.bind(this);
  }

  render() {
    Animated.timing(animationValue, {
      toValue: 1,
      duration: 3000,
    }).start();

    const signUpType = this.props.navigation.state.params.signUpType;

    switch (signUpType) {
      case types.SIGNUP_TYPE_PARTICIPANT:
        headerText = SIGNUP_OPTIONS_NEW_EXISTING_SCREEN.PARTICIPANT_HEADER;
        secondaryHeaderText = '';
        option1Text = SIGNUP_OPTIONS_NEW_EXISTING_SCREEN.PARTICIPANT_OPTION1A;
        option1HelperText =
          SIGNUP_OPTIONS_NEW_EXISTING_SCREEN.PARTICIPANT_OPTION1B;
        option2Text = SIGNUP_OPTIONS_NEW_EXISTING_SCREEN.PARTICIPANT_OPTION2A;
        option2HelperText =
          SIGNUP_OPTIONS_NEW_EXISTING_SCREEN.PARTICIPANT_OPTION2B;
        accessibility_title = ACCESSIBILITY_SCREEN_TITLES.SIGNUP_PARTICIPANT;
        break;
      case types.SIGNUP_TYPE_CREW:
        headerText = SIGNUP_OPTIONS_NEW_EXISTING_SCREEN.CREW_HEADER;
        secondaryHeaderText =
          SIGNUP_OPTIONS_NEW_EXISTING_SCREEN.CREW_HEADER_SECONDARY;
        option1Text = SIGNUP_OPTIONS_NEW_EXISTING_SCREEN.CREW_OPTION1A;
        option1HelperText = SIGNUP_OPTIONS_NEW_EXISTING_SCREEN.CREW_OPTION1B;
        option2Text = SIGNUP_OPTIONS_NEW_EXISTING_SCREEN.CREW_OPTION2A;
        option2HelperText = SIGNUP_OPTIONS_NEW_EXISTING_SCREEN.CREW_OPTION2B;
        accessibility_title = ACCESSIBILITY_SCREEN_TITLES.SIGNUP_CREW;
        break;
      default:
        throw 'Invalid sign up type';
    }

    announceForAccessibility(accessibility_title);

    return (
      <View style={[CommonStyles.screenWrapperContainer]}>
        <View
          style={[CommonStyles.content]}
          contentContainerStyle={[
            CommonStyles.alignItemsCenter,
            CommonStyles.justifyContentCentre,
            CommonStyles.scrollViewVerticalCentre,
          ]}
        >
          {/* Overlay View - sits on top of the whole view as RN Android cannot overflow over flexboxes
                    This enables the Leap Into text to start on top of the */}
          <OfflineNotice />
          <Animated.View style={styles.overlay}>
            <ScrollView>
              {/* The flex here & in the children positions the title text and signup/login text appropiately. This ensures it appears consistent across different android devices */}
              <View style={this._determineMainMargin(signUpType)}>
                {this._renderScreenHeader(
                  signUpType,
                  headerText,
                  secondaryHeaderText,
                )}
                <View style={this._determineContentsStyling(signUpType)}>
                  <StandardButton
                    ref={option1Text}
                    style={this._determineOpt1Styling(signUpType)}
                    containerStyle={this._determineOpt1ContainerStyling(
                      signUpType,
                    )}
                    onPress={() => this.navigateToNext(types.PLAN_TYPE_NEW)}
                  >
                    {option1Text}
                  </StandardButton>
                  <View
                    style={[
                      CommonStyles.flexRow,
                      CommonStyles.flex1,
                      CommonStyles.SignUpOptionsNewExistingPLPR2OPMB5,
                    ]}
                  >
                    <View style={this._determineSeparatorStyle(signUpType)}>
                      <View
                        style={[
                          BrandStyles.SignUpOptionsNewExistingBackgroundColor,
                          CommonStyles.justifyContentCentre,
                        ]}
                      >
                        <StandardText
                          style={[
                            CommonStyles.customFontBold,
                            CommonStyles.font58,
                            CommonStyles.textAlignCentre,
                            BrandStyles.brandBlockTxtColor3,
                          ]}
                        >
                          {SIGNUP_OPTIONS_NEW_EXISTING_SCREEN.SEPARATOR}
                        </StandardText>
                      </View>
                    </View>
                    <View
                      style={CommonStyles.SignUpOptionsNewExistingPL40PPB7P}
                    >
                      <View
                        style={[
                          CommonStyles.SignUpOptionsNewExistingBW4P15,
                          CommonStyles.justifyContentCentre,
                          CommonStyles.elevation5,
                          BrandStyles.borderColor1,
                          BrandStyles.brandBlockBgColor5,
                        ]}
                      >
                        <StandardText
                          style={[
                            CommonStyles.customFont,
                            CommonStyles.font20,
                            BrandStyles.TextColor1,
                          ]}
                        >
                          {option1HelperText}
                        </StandardText>
                      </View>
                    </View>
                  </View>
                  <StandardButton
                    style={this._determineOpt2Styling(signUpType)}
                    containerStyle={this._determineOpt2ContainerStyling(
                      signUpType,
                    )}
                    onPress={() =>
                      this.navigateToNext(types.PLAN_TYPE_EXISTING)
                    }
                  >
                    {option2Text}
                  </StandardButton>
                  <View style={this._determineOpt2HelperStyling(signUpType)}>
                    <View
                      style={[
                        CommonStyles.SignUpOptionsNewExistingBW4P15,
                        CommonStyles.justifyContentCentre,
                        CommonStyles.elevation5,
                        BrandStyles.borderColor1,
                        BrandStyles.brandBlockBgColor5,
                      ]}
                    >
                      <StandardText
                        style={[
                          CommonStyles.customFont,
                          CommonStyles.font20,
                          BrandStyles.TextColor1,
                        ]}
                      >
                        {option2HelperText}
                      </StandardText>
                    </View>
                  </View>
                  <View style={this._determineInstructionStyling(signUpType)}>
                    <StandardText
                      style={[
                        CommonStyles.customFontMedium,
                        CommonStyles.Transparent,
                        CommonStyles.font25,
                        BrandStyles.brandBlockTxtColor3,
                      ]}
                    >
                      {SIGNUP_OPTIONS_NEW_EXISTING_SCREEN.CHOOSE_OPTION}
                    </StandardText>
                  </View>
                </View>
              </View>
              <View>
                {Platform.OS === types2.IOS ? this.renderBackButton() : null}
              </View>
            </ScrollView>
          </Animated.View>
          {/* Underlying View */}
          <View style={[CommonStyles.flexRow]}>
            {/* The hero image that comes in from the top */}
            <View style={CommonStyles.SignUpOptionsF34}>
              <Animated.View style={styles.heroBanner}>
                <FitImage
                  style={styles.heroImage}
                  source={SignUpOptionsNewExistingImage}
                />
                <Image
                  style={CommonStyles.liLogoCorner}
                  source={BrandIcons.LOGO_ICON}
                />
              </Animated.View>
            </View>
            {/* The content view - this sets it's background colour and animation to come in from the bottom */}
            <Animated.View
              style={[
                styles.backgroundContent,
                BrandStyles.SignUpOptionsNewExistingBackgroundColor,
              ]}
            />
          </View>
        </View>
      </View>
    );
  }

  navigateToNext(planType: any) {
    getDataLoggedIn(types.PREVIOUSLY_SIGNIN).then(alreadyloggedIn => {
      let signUpType = this.props.navigation.state.params.signUpType;
      this.props.navigation.navigate('LoginSignUp', {
        signUpType: signUpType,
        screenMode: alreadyloggedIn
          ? types.LOGINSIGNUP_MODE_LOGIN
          : types.LOGINSIGNUP_MODE_SIGNUP,
        planType: planType,
      });
    });
  }

  _determineMainMargin = (signUpType: any) => {
    let marginStyle = [];
    if (signUpType == types.SIGNUP_TYPE_PARTICIPANT) {
      marginStyle.push(CommonStyles.SignUpOptionsNewExistingMT40);
    } else if (signUpType == types.SIGNUP_TYPE_CREW) {
      marginStyle.push(CommonStyles.SignUpOptionsNewExistingMT30);
    }
    return marginStyle;
  };

  renderBackButton() {
    return (
      <StandardButton
        style={[
          CommonStyles.textAlignCentre,
          CommonStyles.font37,
          CommonStyles.customFont,
          CommonStyles.buttonText,
          BrandStyles.TextColor1,
          {padding: 10},
        ]}
        containerStyle={[
          BrandStyles.SignUpOptionsNewExistingBackgroundColor,
          CommonStyles.elevation5,
          CommonStyles.alignFlexEnd,
          BrandStyles.borderColor1,
          {borderWidth: 4, marginTop: 10, width: 100, marginRight: 10},
        ]}
        onPress={() => this.props.navigation.goBack()}
      >
        {Messages.GO_BACK.CANCEL}
      </StandardButton>
    );
  }

  _renderScreenHeader = (
    signUpType: any,
    headerText: any,
    secondaryHeaderText: any,
  ) => {
    if (signUpType == types.SIGNUP_TYPE_PARTICIPANT) {
      return (
        <View
          style={[
            CommonStyles.alignFlexStart,
            BrandStyles.brandBlockBgColor5,
            ,
            CommonStyles.alignItemsFlexStart,
            CommonStyles.alignSelfFlexStart,
          ]}
        >
          <StandardText
            style={[
              CommonStyles.customFontBold,
              CommonStyles.textAlignCentre,
              CommonStyles.font58,
              BrandStyles.TextColor1,
              BrandStyles.borderColor1,
              CommonStyles.SignUpOptionsNewExistingBW4P10,
            ]}
          >
            {headerText}
          </StandardText>
        </View>
      );
    } else if (signUpType == types.SIGNUP_TYPE_CREW) {
      return (
        <View>
          <View style={[CommonStyles.alignFlexStart]}>
            <StandardText
              style={[
                CommonStyles.customFontBold,
                CommonStyles.textAlignCentre,
                CommonStyles.font40,
                BrandStyles.brandBlockTxtColorWhite,
                BrandStyles.borderColor1,
                BrandStyles.brandBlockBgColor5,
                CommonStyles.SignUpOptionsNewExistingBW4P5,
              ]}
            >
              {headerText}
            </StandardText>
          </View>
          <View
            style={[
              CommonStyles.alignFlexStart,
              CommonStyles.SignUpOptionsNewExistingML50P,
            ]}
          >
            <StandardText
              style={[
                CommonStyles.customFontBold,
                CommonStyles.textAlignCentre,
                CommonStyles.font40,
                BrandStyles.brandBlockTxtColor4,
                BrandStyles.borderColor1,
                BrandStyles.brandBlockBgColorWhite,
                CommonStyles.SignUpOptionsNewExistingBW4P10,
              ]}
            >
              {secondaryHeaderText}
            </StandardText>
          </View>
        </View>
      );
    }
  };

  _determineContentsStyling = (signUpType: any) => {
    let contentsStyling = [
      CommonStyles.flex1,
      CommonStyles.flexColumn,
      CommonStyles.alignFlexStart,
      CommonStyles.justifyContentFlexStart,
      CommonStyles.SignUpOptionsNewExistingMLPRPLPB,
    ];
    if (signUpType == types.SIGNUP_TYPE_PARTICIPANT) {
      contentsStyling.push(CommonStyles.SignUpOptionsNewExistingPT30);
    } else if (signUpType == types.SIGNUP_TYPE_CREW) {
      contentsStyling.push(CommonStyles.SignUpOptionsNewExistingPT20);
    }
    return contentsStyling;
  };

  _determineOpt1Styling = (signUpType: any) => {
    let Opt1Style = [
      CommonStyles.textAlignCentre,
      CommonStyles.customFontBold,
      BrandStyles.brandBlockTxtColor4,
    ];
    if (signUpType == types.SIGNUP_TYPE_PARTICIPANT) {
      Opt1Style.push(
        CommonStyles.font58,
        CommonStyles.SignUpOptionsNewExistingPLPR5,
      );
    } else if (signUpType == types.SIGNUP_TYPE_CREW) {
      Opt1Style.push(
        CommonStyles.font40,
        CommonStyles.SignUpOptionsNewExistingP5,
      );
    }
    return Opt1Style;
  };

  _determineOpt1ContainerStyling = (signUpType: any) => {
    let Opt1ContainerStyle = [
      BrandStyles.primaryBgColor1,
      CommonStyles.elevation5,
      BrandStyles.borderColor1,
      CommonStyles.SignUpOptionsNewExistingBW4,
    ];
    if (signUpType == types.SIGNUP_TYPE_PARTICIPANT) {
      Opt1ContainerStyle.push(CommonStyles.SignUpOptionsNewExistingML15P);
    } else if (signUpType == types.SIGNUP_TYPE_CREW) {
      Opt1ContainerStyle.push(CommonStyles.SignUpOptionsNewExistingML10P);
    }
    return Opt1ContainerStyle;
  };

  _determineOpt2Styling = (signUpType: any) => {
    let Opt2Style = [
      CommonStyles.textAlignCentre,
      CommonStyles.customFontBold,
      BrandStyles.brandBlockTxtColor4,
    ];
    if (signUpType == types.SIGNUP_TYPE_PARTICIPANT) {
      Opt2Style.push(
        CommonStyles.font58,
        CommonStyles.SignUpOptionsNewExistingPLPR5,
      );
    } else if (signUpType == types.SIGNUP_TYPE_CREW) {
      Opt2Style.push(
        CommonStyles.font40,
        CommonStyles.SignUpOptionsNewExistingP5,
      );
    }
    return Opt2Style;
  };

  _determineOpt2ContainerStyling = (signUpType: any) => {
    let Opt2ContainerStyle = [
      BrandStyles.primaryBgColor1,
      CommonStyles.elevation5,
      BrandStyles.borderColor1,
      {borderWidth: 4},
    ];
    if (signUpType == types.SIGNUP_TYPE_PARTICIPANT) {
      Opt2ContainerStyle.push(CommonStyles.SignUpOptionsNewExistingML30P);
    } else if (signUpType == types.SIGNUP_TYPE_CREW) {
      Opt2ContainerStyle.push(CommonStyles.SignUpOptionsNewExistingML25P);
    }
    return Opt2ContainerStyle;
  };

  _determineSeparatorStyle = (signUpType: any) => {
    let separatorStyle = [
      CommonStyles.SignUpOptionsNewExistingPT,
      CommonStyles.alignSelfCenter,
    ];
    if (signUpType == types.SIGNUP_TYPE_PARTICIPANT) {
      separatorStyle.push(CommonStyles.SignUpOptionsNewExistingPR10P);
    } else if (signUpType == types.SIGNUP_TYPE_CREW) {
      separatorStyle.push(CommonStyles.SignUpOptionsNewExistingPL15P);
    }
    return separatorStyle;
  };

  _determineOpt2HelperStyling = (signUpType: any) => {
    let opt2HelperStyling = [CommonStyles.SignUpOptionsNewExistingPR20P];
    if (signUpType == types.SIGNUP_TYPE_PARTICIPANT) {
      opt2HelperStyling.push(CommonStyles.SignUpOptionsNewExistingPL20P);
    } else if (signUpType == types.SIGNUP_TYPE_CREW) {
      opt2HelperStyling.push(CommonStyles.SignUpOptionsNewExistingPL15P);
    }
    return opt2HelperStyling;
  };

  _determineInstructionStyling = (signUpType: any) => {
    let instructionStyling = [
      CommonStyles.flexRow,
      CommonStyles.flex1,
      CommonStyles.alignFlexEnd,
    ];
    if (signUpType == types.SIGNUP_TYPE_PARTICIPANT) {
      instructionStyling.push(CommonStyles.SignUpOptionsNewExistingMP5);
    } else if (signUpType == types.SIGNUP_TYPE_CREW) {
      instructionStyling.push(CommonStyles.SignUpOptionsNewExistingMT0);
    }
    return instructionStyling;
  };
}

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(UserActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignUpOptionsNewExisting);
