/*
 * Author: Gayan
 * Date: 28/05/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a component that will display the selected disability and also give a way for the user to remove the selected disability from their list.
 */

import React, {Component} from 'react';
import {View, StyleSheet, ScrollView, Image} from 'react-native';
import {
  StandardInput,
  StandardText,
  StandardButton,
} from '../Atoms';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import {CloseButtonCharcoal3x} from '../../assets/images';

class SelectedItem extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    const {organisation, removeOrg, index, showRemoveButton} = this.props;
    // Adjusts colors for read only Mode.
    let background;
    let textColor = BrandStyles.TextColor5;

    if (this.props.readOnly == 'read') {
      background = BrandStyles.readOnlyCheckboxGrey;
      textColor = BrandStyles.TextColor1;
    }

    return (
      <View
        key={index}
        style={[
          CommonStyles.flexRow,
          BrandStyles.brandBorderColor2,
          CommonStyles.SISelectedContainer,
          CommonStyles.alignSelfFlexStart,
        ]}
      >
        <View
          style={[
            background,
            CommonStyles.SISelectedPadding,
            CommonStyles.flexShrink1,
          ]}
        >
          <StandardText
            style={[CommonStyles.customFont, textColor, CommonStyles.font20]}
          >
            {organisation}
          </StandardText>
        </View>
        {this._renderRemoveBtn(
          showRemoveButton,
          organisation,
          removeOrg,
          index,
        )}
      </View>
    );
  }

  _renderRemoveBtn(
    showRemoveButton: any,
    organisation: any,
    removeOrg: any,
    index: any,
  ) {
    if (showRemoveButton) {
      return (
        <View
          style={[
            CommonStyles.SIClosePadding,
            BrandStyles.brandBorderColor2,
            CommonStyles.justifyCenter,
          ]}
        >
          <StandardButton
            onPress={() => removeOrg(index)}
            accessibilityLabel={`Remove ${organisation}`}
          >
            <Image
              source={CloseButtonCharcoal3x}
              style={[CommonStyles.SICloseIcon]}
            />
          </StandardButton>
        </View>
      );
    }
    return null;
  }
}
SelectedItem.defaultProps = {
  showRemoveButton: true,
};

export default SelectedItem;
