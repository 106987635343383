/*
 * Author: Declan Hart
 * Date: 12/12/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a component to display ratio questions
 */

import React, {Component} from 'react';
import {SegmentedCheckBox} from '../Molecules';

import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';

class StandardSegmentedControl extends Component {
  state = {
    selectedIndex: -1,
    selectedIndices: [],
  };

  constructor(props: any) {
    super(props);
    this.handleIndexChange = this.handleIndexChange.bind(this);
    this.saveSelection = this.saveSelection.bind(this);
    this.mapValuesToIndices = this.mapValuesToIndices.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.multiple) {
      this.setState({
        selectedIndices: this.mapValuesToIndices(),
      });
    } else if (!this.props.multiple && this.props.selectedValues.length > 0) {
      this.setState({
        selectedIndex: this.mapValuesToIndices().pop(),
      });
    }
  }

  mapValuesToIndices() {
    if (this.props.selectedValues.length != 0) {
      return this.props.selectedValues.map((selectedValue: any) => {
        return this.props.values.findIndex((element: any) => {
          return selectedValue.value.localeCompare(element) === 0;
        });
      });
    }
    return [];
  }

  handleIndexChange(index: any) {
    if (this.props.multiple) {
      var selectedItems = this.state.selectedIndices;
      if (selectedItems.indexOf(index) < 0) {
        selectedItems.push(index);
      } else {
        selectedItems.splice(this.state.selectedIndices.indexOf(index), 1);
      }
      this.setState({
        ...this.state,
        selectedIndices: selectedItems,
      });
      this.saveSelection(
        this.props.type,
        this.state.selectedIndices,
        null,
        null,
        true,
      );
    } else {
      this.setState(
        {
          ...this.state,
          selectedIndex: index,
        },
        this.saveSelection(this.props.type, index, null, null, true),
      );
    }
  }

  saveSelection(type: any, values: any, cost: any, number: any, isTrue: any) {
    switch (this.props.saveType) {
      case types.DRIVER:
        return this.props.save(type, values);
      case types.UNITOFMEASURE:
        return this.props.save(type, values, null, null, true);
      default:
    }
  }

  render() {
    return (
      <SegmentedCheckBox
        values={this.props.values}
        displayLables={this.props.displayLables}
        selectedIndex={this.state.selectedIndex}
        selectedIndices={this.state.selectedIndices}
        onChange={this.handleIndexChange}
        access={this.props.readOnly}
        multiple={this.props.multiple || false}
      />
    );
  }
}

export default StandardSegmentedControl;
