/*
 * Author: Thilina Herath
 * Date: 15/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Help Screen.
 */

import React, {Component} from 'react';
import {View, ScrollView, AppState} from 'react-native';
import NetInfo from '@react-native-community/netinfo';
//import {WebView} from 'react-native-webview';
import {WebViewWeb as WebView} from 'helpers/ReactNativeWebview';

import {StandardText} from '../Components/Atoms';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import * as brand from '../Constants/BrandConstants';
import {MESSAGES} from '../Constants/Messages';
import {Container} from '../Components/Molecules';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

class Help extends Component {
  state = {
    loading: false,
    saveAPIError: false,
  };
  subscription: any;

  constructor(props: any) {
    super(props);
    this._createMainContents.bind(this);
    this._createAllModals.bind(this);
    // Network connectivity handler binding to display the message from this page
    this.handleConnectivityChange = this.handleConnectivityChange.bind(this);
  }

  componentDidMount() {
    NetInfo.addEventListener(this.handleConnectivityChange);
    this.subscription=AppState.addEventListener('change', this._handleAppStateChange);
  }
  componentWillUnmount() {
    // AppState.removeEventListener('change', this._handleAppStateChange);
    this.subscription.remove();
  }

  handleConnectivityChange = (isConnected: any) => {
    if (isConnected) {
      this.setState({isConnected});
    } else {
      this.setState({isConnected});
    }
  };

  _handleAppStateChange = (nextAppState: any) => {
    if (nextAppState) {
      NetInfo.fetch().done((isConnected: any) => {
        this.setState({isConnected: isConnected});
      });
    }
  };

  render() {
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        modals={this._createAllModals}
        needsSidebar={false}
        screenType={types.SCREEN_TYPE_MAIN}
        activeScreen={types.SCREEN_HELP}
        selectedIcon={types.SCREEN_HELP}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={types2.NAVIGATION_TEXT.NEED_ASSISTANCE}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createAllModals = () => {
    let modalJSX: any = [];

    return modalJSX;
  };

  _createMainContents = () => (
    <ScrollView
      style={[CommonStyles.content, BrandStyles.primaryBgColor1]}
      contentContainerStyle={[
        CommonStyles.alignItemsCenter,
        CommonStyles.justifyContentCentre,
        CommonStyles.scrollViewVerticalCentre,
      ]}
    >
      <View
        style={[CommonStyles.mainContentContainer, BrandStyles.primaryBgColor1]}
      >
         {types.isWeb ? (
            <WebView
              title="GuideMeSummary"
              style={CommonStyles.WebViewStyle}
              srcDoc={false}
              source={brand.APP_HELP_URL}
            />
          ) : (
            <WebView
            source={brand.APP_HELP_URL}
            domStorageEnabled={true}
            startInLoadingState={true}
          />
          )}
        
      </View>
    </ScrollView>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Help);
