import {createContext} from 'react'


export type TabRootContextType = {
    screenProps:{
        logoutFunction: ()=>void,
        toggleMenu: ()=>boolean,
        getInitialMenuState: ()=>void,
      },
    ref:(nav: any) => void
    }
export const RootTabContext = createContext<TabRootContextType | null>(null);




