/*
 * Author: Josh Merkas
 * Date: 29/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Service Agreements List information.
 */

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

export default function ServiceAgreementsListReducer(state = {}, action: any) {
  switch (action.type) {
    case types.GET_SERVICE_AGREEMENTS_LIST:
      return {
        serviceAgreementsList: action.serviceAgreementsListObj,
        apiCalled: new Date(),
      };
    default:
      return state;
  }
}
