/*
 * Author: Andrew Seeley
 * Date: 22/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The profile screen, this is the landing page when you sign up/login.
 */

import React, {Component} from 'react';
import {Platform, View, Alert} from 'react-native';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

import {
  MyProfileAboutMe,
  MyProfileCrew,
  MyProfileFavouriteThings,
  MyProfileGoals,
  MyProfileHealthWellbeing,
  MyProfileHome,
  MyProfileSupports,
  OfflineNotice,
  MyProfileBanner,
} from '../Components/Organisms';
import {Container, CrewNoAccess} from '../Components/Molecules';
import {StandardText} from '../Components/Atoms';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';

import {getMember} from '../API/MemberAPI';
import {
  getLivingArrangement,
  getFavourites,
  getHealth,
} from '../API/AboutMeAPI';
import {getCrewAccessLevels} from '../API/CrewAPI';
import {getCrew} from '../API/PersonAPI';
import {getCrewCategories, getBudgetCategories} from '../API/ReferenceAPI';
import {getSupports, getGoalsForPlan} from '../API/PlanDataAPI';
import {callAPIs} from '../API/APICaller';
import * as CrewActions from '../Actions/CrewActions';
import * as MemberActions from '../Actions/MemberActions';
import * as LivingArrangementActions from '../Actions/LivingArrangementActions';
import * as FavouriteThingsActions from '../Actions/FavouriteThingsActions';
import * as HealthWellbeingActions from '../Actions/HealthWellbeingActions';
import * as SupportsActions from '../Actions/SupportsActions';
import * as GoalActions from '../Actions/GoalActions';
import * as NotificationActions from '../Actions/NotificationActions';
import * as SideMenuActions from '../Actions/SideMenuActions';
import * as GuideMeActions from '../Actions/GuideMeActions';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {getValue, setValue, removeItem} from '../API/WebPersistenceStore';
import { RootTabContext } from '../context/RootTabContext';
import {ERRORS} from '../Constants/Messages';
import {Logger} from 'aws-amplify';
import logger from 'helpers/Logger';

// Constants
const RESULT_INDEX = {
  MEMBER: 0,
  LIVING_ARRANGEMENT: 1,
  FAVOURITES: 2,
  HEALTH: 3,
  CREW_CATEGORIES: 4,
  CREW: 5,
  CREW_ACCESS: 6,
};

const PLAN_RESULT_INDEX = {
  SUPPORTS: 0,
  GOALS: 1,
  BUDGETS: 2,
  BUDGET_CATEGORIES: 3,
};

class MyProfile extends Component {
  static contextType:React.ContextType<typeof RootTabContext> = RootTabContext;
  state = {
    loading: true,
    profile: '',
    loadedProfileId: '',
    finishedLoading: [],
  };

  constructor(props: any) {
    super(props);
    this._showAboutMeScreen = this._showAboutMeScreen.bind(this);
    this._showWhereILiveScreen = this._showWhereILiveScreen.bind(this);
    this._showFavouriteThingsScreen =
      this._showFavouriteThingsScreen.bind(this);
    this._showHealthWellbeingScreen =
      this._showHealthWellbeingScreen.bind(this);
    this._showCrewScreen = this._showCrewScreen.bind(this);
    this._showSupportsScreen = this._showSupportsScreen.bind(this);
    this._showGoalsScreen = this._showGoalsScreen.bind(this);
    this._fetchSupportsAndGoalsAndBudgets.bind(this);
    this.setStateCallbackFunction = this.setStateCallbackFunction.bind(this);
  }

  componentDidUpdate(prevProps: any, prevState: any) {  
    if(this.props?.crews?.crew && !prevProps?.crews?.crew){
      this._combineCrew();
    }
    if (
      Object.getOwnPropertyNames(this.props.loadedMemberId).length > 0 &&
      this.props.loadedMemberId.loadedMemberId &&
      prevProps.loadedMemberId.loadedMemberId !=
        this.props.loadedMemberId.loadedMemberId
    ) {
      this.setState(
        {
          loadedProfileId: this.props.loadedMemberId.loadedMemberId,
        },
        this.setStateCallbackFunction,
      );
    }
  }

  setStateCallbackFunction = () => {
    let cameFromLinkedMembers = false;
    let memberObj = {
      showBanner: true,
    };
    if (
      this.props.navigation.state &&
      this.props.navigation.state.params &&
      this.props.navigation.state.params.screenCameFrom &&
      this.props.navigation.state.params.screenCameFrom.type ==
        types2.ROUTING_STATE.LINKED_MEMBERS_SCREEN
    ) {
      cameFromLinkedMembers = true;
    }
    if (!cameFromLinkedMembers) {
      if (
        this.props.user.isCrew &&
        this.props.user.numberOfMembers != 1 &&
        !this.state.loadedProfileId
      ) {
        this.props.actions.MemberActions.actionUpdateMember(memberObj);
        if (types.isWeb) {
          this.props.setLoading(false);
        } else {
          this.setState({loading: false});
        }
      } else {
        let callbackFunction = (results: any) => {
          // Get the data
          let memberPayload = results[RESULT_INDEX.MEMBER].content;
          let livingPayload = results[RESULT_INDEX.LIVING_ARRANGEMENT].content;
          let favouritesPayload = results[RESULT_INDEX.FAVOURITES].content;
          let healthPayload = results[RESULT_INDEX.HEALTH].content;
          let crewCategoryPayload =
            results[RESULT_INDEX.CREW_CATEGORIES].content.categories;
          let personCrewsPayload =
            results[RESULT_INDEX.CREW].personCrews.content;
          let organisationCrewsPayload =
            results[RESULT_INDEX.CREW].organisationCrews.content;
          let crewsAccessPayload = results[RESULT_INDEX.CREW_ACCESS].content;

          // Write the data to redux
          this.props.actions.MemberActions.actionGetMember(
            memberPayload,
            this.props.user.id,
            this.props.user.isEmployee,
            this.props.user.isFinancialStaff,
          );
          this.props.actions.LivingArrangementActions.actionGetLivingArrangement(
            livingPayload,
          );
          this.props.actions.FavouriteThingsActions.actionGetFavouriteThings(
            favouritesPayload,
          );
          this.props.actions.HealthWellbeingActions.actionGetHealthWellbeing(
            healthPayload,
          );
          this.props.actions.CrewActions.actionGetCrews(
            crewCategoryPayload,
            personCrewsPayload,
            organisationCrewsPayload,
            crewsAccessPayload,
          );
          this.props.actions.SideMenuActions.setSideMenuStatus(false);
          this.props.actions.MemberActions.actionUpdateMember(memberObj);
          if (this.props?.crews?.crew){
            this._combineCrew();
          }
          // Now call the get supports and get goals for plan
          this._fetchSupportsAndGoalsAndBudgets(memberPayload.planId);

          if (types.isWeb) {
            this.props.setLoading(false);
          } else {
            this.setState({loading: false});
          }
        };
        callAPIs(
          [
            getMember(this.state.loadedProfileId, this.props.user.demoProfile),
            getLivingArrangement(
              this.state.loadedProfileId,
              this.props.user.demoProfile,
            ),
            getFavourites(
              this.state.loadedProfileId,
              this.props.user.demoProfile,
            ),
            getHealth(this.state.loadedProfileId, this.props.user.demoProfile),
            getCrewCategories(this.state.loadedProfileId),
            getCrew(this.state.loadedProfileId, this.props.user.demoProfile),
            getCrewAccessLevels(
              this.state.loadedProfileId,
              this.props.user.demoProfile,
            ),
          ],
          callbackFunction,
          null,
          () => {
            if (types.isWeb) {
              this.props.setLoading(false);
            } else {
              this.setState({loading: false});
            }
          },
        );
      }
    } else {
      this.props.actions.MemberActions.actionUpdateMember(memberObj);
      if (types.isWeb) {
        this.props.setLoading(false);
      } else {
        this.setState({loading: false});
      }
    }
  };

  componentDidMount() {
    if (types.isWeb) {
      if (
        getValue(types2.WEB_STORE.SUPPORTS) ||
        getValue(types2.WEB_STORE.GOALS)
      ) {
        removeItem(types2.WEB_STORE.SUPPORTS);
        removeItem(types2.WEB_STORE.GOALS);
      }
      this.props.setLoading(true);
    }

    if (
      Object.getOwnPropertyNames(this.props.loadedMemberId).length > 0 &&
      this.props.loadedMemberId.loadedMemberId
    ) {
      this.setState(
        {
          loadedProfileId: this.props.loadedMemberId.loadedMemberId,
        },
        this.setStateCallbackFunction,
      );
    } else {
      Alert.alert(types2.ERROR, ERRORS.GENERIC_ISSUE_ALERT_MSG);
      this.setState(
        {loading: false},
        this.context?.screenProps
          ? () => this.context?.screenProps.logoutFunction()
          : null,
      );
    }

    /*
            Notifications are loaded in this screen initially to update the store with notifications list.
            And register an event handler that will fire when ever a notification is received
            and the handler will update the store with new list of notifications.
        */
  }

  navigate = (url: any) => {
    const {navigate} = this.props.navigation;
    const route = url.replace(/.*?:\/\//g, '');
    const id = route.match(/\/([^\/]+)\/?$/)[1];
    const routeName = route.split('/')[2];

    this.props.navigation.navigate(routeName);
  };

  /**
   * This function will fetch the support and goals once the plan id has been obtained.
   * It will also fetch budgets if required.
   * It will then update the redux with the data downloaded. The state will then update to false.
   * @param {number} planId The plan id that will be fetched for the profile.
   */
  _fetchSupportsAndGoalsAndBudgets = (planId: any) => {
    const callback = (results: any) => {
      const supportsPayload = results[PLAN_RESULT_INDEX.SUPPORTS].content;
      const goalsPayload =
        results[PLAN_RESULT_INDEX.GOALS].content.currentGoals;

      // Write the data to redux
      this.props.actions.SupportsActions.actionGetSupports(supportsPayload);
      this.props.actions.GoalActions.actionGetGoals(goalsPayload);

      // Set the state to no longer be loading
      if (types.isWeb) {
        this.props.setLoading(false);
      } else {
        this.setState({loading: false});
      }
    };

    const APIArray = [
      getSupports(
        planId,
        this.state.loadedProfileId,
        this.props.user.demoProfile,
      ),
      getGoalsForPlan(
        planId,
        this.state.loadedProfileId,
        this.props.user.demoProfile,
      ),
    ];

    callAPIs(APIArray, callback, null, () => {
      if (types.isWeb) {
        this.props.setLoading(false);
      } else {
        this.setState({loading: false});
      }
    });
  };

  render() {
    // If we are a crew & there is no loaded member ID lockout the screen
    if (
      !this.props.member.id &&
      this.props.user.isCrew &&
      this.props.user.numberOfMembers != 1
    ) {
      return (
        <View style={[CommonStyles.content]}>
          <OfflineNotice />
          <CrewNoAccess />
        </View>
      );
    }

    if (typeof document != 'undefined') {
      if (types.isWeb) {
        return this._createMainContents(true);
      } else {
        return null;
      }
    }

    // return (
    //   <View style={[CommonStyles.marginTop10]}>
    //     <StandardText
    //       style={[
    //         CommonStyles.customFontBold,
         
    //       ]}
    //     >
    //  Text Message 
    //     </StandardText>
    //   </View>
    // );
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        needsSidebar={true}
        screenType={types.SCREEN_TYPE_MAIN}
        hideBackButton={true}
        nav={this.props.navigation}
        headerTitle={types2.NAVIGATION_TEXT.MY_PROFILE}
        toggleMenu={this.props?.screenProps?.toggleMenu}
        getInitialMenuState={this.props?.screenProps?.getInitialMenuState}
        sidebarProfilePicture={true}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _combineCrew = () => {
    var combinedArray = JSON.parse(JSON.stringify(this.props.crews.crew));
    var checkingItem = this.props.livingArrangement;
    var displayArray = [];
    //initialise all items to false
    for (var i = 0; i < combinedArray.length; i++) {
      var crewArray = combinedArray[i].crews;
      for (var j = 0; j < crewArray.length; j++) {
        crewArray[j].selected = false;
      }
    }
    if (Object.getOwnPropertyNames(checkingItem).length > 0) {
      //there are some keys returned for our user
      //check if we have livingWith key
      if (checkingItem.hasOwnProperty('supportAtHome')) {
        var checkingItemArray = checkingItem.supportAtHome;
        //iterate the checkingItemArray
        for (var k = 0; k < checkingItemArray.length; k++) {
          var foundItem = false;
          // for each item in the checking array, check if it is in the other arrays, if it , set to true and break out of loop
          for (var i = 0; i < combinedArray.length; i++) {
            var crewArray = combinedArray[i].crews;
            for (var j = 0; j < crewArray.length; j++) {
              if (crewArray[j].crewId == checkingItemArray[k].id) {
                crewArray[j].selected = true;
                displayArray.push(crewArray[j]);
                foundItem = true;
                break;
              }
            }
            if (foundItem) {
              break;
            }
          }
        }
      }
    }
    var reduxObj = {
      originalData: JSON.parse(JSON.stringify(combinedArray)),
      modalData: JSON.parse(JSON.stringify(combinedArray)),
      displayData: JSON.parse(JSON.stringify(displayArray)),
    };
    //SET_WHO_I_LIVE_WITH_DATA
    this.props.actions.LivingArrangementActions.actionSetWhoILiveWithData(
      reduxObj,
    );
  };

  //Call BackFunctions for the Screen
  _showAboutMeScreen = () => this.props.navigation.navigate('AboutMe');

  _showWhereILiveScreen = () => {
    this.props.actions.NavigationParamActions.setParam({
      isCrewSelected: false,
    });
    this.props.navigation.navigate('WhereILive');
  };

  _showFavouriteThingsScreen = () =>
    this.props.navigation.navigate('FavouriteThings');

  _showHealthWellbeingScreen = () =>
    this.props.navigation.navigate('HealthWellbeing');

  _showCrewScreen = () => this.props.navigation.navigate('Crew');

  _showSupportsScreen = () => {
    if (
      this.props.supports &&
      this.props.supports.supports &&
      this.props.supports.supports.length > 0
    ) {
      this.props.navigation.navigate('Supports');
    } else {
      this.props.navigation.navigate('AddSupportsBy');
    }
  };

  _showGoalsScreen = () => {

    if(types.isWeb){
      this.props.navigation.navigate('Goal');
    }
    else {
      this.props.navigation.navigate('GoalSummary');
    }
    
  };

  _showBudgetsScreen = () => {
    this.props.navigation.navigate('Budget');
  };

  showTutorial() {
    if (this.props.user.showTutorial === true) {
      return (
        <View>
          <StandardText style={CommonStyles.customFont}>
            {' '}
            Show Tutorial{' '}
          </StandardText>
        </View>
      );
    }
    return null;
  }

  _renderOnBoardingBanner = () => {
    if (
      this.props.member.memberStatus === types2.MEMBER_STATUS_DATA.ONBOARDING &&
      this.props.member.showBanner === true
    ) {
      return <MyProfileBanner close={this._hideBanner} />;
    }
  };

  _hideBanner = () => {
    const saveObj = {
      id: this.props.member.id,
      showBanner: false,
    };
    this.props.actions.MemberActions.actionUpdateMember(saveObj);
  };

  _commonMainContent = () => {
    return (
      <View style={[CommonStyles.singleMainContentContainer]}>
        {this._renderOnBoardingBanner()}
        <View accessible={false} style={[CommonStyles.columnContainer]}>
          <MyProfileAboutMe
            reducer={this.props.member}
            modal={this._showAboutMeScreen}
          />
          <MyProfileFavouriteThings
            reducer={this.props.favouriteThings}
            modal={this._showFavouriteThingsScreen}
            readOnly={this.props.member}
          />
          <MyProfileHealthWellbeing
            reducer={this.props.healthWellbeing}
            modal={this._showHealthWellbeingScreen}
            readOnly={this.props.member}
          />
          <MyProfileHome
            reducer={this.props.livingArrangement}
            modal={this._showWhereILiveScreen}
            readOnly={this.props.member}
          />
          <MyProfileCrew
            reducer={this.props.crews}
            modal={this._showCrewScreen}
            readOnly={this.props.member}
            navigation={this.props.navigation}
            viewWidth={this.props.viewWidth}
            isMobile={this.props.isMobile}
          />
          <MyProfileSupports
            reducer={this.props.supports}
            modal={this._showSupportsScreen}
            readOnly={this.props.member}
            navigation={this.props.navigation}
            viewWidth={this.props.viewWidth}
            isMobile={this.props.isMobile}
            setLoading={this.props.setLoading}
          />
          <MyProfileGoals
            reducer={this.props.goals}
            modal={this._showGoalsScreen}
            readOnly={this.props.member}
            navigation={this.props.navigation}
            viewWidth={this.props.viewWidth}
            isMobile={this.props.isMobile}
          />
        </View>
      </View>
    );
  };

  _createMainContents = (isWeb: any) => {
    if (isWeb) {
      return (
        <View style={[CommonStyles.overlayContainer]}>
          {this._commonMainContent()}
        </View>
      );
    }
    return this._commonMainContent();
  };
}

const mapStateToProps = (state: any) => ({
  goals: state.GoalReducer,
  auth: state.AuthenticationReducer,
  user: state.UserReducer,
  crews: state.CrewReducer,
  livingArrangement: state.LivingArrangementReducer,
  member: state.MemberReducer,
  favouriteThings: state.FavouriteThingsReducer,
  healthWellbeing: state.HealthWellbeingReducer,
  loadedMemberId: state.LoadedMemberReducer,
  supports: state.SupportsReducer,
  sideMenu: state.SideMenuReducer,
  notifications: state.NotificationReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    GoalActions: bindActionCreators(GoalActions, dispatch),
    MemberActions: bindActionCreators(MemberActions, dispatch),
    LivingArrangementActions: bindActionCreators(
      LivingArrangementActions,
      dispatch,
    ),
    FavouriteThingsActions: bindActionCreators(
      FavouriteThingsActions,
      dispatch,
    ),
    HealthWellbeingActions: bindActionCreators(
      HealthWellbeingActions,
      dispatch,
    ),
    CrewActions: bindActionCreators(CrewActions, dispatch),
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
    notificationActions: bindActionCreators(NotificationActions, dispatch),
    SideMenuActions: bindActionCreators(SideMenuActions, dispatch),
    GuideMeActions: bindActionCreators(GuideMeActions, dispatch),
    NavigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
