/*
 * Author: Shelan Cooray
 * Date: 02/08/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Learn More Screens for Plan Management Options
 */

import React, {Component} from 'react';
import {View, Image} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Container} from '../Components/Molecules';
import {StandardText, BackButton} from '../Components/Atoms';

import {ProsCons} from '../Components/Organisms';
import {ProConBullets} from '../Components/Molecules';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import {ExplorePlanManagement4x, InformationIconx3} from '../assets/images';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import * as learnMore from '../Constants/PMLearnMoreConstants';
import {MESSAGES} from '../Constants/Messages';

import * as LogoutConfirmationActions from '../Actions/LogoutActions';

import {isTablet} from '../Helpers/PlatformSynchronizer';
import {getValue} from '../API/WebPersistenceStore';

const PM_OPTIONS = {
  PLAN_MANAGED: 0,
  SELF_MANAGED: 1,
  NDIA_MANAGED: 2,
};

class LearnMoreOptions extends Component {
  constructor(props: any) {
    super(props);
    this._createMainContents.bind(this);
  }

  render() {
    if (types.isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        needsSidebar={false}
        hideBackButton={false}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        headerTitle={this.getHeaderTitle()}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  getHeaderTitle = () => {
    let option = this.props.navigationParams.params
      ? this.props.navigationParams.params.lernMoreOption
      : getValue('learnMoreOption');

    if (
      option === learnMore.MANAGED_OPTIONS[PM_OPTIONS.PLAN_MANAGED].OPTION_KEY
    ) {
      return learnMore.LEARN_MORE_PLAN_MANAGEMENT.HEADER_TITLE;
    } else if (
      option === learnMore.MANAGED_OPTIONS[PM_OPTIONS.SELF_MANAGED].OPTION_KEY
    ) {
      return learnMore.LEARN_MORE_SELF_MANAGED.HEADER_TITLE;
    } else if (
      option === learnMore.MANAGED_OPTIONS[PM_OPTIONS.NDIA_MANAGED].OPTION_KEY
    ) {
      return learnMore.LEARN_MORE_NDIA_MANAGEMENT.HEADER_TITLE;
    }
    return '';
  };

  _createMainContents = () => {
    let option = this.props.navigationParams.params
      ? this.props.navigationParams.params.lernMoreOption
      : getValue('learnMoreOption');
    let learnMoreOption = {};

    if (
      option === learnMore.MANAGED_OPTIONS[PM_OPTIONS.PLAN_MANAGED].OPTION_KEY
    ) {
      learnMoreOption = learnMore.LEARN_MORE_PLAN_MANAGEMENT;
    } else if (
      option === learnMore.MANAGED_OPTIONS[PM_OPTIONS.SELF_MANAGED].OPTION_KEY
    ) {
      learnMoreOption = learnMore.LEARN_MORE_SELF_MANAGED;
    } else if (
      option === learnMore.MANAGED_OPTIONS[PM_OPTIONS.NDIA_MANAGED].OPTION_KEY
    ) {
      learnMoreOption = learnMore.LEARN_MORE_NDIA_MANAGEMENT;
    }

    let headerFont = CommonStyles.rpfont18;
    if (types.isWeb) {
      headerFont = CommonStyles.font20;
    } else if (isTablet) {
      headerFont = CommonStyles.rpfont25;
    }

    return (
      <View style={CommonStyles.screenWrapper}>
        <View
          style={[
            CommonStyles.flexDirectionRow,
            CommonStyles.PlanManagementHeading,
            CommonStyles.SummaryTileHeaderContainer,
            BrandStyles.headerContainsDataBorder,
            CommonStyles.alignCenter,
          ]}
        >
          <Image
            style={[
              CommonStyles.leapInPlanManagedIcon,
              CommonStyles.titleBoxIconMargin,
            ]}
            source={ExplorePlanManagement4x}
          />
          <View style={[CommonStyles.flex1, CommonStyles.alignFlexStart]}>
            <StandardText
              style={[
                headerFont,
                CommonStyles.customFontSemiBold,
                BrandStyles.TextColor3,
                CommonStyles.textAlignVerticalCenter,
              ]}
            >
              {learnMoreOption.OPTION_TITLE}
            </StandardText>
          </View>
        </View>
        <View
          style={[
            CommonStyles.containerImportantNotification,
            CommonStyles.alignCenter,
          ]}
        >
          <Image
            style={[
              CommonStyles.titleBoxIcon,
              CommonStyles.titleBoxIconMargin,
              CommonStyles.alignSelfCenter,
            ]}
            source={InformationIconx3}
            accessibilityLabel={MESSAGES.ACCESSIBILITY.IMPORTANT_INFORMATION}
            accessible={true}
          />
          <View
            style={[
              BrandStyles.secondaryBgColor3,
              CommonStyles.containerRoundCornersBorderless,
              CommonStyles.alignSelfCenter,
              CommonStyles.marginBottom10,
            ]}
          >
            <StandardText
              style={[
                CommonStyles.SummaryTileText,
                BrandStyles.TextColor5,
                CommonStyles.customFontMedium,
                CommonStyles.alignSelfCenter,
              ]}
            >
              {learnMoreOption.NOTICE}
            </StandardText>
          </View>
        </View>

        {this._renderHeadings(learnMoreOption.HEADINGS)}

        <View style={[CommonStyles.containerLearnMore]}>
          <ProsCons
            proData={learnMoreOption.PROS_AND_CONS.PROS}
            conData={learnMoreOption.PROS_AND_CONS.CONS}
            isMobile={this.props.isMobile}
          />
        </View>
      </View>
    );
  };

  _renderHeadings = (headings: any) => {
    let sections = headings.map((heading: any, index: any) => (
      <View key={index} style={[CommonStyles.containerLearnMore]}>
        <StandardText
          style={[
            types.isWeb ? CommonStyles.font18 : CommonStyles.rpfont20,
            BrandStyles.TextColor4,
            CommonStyles.customFontSemiBold,
          ]}
        >
          {heading.MAIN_HEADING}
        </StandardText>
        {this._renderBulletPoints(heading.BULLETS)}
      </View>
    ));

    return <View>{sections}</View>;
  };

  _renderBulletPoints = (items: any) => {
    let bullets = items.map((item: any, index: any) => (
      <ProConBullets key={index} data={item} typePro={false} />
    ));

    return (
      <View
        style={[
          CommonStyles.summaryTileText,
          CommonStyles.summaryTileTextContainer,
        ]}
      >
        {bullets}
      </View>
    );
  };
}

const mapStateToProps = (state: any) => ({
  navigationParams: state.NavigationParamsReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LearnMoreOptions);
