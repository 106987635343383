/*
 * Author: Shelan Cooray
 * Date: 04/09/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The add support screen by Crew
 */

import React, {Component} from 'react';
import {View, ScrollView, Platform} from 'react-native';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {debounce} from 'lodash';
import {announceForAccessibility} from '../Helpers/PlatformSynchronizer';
import logger from 'helpers/Logger';

import * as messages from '../Constants/Messages';
import {isWeb, SCREEN_TYPE_MAIN} from '../Constants/Constants';
import {NAVIGATION_TEXT, NEXT} from '../Constants/Constants2';
import {SupportPurple3x} from '../assets/images/vector-icons';

import {
  Container,
  BrandActivityIndicator,
  SelectedItem,
  AutoComplete,
  FormTitle,
  SummaryTile,
} from '../Components/Molecules';
import {
  StandardText,
  StandardButton,
  StandardTouchableOpacity,
} from '../Components/Atoms';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import {callAPIs} from '../API/APICaller';
import {getSupportGroupItems} from '../API/ReferenceAPI';
import * as SupportsActions from '../Actions/SupportsActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import {getParamValue} from '../Helpers/Utils';
import {setValue, getValue} from '../API/WebPersistenceStore';
import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';

const BILLER_CODE_STRING = '_bc';

class AddSupportsByCrew extends Component {
  state = {
    isLoading: false,
    orgSelected: [],
    organisation: '',
    searchText: '',
    selectedSuportName: '',
    billerCode: '',
    filteredSuport: {},
    selectedSuportItem: {},
    scrollEnabled: true,
    selectedCategories: [],
    mainTitle: messages.ADD_SUPPORTS_BY_CREW.FORM_TITLE,
    itemValue: [],
    initialFormState: {},
  };

  constructor(props: any) {
    super(props);
    this.navigateToCreateCustomSupport =
      this.navigateToCreateCustomSupport.bind(this);
    this._removeSelectedSupport.bind(this);
    this._renderSelectedSupports.bind(this);
    this._addSupport = this._addSupport.bind(this);
    this._showButton.bind(this);
  }

  componentDidUpdate(prevProps: any){
    if(!prevProps.route && this.props.route){
      this.setState({
      selectedSuportName:getParamValue(
        this.props.route,
        'itemselectedSuportItem',
        '',
        'selectedSuportName',
      ),
      billerCode: getParamValue(
        this.props.route,
        'itemselectedSuportItem',
        '',
        'billerCode',
      )
     });
    }
  }

  componentDidMount() {
    let selectedCrews = this.props.navParams.params
      ? this.props.navParams.params.crewSelected
      : [];

    // Set stored selected supports
    if (types.isWeb) {
      const storedSupportValues = JSON.parse(getValue('addSupportsByCrew'));
      if (storedSupportValues) {
        this.setState({
          selectedCategories: storedSupportValues.selectedCategories,
          itemValue: storedSupportValues.selectedSupports,
          orgSelected: storedSupportValues.orgSelected,
        });
      }
    }

    if (isWeb && !selectedCrews) {
      const crewIds = getValue('supportCrew');
      selectedCrews = this.props.crews.crewIndividual.filter(
        (crew: any) => ~crewIds.indexOf(crew.crewId),
      );

      this.props.actions.SupportsActions.addTempSupportCrewAction(
        selectedCrews,
      );
    }
    let crewDisplayText = '';
    for (let i = 0; i < selectedCrews.length; i++) {
      const crewMember = selectedCrews[i];
      if (crewDisplayText != '') {
        if (i == selectedCrews.length - 1) {
          crewDisplayText += messages.ADD_SUPPORTS_BY_CREW.AND;
          crewDisplayText += this._getName(crewMember);
        } else {
          crewDisplayText += `, `;
          crewDisplayText += this._getName(crewMember);
        }
      } else {
        crewDisplayText += this._getName(crewMember);
      }
    }
    this.setState({
      mainTitle:
        this.state.mainTitle +
        crewDisplayText +
        messages.ADD_SUPPORTS_BY_CREW.PROVIDE_YOU,
    });
    this._setInitialState();
  }

  navigateToCreateCustomSupport() {
    let selectedSupports: any = [];

    if (this.state.selectedCategories.length > 0) {
      this.props.actions.SupportsActions.addTempSupportCategoriesAction(
        this.state.selectedCategories,
      );
    }

    if (this.state.itemValue.length > 0) {
      selectedSupports = [...selectedSupports, ...this.state.itemValue];
    }

    if (selectedSupports.length > 0) {
      this.props.actions.SupportsActions.addTempSupportItemsAction(
        selectedSupports,
      );
    }

    this.props.actions.SupportsActions.addSupportStepAction(1);
    this.props.navigation.navigate('AddSupportDetails', {
      selectedSupports: this.state.itemValue,
    });

    const storeSupportValues = {
      selectedSupports,
      selectedCategories: this.state.selectedCategories,
      orgSelected: this.state.orgSelected,
    };
    // Store selected supports values
    if (types.isWeb) {
      setValue('addSupportsByCrew', JSON.stringify(storeSupportValues));
    }
    this.setState({isLoading: false});
  }

  _getName = (crew: any) => {
    if (!crew.firstName || crew.firstName === '') {
      return `${crew.orgName}`;
    } else {
      if (crew.orgName && crew.orgName !== '') {
        return `${crew.firstName} ${crew.orgName}`;
      } else {
        return `${crew.firstName}`;
      }
    }
  };

  render() {
    if (isWeb) {
      return this._createMainContents();
    }
    return (
      <Container
        contents={this._createMainContents}
        needsSidebar={true}
        screenType={SCREEN_TYPE_MAIN}
        headerTitle={NAVIGATION_TEXT.ADD_SUPPORTS}
        nav={this.props.navigation}
        sidebarProfilePicture={true}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        scrollEnabled={this.state.scrollEnabled}
        hasFormContentChanged={this._hasFormContentChanged}
        currentScreenName={AddSupportsByCrew.name}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _removeSelectedSupport = (index: any) => {
    const orgSelected = JSON.parse(JSON.stringify(this.state.orgSelected));
    const itemValues = JSON.parse(JSON.stringify(this.state.itemValue));

    // Remove the index from the temp selected Organisations
    orgSelected.splice(index, 1);
    itemValues.splice(index, 1);

    // Update the state
    this.setState({
      orgSelected,
      itemValue: itemValues,
    });
  };

  _renderSelectedSupports = () => {
    // Go through the list of selected Organisations to render on screen
    const {orgSelected} = this.state;

    const orgSelectedJSX: any = [];
    let indexCounter = 0;
    orgSelected.map(organisation => {
      orgSelectedJSX.unshift(
        <SelectedItem
          key={indexCounter}
          index={indexCounter}
          organisation={organisation}
          removeOrg={this._removeSelectedSupport}
        />,
      );
      indexCounter++;
    });

    return (
      <View
        style={[
          CommonStyles.containerSearchResults,
          isWeb ? CommonStyles.flex1 : {},
        ]}
      >
        {orgSelected.length > 0 && orgSelectedJSX}
      </View>
    );
  };

  _addSupport() {
    const selectedSupports = this.state.orgSelected;
    let duplicateItem = false;
    selectedSupports.map(org => {
      if (org.toLowerCase() === this.state.organisation.toLowerCase()) {
        duplicateItem = true;
        this.setState({
          organisation: '',
          selectedSuportName: '',
          filteredSuport: [],
        });
      }
    });

    if (!duplicateItem && this.state.organisation !== '') {
      selectedSupports.push(this.state.organisation);
      const itemValue = this.state.itemValue;
      itemValue.push({
        description: this.state.organisation,
      });
      this.setState({
        orgSelected: selectedSupports,
        organisation: '',
        itemValue,
        selectedSuportName: '',
        filteredSuport: [],
      });
    }
  }

  _setFilterdSupport(data: any) {
    this.setState({scrollEnabled: false, isLoadingSearch: false});
    if (data[0].content.groupItems.length > 0) {
      const filteredSuport = data[0].content.groupItems.filter(function (
        item: any,
        index: any,
        array: any,
      ) {
        return item.groupId;
      });
      if (
        this.state.searchText != '' &&
        this.state.searchText != undefined &&
        this.state.searchText.length > 1
      ) {
        this.setState({
          filteredSuport,
          isLoadingSearch: false,
        });
      } else {
        this.setState({
          filteredSuport: [],
          isLoadingSearch: false,
        });
      }
    }
  }

  _filteredSupportFailed(err: any) {
    logger.log('notifications error: ', err);
    this.setState({isLoadingSearch: false, filteredSuport: []});
  }

  _fetchSearchData(requestBody: any) {
    callAPIs(
      [getSupportGroupItems(requestBody, false)],
      (data: any) => this._setFilterdSupport(data),
      (err: any) => this._filteredSupportFailed(err),
    );
    this.setState({isLoadingSearch: true});
  }

  debouncedSearchFilteredSupportFunc = debounce(
    requestBody => this._fetchSearchData(requestBody),
    1000,
  );

  debouncedSearchFilteredSupport(query: any) {
    this.setState({
      searchText: query,
      selectedSuportName: query,
      organisation: query,
      billerCode: '',
    });
    const requestBody = {
      searchTerm: query,
      page: 1,
      itemsPerPage: 50,
    };
    this.debouncedSearchFilteredSupportFunc(requestBody);
  }

  _renderSearchItem = (item: any) => {
    return (
      <StandardTouchableOpacity
        accessibilityLabel={item.type + ' selected'}
        key={item.description}
        style={[CommonStyles.listItem]}
        onPress={() => {
          this._setSuportItem(item);
        }}
      >
        <StandardText
          style={[CommonStyles.StandardFontSize, CommonStyles.customFont]}
        >
          <StandardText style={[CommonStyles.customFont]}>
            {item.description}
          </StandardText>
        </StandardText>
      </StandardTouchableOpacity>
    );
  };

  _setSuportItem = (item: any) => {
    this.setState({
      filteredSuport: [],
      searchText: item.description,
      selectedSuportName: item.description,
      selectedSuportItem: item,
      billerCode: item.Id,
      scrollEnabled: true,
    });

    this.setState(prevState => ({
      supportItems: {
        ...prevState.supportItems,
        billerKey: item.Id,
        billerCode: item.Id + BILLER_CODE_STRING,
        selectedSuportName: item.description,
        selectedSuportItem: item,
      },
    }));

    const selectedOrganisation = this.state.orgSelected;
    let duplicateItem = false;
    selectedOrganisation.map(org => {
      if (org.includes(item.description)) {
        duplicateItem = true;
        this.setState({
          organisation: '',
        });
      }
    });

    if (!duplicateItem) {
      selectedOrganisation.push(item.description);
      const itemValue = this.state.itemValue;
      itemValue.push(item);

      const selectedCategories = this.state.selectedCategories;
      selectedCategories.push(item.categoryId);

      this.setState({
        orgSelected: selectedOrganisation,
        organisation: '',
        itemValue,
        selectedCategories,
      });
    }

    this.setState({
      searchText: '',
      selectedSuportName: '',
      billerCode: '',
      selectedSuportItem: {},
      filteredSuport: {},
    });
  };

  _renderPlaceHolderText = () => {
    if (this.state.orgSelected.length > 0) {
      return messages.ADD_SUPPORTS_BY_CREW.SEARCH_FIELD_TEXT_NON_EMPTY;
    } else {
      return messages.ADD_SUPPORTS_BY_CREW.SEARCH_FIELD_TEXT_EMPTY;
    }
  };

  _showButton() {
    return (
      <View
        style={[
          CommonStyles.AddSupportsFlex1,
          CommonStyles.AddSupportsMT15,
          CommonStyles.addSupportsMB,
        ]}
      >
        <View
          style={[
            CommonStyles.inputContainerFullWidth,
            CommonStyles.PlatformPaddingBottom10,
            CommonStyles.addSupportsMR10,
            CommonStyles.addSupportsML2,
            types.isWeb || Platform.OS === types2.IOS
              ? CommonStyles.zIndex02
              : null,
          ]}
        >
          <AutoComplete
            readOnly={false}
            data={this.state.filteredSuport}
            listStyle={[CommonStyles.content, CommonStyles.listContainer]}
            onChangeText={(text: any) =>
              this.debouncedSearchFilteredSupport(text)
            }
            placeholder={this._renderPlaceHolderText()}
            renderItem={(item: any) => this._renderSearchItem(item)}
            returnKeyType={'done'}
            style={[
              CommonStyles.SupportTextInputWrapper,
              CommonStyles.StandardFontSize,
              CommonStyles.paddingLeft20,
            ]}
            rowStyle={true}
            border={BrandStyles.borderColor4}
            multiline={false}
            numberOfLines={3}
            value={
              this.state.billerCode
                ? this.state.selectedSuportName +
                  ' (' +
                  this.state.billerCode +
                  ')'
                : this.state.selectedSuportName
            }
            underlineColorAndroid="transparent"
            isLoadingSearch={this.state.isLoadingSearch}
            accessibilityLabelClear={'Clear Supports'}
            canAddCustom={true}
            addCustom={this._addSupport}
            onClear={() => {
              this.setState({
                searchText: '',
                selectedSuportName: '',
                billerCode: '',
                selectedSuportItem: {},
                filteredSuport: {},
                scrollEnabled: true,
              });
            }}
            onFocus={() => {
              this.setState({keyboardDismissMode: 'on-drag'});
            }}
            onBlur={() => {
              this.setState({keyboardDismissMode: 'none'});
            }}
          />
        </View>

        <View
          style={[
            CommonStyles.flexRow,
            CommonStyles.flexWrap,
            CommonStyles.marginTop10,
            CommonStyles.zIndex1,
          ]}
        >
          {this._renderSelectedSupports()}
        </View>

        <View
          style={[CommonStyles.justifyFlexRowEnd, CommonStyles.marginRight10]}
        >
          {this._renderNextButton()}
        </View>
      </View>
    );
  }

  _renderNextButton = () => {
    let buttonDisable = true;
    if (this.state.itemValue.length > 0) {
      // announceForAccessibility(messages.NEXT_BUTTON.NEXT_BUTTON_MESSAGE);
      buttonDisable = false;
    }
    return (
      <StandardButton
        onPress={() => this.navigateToCreateCustomSupport()}
        style={this._renderButtonStyle(buttonDisable)}
        containerStyle={this._renderButtonContainerStyle(buttonDisable)}
        accessibilityLabel={NEXT}
        disabled={buttonDisable ? true : false}
      >
        {NEXT}
      </StandardButton>
    );
  };

  _renderButtonStyle = (buttonDisable: any) => {
    if (buttonDisable) {
      return [BrandStyles.TextColor10, CommonStyles.buttonFormAction];
    } else {
      return [BrandStyles.TextColor1, CommonStyles.buttonFormAction];
    }
  };

  _renderButtonContainerStyle = (buttonDisable: any) => {
    if (buttonDisable) {
      return [
        BrandStyles.secondaryBgColor3,
        BrandStyles.borderColor8,
        CommonStyles.buttonContainerFormAction,
      ];
    } else {
      return [
        BrandStyles.primaryBgColor4,
        BrandStyles.brandBorderColor1,
        CommonStyles.buttonContainerFormAction,
      ];
    }
  };

  _createMainContents = () => (
    <View style={[CommonStyles.flexColumn, CommonStyles.content]}>
      <View style={[CommonStyles.marginBottom20]}>
        <SummaryTile
          headerDisplayString={messages.ADD_SUPPORTS_BY.TITLE}
          headerIconImage={SupportPurple3x}
          HeaderTextColor={BrandStyles.TextColor4}
          HeaderBoarderColor={BrandStyles.borderColor4}
          hideYellowStar={true}
          showButton={false}
          fontSize={CommonStyles.font20}
          disableMore={true}
        />
      </View>
      <FormTitle
        text={this.state.mainTitle}
        textColor={BrandStyles.TextColor5}
        containsData={true}
      />
      <ScrollView style={[CommonStyles.AddSupportsFlex1]}>
        <View
          style={[
            CommonStyles.alignFlexStart,
            CommonStyles.justifyContentFlexStart,
            CommonStyles.contentFlexWrap,
          ]}
        >
          {this._showButton()}
        </View>
      </ScrollView>
      <BrandActivityIndicator loading={this.state.isLoading} />
    </View>
  );

  _setInitialState = () => {
    this.setState({
      initialFormState: {
        orgSelected: [],
      },
    });
    if (isWeb) {
      this.props.setLoading(false);
    }
  };
  _hasFormContentChanged = () => {
    const initialFormState = this.state.initialFormState;
    const currentFormState = {
      orgSelected: this.state.orgSelected,
    };

    return (
      JSON.stringify(initialFormState) !== JSON.stringify(currentFormState)
    );
  };
}

const mapStateToProps = (state: any) => ({
  categories: state.SupportCategoriesReducer.categories,
  supportGroups: state.SupportItemGroupsReducer.supportGroups,
  member: state.MemberReducer,
  supports: state.SupportsReducer,
  navParams: state.NavigationParamsReducer,
  crews: state.CrewReducer,
  user: state.UserReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    SupportsActions: bindActionCreators(SupportsActions, dispatch),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSupportsByCrew);
