/*
*Author: Josh Merkas
*Date: 08/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.

*This is a standard picker component which adds support for the following
* - Read Only View

*/

import React, {Component} from 'react';
import {View, Text, Image} from 'react-native';
import PropTypes from 'prop-types';

import {ACCESS_READ_ONLY, isWeb} from '../../Constants/Constants';

import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';
import RNPickerSelect from 'react-native-picker-select-enhanced';
import {INPUT_CONTROL_ACCESSIBILITY} from '../../Constants/Messages';

class StandardPicker extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    let disableAccess = false;
    let background = CommonStyles.PickerBackground;
    let pickerTextColor = BrandStyles.TextColor2;
    let selectedValue = this.props.pickerOptions[0];
    if (this.props.selectedValue) {
      selectedValue = this.props.pickerOptions.find((item: any) => {
        if (typeof item.type == 'number' && isWeb) {
          return item.type === Number(this.props.selectedValue);
        } else {
          return item.type === this.props.selectedValue;
        }
      });
    }

    if (this.props.access == ACCESS_READ_ONLY) {
      disableAccess = true;
      background = CommonStyles.PickerReadOnlyBackground;
      if (!this.props.selectedValue) {
        pickerTextColor = BrandStyles.TextColor6;
      }
    }

    //This is for Web App to replace readonly fields with just Text values
    if (isWeb && disableAccess) {
      return (
        <View style={[CommonStyles.width100]}>
          <View style={[CommonStyles.ModalTextInputContainer]}>
            <Text {...this.props} style={this._readOnlyWebStyle()}>
              {this._renderReadOnlyValue(
                this.props.selectedValue,
                this.props.pickerOptions,
              )}
            </Text>
          </View>
        </View>
      );
    }

    return (
      <RNPickerSelect
        disabled={disableAccess}
        placeholder={{}}
        items={this.props.pickerOptions.map((item, index) => ({
          value: item.type ? item.type : item.description,
          label: item.description,
        }))}
        accessibilityLabel={
          selectedValue?.description + INPUT_CONTROL_ACCESSIBILITY.DROPDOWN
        }
        onValueChange={this.props.onValueChange}
        onUpArrow={() => {}}
        onDonePress={this.props.onDonePress}
        onDownArrow={() => {}}
        style={
          disableAccess
            ? {
                ...{
                  inputIOS: [CommonStyles.pickerGeneral, background],
                },
              }
            : {
                ...{
                  inputIOS: CommonStyles.pickerGeneral,
                },
              }
        }
        value={this.props.selectedValue}
      />
    );
  }

  _renderReadOnlyValue = (selectedValue: any, pickerOptions: any) => {
    let selectedItemDesc = selectedValue;
    pickerOptions.find((item: any) => {
      if (item.type === selectedValue) {
        selectedItemDesc = item.description;
      }
    });
    return selectedItemDesc;
  };

  _readOnlyWebStyle = () => {
    return [
      CommonStyles.customFontInput,
      CommonStyles.font15,
      CommonStyles.inputFieldWrapper,
      BrandStyles.TextColor2,
      BrandStyles.readOnlyTextfieldGrey,
      CommonStyles.padding15,
      CommonStyles.width100,
    ];
  };
}

StandardPicker.propTypes = {
  pickerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default StandardPicker;
