/*
 * Author: Josh Merkas
 * Date: 22/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * The Payments History Screen
 */

import React, {Component} from 'react';
import {View} from 'react-native';
import {InvoiceDollarRed2x} from '../assets/images/vector-icons';
import {debounce, groupBy, toPairs, filter, chain} from 'lodash';
import {paginate} from '../Helpers/Utils';
import {
  Container,
  SummaryTile,
  PaymentDetailTile,
  PaginationNavigator,
} from '../Components/Molecules';
import {StandardSearch, StandardText} from '../Components/Atoms';

import {connect} from 'react-redux';
import * as NavigationParamActions from '../Actions/NavigationParamActions';
import * as LogoutConfirmationActions from '../Actions/LogoutActions';
import * as paymentHistoryActions from '../Actions/paymentHistoryActions';
import {bindActionCreators} from 'redux';

import {callAPIs} from '../API/APICaller';
import {getInvoiceHistory} from '../API/PlanDataAPI';

//Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';

import * as types from '../Constants/Constants';
import * as types2 from '../Constants/Constants2';
import {PAYMENTS_HISTORY_SCREEN as messages} from '../Constants/Messages';



class PaymentsHistory extends Component {
  state = {
    loading: true,
    invoiceHistoryList: [],
    searchInput: '',
    pageSize: types.isWeb ? 3 : 12,
    pageNumber: 1,
    totalPages: 1,
    isPaginationMetaDataAvailable: false,
    refreshing: false,
    totalCount: 0,
    invoiceHistoryListByKey: [],
    categorizedInvoiceArray: [],
  };
  constructor(props: any) {
    super(props);
    this._createMainContents.bind(this);
    this._itemOnPress.bind(this);
    this._notifPaymentDetails.bind(this);
    this.onRefresh.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (
      this.props.member &&
      this.props.member.memberStatus !== types2.MEMBER_STATUS_DATA.MANAGED &&
      !(
        this.props.member.memberStatus ===
          types2.MEMBER_STATUS_DATA.PLAN_READY && this.props.member.pmExitDate
      )
    ) {
      if (types.isWeb) {
        this.props.navigation.navigate(types2.DEEP_LINK_SCREENS.BUDGETS);
        this.props.setLoading(false);
      } else {
        this.setState({loading: false});
      }
    }
  }

  componentDidMount = () => {
    const searchInput =
      this.props.navigation.state &&
      this.props.navigation.state.params &&
      this.props.navigation.state.params.data
        ? this.props.navigation.state.params.data
        : this.state.searchInput;
    const pageNumber = searchInput ? 1 : this.state.pageNumber;

    this._loadInitialData(searchInput, pageNumber, this.state.pageSize);

    if (types.isWeb) {
      this.props.setLoading(true);
    } else {
      this.setState({loading: true});
    }

    if (
      this.props.member &&
      this.props.member.memberStatus !== types2.MEMBER_STATUS_DATA.MANAGED &&
      !(
        this.props.member.memberStatus ===
          types2.MEMBER_STATUS_DATA.PLAN_READY && this.props.member.pmExitDate
      ) || (this.props.paymentsHistory && this.props.paymentsHistory.paymentHistoryData)
    ) {
      if (types.isWeb) {
        this.props.setLoading(false);
      } else {
        this.setState({loading: false});
      }
    }
  };

  _loadInitialData = (
    searchQuery: any,
    pageNumber: any,
    pageSize: any,
    pullRefresh = false,
  ) => {
    const callbackFunction = (data: any) => {
      let pageNumberValue = pageNumber;
      const invoiceHistory = data[0].content;
      const paginationMetadata = data[0].content.paginationMetadata;
      if (
        pullRefresh &&
        JSON.stringify(invoiceHistory.invoices) !==
          JSON.stringify(this.state.invoiceHistoryList)
      ) {
        pageNumberValue = 1;
      } else {
        pageNumberValue = !!paginationMetadata
          ? paginationMetadata.currentPage
          : this.state.pageNumber;
      }

      this.props.actions.paymentHistoryActions.setPaymentHistoryData(data)

      if (types.isWeb) {
        this._loadAllRecords(invoiceHistory.invoices, pageNumber, pageSize);
      } else {

        this.setState(
          {
            invoiceHistoryList: invoiceHistory.invoices,
            loading: false,
            refreshing: false,
            isPaginationMetaDataAvailable: !!paginationMetadata,
            pageNumber: pageNumberValue,
            pageSize: !!paginationMetadata
              ? paginationMetadata.pageSize
              : this.state.pageSize,
            totalPages: !!paginationMetadata
              ? paginationMetadata.totalPages
              : this.state.totalPages,
            totalCount: !!paginationMetadata
              ? paginationMetadata.totalCount
              : this.state.totalCount,
          },
          () => {
            this._notifPaymentDetails();
          },
        );
      }
    };


    let searchQueryInput: string;
    let pageNumberInput: number;
    if(this.props.paymentsHistory && this.props.paymentsHistory.searchQuery && this.props.paymentsHistory.pageNumber){
      searchQueryInput = this.props.paymentsHistory.searchQuery;
      pageNumberInput = parseInt(this.props.paymentsHistory.pageNumber);
    }


    if(searchQueryInput === searchQuery &&  pageNumberInput === pageNumber){
      const paymentHistoryData = this.props.paymentsHistory.paymentHistoryData;
      callbackFunction(paymentHistoryData);
    }else{
      this.props.actions.paymentHistoryActions.setSearchQuery(searchQuery)
      this.props.actions.paymentHistoryActions.setPageNumber(pageNumber)
      callAPIs(
        [
          getInvoiceHistory(
            this.props.loadedMemberId.loadedMemberId,
            searchQuery,
            pageNumber,
            pageSize,
            this.props.user.demoProfile,
          ),
        ],
        callbackFunction,
        null,
        () => {
          if (types.isWeb) {
            this.props.setLoading(false);
          }
          this.setState({loading: false, refreshing: false});
        },
      );
    }
  };

  onRefresh = () => {
    this.setState({refreshing: true});
    this._loadInitialData(
      this.state.searchInput,
      this.state.pageNumber,
      this.state.pageSize,
      true,
    );
  };

  _notifPaymentDetails = () => {
    if (this.props.navigation.params && this.props.navigation.params.data) {
      const item = this.state.invoiceHistoryList.find(
        item => item.invoiceKey === this.props.navigation.state.params.data,
      );
      if (item) {
        this._itemOnPress(item);
      }
    }
  };

  _notifPaymentDetailsWeb = () => {
    if (
      this.props.navigationParams &&
      this.props.navigationParams.params?.data
    ) {
      const item = this.state.invoiceHistoryList.find(
        item => item.invoiceKey === this.props.navigationParams.params?.data,
      );
      if (item) {
        this._itemOnPress(item);
      }
    }
  };

  render() {
    if (types.isWeb) {
      return <View>{this._createMainContents()}</View>;
    }
    return (
      <Container
        contents={this._createMainContents}
        loading={this.state.loading}
        screenType={types.SCREEN_TYPE_MAIN}
        nav={this.props.navigation}
        headerTitle={types2.NAVIGATION_TEXT.PAYMENTS_HISTORY}
        needsSideBar={false}
        toggleMenu={this.props.screenProps?.toggleMenu}
        getInitialMenuState={this.props.screenProps?.getInitialMenuState}
        ignorePadding={true}
        onRefresh={this.onRefresh}
        refreshing={this.state.refreshing}
        demoProfile={this.props.user.demoProfile}
        showConfirmLogout={
          this.props.actions.LogoutConfirmationActions.showConfirmLogout
        }
      />
    );
  }

  _createMainContents = () => {
    return (
      <View style={[CommonStyles.flexColumn]}>
        <View style={[CommonStyles.marginLeft10, CommonStyles.marginRight10]}>
          <SummaryTile
            headerDisplayString={'Payments History'}
            headerIconImage={InvoiceDollarRed2x}
            HeaderTextColor={BrandStyles.TextColor3}
            HeaderBoarderColor={BrandStyles.borderColor3}
            hideYellowStar={true}
            showButton={false}
            fontSize={CommonStyles.summaryTileHeaders}
            iconStyle={[
              CommonStyles.titleBoxIconLarge,
              CommonStyles.titleBoxIconMargin,
            ]}
            disableMore={true}
          />
        </View>

        {types.isWeb ? (
          <StandardText
            style={[
              CommonStyles.subHeaderText,
              CommonStyles.customFontBold,
              CommonStyles.searchContainer,
              types.isWeb ? CommonStyles.font15 : null,
            ]}
          >
            {messages.SEARCH_PREVIOUS_PAYMENTS}
          </StandardText>
        ) : null}

        <View style={[CommonStyles.searchContainer]}>
          <StandardSearch
            border={CommonStyles.borderWidth0}
            underlineColorAndroid="transparent"
            value={this.state.searchInput}
            placeholder={messages.SEARCH_PAYMENTS}
            onChangeText={(searchInput: any) =>
              this.setState({searchInput}, () => {
                debounce(() => {
                  if (types.isWeb) {
                    this.props.setLoading(true);
                  } else {
                    this.setState({loading: true});
                  }
                  this._loadInitialData(
                    this.state.searchInput,
                    1,
                    this.state.pageSize,
                  );
                }, 3000)();
              })
            }
          />
        </View>

        {this.renderResultSummary()}
        {this._renderList()}
        {this.state.isPaginationMetaDataAvailable && this.renderPagination()}
      </View>
    );
  };

  _loadPaginatedData = (pageNumber: any, pageSize: any) => {
    var paginationMetadata = {
      pageSize: pageSize,
      currentPage: pageNumber,
    };

    if (types.isWeb) {
      this.props.setLoading(false);
    }

    this.setState({
      invoiceHistoryListByKey: paginate(
        this.state.categorizedInvoiceArray,
        pageSize,
        pageNumber,
      ),
      loading: false,
      refreshing: false,
      isPaginationMetaDataAvailable: !!paginationMetadata,
      pageNumber: pageNumber,
      pageSize: !!paginationMetadata
        ? paginationMetadata.pageSize
        : this.state.pageSize,
    });
  };

  _loadAllRecords = (data: any, pageNumber: any, pageSize: any) => {
    if (data.length !== 0) {
      const categorizedList = groupBy(
        data.sort(
          (a: any, b: any) =>
            new Date(b.submittedDate) - new Date(a.submittedDate),
        ),
        function (a) {
          const date = new Date(a.submittedDate);
          return new Date(date.getFullYear(), date.getMonth(), 1);
        },
      );

      const categorizedArray = toPairs(categorizedList);

      var paginationMetadata = {
        totalCount: categorizedArray.length,
        pageSize: pageSize,
        currentPage: pageNumber,
        totalPages: Math.ceil(categorizedArray.length / pageSize),
      };
      this.props.setLoading(false);
      this.setState(
        {
          categorizedInvoiceArray: categorizedArray,
          invoiceHistoryListByKey: paginate(
            categorizedArray,
            pageSize,
            pageNumber,
          ),
          invoiceHistoryList: data,
          loading: false,
          refreshing: false,
          isPaginationMetaDataAvailable: !!paginationMetadata,
          pageNumber: pageNumber,
          pageSize: !!paginationMetadata
            ? paginationMetadata.pageSize
            : this.state.pageSize,
          totalPages: !!paginationMetadata
            ? paginationMetadata.totalPages
            : this.state.totalPages,
          totalCount: !!paginationMetadata
            ? paginationMetadata.totalCount
            : this.state.totalCount,
        },
        () => {
          this._notifPaymentDetailsWeb();
        },
      );
    } else {
      this.props.setLoading(false);
    }
  };

  renderPagination = () => {
    return (
      <PaginationNavigator
        categorizeBy={types.isWeb ? 'months' : null}
        pageNumber={this.state.pageNumber}
        totalPages={this.state.totalPages}
        pageSize={this.state.pageSize}
        goToNextPage={() => {
          if (types.isWeb) {
            this.props.setLoading(true);
            this._loadPaginatedData(
              ++this.state.pageNumber,
              this.state.pageSize,
            );
          } else {
            this.setState({loading: true});
            this._loadInitialData(
              this.state.searchInput,
              ++this.state.pageNumber,
              this.state.pageSize,
            );
          }
        }}
        goToPreviousPage={() => {
          if (types.isWeb) {
            this.props.setLoading(true);
            this._loadPaginatedData(
              --this.state.pageNumber,
              this.state.pageSize,
            );
          } else {
            this.setState({loading: true});
            this._loadInitialData(
              this.state.searchInput,
              --this.state.pageNumber,
              this.state.pageSize,
            );
          }
        }}
      />
    );
  };
  renderResultSummary = () => {
    if (
      this.state.invoiceHistoryList &&
      this.state.invoiceHistoryList.length > 0
    ) {
      const pageResultCount = this.state.pageNumber * this.state.pageSize;
      const lastIndex =
        pageResultCount > this.state.totalCount
          ? this.state.totalCount
          : pageResultCount;
      const startIndex =
        this.state.pageNumber === 1
          ? this.state.pageNumber
          : (this.state.pageNumber - 1) * this.state.pageSize + 1;
      return (
        <View style={CommonStyles.alignItemsCenter}>
          <StandardText
            style={[
              CommonStyles.textResultSummary,
              CommonStyles.customFontBold,
              BrandStyles.TextColor13,
            ]}
          >
            {`${types2.RESULT_COUNT.SHOW}${startIndex}-${lastIndex}${types2.RESULT_COUNT.OF}${this.state.totalCount}${types2.RESULT_COUNT.RESULTS}`}
          </StandardText>
        </View>
      );
    }
  };

  _renderList = () => {
    return (
      <PaymentDetailTile
        viewWidth={this.props.viewWidth}
        isMobile={this.props.isMobile}
        data={
          types.isWeb
            ? this.state.invoiceHistoryListByKey
            : this.state.invoiceHistoryList
        }
        categorize={types.isWeb ? true : false}
        extraData={this.state}
        isPositive={true}
        itemOnPress={this._itemOnPress.bind(this)}
      />
    );
  };

  _itemOnPress = (item: any) => {
    this.props.actions.navigationParamActions.setParam(item);
    this.props.navigation.navigate('PaymentDetails');
  };
}

const mapStateToProps = (state: any) => ({
  loadedMemberId: state.LoadedMemberReducer,
  navigationParams: state.NavigationParamsReducer,
  member: state.MemberReducer,
  user: state.UserReducer,
  paymentsHistory: state.PaymentHistoryReducer
});

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    navigationParamActions: bindActionCreators(
      NavigationParamActions,
      dispatch,
    ),
    LogoutConfirmationActions: bindActionCreators(
      LogoutConfirmationActions,
      dispatch,
    ),
    paymentHistoryActions: bindActionCreators(
      paymentHistoryActions,
      dispatch
    )
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsHistory);
