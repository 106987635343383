/*
 * Author: Andrew Seeley
 * Date: 18/11/2017
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This page is the kick off for requesting access to a member
 */

import React, {Component} from 'react';
import {Platform, StyleSheet, Text, View, ScrollView} from 'react-native';
import {StandardButton} from '../Components/Atoms';
// import {NavigationActions} from 'react-navigation';

import {StandardInput, StandardText} from '../Components/Atoms';

// Import Stylesheets
import CommonStyles from '../Styles/CommonStyles';
import BrandStyles from '../Styles/BrandStyles';
import * as brand from '../Constants/BrandConstants';
import {OfflineNotice} from '../Components/Organisms';

class RequestMemberAccessHome extends Component {
  state = {email: ''};

  render() {
    return (
      <View style={CommonStyles.screenWrapper}>
        <OfflineNotice />
        <ScrollView
          style={[CommonStyles.content]}
          contentContainerStyle={[
            CommonStyles.alignItemsCenter,
            CommonStyles.justifyContentCentre,
            CommonStyles.scrollViewVerticalCentre,
          ]}
        >
          <StandardText style={CommonStyles.customFont}>
            {' '}
            Request access to their {brand.LEAP_IN_COMPANY_NAME} account?{' '}
          </StandardText>
          <StandardInput
            value={this.state.email}
            placeholder="Enter their email address"
            onChangeText={(email: any) => {
              let emailTrimmed = email.trim();
              this.setState({email: emailTrimmed});
            }}
          />
          <StandardButton
            style={[BrandStyles.TextColor1]}
            containerStyle={[
              CommonStyles.buttonContainer,
              BrandStyles.secondaryBgColor2,
              CommonStyles.alignSelfStretch,
            ]}
            onPress={this.navigateNext.bind(this)}
          >
            Next
          </StandardButton>
        </ScrollView>
      </View>
    );
  }

  navigateNext() {
    this.props.navigation.navigate('RequestMemberAccessMessageSuccess', {
      navigateType: 'ReplaceCurrentScreen',
    });
  }
}

export default RequestMemberAccessHome;
