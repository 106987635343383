/*
 * Author: Mayuran
 * Date: 15/02/2019
 * Copyright © 2019 Leap in!. All rights reserved.
 *
 * Displays side menu content for web
 */

import React from 'react';
import {View} from 'react-native';

//Import custom components
import {
  WebAccordianMenu,
  NotificationBadge,
  GuideMeSummary,
} from '../Molecules';
import {StandardText, StandardTouchableOpacity} from '../Atoms';

//Import Styles
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import * as env from '../../environments';
import * as types from '../../Constants/Constants';

//Import external functionality
import {isEmpty} from 'lodash';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {getValue, setValue, removeItem} from '../../API/WebPersistenceStore';
import {hasBudgetAccess} from '../../Helpers/determineAnyBudgetAccess';

//Import Constants
import {
  ACCESS_FULL,
  ACCESS_READ_ONLY,
  ACCESS_NONE,
  SCREEN_NOTIFICATIONS,
  isWeb,
  DraftInvoice
} from '../../Constants/Constants';
import {
  PRIMARY_TABS,
  SECONDARY_TABS,
  BUDGET_PAYMENTS,
  NOTIFICATIONS,
  MEMBER_STATUS_DATA,
  PROFILE,
  MEMBERS,
  APPROVAL_TYPE,
  PLAN_STATUS_DATA,
  BUDGET_ONBOARD_DISABLED_MENU,
  PaymentsHistory,
  PLANS,
  PROFILE_DISABLED_MENU,
  DOCUMENTS,
  GUIDE_ME_STEPS,
  WEB_HEIGHLIGHT,
  WEB_STORE,
  SIGNUP_FOR_PLAN_MANAGEMENT,
  UPLOADPLAN,
  MENUE_ID,
  NAVIGATION_PRIMARY_HEADERS,
  MenuItemsBudget
} from '../../Constants/Constants2';
import {APP_ID} from '../../environments';

//Import icons and images
import CustomIcon from '../../assets/images/CustomIcon';

//Import redux actions
import * as GuideMeActions from '../../Actions/GuideMeActions';

import {navigateMember} from '../../Helpers/SignupNavigationHelper';
import {checkFlag} from '../../Helpers/featureFlagsHelpers';

const CLAIM_INVOICES = 'ClaimInvoice';
const DRAFT_CLAIM_INVOICES = 'DraftClaimInvoices';
const LOADED_MEMBERID = 'loadedMemberId';
const SERVICE_AGREEMENT_LIST = 'ServiceAgreementsList';

export class WebSideMenu extends React.Component {
  state = {
    width: window.innerWidth,
    loading: true,
    dataArray: [],
    sideMenuItem: undefined,
    selectedItem: null,
  };

  constructor(props: any) {
    super(props);
    this.getPrimaryMenuItems.bind(this);
    this.getSecondaryMenuItems.bind(this);
    const openSections = {};
    this.state = {
      openSections,
    };
  }

  componentDidMount() {
    this._determineOpenMenu();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.guideMe.currentScreen !== this.props.guideMe.currentScreen) {
      this.navigateToScreen(
        this.props.guideMe.currentScreen,
        this.props.isMobile,
        true,
      );
    }
    const menuHeightDiff = 5;
    if (this.props.member && this.props.member.id !== prevProps.member.id) {
      this._determineOpenMenu();
      if (getValue(MENUE_ID) !== null) {
        removeItem(MENUE_ID);
      }
    }
    let isManagedMember =
      this.props.member.memberStatus === MEMBER_STATUS_DATA.ONBOARDING ||
      this.props.member.memberStatus === MEMBER_STATUS_DATA.MANAGED;

    if (
      isWeb &&
      !this.props.isMobile &&
      this.state.sideMenuItem &&
      prevProps.guideMe.welcomeGuideStep !== this.props.guideMe.welcomeGuideStep
    ) {
      var highlightedMenuItem = null;
      if (isManagedMember) {
        highlightedMenuItem = MenuItemsBudget.filter(menuItem => {
          return (
            menuItem.guideMePosition === this.props.guideMe.welcomeGuideStep
          );
        });
        if (highlightedMenuItem.length === 1) {
          highlightedMenuItem = highlightedMenuItem[0].screen;
        }
      } else {
        highlightedMenuItem = PRIMARY_TABS.members.filter(menuItem => {
          return (
            menuItem.guideMePosition === this.props.guideMe.welcomeGuideStep
          );
        });
        if (highlightedMenuItem.length === 1) {
          highlightedMenuItem = highlightedMenuItem[0].id;
        }
      }
      this.props.actions.GuideMeActions.setHighlightedMenuItem(
        highlightedMenuItem,
      );
    }
  }

  render() {
    if (this.props.isMobile) {
      return (
        <View style={[CommonStyles.borderMenuContainer]}>
          {this.getPrimaryMenuItems(this.props.isMobile)}
        </View>
      );
    } else {
      return (
        <View>
          {this.getPrimaryMenuItems(this.props.isMobile)}
          <GuideMeSummary isMobile={this.props.isMobile} />
        </View>
      );
    }
  }

  navigateToScreen = (id: any, isMobile: any, isRerender = false) => {
    if (isMobile) {
      this.props.closePopover();
    }
    if (
      (SECONDARY_TABS.Profile.some(element => element.id === id) ||
        SECONDARY_TABS.ProfilePlanEnabled.some(element => element.id === id) ||
        SECONDARY_TABS.ProfileOnBoarding.some(element => element.id === id)) &&
      this.state.openSections.Profile
    ) {
      this.setState({
        selectedItem: id,
        openSections: {
          Profile: true,
        },
      });
    } else if (
      SECONDARY_TABS.BudgetsPayments.some(element => element.id === id) &&
      this.state.openSections.BudgetsPayments
    ) {
      this.setState({
        selectedItem: id,
        openSections: {
          BudgetsPayments: true,
        },
      });
    } else if (NAVIGATION_PRIMARY_HEADERS.includes(id)) {
      this.setState({
        selectedItem: null,
      });
    }
    if (!isRerender) {
      this.props.navigation.navigate(id);
    }
  };

  // This sets component details to highlight for guideMe steps
  setFocusComponentInfo = (screenId: any, nativeEvent: any) => {
    this.setState({sideMenuItem: nativeEvent.layout});
  };

  getPrimaryMenuItems = (isMobile: any) => {
    const primaryMenu = this.props.isCrew
      ? PRIMARY_TABS.crew
      : PRIMARY_TABS.members;
    if (isMobile) {
      return primaryMenu.map(item => {
        if (env.IS_STAFF_APP === types.APP_TYPE_STAFF) {
          return (
            <WebAccordianMenu
              item={item}
              navigateToScreen={this.navigateToScreen}
              onPress={this._toggleAccordian}
              isOpen={!!this.state.openSections[item.id]}
              getSecondaryMenuItems={this.getSecondaryMenuItems}
              user={this.props.user}
              navigation={this.props.navigation}
              setLoading={this.props.setLoading}
              isMobile={this.props.isMobile}
              layoutInfo={this.setFocusComponentInfo}
              isItemHighLighted={this.props.guideMe.highlightedMenuItem}
            />
          );
        }else {
          return primaryMenu.map(item => {
            return (
              <View id={item.id}>
                <StandardTouchableOpacity
                  onPress={() => this.navigateToScreen(item.id, isMobile)}
                  onLayout={({nativeEvent}: any) => {
                    this.setFocusComponentInfo(item.id, nativeEvent);
                  }}
                  style={[
                    CommonStyles.borderSecondaryMenu,
                    CommonStyles.containerMenuOptionMobile,
                  ]}
                >
                  <StandardText
                    style={[
                      BrandStyles.TextColor2,
                      CommonStyles.font15,
                      CommonStyles.customFontBold,
                    ]}
                  >
                    {item.title}
                  </StandardText>
                </StandardTouchableOpacity>
                {this.getSecondaryMenuItems(isMobile, item.id)}
              </View>
            );
          });
        }
      });
    
    } else {
      return primaryMenu.map(item => {
        return (
          <WebAccordianMenu
            item={item}
            navigateToScreen={this.navigateToScreen}
            onPress={this._toggleAccordian}
            isOpen={!!this.state.openSections[item.id]}
            getSecondaryMenuItems={this.getSecondaryMenuItems}
            user={this.props.user}
            navigation={this.props.navigation}
            setLoading={this.props.setLoading}
            isMobile={this.props.isMobile}
            layoutInfo={this.setFocusComponentInfo}
            isItemHighLighted={this.props.guideMe.highlightedMenuItem}
          />
        );
      });
    }
  };

  _determineOpenMenu = () => {
    /* Set the default selected primary menu item based on user status */
    let menuId = PROFILE;
    if (this.props.isCrew) {
      if (!parseInt(getValue(WEB_STORE.LOADED_MEMBER_ID))) {
        // After select a member set the primary menu item to budget-payments
        if (
          Object.keys(this.state.openSections)[0] == MEMBERS &&
          (this.props.member.memberStatus === MEMBER_STATUS_DATA.PLAN_READY ||
            this.props.member.memberStatus === MEMBER_STATUS_DATA.ONBOARDING ||
            this.props.member.access.finances !== ACCESS_FULL)
        ) {
          menuId = PROFILE;
        } else if (Object.keys(this.state.openSections)[0] == MEMBERS) {
          menuId = BUDGET_PAYMENTS;
        } else {
          menuId = MEMBERS;
        }
      } else {
        if (
          this.props.member.memberStatus === MEMBER_STATUS_DATA.PLAN_READY ||
          this.props.member.memberStatus === MEMBER_STATUS_DATA.ONBOARDING ||
          this.props.member.access?.finances !== ACCESS_FULL
        ) {
          menuId = PROFILE;
        } else {
          menuId = BUDGET_PAYMENTS;
        }
      }
    } else {
      if (this.props.member.memberStatus === MEMBER_STATUS_DATA.MANAGED) {
        menuId = BUDGET_PAYMENTS;
      } else {
        menuId = PROFILE;
      }
    }
    if (getValue(WEB_STORE.CURRENT_MENU) !== null) {
      menuId = getValue(WEB_STORE.CURRENT_MENU);
    }
    if (getValue(MENUE_ID) !== null) {
      menuId = getValue(MENUE_ID);
    }
    this._toggleAccordian(menuId);
  };

  _toggleAccordian = (id: any) => {
    const {
      state: {openSections},
    } = this;

    const isOpen = !!openSections[id];
    if (this.props.member.memberStatus === MEMBER_STATUS_DATA.MANAGED) {
      setValue(WEB_STORE.CURRENT_MENU, id);
    }

    if (!openSections[id]) {
      this.setState({
        openSections: {
          [id]: !isOpen,
        },
      });
    }
  };

  _checkMenuNoAccess = () => {
    if (
      this.props.member.memberStatus === MEMBER_STATUS_DATA.PLAN_READY ||
      this.props.member.memberStatus === MEMBER_STATUS_DATA.ONBOARDING ||
      this.props.member.planStatus === PLAN_STATUS_DATA.PM_EXPIRED
    ) {
      return true;
    }
    if (
      this.props.member.access &&
      this.props.member.access.finances !== ACCESS_FULL
    ) {
      return true;
    }
    return false;
  };

  _checkMenuReadOnlyAccess = () => {
    if (
      this.props.member.memberStatus === MEMBER_STATUS_DATA.PLAN_READY ||
      this.props.member.memberStatus === MEMBER_STATUS_DATA.ONBOARDING ||
      this.props.member.planStatus === PLAN_STATUS_DATA.PM_EXPIRED
    ) {
      return false;
    }
    if (
      this.props.member.access &&
      this.props.member.access.finances === ACCESS_READ_ONLY
    ) {
      return true;
    } else {
      return false;
    }
  };

  __isCurrentUserStaff = () => {
    const userObj = this.props.user;

    // fetch budget of the selected person if logged in user is a employee
    if (userObj.isEmployee === true || userObj.isFinancialStaff === true) {
      return true;
    }
    return false;
  };

  getSecondaryMenuItems = (isMobile: any, itemId: any) => {
    const userObj = this.props.user;
    let secondaryMenuItems: any = [];
    if (itemId === BUDGET_PAYMENTS) {
      // Remove messages menu item from budget if user not plan managed
      let budgetMenuItems = SECONDARY_TABS.BudgetsPayments;
      if (this.props.member.memberStatus !== MEMBER_STATUS_DATA.MANAGED) {
        budgetMenuItems = budgetMenuItems.filter(function (obj) {
          return obj.id !== 'Notifications';
        });
      }
      secondaryMenuItems = budgetMenuItems;

      if (
        this._checkMenuNoAccess() ||
        (this.props.admin.featureFlags &&
          !checkFlag('claimInvoice', this.props.admin.featureFlags))
      ) {
        secondaryMenuItems = secondaryMenuItems.filter(
          item =>
            item.id !== DRAFT_CLAIM_INVOICES && item.id !== CLAIM_INVOICES,
        );
      }

      const isSAEnabled = getValue(WEB_STORE.SERVICE_AGREEMENTS_ENABLED);

      if (isSAEnabled != 'undefined' && isSAEnabled != null) {
        const isSAFeatureEnabled = JSON.parse(isSAEnabled);
        if (isSAFeatureEnabled == false) {
          secondaryMenuItems = secondaryMenuItems.filter(
            item => item.id !== SERVICE_AGREEMENT_LIST,
          );
        }
      }
      if (
        this.props.member.memberStatus === MEMBER_STATUS_DATA.ONBOARDING ||
        this.props.payInvoice.draftInvoicesCount === 0
      ) {
        secondaryMenuItems = secondaryMenuItems.filter(
          item => item.id !== DRAFT_CLAIM_INVOICES,
        );
      }
    }

    if (itemId === PROFILE) {
      // Remove messages menu item from profile if user is plan managed
      let profileMenuItems = SECONDARY_TABS.Profile;
      const enableNewPlanOption = this.props.member.enableNewPlanOption;

      if (this.props.member.memberStatus === MEMBER_STATUS_DATA.ONBOARDING) {
        if (!enableNewPlanOption) {
          profileMenuItems = SECONDARY_TABS.ProfileOnBoarding;
        } else {
          profileMenuItems = SECONDARY_TABS.ProfilePlanEnabled;
        }
      }

      if (this.props.member.memberStatus === MEMBER_STATUS_DATA.MANAGED) {
        if (!enableNewPlanOption) {
          profileMenuItems = SECONDARY_TABS.ProfileOnBoarding;
        } else {
          profileMenuItems = SECONDARY_TABS.ProfilePlanEnabled;
        }
        profileMenuItems = profileMenuItems.filter(function (obj) {
          return obj.id !== 'Notifications';
        });
      }
      secondaryMenuItems = profileMenuItems;
    }

    const items = secondaryMenuItems.map((item, index) => {
      if (
        this.props.member.access &&
        this.props.member.access.documents === ACCESS_NONE &&
        item.id === DOCUMENTS
      ) {
        item.disabledForReadOnly = true;
      }

      if (this._checkMenuNoAccess()) {
        if (
          (!this.__isCurrentUserStaff() ||
            (this.__isCurrentUserStaff() &&
              this.props.member.pmExitDate == '')) &&
          BUDGET_ONBOARD_DISABLED_MENU.includes(item.id)
        ) {
          item.disabledForReadOnly = true;
        } else if (
          this.props.member.memberStatus === MEMBER_STATUS_DATA.PLAN_READY
        ) {
          item.disabledForReadOnly = false;
        }
      } else if (
        this.props.member.memberStatus === MEMBER_STATUS_DATA.MANAGED
      ) {
        item.disabledForReadOnly = false;
      }

      if (this.props.member.pmtnCAccepted) {
        if (item.id === SIGNUP_FOR_PLAN_MANAGEMENT) {
          item.id = UPLOADPLAN;
        }
      } else {
        if (item.id === UPLOADPLAN) {
          item.id = SIGNUP_FOR_PLAN_MANAGEMENT;
        }
      }

      if (
        !this.__isCurrentUserStaff() ||
        (this.__isCurrentUserStaff() && this.props.member.pmExitDate == '')
      ) {
        if (
          !this.props.member.memberStatus &&
          (BUDGET_ONBOARD_DISABLED_MENU.includes(item.id) ||
            PROFILE_DISABLED_MENU.includes(item.id))
        ) {
          item.disabledForReadOnly = true;
        }

        if (
          this.props.member.access &&
          item.id === NOTIFICATIONS &&
          (this.props.member.access.notifications === ACCESS_NONE ||
            !this.props.member.access.decisionMaker)
        ) {
          item.disabledForReadOnly = true;
        }

        // check for finance readonly access
        if (
          this._checkMenuReadOnlyAccess() &&
          (item.id === PaymentsHistory || item.id === PLANS)
        ) {
          item.disabledForReadOnly = false;
        }

        if (
          !this._checkMenuNoAccess() &&
          (item.id === APPROVAL_TYPE.CLAIM_INVOICE || item.id === DraftInvoice)
        ) {
          item.disabledForReadOnly = false;
        }
      }
      if (
        this.props.user.demoProfile &&
        (item.id === SIGNUP_FOR_PLAN_MANAGEMENT || item.id === UPLOADPLAN)
      ) {
        item.disabledForReadOnly = true;
      }

      return (
        <View id={item.id}>
          {this._renderNavItem(
            item,
            isMobile,
            index !== secondaryMenuItems.length - 1,
          )}
        </View>
      );
    });

    if (isMobile) {
      return <View style={BrandStyles.borderColor6}>{items}</View>;
    } else {
      return (
        <View style={[CommonStyles.conatinerMenu, BrandStyles.borderColor6]}>
          {items}
        </View>
      );
    }
  };

  _determineNavItemDisable = (item: any) => {
    if (isEmpty(this.props.member) || item.disabledForReadOnly) {
      return true;
    } else {
      return false;
    }
  };

  _renderNavItem = (item: any, isMobile: any, lastIndex: any) => {
    // This condition can be move into seperate function
    // When MyProfile related menu items available
    if (this._determineNavItemDisable(item)) {
      return (
        <View
          style={[
            CommonStyles.justifyFlexRowStart,
            CommonStyles.disMenuItemDisabled,
          ]}
        >
          {this._showNotificationBadge(item.id)}
          <CustomIcon
            style={[
              CommonStyles.sideMenuIconDisabled,
              CommonStyles.zIndex02Notification,
            ]}
            name={item.image}
          />
          <StandardText
            style={[
              CommonStyles.paddingLeft10,
              CommonStyles.font15,
              BrandStyles.TextColor13,
            ]}
          >
            {item.title}
          </StandardText>
        </View>
      );
    }
    return (
      <StandardTouchableOpacity
        onPress={() => this.navigateToScreen(item.id, isMobile)}
        style={[
          this.determineBackground(item.id),
          this.isItemHighlighted(item.id),
        ]}
      >
        <View
          style={[
            CommonStyles.customFont,
            CommonStyles.containerSubMenuOption,
            CommonStyles.paddingLeft10,
            lastIndex ? CommonStyles.borderSecondaryMenu : null,
          ]}
        >
          {this._showNotificationBadge(item.id)}
          <CustomIcon
            style={[
              CommonStyles.sideMenuIcon,
              CommonStyles.zIndex02Notification,
            ]}
            name={item.image}
          />
          <StandardText
            style={[CommonStyles.font15, CommonStyles.paddingLeft10]}
          >
            {item.title}
          </StandardText>
        </View>
      </StandardTouchableOpacity>
    );
  };

  determineBackground(id: any) {
    if (this.state.selectedItem === id) {
      return BrandStyles.lightGreyBgColour;
    }
  }

  isItemHighlighted(screen: any) {
    if (this.props.guideMe.highlightedMenuItem === screen) {
      return [CommonStyles.guideMeHighlightWrapper, BrandStyles.borderColor12];
    }
  }

  _showNotification() {
    let showNotification = false;
    if (this.props.member.access != undefined) {
      if (this.props.member.access.notifications != 'none') {
        showNotification = true;
      }
    } else {
      showNotification = true;
    }
    return showNotification;
  }

  _showNotificationBadge(screen: any) {
    if (screen == NOTIFICATIONS && this._showNotification()) {
      let badgeBgColor = BrandStyles.notificationBadgeBgColor;
      let badgeTxtColor = BrandStyles.notificationBadgeTextColor;
      if (
        this.props.selectedIcon != undefined &&
        this.props.selectedIcon == SCREEN_NOTIFICATIONS
      ) {
        badgeBgColor = BrandStyles.notificationSelectedBadgeBgColor;
        badgeTxtColor = BrandStyles.notificationSelectedBadgeTextColor;
      }
      return (
        <NotificationBadge
          count={this._renderUnReadCount()}
          bgColor={badgeBgColor}
          txtColor={badgeTxtColor}
          badgeContainerStyle={CommonStyles.tabIconBadgeWrapperSmall}
        />
      );
    }
  }
  _renderUnReadCount = () => {
    if (!isEmpty(this.props.notificationData)) {
      return this.props.notificationData.totalUnreadCount;
    } else {
      return 0;
    }
  };
}

const mapStateToProps = (state: any) => ({
  loadedMemberId: state.LoadedMemberReducer,
  guideMe: state.GuideMeReducer,
  payInvoice: state.PayInvoiceReducer,
  user: state.UserReducer,
  admin: state.AdminReducer,
});
const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    GuideMeActions: bindActionCreators(GuideMeActions, dispatch),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WebSideMenu);
