/*
 * Author: Shelan Cooray
 * Date: 07/09/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Single select drop down/picker for "When do you use this service?" Unit of Measure question.
 */

import React, {Component} from 'react';
import {View} from 'react-native';

import {StandardPicker} from './';

// Import Stylesheets
import BrandStyles from '../../Styles/BrandStyles';
import CommonStyles from '../../Styles/CommonStyles';

class SelectWhenDoYouUse extends Component {
  state = {
    selectedOption: this.props.values[0].type,
  };

  constructor(props: any) {
    super(props);
    this.renderOptions = this.renderOptions.bind(this);
    this.handleIndexChange = this.handleIndexChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.selectedValues.length > 0) {
      this.setState({
        selectedOption: this.props.selectedValues[0].value,
      });
    }
  }

  renderOptions() {
    let options: any = [];
    if (this.props.values != null) {
      this.props.values.map((option: any, index: any) => {
        options.push({
          description: option.description,
          type: option.type,
        });
      });
    }
    return options;
  }

  handleIndexChange(itemValue: any, itemIndex: any) {
    if (itemIndex !== 0) {
      // Minus 1 value to match existing USAGE indexes, since we have a default 'Please select an option' for the first time.
      const index = itemIndex - 1;
      this.setState(
        {
          ...this.state,
          selectedOption: itemValue,
        },
        this.props.save(this.props.type, index, null, null, true),
      );
    }
  }

  render() {
    return (
      <View
        style={[BrandStyles.secondaryBgColor3, CommonStyles.borderRadius10]}
      >
        <StandardPicker
          selectedValue={this.state.selectedOption}
          pickerOptions={this.renderOptions()}
          onValueChange={(itemValue: any, itemIndex: any) =>
            this.handleIndexChange(itemValue, itemIndex)
          }
          style={[
            CommonStyles.standardPicker,
            CommonStyles.standardPickerBackgroundColorGray,
          ]}
        />
      </View>
    );
  }
}

export default SelectWhenDoYouUse;
