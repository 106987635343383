/*
 * Author: Declan Hart
 * Date: 15/07/2019
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * Displays the Notes section
 */

import React, {Component} from 'react';
import {View, ScrollView} from 'react-native';
import {GridView, FormTitle, RequiredIndicator} from './';
import {StandardInput, StandardButton, StandardText} from '../Atoms';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {ADD_CREW as crewMessages} from '../../Constants/Messages';
import {isWeb} from '../../Constants/Constants';
import {NAVIGATION_TEXT} from '../../Constants/Constants2';

const INPUTS = {
  PHONE_NUMBER: 'phoneNumber',
  EMAIL: 'email',
};

class InvoiceNotes extends Component {
  inputs: any;
  constructor() {
    super();
    this.inputs = {};
  }

  render() {
    if (this.props.readOnly) {
      return (
        <ScrollView style={CommonStyles.ModalTitleContainer}>
          <StandardText
            style={[
              CommonStyles.ModalTextInputWrapper,
              BrandStyles.primaryBgColor1,
              CommonStyles.customFont,
              CommonStyles.height150,
            ]}
          >
            {this.props.note}
          </StandardText>
        </ScrollView>
      );
    } else {
      return (
        <StandardInput
          readOnly={this.props.readOnly}
          value={this.props.note}
          accessibilityLabel={this.props.noteType}
          onSubmitEditing={() => {}}
          onChangeText={(invoiceNotes: any) =>
            this.props.editInvoiceNotes(invoiceNotes, this.props.noteType)
          }
          blurOnSubmit={false}
          multiline={true}
          style={[
            CommonStyles.ModalTextInputWrapper,
            BrandStyles.primaryBgColor1,
            CommonStyles.customFont,
            CommonStyles.height150,
          ]}
          underlineColorAndroid="transparent"
          errorMessage={this.props.validInvoiceNotes}
        />
      );
    }
  }
}

export default InvoiceNotes;
