/*
 * Author: Andrew Seeley
 * Date: 31/01/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a standard component which adds support for the following
 * - Error state with message
 * This is a standard tounchable opacity component which adds support for the following
 * - Set's the default accessibilitylabel, this is needed for:
 *     - Automation testing with RN - their tools use this label as RN does not have support for tag
 *     - Ensures voiceover follows Google guidelines as per https://support.google.com/accessibility/android/answer/6378990?hl=en
 */

import React, {Component} from 'react';
import {View, TouchableOpacity, StyleSheet} from 'react-native';
import PropTypes from 'prop-types';

import * as types from '../../Constants/Constants';
import * as types2 from '../../Constants/Constants2';
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';
import {INPUT_CONTROL_ACCESSIBILITY} from '../../Constants/Messages';

class StandardTouchableOpacity extends Component {
  render() {
    const accessible =
      this.props.accessible !== undefined ? this.props.accessible : true;

    // Since it's technically a button (tapping on this will cause an action)
    // Append the word button at the end of the accessibilityLabel
    // As a normal react native button includes this at the end, in order to keep the voiceover per google guidelines
    let accessibilityLabel = `${this.props.accessibilityLabel} ${
      this.props.mandatory ? INPUT_CONTROL_ACCESSIBILITY.MANDATORY : ''
    }   button`;

    let readOnly = false;
    if (this.props.readOnly == types.ACCESS_READ_ONLY) {
      readOnly = true;
    } else {
      readOnly = false;
    }

    if (readOnly == true) {
      return null;
    }
    return (
      <TouchableOpacity
        {...this.props}
        accessible={accessible}
        disabled={this.props.disabled}
        accessibilityLabel={accessibilityLabel}
        style={this.props.style}
        activeOpacity={this.props.activeOpacity}
        onPress={this.props.onPress}
      >
        {this.props.children}
      </TouchableOpacity>
    );
  }
}

// The accessibilityLabel is required for this component so enforce it
// If not passed it will show a warning to prompt you to add it
StandardTouchableOpacity.propTypes = {
  accessibilityLabel: PropTypes.string.isRequired,
};

export default StandardTouchableOpacity;
