/*
 * Author: Andrew Lee
 * Date: 04/03/2018
 * Copyright © 2018 Leap in!. All rights reserved.
 *
 * This is a component that will display the selected disability and also give a way for the user to remove the selected disability from their list.
 */

import React, {Component} from 'react';
import {View, StyleSheet, Image} from 'react-native';
import {StandardText, StandardButton} from '../Atoms';

// Import Stylesheets
import CommonStyles from '../../Styles/CommonStyles';
import BrandStyles from '../../Styles/BrandStyles';

import {CloseButtonPopupModal3x} from '../../assets/images';

class AboutMeDisabilitySelected extends Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    const {disability, removeDisability, index} = this.props;
    // Adjusts colors for read only Mode.
    let background = BrandStyles.primaryBgColor1;
    let textColor = BrandStyles.TextColor5;

    return (
      <View
        key={index}
        style={[
          CommonStyles.flexRow,
          BrandStyles.brandBorderColor2,
          CommonStyles.DisabilitySelectedContainer,
          CommonStyles.alignSelfFlexStart,
        ]}
      >
        <View
          style={[
            background,
            CommonStyles.DisabilitySelectedPadding,
            CommonStyles.flexShrink1,
          ]}
        >
          <StandardText
            style={[CommonStyles.customFont, textColor, CommonStyles.font18]}
          >
            {disability.type}
          </StandardText>
        </View>
        <View
          style={[
            CommonStyles.DisabilityClosePadding,
            BrandStyles.brandBorderColor2,
            CommonStyles.justifyCenter,
          ]}
        >
          <StandardButton
            style={CommonStyles.SelectProviderFlex1}
            readOnly={this.props.readOnly}
            onPress={() => removeDisability(disability.type)}
            accessibilityLabel={`Remove ${disability.type}`}
          >
            <Image
              source={CloseButtonPopupModal3x}
              style={[CommonStyles.DisabilityCloseIcon]}
            />
          </StandardButton>
        </View>
      </View>
    );
  }
}

export default AboutMeDisabilitySelected;
